
export enum RecordStatus{
active=1,
passive=2,
deleted=3,
approval=4
}

export const RecordStatusList = [
{key:'active', value:1, description:''},
{key:'passive', value:2, description:''},
{key:'deleted', value:3, description:''},
{key:'approval', value:4, description:''}
]
