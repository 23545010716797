import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';


export const usePazaramaCategoryService = defineStore({
id: 'PazaramaCategoryService',
actions: {


	async getGetCategoryList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PazaramaCategory/GetCategoryList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetCategoryAttributes(categoryId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PazaramaCategory/GetCategoryAttributes/${shared.firm.id}/${categoryId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetDelivery()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PazaramaCategory/GetDelivery/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetBrands(cacheDay:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PazaramaCategory/GetBrands/${shared.firm.id}/${cacheDay}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
