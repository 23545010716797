import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "desc-2" }
const _hoisted_2 = { style: {"color":"black"} }
const _hoisted_3 = { class: "desc-3" }
const _hoisted_4 = { style: {"color":"black"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NTagList = _resolveComponent("NTagList")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NButtonGroup = _resolveComponent("NButtonGroup")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NListView = _resolveComponent("NListView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NFormGrid, { cols: "100px 1fr" }, {
      default: _withCtx(() => [
        _createVNode(_component_NFormField, {
          "grid-cols": "100px 1fr",
          label: "İş İstasyon Adı",
          span: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              placeholder: "İş İstasyonu Adı",
              modelValue: _ctx.model.isIstasyonu,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.isIstasyonu) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          "grid-cols": "100px 1fr",
          label: "Makine Hatları",
          span: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NTagList, {
              modelValue: _ctx.model.hatlar,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.hatlar) = $event)),
              type: "string"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          "grid-cols": "100px 1fr",
          label: " ",
          span: "2"
        }, {
          default: _withCtx(() => [
            (_ctx.model.id && _ctx.model.id.length > 0 )
              ? (_openBlock(), _createBlock(_component_NButtonGroup, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NButton, {
                      onClick: _ctx.uretimTanimiGuncelle,
                      size: "small",
                      type: "warning",
                      icon: "edit"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Güncelle")
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_NButton, {
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.model={})),
                      size: "small",
                      type: "danger",
                      icon: "x-circle"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("İptal")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_NButton, {
                  key: 1,
                  onClick: _ctx.uretimTanimiEkle,
                  size: "small",
                  type: "success",
                  icon: "plus"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Ekle")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_NListView, {
      items: _ctx.uretimTanimlari,
      modelValue: _ctx.uretimTanimiSelected,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.uretimTanimiSelected) = $event)),
      size: "xsmall"
    }, {
      item: _withCtx(({ item }) => [
        _createVNode(_component_NGrid, {
          cols: "1fr 70px",
          gap: "5px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NGrid, { cols: "1fr" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createTextVNode(" İş İstasyonu : "),
                  _createElementVNode("span", _hoisted_2, _toDisplayString(item.isIstasyonu), 1)
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createTextVNode(" Makina Hatları : "),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(item.hatlar.join(' , ')), 1)
                ])
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_NGrid, { cols: "auto auto" }, {
              default: _withCtx(() => [
                _createVNode(_component_NIcon, {
                  style: {"line-height":"40px"},
                  onClick: ($event: any) => (_ctx.uretimTanimiSil(item)),
                  stroke: "#F93155",
                  size: 15,
                  type: "trash"
                }, null, 8, ["onClick"]),
                _createVNode(_component_NIcon, {
                  style: {"line-height":"40px"},
                  onClick: ($event: any) => (_ctx.uretimTanimiEdit(item)),
                  stroke: "#ffba00",
                  size: 15,
                  type: "edit"
                }, null, 8, ["onClick"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["items", "modelValue"])
  ], 64))
}