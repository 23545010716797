
import { defineComponent, reactive, ref, toRefs } from "vue";

export default defineComponent({
  name: "TreeView",
  components: {},
  props: {
    items: {
      type: Array
    },
    subField: {
      type: String
    }
  },
  setup(props) {
    const { subField } = toRefs(props);

    const localState = reactive({
      subF: ref("")
    });

    return {
      ...toRefs(localState)
    };
  }
});
