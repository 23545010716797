
import {computed, defineComponent, onMounted, onUnmounted, reactive, ref, toRefs} from "vue";

import ko from "knockout";
import "devexpress-reporting/dx-webdocumentviewer";
import {useNesilceConfig} from "@/api/NesilceConfig";
import {useRoute} from "vue-router";
import {JSReportViewer} from "devexpress-reporting/dx-webdocumentviewer";
import {ajaxSetup} from "@devexpress/analytics-core/analytics-utils"

export default defineComponent({
    components: {},
    props: {},
    setup(props, {emit}) {
        const localState = reactive({
            viewer: ref({} as JSReportViewer),
            slientPrint: ref(useRoute().params.slientPrint),
            reportName: ref(useRoute().params.reportName),
            modelValue: ref(),
        });


        async function load() {

            // console.log("report name" , props.reportName);
            // console.log("model vale" , props.modelValue);

            const config = await useNesilceConfig().config;

            localState.modelValue = window.frameElement?.attributes.getNamedItem('data')?.nodeValue;

            const reportUrl = ko.observable(localState.reportName); // The URL of a report.
            const requestOptions = {
                host: config.API_URL,
                invokeAction: "/DXXRDV",
            };

            ko.applyBindings(
                {
                    reportUrl: reportUrl,
                    requestOptions: requestOptions,

                    callbacks: {
                        DocumentReady: (r: any, e: any) => {
                            //console.log('localState.slientPrint', localState.slientPrint)
                            if (localState.slientPrint == 'true') {
                                r.Print(-1)
                            }


                            // fix landscape paper
                            try {
                                const isWide = r.GetPreviewModel().reportPreview?.pages()?.[0].width() >= 1500
                                if (isWide)
                                    r.GetPreviewModel().reportPreview.zoom(1.1); // 110% Zoom
                            }
                            catch {
                                //
                            }

                        },
                        CustomizeExportOptions: function (s: JSReportViewer, e: any) {

                            //const model = e.GetExportOptionsModel(ExportFormatID.PDF);
                            //model.documentOptions.author('nesilce')
                            //setTimeout(() => localState.viewer?.GetPreviewModel().ExportTo('pdf', true), 6000)

                        },
                        ParametersSubmitted: function (s: any, e: any) {


                            //e.Parameters.filter(item => item.Key === "MyComplexParameter")[0].Value = JSON.stringify({merhaba: 'televole'})

                        },
                        BeforeRender: function (s: JSReportViewer, e: any) {
                            s.GetPreviewModel().reportPreview.zoom(1.5); // 150% Zoom
                            //AsyncExportApproach(true);
                        },
                        OnExport(viewer: any, e: any) {
                            // e.FormData['access_token'] = "FFFFFFFFFFFFFFFFF";
                            // console.log('exportviewer', viewer)
                            // console.log('export', e)
                        },
                        Init: function (s: any) {
                            localState.viewer = s;
                            const parametersModel = localState.viewer.GetParametersModel();
                            if (!parametersModel) {
                                return;
                            }
                            const serializeParametersOriginal = parametersModel.serializeParameters;

                            parametersModel.serializeParameters = function () {
                                const serializedParameters = serializeParametersOriginal.apply(parametersModel);

                                //const reportParameter = serializedParameters.filter(function (p:any) { return p.Key === "data" })[0];
                                serializedParameters?.forEach((reportParameter: any) => {
                                    if (reportParameter) {
                                        if (localState.modelValue)
                                            reportParameter.Value = localState.modelValue;
                                    }
                                })

                                return serializedParameters;
                            }
                        }
                    }
                },
                localState.viewer as any,
            );
        }

        onMounted(() => {
            load()
            window.addEventListener('message', exportPdf);


        })

        onUnmounted(() => {
            //alert('unmounted')
            ko.cleanNode(localState.viewer as any);

            //document.addEventListener()

        })

        function exportPdf(e: any) {

            if (e.data) {
                const splittedData = String(e.data)?.split(':')
                if (splittedData && splittedData[0] && splittedData[0] == 'exportPdf') {

                    const mailId = splittedData[1]
                    if (mailId == undefined)
                        return

                    const firma = splittedData[2]
                    if (firma == undefined)
                        return

                    const previewModel = localState.viewer.GetPreviewModel();
                    ajaxSetup.ajaxSettings = {headers: {'mailId': mailId, 'firma': firma}};

                    try {
                        previewModel.ExportTo('pdf', true)

                    } catch (e:any) {
                        console.log('Report Hata! :', e)
                    }

                    setTimeout(() => ajaxSetup.ajaxSettings = {headers: {}}, 1000)

                }
            }
        }

        return {
            ...toRefs(localState),
            exportPdf
        };
    }
});

