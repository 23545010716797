

export {};

String.prototype.formatDate = function(): string {
  return new Date(Date.parse(this as string)).toLocaleDateString("tr-TR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

String.prototype.formatDateTime = function(): string {
  return new Date(Date.parse(this as string)).toLocaleDateString("tr-TR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  });
};

String.prototype.formatTime = function(): string {
  return new Date(Date.parse(this as string)).formatTime();
};

String.prototype.isNullOrEmpty = function():boolean{
  const ifade = this as string;
  if (ifade && ifade.length > 0 )
    return false ;
  else
    return true ;
}


String.prototype.icindeGeciyorMu = function(aranan: string ): boolean {
  let kayit =this.slice();
  //console.log("kayit", kayit);
  //console.log("aranan", aranan);

  const degisecekHarfler = [
    ["ı", "i"],
    ["ğ", "g"],
    ["ç", "c"],
    ["ş", "s"],
    ["ö", "o"],
    ["ü", "u"]
  ];

  if (!aranan || !kayit) return false;

  let isGeciyor = true;
  if (aranan && aranan.length > 0) {
    const arananArray = aranan.toLocaleLowerCase("tr-TR").split(" ").filter(i => i);

    kayit = kayit.toLocaleLowerCase("tr-TR");

    for (let i = 0; i < arananArray.length; i++) {

      for (let j = 0; j < degisecekHarfler.length; j++) {
        arananArray[i] = arananArray[i].replaceAll(degisecekHarfler[j][0], degisecekHarfler[j][1]);

        kayit = kayit.replaceAll(degisecekHarfler[j][0], degisecekHarfler[j][1]);
      }

      isGeciyor = isGeciyor && kayit.includes(arananArray[i]);

    }

    //console.log("arananArray", arananArray);
    //console.log("kayit", kayit);
   // console.log("isGeciyor", isGeciyor);

    return isGeciyor;
  }
  return false;

};

