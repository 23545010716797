
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import {StokView} from "@/api/models/StokView";
import {StokTip} from "@/api/models/StokTip";
import {StokMalzemeSekilTip, StokMalzemeSekilTipList} from "@/api/models/StokMalzemeSekilTip";
import NGrid from "@/components/Grid/NGrid.vue";
import {StokOzellikTipDigerApi} from "@/modules/StokOzellik/utils/StokOzellikTip_DigerApi";
import {GenelUtils} from "@/modules/Ortak/utils/GenelUtils";

export default defineComponent({
    components: {NGrid},
    props: {
        stok: {
            type: Object
        }
    },
    setup(props) {

        const localState = reactive({
            stokRef: computed(() => props.stok as StokView),
            alasimlar: [] as any[],
            malzemeSekilTipList: ref(StokMalzemeSekilTipList),
            malzemeSekilTip: StokMalzemeSekilTip,
            visibleModel: ref({} as StokMalzemeStandartTip),
            stokTipApi: StokOzellikTipDigerApi
        });

        watch(() => localState.stokRef.tip, (tip: any) => {
            loadOzellikler(tip);
        })

        watch(() => localState.stokRef.tipOzellikler?.carpan, (carpan: any) => {
            localState.stokRef.adetBolen = carpan;
        })

        watch(() => localState.stokRef.tipOzellikler?.malzemeSekilTip, (tip: any) => {
            localState.visibleModel = getVisibleModel() as StokMalzemeStandartTip
        })

        loadOzellikler(localState.stokRef.tip as StokTip);

        function loadOzellikler(tip: StokTip) {

            const siraListesi = [0,1,2,3,4,5,6,7,8,9,10,11,12];
            localState.malzemeSekilTipList = StokMalzemeSekilTipList?.filter(a => siraListesi.indexOf(a.value) >= 0)
            localState.malzemeSekilTipList.sort(GenelUtils.sortByEnums(siraListesi));

            localState.alasimlar = localState.stokTipApi.getAlasimListesiByTip(tip);

            localState.visibleModel = getVisibleModel()
            if (localState.stokRef.tipOzellikler && localState.stokRef.tipOzellikler.malzemeSekilTip == null)
              localState.stokRef.tipOzellikler.malzemeSekilTip = StokMalzemeSekilTip.LamaCubuk
        }

        function alasimValueChanged(e: any) {

            if (e.value && localState.stokRef.tipOzellikler && localState.stokRef.tipOzellikler?.malzemeSekilTip != StokMalzemeSekilTip.Ozel) {
                localState.stokRef.tipOzellikler.carpan = localState.alasimlar.find(a => a.name == e.value)?.value as number;
            }

        }

        function getVisibleModel(): StokMalzemeStandartTip {
            const tip = localState.stokRef?.tipOzellikler?.malzemeSekilTip as StokMalzemeSekilTip;
            let visibleModel = {
                labelGenislik: {name: 'Genişlik (mm)', isVisible: true},
                labelKalinlik: {name: 'Kalınlık (mm)', isVisible: true},
                labelYukseklik: {name: 'Yükseklik (mm)', isVisible: true},
                labelUzunluk: {name: 'Uzunluk (mm)', isVisible: true},
            }
            switch (tip) {
                // ----------------
                case StokMalzemeSekilTip.Ozel:
                    visibleModel = {
                        labelGenislik: {name: '', isVisible: false},
                        labelKalinlik: {name: '', isVisible: false},
                        labelYukseklik: {name: '', isVisible: false},
                        labelUzunluk: {name: 'Uzunluk (mm)', isVisible: true},
                    }
                    break
                case StokMalzemeSekilTip.LamaCubuk:
                case StokMalzemeSekilTip.PlakaLevha:
                case StokMalzemeSekilTip.KalinPlakaLevha:
                case StokMalzemeSekilTip.RuloLevha:
                    visibleModel = {
                        labelGenislik: {name: 'Genişlik (mm)', isVisible: true},
                        labelKalinlik: {name: 'Kalınlık (mm)', isVisible: true},
                        labelYukseklik: {name: '', isVisible: false},
                        labelUzunluk: {name: 'Uzunluk (mm)', isVisible: true},
                    };
                    break
                // ----------------
                // ----------------
                case StokMalzemeSekilTip.KareCubuk:
                case StokMalzemeSekilTip.AltigenCubuk:
                    visibleModel = {
                        labelGenislik: {name: 'Genişlik (mm)', isVisible: true},
                        labelKalinlik: {name: '', isVisible: false},
                        labelYukseklik: {name: '', isVisible: false},
                        labelUzunluk: {name: 'Uzunluk (mm)', isVisible: true},
                    };
                    break;
                // ----------------
                // ----------------
                case StokMalzemeSekilTip.KareKutuProfil:
                    visibleModel = {
                        labelGenislik: {name: 'Genişlik (mm)', isVisible: true},
                        labelKalinlik: {name: 'Kalınlık (mm)', isVisible: true},
                        labelYukseklik: {name: '', isVisible: false},
                        labelUzunluk: {name: 'Uzunluk (mm)', isVisible: true},
                    };
                    break;
                // ----------------
                // ----------------
                case StokMalzemeSekilTip.DikdortgenKutuProfil:
                case StokMalzemeSekilTip.LKosebent:
                case StokMalzemeSekilTip.TProfil:
                case StokMalzemeSekilTip.UProfil:
                    visibleModel = {
                        labelGenislik: {name: 'Genişlik (mm)', isVisible: true},
                        labelKalinlik: {name: 'Kalınlık (mm)', isVisible: true},
                        labelYukseklik: {name: 'Yükseklik (mm)', isVisible: true},
                        labelUzunluk: {name: 'Uzunluk (mm)', isVisible: true},
                    };
                    break;
                // ----------------
                // ----------------
                case StokMalzemeSekilTip.BoruProfil:
                    visibleModel = {
                        labelGenislik: {name: 'Dış Çap (mm)', isVisible: true},
                        labelKalinlik: {name: 'Kalınlık (mm)', isVisible: true},
                        labelYukseklik: {name: '', isVisible: false},
                        labelUzunluk: {name: 'Uzunluk (mm)', isVisible: true},
                    };
                    break;
                // ----------------
                // ----------------
                case StokMalzemeSekilTip.YuvarlakCubuk:
                    visibleModel = {
                        labelGenislik: {name: 'Dış Çap (mm)', isVisible: true},
                        labelKalinlik: {name: '', isVisible: false},
                        labelYukseklik: {name: '', isVisible: false},
                        labelUzunluk: {name: 'Uzunluk (mm)', isVisible: true},
                    };
                    break;
                // ----------------
            }

            return visibleModel as StokMalzemeStandartTip;
        }


        function isVisibleModel() {
            return (localState.visibleModel.labelGenislik && localState.visibleModel.labelGenislik.isVisible) ||
                (localState.visibleModel.labelKalinlik && localState.visibleModel.labelKalinlik.isVisible) ||
                (localState.visibleModel.labelYukseklik && localState.visibleModel.labelYukseklik.isVisible) ||
                (localState.visibleModel.labelUzunluk && localState.visibleModel.labelUzunluk.isVisible)
        }

        function getTeorikLabel() {
            const tip = localState.stokRef?.tipOzellikler?.malzemeSekilTip as StokMalzemeSekilTip;

            switch (tip) {
                case StokMalzemeSekilTip.Ozel:
                case StokMalzemeSekilTip.LamaCubuk:
                case StokMalzemeSekilTip.KareCubuk:
                case StokMalzemeSekilTip.YuvarlakCubuk:
                case StokMalzemeSekilTip.AltigenCubuk:
                case StokMalzemeSekilTip.KareKutuProfil:
                case StokMalzemeSekilTip.DikdortgenKutuProfil:
                case StokMalzemeSekilTip.LKosebent:
                case StokMalzemeSekilTip.TProfil:
                case StokMalzemeSekilTip.UProfil:
                case StokMalzemeSekilTip.BoruProfil:
                    return "mt/kg (Teorik)"

                case StokMalzemeSekilTip.PlakaLevha:
                case StokMalzemeSekilTip.KalinPlakaLevha:
                case StokMalzemeSekilTip.RuloLevha:
                    return "m2/kg (Teorik)"
            }
        }

        function getCarpanLabel() {
            const tip = localState.stokRef?.tipOzellikler?.malzemeSekilTip as StokMalzemeSekilTip;

            switch (tip) {
                case StokMalzemeSekilTip.Ozel:
                    return "mt/kg"
                case StokMalzemeSekilTip.LamaCubuk:
                case StokMalzemeSekilTip.KareCubuk:
                case StokMalzemeSekilTip.YuvarlakCubuk:
                case StokMalzemeSekilTip.AltigenCubuk:
                case StokMalzemeSekilTip.KareKutuProfil:
                case StokMalzemeSekilTip.DikdortgenKutuProfil:
                case StokMalzemeSekilTip.LKosebent:
                case StokMalzemeSekilTip.TProfil:
                case StokMalzemeSekilTip.UProfil:
                case StokMalzemeSekilTip.BoruProfil:
                case StokMalzemeSekilTip.PlakaLevha:
                case StokMalzemeSekilTip.KalinPlakaLevha:
                case StokMalzemeSekilTip.RuloLevha:
                    return "Çarpan (Özgül Ağırlık)"
            }
        }

        class StokMalzemeStandartTip {
            labelKalinlik?: StokMalzemeStandartTipField
            labelGenislik?: StokMalzemeStandartTipField
            labelUzunluk?: StokMalzemeStandartTipField
            labelYukseklik?: StokMalzemeStandartTipField
        }

        class StokMalzemeStandartTipField {
            name?: string;
            isVisible?: boolean;
        }


        function resetSizes() {
            const tip = localState.stokRef?.tipOzellikler?.malzemeSekilTip as StokMalzemeSekilTip;

            if (localState.stokRef.tipOzellikler) {
                switch (tip) {
                    case StokMalzemeSekilTip.LamaCubuk:
                    case StokMalzemeSekilTip.PlakaLevha:
                    case StokMalzemeSekilTip.KalinPlakaLevha:
                    case StokMalzemeSekilTip.RuloLevha:
                    case StokMalzemeSekilTip.KareCubuk:
                        localState.stokRef.tipOzellikler.yukseklik = 0;
                        break;

                    case StokMalzemeSekilTip.YuvarlakCubuk:
                        localState.stokRef.tipOzellikler.yukseklik = 0;
                        localState.stokRef.tipOzellikler.kalinlik = 0;
                        break;
                    case StokMalzemeSekilTip.AltigenCubuk:
                        localState.stokRef.tipOzellikler.yukseklik = 0;
                        localState.stokRef.tipOzellikler.kalinlik = 0;
                        break;
                    case StokMalzemeSekilTip.KareKutuProfil:
                        localState.stokRef.tipOzellikler.yukseklik = 0;
                        break;
                    case StokMalzemeSekilTip.DikdortgenKutuProfil:
                        break;
                    case StokMalzemeSekilTip.LKosebent:
                    case StokMalzemeSekilTip.TProfil:
                        break;
                    case StokMalzemeSekilTip.UProfil:
                        break;
                    case StokMalzemeSekilTip.BoruProfil:
                        localState.stokRef.tipOzellikler.yukseklik = 0;
                        break;
                }
            }
        }


        return {
            ...toRefs(localState),
            alasimValueChanged,
            isVisibleModel,
            getTeorikLabel,
            getCarpanLabel,
            resetSizes
        };
    }
});


