
import {computed, defineComponent} from "vue";
//:style="{'grid-template-columns': gridCols}"
const __default__ = defineComponent({
  name: "NFormFieldSimple",
  props: {
    gridCols: {
      type: String,
      default: "1fr"
    },
    label: {
      type: String,
      default: "Label"
    },
    span: {
      type: String,
      default: "1"
    },
    fill: {
      type: Boolean
    },
    gap: {
      type: String,
      default: '5px',
    },
    alignLabel: {
      type: String,
      default: 'center'
    }
  },
  setup(props) {
    const style = () => {
      return {"grid-template-columns": props.gridCols};
    };

    return {
      style
    };
  }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "5ee544f4": (_ctx.gap)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__