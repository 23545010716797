
import {computed, defineComponent, onMounted, reactive, ref, toRefs, watch} from "vue";
import NTag from "@/components/Tag/NTag.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import {useFileSettingsServiceCache} from "@/api/servicesCache/FileSettingsServiceCache";
import {Doviz} from "@/api/models/Doviz";
import HizliSatisStokAramaInput from "@/modules/HizliSatis/components/HizliSatisStokAramaInput.vue";
import {StokFlatView} from "@/api/models/StokFlatView";
import {useHizliSatisTabsStore} from "@/modules/HizliSatis/store/HizliSatisTabsStore";
import {useHizliSatisStokFlatStore} from "@/modules/HizliSatis/store/HizliSatisStokFlatStore";
import DataSource from "devextreme/data/data_source";
import {StokFlatSearchUtils} from "@/modules/Stok/utils/StokFlatSearchUtils";
import dxTileView from "devextreme/ui/tile_view";
import NIcon from "@/components/Icon/NIcon.vue";
import HizliStokDialog from "@/modules/Stok/dialogs/HizliStokDialog.vue";
import {StokPluUtils} from "@/modules/Stok/utils/StokPluUtils";
import StokXmlSelectListDialog from "@/modules/Stok/dialogs/StokXmlSelectListDialog.vue";
import {useUserSettingsStore} from "@/store/userSettingsStore";
import {useStokStore} from "@/modules/Stok/store/StokStore";
import {number} from "yup";


export default defineComponent({
    components: {StokXmlSelectListDialog, HizliStokDialog, NIcon, HizliSatisStokAramaInput, NGrid, NTag},
    props: [],
    setup(props, {emit}) {

        const localState = reactive({
            tileViewRef: ref(),
            columnCount: ref(4),
            filterGrupText: ref(""),
            id: Date.now().toString(),
            serverUrl: ref(),
            stokSearchText: ref("" as string),
            store: useHizliSatisTabsStore(),
            stokStore: useHizliSatisStokFlatStore(),
            dataSource: new DataSource({
                store: useHizliSatisStokFlatStore().stokArrayStore,
                reshapeOnPush: true,
                requireTotalCount: true
            }),
            tags: ref(useHizliSatisStokFlatStore().tags),
            showStokForm: ref(false),
            showXmlStokListDialog: ref(false),
            selectedStokId: ref(),
            totalCount: ref(-1),
            aramaInput:ref()

        });


        async function load() {
            localState.serverUrl = await useFileSettingsServiceCache().getServerUrl();
            setList();
        }

        onMounted(() => {
            load();
        });

        function getColumns() {
            let columns = "";
            for (let i = 0; i < localState.columnCount; i++) {
                columns += "1fr ";
            }
            return columns;
        }

        function getType(grup: string) {
            if (localState.filterGrupText == grup)
                return "success";
            return "";
        }

        function filter(grup: string) {
            localState.filterGrupText = grup;
            localState.stokSearchText = "";
            setList()
        }

        function setList() {
            //console.log("TEST")
            if (!localState.stokSearchText.isNullOrEmpty()) { // standart arama

                const pluItem = StokPluUtils.splitPluBarcode(localState.stokSearchText);

                if (pluItem) {// Plu barkod ise
                    const filter = StokFlatSearchUtils().stokKodFilter(pluItem.stokKodu);
                    localState.dataSource.filter(filter);
                    localState.dataSource.load().then((a: any[], b: any) => {

                        localState.totalCount = b.totalCount
                        if (b.totalCount == 1) {
                            const stok = a[0];
                            localState.store.addRowPlu(stok, pluItem);
                        } else {
                            filterStandard();
                        }
                    });

                } else { // PLU barkod değil ise

                    let miktar = 1;
                    let barcodeText = localState.stokSearchText;
                    const isMultiply = isMultiplyQuantity(barcodeText);
                    if (isMultiply && barcodeText.length > 6){
                        const splited = localState.stokSearchText.split('*');
                        if (splited.length == 2){
                            const containsAmount = parseFloat(splited[0] as string);
                            if (containsAmount > 1){
                                barcodeText = splited[1] as string;
                                miktar = containsAmount;
                                localState.stokSearchText = barcodeText;
                            }
                        }
                    }

                    const filter = StokFlatSearchUtils().stokBarkodFilter(barcodeText);
                    // Barkoda göre
                    localState.dataSource.filter(filter);
                    localState.dataSource.load().then((a: any[], b: any) => {
                        localState.totalCount = b.totalCount
                        if (b.totalCount == 1) {
                            const stok = a[0];
                            addToSatirDirect(stok,miktar);
                        }  else {
                            filterStandard();
                        }
                    });
                }

            } else {
                filterStandard()
            }


            setTimeout(()=>{
                localState.aramaInput.focusInput();
            },100)

        }

        
        function isMultiplyQuantity(barkod:string) {
            if (barkod && barkod.length > 5 && barkod.indexOf("*") > 0 && barkod.indexOf("*") <= 3){
                return true;
            }
            return  false;
        }

        function filterStandard() {
            if (localState.filterGrupText && localState.filterGrupText.length > 0) {
                localState.dataSource.filter((a: StokFlatView) => a.hizliSatis == localState.filterGrupText);
            } else if (!localState.stokSearchText.isNullOrEmpty()) {
                localState.dataSource.filter(StokFlatSearchUtils().stokGenelFilter(localState.stokSearchText));
            } else {
                localState.dataSource.filter("");
            }
            localState.dataSource.load().then((a: any[], b: any) => {
                localState.totalCount = b.totalCount
                if (localState.totalCount == 0 && isSearchXml()) {
                    localState.showXmlStokListDialog = true
                }
            });
        }

        function isSearchXml() {
            return useStokStore().isSearchXmlStok
        }

        function getResim(resimler: string[]) {
            if (resimler && resimler.length > 0)
                return localState.serverUrl + resimler[0];
            return "no-image.png";
        }

        function getDoviz(doviz: Doviz) {
            switch (doviz) {
                case Doviz.USD:
                    return "$";
                case Doviz.EUR:
                    return "€";
            }
            return "₺";
        }

        function addToSatir(stok: StokFlatView,miktar:number) {
            if(useStokStore().isAddSatirSingleClick)
              localState.store.addRow(stok,miktar);
        }
        function addToSatirDirect(stok: StokFlatView,miktar:number) {
              localState.store.addRow(stok,miktar);
        }
        function addToSatirDoubleClick(stok: StokFlatView,miktar:number) {
            if(!useStokStore().isAddSatirSingleClick)
            localState.store.addRow(stok,miktar);
        }

        function stokAraEnterKey() {
            localState.filterGrupText = "";
            setList();
            // if (localState.dataSource.store()..length == 1)
            //   addToSatir(localState.listFiltered[0]);

        }

        function getPlaceholder() {
            try {
                return `Barkod, Stok Adı, Stok Kodu vs. [Enter] (${localState.stokStore.count})`;
            } catch {
                return `Barkod, Stok Adı, Stok Kodu vs. [Enter]`;
            }
        }

        function getMiktarStyle(miktar: number) {
            if (miktar <= 0)
                return {
                    color: 'red'
                }
            return {}
        }

        function editClick(stokId: string) {
            localState.selectedStokId = stokId;
            localState.showStokForm = true;

        }


        return {
            ...toRefs(localState),
            stokAraEnterKey,
            load,
            getColumns,
            getType,
            filter,
            getResim,
            getDoviz,
            addToSatir,
            getPlaceholder,
            getMiktarStyle,
            editClick,
            addToSatirDoubleClick
        };
    }
});

