
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import { N11CategoryItem } from "@/api/models/N11CategoryItem";
import NDropDownTreeView from "@/components/DropDownTreeView/NDropDownTreeView.vue";
import { useTrendyolKategoriService } from "@/api/services/TrendyolKategoriService";
import { TrendyolPazaryeriItem } from "@/api/models/TrendyolPazaryeriItem";
import { useTrendyolMarkaService } from "@/api/services/TrendyolMarkaService";
import NDropDownListBox from "@/components/Dropdown/NDropDownListBox.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import TrendyolKategoriOzellikEditor
  from "@/modules/Pazaryerleri/Trendyol/components/TrendyolKategoriOzellikEditor.vue";
import NRefresher from "@/components/Refresher/NRefresher.vue";
import NDropDownListCustomStore from "@/components/Dropdown/NDropDownListCustomStore.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import CustomStore from "devextreme/data/custom_store";
import {PazaryeriFiyatTipiList} from "@/api/models/PazaryeriFiyatTipi";

export default defineComponent({
  components: {
      NFormField,
      NDropDownListCustomStore,
      NRefresher, TrendyolKategoriOzellikEditor, NFormGrid, NDropDownTreeView },
  props: ['trendyol'],
  setup(props) {

    const localState = reactive({
      trendyolRef:computed(() => props.trendyol as TrendyolPazaryeriItem),
      kategoriler:ref([] as N11CategoryItem[]),
      markalar:ref([]),

        dataSourceMarkalar:  new CustomStore({
            key: 'id',
            load:(loadOptions)=> {
                return useTrendyolMarkaService().postByNameAsync({data:JSON.stringify(loadOptions) as any})
            }
        }),
        pazaryeriFiyatTipiList:ref(PazaryeriFiyatTipiList),
    });



    async function loadKategoriler(cache:number){
      if (!localState.trendyolRef.kategori){
        localState.trendyolRef.kategori={id:0}
      }

      localState.kategoriler = await useTrendyolKategoriService().getGetir(cache);
    }

    loadKategoriler(300);

    return {
      ...toRefs(localState),
      loadKategoriler
    };
  }
});

