
export enum CekSenetTip{
MusteriCek=0,
MusteriSenet=1
}

export const CekSenetTipList = [
{key:'MusteriCek', value:0, description:'Müşteri Çeki'},
{key:'MusteriSenet', value:1, description:'Müşteri Senedi'}
]
