import { Component, createApp } from "vue";
import * as GlobalComponents from "@/components";

export default {
  install: (app: ReturnType<typeof createApp>) => {
    const GLOBAL_COMPONENTS: Component[] = Object.values(GlobalComponents);

    GLOBAL_COMPONENTS.forEach(COMPONENT => {
      app.component(COMPONENT.name as string, COMPONENT);
    });
  }
};
