import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NGrid = _resolveComponent("NGrid")!

  return (_openBlock(), _createBlock(_component_NGrid, { cols: "1fr 45px" }, {
    default: _withCtx(() => [
      _createVNode(_component_DxSelectBox, {
        "display-expr": "giderYeri",
        "value-expr": "giderYeri",
        items: _ctx.giderYeriList,
        searchEnabled: true,
        value: _ctx.select,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.select) = $event)),
        "show-clear-button": true,
        placeholder: "Gider Yeri Seçiniz"
      }, null, 8, ["items", "value"]),
      _createVNode(_component_NButton, {
        size: "small",
        click: _ctx.refresh,
        icon: "refresh-ccw"
      }, null, 8, ["click"])
    ]),
    _: 1
  }))
}