
import { computed, defineComponent, onMounted, reactive, ref, toRefs, watch } from "vue";
import SiparisViewExtend from "@/modules/SiparisTeklif/models/SiparisViewExtend";
import NGrid from "@/components/Grid/NGrid.vue";
import CariSelectBox from "@/modules/Cari/components/CariSelectBox.vue";
import { DxTextBox } from "devextreme-vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import MahalleAraSelectBox from "@/modules/Ortak/components/MahalleAraSelectBox.vue";
import { useGenelCache } from "@/modules/Ortak/services/useGenelCache";
import { UlkeEntity } from "@/api/models/UlkeEntity";
import NFormFieldLabelTop from "@/components/FormField/NFormFieldLabelTop.vue";
import NTag from "@/components/Tag/NTag.vue";
import NSwitch from "@/components/Switch/NSwitch.vue";
import { useCariService } from "@/api/services/CariService";
import { CariEntity } from "@/api/models/CariEntity";
import { AdresTip } from "@/api/models/AdresTip";
import CariAdresSelecBox from "@/modules/Cari/components/CariAdresSelecBox.vue";

export default defineComponent({
  props: {
    modelValue: {
      type: Object
    },
    farkliTeslimatAdresi: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CariAdresSelecBox,
    NFormGrid,
    NGrid,
    NSwitch,
    NFormField,
    NTag,
    NFormFieldLabelTop,
    MahalleAraSelectBox,
    CariSelectBox,
    DxTextBox
  },
  setup(props, { emit }) {
    const localState = reactive({
      model: computed(
        {
          get: () => props.modelValue as SiparisViewExtend,
          set: (val) => emit("update:modelValue", val)
        }
      ),
      adresler: ref([] as UlkeEntity[]),
      fakliTeslimatAdresiRef: computed(
        {
          get: () => props.farkliTeslimatAdresi as boolean,
          set: (val) => emit("update:farkliTeslimatAdresi", val)
        }
      )
    });

    onMounted(async () => {
      localState.adresler = await useGenelCache().getUlkeList() as UlkeEntity[];
      if (localState.model && localState.model.id && localState.model.id.length > 0) {

        //


      }


      if (localState.model && localState.model.cariId && localState.model.cariId.length > 0 && !(localState.model.id && localState.model.id.length > 0)) {
        const cari: CariEntity = await useCariService().getGetir(localState.model.cariId);
        if (cari) {
          localState.model.cariAd = cari.ad;
          localState.model.cariSoyad = cari.soyad;
          localState.model.cariVknTc = cari.tcNo?.isNullOrEmpty() ? cari.vergiNo : cari.tcNo;
          localState.model.cariUnvan = cari.getUnvan;

          const cariFaturaAdres = cari.adresler?.find(a => a.tipi == AdresTip.Fatura);

          if (cariFaturaAdres && cariFaturaAdres.adres && cariFaturaAdres.adres.length > 2) {
            localState.model.faturaAdres = {
              adres: cariFaturaAdres.adres,
              cep: cariFaturaAdres.cep,
              belde: cariFaturaAdres.belde,
              ilce: cariFaturaAdres.ilce,
              mahalle: cariFaturaAdres.mahalle,
              mail: cariFaturaAdres.mail,
              sehir: cariFaturaAdres.sehir,
              pKodu: cariFaturaAdres.pKodu
            };
          }

          const t = cari.adresler?.find(a => a.tipi == AdresTip.Teslimat);
          if (t && t.adres && t.adres.length > 2) {
            if (!(localState.model.teslimatlar && localState.model.teslimatlar.length === 0))
              localState.model.teslimatlar = [];

            localState.model.teslimatlar.push({
              adres: t.adres,
              cep: t.cep,
              belde: t.belde,
              ilce: t.ilce,
              mahalle: t.mahalle,
              mail: t.mail,
              pKodu: t.pKodu,
              sehir: t.sehir,
              teslimAlan: localState.model.teslimatlar[0]?.teslimAlan
            });


          }

        }
      }

      // console.log("model" , localState.model);


    });

    watch(() => localState.fakliTeslimatAdresiRef, (value, oldValue) => {
      if (value == true) {
        if (localState.model.teslimatlar && localState.model.teslimatlar.length > 0) {
          //
        } else
          localState.model.teslimatlar?.push({});


      }

    });


    function isAllNumber(numbers: any) {
      if (numbers?.length > 10)
        return false;

      if (numbers === undefined || numbers === null || numbers?.length <= 1) {
        return true;
      }

      const sonuc = isNaN(numbers); // NaN = non number
      if (sonuc) // NaN ise Sadece değildir
        return false;
      else
        return true;
    }

    function selectedChangedTeslimatAdres(data: any) {
      if (data && localState.model.teslimatlar && localState.model.teslimatlar[0]) {
        localState.model.teslimatlar[0]!.belde = data.belde;
        localState.model.teslimatlar[0]!.sehir = data.sehir;
        localState.model.teslimatlar[0]!.ilce = data.ilce;
        localState.model.teslimatlar[0]!.pKodu = data.pKodu;
        localState.model.teslimatlar[0]!.mahalle = data.mahalle;
        localState.model.teslimatlar[0]!.ulke = data.ulke;
        localState.model.teslimatlar[0]!.adres = data.adres;
        localState.model.teslimatlar[0]!.cep = data.cep;
        localState.model.teslimatlar[0]!.mail = data.mail;

      }
    }

    function selectedChangedFaturaAdres(data: any) {
      if (data) {
        localState.model.faturaAdres!.belde = data.belde;
        localState.model.faturaAdres!.sehir = data.sehir;
        localState.model.faturaAdres!.ilce = data.ilce;
        localState.model.faturaAdres!.pKodu = data.pKodu;
        localState.model.faturaAdres!.mahalle = data.mahalle;
        localState.model.faturaAdres!.ulke = data.ulke;
        localState.model.faturaAdres!.adres = data.adres;
        localState.model.faturaAdres!.cep = data.cep;
        localState.model.faturaAdres!.mail = data.mail;

      }
    }

    return {
      ...toRefs(localState),
      isAllNumber,
      selectedChangedFaturaAdres,
      selectedChangedTeslimatAdres
    };

  }
});
