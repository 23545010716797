
export enum EIhracatTeslimSartiTip{
CFR=0,
CIF=1,
CIP=2,
CPT=3,
DAF=4,
DAP=5,
DAT=6,
DDP=7,
DDU=8,
DEQ=9,
DES=10,
EXW=11,
FAS=12,
FCA=13,
FOB=14
}

export const EIhracatTeslimSartiTipList = [
{key:'CFR', value:0, description:'CFR -Masraflar ve Navlun'},
{key:'CIF', value:1, description:'CIF - Masraflar, Sigorta ve Navlun'},
{key:'CIP', value:2, description:'CIP -Taşıma ve Sigorta Ödenmiş Olarak'},
{key:'CPT', value:3, description:'CPT -Taşıma Ödenmiş Olarak'},
{key:'DAF', value:4, description:'DAF -Sınırda Teslim'},
{key:'DAP', value:5, description:'DAP - Belirlenen Yerde Teslim'},
{key:'DAT', value:6, description:'DAT - Terminalde Teslim'},
{key:'DDP', value:7, description:'DDP - Gümrük Vergileri Ödenmiş Olarak'},
{key:'DDU', value:8, description:'DDU -Gümrük Resmi Ödenmemiş Olarak Teslim'},
{key:'DEQ', value:9, description:'DEQ - Rıhtımda Teslim (Gümrük Vergi ve Harçları Ödenmiş Olarak)'},
{key:'DES', value:10, description:'DES - Gemide Teslim'},
{key:'EXW', value:11, description:'EXW - İşyerinde Teslim'},
{key:'FAS', value:12, description:'FAS - Gemi Doğrultusunda Masrafsız'},
{key:'FCA', value:13, description:'FCA - Taşıyıcıya Masrafsız'},
{key:'FOB', value:14, description:'FOB - Gemide Masrafsız'}
]
