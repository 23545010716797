import { createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    width: "350px",
    "closable-mask": false,
    modelValue: _ctx.showRef,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showRef) = $event))
  }, {
    header: _withCtx(() => [
      _createTextVNode(" Vergi Ekleme ")
    ]),
    body: _withCtx(() => [
      _createVNode(_component_NFormField, {
        "grid-cols": "75px 1fr",
        label: "Kodu"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NTag, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.vergi.kodu), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_NFormField, {
        "grid-cols": "75px 1fr",
        label: "Adı "
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NTag, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.vergi.adi), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_NFormField, {
        "grid-cols": "75px 1fr",
        label: "Açıklama "
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NTag, { style: {"text-align":"center"} }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.vergi.aciklama), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_NFormField, {
        "grid-cols": "75px 1fr",
        label: "Değer"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NInput, {
            type: "text",
            modelValue: _ctx.model.deger,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.deger) = $event)),
            modelModifiers: { number: true }
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_NFormField, {
        "grid-cols": "75px 1fr",
        label: "Oran"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NInput, {
            type: "text",
            modelValue: _ctx.model.oran,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.oran) = $event)),
            modelModifiers: { number: true }
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_NButton, {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showRef = false)),
        type: "danger",
        icon: "x"
      }, {
        default: _withCtx(() => [
          _createTextVNode("İptal")
        ]),
        _: 1
      }),
      _createVNode(_component_NButton, {
        onClick: _ctx.vergiDialogsAdd,
        type: "success",
        icon: "plus"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Ekle")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}