import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {UserFirmaRoleItem} from '../models/UserFirmaRoleItem'
import {UserRegisterViewRoles} from '../models/UserRegisterViewRoles'
import {UserLoginView} from '../models/UserLoginView'

export const useAccountService = defineStore({
id: 'AccountService',
 state: () => ({
isAuthenticated: false,
expiration: '',
token: null as string | null
}),
actions: {


	async postCheckPin(pin:string)
	{
		const { request, response } = useApi(`/api/Account/CheckPin/${pin}`,{ method:'POST' });
		await request();
		return response.value;
	},

	async getMe()
	{
		const { request, response } = useApi(`/api/Account/Me`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postRegister(userRegisterViewRoles:UserRegisterViewRoles)
	{
		const { request, response } = useApi(`/api/Account/Register`,{ method:'POST', data:userRegisterViewRoles });
		await request();
		return response.value;
	},

	async postB2bLogin(userLoginView:UserLoginView)
	{
		const { request, response } = useApi(`/api/Account/B2bLogin`,{ method:'POST', data:userLoginView });
		await request();
		return response.value;
	},

	async postLogin(userLoginView:UserLoginView)
	{
		const { request, response } = useApi(`/api/Account/Login`,{ method:'POST', data:userLoginView });
		await request();
		return response.value;
	},

	async postRequestAuthorization(userLoginView:UserLoginView)
	{
		const { request, response } = useApi(`/api/Account/RequestAuthorization`,{ method:'POST', data:userLoginView });
		await request();
		return response.value;
	},

	async putResponseAuthorization(ıd:string,codeExpireDate:string)
	{
		const { request, response } = useApi(`/api/Account/ResponseAuthorization`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async putUpdateRegister(userRegisterViewRoles:UserRegisterViewRoles)
	{
		const { request, response } = useApi(`/api/Account/UpdateRegister`,{ method:'PUT', data:userRegisterViewRoles });
		await request();
		return response.value;
	},

	async getExistUsers()
	{
		const { request, response } = useApi(`/api/Account/ExistUsers`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postLogout()
	{
		const { request, response } = useApi(`/api/Account/Logout`,{ method:'POST' });
		await request();
		return response.value;
	},

	async getGetUserList()
	{
		const { request, response } = useApi(`/api/Account/GetUserList`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetUser(id:string)
	{
		const { request, response } = useApi(`/api/Account/GetUser/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async deleteUserDelete(id:string)
	{
		const { request, response } = useApi(`/api/Account/UserDelete/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async putActivate(id:string,isActive:boolean)
	{
		const { request, response } = useApi(`/api/Account/Activate/${id}/${isActive}`,{ method:'PUT' });
		await request();
		return response.value;
	}
}
});
