import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  style: {"display":"flex","justify-content":"center","padding":"0","margin":"0"},
  title: "Maske Ayarı"
}
const _hoisted_2 = {
  style: {"display":"flex","justify-content":"center","padding":"0","margin":"0"},
  title: "Maske Ayarı"
}
const _hoisted_3 = {
  style: {"display":"flex","justify-content":"center","padding":"0","margin":"0"},
  title: "Maske Ayarı"
}
const _hoisted_4 = {
  style: {"display":"flex","justify-content":"center","padding":"0","margin":"0"},
  title: "Maske Ayarı"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_MahalleAraSelectBox = _resolveComponent("MahalleAraSelectBox")!
  const _component_NTextarea = _resolveComponent("NTextarea")!
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!

  return (_ctx.activeRef)
    ? (_openBlock(), _createBlock(_component_NFormGrid, {
        key: 0,
        cols: "1fr 1fr 1fr"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NGrid, {
            cols: "1.15fr 1fr 0.75fr",
            title: "",
            style: {"grid-column":"span 3"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NFormField, {
                "grid-cols": "70px 1fr",
                label: "Adres Adı"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    type: "text",
                    modelValue: _ctx.model.adresTanim,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.adresTanim) = $event)),
                    placeholder: "Ev Adresim, İş Adresim"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "auto 1fr",
                label: "Ülke",
                title: "Ülke"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    type: "text",
                    modelValue: _ctx.model.ulke,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.ulke) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "auto 1fr",
                label: "Tip"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxSelectBox, {
                    "display-expr": "description",
                    "value-expr": "value",
                    items: _ctx.adresTipListRef,
                    value: _ctx.model.tipi,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.tipi) = $event)),
                    searchEnabled: false,
                    "show-clear-button": false
                  }, null, 8, ["items", "value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Adres Ara",
            span: "3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MahalleAraSelectBox, {
                sehir: _ctx.model.sehir,
                "onUpdate:sehir": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.sehir) = $event)),
                ilce: _ctx.model.ilce,
                "onUpdate:ilce": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.ilce) = $event)),
                pk: _ctx.model.pKodu,
                "onUpdate:pk": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.pKodu) = $event)),
                mahalle: _ctx.model.mahalle,
                "onUpdate:mahalle": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.mahalle) = $event)),
                belde: _ctx.model.belde,
                "onUpdate:belde": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.belde) = $event))
              }, null, 8, ["sehir", "ilce", "pk", "mahalle", "belde"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Şehir",
            span: "1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxSelectBox, {
                items: _ctx.adresler.map(a=>a.sehir).filter((value, index, self) => self.indexOf(value) === index),
                "search-timeout": 1,
                searchEnabled: true,
                value: _ctx.model.sehir,
                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.sehir) = $event)),
                "show-clear-button": true,
                acceptCustomValue: true,
                searchMode: "contains",
                autocomplete: "none"
              }, null, 8, ["items", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "İlçe",
            span: "1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxSelectBox, {
                items: _ctx.adresler.filter(a=> a.sehir === _ctx.model.sehir)
                  .map(a=>a.ilce).filter((value, index, self) => self.indexOf(value) === index),
                "search-timeout": 1,
                searchEnabled: true,
                value: _ctx.model.ilce,
                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.ilce) = $event)),
                "show-clear-button": true,
                searchMode: "contains",
                acceptCustomValue: true
              }, null, 8, ["items", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Belde",
            span: "1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxSelectBox, {
                items: _ctx.adresler.filter(a=> a.sehir === _ctx.model.sehir && a.ilce == _ctx.model.ilce)
                  .map(a=>a.beldeAdi).filter((value, index, self) => self.indexOf(value) === index),
                "search-timeout": 1,
                searchEnabled: true,
                value: _ctx.model.belde,
                "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.belde) = $event)),
                "show-clear-button": true,
                searchMode: "contains",
                acceptCustomValue: true,
                placeholder: "Semt / Belde Bucak Giriniz"
              }, null, 8, ["items", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Mah.",
            span: "2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxSelectBox, {
                items: _ctx.adresler.find(a=> a.sehir == _ctx.model.sehir && a.ilce ==_ctx.model.ilce && a.beldeAdi == _ctx.model.belde)
                  ?.mahalleler,
                "search-timeout": 1,
                searchEnabled: true,
                value: _ctx.model.mahalle,
                "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.mahalle) = $event)),
                "show-clear-button": true,
                searchMode: "contains",
                acceptCustomValue: true,
                placeholder: "Mahalle Giriniz"
              }, null, 8, ["items", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "P.Kod",
            title: "Posta Kodu",
            span: "1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NInput, {
                type: "text",
                modelValue: _ctx.model.pKodu,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.pKodu) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Adres",
            title: "Adres",
            span: "3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NTextarea, {
                rows: "2",
                type: "text",
                modelValue: _ctx.model.adres,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.adres) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Tel",
            title: "Telefon Numarası",
            style: {"margin-top":"-2px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTextBox, {
                value: _ctx.model.tel,
                "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.tel) = $event)),
                mask: _ctx.isAllNumber(_ctx.model.tel) && _ctx.useMask ? '(000) 000 00 00' : '',
                buttons: [{name:'mask', options: _ctx.maskOptions}],
                inputAttr: {style:'padding-right:2px'}
              }, {
                maskButton: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("span", {
                      style: _normalizeStyle({color: (_ctx.useMask ? '#2f8fe8' : 'black')})
                    }, "#", 4)
                  ])
                ]),
                _: 1
              }, 8, ["value", "mask", "buttons"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Cep",
            title: "Cep Numarası",
            style: {"margin-top":"-2px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTextBox, {
                value: _ctx.model.cep,
                "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.cep) = $event)),
                mask: _ctx.isAllNumber(_ctx.model.cep) && _ctx.useMask ? '(000) 000 00 00' : '',
                buttons: [{name:'mask', options: _ctx.maskOptions}],
                inputAttr: {style:'padding-right:2px'}
              }, {
                maskButton: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", {
                      style: _normalizeStyle({color: (_ctx.useMask ? '#2f8fe8' : 'black')})
                    }, "#", 4)
                  ])
                ]),
                _: 1
              }, 8, ["value", "mask", "buttons"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Mail",
            title: "E-Posta Adresi",
            span: "1",
            style: {"margin-top":"-2px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NInput, {
                type: "text",
                modelValue: _ctx.model.mail,
                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model.mail) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Tel 2",
            title: "Telefon Numarası"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTextBox, {
                value: _ctx.model.tel2,
                "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model.tel2) = $event)),
                mask: _ctx.isAllNumber(_ctx.model.tel2) && _ctx.useMask ? '(000) 000 00 00' : '',
                buttons: [{name:'mask', options: _ctx.maskOptions}],
                inputAttr: {style:'padding-right:2px'}
              }, {
                maskButton: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("span", {
                      style: _normalizeStyle({color: (_ctx.useMask ? '#2f8fe8' : 'black')})
                    }, "#", 4)
                  ])
                ]),
                _: 1
              }, 8, ["value", "mask", "buttons"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Cep 2",
            title: "Cep Numarası"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTextBox, {
                value: _ctx.model.cep2,
                "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.model.cep2) = $event)),
                mask: _ctx.isAllNumber(_ctx.model.cep2) && _ctx.useMask ? '(000) 000 00 00' : '',
                buttons: [{name:'mask', options: _ctx.maskOptions}],
                inputAttr: {style:'padding-right:2px'}
              }, {
                maskButton: _withCtx(() => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("span", {
                      style: _normalizeStyle({color: (_ctx.useMask ? '#2f8fe8' : 'black')})
                    }, "#", 4)
                  ])
                ]),
                _: 1
              }, 8, ["value", "mask", "buttons"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Mail 2",
            title: "E-Posta Adresi",
            span: "1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NInput, {
                type: "text",
                modelValue: _ctx.model.mail2,
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.model.mail2) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Yetkili",
            title: "Yetkili",
            span: "2",
            style: {"margin-bottom":"-4px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NInput, {
                type: "text",
                modelValue: _ctx.model.yetkili,
                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.model.yetkili) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}