import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {FileLocation} from '../models/FileLocation'
import {DocFileItem} from '../models/DocFileItem'
import {FileFolderInfo} from '../models/FileFolderInfo'
import {FilePathInfo} from '../models/FilePathInfo'

export const useDocumentManagementService = defineStore({
id: 'DocumentManagementService',
actions: {


	async postDocumentManagmentDokumanEkle(options:any)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/DocumentManagment/DokumanEkle/${shared.firm.id}`,options);
		await request();
		return response.value;
	},

	async postDocumentManagmentTopluDokumanEkle(options:any)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/DocumentManagment/TopluDokumanEkle/${shared.firm.id}`,options);
		await request();
		return response.value;
	},

	async postDocumentManagmentDosyalariGetir(fileFolderInfo:FileFolderInfo)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/DocumentManagment/DosyalariGetir/${shared.firm.id}`,{ method:'POST', data:fileFolderInfo });
		await request();
		return response.value;
	},

	async deleteDocumentManagmentDosyaSil(filePathInfo:FilePathInfo)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/DocumentManagment/DosyaSil/${shared.firm.id}`,{ method:'DELETE', data:filePathInfo });
		await request();
		return response.value;
	},

	async deleteDocumentManagmentKlasorSil(fileFolderInfo:FileFolderInfo)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/DocumentManagment/KlasorSil/${shared.firm.id}`,{ method:'DELETE', data:fileFolderInfo });
		await request();
		return response.value;
	}
}
});
