import {defineStore} from "pinia";
import {ref} from "vue";
import {useCariService} from "@/api/services/CariService";
import { CariOzetView } from "@/api/models/CariOzetView";
import {assignWith} from "lodash";
import {useAccountService} from "@/api/services/AccountService";


export const usePinStore = defineStore({
    id: "usePinStore",
    state: () => ({
        interval:0,
        countdown:0,
        intervalId:0,
        isListening:true,
        isActive:false,
        title:'',
        tryCount:0,
        pinError:""
    }),
    actions: {
      load(interval:number, isActive:boolean){

          // get user vs...
          if (isActive) {
              this.isActive = isActive;
              this.title = document.title;
              this.interval = interval;
              this.countdown = this.interval;
              this.startInteval()
              this.addEvent();
              this.tryCount = 0;
              this.isListening = false;
          }else{
              this.removeEvent();

          }
      },
        unload(){
          this.removeEvent();

        },
        async enterPin(pin:string){
            this.tryCount++;

            // check pin
            if (this.tryCount > 3){ // limit doldu
                window.location.href = "";
            }else{

                // giriş yap
                const checkResult = await useAccountService().postCheckPin(pin)
                if (checkResult === true){
                    this.isListening = true;
                    this.addEvent();
                    this.tryCount = 0;
                }else{
                    this.pinError = `Geçersiz pin. ${3 - this.tryCount} deneme hakkınız kaldı.`
                }
            }



        },
        startInteval() {
          if (this.isActive){
            if (this.interval > 0)
                clearInterval(this.intervalId)
            this.countdown = this.interval;
            this.intervalId = setInterval(()=> {
                this.countdown--;
                if (this.countdown <=0){
                    clearInterval(this.intervalId);
                    this.isListening = false;
                    //document.title = this.title;
                }else{
                    //document.title = this.countdown + "sn. " + this.title;
                    //console.log(this.countdown)
                }

            },1000)
            //console.log("START INTERVAL")
          }
        },
        addEvent(){
            if (this.interval > 10 && this.isActive){
                window.addEventListener("mousemove", this.startInteval);
                window.addEventListener("keydown", this.startInteval);
                this.startInteval()
               // console.log("Attach removeEventListener")
            }
        },
        removeEvent(){

                window.removeEventListener("mousemove", this.startInteval);
                window.removeEventListener("keydown", this.startInteval);
                clearInterval(this.intervalId);
                //console.log("Detach removeEventListener")
        }
    }
});