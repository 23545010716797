
import {defineComponent, reactive, ref, toRefs} from "vue";
import NCard from "@/components/Card/NCard.vue";
import router from "@/router";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import {GenelUtils} from "@/modules/Ortak/utils/GenelUtils";
import {useCariSatisIzinleriService} from "@/api/services/CariSatisIzinleriService";

export default defineComponent({
  components: {NLoadingPanel, NCard},
  props: {},
  setup() {
    const localState = reactive({
      firma: ref(''),
      id: ref(''),
      message:ref('')
    });

    async function load() {
      localState.firma = router.resolve("").params?.firma as string;
      localState.id = router.resolve("").params?.key as string;


    }

    setTimeout(async () => {
      if (localState.firma && localState.firma.length >0  && localState.id && localState.id.length > 0 ){
        if (await GenelUtils.onayDiyalogu("İzin Vermek İstiyor musunuz ? " , "Cari Limit Aşımı İzni")){
          await  useCariSatisIzinleriService().getCarisatisizinleriSatisIzinVer(localState.firma , localState.id ,  true);
          localState.message = "İzin Verildi , Artık Bu sayfayı kapatabilirsiniz";
        }else{
          await  useCariSatisIzinleriService().getCarisatisizinleriSatisIzinVer(localState.firma , localState.id ,  false);
          localState.message = "İzin Verilmedi , Artık Bu sayfayı kapatabilirsiniz";
        }
      }
    },500)


    return {
      ...toRefs(localState),
      load,

    };
  }
});
