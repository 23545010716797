
import { defineComponent, reactive, ref, toRefs } from "vue";

export default defineComponent({
  name: "TreeViewItem",
  components: {},
  props: {
    item: {
      type: Object
    },
    subField: {
      type: String
    },
    parent: {
      type: Object
    }
  },
  setup(props) {
    const { subField } = toRefs(props);

    const localState = reactive({
      subF: ref(""),
      showChild: ref(true)
    });

    function getLength(item: any) {
      // alert(props.subField)
      if (props.subField) {
        const subs = item[props.subField] as any[];
        if (subs) {
          return subs.length;
        }
      }
      return -1;
    }

    function getSubs(item: any) {
      const subs = item[localState.subF] as any[];
      return subs;
    }

    return {
      ...toRefs(localState),
      getLength,
      getSubs
    };
  }
});
