
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import { ProjeEntity } from "@/api/models/ProjeEntity";
import { useProjeService } from "@/api/services/ProjeService";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";

export default defineComponent({
  components: { },
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      items: ref([] as ProjeEntity[]),
      selected: computed({
        get: () => props.modelValue,
        set: val => emit("update:modelValue", val)
      })
    });

    async function load() {
      localState.items = await useProjeService().getList();
    }

    load();

    return {
      ...toRefs(localState)
    };
  }
});
