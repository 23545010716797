import useApi from '@/api/useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {FileLocation} from '../models/FileLocation'

import {useFileSettingsService} from "@/api/services/FileSettingsService";

export const useFileSettingsServiceCache = defineStore({
id: 'useFileSettingsServiceCache',
	state:()=>({
		serverUrl:undefined
	}),
actions: {
	async getServerUrl() {
		if (this.serverUrl)
			return  this.serverUrl;
		return this.serverUrl = await useFileSettingsService().getServerUrl();
	}

}
});
