
import {computed, defineComponent, onActivated, reactive, ref, toRefs} from "vue";
import {YetkiliItem} from "@/api/models/YetkiliItem";
import {DevExtremeUtils} from "@/devextreme/DevExtremeUtils";

export default defineComponent({
  props:['modelValue'],
  setup(props) {
    const localState = reactive({
      yetkililerDataGrid: ref(),
      model:computed(()=> props.modelValue)
    })

    function yetkiliEkle() {
      const yetkili = {
        durum: true
      } as YetkiliItem;
      localState.model.yetkililer!.push(yetkili);
      localState.yetkililerDataGrid.instance.refresh();
    }
      onActivated(() => {
          DevExtremeUtils.updateScroll(localState.yetkililerDataGrid);
      });


    return {
      ...toRefs(localState),
      yetkiliEkle
    }
  }
})
