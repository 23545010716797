
import {computed, defineComponent, onMounted, reactive, ref, toRefs, watch} from "vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import {StokFlatView} from "@/api/models/StokFlatView";
import {StokBarkodView} from "@/modules/Stok/models/StokBarkodView";
import {useStokFlatStore} from "@/modules/Stok/store/StokFlatStore";
import NButton from "@/components/Button/NButton.vue";
import StokAramaInput from "@/modules/Fis/pages/StokAramaInput.vue";
import ReportDropDownItemList from "@/modules/Report/components/ReportDropDownItemList.vue";
import {ReportType} from "@/api/models/ReportType";
import NGrid from "@/components/Grid/NGrid.vue";
import NDropdownMenu from "@/components/Dropdown/NDropdownMenu.vue";
import NDropdown from "@/components/Dropdown/NDropdown.vue";
import {useFileSettingsServiceCache} from "@/api/servicesCache/FileSettingsServiceCache";
import {StokBirimItem} from "@/api/models/StokBirimItem";
import {BirimFiyatTipi} from "@/api/models/BirimFiyatTipi";
import {StokBirimUtils} from "@/modules/Stok/utils/StokBirimUtils";
import {StokView} from "@/api/models/StokView";
import {FiyatUtils} from "@/modules/Stok/store/FiyatUtils";
import {PazaryeriFiyatTipi} from "@/api/models/PazaryeriFiyatTipi";
import {Doviz} from "@/api/models/Doviz";
import {DxDataGrid} from "devextreme-vue/data-grid";
import NDxStateStoring from "@/devextreme/NDxStateStoring.vue";
import {DevExtremeUtils} from "@/devextreme/DevExtremeUtils";
import {useStokService} from "@/api/services/StokService";

export default defineComponent({
    components: {
        NDxStateStoring,
        DxDataGrid,
        NDropdown,
        NDropdownMenu,
        NGrid,
        ReportDropDownItemList,
        StokAramaInput,
        NButton,
        NModalDialog
    },
    props: {
        stokIds: {
            type: Array
        },
        basilacaklar: {
            type: Array
        },
        show: {
            type: Boolean,
            default: false
        },
        callBack: {
            type: Function
        },

    },
    setup(props, {emit}) {
        const localState = reactive({
            showRef: computed({
                get: () => props.show,
                set: (val) => emit("update:show", val)
            }),

            stoklarFlat: ref([] as StokFlatView[]),
            stokIdsRef: ref(props.stokIds as string[]),
            basilacaklarRef: ref(props.basilacaklar as StokBarkodView[]),
            reportData: ref([] as any[]),
            reportType: ReportType,
            stokBarkodDataGrid: ref(),
            serverUrl: ref("")
        });

        onMounted(async () => {
            await callBack();
            await load();
        })

        async function callBack() {
            if (props.callBack)
                await props.callBack()
        }

        async function load() {

            localState.serverUrl = await useFileSettingsServiceCache().getServerUrl();
            if (localState.basilacaklarRef && localState.basilacaklarRef?.length > 0) {
                const stokIds: string[] = []
                localState.basilacaklarRef?.forEach(a => {
                    if (a.itemId && a.itemId?.length > 0)
                        stokIds?.push(a.itemId as string)
                    else
                        stokIds?.push(a.stokId as string)
                })
                localState.stoklarFlat = await useStokService().postFlatViewsFromStokIds(stokIds);
            }

            if (localState.stokIdsRef && localState.stokIdsRef?.length > 0)
                localState.stoklarFlat = await useStokService().postFlatViewsFromStokIds(localState.stokIdsRef);

            localState.stoklarFlat?.forEach((stokFlatItem: StokFlatView) => {
                miktarKontrol(stokFlatItem);
            })

        }

        function mergeAnaBirimBirimler(stok: StokFlatView) {
            const birimFiyatlariAll = [] as StokBirimItem[];
            birimFiyatlariAll.push({
                birim: stok.birim,
                fiyat: stok.satisFiyat
            })
            birimFiyatlariAll.push(...stok.birimFiyatlari as StokBirimItem[]);
            stok.birimFiyatlari = birimFiyatlariAll;
        }

        function miktarKontrol(stk: StokFlatView) {
            stk.kalanMiktar = 0;
            stokFlatPush(stk, false);
        }


        function cokluUrunEkle(stoklar: StokFlatView[]) {
            if (stoklar && stoklar.length > 0) {
                stoklar.forEach((stok) => {
                    stokEkle(stok);
                });
            }
        }

        async function miktarlariCek() {

          if (localState.stoklarFlat && localState.stoklarFlat.length > 0) {
            localState.stoklarFlat.forEach((a) => {
              if (a.itemId && a.itemId.length > 0)
                a.kalanMiktar = useStokFlatStore().getStokByItemId(a.stokId as string, a.itemId as string)?.kalanMiktar // stoklar.find(x => x.stokId == a.stokId && x.itemId == a.itemId)?.kalanMiktar;
              else
                a.kalanMiktar = useStokFlatStore().getStok(a.stokId as string)?.kalanMiktar;
            });
            localState.stokBarkodDataGrid?.instance?.refresh()
          }
        }

        watch(() => localState.stoklarFlat, () => {
            const yazdirilacak: StokFlatView[] = [];
            localState.stoklarFlat?.forEach((a:StokFlatView) => {
                if (a.kalanMiktar! > 0) {
                    for (let i = 1; i <= a.kalanMiktar!; i++) {
                        yazdirilacak.push(a);
                    }
                }
            });

            localState.reportData = yazdirilacak;
        }, {deep: true});

        function stokFlatPush(stok: StokFlatView, push: boolean) {
            stok.resim = localState.serverUrl + stok.resim;
            stok.uyumluluklar = [];

            stok.kod = (stok.itemKod && stok.itemKod.length > 0 ? stok.itemKod : stok.kod)
            stok.barkodStok = (stok.barkodItem && stok.barkodItem.length > 0 ? stok.barkodItem : stok.barkodStok)

            const birimFiyatlari = [] as StokBirimItem[];
            birimFiyatlari.push({
                birim: stok.birim,
                fiyat: stok.satisFiyat,
                fiyatTipi: BirimFiyatTipi.SabitFiyat,
                carpan: 1,
                barkod: stok.barkodStok
            })
            birimFiyatlari.push(...stok.birimFiyatlari as StokBirimItem[])
            stok.birimFiyatlari = birimFiyatlari;

            if (push)
                localState.stoklarFlat.push(stok as StokFlatView);

            if (localState.basilacaklarRef && localState.basilacaklarRef) {
                if (stok.itemId && stok.itemId?.length > 0)
                  stok.kalanMiktar = localState.basilacaklarRef?.find(a => a.itemId == stok.itemId)?.miktar as number ?? 0
                else
                  stok.kalanMiktar = localState.basilacaklarRef?.find(a => a.stokId == stok.stokId)?.miktar as number ?? 0
            }
            refreshDataGrid();
        }

        function tekUrunEkle(stok: StokFlatView) {
            stokEkle(stok);
        }

        function stokEkle(stok: StokFlatView) {
            if (stok && stok.id && stok.id.length > 0) {
                const cloneStok = Object.assign({}, stok);
                if (cloneStok.kalanMiktar! <= 0)
                    cloneStok.kalanMiktar = 1;
                stokFlatPush(cloneStok, true);
            }
        }

        function refreshDataGrid() {
            localState.stokBarkodDataGrid?.instance?.refresh();
        }

        function birimChanged(stok: any, e: any) {
            const birim = e.itemData as StokBirimItem;
            if (!stok.satisFiyatOrjinal) {
                stok.satisFiyatOrjinal = stok.satisFiyat;
            }
            stok.satisFiyat = getSatisFiyat(birim, stok);
            stok.barkodStok = getStokBarkod(birim, stok);
        }

        function getStokBarkod(row: StokBirimItem, stok: StokFlatView) {
            const birim = stok.birimFiyatlari?.find(a => a.id == row.id);
            if (birim) {
                return birim.barkod;
            }

            return "";

        }

        function getSatisFiyat(row: StokBirimItem, stok: any) {
            return StokBirimUtils.getFiyat(row.fiyat as number,
                row.fiyatTipi as BirimFiyatTipi,
                stok.satisFiyatOrjinal as number,
                stok.satisKdv as number,
                stok.satisKdvDahil as boolean,
                stok.alisFiyat as number,
                stok.alisKdv as number,
                stok.alisKdvDahil as boolean,
                stok.satisFiyatOrjinal as number)
        }

        function birAdetIsaretle() {
            if (localState.stoklarFlat && localState.stoklarFlat.length > 0) {

                localState.stoklarFlat.forEach((a) => a.kalanMiktar = 1);
                localState.stokBarkodDataGrid?.instance?.refresh()
            }
        }

        async function loadState() {
            return await DevExtremeUtils.loadState(localState.stokBarkodDataGrid?.instance);
        }


        return {
            ...toRefs(localState),
            tekUrunEkle,
            cokluUrunEkle,
            birimChanged,
            birAdetIsaretle,
            miktarlariCek,
            loadState

        };

    }
});
