import { BirimFiyatTipi } from "@/api/models/BirimFiyatTipi";
import { StokBirimItem } from "@/api/models/StokBirimItem";
import { StokFlatView } from "@/api/models/StokFlatView";
import { StokUtils } from "@/modules/Stok/utils/StokUtils";

export const StokBirimUtils = {

  getFiyatStokBirimItem: (stokBirim: StokBirimItem, stok: StokFlatView) => {
    let fiyat = 0;

    switch (stokBirim.fiyatTipi) {
      case BirimFiyatTipi.SabitFiyat:
        fiyat = stokBirim.fiyat!;
        break;

      case BirimFiyatTipi.SatisTutarEkle: {
        const birimFiyat = StokBirimUtils.tutarEkle(stokBirim.fiyat as number, stok.satisFiyat as number, stok.satisKdv as number, stok.satisKdvDahil as boolean);
        fiyat = StokBirimUtils.getKdvsizFiyat(birimFiyat, stok.satisKdv!, false);
      }
        break;

      case BirimFiyatTipi.SatisOranEkle: {
        const birimFiyat = StokBirimUtils.oranEkle(stokBirim.fiyat as number, stok.satisFiyat as number, stok.satisKdv as number, stok.satisKdvDahil as boolean);
        fiyat = StokBirimUtils.getKdvsizFiyat(birimFiyat, stok.satisKdv!, false );
      }
        break;
      case BirimFiyatTipi.AlisTutarEkle: {
        const birimFiyat = StokBirimUtils.tutarEkle(stokBirim.fiyat as number, stok.alisFiyat as number, stok.alisKdv as number, stok.alisKdvDahil as boolean);
        fiyat = StokBirimUtils.getKdvsizFiyat(birimFiyat, stok.satisKdv!, false);
      }
        break;
      case BirimFiyatTipi.AlisOranEkle: {
        const birimFiyat = StokBirimUtils.oranEkle(stokBirim.fiyat as number, stok.alisFiyat as number, stok.alisKdv as number, stok.alisKdvDahil as boolean);
        fiyat = StokBirimUtils.getKdvsizFiyat(birimFiyat, stok.satisKdv!, false);
      }
        break;
      case BirimFiyatTipi.ETicaretTutarEkle:
        fiyat = 0;
        break;
      case BirimFiyatTipi.ETicaretOranEkle:
        fiyat = 0;
        break;
    }
    return fiyat;
  },
  getFiyat: (deger: number, fiyatTipi: BirimFiyatTipi, satisFiyati: number, satisKdv: number, satisKdvDahil: boolean, alisFiyati: number, alisKdv: number, alisKdvDahil: boolean, eTicaretFiyati: number) => {
    switch (fiyatTipi) {
      case BirimFiyatTipi.SabitFiyat:
        return deger;
      case BirimFiyatTipi.SatisTutarEkle:
        return StokBirimUtils.tutarEkle(deger, satisFiyati, satisKdv, satisKdvDahil);
      case BirimFiyatTipi.SatisOranEkle:
        return StokBirimUtils.oranEkle(deger, satisFiyati, satisKdv, satisKdvDahil);
      case BirimFiyatTipi.AlisTutarEkle:
        return StokBirimUtils.tutarEkle(deger, alisFiyati, alisKdv, alisKdvDahil);
      case BirimFiyatTipi.AlisOranEkle:
        return StokBirimUtils.oranEkle(deger, alisFiyati, alisKdv, alisKdvDahil);
      case BirimFiyatTipi.ETicaretTutarEkle:
        return StokBirimUtils.tutarEkle(deger, eTicaretFiyati, 0, true);
      case BirimFiyatTipi.ETicaretOranEkle:
        return StokBirimUtils.oranEkle(deger, eTicaretFiyati, 0, true);
    }
    return 0;
  },
  getKdvsizFiyat: (fiyat: number, kdv: number, kdvDahil: boolean) => {
    if (kdvDahil)
      return fiyat / (1 + (kdv / 100));

    return fiyat;
  },
  getKdvliFiyat: (fiyat: number, kdv: number, kdvDahil: boolean) => {
    if (kdvDahil)
      return fiyat;
    return fiyat * ((100 + kdv) / 100);
  },

  tutarEkle: (tutar: number, fiyat: number, kdv: number, kdvDahil: boolean) => {
    return StokBirimUtils.getKdvliFiyat(fiyat, kdv, kdvDahil) + tutar;
  },

  oranEkle: (oran: number, fiyat: number, kdv: number, kdvDahil: boolean) => {
    const satisFiyat = StokBirimUtils.getKdvliFiyat(fiyat, kdv, kdvDahil);
    return satisFiyat + StokBirimUtils.getOranTutar(satisFiyat, oran);
  },

  getOranTutar(fiyat: number, oran: number) {
    return (fiyat / 100) * oran;
  }
};
