import { Doviz, DovizList } from "@/api/models/Doviz";

export const MoneyUtils = {
  stringToMoneyDoviz(n: string, doviz: Doviz) {
    const dovizIsmi = DovizList.find(a => a.value == doviz);
    if (dovizIsmi) {
      return (
        parseFloat(n)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, "$1.")
          .replace(/\.(\d+)$/, ",$1") +
        " " +
        dovizIsmi.description
      );
    }
  },
  stringToMoney(n: string) {
    return parseFloat(n)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1.")
      .replace(/\.(\d+)$/, ",$1");
  }
};
