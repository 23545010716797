import {NModuleRoute} from "@/models/route.model";

export const IslemlerModuleRoutes: NModuleRoute[] = [
    {
        id: "IslemModule",
        order: 0,
        displayName: "İşlemler",
        icon: {
            type: "home"
        },
        children: [
            {
                id: "Islemler_Cari",
                order: 0,
                displayName: "Cari İşlemleri",
                requiredRoles: {
                    readRoles: ['Cari.Islemler.Liste', 'Cari.Hareket.Liste'],
                    insertRoles: ['Cari.Ekle', 'Cari.Hareket.Ekle'],
                    updateRoles: ['Cari.Guncelle', "Cari.Hareket.Guncelle"],
                    deleteRoles: ['Cari.Sil', "Cari.Hareket.Sil"]
                },
                icon: {
                    type: "home"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "islemler"*/ "../../Cari/pages/CariIslemlerPage.vue"
                        )
            },
            {
                id: "Islemler_Kasa",
                order: 0,
                displayName: "Kasa İşlemleri",
                requiredRoles: {
                    readRoles: ['Kasa.Islemler.Liste', 'Kasa.Hareket.Liste' ,'KasaHareket.Islemler.Liste'],
                    insertRoles: ['Kasa.Ekle' , 'Kasa.Hareket.Ekle'],
                    updateRoles: ['Kasa.Guncelle' , 'Kasa.Hareket.Guncelle'],
                    deleteRoles: ['Kasa.Sil' , 'Kasa.Hareket.Sil']
                },
                icon: {
                    type: "list"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Islemler_kasa" */ "../../Kasa/pages/KasaIslemeriPage.vue"
                        )
            },
            {
                id: "Islemler_Banka",
                order: 0,
                displayName: "Banka İşlemleri",
                requiredRoles: {
                    readRoles: ['Banka.Liste', 'Banka.Hareket.Liste'],
                    insertRoles: ['Banka.Ekle' , 'Banka.Hareket.Ekle'],
                    updateRoles: ['Banka.Guncelle' , 'Banka.Hareket.Guncelle'],
                    deleteRoles: ['Banka.Sil' , 'Banka.Hareket.Sil']
                },
                icon: {
                    type: "list"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Islemler_banka" */ "../../Banka/pages/BankaIslemleriPage.vue"
                        )
            },
            {
                id: "Islemler_KrediKarti",
                order: 0,
                displayName: "Kredi Kartı İşlemleri",
                requiredRoles: {
                    readRoles: ['KrediKarti.Liste' , 'KrediKarti.Hareket.Liste'],
                    insertRoles: ['KrediKarti.Ekle' , 'KrediKarti.Hareket.Ekle'],
                    updateRoles: ['KrediKarti.Guncelle' , 'KrediKarti.Hareket.Guncelle'],
                    deleteRoles: ['KrediKarti.Sil' , 'KrediKarti.Hareket.Sil']
                },
                icon: {
                    type: "list"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Islemler_krediKarti" */ "../../KrediKarti/pages/KrediKartiIslemleriPage.vue"
                        )
            },
            {
                id: "Islemler_Pos",
                order: 0,
                displayName: "POS İşlemleri",
                requiredRoles: {
                    readRoles: ['Pos.Liste', 'Pos.Hareket.Liste'],
                    insertRoles: ['Pos.Ekle' , 'Pos.Hareket.Ekle'],
                    updateRoles: ['Pos.Guncelle' , 'Pos.Hareket.Guncelle'],
                    deleteRoles: ['Pos.Sil' , 'Pos.Hareket.Sil']
                },
                icon: {
                    type: "list"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Islemler_pos" */ "../../Pos/pages/PosIslemleriPage.vue"
                        )
            },
            {
                id: "Islemler_CekSenet",
                order: 0,
                displayName: "Çek/Senet İşlemleri",
                requiredRoles: {
                    readRoles: ['CekSenet.Liste', 'CekSenet.Liste' , 'CekSenet.Rapor.Hareket.Liste', 'MusteriCekSenet.Hareket.Liste'],
                    insertRoles: ['CekSenet.Ekle', 'MusteriCekSenet.Hareket.Ekle'],
                    updateRoles: ['CekSenet.Guncelle', 'MusteriCekSenet.Hareket.Guncelle'],
                    deleteRoles: ['CekSenet.Sil']
                },
                icon: {
                    type: "list"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Islemler_cekSenet" */ "../../CekSenet/pages/CekSenetIslemleriPage.vue"
                        )
            },
            {
                id: "Islemler_HizmetMasraf",
                order: 0,
                displayName: "Hizmet/Masraf İşlemleri",
                requiredRoles: {
                    readRoles: ['HizmetMasraf.Hareket.Liste','HizmetMasraf.Liste','HizmetMasraf.Islemler.Liste', 'Cari.Islemler.Liste'],
                    insertRoles: ['HizmetMasraf.Ekle'],
                    updateRoles: ['HizmetMasraf.Guncelle'],
                    deleteRoles: ['HizmetMasraf.Sil']
                },
                icon: {
                    type: "list"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Islemler_HizmetMasraf" */ "../../HizmetMasraf/pages/HizmetMasrafIslemleriPage.vue"
                        )
            },
            {
                id: "Islemler.EvrakYonetimi",
                order: 0,
                displayName: "Evrak Yonetimi",
                requiredRoles: {
                    readRoles: ['EvrakYonetimi.Liste'],
                    insertRoles: ['EvrakYonetimi.Ekle'],
                    updateRoles: ['EvrakYonetimi.Guncelle'],
                    deleteRoles: ['EvrakYonetimi.Sil']
                },
                icon: {
                    type: "file-plus"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Islem_EvrakYonetimi" */ "../../EvrakYonetim/pages/EvrakYonetimiPage.vue"
                        )
            },
            {
                id: "ObifinBankaServis",
                order: 0,
                displayName: "Online Banka İşlemleri",
                requiredRoles:{
                    readRoles:["Obifin.Liste"],
                    insertRoles:["Obifin.Ekle"],
                    updateRoles:["Obifin.Guncelle"],
                    deleteRoles:["Obifin.Sil"]
                },
                icon: {
                    type: "credit-card"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Obifin"*/ "../../Servisler/Obifin/pages/ObifinListPage.vue"
                        )
            },
        ]
    }
];
