import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {IdName} from '../models/IdName'
import {Doviz} from '../models/Doviz'
import {Ozellik} from '../models/Ozellik'
import {StokGrupItem} from '../models/StokGrupItem'
import {N11PazaryeriItem} from '../models/N11PazaryeriItem'
import {TrendyolPazaryeriItem} from '../models/TrendyolPazaryeriItem'
import {HepsiBuradaPazaryeriItem} from '../models/HepsiBuradaPazaryeriItem'
import {HepsiBuradaListingPazaryeriItem} from '../models/HepsiBuradaListingPazaryeriItem'
import {EpttAvmPazaryeriItem} from '../models/EpttAvmPazaryeriItem'
import {PrestaPazaryeriItem} from '../models/PrestaPazaryeriItem'
import {CicekSepetiPazaryeriItem} from '../models/CicekSepetiPazaryeriItem'
import {StokGrupView} from '../models/StokGrupView'
import {ActionStatusType} from '../models/ActionStatusType'
import {PazaryeriNameType} from '../models/PazaryeriNameType'

export const useStokGrupService = defineStore({
id: 'StokGrupService',
actions: {


	async getGrupluStokDefault()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/GrupluStok/Default/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGrupluStokDefaultId(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/GrupluStok/DefaultId/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGrupluStokListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/GrupluStok/Liste/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postGrupluStokEkle(stokGrupView:StokGrupView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/GrupluStok/Ekle/${shared.firm.id}`,{ method:'POST', data:stokGrupView });
		await request();
		return response.value;
	},

	async putGrupluStokGuncelle(stokGrupView:StokGrupView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/GrupluStok/Guncelle/${shared.firm.id}`,{ method:'PUT', data:stokGrupView });
		await request();
		return response.value;
	},

	async deleteGrupluStokSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/GrupluStok/Sil/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getGrupluStokGet(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/GrupluStok/Get/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async putGrupluStokPazaryeriDurumGuncelle(stokId:string,status:number,pazaryeri:number,index:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/GrupluStok/PazaryeriDurumGuncelle/${shared.firm.id}/${stokId}/${status}/${pazaryeri}/${index}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async putGrupluStokFiyatEsitle(stokId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/GrupluStok/FiyatEsitle/${shared.firm.id}/${stokId}`,{ method:'PUT' });
		await request();
		return response.value;
	}
}
});
