

import {computed, defineComponent, onMounted, reactive, ref, toRefs, watch} from "vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NMultipleImageUploader from "@/components/Uploader/NMultipleImageUploader.vue";
import { useFileSettingsService } from "@/api/services/FileSettingsService";
import BirimlerSelectBox from "@/modules/Ortak/components/BirimlerSelectBox.vue";
import NDropDownTreeView from "@/components/DropDownTreeView/NDropDownTreeView.vue";
import { StokKategoriTreeListView } from "@/api/models/StokKategoriTreeListView";
import { StokUtils } from "@/modules/Stok/utils/StokUtils";
import { StokView } from "@/api/models/StokView";
import DovizFiyatItem from "@/modules/Ortak/components/DovizFiyatItem.vue";
import NInput from "@/components/Input/NInput.vue";
import { useStokKategoriService } from "@/api/services/StokKategoriService";
import NRefresher from "@/components/Refresher/NRefresher.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NSwitch from "@/components/Switch/NSwitch.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NBadge from "@/components/Badge/NBadge.vue";
import NTag from "@/components/Tag/NTag.vue";
import {useStokService} from "@/api/services/StokService";
import StokKategoriDialog from "@/modules/StokKategori/dialogs/StokKategoriDialog.vue";
import StokDevirFormField from "@/modules/Stok/components/StokDevirFormField.vue";
import {PluKodList} from "@/api/models/PluKod";
import {useStokStore} from "@/modules/Stok/store/StokStore";
import NIcon from "@/components/Notification/NNotification.vue";
import NIconIonic from "@/components/NIconIonic.vue";
import NButton from "@/components/Button/NButton.vue";
import OzellikTipForm from "@/modules/StokOzellik/components/OzellikTipForm.vue";
import {StokTip} from "@/api/models/StokTip";
import {useStokSettingsStore} from "@/modules/Stok/store/StokSettingsStore";
import { formatNumber } from "devextreme/localization";
import {IdName} from "@/api/models/IdName";
import DepoSelectBox from "@/modules/Ortak/components/DepoSelectBox.vue";
import {initial} from "lodash";


export default defineComponent({
    methods: {initial},
  components: {
      DepoSelectBox,
      OzellikTipForm,
    NButton,
    StokDevirFormField,
    StokKategoriDialog,
    NTag,
    NGrid,

    NSwitch,
    NFormGrid, NRefresher, NInput, DovizFiyatItem, NDropDownTreeView, BirimlerSelectBox, NMultipleImageUploader, NFormField },
  props: {
    modelValue: {
      type: Object
    },
    serverUrl:{
      type:String
    },
    showDevir:{
      type:Boolean,
      default: false
    },
    showBorder:{
      type:Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      model: computed(() => props.modelValue as StokView),
      selectedItem:ref({id:'',name:''}),
      kategoriler:ref([]),
      showKategoriDialog:ref(false),
      pluKodList:PluKodList,
      store:useStokStore(),
      stokNo: ref(''),
      disableStokKodu: ref(true),
      tip:StokTip,
      storeSettings:useStokSettingsStore(),
    });

    watch(()=> localState.showKategoriDialog, (val)=>{
      if (!val){
        loadKategoriler()
      }
    })

    watch(() => localState.model.kategori, (deger) => {
      if (deger && deger.id && deger.id?.length > 0) {
        if (!(localState.model.id && localState.model.id?.length > 0)) {
          kategoridenKodOlustur();
        }
      }
    })

    // if(localState.model.kategori)
    //   localState.model.kategori = {}

    function  getFiyat(fiyat: number , kdv : number , kdvDahil : boolean){
        let format = localState.storeSettings?.model.fiyatlar?.format as string
        if (format == undefined)
            format = '#,##0.00'

        if (kdvDahil)
          return   `Kdv Hariç : ${ formatNumber((fiyat  /  ( 1 + (kdv / 100 ))), format)  }`;
        else
          return   `Kdv Dahil : ${formatNumber((fiyat  *  ( 1 + (kdv / 100 ))), format)}`;

    }

    // async function loadStokNames(){
    //   localState.stokNames = await useStokService().getStokNames();
    //
    // }
    //
    // loadStokNames()

    onMounted(async () => {

      if (!localState.model.id)
        localState.disableStokKodu = false;



    })


    async function loadKategoriler() {
      localState.kategoriler = await useStokKategoriService().getTreeList();
      localState.stokNo = localState.model.kod?.replace(/\D/g,'') as string


    }

    function selectedChanged(kategori:StokKategoriTreeListView){

      const stok = localState.model as StokView;
      StokUtils.fillStokFromKategori(stok,kategori)

    }

    function getKdvDahil(fiyat:number,kdv:number, kdvDahil:boolean) {
      if (kdvDahil)
        return  fiyat;
      return fiyat * ((kdv / 100) + 1)
    }

    function oranEkle(tutar:number, oran:number) {
      return tutar * (1 + (oran / 100))
    }

    function valueChangedAlisFiyat(e:any) {
      calculateSatisFiyati();
    }
    function valueChangedKarMarji(e:any) {
      calculateSatisFiyati();
    }
    function calculateSatisFiyati() {
      if (localState.model.alisFiyat as number > 0 && localState.model.karMarji as number > 0){

      const alisFiyat = getKdvDahil( localState.model.alisFiyat as number, localState.model.alisKdv as number, localState.model.alisKdvDahil as boolean) as number

        localState.model.satisFiyat =  parseFloat(oranEkle(alisFiyat,localState.model.karMarji as number).toString())
      }
    }

    async function kategoridenKodOlustur() {

      if (localState.model.kategori && localState.model.kategori?.id && localState.model.kategori?.id.length > 0) {

        let kategoriKodu = await useStokKategoriService().getGetAllKategoriKod(localState.model.kategori.id) as string
        const yeniKategoriNo = await useStokService().getYeniStokKategoriNo(localState.model.kategori.id) as number

        localState.model.kategoriNo = yeniKategoriNo;

        kategoriKodu = String(kategoriKodu)
        if (kategoriKodu && kategoriKodu.trim().length > 0)
          localState.model.kod = kategoriKodu + String(yeniKategoriNo).padStart(2, '0');
        else
          localState.model.kod = "NSP-" + localState.stokNo;

      }
    }

     loadKategoriler();

    function changeStokName(yeniAd:string) {
        localState.model.ad = yeniAd;
    }

    function showDevirItem() {
        if (props.showDevir == false)
            return false;
        return (localState.model.alisFiyat && localState.model.alisFiyat > 0 && !(localState.model.id && localState.model.id?.length > 0))

    }

    return {
      ...toRefs(localState),
      selectedChanged,
      loadKategoriler,
      getFiyat,
      valueChangedAlisFiyat,
      valueChangedKarMarji,
      kategoridenKodOlustur,
      changeStokName,
      showDevirItem
    };
  }
});
