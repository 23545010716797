import {SiparisSatirItem} from "@/api/models/SiparisSatirItem";
import {uuid} from "vue-uuid";
import {RecordStatus} from "@/api/models/RecordStatus";
import {FaturaSatirTip} from "@/api/models/FaturaSatirTip";
import {Doviz} from "@/api/models/Doviz";
import {SiparisSatirTeslimatItem} from "@/api/models/SiparisSatirTeslimatItem";

export default class SiparisSatirItemExtend  {
    id?:string;
    status?:RecordStatus;
    kayitTarihi?:string;
    kullaniciAdi?:string;
    tip?:FaturaSatirTip;
    stokId?:string;
    itemId?:string;
    ad?:string;
    kod?:string;
    barkod?:string;
    miktar?:number;
    kdv?:number;
    doviz?:Doviz;
    kur?:number;
    fiyat?:number;
    birim?:string;
    carpan?:number;
    iskontolar?:number[];
    altIskontoOran?:number;
    genelKur?:number;
    aciklama?:string;
    not?:string;
    odemeSekli?:string;
    mensei?:string;
    adet?: number;
    depoId?:string;
    plasiyerId?:string;
    terminTarih?:string;
    teslimatlar?:SiparisSatirTeslimatItem[];
    adresId?:string;
    readonly getTeslimKalan?:number;
    readonly getSatirBrutToplam?:number;
    readonly getIskontoluToplam?:number;
    readonly getAltIskontoluToplam?:number;
    readonly getKdvTutar?:number;
    readonly getNetFiyat?:number;
    readonly getKalanToplam?:number;
    readonly getTeslimTutar?:number;
    resim?:string;
    uzunluk?:number;
    kgAdet?:number;
    fiyatKdvli?: number;
    getTeslimMiktar?:number;
    girilenTeslimMiktar?:number;
    teslimKalan?:number;
    anaBirim?:string;
    bakiye?:number;
    tevkifatOran?:number ;
    adetBolen?:number = 1  ;
    tevkifatAd?:string;
    tevkifatKod?:string;
    isTeslimEdildi?:boolean;
    bultenNo?:string;
    siparisNo?:string;

    constructor(satir?: any) {

        //super();
       // console.log("Sipariş Satır Item " , satir );
        if (satir) {
            this.tip = satir.tip;
            this.stokId = satir.stokId;
            this.itemId = satir.itemId;
            this.ad = satir.ad;
            this.kod = satir.kod;
            this.barkod = satir.barkod;
            this.miktar = satir.miktar;
            this.kdv = satir.kdv;
            this.doviz = satir.doviz;
            this.kur = satir.kur;
            this.fiyat = satir.fiyat;
            this.birim = satir.birim;
            this.carpan = satir.carpan;
            this.iskontolar = satir.iskontolar;
            this.altIskontoOran = satir.altIskontoOran;
            this.genelKur = satir.genelKur;
            this.aciklama = satir.aciklama;
            this.not = satir.not;
            this.odemeSekli = satir.odemeSekli;
            this.depoId = satir.depoId;
            this.plasiyerId = satir.plasiyerId;
            this.teslimatlar = satir.teslimatlar;
            this.adresId = satir.adresId;
            this.teslimKalan = satir.getTeslimKalan;
            this.mensei = satir.mensei;
            this.adet = satir.adet;

            if (satir.girilenTeslimMiktar && satir.girilenTeslimMiktar > 0)
                this.girilenTeslimMiktar = satir.girilenTeslimMiktar;
            else
                this.girilenTeslimMiktar = 0;

            if (satir.getTeslimMiktar && satir.getTeslimMiktar > 0)
                this.getTeslimMiktar = satir.getTeslimMiktar;
            else
                this.getTeslimMiktar = 0;

            this.fiyatKdvli = this.getSatirKdvliTutarExtend();
            // Koşullar
            if (satir.id && satir.id.length > 0)
                this.id = satir.id;
            else
                this.id = uuid.v4();

            if (satir.fiyat && satir.kdv) {
                this.fiyatKdvli = satir.fiyat * (1 + (satir.kdv / 100));
            }
            this.bakiye = satir.bakiye;
            this.anaBirim = satir.anaBirim;
            this.uzunluk = satir.uzunluk ;
            this.kgAdet = satir.kgAdet ;
            this.adetBolen = satir.adetBolen  ;
            this.tevkifatOran = satir.tevkifatOran ;
            this.tevkifatKod = satir.tevkifatKod;
            this.tevkifatAd = satir.tevkifatAd;
            this.terminTarih = satir.terminTarih;
            this.isTeslimEdildi = satir.isTeslimEdildi;
            this.bultenNo = satir.bultenNo ;
            this.siparisNo = satir.siparisNo ;


        }
    }
    getKdvTevkifatTutarExtend() {
        return this.getKdvTutarExtend() * (this.tevkifatOran! / 100);
    }

    getKdvTevkifatliTutarExtend() {
        return this.getIskontoluTutarExtend() + this.getKdvTevkifatTutarExtend();
    }

    getSatirKdvliTutarExtend(): number {
        return this.fiyat! * (1 + (this.kdv! / 100));
    }

    getSatirBrutToplamExtend(): number {
        return this.fiyat! * this.miktar! * this.carpan! * this.kur!;
    }

    getSatirIskontoTutarExtend(): number {
        const brut = this.getSatirBrutToplamExtend()
        let sonuc = brut;

        this.iskontolar?.forEach(iskonto => {
            sonuc -= sonuc * (iskonto / 100)
        })
        return brut - sonuc;
    }

    getAltIskontoTutarExtend() {
        if (!this.altIskontoOran)
            this.altIskontoOran = 0;

        return (this.getSatirBrutToplamExtend() - this.getSatirIskontoTutarExtend()) * (this.altIskontoOran! / 100);
    }

    getIskontoluTutarExtend() {

        return this.getSatirBrutToplamExtend() - this.getAltIskontoTutarExtend() - this.getSatirIskontoTutarExtend()
    }

    getKdvTutarExtend() {
        return this.getIskontoluTutarExtend() * (this.kdv! / 100);
    }

    getKdvliTutarExtend() {
        return this.getIskontoluTutarExtend() + this.getKdvTutarExtend()
    }


    // KDV SİZ
    getNetFiyatExtend() {
        return this.getIskontoluTutarExtend() / (this.miktar! * this.carpan!);
    }


}