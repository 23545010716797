
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from "vue";
import {useListelerService} from "@/api/services/ListelerService";
import {BirimFiyatGrupView} from "@/api/models/BirimFiyatGrupView";

export default defineComponent({
  components: {},
  props: {
    modelValue: {
      type: String,
      default: '',
    }
  },
  setup(props, {emit}) {

    const localState = reactive({
      items: ref([] as BirimFiyatGrupView[]),
      modelRef: computed({
        get: () => props.modelValue,
        set: (val) => emit("update:modelValue", val),
      }),

    });

    onMounted(async () => {
      localState.items = await useListelerService().getBirimFiyatGrupListe();
    })

    return {
      ...toRefs(localState),
    };
  }
});
