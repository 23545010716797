

import { computed, defineComponent, reactive, toRefs } from "vue";
import NDropdown from "@/components/Dropdown/NDropdown.vue";
import NDropdownItem from "@/components/Dropdown/NDropdownItem.vue";
import NDropdownMenu from "@/components/Dropdown/NDropdownMenu.vue";
import { DevExtremeUtils } from "@/devextreme/DevExtremeUtils";
import NButton from "@/components/Button/NButton.vue";
import NCardAction from "@/components/Card/NCardAction.vue";

export default defineComponent({
  components: { NDropdown, NDropdownItem, NDropdownMenu },
  props: {
    dataGridRef: {
      type: Object
    },
    menuAlign:{
      type:String,
      default:'bottom-left',
    }
  },
  setup(props) {
    const localState = reactive({
      dataGrid: computed(() => props.dataGridRef),
      devExtremeUtils: DevExtremeUtils
    });


    return {
      ...toRefs(localState)
    };
  }
});
