
export enum FaturaSatirTip{
Stok=0,
HizmetMasraf=1,
Sac=2
}

export const FaturaSatirTipList = [
{key:'Stok', value:0, description:'Stok'},
{key:'HizmetMasraf', value:1, description:'Hizmet/Masraf'},
{key:'Sac', value:2, description:'Sac'}
]
