import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "n-input-label"
}
const _hoisted_2 = ["value", "placeholder", "rows", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.labelTop)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.labelTop), 1))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", {
      ref: "textarea",
      class: "n-textarea",
      value: _ctx.modelValue,
      placeholder: _ctx.placeholder,
      rows: _ctx.rows,
      style: _normalizeStyle({ resize: _ctx.resize }),
      disabled: _ctx.disabled,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focus = true)),
      onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focus = false)),
      autocomplete: "nope"
    }, null, 44, _hoisted_2)
  ]))
}