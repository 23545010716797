import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {NotificationStatus} from '../models/NotificationStatus'
import {RecordStatus} from '../models/RecordStatus'
import {IdName} from '../models/IdName'
import {NotificationType} from '../models/NotificationType'
import {Scope} from '../models/Scope'
import {NotificationEntity} from '../models/NotificationEntity'

export const useNotificationService = defineStore({
id: 'NotificationService',
actions: {


	async getGet(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Notification/Get/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetAll()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Notification/GetAll/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetAllByStatus(status:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Notification/GetAllByStatus/${shared.firm.id}/${status}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async deleteRemove(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Notification/Remove/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async putMarkAsRead(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Notification/MarkAsRead/${shared.firm.id}/${id}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async putMarkAllAsRead()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Notification/MarkAllAsRead/${shared.firm.id}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async postSendTest(notificationEntity:NotificationEntity)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Notification/SendTest/${shared.firm.id}`,{ method:'POST', data:notificationEntity });
		await request();
		return response.value;
	}
}
});
