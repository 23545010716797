
import { computed, defineComponent, ref } from "vue"
import { useField, useForm } from "vee-validate"
import { object, string } from "yup"
import { isEqual } from "lodash"
import {useRoute, useRouter} from "vue-router"
import { useAccountServiceExtend } from "@/api/extends/AccountServiceExtend"
import { usePopupService } from "@/services/popupService"
import { useFirmaStore } from "@/layouts/LoginLayout/context"
import { AccessToken } from "@/api/models/AccessToken"
import { useAccountService } from "@/api/services/AccountService"
import { useSharedStore } from "@/store"
import NCheckbox from "@/components/Checkbox/NCheckbox.vue"
import {usePinStore} from "@/layouts/PinLayout/store/PinStore";

export default defineComponent({
  name: "LoginForm",
  props:{
    redirectRouteName:{
      type:String,
      default:"ApplicationLayout"
    },
    isCode:{
      type:Boolean,
      default: false
    }
  },
  setup(props) {
    const schema = object({
      username: string().required("E-Posta Adresi Zorunlu"),
      password: string()
        .min(6, "Şifre En Az 6 Karakter Olmalıdır")
        .required("Şifre En Az 6 Karakter Olmalıdır"),
      firm: string().required(),
      period: string().test(
        "len",
        "Dönem 4 rakamdan oluşan bir yıl belirtmelidir",
        val => val?.length === 4
      ),
      code:string()
    })



    const { meta, values } = useForm({
      validationSchema: schema,
      initialValues: {
        username: "",
        password: "",
        code:"",
        firm: "",
        period: ""
      }
    })

    const rememberMe = ref(false)

    const isDirty = computed(() => isEqual(meta.value.initialValues, values))
    const isLoading = ref(false)
    const { value: username, errorMessage: usernameError } = useField(
      "username"
    )
    const { value: password, errorMessage: pwdError } = useField("password")
    const { value: period, errorMessage: periodError } = useField("period");
    const { value: code, errorMessage: codeError } = useField("code");

    const accountService = useAccountServiceExtend()
    const router = useRouter()


      usePinStore().unload();
    async function login() {


      isLoading.value = true

      try {
        const token = await accountService.postLogin(
          {
            userName: username.value,
            password: password.value,
            code:code.value
          } as any,
          rememberMe.value
        ) as AccessToken;

        if (token?.isAuthenticated) {
            useSharedStore().setUserName(username.value as string, rememberMe.value)

            usePinStore().load(token.pinTimeout as number,token.pinActive as boolean);
        }

        const firma = (await useFirmaStore.getFirma(username.value as string)) as any

        if (firma) {
          useFirmaStore.setFirma(firma, username.value as string)
          useSharedStore().firm.id = firma.firma;
          useSharedStore().firm.period = firma.donem;

           await router.push({
              name: props.redirectRouteName,
              params: {
                firm: firma.firma as string,
                period: firma.donem as string
              }
            })
        } else {
          throw "Firma bulunamadı"
        }
      } catch (err:any) {
        usePopupService().pushDangerNotification({
          body: JSON.stringify(err.message as string),
          title: "Giriş başarısız."
        })
      }
      isLoading.value = false
    }

    return {
      username,
      usernameError,
      password,
      code,
      pwdError,
      codeError,
      period,
      periodError,
      isDirty,
      login,
      isLoading,
      rememberMe
    }
  }
})
