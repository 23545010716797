
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import FaturaAdresComponent from "@/modules/Fatura/components/FaturaAdresComponent.vue";
import { FaturaView } from "@/api/models/FaturaView";
import { UlkeEntity } from "@/api/models/UlkeEntity";
import NInput from "@/components/Input/NInput.vue";
import { useGenelCache } from "@/modules/Ortak/services/useGenelCache";
import NFormField from "@/components/FormField/NFormField.vue";
import CariAdresSelecBox from "@/modules/Cari/components/CariAdresSelecBox.vue";
import { AdresItem } from "@/api/models/AdresItem";
import MahalleAraSelectBox from "@/modules/Ortak/components/MahalleAraSelectBox.vue";
import NCard from "@/components/Card/NCard.vue";
import FaturaSevkListSelect from "@/modules/Fatura/components/FaturaSevkListSelect.vue";
import { FaturaHareketTip } from "@/api/models/FaturaHareketTip";
import { useCariService } from "@/api/services/CariService";
import { AdresTip } from "@/api/models/AdresTip";

export default defineComponent({
  components: {
    FaturaSevkListSelect,
    NCard,
    MahalleAraSelectBox,
    CariAdresSelecBox,
    NFormField,
    NInput,
    FaturaAdresComponent
  },
  props: {
    modelValue: {
      type: Object
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      model: computed({
        get: () => props.modelValue as FaturaView,
        set: (val) => emit("update:modelValue", val)
      }),
      adresler: ref([] as UlkeEntity[]),
      selectedTab: ref(0)
    });

    load();

    async function load() {
      localState.adresler = await useGenelCache().getUlkeList() as UlkeEntity[];
      const cariAdresler: AdresItem[] = await useCariService().getGetAllAddress(localState.model.ustBilgi?.cari?.id as string);
      let adres:AdresItem = {};

      if (localState.model.tip == FaturaHareketTip.SatisIrsaliye
        && !(localState.model && localState.model.id && localState.model.id.length > 0)) {

        if (cariAdresler && cariAdresler.length > 0) {
          adres = cariAdresler.find(a => a.tipi == AdresTip.Teslimat) as AdresItem;
          if (adres && adres.adres && adres.adres.length > 0  )
            adres = cariAdresler[0] as AdresItem;

          localState.model.teslimatAdresi = {
            adres : adres.adres,
            cep : adres.cep,
            belde : adres.belde,
            ilce : adres.ilce,
            mahalle : adres.mahalle,
            mail : adres.mail,
            pKodu : adres.pKodu,
            sehir : adres.sehir,
            ulke : adres.ulke,

          }
        }

      }


    }

    function cariAdresSelectedChanged(data: AdresItem) {
      if (data) {

        localState.model.ustBilgi!.belde = data.belde;
        localState.model.ustBilgi!.sehir = data.sehir;
        localState.model.ustBilgi!.ilce = data.ilce;
        localState.model.ustBilgi!.pKodu = data.pKodu;
        localState.model.ustBilgi!.mahalle = data.mahalle;
        localState.model.ustBilgi!.ulke = data.ulke;
        localState.model.ustBilgi!.adres = data.adres;
        localState.model.ustBilgi!.cep = data.cep;
        localState.model.ustBilgi!.tel = data.tel;
        localState.model.ustBilgi!.mail = data.mail;

      }
    }

    watch(() => localState.model.isFatura, () => {

      if (localState.model.isFatura) {

        if (!localState.model.ustBilgi?.vergiNo || localState.model.ustBilgi?.vergiNo?.length == 0) {

          localState.model.ustBilgi!.vergiNo = "11111111111";

        }

      }
    });


    function sevkSelect(sevk: any) {
      //console.log(sevk)

      if (localState.model && localState.model.ustBilgi) {
        localState.model.ustBilgi.sevkAd = sevk.sevkAd;
        localState.model.ustBilgi.sevkDorsePlaka = sevk.sevkDorsePlaka;
        localState.model.ustBilgi.sevkFirma = sevk.sevkFirma;
        localState.model.ustBilgi.sevkFirmaVergiNo = sevk.sevkFirmaVergiNo;
        localState.model.ustBilgi.sevkPlaka = sevk.sevkPlaka;
        localState.model.ustBilgi.sevkSoyad = sevk.sevkSoyad;
        localState.model.ustBilgi.sevkTcNo = sevk.sevkTcNo;
      }

    }

    return {
      ...toRefs(localState),
      cariAdresSelectedChanged,
      sevkSelect
    };
  }
});
