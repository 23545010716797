
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import {Liste} from "@/api/models/Liste";
import {useListelerService} from "@/api/services/ListelerService";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NInput from "@/components/Input/NInput.vue";
import {BirimView} from "@/api/models/BirimView";
import NButton from "@/components/Button/NButton.vue";
import NListView from "@/components/ListView/NListView.vue";
import NIcon from "@/components/Icon/NIcon.vue";
import NButtonGroup from "@/components/Button/NButtonGroup.vue";
import NViewGrid from "@/components/ViewGrid/NViewGrid.vue";
import NCard from "@/components/Card/NCard.vue";
import {BirimFiyatGrupView} from "@/api/models/BirimFiyatGrupView";
import {GenelUtils} from "@/modules/Ortak/utils/GenelUtils";
import {UretimTanimView} from "@/api/models/UretimTanimView";
import NTagList from "@/components/Tag/NTagList.vue";
import NGrid from "@/components/Grid/NGrid.vue";

export default defineComponent({
  components: {NGrid, NTagList, NButtonGroup, NIcon, NListView, NButton, NInput, NFormField, NFormGrid},
  props: {},
  setup() {
    const localState = reactive({
      model: ref({} as UretimTanimView),
      uretimTanimlari: ref([] as UretimTanimView[]),
      uretimTanimiSelected: computed({
        get: () => -1,
        set: () => -1,
      }),
    })

    load()

    async function load() {
      localState.uretimTanimlari = await useListelerService().getUretimTanimlari();
      localState.model = {
        hatlar: [],
      }
    }

    async function uretimTanimiSil(item: UretimTanimView) {

      if (await GenelUtils.onayDiyalogu("Silmek İstiyor Musunuz ? ", "Silme İşlemi")) {
        await useListelerService().deleteUretimTanimSil(item.id as string);
        load();

      }


    }

    async function uretimTanimiEkle() {
      await useListelerService().postUretimTanimEkle(localState.model);
      load();

    }

    function uretimTanimiEdit(item: UretimTanimView) {
      localState.model = item;
    }

    async function uretimTanimiGuncelle() {
      const view: UretimTanimView = {
        id: localState.model.id,
        isIstasyonu: localState.model.isIstasyonu,
        hatlar: localState.model.hatlar,
      }
      await useListelerService().putUretimTanimGuncelle(view);
      load();
      localState.model = {};
    }

    return {
      ...toRefs(localState),
      uretimTanimiSil,
      uretimTanimiEkle,
      uretimTanimiEdit,
      uretimTanimiGuncelle,
    };
  }
});
