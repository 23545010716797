import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSelectBox = _resolveComponent("NSelectBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDropDownTreeView = _resolveComponent("NDropDownTreeView")!
  const _component_NRefresher = _resolveComponent("NRefresher")!
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NImageUploader = _resolveComponent("NImageUploader")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_CariFormAdresler = _resolveComponent("CariFormAdresler")!

  return (_openBlock(), _createElementBlock("div", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), [
    _createVNode(_component_NFormGrid, {
      cols: "auto auto",
      padding: "0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NGrid, {
          cols: "1fr 1fr ",
          style: {"padding":"3px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NFormField, {
              "grid-cols": "80px 1fr",
              label: "Cari Tip"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NSelectBox, {
                  items: _ctx.cariTipListRef,
                  "label-field": "description",
                  "value-field": "value",
                  modelValue: _ctx.model.tip,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.tip) = $event))
                }, null, 8, ["items", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "80px 1fr",
              label: "Kurum"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NSelectBox, {
                  items: _ctx.kurumTipListRef,
                  "label-field": "key",
                  "value-field": "value",
                  modelValue: _ctx.model.kurumTipi,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.kurumTipi) = $event))
                }, null, 8, ["items", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "80px 1fr auto",
              label: "Kod",
              span: "1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  type: "text",
                  modelValue: _ctx.model.kod,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.kod) = $event)),
                  disabled: _ctx.disableStokKodu
                }, null, 8, ["modelValue", "disabled"]),
                _createVNode(_component_NButton, {
                  click: () => _ctx.disableStokKodu = !_ctx.disableStokKodu,
                  size: "small",
                  icon: _ctx.disableStokKodu === true ? 'lock' : 'unlock',
                  title: "Cari kodu kilidi"
                }, null, 8, ["click", "icon"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Kategori",
              "grid-cols": "80px 1fr",
              span: "1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NRefresher, { load: _ctx.loadKategoriler }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NDropDownTreeView, {
                      "selected-item": _ctx.model.kategori,
                      "onUpdate:selectedItem": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.kategori) = $event)),
                      onSelectedChanged: _ctx.selectedChanged,
                      "data-source": _ctx.kategoriler,
                      "items-expr": "altKategoriler"
                    }, null, 8, ["selected-item", "onSelectedChanged", "data-source"])
                  ]),
                  _: 1
                }, 8, ["load"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "80px 1fr",
              span: "2",
              label: "Ünvan"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  modelValue: _ctx.model.unvan,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.unvan) = $event)),
                  type: "text",
                  "is-big": true
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "80px 1fr",
              label: "Vergi Dairesi",
              title: "Vergi Dairesi"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  modelValue: _ctx.model.vergiDairesi,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.vergiDairesi) = $event)),
                  type: "text"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "80px 1fr",
              label: "Vergi No",
              title: "Vergi No"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxTextBox, {
                  value: _ctx.model.vergiNo,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.vergiNo) = $event)),
                  mask: "##########"
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "80px 1fr 1fr",
              label: "Ad / Soyad"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  modelValue: _ctx.model.ad,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.ad) = $event)),
                  type: "text"
                }, null, 8, ["modelValue"]),
                _createVNode(_component_NInput, {
                  modelValue: _ctx.model.soyad,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.soyad) = $event)),
                  type: "text"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "80px 1fr",
              label: "T.C. No",
              span: "1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxTextBox, {
                  value: _ctx.model.tcNo,
                  "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.tcNo) = $event)),
                  mask: "###########"
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_NImageUploader, {
          src: _ctx.model.resimYol,
          "onUpdate:src": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.resimYol) = $event)),
          height: "80",
          width: "80",
          "folder-name": "",
          "image-height": "80",
          "image-width": "80",
          "server-url": _ctx.serverUrl,
          style: {"padding-left":"2px","padding-right":"10px","padding-top":"5px"}
        }, null, 8, ["src", "server-url"])
      ]),
      _: 1
    }),
    _createVNode(_component_CariFormAdresler, {
      modelValue: _ctx.model,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model) = $event)),
      adresler: _ctx.adreslerRef,
      "onUpdate:adresler": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.adreslerRef) = $event))
    }, null, 8, ["modelValue", "adresler"])
  ], 16))
}