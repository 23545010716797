import { NModuleRoute } from "@/models";

export const SiparisModuleRoutes: NModuleRoute[] = [
    {
        id: "SiparisModule",
        order: 10,
        displayName: "Sipariş / Teklif",
        icon: {
            type: "file-minus"
        },
        children: [
            {
                id: "SiparisListesi",
                order: 0,
                displayName: "Sipariş Listesi",
                requiredRoles:{
                    readRoles:['Siparis.Liste'],
                    insertRoles:['Siparis.Ekle'],
                    updateRoles:['Siparis.Guncelle'],
                    deleteRoles:['Siparis.Sil']
                },
                icon: {
                    type: "file-minus"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "SiparisListesi"*/ "../pages/SiparisListesiPage.vue"
                        )
            },
            {
                id: "TeklifListesi",
                order: 1,
                displayName: "Teklif Listesi",
                requiredRoles:{
                    readRoles:['Teklif.Liste'],
                    insertRoles:[],
                    updateRoles:['Teklif.Guncelle'],
                    deleteRoles:[]
                },
                icon: {
                    type: "file-minus"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "TeklifListesi"*/ "../pages/TeklifListesiPage.vue"
                        )
            },
            {
                id: "B2BSiparisListesi",
                order: 0,
                displayName: "B2B Sipariş Listesi",
                requiredRoles:{
                    readRoles:['Siparis.Liste'],
                    insertRoles:['Siparis.Ekle'],
                    updateRoles:['Siparis.Guncelle'],
                    deleteRoles:['Siparis.Sil']
                },
                icon: {
                    type: "file-minus"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "B2BSiparisListesi"*/ "../pages/B2BSiparisListesiPage.vue"
                        )
            },
            {
                id: "HizliSiparisListesi",
                order: 0,
                displayName: "Hızlı Sipariş Listesi",
                requiredRoles:{
                    readRoles:['Siparis.Liste'],
                    insertRoles:['Siparis.Ekle'],
                    updateRoles:['Siparis.Guncelle'],
                    deleteRoles:['Siparis.Sil']
                },
                icon: {
                    type: "file-minus"
                },
                component: () =>
                  import(
                    /* webpackChunkName: "SiparisListesi"*/ "../pages/HizliSiparisListesi.vue"
                    )
            },

        ]
    }
];
