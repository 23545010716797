
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NTextarea from "@/components/Textarea/NTextarea.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import { KargoTip, KargoTipList } from "@/api/models/KargoTip";
import { SmsMailKargoGonderItem } from "@/api/models/SmsMailKargoGonderItem";
import { KargoTeslimatTip, KargoTeslimatTipList } from "@/api/models/KargoTeslimatTip";
import { KargoBoyutTip, KargoBoyutTipList } from "@/api/models/KargoBoyutTip";
import { KargoTeslimTip, KargoTeslimTipList } from "@/api/models/KargoTeslimTip";
import { KargoOdemeTip, KargoOdemeTipList } from "@/api/models/KargoOdemeTip";
import { PlatformTip, PlatformTipList } from "@/api/models/PlatformTip";
import NInput from "@/components/Input/NInput.vue";
import NSwitch from "@/components/Switch/NSwitch.vue";
import NCard from "@/components/Card/NCard.vue";
import { KapidaOdemeTip, KapidaOdemeTipList } from "@/api/models/KapidaOdemeTip";
import NCheckbox from "@/components/Checkbox/NCheckbox.vue";
import NFormFieldLabelTop from "@/components/FormField/NFormFieldLabelTop.vue";
import { GenelUtils } from "@/modules/Ortak/utils/GenelUtils";

export default defineComponent({
  components: { NFormFieldLabelTop, NCard, NSwitch, NInput, NGrid, NTextarea, NFormField },
  props: {
    modelValue: {
      type: Object
    },
    tutar: {
      type: Number,
      default: 0
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      model: computed({
        get: () => props.modelValue as SmsMailKargoGonderItem,
        set: val => emit("update:modelValue", val)
      }),

      kargoTipList: KargoTipList,
      kargoTeslimatTipList: KargoTeslimatTipList,
      kargoBoyutTipList: KargoBoyutTipList,
      kargoTeslimTipList: KargoTeslimTipList,
      kargoOdemeTipList: KargoOdemeTipList,
      platformTipList: PlatformTipList,
      kargoOdemeTip: KargoOdemeTip,
      kapidaOdemeTipList: KapidaOdemeTipList,
      tutarRef: computed(() => props.tutar),
      genelUtils: GenelUtils
    });


    watch(() => localState.model?.kargoOdemeTip, val => {
      if (localState.kargoOdemeTip && localState.model?.kargoOdemeTip == KargoOdemeTip.Platform) {
        localState.model.kapidaOdemeMi = false;
      }

    });


    return {
      ...toRefs(localState)
    };
  }
});
