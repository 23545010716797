
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import NDropDownTreeView from "@/components/DropDownTreeView/NDropDownTreeView.vue";
import { useHepsiBuradaService } from "@/api/services/HepsiBuradaService";
import { HepsiBuradaCategoryModel } from "@/api/models/HepsiBuradaCategoryModel";
import HepsiBuradaOzellikEditor from "@/modules/Pazaryerleri/HepsiBurada/components/HepsiBuradaOzellikEditor.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import {HepsiBuradaPazaryeriItem} from "@/api/models/HepsiBuradaPazaryeriItem";

export default defineComponent({
  components: {NFormGrid, HepsiBuradaOzellikEditor,   NDropDownTreeView },
  props: ['hepsiBurada'],
  setup(props) {

    const localState = reactive({
      hepsiBuradaRef:computed(() => props.hepsiBurada as HepsiBuradaPazaryeriItem),
      kategoriler:ref([] as HepsiBuradaCategoryModel[]),
    });


    async function load(){
      const response = await useHepsiBuradaService().getKategoriler(365);
      localState.kategoriler = response;
    }

    load()
    return {
      ...toRefs(localState)
    };
  }
});

