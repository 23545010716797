
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";
import { ValueType, ValueTypeList } from "@/api/models/ValueType";
import NInput from "@/components/Input/NInput.vue";
import { Ozellik } from "@/api/models/Ozellik";
import NSwitch from "@/components/Switch/NSwitch.vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import NTagList from "@/components/Tag/NTagList.vue";
import {usePopupService} from "@/services/popupService";
import {ResponseMessageConverter} from "@/api/utils/ResponseMessageConverter";

export default defineComponent({
  components: {
    NTagList,
    NModalDialog,
    NSwitch,
    NInput,
    NSelectBox,
    NFormField,
    NFormGrid
  },
  props: {
    show: {
      type: Boolean
    },
    ozellikId: {
      type: String
    },
    ozellikler: {
      type: Array
    },
    isVaryantForm:{
      type:Boolean,
      default:false
    },
    isStok:{
      type:Boolean,
      default:true
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      valueTypes: ref(ValueTypeList),
      model: ref({ valueType: ValueType.Metin, id:'', values:[],selectedValues:[] } as Ozellik),
      ozelliklerRef: computed({
        get: () => props.ozellikler as Ozellik[],
        set: val => emit("update:ozellikler", val)
      }),
      showDialog: computed({
        get: () => props.show,
        set: val => emit("update:show", val)
      })
    });

    function save() {

      if (isOzelliklerExists()) {
          errorMessage("Daha önce aynı isimde özellik/varyant girilmiş!")
          return
      }

      if (localState.ozelliklerRef) {
        if (props.ozellikId && props.ozellikId.length > 0) {

          // update
          const index = localState.ozelliklerRef.findIndex(
            a => a.id == props.ozellikId
          );

          localState.ozelliklerRef.splice(index, 1, localState.model);
        } else {
          localState.model.id = Date.now().toString();
          localState.ozelliklerRef.push(localState.model);
        }
        localState.showDialog = false;
      } else {
        alert("localState.ozelliklerRef tanımsız.");
    //    console.log(localState.ozelliklerRef);
      }
    }

    function load() {
      if (props.ozellikId && props.ozellikId.length > 0) {
        const ozellik = localState.ozelliklerRef.find(
          a => a.id == props.ozellikId
        );
        localState.model = JSON.parse(JSON.stringify(ozellik));

      }
    }
    function remove() {
      if (confirm("Özelliği silmek istiyor musunuz?")) {
        const index = localState.ozelliklerRef.findIndex(
          a => a.id == props.ozellikId
        );

        if (index >= 0) localState.ozelliklerRef.splice(index, 1);
        localState.showDialog = false;
      }
    }

      function isOzelliklerExists() {
          for (const oldOzellik of localState.ozelliklerRef as any[]) {

              const ozellikMevcut = oldOzellik.name?.toUpperCase() == localState.model.name?.toUpperCase() /*||
            (localState.model.nameTrendyol ? oldOzellik.nameTrendyol == localState.model.nameTrendyol : false) ||
            (localState.model.nameHepsiBurada ? oldOzellik.nameHepsiBurada == localState.model.nameHepsiBurada : false) ||
            (localState.model.nameN11 ? oldOzellik.nameN11 == localState.model.nameN11 : false) ||
            (localState.model.nameCicekSepeti ? oldOzellik.nameCicekSepeti == localState.model.nameCicekSepeti : false) ||
            (localState.model.namePazarama ? oldOzellik.namePazarama == localState.model.namePazarama : false)*/
              && oldOzellik.id != props.ozellikId


              if (ozellikMevcut)
                  return true;

          }

      }

    load();


    function errorMessage(body:string) {
        usePopupService().pushDangerNotification({
            body: body
        });
    }

    return {
      ...toRefs(localState),
      save,
      remove
    };
  }
});
