export const Benchmark = {
  stopWatch:(name:string, func:any)=>{
      const t1 = new Date().getTime();
      func();
      const t2 = new Date().getTime();
      console.log((t2 - t1) +", " + name + " " + func.name  + "ms. completed  !" )
  },
     stopWatchAsync:async (name:string, func:any)=>{
        const t1 = new Date().getTime();
        await func(undefined);
        const t2 = new Date().getTime();
        console.log((t2 - t1) +", " + name + " " + func.name  + "ms. completed  !" )
    },
    delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}