import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {FaturaTopluEFaturaView} from '../models/FaturaTopluEFaturaView'
import {RecordStatus} from '../models/RecordStatus'
import {FaturaHareketTip} from '../models/FaturaHareketTip'
import {FaturaTip} from '../models/FaturaTip'
import {FaturaUstBilgiItem} from '../models/FaturaUstBilgiItem'
import {TeslimatAdresItem} from '../models/TeslimatAdresItem'
import {FaturaSatirItem} from '../models/FaturaSatirItem'
import {FaturaAltBilgiItem} from '../models/FaturaAltBilgiItem'
import {DocumentItem} from '../models/DocumentItem'
import {PlanVadelerItem} from '../models/PlanVadelerItem'
import {FaturaEFaturaItem} from '../models/FaturaEFaturaItem'
import {SmsMailKargoGonderItem} from '../models/SmsMailKargoGonderItem'
import {FaturaView} from '../models/FaturaView'
import {EFaturaContentType} from '../models/EFaturaContentType'

export const useIzibizEfaturaService = defineStore({
id: 'IzibizEfaturaService',
actions: {


	async getCariEfaturaKontrol(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IzibizEfatura/CariEfaturaKontrol/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getEfaturaKontrol(vergiNo:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IzibizEfatura/EfaturaKontrol/${shared.firm.id}/${vergiNo}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postTopluEfaturaKontrol(liste:FaturaTopluEFaturaView[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IzibizEfatura/TopluEfaturaKontrol/${shared.firm.id}`,{ method:'POST', data:liste });
		await request();
		return response.value;
	},

	async getGetHtml(faturaId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IzibizEfatura/GetHtml/${shared.firm.id}/${shared.firm.period}/${faturaId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postGetHtmlFaturaView(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IzibizEfatura/GetHtmlFaturaView/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async getGetPdfInvoiceEncrypted(hash:string)
	{
		const { request, response } = useApi(`/api/IzibizEfatura/GetPdfInvoiceEncrypted/${hash}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetPdfInvoice(faturaId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IzibizEfatura/GetPdfInvoice/${shared.firm.id}/${shared.firm.period}/${faturaId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getDownloadPdfInvoice(faturaId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IzibizEfatura/DownloadPdfInvoice/${shared.firm.id}/${shared.firm.period}/${faturaId}.pdf`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetPdf(eFaturaNo:string,contentType:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IzibizEfatura/GetPdf/${shared.firm.id}/${eFaturaNo}/${contentType}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postPrintSelectedHash(options:any,hash:string)
	{
		const { request, response } = useApi(`/api/IzibizEfatura/PrintSelectedHash/${hash}`,{ method:'POST' });
		await request();
		return response.value;
	},

	async postPrintSelectedPdf(liste:string[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IzibizEfatura/PrintSelectedPdf/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data: liste });
		await request();
		return response.value;
	},

	async postPrintSelected(options:any)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IzibizEfatura/PrintSelected/${shared.firm.id}/${shared.firm.period}`,options);
		await request();
		return response.value;
	},

	async postPrintSelectedFromFaturaView(options:any)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IzibizEfatura/PrintSelectedFromFaturaView/${shared.firm.id}/${shared.firm.period}`,options);
		await request();
		return response.value;
	}
}
});
