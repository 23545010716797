export enum MenuFormType {
    Kullanilmaz,
    Cari_Dekont_Form, //
    Banka_Dekont_Form, //
    Kredi_Karti_Dekont_Form,
    Kasa_Dekont_Form,
    Kredi_Dekont_Form,
    Pos_Dekont_Form,
    Fatura_Form,
    Siparis_Form,

    MusteriCekSenetForm,
    FirmaCekSenetForm,
    Kiymet_Form,
    FisForm,
    OnlineTahsilat
}
