import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "treeview-item" }
const _hoisted_2 = { class: "tree-view-item-container" }
const _hoisted_3 = {
  key: 2,
  class: "collapse-button"
}
const _hoisted_4 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TreeView = _resolveComponent("TreeView")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showChild && _ctx.getLength(_ctx.item) > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "collapse-button",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showChild = false))
          }, " - "))
        : _createCommentVNode("", true),
      (!_ctx.showChild && _ctx.getLength(_ctx.item) > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "collapse-button",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showChild = true))
          }, " + "))
        : _createCommentVNode("", true),
      (_ctx.getLength(_ctx.item) === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "item", { item: _ctx.item })
      ])
    ]),
    (_ctx.item[_ctx.subField] && _ctx.item[_ctx.subField].length > 0)
      ? _withDirectives((_openBlock(), _createBlock(_component_TreeView, {
          key: 0,
          subField: _ctx.subField,
          items: _ctx.item[_ctx.subField]
        }, {
          item: _withCtx(({ item }) => [
            _renderSlot(_ctx.$slots, "item", {
              item: item,
              parent: _ctx.parent
            })
          ]),
          _: 3
        }, 8, ["subField", "items"])), [
          [_vShow, _ctx.showChild]
        ])
      : _createCommentVNode("", true)
  ]))
}