
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import NIcon from "@/components/Icon/NIcon.vue";
import NLoading from "@/components/Loading/NLoading.vue";



import { useDocumentManagementServiceExtend } from "@/api/uploaders/DocumentManagementServiceExtend";
import DocumentUploadType from "@/api/uploaders/models/DocumentUploadType";
import { UploadState } from "@/api/uploaders/models/UploadState";
import {alert} from "devextreme/ui/dialog";

export default defineComponent({
  components: { NLoading, NIcon },
  props: {
    serverUrl: { type: String, default: "" },
    src: { type: Array },
    width: { type: Number, default: 30 },
    height: { type: Number, default: 30 },
    folderName: { type: String, default: "" },
    showUploadButton: { type: Boolean, default: true }
  },
  setup(props, { emit }) {
    const localState = reactive({
      states: ref(UploadState),
      models: ref([] as any[]),
      srcRef: ref(computed(() => props.src as any[]))
    });

    async function fileSelected(e: any) {
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];

        const model: any = {};

        model.width = props.width;
        model.folderName = props.folderName;
        model.height = props.height;
        model.state = UploadState.Loading;
        model.file = file;
        model.loaded = 0;
        model.total = 0;
        if (localState.models && localState.models.length > 4){
          alert("<h4>Çoklu  Dosya Sınırana Ulaşıldı</h4>" +
              "<h5>İzin verilen dosya sınırı = 5</h5r>" , "Döküman Sınırı") ;
          return;
        }

        localState.models.push(model);
        await fileUpload(model);
      }
    }

    async function fileUpload(model: DocumentUploadType) {
      try {
        model.state = UploadState.Loading;
        const response = await useDocumentManagementServiceExtend().postDocument(
          model
        );

        if (response.filePath) localState.srcRef.push(response.filePath);
        model.error = response.error;
        if (model.error && model.error.length > 0) {
          model.state = UploadState.Error;
        } else {
          model.state = UploadState.Ready;
        }
      } catch (err:any) {
        model.error = err.message;
      }
    }

    function getRepeat() {
      if (localState.srcRef)
        return (
          "repeat(" +
          (localState.srcRef.length + 1) +
          ", " +
          props.width +
          "px) 1fr"
        );
      else return props.width + "px auto";
    }

    function remove(i: number) {
      if (confirm(i + 1 + " numaralı dosyayı silmek istiyor musunuz?"))
        localState.srcRef.splice(i, 1);
    }

    function load() {
      if (!props.src) {
        emit("update:src", []);
      }
    }

    load();

    return {
      ...toRefs(localState),
      fileSelected,

      remove,
      getRepeat
    };
  }
});
