
import {defineComponent, ref} from "vue";
import {maska} from "maska";
import {TextCaseType} from "@/components/Input/models/TextCaseType";
import {uuid} from "vue-uuid";

export default defineComponent({
  name: "NInput",
  directives: {
    maska
  },
  props: {
    status: {
      type: String
    },
    type: {
      type: String,
      default: "text"
    },
      autoComplete:{
          type:String,
          default:'off'
      },
    placeholder: String,
    labelTop: String,
    prefix: String,
    suffix: String,
    message: String,
    suffixIcon: String,
    prefixIcon: String,
    disabled: Boolean,
    modelValue: String,
    fieldName: {
        type:String,
        default:()=> uuid.v4()
    },
    dataset: Object,
    datasetFormatter: Function,
    mask: Object,
    unmasked: String,
    showCounter:{
      type:Boolean,
      default:false
    },
    maxlength:{
      type:Number,
      default:-1,
    },
    isBig:{
      type:Boolean,
      default:false
    },
    convertCase:{
      default:TextCaseType.Normal
    }
  },
  setup(props, { emit }) {
    const focus = ref(false);

    function input(e: Event) {
      const text = (e.target as HTMLInputElement).value;
      const textConverted = convertCase(text);
      emit("update:modelValue", textConverted);
      emit("input",textConverted)
    }

    function convertCase(text:string){
      if (text){
        switch (props.convertCase as TextCaseType) {
          case TextCaseType.LowerCase:
            return  text.toLowerCase()
            case TextCaseType.UpperCase:
              return text.toUpperCase();
        }
      }
      return text;
    }

    function onInputFocused() {
      focus.value = true;
    }

    function onBlur() {
      focus.value = false;
    }

    function onMaska(e: Event) {
      emit("update:unmasked",(e.target as HTMLInputElement).dataset.maskRawValue);
    }
    function onKeyDown(e:any) {
      if (e.key == 'Enter'){
        emit('enter',e);
      }
    }

    return { input, focus, onInputFocused, onBlur, onMaska,onKeyDown };
  }
});
