
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import { KasaHareketView } from "@/api/models/KasaHareketView";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import TutarsalBilgiler from "@/modules/Ortak/components/TutarsalBilgiler.vue";
import { GenelResmiMuhasebeTipList } from "@/api/models/GenelResmiMuhasebeTip";
import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import { MenuRefrenceType } from "@/modules/Ortak/models/Menu/MenuRefrenceType";
import { KasaUtils } from "@/modules/Kasa/utils/KasaUtils";
import BankaSelectBox from "@/modules/Banka/components/BankaSelectBox.vue";
import KasaSelectBox from "@/modules/Kasa/components/KasaSelectBox.vue";
import PosSelectBox from "@/modules/Pos/components/PosSelectBox.vue";
import KrediKartiSelectBox from "@/modules/KrediKarti/components/KrediKartiSelectBox.vue";
import HizmetMasrafSelectBox from "@/modules/HizmetMasraf/components/HizmetMasrafSelectBox.vue";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";
import SubeSelectBox from "@/modules/Sube/components/SubeSelectBox.vue";
import { KasaView } from "@/api/models/KasaView";
import { useKasaService } from "@/api/services/KasaService";
import { useKasaHareketService } from "@/api/services/KasaHareketService";
import { KasaHareketTip } from "@/api/models/KasaHareketTip";
import { useHizmetMasrafHareketService } from "@/api/services/HizmetMasrafHareketService";
import { HizmetMasrafHareketEntity } from "@/api/models/HizmetMasrafHareketEntity";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NViewGrid from "@/components/ViewGrid/NViewGrid.vue";
import CokluDokuman from "@/modules/Ortak/components/CokluDokuman.vue";
import NTextarea from "@/components/Textarea/NTextarea.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import GiderYeriSelectBox from "@/modules/Ortak/components/GiderYeriSelectBox.vue";
import { confirm } from "devextreme/ui/dialog";
import NInput from "@/components/Input/NInput.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import ProjeSelectBox from "@/modules/Proje/components/ProjeSelectBox.vue";
import {KurView} from "@/api/models/KurView";
import {useDovizKurService} from "@/api/services/DovizKurService";


export default defineComponent({
  components: {
    ProjeSelectBox,
    NGrid,
    NInput,
    GiderYeriSelectBox,
    NLoadingPanel,
    NFormField,
    NTextarea,
    CokluDokuman,
    NViewGrid,
    NFormGrid,
    SubeSelectBox,
    NSelectBox,
    HizmetMasrafSelectBox,
    KrediKartiSelectBox,
    PosSelectBox,
    KasaSelectBox,
    BankaSelectBox,
    TutarsalBilgiler,
    NModalDialog
  },
  props: {
    kasaId: {
      type: String
    },
    show: {
      type: Boolean,
      default: false
    },
    hareketId: {
      type: String,
      default: ""
    },
    menu: {
      type: Object
    },
    tutar: {
      type: Number,
      default: 0
    },
    hizmetMasrafId: {
      type: String

    },
    taksitPlanId: {
      type: String,
      default: ""
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      kurlarRef: ref([] as KurView[]),
      genelResmiMuhasebeTipList: ref(GenelResmiMuhasebeTipList),
      showRef: computed({
        get: () => props.show,
        set: val => emit("update:show", val)
      }),
      kasa: ref({} as KasaView),
      model: ref({} as KasaHareketView),
      menuModel: ref(props.menu as MenuModel),
      menuRefrenceType: ref(MenuRefrenceType),
      utils: ref(KasaUtils),
      selectedTab: ref(0)

    });

    async function load() {

      localState.model = (await localState.utils.getHareketView(
        props.kasaId as string,
        props.hareketId,
        props.tutar
      )) as KasaHareketView;


      localState.kasa = await useKasaService().getGetir(
        localState.model.kasaId as string
      );


      if (localState.menuModel && localState.model && localState.model.id) {
        if (localState.menuModel.hareketTipi == KasaHareketTip.KasadanMasrafOdeme || localState.menuModel.hareketTipi == KasaHareketTip.KasayaHizmetTahsilati) {
          const hizmetMasrafHareket = await useHizmetMasrafHareketService().getReferansId(localState.model.id) as HizmetMasrafHareketEntity;
          localState.model.giderYeri = hizmetMasrafHareket.giderYeri;
        }
      }

      if (localState.menuModel.hareketTipi == KasaHareketTip.KasadanMasrafOdeme) {
        if (localState.model.id == null || !localState.model.id) {
          localState.model.referansId = props.hizmetMasrafId;

        }
      }

      localState.model.taksitPlanId = props.taksitPlanId;
    }

    function kdvHesaplama() {
      setTimeout(() => {
        if (localState.model &&
          localState.model.hesaplama &&
          localState.model.hesaplama.islenecekTutar &&
          localState.model.hesaplama.islenecekTutar
        ) {

          if (localState.model.id && localState.model.id.length > 0) {
            //
          } else
            localState.model.kdvTutar =
              localState.model.hesaplama.islenecekTutar - (localState.model.hesaplama.islenecekTutar / (1 + (localState.model.kdv! / 100)));

        }

      }, 100);

    }


    watch(() => [localState.model.hesaplama, localState.model.kdv, localState.model.referansId], () => {
      kdvHesaplama();
    }, { deep: true });

    async function hareketDelete() {
      if (
        localState.model &&
        localState.model.id &&
        localState.model.id.length > 0
      ) {
        if (await confirm("Kasa Hareketi Silinecek , Onaylıyor musunuz ? ", "Hareket Silme")) {
          await useKasaHareketService().deleteSil(localState.model.id);
          localState.showRef = false;
        }

      }
    }

    async function save() {
      await localState.utils.dekontKaydet(
        localState.menuModel,
        localState.model
      );
      emit("saveRefresh");
      localState.showRef = false;

    }

    async function saveAndPrint() {
      await localState.utils.dekontKaydet(
        localState.menuModel,
        localState.model
      );
      emit("print");
      localState.showRef = false;
    }

    function getKasaLabel() {
      if (localState.menuModel.hareketTipi == KasaHareketTip.KasadanVirmanTahsilati)
        return "Veren Kasa";
      if (localState.menuModel.hareketTipi == KasaHareketTip.KasayaVirmanOdeme)
        return "Alanıcak Kasa";
      if (localState.menuModel.hareketTipi == KasaHareketTip.KasadanDovizBozdurma)
        return "Veren Kasa";
      if (localState.menuModel.hareketTipi == KasaHareketTip.KasayaDovizAlma)
        return "Alanıcak Kasa";

      return "Kasa";
    }

    async function evrakTarihiChanged(e: any) {
        if (e && e.value && e.value.length > 0) {
            const kurlar = await useDovizKurService().getKurlarDate(e.value) as KurView[];
            if (kurlar && kurlar.length > 0)
                localState.kurlarRef = kurlar;
        }

        //console.log(e);
    }


    return {
      ...toRefs(localState),
      getKasaLabel,
      hareketDelete,
      save,
      saveAndPrint,
      load,
      evrakTarihiChanged
    };
  }
});
