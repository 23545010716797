import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    title: "Satış Fiyatını Değiştir",
    modelValue: _ctx.showRef,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showRef) = $event)),
    width: "500px"
  }, {
    body: _withCtx(() => [
      _createVNode(_component_NLoadingPanel, {
        title: "Yükleniyor...",
        action: _ctx.load
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NFormGrid, { cols: "1fr" }, {
            default: _withCtx(() => [
              _createVNode(_component_NFormField, {
                label: "Ad",
                "grid-cols": "100px auto 1fr"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NTag, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.stok.ad), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                label: "Kod",
                "grid-cols": "100px auto 1fr"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NTag, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.stok.kod), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                label: "Barkod",
                "grid-cols": "100px auto 1fr"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NTag, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.stok.barkod), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                label: "Satış Fiyatı",
                "grid-cols": "100px auto 1fr"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NTag, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.stok.satisFiyat), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                label: "Yeni Satış Fiyatı",
                "grid-cols": "100px 100px "
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxNumberBox, {
                    value: _ctx.fiyat,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fiyat) = $event)),
                    onInitialized: _ctx.initialized
                  }, null, 8, ["value", "onInitialized"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["action"])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_NButton, {
        icon: "x-circle",
        size: "small",
        type: "warning",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showRef = false))
      }, {
        default: _withCtx(() => [
          _createTextVNode("İptal")
        ]),
        _: 1
      }),
      _createVNode(_component_NButton, {
        icon: "save",
        size: "small",
        type: "success",
        click: _ctx.save
      }, {
        default: _withCtx(() => [
          _createTextVNode("Kaydet")
        ]),
        _: 1
      }, 8, ["click"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}