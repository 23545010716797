
import { defineComponent } from "vue";

export default defineComponent({
  name: "NActionTray",
  props: {
    selected: {
      type: Number
    },
    visible: {
      type: Boolean
    },
    label:{
      type:String,
      default:''
    }
  },
  setup(props, { emit }) {
    function close() {
      emit("update:visible", false);
      emit("close");
    }

    return {
      close
    };
  }
});
