
import {defineComponent, onMounted, reactive, ref, toRefs} from "vue";
import {useHizliSatisTabsStore} from "@/modules/HizliSatis/store/HizliSatisTabsStore";
import NViewGrid from "@/components/ViewGrid/NViewGrid.vue";
import HizliSatisTabs from "@/modules/HizliSatis/components/HizliSatisTabs.vue";
import {useSharedStore} from "@/store";
import HizliSatisToolbar from "@/modules/HizliSatis/components/HizliSatisToolbar.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";

export default defineComponent({
  components: {NLoadingPanel, HizliSatisToolbar,  NViewGrid},

  setup() {
    const localState = reactive({
      tabsStore:useHizliSatisTabsStore(),
      sharedStore:useSharedStore()

    })

    async function load(){
      await localState.tabsStore.load();
    }



    return {
      ...toRefs(localState),
      load
    }
  }
})
