
import {defineComponent, onMounted, reactive, ref, toRefs} from "vue";
import {useSharedStore} from "@/store";
import {useCariOnlineService} from "@/api/services/CariOnlineService";
import NCard from "@/components/Card/NCard.vue";
import {useNesilceConfig} from "@/api/NesilceConfig";
import NGrid from "@/components/Grid/NGrid.vue";
import NTag from "@/components/Tag/NTag.vue";
import NBadge from "@/components/Badge/NBadge.vue";
import {CariHareketEntity} from "@/api/models/CariHareketEntity";
import router from "@/router";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import {CariHareketTip, CariHareketTipList} from "@/api/models/CariHareketTip";
import NViewGrid from "@/components/ViewGrid/NViewGrid.vue";
import {FaturaDurumu} from "@/api/models/FaturaDurumu";
import {useFaturaService} from "@/api/services/FaturaService";
import {FaturaEFaturaItem} from "@/api/models/FaturaEFaturaItem";
import {EFaturaDurumu} from "@/api/models/EFaturaDurumu";
import {alert} from "devextreme/ui/dialog";
import {useIzibizEfaturaService} from "@/api/services/IzibizEfaturaService";


export default defineComponent({
  components: {NViewGrid, NLoadingPanel, NTag, NBadge, NCard, NGrid},
  props: {},
  setup() {

    const localState = reactive({
      hareketler: ref([] as CariHareketEntity[]),
      cariHareketTipList: CariHareketTipList,
      resizeCount: ref(0),
      formId: ref(Date.now().toString()),
      selectedFaturaId: ref(''),
      faturaDurumu: FaturaDurumu,
      cariHareketTip: CariHareketTip,
      root: ref('' as string),


    });

    onMounted(() => {
      window.addEventListener("resize", resizeDetector);
    });

    async function load() {
      localState.root = router.resolve("").params?.id as string;
      localState.hareketler = await useCariOnlineService().getEkstre(localState.root as string);
    }

    function calculateCustomSummary(options: any) {
      if (options.name === "tryBakiye") {
        if (options.summaryProcess === "start")
          options.totalValue = 0;
        if (options.summaryProcess === "calculate")
          options.totalValue += options.value.getTryBakiye;
      }

      if (options.name === "usdBakiye") {
        if (options.summaryProcess === "start")
          options.totalValue = 0;
        if (options.summaryProcess === "calculate")
          options.totalValue += options.value.getUsdBakiye;
      }

      if (options.name === "eurBakiye") {
        if (options.summaryProcess === "start")
          options.totalValue = 0;
        if (options.summaryProcess === "calculate")
          options.totalValue += options.value.getEurBakiye;
      }

      if (options.name === "yerelBakiye") {
        if (options.summaryProcess === "start")
          options.totalValue = 0;
        if (options.summaryProcess === "calculate")
          options.totalValue += options.value.getYerelBakiye;
      }
    }

    function isDesktop() {
      const screenWidth = Math.max(
          // document.body.scrollWidth,
          // document.documentElement.scrollWidth,
          document.body.offsetWidth,
          // document.documentElement.offsetWidth,
          // document.documentElement.clientWidth
      );

      //console.log("counted", screenWidth > 600, screenWidth)
      return screenWidth > 600;
    }

    function resizeDetector() {
      localState.resizeCount++;
    }

    async function getEfaturaDurumStatus(hashId: string, tip: CariHareketTip) {

      if (tip == CariHareketTip.Satis_Faturasi) {
        const result: FaturaEFaturaItem = await useFaturaService().getOnlineEFaturaStatus(localState.root, hashId)
        return {faturaId: result.eFaturaID, status: result.eFaturaStatus?.status === EFaturaDurumu.Sended}

      } else {
        return {faturaId: '', status: false}
      }
    }

    async function efaturaGoster(faturaIdEncrypted: string) {

      const response = await useIzibizEfaturaService().getGetPdfInvoiceEncrypted(faturaIdEncrypted)
      const response_raw = atob(response);
      const blob = new Blob(convertToByteArray(response_raw), {type: "application/pdf"});
      const fileURL = URL.createObjectURL(blob);
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", "E-Fatura İndir.pdf");
      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
      window.URL.revokeObjectURL(fileURL);


     //  const sonuc = await getEfaturaDurumStatus(referansHareketId, tip)
     // // console.log("sonuc", sonuc);
     //  if (sonuc?.faturaId && sonuc?.faturaId?.length > 0 && sonuc.status) {
     //
     //    localState.selectedFaturaId = sonuc?.faturaId;
     //
     //    const form = document.getElementById(localState.formId) as HTMLFormElement;
     //    const config = await useNesilceConfig().getConfig();
     //
     //    // form.action = `${config.API_URL}/api/IzibizEfatura/PrintSelected/${useSharedStore().firm.id}/${useSharedStore().firm.period}`;
     //    form.action = `${config.API_URL}/api/IzibizEfatura/PrintSelectedHash/${localState.root}`;
     //    form.submit();
     //  } else {
     //    alert("E-Faturanız Henüz hazır değildir.", "Bilgi")
     //  }

    }

    function convertToByteArray(input:any) {
      const sliceSize = 512;
      const bytes = [];

      for (let offset = 0; offset < input.length; offset += sliceSize) {
        const slice = input.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        bytes.push(byteArray);
      }

      return bytes;
    }

    return {
      ...toRefs(localState),
      calculateCustomSummary,
      load,
      getEfaturaDurumStatus,
      isDesktop,
      efaturaGoster
    };
  }
});
