
import {computed, defineComponent, onActivated, reactive, ref, toRefs, watch} from "vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import {OdemeTahsilatPlanView} from "@/api/models/OdemeTahsilatPlanView";
import ArrayStore from "devextreme/data/array_store";
import {CariHareketTipList} from "@/api/models/CariHareketTip";
import {DovizList} from "@/api/models/Doviz";
import {useCachedSubeDepoService} from "@/modules/Sube/services/useCachedSubeDepoService";
import {IdName} from "@/api/models/IdName";
import NBadge from "@/components/Badge/NBadge.vue";
import { useTaksitPlanService } from "@/api/services/TaksitPlanService";
import {DevExtremeUtils} from "@/devextreme/DevExtremeUtils";

export default defineComponent({
  components: {NBadge, NLoadingPanel, NGrid},
  props: {
    cariHareketTip: {
      type: Number,
    },
    cariId: {
      type: String
    }
  },

  setup(props, {emit}) {
    const localState = reactive({
      cariTaksitlerDataSource: new ArrayStore(),
      cariTaksitler: ref([] as OdemeTahsilatPlanView[]),
      cariHareketTipRef: computed(() => props.cariHareketTip),
      hareketTipleri: CariHareketTipList,
      dovizTipleri: DovizList,
      subeler: ref([] as IdName[]),
      cariHareketTipList:CariHareketTipList,
        cariTaksitlerDataGrid:ref()
    })

    async function load() {
      localState.subeler = await useCachedSubeDepoService().getSubeList();
      localState.cariTaksitler = await useTaksitPlanService().getCariIdByTaksitlerHareketTip(props.cariId as string , props.cariHareketTip as number);

    }
      onActivated(() => {
          DevExtremeUtils.updateScroll(localState.cariTaksitlerDataGrid);
      });


    return {
      ...toRefs(localState),
      load
    };
  }
});
