import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16dfcbe2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabsStore.tabs, (tab, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: i,
        class: _normalizeClass([tab.id === _ctx.tabsStore?.selectedTab?.id?'tab-item selected':'tab-item'])
      }, [
        _createElementVNode("div", {
          onClick: ($event: any) => (_ctx.tabsStore.selectTabById(tab.id))
        }, _toDisplayString(i+1), 9, _hoisted_2)
      ], 2))
    }), 128)),
    _createVNode(_component_NButton, {
      style: {"margin":"4px 12px 4px 16px"},
      size: "small",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.tabsStore.addTab())),
      icon: "plus",
      type: "info"
    }, {
      default: _withCtx(() => [
        _createTextVNode("Yeni Satış")
      ]),
      _: 1
    }),
    (_ctx.tabsStore.tabs.length > 1)
      ? (_openBlock(), _createBlock(_component_NButton, {
          key: 0,
          style: {"margin":"4px 0px 4px 4px"},
          size: "small",
          onClick: _ctx.removeActiveTab,
          icon: "x",
          type: "danger"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Satış " + _toDisplayString(_ctx.tabsStore.selectedIndex + 1) + " İptal Et", 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true)
  ]))
}