import { CariEntity } from "@/api/models/CariEntity";
import { useCariService } from "@/api/services/CariService";
import { onCariHubManagerSignal } from "@/api/signals/CariHubManagerSignal";
import { defineStore } from "pinia";

export const useCariCachedService = defineStore({
  state: () => ({
    cariListesi: [] as CariEntity[],
    service: useCariService(),
    isCached: false,

  }),
  id: "useCariCachedService",
  actions: {
    async loadTumListe() {
      if (!this.isCached) {
        // Service
        this.cariListSignal();
        await this.cariListRefresh();
        this.isCached = true;
      }
    },
    async cariListRefresh() {
      this.cariListesi = await this.service.getTumListe();
    },
    cariListSignal() {
      // Dinle
    }
  }
});
