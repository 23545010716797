import {FaturaView} from "@/api/models/FaturaView";
import FaturaSatirItemExtend from "@/modules/Fatura/models/FaturaSatirItemExtend";

import {KdvMatrahToplamlari} from "@/modules/Fatura/models/KdvMatrahToplamlari";
import { BirimToplamlari } from "@/modules/Fatura/models/BirimToplamlari";
import _ from "lodash";


export default class FaturaViewExtend extends FaturaView {
    constructor(faturaView?: FaturaView) {
        super();
        if (faturaView) {
            this.status = faturaView.status ;
            this.dokumanlar = faturaView.dokumanlar;
            this.tip = faturaView.tip;
            this.faturaTip = faturaView.faturaTip;
            this.teslimatAdresi = faturaView.teslimatAdresi;
            this.ustBilgi = faturaView.ustBilgi;

            this.satirlar = [];
            this.tip = faturaView.tip;
            if (faturaView.satirlar && faturaView.satirlar?.length > 0) {
                for (let i = 0; i < faturaView.satirlar?.length; i++) {
                    if (faturaView.satirlar) {
                        const faturaSatir = new FaturaSatirItemExtend(
                            faturaView.satirlar[i]
                        );
                        this.satirlar.push(faturaSatir);
                    }
                }
            }

            this.id = faturaView.id;
            this.eFatura = faturaView.eFatura;
            this.efaturaGonder = faturaView.efaturaGonder;
            this.isFatura = faturaView.isFatura;
            this.gonder = faturaView.gonder;
            this.satisFiyatGuncelle = faturaView.satisFiyatGuncelle;
            this.ekMasraf = faturaView.ekMasraf ;
            this.faturaMaliyet = faturaView.faturaMaliyet ;
            this.altBilgi = faturaView.altBilgi ;


        }
    }

    // getSatirBrutToplam getSatirIskontoTutar getAltIskontoTutar getIskontoluTutar getKdvTutar getKdvliTutar getKdvTevkifatTutar getKdvTevkifatliTutar

    getSatirBrutToplam(): number {
        return (this.satirlar as FaturaSatirItemExtend[])?.reduce((x, satir: any) => x + satir.getSatirBrutToplamExtend(), 0)
    }

    getSatirIskontoTutar(): number {
        return (this.satirlar as FaturaSatirItemExtend[])?.reduce((x, satir: any) => x + satir.getSatirIskontoTutarExtend(), 0)
    }

    getAltIskontoTutar(): number {
        return (this.satirlar as FaturaSatirItemExtend[])?.reduce((x, satir: any) => x + satir.getAltIskontoTutarExtend(), 0)
    }

    getIskontoluTutar(): number {
        return (this.satirlar as FaturaSatirItemExtend[])?.reduce((x, satir: any) => x + satir.getIskontoluTutarExtend(), 0)
    }

    getKdvTutar(): number {
        return (this.satirlar as FaturaSatirItemExtend[])?.reduce((x, satir: any) => x + satir.getKdvTutarExtend(), 0)
    }

    getKdvliTutar(): number {
        return (this.satirlar as FaturaSatirItemExtend[])?.reduce((x, satir: any) => x + satir.getKdvliTutarExtend() , 0)
    }

    getKdvTevkifatTutar(): number {

        return (this.satirlar as FaturaSatirItemExtend[])?.reduce((x, satir: any) => x + satir.getKdvTevkifatTutarExtend(), 0)
    }

    getKdvTevkifatliTutar(): number {
        return (this.satirlar as FaturaSatirItemExtend[])?.reduce((x, satir: any) => x + satir.getKdvTevkifatliTutarExtend(), 0)
    }
    getOdenecekIhracKayitliTutar() {
        return this.getIskontoluTutar()
    }

    getOdenecekIhracKayitliDovizTutar(cariIslemKur:number){


        return  this.getIskontoluTutar() / cariIslemKur ;
    }
    getOdenecekTutar() {
        return this.getIskontoluTutar() + this.getKdvTutar() - this.getKdvTevkifatTutar();
    }

    getOdenecekDovizTutar(cariIslemKur:number){


        return ( this.getIskontoluTutar() + this.getKdvTutar() - this.getKdvTevkifatTutar()) / cariIslemKur ;
    }
    getKalanTutar(){
       const faturaTutar =  this.getOdenecekDovizTutar(this.altBilgi!.cariIslemKur as number);

        const odenecekTutar =
          this.altBilgi!.kasaTutar as number +
          (this.altBilgi!.posTutar as number) +
          (this.altBilgi!.havaleTutar as number);

        return faturaTutar - odenecekTutar ;
    }




    // Helpers

    altIskontoOranBul(toplamSatirIskontoluTutar: number, altIskontoTutar: number, iskontolar: number[]) {
        const toplamSatirIskontoluTutarDeger = toplamSatirIskontoluTutar;
        let tutar = 0;
        iskontolar.forEach(iskonto => {
            tutar += toplamSatirIskontoluTutar * iskonto / 100;
            toplamSatirIskontoluTutar -= tutar;
        })

        const sonuc = tutar + altIskontoTutar;


        return 100 * sonuc / toplamSatirIskontoluTutarDeger;
    }

    setFaturaAltiIskonto() {

            const oran = this.altIskontoOranBul(this.getSatirBrutToplam() - this.getSatirIskontoTutar(), this.altBilgi!.tutarIskonto!, this.altBilgi!.altIskontolar!)
            this.satirlar?.forEach(satir => {
                satir.altIskontoOran = oran;
            })

    }

    setFaturaAltiIskontoSifirla() {
        if (this.altBilgi && this.altBilgi.altIskontolar){
            const oran = this.altIskontoOranBul(this.getSatirBrutToplam() - this.getSatirIskontoTutar(), 0, this.altBilgi.altIskontolar!)
            this.satirlar?.forEach(satir => {
                satir.altIskontoOran = oran;
            })
        }
    }
    // kdv matrahlarını ayrı ayrı hesaplama
    getBirimTekTekHesapla():BirimToplamlari[]{
        const birimToplamlari =[] as BirimToplamlari[];

        const birimler = this.satirlar?.map(a=>a.birim);
        const uniqBirim = [...new Set(birimler)];

        if (uniqBirim && uniqBirim.length > 0) {

            uniqBirim.forEach(birim => {
                if (birim && birim.length > 0 ){
                    const miktar = _.sum(this.satirlar?.filter(a=>a.birim == birim ).map((satir: any) => (satir.miktar * satir.carpan)));

                    birimToplamlari.push({
                        birim : birim,
                        miktar :miktar,
                    });
                }
            })

        }
        return birimToplamlari ;
    }
    getKdvTekTekHesapla():KdvMatrahToplamlari[] {
        const kdvToplamlari =[] as  KdvMatrahToplamlari[];
        const kdvler = this.satirlar?.map(a => a.kdv);
        const uniqKdv = [...new Set(kdvler)];
        if (uniqKdv && uniqKdv.length > 0) {

            uniqKdv.forEach(kdv=>{
                let kdvToplam =  0;
                let kdvMatrahToplam =  0;
                const satir = this.satirlar?.filter(a=>a.kdv == kdv) as FaturaSatirItemExtend[];
                satir.forEach(a => {
                    kdvToplam += a.getKdvTutarExtend()  - a.getKdvTevkifatTutarExtend();
                    kdvMatrahToplam += a.getIskontoluTutarExtend() - a.getAltIskontoTutarExtend();
                } );

                kdvToplamlari.push({
                    kdv : kdv,
                    kdvTutar : kdvToplam,
                    matrah : kdvMatrahToplam,
                });
            });
        }
        return kdvToplamlari;
    }


}
