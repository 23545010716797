import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HizliSatisOdeme = _resolveComponent("HizliSatisOdeme")!
  const _component_HizliSatisGenel = _resolveComponent("HizliSatisGenel")!
  const _component_NCard = _resolveComponent("NCard")!

  return (_openBlock(), _createBlock(_component_NCard, {
    modelValue: _ctx.selectedTab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTab) = $event)),
    tabs: [{id:'0', label:'Ödeme' }, {id:'1', label:'Genel'}, {id:'2', label:'E-Fatura'}]
  }, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_HizliSatisOdeme, null, null, 512), [
        [_vShow, _ctx.selectedTab === 0]
      ]),
      (_ctx.selectedTab === 1)
        ? (_openBlock(), _createBlock(_component_HizliSatisGenel, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}