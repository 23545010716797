import {Doviz, DovizList} from "@/api/models/Doviz";
import {GenelResmiMuhasebeTipList} from "@/api/models/GenelResmiMuhasebeTip";

export const HareketUtils = {

    formatDateTime(a: string) {
        return new Date(Date.parse(a)).formatDateTime();
    },
    formatDate(a: string) {
        return new Date(Date.parse(a)).formatDate();
    },
    formatCurrency(a: number) {
        return a.format(2, 2);
    },
    formatCurrencyZero(a: number) {
        return a.format(0, 0);
    },
    getEvrakTipDescription(tip: number) {
        const result = GenelResmiMuhasebeTipList.find(a => a.value === tip)
        if (result) return result.description
        return '--YOK--';
    },
    getDovizDescription(doviz: number): string {
        const result = DovizList.find(a => a.value === doviz)
        if (result) return result.description
        return '--YOK--';
    },
    getDovizKey(doviz: number): string {
        const result = DovizList.find(a => a.value == doviz)
        if (result) return result.key
        return '--YOK--';
    }


}
