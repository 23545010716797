import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import { MenuFormType } from "@/modules/Ortak/models/Menu/MenuFormType";
import { MenuRefrenceType } from "@/modules/Ortak/models/Menu/MenuRefrenceType";
import { CariHareketTip } from "@/api/models/CariHareketTip";

export const CariMenuListesi: MenuModel[] = [
    {
        name: "Alış",
        buttonStyle:"danger",
        // hareketTipi: CariHareketTip.Alis_Faturasi,
        // formType: MenuFormType.Fatura_Form,
        // refrenceType: MenuRefrenceType.Kullanilmaz,
        childs: [
            {
                name: "Alış Fatura",
                hareketTipi: CariHareketTip.Alis_Faturasi,
                formType: MenuFormType.Fatura_Form,
                refrenceType: MenuRefrenceType.Kullanilmaz,

            },
            {
                name: "Teklif",
                formType: MenuFormType.Siparis_Form,
                hareketTipi:  CariHareketTip.Alis_Teklifi,
                refrenceType : MenuRefrenceType.Kullanilmaz,

            },
            {
                name: "Sipariş",
                formType: MenuFormType.Siparis_Form,
                hareketTipi: CariHareketTip.Alis_Siparisi,
                refrenceType : MenuRefrenceType.Kullanilmaz,

            },
            {
                name: "İrsaliye",
                formType: MenuFormType.Fatura_Form,
                hareketTipi: CariHareketTip.Alis_Irsaliyesi,
                refrenceType: MenuRefrenceType.Kullanilmaz
            },
            {
                name: "Kur Farkı",
                formType: MenuFormType.Fatura_Form,
                hareketTipi: CariHareketTip.Kur_Farki_Alis_Faturasi,
                refrenceType: MenuRefrenceType.Kullanilmaz
            },
            {
                name: "Müstahsil",
                formType: MenuFormType.Fatura_Form
            },
            {
                name: "Gider Pusulası",
                formType: MenuFormType.Fatura_Form
            },
            {
                name: "Serbest Meslek Makbuzu",
                formType: MenuFormType.Fatura_Form
            },
            {
                name: "Konsinye",
                formType: MenuFormType.Fatura_Form
            },
            {
                name: "Demirbaş",
                formType: MenuFormType.Fatura_Form
            },
            {
                name: "Satıştan Iade",
                formType: MenuFormType.Fatura_Form,
                refrenceType: MenuRefrenceType.Kullanilmaz,
                hareketTipi: CariHareketTip.Satis_Iade_Faturasi_AlisFaturasi,
            },
            {
                name: "Sac İrsaliye",
                hareketTipi: CariHareketTip.Alis_Irsaliyesi,
                formType: MenuFormType.Fatura_Form,
                refrenceType: MenuRefrenceType.Sac,

            },

        ]
    },
    {
        name: "Satış",
        buttonStyle:"success",
        // hareketTipi: CariHareketTip.Satis_Faturasi,
        // formType: MenuFormType.Fatura_Form,
        // refrenceType: MenuRefrenceType.Kullanilmaz,
        childs: [
            {
                name: "Satış Fatura",
                hareketTipi: CariHareketTip.Satis_Faturasi,
                formType: MenuFormType.Fatura_Form,
                refrenceType: MenuRefrenceType.Kullanilmaz,
            },
            {
                name: "Teklif",
                formType: MenuFormType.Siparis_Form,
                hareketTipi: CariHareketTip.Satis_Teklifi,
                refrenceType : MenuRefrenceType.Kullanilmaz,
            },
            {
                name: "Sipariş",
                formType: MenuFormType.Siparis_Form,
                hareketTipi: CariHareketTip.Satis_Siparisi,
                refrenceType : MenuRefrenceType.Kullanilmaz,
            },
            {
                name: "İrsaliye",
                formType: MenuFormType.Fatura_Form,
                hareketTipi: CariHareketTip.Satis_Irsaliyesi,
                refrenceType: MenuRefrenceType.Kullanilmaz
            },
            {
                name: "Kur Farkı",
                formType: MenuFormType.Fatura_Form,
                hareketTipi: CariHareketTip.Kur_Farki_Faturamiz,
                refrenceType: MenuRefrenceType.Kullanilmaz
            },
            {
                name: "Müstahsil",
                formType: MenuFormType.Fatura_Form
            },
            {
                name: "Gider Pusulası",
                formType: MenuFormType.Fatura_Form
            },
            {
                name: "Serbest Meslek Makbuzu",
                formType: MenuFormType.Fatura_Form
            },
            {
                name: "Konsinye",
                formType: MenuFormType.Fatura_Form,
                hareketTipi: CariHareketTip.Konsinye_Satis,
                refrenceType: MenuRefrenceType.Kullanilmaz
            },
            {
                name: "Demirbaş",
                formType: MenuFormType.Fatura_Form
            },
            {
                name: "Alış İademiz",
                formType: MenuFormType.Fatura_Form,
                refrenceType: MenuRefrenceType.Kullanilmaz,
                hareketTipi: CariHareketTip.Alis_Iade_Faturasi_Satis_Faturamiz,
            }
        ]
    },
    {
        name: "Tahsilat",
        // hareketTipi: CariHareketTip.Cari_Nakit_Tahsilat,
        // formType: MenuFormType.Cari_Dekont_Form,
        // refrenceType: MenuRefrenceType.Kasa,
        childs: [
            {
                name: "Nakit Alma",
                formType: MenuFormType.Cari_Dekont_Form,
                refrenceType: MenuRefrenceType.Kasa,
                hareketTipi: CariHareketTip.Cari_Nakit_Tahsilat
            },
            {
                name: "Havale Alma",
                formType: MenuFormType.Cari_Dekont_Form,
                refrenceType: MenuRefrenceType.Banka,
                hareketTipi: CariHareketTip.Cari_Havale_Alma
            },
            {
                name: "Pos Cekme",
                formType: MenuFormType.Cari_Dekont_Form,
                refrenceType: MenuRefrenceType.Pos,
                hareketTipi: CariHareketTip.Cari_Pos_Cekme
            },
            {
                name: "Müşteriden Senet Alma",
                formType: MenuFormType.MusteriCekSenetForm,
                hareketTipi:  CariHareketTip.Cari_Senet_Alma,
            },
            {
                name: "Müşteriden Çek Alma",
                formType: MenuFormType.MusteriCekSenetForm,
                hareketTipi:  CariHareketTip.Cari_Cek_Alma,
            },
            {
                name: "Mail Order Çekme",
                formType: MenuFormType.Cari_Dekont_Form,
                refrenceType: MenuRefrenceType.CariMailOrder,
                hareketTipi: CariHareketTip.MailOrderCekme
            },
            {
                name: "Alacak Dekontu",
                formType: MenuFormType.Cari_Dekont_Form,
                refrenceType: MenuRefrenceType.Kullanilmaz,
                hareketTipi: CariHareketTip.Cari_Alacak_Dekontu
            },
            {
                name: "Çoklu Tahsilat",
                formType: MenuFormType.Cari_Dekont_Form
            },
            {
                name: "Virman",
                formType: MenuFormType.Cari_Dekont_Form,
                refrenceType: MenuRefrenceType.Cari,
                hareketTipi: CariHareketTip.Cari_Virman_Borc
            },
            {
                name: "Borç Taksit Yapılandırması",
                formType: MenuFormType.Cari_Dekont_Form,
                refrenceType: MenuRefrenceType.Kullanilmaz,
                hareketTipi: CariHareketTip.BorcYapilandirma,
            },
            {
                name: "Online Tahsilat",
                formType: MenuFormType.OnlineTahsilat,
                refrenceType: MenuRefrenceType.Cari,
                hareketTipi: CariHareketTip.Cari_Pos_Cekme,
            }
        ]
    },
    {
        name: "Ödeme",
        // refrenceType: MenuRefrenceType.Kasa,
        // hareketTipi: CariHareketTip.Cari_Nakit_Odeme,
        // formType: MenuFormType.Cari_Dekont_Form,
        childs: [
            {
                name: "Nakit Verme",
                formType: MenuFormType.Cari_Dekont_Form,
                refrenceType: MenuRefrenceType.Kasa,
                hareketTipi: CariHareketTip.Cari_Nakit_Odeme
            },
            {
                name: "Havale / EFT Gönderme",
                formType: MenuFormType.Cari_Dekont_Form,
                refrenceType: MenuRefrenceType.Banka,
                hareketTipi: CariHareketTip.Cari_Havale_Gonderme
            },
            {
                name: "Firma Senedi Verme",
                formType: MenuFormType.FirmaCekSenetForm,
                hareketTipi:  CariHareketTip.Cariye_Firma_Senedimizi_Verme,
            },
            {
                name: "Firma Çeki Verme",
                formType: MenuFormType.FirmaCekSenetForm,
                hareketTipi:  CariHareketTip.Cariye_Firma_Cekimizi_Verme,
            },
            {
                name: "DBS Verme",
                formType: MenuFormType.FirmaCekSenetForm
            },
            {
                name: "Firma Kredi Kartımız ile Ödeme",
                formType: MenuFormType.Cari_Dekont_Form,
                refrenceType: MenuRefrenceType.Kredi_Karti,
                hareketTipi: 130
            },
            {
                name: "Borç Dekontu",
                formType: MenuFormType.Cari_Dekont_Form,
                refrenceType: MenuRefrenceType.Kullanilmaz,
                hareketTipi: CariHareketTip.Cari_Borc_Dekontu
            },
            {
                name: "Çoklu Ödeme",
            },
            {
                name: "Virman",
                formType: MenuFormType.Cari_Dekont_Form,
                refrenceType: MenuRefrenceType.Cari,
                hareketTipi: CariHareketTip.Cari_Virman_Alacak
            },
            {
                name: "Alacak Taksit Yapılandırması",
                formType: MenuFormType.Cari_Dekont_Form,
                refrenceType: MenuRefrenceType.Kullanilmaz,
                hareketTipi: CariHareketTip.AlacakYapilandirma,
            }
        ]
    },
    {
        name: "Devir",
        childs: [
            {
                name: "Alacak Devri",
                formType: MenuFormType.Cari_Dekont_Form,
                refrenceType: MenuRefrenceType.Kullanilmaz,
                hareketTipi: CariHareketTip.Cari_Alacak_Devir
            },
            {
                name: "Borç Devri",
                formType: MenuFormType.Cari_Dekont_Form,
                refrenceType: MenuRefrenceType.Kullanilmaz,
                hareketTipi: CariHareketTip.Cari_Borc_Devir
            }
        ]
    }
];
