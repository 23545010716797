import mitt, { Handler } from 'mitt';
import { defineStore } from 'pinia';
import { useSignal } from '@/services/useSignal';
//signal document content
import {RecordStatus} from '../models/RecordStatus'
import {Doviz} from '../models/Doviz'
import {PluKod} from '../models/PluKod'
import {StokItem} from '../models/StokItem'
import {StokBirimItem} from '../models/StokBirimItem'
import {IdName} from '../models/IdName'
import {Ozellik} from '../models/Ozellik'
import {OzellikVaryant} from '../models/OzellikVaryant'
import {SubeFiyatItem} from '../models/SubeFiyatItem'
import {StokUreticiItem} from '../models/StokUreticiItem'
import {StokLanguageItem} from '../models/StokLanguageItem'
import {N11PazaryeriItem} from '../models/N11PazaryeriItem'
import {TrendyolPazaryeriItem} from '../models/TrendyolPazaryeriItem'
import {HepsiBuradaPazaryeriItem} from '../models/HepsiBuradaPazaryeriItem'
import {HepsiBuradaListingPazaryeriItem} from '../models/HepsiBuradaListingPazaryeriItem'
import {AliExpressPazaryeriItem} from '../models/AliExpressPazaryeriItem'
import {EpttAvmPazaryeriItem} from '../models/EpttAvmPazaryeriItem'
import {PazaramaPazaryeriItem} from '../models/PazaramaPazaryeriItem'
import {PrestaPazaryeriItem} from '../models/PrestaPazaryeriItem'
import {CicekSepetiPazaryeriItem} from '../models/CicekSepetiPazaryeriItem'
import {StokUyumluluktem} from '../models/StokUyumluluktem'
import {StokTipOzellikler} from '../models/StokTipOzellikler'
import {StokTip} from '../models/StokTip'
import {StokMiktarOzetItem} from '../models/StokMiktarOzetItem'
import {StokAnalizItem} from '../models/StokAnalizItem'
import {StokEntity} from '../models/StokEntity'
import {FlatMiktarOzet} from '../models/FlatMiktarOzet'
import {StokFlatView} from '../models/StokFlatView'

export const onStokHubManagerSignal = defineStore({
id: 'StokHubManagerSignal',
actions: {


	Added(handler:Handler<StokEntity>)
	{
		useSignal().on('StokHubManager.Added',handler);
	},

	AddedFlats(handler:Handler<StokFlatView>)
	{
		useSignal().on('StokHubManager.AddedFlats',handler);
	},

	AddedFlat(handler:Handler<StokFlatView>)
	{
		useSignal().on('StokHubManager.AddedFlat',handler);
	},

	Changed(handler:Handler<StokEntity>)
	{
		useSignal().on('StokHubManager.Changed',handler);
	},

	ChangedSimple(handler:Handler<StokEntity>)
	{
		useSignal().on('StokHubManager.ChangedSimple',handler);
	},

	ChangedFlats(handler:Handler<StokFlatView>)
	{
		useSignal().on('StokHubManager.ChangedFlats',handler);
	},

	ChangedFlat(handler:Handler<StokFlatView>)
	{
		useSignal().on('StokHubManager.ChangedFlat',handler);
	},

	Changeds(handler:Handler<StokEntity>)
	{
		useSignal().on('StokHubManager.Changeds',handler);
	},

	Deleted(handler:Handler<string>)
	{
		useSignal().on('StokHubManager.Deleted',handler);
	},

	DeletedFlat(handler:Handler<string>)
	{
		useSignal().on('StokHubManager.DeletedFlat',handler);
	},

	DeletedFlats(handler:Handler<StokFlatView>)
	{
		useSignal().on('StokHubManager.DeletedFlats',handler);
	}
}
});
