import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dx-card",
  style: {"height":"100%"}
}
const _hoisted_2 = { class: "ustTutar" }
const _hoisted_3 = { class: "altTutar" }
const _hoisted_4 = { style: {"font-size":"10px"} }
const _hoisted_5 = { class: "ustTutar" }
const _hoisted_6 = { class: "altTutar" }
const _hoisted_7 = { style: {"font-size":"10px"} }
const _hoisted_8 = { class: "ustTutar" }
const _hoisted_9 = {
  key: 0,
  class: "altTutar"
}
const _hoisted_10 = { style: {"font-size":"10px"} }
const _hoisted_11 = { class: "ustTutar" }
const _hoisted_12 = {
  key: 0,
  class: "altTutar"
}
const _hoisted_13 = { style: {"font-size":"10px"} }
const _hoisted_14 = { class: "ustTutar" }
const _hoisted_15 = {
  key: 0,
  class: "altTutar"
}
const _hoisted_16 = { style: {"font-size":"10px"} }
const _hoisted_17 = { class: "ustTutar" }
const _hoisted_18 = {
  key: 0,
  class: "altTutar"
}
const _hoisted_19 = { style: {"font-size":"10px"} }
const _hoisted_20 = {
  key: 1,
  class: "dx-card",
  style: {"height":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxLoadPanel = _resolveComponent("DxLoadPanel")!
  const _component_DxScrolling = _resolveComponent("DxScrolling")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxColumnChooser = _resolveComponent("DxColumnChooser")!
  const _component_DxStateStoring = _resolveComponent("DxStateStoring")!
  const _component_NDxStateStoring = _resolveComponent("NDxStateStoring")!
  const _component_DxSearchPanel = _resolveComponent("DxSearchPanel")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxTotalItem = _resolveComponent("DxTotalItem")!
  const _component_DxSummary = _resolveComponent("DxSummary")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NCard = _resolveComponent("NCard")!
  const _component_SacUretimTalimatFormDialog = _resolveComponent("SacUretimTalimatFormDialog")!
  const _component_SacProfilUretimTalimatFormDialog = _resolveComponent("SacProfilUretimTalimatFormDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NCard, {
      tabs: _ctx.tabs,
      modelValue: _ctx.selectedTab,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedTab) = $event)),
      row: "minmax(0,1fr)",
      style: {"min-height":"580px","height":"100%"}
    }, {
      default: _withCtx(() => [
        (_ctx.selectedTab !== 6)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_DxDataGrid, {
                "data-source": _ctx.stokHareketListesi,
                ref: "stokHareketleriListesiDataGrid",
                selection: { mode: 'single' },
                "row-alternation-enabled": true,
                "allow-column-reordering": true,
                "allow-column-resizing": true,
                "column-resizing-mode": "widget",
                "show-row-lines": true,
                "show-borders": true,
                toolbarTemplate: "toolbar",
                onRowDblClick: _cache[0] || (_cache[0] = (e)=>_ctx.rowDblClick(e.data)),
                style: {"height":"100%","width":"100%"},
                "key-expr": "id"
              }, {
                toolbar: _withCtx(() => [
                  _createVNode(_component_NDxStateStoring, { "data-grid-ref": _ctx.stokHareketleriListesiDataGrid }, null, 8, ["data-grid-ref"])
                ]),
                guncelUSDFiyatTemplate: _withCtx(({data}) => [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_2, _toDisplayString(data.data.guncelUSDFiyat.format(2, 2)) + " $ ", 1),
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("span", _hoisted_4, "( Kur : " + _toDisplayString(data.data.guncelUSDKur.format(2, 3)) + " )", 1)
                    ])
                  ])
                ]),
                guncelEURFiyatTemplate: _withCtx(({data}) => [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(data.data.guncelEURFiyat.format(2, 2)) + " € ", 1),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("span", _hoisted_7, "( Kur : " + _toDisplayString(data.data.guncelEURKur.format(2, 3)) + " )", 1)
                    ])
                  ])
                ]),
                netFiyatTemplate: _withCtx(({data}) => [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(data.data.getDovizNetFiyat.format(2, 2)) + " ₺ ", 1),
                    (data.data.doviz !== 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createTextVNode(_toDisplayString(data.data.getNetFiyat.format(2, 2)) + " " + _toDisplayString(_ctx.dovizListRef.find(a => a.value == data.data.doviz)?.description) + " ", 1),
                          _createElementVNode("span", _hoisted_10, "( " + _toDisplayString(data.data.kur.format(2, 3)) + " )", 1)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                netFiyatKdvliTemplate: _withCtx(({data}) => [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(data.data.getDovizNetFiyatKdvDahil.format(2, 2)) + " ₺ ", 1),
                    (data.data.doviz !== 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createTextVNode(_toDisplayString(data.data.getNetFiyatKdvDahil.format(2, 2)) + " " + _toDisplayString(_ctx.dovizListRef.find(a => a.value == data.data.doviz)?.description) + " ", 1),
                          _createElementVNode("span", _hoisted_13, "( " + _toDisplayString(data.data.kur.format(2, 3)) + " )", 1)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                getNetFiyatToplamTutarTemplate: _withCtx(({data}) => [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_14, _toDisplayString(data.data.getDovizNetFiyatToplamTutar.format(2, 2)) + " ₺ ", 1),
                    (data.data.doviz !== 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                          _createTextVNode(_toDisplayString((data.data.getNetFiyatToplamTutar).format(2, 2)) + " " + _toDisplayString(_ctx.dovizListRef.find(a => a.value == data.data.doviz)?.description) + " ", 1),
                          _createElementVNode("span", _hoisted_16, "( " + _toDisplayString(data.data.kur.format(2, 3)) + " )", 1)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                getNetFiyatKdvDahilToplamTutarTemplate: _withCtx(({data}) => [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_17, _toDisplayString(data.data.getDovizNetFiyatKdvDahilToplamTutar.format(2, 2)) + " ₺ ", 1),
                    (data.data.doviz !== 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                          _createTextVNode(_toDisplayString((data.data.getNetFiyatKdvDahilToplamTutar).format(2, 2)) + " " + _toDisplayString(_ctx.dovizListRef.find(a => a.value == data.data.doviz)?.description) + " ", 1),
                          _createElementVNode("span", _hoisted_19, "( " + _toDisplayString(data.data.kur.format(2, 3)) + " )", 1)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" > "),
                  _createVNode(_component_DxLoadPanel, { enabled: true }),
                  _createVNode(_component_DxScrolling, { mode: "infinite" }),
                  _createVNode(_component_DxHeaderFilter, { visible: true }),
                  _createVNode(_component_DxFilterRow, { visible: true }),
                  _createVNode(_component_DxColumnChooser, {
                    enabled: true,
                    mode: "select"
                  }),
                  _createVNode(_component_DxStateStoring, {
                    enabled: true,
                    type: "custom",
                    "custom-load": () =>  _ctx.loadStateHareketler()
                  }, null, 8, ["custom-load"]),
                  _createVNode(_component_DxSearchPanel, {
                    visible: true,
                    width: 240,
                    placeholder: "Arama..."
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "120",
                    "data-field": "tip",
                    caption: "Tip",
                    "calculate-cell-value": (s) => _ctx.stokHareketTipList.find(a=>a.value == s.tip)?.description
                  }, null, 8, ["calculate-cell-value"]),
                  _createVNode(_component_DxColumn, {
                    width: "100",
                    "data-field": "tarih",
                    caption: "Tarih",
                    "data-type": "date"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "80",
                    "data-field": "evrakNo",
                    caption: "Evrak No"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "180",
                    "data-field": "cari.name",
                    caption: "Cari"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "80",
                    "data-field": "miktar",
                    caption: "Miktar",
                    "data-type": "number",
                    format: "#,##0.00"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "80",
                    "data-field": "getDovizNetFiyat",
                    caption: "Net Fiyat",
                    "data-type": "number",
                    format: "#,##0.00",
                    cellTemplate: "netFiyatTemplate"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "80",
                    "data-field": "getDovizNetFiyatKdvDahil",
                    caption: "Net Fiyat (%)",
                    "data-type": "number",
                    format: "#,##0.00",
                    cellTemplate: "netFiyatKdvliTemplate"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "80",
                    "data-field": "getDovizNetFiyatToplamTutar",
                    caption: "Toplam Tutar",
                    "data-type": "number",
                    format: "#,##0.00",
                    cellTemplate: "getNetFiyatToplamTutarTemplate"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "80",
                    "data-field": "getDovizNetFiyatKdvDahilToplamTutar",
                    caption: "Toplam Tutar (%)",
                    "data-type": "number",
                    format: "#,##0.00",
                    cellTemplate: "getNetFiyatKdvDahilToplamTutarTemplate"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "100",
                    "data-field": "guncelUSDFiyat",
                    caption: "USD Fiyat",
                    "data-type": "number",
                    format: "#,##0.00",
                    cellTemplate: "guncelUSDFiyatTemplate"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "100",
                    "data-field": "guncelEURFiyat",
                    caption: "EUR Fiyat",
                    "data-type": "number",
                    format: "#,##0.00",
                    cellTemplate: "guncelEURFiyatTemplate"
                  }),
                  _createVNode(_component_DxSummary, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_DxTotalItem, {
                        column: "miktar",
                        "summary-type": "sum",
                        "value-format": (a) => a.format(2,2),
                        "display-format": "{0}"
                      }, null, 8, ["value-format"]),
                      _createVNode(_component_DxTotalItem, {
                        column: "tarih",
                        "summary-type": "count",
                        "display-format": "Top: {0} "
                      }),
                      _createVNode(_component_DxTotalItem, {
                        column: "getDovizNetFiyat",
                        "summary-type": "sum",
                        "value-format": (a) => a.format(2,2),
                        "display-format": "₺ {0}"
                      }, null, 8, ["value-format"]),
                      _createVNode(_component_DxTotalItem, {
                        column: "getDovizNetFiyatKdvDahil",
                        "summary-type": "sum",
                        "value-format": (a) => a.format(2,2),
                        "display-format": "₺ {0}"
                      }, null, 8, ["value-format"]),
                      _createVNode(_component_DxTotalItem, {
                        column: "getDovizNetFiyatToplamTutar",
                        "summary-type": "sum",
                        "value-format": (a) => a.format(2,2),
                        "display-format": "₺ {0}"
                      }, null, 8, ["value-format"]),
                      _createVNode(_component_DxTotalItem, {
                        column: "getDovizNetFiyatKdvDahilToplamTutar",
                        "summary-type": "sum",
                        "value-format": (a) => a.format(2,2),
                        "display-format": "₺ {0}"
                      }, null, 8, ["value-format"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["data-source"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedTab === 6  )
          ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
              _createVNode(_component_DxDataGrid, {
                "data-source": _ctx.tumStokHareketleri,
                ref: "tumStokHareketleriListesiDataGrid",
                selection: { mode: 'single' },
                "row-alternation-enabled": true,
                "allow-column-reordering": true,
                "allow-column-resizing": true,
                "column-resizing-mode": "widget",
                toolbarTemplate: "toolbar",
                "show-row-lines": true,
                "show-borders": true,
                style: {"height":"100%","width":"100%"},
                onRowDblClick: _cache[1] || (_cache[1] = (e)=>_ctx.rowDblClick(e.data)),
                "key-expr": "id"
              }, {
                toolbar: _withCtx(() => [
                  _createVNode(_component_NGrid, { cols: "auto auto" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NDxStateStoring, { "data-grid-ref": _ctx.tumStokHareketleriListesiDataGrid }, null, 8, ["data-grid-ref"]),
                      _createVNode(_component_NButton, {
                        icon: "refresh-cw",
                        click: _ctx.load
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Yenile")
                        ]),
                        _: 1
                      }, 8, ["click"])
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_DxLoadPanel, { enabled: true }),
                  _createVNode(_component_DxScrolling, { mode: "virtual" }),
                  _createVNode(_component_DxHeaderFilter, { visible: true }),
                  _createVNode(_component_DxFilterRow, { visible: true }),
                  _createVNode(_component_DxColumnChooser, {
                    enabled: true,
                    mode: "select"
                  }),
                  _createVNode(_component_DxStateStoring, {
                    enabled: true,
                    type: "custom",
                    "custom-load": () =>  _ctx.loadStateTumHareketler()
                  }, null, 8, ["custom-load"]),
                  _createVNode(_component_DxSearchPanel, {
                    visible: true,
                    width: 240,
                    placeholder: "Arama..."
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "120",
                    "data-field": "tarih",
                    caption: "Tarih",
                    "data-type": "date"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "100",
                    "data-field": "kod",
                    caption: "Stok Kodu",
                    "data-type": "string"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "160",
                    "data-field": "stok.name",
                    caption: "Stok",
                    "data-type": "string"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "120",
                    "data-field": "evrakNo",
                    caption: "Evrak No"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "120",
                    "data-field": "cari.name",
                    caption: "Cari"
                  }),
                  _createVNode(_component_DxColumn, {
                    cssClass: "girenMiktar",
                    width: "80",
                    "data-field": "girenMiktar",
                    caption: "Giren",
                    "data-type": "number",
                    format: (d) => d.format(2,2)
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    cssClass: "cikanMiktar",
                    width: "80",
                    "data-field": "cikanMiktar",
                    caption: "Çıkan",
                    "data-type": "number",
                    format: (d) => d.format(2,2)
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    cssClass: "bakiye",
                    width: "80",
                    "data-field": "bakiye",
                    caption: "Bakiye",
                    "data-type": "number",
                    format: (d) => d.format(2,2)
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    width: "80",
                    "data-field": "etkisizGiren",
                    caption: "E.Giren",
                    title: "Etkisiz Giren",
                    "data-type": "number",
                    format: (d) =>d.format(2,2)
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    width: "80",
                    "data-field": "etkisizCikan",
                    caption: "E.Çıkan",
                    title: "Etkisiz Giren",
                    "data-type": "number",
                    format: (d) =>d.format(2,2)
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    width: "120",
                    "data-field": "subeId",
                    caption: "Şube",
                    "calculate-cell-value": (s) => _ctx.depoIdNameRef.find(a=>a.id == s.depoId)?.subeAdi
                  }, null, 8, ["calculate-cell-value"]),
                  _createVNode(_component_DxColumn, {
                    width: "120",
                    "data-field": "depoId",
                    caption: "Depo",
                    "calculate-cell-value": (s) => _ctx.depoIdNameRef.find(a=>a.id == s.depoId)?.name
                  }, null, 8, ["calculate-cell-value"]),
                  _createVNode(_component_DxColumn, {
                    width: "120",
                    "data-field": "kdv",
                    format: (s) => s.format(2,2),
                    "data-type": "number"
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    width: "150",
                    "data-field": "tip",
                    caption: "Tip",
                    "calculate-cell-value": (s) => _ctx.stokHareketTipList.find(a=>a.value == s.tip)?.description
                  }, null, 8, ["calculate-cell-value"]),
                  _createVNode(_component_DxColumn, {
                    width: "100",
                    "data-field": "fiyat",
                    caption: "Fiyat",
                    format: (s) => s.format(2,2),
                    "data-type": "number"
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    width: "100",
                    "data-field": "netFiyat",
                    caption: "Net Fyt",
                    format: (s) => s.format(2,2),
                    "data-type": "number"
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    width: "100",
                    "data-field": "netFiyatKdvli",
                    caption: "Net Fyt (Kdvli)",
                    format: (s) => s.format(2,2),
                    "data-type": "number",
                    "calculate-cell-value": (d) => d.netFiyat * (1 + (d.kdv / 100 )) 
                  }, null, 8, ["format", "calculate-cell-value"]),
                  _createVNode(_component_DxColumn, {
                    width: "100",
                    "data-field": "kdvLiFiyat",
                    caption: "Kdvli Fiyat",
                    format: (s) => s.format(2,2),
                    "data-type": "number",
                    "calculate-cell-value": (d) => d.fiyat * (1 + (d.kdv / 100 )) 
                  }, null, 8, ["format", "calculate-cell-value"]),
                  _createVNode(_component_DxColumn, {
                    width: "100",
                    "data-field": "tutar",
                    caption: "Tutar",
                    format: (s) => s.format(2,2),
                    "data-type": "number",
                    "calculate-cell-value": (d) => d.fiyat * d.miktar 
                  }, null, 8, ["format", "calculate-cell-value"]),
                  _createVNode(_component_DxColumn, {
                    width: "100",
                    "data-field": "kdvLiTutar",
                    caption: "Kdvli Tutar",
                    format: (s) => s.format(2,2),
                    "data-type": "number",
                    "calculate-cell-value": (d) => (d.fiyat * d.miktar) * (1 + ( d.kdv / 100 )) 
                  }, null, 8, ["format", "calculate-cell-value"]),
                  _createVNode(_component_DxColumn, {
                    width: "120",
                    "data-field": "stokMaliyet",
                    caption: "Stok Maliyet",
                    format: (s) => s.format(2,2),
                    "data-type": "number"
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    width: "120",
                    "data-field": "topStokMaliyet",
                    caption: "Top. Stok Maliyet",
                    format: (s) => s.format(2,2),
                    "data-type": "number",
                    "calculate-cell-value": (d) => d.stokMaliyet * d.miktar 
                  }, null, 8, ["format", "calculate-cell-value"]),
                  _createVNode(_component_DxColumn, {
                    width: "120",
                    "data-field": "mevcutMiktar",
                    caption: "Mevcut Miktar",
                    format: (s) => s.format(2,2),
                    "data-type": "number"
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    width: "100",
                    "data-field": "yerelSatisFiyat",
                    caption: "Yerel Satış Fiyati",
                    format: (s) => s.format(2,2),
                    "data-type": "number"
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    width: "100",
                    "data-field": "kdvliSatisFiyat",
                    caption: "(Kdvli) Yerel Satış Fiyati",
                    calculateCellValue: (s) => (s.yerelSatisFiyat * (1 + (s.kdv / 100 ))).format(2,2),
                    "data-type": "number"
                  }, null, 8, ["calculateCellValue"]),
                  _createVNode(_component_DxColumn, {
                    width: "120",
                    "data-field": "genelResmiMuhasebeTip",
                    caption: "M. Durum",
                    "calculate-cell-value": (s) => _ctx.genelResmiMuhasebeRef.find(a=>a.value == s.genelResmiMuhasebeTip)?.description 
                  }, null, 8, ["calculate-cell-value"]),
                  _createVNode(_component_DxColumn, {
                    width: "80",
                    "data-field": "doviz",
                    caption: "Doviz",
                    "calculate-cell-value": (s) => _ctx.dovizListRef.find(a=>a.value == s.doviz)?.key
                  }, null, 8, ["calculate-cell-value"]),
                  _createVNode(_component_DxColumn, {
                    width: "50",
                    "data-field": "kur",
                    caption: "Kur",
                    "data-type": "number"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "90",
                    "data-field": "seriler",
                    caption: "Seriler",
                    calculateCellValue: (d) =>d?.seriler?.join(' , ')
                  }, null, 8, ["calculateCellValue"]),
                  _createVNode(_component_DxColumn, {
                    width: "120",
                    "data-field": "aciklama",
                    caption: "Açıklama"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "100",
                    "data-field": "siparisTarih",
                    caption: "Termin Tarih",
                    dataType: "date"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "100",
                    "data-field": "fifoMaliyet",
                    caption: "FIFO Maliyet",
                    dataType: "number",
                    format: "#,##0.00"
                  }),
                  _createVNode(_component_DxSummary, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_DxTotalItem, {
                        column: "girenMiktar",
                        "summary-type": "sum",
                        "value-format": (a) => a.format(2,2),
                        "display-format": "G: {0}"
                      }, null, 8, ["value-format"]),
                      _createVNode(_component_DxTotalItem, {
                        column: "cikanMiktar",
                        "summary-type": "sum",
                        "value-format": (a) => a.format(2,2),
                        "display-format": "Ç: {0}"
                      }, null, 8, ["value-format"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["data-source"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["tabs", "modelValue"]),
    (_ctx.showSacTalimatDialog)
      ? (_openBlock(), _createBlock(_component_SacUretimTalimatFormDialog, {
          key: 0,
          show: _ctx.showSacTalimatDialog,
          "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showSacTalimatDialog) = $event)),
          id: _ctx.sacTalimat.id,
          "onUpdate:id": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sacTalimat.id) = $event)),
          "stok-id": _ctx.sacTalimat.stokParent?.id as string
        }, null, 8, ["show", "id", "stok-id"]))
      : _createCommentVNode("", true),
    (_ctx.showSacProfilTalimatDialog)
      ? (_openBlock(), _createBlock(_component_SacProfilUretimTalimatFormDialog, {
          key: 1,
          id: _ctx.sacTalimat.id,
          "onUpdate:id": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.sacTalimat.id) = $event)),
          "stok-id": _ctx.sacTalimat.stokParent?.id as string,
          show: _ctx.showSacProfilTalimatDialog,
          "onUpdate:show": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showSacProfilTalimatDialog) = $event))
        }, null, 8, ["id", "stok-id", "show"]))
      : _createCommentVNode("", true)
  ], 64))
}