import {createRouter, createWebHashHistory,  RouteRecordRaw} from "vue-router";
import ApplicationLayout from "../layouts/ApplicationLayout/ApplicationLayout.vue";
import LoginLayout from "../layouts/LoginLayout/LoginLayout.vue";
import authGuard from "./guards/authGuard";
import MiniTester from "@/modules/TestModule/views/MiniTester.vue";
import YasinTest from "@/modules/TestModule/views/YasinTest.vue";
import YasinTest2 from "@/modules/TestModule/views/YasinTest2.vue";
import StokGroupFormPage from "@/modules/StokGroup/pages/StokGroupFormPage.vue";
import EfaturaOnizleme from "@/modules/Fatura/components/EfaturaOnizleme.vue";
import CariOnlinePage from "@/modules/Online/Cari/CariOnlinePage.vue";
import CariIzinPage from "@/modules/Cari/pages/CariIzinPage.vue";
import CariOnlineMutabakatPage from "@/modules/Online/Cari/CariOnlineMutabakatPage.vue";
import HizliSatisPage from "@/modules/HizliSatis/layout/HizliSatisLayout.vue";
import HizliSatisLayout from "@/modules/HizliSatis/layout/HizliSatisLayout.vue";
import ReportViewer from "@/modules/Report/components/ReportViewer.vue";
import ReportViwerIFrame from "@/modules/Report/iframe/ReportViwerIFrame.vue";
import FiyatGorLayout from "@/modules/FiyatGor/layout/FiyatGorLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Login",
    component: LoginLayout
  },
  {
    path: "/wizard",
    name: "Wizard",
    component: () => import( /* webpackChunkName: "wizard"*/ "../modules/Wizard/page/InstallWizardPage.vue")
  },

  {
    path: "/hizliSatis/",
    name: "HizliSatisLogin",
    component: LoginLayout
  },
  {
    path: "/fiyatGor/",
    name: "FiyatGor",
    component: LoginLayout
  },
  {
    path: "/uretim/",
    name: "UretimTalimatLogin",
    component: LoginLayout
  },
  {
    path: "/loginCode/",
    name: "LoginCode",
    component: LoginLayout
  },
  {
    path: "/hizliSatis/:firm/:period",
    name: "HizliSatisLayout",
    component: HizliSatisLayout,
    meta: {
      authRequired: true
    }
  },
  {
    path: "/fiyatGor/:firm/:period",
    name: "FiyatGorLayout",
    component: FiyatGorLayout,
    meta: {
      authRequired: true
    }
  },
  {
    path: "/uretim/:firm/:period",
    name: "UretimTalimatMobile",
    component: () => import(/* webpackChunkName: "UretimMobilTalimatModule"*/ "../modules/UretimMobile/pages/UretimTalimatMobilPage.vue"),
    meta: {
      authRequired: true
    }
  },

  {
    path: "/:firm/:period",
    name: "ApplicationLayout",
    component: ApplicationLayout,
    meta: {
      authRequired: true
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    redirect: "Login"
  },

  {
    path: "/test",
    name: "Test",
    component: MiniTester
  },
  {
    path: "/yasin",
    name: "Yasin",
    component: YasinTest
  },
  {
    path: "/yasin2",
    name: "Yasin2",
    component: YasinTest2
  },
  {
    path: "/stokGroup/:firm",
    name: "StokGroupPage",
    component: StokGroupFormPage
  },
  {
    path: "/Online/Cari/:id",
    name: "Online_Cari",
    component: CariOnlinePage
  },
  {
    path: "/CariIzin/:firma/:key",
    name: "Cari_İzin",
    component: CariIzinPage
  },
  {
    path: "/CariOnlineMutabakat/:key",
    name: "Cari_Mutabakat",
    component: CariOnlineMutabakatPage
  },
  {
    path:"/efaturaOnizleme",
    name:"EfaturaOnizleme",
    component : EfaturaOnizleme
  }
  ,
  {
    path:"/reportViewer/:reportName/:slientPrint",
    name:"ReportViewer",
    component : ReportViwerIFrame
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(authGuard);

export default router;
