<template>

  <div :class="{ 'firm-selector': true, 'selector--condensed': condensed }">
    <div class="firm-condensed" v-if="condensed">
      {{ firmInfo.substring(0, 1) }}
    </div>
    <div class="firm-name">{{ firmInfo }}</div>
    <div class="firm-period">{{ firmPeriod }}</div>
    <div class="selector-icon">
      <NIcon type="chevron-down" style="cursor: pointer" size="16px" strokeWidth="3px"
             @click="firmaDropDownOpen = !firmaDropDownOpen" />
    </div>
    <ul class="firm-drop-down" v-if="firmaDropDownOpen">
      <li class="firm-drop-down-item" v-for="(firma, i) in firmalar" :key="i">
        {{ firma.firma }}
        <ul>
          <li :class="firmPeriod==donem && firmInfo == firma.firma?'open':'close'" v-for="(donem,x) in firma.donemler"
              :key="x" @click="changeFirma(firma.firma,donem)">{{ donem }}
          </li>
        </ul>
      </li>
    </ul>
    {{ drop }}
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { useSharedStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
import { useFirmaService } from "@/api/services/FirmaService";
import { useLayoutStore } from "@/layouts/ApplicationLayout/context";
import { useFirmaStore } from "@/layouts/LoginLayout/context";
import { useCachedPlasiyerList } from "@/modules/Plasiyer/services/useCachedPlasiyerList";
import { useCachedSubeDepoService } from "@/modules/Sube/services/useCachedSubeDepoService";
import { useCariCachedService } from "@/modules/Cari/services/useCariCachedService";
import { usedCacheFirmaService } from "@/modules/Ayarlar/Firmalar/services/usedCacheFirmaService";
import { useStokFlatStore } from "@/modules/Stok/store/StokFlatStore";
import { useStokStore } from "@/modules/Stok/store/StokStore";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { useCachedHizmetMasrafGiderService } from "@/modules/HizmetMasraf/services/useCachedHizmetMasrafGiderService";
import CariSelectBox from "@/modules/Cari/components/CariSelectBox";
import { useCariStore } from "@/modules/Cari/store/CariStore";
import {confirm} from "devextreme/ui/dialog";
import {GenelUtils} from "@/modules/Ortak/utils/GenelUtils";


export default defineComponent({
  name: "FirmSelector",
  props: {
    condensed: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const shared = useSharedStore();

    const firmPeriod = computed(() => route.params?.period);
    const firmInfo = computed(() => route.params?.firm);
    const firmaDropDownOpen = ref(false);
    const firmalar = ref([]);

    async function changeFirma(firma, donem) {

      if (useSharedStore().firm.id === firma && useSharedStore().firm.period === donem) // Aynı döneme tıklama
        return;

      if(! await GenelUtils.onayDiyalogu("Firma/Dönem değiştirmek istiyor musunuz ? (UYARI!! : Açık tüm sekmeler kapatılacaktır)", "Firma/Dönem Değiştirme (Uyarı)"))
          return;


      const userName = useSharedStore().user.userName;
      if (route.params) {
        useFirmaStore.setFirma({ firma: firma, donem: donem }, userName);

        router.push({
          name: "ApplicationLayout",
          params: {
            firm: firma,
            period: donem
          }
        });

        useSharedStore().firm.name = firma;
        useSharedStore().firm.period = donem;
        useSharedStore().firm.id = firma;
        useLayoutStore().tabs = [];
      }
      firmaDropDownOpen.value = false;

      storeRefresh();
    }


    // Firma Değiştirme İşleminde Boşaltılması Gereken Storelar
    async function storeRefresh() {
      // Plasiyer
      useCachedPlasiyerList().userListe = [];
      useCachedPlasiyerList().liste = [];

      // Sube Depo
      useCachedSubeDepoService().listDepo = [];
      useCachedSubeDepoService().listeSube = [];
      useCachedSubeDepoService().subeUserList = [];
      useCachedSubeDepoService().depoUserList = [];

      // Cari
      useCariCachedService().cariListesi = [];
      useCariCachedService().isCached = false;
      //await useCariStore().refreshCariSelectBox();


      // Firma
      usedCacheFirmaService().firma = {};

      // Stok
      await useCachedHizmetMasrafGiderService().refreshHizmetMasraf();


      useStokFlatStore().isCache = false;
      useStokFlatStore().stokArrayStore = new ArrayStore();
      useStokFlatStore().dataSource = new DataSource({
        reshapeOnPush: true
      });

      useStokStore().isCache = false;
      useStokStore().isLoaded = false;
      useStokStore().arrayStore = new ArrayStore();
      useStokStore().dataSource = new DataSource({
        reshapeOnPush: true
      });

    }

    async function load() {
      firmalar.value = await useFirmaService().getFirmaListesi();
      firmalar.value = firmalar.value.filter(a => !a.firma.endsWith("_Log"));
    }

    load();

    return {
      firmPeriod,
      firmInfo,
      firmaDropDownOpen,
      firmalar,
      changeFirma
    };
  }
});
</script>

<style lang="scss" scoped>
.firm-selector {
  display: grid;
  border-radius: 10px;
  background-color: #162b49;

  &:not(.selector--condensed) {
    grid-template-columns: 1fr 32px;
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      "name ico"
      "perd ico";
    padding: 15px 10px 15px 20px;

    .firm-name {
      grid-area: name;
      display: flex;
      align-items: flex-end;
      line-height: 16px;
    }

    .firm-period {
      grid-area: perd;
      display: flex;
      align-items: flex-start;
      color: #8a95a4;
      line-height: 16px;
    }

    .selector-icon {
      grid-area: ico;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;

      i {
        position: relative;
        height: 16px;

        &:first-child {
          top: -2px;
        }

        &:last-child {
          top: -4px;
        }
      }
    }
  }

  &.selector--condensed {
    place-content: center;

    .firm-condensed {
      padding: 8px 0px;
      font-weight: 900;
    }

    .firm-name {
      grid-area: name;
      display: none;
    }

    .firm-period {
      grid-area: perd;
      display: none;
    }

    .selector-icon {
      grid-area: ico;
      display: none;
    }
  }
}

ul.firm-drop-down {
  padding: 0 0 0 12px;
  margin: 4px 0 0 0;
  list-style: none;
  font-size: 14px;
  font-weight: normal;

  li.firm-drop-down-item {
    margin: 4px 0;
    padding: 4px 0;
    display: block;

    ul {
      margin: 0 0 0 0;
      padding: 0 0 0 0;

      li {
        cursor: pointer;
        list-style: none;
        margin: 0;
        padding: 0 0 0 0px;

        color: #c6d8ef;
        position: relative;
      }

      li:before {
        content: "";
        position: absolute;
        top: 50%;
        left: -20px;
        display: block;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background-color: #394b64;
        transform: translateY(-50%);
      }

      li.open:before {
        background-color: #78b81c;
      }
    }
  }
}
</style>
