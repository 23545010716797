
export enum TaksitPlanTip{
Tahsilat=1,
SatisFatura=10,
PosCekim=20,
BorcYapilandirma=30,
MusteriCek=40,
MusteriSenet=45,
BorcDekont=50,
Hizmet=60,
HavaleAlma=70,
NakitTahsilat=80,
Odeme=1001,
AlisFatura=1010,
FirmaKk=1020,
AlacakYapilandirma=1030,
FirmaCek=1040,
FirmaSenet=1045,
AlacakDekont=1050,
Masraf=1060,
HaveleGonderme=1070,
NakiOdeme=1080,
BankadanOdeme=1090,
SatisIadeFaturasi=1100,
Kredi=1110,
FirmaDbs=1120,
NotVeyaVade=2000
}

export const TaksitPlanTipList = [
{key:'Tahsilat', value:1, description:'Tahsil Edildi'},
{key:'SatisFatura', value:10, description:'Satış Fatura'},
{key:'PosCekim', value:20, description:'Pos Çekim'},
{key:'BorcYapilandirma', value:30, description:'Borç Yapılandırma'},
{key:'MusteriCek', value:40, description:'Müşteri Çek'},
{key:'MusteriSenet', value:45, description:'Müşteri Senet'},
{key:'BorcDekont', value:50, description:'Borç Dekontu'},
{key:'Hizmet', value:60, description:'Borç Dekontu'},
{key:'HavaleAlma', value:70, description:'Havele Alma'},
{key:'NakitTahsilat', value:80, description:'Nakit Tahsilat'},
{key:'Odeme', value:1001, description:'Ödeme Yapıldı'},
{key:'AlisFatura', value:1010, description:'Alış Faturası'},
{key:'FirmaKk', value:1020, description:'Firma Kredi Kartı'},
{key:'AlacakYapilandirma', value:1030, description:'Alacak Yapılandırma'},
{key:'FirmaCek', value:1040, description:'Firma Çek'},
{key:'FirmaSenet', value:1045, description:'Firma Senet'},
{key:'AlacakDekont', value:1050, description:'Alacak Dekontu'},
{key:'Masraf', value:1060, description:'Masraf'},
{key:'HaveleGonderme', value:1070, description:'Havele Gönderme'},
{key:'NakiOdeme', value:1080, description:'Nakit Ödeme'},
{key:'BankadanOdeme', value:1090, description:'Bankadan Ödeme'},
{key:'SatisIadeFaturasi', value:1100, description:'Satış İade Faturası'},
{key:'Kredi', value:1110, description:'Kredi'},
{key:'FirmaDbs', value:1120, description:'Firma Senet'},
{key:'NotVeyaVade', value:2000, description:'Not'}
]
