import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {KampanyaFaturaSatirItem} from '../models/KampanyaFaturaSatirItem'
import {KampanyaFaturaView} from '../models/KampanyaFaturaView'
import {Kapsam} from '../models/Kapsam'
import {KaynakHedef} from '../models/KaynakHedef'
import {KampanyaView} from '../models/KampanyaView'
import {StokKampanyaFlatView} from '../models/StokKampanyaFlatView'
import {KampanyaStokValidationView} from '../models/KampanyaStokValidationView'

export const useKampanyaService = defineStore({
id: 'KampanyaService',
actions: {


	async getGetAll()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Kampanya/GetAll/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetAllAktif()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Kampanya/GetAllAktif/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetDefaultView()
	{
		const { request, response } = useApi(`/api/Kampanya/GetDefaultView`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postGetAllByFatura(kampanyaFaturaView:KampanyaFaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Kampanya/GetAllByFatura/${shared.firm.id}`,{ method:'POST', data:kampanyaFaturaView });
		await request();
		return response.value;
	},

	async getGet(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Kampanya/Get/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Kampanya/GetView/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postInsert(kampanyaView:KampanyaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Kampanya/Insert/${shared.firm.id}`,{ method:'POST', data:kampanyaView });
		await request();
		return response.value;
	},

	async putUpdate(kampanyaView:KampanyaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Kampanya/Update/${shared.firm.id}`,{ method:'PUT', data:kampanyaView });
		await request();
		return response.value;
	},

	async deleteDelete(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Kampanya/Delete/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postKampanyaKategoriValidate(kampanyaStokValidationView:KampanyaStokValidationView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Kampanya/KampanyaKategoriValidate/${shared.firm.id}`,{ method:'POST', data:kampanyaStokValidationView });
		await request();
		return response.value;
	}
}
});
