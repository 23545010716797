
export enum BasitUretimTip{
UretilenStoklar=0,
UretimdeKullanilan=1,
Fire=2
}

export const BasitUretimTipList = [
{key:'UretilenStoklar', value:0, description:'Üretilen Stok'},
{key:'UretimdeKullanilan', value:1, description:'Üretimde Kullanılan'},
{key:'Fire', value:2, description:'Üretimde Fire'}
]
