
import { defineComponent, computed } from "vue";
import { Colors, Positions } from "../../models";
export default defineComponent({
  name: "NBadge",
  props: {
    type: {
      type: String,
      default: Colors.DANGER,
      validator: (value: Colors) =>
        [
         Colors.BLACK ,
          Colors.PRIMARY,
          Colors.SUCCESS,
          Colors.DANGER,
          Colors.WARNING
        ].includes(value)
    },
    value: { type: [String, Number] },
    position: {
      type: String,
      default: Positions.TR,
      validator: (value: Positions) =>
        [Positions.TL, Positions.TR, Positions.BL, Positions.BR].includes(value)
    },
    show: { type: Boolean, default: true },
    point: { type: Boolean },
    square: { type: Boolean },
    maxValue: { type: Number }
  },
  setup(props) {
    const contentValue = computed(() => {
      if (props.point) {
        return;
      }
      return (props.value as number) > (props.maxValue as number)
        ? `${props.maxValue}+`
        : props.value;
    });
    return { contentValue };
  }
});
