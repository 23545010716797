import {defineStore} from "pinia";
import {NModuleRoute} from "@/models";
import {useLayoutStore} from "@/layouts/ApplicationLayout/context";
import StokFisFormProp from "@/modules/Cari/models/StokFisFormProp";
import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import {StokFisEntity} from "@/api/models/StokFisEntity";
import {StokFisView} from "@/api/models/StokFisView";

export const useFisStore = defineStore({

    id: "FisStore",
    state: () => ({
        stokFisFormProp: {

        } as StokFisFormProp,
    }),
    actions: {
        openFisForm(menu:MenuModel  , id:string , model:StokFisView ) {
            this.stokFisFormProp = {
                model : model,
                id : id ,
                menu : menu,
            } as StokFisFormProp;

            const route: NModuleRoute = {
                id: "FisForm",
                order: 0,
                displayName: menu.name!,
                icon: {
                    type: "file-minus"
                },
                entityId: model.id,
            };
            useLayoutStore().openRouteAsTab(route);
        }
    }


})