import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-de14cd40"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "n-dropdown-trigger",
  ref: "trigger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _mergeProps({
    ref: "dropdownEl",
    class: "n-dropdown"
  }, _toHandlers(_ctx.listeners, true)), [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 512),
    _createVNode(_Transition, { name: _ctx.transition }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", {
          class: _normalizeClass(["n-dropdown-slot", [_ctx.placementRef && `n-dropdown-slot--${_ctx.placementSide}`]]),
          ref: "popover"
        }, [
          _renderSlot(_ctx.$slots, "menu", {}, undefined, true)
        ], 2), [
          [_vShow, _ctx.show]
        ])
      ]),
      _: 3
    }, 8, ["name"])
  ], 16))
}