
export enum VergiTip{
Otv=1,
Stopaj=2,
Iletisim=3,
Diger=4
}

export const VergiTipList = [
{key:'Otv', value:1, description:'Ötv'},
{key:'Stopaj', value:2, description:'Stopaj'},
{key:'Iletisim', value:3, description:'İletişim'},
{key:'Diger', value:4, description:'Diğer'}
]
