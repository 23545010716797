export enum Positions {
  B = "bottom",
  BL = "bottom-left",
  BR = "bottom-right",
  L = "left",
  LT = "left-top",
  LB = "left-bottom",
  R = "right",
  RT = "right-top",
  RB = "right-bottom",
  T = "top",
  TL = "top-left",
  TR = "top-right"
}
