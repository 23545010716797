
import { defineComponent, reactive, ref, toRefs, computed } from "vue";

import { StokFlatView } from "@/api/models/StokFlatView";
import { useStokService } from "@/api/services/StokService";
import { StokGrupItem } from "@/api/models/StokGrupItem";
import { confirm } from "devextreme/ui/dialog";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import StokAramaInput from "@/modules/Fis/pages/StokAramaInput.vue";

export default  defineComponent({
  components: { StokAramaInput,   NFormGrid,NGrid },
  props:['stok'],
  setup(props){
    const  localState = reactive({
      stokRef:computed(()=> (props.stok)),
      showDialog:ref(false),
      list:ref()
    })

    async function load(){
       //localState.stoklar = await  useStokService().getStokFlatList()
    }



    function tekli(stok:StokFlatView){
      if (localState.stokRef.stoklar)
        localState.stokRef.stoklar.push(generateStokGroupItem(stok));

      refresh()
    }
    function coklu(stoklar:StokFlatView[]){
      if (localState.stokRef.stoklar)
      localState.stokRef.stoklar.push(...stoklar.map((a:StokGrupItem) => generateStokGroupItem(a)));

      refresh()
    }

    function generateStokGroupItem(stok:StokFlatView){
      return {
        ad:stok.ad,
        fiyat:stok.satisFiyat,
        itemId:stok.itemId,
        miktar:1,
        stokId:stok.id

      } as StokGrupItem;
    }

    async function sil(index:number) {
      if (await confirm("Gruba ait stoğu silmek istiyormusunuz?", "UYARI!")) {
        localState.stokRef.stoklar?.splice(index, 1);
        refresh()
      }
    }

    function refresh(){
      localState.list.instance?.reload();
    }


    return {
      ...toRefs(localState),
      coklu,
      tekli,
      sil
    }
  }
})

