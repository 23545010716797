import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NDropDownLabelItem = _resolveComponent("NDropDownLabelItem")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NImporterDialog = _resolveComponent("NImporterDialog")!
  const _component_NImporterFormDialog = _resolveComponent("NImporterFormDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NDropDownLabelItem, {
      label: _ctx.getImportName()
    }, null, 8, ["label"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.importList, (item, i) => {
      return (_openBlock(), _createBlock(_component_NDropdownItem, {
        key: i,
        icon: "box",
        onClick: ($event: any) => (_ctx.openImportViewer(item))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(item.name), 1)
        ]),
        _: 2
      }, 1032, ["onClick"]))
    }), 128)),
    _createVNode(_component_NDropdownItem, {
      icon: "plus",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showYeniDialog=true))
    }, {
      default: _withCtx(() => [
        _createTextVNode("Yeni Şablon Ekle")
      ]),
      _: 1
    }),
    (_ctx.showDialog)
      ? (_openBlock(), _createBlock(_component_NImporterDialog, {
          key: 0,
          tip: _ctx.importType,
          show: _ctx.showDialog,
          "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDialog) = $event)),
          id: _ctx.selectedValue,
          callback: _ctx.callback
        }, null, 8, ["tip", "show", "id", "callback"]))
      : _createCommentVNode("", true),
    (_ctx.showYeniDialog)
      ? (_openBlock(), _createBlock(_component_NImporterFormDialog, {
          key: 1,
          show: _ctx.showYeniDialog,
          "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showYeniDialog) = $event)),
          tip: _ctx.importType
        }, null, 8, ["show", "tip"]))
      : _createCommentVNode("", true)
  ], 64))
}