import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {CariJsonView} from '../models/CariJsonView'
import {GenelResmiMuhasebeTip} from '../models/GenelResmiMuhasebeTip'
import {CariBakiyeRaporRequestView} from '../models/CariBakiyeRaporRequestView'
import {DocumentItem} from '../models/DocumentItem'
import {IslemHesaplamaEntity} from '../models/IslemHesaplamaEntity'
import {PlanVadelerItem} from '../models/PlanVadelerItem'
import {CariHareketViewVadeli} from '../models/CariHareketViewVadeli'
import {CariHareketView} from '../models/CariHareketView'

export const useCariHareketService = defineStore({
id: 'CariHareketService',
actions: {


	async getCariHareketReportModel()
	{
		const { request, response } = useApi(`/api/carihareket/CariHareketReportModel`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getTumListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/TumListe/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async putCariAktar(liste:CariJsonView[],subeId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/CariAktar/${shared.firm.id}/${shared.firm.period}/${subeId}`,{ method:'PUT', data:liste });
		await request();
		return response.value;
	},

	async getGetir(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/Getir/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetirView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/GetirView/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/Sil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getListeGenelMuhasabeAralik(cariId:string,start:string,end:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/ListeGenelMuhasabeAralik/${shared.firm.id}/${shared.firm.period}/${cariId}/${start}/${end}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListeMuhasebeResmiAralik(cariId:string,start:string,end:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/ListeMuhasebeResmiAralik/${shared.firm.id}/${shared.firm.period}/${cariId}/${start}/${end}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListeGenelMuhasabe(cariId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/ListeGenelMuhasabe/${shared.firm.id}/${shared.firm.period}/${cariId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListeGenelMuhasabeSatislar(cariId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/ListeGenelMuhasabeSatislar/${shared.firm.id}/${shared.firm.period}/${cariId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postListeGenelMuhasabeMailOrder(cariBakiyeRaporRequestView:CariBakiyeRaporRequestView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/ListeGenelMuhasabeMailOrder/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cariBakiyeRaporRequestView });
		await request();
		return response.value;
	},

	async getListeMuhasebeResmi(cariId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/ListeMuhasebeResmi/${shared.firm.id}/${shared.firm.period}/${cariId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getHesapOzet(isProje:boolean)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/HesapOzet/${shared.firm.id}/${shared.firm.period}/${isProje}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postVirmanBorc(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/VirmanBorc/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async putVirmanBorcGuncelle(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/VirmanBorcGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async postMailOrderCekme(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/MailOrderCekme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async putMailOrderCekmeGuncelle(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/MailOrderCekmeGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async postBorcDekontuEkle(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/BorcDekontuEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async putBorcDekontuGuncelle(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/BorcDekontuGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async postBorcDevirEkle(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/BorcDevirEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async putBorcDevirGuncelle(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/BorcDevirGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async postCariyeFirmaKrediKartimizlaOdeme(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/CariyeFirmaKrediKartimizlaOdeme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async putCariyeFirmaKrediKartimizlaOdemeGuncelle(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/CariyeFirmaKrediKartimizlaOdemeGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async postNakitOdemeEkle(cariHareketView:CariHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/NakitOdemeEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cariHareketView });
		await request();
		return response.value;
	},

	async putNakitOdemeGuncelle(cariHareketView:CariHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/NakitOdemeGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:cariHareketView });
		await request();
		return response.value;
	},

	async postCariHaveleGonderme(cariHareketView:CariHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/CariHaveleGonderme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cariHareketView });
		await request();
		return response.value;
	},

	async putCariHaveleGondermeGuncelle(cariHareketView:CariHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/CariHaveleGondermeGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:cariHareketView });
		await request();
		return response.value;
	},

	async postVirmanAlacak(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/VirmanAlacak/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async putVirmanAlacakGuncelle(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/VirmanAlacakGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async postAlacakDevirEkle(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/AlacakDevirEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async putAlacakDevirGuncelle(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/AlacakDevirGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async postAlacakDekontuEkle(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/AlacakDekontuEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async putAlacakDekontuGuncelle(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/AlacakDekontuGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async postNakitTahsilatEkle(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/NakitTahsilatEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async putNakitTahsilatGuncelle(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/NakitTahsilatGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async postCariHavaleAlma(cariHareketView:CariHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/CariHavaleAlma/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cariHareketView });
		await request();
		return response.value;
	},

	async putCariHavaleAlmaGuncelle(cariHareketView:CariHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/CariHavaleAlmaGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:cariHareketView });
		await request();
		return response.value;
	},

	async postCariPosCekme(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/CariPosCekme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async putCariPosCekmeGuncelle(cariHareketViewVadeli:CariHareketViewVadeli)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/CariPosCekmeGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:cariHareketViewVadeli });
		await request();
		return response.value;
	},

	async getHareketReferansId(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/HareketReferansId/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getRaporCariHareket(baslangic:string,bitis:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/RaporCariHareket/${shared.firm.id}/${shared.firm.period}/${baslangic}/${bitis}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getRefreshQueue(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carihareket/RefreshQueue/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
