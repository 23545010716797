import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NDropDownTreeView = _resolveComponent("NDropDownTreeView")!
  const _component_NRefresher = _resolveComponent("NRefresher")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_N11ShipmentTemplateSelectBox = _resolveComponent("N11ShipmentTemplateSelectBox")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NInputHtml = _resolveComponent("NInputHtml")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_PazaryeriFiyatItem = _resolveComponent("PazaryeriFiyatItem")!
  const _component_N11OzellikEditor = _resolveComponent("N11OzellikEditor")!
  const _component_N11SearchDialog = _resolveComponent("N11SearchDialog")!

  return (_openBlock(), _createElementBlock("div", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), [
    (_ctx.stokRef && _ctx.n11 && _ctx.n11Ref.kategori)
      ? (_openBlock(), _createBlock(_component_NFormGrid, {
          key: 0,
          cols: "1fr 1fr 1fr",
          title: "N11'e Gönder"
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_NFormField, {
              label: "N11'e Gönder",
              "grid-cols": "1fr auto auto auto"
            }, {
              default: _withCtx(() => [
                (_ctx.index > 0)
                  ? (_openBlock(), _createBlock(_component_NButton, {
                      key: 0,
                      icon: "trash",
                      size: "small",
                      type: "danger",
                      "icon-size": 12,
                      style: {"padding":"4px"},
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.remove(_ctx.index)))
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_NSwitch, {
                  modelValue: _ctx.n11Ref.isActive,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.n11Ref.isActive) = $event))
                }, null, 8, ["modelValue"]),
                _createVNode(_component_NButton, {
                  icon: "plus",
                  size: "small",
                  onClick: _ctx.addNew
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Yeni N11 Ekle")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_NFormField, {
              label: "Kategori",
              "grid-cols": "120px 1fr",
              span: "3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NRefresher, { load: _ctx.load }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NDropDownTreeView, {
                      "selected-item": _ctx.n11Ref.kategori,
                      "onUpdate:selectedItem": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.n11Ref.kategori) = $event)),
                      "data-source": _ctx.kategoriler,
                      "onUpdate:dataSource": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.kategoriler) = $event)),
                      "items-expr": "subCategories"
                    }, null, 8, ["selected-item", "data-source"])
                  ]),
                  _: 1
                }, 8, ["load"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Hazırlanma Günü",
              "grid-cols": "120px 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxNumberBox, {
                  value: _ctx.n11Ref.hazirlanmaGunu,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.n11Ref.hazirlanmaGunu) = $event)),
                  placeholder: "En az 1 gün giriniz"
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Kargo",
              "grid-cols": "60px 1fr",
              span: "2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_N11ShipmentTemplateSelectBox, {
                  modelValue: _ctx.n11Ref.teslimatSablonu,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.n11Ref.teslimatSablonu) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Stok Kodu",
              "grid-cols": "120px 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  modelValue: _ctx.n11Ref.n11StokKod,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.n11Ref.n11StokKod) = $event)),
                  placeholder: "N11'de kayıtlı stok kodu. Stok N11'de yoksa boş bırakınız",
                  type: "text"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            (_ctx.stokRef)
              ? (_openBlock(), _createBlock(_component_NFormField, {
                  key: 0,
                  label: "Barkod",
                  "grid-cols": "60px 1fr"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NInput, {
                      modelValue: _ctx.n11Ref.barkod,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.n11Ref.barkod) = $event)),
                      placeholder: _ctx.stokRef?.barkod
                    }, null, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.stokRef)
              ? (_openBlock(), _createBlock(_component_NFormField, {
                  key: 1,
                  label: "Katalog Id",
                  "grid-cols": "70px 1fr auto"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxNumberBox, {
                      value: _ctx.n11Ref.catalogId,
                      "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.n11Ref.catalogId) = $event))
                    }, null, 8, ["value"]),
                    _createVNode(_component_NButton, {
                      icon: "search",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showSearch=true)),
                      size: "small",
                      type: "info"
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.stokRef)
              ? (_openBlock(), _createBlock(_component_NFormField, {
                  key: 2,
                  label: "Stok Adı",
                  "grid-cols": "120px 1fr",
                  span: "3"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NInput, {
                      modelValue: _ctx.n11Ref.ad,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.n11Ref.ad) = $event)),
                      placeholder: _ctx.stokRef?.ad,
                      maxlength: 65,
                      "show-counter": true
                    }, null, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.stokRef)
              ? (_openBlock(), _createBlock(_component_NFormField, {
                  key: 3,
                  label: "Kısa Açıklama",
                  "grid-cols": "120px 1fr",
                  span: "3"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NInput, {
                      modelValue: _ctx.n11Ref.kisaAciklama,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.n11Ref.kisaAciklama) = $event)),
                      placeholder: _ctx.stokRef?.kisaAciklama
                    }, null, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_NFormField, {
              label: "Açıklama",
              "grid-cols": "120px 1fr",
              span: "3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInputHtml, {
                  modelValue: _ctx.n11Ref.aciklama,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.n11Ref.aciklama) = $event)),
                  placeholder: _ctx.stokRef?.aciklama
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_PazaryeriFiyatItem, {
      title: "N11 Fiyatı",
      "alis-kdv": _ctx.alisKdvRef,
      "onUpdate:alisKdv": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
      "alis-kdv-dahil": _ctx.alisKdvDahilRef,
      "onUpdate:alisKdvDahil": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
      "satis-kdv": _ctx.satisKdvRef,
      "onUpdate:satisKdv": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
      "satis-kdv-dahil": _ctx.satisKdvDahilRef,
      "onUpdate:satisKdvDahil": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
      "alis-fiyati": _ctx.alisFiyatRef,
      "onUpdate:alisFiyati": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
      "satis-fiyati": _ctx.satisFiyatRef,
      "onUpdate:satisFiyati": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.satisFiyatRef) = $event)),
      "kargo-fiyat": _ctx.n11Ref.kargoTutar,
      "onUpdate:kargoFiyat": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.n11Ref.kargoTutar) = $event)),
      "komisyon-oran": _ctx.n11Ref.komisyon,
      "onUpdate:komisyonOran": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.n11Ref.komisyon) = $event)),
      "fiyat-tipi": _ctx.n11Ref.fiyat.tip,
      "onUpdate:fiyatTipi": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.n11Ref.fiyat.tip) = $event)),
      deger: _ctx.n11Ref.fiyat.deger,
      "onUpdate:deger": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.n11Ref.fiyat.deger) = $event)),
      "kar-marji": _ctx.n11Ref.karMarji,
      "onUpdate:karMarji": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.n11Ref.karMarji) = $event)),
      "indirim-tipi": _ctx.n11Ref.indirim.tip,
      "onUpdate:indirimTipi": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.n11Ref.indirim.tip) = $event)),
      indirim: _ctx.n11Ref.indirim.deger,
      "onUpdate:indirim": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.n11Ref.indirim.deger) = $event)),
      "alis-doviz": _ctx.stokRef.alisDoviz,
      "onUpdate:alisDoviz": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.stokRef.alisDoviz) = $event)),
      "satis-doviz": _ctx.stokRef.satisDoviz,
      "onUpdate:satisDoviz": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.stokRef.satisDoviz) = $event)),
      maliyet: _ctx.stokRef.getMaliyet,
      "onUpdate:maliyet": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.stokRef.getMaliyet) = $event))
    }, null, 8, ["alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyati", "satis-fiyati", "kargo-fiyat", "komisyon-oran", "fiyat-tipi", "deger", "kar-marji", "indirim-tipi", "indirim", "alis-doviz", "satis-doviz", "maliyet"]),
    (_ctx.n11Ref && _ctx.n11Ref.kategori && _ctx.n11Ref.kategori && _ctx.n11Ref.kategori.id  > 0)
      ? (_openBlock(), _createBlock(_component_N11OzellikEditor, {
          key: 1,
          "kategori-id": _ctx.n11Ref.kategori.id,
          "onUpdate:kategoriId": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.n11Ref.kategori.id) = $event)),
          ozellikler: _ctx.n11Ref.ozellikler,
          "onUpdate:ozellikler": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.n11Ref.ozellikler) = $event))
        }, null, 8, ["kategori-id", "ozellikler"]))
      : _createCommentVNode("", true),
    (_ctx.showSearch)
      ? (_openBlock(), _createBlock(_component_N11SearchDialog, {
          key: 2,
          text: _ctx.stok.ad,
          modelValue: _ctx.n11Ref.catalogId,
          "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.n11Ref.catalogId) = $event)),
          show: _ctx.showSearch,
          "onUpdate:show": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.showSearch) = $event))
        }, null, 8, ["text", "modelValue", "show"]))
      : _createCommentVNode("", true)
  ], 16))
}