
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import { CekSenetHareketView } from "@/api/models/CekSenetHareketView";
import { CekSenetDurum, CekSenetDurumList } from "@/api/models/CekSenetDurum";
import { CekSenetFlatView } from "@/api/models/CekSenetFlatView";
import BankaSelectBox from "@/modules/Banka/components/BankaSelectBox.vue";
import { useCekSenetHareketService } from "@/api/services/CekSenetHareketService";
import NGrid from "@/components/Grid/NGrid.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import KasaSelectBox from "@/modules/Kasa/components/KasaSelectBox.vue";
import { Doviz, DovizList } from "@/api/models/Doviz";
import CariSelectBox from "@/modules/Cari/components/CariSelectBox.vue";
import { GenelResmiMuhasebeTip, GenelResmiMuhasebeTipList } from "@/api/models/GenelResmiMuhasebeTip";
import { useEvrakNoService } from "@/api/services/EvrakNoService";
import { CekSenetItemView } from "@/api/models/CekSenetItemView";
import NTag from "@/components/Tag/NTag.vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import { useKurCachedServis } from "@/modules/Ortak/services/useKurCachedServis";
import { KurView } from "@/api/models/KurView";
import { CekSenetUtils } from "@/modules/CekSenet/utils/CekSenetUtils";
import NTextarea from "@/components/Textarea/NTextarea.vue";
import ProjeSelectBox from "@/modules/Proje/components/ProjeSelectBox.vue";
import NFormFieldLabelTop from "@/components/FormField/NFormFieldLabelTop.vue";
import { useCariService } from "@/api/services/CariService";
import { CariEntity } from "@/api/models/CariEntity";
import TutarsalBilgiler from "@/modules/Ortak/components/TutarsalBilgiler.vue";
import { IslemHesaplamaEntity } from "@/api/models/IslemHesaplamaEntity";
import { RecordStatus } from "@/api/models/RecordStatus";
import { alert } from "devextreme/ui/dialog";
import {useDovizKurService} from "@/api/services/DovizKurService";


export default defineComponent({
  components: {
    TutarsalBilgiler,
    NFormFieldLabelTop,
    ProjeSelectBox,
    NTextarea,
    NModalDialog,
    NTag,
    CariSelectBox,
    KasaSelectBox,
    NGrid,
    NFormGrid,
    BankaSelectBox
  },
  props: {
    cekSenetler: {
      type: Array
    },
    islem: {
      type: Number
    },
    show: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ""
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      model: ref({} as CekSenetHareketView),
      cekSenetDurumListRef: CekSenetDurumList,
      cekSenetDurumRef: CekSenetDurum,
      cekSenetlerRef: computed(() => props.cekSenetler as CekSenetFlatView[]),
      islemRef: ref(props.islem),
      showRef: computed({
        get: () => props.show,
        set: val => emit("update:show", val)
      }),
      dovizList: DovizList,
      disabledReferansId: ref(false),
      referansId: ref("" as string),
      cariHesaplar: ref([] as string[]),
      cariIsler: ref([] as string[]),
      genelResmiMuhasebeTipList: GenelResmiMuhasebeTipList,
      ortalamaVade: ref(new Date() as Date),
      ortalamaVadeGun: ref(0 as number),
      toplamYerelTutar: ref(0 as number),
      kurlar: ref([] as KurView[]),
    });


    load();

    async function load() {
      const evrakNo = await useEvrakNoService().getYeniEvrakNo(10);
      localState.kurlar = await useKurCachedServis().getKurList();

      if (props.id && props.id.length > 0) {
        localState.model = await useCekSenetHareketService().getHareketView(props.id as string) as CekSenetHareketView;

        return;
      }


      let aciklama = "";
      let bankaId = "";
      if (localState.cekSenetlerRef && localState.cekSenetlerRef.length > 0) {
        localState.cekSenetlerRef.forEach((cekSenet: CekSenetFlatView) => {
          if (cekSenet.evrakNo && cekSenet.evrakNo.length > 0)
            aciklama += " " + cekSenet.evrakNo;
          if (cekSenet.ustAciklama && cekSenet.ustAciklama.length > 0)
            aciklama += " " + cekSenet.ustAciklama;
          if (cekSenet.cekSenetAciklama && cekSenet.cekSenetAciklama.length > 0)
            aciklama += " " + cekSenet.cekSenetAciklama;

          // console.log(cekSenet);


          if (localState.islemRef === CekSenetDurum.BankaTahsil && cekSenet.getSonDurum != CekSenetDurum.Portfoy) {

            const find = cekSenet.hareketler?.slice().reverse()?.find(a => a.status == RecordStatus.active && (a.durum == CekSenetDurum.BankaTeminat || a.durum == CekSenetDurum.BankaTakas) && (a.referansId != "" || a.referansId != null));

            if (find) {

              if (bankaId != "" && bankaId != find.referansId) {
                alert("Seçtiğiniz Bankalar Farklı , Lütfen Aynı Bankaya ait işlemleri seçiniz ", "Banka Seçimi");
                localState.showRef = false;
                return;
              }


              localState.disabledReferansId = true;
              localState.referansId = find.referansId as string;
              bankaId = find.referansId as string;

            }
          }


          // cekSenet.hareketler?.forEach((har: CekSenetHareketItem) => {
          //   if (har.durum == CekSenetDurum.BankaTakas) {
          //     if (har.referansId != "") {
          //       localState.referansId = har.referansId as string;
          //     }
          //   }
          // });
        });


        const cekSenetIds = localState.cekSenetlerRef.map(a => a.cekSenetId);


        if (aciklama.length > 0)
          aciklama = aciklama.trim();

        let hesaplama: IslemHesaplamaEntity = {
          islenecekDoviz: Doviz.TRY,
          islenecekKur: 1
        };


        if (localState.cekSenetlerRef.length == 1) {
          hesaplama = localState.cekSenetlerRef[0]?.hesaplama as IslemHesaplamaEntity;
        }


        localState.model = {
          tarih: new Date().toISOString(),
          cekSenetId: cekSenetIds as string[],
          referansId: localState.referansId,
          evrakNo: evrakNo as string,
          aciklama: aciklama,
          genelResmiMuhasebeTip: GenelResmiMuhasebeTip.Genel,
          hesaplama: hesaplama
        };
      }


      if (localState.cekSenetlerRef && localState.cekSenetlerRef.length > 0) {
        let toplamYerelTutar = 0;
        localState.cekSenetlerRef.forEach(item => {
          if (item.hesaplama) {
            toplamYerelTutar += (item.hesaplama.islemTutar as number) * (item.hesaplama.islemKur as number);
          }
        });
        localState.toplamYerelTutar = toplamYerelTutar;
        ortalamaVadeGunHesapla();

        if (localState.cekSenetlerRef[0] && localState.cekSenetlerRef[0].cari && localState.cekSenetlerRef[0].cari.id && localState.cekSenetlerRef[0].cari.id.length > 0) {
          const cariId = localState.cekSenetlerRef[0].cari.id as string;
          const cari = await useCariService().getGetir(cariId) as CariEntity;
          if (cari) {
            localState.cariHesaplar = cari.hesaplar as string[];
            localState.cariIsler = cari.isler as string[];
          }
        }


      }


    }


    watch(() => localState.model.hesaplama?.islenecekDoviz, () => {
      if (localState.model.hesaplama?.islenecekDoviz != Doviz.TRY) {
        const kur = localState.kurlar.find(a => a.doviz == localState.model.hesaplama?.islenecekDoviz);
        if (kur)
          localState.model.hesaplama!.islenecekKur = kur.alis;
      } else
        localState.model.hesaplama.islenecekKur = 1;
    });


    function ortalamaVadeGunHesapla() {
      let total = 0;
      if (localState.cekSenetlerRef) {

        localState.cekSenetlerRef.forEach((satir: CekSenetItemView) => {
          total += CekSenetUtils.vadeTicks(
            satir.vadeTarihi as string,
            (satir.hesaplama?.islemKur as number) * (satir.hesaplama?.islemTutar as number)
          );
        });
        if (total > 0 && localState.toplamYerelTutar > 0)
          localState.ortalamaVade = new Date(total / localState.toplamYerelTutar);
      }

      if (localState.model && localState.model.tarih) {
        const tarihTime = Date.parse(localState.model.tarih.split("T")[0]);
        const vadeTime = Date.parse(localState.ortalamaVade.toString());

        const vadeGun = ((vadeTime - tarihTime) /
          1000 /
          60 /
          60 /
          24) as number;

        if (vadeGun >= 0)
          localState.ortalamaVadeGun = vadeGun;
        else
          localState.ortalamaVadeGun = 0;
      }
    }

    async function save() {
      if (localState.model.id && localState.model.id.length > 0) {
        await useCekSenetHareketService().postSonHareketGuncelle(localState.model);
        localState.showRef = false;
        return;
      }


      switch (localState.islemRef) {

        // Çek / Senet Genel Kendi İşlemleri
        case CekSenetDurum.Portfoy:
          await useCekSenetHareketService().postPortfoy(localState.model);
          break;
        case CekSenetDurum.CirodanTahsil:
          await useCekSenetHareketService().postCirodanTahsil(localState.model);
          break;
        case CekSenetDurum.Avukat:
          await useCekSenetHareketService().postAvukat(localState.model);
          break;
        case CekSenetDurum.AvukatTahsil:
          await useCekSenetHareketService().postAvukatTahsil(localState.model);
          break;
        case CekSenetDurum.Iade:
          await useCekSenetHareketService().postIade(localState.model);
          break;
        case CekSenetDurum.Karsiliksiz:
          await useCekSenetHareketService().postKarsiliksiz(localState.model);
          break;
        case CekSenetDurum.TeminattanTahsil:
          await useCekSenetHareketService().postTeminattanTahsil(localState.model);
          break;



        // Cari İle ilgili İşlemler
        case CekSenetDurum.Ciro:
          await useCekSenetHareketService().postCiro(localState.model);
          break;
        case CekSenetDurum.CariTeminat:
          await useCekSenetHareketService().postCariTeminat(localState.model);
          break;
        // Kasa İle İlgili İşlemler
        case CekSenetDurum.KasaKismenTahsil:
          await useCekSenetHareketService().postKasaKismenTahsil(localState.model);
          break;
        case CekSenetDurum.KasaTahsil:
          await useCekSenetHareketService().postKasaTahsil(localState.model);
          break;

        // Banka ile ilgili İşlemler
        case CekSenetDurum.BankaTakas:
          await useCekSenetHareketService().postBankaTakas(localState.model);
          break;
        case CekSenetDurum.BankaTahsil:
          await useCekSenetHareketService().postBankaTahsil(localState.model);
          break;
        case CekSenetDurum.BankaTeminat:
          await useCekSenetHareketService().postBankaTeminat(localState.model);
          break;

      }
      emit("save");

      localState.showRef = false;

    }

      async function evrakTarihiChanged(e: any) {
          if (e && e.value && e.value.length > 0) {
              const kurlar = await useDovizKurService().getKurlarDate(e.value) as KurView[];
              if (kurlar && kurlar.length > 0)
                  localState.kurlar = kurlar;
          }

          //console.log(e);
      }

    return {
      ...toRefs(localState),
      save,
      evrakTarihiChanged
    };
  }
});
