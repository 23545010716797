
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import {BankaHareketView} from "@/api/models/BankaHareketView";
import BankaSelectBox from "@/modules/Banka/components/BankaSelectBox.vue";
import {GenelResmiMuhasebeTipList} from "@/api/models/GenelResmiMuhasebeTip";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";
import TutarsalBilgiler from "@/modules/Ortak/components/TutarsalBilgiler.vue";
import {BankaUtils} from "@/modules/Banka/utils/BankaUtils";
import SubeSelectBox from "@/modules/Sube/components/SubeSelectBox.vue";
import {DateUtils} from "@/utils/date/DateUtils";

import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import NTag from "@/components/Tag/NTag.vue";
import {BankaEntity} from "@/api/models/BankaEntity";
import {useBankaService} from "@/api/services/BankaService";
import {MenuRefrenceType} from "@/modules/Ortak/models/Menu/MenuRefrenceType";
import KasaSelectBox from "@/modules/Kasa/components/KasaSelectBox.vue";
import PosSelectBox from "@/modules/Pos/components/PosSelectBox.vue";
import KrediKartiSelectBox from "@/modules/KrediKarti/components/KrediKartiSelectBox.vue";
import HizmetMasrafSelectBox from "@/modules/HizmetMasraf/components/HizmetMasrafSelectBox.vue";
import {useBankaHareketService} from "@/api/services/BankaHareketService";

import {useDovizKurService} from "@/api/services/DovizKurService";
import {KasaHareketTip} from "@/api/models/KasaHareketTip";
import {useHizmetMasrafHareketService} from "@/api/services/HizmetMasrafHareketService";
import {HizmetMasrafHareketEntity} from "@/api/models/HizmetMasrafHareketEntity";
import CokluDokuman from "@/modules/Ortak/components/CokluDokuman.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import {confirm} from "devextreme/ui/dialog";
import GiderYeriSelectBox from "@/modules/Ortak/components/GiderYeriSelectBox.vue";
import {BankaHareketTip} from "@/api/models/BankaHareketTip";
import NGrid from "@/components/Grid/NGrid.vue";

import {useKrediService} from "@/api/services/KrediService";
import ProjeSelectBox from "@/modules/Proje/components/ProjeSelectBox.vue";
import {useTaksitPlanService} from "@/api/services/TaksitPlanService";
import {TaksitPlanEntity} from "@/api/models/TaksitPlanEntity";
import {KrediView} from "@/api/models/KrediView";
import {KurView} from "@/api/models/KurView";

export default defineComponent({
    components: {
        ProjeSelectBox,
        NGrid,
        GiderYeriSelectBox,
        NLoadingPanel,
        CokluDokuman,
        HizmetMasrafSelectBox,
        KrediKartiSelectBox,
        PosSelectBox,
        KasaSelectBox,
        NTag,
        NModalDialog,
        BankaSelectBox,
        NSelectBox,
        TutarsalBilgiler,
        SubeSelectBox
    },
    props: {
        selectedBankaId: {
            type: String
        },
        show: {
            type: Boolean,
            default: false
        },
        hareketId: {
            type: String,
            default: ""
        },
        menu: {
            type: Object
        },
        tutar: {
            type: Number,
            default: 0
        },
        hizmetMasrafId: {
            type: String
        },
        taksitPlanId: {
            type: String,
            default: ""
        },
        referansId: {
            type: String,
            default: '',
        },  onlineBankaHareketId : {
        type:String ,
        default : '',
      }

    },
    setup(props, {emit}) {
        const localState = reactive({
            genelResmiMuhasebeTipList: ref(GenelResmiMuhasebeTipList),
            showRef: computed({
                get: () => props.show,
                set: val => emit("update:show", val)
            }),
            model: ref({} as BankaHareketView),
            menuModel: ref(props.menu as MenuModel),
            menuRefrenceType: ref(MenuRefrenceType),
            utils: ref(BankaUtils),
            selectedBanka: ref({} as BankaEntity),
            selectedTab: ref(0),
            krediler: [] as KrediView[],
            kurlarRef: ref([] as KurView[])


        });

        async function load() {

            if (props.selectedBankaId && props.selectedBankaId.length > 0) {
                localState.selectedBanka = await useBankaService().get(
                    props.selectedBankaId
                );
            }


            localState.model = await localState.utils.getBankaHareketView(
                localState.selectedBanka.id as string,
                props.hareketId as string,
                props.tutar as number
            );

            if (localState.menuModel && localState.model && localState.model.id) {
                if (localState.menuModel.hareketTipi == BankaHareketTip.BankadanMasrafOdeme || localState.menuModel.hareketTipi == BankaHareketTip.BankayaHizmetTahsilati) {
                    const hizmetMasrafHareket = await useHizmetMasrafHareketService().getReferansId(localState.model.id) as HizmetMasrafHareketEntity;
                    localState.model.giderYeri = hizmetMasrafHareket.giderYeri;
                }
            }
            if (localState.menuModel && localState.menuModel.hareketTipi && localState.menuModel.hareketTipi == BankaHareketTip.BankadanBankaKredisiOdeme) {

                const krediler = await useKrediService().getList() as KrediView[];
                localState.krediler = krediler?.filter(a => a.bankaId == localState.model.bankaId);
                if (localState.model && localState.model.id && localState.model.id.length > 0) {
                    const odemeHareketi = await useTaksitPlanService().getOdemeItemByReferansId(localState.model.id);
                    localState.model.faizTutar = odemeHareketi?.odenenTutarDiger;
                }

            }


            if (localState.model.id && localState.model.id.length > 0) {
                //
            } else {
                localState.model.referansId = props.hizmetMasrafId;
            }

            localState.model.taksitPlanId = props.taksitPlanId;

          if (props.onlineBankaHareketId && props.onlineBankaHareketId.length > 0 ){
            localState.model.onlineBankaHareketId = props.onlineBankaHareketId;
          }
        }


        async function save() {
            await localState.utils.dekontKaydet(
                localState.menuModel,
                localState.model
            );
            emit("saveRefresh");
            localState.showRef = false;

        }

        function kdvHesaplama() {
            setTimeout(() => {
                if (localState.model &&
                    localState.model.hesaplama &&
                    localState.model.hesaplama.islenecekTutar &&
                    localState.model.hesaplama.islenecekTutar
                ) {

                    if (localState.model.id && localState.model.id.length > 0) {
                        //
                    } else
                        localState.model!.kdvTutar! =
                            localState.model.hesaplama.islenecekTutar - (localState.model.hesaplama.islenecekTutar / (1 + (localState.model.kdv! / 100)));
                }

            }, 100);

        }


        watch(() => [localState.model.hesaplama, localState.model.kdv, localState.model.referansId], () => {
            kdvHesaplama();
        }, {deep: true});

        async function saveAndPrint() {
            await localState.utils.dekontKaydet(
                localState.menuModel,
                localState.model
            );
            emit("print");
            localState.showRef = false;
        }

        async function hareketDelete() {
            if (
                localState.model &&
                localState.model.id &&
                localState.model.id.length > 0
            ) {
                if (await confirm("Banka Hareketini Silinecek , Onaylıyor musunuz ? ", "Hareket Silme")) {
                    await useBankaHareketService().deleteSil(localState.model.id);
                    localState.showRef = false;
                }
            }
        }

        async function krediValueChanged(e: any) {
            const kredi = localState.krediler.find(a => a.id == e.value);


            if (kredi) {
                const taksitHareket = await useTaksitPlanService().getReferansHareketIdByTaksitler(kredi.id as string) as TaksitPlanEntity[];
                if (taksitHareket && taksitHareket.length > 0) {
                    const ilkTaksit = taksitHareket.find(a => a.taksitKalan! > 0);
                    if (ilkTaksit) {
                        localState.model.faizTutar = ilkTaksit.taksitKalanYerelTutarDiger as number;
                        localState.model.hesaplama!.islemTutar = ilkTaksit.taksitKalan;

                    }
                }

            }


        }

        function getBankaLabel() {
            if (
                localState.menuModel.hareketTipi == BankaHareketTip.BankadanVirmanTahsilati ||
                localState.menuModel.hareketTipi == BankaHareketTip
            )
                return "Veren Banka";
            else
                return "Alan Banka";
        }

        async function evrakTarihiChanged(e: any) {
            if (e && e.value && e.value.length > 0) {
                const kurlar = await useDovizKurService().getKurlarDate(e.value) as KurView[];
                if (kurlar && kurlar.length > 0)
                    localState.kurlarRef = kurlar;
            }

            //console.log(e);
        }

        return {
            ...toRefs(localState),
            getBankaLabel,
            krediValueChanged,
            hareketDelete,
            save,
            saveAndPrint,
            load,
            evrakTarihiChanged
        };
    }
});
