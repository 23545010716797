
import {computed, defineComponent, onMounted, reactive, ref, toRefs, watch} from "vue";
import {FaturaView} from "@/api/models/FaturaView";
import FaturaBilgiItemComponent from "@/modules/Fatura/components/FaturaBilgiItemComponent.vue";
import ProjeSelectBox from "@/modules/Proje/components/ProjeSelectBox.vue";
import DepoSelectBox from "@/modules/Ortak/components/DepoSelectBox.vue";
import GenelResmiMuhasebeSelectBox from "@/modules/Ortak/components/GenelResmiMuhasebeSelectBox.vue";
import PlasiyerSelectBox from "@/modules/Plasiyer/components/PlasiyerSelectBox.vue";
import NInput from "@/components/Input/NInput.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NIcon from "@/components/Icon/NIcon.vue";
import FaturaViewExtend from "@/modules/Fatura/models/FaturaViewExtend";
import {confirm} from "devextreme/ui/dialog";
import {KurView} from "@/api/models/KurView";
import {useDovizKurService} from "@/api/services/DovizKurService";
import FiyatGruplariSelectBox from "@/modules/Ortak/components/FiyatGruplariSelectBox.vue";
import {CariEntity} from "@/api/models/CariEntity";
import NFormFieldLabelTop from "@/components/FormField/NFormFieldLabelTop.vue";
import NSwitch from "@/components/Switch/NSwitch.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";


export default defineComponent({
  components: {
    NSwitch,
    FiyatGruplariSelectBox,
    NIcon,
    NFormField,
    NInput,
    PlasiyerSelectBox, GenelResmiMuhasebeSelectBox, DepoSelectBox, ProjeSelectBox, FaturaBilgiItemComponent
  },
  props: {
    modelValue: {
      type: Object
    },
    subeId: {
      type: String
    },
    depoId: {
      type: String
    },
    plasiyerId: {
      type: String
    },
    cari: {
      type: Object
    },
    kurlar: {
      type: Array
    },

  },
  setup(props, {emit}) {
    const localState = reactive({
      oran: ref(0),
      tutar: ref(0),
      kurlarRef: computed({
        get: () => props.kurlar as KurView[],
        set: (val) => emit("update:kurlar", val)
      }),
      model: computed({
        get: () => props.modelValue as FaturaViewExtend,
        set: (val) => emit("update:modelValue", val)
      }),
      cariRef: computed(() => props.cari as CariEntity),
      subeIdRef: ref(""),
      depoIdRef: ref(""),
      plasiyerIdRef: computed({
        get: () => props.plasiyerId,
        set: (val) => emit("update:plasiyerId", val)
      }),
      maliyetYuzde: ref(0 as number),
      fiyatGrubuId: ref(""),

    });

    onMounted(() => {
      if (localState.cariRef.fiyatGrupId && localState.cariRef.fiyatGrupId.length && (localState.model.id == null || localState.model.id == "")) {
        localState.fiyatGrubuId = localState.cariRef.fiyatGrupId;
      }
    });

    watch(() => localState.depoIdRef, val => {
          if (val && val.length > 0)
            emit("update:depoId", val);
        }
    );
    watch(() => localState.subeIdRef, val => {
          if (val && val.length > 0)
            emit("update:subeId", val);
        }
    );
    watch(() => localState.maliyetYuzde, val => {
      const toplamKdvSizFiyat = localState.model.getOdenecekTutar() - localState.model.getKdvTutar();
      if (toplamKdvSizFiyat > 0) {
        const hesaplananMaliyet = (localState.maliyetYuzde * toplamKdvSizFiyat / 100);
        if (hesaplananMaliyet > 0)
          localState.model.faturaMaliyet = hesaplananMaliyet;
      }

    });

    async function valueChangedDepoId(e: any) {

      if (e.previousValue && e.previousValue.length > 0) {
        if (await confirm("Satırdaki Depolda Değiştirilsin mi ?", "Genel Depo Değiştirme")) {
          localState.model.satirlar?.forEach((har: any) => {
            har.depoId = e.value;
          });
        }
      }

    }

    async function evrakTarihiChanged(e: any) {
      if (e && e.value && e.value.length > 0) {
        const kurlar = await useDovizKurService().getKurlarDate(e.value) as KurView[];
        if (kurlar && kurlar.length > 0)
          localState.kurlarRef = kurlar;
      }

      //console.log(e);
    }

    function vadeChanged(e: any) {

      if (e.event.originalEvent.key == "Enter" && e.event.target?.value) {
        const day = e.event.target.value as string;
        if (localState.model.ustBilgi) {
          const yeniVade = getAddedDay(day)
          if (yeniVade)
            localState.model.ustBilgi.vade = yeniVade
        }
      }
    }

    function getAddedDay(day: string) {
      if (!day.includes('.') && localState.model.ustBilgi && day.length < 4) {

        let gun = parseInt(day)
        if (!gun) gun = 0;

        let tarihMs = Date.parse(localState.model.ustBilgi.tarih as string);
        tarihMs += (gun * 60 * 60 * 24 * 1000);

        const vadeTarihi = new Date(tarihMs)
        return vadeTarihi.toISOString();
      }
      //return  new Date().toISOString();
    }
      function  fiyatGrubuOnValueChanged(data:any){
        emit("fiyatGrubuOnValueChanged" , data);
      }

    return {
      ...toRefs(localState),
      evrakTarihiChanged,
      valueChangedDepoId,
      vadeChanged,
      fiyatGrubuOnValueChanged
    };
  }
});
