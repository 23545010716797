
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import NDropDownTreeView from "@/components/DropDownTreeView/NDropDownTreeView.vue";
import { useHepsiBuradaService } from "@/api/services/HepsiBuradaService";
import { HepsiBuradaCategoryModel } from "@/api/models/HepsiBuradaCategoryModel";
import HepsiBuradaOzellikEditor from "@/modules/Pazaryerleri/HepsiBurada/components/HepsiBuradaOzellikEditor.vue";
import StokPazaryeriFiyatItem from "@/modules/Stok/components/StokPazaryeriFiyatItem.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NRefresher from "@/components/Refresher/NRefresher.vue";
import {HepsiBuradaPazaryeriItem} from "@/api/models/HepsiBuradaPazaryeriItem";
import {StokEntity} from "@/api/models/StokEntity";
import NCheckbox from "@/components/Checkbox/NCheckbox.vue";

export default defineComponent({
  components: {NCheckbox, NRefresher, NFormField, NFormGrid, HepsiBuradaOzellikEditor, NDropDownTreeView },
  props: ['hepsiBurada','stok'],
  setup(props) {

    const localState = reactive({
        hepsiBuradaRef:computed(() => props.hepsiBurada as HepsiBuradaPazaryeriItem),
        stokRef:computed(()=> props.stok as StokEntity),
        kategoriler:ref([] as HepsiBuradaCategoryModel[]),
    });


    async function loadKategoriler(cache:number){

      localState.kategoriler =  await useHepsiBuradaService().getKategorilerTree(cache);
    }


    function markaPlaceHolder(){
      if (localState.stokRef && localState.stokRef.ozellikler && localState.stokRef.ozellikler.length > 0)
      {
        const marka = localState.stokRef.ozellikler.find(a => a.name == "Marka");
        if (marka){
          return marka.value;
        }
      }
      return "";
    }


    loadKategoriler(365)
    return {
      ...toRefs(localState),
      loadKategoriler,
      markaPlaceHolder
    };
  }
});

