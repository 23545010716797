
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import NCard from "@/components/Card/NCard.vue";
import NFormFieldLabelTop from "@/components/FormField/NFormFieldLabelTop.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import DepoSelectBox from "@/modules/Ortak/components/DepoSelectBox.vue";
import NTag from "@/components/Tag/NTag.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import { UretimStokEntity } from "@/api/models/UretimStokEntity";
import NButton from "@/components/Button/NButton.vue";
import { UretimStokView } from "@/api/models/UretimStokView";
import { useUretimStokService } from "@/api/services/UretimStokService";
import { alert } from "devextreme/ui/dialog";
import { useCachedSubeDepoService } from "@/modules/Sube/services/useCachedSubeDepoService";
import { IdName } from "@/api/models/IdName";
import { GenelUtils } from "@/modules/Ortak/utils/GenelUtils";

export default defineComponent({
  components: { NButton, NFormGrid, NTag, NFormFieldLabelTop, DepoSelectBox, NGrid, NModalDialog },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    cariId: {
      type: String,
      default: ""
    },
    tarih: {
      type: String,
      default: ""
    },
    depoId: {
      type: String,
      default: ""

    }

  },
  setup(props, { emit }) {
    const localState = reactive({
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val)

      }),
      model: {} as UretimStokView,
      faturaUretimSacDxDataGrid: {} as any,
      models: [] as UretimStokEntity[],
      depolar: [] as IdName[]


    });
    load();

    async function load() {
      localState.model = {
        cariId: "",
        adet: 1,
        birim: "Kilo",
        boy: 0,
        cins: "",
        genislik: 0,
        dokumanlar: "",
        etiket: "",
        kalinlik: 0,
        kalite: "",
        plaka: "",
        aciklama: "",
        agirlik: 0,
        boyAciklama: "R",
        depoId: props.depoId,
        tarih : props.tarih ,
        altBilgi: {}

      };
      localState.depolar = await useCachedSubeDepoService().getDepoList();

    }

    async function satiraSacEkle() {
      const model = Object.assign({}, localState.model);
      model.cariId = props.cariId;


      if (model.etiket && model.etiket.length > 0) {
        const exist = await useUretimStokService().getExistByEtiketNo(model.etiket);
        if (exist) {
          await alert(model.etiket + " etiket numarası daha önce kullanmış", "UYARI!");
          return;
        } else {
          if (!(model.id && model.id.length > 0)) {
            const exist = localState.models.find(a => a.etiket == model.etiket);
            if (exist) {
              await alert(model.etiket + " etiket numarası daha önce kullanmış", "UYARI!");
              return;
            }
          }


        }
      }
      let gelenSacEntity = {} as UretimStokEntity;

      if (model.id && model.id.length > 0) {
        gelenSacEntity = await useUretimStokService().putPasifGuncelle(model) as UretimStokEntity;
        if (gelenSacEntity && gelenSacEntity.id && gelenSacEntity.id.length > 0) {
          const index = localState.models.findIndex(a => a.id == model.id);
          if (index > -1) {
            localState.models.splice(index, 1, gelenSacEntity);
            iptalEt();

          }

        }

      } else {
        gelenSacEntity = await useUretimStokService().postPasifEkle(model) as UretimStokEntity;
        localState.models.push(gelenSacEntity);
      }


      localState.faturaUretimSacDxDataGrid?.instance?.refresh();
    }

    function faturayaAktar() {
      emit("sacStoklariClick", localState.models);
      localState.showRef = false;
    }

    async function duzenle(id: string) {
      const view = localState.models.find(a => a.id == id);
      if (view)
        localState.model = await useUretimStokService().getGetirViewPassive(view.id as string);
    }

    async function sil(id: string) {
      if (await GenelUtils.silmeOnayiDiyalogu()) {
        const index = localState.models.findIndex(a => a.id == id);
        if (index > -1) {
          localState.models.splice(index, 1);
          localState.faturaUretimSacDxDataGrid?.instance?.refresh();
        }

      }
    }

    function iptalEt() {
      localState.model.id = "";
      localState.model.etiket = "";
    }

    return {
      ...toRefs(localState),
      satiraSacEkle, faturayaAktar,
      duzenle, sil,
      iptalEt

    };
  }
});
