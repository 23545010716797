import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "dx-card",
  style: {"height":"100%","min-height":"450px"}
}
const _hoisted_2 = { style: {"height":"300px","width":"800px","overflow":"auto"} }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["src", "onMouseenter"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSelection = _resolveComponent("DxSelection")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxGroupPanel = _resolveComponent("DxGroupPanel")!
  const _component_DxScrolling = _resolveComponent("DxScrolling")!
  const _component_DxSearchPanel = _resolveComponent("DxSearchPanel")!
  const _component_DxStateStoring = _resolveComponent("DxStateStoring")!
  const _component_DxPager = _resolveComponent("DxPager")!
  const _component_DxMasterDetail = _resolveComponent("DxMasterDetail")!
  const _component_DxColumnChooser = _resolveComponent("DxColumnChooser")!
  const _component_DxLookup = _resolveComponent("DxLookup")!
  const _component_DxColummn = _resolveComponent("DxColummn")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxTotalItem = _resolveComponent("DxTotalItem")!
  const _component_DxSummary = _resolveComponent("DxSummary")!
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NDxStateStoring = _resolveComponent("NDxStateStoring")!
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_StokFormDialog = _resolveComponent("StokFormDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_DxDataGrid, {
        style: {"height":"100%","width":"100%","font-weight":"normal"},
        "remote-operations": true,
        "data-source": _ctx.dataSource,
        "onUpdate:dataSource": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dataSource) = $event)),
        ref: "xmlStokListPageDataGrid",
        toolbarTemplate: "toolbarTemplate",
        "load-panel": {enabled:false}
      }, {
        masterDetailTemplate: _withCtx(({data}) => [
          _createElementVNode("div", null, [
            _createElementVNode("pre", _hoisted_2, _toDisplayString(data.data), 1)
          ])
        ]),
        aktarildi: _withCtx(({data}) => [
          (data.data.stokId && data.data.stokId.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_NTag, {
                  type: "success",
                  filled: "true"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Aktarıldı")
                  ]),
                  _: 1
                }),
                _createVNode(_component_NTag, {
                  style: {"margin-left":"4px","cursor":"pointer"},
                  title: "Stok düzenle",
                  filled: true,
                  type: "info",
                  onClick: ()=> _ctx.stokAktarDialog(data.data)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NIcon, {
                      type: "link",
                      size: "12px"
                    })
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_NTag, {
                  type: "info",
                  filled: false
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Aktarılmadı")
                  ]),
                  _: 1
                }),
                _createVNode(_component_NTag, {
                  style: {"margin-left":"4px","cursor":"pointer"},
                  title: "Stok aktar",
                  filled: true,
                  type: "info",
                  onClick: ()=> _ctx.stokAktarDialog(data.data)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NIcon, {
                      type: "link",
                      size: "12px"
                    })
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]))
        ]),
        resim: _withCtx(({data}) => [
          (data.data.xmlView?.resimler && data.data.xmlView.resimler?.length > 0)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: data.data.xmlView.resimler[0],
                height: "20",
                onMouseenter: (e)=> _ctx.mouseEnter(e,data.data.xmlView.resimler[0]),
                onMouseout: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mouseOut && _ctx.mouseOut(...args)))
              }, null, 40, _hoisted_5))
            : _createCommentVNode("", true)
        ]),
        toolbarTemplate: _withCtx(() => [
          _createVNode(_component_NGrid, {
            cols: "auto auto auto auto auto 1fr",
            gap: "8px",
            rows: "34px"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NDxStateStoring, { "data-grid-ref": _ctx.xmlStokListPageDataGrid }, null, 8, ["data-grid-ref"]),
              _createVNode(_component_DxTextBox, {
                placeholder: "Stok Adı, Kod, Barkod ile ara... [ENTER]",
                value: _ctx.searchTextRef,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchTextRef) = $event)),
                width: 300,
                "show-clear-button": true,
                valueChangeEvent: "input"
              }, null, 8, ["value"]),
              _createVNode(_component_NButton, {
                size: "small",
                icon: "x-circle",
                type: "danger",
                onClick: _ctx.clearFilter
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Filtreleri Temizle ")
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_NButton, {
                title: "Yenile",
                icon: "refresh-cw",
                type: "info",
                click: _ctx.refresh,
                loading: _ctx.isLoading,
                "onUpdate:loading": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isLoading) = $event))
              }, null, 8, ["click", "loading"])
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_DxSelection, {
            mode: "multiple",
            "allow-select-all": true,
            deferred: true,
            "show-check-boxes-mode": "always"
          }),
          _createVNode(_component_DxHeaderFilter, {
            visible: true,
            search: {enabled:true},
            "allow-search": true
          }),
          _createVNode(_component_DxFilterRow, { visible: true }),
          _createVNode(_component_DxGroupPanel, { visible: true }),
          _createVNode(_component_DxScrolling, { mode: "virtual" }),
          _createVNode(_component_DxSearchPanel, { visible: false }),
          _createVNode(_component_DxStateStoring, {
            enabled: true,
            type: "custom",
            "custom-load": () =>  _ctx.loadState()
          }, null, 8, ["custom-load"]),
          _createVNode(_component_DxPager, {
            visible: false,
            "show-page-size-selector": true,
            "show-navigation-buttons": true
          }),
          _createVNode(_component_DxMasterDetail, {
            enabled: true,
            template: "masterDetailTemplate"
          }),
          _createVNode(_component_DxColumnChooser, {
            enabled: true,
            mode: "select"
          }),
          _createVNode(_component_DxColummn, {
            caption: "Kaynak",
            "data-field": "configId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxLookup, {
                datasource: _ctx.configs,
                displayExpr: "name",
                valueExpr: "id"
              }, null, 8, ["datasource"])
            ]),
            _: 1
          }),
          _createVNode(_component_DxColumn, {
            width: "260",
            caption: "Id",
            "data-field": "id",
            visible: false
          }),
          _createVNode(_component_DxColumn, {
            width: "130",
            caption: "Servis Adı",
            "data-field": "configId"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxLookup, {
                "data-source": _ctx.configs,
                "display-expr": "name",
                "value-expr": "id"
              }, null, 8, ["data-source"])
            ]),
            _: 1
          }),
          _createVNode(_component_DxColumn, {
            width: "130",
            caption: "Stok Id",
            "data-field": "stokId",
            "cell-template": "aktarildi"
          }),
          _createVNode(_component_DxColumn, {
            width: "260",
            caption: "Stok Adı",
            "data-field": "xmlView.ad"
          }),
          _createVNode(_component_DxColumn, {
            width: "70",
            caption: "Resim",
            "data-field": "xmlView.resimler",
            "cell-template": "resim"
          }),
          _createVNode(_component_DxColumn, {
            width: "120",
            caption: "Barkod",
            "data-field": "xmlView.barkod"
          }),
          _createVNode(_component_DxColumn, {
            width: "100",
            caption: "Kod",
            "data-field": "xmlView.kod"
          }),
          _createVNode(_component_DxColumn, {
            width: "75",
            caption: "Miktar",
            "data-field": "xmlView.miktar"
          }),
          _createVNode(_component_DxColumn, {
            width: "100",
            caption: "Fiyat",
            "data-field": "xmlView.fiyat",
            format: "#,##0.00"
          }),
          _createVNode(_component_DxColumn, {
            width: "100",
            caption: "Marka",
            "data-field": "xmlView.marka"
          }),
          _createVNode(_component_DxColumn, {
            width: "100",
            caption: "Ana Kategori",
            "data-field": "xmlView.anaKategori"
          }),
          _createVNode(_component_DxColumn, {
            width: "100",
            caption: "Alt Kategori",
            "data-field": "xmlView.altKategori"
          }),
          _createVNode(_component_DxColumn, {
            width: "100",
            caption: "Kısa Açıklama",
            "data-field": "xmlView.kisaAciklama"
          }),
          _createVNode(_component_DxColumn, {
            width: "100",
            caption: "Açıklama",
            "data-field": "xmlView.aciklama"
          }),
          _createVNode(_component_DxColumn, {
            width: "100",
            caption: "Üretici Ad",
            "data-field": "uretici.cari.xmlView.name"
          }),
          _createVNode(_component_DxColumn, {
            width: "100",
            caption: "Üretici Kod",
            "data-field": "xmlView.ureticiKod"
          }),
          _createVNode(_component_DxColumn, {
            width: "100",
            caption: "Üretici Barkod",
            "data-field": "xmlView.ureticiBarkod"
          }),
          _createVNode(_component_DxColumn, {
            width: "100",
            caption: "Üretici Stok Ad",
            "data-field": "xmlView.ureticiStokAd"
          }),
          _createVNode(_component_DxColumn, {
            width: "160",
            caption: "Kayıt Tarihi",
            "data-field": "kayitTarihi",
            "data-type": "datetime"
          }),
          _createVNode(_component_DxSummary, null, {
            default: _withCtx(() => [
              _createVNode(_component_DxTotalItem, {
                column: "xmlView.ad",
                "summary-type": "count"
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data-source"])
    ]),
    (_ctx.showStokForm)
      ? (_openBlock(), _createBlock(_component_StokFormDialog, {
          key: 0,
          onStokEntityAddedFromXml: _ctx.stokEntityAddedFromXml,
          "stok-view": _ctx.xmlStokView,
          "onUpdate:stokView": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.xmlStokView) = $event)),
          "stok-id": _ctx.selectedStokId,
          "onUpdate:stokId": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedStokId) = $event)),
          "xml-config": _ctx.selectedConfig,
          "onUpdate:xmlConfig": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedConfig) = $event)),
          "xml-data-id": _ctx.selectedDataId,
          "onUpdate:xmlDataId": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectedDataId) = $event)),
          "show-form": _ctx.showStokForm,
          "onUpdate:showForm": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showStokForm) = $event))
        }, null, 8, ["onStokEntityAddedFromXml", "stok-view", "stok-id", "xml-config", "xml-data-id", "show-form"]))
      : _createCommentVNode("", true)
  ], 64))
}