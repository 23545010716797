
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import { SubeEntity } from "@/api/models/SubeEntity";
import { useSubeService } from "@/api/services/SubeService";
import { dxDataGridColumn } from "devextreme/ui/data_grid";
import { StokMiktarOzetItem } from "@/api/models/StokMiktarOzetItem";
import { StokFlatView } from "@/api/models/StokFlatView";
import { useStokFlatStore } from "@/modules/Stok/store/StokFlatStore";
import _ from "lodash";
import DataSource from "devextreme/data/data_source";
import {DxColumn, DxDataGrid} from "devextreme-vue/data-grid";

export default defineComponent({
  components: { NModalDialog },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    stokId: {
      type: String,
      default: ""
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      showRef: computed({
        get: () => props.show,
        set: val => emit("update:show", val)
      }),
      stokOzeDataGrid: ref([] as any[]),
      miktarOzetleri: ref([] as StokMiktarOzetItem[]),
      subeler: ref([] as SubeEntity[]),
      columns: ref([] as dxDataGridColumn[]),
      dataSource: ref(),
      stokIdRef:computed(() => props.stokId),
    });

    load();

    async function load() {



      localState.subeler = await useSubeService().getListe();
      getColumnAndData();

      if (localState.stokIdRef && localState.stokIdRef.length > 0 ){ // Sadece 1 adet stok
        localState.dataSource = useStokFlatStore().getQuery().filter((a:any) => a.stokId == localState.stokIdRef).toArray();
      }else{
        localState.dataSource = useStokFlatStore().generateDataSource();
      }
    }

    function getColumnAndData() {
      localState.stokOzeDataGrid = [],
        localState.columns = [
          {
            width: "250",
            alignment: "left",
            caption: "Stok Adi",
            dataField: "ad",
            allowHeaderFiltering: true,
            allowFiltering: true,
            allowSorting: true,
            allowExporting: true,
            allowHiding: true,

            allowSearch: true,
            headerFilter: {
              allowSearch: true
            }
          },
          {
            width: "100",
            format: (a:any) => (a as number)?.format(2, 2),
            dataType: "number",
            caption: "Genel Bakiye",
            dataField: "kalanMiktar",
            allowHeaderFiltering: true,
            allowSorting: true,
            allowFiltering: true,
            headerFilter: {
              allowSearch: true
            }
          }
        ];

      localState.subeler.forEach((sube: SubeEntity) => {
        if (sube.depolar && sube.depolar.length > 0) {

          const depoColumn: any[] = [];
          sube.depolar?.forEach((depo) => {
            depoColumn.push({
              width: "auto",
              caption: depo.name,
              dataType: "number",
              allowHeaderFiltering: true,
              allowSorting: true,
              allowFiltering: true,
              headerFilter: {
                allowSearch: true
              },
              calculateCellValue: (d:any) => bakiyeBul(sube.id as string, depo.id as string, d)?.format(2, 2)
            });
          });

          const subeColumn: any = {
            width: "auto",
            alignment: "center",
            caption: sube.name,
            columns: depoColumn,
          };
          localState.columns.push(subeColumn);
        }
      });
      const subeBazliColumn: any = {
        width: "auto",
        caption: "Şube Bazlı Bakiyeler",
        alignment: "center",
        columns: []
      };

      localState.subeler.forEach((sube: SubeEntity) => {
        const subeColumn: any = {
          width: "auto",
          alignment: "center",
          caption: sube.name,
          allowSorting: true,
          allowFiltering: true,
          allowHeaderFiltering: true,
          headerFilter: {
            allowSearch: true
          },
          calculateCellValue: (d:any) => subeBazliBakiyeBul(sube.id as string, d)?.format(2, 2)
        };
        subeBazliColumn.columns?.push(subeColumn);

      });
      localState.columns.push(subeBazliColumn);


    }

    function subeBazliBakiyeBul(subeId: string, stok: StokFlatView) {
      const findSubeBazli = stok.miktarOzet?.filter(a => a.sube!.id == subeId).map(a => a.kalanMiktar);
      if (findSubeBazli) {
        return _.sum(findSubeBazli);
      } else
        return 0;

    }

    function bakiyeBul(subeId: string, depoId: string, stok: StokFlatView) {
      const findDepoSube = stok.miktarOzet?.find(a => a.depo!.id == depoId && a.sube!.id == subeId);
      if (findDepoSube) {
        return findDepoSube.kalanMiktar;
      } else
        return 0;

    }


    return {
      ...toRefs(localState)
    };
  }
});
