import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UretimTanim = _resolveComponent("UretimTanim")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    modelValue: _ctx.showRef,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showRef) = $event)),
    width: "680px"
  }, {
    header: _withCtx(() => [
      _createTextVNode("Üretim Tanımları ")
    ]),
    body: _withCtx(() => [
      _createVNode(_component_UretimTanim)
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_NButton, {
        size: "small",
        type: "warning",
        icon: "x",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showRef = false ))
      }, {
        default: _withCtx(() => [
          _createTextVNode("İptal")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}