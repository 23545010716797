
import { defineComponent } from "vue";

export default defineComponent({
  name: "NListItem",
  props: ["item", "selected"],
  setup(props, { emit }) {
    function selectItem() {
      emit("select", props.item);
    }
    return {
      selectItem
    };
  }
});
