import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxEditing = _resolveComponent("DxEditing")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxGroupPanel = _resolveComponent("DxGroupPanel")!
  const _component_DxExport = _resolveComponent("DxExport")!
  const _component_DxKeyboardNavigation = _resolveComponent("DxKeyboardNavigation")!
  const _component_DxStateStoring = _resolveComponent("DxStateStoring")!
  const _component_DxLookup = _resolveComponent("DxLookup")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_NCard = _resolveComponent("NCard")!

  return (_openBlock(), _createBlock(_component_NCard, { title: _ctx.title }, {
    default: _withCtx(() => [
      (_ctx.modelValue && _ctx.modelValue.subeFiyatlari)
        ? (_openBlock(), _createElementBlock("div", _mergeProps({ key: 0 }, _ctx.$attrs, {
            class: "dx-card",
            style: {"height":"100%"}
          }), [
            _createVNode(_component_DxDataGrid, {
              "data-source": _ctx.modelRef.subeFiyatlari,
              disabled: _ctx.disabled,
              style: {"height":"100%","width":"100%","min-height":"100px"},
              "key-expr": "subeId"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxEditing, {
                  "allow-updating": true,
                  mode: "cell",
                  "allow-deleting": false
                }),
                _createVNode(_component_DxHeaderFilter, { visible: false }),
                _createVNode(_component_DxFilterRow, { visible: false }),
                _createVNode(_component_DxGroupPanel, { visible: false }),
                _createVNode(_component_DxExport, { enabled: false }),
                _createVNode(_component_DxKeyboardNavigation, {
                  "edit-on-key-press": true,
                  "enter-key-action": "moveFocus",
                  "enter-key-direction": "column"
                }),
                _createVNode(_component_DxStateStoring, {
                  enabled: true,
                  storageKey: "StokForm_SubeFiyatlari"
                }),
                _createVNode(_component_DxColumn, {
                  "data-field": "subeId",
                  caption: "Şube",
                  "allow-editing": false
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxLookup, {
                      "data-source": _ctx.subeler,
                      "display-expr": "name",
                      "value-expr": "id"
                    }, null, 8, ["data-source"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_DxColumn, {
                  "data-field": "deger",
                  caption: "Değer",
                  "allow-editing": true
                }),
                _createVNode(_component_DxColumn, {
                  "data-field": "tip",
                  caption: "Fiyat Tipi",
                  "allow-editing": true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxLookup, {
                      "data-source": _ctx.subeFiyatTipList,
                      "display-expr": "description",
                      "value-expr": "value"
                    }, null, 8, ["data-source"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data-source", "disabled"])
          ], 16))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}