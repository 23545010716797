import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "dx-card",
  style: {"width":"100%","height":"100%"}
}
const _hoisted_2 = {
  style: {"text-decoration":"none"},
  href: "https://dakinet.com",
  target: "_blank"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { style: {"display":"none"} }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  style: {"text-align":"right"},
  class: "text-3"
}
const _hoisted_8 = ["id"]
const _hoisted_9 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_DxLoadPanel = _resolveComponent("DxLoadPanel")!
  const _component_DxGroupPanel = _resolveComponent("DxGroupPanel")!
  const _component_DxGrouping = _resolveComponent("DxGrouping")!
  const _component_DxScrolling = _resolveComponent("DxScrolling")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxColumnChooser = _resolveComponent("DxColumnChooser")!
  const _component_DxSearchPanel = _resolveComponent("DxSearchPanel")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_DxTotalItem = _resolveComponent("DxTotalItem")!
  const _component_DxSummary = _resolveComponent("DxSummary")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_NViewGrid = _resolveComponent("NViewGrid")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!
  const _component_NBadge = _resolveComponent("NBadge")!
  const _component_NCard = _resolveComponent("NCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NViewGrid, {
      cols: "minmax(0,1fr)",
      rows: "minmax(0,1fr)",
      style: {"padding":"5px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NCard, null, {
          top: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.hareketler[0]?.cari?.name) + " ", 1),
            _createVNode(_component_NTag, {
              filled: true,
              type: "success"
            }, {
              default: _withCtx(() => [
                _createTextVNode("Online Ekstre")
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_NLoadingPanel, {
              action: _ctx.load,
              title: "Online Ekstre Yükleniyor..."
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_NViewGrid, {
                    cols: "minmax(0, 1fr)",
                    rows: "minmax(0,1fr)"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DxDataGrid, {
                        "data-source": _ctx.hareketler,
                        width: "100%",
                        height: "100%"
                      }, {
                        efaturaGoster: _withCtx(({data}) => [
                          _createVNode(_component_NGrid, {
                            cols: "22px auto",
                            gap: "5px"
                          }, {
                            default: _withCtx(() => [
                              (data.data.tip === _ctx.cariHareketTip.Satis_Faturasi)
                                ? (_openBlock(), _createBlock(_component_NTag, {
                                    key: 0,
                                    style: {"cursor":"pointer"},
                                    filled: false,
                                    type: "success",
                                    onClick: ($event: any) => (_ctx.efaturaGoster(data.data.referansHareketId))
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_NIcon, {
                                        type: "search",
                                        size: "12",
                                        stroke: "#78b81c",
                                        title: "E-Fatura İndir (PDF)"
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", null, _toDisplayString(_ctx.cariHareketTipList.find(x => x.value == data.data.tip)?.description), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_DxLoadPanel, { enabled: true }),
                          _createVNode(_component_DxGroupPanel, { visible: false }),
                          _createVNode(_component_DxGrouping, { "auto-expand-all": false }),
                          _createVNode(_component_DxScrolling, { mode: "infinite" }),
                          _createVNode(_component_DxHeaderFilter, { visible: true }),
                          _createVNode(_component_DxFilterRow, { visible: true }),
                          _createVNode(_component_DxColumnChooser, { enabled: false }),
                          _createVNode(_component_DxSearchPanel, {
                            visible: true,
                            width: 240,
                            placeholder: "Arama..."
                          }),
                          _createVNode(_component_DxColumn, {
                            width: "120",
                            caption: "Tarih",
                            "data-field": "tarih",
                            "data-type": "date"
                          }),
                          _createVNode(_component_DxColumn, {
                            width: "120",
                            caption: "Evrak No",
                            "data-field": "evrakNo"
                          }),
                          _createVNode(_component_DxColumn, {
                            alignment: "left",
                            width: "auto",
                            "calculate-cell-value": (a) => _ctx.cariHareketTipList.find(x=>x.value == a.tip)?.description ,
                            caption: "Tip",
                            "data-field": "İşlem",
                            "cell-template": "efaturaGoster"
                          }, null, 8, ["calculate-cell-value"]),
                          _createVNode(_component_DxColumn, {
                            width: "100",
                            format: (a) => a.format(2,2),
                            caption: "Kur",
                            "data-field": "hesaplama.islemKur",
                            "calculate-cell-value": a=> a.hesaplama?.islenecekKur
                          }, null, 8, ["format", "calculate-cell-value"]),
                          _createVNode(_component_DxColumn, {
                            width: 120,
                            caption: "Borç",
                            "data-field": "getYerelBorc",
                            type: "number",
                            format: (d) => d.format(2,2)
                          }, null, 8, ["format"]),
                          _createVNode(_component_DxColumn, {
                            width: 120,
                            caption: "Alacak",
                            "data-field": "getYerelAlacak",
                            type: "number",
                            format: (d) => d.format(2,2)
                          }, null, 8, ["format"]),
                          _createVNode(_component_DxColumn, {
                            width: 120,
                            type: "number",
                            caption: "Bakiye",
                            "data-field": "yerelBakiye",
                            format: (d) => d.format(2,2)
                          }, null, 8, ["format"]),
                          _createVNode(_component_DxSummary, { "calculate-custom-summary": _ctx.calculateCustomSummary }, {
                            default: _withCtx(() => [
                              _createVNode(_component_DxTotalItem, {
                                column: "tarih",
                                "summary-type": "count"
                              }),
                              _createVNode(_component_DxTotalItem, {
                                column: "getTryBorc",
                                "summary-type": "sum",
                                "value-format": (a) => a.format(2,2),
                                "display-format": "₺ {0}"
                              }, null, 8, ["value-format"]),
                              _createVNode(_component_DxTotalItem, {
                                column: "getTryAlacak",
                                "summary-type": "sum",
                                "value-format": (a) => a.format(2,2),
                                "display-format": "₺ {0}"
                              }, null, 8, ["value-format"]),
                              _createVNode(_component_DxTotalItem, {
                                "show-in-column": "tryBakiye",
                                name: "tryBakiye",
                                "summary-type": "custom",
                                "value-format": (a) => a.format(2,2),
                                "display-format": "₺ {0}"
                              }, null, 8, ["value-format"]),
                              _createVNode(_component_DxTotalItem, {
                                column: "getUsdBorc",
                                "summary-type": "sum",
                                "value-format": (a) => a.format(2,2),
                                "display-format": "$ {0}"
                              }, null, 8, ["value-format"]),
                              _createVNode(_component_DxTotalItem, {
                                column: "getUsdAlacak",
                                "summary-type": "sum",
                                "value-format": (a) => a.format(2,2),
                                "display-format": "$ {0}"
                              }, null, 8, ["value-format"]),
                              _createVNode(_component_DxTotalItem, {
                                "show-in-column": "usdBakiye",
                                name: "usdBakiye",
                                "summary-type": "custom",
                                "value-format": (a) => a.format(2,2),
                                "display-format": "$ {0}"
                              }, null, 8, ["value-format"]),
                              _createVNode(_component_DxTotalItem, {
                                column: "getEurBorc",
                                "summary-type": "sum",
                                "value-format": (a) => a.format(2,2),
                                "display-format": "€ {0}"
                              }, null, 8, ["value-format"]),
                              _createVNode(_component_DxTotalItem, {
                                column: "getEurAlacak",
                                "summary-type": "sum",
                                "value-format": (a) => a.format(2,2),
                                "display-format": "€ {0}"
                              }, null, 8, ["value-format"]),
                              _createVNode(_component_DxTotalItem, {
                                "show-in-column": "eurBakiye",
                                name: "eurBakiye",
                                "summary-type": "custom",
                                "value-format": (a) => a.format(2,2),
                                "display-format": "€ {0}"
                              }, null, 8, ["value-format"]),
                              _createVNode(_component_DxTotalItem, {
                                column: "getYerelBorc",
                                "summary-type": "sum",
                                "value-format": (a) => a.format(2,2),
                                "display-format": "{0} ₺"
                              }, null, 8, ["value-format"]),
                              _createVNode(_component_DxTotalItem, {
                                column: "getYerelAlacak",
                                "summary-type": "sum",
                                "value-format": (a) => a.format(2,2),
                                "display-format": "{0} ₺"
                              }, null, 8, ["value-format"]),
                              _createVNode(_component_DxTotalItem, {
                                "show-in-column": "yerelBakiye",
                                name: "yerelBakiye",
                                "summary-type": "custom",
                                "value-format": (a) => a.format(2,2),
                                "display-format": "₺ {0}"
                              }, null, 8, ["value-format"])
                            ]),
                            _: 1
                          }, 8, ["calculate-custom-summary"])
                        ]),
                        _: 1
                      }, 8, ["data-source"])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }, 8, ["action"])
          ]),
          bottom: _withCtx(() => [
            _createElementVNode("a", _hoisted_2, [
              _createVNode(_component_NGrid, { cols: "1fr auto" }, {
                default: _withCtx(() => [
                  _createVNode(_component_NGrid, { cols: "100px 100px auto" }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        style: {"height":"28px"},
                        src: require('@/assets/images/nesilce.png')
                      }, null, 8, _hoisted_3),
                      _createElementVNode("img", {
                        style: {"height":"28px"},
                        src: require('@/assets/images/dakinetLogo.png')
                      }, null, 8, _hoisted_4),
                      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.resizeCount), 1),
                      (_ctx.isDesktop())
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createVNode(_component_NGrid, { cols: "auto auto auto auto auto auto auto auto" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_NBadge, {
                                  type: "warning",
                                  value: "Muhasebe"
                                }),
                                _createVNode(_component_NBadge, {
                                  type: "success",
                                  value: "Pazaryeri Entegrasyonu"
                                }),
                                _createVNode(_component_NBadge, {
                                  type: "primary",
                                  value: "E-Ticaret"
                                }),
                                _createVNode(_component_NBadge, {
                                  type: "black",
                                  value: "E-Fatura"
                                }),
                                _createVNode(_component_NBadge, {
                                  type: "danger",
                                  value: "E-Arşiv"
                                }),
                                _createVNode(_component_NBadge, {
                                  type: "success",
                                  value: "E-Irsaliye"
                                }),
                                _createVNode(_component_NBadge, {
                                  type: "warning",
                                  value: "Kargo"
                                }),
                                _createVNode(_component_NBadge, {
                                  type: "black",
                                  value: "SMS"
                                })
                              ]),
                              _: 1
                            })
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_7, " Copyright © " + _toDisplayString(new Date().getFullYear()) + " Tüm Hakları Saklıdır. ", 1)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("form", {
      style: {"display":"none"},
      id: _ctx.formId,
      target: "_blank",
      method: "POST"
    }, [
      _createElementVNode("input", {
        name: 'faturaIds[0]',
        value: _ctx.selectedFaturaId
      }, null, 8, _hoisted_9)
    ], 8, _hoisted_8)
  ], 64))
}