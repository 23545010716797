import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';


export const useN11ShipmentService = defineStore({
id: 'N11ShipmentService',
actions: {


	async getGetir(cacheDay:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/N11Shipment/Getir/${shared.firm.id}/${cacheDay}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
