
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";
import {useBankaService} from "@/api/services/BankaService";
import {PosView} from "@/api/models/PosView";
import {DateUtils} from "@/utils/date/DateUtils";

export default defineComponent({
  components: {},
  props: {
    modelValue: {
      type: String
    },
    hareketId: {
      type: String
    },
    vade: {
      type: String
    },
    name:{
      type:String
    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      dateUtils: DateUtils,
      items: ref([] as PosView[]),
      selected: computed({
        get: () => props.modelValue,
        set: val => emit("update:modelValue", val)
      }),
      vadeRef: computed({
        get: () => props.vade,
        set: val => emit("update:vade", val)
      }),


    });

    async function load() {
      localState.items = await useBankaService().getPosList();
      if (props.modelValue && props.modelValue.length > 0) {
        //
      } else {
        if (localState.items && localState.items.length > 0)
          localState.selected = localState.items[0].posId;
      }
    }

    watch(
        () => props.modelValue,
        newVal => {
          if (props.modelValue) {
            if (props.hareketId && props.hareketId.length > 0) {
              //
            } else {
              const result = localState.items.find(
                  a => a.posId == props.modelValue
              );
              if (result){
                localState.vadeRef = localState.dateUtils.convertInputDateFromStringDate(result.hesabaGecmeTarihi as string);
                emit("update:name" , result.posAdi );
              }
            }
          }
        }
    );

    load();
    return {
      ...toRefs(localState)
    };
  }
});
