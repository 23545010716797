
export enum TaksitPlanDonemTip{
Bugun=0,
BuHafta=1,
BuAy=2,
Gecikenler=3,
Tumu=4
}

export const TaksitPlanDonemTipList = [
{key:'Bugun', value:0, description:''},
{key:'BuHafta', value:1, description:''},
{key:'BuAy', value:2, description:''},
{key:'Gecikenler', value:3, description:''},
{key:'Tumu', value:4, description:''}
]
