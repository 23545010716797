import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HizliSatisStokListesi = _resolveComponent("HizliSatisStokListesi")!
  const _component_HizliSatisIadeListesi = _resolveComponent("HizliSatisIadeListesi")!
  const _component_NCard = _resolveComponent("NCard")!

  return (_openBlock(), _createBlock(_component_NCard, _mergeProps({ tabs: _ctx.tabs }, _ctx.$attrs, {
    modelValue: _ctx.selectedTab,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTab) = $event))
  }), {
    default: _withCtx(() => [
      (_ctx.selectedTab===0)
        ? (_openBlock(), _createBlock(_component_HizliSatisStokListesi, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.selectedTab===1)
        ? (_openBlock(), _createBlock(_component_HizliSatisIadeListesi, { key: 1 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16, ["tabs", "modelValue"]))
}