import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdownMenu = _resolveComponent("NDropdownMenu")!
  const _component_NDropdown = _resolveComponent("NDropdown")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NViewToolbar = _resolveComponent("NViewToolbar")!
  const _component_StokGroup_GenelBilgiler = _resolveComponent("StokGroup_GenelBilgiler")!
  const _component_OzellikForm = _resolveComponent("OzellikForm")!
  const _component_NCard = _resolveComponent("NCard")!
  const _component_StokForm_Pazaryerleri = _resolveComponent("StokForm_Pazaryerleri")!
  const _component_StokGroupStoklar = _resolveComponent("StokGroupStoklar")!
  const _component_NViewGrid = _resolveComponent("NViewGrid")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!

  return (_openBlock(), _createBlock(_component_NLoadingPanel, {
    title: "Gruplu Stok Formu Hazırlanıyor...",
    action: _ctx.load
  }, {
    default: _withCtx(() => [
      _createVNode(_component_NViewGrid, {
        cols: "1fr 1fr",
        rows: "auto auto minmax(1px,1fr)",
        style: {"background":"#FFF","height":"100%"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NViewToolbar, { title: "Gruplu Stok Formu" }, {
            default: _withCtx(() => [
              _createVNode(_component_NGrid, {
                cols: "auto auto ",
                gap: "12px"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NDropdown, null, {
                    menu: _withCtx(() => [
                      _createVNode(_component_NDropdownMenu, null, {
                        default: _withCtx(() => [
                          (_ctx.isUpdateMode())
                            ? (_openBlock(), _createBlock(_component_NDropdownItem, {
                                key: 0,
                                onClick: _ctx.remove,
                                icon: "trash"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Sil")
                                ]),
                                _: 1
                              }, 8, ["onClick"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_NButton, {
                        type: "primary",
                        icon: "menu"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("İşlemler")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NButton, {
                    type: "success",
                    icon: "save",
                    onClick: _ctx.save
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Kaydet")
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_NCard, {
            col: "1fr",
            modelValue: _ctx.selectedTab,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedTab) = $event)),
            tabs: _ctx.genelTabs
          }, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_StokGroup_GenelBilgiler, {
                modelValue: _ctx.stok,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.stok) = $event)),
                "server-url": _ctx.serverUrl,
                "onUpdate:serverUrl": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.serverUrl) = $event))
              }, null, 8, ["modelValue", "server-url"]), [
                [_vShow, _ctx.selectedTab === 0]
              ]),
              _withDirectives(_createVNode(_component_OzellikForm, {
                ozellikler: _ctx.stok.ozellikler
              }, null, 8, ["ozellikler"]), [
                [_vShow, _ctx.selectedTab=== 1]
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "tabs"]),
          _createVNode(_component_StokForm_Pazaryerleri, {
            stok: _ctx.stok,
            "onUpdate:stok": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.stok) = $event)),
            "alis-kdv": _ctx.stok.satisKdv,
            "onUpdate:alisKdv": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.stok.satisKdv) = $event)),
            "satis-kdv": _ctx.stok.satisKdv,
            "onUpdate:satisKdv": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.stok.satisKdv) = $event)),
            "alis-kdv-dahil": true,
            "satis-kdv-dahil": true,
            "alis-fiyat": _ctx.getSatisFiyati(),
            "satis-fiyat": _ctx.getSatisFiyati(),
            style: {"grid-row":"span 2"}
          }, null, 8, ["stok", "alis-kdv", "satis-kdv", "alis-fiyat", "satis-fiyat"]),
          _createVNode(_component_StokGroupStoklar, {
            stok: _ctx.stok,
            "onUpdate:stok": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.stok) = $event))
          }, null, 8, ["stok"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["action"]))
}