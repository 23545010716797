
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NInput from "@/components/Input/NInput.vue";
import NButton from "@/components/Button/NButton.vue";
import NInputHtmlDialog from "@/components/Input/dialogs/NInputHtmlDialog.vue";

export default defineComponent({
  components: {NInputHtmlDialog, NButton, NInput, NGrid},
  props: {
   modelValue: String,
    placeholder:String,
    fields:Array
  },
  setup(props, { emit }) {


    const localState = reactive({
      showDialog:ref(false),
      model:computed({
        get:()=> props.modelValue,
        set:(val)=> emit('update:modelValue',val)
      }),
      content:ref()
    });


    return {
      ...toRefs(localState)

    };
  }
});
