import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';


export const useTrendyolKategoriOzellikService = defineStore({
id: 'TrendyolKategoriOzellikService',
actions: {


	async getGetir(categoryId:number)
	{
		const { request, response } = useApi(`/api/TrendyolKategoriOzellik/Getir/${categoryId}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
