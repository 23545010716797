import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!

  return (_openBlock(), _createBlock(_component_DxSelectBox, _mergeProps(_ctx.$attrs, {
    "display-expr": "adSoyad",
    label: "Plasiyer",
    "label-mode": "floating",
    "value-expr": "id",
    items: _ctx.items,
    searchEnabled: true,
    value: _ctx.selected,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
    "show-clear-button": true,
    placeholder: "Plasiyer Seçiniz",
    onValueChanged: _ctx.valueChanged
  }), null, 16, ["items", "value", "onValueChanged"]))
}