import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"color":"#C00","margin-left":"4px"}
}
const _hoisted_2 = {
  key: 1,
  style: {"color":"#0C0","margin-left":"4px"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NDropDownListBox = _resolveComponent("NDropDownListBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!
  const _component_TrendyolEslestirmeDialog = _resolveComponent("TrendyolEslestirmeDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NLoadingPanel, {
      ref: "loadingPanel",
      action: ()=> _ctx.load(368),
      title: "Trendyol özellikleri yükleniyor..."
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NFormGrid, {
          cols: _ctx.cols,
          title: "Trendyol Özellikleri"
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_NButton, {
              icon: "refresh-ccw",
              size: "small",
              click: ()=> _ctx.load(0)
            }, {
              default: _withCtx(() => [
                _createTextVNode("Yenile")
              ]),
              _: 1
            }, 8, ["click"])
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trendyolOzellikler.categoryAttributes, (attribute, i) => {
              return (_openBlock(), _createBlock(_component_NFormField, {
                "grid-cols": "150px 1fr",
                label: attribute.attribute.name + ' (' + attribute.attributeValues.length + ')',
                key: i
              }, {
                label: _withCtx(() => [
                  _createElementVNode("div", null, [
                    (attribute.required)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
                      : _createCommentVNode("", true),
                    (attribute.varianter)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "V"))
                      : _createCommentVNode("", true)
                  ])
                ]),
                default: _withCtx(() => [
                  (_ctx.stokRef.varyantlar?.find(a => a.name===attribute.attribute.name || a.nameTrendyol === attribute.attribute.name))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        (attribute.allowCustom === true)
                          ? (_openBlock(), _createBlock(_component_NTag, {
                              key: 0,
                              type: "success",
                              style: {"display":"block!important"}
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Varyantlarda belirleniyor")
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createElementBlock("span", _hoisted_4, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPropertyValues(attribute.attribute?.name), (value, i) => {
                                return (_openBlock(), _createElementBlock("span", {
                                  style: {"display":"inline-block","margin-right":"4px"},
                                  type: "warning",
                                  key: i
                                }, [
                                  (_ctx.getPropertyValueId(attribute.attributeValues, value) < 0)
                                    ? (_openBlock(), _createBlock(_component_NTag, {
                                        key: 0,
                                        type: "danger"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(value), 1)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    : _createCommentVNode("", true)
                                ]))
                              }), 128)),
                              _createVNode(_component_NTag, {
                                type: "info",
                                filled: true,
                                style: {"font-weight":"100","cursor":"pointer"},
                                onClick: ($event: any) => (_ctx.eslestir(attribute.attribute?.name,attribute.attributeValues)),
                                title: "Trendyol seçenekleri ile eşleşmeyen seçenekleri eşletirmek için tıklayınız."
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Seçenekleri Eşleştir")
                                ]),
                                _: 2
                              }, 1032, ["onClick"])
                            ]))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_NDropDownListBox, {
                          "accept-custom-value": attribute.allowCustom,
                          text: attribute.value,
                          "onUpdate:text": ($event: any) => ((attribute.value) = $event),
                          onChange: _ctx.trendyolToNesilce,
                          "selected-value": attribute.valueId,
                          "onUpdate:selectedValue": ($event: any) => ((attribute.valueId) = $event),
                          items: attribute.attributeValues
                        }, null, 8, ["accept-custom-value", "text", "onUpdate:text", "onChange", "selected-value", "onUpdate:selectedValue", "items"])
                      ]))
                ]),
                _: 2
              }, 1032, ["label"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["cols"])
      ]),
      _: 1
    }, 8, ["action"]),
    (_ctx.showEslestir)
      ? (_openBlock(), _createBlock(_component_TrendyolEslestirmeDialog, {
          key: 0,
          show: _ctx.showEslestir,
          "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showEslestir) = $event)),
          "trendyol-values": _ctx.selectedTrendyolValues,
          "onUpdate:trendyolValues": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedTrendyolValues) = $event)),
          "kategori-id": _ctx.kategoriId,
          "nesilce-values": _ctx.selectedNesilceValues,
          "onUpdate:nesilceValues": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedNesilceValues) = $event)),
          "property-name": _ctx.selectedPropertyName,
          "onUpdate:propertyName": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedPropertyName) = $event))
        }, null, 8, ["show", "trendyol-values", "kategori-id", "nesilce-values", "property-name"]))
      : _createCommentVNode("", true)
  ], 64))
}