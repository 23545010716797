import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {IdName} from '../models/IdName'
import {EFaturaFaturaSatir} from '../models/EFaturaFaturaSatir'
import {EFaturaSenaryoTip} from '../models/EFaturaSenaryoTip'
import {EFaturaTip} from '../models/EFaturaTip'
import {EIrsaliyeFaturaView} from '../models/EIrsaliyeFaturaView'
import {EFaturaContentType} from '../models/EFaturaContentType'
import {RecordStatus} from '../models/RecordStatus'
import {FaturaHareketTip} from '../models/FaturaHareketTip'
import {FaturaTip} from '../models/FaturaTip'
import {FaturaUstBilgiItem} from '../models/FaturaUstBilgiItem'
import {TeslimatAdresItem} from '../models/TeslimatAdresItem'
import {FaturaSatirItem} from '../models/FaturaSatirItem'
import {FaturaAltBilgiItem} from '../models/FaturaAltBilgiItem'
import {DocumentItem} from '../models/DocumentItem'
import {PlanVadelerItem} from '../models/PlanVadelerItem'
import {FaturaEFaturaItem} from '../models/FaturaEFaturaItem'
import {SmsMailKargoGonderItem} from '../models/SmsMailKargoGonderItem'
import {FaturaView} from '../models/FaturaView'

export const useIziGelenIrsaliyeService = defineStore({
id: 'IziGelenIrsaliyeService',
actions: {


	async getGetAllAsync()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenIrsaliye/GetAllAsync/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetAllAsyncNotMatched(faturaId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenIrsaliye/GetAllAsyncNotMatched/${shared.firm.id}/${shared.firm.period}/${faturaId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetInvoiceDetailsAsync(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenIrsaliye/GetInvoiceDetailsAsync/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postGenerateFaturaView(eIrsaliyeFaturaView:EIrsaliyeFaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenIrsaliye/GenerateFaturaView/${shared.firm.id}`,{ method:'POST', data:eIrsaliyeFaturaView });
		await request();
		return response.value;
	},

	async getGetPdf(uuid:string,contentType:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenIrsaliye/GetPdf/${shared.firm.id}/${uuid}/${contentType}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetCariByVergiNo(vergiNo:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenIrsaliye/GetCariByVergiNo/${shared.firm.id}/${vergiNo}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postFaturaEkle(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenIrsaliye/FaturaEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async deleteFaturaSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenIrsaliye/FaturaSil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async putEFaturaToFaturaLink(faturaId:string,eFaturaId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenIrsaliye/EFaturaToFaturaLink/${shared.firm.id}/${shared.firm.period}/${faturaId}/${eFaturaId}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async putUpdateOkundu(eIrsaliyeId:string,isOkundu:boolean)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenIrsaliye/UpdateOkundu/${shared.firm.id}/${eIrsaliyeId}/${isOkundu}`,{ method:'PUT' });
		await request();
		return response.value;
	}
}
});
