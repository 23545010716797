
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NInputHtml from "@/components/Input/NInputHtml.vue";
import NTagList from "@/components/Tag/NTagList.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import {useStokService} from "@/api/services/StokService";
import {useCariService} from "@/api/services/CariService";
import NInput from "@/components/Input/NInput.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NButton from "@/components/Button/NButton.vue";
import StokDiller from "@/modules/Stok/components/StokDiller.vue";
import {PluKodList} from "@/api/models/PluKod";

export default defineComponent({
  components: {StokDiller, NFormGrid, NGrid, NInput, NLoadingPanel, NTagList, NInputHtml, NFormField},
  props: ["modelValue"],
  setup(props) {
    const localState = reactive({
      model: computed(() => props.modelValue),
      hizliSatisGruplari: ref([]),
      grup1Ref: ref([] as any[]),
      grup2Ref: ref([] as any[]),
      grup3Ref: ref([] as any[]),
      grup4Ref: ref([] as any[]),
      pluKodList:PluKodList,
    });

    async function load() {
      localState.hizliSatisGruplari = await useStokService().getStokHizliSatisNames()
      await grup1ListeYukle();
    }

    async function grup1ListeYukle() {
      localState.grup1Ref = await useStokService().getGrup1();
      //  console.log(localState.grup1Ref)
      if (localState.model.grup1 && localState.model.grup1.length > 0)
        grup2ListeYukle();

      if (localState.model.grup2 && localState.model.grup2.length > 0)
        grup3ListeYukle();

      if (localState.model.grup3 && localState.model.grup3.length > 0)
        grup4ListeYukle();

    }


    async function grup2ListeYukle() {
      if (localState.model.grup1)
        localState.grup2Ref = await useStokService().getGrup2(
            localState.model.grup1 as string
        );
    }

    async function grup3ListeYukle() {
      if (localState.model.grup1 && localState.model.grup2)
        localState.grup3Ref = await useStokService().getGrup3(
            localState.model.grup1 as string,
            localState.model.grup2 as string
        );
    }

    async function grup4ListeYukle() {
      if (localState.model.grup1 && localState.model.grup2 && localState.model.grup3)
        localState.grup4Ref = await useStokService().getGrup4(
            localState.model.grup1 as string,
            localState.model.grup2 as string,
            localState.model.grup3 as string
        );
    }


    return {
      ...toRefs(localState),
      load,
      grup1ListeYukle,
      grup2ListeYukle,
      grup3ListeYukle,
      grup4ListeYukle
    };
  }
});
