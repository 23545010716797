
import { computed, defineComponent, onMounted, reactive, ref, toRefs, watch } from "vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NInput from "@/components/Input/NInput.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import { PlanVadelerItem } from "@/api/models/PlanVadelerItem";
import NTag from "@/components/Tag/NTag.vue";
import NIcon from "@/components/Icon/NIcon.vue";
import { HareketUtils } from "@/modules/Ortak/utils/HareketlerUtils";
import NButton from "@/components/Button/NButton.vue";
import { useTaksitPlanService } from "@/api/services/TaksitPlanService";
import NGrid from "@/components/Grid/NGrid.vue";
import { TaksitPlanEntity } from "@/api/models/TaksitPlanEntity";


export default defineComponent({
  components: { NGrid, NButton, NIcon, NTag, NFormField, NInput, NFormGrid, NModalDialog },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    taksitler: {
      type: Array

    },
    tutar: {
      type: Number,
      default: 0
    },
    vade: {
      type: String,
      default: new Date().toISOString().split("T")[0]
    },
    referansId: {
      type: String,
      default: ""
    }
  },
  setup: function(props, { emit }) {
    const localState = reactive({
      hareketUtils: HareketUtils,
      taksitSayisi: ref(0),
      taksitSayisiToplam: ref(0 as number),
      taksitTutariToplam: ref(0 as number),
      taksitTutari: ref(0 as number),
      hareketTutar: ref(Number(props.tutar.toFixed(2))),
      vadeRef: ref(props.vade),
      showRef: computed({
        get: () => props.show,
        set: val => emit("update:show", val)
      }),
      taksitlerRef: ref([] as PlanVadelerItem[])

    });

    onMounted(async () => {

      //console.log("taksitler" , props.taksitler);

      if (props.taksitler && props.taksitler.length > 0) {
        localState.taksitlerRef = Object.assign([] , props.taksitler as PlanVadelerItem[]);
      } else if (props.referansId && props.referansId.length > 0) {

        const taksitler: TaksitPlanEntity[] = await useTaksitPlanService().getReferansHareketIdByTaksitler(props.referansId);
        if (taksitler && taksitler.length > 0) {
          localState.taksitlerRef = taksitler.map((a) => ({ tutar: a.taksitTutar, vade: a.vade }));
        }

      }
    });

    async function kaydet() {
      const taksitler = Object.assign([] ,localState.taksitlerRef )
      emit("update:taksitler",taksitler);
      localState.showRef = false;
    }

    function taksitlendir() {

      localState.taksitlerRef = [];

      if (localState.taksitTutari && localState.taksitTutari > 0) {
        localState.taksitlerRef = taksitOlusturTutaraGore(localState.hareketTutar as number , localState.taksitTutari as number, localState.vadeRef);
      }

      if (localState.taksitSayisi && localState.taksitSayisi > 0) {
        localState.taksitlerRef = taksitOlusturAdedeGore(localState.hareketTutar as number, localState.taksitSayisi as number, localState.vadeRef);

      }

      const taksitler = Object.assign([] ,localState.taksitlerRef )
      emit("update:taksitler",taksitler);


    }

    watch(() => localState.taksitlerRef, (val) => {
      let taksitSayisi = 0 as number;
      let taksitTutari = 0 as number;
      if (localState.taksitlerRef && localState.taksitlerRef.length > 0) {
        localState.taksitlerRef.forEach((har) => {
          taksitSayisi++;
          taksitTutari += har.tutar as number;
        });

      }

      localState.taksitSayisiToplam = taksitSayisi;
      localState.taksitTutariToplam = taksitTutari;
    }, { deep: true });


    function taksitOlusturTutaraGore(toplam: number, tutar: number, baslangicTarihi: string) {

      const vadeler: PlanVadelerItem[] = [];

      if (toplam > 0) {
        if (toplam >= tutar) {

          const taksitSayisi = toplam / tutar;
          for (let i = 0; i < taksitSayisi; i++) {
            const vade: PlanVadelerItem = {
              vade: new Date(Date.parse(baslangicTarihi)).addMonths(i).toISOString(),
              tutar: tutar as number
            };
            vadeler.push(vade);
          }
          if (toplam % tutar) {
            const vade: PlanVadelerItem = vadeler[vadeler.length as number - 1] as PlanVadelerItem;
            vade.tutar = toplam as number % tutar as number;
          }
        }
      }
      return vadeler;
    }

    function taksitOlusturAdedeGore(toplam: number, taksitSayisi: number, baslangicTarihi: string) {
      const vadeler: PlanVadelerItem[] = [];

      if (taksitSayisi > 0 && toplam > 0) {
        for (let i = 0; i < taksitSayisi; i++) {
          vadeler.push({
            vade: new Date(Date.parse(baslangicTarihi)).addMonths(i).toISOString(),
            tutar: toplam / taksitSayisi
          });
        }
      }
      return vadeler;
    }


    return {
      ...toRefs(localState),
      taksitlendir,
      kaydet
    };
  }
});
