import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c32fd60"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "kampanya-item" }
const _hoisted_2 = {
  class: "kampanya-satir",
  style: {"margin-bottom":"3px"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "kampanya-satir"
}
const _hoisted_5 = { style: {"display":"block","margin-top":"5px","align-self":"center"} }
const _hoisted_6 = { style: {"align-self":"center"} }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 0,
  class: "kampanya-satir"
}
const _hoisted_9 = { style: {"display":"block","margin-top":"5px","align-self":"center"} }
const _hoisted_10 = { style: {"align-self":"center"} }
const _hoisted_11 = {
  key: 0,
  style: {"text-align":"center","color":"#7d7d7d"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_KampanyaKategoriSelectDialog = _resolveComponent("KampanyaKategoriSelectDialog")!
  const _component_NCard = _resolveComponent("NCard")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdownMenu = _resolveComponent("NDropdownMenu")!
  const _component_NDropdown = _resolveComponent("NDropdown")!

  return (_openBlock(), _createBlock(_component_NDropdown, {
    placement: "bottom-left",
    clickable: true,
    style: {"width":"auto"}
  }, {
    menu: _withCtx(() => [
      _createVNode(_component_NDropdownMenu, { style: {"width":"346px"} }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, i) => {
            return (_openBlock(), _createBlock(_component_NDropdownItem, {
              key: i,
              style: {"cursor":"default","padding":"5px 5px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NCard, { style: {"width":"335px"} }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_NIcon, {
                        type: "star",
                        size: "15px",
                        style: {"margin-right":"3px"}
                      }),
                      _createElementVNode("b", null, _toDisplayString(item.name), 1),
                      _createElementVNode("div", _hoisted_2, _toDisplayString(item.getDropDownAciklama) + " " + _toDisplayString(item.hedef?.tip === _ctx.kaynakHedefTipi.Stok ? 'Aşağıdaki ürünlerden;' : 'Aşağıdaki kategorilerden;'), 1),
                      (item.hedef?.tip === _ctx.kaynakHedefTipi.Kategori)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            (item.hedef.beraber)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                  _createVNode(_component_NButton, {
                                    type: "info",
                                    size: "small",
                                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.stokSec(''))),
                                    style: {"width":"100%"}
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_NGrid, {
                                        cols: "1fr 75px",
                                        style: {"width":"290px"}
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.idNamesSeperate(item.hedef?.idNames)), 1),
                                          _createVNode(_component_NTag, { filled: true }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.getDropDownSatirDeger), 1)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]))
                              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.getHediyeList(item), (satir, i) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "kampanya-satir",
                                    key: i
                                  }, [
                                    _createVNode(_component_NButton, {
                                      type: "info",
                                      style: {"margin-top":"5px","width":"100%"},
                                      size: "small",
                                      onClick: ($event: any) => (_ctx.stokSec(_ctx.getItem(satir, 'id')))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_NGrid, {
                                          cols: "1fr 75px",
                                          style: {"width":"290px"}
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.getItem(satir, 'name')), 1),
                                            _createVNode(_component_NTag, { filled: true }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(item.getDropDownSatirDeger), 1)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"])
                                  ]))
                                }), 128)),
                            (_ctx.showKategoriSecDialog)
                              ? (_openBlock(), _createBlock(_component_KampanyaKategoriSelectDialog, {
                                  key: 2,
                                  show: _ctx.showKategoriSecDialog,
                                  "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showKategoriSecDialog) = $event)),
                                  kampanya: item,
                                  selectedKampanyaId: _ctx.selectedKampanyaId,
                                  onTek: _ctx.tekStokEkle,
                                  onCoklu: _ctx.coklu
                                }, null, 8, ["show", "kampanya", "selectedKampanyaId", "onTek", "onCoklu"]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      (item.hedef?.tip === _ctx.kaynakHedefTipi.Stok)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            (item.hedef.beraber)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                  _createVNode(_component_NButton, {
                                    type: "info",
                                    size: "small",
                                    onClick: ($event: any) => (_ctx.kampanyaUygula('', item)),
                                    style: {"width":"100%"}
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_NGrid, {
                                        cols: "1fr 75px",
                                        style: {"width":"290px"}
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.idNamesSeperate(item.hedef?.idNames)), 1),
                                          _createVNode(_component_NTag, { filled: true }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(item.getDropDownSatirDeger), 1)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]))
                              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.getHediyeList(item), (satir, i) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    class: "kampanya-satir",
                                    key: i
                                  }, [
                                    _createVNode(_component_NButton, {
                                      type: "info",
                                      style: {"margin-top":"5px","width":"100%"},
                                      size: "small",
                                      onClick: ($event: any) => (_ctx.kampanyaUygula(_ctx.getItem(satir, 'id'), item))
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_NGrid, {
                                          cols: "1fr 75px",
                                          style: {"width":"290px"}
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.getItem(satir, 'name')), 1),
                                            _createVNode(_component_NTag, { filled: true }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(item.getDropDownSatirDeger), 1)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"])
                                  ]))
                                }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1024))
          }), 128)),
          (_ctx.list.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_NIcon, {
                  type: "info",
                  size: "14px",
                  style: {"margin-right":"5px"}
                }),
                _createTextVNode(" Liste Boş ")
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_NButton, {
        size: "small",
        block: "",
        icon: "star",
        click: _ctx.load,
        title: "Kampanyaları görmek için tıklayınız.",
        style: {"width":"100%"},
        type: "success"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.getSelectedKampanyaLabel()), 1)
        ]),
        _: 1
      }, 8, ["click"])
    ]),
    _: 1
  }))
}