import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { defineStore } from "pinia";
import { useNesilceConfig } from "@/api/NesilceConfig";

export const useSignal = defineStore({
  id: "useSignal",
  state: () => ({
    connection: {} as any,
    signalStatus: "",
    isInitialized: false,
    isfailCount: 0,

  }),
  actions: {
    async init() {



      this.connection = new HubConnectionBuilder()
        .withUrl(useNesilceConfig().config.API_URL + "/GenelHub")
        .configureLogging(LogLevel.Error)
        .build();

      const connection: HubConnection = this.connection;

      if (this.isInitialized == false) {

        const conn = this.connect;
        // disconnected
        connection.onclose(() => {
          console.log("0 - BAĞLANTI KESİLDİ...", connection.state);
          this.signalStatus = HubConnectionState.Disconnected;
          setTimeout(() => conn(connection), 5000);

        });


        // connect
        this.connect(this.connection);

      }
    },
    async connect(connection: HubConnection) {
      this.signalStatus = HubConnectionState.Connecting;
      console.log("1 - BAĞLANIYOR...", connection.state);
      try {

        if (connection.state == HubConnectionState.Disconnected)
          await connection.start();

        console.log("2 - BAĞLANDI...", connection.state);
        this.signalStatus = HubConnectionState.Connected;

      } catch (err) {
        this.signalStatus = connection.state;
        console.log("3 - HATA OLUŞTU..." + connection.state, err);
        this.isfailCount += 1;
        if (this.isfailCount < 100)
          setTimeout(() => this.connect(connection), 5000);
        else
          setTimeout(() => this.connect(connection), 60000);
      }
    },
    on(methodName: string, handler: any) {
        if (this.connection.state == HubConnectionState.Disconnected)
          this.connect(this.connection);

        const conn = this.connection as HubConnection;

        if (conn.state == HubConnectionState.Disconnected) {
          this.connect(this.connection);
          this.signalStatus = this.connection.state;
        }


        if (methodName && methodName.length > 0) {
          //console.log(methodName,methodName.length);
          this.connection.on(methodName, (...args: any[]) => {
            //alert(args[0].id)

            handler(...args);
            this.signalStatus = this.connection.state;
          });
        }

    }
  }
});
