
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import FaturaViewExtend from "@/modules/Fatura/models/FaturaViewExtend";
import NFormField from "@/components/FormField/NFormField.vue";
import CariSelectBox from "@/modules/Cari/components/CariSelectBox.vue";
import { FaturaHareketTip, FaturaHareketTipList } from "@/api/models/FaturaHareketTip";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import { useFaturaStore } from "@/modules/Fatura/store";
import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import { MenuFormType } from "@/modules/Ortak/models/Menu/MenuFormType";
import { FaturaTip } from "@/api/models/FaturaTip";
import { RecordStatus } from "@/api/models/RecordStatus";
import { SiparisHareketTipList } from "@/api/models/SiparisHareketTip";
import NSwitch from "@/components/Switch/NSwitch.vue";
import SiparisViewExtend from "@/modules/SiparisTeklif/models/SiparisViewExtend";
import { useSiparisStore } from "@/modules/SiparisTeklif/stores";
import { uuid } from "vue-uuid";
import { alert } from "devextreme/ui/dialog";

export default defineComponent({

  components: { NSwitch, NLoadingPanel, CariSelectBox, NFormField, NModalDialog },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Object
    },
    tip: {
      type: Number,
      default: 7
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      showRef: computed({
        get: () => props.show,
        set: val => emit("update:show", val)
      }),

      cariId: ref(""),
      getUnvan: ref(""),
      faturaTip: ref({} as FaturaHareketTip),
      siparisTip: ref(0),
      faturaTipLoadListe: ref([] as FaturaHareketTip[]),

      faturaHareketTipList: FaturaHareketTipList,
      siparisHareketTipList: SiparisHareketTipList,
      faturaModelRef: computed(() => props.modelValue as FaturaViewExtend),
      siparisModelRef: computed(() => props.modelValue as SiparisViewExtend),
      tipRef: computed(() => props.tip as MenuFormType),
      menuFormType: MenuFormType,
      sil: ref(false)

    });


    async function load() {
      await doldur(localState.tipRef);
    }

    async function doldur(tip: MenuFormType) {
      if (tip == MenuFormType.Fatura_Form) {


        localState.faturaTipLoadListe.push(FaturaHareketTip.AlisFaturasi);
        localState.faturaTipLoadListe.push(FaturaHareketTip.SatisFatura);
        localState.faturaTipLoadListe.push(FaturaHareketTip.AlisIadeFaturasi);
        localState.faturaTipLoadListe.push(FaturaHareketTip.SatisIadeFaturasi);
        localState.faturaTipLoadListe.push(FaturaHareketTip.FiyatFarkiAlis);
        localState.faturaTipLoadListe.push(FaturaHareketTip.FiyatFarkiSatis);
        localState.faturaTipLoadListe.push(FaturaHareketTip.SatisIrsaliye);
        localState.faturaTipLoadListe.push(FaturaHareketTip.AlisIrsaliye);

        if (localState.faturaModelRef && localState.faturaModelRef.tip)
          localState.faturaTip = localState.faturaModelRef.tip;
        if (localState.faturaModelRef && localState.faturaModelRef.ustBilgi && localState.faturaModelRef.ustBilgi.cari
          && localState.faturaModelRef.ustBilgi.cari.id && localState.faturaModelRef.ustBilgi.cari.id.length > 0)
          localState.cariId = localState.faturaModelRef.ustBilgi.cari?.id;
      }


      if (tip == MenuFormType.Siparis_Form) {
        if (localState.siparisModelRef && localState.siparisModelRef.tip)
          localState.siparisTip = localState.siparisModelRef.tip as number;
        localState.cariId = localState.siparisModelRef.cariId as string;
      }

    }


    async function kopyala() {
      if (localState.tipRef == MenuFormType.Fatura_Form)
        await faturaKopyalama();
      else
        await siparisTeklifKopyalama();


    }

    async function siparisTeklifKopyalama() {
      const modelClone = Object.assign({}, localState.siparisModelRef) as SiparisViewExtend;

      modelClone.cariId = localState.cariId;
      modelClone.cariUnvan = localState.getUnvan;
      modelClone.tip = localState.siparisTip;
      modelClone.id = "";

      modelClone.satirlar?.forEach((a: any) => {
        a.id = uuid.v4();
        a.teslimatlar = [];
        a.getTeslimMiktar = 0;
        a.getTeslimKalan = 0;
      });
      const menuModel = {
        formType: MenuFormType.Siparis_Form,
        hareketTipi: localState.siparisTip,
        name: localState.siparisHareketTipList?.find(a => a.value == localState.siparisTip)?.description
      } as MenuModel;

      useSiparisStore().openSiparisFormView(
        menuModel,
        localState.cariId,
        localState.getUnvan,
        "",
        modelClone
      );
    }

    async function faturaKopyalama() {
      if (localState.faturaModelRef && localState.faturaModelRef.ustBilgi)
        localState.faturaModelRef.ustBilgi.cari = {
          id: localState.cariId,
          name: localState.getUnvan
        };

      localState.faturaModelRef.eFatura = {};
      localState.faturaModelRef.tip = localState.faturaTip;
      localState.faturaModelRef.id = "";
      localState.faturaModelRef.status = RecordStatus.active;

      const faturaMenu: MenuModel = {};
      faturaMenu.formType = MenuFormType.Fatura_Form;
      faturaMenu.hareketTipi = localState.faturaTip;
      faturaMenu.name = localState.faturaHareketTipList.find(a => a.value == localState.faturaTip)?.description;
      if (localState.faturaModelRef && localState.faturaModelRef.satirlar && localState.faturaModelRef.satirlar.length > 0 ){
        localState.faturaModelRef.satirlar.forEach((d) => {
          d.id =  uuid.v4().toString()
        })
      }else {
        await alert("Kopyalama Geçersiz , Satırlar Boş Olamaz !" , "Fatura Kopyalama")
      }

      useFaturaStore().viewToFaturaForm(faturaMenu, localState.faturaModelRef, FaturaTip.Normal);
    }


    return {
      ...toRefs(localState),
      load,
      kopyala
    };
  }
});
