import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {FirmaEntity} from '../models/FirmaEntity'
import {CariEntity} from '../models/CariEntity'
import {PerakendeView} from '../models/PerakendeView'
import {PerakendeReportView} from '../models/PerakendeReportView'
import {PerakendeSatirItem} from '../models/PerakendeSatirItem'
import {PerakendeIadeItem} from '../models/PerakendeIadeItem'
import {IdName} from '../models/IdName'
import {SmsMailKargoGonderItem} from '../models/SmsMailKargoGonderItem'
import {EFaturaSenaryoTip} from '../models/EFaturaSenaryoTip'
import {EFaturaTip} from '../models/EFaturaTip'
import {EFaturaDocType} from '../models/EFaturaDocType'
import {GenelResmiMuhasebeTip} from '../models/GenelResmiMuhasebeTip'

export const usePerakendeService = defineStore({
id: 'PerakendeService',
actions: {


	async postPerakendeReportView(perakendeReportView:PerakendeReportView)
	{
		const { request, response } = useApi(`/api/perakende/PerakendeReportView`,{ method:'POST', data:perakendeReportView });
		await request();
		return response.value;
	},

	async postEkle(perakendeView:PerakendeView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/perakende/Ekle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:perakendeView });
		await request();
		return response.value;
	},

	async getCariSatisFaturalari(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/perakende/CariSatisFaturalari/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
