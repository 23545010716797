
export enum KasaHareketTip{
KasadanCariyeOdeme=100,
KasadanMasrafOdeme=110,
KasadanBankayaOdeme=120,
KasadanFirmaSenediOdeme=130,
KasadanFirmaCekiOdeme=140,
KasayaVirmanOdeme=150,
KasadanDegerliKiymetAlimi=160,
KasaCikanDevir=180,
KasadanFirmaKrediKartinaOdeme=190,
KasadanDovizBozdurma=200,
KasadanBankaKredisiOdeme=210,
KasaFazlalik=211,
KasayaCaridenTahsilat=2100,
KasayaHizmetTahsilati=2110,
KasayaBankadanCekme=2120,
KasayaMusteriSenediTahsilati=2130,
KasayaMusteriCekiTahsilati=2140,
KasadanVirmanTahsilati=2150,
KasayaDegerliKiymetTahsilati=2160,
KasaNoksanlik=2170,
KasaGirenDevir=2180,
KasayaDovizAlma=2190,
KasayaFirmaKrediKartindanParaCekme=2200,
KasayaBankaKredisiCekme=2210
}

export const KasaHareketTipList = [
{key:'KasadanCariyeOdeme', value:100, description:'Cariye Ödeme'},
{key:'KasadanMasrafOdeme', value:110, description:'Masraf Ödeme'},
{key:'KasadanBankayaOdeme', value:120, description:'Bankaya Ödeme'},
{key:'KasadanFirmaSenediOdeme', value:130, description:'Firma Senedi Ödeme'},
{key:'KasadanFirmaCekiOdeme', value:140, description:'Firma Çeki Ödeme'},
{key:'KasayaVirmanOdeme', value:150, description:'Virman Ödeme'},
{key:'KasadanDegerliKiymetAlimi', value:160, description:'Değerli Kıymet Alışı Ödemesi'},
{key:'KasaCikanDevir', value:180, description:'Çıkan Devir'},
{key:'KasadanFirmaKrediKartinaOdeme', value:190, description:'Firma Kredi Kartına Ödeme'},
{key:'KasadanDovizBozdurma', value:200, description:'Döviz Bozdurma'},
{key:'KasadanBankaKredisiOdeme', value:210, description:'Kasaadan Banka Kredisi Ödeme'},
{key:'KasaFazlalik', value:211, description:'Fazlalık'},
{key:'KasayaCaridenTahsilat', value:2100, description:'Cariden Tahsilat'},
{key:'KasayaHizmetTahsilati', value:2110, description:'Hizmet Tahsilatı'},
{key:'KasayaBankadanCekme', value:2120, description:'Bankadan Para Çekme'},
{key:'KasayaMusteriSenediTahsilati', value:2130, description:'Müşteri Senedi Tahsil Etme'},
{key:'KasayaMusteriCekiTahsilati', value:2140, description:'Müşteri Çeki Tahsil Etme'},
{key:'KasadanVirmanTahsilati', value:2150, description:'Virman Tahsilat'},
{key:'KasayaDegerliKiymetTahsilati', value:2160, description:'Değerli Kıymet Satış Tahsilati'},
{key:'KasaNoksanlik', value:2170, description:'Noksanlık'},
{key:'KasaGirenDevir', value:2180, description:'Giren Devir'},
{key:'KasayaDovizAlma', value:2190, description:'Döviz Alma'},
{key:'KasayaFirmaKrediKartindanParaCekme', value:2200, description:'Firma Kredi Kartından Para Çekme'},
{key:'KasayaBankaKredisiCekme', value:2210, description:'Banka Kredisi Çekme'}
]
