
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NInput from "@/components/Input/NInput.vue";
import NTextarea from "@/components/Textarea/NTextarea.vue";

import {UlkeEntity} from "@/api/models/UlkeEntity";
import {useGenelCache} from "@/modules/Ortak/services/useGenelCache";
import {useSharedStore} from "@/store";
import {FaturaView} from "@/api/models/FaturaView";
import MahalleAraSelectBox from "@/modules/Ortak/components/MahalleAraSelectBox.vue";
import CariAdresSelecBox from "@/modules/Cari/components/CariAdresSelecBox.vue";
import { AdresItem } from "@/api/models/AdresItem";

export default defineComponent({
  components: { CariAdresSelecBox, MahalleAraSelectBox, NTextarea, NInput, NFormField},
  props: {
    modelValue:{
      type:Object,

    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      model:computed({
        get:() => props.modelValue as FaturaView,
        set:(val) => emit('update:modelValue' , val) ,
      }),
      adresler: ref([] as UlkeEntity[]),
    })
    load();

    async function load(){
      localState.adresler = await useGenelCache().getUlkeList() as UlkeEntity[];
      if(!isUpdate() && localState.model.teslimatAdresi){
        localState.model.teslimatAdresi.teslimEden = useSharedStore().user.name;
      }
    }

    function isUpdate(){
      return localState.model && localState.model.id && localState.model.id.length > 0;
    }


    function cariAdresSelectedChanged(data:AdresItem){
      if (data){

        localState.model.teslimatAdresi!.belde = data.belde;
        localState.model.teslimatAdresi!.sehir = data.sehir;
        localState.model.teslimatAdresi!.ilce = data.ilce;
        localState.model.teslimatAdresi!.pKodu = data.pKodu;
        localState.model.teslimatAdresi!.mahalle = data.mahalle;
        localState.model.teslimatAdresi!.ulke = data.ulke;
        localState.model.teslimatAdresi!.adres = data.adres;
        localState.model.teslimatAdresi!.cep = data.cep;
        localState.model.teslimatAdresi!.mail = data.mail;

      }
    }
    return {
      ...toRefs(localState),cariAdresSelectedChanged
    };
  }
});
