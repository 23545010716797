
import {defineComponent, onMounted, reactive, ref, toRefs} from "vue";
import {useNotificationService} from "@/api/services/NotificationService";
import ArrayStore from "devextreme/data/array_store";
import {ObjectUtils} from "@/utils/object/ObjectUtils";
import DataSource from "devextreme/data/data_source";
import {onNotificationHubManagerSignal} from "@/api/signals/NotificationHubManagerSignal";
import {NotificationEntity} from "@/api/models/NotificationEntity";
import {useSharedStore} from "@/store";
import {useBildirimSesleriStore} from "@/modules/Ayarlar/Tanımlar/store/BildirimSesleriStore";

export default defineComponent({
  components: {},
  props: {},
  setup(props) {

    const localState = reactive({
      loaded: ref(false),
      arrayStore: ref({} as ArrayStore),
      dataSource: ref({} as DataSource),
      list: ref([] as NotificationEntity[]),
      notificationRef: ref(),
      userName:ref(useSharedStore().user.userName),
      genelBildirimSesi: useBildirimSesleriStore().bildirimGenelSesler
    });

    onMounted(() => {

      onNotificationHubManagerSignal().Added((entity) => {
        // console.log('entity:', entity)
        if (entity) {
          localState.arrayStore.push([{type: "insert", data: entity}]);
          localState.notificationRef?.instance?.repaint();
          bildirimCal();
        }
      });

      onNotificationHubManagerSignal().Deleted((id) => {
        localState.arrayStore.push([{type: "remove", key: id}]);
      });


      onNotificationHubManagerSignal().Changed((item) => {
        if (item) {
          localState.arrayStore.push([{ type: "update", data: item, key: item.id }]);
        }
      })

      onNotificationHubManagerSignal().ChangedAll((item) => {
        if (item === localState.userName) { // biz isek, hepsini okundu yap

          for (let i = 0; i < ((localState.arrayStore as any)._array as any[])?.length; i++) {
            const id = ((localState.arrayStore as any)._array as any[])[i].id
            markModelAsRead(id as string);
          }

          localState.notificationRef?.instance?.repaint();
        }


      })


    });


    async function loadNotifications() {
      localState.list = await useNotificationService().getGetAll();
      localState.arrayStore = new ArrayStore({
        data: localState.list,
        key: "id",

      });
      //sort: [{ field: "tarih", desc: true }],
      localState.dataSource = new DataSource({
        store: localState.arrayStore,


        sort: [
          { selector: 'tarih', desc: true }
        ],

        reshapeOnPush: true
      });

      localState.loaded = true;

    }


    loadNotifications();

    function notificationClick(e: MouseEvent) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }

    async function markAsRead(data:any) {

      if (getOkunduMu(data))
        return;

      const id = data.id;

      if (id && id.length > 0) {
        await useNotificationService().putMarkAsRead(id);
        data.getOkunduMu = true;
        localState.arrayStore.push([{type:'update',data:data,key:id}])
      }
    }

    function getOkunmayanCount() {
      if (localState.arrayStore)
        return ((localState.arrayStore as any)._array as any[])?.filter((a:any) => getOkunduMu(a) == false)?.length
      return 0;
    }

    function markModelAsRead(id: string) {
      localState.arrayStore.byKey(id).then((fieldObj: any) => {
        if (fieldObj) {
          ObjectUtils.setObj(fieldObj, "getOkunduMu", true);
        }
      });
    }

    function dateToLocalDateTime(isoDate: string) {
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      };
      const dateString = new Date(isoDate).toLocaleDateString("tr-TR", options as any);
      return dateString;
    }

    function resetListPosition() {
      setTimeout(() => {
        localState.notificationRef?.instance?.scrollToItem(0);
        localState.notificationRef?.instance?.repaint();
      }, 100);
    }

    function isPazaryeri(kimden: string) {
      switch (kimden) {
        case "Çiçek Sepeti":
          return true;
        case "Trendyol":
          return true;
        case "HepsiBurada":
          return true;
        case "N11":
          return true;
        case "E-Ticaret Sitesi":
          return true;
        case "B2B Bayi":
          return true;
      }
    }

    function getPazaryeriAsset(kimden: string) {
      switch (kimden) {
        case "Çiçek Sepeti":
          return ("icons/ciceksepeti.png");
        case "Trendyol":
          return ("icons/trendyol.png");
        case "HepsiBurada":
          return ("icons/hb.png");
        case "N11":
          return ("icons/n11.png");
        case "E-Ticaret Sitesi":
          return ("icons/web.png");
        case "B2B Bayi":
          return ("icons/b2b.png");
      }
    }

    async function markAllAsRead(e: MouseEvent) {
      e.preventDefault();
      e.stopImmediatePropagation();

      await useNotificationService().putMarkAllAsRead();
    }


    function timeSince(date: Date) {
      if (typeof date !== "object") {
        date = new Date(date);
      }

      const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
      let intervalType;

      let interval = Math.floor(seconds / 31536000);
      if (interval >= 1) {
        intervalType = "yıl";
      } else {
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
          intervalType = "ay";
        } else {
          interval = Math.floor(seconds / 86400);
          if (interval >= 1) {
            intervalType = "gün";
          } else {
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
              intervalType = "saat";
            } else {
              interval = Math.floor(seconds / 60);
              if (interval >= 1) {
                intervalType = "dakika";
              } else {
                interval = seconds;
                intervalType = "saniye";
              }
            }
          }
        }
      }

      if (interval > 1 || interval === 0) {
        intervalType += "";
      }

      return interval + " " + intervalType + " önce";
    }

    async function bildirimCal() {
      if (localState.genelBildirimSesi) {
        const sound = new Audio(("/notification.mp3"));
        await sound.play();
      }
    }

    function getOkunduMu(data:NotificationEntity) {
      if (data.getOkunduMu)
        return true;

      if (data.okuyanlar)
        return  data.okuyanlar.findIndex(a => a.name == localState.userName) >= 0;
      return  false;
    }

    return {
      ...toRefs(localState),
      markAsRead,
      dateToLocalDateTime,
      resetListPosition,
      isPazaryeri,
      getPazaryeriAsset,
      getOkunmayanCount,
      markAllAsRead,
      notificationClick,
      timeSince,
      getOkunduMu
    };
  }
});

