export const ResponseMessageConverter = {
    getMessage(response: ResponseMessageModel) {
        // fconsole.log("getMessage(response)", response);

        if (response.data && response.data.errors)
            return this.getErrorMessages(response.data.errors)
        else if (response.data.title)
            return response.data.title
        else
            return response.statusText;

    },
    getErrorMessages(errors: any[]) {
        let errorMessage = "";
        for (const errorsKey in errors) {
            errorMessage += errors[errorsKey] + " "
        }
        return errorMessage;
    }
}

interface ResponseMessageModel {
    data: Data;
    status: number;
    statusText: string;
    request: string;
}


interface Data {
    type: string;
    title: string;
    status: number;
    traceId: string;
    date: string;
    stackTrace?: any;
    errors: any;
}

