
import { computed, defineComponent, reactive, toRefs } from "vue";
import NButton from "@/components/Button/NButton.vue";
import FaturaViewExtend from "@/modules/Fatura/models/FaturaViewExtend";
import { FaturaDurumu } from "@/api/models/FaturaDurumu";
import NTag from "@/components/Tag/NTag.vue";
import NIconIonic from "@/components/NIconIonic.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NGrid from "@/components/Grid/NGrid.vue";


export default defineComponent({
  computed: {
    FaturaDurumu() {
      return FaturaDurumu;
    }
  },
  components: { NGrid, NIconIonic, NTag, NButton },
  props: {
    fatura: {
      type: Object

    }

  },
  setup(props, { emit }) {
    const localState = reactive({
      model: computed({
        get: () => props.fatura as FaturaViewExtend,
        set: (val) => emit("update:fatura", val)
      })
    });
function  openSmallWindow(){
  const width = 400;
  const height = 300;
  const left = (window.screen.width / 2) - (width / 2);
  const top = (window.screen.height / 2) - (height / 2);
  const options = `width=${width},height=${height},left=${left},top=${top},resizable=no`;
  //console.log(options);
  const newWindow = window.open('https://www.example.com', 'Küçük Pencere', options);



}
    function faturaNoKopyala(text: string) {
      if (text && text.length > 0) {
        navigator.clipboard.writeText(text);
      }
    }

    return {
      ...toRefs(localState),
      faturaNoKopyala,
      openSmallWindow
    };
  }

});
