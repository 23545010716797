import {RecordStatus} from '../models/RecordStatus'
import {FaturaHareketTip} from '../models/FaturaHareketTip'
import {FaturaTip} from '../models/FaturaTip'
import {FaturaUstBilgiItem} from '../models/FaturaUstBilgiItem'
import {TeslimatAdresItem} from '../models/TeslimatAdresItem'
import {FaturaSatirItem} from '../models/FaturaSatirItem'
import {FaturaAltBilgiItem} from '../models/FaturaAltBilgiItem'
import {DocumentItem} from '../models/DocumentItem'
import {PlanVadelerItem} from '../models/PlanVadelerItem'
import {FaturaEFaturaItem} from '../models/FaturaEFaturaItem'
import {SmsMailKargoGonderItem} from '../models/SmsMailKargoGonderItem'

export class FaturaView{
	status?:RecordStatus;
	tip?:FaturaHareketTip;
	faturaTip?:FaturaTip;
	id?:string;
	ustBilgi?:FaturaUstBilgiItem;
	teslimatAdresi?:TeslimatAdresItem;
	satirlar?:FaturaSatirItem[];
	altBilgi?:FaturaAltBilgiItem;
	dokumanlar?:DocumentItem[];
	vadeler?:PlanVadelerItem[];
	eFatura?:FaturaEFaturaItem;
	efaturaGonder?:boolean;
	isFatura?:boolean;
	gonder?:SmsMailKargoGonderItem;
	satisFiyatGuncelle?:boolean;
	ekMasraf?:number;
	faturaMaliyet?:number;
	readonly getKdvToplamTutar?:number;
	readonly getKdvSizToplam?:number;
	kullaniciAdi?:string;
	numaraAl?:boolean;
}
