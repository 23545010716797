
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import {PazaramaPazaryeriItem} from "@/api/models/PazaramaPazaryeriItem";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NSwitch from "@/components/Switch/NSwitch.vue";
import NRefresher from "@/components/Refresher/NRefresher.vue";
import NDropDownTreeView from "@/components/DropDownTreeView/NDropDownTreeView.vue";
import NInput from "@/components/Input/NInput.vue";
import NButton from "@/components/Button/NButton.vue";
import NInputHtml from "@/components/Input/NInputHtml.vue";
import PazaryeriFiyatItem from "@/modules/Pazaryerleri/components/PazaryeriFiyatItem.vue";
import NCard from "@/components/Card/NCard.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import {usePazaramaCategoryService} from "@/api/services/PazaramaCategoryService";
import {PazaramaCategoryTreeModel} from "@/api/models/PazaramaCategoryTreeModel";
import PazaramaOzellikEditor from "@/modules/Pazaryerleri/Pazarama/components/PazaramaOzellikEditor.vue";
import {IdName} from "@/api/models/IdName";
import NDropDownListBox from "@/components/Dropdown/NDropDownListBox.vue";
import NTagList from "@/components/Tag/NTagList.vue";
import NAlert from "@/components/Alert/NAlert.vue";

export default defineComponent({
  components: {
    NDropDownListBox,
    PazaramaOzellikEditor,
    PazaryeriFiyatItem,
    NInputHtml,
    NInput,
    NDropDownTreeView,
    NRefresher,
    NSwitch,
    NFormField,
    NFormGrid
  },
  props: [
    'pazarama',
    'stok',
    'alisKdv',
    'alisKdvDahil',
    'satisKdv',
    'satisKdvDahil',
    'alisFiyat',
    'satisFiyat'
  ],

  setup(props, {emit}) {
    const localState = reactive({
      pazaramaRef: computed(() => props.pazarama as PazaramaPazaryeriItem),
      stokRef: computed(() => props.stok),
      kategoriler: ref([] as PazaramaCategoryTreeModel[]),
      selectedKategoriItem: ref({} as PazaramaCategoryTreeModel),

      alisKdvRef: computed({get: () => props.alisKdv, set: (val) => emit('update:alisKdv', val)}),
      alisKdvDahilRef: computed({get: () => props.alisKdvDahil, set: (val) => emit('update:alisKdvDahil', val)}),
      satisKdvRef: computed({get: () => props.satisKdv, set: (val) => emit('update:satisKdv', val)}),
      satisKdvDahilRef: computed({get: () => props.satisKdvDahil, set: (val) => emit('update:satisKdvDahil', val)}),
      alisFiyatRef: computed({get: () => props.alisFiyat, set: (val) => emit('update:alisFiyat', val)}),
      satisFiyatRef: computed({get: () => props.satisFiyat, set: (val) => emit('update:satisFiyat', val)}),
      showSearch: ref(false),
      markalar: ref([] as IdName[])
    })

    async function load() {
      localState.kategoriler = await usePazaramaCategoryService().getGetCategoryList()

      localState.selectedKategoriItem = {
        categoryId: localState.pazaramaRef.kategoriId,
        name: getSubItem(localState.kategoriler, localState.pazaramaRef.kategoriId as string)?.name
      }

      await loadMarkalar(365);
    }

    async function loadMarkalar(cacheDay: number) {
      localState.markalar = await usePazaramaCategoryService().getGetBrands(cacheDay)
    }


    watch(() => localState.selectedKategoriItem, async (newValue) => {
      localState.pazaramaRef.kategoriId = newValue.categoryId
    })

    load();

    function getSubItem(subMenuItems: any[], categoryId: string): any {
      if (subMenuItems) {
        for (let i = 0; i < subMenuItems.length; i++) {
          if (subMenuItems[i].categoryId == categoryId) {
            return subMenuItems[i];
          }
          const found = getSubItem(subMenuItems[i].subCategories, categoryId);
          if (found) return found;
        }
      }
    }


    return {
      ...toRefs(localState),
      load,
      loadMarkalar
    }
  }
})
