export default class PazaryeriStatusCount {
    public n11: PazaryeriStatusCountItem = new PazaryeriStatusCountItem(0, 0, 0, 0,0,0);
    public trendyol: PazaryeriStatusCountItem = new PazaryeriStatusCountItem(0, 0, 0, 0,0,0);
    public hepsiBurada: PazaryeriStatusCountItem = new PazaryeriStatusCountItem(0, 0, 0, 0,0,0);
    public hepsiBuradaListing: PazaryeriStatusCountItem = new PazaryeriStatusCountItem(0, 0, 0, 0,0,0);
    public presta: PazaryeriStatusCountItem = new PazaryeriStatusCountItem(0, 0, 0, 0,0,0);
    public cicekSepeti: PazaryeriStatusCountItem = new PazaryeriStatusCountItem(0, 0, 0, 0,0,0);
}

export class PazaryeriStatusCountItem{
    constructor(public gonderilecek:number=0,
                public gonderiliyor:number=0,
                public gonderildi:number=0,
                public gonderilemedi:number=0,
                public inceleniyor:number=0,
                public pasif:number) {
    }
}
