import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';


export const usePrestaKatalogService = defineStore({
id: 'PrestaKatalogService',
actions: {


	async getGetKategoriler()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PrestaKatalog/GetKategoriler/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
