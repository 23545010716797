
import { computed, defineComponent, onActivated } from "vue";
import {LayoutTab, useLayoutStore} from "../context";

export default defineComponent({
  name: "LayoutTabs",
  setup() {
    const layout = useLayoutStore();

    const tabs = computed( () =>  layout.tabs);
    const selectedTabKey = computed( () =>  layout.selectedTabKey);


      function allowDrop(e:any, tab:any) {
          e.preventDefault();
      }

      function drag(e:any,tab:LayoutTab) {
          //console.log(e, tab)
          e.dataTransfer.setData("text", tab.key);
      }

      function drop(ev:any,tab:any) {
          ev.preventDefault();
          const sourceKey = ev.dataTransfer.getData("text");
          layout.changeOrder(sourceKey,tab.key);
          //ev.target.appendChild(document.getElementById(data));
      }



    return {
      tabs,
      selectedTabKey,
      closeTab: layout.closeTabValidate,
      selectTab: layout.selectTab,
        allowDrop,
        drag,
        drop
    };
  }
});
