
import {defineComponent, onActivated, reactive, ref, toRefs, watch} from "vue";
import NViewGrid from "@/components/ViewGrid/NViewGrid.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NViewToolbar from "@/components/Toolbar/NToolbar.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NCard from "@/components/Card/NCard.vue";
import CokluDokuman from "@/modules/Ortak/components/CokluDokuman.vue";
import Fatura_GenelBilgiler from "@/modules/Fatura/components/Fatura_GenelBilgiler.vue";
import Fatura_DigerBilgiler from "@/modules/Fatura/components/Fatura_DigerBilgiler.vue";
import {StokFlatView} from "@/api/models/StokFlatView";
import {HizmetMasrafEntity} from "@/api/models/HizmetMasrafEntity";
import {FaturaUtil} from "@/modules/Fatura/utils/FaturaUtil";
import SmsKargoMailGonderComponent from "@/modules/Ortak/components/SmsKargoMailGonderComponent.vue";
import FaturaViewExtend from "@/modules/Fatura/models/FaturaViewExtend";
import DataSource from "devextreme/data/data_source";
import {useFaturaStore} from "@/modules/Fatura/store";
import FaturaFormProp from "@/modules/Fatura/models/FaturaFormProp";
import {CariEntity} from "@/api/models/CariEntity";
import {useCariService} from "@/api/services/CariService";
import {KurView} from "@/api/models/KurView";
import {GiderYerleriView} from "@/api/models/GiderYerleriView";
import {useListelerService} from "@/api/services/ListelerService";
import {DepoFlatView} from "@/api/models/DepoFlatView";
import NCheckbox from "@/components/Checkbox/NCheckbox.vue";
import {EFaturaSenaryoTip, EFaturaSenaryoTipList} from "@/api/models/EFaturaSenaryoTip";
import FaturaHizliIslemDialog from "@/modules/Fatura/dialogs/FaturaHizliIslemDialog.vue";
import TaksitlendirDialog from "@/modules/OdemeTahsilat/dialogs/TaksitlendirDialog.vue";
import FaturaSatirItemExtend from "@/modules/Fatura/models/FaturaSatirItemExtend";
import {StokBirimItem} from "@/api/models/StokBirimItem";
import {FaturaSatirTip} from "@/api/models/FaturaSatirTip";
import {usePopupService} from "@/services/popupService";
import {useLayoutStore} from "@/layouts/ApplicationLayout/context";
import {EFaturaTip, EFaturaTipList} from "@/api/models/EFaturaTip";
import {useFaturaService} from "@/api/services/FaturaService";
import {FaturaSatirItem} from "@/api/models/FaturaSatirItem";
import {FaturaHareketTip, FaturaHareketTipList} from "@/api/models/FaturaHareketTip";
import NTagList from "@/components/Tag/NTagList.vue";
import NTag from "@/components/Tag/NTag.vue";
import {useCachedSubeDepoService} from "@/modules/Sube/services/useCachedSubeDepoService";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import FaturaCariAlisStoklar from "@/modules/Fatura/components/FaturaCariOncekiStoklarGrid.vue";
import {useKurCachedServis} from "@/modules/Ortak/services/useKurCachedServis";
import StokAramaInput from "@/modules/Fis/pages/StokAramaInput.vue";
import {FaturaSerileri} from "@/api/models/FaturaSerileri";
import NInput from "@/components/Input/NInput.vue";
import {Doviz, DovizList} from "@/api/models/Doviz";
import {ListeTip} from "@/api/models/ListeTip";
import {KdvIstisnaListe} from "@/api/models/KdvIstisnaListe";
import {TevkifatOranlariListe} from "@/api/models/TevkifatOranlariListe";
import {alert, confirm} from "devextreme/ui/dialog";
import {useUretimSevkService} from "@/api/services/UretimSevkService";
import {VergiView} from "@/api/models/VergiView";
import {VergiTip} from "@/api/models/VergiTip";
import VergiDialogs from "@/modules/Fatura/dialogs/VergiDialogs.vue";
import {Vergiler} from "@/api/models/Vergiler";
import {useSiparisService} from "@/api/services/SiparisService";
import {FaturaTip} from "@/api/models/FaturaTip";
import {PlasiyerEntity} from "@/api/models/PlasiyerEntity";
import {FirmaEntity} from "@/api/models/FirmaEntity";
import {usedCacheFirmaService} from "@/modules/Ayarlar/Firmalar/services/usedCacheFirmaService";
import {FaturaEntityDataSource} from "@/api/models/FaturaEntityDataSource";
import ReportViewerDialog from "@/modules/Report/dialogs/ReportViewerDialog.vue";
import NButton from "@/components/Button/NButton.vue";
import {FaturaView} from "@/api/models/FaturaView";
import {StokMenu} from "@/modules/Stok/models/StokMenu";
import {StokUtils} from "@/modules/Stok/utils/StokUtils";
import {PKAdresItem} from "@/api/models/PKAdresItem";

import {KurumTip} from "@/api/models/KurumTip";

import StokHareketDialogList from "@/modules/Stok/dialogs/StokHareketDialogList.vue";
import {RecordStatus} from "@/api/models/RecordStatus";
import {useN11SiparisService} from "@/api/services/N11SiparisService";
import {useHepsiBuradaService} from "@/api/services/HepsiBuradaService";
import FaturaKopyalaDialogs from "@/modules/Fatura/dialogs/FaturaKopyalaDialogs.vue";

import {FaturaDurumu} from "@/api/models/FaturaDurumu";
import {useIzibizEfaturaService} from "@/api/services/IzibizEfaturaService";


import {useStokFlatStore} from "@/modules/Stok/store/StokFlatStore";
import NDropdownItem from "@/components/Dropdown/NDropdownItem.vue";
import {StokBarkodView} from "@/modules/Stok/models/StokBarkodView";
import StokBarkodBasDialogs from "@/modules/Stok/dialogs/StokBarkodBasDialogs.vue";
import {ReportType} from "@/api/models/ReportType";
import {MenuFormType} from "@/modules/Ortak/models/Menu/MenuFormType";
import FaturaHizliSeriDialogs from "@/modules/Fatura/dialogs/FaturaHizliSeriDialogs.vue";
import {uuid} from "vue-uuid";
import ReportDropDownItemList from "@/modules/Report/components/ReportDropDownItemList.vue";
import NDropDownLabelItem from "@/components/Dropdown/NDropDownLabelItem.vue";
import {useGenelCache} from "@/modules/Ortak/services/useGenelCache";
import {ImporterType} from "@/api/models/ImporterType";
import {useCachedPlasiyerList} from "@/modules/Plasiyer/services/useCachedPlasiyerList";
import {onCariHareketHubManagerSignal} from "@/api/signals/CariHareketHubManagerSignal";
import {GenelUtils} from "@/modules/Ortak/utils/GenelUtils";
import {useCariSatisIzinleriService} from "@/api/services/CariSatisIzinleriService";
import {CariHareketTip} from "@/api/models/CariHareketTip";
import {usePersonelIsTakipService} from "@/api/services/PersonelIsTakipService";
import FaturaOdemeComponent from "@/modules/Fatura/components/FaturaOdemeComponent.vue";
import FaturaBilgiItemComponent from "@/modules/Fatura/components/FaturaBilgiItemComponent.vue";
import {useIziGelenFaturaService} from "@/api/services/IziGelenFaturaService";
import {StokBirimUtils} from "@/modules/Stok/utils/StokBirimUtils";
import {useNesilceConfig} from "@/api/NesilceConfig";
import {useSharedStore} from "@/store";
import {HizliEFaturaItemView} from "@/api/models/HizliEFaturaItemView";
import {DevExtremeUtils} from "@/devextreme/DevExtremeUtils";
import NDxStateStoring from "@/devextreme/NDxStateStoring.vue";
import NIconIonic from "@/components/NIconIonic.vue";
import CariFormDialog from "@/modules/Cari/dialogs/CariFormDialog.vue";
import NImportDropDownListItem from "@/modules/Import/components/NImportDropDownListItem.vue";
import {isNumber} from "lodash";
import StokMiktarOzet from "@/modules/Stok/components/StokMiktarOzet.vue";
import HizliStokDialog from "@/modules/Stok/dialogs/HizliStokDialog.vue";
import HizliSacEklemeDialog from "@/modules/Uretim/dialogs/HizliSacEklemeDialog.vue";
import {UretimStokEntity} from "@/api/models/UretimStokEntity";
import {Liste} from "@/api/models/Liste";
import {DxDataGrid} from "devextreme-vue/data-grid";
import EfaturaGonderCom from "@/modules/Fatura/components/EfaturaGonderCom.vue";
import {EIhracatGonderimTipList} from "@/api/models/EIhracatGonderimTip";
import {EIhracatTeslimSartiTipList} from "@/api/models/EIhracatTeslimSartiTip";
import {EIhracatKapCinsTipList} from "@/api/models/EIhracatKapCinsTip";
import SiparisFlatListBekleyenler from "@/modules/SiparisTeklif/dialogs/SiparisFlatListBekleyenler.vue";

import {SiparisSatirFlatView} from "@/api/models/SiparisSatirFlatView";
import {SiparisHareketTip} from "@/api/models/SiparisHareketTip";
import IhracatFaturaBilgileri from "@/modules/Fatura/components/IhracatFaturaBilgileri.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";


import {useIziGelenIrsaliyeService} from "@/api/services/IziGelenIrsaliyeService";
import {useCariCachedService} from "@/modules/Cari/services/useCariCachedService";
import {useFirmaService} from "@/api/services/FirmaService";
import {string} from "yup";
import StokSatisFiyatDegistirDialog from "@/modules/Stok/dialogs/StokSatisFiyatDegistirDialog.vue";


export default defineComponent({
  computed: {
    SiparisHareketTip() {
      return SiparisHareketTip;
    }
  },
  components: {
      StokSatisFiyatDegistirDialog,

    IhracatFaturaBilgileri,
    SiparisFlatListBekleyenler,
    EfaturaGonderCom,
    DxDataGrid,
    HizliSacEklemeDialog,
    HizliStokDialog,
    StokMiktarOzet,
    NImportDropDownListItem,
    CariFormDialog,
    NIconIonic,
    NDxStateStoring,
    FaturaBilgiItemComponent,
    FaturaOdemeComponent,
    NDropDownLabelItem,
    ReportDropDownItemList,
    FaturaHizliSeriDialogs,
    StokBarkodBasDialogs,
    NDropdownItem,
    FaturaKopyalaDialogs,
    StokHareketDialogList,
    NButton,
    ReportViewerDialog,
    VergiDialogs,
    NInput,
    StokAramaInput,
    FaturaCariAlisStoklar,
    NLoadingPanel,
    NTag,
    NTagList,
    TaksitlendirDialog,
    FaturaHizliIslemDialog,
    NFormField,
    NCheckbox,
    SmsKargoMailGonderComponent,
    Fatura_DigerBilgiler,
    Fatura_GenelBilgiler,
    CokluDokuman,
    NCard, NViewToolbar, NGrid, NViewGrid
  },
  props: {},
  setup() {
    const localState = reactive({
      reportType: ReportType,
      model: ref({ustBilgi: {}} as FaturaViewExtend),
      dataSource: ref({} as DataSource),
      solMenuDisabled: ref(true),

      seciliTab: ref(0),
      reportName: ref(""),
      mailSendTo: ref(""),
      util: FaturaUtil,
      faturaProp: ref({} as FaturaFormProp),
      cari: ref({} as CariEntity),
      kurlar: ref([] as KurView[]),
      giderYeriListe: ref([] as GiderYerleriView[]),
      depoList: ref([] as DepoFlatView[]),
      faturaFormuSatirlarDataGrid: ref(),
      depoId: ref(""),
      subeId: ref(""),
      faturaSenoryaTip: EFaturaSenaryoTip,
      efaturaSenoryaTip: ([{
        senaryo: EFaturaSenaryoTip.TANIMSIZ,
        pkAdres: ""
      }] as PKAdresItem[]),
      efaturaTip: EFaturaTipList,
      hizliHizmetEkleDialogRef: ref(false),
      taksitDialog: ref(false),
      selectedEfaturaSenaryoTip: (EFaturaSenaryoTip.TANIMSIZ as EFaturaSenaryoTip),
      selectedEfaturaTip: ref(EFaturaTip.SATIS as EFaturaTip),
      efaturaFaturaTip: EFaturaTip,
      faturaSerileri: ref([] as FaturaSerileri[]),
      faturaSerileriSelectBox: ref([] as FaturaSerileri[]),
      dovizListRef: DovizList,
      kdvIstinalari: ref([] as KdvIstisnaListe[]),
      tevkifatOranlari: ref([] as TevkifatOranlariListe[]),
      vergiler: ref([] as VergiView[]),
      vergiShowDialog: ref(false),
      showMiktarOzet: ref(false),
      vergiSelected: ref({} as VergiView),
      vergiSelectedSatirId: ref(""),
      plasiyerId: ref(""),
      plasiyerList: ref([] as PlasiyerEntity[]),
      firmaEntity: ref({} as FirmaEntity),
      reportData: ref([] as FaturaEntityDataSource[]),
      showReportDialog: ref(false),
      stokUtils: StokUtils,
      eFaturaSenaryoTipList: EFaturaSenaryoTipList,
      kopyalaDailog: ref(false),
      stokHareketShowDialog: ref(false),
      stokId: ref(""),
      itemId: ref(""),
      stokAdi: ref(""),
      recordStatus: RecordStatus,
      solMenuGenislik: ref(false),
      kdvsizTutarIskonto: ref(0),

      //stoklarFlat: ref([] as StokFlatView[]),
      faturaHareketTipRef: FaturaHareketTip,
      faturaHareketTipListRef: FaturaHareketTipList,
      basilacaklar: ref([] as StokBarkodView[]),
      showBarkodBasDialog: ref(false),
      showHizliSeriDialog: ref(false),
      hizliSeriFaturaSatirItem: ref({} as FaturaSatirItemExtend),
      importerDialog: ref(false),
      importerType: ImporterType,
      onSignalSatisIzni: ref(false),
      cariFormShow: ref(false),
      hizliStokDialogShow: ref(false),
      hizliSacEklemeDialogShow: ref(false),
      fiyatGrubuOran: ref(0),
      fiyatGrubuTutar: ref(0),
      eihracatGonderimTipList: EIhracatGonderimTipList,
      eihracatTeslimTipList: EIhracatTeslimSartiTipList,
      eihracatKapTipLst: EIhracatKapCinsTipList,
      siparisTeklifBekleyenDialogShow: ref(false),
      cariNameChanged: ref(false),
      showSatisFiyatDialog:ref(false)
    });

    function siparisFlatAdd(data: SiparisSatirFlatView) {

      if (data) {
        localState.model.faturaTip = FaturaTip.Siparis;
        const newSatir = FaturaUtil.generateSiparisToFatura(localState.depoId, localState.plasiyerId, data);


        if (localState.model && localState.model.satirlar && localState.model.satirlar.length > -1) {
          localState.model.satirlar.push(newSatir);
          localState.dataSource.reload();
          (localState.faturaFormuSatirlarDataGrid.instance as any).navigateToRow(newSatir.id);
          localState.model.setFaturaAltiIskonto();
        }

      }


    }

    function getEFaturaSenaryoTip(): PKAdresItem[] {
      if (localState.faturaProp?.menu?.hareketTipi == FaturaHareketTip.SatisIrsaliye) {
        const tipler = localState.efaturaSenoryaTip.filter(a => a.senaryo == EFaturaSenaryoTip.TEMELIRSALIYE);
        tipler.push({senaryo: EFaturaSenaryoTip.TANIMSIZ});

        return tipler;
      } else
        return localState.efaturaSenoryaTip;
    }

    function calculateFilterExpression(e: any) {
      //console.log('calculateFilterExpression', e);
    }

    function faturaGuncelleKontrol() {
      if (localState.model.tip == FaturaHareketTip.HizmetMasrafAlisFatura || localState.model.status != RecordStatus.active)
        return true;
      else
        return false;
    }

    function faturaSilKontrol() {
      if (localState.model.id && localState.model.id.length > 0) {
        if (localState.model.tip == FaturaHareketTip.HizmetMasrafAlisFatura)
          return false;
        else
          return true;
      } else {
        return true;
      }
    }

    // watch(() => localState.selectedEfaturaSenaryoTip, (val) => {
    //
    //
    // });
    watch(() => localState.model.altBilgi, () => {

      localState.model.setFaturaAltiIskonto();
    }, {deep: true});

    watch(() => localState.kdvsizTutarIskonto, () => {
      localState.model.setFaturaAltiIskontoSifirla();
      const toplam = localState.model.getSatirBrutToplam() - localState.model.getSatirIskontoTutar();
      localState.model.altBilgi!.tutarIskonto = toplam * (localState.kdvsizTutarIskonto * localState.model!.altBilgi!.cariIslemKur!) / (toplam + localState.model.getKdvTutar());
    });

    function kurChanged(data: FaturaSatirItemExtend) {
      const fiyat = data.fiyat! * data.kur!;
      data.kur = localState.kurlar[data.doviz as number].alis;
      data.fiyat = fiyat / data.kur!;

      changedKdvsiz(data, data.fiyat);
    }


    async function load() {

      localState.faturaFormuSatirlarDataGrid?.instance?.beginCustomLoading();
      // ...

      localState.firmaEntity = await usedCacheFirmaService().getFirma();
      localState.faturaProp = JSON.parse(JSON.stringify(useFaturaStore().$state)).faturaFormProp as FaturaFormProp;


      if (localState.faturaProp.model) {
        localState.model = new FaturaViewExtend(localState.faturaProp.model);
        localState.dataSource = new DataSource({
          store: localState.model.satirlar
        });
        if (localState.model.status) {
          //
        } else {
          localState.model.status = RecordStatus.active;
        }


      } else {
        if (localState.faturaProp.faturaId && localState.faturaProp.faturaId.length > 0) {
          localState.model = await FaturaUtil.generateUpdateFaturaView(localState.faturaProp.faturaId);
        } else
          localState.model = await FaturaUtil.generateInsertFaturaView(localState.faturaProp.cariId, localState.faturaProp.menu?.hareketTipi);

        localState.dataSource = new DataSource({
          store: localState.model.satirlar
        });
      }

      localState.plasiyerList = await useCachedPlasiyerList().getUserPlasiyerList();
      if (localState.model && localState.model.eFatura && localState.model.eFatura.senaryo) {

        if ((localState.model.eFatura.senaryo as EFaturaSenaryoTip) != EFaturaSenaryoTip.TANIMSIZ) {
          localState.efaturaSenoryaTip.push({
            senaryo: localState.model.eFatura.senaryo,
            pkAdres: ""
          });
          localState.selectedEfaturaSenaryoTip = localState.model.eFatura.senaryo;
        }
      }

      if (localState.model!.ustBilgi!.cari?.id && localState.model!.ustBilgi!.cari?.id.length > 0)
        localState.cari = await useCariService().getGetir(localState.model!.ustBilgi!.cari.id);

      // n11

      if (localState.model && localState.model.id && localState.model.id.length > 0 && localState.model.faturaTip == FaturaTip.N11) {
        if (localState.model.ustBilgi && localState.model.ustBilgi.pazaryeriKampanyaKod && localState.model.ustBilgi.pazaryeriKampanyaKod.length > 0) {
          //
        } else {
          const liste = await useN11SiparisService().getN11SiparisleriKampanyaKod(localState.model.id) as string;
          if (liste) {
            localState.model.ustBilgi!.pazaryeriKampanyaKod = liste[0] as string;
            localState.model.ustBilgi!.kargoFirmasi = liste[1] as string;
          }
        }
      }
      // hepsiburada
      if (localState.model && localState.model.id && localState.model.id.length > 0 && localState.model.faturaTip == FaturaTip.HepsiBurada) {
        if (localState.model.ustBilgi && localState.model.ustBilgi.pazaryeriKampanyaKod && localState.model.ustBilgi.pazaryeriKampanyaKod.length > 0) {
          //
        } else {
          const kargoKod: string = await useHepsiBuradaService().getKargoKodu(localState.model.id);
          localState.model.ustBilgi!.pazaryeriKampanyaKod = kargoKod?.toString();
        }
      }

      if (localState.cari && localState.cari.id && localState.cari.id.length > 0) {
        // cari defaultları
        if (localState.cari.plasiyerId && localState.cari.plasiyerId.length > 0)
          localState.plasiyerId = localState.cari.plasiyerId;


        if (localState.cari.calisilacakVadeGun && localState.cari.calisilacakVadeGun > 0) {
          if (localState.model && localState.model.ustBilgi && localState.model.ustBilgi.vade) {
            if (!(localState.model.id && localState.model.id.length > 0))
              localState.model.ustBilgi.vade = new Date().addDays(localState.cari.calisilacakVadeGun).toISOString();
          }
        }

      }


      //localState.stoklarFlat = useStokFlatStore().dataSource.store()._array;

      if (localState.model && localState.model.id && localState.model.id.length > 0) {
        localState.selectedEfaturaTip = localState.model.eFatura?.eFaturaTip as EFaturaTip;
        localState.model!.altBilgi!.cariIslemKur = localState.model.altBilgi?.cariIslemKur;
      } else {
        localState.model!.altBilgi!.cariIslemDoviz = localState.cari.varsayilanDoviz;
        if (localState.cari.varsayilanDoviz != Doviz.TRY) {
          cairIslemDovizChanged();
        }

      }
      if (localState.faturaProp?.menu?.hareketTipi == FaturaHareketTip.SatisIrsaliye)
        localState.selectedEfaturaTip = EFaturaTip.SEVK;


      // Sol Menü Varsayılanı
      const solMenuVarsayilan = await GenelUtils.getDefaultSettings("faturaIrsaliyeSolMenu");
      if (solMenuVarsayilan == "1") {
        localState.solMenuDisabled = true;
      } else if (solMenuVarsayilan == "0") {
        localState.solMenuDisabled = false;
      } else {
        localState.solMenuDisabled = true;
      }
      // console.log("yüklendi");

      await FaturaUtil.getFaturaLoadingStokBilgileri(localState.model.satirlar as FaturaSatirItemExtend[]);
      localState.faturaFormuSatirlarDataGrid?.instance?.endCustomLoading();
    }


    async function efaturaKontrol() {

      setTimeout(async () => {
        if (localState.cari && localState.cari.adresler && localState.model.isFatura == false)
          localState.model!.gonder!.mailAdres = localState.cari.adresler[0]?.mail;

        if (localState.model.isFatura == true)
          if (localState.model.ustBilgi?.mail && localState.model.ustBilgi.mail.length > 0)
            localState.model!.gonder!.mailAdres = localState.model.ustBilgi?.mail;
          else
            localState.model!.gonder!.mailAdres = localState.model.teslimatAdresi?.mail;
        // if (localState.firmaEntity.eArsivOtamatikMail == true && localState.model && localState.model.gonder)

        if (localState.firmaEntity && localState.firmaEntity && localState.firmaEntity.eArsivOtamatikMail)
          localState.model.gonder!.eArsivMailGonder = true;
        else
          localState.model.gonder!.eArsivMailGonder = false;
      }, 1000);

    }

    secondLoad();

    async function secondLoad() {

      localState.kurlar = await useKurCachedServis().getKurList();
      localState.depoList = await useCachedSubeDepoService().getUserDepoList();
      localState.giderYeriListe = await useListelerService().getGiderYerleri();
      localState.faturaSerileri = await useListelerService().getFaturaSerileri();
      localState.kdvIstinalari = await useGenelCache().getKdvIstisnaList();
      localState.tevkifatOranlari = await useGenelCache().getTevkifatList();
      localState.vergiler = await useGenelCache().getVergiList();

      efaturaKontrol();
    }


    function stokDblClick(stok: StokFlatView) {
      // console.log("stok bulundu", stok);
      const satir = FaturaUtil.generateFaturaStokSatirItem(stok, localState.model.tip as FaturaHareketTip, localState.plasiyerId);

      satiraStokEkle(satir, stok);


    }

    async function efaturaGoster() {

        if(localState.model.eFatura && localState.selectedEfaturaSenaryoTip > 0){
          localState.model.eFatura.senaryo = localState.selectedEfaturaSenaryoTip;
        }
      const form = document.getElementById(localState.model.id as string) as HTMLFormElement;
      const config = await useNesilceConfig().config;
      let url = config?.API_URL;
      if (!url)
          url = "";
      form.action = `${url}/api/IzibizEfatura/PrintSelectedFromFaturaView/${useSharedStore().firm.id}/${useSharedStore().firm.period}`;
      form.submit();
    }

    function satiraStokEkle(satir: FaturaSatirItemExtend, stok: StokFlatView = {}, useFiyatGrup = true) {
      if (localState.model.satirlar) {
        satir.depoId = localState.depoId;
        satir.plasiyerId = localState.plasiyerId;
        if (satir.doviz && (satir.doviz == Doviz.EUR || satir.doviz == Doviz.USD)) {
          satir.kur = localState.kurlar.find(a => a.doviz == satir.doviz)?.alis;
        }
        let newSatir = satir;
        if (useFiyatGrup == true)
          newSatir = fiyatGrubuAyari(satir);

        if (localState.faturaProp &&
            localState.faturaProp.menu &&
            localState.faturaProp.menu.hareketTipi &&
            localState.faturaProp.menu.hareketTipi == FaturaHareketTip.SatisFatura){

          FaturaUtil.getStokBirimGroupFiyat(localState.cari.birimFiyatGrupAdi as string, stok, newSatir);

          satir.fiyat = StokUtils.getPerakendeSubeFiyat(satir.fiyat as number, satir.stokId as string, satir.depoId as string);

          satir.fiyat = satir.fiyat * (1 + (satir.kdv! / 100));
        }
        // console.log("new Satir" ,newSatir);
        localState.model.satirlar.push(newSatir);
        localState.dataSource.reload();
        (localState.faturaFormuSatirlarDataGrid.instance as any).navigateToRow(newSatir.id);
        localState.model.setFaturaAltiIskonto();

        //console.log("new Satir" , newSatir);
      }
    }


    function fiyatGrubuAyari(satir: FaturaSatirItemExtend) {
      if ((localState.fiyatGrubuOran != 0 || localState.fiyatGrubuTutar != 0) && satir.tip == FaturaSatirTip.Stok) {

        satir.fiyat = StokUtils.getStokFiyat(satir.stokId as string, localState.faturaProp?.menu?.hareketTipi);

        if (localState.fiyatGrubuOran > 0) {
          satir.fiyat = satir.fiyat! * (1 + (localState.fiyatGrubuOran / 100));
          satir.fiyatKdvli = satir.fiyat * (1 + (satir.kdv! / 100));

          localState.model.altBilgi!.altIskontolar = [];


        } else if (localState.fiyatGrubuOran != 0) {
          satir.fiyatKdvli = satir.fiyat * (1 + (satir.kdv! / 100));
          localState.model.altBilgi!.altIskontolar = [];
          localState.model.altBilgi!.altIskontolar.push(localState.fiyatGrubuOran * -1);

        }

        if (localState.fiyatGrubuOran == 0 && localState.fiyatGrubuTutar != 0) {
          satir.fiyat = satir.fiyat + localState.fiyatGrubuTutar;
          satir.fiyatKdvli = satir.fiyat * (1 + (satir.kdv! / 100));
        }

      } else {
        satir.fiyat = StokUtils.getStokFiyat(satir.stokId as string, localState.faturaProp?.menu?.hareketTipi);
        satir.fiyatKdvli = satir.fiyat! * (1 + (satir.kdv! / 100));
        localState.model.altBilgi!.altIskontolar = [];

      }
      localState.model.setFaturaAltiIskonto();
      return satir;
    }

    function cokluhHizmetDblClick(hizmetler: HizmetMasrafEntity[]) {
      if (hizmetler && hizmetler.length > 0) {
        hizmetler.forEach((a) => hizmetDblClick(a));
      }
    }

    function hizmetDblClick(hizmet: HizmetMasrafEntity) {
      if (localState.model.satirlar) {
        const satir = FaturaUtil.generateFaturaHizmetSatirItem(hizmet);
        satir.depoId = localState.depoId;
        satir.plasiyerId = localState.plasiyerId;
        const newSatir = fiyatGrubuAyari(satir);
        newSatir.bakiye = 0;
        newSatir.anaBirim = satir.birim;


        newSatir.guncelSatisFiyat = 0;
        newSatir.guncelSatisDoviz = hizmet.doviz;
        newSatir.guncelSatisKdv = hizmet.kdv;
        newSatir.guncelSatisKdvDahil = false;

        satir.yeniSatisFiyatKdvDahil = 0;
        satir.yeniSatisFiyat = 0;


        localState.model.satirlar.push(newSatir);
        localState.dataSource.reload();
        (localState.faturaFormuSatirlarDataGrid.instance as any).navigateToRow(satir.id);
        localState.model.setFaturaAltiIskonto();
      }
    }


    function rowUpdating(e: any) {
      const row = e.key as FaturaSatirItemExtend;
      //console.log("e", e);
      // console.log("rowUpdating", row);


      const getKdvliTutar = e.newData["getKdvliTutar"];
      if (getKdvliTutar) {
          if (getKdvliTutar == 0)
              row.fiyat = 0;
          else
              changedKdvliToplam(row, getKdvliTutar);
      }

      const uzunluk = e.newData["uzunluk"];
      if (uzunluk && uzunluk > 0 && row.sacKgMetre != 0) {

        row.miktar = (uzunluk * row.kiloAdet! * row.sacKgMetre!) / 1000;
      }

      const kiloAdet = e.newData["kiloAdet"];
      if (kiloAdet && kiloAdet > 0 && row.sacKgMetre != 0) {
        if (row.uzunluk && row.uzunluk != 0)
          row.miktar = (row.uzunluk * kiloAdet * row.sacKgMetre!) / 1000;
        else
          row.miktar = kiloAdet * row.sacKgMetre!;
      }
      // const miktar = e.newData["miktar"];
      // if (miktar > 0 && row.kiloAdet && row.kiloAdet != 0) {
      //   if (row.adetKiloCarpan && row.adetKiloCarpan != 0) {
      //     row.kiloAdet = miktar * row.adetKiloCarpan;
      //   } else {
      //     const stok: StokFlatView = useStokFlatStore().getStok(row.stokId as string);
      //     if (stok && stok.getAdetKilo)
      //       row.kiloAdet = miktar * stok.getAdetKilo;
      //   }
      //
      // }


      const yeniSatisFiyat = e.newData["yeniSatisFiyat"];
      if (isNumber(yeniSatisFiyat)) {
        if (yeniSatisFiyat <= 0)
          row.yeniSatisFiyatKdvDahil = 0;
        else
          row.yeniSatisFiyatKdvDahil = yeniSatisFiyat * (1 + (row.guncelSatisKdv! / 100));

      }
      const yeniSatisFiyatKdvDahil = e.newData["yeniSatisFiyatKdvDahil"];

      if (isNumber(yeniSatisFiyatKdvDahil)) {
        if (yeniSatisFiyatKdvDahil <= 0)
          row.yeniSatisFiyat = 0;
        else
          row.yeniSatisFiyat = yeniSatisFiyatKdvDahil / (1 + (row.guncelSatisKdv! / 100));

      }


      const kdvliFiyat = e.newData["fiyatKdvli"];
      if (kdvliFiyat) {
        if (kdvliFiyat == 0)
          row.fiyat = 0;
        else
          changedKdvli(row, kdvliFiyat);
      }

      const kdvsizFiyat = e.newData["fiyat"];
      if (kdvsizFiyat) {
        if (kdvsizFiyat == 0)
          row.fiyatKdvli = 0;
        else
          changedKdvsiz(row, kdvsizFiyat);
      }
      const kdv = e.newData["kdv"];
      if (kdv) {
        const carpan = ((100 + kdv) / 100);
        row.fiyatKdvli = row.fiyat! * carpan;
      }
      if (kdv > 0) e.key.kdvIstisnasi = "";

      const tevkifatOran = e.newData["tevkifatOran"];
      if (tevkifatOran == 0) {
        row.tevkifatKod = "";
        row.tevkifatAd = "";
      }
      if (tevkifatOran && tevkifatOran > 0) {
        const tevkifat = localState.tevkifatOranlari?.find(a => a.oran == tevkifatOran);
        row.tevkifatKod = tevkifat?.kod;
        row.tevkifatAd = tevkifat?.aciklama;
      }
      const tevkifatKod = e.newData["tevkifatKod"];
      if (tevkifatKod) {
        const tevkifat = localState.tevkifatOranlari?.find(a => a.kod == tevkifatKod);
        row.tevkifatAd = tevkifat?.aciklama;
        row.tevkifatOran = tevkifat?.oran;
      }

      const tevkifatAd = e.newData["tevkifatAd"];
      if (tevkifatAd) {
        row.tevkifatKod = localState.tevkifatOranlari?.find(a => a.aciklama == tevkifatAd)?.kod;
      }

      const katilimPayi = e.newData["katilimPayi"];
      if (katilimPayi > 0)
        if (e && e.key) {


          const arttirim = (row.fiyat! * katilimPayi) / row.getNetFiyatExtend()!;
          row.fiyat = row.fiyat! + arttirim;

          changedKdvsiz(row, row.fiyat);
        }


    }

    function rowEditing(e: any) {

      if (e.column.name == "kdvIstisnasi") {
        if (e.data.kdv > 0) {
          e.cancel = true;
          alert("Bu Alanı Seçmek İçin Kdv Oranı %0 Olmalıdır !", "Kdv İstisna Hatası");
        }
      }
      if (e.column.name == "giderYeri") {
        if (e.data.tip == FaturaSatirTip.Stok) e.cancel = true;
      }

      if (e.column.name == "getKdvliTutar") {
          e.data.getKdvliTutar = e.data.getKdvliTutarExtend() / e.data.kur
      }


    }

    function changedKdvli(row: FaturaSatirItemExtend, kdvliFiyat: number) {
      if (kdvliFiyat == 0)
        row.fiyat = 0;
      else {
        const carpan = ((100 + row.kdv!) / 100);
        row.fiyat = kdvliFiyat / carpan;
      }
      // console.log(` changedKdvli kdvsiz fiyat : ${row.fiyat}   - fiyat kdvli : ${kdvliFiyat}`);
    }

    function changedKdvliToplam(row: FaturaSatirItemExtend, getkdvliToplam: number) {
        if (getkdvliToplam == 0)
            row.fiyat = 0;
        else {
            const carpan = ((100 + row.kdv!) / 100);
            row.fiyat = getkdvliToplam / carpan / (row.miktar as number);
        }
        changedKdvsiz(row, row.fiyat as number)
    }

    function changedKdvsiz(row: FaturaSatirItemExtend, kdvsizFiyat: number) {

      if (kdvsizFiyat == 0)
        row.fiyatKdvli = 0;
      else {
        const carpan = ((100 + row.kdv!) / 100);
        row.fiyatKdvli = kdvsizFiyat * carpan;
      }

      // console.log(` changedKdvsiz kdvsiz fiyat : ${kdvsizFiyat}   - fiyat kdvli : ${row.fiyatKdvli}`);
    }

    async function efaturaGonder() {

      await useFaturaService().postEfaturaGonder(
          {
            eArsivMailGonder: localState.model.gonder?.eArsivMailGonder,
            eArsivMail: localState.model.gonder?.mailAdres,
            eFaturaSenaryoTip: localState.selectedEfaturaSenaryoTip,
            eFaturaTip: localState.selectedEfaturaTip,
            seri: localState.model.ustBilgi?.faturaSeri as string,
            faturaId: localState.model.id as string

          } as HizliEFaturaItemView
      );

      usePopupService().pushSuccessNotification({
        title: "Başarılı",
        body: localState.model!.ustBilgi!.cari?.name + " E-FATURA SERVİSTE İŞLENECEK"
      });
      useLayoutStore().closeCurrentTab();
    }


    async function save() {
      // Tabloyu commit et (focus)
      localState.faturaFormuSatirlarDataGrid?.instance?.closeEditCell()

      const stokIdAndItemId = localState.model.satirlar?.filter(a => a.tip == FaturaSatirTip.Stok).map((a) => {
        if (a.itemId && a.itemId.length > 0)
          return (a.stokId + a.itemId);
        else
          return a.stokId;
      });
      const kontrol = await StokUtils.getStokKontrol(stokIdAndItemId as string[]);
      if (kontrol == false) {
        alert("Fatura İçinde Stoklardan Biri veya Birkaçı Bulunamadı !", "Stoklar Bulunamadı !");

        return;
      }
      localState.model.ustBilgi!.subeId = localState.subeId;
      localState.model.eFatura!.senaryo = localState.selectedEfaturaSenaryoTip;
      localState.model.eFatura!.eFaturaTip = localState.selectedEfaturaTip;
      if (localState.faturaProp.fatuuraTip)
        localState.model.faturaTip = localState.faturaProp.fatuuraTip;

      // Tevkifat Number Düzeltmesi
      localState.model.satirlar?.forEach((a:FaturaSatirItem) => {
          if (a.tevkifatOran == undefined)
              a.tevkifatOran = 0
      })


      await FaturaUtil.saveFaturaForm(localState.model, localState.faturaProp.menu);

      usePopupService().pushSuccessNotification({
        title: "Başarılı",
        body: localState.model!.ustBilgi!.cari?.name + " carisine ait fatura kayıt edildi."
      });
      useLayoutStore().closeCurrentTab();
      // }
    }

    async function hareketSil() {
      if (await confirm("Silmek istiyor musunuz ? ", "Silme Onayı")) {
        if (localState.model.id && localState.model.id!.length > 0) {
          const faturaState = localState.model?.eFatura?.faturaDurumu;
          if (faturaState == FaturaDurumu.Sended) {
            if (await confirm("Fatura Gibe Gönderilmiş, Yinede Silmek İstiyor musunuz ? ", "E-Fatura Silme Uyarısı"))
              await hareketSilSecond();
          } else {
            await hareketSilSecond();
          }


        }
      }
    }

    async function hareketSilSecond() {
      switch (localState.model.faturaTip) {
        case FaturaTip.B2b : {
          await useSiparisService().deleteB2bFaturaSil(localState.model.id as string);
          break;
        }
        case FaturaTip.Siparis: {
          await useSiparisService().deleteFaturaSil(localState.model.id as string);
          break;
        }
        case FaturaTip.Sac: {
          await useUretimSevkService().deleteSacFaturaSil(localState.model.id as string);
          break;
        }
        case FaturaTip.IsTakip : {
          await usePersonelIsTakipService().deleteFaturaSil(localState.model.id as string);
          break;
        }
        case FaturaTip.Normal: {
          await useFaturaService().deleteSil(localState.model.id as string);
          break;
        }
        case FaturaTip.Izibiz : {
          if (localState.model.tip == FaturaHareketTip.AlisIrsaliye)
            await useIziGelenIrsaliyeService().deleteFaturaSil(localState.model.id as string);
          else
            await useIziGelenFaturaService().deleteFaturaSil(localState.model.id as string);
          break;
        }
        default: {
          await useFaturaService().deleteSil(localState.model.id as string);
          break;
        }

      }
        await useIziGelenFaturaService().putRemoveMatch(localState.model?.id as string);
      usePopupService().pushWarningNotification({
        duration: 5000,
        title: "Başarılı",
        body:
            localState.model?.ustBilgi?.cari?.name +
            " carisine ait fatura kaydı başarıyla silindi."
      });
      useLayoutStore().closeCurrentTab();
    }

    function hizmasrafEkleClick(data: FaturaSatirItem[]) {
      data.forEach(d => {
        localState.model!.satirlar!.push(new FaturaSatirItemExtend(d));
      });
      dataGridRefresh();
    }

    function dataGridRefresh() {
      localState.dataSource.reload();
      (localState.faturaFormuSatirlarDataGrid.instance as any).refresh();
    }

    function satirSilClick(e: any) {
      localState.model.setFaturaAltiIskonto();
    }

    function getDovizGenel() {
      if (localState.kurlar && localState.kurlar.length > 0 && localState.model.altBilgi) {
        const doviz = localState.model.altBilgi.cariIslemDoviz as number;
        const kur = localState.kurlar[doviz];
        if (kur) return kur.sembol;
      }
      return "₺";
    }

    function cairIslemDovizChanged() {
      const kur = localState.kurlar.find(a => a.doviz == localState.model?.altBilgi?.cariIslemDoviz);
      if (kur)
        localState.model.altBilgi!.cariIslemKur = kur.alis;
    }


    watch(() => localState.model.isFatura, () => {
      localState.model!.gonder!.mailAdres = "";
    });

    async function satirlariBirlestir() {

      const result = await useFaturaService().putSatirBirlestir(localState.model.satirlar as FaturaSatirItem[]);
      if (result) {
        localState.model.satirlar = [];
        localState.dataSource = new DataSource({
          store: localState.model.satirlar
        });
        result.forEach((a: FaturaSatirItem) => {
          localState.model!.satirlar!.push(new FaturaSatirItemExtend(a));
        });
      }
      dataGridRefresh();
    }

    function tabDisabled(): boolean {
      return localState.faturaProp && localState.faturaProp?.menu && localState.faturaProp.menu?.hareketTipi && localState.faturaProp.menu?.hareketTipi == FaturaHareketTip.AlisFaturasi ||
          localState.faturaProp.menu?.hareketTipi == FaturaHareketTip.SatisIadeFaturasi;
    }


    function oncekiStoklarDblClick(oncekiSatir: FaturaSatirItem) {
      if (oncekiSatir) {
        const satir = new FaturaSatirItemExtend(oncekiSatir);
        if (satir && localState.model.satirlar) {
          satir.depoId = localState.depoId;
          satir.miktar = 1;
          satir.referansId = satir.id;
          satir.id = uuid.v4();
          satir.fiyatKdvli = (satir.fiyat! * (1 + (satir.kdv! / 100)));

          if (satir.doviz && (satir.doviz == Doviz.EUR || satir.doviz == Doviz.USD)) {
            satir.kur = localState.kurlar.find(a => a.doviz == satir.doviz)?.alis;
          }

          localState.model.satirlar.push(satir);
          localState.dataSource.reload();
          (localState.faturaFormuSatirlarDataGrid.instance as any).navigateToRow(satir.id);
          localState.model.setFaturaAltiIskonto();
        }
      }
    }

    function rightMenu(e: any) {
      const otv: any = [];
      const stopaj: any = [];
      const ozelIletisim: any = [];
      const diger: any = [];

      // H/M Sağ tık engelleme
      const satir = e?.row?.data as FaturaSatirItem;
      const isStok = satir?.tip == FaturaSatirTip.Stok;
      if (!isStok) {
        e.preventDefault();
        return;
      }

      localState.vergiler.forEach((v) => {
        switch (v.vergiTip) {
          case VergiTip.Otv:
            otv.push({text: v.adi, onClick: () => vergiEkle(e.row.data, v)});
            break;
          case VergiTip.Stopaj:
            stopaj.push({text: v.adi, onClick: () => vergiEkle(e.row.data, v)});
            break;
          case VergiTip.Iletisim:
            ozelIletisim.push({text: v.adi, onClick: () => vergiEkle(e.row.data, v)});
            break;
          case VergiTip.Diger:
            diger.push({text: v.adi, onClick: () => vergiEkle(e.row.data, v)});
            break;
        }
      });
      e.items = [
        {text: "Stoğa Git", onClick: () => StokMenu.stokOpenPage(e?.row?.data), icon: "plus"},
          {
              text: "Satış Fiyatını Değiştir", onClick: () => {
                  const satir = e?.row?.data as FaturaSatirItem;
                  if (satir && satir.tip == FaturaSatirTip.Stok) {
                      localState.stokId = satir.stokId as string;
                      localState.itemId = satir.itemId as string;
                      localState.stokAdi = satir.ad as string;
                      localState.showSatisFiyatDialog = true;
                  }
              }, icon: "money"
          },
          {
              text: "Barkod Yazdır", onClick: () => {
                  const satir = e?.row?.data as FaturaSatirItem;
                  if (satir && satir.tip == FaturaSatirTip.Stok) {
                      localState.stokId = satir.stokId as string;
                      localState.itemId = satir.itemId as string;
                      localState.stokAdi = satir.ad as string;
                      barkodBas(localState.stokId,localState.itemId);
                  }
              }, icon: "print"
          },
          {
          text: "Stok Hareketleri", onClick: () => {
            const satir = e?.row?.data as FaturaSatirItem;
            if (satir && satir.tip == FaturaSatirTip.Stok) {
              localState.stokId = satir.stokId as string;
              localState.itemId = satir.itemId as string;
              localState.stokAdi = satir.ad as string;
              localState.stokHareketShowDialog = true;
            }
          }, icon: "verticalaligncenter"
        },


        {text: "Stok Miktar Özetleri", icon: "overflow", onClick: () => openMiktarOzet(e)},
        {
          text: "Hızlı Stok Düzenle", icon: "edit", onClick: () => {
            const satir = e?.row?.data as FaturaSatirItem;
            if (satir && satir.tip == FaturaSatirTip.Stok) {
              localState.stokId = satir.stokId as string;
              localState.hizliStokDialogShow = true;
            }
          }
        },
        {
          text: "Hızlı Seri Gir", icon: "increaseindent", onClick: () => {
            const satir = e?.row?.data as FaturaSatirItemExtend;
            if (satir && satir.tip == FaturaSatirTip.Stok) {
              localState.hizliSeriFaturaSatirItem = satir;
              localState.showHizliSeriDialog = true;
            }
          }
        },
        {
          text: "Vergiler", items: [
            {text: "Ötv  Ekle", items: otv},
            {text: "Stopaj Ekle", items: stopaj},
            {text: "Özel İletişim Ekle", items: ozelIletisim},
            {text: "Diğer", items: diger}
          ]
        }];


      if (e.row == undefined)
        e.items = undefined;
    }

    function vergiEkle(e: FaturaSatirItemExtend, vergi: VergiView) {
      localState.vergiSelected = vergi;
      localState.vergiSelectedSatirId = e.id as string;
      localState.vergiShowDialog = true;

    }

    function vergiDialogAdd(vergi: Vergiler, satirId: string) {
      const satir = localState.model?.satirlar!.find(a => a.id == satirId);
      if (satir) {
        satir.vergiler!.push(vergi);
        localState.faturaFormuSatirlarDataGrid?.instance?.refresh();
      }
    }

    function vergiDialogsDelete(data: FaturaSatirItem, index: number) {

      if (data) {
        data.vergiler?.splice(index, 1);
      }
    }

    function coklu(stoklar: StokFlatView[]) {
      if (stoklar && stoklar.length > 0) {
        stoklar.forEach((stok) => {
          stokDblClick(stok);
        });
      }
    }

    async function onizleme() {
      const reportData: FaturaEntityDataSource = {
        fatura: await useFaturaService().postToEntity(localState.model as FaturaView, localState.faturaProp.menu.hareketTipi as FaturaHareketTip),
        firma: localState.firmaEntity,
        cari: localState.cari
      };
      localState.reportData = [];
      localState.reportData.push(reportData);
      localState.showReportDialog = true;

    }

    async function passiveToActive() {
      await useFaturaService().getPassiveToActive(localState.model.id as string);
      usePopupService().pushSuccessNotification({
        title: "Aktif Edildi",
        body: localState.model!.ustBilgi!.cari?.name + " carisine ait fatura Aktif edildi"
      });
      useLayoutStore().closeCurrentTab();

    }

    async function efaturaOnFoucIn(refresh = true) {
      if (localState.model && localState.model.ustBilgi && localState.model.ustBilgi.cari && localState.model.ustBilgi.cari.id
          && localState.model.ustBilgi.cari.id.length > 0)
        localState.cari = await useCariService().getGetir(localState.model.ustBilgi.cari.id);


      if (localState.model.isFatura == false) {
        if (localState.cari.pkAdresleri && localState.cari.pkAdresleri.length > 0 && refresh == true) {
          localState.efaturaSenoryaTip = localState.cari.pkAdresleri;
        } else {
          let idendiferNumber = "";
          switch (localState.cari.kurumTip) {
            case  KurumTip.Mukellef:
              idendiferNumber = localState.cari.vergiNo as string;
              break;
            case KurumTip.Sahis:
              idendiferNumber = localState.cari.tcNo as string;
              break;
            case KurumTip.Bos: {
              if (localState.cari.vergiNo && localState.cari.vergiNo.length > 0)
                idendiferNumber = localState.cari.vergiNo as string;
              else
                idendiferNumber = localState.cari.tcNo as string;
              break;
            }
          }
          if (idendiferNumber && idendiferNumber.length > 0) {
            localState.efaturaSenoryaTip = await useIzibizEfaturaService().getEfaturaKontrol(idendiferNumber);
            localState.cari.pkAdresleri = localState.efaturaSenoryaTip;

            if (localState.efaturaSenoryaTip.filter(a => a.senaryo == EFaturaSenaryoTip.EARSIVFATURA).length == 0)
              await useCariService().putPkAdresiKaydet(localState.efaturaSenoryaTip, localState.cari.id as string);
          } else {
            usePopupService().pushDangerNotification(
                {
                  body: "Cari VKN veya TCKNO Eksik", title: "Eksik Bilgi"
                }
            );
          }
        }

      } else {
        let idendiferNumber = "";
        // if (localState.model.ustBilgi?.tcNo && localState.model.ustBilgi.tcNo.length > 0)
        //   idendiferNumber = localState.model.ustBilgi?.tcNo;
        if (localState.model.ustBilgi?.vergiNo && localState.model.ustBilgi.vergiNo.length > 0)
          idendiferNumber = localState.model.ustBilgi?.vergiNo;
        if (idendiferNumber && idendiferNumber.length > 0)
          localState.efaturaSenoryaTip = await useIzibizEfaturaService().getEfaturaKontrol(idendiferNumber);
      }


    }

    function changedTevkifatFatura() {
      const tevkifatSatir = localState.model.satirlar?.find((a: any) => a.tevkifatOran > 0);
      if (tevkifatSatir) {
        localState.model.eFatura!.eFaturaTip = EFaturaTip.TEVKIFAT;
      }

    }

    async function changedEfaturaSenaryo() {
      //console.log(localState.selectedEfaturaSenaryoTip);

      switch (localState.selectedEfaturaSenaryoTip) {
        case EFaturaSenaryoTip.EARSIVFATURA:
          localState.faturaSerileriSelectBox = localState.faturaSerileri.filter(a => a.tip == ListeTip.EarsivSeri);
          localState.model.ustBilgi!.faturaSeri = localState.faturaSerileri.find(a => a.tip == ListeTip.EarsivSeri)?.seri;
          changedTevkifatFatura();
          break;
        case EFaturaSenaryoTip.TEMELFATURA :
        case EFaturaSenaryoTip.TICARIFATURA :
        case  EFaturaSenaryoTip.IHRACAT: {
          const firma = await useFirmaService().get();
          localState.model.eFatura!.notlar = firma.ihracatFaturaAciklamalari;
          localState.faturaSerileriSelectBox = localState.faturaSerileri.filter(a => a.tip == ListeTip.EFaturaSeri);
          localState.model.ustBilgi!.faturaSeri = localState.faturaSerileri.find(a => a.tip == ListeTip.EFaturaSeri)?.seri;

          changedTevkifatFatura();
          if (localState.selectedEfaturaSenaryoTip == EFaturaSenaryoTip.IHRACAT)
            localState.selectedEfaturaTip = EFaturaTip.ISTISNA;

        }


          break;
        case EFaturaSenaryoTip.TEMELIRSALIYE :
          localState.faturaSerileriSelectBox = localState.faturaSerileri.filter(a => a.tip == ListeTip.EirsaliyeSeri);
          localState.model.ustBilgi!.faturaSeri = localState.faturaSerileri.find(a => a.tip == ListeTip.EirsaliyeSeri)?.seri;
          break;
      }


    }


    async function barkodBas(stokId?:string, itemId?:string) {
      localState.basilacaklar = [];

      localState.model.satirlar?.filter((a:any) => filterBarkod(a,stokId, itemId))?.forEach((stk) => {
        localState.basilacaklar.push({
          miktar: stk.miktar! * stk.carpan! as number,
          itemId: stk.itemId,
          stokId: stk.stokId as string
        } as StokBarkodView);
      });
      localState.showBarkodBasDialog = true;
    }

      function filterBarkod(satir:any,stokId?: string, itemId?: string) {
        let result = true;
        if  (stokId && stokId.length > 1)
            result = result && satir.stokId == stokId;

        if (itemId && itemId.length > 1)
            result = result && satir.itemId == itemId;

          return result;
      }
    async function faturaOnlizlemeOlustur(e: any) {
      e.stopPropagation();
      const reportData: FaturaEntityDataSource = {
        cari: localState.cari,
        // fatura : localState.model ,
        firma: localState.firmaEntity,
        fatura: await useFaturaService().postToEntity(localState.model, localState.model.tip as FaturaHareketTip),
        vadeler: localState.model.vadeler
      };

      localState.reportData = [];
      localState.reportData.push(reportData);
    }

    function iadeAl() {
      const iadeView = new FaturaViewExtend(localState.model);
      iadeView.id = "";
      iadeView.eFatura = {};
      iadeView.dokumanlar = [];
      iadeView?.satirlar?.forEach((a) => {
        a.id = uuid.v4();
        a.referansId = "";
      });

      useFaturaStore().viewToFaturaForm({
            formType: MenuFormType.Fatura_Form,
            hareketTipi: FaturaHareketTip.SatisIadeFaturasi,
            name: "SATIŞTAN İADE => " + localState.cari.getUnvan
          },
          iadeView,
          iadeView.faturaTip
      );


    }


    async function reportClick(reportName: string) {
      if (reportName && reportName.length > 0) {
        await setReportMail()
        localState.reportName = reportName;
        localState.showReportDialog = true;
      }
    }

    async function setReportMail() {
      // cari maili bul
      await useCariCachedService().loadTumListe()
      const cariEntity = await useCariCachedService().cariListesi?.find(a => a.id == localState.cari.id) as CariEntity
      if (cariEntity && cariEntity.adresler && cariEntity.adresler?.length > 0)
        localState.mailSendTo = cariEntity.adresler[0].mail as string
    }

    function odemeTabDisabled() {

      if (
          localState.faturaProp.menu?.hareketTipi == FaturaHareketTip.AlisFaturasi ||
          localState.faturaProp.menu?.hareketTipi == FaturaHareketTip.SatisFatura ||
          localState.faturaProp.menu?.hareketTipi == FaturaHareketTip.SatisIrsaliyeToFatura ||
          localState.faturaProp.menu?.hareketTipi == FaturaHareketTip.AlisIrsaliyeToFatura
      )
        return true;
      else return false;
    }

    async function importerCallBack(e: any) {

      if (e && e.kod && e.kod.length > 0) {
        const stok = useStokFlatStore().getStoklar((a: any) => a.kod == e.kod, 0, 1)[0];


        if (stok) {
          const satir = FaturaUtil.generateFaturaStokSatirItem(stok, localState.model.tip as FaturaHareketTip, localState.plasiyerId);
          if (satir) {
            if (e.referans && e.referans.length > 0)
              satir.kod = e.referans;
            else
              satir.kod = e?.kod;

            if (e.ad && e.ad.length > 0)
              satir.ad = e.ad;
            else
              satir.ad = stok.ad;


            satir.fiyat = e?.fiyat;

            satir.aciklama = e.spec;
            satir.miktar = e.miktar;
            satir.siparisTarih = e.tarih;


            if (e.fiyatKdvDahil && e.fiyatKdvDahil > 0) {
              satir.fiyatKdvli = e.fiyatKdvDahil;
              satir.fiyat = e.fiyatKdvDahil / (1 + (satir.kdv! / 100));
              satiraStokEkle(satir, {}, false);
            } else if (e.fiyatKdvHaric && e.fiyatKdvHaric > 0) {
              satir.fiyatKdvli = e.fiyatKdvHaric * (1 + (satir.kdv! / 100));
              satir.fiyat = e.fiyatKdvHaric;
              satiraStokEkle(satir, {}, false);
            } else {
              satiraStokEkle(satir, {});
            }


          }

        } else {
          await alert(e.kod + " - stok kodlu ürün bulunamadı ! ", "Stok Kodu Bulunamayanlar");
        }
      }


    }


    watch(() => localState.onSignalSatisIzni, async (val) => {
      if (val && useFaturaStore().onSignalIzin) {
        useFaturaStore().onSignalIzin = false;
        localState.onSignalSatisIzni = false;
        if (await GenelUtils.onayDiyalogu("<b>VADE LİMİTİ AŞILDI </b> Yetkili kullanacı(lar) dan izin istenecek ve SMS gönderilecek , Onaylıyor musunuz ?  ", "Limit İzni İsteme")) {

          localState.model.setFaturaAltiIskonto();
          const odenecekTutar = (new FaturaViewExtend(localState.model)).getOdenecekDovizTutar(localState.model.altBilgi?.cariIslemKur as number);
          // console.log("odenecekTutar" , odenecekTutar);
          await useCariSatisIzinleriService().getCarisatisizinleriEkle(localState.cari.id as string, CariHareketTip.Satis_Faturasi, odenecekTutar);

          usePopupService().pushWarningNotification({
            body: odenecekTutar.format(2, 2) + " Tutarı için SMS gönderildi",
            title: "İzin İstendi"
          });
        }
      }
    });


    async function getBirimlerDataSource(data: any) {
      if (data && data.data && data.data.tip &&
          (data.data.tip == FaturaSatirTip.Sac || data.data.tip == FaturaSatirTip.HizmetMasraf)
      ) {
        const birimler = [] as any[];
        const birimListesi = await useListelerService().getBirimListesi() as Liste[];
        birimListesi.forEach((b) => {
          birimler.push({
            birim: b.deger,
            carpan: 1,
            getBirimKisaAd: b.deger

          });
        });

        data.data.birimler = birimler;
      }

      if (data && data.data && data.data.stokId && data.data.stokId.length > 0) {

        const findStok = useStokFlatStore().getStok(data.data.stokId);

        if (findStok) {
          const birimler = Object.assign([], findStok.birimFiyatlari as StokBirimItem[]);
          birimler.push({
            birim: findStok.birim,
            carpan: 1,
            getBirimKisaAd: findStok.birim

          });
          data.data.birimler = birimler;

        }

      }


    }

    function changedBirim(e: any, row: any) {

      if (row && row.tip == FaturaSatirTip.Stok) {
        const stok = useStokFlatStore().getStok(row.stokId as string);
        const birimler = stok?.birimFiyatlari as StokBirimItem[];
        if (birimler && stok) {
          const birimAdi = e.value;
          const birim = birimler.find(a => a.birim == birimAdi);

          if (birim) {
            row.birim = birimAdi;
            row.carpan = birim.carpan;
            row.fiyat = StokBirimUtils.getFiyat(
                birim.fiyat!,
                birim.fiyatTipi!,
                stok.satisFiyat!,
                stok.satisKdv!,
                stok.satisKdvDahil!,
                stok.alisFiyat!,
                stok.alisKdv!,
                stok.alisKdvDahil!,
                0
            );
            //var fiyatKdvliMi = StokBirimUtils.getFiyatKdvDahilMi(birim.fiyatTipi , stok.satisKdvDahil , stok.alisKdvDahil);


            row.fiyatKdvli = row.fiyat * (1 + (stok.satisKdv / 100));
          } else {
            row.birim = birimAdi;
            row.carpan = 1;
            row.fiyat = !stok.satisKdvDahil ? stok.satisFiyat : stok.satisFiyat! / (1 + (stok.satisKdv! / 100));
            row.fiyatKdvli = row.fiyat * (1 + (stok.satisKdv / 100));
          }

        }
      } else {
        row.birim = e.value;
      }


    }

    function openMiktarOzet(e: any) {
      const satir = e?.row?.data as FaturaSatirItem;
      if (satir) {
        localState.stokId = satir.stokId as string;
        localState.showMiktarOzet = true;
      }
    }

    onCariHareketHubManagerSignal().LimitIzin(async (cariId) => {
      if (cariId && cariId.length > 0 && cariId == localState.cari.id) {
        localState.onSignalSatisIzni = true;
        useFaturaStore().onSignalIzin = true;
      }
    });

    async function loadState() {
      return await DevExtremeUtils.loadState(localState.faturaFormuSatirlarDataGrid?.instance);
    }

    function sacStoklariClick(uretimstoklar: UretimStokEntity[]) {
      if (uretimstoklar && uretimstoklar.length > 0) {
        uretimstoklar?.forEach((uretimStok: UretimStokEntity) => {
          const satir = FaturaUtil.generateFaturaUretimStokItem(uretimStok, uretimStok.depoId as string);

          localState.model?.satirlar?.push(satir);
        });

        localState.dataSource.reload();
        // (localState.faturaFormuSatirlarDataGrid.instance as any).navigateToRow(satir.id);
        localState.model.setFaturaAltiIskonto();
      } else
        alert("Birşeyler ters gitti  , Yeniden Deneyiniz !", "Alış Sac Ekleme");
    }

    onActivated(() => {
      DevExtremeUtils.updateScroll(localState.faturaFormuSatirlarDataGrid);
    });

    function fiyatGrubuOnValueChanged(data: any) {
      localState.fiyatGrubuOran = data.oran;
      localState.fiyatGrubuTutar = data.tutar;

      if (data && data.oran && data.oran != 0) {
        if (localState.model && localState.model.satirlar && localState.model.satirlar.length) {
          localState.model.satirlar.forEach((satir) => {
            const newSatir = fiyatGrubuAyari(satir as FaturaSatirItemExtend);
            satir = newSatir;
          });
          localState.faturaFormuSatirlarDataGrid?.instance?.refresh();
          // console.log(localState.model.satirlar);
        }
      }

      if (data && data.tutar && data.tutar != 0) {

        if (localState.model && localState.model.satirlar && localState.model.satirlar.length) {
          localState.model.satirlar.forEach((satir) => {
            const newSatir = fiyatGrubuAyari(satir as FaturaSatirItemExtend);
            satir = newSatir;
          });
          localState.faturaFormuSatirlarDataGrid?.instance?.refresh();
          //  console.log(localState.model.satirlar);
        }
      }


    }

      function onReorder(e:any) {
          const visibleRows = e.component.getVisibleRows();
          const toIndex = localState.model.satirlar?.findIndex((item) => item.id === visibleRows[e.toIndex].data.id) as number;
          const fromIndex = localState.model.satirlar?.findIndex((item) => item.id === e.itemData.id) as number;

          // console.log('fromIndex', fromIndex)
          // console.log('toIndex', toIndex)
          localState.model.satirlar?.splice(fromIndex, 1);
          localState.model.satirlar?.splice(toIndex, 0, e.itemData);
          localState.faturaFormuSatirlarDataGrid?.instance?.refresh()
      }



    return {
      ...toRefs(localState),
      siparisFlatAdd,
      sacStoklariClick,
      changedEfaturaSenaryo,
      importerCallBack,
      stokDblClick,
      hizmetDblClick,
      kurChanged,
      hareketSil,
      getBirimlerDataSource,
      changedBirim,
      rowEditing,
      rowUpdating,
      loadState,
      save,
      hizmasrafEkleClick,
      satirSilClick,
      getDovizGenel,
      satirlariBirlestir,
      faturaSilKontrol,
      faturaGuncelleKontrol,
      load,
      tabDisabled,
      oncekiStoklarDblClick,
      rightMenu,
      vergiDialogAdd,
      vergiDialogsDelete,
      coklu,
      onizleme,
      efaturaOnFoucIn,
      passiveToActive,
      efaturaGonder,
      cairIslemDovizChanged,
      barkodBas,
      faturaOnlizlemeOlustur,
      reportClick,
      iadeAl,
      cokluhHizmetDblClick,
      calculateFilterExpression,
      efaturaGoster,
      getEFaturaSenaryoTip,
      odemeTabDisabled,
      fiyatGrubuOnValueChanged,
      onReorder,

    };
  }
});
