
import {computed, defineComponent, onActivated, onMounted, reactive, ref, toRefs, watch} from "vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NButton from "@/components/Button/NButton.vue";
import {StokFlatView} from "@/api/models/StokFlatView";
import {useHizliSatisTabsStore} from "@/modules/HizliSatis/store/HizliSatisTabsStore";
import HizliSatisStokUpdateDialog from "@/modules/HizliSatis/dialogs/HizliSatisStokUpdateDialog.vue";
import HizliStokDialog from "@/modules/Stok/dialogs/HizliStokDialog.vue";
import NDropdown from "@/components/Dropdown/NDropdown.vue";
import NDropdownItem from "@/components/Dropdown/NDropdownItem.vue";
import NDropdownMenu from "@/components/Dropdown/NDropdownMenu.vue";
import dxTextBox from "devextreme/ui/text_box";
import NCheckbox from "@/components/Checkbox/NCheckbox.vue";
import StokXmlSelectListDialog from "@/modules/Stok/dialogs/StokXmlSelectListDialog.vue";
import {useHizliSatisStokFlatStore} from "@/modules/HizliSatis/store/HizliSatisStokFlatStore";
import {useStokStore} from "@/modules/Stok/store/StokStore";
import {useUserSettingsStore} from "@/store/userSettingsStore";

export default defineComponent({
    props: {
        value: {
            type: String
        },
        placeholder: {
            type: String
        }
    },
    components: {
        NCheckbox, NDropdownMenu, NButton, NDropdownItem, NDropdown, HizliStokDialog, HizliSatisStokUpdateDialog, NGrid
    },
    setup(props, {emit}) {
        const localState = reactive({
            valueRef: computed({
                get: () => props.value,
                set: (val) => emit("update:value", val)
            }),
            hizliSatisDialogShow: ref(false),
            hizliStokDialogShow: ref(false),
            isSearchBarkodlar: ref(false),
            isSearchKodlar: ref(false),
            isSearchXmlStok: ref(false),
            isAddSatirSingleClick :ref(false),
            textBox: ref()
        });

        onMounted(async () => {
            await load();
        })

        watch(() => localState.isSearchXmlStok, async (deger: boolean) => {
            if (deger) {
                await useUserSettingsStore().saveSettings("isSearchXmlStok", "1");
                useStokStore().isSearchXmlStok = true;
            } else {
                await useUserSettingsStore().saveSettings("isSearchXmlStok", "0");
                useStokStore().isSearchXmlStok = false;
            }
        })

        watch(() => localState.isSearchBarkodlar, async (deger: boolean) => {
            if (deger) {
                await useUserSettingsStore().saveSettings("isSearchBarkodlar", "1");
                useStokStore().isSearchBarkodlar = true;
            } else {
                await useUserSettingsStore().saveSettings("isSearchBarkodlar", "0");
                useStokStore().isSearchBarkodlar = false;
            }
        })

        watch(() => localState.isSearchKodlar, async (deger: boolean) => {
            if (deger) {
                await useUserSettingsStore().saveSettings("isSearchKodlar", "1");
                useStokStore().isSearchKodlar = true;
            } else {
                await useUserSettingsStore().saveSettings("isSearchKodlar", "0");
                useStokStore().isSearchKodlar = false;
            }
        })

        watch(() => localState.isAddSatirSingleClick, async (deger: boolean) => {
            if (deger) {
                await useUserSettingsStore().saveSettings("isAddSatirSingleClick", "1");
                useStokStore().isAddSatirSingleClick = true;
            } else {
                await useUserSettingsStore().saveSettings("isAddSatirSingleClick", "0");
                useStokStore().isAddSatirSingleClick = false;
            }
        })


        async function load() {
            localState.isSearchBarkodlar = await isSearchBarkodlar();
            localState.isSearchKodlar = await isSearchKodlar();
            localState.isSearchXmlStok = await isSearchXml();
            localState.isAddSatirSingleClick = await isAddSatirSingleClick();
        }

        async function isAddSatirSingleClick() {
            const isAddSatirSingleClick = await useUserSettingsStore().getDefaultSettings("isAddSatirSingleClick")
            return isAddSatirSingleClick && isAddSatirSingleClick == "1"
        }

        async function isSearchXml() {
            const isSearchXmlStok = await useUserSettingsStore().getDefaultSettings("isSearchXmlStok")
            return isSearchXmlStok && isSearchXmlStok == "1"
        }

        async function isSearchBarkodlar() {
            const isSearchBarkodlar = await useUserSettingsStore().getDefaultSettings("isSearchBarkodlar")
            return isSearchBarkodlar && isSearchBarkodlar == "1"
        }

        async function isSearchKodlar() {
            const isSearchKodlar = await useUserSettingsStore().getDefaultSettings("isSearchKodlar")
            return isSearchKodlar && isSearchKodlar == "1"
        }

        function stokEntityAdded(stok: StokFlatView[],miktar:number) {
            useHizliSatisTabsStore().addRow(stok[0],miktar);
        }

        function stokAraEnterKey(e: any) {
            e.event.currentTarget.select();
            emit("stokAraEnterKey")
         }

        onActivated(() => {
           focusInput()
        });

        function focusInput() {

            const textBox = document.getElementById('stokSearch')?.getElementsByTagName('input')?.item(0);
            if (textBox){
                textBox.focus();
                textBox.setSelectionRange(0,11111)
            }
        }

        return {
            ...toRefs(localState),
            stokEntityAdded,
            stokAraEnterKey,
            focusInput
        };
    }
});
