import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StokKategori_SubeFiyatlari = _resolveComponent("StokKategori_SubeFiyatlari")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!

  return (_openBlock(), _createBlock(_component_NLoadingPanel, {
    title: "Yükleniyor...",
    action: _ctx.load
  }, {
    default: _withCtx(() => [
      _createVNode(_component_NGrid, {
        rows: "auto auto",
        cols: "1fr",
        gap: "8px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_StokKategori_SubeFiyatlari, {
            title: "Şube Fiyatları",
            subeler: _ctx.subeler,
            "onUpdate:subeler": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.subeler) = $event)),
            modelValue: _ctx.ozellikRef,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ozellikRef) = $event))
          }, null, 8, ["subeler", "modelValue"]),
          (_ctx.ozellikDevirRef && _ctx.ozellikDevirRef.subeFiyatlari && _ctx.ozellikDevirRef.subeFiyatlari.length > 0)
            ? (_openBlock(), _createBlock(_component_StokKategori_SubeFiyatlari, {
                key: 0,
                title: "Şube Fiyatları (Üst Kategoriden gelenler)",
                subeler: _ctx.subeler,
                "onUpdate:subeler": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.subeler) = $event)),
                modelValue: _ctx.ozellikDevirRef,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.ozellikDevirRef) = $event)),
                disabled: true
              }, null, 8, ["subeler", "modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["action"]))
}