import { defineStore } from "pinia";
import { StokFlatView } from "@/api/models/StokFlatView";
import ArrayStore from "devextreme/data/array_store";
import { useStokService } from "@/api/services/StokService";
import { onStokHubManagerSignal } from "@/api/signals/StokHubManagerSignal";
import DataSource from "devextreme/data/data_source";
import { StokUyumluluktem } from "@/api/models/StokUyumluluktem";
import { dataSource } from "devexpress-reporting/designer/controls/metadata/properties/metadata";
import DevExpress from "devextreme";
import Query = DevExpress.data.Query;

export const useStokFlatStore = defineStore({
  id: "StokFlatStore",
  state: () => ({
    store: new ArrayStore,
    isCache: false,
    signalListening: false,
    isLoaded: false,
    count: 0
  }),
  actions: {
    getQuery() {
      return this.store.createQuery();
    },
    getStoklar(predicate: any, skip: number, take: number) {
      return this.getQuery().filter(predicate).slice(skip, take).toArray();
    },
    getStok(stokId: string) {
      return this.getStoklar((a: any) => a.stokId == stokId, 0, 1)[0];
    },

    getAllStok(stokId: string) {
      return this.getStoklar((a: any) => a.stokId == stokId, 0, 999999);
    },
    getStokByItemId(stokId: string, itemId: string): StokFlatView {
      if (itemId && itemId.length > 0)
        return this.getStoklar((a: any) => a.stokId == stokId && a.itemId == itemId, 0, 1)[0];
      else
        return this.getStoklar((a: any) => a.stokId == stokId, 0, 1)[0];
    },

    isExist(predicate: any) {
      return this.getQuery().filter(predicate).slice(0, 1).toArray().length > 0;
    },
    generateDataSource() {
      return new DataSource({
        store: this.store,
        reshapeOnPush: true
      });
    },

    async load() {
      if (!this.signalListening)
        this.onSignal();
      this.isLoaded = false;
      const list = await useStokService().getStokFlatList();
      this.store = new ArrayStore({
        data: list,
        key: "id"
      });
      this.count = list.length;
      this.isLoaded = true;

    },
    onSignal() {
      onStokHubManagerSignal().AddedFlat((stok) => {
        if (stok) {
          this.store.push([{ type: "insert", data: stok }]);
        }
      });
      onStokHubManagerSignal().ChangedFlat((stok) => {
        if (stok) {
          this.store.push([{ type: "update", data: stok, key: stok.id }]);
        }
      });
      onStokHubManagerSignal().DeletedFlat((stokId) => {
        if (stokId && stokId.length > 0) {

          this.store.push([{ type: "remove", key: stokId }]);
        }
      });
    }

  }


});
