
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import StokFlatListSagMenu from "@/modules/Stok/components/StokFlatListSagMenu.vue";
import NButton from "@/components/Button/NButton.vue";
import NTag from "@/components/Tag/NTag.vue";
import {DxDataGrid, DxExport} from "devextreme-vue/data-grid";
import NViewGrid from "@/components/ViewGrid/NViewGrid.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import ArrayStore from "devextreme/data/array_store";
import {StokFlatView} from "@/api/models/StokFlatView";
import {DovizList} from "@/api/models/Doviz";
import {useStokFlatStore} from "@/modules/Stok/store/StokFlatStore";
import {useFileSettingsServiceCache} from "@/api/servicesCache/FileSettingsServiceCache";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import {StokKampanyaFlatView} from "@/api/models/StokKampanyaFlatView";
import {useStokService} from "@/api/services/StokService";
import {useKampanyaService} from "@/api/services/KampanyaService";
import {KampanyaView} from "@/api/models/KampanyaView";
import {DegerTipi} from "@/api/models/DegerTipi";

export default defineComponent({
  components: {NLoadingPanel, StokFlatListSagMenu, NButton, NTag, DxDataGrid, DxExport, NViewGrid, NGrid, NModalDialog},
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    kampanya: {
      type: KampanyaView,
    },
    selectedKampanyaId: {
      type: String,
      default: '',
    }

  },

  setup(props, {emit}) {
    const localState = reactive({
      showRef: computed({
        get: () => props.show,
        set: val => emit('update:show', val)
      }),
      stokFlatListArrayStore: new ArrayStore(),
      serverUrlRef: ref(''),
      selectedStok: ref({} as StokKampanyaFlatView),
      selectedStoks: ref([] as StokKampanyaFlatView[]),
      dovizList: DovizList,
      resimler: ref([] as string[]),
      searchStokInput: ref(''),
      stokFlatListDataGrid: ref(),
      stokHover: ref({} as StokKampanyaFlatView),
      useStokFlatStore: useStokFlatStore(),
      stoklar: ref([] as StokKampanyaFlatView[]),
      dovizTipList: DovizList,
      stokFlatListSagMenu: ref(),
      degerTipi:DegerTipi,
    })

    watch(() => localState.searchStokInput, () => {
      stokArama();
    })

    function calculateFilterExpression(filterValue: any, selectedFilterOperation: any, target: any) {
      const getter = function (data: any) {
        return data['getUrunAdi'].normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      };
      filterValue = filterValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
      return [getter, selectedFilterOperation || "contains", filterValue];
    }

    async function load() {
      await loadFlatStokListe();
      localState.serverUrlRef = await useFileSettingsServiceCache().getServerUrl();
      stokArama();
    }

    //load();


    async function loadFlatStokListe() {
      let kampanyaIds = props.kampanya?.hedef?.idNames?.map((a) => a.id)
      if (props.selectedKampanyaId && props.selectedKampanyaId.length > 0) {
        kampanyaIds = [props.selectedKampanyaId]
      }
      const result = await useStokService().postStokKampanyaFlatList(kampanyaIds as any);
      localState.stoklar = result;
    }

    function stokArama() {
      const arama = localState.searchStokInput;

      if (arama && arama != '') {
        localState.stoklar = localState.stoklar.filter((a: any) => {

          return (a.ad?.icindeGeciyorMu(arama) ||
              a.kod?.icindeGeciyorMu(arama) ||
              a.itemKod?.icindeGeciyorMu(arama) ||
              a.barkodStok?.icindeGeciyorMu(arama) ||
              a.barkodItem?.icindeGeciyorMu(arama) ||
              a.kategori?.icindeGeciyorMu(arama) ||
              a.ozellikler?.icindeGeciyorMu(arama)
          );

        });

      } else {
        loadFlatStokListe();
      }

    }


    function getImage() {
      if (localState.stokHover && localState.stokHover.resimler && localState.stokHover.resimler.length > 0)
        return localState.serverUrlRef + localState.stokHover.resimler[0]
      else
        return ('@/assets/images/gorselYok.png')
    }

    function itemClick(e: any) {
      localState.selectedStoks = e?.selectedRowsData;
      localState.selectedStok = e?.selectedRowsData[0];

    }

    async function rowDblClick(data: StokKampanyaFlatView) {
      if (data) {
        await useKampanyaService().postKampanyaKategoriValidate({
          kampanya: props.kampanya,
          stokList: [data]
        })
        emit('tek', data, props.kampanya);
        localState.showRef = false;
      }

    }

    async function coklu() {
      await useKampanyaService().postKampanyaKategoriValidate({
        kampanya: props.kampanya,
        stokList: localState.selectedStoks
      })
      emit('coklu', localState.selectedStoks, props.kampanya);
      localState.showRef = false;
    }

    function cellHover(e: any) {
      localState.stokHover = e.data;
    }

    return {
      ...toRefs(localState),
      load,
      getImage,
      itemClick,
      rowDblClick,
      coklu,
      cellHover,
      calculateFilterExpression,
    }
  }
})
