import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_CariAdresSelecBox = _resolveComponent("CariAdresSelecBox")!
  const _component_MahalleAraSelectBox = _resolveComponent("MahalleAraSelectBox")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NTextarea = _resolveComponent("NTextarea")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_FaturaAdresComponent = _resolveComponent("FaturaAdresComponent")!
  const _component_FaturaSevkListSelect = _resolveComponent("FaturaSevkListSelect")!
  const _component_DxDateBox = _resolveComponent("DxDateBox")!
  const _component_NCard = _resolveComponent("NCard")!

  return (_openBlock(), _createBlock(_component_NCard, {
    tabs: [
              {label:'Cari  ve Fatura Adresi'  },
              {label:'Teslimat Adr.' },
              {label:'Sevk' },
              ],
    style: {"height":"100%"},
    modelValue: _ctx.selectedTab,
    "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.selectedTab) = $event)),
    scrollable: ""
  }, {
    default: _withCtx(() => [
      (_ctx.selectedTab === 0)
        ? (_openBlock(), _createBlock(_component_NGrid, {
            key: 0,
            cols: "1fr"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NFormField, {
                "grid-cols": "1fr auto",
                label: "Bu Bilgileri Kullan"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NSwitch, {
                    type: "success",
                    pulse: true,
                    modelValue: _ctx.model.isFatura,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.isFatura) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "0px 1fr",
                label: " "
              }, {
                default: _withCtx(() => [
                  (_ctx.model?.ustBilgi?.cari?.id?.length > 0 )
                    ? (_openBlock(), _createBlock(_component_CariAdresSelecBox, {
                        key: 0,
                        onSelectedChanged: _ctx.cariAdresSelectedChanged,
                        "cari-id": _ctx.model.ustBilgi.cari.id,
                        "onUpdate:cariId": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.ustBilgi.cari.id) = $event))
                      }, null, 8, ["onSelectedChanged", "cari-id"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "0px 1fr",
                label: " "
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MahalleAraSelectBox, {
                    belde: _ctx.model.ustBilgi.belde,
                    "onUpdate:belde": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.ustBilgi.belde) = $event)),
                    mahalle: _ctx.model.ustBilgi.mahalle,
                    "onUpdate:mahalle": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.ustBilgi.mahalle) = $event)),
                    pk: _ctx.model.ustBilgi.pKodu,
                    "onUpdate:pk": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.ustBilgi.pKodu) = $event)),
                    ilce: _ctx.model.ustBilgi.ilce,
                    "onUpdate:ilce": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.ustBilgi.ilce) = $event)),
                    sehir: _ctx.model.ustBilgi.sehir,
                    "onUpdate:sehir": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.ustBilgi.sehir) = $event))
                  }, null, 8, ["belde", "mahalle", "pk", "ilce", "sehir"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "80px 1fr",
                label: "Ünvan"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.model.ustBilgi.faturaUnvan,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.ustBilgi.faturaUnvan) = $event)),
                    disabled: !_ctx.model.isFatura
                  }, null, 8, ["modelValue", "disabled"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "80px 1fr",
                title: "Vergi Dairesi",
                label: "Vergi Dairesi"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    placeholder: "Vergi Dairesi",
                    modelValue: _ctx.model.ustBilgi.vergiDairesi,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.ustBilgi.vergiDairesi) = $event)),
                    disabled: !_ctx.model.isFatura
                  }, null, 8, ["modelValue", "disabled"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "80px 1fr",
                title: "TC/Vergi Kimlik No",
                label: "TC/Vergi No"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    placeholder: "TC/Vergi No",
                    modelValue: _ctx.model.ustBilgi.vergiNo,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.ustBilgi.vergiNo) = $event)),
                    disabled: !_ctx.model.isFatura
                  }, null, 8, ["modelValue", "disabled"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "80px 1fr",
                label: "Ad"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.model.ustBilgi.ad,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.ustBilgi.ad) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "80px 1fr",
                label: "Soyad"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.model.ustBilgi.soyad,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.ustBilgi.soyad) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "80px 1fr",
                label: "Şehir"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxSelectBox, {
                    items: _ctx.adresler.map(a=>a.sehir)
                  .filter((value, index, self) => self.indexOf(value) === index),
                    "search-timeout": 1,
                    searchEnabled: true,
                    value: _ctx.model.ustBilgi.sehir,
                    "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.ustBilgi.sehir) = $event)),
                    "show-clear-button": true,
                    acceptCustomValue: true,
                    searchMode: "contains"
                  }, null, 8, ["items", "value"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "80px 1fr",
                label: "İlçe"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxSelectBox, {
                    items: _ctx.adresler.filter(a=>a.sehir == _ctx.model.ustBilgi.sehir)
                  .map(a=>a.ilce).filter((value, index, self) => self.indexOf(value) === index),
                    "search-timeout": 1,
                    searchEnabled: true,
                    value: _ctx.model.ustBilgi.ilce,
                    "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.ustBilgi.ilce) = $event)),
                    "show-clear-button": true,
                    searchMode: "contains",
                    acceptCustomValue: true
                  }, null, 8, ["items", "value"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "80px 1fr",
                label: "Belde"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxSelectBox, {
                    items: _ctx.adresler.filter(a=>a.sehir == _ctx.model.ustBilgi.sehir && a.ilce == _ctx.model.ustBilgi.ilce)
                  .map(a=>a.beldeAdi).filter((value, index, self) => self.indexOf(value) === index),
                    "search-timeout": 1,
                    searchEnabled: true,
                    value: _ctx.model.ustBilgi.belde,
                    "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.ustBilgi.belde) = $event)),
                    "show-clear-button": true,
                    searchMode: "contains",
                    acceptCustomValue: true,
                    placeholder: "Semt / Belde Bucak Giriniz"
                  }, null, 8, ["items", "value"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "80px 1fr",
                label: "Mahalle"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxSelectBox, {
                    items: _ctx.adresler.find(a=>a.sehir == _ctx.model.ustBilgi.sehir && a.ilce ==_ctx.model.ustBilgi.ilce && a.beldeAdi == _ctx.model.ustBilgi.belde)
                  ?.mahalleler,
                    "search-timeout": 1,
                    searchEnabled: true,
                    value: _ctx.model.ustBilgi.mahalle,
                    "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.ustBilgi.mahalle) = $event)),
                    "show-clear-button": true,
                    searchMode: "contains",
                    acceptCustomValue: true,
                    placeholder: "Mahalle Giriniz"
                  }, null, 8, ["items", "value"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "80px 1fr",
                label: "Cep"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.model.ustBilgi.cep,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model.ustBilgi.cep) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "80px 1fr",
                label: "Tel"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.model.ustBilgi.tel,
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model.ustBilgi.tel) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "80px 1fr",
                label: "Mail"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.model.ustBilgi.mail,
                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.model.ustBilgi.mail) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "80px 1fr",
                label: "Adres"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NTextarea, {
                    modelValue: _ctx.model.ustBilgi.adres,
                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.model.ustBilgi.adres) = $event)),
                    rows: "2"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.selectedTab === 1)
        ? (_openBlock(), _createBlock(_component_FaturaAdresComponent, {
            key: 1,
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.model) = $event))
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.selectedTab === 2)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_NFormField, {
              "grid-cols": "80px 1fr",
              label: " "
            }, {
              default: _withCtx(() => [
                _createVNode(_component_FaturaSevkListSelect, { onSevkSelect: _ctx.sevkSelect }, null, 8, ["onSevkSelect"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "80px 1fr",
              label: "Tarih"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxDateBox, {
                  value: _ctx.model.ustBilgi.sevkTarih,
                  "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.model.ustBilgi.sevkTarih) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "80px 1fr",
              label: "Firma Ünvan"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  placeholder: "Firma Bilgisi",
                  modelValue: _ctx.model.ustBilgi.sevkFirma,
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.model.ustBilgi.sevkFirma) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "80px 1fr",
              label: "Firma VKN"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  placeholder: "Firma Vergi No",
                  modelValue: _ctx.model.ustBilgi.sevkFirmaVergiNo,
                  "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.model.ustBilgi.sevkFirmaVergiNo) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "80px 1fr",
              label: "Ad"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  placeholder: "Şoför Ad",
                  modelValue: _ctx.model.ustBilgi.sevkAd,
                  "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.model.ustBilgi.sevkAd) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "80px 1fr",
              label: "Soyad"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  placeholder: "Şoför Soyad",
                  modelValue: _ctx.model.ustBilgi.sevkSoyad,
                  "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.model.ustBilgi.sevkSoyad) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "80px 1fr",
              label: "T.C. No"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  placeholder: "Şoför T.C. Nosu",
                  modelValue: _ctx.model.ustBilgi.sevkTcNo,
                  "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.model.ustBilgi.sevkTcNo) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "80px 1fr",
              label: "Plaka"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  placeholder: "Plaka Giriniz",
                  modelValue: _ctx.model.ustBilgi.sevkPlaka,
                  "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.model.ustBilgi.sevkPlaka) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "80px 1fr",
              label: "Dorse Plaka"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  placeholder: "Dorse Plakası Giriniz",
                  modelValue: _ctx.model.ustBilgi.sevkDorsePlaka,
                  "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.model.ustBilgi.sevkDorsePlaka) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "1px 1fr",
              label: " "
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  type: "text",
                  "label-top": "Kargo Firması",
                  modelValue: _ctx.model.ustBilgi.kargoFirmasi,
                  "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.model.ustBilgi.kargoFirmasi) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "1px 1fr",
              label: " "
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  type: "text",
                  "label-top": "Pazaryeri Kampanya Kod",
                  modelValue: _ctx.model.ustBilgi.pazaryeriKampanyaKod,
                  "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.model.ustBilgi.pazaryeriKampanyaKod) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["tabs", "modelValue"]))
}