
import {computed, defineComponent, onMounted, reactive, ref, toRefs, watch} from "vue";
import {HareketUtils} from "@/modules/Ortak/utils/HareketlerUtils";
import {PlanVadelerItem} from "@/api/models/PlanVadelerItem";
import NTag from "@/components/Tag/NTag.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NInput from "@/components/Input/NInput.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NButton from "@/components/Button/NButton.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import {useTaksitPlanService} from "@/api/services/TaksitPlanService";
import {TaksitPlanEntity} from "@/api/models/TaksitPlanEntity";
import _ from "lodash";
import {confirm} from "devextreme/ui/dialog";


export default defineComponent({
  components: {NTag, NGrid, NButton, NFormField, NInput, NFormGrid},
  props: {
    taksitler: {
      type: Array
    },
    tutar: {
      type: Number,
      default: 0
    },
    vade: {
      type: String,
      default: new Date().toISOString().split("T")[0]
    },
    hizmetMasraf: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    referansHareketId: {
      type: String,
      default: '',
    },
    digerTutar: {
      type: Number,
      default: 0,
    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      hareketUtils: HareketUtils,
      taksitSayisi: ref(0),
      taksitTutari: ref(0 as number),
      taksitTutariDiger: ref(0 as number),
      hareketTutar: ref(0 as number),
      taksitTutariToplam: ref(0 as number),
      vadeRef: ref(props.vade),
      digerTutarRef: computed(() => props.digerTutar as number),

      taksitlerRef: computed({
        get: () => props.taksitler as PlanVadelerItem[],
        set: (val) => emit("update:taksitler", val)
      }),
      taksitlerDataGrid: ref(),
    });


    onMounted(async () => {

      if (props.tutar && props.tutar > 0)
        localState.hareketTutar = props.tutar;

      if (props.vade && props.vade.length > 0)
        localState.vadeRef = props.vade;


      if (props.referansHareketId && props.referansHareketId.length > 0) {
        const taksitler = await useTaksitPlanService().getReferansHareketIdByTaksitler(props.referansHareketId);
        if (taksitler && taksitler.length > 0)
          localState.taksitlerRef = taksitler.map((a: TaksitPlanEntity) => ({
            vade: a.vade,
            tutar: a.taksitTutar,
            digerTutar: a.taksitTutarDiger
          }));
      }
      localState.taksitTutariToplam = _.sum(localState.taksitlerRef.map(a => a.tutar))

    });

    async function taksitlendir() {


      if (localState.taksitTutari && localState.taksitTutari > 0) {
        localState.taksitlerRef = taksitOlusturTutaraGore(localState.hareketTutar as number, localState.taksitTutari as number, localState.vadeRef);

      } else if (localState.taksitSayisi && localState.taksitSayisi > 0) {
        localState.taksitlerRef = await taksitOlusturAdedeGore(localState.hareketTutar as number, localState.taksitSayisi as number, localState.vadeRef);

      }

      setTimeout(async () => {
        if (props.digerTutar > 0)
          await digerTutarTaksitiOlustur();
      } , 100)



      emit('update:tutar', localState.hareketTutar)
      localState.taksitlerDataGrid?.instance.refresh();
    }

    async function digerTutarTaksitiOlustur() {

      const taksitSayisi = localState.taksitlerRef?.length;
      if (taksitSayisi > 0) {
        const digerTaksitTutari = localState.digerTutarRef / taksitSayisi;

        localState.taksitlerRef.forEach((t) => {
          t.digerTutar = digerTaksitTutari;
        })

      }
    }

    watch(() => localState.taksitlerRef, (val) => {

      let taksitSayisi = 0 as number;
      let taksitTutari = 0 as number;
      localState.taksitlerRef.forEach((har) => {
        taksitSayisi++;
        taksitTutari += har.tutar as number;
      });

      localState.taksitTutariToplam = taksitTutari;
    }, {deep: true});


    function taksitOlusturTutaraGore(toplam: number, tutar: number, baslangicTarihi: string) {

      const vadeler: PlanVadelerItem[] = [];

      if (toplam > 0) {
        if (toplam >= tutar) {

          const taksitSayisi = toplam / tutar;
          for (let i = 0; i < taksitSayisi; i++) {
            const vade: PlanVadelerItem = {
              vade: new Date(Date.parse(baslangicTarihi)).addMonths(i).toISOString(),
              tutar: tutar as number,
              digerTutar: 0,
            };
            vadeler.push(vade);
          }
          if (toplam % tutar) {
            const vade: PlanVadelerItem = vadeler[vadeler.length as number - 1] as PlanVadelerItem;
            vade.tutar = toplam as number % tutar as number;
          }
        }
      }
      return vadeler;
    }

    async function taksitOlusturAdedeGore(toplam: number, taksitSayisi: number, baslangicTarihi: string): Promise<PlanVadelerItem[]> {
      const vadeler: PlanVadelerItem[] = [];

      //console.log("taksitSayisi", taksitSayisi, "toplam", toplam)
      if (taksitSayisi > 0 && toplam > 0) {
        for (let i = 0; i < taksitSayisi; i++) {
          vadeler.push({
            vade: new Date(Date.parse(baslangicTarihi)).addMonths(i).toISOString(),
            tutar: toplam / taksitSayisi,
            digerTutar: 0,
          });
        }
      }
      return vadeler;
    }

    function hizmetTaksitlendir() {

      const vadeler: PlanVadelerItem[] = [];
      for (let i = 0; i < localState.taksitSayisi; i++) {
        vadeler.push({
          vade: new Date(Date.parse(localState.vadeRef)).addMonths(i).toISOString(),
          tutar: localState.hareketTutar
        });
      }

      localState.taksitlerRef = vadeler;
    }

      function rightMenu(e: any) {
          const hareket = e.row?.data;

          e.items = [
              {
                  text: "Tüm Satırları Sil", onClick: async () => {
                      await tumSatirlariSil();
                  },
                  icon: "remove",
                  visible: true,
                  disabled:(hareket == undefined)
              },
          ];

          if (e.row == undefined)
              e.items = undefined;
      }

      async function tumSatirlariSil() {
        if (await confirm('Tüm satırları silmek istiyor musunuz?', 'Satırları Sil'))
            localState.taksitlerRef = []
      }


    return {
      ...toRefs(localState),
      taksitlendir,
      hizmetTaksitlendir,
      rightMenu
    };
  }
});
