
export enum CicekSepetiDeliveryMessageType{
Secilmedi=0,
Çiçek_Servis=1,
HediyeKargoAyniGun=4,
HediyeKargo=5,
HediyeKargo1_5IsGunu=6,
HediyeKargo1_7IsGunu=7,
HediyeKargo1_15IsGunu=14,
HediyeKargo1_2IsGunu=18,
HediyeKargo1_10IsGunu=19
}

export const CicekSepetiDeliveryMessageTypeList = [
{key:'Secilmedi', value:0, description:''},
{key:'Çiçek_Servis', value:1, description:''},
{key:'HediyeKargoAyniGun', value:4, description:''},
{key:'HediyeKargo', value:5, description:''},
{key:'HediyeKargo1_5IsGunu', value:6, description:''},
{key:'HediyeKargo1_7IsGunu', value:7, description:''},
{key:'HediyeKargo1_15IsGunu', value:14, description:''},
{key:'HediyeKargo1_2IsGunu', value:18, description:''},
{key:'HediyeKargo1_10IsGunu', value:19, description:''}
]
