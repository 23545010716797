import SiparisSatirItemExtend from "@/modules/SiparisTeklif/models/SiparisSatirItemExtend";
import { useSiparisService } from "@/api/services/SiparisService";
import { SiparisView } from "@/api/models/SiparisView";
import { StokFlatView } from "@/api/models/StokFlatView";
import { useEvrakNoService } from "@/api/services/EvrakNoService";
import { Doviz, DovizList } from "@/api/models/Doviz";
import { uuid } from "vue-uuid";
import { SiparisSatirItem } from "@/api/models/SiparisSatirItem";
import { SiparisHareketTip } from "@/api/models/SiparisHareketTip";
import { useStokFlatStore } from "@/modules/Stok/store/StokFlatStore";
import { FaturaSatirTip } from "@/api/models/FaturaSatirTip";
import { SiparisTip } from "@/api/models/SiparisTip";
import { usePersonelIsTakipService } from "@/api/services/PersonelIsTakipService";
import { StokBirimUtils } from "@/modules/Stok/utils/StokBirimUtils";
import { useCariService } from "@/api/services/CariService";
import { CariView } from "@/api/models/CariView";
import { KurumTip } from "@/api/models/KurumTip";
import { AdresTip } from "@/api/models/AdresTip";
import { CariEntity } from "@/api/models/CariEntity";
import { KargoTeslimatTip } from "@/api/models/KargoTeslimatTip";
import { KargoBoyutTip } from "@/api/models/KargoBoyutTip";
import { KargoOdemeTip } from "@/api/models/KargoOdemeTip";
import { KargoTip } from "@/api/models/KargoTip";
import { KargoTeslimTip } from "@/api/models/KargoTeslimTip";
import { PlatformTip } from "@/api/models/PlatformTip";
import { SiparisEntity } from "@/api/models/SiparisEntity";
import { FaturaView } from "@/api/models/FaturaView";
import { FaturaHareketTip } from "@/api/models/FaturaHareketTip";
import { FaturaAltBilgiItem } from "@/api/models/FaturaAltBilgiItem";
import { useStokService } from "@/api/services/StokService";
import { StokBakiyeRequest } from "@/api/models/StokBakiyeRequest";
import { HizmetMasrafView } from "@/api/models/HizmetMasrafView";
import { alert } from "devextreme/ui/dialog";


export const SiparisUtil = {
  satirBirimFiyatUpdate(satirlar: SiparisSatirItemExtend[], cariGroup: string) {
    if (satirlar && satirlar.length > 0) {
      satirlar.forEach((satir) => {
        const stok = useStokFlatStore().getStok(satir.stokId as string);
        satir = SiparisUtil.getStokBirimGroupFiyat(cariGroup, stok, satir);

      });

    }
  },
  generateSiparisTeklifHizmetSatir(hizmet: HizmetMasrafView) {
    const satir = new SiparisSatirItemExtend();
    satir.tip = FaturaSatirTip.HizmetMasraf;
    satir.fiyat = hizmet.aylikTutar;
    satir.fiyatKdvli = hizmet.aylikTutar;
    satir.kdv = hizmet.kdv;
    satir.birim = hizmet.birim;
    satir.kur = 1;
    satir.birim = hizmet.birim;
    satir.barkod = hizmet.barkod;
    satir.carpan = 1;
    satir.ad = hizmet.ad;
    satir.miktar = 1;
    satir.doviz = Doviz.TRY;
    satir.kod = hizmet.kod;
    satir.iskontolar = [];
    satir.altIskontoOran = 0;
    satir.tevkifatOran = 0;
    satir.stokId = hizmet.id;
    satir.teslimatlar = [];
    satir.girilenTeslimMiktar =  0 ;
    satir.getTeslimMiktar = 0 ;
    satir.tevkifatOran = 0 ;
    satir.isTeslimEdildi = false ;
    return satir;
  },
  getStokBirimGroupFiyat(cariGroup: string, stok: StokFlatView, satir: SiparisSatirItemExtend) {
    if (stok && stok.birimFiyatlari && stok.birimFiyatlari.length > 0) {
      const birimFiyat = stok.birimFiyatlari.find(a => a.grup == cariGroup && a.carpan == 1);
      if (birimFiyat) {
        const fiyat = StokBirimUtils.getFiyatStokBirimItem(birimFiyat, stok);
        satir.birim = birimFiyat.birim;
        satir.fiyatKdvli = fiyat;
        satir.fiyat = satir.fiyatKdvli! / (1 + (satir.kdv! / 100));


      }


    }

    return satir;
  },

  async getFaturaLoadingBakiye(satirlar: SiparisSatirItemExtend[]) {

    if (satirlar && satirlar.length > 0) {

      const stokBakiyeRequest: StokBakiyeRequest[] = [];
      satirlar.forEach((h) => {
        if (h.tip == FaturaSatirTip.Stok)
          stokBakiyeRequest.push({
            stokId: h.stokId,
            depoId: h.depoId,
            itemId: h.itemId,
            miktar: 0
          });
      });

      const stokBakiyeResponse = await useStokService().postStokBakiyesi(stokBakiyeRequest);

      let sayac = 0;
      if (stokBakiyeResponse != null) {
        satirlar.forEach((h: any) => {

          if (h.tip == FaturaSatirTip.Stok) {
            const bakiye = stokBakiyeResponse[sayac];
            if (bakiye == null)
              h.bakiye = 0;
            else {
              h.bakiye = bakiye.miktar;
              sayac = sayac + 1;
            }

          } else {
            h.bakiye = 0;
            h.anaBirim = h.birim;
          }


        });
      }
    }


  },
  getStokFiyat(stokId: string, hareketTipi: SiparisHareketTip): number {
    let fiyat = 0;
    const stok = useStokFlatStore().getStok(stokId);

    if (stok) {
      if (
        hareketTipi == SiparisHareketTip.SatisSiparis ||
        hareketTipi == SiparisHareketTip.SatisTeklif
      ) {
        if (stok.satisKdvDahil == true) {
          fiyat = stok.satisFiyat! / (1 + (stok.satisKdv! / 100));
        } else {
          fiyat = stok.satisFiyat!;
        }

      } else {
        if (stok.alisKdvDahil == true) {
          fiyat = stok.alisFiyat! / (1 + (stok.alisKdv! / 100));
        } else {
          fiyat = stok.alisFiyat!;
        }
      }

    }
    return fiyat;
  },

  siparisToCariView(model: SiparisView) {
    const cari: CariView = {};
    cari.ad = model.cariAd;
    cari.soyad = model.cariSoyad;
    cari.unvan = model.cariUnvan;
    if (model.cariVknTc && model.cariVknTc.length == 10) {
      cari.vergiNo = model.cariVknTc;
      cari.kurumTipi = KurumTip.Mukellef;
      cari.unvan = model?.cariUnvan?.isNullOrEmpty() ? model.cariAd + " " + model.cariSoyad : model.cariUnvan;

    } else if (model.cariVknTc && model.cariVknTc.length == 11) {
      cari.tcNo = model.cariVknTc;
      cari.kurumTipi = KurumTip.Sahis;
    } else {
      cari.tcNo = "11111111111";
      cari.kurumTipi = KurumTip.Sahis;
    }
    cari.adresler = [];
    cari.adresler.push({
      tipi: AdresTip.Fatura,
      adres: model.faturaAdres?.adres,
      cep: model.faturaAdres?.cep,
      belde: model.faturaAdres?.belde,
      ilce: model.faturaAdres?.ilce,
      mahalle: model.faturaAdres?.mahalle,
      mail: model.faturaAdres?.mail,
      pKodu: model.faturaAdres?.pKodu,
      sehir: model.faturaAdres?.sehir
    });
    if (model && model.teslimatlar && model.teslimatlar[0]) {
      cari.adresler.push({
        tipi: AdresTip.Teslimat,
        adres: model.teslimatlar[0]?.adres,
        cep: model.teslimatlar[0]?.cep,
        belde: model.teslimatlar[0]?.belde,
        ilce: model.teslimatlar[0]?.ilce,
        mahalle: model.teslimatlar[0]?.mahalle,
        mail: model.teslimatlar[0]?.mail,
        pKodu: model.teslimatlar[0]?.pKodu,
        sehir: model.teslimatlar[0]?.sehir
      });
    }
    return cari;
  },
  async siparisKaydetVeyaGuncelle(model: SiparisView): Promise<SiparisEntity> {
    if (model && model.id && model.id.length > 0)
      return await useSiparisService().putGuncelle(model);
    else {
      if (!(model.cariId && model.cariId.length > 0)) {
        const cari = this.siparisToCariView(model);
        const cariEntity = await useCariService().postEkle(cari as CariView);
        if (cariEntity) {
          model.cariId = cariEntity.id;
          model.cariUnvan = cariEntity.getUnvan;
        }
      } else {

        // const cari = this.siparisToCariView(model );
        // const cariEntity = await useCariService().putGuncelle(cari );
        // if (cariEntity){
        //   model.cariId = cariEntity.id ;
        //   model.cariUnvan = cariEntity.getUnvan  ;
        // }
      }


      if (model && model.siparisTip && model.siparisTip == SiparisTip.IsTakip) {
        return await usePersonelIsTakipService().postSiparisVer(model);
      } else {
        return await useSiparisService().postSiparisEkle(model);
      }

    }


    return {};
  },
  async saveFatura(model: SiparisView , isIrsaliye  = false  ) {

   let  hareketTip  = null ;

   if (isIrsaliye == true ){
     if (model.tip == SiparisHareketTip.SatisSiparis)
       hareketTip =  FaturaHareketTip.SatisFatura ;
     else if (model.tip == SiparisHareketTip.AlisSiparis)
       hareketTip = FaturaHareketTip.AlisFaturasi;


   }else{
     if (model.tip == SiparisHareketTip.SatisSiparis)
       hareketTip =  FaturaHareketTip.SatisIrsaliye ;
     else if (model.tip == SiparisHareketTip.AlisSiparis)
       hareketTip = FaturaHareketTip.AlisIrsaliye;
   }



   if (hareketTip == null){
     await alert("Tip Dönüşüm Hatası" , "Dönüşüm Hatası");

     return ;
   }


    //console.log("saveFatura, model", model)
    model!.satirlar?.forEach((a: any) => {
      a.girilenTeslimMiktar = a.miktar;
    });

    const faturaView: FaturaView = await useSiparisService().postSiparisToIrsaliye(model, model.tip as number);
    if (faturaView) {
      faturaView.tip = FaturaHareketTip.SatisFatura;
      faturaView.altBilgi = {
        altIskontolar: model.altIskontolar,
        cariIslemKur: model.cariIslemKur,
        tutarIskonto: model.tutarIskonto,
        cariIslemDoviz: model.cariIslemDoviz,
        faturaKalan: 0,
        faturaMaliyeti: 0,
        havaleTutar: model.isBankaOdeme ? model.bankaTutar : 0,
        kasaTutar: model.isKasaOdeme ? model.kasaTutar : 0,
        posTutar: model.isPosOdeme ? model.posTutar : 0

      } as FaturaAltBilgiItem;

      faturaView.ustBilgi?.siparisler?.push({
        tarih: model.tarih,
        no: model.evrakNo,
        referansId: model.id
      });

      faturaView.gonder = model.gonder;

      //console.log("faturaView", faturaView)

      await useSiparisService().postFaturaEkle(faturaView, hareketTip);

    }

  },
  changedKdvli(row: SiparisSatirItemExtend, kdvliFiyat: number) {
    const carpan = ((100 + row.kdv!) / 100);
    row.fiyat = kdvliFiyat / carpan;
  },

  changedKdvsiz(row: SiparisSatirItemExtend, kdvsizFiyat: number) {
    const carpan = ((100 + row.kdv!) / 100);
    row.fiyatKdvli = kdvsizFiyat * carpan;
  },
  getDoviz(doviz: number): string {
    const result = DovizList.find(a => a.value == doviz);
    if (result) return result.key;
    return "--YOK--";
  },
  stokToSiparisItem(stok: StokFlatView): SiparisSatirItemExtend {
    return new SiparisSatirItemExtend(stok);
  },

  async load(cariId: string, cariUnvan: string, haraketId: string): Promise<SiparisView> {
    if (haraketId && haraketId.length > 0) {
      return await useSiparisService().getView(haraketId) as SiparisView;


    } else {
      const evrakNo = await useEvrakNoService().getYeniEvrakNo(10);
      const view = {
        cariId: cariId,
        cariUnvan: cariUnvan,
        faturaAdres: {},
        altIskontolar: [],
        cariIslemDoviz: Doviz.TRY,
        cariIslemKur: 1,
        dokumanlar: [],
        evrakNo: evrakNo,
        gonder: {
          desi: 0,
          mailGonder: false,
          kargoGonder: false,
          smsGonder: false,
          otomatikMailGonder: false,
          otomatikSmsGonder: false,
          kapidaOdemeTutar: 0,
          kg: 0,
          kargoTeslimatTip: KargoTeslimatTip.StandartTeslimat,
          kargoBoyutTip: KargoBoyutTip.Koli,
          kargoOdemeTip: KargoOdemeTip.Alici,
          kargoTip: KargoTip.IslemYok,
          kargoTeslimTip: KargoTeslimTip.AdresTeslim,
          platformTip: PlatformTip.secilmedi


        },
        tarih: new Date().toISOString(),
        vade: new Date().toISOString(),
        tutarIskonto: 0,
        teslimatlar: [],
        satirlar: [],
        teklifler: [],
        siparisler: [],
        faturalar: [],
        irsaliyeler: []


      } as SiparisView;

      if (cariId && cariId.length > 0) {


        const cari = await useCariService().getGetir(view.cariId as string) as CariEntity;
        if (cari && cari.adresler && cari.adresler.length > 0) {
          const faturaAdres = cari.adresler.find(a => a.tipi == AdresTip.Fatura);
          const teslimatAdres = cari.adresler.find(a => a.tipi == AdresTip.Teslimat);
          if (faturaAdres) {
            view.faturaAdres!.adres = faturaAdres.adres;
            view.faturaAdres!.sehir = faturaAdres.sehir;
            view.faturaAdres!.pKodu = faturaAdres.pKodu;
            view.faturaAdres!.cep = faturaAdres.cep;
            view.faturaAdres!.ilce = faturaAdres.ilce;
            view.faturaAdres!.belde = faturaAdres.belde;
            view.faturaAdres!.mahalle = faturaAdres.mahalle;
            view.faturaAdres!.mail = faturaAdres.mail;
          }

          if (teslimatAdres) {
            view.teslimatlar?.push({
              adres: teslimatAdres.adres,
              sehir: teslimatAdres.sehir,
              pKodu: teslimatAdres.pKodu,
              cep: teslimatAdres.cep,
              ilce: teslimatAdres.ilce,
              belde: teslimatAdres.belde,
              mahalle: teslimatAdres.mahalle,
              mail: teslimatAdres.mail
            });
          }

        }
      }


      return view;
    }
  },
  newSiparisSatirItem(stok: StokFlatView, depoId: string, siparisHareketTip: SiparisHareketTip) {


    if (stok) {
      const satir = {
        tip: FaturaSatirTip.Stok,
        id: uuid.v4(),
        ad: stok.ad,
        kod: stok.kod,
        itemId: stok.itemId,
        aciklama: "",
        adresId: "",
        kur: 1,
        altIskontoOran: 0,
        barkod: stok.barkodStok,
        birim: stok.birim,
        carpan: 1,
        stokId: stok.stokId,
        iskontolar: [],
        doviz: stok.satisDoviz,
        genelKur: 1,
        kdv: stok.satisKdv,
        miktar: 1,
        fiyat: stok.satisFiyat,
        depoId: depoId,
        girilenTeslimMiktar: 0,
        teslimatlar: [],
        getTeslimMiktar: 0,
        kgAdet: 1 / stok.getAdetKilo!,
        uzunluk: 0,
        tevkifatOran: 0,
        terminTarih: new Date().toUTCString(),
        bultenNo : stok.tipOzellikler?.bultenNo,
        siparisNo : stok.tipOzellikler?.siparisNo,
        mensei : stok.tipOzellikler?.mensei,
        adet : stok.tipOzellikler?.adet,
        isTeslimEdildi : false,


      } as SiparisSatirItem;

      if (siparisHareketTip == SiparisHareketTip.AlisSiparis) {
        if (stok.alisKdvDahil == true) {
          satir.fiyat = stok.alisFiyat! / (1 + (stok.alisKdv! / 100));
          satir.doviz = stok.alisDoviz;
        } else {
          satir.fiyat = stok.alisFiyat;
          satir.doviz = stok.alisDoviz;
        }

      } else {
        if (stok.satisKdvDahil == true) {
          satir.fiyat = stok.satisFiyat! / (1 + (stok.satisKdv! / 100));
          satir.doviz = stok.satisDoviz;
        } else {
          satir.fiyat = stok.satisFiyat;
          satir.doviz = stok.satisDoviz;
        }
      }
      if (satir.kgAdet && satir.kgAdet === Infinity)
        satir.kgAdet = 1;


      return satir;
    }

  }


};