
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";

import { StokView } from "@/api/models/StokView";
import { useStokHareketService } from "@/api/services/StokHareketService";
import { useStokService } from "@/api/services/StokService";

export default defineComponent({
  components: {},
  props: {
    stok: {
      type: Object
    }
  },
  setup(props) {

    const localState = reactive({

      index: ref(0),
      stokRef: computed(() => props.stok as StokView),
      devirlerLength: computed(() => props.stok?.devirler?.length),
      selectedId: ref(),
      countStokHareket: ref(-1)
    });

    // watch(() => props.stok?.devirler[0].devirTarih, (id) => {
    //   localState.devirler = localState.stokRef.devirler as StokDevirItem[];
    //   setId();
    // });

    // watch(() => localState.selectedId, (id) => {
    //   setIndex();
    // });


    async function load() {
      localState.countStokHareket = await useStokHareketService().getCountStokHareket(localState.stokRef.id as string);
    }

    load();

    // function setIndex() {
    //   localState.index = localState.devirler.findIndex(a => a.id == localState.selectedId);
    //   if (localState.index <= 0)
    //     localState.index = 0;
    // }
    //
    // function setId() {
    //   localState.selectedId = localState.devirler[localState.index]?.id;
    // }

    // setId();

    // async function valueChanged(e: any) {
    //   if (localState.stokRef && localState.stokRef.id && localState.stokRef.id.length > 10) {
    //     const devirItem = localState.devirler[localState.index];
    //     devirMaliyetiHesapla(devirItem);
    //     await useStokService().putDevirGuncelleVaryantsiz(devirItem, localState.stokRef.id as string);
    //
    //   }
    // }

    // function devirMaliyetiHesapla(devirItem: StokDevirItem) {
    //   if (devirItem.devirMaliyeti == 0) {
    //     if (localState.stokRef.alisKdvDahil)
    //       devirItem.devirMaliyeti = localState.stokRef.alisFiyat! / (1 + (localState.stokRef.alisKdv! / 100));
    //     else
    //       devirItem.devirMaliyeti = localState.stokRef.alisFiyat!;
    //   }
    // }

    return {
      ...toRefs(localState),
    };
  }
});

