
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import NTag from "@/components/Tag/NTag.vue";
import NInput from "@/components/Input/NInput.vue";
import { number } from "yup";
import { uuid } from "vue-uuid";
import NCheckbox from "@/components/Checkbox/NCheckbox.vue";
import _, { isNumber } from "lodash";
import NSwitch from "@/components/Switch/NSwitch.vue";
import { bookmark } from "devexpress-reporting/designer/controls/metadata/properties/metadata";
import NGrid from "@/components/Grid/NGrid.vue";

export default defineComponent({
  components: { NGrid, NCheckbox, NTag },
  props: {
    modelValue: {
      type: Array
    },
    type: {
      type: String,
      default: "number"
    },
    inputStyle: {
      type: Object,
      default: {
        width: "100%"
      } as any
    },
    selectedItems: {
      type: Array,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showSelectAll: {
      type: Boolean,
      default: true
    },
    multipleEnter: {
      type: Boolean,
      default: false
    }

  },
  setup(props, { emit }) {
    const localState = reactive({
      model: computed(() => props.modelValue as any[]),
      selectedItemsRef: computed(() => props.selectedItems),
      uid: uuid.v4(),
      text: ref(""),
      selectAll: ref(false)
    });


    function addTag(e: any) {
      // console.log(e);
      // console.log(localState.model);
      // console.log(localState.text);
      if (e.key == "Enter") {
        if (localState.text) {
          if (props.type == "number") {
            NumberTaggAdd();
          } else {
            StringTaggAdd();
          }

        }
        localState.text = "";

      } else if (e.key == "Backspace" && !localState.text && localState.model && localState.model.length) {
        localState.model.splice(localState.model.length - 1, 1);
        emit("tagChanged");
      }


      e.target.size = Math.max(e.target.value.length * 1.3, 1.3);
    }
    function isNumber(n:any) { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }

    function NumberTaggAdd() {
      if (props.multipleEnter == true) {
        const text = localState.text.split("-");
        if (text && text.length > 1) {
          text.forEach((a) => {
            a = a.replace("," , ".");
            if (isNumber(a) == true)
              localState.model.push(Number.parseFloat(a));
          });
        } else {
          const text = localState.text?.replace("," ,".");
          if (isNumber(text) == true)
            localState.model.push(Number.parseFloat(text));
        }

      } else {
          localState.model.push(Number.parseFloat(localState.text));
      }
      emit("tagChanged");
    }

    function StringTaggAdd() {
      const text = localState.text.split("-");
      if (text && text.length > 1) {
        text.forEach((a) => localState.model.push((a)));
      } else {
        localState.model.push(localState.text);
      }
      emit("tagChanged");
    }

    function yapistir(e: any) {
      const paste = e.clipboardData?.getData("text") as string;

      const isEnter = paste.indexOf(",") + paste.indexOf("\n");
      if (isEnter > -1) {
        let pastes = paste.split("\n");
        pastes = pastes.map(a => a.replace("\n", "").replace("\r", ""));

        pastes = _.uniq(pastes);

        pastes.forEach(val => {
          const item = val.trim();
          if (item && item.length > 0) {
            localState.model.push(item);
            e.preventDefault();
          }
        });
      }
      setTimeout(() => {
        e.target.size = Math.max(e.target.value.length * 1.3, 1.3);
      }, 100);

    }

    //localState.model.push(val)

    function blurInput() {
      if (localState.text) {
        if (props.type == "number") {
          localState.model.push(Number.parseInt(localState.text));
          emit("tagChanged");
        } else {
          localState.model.push(localState.text);
          emit("tagChanged");
        }
      }
      localState.text = "";
    }

    function clickContainer() {
      document.getElementById(localState.uid as string)?.focus({ preventScroll: true });
    }


    function removeTag(index: number) {
      localState.model.splice(index, 1);
    }

    function toggle(selected: boolean, item: string) {
      if (localState.selectedItemsRef) {
        if (selected) {
          localState.selectedItemsRef?.push(item);
        } else {
          const findIndex = localState.selectedItemsRef.findIndex(a => a == item);
          if (findIndex >= 0) {
            localState.selectedItemsRef?.splice(findIndex, 1);
          }
        }
      }
      initSelectAll();

    }

    function initSelectAll() {
      localState.selectAll = localState.selectedItemsRef?.length == localState.model?.length;
    }

    // initSelectAll();

    function toggleSelectAll(selected: boolean) {
      if (localState.selectedItemsRef){
        localState.selectedItemsRef.splice(0, localState.selectedItemsRef.length);
        if (selected){
          localState.selectedItemsRef.push(...localState.model);
        }
      }
    }

    function getTitle() {
      let message = "Toplam: " + localState.model?.length + "\n";
      if (localState.selectedItemsRef && localState.selectedItemsRef.length > 0)
        message += "Seçili: " + localState.selectedItemsRef.length;
      return message;
    }


    return {
      ...toRefs(localState),
      blurInput,
      removeTag,
      addTag,
      clickContainer,
      toggle,
      yapistir,
      toggleSelectAll,
      getTitle
    };
  }
});

