import {defineStore} from "pinia";
import {useUserSettingsService} from "@/api/services/UserSettingsService";
import {Doviz} from "@/api/models/Doviz";

export const useStokSettingsStore = defineStore({
    id: "StokSettingsStore",
    state: () => ({
        stokFormatSettingsDefault: {
            fiyatlar: {
                fieldName: 'fiyatlar',
                format: '#,##0.00',
            },
            genislik: {
                fieldName: 'genislik',
                format: '#,##0.00',
            },
            kalinlik: {
                fieldName: 'kalinlik',
                format: '#,##0.00',
            },
            yukseklik: {
                fieldName: 'yukseklik',
                format: '#,##0.00',
            },
            uzunluk: {
                fieldName: 'uzunluk',
                format: '#,##0.00',
            },
            cap: {
                fieldName: 'cap',
                format: '#,##0.00',
            },
            yanak: {
                fieldName: 'yanak',
                format: '#,##0.00',
            },
            sacAdet: {
                fieldName: 'sacAdet',
                format: '#,##0',
            },
            agirlik: {
                fieldName: 'agirlik',
                format: '#,##0.00',
            },
            metre: {
                fieldName: 'metre',
                format: '#,##0.00',
            },
            miktar: {
                fieldName: 'miktar',
                format: '#,##0.00',
            },
            alisDoviz:Doviz.TRY,
            satisDoviz:Doviz.TRY
        } as StokFormatSettings,
        model:{} as StokFormatSettings,
        isLoaded:false

    }),
    actions: {
        async load() {
            this.isLoaded = false;
            this.reset();
            const result = await this._getDefaultSettings('stokFormatSettings');
            if (result)
                this.setModel(result)
            this.isLoaded = true;
        },
        async save(model:StokFormatSettings) {
            await this._saveSettings('stokFormatSettings', JSON.stringify(model));
            this.setModel(model);
        },
        reset() {
            this.setModel(this.stokFormatSettingsDefault);
        },
        async _saveSettings(key: string, value: string) {
            await useUserSettingsService().postSave({
                name: key,
                value: value
            });
        },
        async _getDefaultSettings(key: string) {
            return useUserSettingsService().getGetValue(key);
        },
        setModel(model:StokFormatSettings){
            this.model = Object.assign(this.model,model);
        }
    }
});

export class StokFormatSettings{
    fiyatlar?:FormatSettingItem;
    genislik?:FormatSettingItem;
    kalinlik?:FormatSettingItem;
    yukseklik?:FormatSettingItem;
    uzunluk?:FormatSettingItem;
    cap?:FormatSettingItem;
    yanak?:FormatSettingItem;
    sacAdet?:FormatSettingItem;
    agirlik?:FormatSettingItem;
    metre?:FormatSettingItem;
    miktar?:FormatSettingItem;
    showVariants?:boolean;
    showGroupped?:boolean;
    showAltToplam?:boolean;
    showTreeView?:boolean;
    showTipOzelliklerAltToplam?:boolean;
    alisDoviz?:Doviz;
    satisDoviz?:Doviz;

}

export class FormatSettingItem{
    fieldName?:string;
    format?:string;
}
