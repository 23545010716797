
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormFieldSimple from "@/components/FormField/NFormFieldSimple.vue";
import {PazaryeriFiyatTipi, PazaryeriFiyatTipiList} from "@/api/models/PazaryeriFiyatTipi";
import NFormField from "@/components/FormField/NFormField.vue";
import {useKurCachedServis} from "@/modules/Ortak/services/useKurCachedServis";
import {KurView} from "@/api/models/KurView";
import {Doviz, DovizList} from "@/api/models/Doviz";
import {FiyatUtils} from "@/modules/Stok/store/FiyatUtils";


export default defineComponent({
  components: { NFormField,  NFormFieldSimple, NFormGrid },
  props: {
    fiyatTipi:{
      type:Number,
      default:0
    },
    komisyonOran:{
      type:Number,
      default:0
    },
    karMarji:{
      type:Number,
      default:0
    },
    alisFiyati:{
      type:Number,
      default:0
    },
    satisFiyati:{
      type:Number,
      default:0
    },
    deger:{
      type:Number,
      default:0
    },
    kargoFiyat:{
      type:Number,
      default:0
    },
    indirim:{
      type:Number,
      default:0
    },
    indirimTipi:{
      type:Number,
      default:0
    },
    showIndirim:{
      type:Boolean,
      default:true
    },
    alisKdv:{
      type:Number,
      default:0
    },
    alisKdvDahil:{
      type:Boolean,
      default:false
    },
    satisKdv:{
      type:Number,
      default:0
    },
    satisKdvDahil:{
      type:Boolean,
      default:false
    },
    maliyet:{
      type:Number
    },
    title:{
      type:String,
      default:'Fiyatlandırma'
    },
    alisDoviz:{
      default:Doviz.TRY
    },
    satisDoviz:{
      default:Doviz.TRY
    }

  },
  setup(props,{emit}) {

    const localState = reactive({
      fiyatTipiRef:computed({
        get:() => props.fiyatTipi,
        set:(val) => emit('update:fiyatTipi', val)
      }),
      komisyonOranRef:computed({
        get:() => props.komisyonOran,
        set:(val) => emit('update:komisyonOran', val)
      }),
      karmarjiRef:computed({
        get:() => props.karMarji,
        set:(val) => emit('update:karMarji', val)
      }),
      alisFiyatiRef:computed({
        get:() => props.alisFiyati,
        set:(val) => emit('update:alisFiyati', val)
      }),
      degerRef:computed({
        get:() => props.deger,
        set:(val) => emit('update:deger', val)
      }),
      satisFiyatiRef:computed({
        get:() => props.satisFiyati,
        set:(val) => emit('update:satisFiyati', val)
      }),
      kargoFiyatRef:computed({
        get:() => props.kargoFiyat,
        set:(val) => emit('update:kargoFiyat', val)
      }),
      indirimRef:computed({
        get:() => props.indirim,
        set:(val) => emit('update:indirim', val)
      }),
      indirimTipiRef:computed({
        get:() => props.indirimTipi,
        set:(val) => emit('update:indirimTipi', val)
      }),
      alisKdvDahilRef:computed(
          {get:()=>props.alisKdvDahil,
            set:(val)=> emit('update:alisKdvDahil',val)
          }),
      satisKdvDahilRef:computed({
        get:()=>props.satisKdvDahil,
        set:(val)=> emit('update:satisKdvDahil',val)
      }),
      pazaryeriFiyatTipi:ref(PazaryeriFiyatTipi),
      pazaryeriFiyatTipiList:ref(PazaryeriFiyatTipiList),
      pazaryeriIndirimTipiList:ref(PazaryeriFiyatTipiList),
      kurList:ref([] as KurView[]),
      dovizTip:Doviz
    });

    async function load(){
      localState.kurList = await useKurCachedServis().getKurList();
      localState.pazaryeriIndirimTipiList = JSON.parse(JSON.stringify(localState.pazaryeriIndirimTipiList))
      localState.pazaryeriIndirimTipiList[0].description = 'İndirimsiz'
    }

    function getKdvDahilSatisFiyat() {
      return FiyatUtils.getKdvDahilFiyat(props.satisFiyati,props.satisKdv,props.satisKdvDahil);
    }
    function getKdvDahilAlisFiyat() {
      const alisFiyati =FiyatUtils.getKdvDahilFiyat(props.alisFiyati,props.alisKdv,props.alisKdvDahil);
      return alisFiyati;
    }

    function getKdvDahilAlisMaliyetFiyat() {
      const maliyetFiyat =FiyatUtils.getKdvDahilFiyat(props.maliyet as number,props.alisKdv,false);
      const alisFiyati =FiyatUtils.getKdvDahilFiyat(props.alisFiyati,props.alisKdv,props.alisKdvDahil);
      return Math.max(alisFiyati, maliyetFiyat);
    }

    function getPazaryeriFiyat() {
      return FiyatUtils.getPazaryeriFiyat(localState.kurList, props.fiyatTipi,props.deger,props.satisDoviz, props.satisFiyati,props.satisKdv,props.satisKdvDahil,props.alisDoviz,props.alisFiyati,props.alisKdv,props.alisKdvDahil, props.maliyet as number, props.karMarji, props.kargoFiyat,props.komisyonOran)
    }

    function getIndirimsizFiyat() {
      const value = FiyatUtils.getIndirimsizFiyat(localState.kurList, props.indirimTipi, props.indirim,props.fiyatTipi,props.deger, props.satisDoviz,props.satisFiyati,props.satisKdv,props.satisKdvDahil,props.alisDoviz,props.alisFiyati,props.alisKdv,props.alisKdvDahil,props.karMarji,props.kargoFiyat,props.komisyonOran);
      return value;
    }

    function getIndirimOran(){
      return FiyatUtils.getIndirimOran(localState.kurList, props.indirimTipi, props.indirim,props.fiyatTipi,props.deger, props.satisDoviz,props.satisFiyati,props.satisKdv,props.satisKdvDahil,props.alisDoviz,props.alisFiyati,props.alisKdv,props.alisKdvDahil,props.karMarji,props.kargoFiyat,props.komisyonOran)
    }

    function getKur(){
      return FiyatUtils.getKur(localState.kurList, props.fiyatTipi,props.satisDoviz,props.alisDoviz);
    }

    function getDoviz(){
      return  FiyatUtils.getDoviz(props.fiyatTipi,props.satisDoviz,props.alisDoviz);
    }
    function getDovizAd() {
      return FiyatUtils.getDovizAd(props.fiyatTipi,props.satisDoviz,props.alisDoviz);
    }

    load()
    return {
      ...toRefs(localState),
      getPazaryeriFiyat,
      getIndirimsizFiyat,
      getIndirimOran,
      getKdvDahilSatisFiyat,
      getKdvDahilAlisFiyat,
      getKdvDahilAlisMaliyetFiyat,
      getKur,
      getDoviz,
      getDovizAd
    };
  }
});

