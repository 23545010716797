import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';


export const useCariOnlineService = defineStore({
id: 'CariOnlineService',
actions: {


	async getEkstre(hash:string)
	{
		const { request, response } = useApi(`/api/CariOnline/Ekstre/${hash}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCreateLink(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CariOnline/CreateLink/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
