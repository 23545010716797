
import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  props: {
    label:{
      type:String,
      default:'Menü Başlığı'
    }
  },
  setup() {

    const localState = reactive({});

    return {
      ...toRefs(localState)
    };
  }
});

