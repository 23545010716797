
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import {usedCacheFirmaService} from "@/modules/Ayarlar/Firmalar/services/usedCacheFirmaService";

export default defineComponent({
  components: { },
  props: {
    modelValue:{
      type:String
    },
    label:{
      type:String
    }

  },
  setup(props,{emit}) {

    const localState = reactive({
      list:ref([] as string[]),
      model:computed({
        get: () => props.modelValue,
        set:(val)=> emit('update:modelValue',val)
      })
    });

    async function load() {
      const firma = await usedCacheFirmaService().getFirma()
      localState.list = firma.diller as string[];
    }
    load();

    return {
      ...toRefs(localState)
    };
  }
});

