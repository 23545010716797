import {sum} from "lodash";
import {StokSimpleView} from "@/api/models/StokSimpleView";
import {KesimApi} from "@/modules/SacUretim/api/KesimApi";
import {SacUretimFisView} from "@/api/models/SacUretimFisView";

export const UretimSacFisApi = {
    kesimApi:KesimApi,
    fisToplamTonaj(fisRef:SacUretimFisView) {
        return  sum(fisRef.satirlar?.map(a => a.tonaj))
    },
    getKalanTonaj(stokRef:StokSimpleView, fisRef:SacUretimFisView) {
        return stokRef.getKalanMiktar as number - this.fisToplamTonaj(fisRef);
    },
    fisToplamBoy(fisRef:SacUretimFisView) {
        return sum(fisRef.satirlar?.map(a => a.uzunluk as number * (a.adet as number)))
    },

    getToplamBoy(stokRef:StokSimpleView) {
        return this.kesimApi.getUzunluk(stokRef.getKalanMiktar as number, stokRef.tipOzellikler?.genislik as number, stokRef.tipOzellikler?.kalinlik as number)
    },
    getKalanBoy(stokRef:StokSimpleView, fisRef:SacUretimFisView) {
        return this.getToplamBoy(stokRef) - this.fisToplamBoy(fisRef);
    },
    getKalanGenislik(stokRef:StokSimpleView, fisRef:SacUretimFisView){

    }

}