
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import KasaSelectBox from "@/modules/Kasa/components/KasaSelectBox.vue";
import BankaSelectBox from "@/modules/Banka/components/BankaSelectBox.vue";
import PosSelectBox from "@/modules/Pos/components/PosSelectBox.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import { SiparisView } from "@/api/models/SiparisView";
import NButton from "@/components/Button/NButton.vue";
import NFormFieldLabelTop from "@/components/FormField/NFormFieldLabelTop.vue";

export default defineComponent({
  components: { NFormFieldLabelTop, NButton, NGrid, PosSelectBox, BankaSelectBox, KasaSelectBox },
  props: {
    modelValue: {
      type: Object
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      model: computed({
        get: () => props.modelValue as SiparisView,
        set: (val) => emit("update:modelValue", val)
      }),

    });

    function bankaOdeme() {
      localState.model.isBankaOdeme = true ;
      localState.model.isKasaOdeme = false ;
      localState.model.isPosOdeme = false ;

    }

    function nakitOdeme() {
      localState.model.isBankaOdeme = false ;
      localState.model.isKasaOdeme = true ;
      localState.model.isPosOdeme = false ;
    }

    function posOdeme() {
      localState.model.isBankaOdeme = false ;
      localState.model.isKasaOdeme = false ;
      localState.model.isPosOdeme = true ;
    }


    return {
      ...toRefs(localState),
      bankaOdeme,
      nakitOdeme,
      posOdeme
    };
  }
});
