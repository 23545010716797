
export enum ReportExportTypes{
pdf=0,
xlsx=1,
csv=2,
docx=3,
html=4,
jpg=5
}

export const ReportExportTypesList = [
{key:'pdf', value:0, description:'Pdf'},
{key:'xlsx', value:1, description:'Excel'},
{key:'csv', value:2, description:'Csv'},
{key:'docx', value:3, description:'Word'},
{key:'html', value:4, description:'Html'},
{key:'jpg', value:5, description:'Resim'}
]
