import {alert, confirm} from "devextreme/ui/dialog";
import {useUserSettingsService} from "@/api/services/UserSettingsService";

export const GenelUtils = {
   async hata(hataHtml : string , hataBaslik : string){
     await alert(hataHtml , hataBaslik);
  },
  isAllNumber(numbers: any) {
    if (numbers?.length > 10)
      return false;

    if (numbers === undefined || numbers === null || numbers?.length <= 1) {
      return true;
    }

    const sonuc = isNaN(numbers); // NaN = non number
    if (sonuc) // NaN ise Sadece değildir
      return false;
    else
      return true;
  },

  copyMenuClick(val: string) {
    if (val && val.length > 0)
      navigator.clipboard.writeText(val);
  },
  async onayDiyalogu(htmlString = "İşlemi Onaylıyor musunuz ? ", baslik = "İşlem Onayı"): Promise<boolean> {
    return confirm(htmlString, baslik);
  },
  async silmeOnayiDiyalogu(htmlString = "Silme İşlemi Onaylıyor musunuz ? ", baslik = "Silme Onayı"): Promise<boolean> {
    return confirm(htmlString, baslik);
  },

  async getDefaultSettings(key: string) {
    return useUserSettingsService().getGetValue(key);
  },
  sortByEnums(enumList:Array<any>) {
    return (a: any, b: any) => {
      const aSira = enumList.indexOf(a.value);
      const bSira = enumList.indexOf(b.value);
      return aSira - bSira;
    };
  },


};