
export enum EFaturaDurumu{
Waiting=0,
Rejected=1,
InCanceled=2,
Canceled=3,
InProgress=4,
Sended=5,
Error=6,
ServiceError=7
}

export const EFaturaDurumuList = [
{key:'Waiting', value:0, description:'Bekliyor'},
{key:'Rejected', value:1, description:'Red Edildi'},
{key:'InCanceled', value:2, description:'İptal Ediliyor...'},
{key:'Canceled', value:3, description:'İptal Edildi'},
{key:'InProgress', value:4, description:'GİB`e Gönderiliyor...'},
{key:'Sended', value:5, description:'GİB`e Gönderildi'},
{key:'Error', value:6, description:'Hata'},
{key:'ServiceError', value:7, description:'Servis Hatası'}
]
