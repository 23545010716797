import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!

  return (_openBlock(), _createBlock(_component_DxSelectBox, {
    items: _ctx.list,
    "onUpdate:items": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
    value: _ctx.model,
    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model) = $event)),
    label: _ctx.label
  }, null, 8, ["items", "value", "label"]))
}