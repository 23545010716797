
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import {onStokHubManagerSignal} from "@/api/signals/StokHubManagerSignal";
import {NameObject} from "@/api/models/NameObject";
import NCard from "@/components/Card/NCard.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NInput from "@/components/Input/NInput.vue";
import NButton from "@/components/Button/NButton.vue";
import {useHepsiBuradaService} from "@/api/services/HepsiBuradaService";
import NTagList from "@/components/Tag/NTagList.vue";
import _ from "lodash";

export default defineComponent({
  components: {
    NFormField,
    NTagList,
    NButton,
    NInput,
    NFormGrid,
    NGrid,
    NCard

  },
  props: {
    modelValue:{
      type:Array
    },
    show:{
      type:Boolean,
      defaultValue:true
    },
    text:{
      type:String
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      model:computed(()=> props.modelValue),
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val)
      }),
      data:ref({} as NameObject),
      counter:ref(0),
      products:ref(),
      searchText:ref(props.text),
      selectedSkus:ref([] as string[])
    });

    if (localState.searchText && localState.searchText.length > 0){
      search()
    }

    async function search(){
      const list = await useHepsiBuradaService().getProductsSearch(localState.searchText as string)
      const products = [] as any[];
      list.products.forEach((product:any)=>{
        product.variantList.forEach((variant:any)=>{
          variant.brand = product.brand;
          variant.ozellikler = "";
          for (const propertiesKey in variant.properties) {
            variant.ozellikler += propertiesKey + ": " + variant.properties[propertiesKey].displayValue + ", "
          }
          products.push(variant)
        })

        })
      localState.products = products;
    }

    function save() {
      let skus = [];

      props.modelValue?.forEach(a => skus.push(a));
      skus.push(...localState.selectedSkus);
      skus = _.uniq(skus);
      emit('update:modelValue',skus)
      localState.showRef = false;
    }



    return {
      ...toRefs(localState),
      search,
      save
    };
  }
});
