import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {IdName} from '../models/IdName'
import {DepoView} from '../models/DepoView'

export const useSubeService = defineStore({
id: 'SubeService',
actions: {


	async getDefault()
	{
		const { request, response } = useApi(`/api/sube/default`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetir(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sube/Getir/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getDefaultInstall(firmaKod:string)
	{
		const { request, response } = useApi(`/api/sube/DefaultInstall/${firmaKod}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sube/Liste/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListeIdName()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sube/ListeIdName/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getDepolar()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sube/Depolar/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getDepo(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sube/Depo/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async putDepoGuncelle(id:string,name:string,isBakiye:boolean)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sube/DepoGuncelle/${shared.firm.id}/${id}/${name}/${isBakiye}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async postEkle(ıdName:IdName)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sube/Ekle/${shared.firm.id}`,{ method:'POST', data:ıdName });
		await request();
		return response.value;
	},

	async postDepoEkle(depoView:DepoView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sube/DepoEkle/${shared.firm.id}`,{ method:'POST', data:depoView });
		await request();
		return response.value;
	},

	async putGuncelle(ıdName:IdName)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sube/Guncelle/${shared.firm.id}`,{ method:'PUT', data:ıdName });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sube/Sil/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async deleteDepoSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sube/DepoSil/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getDepoFlatList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sube/DepoFlatList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getUserSubeList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sube/UserSubeList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getUserDepoList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sube/UserDepoList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
