import {defineStore} from "pinia";
import axios from "axios";
import useApi from "@/api/useApi";
import { ReportType } from "@/api/models/ReportType";
import { useRaporEkraniService } from "@/api/services/RaporEkraniService";
import { ReportEntity } from "@/api/models/ReportEntity";

export const useReportStore = defineStore({
    id: "ReportStore",
    state: () => ({
        url:'http://localhost:8989/'
    }),
    actions: {
       async print(report: string, data: any, printer: string) {

            const dataString = JSON.stringify(data);
            const body = {
                printer: printer,
                data: dataString,
                report: report
            }
            const { request, response } = useApi(this.url + 'api/printer/',{ method:'POST', data:body, withCredentials:false });
            await request();
            return response.value;
      },
      async printers() {
        const { request, response } = useApi(this.url + 'api/printer/',{ method:'GET', withCredentials:false });
        await request();
        return response.value;
      },
      setPrinter(id:string,printName:string){
         localStorage.setItem(id,printName)
      },
      getPrinter(id:string){
         return localStorage.getItem(id);
      },
      setDefaultReportId(reportType:ReportType, id:string){
         localStorage.setItem("report_" + reportType,id);
      },
      async getDefaultReportId(reportType:ReportType){
         const defultReportType = localStorage.getItem("report_" + reportType);

         if (!defultReportType){
           const reports = await useRaporEkraniService().getGetAllByType(reportType) as ReportEntity[];
           if (reports && reports.length > 0){
             const report = reports[0];
             this.setDefaultReportId(reportType,report.id as string);
             return report.id;
           }
         }else{
           return  defultReportType;
         }
         return null;
      }

    }

})
