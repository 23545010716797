
import {
  computed,
  defineComponent, onMounted,
  reactive,
  ref,
  toRefs,
  watch
} from "vue";
import { Doviz, DovizList } from "@/api/models/Doviz";
import { IslemHesaplamaEntity } from "@/api/models/IslemHesaplamaEntity";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";
import { KurView } from "@/api/models/KurView";
import { MoneyUtils } from "@/utils/money/MoneyUtils";
import { useKurCachedServis } from "@/modules/Ortak/services/useKurCachedServis";
import { DxTextBox } from "devextreme-vue";
import NGrid from "@/components/Grid/NGrid.vue";
import { DxNumberBox } from "devextreme-vue/number-box";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
export default defineComponent({
  components: { NFormGrid, NGrid, DxNumberBox },
  props: {
    islemHesaplamaEntity: {
      type: Object
    },
    hareketId: {
      type: String,
      default : ''
    },
    kurlar: {
      type: Array,
    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      model: computed(() => props.islemHesaplamaEntity as IslemHesaplamaEntity),
      dovizTipleri: ref(DovizList),
      kurlarRef: computed({
          get: () => props.kurlar as KurView[],
          set: (val) => emit("update:kurlar", val)
      }),
      moneyUtils: ref(MoneyUtils),
      islemTutarNumberBox: ref()
    });
    // watch(
    //     () => localState.model,
    //     (newValue: IslemHesaplamaEntity) => {
    //       calculate(newValue);
    //     },
    //     { deep: true }
    // );
    // watch(
    //     () => localState.model?.islenecekDoviz, val => islenecekKurChanged()
    // );
    // watch(
    //     () => localState.model?.islemDoviz, val => islemKurChanged()
    // );
    async function load() {
        const kurlar = (props.kurlar == undefined || (props.kurlar && props.kurlar?.length == 0) ? await useKurCachedServis().getKurList() : props.kurlar) as KurView[]
        Object.assign(localState.kurlarRef, kurlar)
        if (localState.model.islenecekTutar! == 0)
          islenecekKurChanged()
    }
    setTimeout(() => {
      localState.islemTutarNumberBox?.instance?.focus();
    }, 950);
    load();
    // Events
    function islemKurChanged() {
      if (localState.model.islemDoviz === Doviz.TRY) {
        localState.model.islemKur = 1;
      } else if (localState.kurlarRef && localState.model.islemDoviz) {
        const kur = localState.kurlarRef.find(a => a.doviz == localState.model.islemDoviz) as KurView;
        if (props.hareketId && props.hareketId.length > 0) {
          localState.model.islemKur = kur.alis;
        } else {
          localState.model.islemKur = kur!.alis;
        }
      }
    }
    function islenecekKurChanged() {
      if (localState.model.islenecekDoviz === Doviz.TRY) {
        localState.model.islenecekKur = 1;
      } else if (localState.kurlarRef && localState.model.islenecekDoviz) {
        const kur = localState.kurlarRef.find((a: KurView) => a.doviz == localState.model.islenecekDoviz) as KurView;
        if (props.hareketId && props.hareketId.length > 0) {
          localState.model.islenecekKur = kur.alis;
        } else {
          localState.model.islenecekKur = kur.alis;
        }
      }
    }
    function calculate() {
      const hesaplama = localState.model as any ;
      // 1. İşlem Tutara göre
      hesaplama.islemYerelTutar =
          (hesaplama.islemKur as number) * (hesaplama.islemTutar as number);
      hesaplama.islenecekTutar =
          hesaplama.islemYerelTutar / hesaplama.islenecekKur;
      hesaplama.islenecekYerelTutar = hesaplama.islenecekTutar * hesaplama.islenecekKur;
    }
    function  islenecekYerelTutarValueChanged(){
      const hesaplama = localState.model  as any;

      hesaplama.islenecekTutar = hesaplama.islenecekYerelTutar / hesaplama.islenecekKur ;

      hesaplama.islemTutar = hesaplama.islenecekYerelTutar / hesaplama.islemKur ;


    }
    return {
      ...toRefs(localState),
      islenecekYerelTutarValueChanged,
      calculate,
      islemKurChanged,
      islenecekKurChanged
    };
  }
});
