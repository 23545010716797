import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src", "data"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!
  const _component_MailSendDialog = _resolveComponent("MailSendDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showModal)
      ? (_openBlock(), _createBlock(_component_NModalDialog, {
          key: 0,
          modelValue: _ctx.showModal,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showModal) = $event)),
          width: "100%",
          title: _ctx.reportName,
          "show-close": true
        }, {
          toolbar: _withCtx(() => [
            _createVNode(_component_NButton, {
              type: "",
              icon: "send",
              click: _ctx.mailGonder
            }, {
              default: _withCtx(() => [
                _createTextVNode("Mail Olarak Gönder ")
              ]),
              _: 1
            }, 8, ["click"])
          ]),
          body: _withCtx(() => [
            _createVNode(_component_NGrid, {
              rows: "1fr",
              cols: "1fr",
              style: {"min-height":"600px"},
              id: "reportFrameContent"
            }, {
              default: _withCtx(() => [
                (_ctx.src && _ctx.src?.length > 0)
                  ? (_openBlock(), _createElementBlock("iframe", {
                      key: 0,
                      ref: "iframeRef",
                      name: "pdfPreview",
                      src: _ctx.src,
                      data: JSON.stringify(_ctx.modelValue),
                      id: "pdfDocument",
                      allow: "cross-origin",
                      width: "100%",
                      height: "100%"
                    }, null, 8, _hoisted_1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["modelValue", "title"]))
      : _createCommentVNode("", true),
    (_ctx.showMailDialog)
      ? (_openBlock(), _createBlock(_component_MailSendDialog, {
          key: 1,
          show: _ctx.showMailDialog,
          "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showMailDialog) = $event)),
          modelValue: _ctx.mailView,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.mailView) = $event)),
          "report-key": _ctx.reportName,
          mailSendTo: _ctx.mailSendTo,
          onOnInsert: _ctx.exportPdf
        }, null, 8, ["show", "modelValue", "report-key", "mailSendTo", "onOnInsert"]))
      : _createCommentVNode("", true)
  ], 64))
}