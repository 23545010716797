
export enum KapidaOdemeTip{
Nakit=0,
KrediKarti=1,
TaksitliKrediKarti=2
}

export const KapidaOdemeTipList = [
{key:'Nakit', value:0, description:'Nakit'},
{key:'KrediKarti', value:1, description:'Kredi Kartı'},
{key:'TaksitliKrediKarti', value:2, description:'Taksitli Kredi Kartı'}
]
