import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NDropDownTreeView = _resolveComponent("NDropDownTreeView")!
  const _component_NRefresher = _resolveComponent("NRefresher")!
  const _component_StokPazaryeriFiyatItem = _resolveComponent("StokPazaryeriFiyatItem")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NInputHtml = _resolveComponent("NInputHtml")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_PazaryeriFiyatItem = _resolveComponent("PazaryeriFiyatItem")!
  const _component_CicekSepetiOzellikEditor = _resolveComponent("CicekSepetiOzellikEditor")!

  return (_openBlock(), _createElementBlock("div", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), [
    (_ctx.stokRef && _ctx.cicekSepeti && _ctx.cicekSepetiRef.kategori)
      ? (_openBlock(), _createBlock(_component_NFormGrid, {
          key: 0,
          cols: "1fr 1fr",
          title: "Çiçek Sepeti'ne Gönder"
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_NFormField, {
              label: "Çiçek Sepeti'ne Gönder",
              "grid-cols": "1fr auto"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NSwitch, {
                  modelValue: _ctx.cicekSepetiRef.isActive,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cicekSepetiRef.isActive) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_NFormField, {
              label: "Kategori",
              "grid-cols": "120px 1fr",
              span: "2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NRefresher, { load: _ctx.load }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NDropDownTreeView, {
                      "selected-item": _ctx.cicekSepetiRef.kategori,
                      "onUpdate:selectedItem": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.cicekSepetiRef.kategori) = $event)),
                      "data-source": _ctx.kategoriler,
                      "onUpdate:dataSource": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kategoriler) = $event)),
                      onSelectedChanged: _ctx.selectedChangedKategori,
                      "items-expr": "subCategories"
                    }, null, 8, ["selected-item", "data-source", "onSelectedChanged"])
                  ]),
                  _: 1
                }, 8, ["load"])
              ]),
              _: 1
            }),
            _createVNode(_component_StokPazaryeriFiyatItem, {
              modelValue: _ctx.cicekSepetiRef.fiyat,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.cicekSepetiRef.fiyat) = $event)),
              "grid-cols": "120px 1fr 1fr"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_StokPazaryeriFiyatItem, {
              modelValue: _ctx.cicekSepetiRef.indirim,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.cicekSepetiRef.indirim) = $event)),
              "grid-cols": "60px 1fr 1fr",
              label: "İndirim"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_NFormField, {
              label: "Stok Kodu",
              "grid-cols": "120px 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  modelValue: _ctx.cicekSepetiRef.cicekSepetiStokKod,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.cicekSepetiRef.cicekSepetiStokKod) = $event)),
                  modelModifiers: { number: true },
                  placeholder: "CicekSepeti'de kayıtlı stok kodu. Stok CicekSepeti'de yoksa boş bırakınız",
                  type: "text"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            (_ctx.stokRef)
              ? (_openBlock(), _createBlock(_component_NFormField, {
                  key: 0,
                  label: "Barkod",
                  "grid-cols": "60px 1fr"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NInput, {
                      modelValue: _ctx.cicekSepetiRef.barkod,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.cicekSepetiRef.barkod) = $event)),
                      placeholder: _ctx.stokRef?.barkod
                    }, null, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.stokRef)
              ? (_openBlock(), _createBlock(_component_NFormField, {
                  key: 1,
                  label: "Stok Adı",
                  "grid-cols": "120px 1fr",
                  span: "2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NInput, {
                      modelValue: _ctx.cicekSepetiRef.ad,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.cicekSepetiRef.ad) = $event)),
                      placeholder: _ctx.stokRef?.ad
                    }, null, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.stokRef)
              ? (_openBlock(), _createBlock(_component_NFormField, {
                  key: 2,
                  label: "Kısa Açıklama",
                  "grid-cols": "120px 1fr",
                  span: "2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NInput, {
                      modelValue: _ctx.cicekSepetiRef.kisaAciklama,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.cicekSepetiRef.kisaAciklama) = $event)),
                      placeholder: _ctx.stokRef?.kisaAciklama
                    }, null, 8, ["modelValue", "placeholder"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_NFormField, {
              label: "Açıklama",
              "grid-cols": "120px 1fr",
              span: "2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInputHtml, {
                  modelValue: _ctx.cicekSepetiRef.aciklama,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.cicekSepetiRef.aciklama) = $event)),
                  placeholder: _ctx.stokRef?.aciklama
                }, null, 8, ["modelValue", "placeholder"])
              ]),
              _: 1
            }),
            (_ctx.stokRef)
              ? (_openBlock(), _createBlock(_component_NFormField, {
                  key: 3,
                  label: "Gönderim Mesaj Tipi",
                  "grid-cols": "120px 1fr",
                  span: "1"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxSelectBox, {
                      items: _ctx.deliveryMessageTypeList,
                      "display-expr": "key",
                      "value-expr": "value",
                      searchEnabled: false,
                      value: _ctx.cicekSepetiRef.deliveryMessageType,
                      "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.cicekSepetiRef.deliveryMessageType) = $event))
                    }, null, 8, ["items", "value"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.stokRef)
              ? (_openBlock(), _createBlock(_component_NFormField, {
                  key: 4,
                  label: "Gönderim Tipi",
                  "grid-cols": "120px 1fr",
                  span: "1"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxSelectBox, {
                      items: _ctx.deliveryTypeList,
                      "display-expr": "key",
                      "value-expr": "value",
                      searchEnabled: false,
                      value: _ctx.cicekSepetiRef.deliveryType,
                      "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.cicekSepetiRef.deliveryType) = $event))
                    }, null, 8, ["items", "value"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_PazaryeriFiyatItem, {
      title: "CicekSepeti Fiyatı",
      "alis-kdv": _ctx.alisKdvRef,
      "onUpdate:alisKdv": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
      "alis-kdv-dahil": _ctx.alisKdvDahilRef,
      "onUpdate:alisKdvDahil": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
      "satis-kdv": _ctx.satisKdvRef,
      "onUpdate:satisKdv": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
      "satis-kdv-dahil": _ctx.satisKdvDahilRef,
      "onUpdate:satisKdvDahil": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
      "alis-fiyati": _ctx.alisFiyatRef,
      "onUpdate:alisFiyati": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
      "satis-fiyati": _ctx.satisFiyatRef,
      "onUpdate:satisFiyati": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.satisFiyatRef) = $event)),
      "kargo-fiyat": _ctx.cicekSepetiRef.kargoTutar,
      "onUpdate:kargoFiyat": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.cicekSepetiRef.kargoTutar) = $event)),
      "komisyon-oran": _ctx.cicekSepetiRef.komisyon,
      "onUpdate:komisyonOran": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.cicekSepetiRef.komisyon) = $event)),
      "fiyat-tipi": _ctx.cicekSepetiRef.fiyat.tip,
      "onUpdate:fiyatTipi": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.cicekSepetiRef.fiyat.tip) = $event)),
      deger: _ctx.cicekSepetiRef.fiyat.deger,
      "onUpdate:deger": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.cicekSepetiRef.fiyat.deger) = $event)),
      "kar-marji": _ctx.cicekSepetiRef.karMarji,
      "onUpdate:karMarji": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.cicekSepetiRef.karMarji) = $event)),
      "indirim-tipi": _ctx.cicekSepetiRef.indirim.tip,
      "onUpdate:indirimTipi": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.cicekSepetiRef.indirim.tip) = $event)),
      indirim: _ctx.cicekSepetiRef.indirim.deger,
      "onUpdate:indirim": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.cicekSepetiRef.indirim.deger) = $event)),
      "alis-doviz": _ctx.stokRef.alisDoviz,
      "onUpdate:alisDoviz": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.stokRef.alisDoviz) = $event)),
      "satis-doviz": _ctx.stokRef.satisDoviz,
      "onUpdate:satisDoviz": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.stokRef.satisDoviz) = $event)),
      maliyet: _ctx.stokRef.getMaliyet,
      "onUpdate:maliyet": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.stokRef.getMaliyet) = $event))
    }, null, 8, ["alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyati", "satis-fiyati", "kargo-fiyat", "komisyon-oran", "fiyat-tipi", "deger", "kar-marji", "indirim-tipi", "indirim", "alis-doviz", "satis-doviz", "maliyet"]),
    _createVNode(_component_NFormGrid, {
      cols: "1fr",
      title: "CicekSepeti Özellikleri"
    }, {
      default: _withCtx(() => [
        (_ctx.cicekSepetiRef && _ctx.cicekSepetiRef.kategori && _ctx.cicekSepetiRef.kategori && _ctx.cicekSepetiRef.kategori.id  > 0)
          ? (_openBlock(), _createBlock(_component_CicekSepetiOzellikEditor, {
              key: 0,
              stok: _ctx.stokRef,
              cols: "1fr 1fr",
              "kategori-id": _ctx.cicekSepetiRef.kategori.id,
              "onUpdate:kategoriId": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.cicekSepetiRef.kategori.id) = $event)),
              ozellikler: _ctx.cicekSepetiRef.cicekSepetiOzellikler,
              "onUpdate:ozellikler": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.cicekSepetiRef.cicekSepetiOzellikler) = $event))
            }, null, 8, ["stok", "kategori-id", "ozellikler"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 16))
}