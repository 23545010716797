
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import N11OzellikEditor from "@/modules/Pazaryerleri/N11/components/N11OzellikEditor.vue";
import NDropDownTreeView from "@/components/DropDownTreeView/NDropDownTreeView.vue";
import N11ShipmentTemplateSelectBox from "@/modules/Pazaryerleri/N11/components/N11ShipmentTemplateSelectBox.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import { N11CategoryItem } from "@/api/models/N11CategoryItem";
import { useN11CategoryService } from "@/api/services/N11CategoryService";
import StokPazaryeriFiyatItem from "@/modules/Stok/components/StokPazaryeriFiyatItem.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import PazaryeriFiyatItem from "@/modules/Pazaryerleri/components/PazaryeriFiyatItem.vue";
import { N11PazaryeriItem } from "@/api/models/N11PazaryeriItem";
import NRefresher from "@/components/Refresher/NRefresher.vue";
import {useN11CategoryServiceCache} from "@/api/servicesCache/N11CategoryServiceCache";
import NInputHtml from "@/components/Input/NInputHtml.vue";
import N11SearchDialog from "@/modules/Stok/dialogs/N11SearchDialog.vue";
import NInput from "@/components/Input/NInput.vue";

export default defineComponent({
  components: {
    NInput,
    N11SearchDialog, NInputHtml, NRefresher, PazaryeriFiyatItem, NFormGrid, N11ShipmentTemplateSelectBox, NDropDownTreeView, N11OzellikEditor },
  props: [
    'n11',
    'stok',
    'alisKdv',
    'alisKdvDahil',
    'satisKdv',
    'satisKdvDahil',
    'alisFiyat',
    'satisFiyat',
    'index'
  ],
  setup(props,{emit}) {

    const localState = reactive({
        n11Ref:computed(() => props.n11 as N11PazaryeriItem),
        stokRef:computed(()=> props.stok),
        kategoriler:ref([] as N11CategoryItem[]),

      alisKdvRef:computed({get:()=>props.alisKdv, set:(val)=> emit('update:alisKdv',val) }),
      alisKdvDahilRef:computed({get:()=>props.alisKdvDahil, set:(val)=> emit('update:alisKdvDahil',val) }),
      satisKdvRef:computed({get:()=>props.satisKdv, set:(val)=> emit('update:satisKdv',val) }),
      satisKdvDahilRef:computed({get:()=>props.satisKdvDahil, set:(val)=> emit('update:satisKdvDahil',val) }),
      alisFiyatRef:computed({get:()=>props.alisFiyat, set:(val)=> emit('update:alisFiyat',val) }),
      satisFiyatRef:computed({get:()=>props.satisFiyat, set:(val)=> emit('update:satisFiyat',val) }),
      showSearch:ref(false)
    });

    async function load(){
      const response = await useN11CategoryServiceCache().getN11KategoriGetirTumu();
      localState.kategoriler = response.categories;
    }

    function addNew() {
      if (confirm('Yeni N11 sekmesi eklemek istediğinize emin misiniz?'))
        if (localState.stokRef.n11) {
          const clone = JSON.parse(JSON.stringify(localState.stokRef.n11[0]));
          localState.stokRef.n11.push(clone);
        }
    }

    function remove(index: number) {
      if (confirm('N11 sekmesini silmek istiyor musunuz?'))
        if (localState.stokRef?.n11) {
          localState.stokRef.n11.splice(index, 1)
        }
    }

    load()
    return {
      ...toRefs(localState),
      load,
      addNew,
      remove
    };
  }
});

