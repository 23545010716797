
import {computed, defineComponent, reactive, ref, toRefs} from "vue";

import NGrid from "@/components/Grid/NGrid.vue";

export default defineComponent({
  components: {  },
  props: {
    dataSource:{
      type:Object
    },
    selectedItem:{
      type:Object
    },
    displayExpr:{
      type:String,
      default:'name'
    },
    valueExpr:{
      type:String,
      default:'id'
    }

  },

  setup(props,{emit}) {

    const localState = reactive({
      opened:ref(false),
      selectedItemRef:computed({
          get:()=> props.selectedItem,
          set:(val)=>  emit('update:selectedItem',val)
      })
    });


    function load() {
        localState.selectedItemRef = props.selectedItem;
    }

    load()

    function itemClick(e:any){
        //alert("item clikc")
        localState.opened = false;
       localState.selectedItemRef = e.itemData;
    }

    function getValue() {
      if (props.selectedItem)
        return  props.selectedItem[props.displayExpr]
      return ""
    }

    return {
      ...toRefs(localState),
      itemClick,
      getValue
    };
  }
});

