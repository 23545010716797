import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['n-sort-order-icon', `n-sort-order-icon--${_ctx.sortOrder}`])
      }, [
        _createVNode(_component_NIcon, {
          type: "chevron-up",
          size: "16px"
        }),
        _createVNode(_component_NIcon, {
          type: "chevron-down",
          size: "16px"
        })
      ], 2))
    : _createCommentVNode("", true)
}