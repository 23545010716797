import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {FirmaEntity} from '../models/FirmaEntity'
import {FaturaEntityView} from '../models/FaturaEntityView'
import {CariEntity} from '../models/CariEntity'
import {PlanVadelerItem} from '../models/PlanVadelerItem'
import {FaturaEntityDataSource} from '../models/FaturaEntityDataSource'
import {RecordStatus} from '../models/RecordStatus'
import {FaturaHareketTip} from '../models/FaturaHareketTip'
import {FaturaTip} from '../models/FaturaTip'
import {FaturaUstBilgiItem} from '../models/FaturaUstBilgiItem'
import {TeslimatAdresItem} from '../models/TeslimatAdresItem'
import {FaturaSatirItem} from '../models/FaturaSatirItem'
import {FaturaAltBilgiItem} from '../models/FaturaAltBilgiItem'
import {DocumentItem} from '../models/DocumentItem'
import {FaturaEFaturaItem} from '../models/FaturaEFaturaItem'
import {SmsMailKargoGonderItem} from '../models/SmsMailKargoGonderItem'
import {FaturaView} from '../models/FaturaView'
import {DataSourceLoadOptionsItem} from '../models/DataSourceLoadOptionsItem'
import {FaturaTopluEFaturaView} from '../models/FaturaTopluEFaturaView'
import {EFaturaSenaryoTip} from '../models/EFaturaSenaryoTip'
import {EFaturaTip} from '../models/EFaturaTip'
import {HizliEFaturaItemView} from '../models/HizliEFaturaItemView'

export const useFaturaService = defineStore({
id: 'FaturaService',
actions: {


	async postFaturaEntityDataSource(faturaEntityDataSource:FaturaEntityDataSource)
	{
		const { request, response } = useApi(`/api/fatura/FaturaEntityDataSource`,{ method:'POST', data:faturaEntityDataSource });
		await request();
		return response.value;
	},

	async getList(start:string,end:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/List/${shared.firm.id}/${shared.firm.period}/${start}/${end}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getIrsaliyeList(start:string,end:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/IrsaliyeList/${shared.firm.id}/${shared.firm.period}/${start}/${end}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getIptalListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/IptalListe/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getSilinenFaturaListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/SilinenFaturaListe/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getFaturaListeEntity(start:string,end:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/FaturaListeEntity/${shared.firm.id}/${shared.firm.period}/${start}/${end}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getFaturaListView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/FaturaListView/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetir(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/Getir/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postToEntity(faturaView:FaturaView,tip:number)
	{
		const { request, response } = useApi(`/api/fatura/ToEntity/${tip}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async getStokHareketToFaturaView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/StokHareketToFaturaView/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getPassiveToActive(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/PassiveToActive/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetirView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/GetirView/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postSevkListDataGrid(dataSourceLoadOptionsItem:DataSourceLoadOptionsItem)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/SevkListDataGrid/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:dataSourceLoadOptionsItem });
		await request();
		return response.value;
	},

	async postIrsaliyelerToFatura(liste:string[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/IrsaliyelerToFatura/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data: liste });
		await request();
		return response.value;
	},

	async putSatirBirlestir(liste:FaturaSatirItem[])
	{
		const { request, response } = useApi(`/api/fatura/SatirBirlestir`,{ method:'PUT', data:liste });
		await request();
		return response.value;
	},

	async postTopluEFaturaGonder(liste:FaturaTopluEFaturaView[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/TopluEFaturaGonder/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:liste });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/Sil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async putFaturaGuncelle(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/FaturaGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:faturaView });
		await request();
		return response.value;
	},

	async putEFaturaGuncelle(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/EFaturaGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:faturaView });
		await request();
		return response.value;
	},

	async postAlisFaturaEkle(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/AlisFaturaEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async postAlisIrsaliyeEkle(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/AlisIrsaliyeEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async postIrsaliyeliAlisFaturaEkle(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/IrsaliyeliAlisFaturaEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async postIrsaliyeliSatisFaturaEkle(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/IrsaliyeliSatisFaturaEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async postSatisFaturaEkle(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/SatisFaturaEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async postSatisIrsaliyeEkle(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/SatisIrsaliyeEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async postSatisKurFarki(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/SatisKurFarki/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async postSatisKonsinyeEkle(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/SatisKonsinyeEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async postAlisKurFarki(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/AlisKurFarki/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async postAlistanIade(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/AlistanIade/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async postSatistanIade(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/SatistanIade/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async getBaRapor(fiyat:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/BaRapor/${shared.firm.id}/${shared.firm.period}/${fiyat}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async putBirlestir(liste:FaturaView[],tip:number)
	{
		const { request, response } = useApi(`/api/fatura/Birlestir/${tip}`,{ method:'PUT', data:liste });
		await request();
		return response.value;
	},

	async getBsRapor(fiyat:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/BsRapor/${shared.firm.id}/${shared.firm.period}/${fiyat}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getRaporHareket(baslangic:string,bitis:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/RaporHareket/${shared.firm.id}/${shared.firm.period}/${baslangic}/${bitis}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postEfaturaGonder(hizliEFaturaItemView:HizliEFaturaItemView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/EfaturaGonder/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:hizliEFaturaItemView });
		await request();
		return response.value;
	},

	async postEtksizFaturaBelirleme(liste:string[],evrakNo:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/EtksizFaturaBelirleme/${shared.firm.id}/${shared.firm.period}/${evrakNo}`,{ method:'POST', data:liste });
		await request();
		return response.value;
	},

	async postSeriUpdate(liste:string[],satirId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/SeriUpdate/${shared.firm.id}/${shared.firm.period}/${satirId}`,{ method:'POST', data:liste });
		await request();
		return response.value;
	},

	async getOnlineEFaturaStatus(hash:string,hashId:string)
	{
		const { request, response } = useApi(`/api/fatura/OnlineEFaturaStatus/${hash}/${hashId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCariReportSatirlar(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/fatura/CariReportSatirlar/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getFaturaTopluEFaturaView()
	{
		const { request, response } = useApi(`/api/fatura/FaturaTopluEFaturaView`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
