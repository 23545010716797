
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from "vue";
import "devexpress-reporting/dx-webdocumentviewer";
import NGrid from "@/components/Grid/NGrid.vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import {ReportExportTypesList} from "@/api/models/ReportExportTypes";
import {MailView} from "@/api/models/MailView";
import MailSendDialog from "@/modules/Servisler/Mail/dialogs/MailSendDialog.vue";
import {MailActionStatusType} from "@/api/models/MailActionStatusType";
import {ServisGonderimTip} from "@/api/models/ServisGonderimTip";
import {useSharedStore} from "@/store";
import {useRaporEkraniService} from "@/api/services/RaporEkraniService";
import {useMailService} from "@/api/services/MailService";


export default defineComponent({
  components: {MailSendDialog,  NModalDialog, NGrid},
  props: {
    show: {
      type: Boolean
    },
    slientPrint: {
      type: Boolean
    },
    reportName: {
      type: String
    },
    modelValue: {
      type: Object
    },
    callBack: {
        type: Function
    },
    mailSendTo:{
      type:String,
      default: ''
    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      viewer: ref(),
      showModal: computed({
        get: () => props.show,
        set: (val) => emit('update:show', val)
      }),
      baseUrl:ref(),
      exportTypeList:ReportExportTypesList,
      src:ref(),
      mailView:ref({} as MailView),
      showMailDialog:ref(false),
      iframeRef:ref(),
      sharedStore:useSharedStore(),
      isDirectPrint:ref(false),
    });

    onMounted(async () => {
        await callBack()
        await load();
    })

    async function load() {

        localState.isDirectPrint = await useRaporEkraniService().getIsDirectPrint(props.reportName as string)
        localState.src = '/#/reportViewer/' + props.reportName + '/' + (localState.isDirectPrint)
        // console.log('localState.src', localState.src)
    }

    async function mailGonder() {
        // localState.mailView = {
        //     attachmentsBase64: [{dosya: '', dosyaAdi: props.reportName + '.pdf'}],
        //     gonderilecekTarih: new Date().toISOString(),
        //     emails: [],
        //     durum: MailActionStatusType.Ready,
        //     attachments: [],
        //     gonderimTarihi: "0001-01-01T02:00:00",
        //     tip: ServisGonderimTip.Standart,
        // } as MailView

        localState.mailView = await useMailService().postGetMailViewFromReport(props.modelValue, props.reportName as string)

        localState.showMailDialog = true
    }

    async function callBack() {
        if (props.callBack)
            await props.callBack()
    }
    function exportPdf(mailId:string) {
        //if (mailId)
          // localState.iframeRef.contentWindow.postMessage('exportPdf:' + mailId + ':' + localState.sharedStore.firm.id);
    }

    return {
      ...toRefs(localState),
      print,
      mailGonder,
      exportPdf
    };
  }
});

