
export enum SacUretimSatirTip{
Sicak_Kesim=10,
Soguk_Kesim=20,
Sicak_Dilme=30,
Soguk_Dilme=40
}

export const SacUretimSatirTipList = [
{key:'Sicak_Kesim', value:10, description:'Sıcak Kesim'},
{key:'Soguk_Kesim', value:20, description:'Soğuk Kesim'},
{key:'Sicak_Dilme', value:30, description:'Sıcak Dilme'},
{key:'Soguk_Dilme', value:40, description:'Soğuk Dilme'}
]
