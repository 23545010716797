import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, vShow as _vShow, mergeProps as _mergeProps, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NCheckbox = _resolveComponent("NCheckbox")!
  const _component_NIcon = _resolveComponent("NIcon")!

  return _withDirectives((_openBlock(), _createElementBlock("span", _mergeProps(_ctx.$attrs, {
    class: [ 'n-tag', _ctx.type && `n-tag--${_ctx.type}`, _ctx.filled && `n-tag--${_ctx.type}--filled`]
  }), [
    (_ctx.model !== undefined)
      ? (_openBlock(), _createBlock(_component_NCheckbox, {
          key: 0,
          modelValue: _ctx.model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
          onToggle: _ctx.toggle
        }, null, 8, ["modelValue", "onToggle"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.closable)
      ? (_openBlock(), _createBlock(_component_NIcon, {
          key: 1,
          size: "14px",
          type: "x",
          stroke: "#AAA",
          class: "n-tag-close",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.close()))
        }))
      : _createCommentVNode("", true)
  ], 16)), [
    [_vShow, _ctx.show]
  ])
}