import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock("form", {
    class: "form",
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.login()), ["prevent"]))
  }, [
    _createVNode(_component_NInput, {
      "field-name": "username",
      "prefix-icon": "mail",
      "label-top": "E-Posta",
      placeholder: "mail@alanadiniz.com",
      status: _ctx.usernameError ? 'danger' : 'neutral',
      message: _ctx.usernameError ?? '',
      disabled: _ctx.isLoading,
      modelValue: _ctx.username,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event))
    }, null, 8, ["status", "message", "disabled", "modelValue"]),
    _createVNode(_component_NInput, {
      "field-name": "password",
      type: "password",
      "prefix-icon": "lock",
      "label-top": "Şifre",
      placeholder: "••••••••",
      status: _ctx.pwdError ? 'danger' : 'neutral',
      message: _ctx.pwdError ?? '',
      disabled: _ctx.isLoading,
      modelValue: _ctx.password,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event))
    }, null, 8, ["status", "message", "disabled", "modelValue"]),
    (_ctx.isCode)
      ? (_openBlock(), _createBlock(_component_NInput, {
          key: 0,
          "field-name": "code",
          type: "password",
          "prefix-icon": "lock",
          "label-top": "Kod (Yöneticiden isteyiniz)",
          placeholder: "••••••••",
          status: _ctx.codeError ? 'danger' : 'neutral',
          message: _ctx.codeError ?? '',
          disabled: _ctx.isLoading,
          modelValue: _ctx.code,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.code) = $event))
        }, null, 8, ["status", "message", "disabled", "modelValue"]))
      : _createCommentVNode("", true),
    _createVNode(_component_NButton, {
      type: "primary",
      loading: _ctx.isLoading,
      isSubmit: ""
    }, {
      default: _withCtx(() => [
        _createTextVNode("Giriş")
      ]),
      _: 1
    }, 8, ["loading"])
  ], 32))
}