
import {computed, defineComponent, onActivated, reactive, ref, toRefs} from "vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import NButton from "@/components/Button/NButton.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import {useStokStore} from "@/modules/Stok/store/StokStore";
import NAlert from "@/components/Alert/NAlert.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import {useStokService} from "@/api/services/StokService";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import {useFileSettingsServiceCache} from "@/api/servicesCache/FileSettingsServiceCache";
import NTag from "@/components/Tag/NTag.vue";
import {DevExtremeUtils} from "@/devextreme/DevExtremeUtils";

export default defineComponent({
  components: {NTag, NLoadingPanel, NFormGrid, NAlert, NFormField, NButton, NModalDialog},
  props:['show','stokIds'],
  setup(props,{emit}) {
    const localState = reactive({
      dataGrid:ref(),
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val)
      }),
      model:ref({stokIds:[]} as any),
      stokStore:useStokStore(),
      hizliSatisGruplari:ref([]),
      loaded:ref(false),
      serverUrl:ref()


    })

    async function load(){
      localState.serverUrl = await useFileSettingsServiceCache().getServerUrl();
      localState.hizliSatisGruplari = await useStokService().getStokHizliSatisNames();
      if (props.stokIds && props.stokIds.length > 0){
        localState.model.stokIds= props.stokIds;
      }

    }

    async function save(){
      await useStokService().postStokHizliSatisGuncelle(localState.model)
      localState.hizliSatisGruplari = await useStokService().getStokHizliSatisNames();
    }
      onActivated(() => {
          DevExtremeUtils.updateScroll(localState.dataGrid);
      });


    return ({
      ...toRefs(localState),
      load,
      save
    })
  }
})
