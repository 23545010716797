
export enum StokMalzemeSekilTip{
Ozel=0,
LamaCubuk=1,
PlakaLevha=2,
KalinPlakaLevha=3,
RuloLevha=4,
KareCubuk=5,
YuvarlakCubuk=6,
AltigenCubuk=7,
KareKutuProfil=8,
DikdortgenKutuProfil=9,
LKosebent=10,
TProfil=11,
UProfil=12,
BoruProfil=13,
CProfil=14,
OmegaProfil=15,
ZProfil=16,
Profil=17,
BoyaliProfil=18,
BoyaliBoru=19
}

export const StokMalzemeSekilTipList = [
{key:'Ozel', value:0, description:'Özel'},
{key:'LamaCubuk', value:1, description:'Lama Çubuk'},
{key:'PlakaLevha', value:2, description:'Plaka Levha'},
{key:'KalinPlakaLevha', value:3, description:'Kalın Plaka Levha'},
{key:'RuloLevha', value:4, description:'Rulo Levha'},
{key:'KareCubuk', value:5, description:'Kare Çubuk'},
{key:'YuvarlakCubuk', value:6, description:'Yuvarlak Çubuk'},
{key:'AltigenCubuk', value:7, description:'Altıgen Çubuk'},
{key:'KareKutuProfil', value:8, description:'Kare Kutu Profil'},
{key:'DikdortgenKutuProfil', value:9, description:'Dikdörtgen Kutu Profil'},
{key:'LKosebent', value:10, description:'L Köşebent'},
{key:'TProfil', value:11, description:'T Profil'},
{key:'UProfil', value:12, description:'U Profil'},
{key:'BoruProfil', value:13, description:'Boru'},
{key:'CProfil', value:14, description:'C Profil'},
{key:'OmegaProfil', value:15, description:'Omega Profil'},
{key:'ZProfil', value:16, description:'Z Profil'},
{key:'Profil', value:17, description:'Profil'},
{key:'BoyaliProfil', value:18, description:'Boyalı Profil'},
{key:'BoyaliBoru', value:19, description:'Boyalı Boru'}
]
