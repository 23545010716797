import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {BaBsView} from '../models/BaBsView'
import {MutabakatTip} from '../models/MutabakatTip'

export const useCariMutabakatService = defineStore({
id: 'CariMutabakatService',
actions: {


	async postCariBaBsMailRapor(liste:BaBsView[],tip:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CariMutabakat/CariBaBsMailRapor/${shared.firm.id}/${shared.firm.period}/${tip}`,{ method:'POST', data:liste });
		await request();
		return response.value;
	},

	async getBaBsGetAll()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CariMutabakat/BaBsGetAll/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getBaBsGetMutabakatDurum(hash:string)
	{
		const { request, response } = useApi(`/api/CariMutabakat/BaBsGetMutabakatDurum/${hash}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getBaBsSaveMutabakat(hash:string,mutabakatCevabi:boolean)
	{
		const { request, response } = useApi(`/api/CariMutabakat/BaBsSaveMutabakat/${hash}/${mutabakatCevabi}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
