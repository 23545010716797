import { NModuleRoute } from "@/models/route.model";

export const UretimOnlineModuleRoutes: NModuleRoute[] = [
  {
    id: "UretimOnlineModule",
    order: 12,
    displayName: "Üretim Online",
    requiredRoles:{
      readRoles:[],
      insertRoles:[],
      updateRoles:[],
      deleteRoles:[]
    },
    icon: {
      type: "archive"
    },
    children: [
      {
        id: "UretimOnlineEkstre",
        order: 0,
        displayName: "Ekstre",
        requiredRoles:{
          readRoles:['Uretim.Online'],
          insertRoles:['Uretim.Online'],
          updateRoles:['Uretim.Online'],
          deleteRoles:['Uretim.Online']
        },
        icon: {
          type: "archive"
        },
        component: () =>
          import(/* webpackChunkName: "UretimStokListesi"*/ "../pages/UretimOnlineEkstreListPage.vue")
      },
      {
        id: "UretimOnlineStoklar",
        order: 0,
        displayName: "Stoklar",
        requiredRoles:{
          readRoles:['Uretim.Online'],
          insertRoles:['Uretim.Online'],
          updateRoles:['Uretim.Online'],
          deleteRoles:['Uretim.Online']
        },
        icon: {
          type: "package"
        },
        component: () =>
          import(/* webpackChunkName: "UretimStokListesi"*/ "../pages/UretimOnlineStokListPage.vue")
      },
      {
        id: "UretimOnlineStokListesi",
        order: 0,
        displayName: "Stok Listesi",
        requiredRoles:{
          readRoles:['Uretim.Online.StokListesi'],
          insertRoles:['Uretim.Online.StokListesi','Uretim.Online.Sayim'],
          updateRoles:['Uretim.Online.StokListesi'],
          deleteRoles:['Uretim.Online.StokListesi']
        },
        icon: {
          type: "package"
        },
        component: () =>
            import(/* webpackChunkName: "UretimStokListesi"*/ "../pages/UretimOnlineStok2ListPage.vue")
      }
    ]
  }
];
