
export enum EFaturaGeneralStatusType{
None=0,
Waiting=1,
Warning=2,
Completed=3,
Error=4,
InProgress=5,
Reporting=6,
Rejected=7,
Cancelled=8
}

export const EFaturaGeneralStatusTypeList = [
{key:'None', value:0, description:'Yeni Kayıt'},
{key:'Waiting', value:1, description:'Onay Bekliyor'},
{key:'Warning', value:2, description:'Uyarı'},
{key:'Completed', value:3, description:'Tamamlandı'},
{key:'Error', value:4, description:'Hata Oluştu'},
{key:'InProgress', value:5, description:'İşleniyor'},
{key:'Reporting', value:6, description:'Raporlanacak'},
{key:'Rejected', value:7, description:'Ret Edildi'},
{key:'Cancelled', value:8, description:'İptal Edildi'}
]
