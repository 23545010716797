
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import {Ozellik} from "@/api/models/Ozellik";
import {useStokKategoriService} from "@/api/services/StokKategoriService";


export default defineComponent({
  components: {  },
  props: {
    ozellik:{
      type:Object
    },
    kategoriId:{
      type:String
    },
    isStok:{
      type:Boolean
    }
  },
  setup(props,{emit}) {

    const localState = reactive({
      showOzellikForm: ref(false),
      ozellikRef:computed(()=> props.ozellik as Ozellik),
      values:ref()
    });


    async function load(){
      if (props.isStok){
        if (props.kategoriId && props.ozellik && props.ozellik.name)
          localState.values = await useStokKategoriService().getOzellikValues(props.kategoriId as string, props.ozellik.name as string);
      } else{
        localState.values = localState.ozellikRef.values;
      }
    }



    load()

    function openOzellikForm(ozellikId:string) {
      emit('openOzellikForm',ozellikId)
    }

    return {
      ...toRefs(localState),
      openOzellikForm,
      load
    };
  }
});

