
import { defineComponent } from "vue";

export default defineComponent({
  name: "NDropdownItem",
  props: {
    disabled: Boolean,
    divided: Boolean,
    icon: String,
    type: String,
    padding: {
      type: String,
      default: "5px 8px"
    }
  }
});
