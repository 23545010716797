import {Doviz, DovizList} from "@/api/models/Doviz";
import {PazaryeriFiyatTipi} from "@/api/models/PazaryeriFiyatTipi";
import {useKurCachedServis} from "@/modules/Ortak/services/useKurCachedServis";
import {KurView} from "@/api/models/KurView";

export const FiyatUtils = {
    getPazaryeriFiyat: (kurlar:KurView[], fiyatTipi:PazaryeriFiyatTipi, deger:number, satisDoviz:Doviz, satisFiyati:number, satisKdv:number, satisKdvDahil:boolean, alisDoviz:Doviz, alisFiyati:number, alisKdv:number, alisKdvDahil:boolean, maliyet:number, karMarji:number,kargoFiyat:number,komisyon:number)=> {
        if (fiyatTipi == PazaryeriFiyatTipi.Fiyat)
            return deger;
        const kargoFiyatCalculate = ( FiyatUtils.getKargoFiyat(kurlar,kargoFiyat,fiyatTipi,satisDoviz,alisDoviz));
        const kdvDahilAlisFiyat = FiyatUtils.getKdvDahilFiyat(alisFiyati,alisKdv,alisKdvDahil);
        const kdvDahilMaliyet = FiyatUtils.getKdvDahilFiyat(maliyet,alisKdv,false);
        const kdvDahilSatisFiyat = FiyatUtils.getKdvDahilFiyat(satisFiyati,satisKdv,satisKdvDahil);
        const carpanKar = FiyatUtils.carpan(karMarji);
        const carpanDeger = FiyatUtils.carpan(deger);
        const bolen =  FiyatUtils.bolen(komisyon);
        switch (fiyatTipi) {
            case PazaryeriFiyatTipi.Otomatik:
                return (((kdvDahilAlisFiyat) * carpanKar) + kargoFiyatCalculate)  / bolen;
            case PazaryeriFiyatTipi.MaliyetAlisOran:
                return (((Math.max(kdvDahilAlisFiyat, kdvDahilMaliyet) * carpanKar) + kargoFiyatCalculate)  / bolen);
            case PazaryeriFiyatTipi.Oran:
                return (((kdvDahilSatisFiyat * carpanDeger) + kargoFiyatCalculate) / bolen);
            case PazaryeriFiyatTipi.Tutar:
                return (((kdvDahilSatisFiyat + deger) + kargoFiyatCalculate) / bolen);

                return 0
        }
    },
    kdvLiFiyat: (fiyat: number, kdv: number) => {
        return ((100 + kdv) / 100) * fiyat;
    },
    getKdvDahilFiyat: (alisFiyati: number, alisKdv: number, alisKdvDahil: boolean) => {
        if (alisKdvDahil === true)
            return alisFiyati;
            return FiyatUtils.kdvLiFiyat(alisFiyati, alisKdv as number)

    },
    carpan: (oran: number) => {
        return (100 + oran) / 100;
    },
    bolen: (oran: number) => {
        return (100 - oran) / 100
    },

    getKargoFiyat:  (kurlar:KurView[], kargo: number, fiyatTipi:PazaryeriFiyatTipi,satisDoviz:Doviz,alisDoviz:Doviz) => {
        const doviz = FiyatUtils.getDoviz(fiyatTipi,satisDoviz,alisDoviz);
        if (doviz != Doviz.TRY) {
            const kur = FiyatUtils.getKur(kurlar, fiyatTipi,satisDoviz,alisDoviz);
            if (kur && kur != 0)
                return kargo / kur;
        }
        return kargo;
    },

    getIndirimsizFiyat: (kurlar:KurView[], indirimTipi:PazaryeriFiyatTipi, indirimDeger:number, fiyatTipi:PazaryeriFiyatTipi, deger:number, satisDoviz:Doviz, satisFiyati:number, satisKdv:number, satisKdvDahil:boolean, alisDoviz:Doviz, alisFiyati:number, alisKdv:number, alisKdvDahil:boolean, karMarji:number,kargoFiyat:number,komisyon:number) => {
        const pazaryeriFiyat =   FiyatUtils.getPazaryeriFiyat(kurlar, fiyatTipi, deger, satisDoviz, satisFiyati, satisKdv, satisKdvDahil, alisDoviz, alisFiyati, alisKdv, alisKdvDahil, alisFiyati, karMarji,kargoFiyat,komisyon);

        switch (indirimTipi) {
            case PazaryeriFiyatTipi.Otomatik:
                return 0;
            case PazaryeriFiyatTipi.Oran:
                return pazaryeriFiyat * ((indirimDeger + 100) / 100);
            case PazaryeriFiyatTipi.Fiyat:
                return indirimDeger;
            case PazaryeriFiyatTipi.Tutar:
                return pazaryeriFiyat + indirimDeger;
        }

        return 0;
    },

    getIndirimOran:  (kurlar:KurView[],
                      indirimTipi:PazaryeriFiyatTipi,
                      indirimDeger:number,
                      fiyatTipi:PazaryeriFiyatTipi,
                      deger:number,
                      satisDoviz:Doviz, satisFiyati:number, satisKdv:number, satisKdvDahil:boolean,
                      alisDoviz:Doviz, alisFiyati:number, alisKdv:number, alisKdvDahil:boolean,
                      karMarji:number, kargoFiyat:number, komisyon:number) => {

        const indirimsizFiyat =  FiyatUtils.getIndirimsizFiyat(kurlar,
            indirimTipi,
            indirimDeger,
            fiyatTipi,deger,
            satisDoviz, satisFiyati, satisKdv, satisKdvDahil,
            alisDoviz, alisFiyati, alisKdv, alisKdvDahil,
            karMarji,kargoFiyat,komisyon);

        const pazaryeriFiyat =   FiyatUtils.getPazaryeriFiyat(
            kurlar,fiyatTipi, deger,
            satisDoviz, satisFiyati, satisKdv, satisKdvDahil,
            alisDoviz, alisFiyati, alisKdv, alisKdvDahil,
            alisFiyati,
            karMarji,kargoFiyat,komisyon);
        const oran = (indirimsizFiyat / pazaryeriFiyat * 100) - 100;
        return oran;
    },
    getDoviz: (fiyatTipi:PazaryeriFiyatTipi,satisDoviz:Doviz,alisDoviz:Doviz) => {
        if (fiyatTipi == PazaryeriFiyatTipi.Otomatik)
            return alisDoviz
        return satisDoviz;
    },
    getDovizAd: (fiyatTipi:PazaryeriFiyatTipi,satisDoviz:Doviz,alisDoviz:Doviz) => {
        const doviz = FiyatUtils.getDoviz(fiyatTipi,satisDoviz,alisDoviz);
        return DovizList.find(a => a.value == doviz)?.description
    },
     getKur: (kurlar:KurView[], fiyatTipi:PazaryeriFiyatTipi,satisDoviz:Doviz,alisDoviz:Doviz) => {

        const doviz: Doviz = FiyatUtils.getDoviz(fiyatTipi,satisDoviz,alisDoviz);

        if (isNaN(doviz))
            return 0

        if (doviz == Doviz.TRY)
            return 1;

        const kurItem = kurlar.find(a => a.doviz == doviz)

        if (!kurItem)
            return 1;

        return kurItem?.alis;
    }
}
