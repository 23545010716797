import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {PlasiyerView} from '../models/PlasiyerView'

export const usePlasiyerService = defineStore({
id: 'PlasiyerService',
actions: {


	async getDefault()
	{
		const { request, response } = useApi(`/api/plasiyer/default`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetir(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/plasiyer/Getir/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/plasiyer/Liste/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getIdNameListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/plasiyer/IdNameListe/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postEkle(plasiyerView:PlasiyerView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/plasiyer/Ekle/${shared.firm.id}`,{ method:'POST', data:plasiyerView });
		await request();
		return response.value;
	},

	async putGuncelle(plasiyerView:PlasiyerView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/plasiyer/Guncelle/${shared.firm.id}`,{ method:'PUT', data:plasiyerView });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/plasiyer/Sil/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getUserPlasiyerList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/plasiyer/UserPlasiyerList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
