
import {defineComponent, reactive, ref, toRefs} from "vue";
import HizliSatisStokListesi from "@/modules/HizliSatis/components/HizliSatisStokListesi.vue";
import HizliSatisIadeListesi from "@/modules/HizliSatis/components/HizliSatisIadeListesi.vue";

export default defineComponent({
  components: {HizliSatisIadeListesi, HizliSatisStokListesi},
  setup() {
    const localState = reactive({
      selectedTab:ref(0),
      tabs:[
        {id:0,label:"Stoklar"},
        {id:1,label:"İade"}
      ]
    })




    return {
      ...toRefs(localState)
    }
  }
})
