import {
  BankaHareketTip,
  BankaHareketTipList
} from "@/api/models/BankaHareketTip";
import { GenelResmiMuhasebeTip } from "@/api/models/GenelResmiMuhasebeTip";
import { Doviz } from "@/api/models/Doviz";
import { BankaHareketView } from "@/api/models/BankaHareketView";
import { useBankaHareketService } from "@/api/services/BankaHareketService";
import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import { CariHareketTip } from "@/api/models/CariHareketTip";
import { CariMenuListesi } from "@/modules/Cari/models/CariMenuListesi";
import { BankaMenuListesi } from "@/modules/Banka/models/BankaMenuListesi";
import { ref } from "vue";
import { useEvrakNoService } from "@/api/services/EvrakNoService";
import { alert } from "devextreme/ui/dialog";

export const BankaUtils = {
  getEnumDescription(tip: BankaHareketTip) {
    const result = BankaHareketTipList.find(a => a.value === tip);
    if (result) return result.description;
  },
  getColumns() {
    return [
      {
        label: "Durum",
        prop: "queueDescription",
        visible: true,
        width: "80px"
      },
      { label: "Açıklama", prop: "aciklama", visible: true, width: "80px" },
      { label: "Tarih", prop: "tarih", visible: true, width: "80px" },
      { label: "Tip", prop: "tip", visible: true, width: "auto" },
      { label: "Evrak No", prop: "evrakNo", visible: true, width: "80px" },
      {
        label: "Evrak Tipi",
        prop: "genelResmiMuhasebeTip",
        visible: true,
        width: "80px"
      },
      { label: "Şube", prop: "subeId", visible: true, width: "80px" },
      { label: "İşlem", prop: "islem", visible: true, width: "150px" },
      {
        label: "Yerel Tutar",
        prop: "yerelTutar",
        visible: true,
        width: "110px"
      }
    ];
  },
  async getBankaHareketView(bankaId: string, hareketId: string, tutar: number) {
    if (hareketId && hareketId.length > 0) {
      return await useBankaHareketService().getHareketView(hareketId);
    } else {
      const evrakNo = await useEvrakNoService().getYeniEvrakNo(10) as string;

      return {
        dokumanlar: [],
        bankaId: bankaId,
        evrakNo: evrakNo,
        vade: new Date().toISOString(),
        tarih: new Date().toISOString(),
        genelResmiMuhasebeTip: GenelResmiMuhasebeTip.Genel,
        kdv: 0,
        kdvTutar: 0,
        hesaplama: {
          islemDoviz: Doviz.TRY,
          islemKur: 1,
          islenecekDoviz: Doviz.TRY,
          islenecekKur: 1,
          islemYerelTutar: 0,
          islemTutar: tutar,
          islenecekTutar: 0,
          islenecekYerelTutar: 0
        },
        faizTutar: 0
      } as BankaHareketView;
    }
  },
  async dekontKaydet(menu: MenuModel, model: BankaHareketView) {
    if (model.id && model.id.length > 0) {
      switch (menu.hareketTipi) {
        case BankaHareketTip.BankadanBankaKredisiOdeme :
          await useBankaHareketService().putKrediOdemesiGuncelle(model);
          break;
        case BankaHareketTip.BankadanMasrafOdeme:
          await useBankaHareketService().putBankadanMasrafOdemeGuncelle(model);
          break;
        case BankaHareketTip.BankayaVirmanOdeme:
          await useBankaHareketService().putBankayaVirmanOdemeGuncelle(model);
          break;
        case BankaHareketTip.BankadanFirmaKkOdeme:
          await useBankaHareketService().putBankadanFirmaKkOdemeGuncelle(model);
          break;
        case BankaHareketTip.BankaCikisFisi:
          await useBankaHareketService().putBankaCikisFisiGuncelle(model);
          break;
        case BankaHareketTip.BankaCikanDevir:
          await useBankaHareketService().putBankaCikanDevirGuncelle(model);
          break;
        case BankaHareketTip.BankadanVirmanTahsilati:
          await useBankaHareketService().putBankadanVirmanTahsilatiGuncelle(model);
          break;
        case BankaHareketTip.BankayaHizmetTahsilati:
          await useBankaHareketService().putBankayaHizmetTahsilatiGuncelle(model);
          break;
        case BankaHareketTip.BankaGirisFisi:
          await useBankaHareketService().putBankaGirisFisiGuncelle(model);
          break;
        case BankaHareketTip.BankaGirenDevir:
          await useBankaHareketService().putBankaGirenDevirGuncelle(model);
          break;
        case BankaHareketTip.BankayaFirmaKkParaCekme:
          await useBankaHareketService().putBankayaFirmaKkParaCekmeGuncelle(model);
          break;

      }
    } else {
      switch (menu.hareketTipi) {
        case BankaHareketTip.BankadanBankaKredisiOdeme :
          await useBankaHareketService().postKrediOdemesi(model);
          break;
        case BankaHareketTip.BankadanMasrafOdeme:
          await useBankaHareketService().postBankadanMasrafOdeme(model);
          break;
        case BankaHareketTip.BankayaVirmanOdeme:
          await useBankaHareketService().postBankayaVirmanOdeme(model);
          break;
        case BankaHareketTip.BankadanFirmaKkOdeme:
          await useBankaHareketService().postBankadanFirmaKkOdeme(model);
          break;
        case BankaHareketTip.BankaCikisFisi:
          await useBankaHareketService().postBankaCikisFisi(model);
          break;
        case BankaHareketTip.BankaCikanDevir:
          await useBankaHareketService().postBankaCikanDevir(model);
          break;
        case BankaHareketTip.BankadanVirmanTahsilati:
          await useBankaHareketService().postBankadanVirmanTahsilati(model);
          break;
        case BankaHareketTip.BankayaHizmetTahsilati:
          await useBankaHareketService().postBankayaHizmetTahsilati(model);
          break;
        case BankaHareketTip.BankaGirisFisi:
          await useBankaHareketService().postBankaGirisFisi(model);
          break;
        case BankaHareketTip.BankaGirenDevir:
          await useBankaHareketService().postBankaGirenDevir(model);
          break;
        case BankaHareketTip.BankayaFirmaKkParaCekme:
          await useBankaHareketService().postBankayaFirmaKkParaCekme(model);
          break;

      }
    }
  },
  getMenuFromHareket(tip: BankaHareketTip) {
    if (tip == BankaHareketTip.KrediFaiziOdeme) {
      alert("Bağlı Olduğu Kredi Ödemesi İşleminden Güncelleme veya Silme Yapabilirsiniz", "Hata");
      return;
    }

    for (let i = 0; i < BankaMenuListesi.length; i++) {
      const mainMenu = BankaMenuListesi[i];
      if (mainMenu.hareketTipi == tip) {
        return mainMenu;
      } else {
        if (mainMenu.childs && mainMenu.childs.length > 0) {
          for (let j = 0; j < mainMenu.childs.length; j++) {
            const subMenu = mainMenu.childs[j];
            if (subMenu.hareketTipi === tip) return subMenu;
          }
        }
      }
    }
  },

  getBankaListesi() {
    return [
      "Adabank A.Ş.",
      "Akbank T.A.Ş.",
      "Aktif Yatırım Bankası A.Ş.",
      "Alternatifbank A.Ş.",
      "Anadolubank A.Ş.",
      "Arap Türk Bankası A.Ş.",
      "Bank Mellat",
      "Bank of Tokyo-Mitsubishi UFJ Turkey A.Ş.",
      "BankPozitif Kredi ve Kalkınma Bankası A.Ş.",
      "Birleşik Fon Bankası A.Ş.",
      "Burgan Bank A.Ş.",
      "Citibank A.Ş.",
      "Denizbank A.Ş.",
      "Deutsche Bank A.Ş.",
      "Diler Yatırım Bankası A.Ş.",
      "Fibabanka A.Ş.",
      "Finans Bank A.Ş.",
      "GSD Yatırım Bankası A.Ş.",
      "Habib Bank Limited",
      "HSBC Bank A.Ş.",
      "ING Bank A.Ş.",
      "Intesa Sanpaolo S.p.A.",
      "İller Bankası A.Ş.",
      "İstanbul Takas ve Saklama Bankası A.Ş.",
      "JPMorgan Chase Bank N.A.",
      "Merrill Lynch Yatırım Bank A.Ş.",
      "Nurol Yatırım Bankası A.Ş.",
      "Odea Bank A.Ş.",
      "Pasha Yatırım Bankası A.Ş.",
      "Rabobank A.Ş.",
      "Societe Generale (SA)",
      "Standard Chartered Yatırım Bankası Türk A.Ş.",
      "Şekerbank T.A.Ş.",
      "Tekstil Bankası A.Ş.",
      "The Royal Bank of Scotland Plc.",
      "Turkish Bank A.Ş.",
      "Turkland Bank A.Ş.",
      "Türk Ekonomi Bankası A.Ş.",
      "Türk Eximbank",
      "Türkiye Cumhuriyeti Ziraat Bankası A.Ş.",
      "Türkiye Garanti Bankası A.Ş.",
      "Türkiye Halk Bankası A.Ş.",
      "Türkiye İş Bankası A.Ş.",
      "Türkiye Kalkınma Bankası A.Ş.",
      "Türkiye Sınai Kalkınma Bankası A.Ş.",
      "Türkiye Vakıflar Bankası T.A.O.",
      "Yapı ve Kredi Bankası A.Ş."
    ];
  }
};
