import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_OzellikFormDialog = _resolveComponent("OzellikFormDialog")!

  return (_openBlock(), _createElementBlock("div", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), [
    _createVNode(_component_NFormGrid, {
      title: _ctx.title,
      cols: "1fr 1fr",
      style: {"grid-column":"span 3","gap":"0"}
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ozelliklerRef, (ozellik) => {
          return (_openBlock(), _createElementBlock("div", {
            key: ozellik.id
          }, [
            _createVNode(_component_NFormField, {
              label: ozellik.name
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  disabled: true,
                  "field-name": ozellik.id,
                  dataset: ozellik.values,
                  modelValue: ozellik.value,
                  "onUpdate:modelValue": ($event: any) => ((ozellik.value) = $event),
                  placeholder: ozellik.values
                }, null, 8, ["field-name", "dataset", "modelValue", "onUpdate:modelValue", "placeholder"])
              ]),
              _: 2
            }, 1032, ["label"])
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["title"]),
    (_ctx.showOzellikForm)
      ? (_openBlock(), _createBlock(_component_OzellikFormDialog, {
          key: 0,
          show: _ctx.showOzellikForm,
          "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showOzellikForm) = $event)),
          ozellikler: _ctx.ozelliklerRef,
          "onUpdate:ozellikler": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ozelliklerRef) = $event)),
          "ozellik-id": _ctx.selectedOzellikId,
          "onUpdate:ozellikId": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedOzellikId) = $event))
        }, null, 8, ["show", "ozellikler", "ozellik-id"]))
      : _createCommentVNode("", true)
  ], 16))
}