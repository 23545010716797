import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {DocumentItem} from '../models/DocumentItem'
import {CekSenetItemView} from '../models/CekSenetItemView'
import {CekSenetView} from '../models/CekSenetView'

export const useCekSenetService = defineStore({
id: 'CekSenetService',
actions: {


	async getCekSenetReportView()
	{
		const { request, response } = useApi(`/api/cekSenet/CekSenetReportView`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getFirmaCekSenetReportView()
	{
		const { request, response } = useApi(`/api/cekSenet/FirmaCekSenetReportView`,{ method:'GET' });
		await request();
		return response.value;
	},

	async putCekSenetGuncelle(cekSenetView:CekSenetView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cekSenet/CekSenetGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:cekSenetView });
		await request();
		return response.value;
	},

	async deleteCekSenetSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cekSenet/CekSenetSil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postCekSenetEkle(cekSenetView:CekSenetView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cekSenet/CekSenetEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cekSenetView });
		await request();
		return response.value;
	},

	async getGirisView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cekSenet/GirisView/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cekSenet/List/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getFlatList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cekSenet/FlatList/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getFlatListId(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cekSenet/FlatListId/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getEntityByItemId(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cekSenet/EntityByItemId/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetir(cekSenetId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cekSenet/Getir/${shared.firm.id}/${shared.firm.period}/${cekSenetId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getRaporHareket(baslangic:string,bitis:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cekSenet/RaporHareket/${shared.firm.id}/${shared.firm.period}/${baslangic}/${bitis}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getMusteriCekSenetRapor(baslangic:string,bitis:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cekSenet/MusteriCekSenetRapor/${shared.firm.id}/${shared.firm.period}/${baslangic}/${bitis}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
