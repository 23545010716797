import mitt, { Handler } from 'mitt';
import { defineStore } from 'pinia';
import { useSignal } from '@/services/useSignal';
//signal document content
import {RecordStatus} from '../models/RecordStatus'
import {Doviz} from '../models/Doviz'
import {DocumentItem} from '../models/DocumentItem'
import {HizmetMasrafEntity} from '../models/HizmetMasrafEntity'
import {NameObject} from '../models/NameObject'

export const onHizmetMasrafHubManagerSignal = defineStore({
id: 'HizmetMasrafHubManagerSignal',
actions: {


	Added(handler:Handler<HizmetMasrafEntity>)
	{
		useSignal().on('HizmetMasrafHubManager.Added',handler);
	},

	Changed(handler:Handler<HizmetMasrafEntity>)
	{
		useSignal().on('HizmetMasrafHubManager.Changed',handler);
	},

	ChangedField(handler:Handler<NameObject>)
	{
		useSignal().on('HizmetMasrafHubManager.ChangedField',handler);
	},

	Changeds(handler:Handler<HizmetMasrafEntity>)
	{
		useSignal().on('HizmetMasrafHubManager.Changeds',handler);
	},

	Deleted(handler:Handler<string>)
	{
		useSignal().on('HizmetMasrafHubManager.Deleted',handler);
	}
}
});
