
import {computed, defineComponent, onMounted, reactive, ref, toRefs, watch} from "vue";
import NFormField from "@/components/FormField/NFormField.vue";
import {StokTip} from "@/api/models/StokTip";
import {StokView} from "@/api/models/StokView";
import DepoSelectBox from "@/modules/Ortak/components/DepoSelectBox.vue";
import CariSelectBox from "@/modules/Cari/components/CariSelectBox.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import {StokMalzemeSekilTip, StokMalzemeSekilTipList} from "@/api/models/StokMalzemeSekilTip";
import {StokOzellikTipDigerApi} from "@/modules/StokOzellik/utils/StokOzellikTip_DigerApi";
import {useStokSettingsStore} from "@/modules/Stok/store/StokSettingsStore";

export default defineComponent({
    components: {NGrid, CariSelectBox, DepoSelectBox, NFormField},
    props: {
        stok:{
            type:Object
        }
    },
    setup(props,{emit}) {

        const localState = reactive({
            stokRef:computed(()=> props.stok as StokView),
            tipler:[],
            malzemeSekilTipList: ref(StokMalzemeSekilTipList),
            malzemeSekilTip: StokMalzemeSekilTip,
            visibleModel: ref({} as StokMalzemeStandartTip),
            stokTipApi: StokOzellikTipDigerApi,
            cinsList:ref(['SOĞUK', 'SICAK', 'GZR', 'ASİTLİ'] as string[]),
            storeSettings:useStokSettingsStore(),
        });


        onMounted(() => {
            load();
        })

        watch(() => localState.stokRef.tipOzellikler?.malzemeSekilTip, (tip: any) => {
            localState.visibleModel = getVisibleModel() as StokMalzemeStandartTip
        })

        function load() {

            localState.malzemeSekilTipList = StokMalzemeSekilTipList?.filter(a =>
                a.value === StokMalzemeSekilTip.Profil ||
                a.value === StokMalzemeSekilTip.BoyaliProfil ||
                a.value === StokMalzemeSekilTip.BoruProfil ||
                a.value === StokMalzemeSekilTip.BoyaliBoru ||
                a.value === StokMalzemeSekilTip.CProfil ||
                a.value === StokMalzemeSekilTip.UProfil ||
                a.value === StokMalzemeSekilTip.OmegaProfil ||
                a.value === StokMalzemeSekilTip.ZProfil
            )

            const siraListesi = [StokMalzemeSekilTip.Profil,
                StokMalzemeSekilTip.BoyaliProfil,
                StokMalzemeSekilTip.BoruProfil,
                StokMalzemeSekilTip.BoyaliBoru,
                StokMalzemeSekilTip.CProfil,
                StokMalzemeSekilTip.UProfil,
                StokMalzemeSekilTip.OmegaProfil,
                StokMalzemeSekilTip.ZProfil];

            const sirayaGoreSiralama = (a:any, b:any) => {
                const aSira = siraListesi.indexOf(a.value);
                const bSira = siraListesi.indexOf(b.value);
                return aSira - bSira;
            }

            localState.malzemeSekilTipList.sort(sirayaGoreSiralama);

            const item = localState.malzemeSekilTipList.find(a => a.value == StokMalzemeSekilTip.BoruProfil) as any
            item.description = "Boru"

            localState.visibleModel = getVisibleModel()
            if (localState.stokRef.tipOzellikler && localState.stokRef.tipOzellikler.malzemeSekilTip == null)
                localState.stokRef.tipOzellikler.malzemeSekilTip = StokMalzemeSekilTip.Profil

        }
        function updateStokName() {

            localState.stokRef.tipOzellikler!.cins = localState.stokRef?.tipOzellikler?.cins?.toUpperCase()
            localState.stokRef.tipOzellikler!.kalite = localState.stokRef?.tipOzellikler?.kalite?.toUpperCase()

            if (localState.stokRef.tip === StokTip.SacProfil && isSacValid()) {
                let stokName = "";
                const cins = localState.stokRef.tipOzellikler?.cins?.toUpperCase()
                const kalite = localState.stokRef.tipOzellikler?.kalite?.toUpperCase()
                const tip = getTipName(localState.stokRef.tipOzellikler?.malzemeSekilTip as  StokMalzemeSekilTip)

                const genislik = localState.stokRef.tipOzellikler?.genislik
                const kalinlik = localState.stokRef.tipOzellikler?.kalinlik
                const uzunluk = localState.stokRef.tipOzellikler?.uzunluk
                const yukseklik = localState.stokRef.tipOzellikler?.yukseklik
                const cap = localState.stokRef.tipOzellikler?.cap
                const yanak = localState.stokRef.tipOzellikler?.yanak

                switch (localState.stokRef.tipOzellikler?.malzemeSekilTip as StokMalzemeSekilTip) {
                    case StokMalzemeSekilTip.Profil:
                    case StokMalzemeSekilTip.BoyaliProfil:
                    case StokMalzemeSekilTip.UProfil:
                        stokName = `${cins} ${kalite} ${tip} ${genislik} * ${yukseklik} * ${kalinlik} * ${uzunluk}`
                        break;
                    case StokMalzemeSekilTip.BoruProfil:
                    case StokMalzemeSekilTip.BoyaliBoru:
                        stokName = `${cins} ${kalite} ${tip} ${cap} * ${kalinlik} * ${uzunluk}`
                        break;
                    case StokMalzemeSekilTip.CProfil:
                    case StokMalzemeSekilTip.OmegaProfil:
                        stokName = `${cins} ${kalite} ${tip} ${genislik} * ${yukseklik} * ${yanak} * ${kalinlik} * ${uzunluk}`
                        break;
                    case StokMalzemeSekilTip.ZProfil:
                        stokName = `${cins} ${kalite} ${tip} ${genislik} * ${yukseklik} * ${kalinlik} * ${uzunluk}`
                        break;
                }
                localState.stokRef.ad = stokName;
            }
        }
        function isSacValid() {
            const cins = localState.stokRef.tipOzellikler?.cins?.toUpperCase()
            const kalite = localState.stokRef.tipOzellikler?.kalite?.toUpperCase()
            const tip = getTipName(localState.stokRef.tipOzellikler?.malzemeSekilTip as  StokMalzemeSekilTip)

            const genislik = Number(localState.stokRef.tipOzellikler?.genislik ?? 0)
            const kalinlik = Number(localState.stokRef.tipOzellikler?.kalinlik ?? 0)
            const uzunluk = Number(localState.stokRef.tipOzellikler?.uzunluk ?? 0)
            const yukseklik = Number(localState.stokRef.tipOzellikler?.yukseklik ?? 0)
            const cap = Number(localState.stokRef.tipOzellikler?.cap ?? 0)
            const yanak = Number(localState.stokRef.tipOzellikler?.yanak ?? 0)

            switch (localState.stokRef.tipOzellikler?.malzemeSekilTip as StokMalzemeSekilTip) {
                case StokMalzemeSekilTip.Profil:
                case StokMalzemeSekilTip.BoyaliProfil:
                case StokMalzemeSekilTip.UProfil:
                    return cins && kalite && tip && genislik > 0 && yukseklik > 0 && kalinlik > 0 && uzunluk > 0
                case StokMalzemeSekilTip.ZProfil:
                    return cins && kalite && tip && genislik > 0 && yukseklik > 0 && kalinlik > 0 && uzunluk > 0
                case StokMalzemeSekilTip.BoruProfil:
                case StokMalzemeSekilTip.BoyaliBoru:
                    return cins && kalite && tip && cap > 0 && kalinlik > 0 && uzunluk > 0
                case StokMalzemeSekilTip.CProfil:
                case StokMalzemeSekilTip.OmegaProfil:
                    return cins && kalite && tip && genislik > 0 && yukseklik > 0 && yanak > 0 && kalinlik > 0 && uzunluk > 0
            }
            return false;
        }

        class StokMalzemeStandartTip {
            labelKalinlik?: StokMalzemeStandartTipField
            labelGenislik?: StokMalzemeStandartTipField
            labelUzunluk?: StokMalzemeStandartTipField
            labelYukseklik?: StokMalzemeStandartTipField
            labelCap?: StokMalzemeStandartTipField
            labelYanak?: StokMalzemeStandartTipField
        }

        class StokMalzemeStandartTipField {
            name?: string;
            isVisible?: boolean;
        }

        function resetSizes() {
            const tip = localState.stokRef?.tipOzellikler?.malzemeSekilTip as StokMalzemeSekilTip;

            if (localState.stokRef.tipOzellikler) {

                switch (tip) {
                case StokMalzemeSekilTip.Profil:
                case StokMalzemeSekilTip.BoyaliProfil:
                case StokMalzemeSekilTip.UProfil:
                    localState.stokRef.tipOzellikler!.cap = 0
                    localState.stokRef.tipOzellikler!.yanak = 0
                    break
                case StokMalzemeSekilTip.ZProfil:
                    localState.stokRef.tipOzellikler!.uzunluk = 0
                    localState.stokRef.tipOzellikler!.cap = 0
                    localState.stokRef.tipOzellikler!.yanak = 0
                    break
                case StokMalzemeSekilTip.BoyaliBoru:
                case StokMalzemeSekilTip.BoruProfil:
                    localState.stokRef.tipOzellikler!.genislik = 0
                    localState.stokRef.tipOzellikler!.yukseklik = 0
                    localState.stokRef.tipOzellikler!.yanak = 0
                    break
                case StokMalzemeSekilTip.CProfil:
                case StokMalzemeSekilTip.OmegaProfil:
                    localState.stokRef.tipOzellikler!.cap = 0
                    break
                }

            }
            updateStokName()
        }

        function getVisibleModel(): StokMalzemeStandartTip {
            const tip = localState.stokRef?.tipOzellikler?.malzemeSekilTip as StokMalzemeSekilTip;
            let visibleModel = {
                labelGenislik: {name: 'Genişlik', isVisible: true},
                labelKalinlik: {name: 'Kalınlık', isVisible: true},
                labelYukseklik: {name: 'Yükseklik', isVisible: true},
                labelUzunluk: {name: 'Uzunluk', isVisible: true},
                labelCap: {name: 'Çap', isVisible: true},
                labelYanak: {name: 'Yanak', isVisible: true},
            }
            switch (tip) {
                // ----------------
                case StokMalzemeSekilTip.UProfil:
                case StokMalzemeSekilTip.Profil:
                case StokMalzemeSekilTip.BoyaliProfil:
                    visibleModel = {
                        labelGenislik: {name: 'Genişlik', isVisible: true},
                        labelKalinlik: {name: 'Kalınlık', isVisible: true},
                        labelYukseklik: {name: 'Yükseklik', isVisible: true},
                        labelUzunluk: {name: 'Uzunluk', isVisible: true},
                        labelCap: {name: '', isVisible: false},
                        labelYanak: {name: '', isVisible: false},
                    };
                    break;
                case StokMalzemeSekilTip.ZProfil:
                    visibleModel = {
                        labelGenislik: {name: 'Genişlik', isVisible: true},
                        labelKalinlik: {name: 'Kalınlık', isVisible: true},
                        labelYukseklik: {name: 'Yükseklik', isVisible: true},
                        labelUzunluk: {name: 'Uzunluk', isVisible: true},
                        labelCap: {name: '', isVisible: false},
                        labelYanak: {name: '', isVisible: false},
                    };
                    break;
                case StokMalzemeSekilTip.BoyaliBoru:
                case StokMalzemeSekilTip.BoruProfil:
                    visibleModel = {
                        labelGenislik: {name: '', isVisible: false},
                        labelKalinlik: {name: 'Kalınlık', isVisible: true},
                        labelYukseklik: {name: '', isVisible: false},
                        labelUzunluk: {name: 'Uzunluk ', isVisible: true},
                        labelCap: {name: 'Çap', isVisible: true},
                        labelYanak: {name: '', isVisible: false},
                    };
                    break;
                case StokMalzemeSekilTip.CProfil:
                case StokMalzemeSekilTip.OmegaProfil:
                    visibleModel = {
                        labelGenislik: {name: 'Genişlik', isVisible: true},
                        labelKalinlik: {name: 'Kalınlık', isVisible: true},
                        labelYukseklik: {name: 'Yükseklik', isVisible: true},
                        labelUzunluk: {name: 'Uzunluk', isVisible: true},
                        labelCap: {name: '', isVisible: false},
                        labelYanak: {name: 'Yanak', isVisible: true},
                    };
                    break;
                
            }

            return visibleModel as StokMalzemeStandartTip;
        }


        function isVisibleModel() {
            return (localState.visibleModel.labelGenislik && localState.visibleModel.labelGenislik.isVisible) ||
                (localState.visibleModel.labelKalinlik && localState.visibleModel.labelKalinlik.isVisible) ||
                (localState.visibleModel.labelYukseklik && localState.visibleModel.labelYukseklik.isVisible) ||
                (localState.visibleModel.labelUzunluk && localState.visibleModel.labelUzunluk.isVisible) ||
                (localState.visibleModel.labelCap && localState.visibleModel.labelCap.isVisible) ||
                (localState.visibleModel.labelYanak && localState.visibleModel.labelYanak.isVisible)
        }

        function getTipName(malzemeSekilTip:StokMalzemeSekilTip) {
            switch (malzemeSekilTip) {
                case StokMalzemeSekilTip.Profil:
                    return "PROFİL";
                case StokMalzemeSekilTip.BoyaliProfil:
                    return "BOYALI PROFİL";
                case StokMalzemeSekilTip.UProfil:
                    return "U-PROFİL";
                case StokMalzemeSekilTip.BoruProfil:
                    return "BORU";
                case StokMalzemeSekilTip.BoyaliBoru:
                    return "BOYALI BORU";
                case StokMalzemeSekilTip.CProfil:
                    return "C-PROFİL";
                case StokMalzemeSekilTip.OmegaProfil:
                    return "OMEGA PROFİL";
                case StokMalzemeSekilTip.ZProfil:
                    return "Z-PROFİL";
            }
        }

        return {
            ...toRefs(localState),
            updateStokName,
            isVisibleModel,
            resetSizes,
            getTipName
        };
    }
});

