import { defineStore } from "pinia";


import NConfig from "@/config/NConfig";
import { axios } from "@/api/axios";
import useApi from "@/api/useApi";


export const useNesilceConfig = defineStore({
  id: "NesilceConfig",
  state: () => ({
    config: {
      API_URL: undefined,
        LOGO_URL:undefined
    } as NConfig,
  }),
  actions: {
    async load() {
      const res = await axios.get("/nesilce.config.json");
      this.config = res.data;
      //  console.log(this.config)
        if (process.env.NODE_ENV === "production")
          this.config.API_URL = this.config.API_URL_PROD;
    },
  }


});
