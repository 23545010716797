
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import NCard from "@/components/Card/NCard.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import { DovizList } from "@/api/models/Doviz";
import NIconIonic from "@/components/NIconIonic.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NTag from "@/components/Tag/NTag.vue";
import { useKasaService } from "@/api/services/KasaService";
import { KasaEntity } from "@/api/models/KasaEntity";
import { useBankaService } from "@/api/services/BankaService";
import { BankaEntity } from "@/api/models/BankaEntity";
import { KrediKartiView } from "@/api/models/KrediKartiView";
import { TaksitPlanEntity } from "@/api/models/TaksitPlanEntity";
import { TaksitPlanTip, TaksitPlanTipList } from "@/api/models/TaksitPlanTip";
import { IdName } from "@/api/models/IdName";
import { useCachedSubeDepoService } from "@/modules/Sube/services/useCachedSubeDepoService";

export default defineComponent({
  props: {
    haraketler: {
      type: Array
    },
    tip: {
      type: String,
      default: "odemeler"
    },
    filter: {}

  },
  components: { NTag, NGrid, NIconIonic, NLoadingPanel, NCard },
  setup(props, { emit }) {
    const localState = reactive({
      haraketlerRef: computed(() => props.haraketler as TaksitPlanEntity[]),
      tipRef: computed(() => props.tip as string),
      dovizRef: DovizList,
      haraketlerDataGrid: ref(),
      filterRef: computed(() => props.filter as any),
      taksitTipList: TaksitPlanTipList,
      kasaListesi: ref([] as KasaEntity[]),
      bankaListesi: ref([] as BankaEntity[]),
      firmaKrediKartiListesi: ref([] as KrediKartiView[]),
      subeList: ref([] as IdName[])
    });

    watch(() => localState.filterRef, () => {
      filtre(localState.filterRef);
    });


    function filtre(filtre: any) {
      localState.haraketlerDataGrid?.instance?.clearFilter();
      localState.haraketlerDataGrid?.instance?.filter(filtre);
    }


    load();

    async function load() {
      localState.subeList = await useCachedSubeDepoService().getSubeList();
      localState.kasaListesi = await useKasaService().getUserKasaList();
      localState.bankaListesi = await useBankaService().getUserBankaList();
      localState.firmaKrediKartiListesi = await useBankaService().getKrediKartiList();

    }

    function odemelerSagMenu(e: any) {
      const data = e?.row?.data as TaksitPlanEntity;


      if (data.tip == TaksitPlanTip.Masraf) {
        const items = [];


        if (localState.kasaListesi && localState.kasaListesi.length) {
          const kasaItem = { text: "Kasadan Masraf Öde", icon: "box", items: [] as any[] };

          localState.kasaListesi.forEach((a) => {
            kasaItem.items.push({
              text: a.kasaAdi,
              onClick: () => emit("kasadanMasrafOdeClick", e?.row?.data, a.id)
            });
          });
          items.push(kasaItem);
        }

        if (localState.bankaListesi && localState.bankaListesi.length) {
          const bankaItem = { text: "Bankadan Masraf Öde", icon: "message", items: [] as any[] };

          localState.bankaListesi.forEach((a) => {
            bankaItem.items.push({
              text: a.bankaAdi,
              onClick: () => emit("bankadanMasrafOdeClick", e?.row?.data, a.id)
            });
          });
          items.push(bankaItem);
        }

        if (localState.firmaKrediKartiListesi && localState.firmaKrediKartiListesi.length) {
          const krediKartiItem = { text: "Firma Kredi Kartından Öde", icon: "toolbox", items: [] as any[] };

          localState.firmaKrediKartiListesi.forEach((a) => {
            krediKartiItem.items.push({
              text: a.kartAdi,
              onClick: () => emit("firmaKrediKartiMasrafClick", e?.row?.data, a.krediKartId)
            });
          });
          items.push(krediKartiItem);
        }
        return items;

      } else if (data.tip == TaksitPlanTip.Kredi) {
        return [
          { text: "Bankadan Öde ", icon: "user", onClick: () => emit("krediBankadanOde", e?.row?.data) }
        ];
      } else if (data.tip == TaksitPlanTip.FirmaKk) {
        if (localState.bankaListesi && localState.bankaListesi.length) {
          const bankaItem = { text: "Bankadan Ödeme", icon: "message", items: [] as any[] };

          localState.bankaListesi.forEach((a) => {
            bankaItem.items.push({
              text: a.bankaAdi,
              onClick: () => emit("bankadanOdeFirmaKrediKarti", e?.row?.data, a.id)
            });
          });
          // console.log("bankaıtem" ,  bankaItem)
          return [bankaItem];

        }
      } else if (data.tip != TaksitPlanTip.FirmaSenet && data.tip != TaksitPlanTip.FirmaCek) {
        return [
          { text: "Kasadan Öde", icon: "box", onClick: () => emit("kasadanOdeClick", e?.row?.data) },
          { text: "Havale / Eft İle Öde", icon: "message", onClick: () => emit("havaleOdemeClick", e?.row?.data) },
          {
            text: "Firma Kredi Kartı İle Ödeme",
            icon: "toolbox",
            onClick: () => emit("firmaKrediKartiClick", e?.row?.data)
          }
        ];
      } else if (data.tip != TaksitPlanTip.FirmaSenet) {

        return [
          { text: "Bankadan Öde", icon: "user", onClick: () => emit("bankadanOde", e?.row?.data) }
        ];

      } else {
        return [
          { text: "Kasadan Öde ", icon: "user", onClick: () => emit("kasadanOde", e?.row?.data) }
        ];
      }
    }


    function tahsilatlarSagMenu(e: any) {
      const data = e?.row?.data as TaksitPlanEntity;
      //console.log("odeme tahsilat plan sag menu datası", data);
      if (data.tip == TaksitPlanTip.PosCekim) {
        return [
          { text: "Hesaba Aktar", icon: "user", onClick: () => emit("hesabaAktar", e?.row?.data) }
        ];
      } else if (data.tip == TaksitPlanTip.FirmaKk) {
        return [
          { text: "Bankadan Para Yatırma", icon: "user", onClick: () => emit("bankadanParaYatirma", e?.row?.data) }
        ];

      } else if (data.tip != TaksitPlanTip.MusteriCek && data.tip != TaksitPlanTip.MusteriSenet) {

        return [
          { text: "Havale Alma", icon: "user", onClick: () => emit("havaleAlmaClick", e?.row?.data) },
          { text: "Kasadan Tahsil Et", icon: "box", onClick: () => emit("kasadanTahsilClick", e?.row?.data) },
          { text: "Pos Çek", icon: "print", onClick: () => emit("posCekClick", e?.row?.data) }
        ];

      } else {

        return [
          { text: "Bankadan Tahsil Et", icon: "user", onClick: () => emit("bankadanTahsilClick", e?.row?.data) }
        ];
      }
    }


    function rightMenu(e: any) {
      const event = Object.assign({}, e);

      if (localState.tipRef == "odemeler") {
        const menu = odemelerSagMenu(event);
        //console.log(menu);
        e.items = menu;
      } else {
        const menu = tahsilatlarSagMenu(event);
        //console.log(menu);
        e.items = menu;
      }

      e.items.push({
        icon: "textdocument",
        text: "Not Ekle",
        onClick: () => emit("notEkleClick", e?.row?.data)
      });

      if (e.row == undefined)
        e.items = undefined;
    }

    function rowUpdating(satir: any) {
      TaksitGuncelle(satir.key);
    }

    async function TaksitGuncelle(satir: any) {


      //await useCariHareketTaksitService().putTaksitHareketGuncelle(satir);
    }

    function onCellPrepared(e: any) {

      if (e && e.data && e.data && e.rowType && e.rowType == "data") {

        if (e.data.odenmis && e.data.odenmis == true) {
          e.cellElement.className += " odenmisClass";
        } else {
          if (e.data.isGeciken && e.data.isGeciken == true) {
            e.cellElement.className += " gecikenClass";

          }


        }


      }
    }

    return {
      ...toRefs(localState),
      load,
      rightMenu,
      filtre,
      rowUpdating,
      onCellPrepared


    };
  }
})
;
