import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!

  return (_openBlock(), _createBlock(_component_DxSelectBox, _mergeProps(_ctx.$attrs, {
    label: "Depo",
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    "data-source": _ctx.depolar,
    "onUpdate:dataSource": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.depolar) = $event)),
    "display-expr": "name",
    "value-expr": "id",
    grouped: true,
    style: {"grid-column":"span 2"}
  }), null, 16, ["modelValue", "data-source"]))
}