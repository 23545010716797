import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSelectBox = _resolveComponent("NSelectBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NTagList = _resolveComponent("NTagList")!
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    modelValue: _ctx.showDialog,
    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.showDialog) = $event)),
    width: "700px"
  }, {
    header: _withCtx(() => [
      _createTextVNode(" Özellik Formu ")
    ]),
    body: _withCtx(() => [
      _createVNode(_component_NFormGrid, { cols: "minmax(0,100%)" }, {
        default: _withCtx(() => [
          false
            ? (_openBlock(), _createBlock(_component_NFormField, {
                key: 0,
                label: "Değer Tipi",
                "grid-cols": "170px auto 1fr"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NSelectBox, {
                    items: _ctx.valueTypes,
                    modelValue: _ctx.model.valueType,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.valueType) = $event)),
                    "value-field": "value",
                    "label-field": "key"
                  }, null, 8, ["items", "modelValue"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_NFormField, {
            label: "Özellik Adı",
            "grid-cols": "170px auto 1fr"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NInput, {
                modelValue: _ctx.model.name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.name) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          (_ctx.isVaryantForm)
            ? (_openBlock(), _createBlock(_component_NFormField, {
                key: 1,
                label: "Özellik Adı (Trendyol)",
                "grid-cols": "170px auto 1fr"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.model.nameTrendyol,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.nameTrendyol) = $event)),
                    placeholder: _ctx.model.name
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isVaryantForm)
            ? (_openBlock(), _createBlock(_component_NFormField, {
                key: 2,
                label: "Özellik Adı (HepsiBurada)",
                "grid-cols": "170px auto 1fr"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.model.nameHepsiBurada,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.nameHepsiBurada) = $event)),
                    placeholder: _ctx.model.name
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isVaryantForm)
            ? (_openBlock(), _createBlock(_component_NFormField, {
                key: 3,
                label: "Özellik Adı (N11)",
                "grid-cols": "170px auto 1fr"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.model.nameN11,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.nameN11) = $event)),
                    placeholder: _ctx.model.name
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isVaryantForm)
            ? (_openBlock(), _createBlock(_component_NFormField, {
                key: 4,
                label: "Özellik Adı (ÇiçekSepeti)",
                "grid-cols": "170px auto 1fr"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.model.nameCicekSepeti,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.nameCicekSepeti) = $event)),
                    placeholder: _ctx.model.name
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.isVaryantForm)
            ? (_openBlock(), _createBlock(_component_NFormField, {
                key: 5,
                label: "Özellik Adı (Pazarama)",
                "grid-cols": "170px auto 1fr"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.model.namePazarama,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.namePazarama) = $event)),
                    placeholder: _ctx.model.name
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.isStok)
            ? (_openBlock(), _createBlock(_component_NFormField, {
                key: 6,
                label: "Değerler",
                "grid-cols": "170px minmax(0,1fr)"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NTagList, {
                    type: "text",
                    modelValue: _ctx.model.values,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.values) = $event)),
                    "input-style": {width:'100px'}
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (!_ctx.isVaryantForm && !_ctx.isStok)
            ? (_openBlock(), _createBlock(_component_NFormField, {
                key: 7,
                label: "Varsayılan Değer",
                "grid-cols": "170px 1fr"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.model.value,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.value) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_NFormField, {
            label: "İsimde Göster",
            "grid-cols": "170px 1fr"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NSwitch, {
                modelValue: _ctx.model.isVisible,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.isVisible) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    actions: _withCtx(() => [
      (_ctx.ozellikId && _ctx.ozellikId.length> 0)
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 0,
            type: "danger",
            onClick: _ctx.remove
          }, {
            default: _withCtx(() => [
              _createTextVNode("Sil")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_NButton, {
        type: "warning",
        onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.showDialog = false))
      }, {
        default: _withCtx(() => [
          _createTextVNode("İptal")
        ]),
        _: 1
      }),
      _createVNode(_component_NButton, {
        type: "success",
        onClick: _ctx.save
      }, {
        default: _withCtx(() => [
          _createTextVNode("Kaydet")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}