
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import ArrayStore from "devextreme/data/array_store";
import {KesimApi} from "@/modules/SacUretim/api/KesimApi";
import {StokSimpleView} from "@/api/models/StokSimpleView";
import {SacUretimFisView} from "@/api/models/SacUretimFisView";
import {SacUretimKesimModel} from "@/modules/SacUretim/api/SacUretimKesimModel";
import DepoGroupedSelectBox from "@/modules/Depo/components/DepoGroupedSelectBox.vue";
import {SacUretimSatirItem} from "@/api/models/SacUretimSatirItem";
import {UretimSacFisApi} from "@/modules/SacUretim/api/UretimSacFisApi";
import {usePopupService} from "@/services/popupService";
import {SacUretimSatirKaynakTip} from "@/api/models/SacUretimSatirKaynakTip";
import SacUretimSiparisSelectButton from "@/modules/SacUretim/dialogs/SacUretimSiparisSelectButton.vue";
import {SiparisSatirFlatView} from "@/api/models/SiparisSatirFlatView";
import {useStokService} from "@/api/services/StokService";
import {StokEntity} from "@/api/models/StokEntity";
import {uuid} from "vue-uuid";
import NGrid from "@/components/Grid/NGrid.vue";
import {useSacUretimFisService} from "@/api/services/SacUretimFisService";
import {alert} from "devextreme/ui/dialog";
import NButton from "@/components/Button/NButton.vue";
import {StokMalzemeSekilTip, StokMalzemeSekilTipList} from "@/api/models/StokMalzemeSekilTip";
import {SacUretimProfilModel} from "@/modules/SacUretim/api/SacUretimProfilModel";
import {GenelUtils} from "@/modules/Ortak/utils/GenelUtils";
import {useStokSettingsStore} from "@/modules/Stok/store/StokSettingsStore";

export default defineComponent({
    components: {NButton, NGrid, SacUretimSiparisSelectButton, DepoGroupedSelectBox},
    props: {
        stok: {
            type: Object
        },
        fis: {
            type: Object
        },
        store: {
            type: Object
        },
        tip: {
            type: Number,
            default: -1
        },
        cins: {
            type: String,
            default: ""
        },
        aciklamaList: {
            type: Array,
            default: () => [],
        },
        paketlemeList: {
            type: Array,
            default: () => [],
        },
        isUpdate: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {

        const localState = reactive({
            model: ref({
                tonaj: props.stok?.getKalanMiktar as number,
                uzunluk: 1,
                tip:StokMalzemeSekilTip.Profil,
                genislik: 0,
                yukseklik: 0,
                cap: 0,
                yanak: 0,
                depoId: '',
                adet: 1,
                paketeBol: 1,
                isFire: false,
                cins: props.cins,
                stok: {}
            } as SacUretimProfilModel),
            stokRef: computed(() => props.stok as StokSimpleView),
            fisRef: computed(() => props.fis as SacUretimFisView),
            storeRef: computed(() => props.store as ArrayStore),
            kesimApi: KesimApi,
            uretimSacFisApi: UretimSacFisApi,
            malzemeSekilTipList: StokMalzemeSekilTipList,
            cinsList:ref(['SOĞUK', 'SICAK', 'GZR', 'ASİTLİ'] as string[]),
            sekilTip:StokMalzemeSekilTip,
            storeSettings:useStokSettingsStore(),
        });

        async function load() {
            setFormFromKalan()

            // İşlemleri Enum Sırasına göre filtrele, diz
            const siraListesi = [StokMalzemeSekilTip.Profil,
                StokMalzemeSekilTip.BoyaliProfil,
                StokMalzemeSekilTip.BoruProfil,
                StokMalzemeSekilTip.BoyaliBoru,
                StokMalzemeSekilTip.CProfil,
                StokMalzemeSekilTip.UProfil,
                StokMalzemeSekilTip.OmegaProfil,
                StokMalzemeSekilTip.ZProfil];
            localState.malzemeSekilTipList = StokMalzemeSekilTipList?.filter(a => siraListesi.indexOf(a.value) >= 0)
            localState.malzemeSekilTipList.sort(GenelUtils.sortByEnums(siraListesi));
            const item = localState.malzemeSekilTipList.find(a => a.value == StokMalzemeSekilTip.BoruProfil) as any
            item.description = "Boru"
        }

        load()

        watch(() => props.tip, () => {
            localState.model.genislik = localState.stokRef?.tipOzellikler?.genislik;
            localState.model.yukseklik = 0;
            localState.model.yanak = 0;
            localState.model.cap = 0;
        })


        function addRow() {
            if (!localState.model.depoId || localState.model.depoId?.length < 4) {
                usePopupService().pushDangerNotification({
                    title: "Uyarı",
                    body: "Depo seçiniz!"
                });
            } else if ((localState.model.paketeBol as number) > 1 &&
                ((localState.model.adet as number) % (localState.model.paketeBol as number) != 0)) {
                usePopupService().pushDangerNotification({
                    title: "Hata",
                    body: "Girdiğiniz paket değeri, kalansız bölünemiyor."
                });
            } else {
                const satirlar = generateSatirlar() as any[];

                for (const satir of satirlar) {
                    const changes = [{type: 'insert', data: satir}] as any;
                    localState.storeRef.push(changes);
                }
                setFormFromKalan();
                //console.log(changes)
            }
        }

        function generateSatirlar() {
            const satirlar = [] as any[]

            if (localState.model.paketeBol as number > 1) {
                const adetSayisi = (localState.model.adet as number) / (localState.model.paketeBol as number)
                for (let i = 0; i < Number(localState.model.paketeBol); i++) {

                    const satir = {} as SacUretimSatirItem;
                    satir.kaynakTip = localState.model.kaynakTip;
                    satir.cins = localState.model.cins
                    // Model
                    satir.adet = adetSayisi;
                    satir.uzunluk = localState.model.uzunluk;
                    satir.aciklama = localState.model.aciklama;
                    satir.paketleme = localState.model.paketleme;
                    satir.tonaj = localState.model.tonaj as number / Number(localState.model.paketeBol);
                    satir.isFire = localState.model.isFire;
                    satir.depoId = localState.model.depoId;

                    // Stok
                    satir.genislik = localState.model.genislik
                    satir.kalinlik = localState.stokRef.tipOzellikler?.kalinlik as number;

                    //
                    satir.profilTip = localState.model.tip;
                    satir.yukseklik = localState.model.yukseklik
                    satir.yanak = localState.model.yanak
                    satir.cap = localState.model.cap

                    // Sipariş stokları
                    satir.cari = localState.model.cari,
                    satir.siparisId = localState.model.siparisId,
                    satir.siparisNo = localState.model.siparisNo,
                    satir.doviz = localState.model.doviz,
                    satir.siparisSatirId = localState.model.siparisSatirId,
                    satir.subeId = localState.model.subeId,
                    //

                    satir.tip = props.tip;
                    satir.id = (Date.now() + i).toString();
                    satir.stok = localState.model.stok;
                    satirlar.push(satir);

                }

            } else {
                const satir = {} as SacUretimSatirItem;
                satir.kaynakTip = localState.model.kaynakTip;
                satir.cins = localState.model.cins
                // Model
                satir.adet = localState.model.adet as number;
                satir.uzunluk = localState.model.uzunluk;
                satir.aciklama = localState.model.aciklama;
                satir.paketleme = localState.model.paketleme;
                satir.tonaj = localState.model.tonaj;
                satir.isFire = localState.model.isFire;
                satir.depoId = localState.model.depoId;

                // Stok
                satir.genislik = localState.model.genislik
                satir.kalinlik = localState.stokRef.tipOzellikler?.kalinlik as number;

                //
                satir.profilTip = localState.model.tip;
                satir.yukseklik = localState.model.yukseklik
                satir.yanak = localState.model.yanak
                satir.cap = localState.model.cap
                // Tiplere göre sıfırla

                // Sipariş stokları
                satir.cari = localState.model.cari,
                satir.siparisId = localState.model.siparisId,
                satir.siparisNo = localState.model.siparisNo,
                satir.doviz = localState.model.doviz,
                satir.siparisSatirId = localState.model.siparisSatirId,
                satir.subeId = localState.model.subeId,

                //
                satir.tip = props.tip;
                satir.id = Date.now().toString();
                satir.stok = localState.model.stok;
                satirlar.push(satir);
            }


            return satirlar;
        }

        function valueChangedUzunluk(e: any) {
            localState.model.tonaj = localState.uretimSacFisApi.getKalanTonaj(localState.stokRef, localState.fisRef);
            setAdet();
            setTonaj();
        }

        function valueChangedAdet(e: any) {
            setTonaj()
        }

        function valueChangedTonaj(e: any) {
            setAdet()
        }

        function setAdet() {
            if (localState.stokRef && localState.stokRef.tipOzellikler) {

                localState.model.adet = localState.kesimApi.getAdet(
                    localState.model.tonaj as number,
                    localState.stokRef.tipOzellikler.genislik as number,
                    localState.stokRef.tipOzellikler.kalinlik as number,
                    localState.model.uzunluk as number)
            }


        }

        function setTonaj() {
            if (localState.stokRef && localState.stokRef.tipOzellikler)
                localState.model.tonaj = Math.round(localState.kesimApi.getAgirlik(
                    localState.stokRef.tipOzellikler.genislik as number,
                    localState.stokRef.tipOzellikler.kalinlik as number,
                    localState.model.uzunluk as number,
                    localState.model.adet as number
                ))

        }

        function setFormFromKalan() {
            localState.model.tonaj = localState.uretimSacFisApi.getKalanTonaj(localState.stokRef, localState.fisRef);
            localState.model.adet = 1;
            localState.model.uzunluk = localState.uretimSacFisApi.getKalanBoy(localState.stokRef, localState.fisRef);
            localState.model.paketeBol = 1;
            localState.model.kaynakTip = SacUretimSatirKaynakTip.Talimat_Fisi;
            localState.model.stok = {}
            setAdet()
        }

        async function addRowFromSiparis(row: SiparisSatirFlatView) {

            const ilgiliStok = await useStokService().getGetir(row?.stokId as string) as StokEntity

            const fis = await useSacUretimFisService().getFindFisFromStokId(ilgiliStok.id as string)
            if (fis)
            {
                if (!(await confirm(`Seçtiğiniz sipariş stoğu daha önce başka talimatta kullanılmış! Devam etmek istiyor musunuz ? (${fis.stokParent.name})`)))
                return;
            }

            const result = localState.fisRef?.satirlar?.find((a:SacUretimSatirItem) => a.stok?.id == ilgiliStok.id)
            if (result)
            {
                await alert(`Seçtiğiniz sipariş stoğu daha önce satırda kullanılmış!`, "Hata");
                return;
            }

            localState.model = {
                id: uuid.v4(),
                cins: ilgiliStok?.tipOzellikler?.cins,
                uzunluk: ilgiliStok?.tipOzellikler?.uzunluk,
                paketeBol: 1,

                // Sipariş stokları
                cari: row.cari,
                kaynakTip: SacUretimSatirKaynakTip.Siparis,
                siparisId: row.siparisId,
                siparisNo: row.evrakNo,
                doviz: row?.doviz,
                siparisSatirId: row.id,
                subeId: row.subeId,
                //

                isFire: false,
                aciklama: localState.model.aciklama,
                paketleme: localState.model.paketleme,
                depoId: localState.model.depoId,
                kur: 1,
                stok: {id: row?.stokId, name: row?.ad, kod: ilgiliStok.kod, ilkKod: ilgiliStok.kod},
                genislik: ilgiliStok?.tipOzellikler?.genislik,
                kalinlik: ilgiliStok?.tipOzellikler?.kalinlik,
                yukseklik: ilgiliStok?.tipOzellikler?.yukseklik,
                yanak: ilgiliStok?.tipOzellikler?.yanak,
                cap: ilgiliStok?.tipOzellikler?.cap,
                tip: ilgiliStok.tipOzellikler?.malzemeSekilTip,

                adet: ilgiliStok?.tipOzellikler?.adet,
                tonaj: generateTonajFromSiparis(row?.miktar as number,
                    ilgiliStok?.tipOzellikler?.genislik as number,
                    ilgiliStok?.tipOzellikler?.genislik as number),
            } as SacUretimProfilModel;

            //const kalanTonaj = localState.uretimSacFisApi.getKalanTonaj(localState.stokRef, localState.fisRef) as number
            // localState.model.tonaj = Number(row?.miktar) // kalanTonaj <= Number(row?.miktar) ? kalanTonaj : Number(row?.miktar)
            setAdet()


        }

        function generateTonajFromSiparis(tabanTonaj: number, tabanGenislik: number, uretilenGenislik: number) {
            if (tabanGenislik == 0)
                return tabanTonaj;
            return ((tabanTonaj * uretilenGenislik) / tabanGenislik);
        }

        function isProfilValid() {
            const yukseklik = localState.model.yukseklik as number
            const cap = localState.model.cap as number
            const yanak = localState.model.yanak as number
            const uzunluk = localState.model.uzunluk as number
            const genislik = localState.model.genislik as number

            if (genislik <= 0 && !(localState.model.tip == localState.sekilTip.BoruProfil || localState.model.tip == localState.sekilTip.BoyaliBoru))
                return false

            if (localState.model.cins && localState.model.cins.length > 0) {
                switch (localState.model.tip as StokMalzemeSekilTip) {
                    case StokMalzemeSekilTip.Profil:
                    case StokMalzemeSekilTip.BoyaliProfil:
                    case StokMalzemeSekilTip.UProfil:
                        return yukseklik > 0 && uzunluk > 0
                    case StokMalzemeSekilTip.ZProfil:
                        return yukseklik > 0
                    case StokMalzemeSekilTip.BoruProfil:
                    case StokMalzemeSekilTip.BoyaliBoru:
                        return cap > 0 && uzunluk > 0
                    case StokMalzemeSekilTip.CProfil:
                    case StokMalzemeSekilTip.OmegaProfil:
                        return yukseklik > 0 && yanak > 0 && uzunluk > 0
                }
            }
            return false;
        }

        return {
            ...toRefs(localState),
            valueChangedUzunluk,
            valueChangedAdet,
            valueChangedTonaj,
            addRow,
            addRowFromSiparis,
            isProfilValid
        };
    }
});

