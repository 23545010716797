
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import NInput from "@/components/Input/NInput.vue";
import NButton from "@/components/Button/NButton.vue";
import NTag from "@/components/Tag/NTag.vue";
import {useMailService} from "@/api/services/MailService";
import {MailEntity} from "@/api/models/MailEntity";
import NGrid from "@/components/Grid/NGrid.vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import {useTemplateService} from "@/api/services/TemplateService";
import {TemplateEntity} from "@/api/models/TemplateEntity";
import NInputHtml from "@/components/Input/NInputHtml.vue";
import CokluDokuman from "@/modules/Ortak/components/CokluDokuman.vue";
import {useFileSettingsServiceCache} from "@/api/servicesCache/FileSettingsServiceCache";
import {useFileSettingsService} from "@/api/services/FileSettingsService";
import {GenelUtils} from "@/modules/Ortak/utils/GenelUtils";
import {useNetGsmSmsService} from "@/api/services/NetGsmSmsService";
import { MailView } from "@/api/models/MailView";
import NTagList from "@/components/Tag/NTagList.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import {useUserSettingsService} from "@/api/services/UserSettingsService";
import {IdName} from "@/api/models/IdName";
import NIcon from "@/components/Icon/NIcon.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import {DxTagBox} from "devextreme-vue";
import {usePopupService} from "@/services/popupService";

export default defineComponent({
  components: {
      DxTagBox,
      NGrid,
      NFormGrid, NIcon, NFormField, NTag, NInput, NButton, NModalDialog, NInputHtml, CokluDokuman},
  props: {
    id: {
      type: String,
    },
    show: {
      type: Boolean,
      default: false
    },
      modelValue: {
        type: MailView,
          default: {}
      },
      reportKey: {
        type:String,
        default: ''
      },
      mailSendTo:{
          type:String,
          default: ''
      }
  },
  setup(props, {emit}) {
    const localState = reactive({
      model: ref({} as MailView),
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val)
      }),
      subTitle: ref(props.id && props.id.length > 0 ? "- Mail Düzenle -" : "- Yeni Mail Gönder -"),
      title: ref(props.id && props.id.length > 0 ? "Mail Düzenle" : "Mail Gönder"),
      templateList: ref([] as TemplateEntity[]),
      serverUrl:ref(''),

      fromMailList:ref([] as string[]),
      sablonList:ref([] as IdName[]),
      gonderilenMailList:ref([] as string[]),
      seciliSablonId:ref(''),
      isKime:ref(true)

    });

    load();

    async function load() {


      if (props.id?.length && props?.id.length > 0) {

        localState.model = await useMailService().getMailGetir(props.id as string);


      } else if (props.modelValue && props.modelValue.tip != undefined) {
          localState.model = props.modelValue
          localState.model.email = props.mailSendTo
          localState.model.subject = localState.model.attachmentsBase64?.[0]?.dosyaAdi?.substring(0, localState.model.attachmentsBase64?.[0]?.dosyaAdi?.length - 4);
      } else {

        localState.model = {
          tip: 0,
          subject: "",
          body: "",
          aciklama: "",
          durum: 0,
          gonderilecekTarih: new Date().toISOString(),
          gonderimTarihi: new Date().toISOString(),
          attachments: [],
          email: '',
          emails:[],

        } as MailView;
      }



      // load from
      localState.fromMailList = await useMailService().getMailConfigIsimGetir() as string[]

      // load old sent mails
      localState.gonderilenMailList = await useMailService().getAllSentMails()


      // load gönderen user settings (from)
      const fromSettings = await getSettings('mailFrom') as string
      if (fromSettings)
          localState.model.from = fromSettings

      // Load Default (Template) Şablon
      if (props.reportKey && props.reportKey?.length > 0) {
          const sablonId = await getSettings(props.reportKey) as string
          if (sablonId && sablonId?.length > 0) {
              localState.seciliSablonId = sablonId
              await sablonChanged()
          }
      }

      localState.sablonList = await useTemplateService().getIdNameList();
      localState.sablonList = [{id:'',name:'Seçiniz...'}, ...localState.sablonList]
      localState.serverUrl = await useFileSettingsService().getServerUrl();
      localState.templateList = await useTemplateService().getSablonListesi();

    }


    async function save() {
      // gönderen save
        await saveSettings('mailFrom', localState.model.from as string)

      // save report template ID
      if (props.reportKey && props.reportKey?.length > 0
          && localState.seciliSablonId && localState.seciliSablonId?.length > 0)
        await saveSettings(props.reportKey as string, localState.seciliSablonId)

      if (localState.model.id && localState.model.id.length > 0) {
          await useMailService().putMailGuncelle(localState.model);
      } else {
          const mailEntity = await useMailService().postMailEkle(localState.model);
          emit('onInsert', mailEntity.id)
          await usePopupService().pushSuccessNotification({
              body: "Mail, gönderilmek üzere listeye eklendi.",
              duration: 2500,
              title: "Başarılı!"
          });
      }
      localState.showRef = false;
    }

    async function remove() {

      if (localState.model.id && localState.model.id.length > 0) {

        if (await GenelUtils.silmeOnayiDiyalogu('Mail kaydı silinecek, onaylıyor musunuz ?', 'Mail Silme İşlemi')) {
          await useMailService().deleteMailSil(localState.model.id as string);
          localState.showRef = false;

        }
      }
    }

    async function saveSettings(key: string, value: string) {
        await useUserSettingsService().postSave({
            name: key,
            value: value
        });
    }

    async function getSettings(key: string) {
        return await useUserSettingsService().getGetValue(key);
    }

    async function sablonChanged() {

        if (localState.seciliSablonId && localState.seciliSablonId?.length > 0) {
          const sablonData = await useTemplateService().getSablonGetir(localState.seciliSablonId) as TemplateEntity
          if (sablonData && sablonData.ad && sablonData.ad?.length > 0) {
              // localState.model.subject = sablonData.konu
              localState.model.body = sablonData.icerik
          }

        }

    }

    function toggleBulk() {
        localState.isKime = !localState.isKime
        if (!localState.isKime && localState.model.emails?.length == 0 && localState.model.email) {
            localState.model.emails?.push(localState.model.email as string)
        }
        if (localState.isKime)
            localState.model.emails = []
    }

    function openFileTab(fileBase64?:string, fileName?:string) {
        if (fileBase64 == undefined || fileBase64?.length == 0)
            return

        const pdfWindow = window.open("<p></p>", "_blank")
        pdfWindow!.document.write(
            "<iframe width='100%' height='100%' style='border:none;' " +
            "src='data:application/pdf;base64, " + encodeURI(fileBase64 as string) + "'></iframe>"
        )
        pdfWindow!.document.body.style.margin = "0"
        pdfWindow!.document.title = "PDF Önizleme (" + fileName + ")"
    }

    function checkFileExtension(fileName:string) {
        if (fileName.length == 0)
            fileName = (props.reportKey ? props.reportKey : '1') + '.pdf'
        if (fileName.indexOf('.pdf') == -1) {
            fileName = fileName + '.pdf'
        }
        return fileName
    }

    return {
      ...toRefs(localState),
        load,
        save,
        remove,
        sablonChanged,
        toggleBulk,
        openFileTab,
        checkFileExtension
    };
  }
});
