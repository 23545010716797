
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import {NameObject} from "@/api/models/NameObject";
import NCard from "@/components/Card/NCard.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NInput from "@/components/Input/NInput.vue";
import NButton from "@/components/Button/NButton.vue";
import NTagList from "@/components/Tag/NTagList.vue";
import _ from "lodash";
import {useN11ProductService} from "@/api/services/N11ProductService";
import {N11WebProductModel} from "@/api/models/N11WebProductModel";
import {N11WebProductDetail} from "@/api/models/N11WebProductDetail";
import NTag from "@/components/Tag/NTag.vue";
import {usePopupService} from "@/services/popupService";

export default defineComponent({
  components: {
    NTag,
    NFormField,
    NButton,
    NInput,
    NFormGrid,
    NGrid,
    NCard

  },
  props: {
    modelValue:{
      type:Array
    },
    text:{
      type:String
    },
    show:{
      type:Boolean,
      defaultValue:true
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      model:computed(()=> props.modelValue),
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val)
      }),
      data:ref({} as NameObject),
      counter:ref(0),
      products:ref(),
      searchText:ref(props.text),
      selectedSkus:ref([] as string[]),
      catalogId:ref(0)
    });


    if (localState.searchText && localState.searchText.length > 0){
      search()
    }

    async function search(){
      localState.products = await useN11ProductService().getWebProducts(localState.searchText as string) as N11WebProductModel[]
    }

    function save() {
      emit('update:modelValue',localState.catalogId)
      localState.showRef = false;
    }

    async function selectionChanged(e:any) {
      const selectedRow = e.selectedRowsData[0] as N11WebProductModel;
      localState.catalogId = 0;
      const product = await useN11ProductService().postWebProduct(selectedRow) as N11WebProductDetail;
      if (product.skuList)
        e.selectedRowsData[0].skuList = product.skuList;

      if (e.selectedRowsData[0].skuList && e.selectedRowsData[0].skuList.length > 0)
        localState.catalogId = e.selectedRowsData[0].skuList[0].pimsId;
      else
        usePopupService().pushWarningNotification({
          title:'Catalog Id bulunamadı',
          body: selectedRow.name + ' ürününe ait Catalog Id bulunamadı.'
        })

    }



    return {
      ...toRefs(localState),
      search,
      save,
      selectionChanged
    };
  }
});
