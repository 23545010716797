import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_DxAutocomplete = _resolveComponent("DxAutocomplete")!
  const _component_NFormField = _resolveComponent("NFormField")!

  return (_openBlock(), _createBlock(_component_NFormField, {
    label: _ctx.ozellikRef.name + ' '
  }, {
    label: _withCtx(() => [
      _createVNode(_component_NIcon, {
        style: {"margin-left":"4px","margin-top":"2px"},
        type: "edit",
        size: "12",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openOzellikForm(_ctx.ozellikRef.id)))
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_DxAutocomplete, {
        value: _ctx.ozellikRef.value,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ozellikRef.value) = $event)),
        maxItemCount: 1000,
        "show-clear-button": false,
        "data-source": _ctx.values,
        placeholder: _ctx.values?.join(',')
      }, null, 8, ["value", "data-source", "placeholder"])
    ]),
    _: 1
  }, 8, ["label"]))
}