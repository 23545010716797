import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, vShow as _vShow, createBlock as _createBlock, withDirectives as _withDirectives, mergeProps as _mergeProps, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_N11KategoriForm = _resolveComponent("N11KategoriForm")!
  const _component_TrendyolKategoriForm = _resolveComponent("TrendyolKategoriForm")!
  const _component_HepsiBuradaListingKategoriForm = _resolveComponent("HepsiBuradaListingKategoriForm")!
  const _component_HepsiBuradaKategoriForm = _resolveComponent("HepsiBuradaKategoriForm")!
  const _component_PrestaKategoriForm = _resolveComponent("PrestaKategoriForm")!
  const _component_CicekSepetiKategoriForm = _resolveComponent("CicekSepetiKategoriForm")!
  const _component_EpttAvmKategoriForm = _resolveComponent("EpttAvmKategoriForm")!
  const _component_PazaramaKategoriForm = _resolveComponent("PazaramaKategoriForm")!
  const _component_NCard = _resolveComponent("NCard")!

  return (_ctx.kategoriRef && _ctx.kategoriRef.ozellik && _ctx.kategoriRef.ozellik.n11 && _ctx.kategoriRef.ozellik.trendyol)
    ? (_openBlock(), _createBlock(_component_NCard, _mergeProps({
        key: 0,
        tabs: _ctx.getTabs()
      }, _ctx.$attrs, {
        modelValue: _ctx.selectedTab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTab) = $event))
      }), {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kategoriRef.ozellik.n11, (n11, i) => {
            return _withDirectives((_openBlock(), _createBlock(_component_N11KategoriForm, {
              key: i,
              n11: n11
            }, null, 8, ["n11"])), [
              [_vShow, _ctx.selectedTab === i]
            ])
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kategoriRef.ozellik.trendyol, (trendyol, i) => {
            return _withDirectives((_openBlock(), _createBlock(_component_TrendyolKategoriForm, {
              key: i,
              trendyol: trendyol
            }, null, 8, ["trendyol"])), [
              [_vShow, _ctx.selectedTab === i + _ctx.kategoriRef.ozellik.n11.length]
            ])
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kategoriRef.ozellik.hepsiBuradaListing, (hepsiBuradaListing, i) => {
            return _withDirectives((_openBlock(), _createBlock(_component_HepsiBuradaListingKategoriForm, {
              "hepsi-burada-listing": hepsiBuradaListing,
              key: i
            }, null, 8, ["hepsi-burada-listing"])), [
              [_vShow, _ctx.selectedTab === i + _ctx.kategoriRef.ozellik.n11.length + _ctx.kategoriRef.ozellik.trendyol.length]
            ])
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kategoriRef.ozellik.hepsiBurada, (hepsiBurada, i) => {
            return _withDirectives((_openBlock(), _createBlock(_component_HepsiBuradaKategoriForm, {
              "hepsi-burada": hepsiBurada,
              key: i
            }, null, 8, ["hepsi-burada"])), [
              [_vShow, _ctx.selectedTab === i + _ctx.kategoriRef.ozellik.n11.length + _ctx.kategoriRef.ozellik.trendyol.length + _ctx.kategoriRef.ozellik.hepsiBuradaListing.length]
            ])
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kategoriRef.ozellik.presta, (presta, i) => {
            return _withDirectives((_openBlock(), _createBlock(_component_PrestaKategoriForm, {
              presta: presta,
              configs: _ctx.configs,
              kategori: _ctx.kategoriRef,
              key: i,
              index: i
            }, null, 8, ["presta", "configs", "kategori", "index"])), [
              [_vShow, _ctx.selectedTab === i + _ctx.kategoriRef.ozellik.n11.length + _ctx.kategoriRef.ozellik.trendyol.length + _ctx.kategoriRef.ozellik.hepsiBuradaListing.length + _ctx.kategoriRef.ozellik.hepsiBurada.length]
            ])
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kategoriRef.ozellik.cicekSepeti, (cicekSepeti, i) => {
            return _withDirectives((_openBlock(), _createBlock(_component_CicekSepetiKategoriForm, {
              "cicek-sepeti": cicekSepeti,
              key: i
            }, null, 8, ["cicek-sepeti"])), [
              [_vShow, _ctx.selectedTab === i + _ctx.kategoriRef.ozellik.n11.length + _ctx.kategoriRef.ozellik.trendyol.length + _ctx.kategoriRef.ozellik.hepsiBuradaListing.length + _ctx.kategoriRef.ozellik.hepsiBurada.length  + _ctx.kategoriRef.ozellik.presta.length]
            ])
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kategoriRef.ozellik.epttAvm, (epttAvm, i) => {
            return _withDirectives((_openBlock(), _createBlock(_component_EpttAvmKategoriForm, {
              "eptt-avm": epttAvm,
              key: i
            }, null, 8, ["eptt-avm"])), [
              [_vShow, _ctx.selectedTab === i + _ctx.kategoriRef.ozellik.n11.length + _ctx.kategoriRef.ozellik.trendyol.length + _ctx.kategoriRef.ozellik.hepsiBuradaListing.length + _ctx.kategoriRef.ozellik.hepsiBurada.length  + _ctx.kategoriRef.ozellik.presta.length + _ctx.kategoriRef.ozellik.cicekSepeti.length]
            ])
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.kategoriRef.ozellik.pazarama, (pazarama, i) => {
            return _withDirectives((_openBlock(), _createBlock(_component_PazaramaKategoriForm, {
              pazarama: pazarama,
              key: i
            }, null, 8, ["pazarama"])), [
              [_vShow, _ctx.selectedTab === i + _ctx.kategoriRef.ozellik.n11.length + _ctx.kategoriRef.ozellik.trendyol.length + _ctx.kategoriRef.ozellik.hepsiBuradaListing.length + _ctx.kategoriRef.ozellik.hepsiBurada.length  + _ctx.kategoriRef.ozellik.presta.length + _ctx.kategoriRef.ozellik.cicekSepeti.length + _ctx.kategoriRef.ozellik.epttAvm.length]
            ])
          }), 128))
        ]),
        _: 1
      }, 16, ["tabs", "modelValue"]))
    : _createCommentVNode("", true)
}