import {defineStore} from "pinia";
import {StokFlatView} from "@/api/models/StokFlatView";
import ArrayStore from "devextreme/data/array_store";
import {useStokService} from "@/api/services/StokService";
import {onStokHubManagerSignal} from "@/api/signals/StokHubManagerSignal";
import DataSource from "devextreme/data/data_source";
import {StokUyumluluktem} from "@/api/models/StokUyumluluktem";
import {useStokStore} from "@/modules/Stok/store/StokStore";

export const useHizliSatisStokFlatStore = defineStore({
    id: "HizliSatisStokFlatStore",
    state: () => ({
        stokArrayStore: new ArrayStore,
        dataSource: new DataSource({
            reshapeOnPush: true
        }),
        isSignal:false,
        service:useStokService(),
        count:0,
        tags:[] as string[],
        isLoadingTags:false
    }),
    actions: {
        getStoklar(){
            return (this.dataSource.store() as ArrayStore).createQuery().toArray();
        },

        async load() {
            this.loadTags();
            if (!this.isSignal)
                this.onSignal();

                const stok =await this.service.getStokFlatList() as any[];
                this.count = stok.length;
                this.stokArrayStore = new ArrayStore({
                    data: stok,
                    key: "id",
                });
                this.dataSource = new DataSource({
                    store: this.stokArrayStore,
                    reshapeOnPush: true
                })

        },
        loadTags() {

            if (!this.isLoadingTags) {
                this.isLoadingTags = true;
                setTimeout(() => {
                    useStokService().getStokHizliSatisNames().then((tags: string[]) => {
                        this.tags = tags;
                    })
                    this.isLoadingTags = false;
                }, 2000)
            }
        },
         onSignal() {
            this.isSignal = true;
            const signal =onStokHubManagerSignal();
            signal.AddedFlat((stok) => {
                if (stok) {
                    this.stokArrayStore.push([{type: "insert", data: stok}]);
                    this.loadTags();
                }
            })
             signal.ChangedFlat((stok) => {
                if (stok) {
                    this.stokArrayStore.push([{type: "update", data: stok, key: stok.id}]);
                    this.loadTags();
                }
            })
             signal.DeletedFlat((stokId) => {
                if (stokId && stokId.length > 0) {
                    this.stokArrayStore.push([{type: "remove", key: stokId}]);
                    this.loadTags();
                }
            })
        }

    }
});
