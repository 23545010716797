import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, vModelDynamic as _vModelDynamic, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = ["disabled", "onpaste", "id", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NCheckbox = _resolveComponent("NCheckbox")!
  const _component_NGrid = _resolveComponent("NGrid")!

  return (_openBlock(), _createBlock(_component_NGrid, { cols: "1fr auto" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        style: {"max-height":"200px","overflow":"auto"},
        class: "tag-list",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.clickContainer && _ctx.clickContainer(...args))),
        title: _ctx.getTitle()
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model, (tag, i) => {
          return (_openBlock(), _createBlock(_component_NTag, {
            onClose: ($event: any) => (_ctx.removeTag(i)),
            onToggle: ($event: any) => (_ctx.toggle($event,tag)),
            closable: "true",
            key: i,
            "model-value": _ctx.selectedItems && _ctx.selectedItems?.find(a => a === tag) != null
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(tag), 1)
            ]),
            _: 2
          }, 1032, ["onClose", "onToggle", "model-value"]))
        }), 128)),
        _withDirectives(_createElementVNode("input", {
          disabled: _ctx.disabled,
          onpaste: _ctx.yapistir,
          placeholder: "...",
          title: "Eklemek için Enter tuşuna basınız.",
          onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.blurInput && _ctx.blurInput(...args))),
          id: _ctx.uid,
          class: "input",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.text) = $event)),
          style: _normalizeStyle(_ctx.inputStyle),
          type: _ctx.$props.multipleEnter == true  ?  'string' : _ctx.type,
          onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addTag && _ctx.addTag(...args)))
        }, null, 44, _hoisted_2), [
          [_vModelDynamic, _ctx.text]
        ])
      ], 8, _hoisted_1),
      (_ctx.showSelectAll && _ctx.model && _ctx.model.length > 0 && _ctx.selectedItems)
        ? (_openBlock(), _createBlock(_component_NCheckbox, {
            key: 0,
            title: "Tümünü Seç",
            onToggle: _ctx.toggleSelectAll,
            style: {"align-self":"center","margin-left":"5px"},
            modelValue: _ctx.selectAll,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectAll) = $event))
          }, null, 8, ["onToggle", "modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}