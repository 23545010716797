import { PerakendeView } from "@/api/models/PerakendeView";
import { StokFlatView } from "@/api/models/StokFlatView";
import { PerakendeSatirItem } from "@/api/models/PerakendeSatirItem";
import _ from "lodash";
import { number } from "yup";
import { PerakendeIadeItem } from "@/api/models/PerakendeIadeItem";
import { Doviz } from "@/api/models/Doviz";
import { uuid } from "vue-uuid";
import { KurView } from "@/api/models/KurView";
import {useEvrakNoService} from "@/api/services/EvrakNoService";
import {PerakendeUtil} from "@/modules/Perakende/utils/PerakendeUtil";

export const HizliSatisUtils = {
 generateView:  () => {
    const view = PerakendeUtil.newPerakende();
     useEvrakNoService().getYeniEvrakNo(10).then(a => view.evrakNo = a);
    return view;
  },
  getOdenecekTutar(model:PerakendeView){
    return this.getToplamAlinacakTutar(model ) -  this.getToplamIade(model) - (model.genelIskonto  as number);
  },
  getToplamAlinacakTutar(model: PerakendeView) {
    let fiyat = 0;
    if (model && model.satirlar && model.satirlar.length > 0) {
        model.satirlar.forEach((satir: PerakendeSatirItem) => {
        let satirFiyat = (satir.fiyat! * satir.miktar! * satir.carpan!);
        if (satir.iskontolar && satir.iskontolar.length > 0) {
          satir.iskontolar.forEach((i: number) => {
            satirFiyat *= (1 - (i / 100));
          });
        }
        fiyat = fiyat + satirFiyat;
      });
    }


    return fiyat;
  },
  getToplamIskonto(model: PerakendeView) {
    let toplamSatirIskonto = 0;
    if (model && model.satirlar && model.satirlar.length > 0) {

      model.satirlar.forEach((satir: PerakendeSatirItem) => {
        const satirFiyatIskontosuz = (satir.fiyat! * satir.miktar! * satir.carpan!);
        let satirFiyatIskontolu = (satir.fiyat! * satir.miktar! * satir.carpan!);

        if (satir.iskontolar && satir.iskontolar.length > 0) {
          satir.iskontolar.forEach((i: number) => {
            satirFiyatIskontolu *= (1 - (i / 100));
          });
        }
        toplamSatirIskonto +=  (satirFiyatIskontosuz  -  satirFiyatIskontolu );
      });
    }
    return (model.genelIskonto  as number) + toplamSatirIskonto ;
  },

  getToplamIade(model: PerakendeView) {
    let fiyat = 0;
    if (model && model.iadeler && model.iadeler.length > 0) {
      model.iadeler.forEach((satir: PerakendeIadeItem) => {
        fiyat = fiyat + (satir.fiyat! * satir.miktar! * satir.carpan!);
      });

    }
    return fiyat ;
  },
  getToplamTutar(model: PerakendeView) {
    return _.sum(model.satirlar?.map(a => ((a.miktar as number) * (a.fiyat as number))));
  }


};
