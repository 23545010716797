import {defineStore} from "pinia";
import {useUserSettingsService} from "@/api/services/UserSettingsService";

export const useBildirimSesleriStore = defineStore({
    id: "useBildirimSesleriStore",
    state: () => ({
        bildirimBasariliSesi:true,
        bildirimHataSesi:true,
        bildirimSoruSesi:true,
        bildirimStokBulunduSesi:true,
        bildirimGenelSesler:true,
    }),
    actions: {
        async load() {

            const basariliSesi = await this.getDefaultSettings("bildirimBasariliSesi");
            this.bildirimBasariliSesi = basariliSesi == "True" || !basariliSesi

            const hataSesi = await this.getDefaultSettings("bildirimHataSesi")
            this.bildirimHataSesi = hataSesi == "True" || !hataSesi

            const soruSesi = await this.getDefaultSettings("bildirimSoruSesi")
            this.bildirimSoruSesi = soruSesi == "True" || !soruSesi

            const stokBulunduSesi = await this.getDefaultSettings("bildirimStokBulunduSesi")
            this.bildirimStokBulunduSesi = stokBulunduSesi == "True" || !stokBulunduSesi

            const genelSesler = await this.getDefaultSettings("bildirimGenelSesler")
            this.bildirimGenelSesler = genelSesler == "True" || !genelSesler

        },

        async getDefaultSettings(key: string) {
            return await useUserSettingsService().getGetValue(key);
        }

    }

})