
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import { usePopupService } from "@/services/popupService";
import NLoading from "@/components/Loading/NLoading.vue";

export default defineComponent({
  name: "NButton",
  components: { NLoading },
  props: {
    type: { type: String },
    iconAfter: { type: Boolean },
    disabled: { type: Boolean },
    loading: { type: Boolean },
    block: { type: Boolean },
    text: { type: Boolean },
    icon: { type: String },
    isSubmit: { type: Boolean },
    size: {
      type: String,
      default: "normal"
    },
    iconSize: { type: String },
    click: { type: Function }
    // isLoading:{type:Boolean, default:false}
  },
  setup(props, { emit }) {

    const localState = reactive({
      calculatedIconSize: computed(() => {
        switch (props.size) {
          case "xsmall":
            return "10";
          case "small":
            return "12";
          case "big":
            return "16";
          default:
            return "14";
        }
      }),
      btn: ref(),
      isLoadingProp: ref(false),
      isLoadingRef: computed({
        get: () => {
          return props.loading;
        },
        set: (val) => {
          localState.isLoadingProp = val;
          emit("update:isLoading", val);
        }
      })
    });


    onMounted(() => {
      localState.btn?.addEventListener("click", async () => {
        if (props.click) {
          localState.isLoadingProp = true;
          try {

            await props.click();
          } catch (e:any) {
            console.error('NButton Error:', e)
            throw new e;
            // usePopupService().pushDangerNotification({
            //   title:'Hata oluştu',
            //   body:e.message
            // })
          } finally {
            localState.isLoadingProp = false;
          }

        }

      });
    });


    return {
      ...toRefs(localState)
    };
  }
});
