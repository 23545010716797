import {MailActionStatusType} from '../models/MailActionStatusType'
import {DocumentItem} from '../models/DocumentItem'
import {MailFileInfo} from '../models/MailFileInfo'
import {ServisGonderimTip} from '../models/ServisGonderimTip'

export class MailView{
	id?:string;
	emails?:string[];
	from?:string;
	email?:string;
	subject?:string;
	body?:string;
	durum?:MailActionStatusType;
	durumAciklama?:string;
	attachments?:DocumentItem[];
	attachmentsBase64?:MailFileInfo[];
	gonderilecekTarih?:string;
	gonderimTarihi?:string;
	aciklama?:string;
	referansId?:string;
	tip?:ServisGonderimTip;
}
