
export enum StokHareketTip{
AlisFatura=100,
GirisFisi=200,
SayimGiren=300,
UretimGiren=400,
NakilGiren=500,
DevirFisi=600,
SatisIade=700,
SacGirisFis=800,
SatisFatura=2100,
CikisFisi=2200,
SayimCikan=2300,
UretimdeKullanilan=2400,
NakilCikan=2500,
Fire=2600,
Sarf=2700,
UretimFire=2800,
AlisIade=2900,
SatisKonsinye=2901,
SacCikisFis=3000,
AlisSiparis=5000,
SatisSiparis=5100,
AlisIrsaliye=5200,
SatisIrsaliye=5300,
AlisTeklif=5400,
SatisTeklif=5500,
FaturaliAlisIrsaliye=5600,
FaturaliSatisIrsaliye=5700,
NakilFis=7000,
SayimFis=7100,
AlisIrsaliyeToFatura=7200,
SatisIrsaliyeToFatura=7300,
AlisKurFarki=7400,
SatisKurFarki=7500
}

export const StokHareketTipList = [
{key:'AlisFatura', value:100, description:'Alış Faturası'},
{key:'GirisFisi', value:200, description:'Giriş Fişi'},
{key:'SayimGiren', value:300, description:'Sayım Giriş Fişi'},
{key:'UretimGiren', value:400, description:'Üretimden Giren'},
{key:'NakilGiren', value:500, description:'Nakil Giren'},
{key:'DevirFisi', value:600, description:'Devir Fişi'},
{key:'SatisIade', value:700, description:'Satış İade'},
{key:'SacGirisFis', value:800, description:'Sac Giriş Fişi'},
{key:'SatisFatura', value:2100, description:'Satış Faturası'},
{key:'CikisFisi', value:2200, description:'Çıkış Fişi'},
{key:'SayimCikan', value:2300, description:'Sayım Çıkış Fişi'},
{key:'UretimdeKullanilan', value:2400, description:'Üretimde Kullanılan'},
{key:'NakilCikan', value:2500, description:'Nakil Çıkan'},
{key:'Fire', value:2600, description:'Fire Fişi'},
{key:'Sarf', value:2700, description:'Sarf Fişi'},
{key:'UretimFire', value:2800, description:'Üretim Fire Fişi'},
{key:'AlisIade', value:2900, description:'Alış İade'},
{key:'SatisKonsinye', value:2901, description:'Satış Konsinye'},
{key:'SacCikisFis', value:3000, description:'Sac Çıkış Fişi'},
{key:'AlisSiparis', value:5000, description:'Alış Siparişi'},
{key:'SatisSiparis', value:5100, description:'Satış Siparişi'},
{key:'AlisIrsaliye', value:5200, description:'Alış Irsaliyesi'},
{key:'SatisIrsaliye', value:5300, description:'Satış Irsaliyesi'},
{key:'AlisTeklif', value:5400, description:'Alış Teklifi'},
{key:'SatisTeklif', value:5500, description:'Satış Teklifi'},
{key:'FaturaliAlisIrsaliye', value:5600, description:'Alış Irsaliyesi'},
{key:'FaturaliSatisIrsaliye', value:5700, description:'Satış Irsaliyesi'},
{key:'NakilFis', value:7000, description:'Nakil Fişi'},
{key:'SayimFis', value:7100, description:'Sayım Fişi'},
{key:'AlisIrsaliyeToFatura', value:7200, description:'Alış Faturası ( İrsaliyeli )'},
{key:'SatisIrsaliyeToFatura', value:7300, description:'Satış Faturası ( İrsaliyeli )'},
{key:'AlisKurFarki', value:7400, description:'Alış Kur Farkı'},
{key:'SatisKurFarki', value:7500, description:'Satış Kur Farkı'}
]
