
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from "vue";
import {KasaEntity} from "@/api/models/KasaEntity";
import {useKasaService} from "@/api/services/KasaService";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";

export default defineComponent({
  components: {},
  props: {
    modelValue: {
      type: String
    },
    name: {
      type: String
    },
    firstSelect:{
      type:Boolean,
      default : true ,

    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      items: ref([] as KasaEntity[]),
      selected: computed({
        get: () => props.modelValue,
        set: val => {
          emit("update:modelValue", val);
          if (val && val.length > 0) {
            const result = localState.items.find(a => a.id == val);
            if (result)
              emit('update:name', result.kasaAdi);
          }
        }
      })
    });

    async function load() {
      localState.items = await useKasaService().getUserKasaList();
      if (props.modelValue && props.modelValue.length > 0) {
        //
      } else {

        if (localState.items && localState.items.length > 0 && props.firstSelect == true ) {
          localState.selected = localState.items[0].id as string;
          emit("update:name", localState.items[0].kasaAdi);
        }

      }
    }


    onMounted(() => {
      load();
    });

    return {
      ...toRefs(localState)
    };
  }
});
