
import { defineComponent, reactive, ref, toRefs, watch } from "vue";
import { ReportView } from "../../../api/models/ReportView";
import { useRaporEkraniService } from "@/api/services/RaporEkraniService";
import { useReportStore } from "@/modules/Report/store/ReportStore";


export default defineComponent({
  components: {

  },
  props: {
    reportType:{
      type:Number
    }
  },
  setup(props) {

    const localState = reactive({
      list:ref([] as ReportView[]),
      selectedReportId:ref()
    });


    async function load(){
      if (props.reportType){
        localState.list = await useRaporEkraniService().getGetAllByType(props.reportType as number);
        const defaultReportId = await useReportStore().getDefaultReportId(props.reportType) as string;
        localState.selectedReportId = defaultReportId;
        await useReportStore().setDefaultReportId(props.reportType, defaultReportId);
      }else{
        alert('ReportListSelectBox.props.reportType tanımsız.')
      }
    }

    async function valueChanged(e:any){
      await useReportStore().setDefaultReportId(props.reportType as number,e.value);
    }

    load()

    return {
      ...toRefs(localState),valueChanged

    };
  }
});

