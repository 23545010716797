
import {computed, defineComponent, reactive, toRefs} from "vue";
import XmlImporterStokListesi from "@/modules/Servisler/XmlImporter/components/XmlImporterStokListesi.vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import {StokFlatView} from "@/api/models/StokFlatView";

export default defineComponent({
    components: {NModalDialog, XmlImporterStokListesi},
    props: {
        modelValue: {
            type: Array
        },
        show: {
            type: Boolean,
            defaultValue: true
        },
        searchText: {
            type: String
        }
    },

    setup(props, {emit}) {
        const localState = reactive({
            model: computed(() => props.modelValue),
            showRef: computed({
                get: () => props.show,
                set: (val) => emit("update:show", val)
            }),

        })

        async function load() {

        }

        function stokEntityAddedFromXml(stok:StokFlatView, isSingleSelect:boolean) {
            emit('stokEntityAddedFromXml', stok)
            if (isSingleSelect)
                localState.showRef = false
        }

        return {
            ...toRefs(localState),
            stokEntityAddedFromXml
        }
    }
})
