import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-927bc8de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-field-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.fill ? 'fill' : ''),
    style: _normalizeStyle({ gridColumn: 'span ' + _ctx.span })
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          style: _normalizeStyle({textAlign:_ctx.alignLabel})
        }, _toDisplayString(_ctx.label), 5))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "label", {}, undefined, true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "form-field-grid",
        style: _normalizeStyle({ gridTemplateColumns: _ctx.gridCols })
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 4)
    ])
  ], 6))
}