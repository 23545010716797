import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {ImporterType} from '../models/ImporterType'
import {RecordStatus} from '../models/RecordStatus'
import {ImporterColumnItem} from '../models/ImporterColumnItem'
import {ImporterEntity} from '../models/ImporterEntity'

export const useImporterService = defineStore({
id: 'ImporterService',
actions: {


	async getİmportGetDefaultNew(importType:number)
	{
		const { request, response } = useApi(`/api/import/GetDefaultNew/${importType}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getİmportGetDefaultUpdate(id:string)
	{
		const { request, response } = useApi(`/api/import/GetDefaultUpdate/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getİmportGetAll()
	{
		const { request, response } = useApi(`/api/import/GetAll`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getİmportGetAllByType(type:number)
	{
		const { request, response } = useApi(`/api/import/GetAllByType/${type}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postİmportInsert(ımporterEntity:ImporterEntity)
	{
		const { request, response } = useApi(`/api/import/Insert`,{ method:'POST', data:ımporterEntity });
		await request();
		return response.value;
	},

	async putİmportUpdate(ımporterEntity:ImporterEntity)
	{
		const { request, response } = useApi(`/api/import/Update`,{ method:'PUT', data:ımporterEntity });
		await request();
		return response.value;
	},

	async deleteİmportDelete(id:string)
	{
		const { request, response } = useApi(`/api/import/Delete/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	}
}
});
