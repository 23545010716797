import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NDropDownListBox = _resolveComponent("NDropDownListBox")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!

  return (_openBlock(), _createBlock(_component_NLoadingPanel, {
    ref: "loadingPanel",
    action: _ctx.load,
    title: "EPttAvm özellikleri yükleniyor..."
  }, {
    default: _withCtx(() => [
      _createVNode(_component_NFormGrid, {
        cols: _ctx.cols,
        title: "EPttAvm Özellikleri"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.epttAvmOzellikler, (attribute, i) => {
            return (_openBlock(), _createBlock(_component_NFormField, {
              "grid-cols": "100px 1fr",
              label: attribute.title + ' (' + attribute.attributes?.length + ')',
              key: i
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NGrid, {
                  cols: "auto auto",
                  gap: "2px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NDropDownListBox, {
                      "accept-custom-value": false,
                      onChange: _ctx.epttAvmToNesilce,
                      "selected-value": attribute.values,
                      "onUpdate:selectedValue": ($event: any) => ((attribute.values) = $event),
                      "value-expr": "id",
                      "display-expr": "name",
                      items: attribute.attributes
                    }, null, 8, ["onChange", "selected-value", "onUpdate:selectedValue", "items"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["label"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["cols"])
    ]),
    _: 1
  }, 8, ["action"]))
}