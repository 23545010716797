import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "text-2" }
const _hoisted_2 = {
  key: 0,
  class: "desc-3"
}
const _hoisted_3 = { class: "text-2" }
const _hoisted_4 = {
  key: 0,
  class: "desc-3"
}
const _hoisted_5 = { class: "text-2" }
const _hoisted_6 = {
  key: 0,
  class: "desc-3"
}
const _hoisted_7 = { class: "text-2" }
const _hoisted_8 = {
  key: 0,
  class: "desc-3"
}
const _hoisted_9 = { class: "text-2" }
const _hoisted_10 = {
  key: 0,
  class: "desc-3"
}
const _hoisted_11 = { class: "text-2" }
const _hoisted_12 = {
  key: 0,
  class: "desc-3"
}
const _hoisted_13 = { class: "text-2 number1" }
const _hoisted_14 = { class: "text-2 number1" }
const _hoisted_15 = {
  key: 0,
  class: "desc-2 number1"
}
const _hoisted_16 = { class: "text-2 number1" }
const _hoisted_17 = {
  key: 0,
  class: "desc-2 number1"
}
const _hoisted_18 = { class: "text-2 number1" }
const _hoisted_19 = {
  key: 0,
  class: "desc-2 number1"
}
const _hoisted_20 = { class: "text-2 number1" }
const _hoisted_21 = {
  key: 0,
  class: "desc-3 number"
}
const _hoisted_22 = { class: "text-2 number1" }
const _hoisted_23 = {
  key: 0,
  class: "desc-3 number"
}
const _hoisted_24 = { class: "text-2 number1" }
const _hoisted_25 = {
  key: 0,
  class: "desc-3 number"
}
const _hoisted_26 = { class: "text-2 number1" }
const _hoisted_27 = {
  key: 0,
  class: "desc-3 number"
}
const _hoisted_28 = { class: "text-2 number1" }
const _hoisted_29 = {
  key: 0,
  class: "desc-3 number"
}
const _hoisted_30 = { class: "text-2 number1" }
const _hoisted_31 = {
  key: 0,
  class: "desc-2 number"
}
const _hoisted_32 = { class: "header-1 number1" }
const _hoisted_33 = {
  key: 0,
  class: "desc-2 number"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxLoadPanel = _resolveComponent("DxLoadPanel")!
  const _component_DxEditing = _resolveComponent("DxEditing")!
  const _component_DxKeyboardNavigation = _resolveComponent("DxKeyboardNavigation")!
  const _component_DxGroupPanel = _resolveComponent("DxGroupPanel")!
  const _component_DxSearchPanel = _resolveComponent("DxSearchPanel")!
  const _component_DxColumnChooser = _resolveComponent("DxColumnChooser")!
  const _component_DxStateStoring = _resolveComponent("DxStateStoring")!
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_NDxStateStoring = _resolveComponent("NDxStateStoring")!
  const _component_NTag = _resolveComponent("NTag")!
  const _component_DxLookup = _resolveComponent("DxLookup")!
  const _component_DxTotalItem = _resolveComponent("DxTotalItem")!
  const _component_DxSummary = _resolveComponent("DxSummary")!
  const _component_NTagList = _resolveComponent("NTagList")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NCard = _resolveComponent("NCard")!
  const _component_StokHareketDialogList = _resolveComponent("StokHareketDialogList")!
  const _component_StokMiktarOzet = _resolveComponent("StokMiktarOzet")!
  const _component_HizliStokDialog = _resolveComponent("HizliStokDialog")!
  const _component_SacUretimEtiketDegistirDialog = _resolveComponent("SacUretimEtiketDegistirDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NCard, {
      col: "1fr",
      row: "minimal(0,1fr)"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "dx-card",
          style: _normalizeStyle({'height' :  _ctx.dataGridHeight , 'width' : '100%'})
        }, [
          _createVNode(_component_DxDataGrid, {
            "data-source": _ctx.model.satirlar,
            scrolling: {mode:'standard' , rowRenderingMode : 'standard' , columnRenderingMode:'virtual'  },
            ref: "siparisSatirDataGrid",
            width: "100%",
            height: "100%",
            "allow-column-reordering": true,
            "allow-column-resizing": true,
            "column-resizing-mode": "widget",
            "show-row-lines": true,
            style: _normalizeStyle({'height' :  _ctx.dataGridHeight}),
            key: "id",
            toolbarTemplate: "toolbar",
            onRowUpdating: _ctx.rowUpdating,
            onContextMenuPreparing: _ctx.rightMenu,
            onEditingStart: _ctx.rowEditing
          }, {
            toolbar: _withCtx(() => [
              _createVNode(_component_NDxStateStoring, { "data-grid-ref": _ctx.siparisSatirDataGrid }, null, 8, ["data-grid-ref"])
            ]),
            bakiyeCellTemplate: _withCtx(({data}) => [
              _createVNode(_component_NTag, {
                type: data.data.bakiye > 0 ? 'success' : data.data.bakiye == 0  ?  'primary' : 'danger',
                filled: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(data.data.bakiye), 1)
                ]),
                _: 2
              }, 1032, ["type"])
            ]),
            indexCellTemplate: _withCtx(({data}) => [
              _createTextVNode(_toDisplayString(data.rowIndex + 1), 1)
            ]),
            tipTemplate: _withCtx(({data}) => [
              _createVNode(_component_NTag, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(data.data.tip ==  0 ? 'S' : data.data.tip == 1 ? 'H / M'  : 'SAC'), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            getKalanMiktar: _withCtx(({data}) => [
              _createVNode(_component_NTag, {
                filled: true,
                type: (data.data.miktar - (data.data.girilenTeslimMiktar  +  data.data.getTeslimMiktar)) != 0 ? 'danger' : 'neutral' 
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(data.data.miktar - (data.data.girilenTeslimMiktar + data.data.getTeslimMiktar)), 1)
                ]),
                _: 2
              }, 1032, ["type"])
            ]),
            getNetFiyatTemplate: _withCtx(({data}) => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_1, _toDisplayString((data.data.getNetFiyatExtend() / data.data.kur).format(3, 3)) + " " + _toDisplayString(_ctx.util.getDoviz(data.data.doviz)), 1),
                (data.data.doviz !== 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(data.data.getNetFiyatExtend().format(3, 3)) + " ₺ ", 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            iskontolarTemplate: _withCtx(({data}) => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.data.iskontolar, (iskonto, i) => {
                return (_openBlock(), _createBlock(_component_NTag, { key: i }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(iskonto), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            iskontolarEditTemplate: _withCtx(({data}) => [
              _createVNode(_component_NTagList, {
                modelValue: data.data.iskontolar,
                "onUpdate:modelValue": ($event: any) => ((data.data.iskontolar) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            tutarTemplate: _withCtx(({data}) => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_3, _toDisplayString((data.data.getSatirBrutToplamExtend() / data.data.kur).format(3, 3)) + " " + _toDisplayString(_ctx.util.getDoviz(data.data.doviz)), 1),
                (data.data.doviz !== 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(data.data.getSatirBrutToplamExtend().format(3, 3)) + " ₺ ", 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            iskontoTutarTemplate: _withCtx(({data}) => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_5, _toDisplayString((data.data.getSatirIskontoTutarExtend() / data.data.kur).format(3, 3)) + " " + _toDisplayString(_ctx.util.getDoviz(data.data.doviz)), 1),
                (data.data.doviz !== 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(data.data.getSatirIskontoTutarExtend().format(3, 3)) + " ₺ ", 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            iskontoluTutarTemplate: _withCtx(({data}) => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_7, _toDisplayString((data.data.getIskontoluTutarExtend() / data.data.kur).format(3, 3)) + " " + _toDisplayString(_ctx.util.getDoviz(data.data.doviz)), 1),
                (data.data.doviz !== 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(data.data.getIskontoluTutarExtend().format(3, 3)) + " ₺ ", 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            kdvTutarTemplate: _withCtx(({data}) => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_9, _toDisplayString((data.data.getKdvTutarExtend() / data.data.kur).format(3, 3)) + " " + _toDisplayString(_ctx.util.getDoviz(data.data.doviz)), 1),
                (data.data.doviz !== 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString((data.data.getKdvTutarExtend() * data.data.kur).format(3, 3)) + " ₺ ", 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            kdvliTutarTemplate: _withCtx(({data}) => [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_11, _toDisplayString(data.data.getKdvliTutarExtend().format(3, 3)) + " " + _toDisplayString(_ctx.util.getDoviz(data.data.doviz)), 1),
                (data.data.doviz !== 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString((data.data.getKdvliTutarExtend() * data.data.kur).format(3, 3)) + " ₺ ", 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            birimEditTemplate: _withCtx(({data}) => [
              _createVNode(_component_DxSelectBox, {
                dataSource: data.data.birimler,
                onValueChanged: ($event: any) => (_ctx.changedBirim($event,data.data)),
                placeholder: data.data.birim,
                "show-clear-button": true,
                onOpened: ($event: any) => (_ctx.getBirimlerDataSource(data)),
                valueExpr: "birim",
                displayExpr: "getBirimKisaAd"
              }, null, 8, ["dataSource", "onValueChanged", "placeholder", "onOpened"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_DxLoadPanel, { enabled: false }),
              _createVNode(_component_DxEditing, {
                "allow-updating": true,
                "allow-deleting": true,
                "use-icons": true,
                "select-text-on-edit-start": true,
                "start-edit-action": "click",
                mode: "cell"
              }),
              _createVNode(_component_DxKeyboardNavigation, {
                "edit-on-key-press": true,
                "enter-key-action": "moveFocus",
                "enter-key-direction": "column"
              }),
              _createVNode(_component_DxGroupPanel, { visible: false }),
              _createVNode(_component_DxSearchPanel, {
                visible: true,
                width: 240,
                placeholder: "Satırlar İçinde Arayın..."
              }),
              _createVNode(_component_DxColumnChooser, {
                enabled: true,
                mode: "select"
              }),
              _createVNode(_component_DxStateStoring, {
                enabled: true,
                type: "custom",
                "custom-load": () =>  _ctx.loadState()
              }, null, 8, ["custom-load"]),
              _createVNode(_component_DxColumn, {
                caption: "Sil",
                type: "buttons",
                width: 40
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxButton, { name: "delete" })
                ]),
                _: 1
              }),
              _createVNode(_component_DxColumn, {
                width: "50",
                "data-field": "tip",
                caption: "Tip",
                cellTemplate: "tipTemplate",
                "allow-editing": false
              }),
              _createVNode(_component_DxColumn, {
                width: "400",
                "data-field": "ad",
                caption: "Ad"
              }),
              _createVNode(_component_DxColumn, {
                width: "120",
                "data-field": "aciklama",
                caption: "Açıklama",
                alignment: "left"
              }),
              _createVNode(_component_DxColumn, {
                width: "120",
                "data-field": "odemeSekli",
                caption: "Ödeme Şekli",
                alignment: "left"
              }),
              _createVNode(_component_DxColumn, {
                width: "100",
                "data-field": "miktar",
                caption: "Miktar",
                "data-type": "number",
                "editor-options": { min: 0.01 }
              }, null, 8, ["editor-options"]),
              _createVNode(_component_DxColumn, {
                width: "120",
                "data-field": "kod",
                caption: "Kod"
              }),
              _createVNode(_component_DxColumn, {
                width: "120",
                "data-field": "barkod",
                caption: "Barkod"
              }),
              _createVNode(_component_DxColumn, {
                width: "55",
                cellTemplate: "bakiyeCellTemplate",
                "data-field": "bakiye",
                caption: "Bakiye",
                "data-type": "number",
                "allow-editing": false
              }),
              _createVNode(_component_DxColumn, {
                width: "150",
                "data-field": "girilenTeslimMiktar",
                caption: "Teslim Edilecek",
                "data-type": "number"
              }),
              _createVNode(_component_DxColumn, {
                width: "100",
                "data-field": "getTeslimMiktar",
                caption: "Teslim Edilen",
                "data-type": "number"
              }),
              _createVNode(_component_DxColumn, {
                width: "100",
                "data-field": "kalan",
                caption: "Kalan",
                cellTemplate: "getKalanMiktar",
                "calculate-cell-value": (r) => r.miktar -  (r.girilenTeslimMiktar  +  r.getTeslimMiktar)
              }, null, 8, ["calculate-cell-value"]),
              _createVNode(_component_DxColumn, {
                width: "120",
                "data-field": "fiyat",
                caption: "Fiyat",
                alignment: "right",
                "data-type": "number",
                format: (a) => a.format(3,3)
              }, null, 8, ["format"]),
              _createVNode(_component_DxColumn, {
                width: "80",
                "data-field": "fiyatKdvli",
                caption: "Kdvli Fiyat",
                alignment: "right",
                format: (a) => a.format(3,3),
                "data-type": "number",
                "calculate-cell-value": (r) => r.fiyat * ( 1 + (r.kdv / 100 ))
              }, null, 8, ["format", "calculate-cell-value"]),
              _createVNode(_component_DxColumn, {
                width: "80",
                "data-field": "iskontolar",
                caption: "İskontolar",
                editCellTemplate: "iskontolarEditTemplate"
              }),
              _createVNode(_component_DxColumn, {
                width: "100",
                "data-field": "doviz",
                caption: "Döviz"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxLookup, {
                    "data-source": _ctx.dovizListRef,
                    "display-expr": "key",
                    "value-expr": "value"
                  }, null, 8, ["data-source"])
                ]),
                _: 1
              }),
              _createVNode(_component_DxColumn, {
                width: "60",
                "data-field": "kur",
                caption: "Kur",
                alignment: "right",
                format: (a) => a.format(3,3),
                "data-type": "number"
              }, null, 8, ["format"]),
              _createVNode(_component_DxColumn, {
                visble: true,
                width: "80",
                "data-field": "birim",
                caption: "Birim",
                editCellTemplate: "birimEditTemplate"
              }),
              _createVNode(_component_DxColumn, {
                visble: false,
                width: "100",
                "data-field": "bultenNo",
                caption: "Bülten No"
              }),
              _createVNode(_component_DxColumn, {
                visble: false,
                width: "100",
                "data-field": "siparisNo",
                caption: "Sipariş No"
              }),
              _createVNode(_component_DxColumn, {
                width: "100",
                "data-field": "mensei",
                caption: "Menşei"
              }),
              _createVNode(_component_DxColumn, {
                width: "100",
                "data-field": "adet",
                caption: "Adet (Sac)",
                "data-type": "number",
                format: (d) => d?.format(0, 2)
              }, null, 8, ["format"]),
              _createVNode(_component_DxColumn, {
                width: "50",
                "data-field": "kdv",
                caption: "Kdv",
                alignment: "right",
                format: (a) => a.format(3,3),
                "data-type": "number"
              }, null, 8, ["format"]),
              _createVNode(_component_DxColumn, {
                width: "160",
                "data-field": "adresId",
                caption: "Adres",
                editCellTemplate: "adresEditTemplate",
                "calculate-cell-value": (r) => _ctx.model.teslimatlar?.find(a=>a.id == r.adresId)?.kisaAd
              }, null, 8, ["calculate-cell-value"]),
              _createVNode(_component_DxColumn, {
                width: "150",
                "data-field": "depoId",
                caption: "Şube/Depo"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxLookup, {
                    "data-source": _ctx.depoList,
                    "display-expr": "depoSubeAd",
                    "value-expr": "depoId"
                  }, null, 8, ["data-source"])
                ]),
                _: 1
              }),
              _createVNode(_component_DxColumn, {
                visible: false,
                caption: "Kg Adet",
                width: "150",
                "data-field": "kgAdet",
                format: "#,##0.00"
              }),
              _createVNode(_component_DxColumn, {
                visible: false,
                caption: "Uzunluk",
                width: "150",
                "data-field": "uzunluk",
                format: "#,##0.00"
              }),
              _createVNode(_component_DxColumn, {
                width: "200",
                "data-field": "plasiyerId",
                caption: "Plasiyer"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxLookup, {
                    "data-source": _ctx.plasiyerList,
                    "display-expr": "adSoyad",
                    "value-expr": "id"
                  }, null, 8, ["data-source"])
                ]),
                _: 1
              }),
              _createVNode(_component_DxColumn, {
                width: "100",
                "data-field": "getSatirIskontoTutar",
                caption: "İskonto",
                "allow-editing": false,
                cellTemplate: "iskontoTutarTemplate"
              }),
              _createVNode(_component_DxColumn, {
                width: "100",
                "data-field": "getIskontoluTutar",
                caption: "İskontolu",
                "allow-editing": false,
                cellTemplate: "iskontoluTutarTemplate",
                alignment: "right"
              }),
              _createVNode(_component_DxColumn, {
                width: "100",
                "data-field": "getKdvTutar",
                caption: "Kdv",
                "allow-editing": false,
                cellTemplate: "kdvTutarTemplate",
                alignment: "right"
              }),
              _createVNode(_component_DxColumn, {
                width: "100",
                "data-field": "getNetFiyat",
                caption: "Net Fiyat",
                "allow-editing": false,
                cellTemplate: "getNetFiyatTemplate",
                alignment: "right"
              }),
              _createVNode(_component_DxColumn, {
                width: "60",
                "data-field": "tevkifatOran",
                caption: "Tevkifat (%)"
              }),
              _createVNode(_component_DxColumn, {
                width: "130",
                "data-field": "terminTarih",
                caption: "Termin Tarihi",
                dataType: "date"
              }),
              _createVNode(_component_DxColumn, {
                alignment: "center",
                width: "40",
                "data-field": "isTeslimEdildi",
                caption: "Teslim Edildi",
                dataType: "boolean"
              }),
              _createVNode(_component_DxColumn, {
                width: "150",
                "data-field": "tevkifatKod",
                caption: "Tevkifat Kod"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxLookup, {
                    "data-source": _ctx.tevkifatOranlari,
                    "display-expr": "kod",
                    "value-expr": "kod"
                  }, null, 8, ["data-source"])
                ]),
                _: 1
              }),
              _createVNode(_component_DxColumn, {
                width: "500",
                "data-field": "tevkifatAd",
                caption: "Tevkifat Açıklama"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxLookup, {
                    "data-source": _ctx.tevkifatOranlari,
                    "display-expr": "aciklama",
                    "value-expr": "aciklama"
                  }, null, 8, ["data-source"])
                ]),
                _: 1
              }),
              _createVNode(_component_DxColumn, {
                visible: true,
                caption: "Sıra",
                type: "",
                width: 50,
                cellTemplate: "indexCellTemplate"
              }),
              _createVNode(_component_DxSummary, null, {
                default: _withCtx(() => [
                  _createVNode(_component_DxTotalItem, {
                    column: "ad",
                    "summary-type": "count",
                    "value-format": "#,##0.00",
                    "display-format": "Satir : {0}"
                  }),
                  _createVNode(_component_DxTotalItem, {
                    column: "miktar",
                    "summary-type": "sum",
                    "value-format": "#,##0.00",
                    "display-format": "{0}"
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["data-source", "style", "onRowUpdating", "onContextMenuPreparing", "onEditingStart"])
        ], 4)
      ]),
      bottom: _withCtx(() => [
        (_ctx.model && _ctx.model.altIskontolar)
          ? (_openBlock(), _createBlock(_component_NGrid, {
              key: 0,
              cols: "1fr 1fr auto"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_NFormField, {
                    label: "Döviz",
                    "grid-cols": "100px 80px auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DxSelectBox, {
                        value: _ctx.model.cariIslemDoviz,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.cariIslemDoviz) = $event)),
                        items: _ctx.dovizListRef,
                        "search-enabled": false,
                        "display-expr": "key",
                        "value-expr": "value"
                      }, null, 8, ["value", "items"]),
                      _createVNode(_component_DxNumberBox, {
                        disabled: _ctx.model.cariIslemDoviz == 0,
                        prefix: "Kur",
                        value: _ctx.model.cariIslemKur,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.cariIslemKur) = $event)),
                        format: "KUR #,##0.######",
                        "value-change-event": "keyup"
                      }, null, 8, ["disabled", "value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NFormField, {
                    label: 'İskonto (' + _ctx.dovizListRef.find(a=>a.value == _ctx.model.cariIslemDoviz)?.key + ')',
                    "grid-cols": "100px 1fr"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DxNumberBox, {
                        value: _ctx.model.tutarIskonto,
                        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.tutarIskonto) = $event)),
                        format: "KDVSİZ #,##0.####",
                        "value-change-event": "keyup"
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_NFormField, {
                    label: 'İskonto (' + _ctx.dovizListRef.find(a=>a.value == _ctx.model.cariIslemDoviz)?.key + ')',
                    "grid-cols": "100px 1fr"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DxNumberBox, {
                        value: _ctx.kdvsizTutarIskonto,
                        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.kdvsizTutarIskonto) = $event)),
                        format: "KDVLİ #,##0.####",
                        "value-change-event": "keyup"
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_NFormField, {
                    label: "İskontolar (%)",
                    "grid-cols": "100px 1fr"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NTagList, {
                        type: "number",
                        modelValue: _ctx.model.altIskontolar,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.altIskontolar) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.getBirimTekTekHesapla(), (birim, i) => {
                    return (_openBlock(), _createBlock(_component_NFormField, {
                      "grid-cols": "100px 100px auto",
                      label: `Birim (${birim.birim})`,
                      fill: "true",
                      key: i
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_13, _toDisplayString(birim.miktar) + " " + _toDisplayString(birim.birim), 1)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["label"]))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.getKdvTekTekHesapla(), (kdvTutar, i) => {
                    return (_openBlock(), _createBlock(_component_NFormField, {
                      "grid-cols": "100px 100px auto",
                      label: `Kdv (%${kdvTutar.kdv})`,
                      fill: "true",
                      key: i
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_14, _toDisplayString(kdvTutar.kdvTutar.format(2, 2)) + " ₺ ", 1),
                          (_ctx.model.cariIslemDoviz != 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString((kdvTutar.kdvTutar / _ctx.model.cariIslemKur).format(2, 2)) + " " + _toDisplayString(_ctx.getDovizGenel()), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", null, [
                          _createElementVNode("div", _hoisted_16, _toDisplayString(kdvTutar.matrah.format(2, 2)) + " ₺ ", 1),
                          (_ctx.model.cariIslemDoviz != 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString((kdvTutar.matrah / _ctx.model.cariIslemKur).format(2, 2)) + " " + _toDisplayString(_ctx.getDovizGenel()), 1))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 2
                    }, 1032, ["label"]))
                  }), 128)),
                  (_ctx.model.getKdvTevkifatTutar() > 0 )
                    ? (_openBlock(), _createBlock(_component_NFormField, {
                        key: 0,
                        "grid-cols": "1fr 120px",
                        label: "Tevkifat Tutar",
                        fill: "true"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_18, _toDisplayString((_ctx.model.getKdvTevkifatTutar() / _ctx.model.cariIslemKur).format(2, 2)) + " " + _toDisplayString(_ctx.getDovizGenel()), 1),
                            (_ctx.model.cariIslemDoviz != 0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.model.getKdvTevkifatTutar().format(2, 2)) + " ₺ ", 1))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_NFormField, {
                    "grid-cols": "1fr 120px",
                    label: "Toplam",
                    fill: "true"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_20, _toDisplayString((_ctx.model.getSatirBrutToplam() / _ctx.model.cariIslemKur).format(2, 2)) + " " + _toDisplayString(_ctx.getDovizGenel()), 1),
                        (_ctx.model.cariIslemDoviz != 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_ctx.model?.getSatirBrutToplam().format(2, 2)) + " ₺ ", 1))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }),
                  (_ctx.model.getSatirIskontoTutar() > 0)
                    ? (_openBlock(), _createBlock(_component_NFormField, {
                        key: 0,
                        "grid-cols": "1fr 120px",
                        label: "Satır İskonto",
                        fill: "true"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_22, _toDisplayString((_ctx.model.getSatirIskontoTutar() / _ctx.model.cariIslemKur).format(2, 2)) + " " + _toDisplayString(_ctx.getDovizGenel()), 1),
                            (_ctx.model.cariIslemDoviz != 0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(_ctx.model.getSatirIskontoTutar().format(2, 2)) + " ₺ ", 1))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.model.getAltIskontoTutar() > 0 )
                    ? (_openBlock(), _createBlock(_component_NFormField, {
                        key: 1,
                        "grid-cols": "1fr 120px",
                        label: "Alt İskonto",
                        fill: "true"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_24, _toDisplayString((_ctx.model.getAltIskontoTutar() / _ctx.model.cariIslemKur).format(2, 2)) + " " + _toDisplayString(_ctx.getDovizGenel()), 1),
                            (_ctx.model.cariIslemDoviz != 0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_25, _toDisplayString(_ctx.model.getAltIskontoTutar().format(2, 2)) + " ₺ ", 1))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  ((_ctx.model.getAltIskontoTutar() > 0  || _ctx.model.getSatirIskontoTutar() > 0 ))
                    ? (_openBlock(), _createBlock(_component_NFormField, {
                        key: 2,
                        "grid-cols": "1fr 120px",
                        label: "İskontolu Toplam",
                        fill: "true"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_26, _toDisplayString((_ctx.model.getIskontoluTutar() / _ctx.model.cariIslemKur).format(2, 2)) + " " + _toDisplayString(_ctx.getDovizGenel()), 1),
                            (_ctx.model.cariIslemDoviz != 0)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_27, _toDisplayString(_ctx.model.getIskontoluTutar().format(2, 2)) + " ₺ ", 1))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_NFormField, {
                    "grid-cols": "1fr 120px",
                    label: "Kdv Tutar",
                    fill: "true"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_28, _toDisplayString((_ctx.model.getKdvTutar() / _ctx.model.cariIslemKur).format(2, 2)) + " " + _toDisplayString(_ctx.getDovizGenel()), 1),
                        (_ctx.model.cariIslemDoviz != 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(_ctx.model.getKdvTutar().format(2, 2)) + " ₺ ", 1))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NFormField, {
                    "grid-cols": "1fr 120px",
                    label: "Kdvli Toplam",
                    fill: "true"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_30, _toDisplayString((_ctx.model.getKdvliTutar() / _ctx.model.cariIslemKur).format(2, 2)) + " " + _toDisplayString(_ctx.getDovizGenel()), 1),
                        (_ctx.model.cariIslemDoviz != 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_31, _toDisplayString(_ctx.model.getKdvliTutar().format(2, 2)) + " ₺ ", 1))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NFormField, {
                    "grid-cols": "1fr 120px",
                    label: "Ödenecek Tutar",
                    fill: "true"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_32, _toDisplayString((_ctx.model.getOdenecekTutar() / _ctx.model.cariIslemKur).format(2, 2)) + " " + _toDisplayString(_ctx.getDovizGenel()), 1),
                        (_ctx.model.cariIslemDoviz != 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_33, _toDisplayString(_ctx.model.getOdenecekTutar().format(2, 2)) + " ₺ ", 1))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.stokHareketShowDialog)
      ? (_openBlock(), _createBlock(_component_StokHareketDialogList, {
          key: 0,
          "cari-id": _ctx.cari.id,
          "onUpdate:cariId": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.cari.id) = $event)),
          "stok-adi": _ctx.stokAdi,
          "onUpdate:stokAdi": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.stokAdi) = $event)),
          show: _ctx.stokHareketShowDialog,
          "onUpdate:show": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.stokHareketShowDialog) = $event)),
          "stok-id": _ctx.stokId,
          "onUpdate:stokId": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.stokId) = $event)),
          "item-id": _ctx.itemId,
          "onUpdate:itemId": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.itemId) = $event))
        }, null, 8, ["cari-id", "stok-adi", "show", "stok-id", "item-id"]))
      : _createCommentVNode("", true),
    (_ctx.showMiktarOzet)
      ? (_openBlock(), _createBlock(_component_StokMiktarOzet, {
          key: 1,
          show: _ctx.showMiktarOzet,
          "onUpdate:show": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.showMiktarOzet) = $event)),
          "stok-id": _ctx.stokId,
          "onUpdate:stokId": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.stokId) = $event))
        }, null, 8, ["show", "stok-id"]))
      : _createCommentVNode("", true),
    (_ctx.hizliStokDialogShow)
      ? (_openBlock(), _createBlock(_component_HizliStokDialog, {
          key: 2,
          show: _ctx.hizliStokDialogShow,
          "onUpdate:show": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.hizliStokDialogShow) = $event)),
          "stok-id": _ctx.stokId
        }, null, 8, ["show", "stok-id"]))
      : _createCommentVNode("", true),
    (_ctx.showEtiketlendirDialog)
      ? (_openBlock(), _createBlock(_component_SacUretimEtiketDegistirDialog, {
          key: 3,
          show: _ctx.showEtiketlendirDialog,
          "onUpdate:show": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.showEtiketlendirDialog) = $event)),
          id: _ctx.stokId
        }, null, 8, ["show", "id"]))
      : _createCommentVNode("", true)
  ], 64))
}