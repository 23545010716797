import {useHizmetMasrafStore} from "@/modules/HizmetMasraf/store/HizmetMasrafStore";

export const HizmetMasrafSearchUtils = () => ({
    search(text:string){
        const query = useHizmetMasrafStore().getQuery();
        const list = query.filter(this.filter(text));
        return list.toArray();
    },
    filter:(text:string)=>(a: any) => {
            return  a.ad?.icindeGeciyorMu(text) ||
                a.kod?.icindeGeciyorMu(text) ||
                a.barkod?.icindeGeciyorMu(text) ||
                a.grup1?.icindeGeciyorMu(text) ||
                a.grup2?.icindeGeciyorMu(text) ||
                a.grup3?.icindeGeciyorMu(text) ||
                a.grup4?.icindeGeciyorMu(text)
    }
})