import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_DxExport = _resolveComponent("DxExport")!
  const _component_DxLoadPanel = _resolveComponent("DxLoadPanel")!
  const _component_DxGrouping = _resolveComponent("DxGrouping")!
  const _component_DxScrolling = _resolveComponent("DxScrolling")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxColumnChooser = _resolveComponent("DxColumnChooser")!
  const _component_DxGroupPanel = _resolveComponent("DxGroupPanel")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_DxDropDownBox = _resolveComponent("DxDropDownBox")!

  return (_openBlock(), _createBlock(_component_DxDropDownBox, {
    value: _ctx.selectedAdres,
    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedAdres) = $event)),
    ref: "dropDown",
    "defer-rendering": true,
    "display-expr": "getValue",
    "show-clear-button": true,
    "data-source": _ctx.mahalleler,
    "value-expr": "getValue",
    placeholder: "Ara : Mahalle , İl , İlçe , Posta Kodu ... "
  }, {
    default: _withCtx(() => [
      _createVNode(_component_NInput, {
        type: "text",
        modelValue: _ctx.searchValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
        "prefix-icon": "map-pin",
        placeholder: "Ara : Mahalle , İl , İlçe , Posta Kodu"
      }, null, 8, ["modelValue"]),
      _createVNode(_component_DxDataGrid, {
        ref: "mahallelerDataGrid",
        dataSource: _ctx.mahalleler,
        "defer-rendering": true,
        wordWrapEnabled: true,
        style: {"width":"100%","height":"450px"},
        onRowClick: _cache[1] || (_cache[1] = (e) => _ctx.rowClick(e))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DxExport, { enabled: false }),
          _createVNode(_component_DxLoadPanel, { enabled: true }),
          _createVNode(_component_DxGrouping, { "auto-expand-all": false }),
          _createVNode(_component_DxScrolling, { mode: "infinite" }),
          _createVNode(_component_DxHeaderFilter, { visible: false }),
          _createVNode(_component_DxFilterRow, { visible: false }),
          _createVNode(_component_DxColumnChooser, { enabled: false }),
          _createVNode(_component_DxGroupPanel, { visible: false }),
          _createVNode(_component_DxColumn, {
            width: "auto",
            caption: "Adresler",
            dataField: "getValue"
          })
        ]),
        _: 1
      }, 8, ["dataSource"])
    ]),
    _: 1
  }, 8, ["value", "data-source"]))
}