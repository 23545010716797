import { NModuleRoute } from "@/models";

export const CariModuleRoutes: NModuleRoute[] = [
  {
    id: "CariModule",
    order: 3,
    displayName: "Cariler",
    icon: {
      type: "user"
    },
    children: [
      {
        id: "CariListesi",
        order: 0,
        displayName: "Cari Listesi",
        requiredRoles:{
          readRoles:['Cari.Liste'],
          insertRoles:['Cari.Ekle'],
          updateRoles:['Cari.Guncelle'],
          deleteRoles:['Cari.Sil']
        },
        icon: {
          type: "user"
        },
        component: () =>
          import(
            /* webpackChunkName: "cariler"*/ "../pages/CariListesiPage.vue"
          )
      },
      {
        id: "CariKategoriler",
        order: 0,
        displayName: "Cari Kategorileri",
        requiredRoles:{
          readRoles:['Cari.Kategori.Liste'],
          insertRoles:['Cari.Kategori.Ekle'],
          updateRoles:['Cari.Kategori.Guncelle'],
          deleteRoles:['Cari.Kategori.Sil']
        },
        icon: {
          type: "box"
        },
        component: () =>
            import(
                /* webpackChunkName: "CariKategorilerPage"*/ "../../CariKategori/pages/CariKategorilerPage.vue"
                )
      },
      {
        id: "CariHesapOzet",
        order: 0,
        displayName: "Hesap Özeti",
        requiredRoles:{
          readRoles:['Cari.Liste'],
          insertRoles:[],
          updateRoles:[],
          deleteRoles:[]
        },
        icon: {
          type: "user"
        },
        component: () =>
            import(
                /* webpackChunkName: "cariler"*/ "../pages/CariHesapOzetiPage.vue"
                )
      },
      {
        id: "OdemeTahsilat",
        order: 0,
        displayName: "Ödeme / Tahsilat Plan",
        requiredRoles:{
          readRoles:['Cari.Liste'],
          insertRoles:[],
          updateRoles:[],
          deleteRoles:[]
        },
        icon: {
          type: "user"
        },
        component: () =>
            import(
                /* webpackChunkName: "cariler"*/ "../../OdemeTahsilat/pages/OdemeTahsilatPages.vue"
                )
      }
    ]
  }
];
