import {Kapsam} from '../models/Kapsam'
import {KaynakHedef} from '../models/KaynakHedef'

export class KampanyaView{
	id?:string;
	aktif?:boolean;
	limit?:number;
	isLimitsiz?:boolean;
	kullaniciMiktar?:number;
	isKullaniciMiktarLimitsiz?:boolean;
	name?:string;
	subeId?:string;
	baslangic?:string;
	bitis?:string;
	kapsam?:Kapsam;
	kaynak?:KaynakHedef;
	hedef?:KaynakHedef;
}
