
export enum BankaHareketTip{
HavaleEftGonderme=100,
BankadanMasrafOdeme=110,
KrediFaiziOdeme=120,
BankadanFirmaSenediOdeme=130,
BankadanFirmaCekiOdeme=140,
BankayaVirmanOdeme=150,
BankadanDegerliKiymetAlimi=160,
BankadanBankaKredisiOdeme=170,
BankaCikisFisi=180,
BankadanFirmaKkOdeme=190,
BankaCikanDevir=200,
BankadanKasayaParaCekme=210,
DbsOdeme=220,
BankayaCaridenTahsilat=2100,
BankayaHizmetTahsilati=2110,
BankayaMusteriSenediTahsilati=2130,
BankayaMusteriCekiTahsilati=2140,
BankadanVirmanTahsilati=2150,
BankayaDegerliKiymetTahsilati=2160,
BankaGirisFisi=2180,
BankaGirenDevir=2200,
BankayaFirmaKkParaCekme=2320,
BankayaKasadanParaYatirma=2330,
BankaPosIsleme=2340,
GelenKredi=2350,
BankaPosCekim=3100,
BankaPosDevir=3200,
TeminatCek=3300,
TeminatSenet=3400,
TakasCek=3500,
TakasSenet=3600
}

export const BankaHareketTipList = [
{key:'HavaleEftGonderme', value:100, description:'Bankadan Cariye Ödeme'},
{key:'BankadanMasrafOdeme', value:110, description:'Bankadan Masraf Ödeme '},
{key:'KrediFaiziOdeme', value:120, description:'Bankadan Kredi Faiz Ödemesi'},
{key:'BankadanFirmaSenediOdeme', value:130, description:'Bankadan Firma Senedi Ödeme'},
{key:'BankadanFirmaCekiOdeme', value:140, description:'Firma Çeki Ödeme'},
{key:'BankayaVirmanOdeme', value:150, description:'Virman Ödeme'},
{key:'BankadanDegerliKiymetAlimi', value:160, description:'Değerli Kıymet Alışı Ödemesi'},
{key:'BankadanBankaKredisiOdeme', value:170, description:'Banka Kredisi Ödeme'},
{key:'BankaCikisFisi', value:180, description:'Çıkış Fişi'},
{key:'BankadanFirmaKkOdeme', value:190, description:'Firma Kredi Kartı Ödeme'},
{key:'BankaCikanDevir', value:200, description:'Çıkan Devir'},
{key:'BankadanKasayaParaCekme', value:210, description:'Kasaya Para Çekme'},
{key:'DbsOdeme', value:220, description:'Firma Dbs Ödeme'},
{key:'BankayaCaridenTahsilat', value:2100, description:'Cariden Tahsilat'},
{key:'BankayaHizmetTahsilati', value:2110, description:'Hizmet Tahsilatı'},
{key:'BankayaMusteriSenediTahsilati', value:2130, description:'Müşteri Senedi Tahsil Etme'},
{key:'BankayaMusteriCekiTahsilati', value:2140, description:'Müşteri Çeki Tahsil Etme'},
{key:'BankadanVirmanTahsilati', value:2150, description:' Virman Tahsilatı'},
{key:'BankayaDegerliKiymetTahsilati', value:2160, description:'Bankaya Değerli Kıymet Satış Tahsilati'},
{key:'BankaGirisFisi', value:2180, description:'Banka Giriş Fişi'},
{key:'BankaGirenDevir', value:2200, description:'Banka Giren Devir'},
{key:'BankayaFirmaKkParaCekme', value:2320, description:'Bankaya Firma Kredi Kartından Para Çekme'},
{key:'BankayaKasadanParaYatirma', value:2330, description:'Bankaya Kasadan Para Yatırma'},
{key:'BankaPosIsleme', value:2340, description:'Banka Pos İşleme'},
{key:'GelenKredi', value:2350, description:'Bankaya Yatan Kredi'},
{key:'BankaPosCekim', value:3100, description:'Pos Çekim'},
{key:'BankaPosDevir', value:3200, description:'Pos Devir'},
{key:'TeminatCek', value:3300, description:'M.Ç. Teminata Verme'},
{key:'TeminatSenet', value:3400, description:'M.S. Teminata Verme'},
{key:'TakasCek', value:3500, description:'M.Ç. Takasa Verme'},
{key:'TakasSenet', value:3600, description:'M.S. Takasa Verme'}
]
