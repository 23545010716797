
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";

import {useCariService} from "@/api/services/CariService";
import {IdName} from "@/api/models/IdName";


export default defineComponent({
  components: {},
  props: {
    modelValue: {
      type: Object,
    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      id: ref(''),
      items: ref([] as IdName[]),
      model: computed({
        get: () => props.modelValue as IdName,
        set: (val) => emit("update:modelValue", val),
      }),


    });

    async function load() {
      localState.items = await useCariService().getGrupSirketList();
      if (props.modelValue && props.modelValue.id && props.modelValue.id.length > 0)
        localState.id = props.modelValue.id ;

    }

    load();

    function valueChanged() {
      const result = localState.items.find(a => a.id == localState.id);
      if (result)
        emit("update:modelValue" , result);
    }


    return {
      ...toRefs(localState),
      valueChanged
    };
  }
})
;
