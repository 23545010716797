import { FaturaSatirItem } from "@/api/models/FaturaSatirItem";
import { StokBirimItem } from "@/api/models/StokBirimItem";
import { Doviz } from "@/api/models/Doviz";

export default class FaturaSatirItemExtend extends FaturaSatirItem {
  birimler?: StokBirimItem[];
  fiyatKdvli?: number;
  bakiye?: number;
  anaBirim?: string;

  // satış fiyatları ile ilgili alanlar burada

  guncelSatisFiyat?: number;
  guncelSatisKdv?: number;
  guncelSatisDoviz?: Doviz;
  guncelSatisKdvDahil?: boolean;
  yeniSatisFiyatKdvDahil?: number;
  adetKiloCarpan?: number;
  sacKgMetre?: number;
  satirIskontoTutar?: number;


  constructor(satir?: FaturaSatirItem) {
    super();
    if (satir) {
      if (satir.id && satir.id.length > 0) {
        this.id = satir.id;
      }
      this.giderYeri = satir.giderYeri;
      this.tip = satir.tip;
      this.kdv = satir.kdv;
      this.iskontolar = satir.iskontolar;
      this.miktar = satir.miktar;
      this.kur = satir.kur;
      this.doviz = satir.doviz;
      this.fiyat = satir.fiyat;
      this.birim = satir.birim;
      this.seriler = satir.seriler;
      this.birimler = [
        {
          carpan: satir.carpan,
          barkod: satir.barkod,
          birim: satir.birim,
          fiyat: satir.fiyat
        }
      ];

      this.carpan = satir.carpan;
      this.barkod = satir.barkod;
      this.plasiyerId = satir.plasiyerId;
      this.kod = satir.kod;
      this.itemId = satir.itemId;
      this.stokId = satir.stokId;
      this.ad = satir.ad;
      this.not = satir.not;
      this.depoId = satir.depoId;
      this.aciklama = satir.aciklama;
      this.mensei = satir.mensei;
      this.adet = satir.adet;

      this.fiyatKdvli = this.getSatirKdvliTutarExtend();
      this.altIskontoOran = satir.altIskontoOran;
      this.tevkifatAd = satir.tevkifatAd;
      this.tevkifatKod = satir.tevkifatKod;
      this.tevkifatOran = satir.tevkifatOran;
      this.referansId = satir.referansId;
      this.kdvIstisnasi = satir.kdvIstisnasi;
      this.vergiler = satir.vergiler;
      this.katilimPayi = satir.katilimPayi;
      this.siparisTarih = satir.siparisTarih;
      this.bakiye = 0;
      this.anaBirim = satir.birim;

      this.yeniSatisFiyatKdvDahil = 0;
      this.yeniSatisFiyat = satir.yeniSatisFiyat;

      this.guncelSatisFiyat = 0;
      this.guncelSatisKdv = 0;
      this.guncelSatisDoviz = Doviz.TRY;
      this.guncelSatisKdvDahil = false;
      this.adetKiloCarpan = 0;
      this.kiloAdet = satir.kiloAdet;
      this.uzunluk = satir.uzunluk;
      this.sacKgMetre = 0;
      this.satirIskontoTutar = 0;


      this.gonderimTip = satir.gonderimTip;
      this.teslimTip = satir.teslimTip;
      this.gTipNo = satir.gTipNo;
      this.kapMarka = satir.kapMarka;
      this.kapTip = satir.kapTip;
      this.kapAdet = satir.kapAdet ;

    }
  }

  getSatirKdvliTutarExtend(): number {
    if (this.fiyat == 0)
      return 0;
    else
      return this.fiyat! * (1 + (this.kdv! / 100));
  }

  getSatirBrutToplamExtend(): number {
    return this.fiyat! * this.miktar! * this.carpan! * this.kur!;
  }

  getSatirIskontoTutarExtend(): number {
    const brut = this.getSatirBrutToplamExtend();
    let sonuc = brut;

    this.iskontolar?.forEach(iskonto => {
      sonuc -= sonuc * (iskonto / 100);
    });
    return brut - sonuc;
  }

  getAltIskontoTutarExtend() {
    if (!this.altIskontoOran)
      this.altIskontoOran = 0;

    return (this.getSatirBrutToplamExtend() - this.getSatirIskontoTutarExtend()) * (this.altIskontoOran! / 100);
  }

  getIskontoluTutarExtend() {

    return this.getSatirBrutToplamExtend() - this.getAltIskontoTutarExtend() - this.getSatirIskontoTutarExtend();
  }

  getKdvTutarExtend() {
    return this.getIskontoluTutarExtend() * this.kdv! / 100;
  }

  getKdvliTutarExtend() {
    return this.getIskontoluTutarExtend() + this.getKdvTutarExtend();
  }


  getKdvTevkifatTutarExtend() {
    return this.getKdvTutarExtend() * (this.tevkifatOran! / 100);
  }

  getKdvTevkifatliTutarExtend() {
    return this.getIskontoluTutarExtend() + this.getKdvTevkifatTutarExtend();
  }

  // KDV SİZ
  getNetFiyatExtend() {
    return this.getIskontoluTutarExtend() / (this.miktar! * this.carpan!);
  }


}
