
export enum SiparisTip{
Normal=0,
IsTakip=1,
B2b=2
}

export const SiparisTipList = [
{key:'Normal', value:0, description:'Normal'},
{key:'IsTakip', value:1, description:'İş Takip'},
{key:'B2b', value:2, description:'B2B'}
]
