import { defineStore } from "pinia";
import ArrayStore from "devextreme/data/array_store";
import { useStokService } from "@/api/services/StokService";
import { onStokHubManagerSignal } from "@/api/signals/StokHubManagerSignal";
import { StokView } from "@/api/models/StokView";
import { ObjectUtils } from "@/utils/object/ObjectUtils";
import PazaryeriStatusCount from "@/modules/Stok/models/PazaryeriStatusCount";
import { ActionType } from "@/api/models/ActionType";
import { PazaryeriProcessItem } from "@/api/models/PazaryeriProcessItem";
import { ActionStatusType } from "@/api/models/ActionStatusType";
import { StokFlatView } from "@/api/models/StokFlatView";
import DataSource from "devextreme/data/data_source";
import {useUserSettingsService} from "@/api/services/UserSettingsService";


export const useStokStore = defineStore({
  id: "useStokStore",
  state: () => ({
    arrayStore: new ArrayStore(),
    isLoaded: false,
    isLoading: false,
    isSignalListining: false,
    tabKey: "",
    stokView: {} as StokView,
    pazaryeriStatusCount: {} as any,
    pazaryeriCalculating: false,
    isBarkodSearch: false,
    isStokKodSearch: false,
    isStokAdSearch: false,
    isSearchXmlStok: false,
    isSearchBarkodlar: false,
    isSearchKodlar: false,
    isAddSatirSingleClick:false,
    stokNames:[]
  }),
  actions: {

    getQuery() {
      return this.arrayStore.createQuery();
    },
    getStoklar(predicate: any, skip: number, take: number) {
      return this.getQuery().filter(predicate).slice(skip, take).toArray();
    },
    getStok(stokId: string) {
      return this.getStoklar((a: any) => a.id == stokId, 0, 1)[0];
    },
    getStokByItemId(stokId: string, itemId: string): StokFlatView {
      return this.getStoklar((a: any) => a.stokId == stokId && a.itemId == itemId, 0, 1)[0];
    },

    isExist(predicate: any) {
      return this.getQuery().filter(predicate).slice(0, 1).toArray().length > 0;
    },
    generateDataSource() {
      return new DataSource({
        store: this.arrayStore,
        reshapeOnPush: true
      });
    },
    async load() {

      this.stokNames = await useStokService().getStokNames();
     // await this.onSignal();

      if (!this.isLoading) {
        this.isLoading = true;
        try {
          const list = await useStokService().getList();
          this.arrayStore = new ArrayStore({
            data: list as any[],
            key: "id"
          });

        } catch (e:any) {
          console.error(e);
        } finally {
          this.isLoading = false;
          this.isLoaded = true;
        }

      }
    },
    async onSignal() {
      if (!this.isSignalListining) {
        this.isSignalListining = true;
        onStokHubManagerSignal().Added((stok) => {
          if (this.isLoaded)
            if (stok) {
              if (this.isLoaded)
                this.arrayStore.push([{ type: "insert", data: stok }]);
              this.countPazaryeriStatusAll();
            }
        });
        onStokHubManagerSignal().Changed((stok) => {
          if (this.isLoaded)
            if (stok) {
              this.arrayStore.push([{ type: "update", data: stok, key: stok.id }]);
              this.countPazaryeriStatusAll();
            }
        });


        onStokHubManagerSignal().Deleted((stokId) => {
          if (stokId && stokId.length > 0) {
            this.arrayStore.push([{ type: "remove", key: stokId }]);
            this.countPazaryeriStatusAll();
          }
        });

      }
    },

    countPazaryeriStatusAll() {

      if (!this.pazaryeriCalculating) {
        this.pazaryeriCalculating = true;
        //console.log("countPazaryeriStatusAll");
        setTimeout(() => {
          //console.log("countPazaryeriStatusAll.setTimeout");
          this.pazaryeriStatusCount = new PazaryeriStatusCount();
          this.countPazaryeriStatus("n11");
          this.countPazaryeriStatus("trendyol");
          this.countPazaryeriStatus("hepsiBurada");
          this.countPazaryeriStatus("hepsiBuradaListing");
          this.countPazaryeriStatus("presta");
          this.countPazaryeriStatus("cicekSepeti");
          this.countPazaryeriStatus("epttAvm");
          this.countPazaryeriStatus("pazarama");
          this.pazaryeriCalculating = false;
        }, 15000);

      }
    },
    countPazaryeriStatus(pazaryeriFieldName: string) {
      new Promise(resolve => {
        this.arrayStore.createQuery().filter((a: any) => a[pazaryeriFieldName] && a[pazaryeriFieldName].length > 0).toArray().forEach((stok: any) => {
          if (this.pazaryeriStatusCount[pazaryeriFieldName] && this.pazaryeriStatusCount[pazaryeriFieldName].length > 0) {
            const islem = stok[pazaryeriFieldName][0].islem as PazaryeriProcessItem;
            //debugger;
            // Gönderilenler, Gönderiliyor, Gönderilmeyenler, Gönderilemeyenler, İncelenenler

            if (islem.actionType != ActionType.Islem_Yok) {
              if (islem.actionStatusType == ActionStatusType.Error)
                this.pazaryeriStatusCount[pazaryeriFieldName].gonderilemedi += 1;

              else if (islem.actionStatusType == ActionStatusType.Ready)
                this.pazaryeriStatusCount[pazaryeriFieldName].gonderilecek += 1;

              else if (islem.actionStatusType == ActionStatusType.Sending)
                this.pazaryeriStatusCount[pazaryeriFieldName].gonderiliyor += 1;

              else if (islem.actionStatusType == ActionStatusType.Sended)
                this.pazaryeriStatusCount[pazaryeriFieldName].gonderildi += 1;

              else if (islem.actionStatusType == ActionStatusType.Processing)
                this.pazaryeriStatusCount[pazaryeriFieldName].inceleniyor += 1;
            } else {
              this.pazaryeriStatusCount[pazaryeriFieldName].pasif += 1;
            }
          }
        });
      });

    },
  }
});
