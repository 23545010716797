import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f732be22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "n-list-item-body" }
const _hoisted_2 = { class: "n-list-item-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['n-list-item', _ctx.selected && 'n-list-item--selected']),
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.selectItem()), ["stop"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.item), 1)
      ], true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ])
  ], 2))
}