
import { defineComponent } from "vue";

export default defineComponent({
  name: "NGrid",
  props: {
    cols: {
      type: String,
      default: "repeat(12, 1fr)"
    },
    rows: {
      type: String,
      default: "auto"
    },
    gap: {
      type: String,
      default: "0px"
    },
    title: {
      type: String
    }
  },
  setup(props) {
    function getGridFormClass() {
      if (props.title && props.title.length > 0) {
        //console.log(props.title);
        return ["form-grid", "form-grid-headered"];
      } else {
        return ["form-grid"];
      }
    }
    return {
      getGridFormClass
    };
  }
});
