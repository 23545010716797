import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "hizli-satis-filter" }
const _hoisted_2 = {
  class: "dx-card",
  style: {"height":"100%","width":"100%"}
}
const _hoisted_3 = ["onDblclick", "onClick"]
const _hoisted_4 = { class: "label-container" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HizliSatisStokAramaInput = _resolveComponent("HizliSatisStokAramaInput")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_DxTileView = _resolveComponent("DxTileView")!
  const _component_HizliStokDialog = _resolveComponent("HizliStokDialog")!
  const _component_StokXmlSelectListDialog = _resolveComponent("StokXmlSelectListDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NGrid, {
      cols: "1fr",
      rows: "auto minmax(0,1fr)"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createVNode(_component_NGrid, {
              cols: "1fr auto",
              gap: "12px",
              style: {"margin-bottom":"12px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_HizliSatisStokAramaInput, {
                  ref: "aramaInput",
                  placeholder: _ctx.getPlaceholder(),
                  id: "stokSearch",
                  value: _ctx.stokSearchText,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.stokSearchText) = $event)),
                  onStokAraEnterKey: _ctx.stokAraEnterKey
                }, null, 8, ["placeholder", "value", "onStokAraEnterKey"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_NTag, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filter(''))),
                filled: true,
                type: _ctx.getType('')
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Tümü")
                ]),
                _: 1
              }, 8, ["type"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stokStore.tags, (item, i) => {
                return (_openBlock(), _createBlock(_component_NTag, {
                  onClick: ()=>_ctx.filter(item),
                  filled: true,
                  type: _ctx.getType(item),
                  key: i
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick", "type"]))
              }), 128))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_DxTileView, {
            dataSource: _ctx.dataSource,
            direction: "vertical",
            ref: "tileViewRef",
            style: {"height":"100%!important"},
            height: "100%",
            "base-item-width": 150,
            "base-item-height": 200
          }, {
            item: _withCtx(({data}) => [
              _createElementVNode("div", {
                class: "hizli-satis-item",
                onDblclick: ($event: any) => (_ctx.addToSatirDoubleClick(data,1)),
                onClick: ($event: any) => (_ctx.addToSatir(data,1)),
                title: "Satışa eklemek için çift tıklayınız."
              }, [
                _createElementVNode("div", {
                  class: "resim",
                  style: _normalizeStyle({ backgroundImage:'url('+ _ctx.getResim(data.resimler) + ')' })
                }, null, 4),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("h6", null, _toDisplayString(data.ad), 1),
                  _createElementVNode("div", _hoisted_5, _toDisplayString(data.getSatisFiyat.format(0, 2)) + " " + _toDisplayString(_ctx.getDoviz(data.satisDoviz)), 1)
                ]),
                _createElementVNode("div", {
                  class: "kalanMiktar",
                  style: _normalizeStyle(_ctx.getMiktarStyle(data.kalanMiktar)),
                  title: "Kalan miktar"
                }, _toDisplayString(data.kalanMiktar) + " " + _toDisplayString(data.birim), 5),
                _createElementVNode("div", {
                  class: "editButton",
                  onClick: () => _ctx.editClick(data.stokId),
                  title: "Stoğu düzenle"
                }, [
                  _createVNode(_component_NIcon, {
                    type: "edit",
                    size: "11px"
                  })
                ], 8, _hoisted_6)
              ], 40, _hoisted_3)
            ]),
            _: 1
          }, 8, ["dataSource"])
        ])
      ]),
      _: 1
    }),
    (_ctx.showStokForm)
      ? (_openBlock(), _createBlock(_component_HizliStokDialog, {
          key: 0,
          show: _ctx.showStokForm,
          "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showStokForm) = $event)),
          "stok-id": _ctx.selectedStokId,
          "onUpdate:stokId": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedStokId) = $event))
        }, null, 8, ["show", "stok-id"]))
      : _createCommentVNode("", true),
    (_ctx.showXmlStokListDialog)
      ? (_openBlock(), _createBlock(_component_StokXmlSelectListDialog, {
          key: 1,
          show: _ctx.showXmlStokListDialog,
          "onUpdate:show": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showXmlStokListDialog) = $event)),
          onStokEntityAddedFromXml: _ctx.addToSatir,
          "search-text": _ctx.stokSearchText
        }, null, 8, ["show", "onStokEntityAddedFromXml", "search-text"]))
      : _createCommentVNode("", true)
  ], 64))
}