
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NInput from "@/components/Input/NInput.vue";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";
import {GenelResmiMuhasebeTip, GenelResmiMuhasebeTipList} from "@/api/models/GenelResmiMuhasebeTip";
import {KrediKartiHareketView} from "@/api/models/KrediKartiHareketView";

import TutarsalBilgiler from "@/modules/Ortak/components/TutarsalBilgiler.vue";
import SubeSelectBox from "@/modules/Sube/components/SubeSelectBox.vue";
import {KrediKartiUtils} from "@/modules/KrediKarti/utils/KrediKartiUtils";
import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import CokluDokuman from "@/modules/Ortak/components/CokluDokuman.vue";
import {MenuRefrenceType} from "@/modules/Ortak/models/Menu/MenuRefrenceType";
import HizmetMasrafSelectBox from "@/modules/HizmetMasraf/components/HizmetMasrafSelectBox.vue";
import {useKrediKartiHareketService} from "@/api/services/KrediKartiHareketService";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import {KrediKartiView} from "@/api/models/KrediKartiView";
import {useEvrakNoService} from "@/api/services/EvrakNoService";
import {useKurCachedServis} from "@/modules/Ortak/services/useKurCachedServis";
import {KurView} from "@/api/models/KurView";
import {Doviz} from "@/api/models/Doviz";
import GiderYeriSelectBox from "@/modules/Ortak/components/GiderYeriSelectBox.vue";
import { BankaHareketTip } from "@/api/models/BankaHareketTip";
import { KrediKartiHareketTip } from '@/api/models/KrediKartiHareketTip';

import { useTaksitPlanService } from "@/api/services/TaksitPlanService";
import { TaksitPlanEntity } from "@/api/models/TaksitPlanEntity";
import NGrid from "@/components/Grid/NGrid.vue";
import TaksitlendirmePanel from "@/modules/OdemeTahsilat/components/TaksitlendirmePanel.vue";
import {useDovizKurService} from "@/api/services/DovizKurService";
export default defineComponent({
  components: {
    NGrid,
    GiderYeriSelectBox,
    TaksitlendirmePanel,
    NLoadingPanel,
    HizmetMasrafSelectBox,
    CokluDokuman, SubeSelectBox, TutarsalBilgiler, NSelectBox, NInput, NFormField, NFormGrid, NModalDialog
  },
  props: {
    hareketId: {
      type: String,
      default: '',
    },
    krediKarti: {
      type: String,
      default: '',
    },
    menu: {
      type: Object,
    },
    show: {
      type: Boolean,
      default: false,
    },
    tutar:{
      type : Number ,
      default : 0 ,
    },
    hizmetMasrafId:{
      type : String,
    },
    taksitPlanId:{
      type:String,
      default : '',
    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      selectedMenu: ref(props.menu as MenuModel),
      model: ref({} as KrediKartiHareketView),
      selectedTab: ref(0),
      showRef: computed({
        get: () => props.show,
        set: val => emit("update:show", val)
      }),
      menuRef: computed(() => props.menu as MenuModel),
      utils: ref(KrediKartiUtils),
      menuReferansTip: MenuRefrenceType,
      krediKartiHareketTip : KrediKartiHareketTip,
      genelResmiMuhaseRef: GenelResmiMuhasebeTipList,
      krediKartiRef: computed(() => props.krediKarti as KrediKartiView),
      kurlarRef: ref([] as KurView[]),
    });

    async function load() {
      if (props.hareketId && props.hareketId.length > 0) {
        localState.model = await localState.utils.getKrediKartiHareketView(
            localState.krediKartiRef.krediKartId as string,
            props.hareketId,
            props.tutar as number
        );
        if (localState.model && localState.model.dokumanlar){
          //
        }else {
          localState.model.dokumanlar = [] ;
        }



      } else {
        const kurlar = await useKurCachedServis().getKurList() as KurView[];
        const evrakno = await useEvrakNoService().getYeniEvrakNo(10);
        localState.model = {
          dokumanlar: [],
          evrakNo: evrakno,
          genelResmiMuhasebeTip: GenelResmiMuhasebeTip.Genel,
          hesaplama: {
            islemDoviz: localState.krediKartiRef.doviz,
            islemKur: kurlar.find(a => a.doviz == localState.krediKartiRef.doviz)?.alis ,
            islemTutar : props.tutar ,
            islemYerelTutar  : 0 ,
            islenecekDoviz : Doviz.TRY,
            islenecekKur : 1,
            islenecekTutar : 0,
            islenecekYerelTutar : 0 ,
          },

          vade: localState.krediKartiRef.sonOdemeGunuTarihi,
          krediKartiId : localState.krediKartiRef.krediKartId,
          tarih : new Date().toISOString(),
          vadeler : [],
        }


      }


      if (localState.menuRef.hareketTipi === KrediKartiHareketTip.KrediKartiIleMasrafOdeme) {
        if (localState.model.id == null || !localState.model.id)
          localState.model.referansId = props.hizmetMasrafId;
      }

      if (localState.model && localState.model.id && localState.model.id.length > 0 ){
        const taksitler = await useTaksitPlanService().getReferansHareketIdByTaksitler(localState.model.id ) as TaksitPlanEntity[];
        if (taksitler)
          localState.model.vadeler =  taksitler.map((a:TaksitPlanEntity) => ({vade : a.vade , tutar : a.taksitTutar}));


      }


      localState.model.taksitPlanId = props.taksitPlanId ;
    }

    async function hareketDelete() {
      await useKrediKartiHareketService().deleteSil(localState.model.id as string);
      localState.showRef = false;
    }

    function kdvHesaplama() {
      setTimeout(() => {
        if (localState.model &&
          localState.model.hesaplama &&
          localState.model.hesaplama.islenecekTutar &&
          localState.model.hesaplama.islenecekTutar
        ) {

          if (localState.model.id  && localState.model.id.length > 0 ){
            //
          }else
            localState.model.kdvTutar =
              localState.model.hesaplama.islenecekTutar - (localState.model.hesaplama.islenecekTutar / (1 + (localState.model.kdv! / 100)));

        }

      }, 100);

    }


    watch(() => [localState.model.hesaplama, localState.model.kdv, localState.model.referansId], () => {
      kdvHesaplama();
    }, { deep: true });

    async function save() {
      await localState.utils.dekontKaydet(
          localState.selectedMenu,
          localState.model
      );
      emit('saveRefresh');
      localState.showRef = false;

    }

      async function evrakTarihiChanged(e: any) {
          if (e && e.value && e.value.length > 0) {
              const kurlar = await useDovizKurService().getKurlarDate(e.value) as KurView[];
              if (kurlar && kurlar.length > 0)
                  localState.kurlarRef = kurlar;
          }

          //console.log(e);
      }

    return {
      ...toRefs(localState),
      save,
      hareketDelete,
      load,
      evrakTarihiChanged
    };
  }
});
