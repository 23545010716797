
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import {ReportType, ReportTypeList} from "@/api/models/ReportType";
import {ReportView} from "@/api/models/ReportView";
import {useRaporEkraniServiceExtend} from "@/api/extends/RaportEkraniServiceExtend";
import ReportViewerDialog from "@/modules/Report/dialogs/ReportViewerDialog.vue";
import ReportStokBarkodPieceDialog from "@/modules/Report/dialogs/ReportStokBarkodPieceDialog.vue";
import {useStokService} from "@/api/services/StokService";
import {StokFlatView} from "@/api/models/StokFlatView";
import {StokEntity} from "@/api/models/StokEntity";
import {useReportStore} from "@/modules/Report/store/ReportStore";
import {useRaporEkraniService} from "@/api/services/RaporEkraniService";
import {FaturaEntityDataSource} from "@/api/models/FaturaEntityDataSource";
import {useFaturaService} from "@/api/services/FaturaService";
import {usedCacheFirmaService} from "@/modules/Ayarlar/Firmalar/services/usedCacheFirmaService";
import NButton from "@/components/Button/NButton.vue";

export default defineComponent({
  components: { NButton, ReportViewerDialog},
  props: {
    reportType: {
      type: Number
    },
    data: {
      type: Array
    },
    reportClick:{
      type:Function
    },
    label:{
      type:String
    },
    buttonType:{
      type:String,
      default:'info'
    },
    buttonSize:{
      type:String,
      default:'normal'
    }
  },
  setup(props, {emit}) {

    const localState = reactive({
      reportTypeList: ref(ReportTypeList),
      reportTypeModel:ReportType,
      reportList: ref([] as ReportView[]),
      showDialog: ref(false),
      selectedReportName: ref(),
      dataRef:computed(()=> props.data)
    });

    watch(()=>localState.showDialog,(v:boolean)=>{
      if (!v)
        emit('close',v)
    })

    async function load() {

      localState.reportList = await useRaporEkraniServiceExtend().getList(props.reportType as ReportType) as ReportView[];
    }

    load()

    async function openReportViewer(report: ReportView) {
      await emit('reportClick', report.name);
      if (props.reportClick){
        await props.reportClick();
      }

      localState.selectedReportName = report.name;

        await sendToReport(report);

    }

    async function sendToReport(report: ReportView) {
      if (report.directPrint) {
        try {
          const defaultPrintName = await useReportStore().getPrinter(report.id as string)
          const reportBase64 = await useRaporEkraniService().getGetirBase64(report.id as string);
          await useReportStore().print(reportBase64, localState.dataRef, defaultPrintName as string);
        } catch {
          localState.showDialog = true;
        }
      } else {
        localState.showDialog = true;
      }
    }

    return {
      ...toRefs(localState),
      openReportViewer
    };
  }
});

