import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {Doviz} from '../models/Doviz'
import {StokUreticiItem} from '../models/StokUreticiItem'
import {IdName} from '../models/IdName'
import {StokBirimItem} from '../models/StokBirimItem'
import {XmlViewItem} from '../models/XmlViewItem'
import {XmlStokView} from '../models/XmlStokView'
import {Ozellik} from '../models/Ozellik'
import {SubeFiyatItem} from '../models/SubeFiyatItem'
import {N11PazaryeriItem} from '../models/N11PazaryeriItem'
import {CicekSepetiPazaryeriItem} from '../models/CicekSepetiPazaryeriItem'
import {TrendyolPazaryeriItem} from '../models/TrendyolPazaryeriItem'
import {HepsiBuradaPazaryeriItem} from '../models/HepsiBuradaPazaryeriItem'
import {HepsiBuradaListingPazaryeriItem} from '../models/HepsiBuradaListingPazaryeriItem'
import {PrestaPazaryeriItem} from '../models/PrestaPazaryeriItem'
import {EpttAvmPazaryeriItem} from '../models/EpttAvmPazaryeriItem'
import {PazaramaPazaryeriItem} from '../models/PazaramaPazaryeriItem'
import {StokViewItem} from '../models/StokViewItem'
import {StokUyumluluktem} from '../models/StokUyumluluktem'
import {OzellikVaryant} from '../models/OzellikVaryant'
import {StokTipOzellikler} from '../models/StokTipOzellikler'
import {StokTip} from '../models/StokTip'
import {StokLanguageItem} from '../models/StokLanguageItem'
import {PluKod} from '../models/PluKod'
import {StokView} from '../models/StokView'
import {DataSourceLoadOptionsItem} from '../models/DataSourceLoadOptionsItem'
import {RecordStatus} from '../models/RecordStatus'
import {XmlImporterConfigStokMap} from '../models/XmlImporterConfigStokMap'
import {XmlImporterConfigLogItem} from '../models/XmlImporterConfigLogItem'
import {XmlImporterConfigEntity} from '../models/XmlImporterConfigEntity'

export const useXmlImporterService = defineStore({
id: 'XmlImporterService',
actions: {


	async getGetDataAll()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/XmlImporter/GetDataAll/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getPaths(source:string,rootPath:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/XmlImporter/Paths/${shared.firm.id}/${source}/${rootPath}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postStokView(xmlStokView:XmlStokView,configId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/XmlImporter/StokView/${shared.firm.id}/${configId}`,{ method:'POST', data:xmlStokView });
		await request();
		return response.value;
	},

	async postSaveStok(stokView:StokView,dataId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/XmlImporter/SaveStok/${shared.firm.id}/${dataId}`,{ method:'POST', data:stokView });
		await request();
		return response.value;
	},

	async postDataSource(dataSourceLoadOptionsItem:DataSourceLoadOptionsItem)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/XmlImporter/DataSource/${shared.firm.id}`,{ method:'POST', data:dataSourceLoadOptionsItem });
		await request();
		return response.value;
	},

	async deleteDeleteConfig(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/XmlImporter/DeleteConfig/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postPreview(xmlImporterConfigEntity:XmlImporterConfigEntity)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/XmlImporter/Preview/${shared.firm.id}`,{ method:'POST', data:xmlImporterConfigEntity });
		await request();
		return response.value;
	},

	async postExecute(configId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/XmlImporter/Execute/${shared.firm.id}/${configId}`,{ method:'POST' });
		await request();
		return response.value;
	}
}
});
