export const StokPluUtils = {
  splitPluBarcode(barcode:string) {
    const isPluBarcode = this.isPluBarcode(barcode);
    if (isPluBarcode){
    const kod = parseInt(barcode.substr(2, 5)).toString();
    const miktar = parseInt(barcode.substr(7, 5));

    return {
      stokKodu: kod,
      miktar: miktar / 1000
    };
    }
    return undefined;
  },
   isPluBarcode(barkod:string){
    // Barkod 13 haneli

    // 27,28,29 ile başlaması gerekir
    // Barkod sayılardan oluşmalıdır

    if (barkod && barkod.length == 13 && this.isNumeric(barkod) &&
      (barkod.startsWith('27') || barkod.startsWith('28') || barkod.startsWith('29')))
    {
      return  true;
    }
    return false;
  },

   isNumeric(numericText:any) {
    return !isNaN(parseFloat(numericText)) && isFinite(numericText as number);
  }


}