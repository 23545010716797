import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {RecordStatus} from '../models/RecordStatus'
import {IdName} from '../models/IdName'
import {SevkIslemTipi} from '../models/SevkIslemTipi'
import {SevkPaletItem} from '../models/SevkPaletItem'
import {UretimSevkEntity} from '../models/UretimSevkEntity'
import {FaturaHareketTip} from '../models/FaturaHareketTip'
import {FaturaTip} from '../models/FaturaTip'
import {FaturaUstBilgiItem} from '../models/FaturaUstBilgiItem'
import {TeslimatAdresItem} from '../models/TeslimatAdresItem'
import {FaturaSatirItem} from '../models/FaturaSatirItem'
import {FaturaAltBilgiItem} from '../models/FaturaAltBilgiItem'
import {DocumentItem} from '../models/DocumentItem'
import {PlanVadelerItem} from '../models/PlanVadelerItem'
import {FaturaEFaturaItem} from '../models/FaturaEFaturaItem'
import {SmsMailKargoGonderItem} from '../models/SmsMailKargoGonderItem'
import {FaturaView} from '../models/FaturaView'
import {SevkPaletItemView} from '../models/SevkPaletItemView'
import {UretimStokEntity} from '../models/UretimStokEntity'

export const useUretimSevkService = defineStore({
id: 'UretimSevkService',
actions: {


	async getGetir(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/Getir/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGenerateSevkNo()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/GenerateSevkNo/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/Liste/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postEkle(uretimSevkEntity:UretimSevkEntity)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/Ekle/${shared.firm.id}`,{ method:'POST', data:uretimSevkEntity });
		await request();
		return response.value;
	},

	async putGuncelle(uretimSevkEntity:UretimSevkEntity)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/Guncelle/${shared.firm.id}`,{ method:'PUT', data:uretimSevkEntity });
		await request();
		return response.value;
	},

	async putGuncelleFiyat(id:string,fiyat:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/GuncelleFiyat/${shared.firm.id}/${shared.firm.period}/${id}/${fiyat}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async putGuncelleSevkIslemTonaj(id:string,tonaj:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/GuncelleSevkIslemTonaj/${shared.firm.id}/${shared.firm.period}/${id}/${tonaj}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async putGuncelleSevkIslemAdet(paletId:string,adet:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/GuncelleSevkIslemAdet/${shared.firm.id}/${paletId}/${adet}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async putGuncelleEkHizmetFiyat(paletId:string,fiyat:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/GuncelleEkHizmetFiyat/${shared.firm.id}/${paletId}/${fiyat}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async putGuncelleEkHizmetAdet(paletId:string,adet:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/GuncelleEkHizmetAdet/${shared.firm.id}/${paletId}/${adet}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async putGuncelleHizmetMasraf(ıdName:IdName,id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/GuncelleHizmetMasraf/${shared.firm.id}/${id}`,{ method:'PUT', data:ıdName });
		await request();
		return response.value;
	},

	async putGuncellePlaka(id:string,plaka:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/GuncellePlaka/${shared.firm.id}/${id}/${plaka}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/Sil/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getGetPlakaSoforList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/GetPlakaSoforList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postSacFaturaEkle(faturaView:FaturaView,faturaHareketTip:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/SacFaturaEkle/${shared.firm.id}/${shared.firm.period}/${faturaHareketTip}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async putSacFaturaGuncelle(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/SacFaturaGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:faturaView });
		await request();
		return response.value;
	},

	async deleteSacFaturaSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/SacFaturaSil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postUretimSevkToFatura(liste:SevkPaletItemView[],cariId:string,cariUnvan:string,faturaHareketTip:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/UretimSevkToFatura/${shared.firm.id}/${shared.firm.period}/${cariId}/${cariUnvan}/${faturaHareketTip}`,{ method:'POST', data:liste });
		await request();
		return response.value;
	},

	async postUretimStoklarToSevkler(liste:UretimStokEntity[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/UretimStoklarToSevkler/${shared.firm.id}`,{ method:'POST', data:liste });
		await request();
		return response.value;
	},

	async postConvertUretimSevkReportView(liste:UretimSevkEntity[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimSevk/ConvertUretimSevkReportView/${shared.firm.id}`,{ method:'POST', data:liste });
		await request();
		return response.value;
	}
}
});
