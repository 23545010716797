
export enum KargoBoyutTip{
Dosya=1,
Mi=2,
Paket=3,
Koli=4
}

export const KargoBoyutTipList = [
{key:'Dosya', value:1, description:'Dosya'},
{key:'Mi', value:2, description:'Mi'},
{key:'Paket', value:3, description:'Paket'},
{key:'Koli', value:4, description:'Koli'}
]
