import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_ShowPasswordComponent = _resolveComponent("ShowPasswordComponent")!
  const _component_NCheckbox = _resolveComponent("NCheckbox")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!
  const _component_NAlert = _resolveComponent("NAlert")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!

  return (_openBlock(), _createBlock(_component_NFormGrid, { cols: "1fr" }, {
    default: _withCtx(() => [
      (_ctx.model.id && _ctx.model.id?.length > 0)
        ? (_openBlock(), _createBlock(_component_NLoadingPanel, {
            key: 0,
            action: _ctx.b2bLoad
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NFormField, {
                "grid-cols": "100px 250px",
                label: "Ad Soyad"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.modelB2BLogin.adSoyad,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelB2BLogin.adSoyad) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "100px 250px",
                label: "E-Posta"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.modelB2BLogin.userName,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelB2BLogin.userName) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ShowPasswordComponent, {
                "grid-cols": "100px 250px 20px",
                modelValue: _ctx.modelB2BLogin.password,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelB2BLogin.password) = $event))
              }, null, 8, ["modelValue"]),
              _createVNode(_component_NFormField, {
                "grid-cols": "100px 250px",
                label: " "
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NCheckbox, {
                    modelValue: _ctx.modelB2BLogin.isAktif,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelB2BLogin.isAktif) = $event)),
                    label: "Aktif"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "100px 250px",
                label: " "
              }, {
                default: _withCtx(() => [
                  (_ctx.modelB2BLogin.id && _ctx.modelB2BLogin.id?.length > 0)
                    ? (_openBlock(), _createBlock(_component_NButton, {
                        key: 0,
                        type: "warning",
                        click: _ctx.b2bSave,
                        icon: "save",
                        size: "small"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Güncelle ")
                        ]),
                        _: 1
                      }, 8, ["click"]))
                    : (_openBlock(), _createBlock(_component_NButton, {
                        key: 1,
                        type: "success",
                        click: _ctx.b2bSave,
                        icon: "save",
                        size: "normal"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Kullanıcı Oluştur")
                        ]),
                        _: 1
                      }, 8, ["click"]))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["action"]))
        : (_openBlock(), _createBlock(_component_NAlert, { key: 1 }, {
            default: _withCtx(() => [
              _createTextVNode("Bu işlemleri yapabilmek için cari ekleme işlemini tamamlayınız.")
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}