
import {computed, defineComponent, reactive, toRefs} from "vue";
import {StokDevirItem} from "@/api/models/StokDevirItem";
import NGrid from "@/components/Grid/NGrid.vue";
import {StokSimpleView} from "@/api/models/StokSimpleView";
import {SacUretimFisView} from "@/api/models/SacUretimFisView";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";

export default defineComponent({
    components: {},
    props: {
        stok: {
            type: Object
        },
        modelValue: {
            type: Object
        },

    },
    setup(props) {

        const localState = reactive({
            stokRef: computed(() => props.stok as StokSimpleView),
            model: computed(() => props.modelValue as SacUretimFisView)


        });

        return {
            ...toRefs(localState)
        };
    }
});

