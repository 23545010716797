import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {ReportType} from '../models/ReportType'
import {ReportExportTypes} from '../models/ReportExportTypes'
import {RecordStatus} from '../models/RecordStatus'
import {ReportEntity} from '../models/ReportEntity'

export const useRaporEkraniService = defineStore({
id: 'RaporEkraniService',
actions: {


	async getGetAll()
	{
		const { request, response } = useApi(`/api/RaporEkrani/GetAll`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetAllByType(reportType:number)
	{
		const { request, response } = useApi(`/api/RaporEkrani/GetAllByType/${reportType}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetirBase64(id:string)
	{
		const { request, response } = useApi(`/api/RaporEkrani/GetirBase64/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetirById(id:string)
	{
		const { request, response } = useApi(`/api/RaporEkrani/GetirById/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getIsDirectPrint(reportName:string)
	{
		const { request, response } = useApi(`/api/RaporEkrani/IsDirectPrint/${reportName}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postGetir(options:any,tipi:number)
	{
		const { request, response } = useApi(`/api/RaporEkrani/Getir/${tipi}`,{ method:'POST' });
		await request();
		return response.value;
	},

	async postRenderPreview(options:any,reportName:string,contentType:number,generateFileName:boolean)
	{
		const { request, response } = useApi(`/api/RaporEkrani/RenderPreview/${contentType}/${generateFileName}/${reportName}`,{ method:'POST' });
		await request();
		return response.value;
	},

	async putGuncelleDirectPrint(id:string,directPrint:boolean)
	{
		const { request, response } = useApi(`/api/RaporEkrani/GuncelleDirectPrint/${id}/${directPrint}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async deleteSablonSil(id:string)
	{
		const { request, response } = useApi(`/api/RaporEkrani/SablonSil/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postImportReportFromJson(reportEntity:ReportEntity,reportType:number)
	{
		const { request, response } = useApi(`/api/RaporEkrani/ImportReportFromJson/${reportType}`,{ method:'POST', data:reportEntity });
		await request();
		return response.value;
	},

	async getDefaultInstall()
	{
		const { request, response } = useApi(`/api/RaporEkrani/DefaultInstall`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
