import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {UserRoleGroupView} from '../models/UserRoleGroupView'

export const useRoleService = defineStore({
id: 'RoleService',
actions: {


	async getDefault()
	{
		const { request, response } = useApi(`/api/role/Default`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetRoles()
	{
		const { request, response } = useApi(`/api/role/GetRoles`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getMyRoles()
	{
		const { request, response } = useApi(`/api/role/MyRoles`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postAddRoleGroup(userRoleGroupView:UserRoleGroupView)
	{
		const { request, response } = useApi(`/api/role/AddRoleGroup`,{ method:'POST', data:userRoleGroupView });
		await request();
		return response.value;
	},

	async putUpdateRoleGroup(userRoleGroupView:UserRoleGroupView)
	{
		const { request, response } = useApi(`/api/role/UpdateRoleGroup`,{ method:'PUT', data:userRoleGroupView });
		await request();
		return response.value;
	},

	async deleteDeleteRoleGroup(id:string)
	{
		const { request, response } = useApi(`/api/role/DeleteRoleGroup/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getGetRoleGroupList()
	{
		const { request, response } = useApi(`/api/role/GetRoleGroupList`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetRoleGroup(id:string)
	{
		const { request, response } = useApi(`/api/role/GetRoleGroup/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetRoleGroupUser(id:string)
	{
		const { request, response } = useApi(`/api/role/GetRoleGroupUser/${id}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
