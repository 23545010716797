import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {DataSourceLoadOptionsItem} from '../models/DataSourceLoadOptionsItem'
import {RecordStatus} from '../models/RecordStatus'
import {PazaryeriConfigMiktarItem} from '../models/PazaryeriConfigMiktarItem'
import {HepsiBuradaConfigEntity} from '../models/HepsiBuradaConfigEntity'
import {Price} from '../models/Price'
import {LineItem} from '../models/LineItem'
import {HepsiBuradaListingPazaryeriItem} from '../models/HepsiBuradaListingPazaryeriItem'

export const useHepsiBuradaService = defineStore({
id: 'HepsiBuradaService',
actions: {


	async getKategoriler(cacheDay:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/HepsiBurada/Kategoriler/${shared.firm.id}/${cacheDay}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getKategorilerTree(cacheDay:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/HepsiBurada/KategorilerTree/${shared.firm.id}/${cacheDay}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getKategoriOzellikleri(cacheDay:number,categoryId:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/HepsiBurada/KategoriOzellikleri/${shared.firm.id}/${cacheDay}/${categoryId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getKategoriOzellikDegerleri(cacheDay:number,categoryId:number,attributeId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/HepsiBurada/KategoriOzellikDegerleri/${shared.firm.id}/${cacheDay}/${categoryId}/${attributeId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getProductsSearch(text:string)
	{
		const { request, response } = useApi(`/api/HepsiBurada/Products/Search/${text}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getProductsBuyBox(hbSkus:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/HepsiBurada/Products/BuyBox/${shared.firm.id}/${hbSkus}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetOrder(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/HepsiBurada/GetOrder/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getSiparisler()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/HepsiBurada/Siparisler/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postListDataGrid(dataSourceLoadOptionsItem:DataSourceLoadOptionsItem)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/HepsiBurada/ListDataGrid/${shared.firm.id}`,{ method:'POST', data:dataSourceLoadOptionsItem });
		await request();
		return response.value;
	},

	async postAyarKaydet(hepsiBuradaConfigEntity:HepsiBuradaConfigEntity)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/HepsiBurada/AyarKaydet/${shared.firm.id}`,{ method:'POST', data:hepsiBuradaConfigEntity });
		await request();
		return response.value;
	},

	async getAyarGetir()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/HepsiBurada/AyarGetir/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getAktif()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/HepsiBurada/Aktif/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getReplay(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/HepsiBurada/Replay/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async optionsCreateStokView(lineItem:LineItem)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/HepsiBurada/CreateStokView/${shared.firm.id}`,{ method:'OPTIONS', data:lineItem });
		await request();
		return response.value;
	},

	async deleteSatisFaturaSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/HepsiBurada/SatisFaturaSil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getKargoKodu(faturaId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/HepsiBurada/KargoKodu/${shared.firm.id}/${shared.firm.period}/${faturaId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async putUpdatePrintStatus(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/HepsiBurada/UpdatePrintStatus/${shared.firm.id}/${id}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async putChangeMatch(stokId:string,index:number,barcode:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/HepsiBurada/ChangeMatch/${shared.firm.id}/${shared.firm.period}/${stokId}/${index}/${barcode}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async putApplyMatch(liste:HepsiBuradaListingPazaryeriItem[],stokId:string,index:number,hbSku:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/HepsiBurada/ApplyMatch/${shared.firm.id}/${shared.firm.period}/${stokId}/${index}/${hbSku}`,{ method:'PUT', data:liste });
		await request();
		return response.value;
	}
}
});
