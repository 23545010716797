import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_DxDateBox = _resolveComponent("DxDateBox")!
  const _component_NGrid = _resolveComponent("NGrid")!

  return (_openBlock(), _createBlock(_component_NGrid, { cols: "1fr 1fr 1fr 2fr" }, {
    default: _withCtx(() => [
      _createVNode(_component_DxTextBox, {
        label: "Evrak No",
        "label-mode": "floating",
        value: _ctx.model.evrakNo,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.evrakNo) = $event))
      }, null, 8, ["value"]),
      _createVNode(_component_DxDateBox, {
        label: "Tarih",
        "label-mode": "floating",
        value: _ctx.model.tarih,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.tarih) = $event)),
        "use-mask-behavior": true
      }, null, 8, ["value"]),
      _createVNode(_component_DxDateBox, {
        label: "Vade",
        "label-mode": "floating",
        value: _ctx.model.vade,
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.vade) = $event)),
        "use-mask-behavior": true
      }, null, 8, ["value"]),
      _createVNode(_component_DxTextBox, {
        label: "Açıklama",
        "label-mode": "floating",
        value: _ctx.model.aciklama,
        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.aciklama) = $event))
      }, null, 8, ["value"])
    ]),
    _: 1
  }))
}