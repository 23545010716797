import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","align-content":"center","margin-left":"4px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_DxDateBox = _resolveComponent("DxDateBox")!
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NListView = _resolveComponent("NListView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NButton, {
      block: true,
      size: "small",
      icon: "plus",
      type: "primary",
      onClick: _ctx.add
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.addTextRef), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_NListView, {
      items: _ctx.modelRef,
      style: {"max-height":"600px"},
      "model-value": -1
    }, {
      item: _withCtx(({item,index}) => [
        _createVNode(_component_NGrid, {
          cols: "auto 120px auto",
          gap: "2px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: item.no,
              "onUpdate:modelValue": ($event: any) => ((item.no) = $event),
              placeholder: _ctx.placeholderRef
            }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]),
            _createVNode(_component_DxDateBox, {
              value: item.tarih,
              "onUpdate:value": ($event: any) => ((item.tarih) = $event)
            }, null, 8, ["value", "onUpdate:value"]),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_NIcon, {
                title: "Sil",
                stroke: "#C00",
                style: {"align-self":"flex-end"},
                type: "trash",
                size: "16",
                onClick: ($event: any) => (_ctx.remove(index))
              }, null, 8, ["onClick"])
            ])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["items"])
  ], 64))
}