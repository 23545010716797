import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {RecordStatus} from '../models/RecordStatus'
import {Liste} from '../models/Liste'
import {StokTipOzellikListe} from '../models/StokTipOzellikListe'
import {BirimFiyatGrupView} from '../models/BirimFiyatGrupView'
import {BirimView} from '../models/BirimView'
import {FiyatGrupView} from '../models/FiyatGrupView'
import {GiderYerleriView} from '../models/GiderYerleriView'
import {UretimTanimView} from '../models/UretimTanimView'

export const useListelerService = defineStore({
id: 'ListelerService',
actions: {


	async getDefaultInstall(firmaKod:string)
	{
		const { request, response } = useApi(`/api/listeler/DefaultInstall/${firmaKod}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCariHesapListesi()
	{
		const { request, response } = useApi(`/api/listeler/CariHesapListesi`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postCariHesapekle(liste:Liste)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/listeler/CariHesapekle/${shared.firm.id}`,{ method:'POST', data:liste });
		await request();
		return response.value;
	},

	async putCariHesapGuncelle(liste:Liste)
	{
		const { request, response } = useApi(`/api/listeler/CariHesapGuncelle`,{ method:'PUT', data:liste });
		await request();
		return response.value;
	},

	async deleteCariHesapSil(id:string)
	{
		const { request, response } = useApi(`/api/listeler/CariHesapSil/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getStokTipOzellikListe(tip:number)
	{
		const { request, response } = useApi(`/api/listeler/StokTipOzellikListe/${tip}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postStokTipOzellikEkle(stokTipOzellikListe:StokTipOzellikListe)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/listeler/StokTipOzellikEkle/${shared.firm.id}`,{ method:'POST', data:stokTipOzellikListe });
		await request();
		return response.value;
	},

	async putStokTipOzellikGuncelle(stokTipOzellikListe:StokTipOzellikListe)
	{
		const { request, response } = useApi(`/api/listeler/StokTipOzellikGuncelle`,{ method:'PUT', data:stokTipOzellikListe });
		await request();
		return response.value;
	},

	async deleteStokTipOzellikSil(id:string)
	{
		const { request, response } = useApi(`/api/listeler/StokTipOzellikSil/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getBirimFiyatGrupListe()
	{
		const { request, response } = useApi(`/api/listeler/BirimFiyatGrupListe`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postBirimFiyatGrupEkle(birimFiyatGrupView:BirimFiyatGrupView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/listeler/BirimFiyatGrupEkle/${shared.firm.id}`,{ method:'POST', data:birimFiyatGrupView });
		await request();
		return response.value;
	},

	async putBirimFiyatGrupGuncelle(birimFiyatGrupView:BirimFiyatGrupView)
	{
		const { request, response } = useApi(`/api/listeler/BirimFiyatGrupGuncelle`,{ method:'PUT', data:birimFiyatGrupView });
		await request();
		return response.value;
	},

	async deleteBirimFiyatGrupSil(id:string)
	{
		const { request, response } = useApi(`/api/listeler/BirimFiyatGrupSil/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getBirimListesi()
	{
		const { request, response } = useApi(`/api/listeler/BirimListesi`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postBirimEkle(birimView:BirimView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/listeler/BirimEkle/${shared.firm.id}`,{ method:'POST', data:birimView });
		await request();
		return response.value;
	},

	async putBirimGuncelle(birimView:BirimView)
	{
		const { request, response } = useApi(`/api/listeler/BirimGuncelle`,{ method:'PUT', data:birimView });
		await request();
		return response.value;
	},

	async deleteBirimSil(id:string)
	{
		const { request, response } = useApi(`/api/listeler/BirimSil/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getFiyatGruplari()
	{
		const { request, response } = useApi(`/api/listeler/FiyatGruplari`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postFiyatGrupEkle(fiyatGrupView:FiyatGrupView)
	{
		const { request, response } = useApi(`/api/listeler/FiyatGrupEkle`,{ method:'POST', data:fiyatGrupView });
		await request();
		return response.value;
	},

	async putFiyatGrupGuncelle(fiyatGrupView:FiyatGrupView)
	{
		const { request, response } = useApi(`/api/listeler/FiyatGrupGuncelle`,{ method:'PUT', data:fiyatGrupView });
		await request();
		return response.value;
	},

	async deleteFiyatGrupSil(id:string)
	{
		const { request, response } = useApi(`/api/listeler/FiyatGrupSil/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getGiderYerleri()
	{
		const { request, response } = useApi(`/api/listeler/GiderYerleri`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postGiderYeriEkle(giderYerleriView:GiderYerleriView)
	{
		const { request, response } = useApi(`/api/listeler/GiderYeriEkle`,{ method:'POST', data:giderYerleriView });
		await request();
		return response.value;
	},

	async putGiderYeriGuncelle(giderYerleriView:GiderYerleriView)
	{
		const { request, response } = useApi(`/api/listeler/GiderYeriGuncelle`,{ method:'PUT', data:giderYerleriView });
		await request();
		return response.value;
	},

	async deleteGiderYeriSil(id:string)
	{
		const { request, response } = useApi(`/api/listeler/GiderYeriSil/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getUretimTanimlari()
	{
		const { request, response } = useApi(`/api/listeler/UretimTanimlari`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postUretimTanimEkle(uretimTanimView:UretimTanimView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/listeler/UretimTanimEkle/${shared.firm.id}`,{ method:'POST', data:uretimTanimView });
		await request();
		return response.value;
	},

	async putUretimTanimGuncelle(uretimTanimView:UretimTanimView)
	{
		const { request, response } = useApi(`/api/listeler/UretimTanimGuncelle`,{ method:'PUT', data:uretimTanimView });
		await request();
		return response.value;
	},

	async deleteUretimTanimSil(id:string)
	{
		const { request, response } = useApi(`/api/listeler/UretimTanimSil/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getTevkifatOranListe()
	{
		const { request, response } = useApi(`/api/listeler/TevkifatOranListe`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getKdvIstisnaListe()
	{
		const { request, response } = useApi(`/api/listeler/KdvIstisnaListe`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getFaturaSerileri()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/listeler/FaturaSerileri/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postFaturaSeriEkle(tip:number,seri:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/listeler/FaturaSeriEkle/${shared.firm.id}/${tip}/${seri}`,{ method:'POST' });
		await request();
		return response.value;
	},

	async putFaturaSeriGuncelle(id:string,tip:number,seri:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/listeler/FaturaSeriGuncelle/${shared.firm.id}/${id}/${tip}/${seri}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async deleteFaturaSeriSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/listeler/FaturaSeriSil/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getVergiListesi()
	{
		const { request, response } = useApi(`/api/listeler/VergiListesi`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
