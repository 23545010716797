import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {KurumTip} from '../models/KurumTip'
import {Doviz} from '../models/Doviz'
import {CariTip} from '../models/CariTip'
import {IdName} from '../models/IdName'
import {AdresItem} from '../models/AdresItem'
import {KvkkTip} from '../models/KvkkTip'
import {DocumentItem} from '../models/DocumentItem'
import {SosyalAglar} from '../models/SosyalAglar'
import {YetkiliItem} from '../models/YetkiliItem'
import {BankaItem} from '../models/BankaItem'
import {CariOzellikItem} from '../models/CariOzellikItem'
import {PKAdresItem} from '../models/PKAdresItem'
import {CariView} from '../models/CariView'
import {CariExcelView} from '../models/CariExcelView'
import {RecordStatus} from '../models/RecordStatus'
import {AdresTip} from '../models/AdresTip'
import {CariTopluIslemView} from '../models/CariTopluIslemView'

export const useCariService = defineStore({
id: 'CariService',
actions: {


	async getDefaultInstall(firmaKod:string)
	{
		const { request, response } = useApi(`/api/cari/DefaultInstall/${firmaKod}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCariHareketReportDefault(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/CariHareketReportDefault/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCariHesapOzetiReportDefault(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/CariHesapOzetiReportDefault/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetir(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/Getir/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/View/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getViewInsert()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/ViewInsert/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCariKod()
	{
		const { request, response } = useApi(`/api/cari/CariKod`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getTumListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/TumListe/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getTumListeOzet(isB2BCarileri:boolean)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/TumListeOzet/${shared.firm.id}/${isB2BCarileri}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGrupSirketList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/GrupSirketList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getIdNameCariList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/IdNameCariList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getTumCarilerFiltre(fields:string,size:number,offset:number,sortby:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/TumCarilerFiltre/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postEkle(cariView:CariView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/Ekle/${shared.firm.id}`,{ method:'POST', data:cariView });
		await request();
		return response.value;
	},

	async postEkleExcel(cariExcelView:CariExcelView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/EkleExcel/${shared.firm.id}`,{ method:'POST', data:cariExcelView });
		await request();
		return response.value;
	},

	async putGuncelle(cariView:CariView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/Guncelle/${shared.firm.id}`,{ method:'PUT', data:cariView });
		await request();
		return response.value;
	},

	async deleteSil(cariId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/Sil/${shared.firm.id}/${shared.firm.period}/${cariId}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getGrup1()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/Grup1/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGrup2(grup1:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/Grup2/${shared.firm.id}/${grup1}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGrup3(grup1:string,grup2:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/Grup3/${shared.firm.id}/${grup1}/${grup2}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGrup4(grup1:string,grup2:string,grup3:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/Grup4/${shared.firm.id}/${grup1}/${grup2}/${grup3}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async putPkAdresiKaydet(liste:PKAdresItem[],id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/PkAdresiKaydet/${shared.firm.id}/${id}`,{ method:'PUT', data:liste });
		await request();
		return response.value;
	},

	async deleteAllPkAdres()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/AllPkAdres/${shared.firm.id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async putUpdateAddress(adresItem:AdresItem,cariId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/UpdateAddress/${shared.firm.id}/${cariId}`,{ method:'PUT', data:adresItem });
		await request();
		return response.value;
	},

	async deleteDeleteAddress(cariId:string,adresId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/DeleteAddress/${shared.firm.id}/${cariId}/${adresId}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getGetAllAddress(cariId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/GetAllAddress/${shared.firm.id}/${cariId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetOneAddress(cariId:string,adresId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/GetOneAddress/${shared.firm.id}/${cariId}/${adresId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postAddAddress(adresItem:AdresItem,cariId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/AddAddress/${shared.firm.id}/${cariId}`,{ method:'POST', data:adresItem });
		await request();
		return response.value;
	},

	async putTopluIslemGuncelle(cariTopluIslemView:CariTopluIslemView,cariId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/TopluIslemGuncelle/${shared.firm.id}/${cariId}`,{ method:'PUT', data:cariTopluIslemView });
		await request();
		return response.value;
	},

	async getYeniCariKategoriNo(kategoriId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cari/YeniCariKategoriNo/${shared.firm.id}/${kategoriId}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
