import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {FisTip} from '../models/FisTip'
import {BasitUretimDurum} from '../models/BasitUretimDurum'
import {DocumentItem} from '../models/DocumentItem'
import {BasitUretimItemView} from '../models/BasitUretimItemView'
import {BasitUretimHizmetItem} from '../models/BasitUretimHizmetItem'
import {IdName} from '../models/IdName'
import {BasitUretimView} from '../models/BasitUretimView'

export const useBasitUretimService = defineStore({
id: 'BasitUretimService',
actions: {


	async postEkle(basitUretimView:BasitUretimView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BasitUretim/Ekle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:basitUretimView });
		await request();
		return response.value;
	},

	async getGenelUretimId(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BasitUretim/GenelUretimId/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async putGuncelle(basitUretimView:BasitUretimView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BasitUretim/Guncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:basitUretimView });
		await request();
		return response.value;
	},

	async getReceteListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BasitUretim/ReceteListe/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getPlanListesi()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BasitUretim/PlanListesi/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BasitUretim/Liste/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BasitUretim/View/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BasitUretim/Sil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getStokReceteListesi(stokId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BasitUretim/StokReceteListesi/${shared.firm.id}/${shared.firm.period}/${stokId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getStokReceteItemListe(stokId:string,itemId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BasitUretim/StokReceteItemListe/${shared.firm.id}/${shared.firm.period}/${stokId}/${itemId}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
