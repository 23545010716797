import { defineStore } from 'pinia';
import {useN11ShipmentService} from "@/api/services/N11ShipmentService";


export const useN11ShipmentServiceCache = defineStore({
	id: 'N11ShipmentServiceCache',

	state:()=>({
		data:undefined
	}),
	actions: {

	async getGetir(cacheDay:number)
	{
		if (cacheDay > 0 && this.data)
			return this.data;
		return useN11ShipmentService().getGetir(cacheDay)
	}
}
});
