
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import NButton from "@/components/Button/NButton.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import {ImporterEntity} from "@/api/models/ImporterEntity";
import {useImporterService} from "@/api/services/ImporterService";
import {ImporterTypeList} from "@/api/models/ImporterType";
import NInput from "@/components/Input/NInput.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NAlert from "@/components/Alert/NAlert.vue";

export default defineComponent({
  components: {NAlert, NFormField, NInput, NGrid,  NButton},
  props: {
    tip: {
      type: Number
    },
    show: {
      type: Boolean,
      default: false
    },
    id:{
      type:String
    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      model: ref({} as ImporterEntity),
      data:ref([] as any[]),
      list:ref([] as ImporterEntity[]),
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val)
      }),
      selectedValue:ref(),
      subTitle: ref("-"),
      title: "İçe aktarma şablonu formu",
      tipRef:computed(() => props.tip),
      showYeniDialog:ref(false)
    });

    async function load() {

      if (isUpdateMode()){
        localState.model = await useImporterService().getİmportGetDefaultUpdate(props.id as string)
      }else{
        localState.model = await useImporterService().getİmportGetDefaultNew(props.tip as number);
      }

      localState.subTitle = ImporterTypeList.find(a => a.value == localState.model.type)?.description as string;

    }

    async function save() {
      if (isUpdateMode()){
        await useImporterService().putİmportUpdate(localState.model)
      }else{
        await useImporterService().postİmportInsert(localState.model)
      }
      localState.showRef = false;
    }


    function isUpdateMode(){
      return props.id && props.id.length > 1
    }

    async function remove(){
      await useImporterService().deleteİmportDelete(props.id as string)
      localState.showRef = false;
    }

    function getDataTypeLabel(type:string) {
      //'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime'
      switch (type) {
        case 'string':
          return "Metin";
        case 'number':
          return "Sayı";
        case 'date':
          return "Tarih";
        case 'boolean':
          return "Evet/Hayır";
        case 'object':
          return "Nesne";
        case 'datetime':
          return "Tarih/Saat";
      }
      return  "-"
    }

    load();

    return {
      ...toRefs(localState),
      load,
      save,
      remove,
      isUpdateMode,
      getDataTypeLabel

    };
  }
});
