
import { defineComponent, reactive, ref, toRefs, watch } from "vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NIconIonic from "@/components/NIconIonic.vue";
import NButton from "@/components/Button/NButton.vue";
import NSwitch from "@/components/Switch/NSwitch.vue";
import {useHizliSatisTabsStore} from "@/modules/HizliSatis/store/HizliSatisTabsStore";
import KasaSelectBox from "@/modules/Kasa/components/KasaSelectBox.vue";
import KrediKartiSelectBox from "@/modules/KrediKarti/components/KrediKartiSelectBox.vue";
import BankaSelectBox from "@/modules/Banka/components/BankaSelectBox.vue";
import {HizliSatisUtils} from "@/modules/HizliSatis/utils/HizliSatisUtils";
import {HizliSatisOdemeTip} from "@/modules/HizliSatis/model/HizliSatisOdemeTip";
import PosSelectBox from "@/modules/Pos/components/PosSelectBox.vue";
import NTag from "@/components/Tag/NTag.vue";

export default defineComponent({
  components: {
    NTag,
    PosSelectBox,
    BankaSelectBox, KasaSelectBox, NSwitch, NButton, NIconIonic, NFormGrid, NFormField, NGrid},
  setup() {
    const localState = reactive({
      store:useHizliSatisTabsStore(),
      tab:useHizliSatisTabsStore().selectedTab,
      model:useHizliSatisTabsStore().selectedTab.model,
      utils:ref(HizliSatisUtils),
      hizliSatisOdemeTip:HizliSatisOdemeTip
    })

    watch(() => localState.model.cokluTahsilat, (val) => {
      if (val == true)
        localState.model.veresiye = false;



      localState.model.posTutar = 0;
      localState.model.havaleTutar = 0;
      localState.model.kasaTutar = 0;
      localState.model.kasa = false;
      localState.model.pos = false;
      localState.model.banka = false;
    });


    async function save(tip:HizliSatisOdemeTip) {
      localState.tab.odemeTip = tip;
      await localState.store.save()
    }

    return {
      ...toRefs(localState),
      save
    }
  }
})
