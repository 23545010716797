import { NModuleRoute } from "@/models/route.model";

export const PersonelModuleRoutes: NModuleRoute[] = [
    {
        id: "PersonelModule",
        order: 7,
        displayName: "Personel",
        icon: {
            type: "user"
        },
        children: [
            {
                id: "Personel",
                order: 0,
                displayName: "Personel İşlemleri",
                requiredRoles:{
                    readRoles:['Personel.Liste', 'Personel.Hareket.Liste'],
                    insertRoles:['Personel.Ekle', 'Personel.Hareket.Ekle'],
                    updateRoles:['Personel.Guncelle', 'Personel.Hareket.Guncelle'],
                    deleteRoles:['Personel.Sil', 'Personel.Hareket.Sil']
                },
                icon: {
                    type: "users"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Personel" */ "../pages/PersonelIslemeriPage.vue"
                        )
            },
            {
                id: "PersonelHakedisOdemeleri",
                order: 0,
                displayName: "Hakediş Ödemeleri",
                requiredRoles:{
                    readRoles:['Personel.Liste'],
                    insertRoles:['Personel.Ekle'],
                    updateRoles:['Personel.Guncelle'],
                    deleteRoles:['Personel.Sil']
                },
                icon: {
                    type: "credit-card"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Personel" */ "../pages/PersonelHakedisOdemeleriPage.vue"
                        )
            },
            {
                id: "PersonelIsTakip",
                order: 0,
                displayName: "Personel İş Takibi",
                requiredRoles:{
                    readRoles:['Personel.Liste'],
                    insertRoles:['Personel.Ekle'],
                    updateRoles:['Personel.Guncelle'],
                    deleteRoles:['Personel.Sil']
                },
                icon: {
                    type: "briefcase"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Personel" */ "../../IsTakip/pages/PersonelIsTakipPages.vue"
                        )
            },
            {
                id: "PersonelListesiPage",
                order: 0,
                displayName: "Personel Listesi",
                requiredRoles:{
                    readRoles:['Personel.Liste'],
                    insertRoles:['Personel.Ekle'],
                    updateRoles:['Personel.Guncelle'],
                    deleteRoles:['Personel.Sil']
                },
                icon: {
                    type: "users"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Personel Listesi" */ "../pages/PersonelListesiPage.vue"
                        )
            },
        ]
    }
];
