
import { computed, defineComponent } from "vue";
import { Colors } from "@/models";
import { usePopupService } from "@/services/popupService";
export default defineComponent({
  name: "NAlert",
  props: {
    type: {
      type: String,
      default: Colors.PRIMARY,
      validator: (value: Colors) =>
        [
          Colors.PRIMARY,
          Colors.SUCCESS,
          Colors.DANGER,
          Colors.WARNING
        ].includes(value)
    },
    showIcon: { type: Boolean, default: true },
    closable: { type: Boolean, default: false },
    visible: { type: Boolean, default: true },
    title: { type: String },
    body: { type: String },
    duration: { type: Number },
    onClose: { type: Function },
    id: { type: String },
    padding:{type:String, default:'12px 18px'}
  },
  setup(props, { emit }) {
    const popupService = usePopupService();

    const typeIcon = computed(() => ({
      primary: "info",
      success: "check",
      warning: "alert-triangle",
      danger: "x"
    }));

    async function destroy(id:string) {
      await popupService.closeNotification(id || "");
    }

    function startTimer() {
      const id = props.id as string;
      if (!props.duration) return;
      setTimeout(() => {
        destroy(id);
      }, props.duration);
    }

    function clickCross() {
      emit("on-close");
      destroy(props.id as string);
    }

    startTimer();

    return {
      clickCross,
      typeIcon
    };
  }
});
