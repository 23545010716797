import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HizmetMasrafSelectBox = _resolveComponent("HizmetMasrafSelectBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_DxDateBox = _resolveComponent("DxDateBox")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NSelectBox = _resolveComponent("NSelectBox")!
  const _component_GiderYeriSelectBox = _resolveComponent("GiderYeriSelectBox")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_SubeSelectBox = _resolveComponent("SubeSelectBox")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_TutarsalBilgiler = _resolveComponent("TutarsalBilgiler")!
  const _component_CokluDokuman = _resolveComponent("CokluDokuman")!
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NTextarea = _resolveComponent("NTextarea")!
  const _component_TaksitlendirmePanel = _resolveComponent("TaksitlendirmePanel")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    modelValue: _ctx.showRef,
    "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.showRef) = $event)),
    width: "700px",
    "closable-mask": false,
    tabs: [
        {id:'1', label:'Evrak Bilgileri'} ,
        {id:'3', label:'Dökümanlar'} ,
        {id:'4', label:'Gönder',icon:'send', showLabel:true},
        {id:'4', label:'Taksitlendirme', showLabel:true ,  disabled : _ctx.menuRef.hareketTipi != _ctx.krediKartiHareketTip.KrediKartiIleMasrafOdeme }
        ],
    "selected-tab": _ctx.selectedTab,
    "onUpdate:selectedTab": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.selectedTab) = $event))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.menu.name), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_NLoadingPanel, {
        title: "Yükleniyor...",
        action: _ctx.load
      }, {
        default: _withCtx(() => [
          (_ctx.selectedTab === 0 )
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_NFormGrid, {
                  cols: "1fr 1fr",
                  gap: "0"
                }, {
                  default: _withCtx(() => [
                    (_ctx.menuRef.refrenceType == _ctx.menuReferansTip.Hizmet_Masraf)
                      ? (_openBlock(), _createBlock(_component_NFormField, {
                          key: 0,
                          span: "2",
                          "grid-cols": "70px 1fr",
                          label: "Hizmet/Masraf"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_HizmetMasrafSelectBox, {
                              "cari-id": _ctx.model.cariId,
                              "onUpdate:cariId": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.cariId) = $event)),
                              kdv: _ctx.model.kdv,
                              "onUpdate:kdv": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.kdv) = $event)),
                              "hizmet-adi": _ctx.model.referansAd,
                              "onUpdate:hizmetAdi": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.referansAd) = $event)),
                              "cari-name": _ctx.model.cariName,
                              "onUpdate:cariName": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.cariName) = $event)),
                              modelValue: _ctx.model.referansId,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.referansId) = $event))
                            }, null, 8, ["cari-id", "kdv", "hizmet-adi", "cari-name", "modelValue"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_NFormField, {
                      "grid-cols": "70px 1fr",
                      label: "Tarih"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxDateBox, {
                          value: _ctx.model.tarih,
                          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.tarih) = $event)),
                          onValueChanged: _ctx.evrakTarihiChanged
                        }, null, 8, ["value", "onValueChanged"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      "grid-cols": "70px 1fr",
                      label: "Vade"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxDateBox, {
                          value: _ctx.model.vade,
                          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.vade) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      "grid-cols": "70px 1fr",
                      label: "Evrak No"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NInput, {
                          type: "text",
                          modelValue: _ctx.model.evrakNo,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.evrakNo) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      "grid-cols": "70px 1fr",
                      label: "Tip"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NSelectBox, {
                          items: _ctx.genelResmiMuhaseRef,
                          "value-field": "value",
                          "label-field": "description",
                          modelValue: _ctx.model.genelResmiMuhasebeTip,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.genelResmiMuhasebeTip) = $event))
                        }, null, 8, ["items", "modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      "grid-cols": "70px 1fr",
                      label: "Açıklama",
                      span: "2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NInput, {
                          type: "text",
                          modelValue: _ctx.model.aciklama,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.aciklama) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    (_ctx.menuRef.refrenceType === _ctx.menuReferansTip.Hizmet_Masraf)
                      ? (_openBlock(), _createBlock(_component_NFormField, {
                          key: 1,
                          "grid-cols": "80px 1fr",
                          label: "Gider Yeri",
                          span: "2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_GiderYeriSelectBox, {
                              modelValue: _ctx.model.giderYeri,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.giderYeri) = $event))
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_NGrid, {
                      cols: "1fr 0.7fr 1fr",
                      style: {"grid-column":"span 2"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NFormField, {
                          "grid-cols": "80px 1fr",
                          label: "Kdv Tutar"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_DxNumberBox, {
                              value: _ctx.model.kdvTutar,
                              "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.kdvTutar) = $event)),
                              format: "#,##0.####"
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_NFormField, {
                          "grid-cols": "80px 1fr",
                          label: "Kdv"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_DxNumberBox, {
                              value: _ctx.model.kdv,
                              "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.kdv) = $event)),
                              "value-change-event": "keyup"
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_NFormField, {
                          label: "Şube",
                          "grid-cols": "80px 1fr"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_SubeSelectBox, {
                              modelValue: _ctx.model.subeId,
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.subeId) = $event))
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_TutarsalBilgiler, {
                  style: {"margin-top":"15px"},
                  kurlar: _ctx.kurlarRef,
                  "hareket-id": _ctx.model.id,
                  "onUpdate:hareketId": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.id) = $event)),
                  "islem-hesaplama-entity": _ctx.model.hesaplama,
                  "onUpdate:islemHesaplamaEntity": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.hesaplama) = $event))
                }, null, 8, ["kurlar", "hareket-id", "islem-hesaplama-entity"])
              ]))
            : _createCommentVNode("", true),
          _withDirectives(_createVNode(_component_NFormGrid, {
            gap: "0",
            cols: "1fr"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CokluDokuman, {
                modelValue: _ctx.model.dokumanlar,
                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model.dokumanlar) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.selectedTab == 1]
          ]),
          _withDirectives(_createVNode(_component_NFormGrid, {
            cols: "1fr",
            gap: "2px"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NFormGrid, { cols: "1fr" }, {
                default: _withCtx(() => [
                  _createVNode(_component_NFormField, {
                    "grid-cols": "75px 1fr 150px",
                    label: "Cep"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NInput),
                      _createVNode(_component_NSwitch, { label: "Sms Gönder" })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_NFormGrid, { cols: "1fr" }, {
                default: _withCtx(() => [
                  _createVNode(_component_NFormField, {
                    "grid-cols": "75px 1fr 150px",
                    label: "Mail"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NInput),
                      _createVNode(_component_NSwitch, { label: "Mail Gönder" })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_NFormGrid, { cols: "1fr" }, {
                default: _withCtx(() => [
                  _createVNode(_component_NFormField, {
                    "grid-cols": "75px 1fr 150px",
                    label: "Kargo"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NSelectBox, {
                        items: ['Yurtiçi','Aras' , 'Mng' , 'Sürat' , 'Ptt' , 'UPS'],
                        "label-field": "",
                        "value-field": ""
                      }),
                      _createVNode(_component_NSwitch, { label: "Kargo Gönder" })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                "grid-cols": "75px 1fr",
                label: "Açıklama"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NTextarea, { placeholder: "Açıklama Buraya Giriniz" })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.selectedTab == 2 ]
          ]),
          (_ctx.selectedTab === 3)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_TaksitlendirmePanel, {
                  taksitler: _ctx.model.vadeler,
                  "onUpdate:taksitler": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model.vadeler) = $event)),
                  tutar: _ctx.model.hesaplama.islenecekTutar,
                  "onUpdate:tutar": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.model.hesaplama.islenecekTutar) = $event)),
                  referansHareketId: _ctx.model.id,
                  vade: _ctx.model.vade
                }, null, 8, ["taksitler", "tutar", "referansHareketId", "vade"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["action"])
    ]),
    actions: _withCtx(() => [
      (_ctx.model.id && _ctx.model.id.length > 0)
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 0,
            type: "danger",
            size: "small",
            icon: "trash",
            click: _ctx.hareketDelete
          }, null, 8, ["click"]))
        : _createCommentVNode("", true),
      _createVNode(_component_NButton, {
        type: "warning",
        size: "small",
        icon: "x-circle",
        onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.showRef = false))
      }, {
        default: _withCtx(() => [
          _createTextVNode("İptal")
        ]),
        _: 1
      }),
      _createVNode(_component_NButton, {
        type: "success",
        size: "small",
        icon: "save",
        click: _ctx.save
      }, {
        default: _withCtx(() => [
          _createTextVNode("Kaydet")
        ]),
        _: 1
      }, 8, ["click"])
    ]),
    _: 1
  }, 8, ["modelValue", "tabs", "selected-tab"]))
}