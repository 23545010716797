
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import NDropDownTreeView from "@/components/DropDownTreeView/NDropDownTreeView.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NRefresher from "@/components/Refresher/NRefresher.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NSwitch from "@/components/Switch/NSwitch.vue";
import NInput from "@/components/Input/NInput.vue";
import {PazaramaPazaryeriItem} from "@/api/models/PazaramaPazaryeriItem";
import {usePazaramaCategoryService} from "@/api/services/PazaramaCategoryService";
import {PazaramaCategoryTreeModel} from "@/api/models/PazaramaCategoryTreeModel";
import NGrid from "@/components/Grid/NGrid.vue";
import {PazaryeriFiyatTipiList} from "@/api/models/PazaryeriFiyatTipi";

export default defineComponent({
  components: {NGrid, NInput, NSwitch, NFormField, NRefresher, NFormGrid, NDropDownTreeView},
  props: ['pazarama'],
  setup(props) {

    const localState = reactive({
      pazaramaRef: computed(() => props.pazarama as PazaramaPazaryeriItem),
      kategoriler: ref([] as PazaramaCategoryTreeModel[]),
      selectedKategoriItem: ref({} as PazaramaCategoryTreeModel),
        pazaryeriFiyatTipiList:ref(PazaryeriFiyatTipiList),
    });

    watch(() => localState.selectedKategoriItem, (newValue) => {
      localState.pazaramaRef.kategoriId = newValue.categoryId
    })


    async function load() {
      localState.kategoriler = await usePazaramaCategoryService().getGetCategoryList()

      localState.selectedKategoriItem = {
        categoryId: localState.pazaramaRef.kategoriId,
        name: getSubItem(localState.kategoriler, localState.pazaramaRef.kategoriId as string)?.name
      }
    }

    function getSubItem(subMenuItems: any[], categoryId: string): any {
      if (subMenuItems) {
        for (let i = 0; i < subMenuItems.length; i++) {
          if (subMenuItems[i].categoryId == categoryId) {
            return subMenuItems[i];
          }
          const found = getSubItem(subMenuItems[i].subCategories, categoryId);
          if (found) return found;
        }
      }
    }


    load()
    return {
      ...toRefs(localState),
      load
    };
  }
});

