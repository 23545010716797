
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import { BankaEntity } from "@/api/models/BankaEntity";
import { useBankaService } from "@/api/services/BankaService";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";
import { BankaHareketEntity } from "@/api/models/BankaHareketEntity";

export default defineComponent({
  components: {},
  props: {
    modelValue: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    name: {
      type: String
    },
    firstSelect:{
      type:Boolean,
      default : true ,
    },
  },
  setup(props, { emit }) {
    const localState = reactive({
      items: ref([] as BankaEntity[]),
      disabledRef: computed(() => props.disabled),
      selected: computed({
        get: () => props.modelValue,
        set: val => emit("update:modelValue", val)
      })
    });

    async function load() {


      localState.items = await useBankaService().getUserBankaList();


        if (!localState.selected){

          if (localState.items && localState.items.length > 0) {
            emit("update:modelValue", localState.items[0].id);
            emit("update:name", localState.items[0].bankaAdi);
          }
        }else {
          if (props.firstSelect){
            const banka = localState.items.find(a=>a.id == localState.selected);
            if (banka){
              emit("update:modelValue", banka.id);
              emit("update:name", banka.bankaAdi);
            }
          }

        }



    }

    function valueChanged(e: any) {
      if (e && e.value && e.value.length > 0) {
        const banka = localState.items.find(a=>a.id == e.value);
        if (banka){
          emit("update:modelValue", banka.id);
          emit("update:name", banka.bankaAdi);
        }else
          setDefaultEmit();
      }else
        setDefaultEmit();
    }

    function  setDefaultEmit(){
      emit("update:modelValue", '');
      emit("update:name", '');
    }

    load();

    return {
      ...toRefs(localState),
      valueChanged

    };
  }
});
