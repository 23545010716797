import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NCard = _resolveComponent("NCard")!

  return (_openBlock(), _createBlock(_component_NCard, null, {
    default: _withCtx(() => [
      _createVNode(_component_NButton, { onClick: _ctx.testClick }, {
        default: _withCtx(() => [
          _createTextVNode("Open Trendyol")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }))
}