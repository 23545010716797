import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_DxAutocomplete = _resolveComponent("DxAutocomplete")!
  const _component_DepoSelectBox = _resolveComponent("DepoSelectBox")!
  const _component_CariSelectBox = _resolveComponent("CariSelectBox")!
  const _component_NGrid = _resolveComponent("NGrid")!

  return (_ctx.stokRef.tipOzellikler?.cari)
    ? (_openBlock(), _createBlock(_component_NGrid, {
        key: 0,
        cols: "180px 180px 180px",
        gap: "8px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Cins"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTextBox, {
                value: _ctx.stokRef.tipOzellikler.cins,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.stokRef.tipOzellikler.cins) = $event)),
                onChange: _ctx.updateStokName,
                class: "uppercase"
              }, null, 8, ["value", "onChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Kalite"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTextBox, {
                value: _ctx.stokRef.tipOzellikler.kalite,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.stokRef.tipOzellikler.kalite) = $event)),
                onChange: _ctx.updateStokName,
                class: "uppercase"
              }, null, 8, ["value", "onChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Etiket"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTextBox, {
                value: _ctx.stokRef.tipOzellikler.etiket,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.stokRef.tipOzellikler.etiket) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Kalınlık"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxNumberBox, {
                value: _ctx.stokRef.tipOzellikler.kalinlik,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.stokRef.tipOzellikler.kalinlik) = $event)),
                min: 0,
                format: (d) => d?.format(0,2),
                onChange: _ctx.updateStokName
              }, null, 8, ["value", "format", "onChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Genişlik"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxNumberBox, {
                value: _ctx.stokRef.tipOzellikler.genislik,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.stokRef.tipOzellikler.genislik) = $event)),
                min: 0,
                format: (d) => d?.format(0,2),
                onChange: _ctx.updateStokName
              }, null, 8, ["value", "format", "onChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Uzunluk"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxNumberBox, {
                value: _ctx.stokRef.tipOzellikler.uzunluk,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.stokRef.tipOzellikler.uzunluk) = $event)),
                min: 0,
                format: (d) => d?.format(0,2),
                onChange: _ctx.updateStokName
              }, null, 8, ["value", "format", "onChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Adet"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxNumberBox, {
                value: _ctx.stokRef.tipOzellikler.adet,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.stokRef.tipOzellikler.adet) = $event)),
                format: _ctx.storeSettings.model.sacAdet?.format,
                onChange: _ctx.updateStokName
              }, null, 8, ["value", "format", "onChange"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Tah. Tonaj"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxNumberBox, {
                value: _ctx.stokRef.tipOzellikler.tonaj,
                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.stokRef.tipOzellikler.tonaj) = $event)),
                format: "#,##0"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Menşei",
            span: "2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxAutocomplete, {
                "data-source": _ctx.menseiList,
                searchMode: "contains",
                value: _ctx.stokRef.tipOzellikler.mensei,
                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.stokRef.tipOzellikler.mensei) = $event)),
                showClearButton: false,
                showDropDownButton: true,
                inputAttr: {style:'cursor: text'}
              }, null, 8, ["data-source", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Sipariş No",
            span: "2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTextBox, {
                value: _ctx.stokRef.tipOzellikler.siparisNo,
                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.stokRef.tipOzellikler.siparisNo) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Bülten No",
            span: "2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTextBox, {
                value: _ctx.stokRef.tipOzellikler.bultenNo,
                "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.stokRef.tipOzellikler.bultenNo) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Depo",
            span: "2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DepoSelectBox, {
                modelValue: _ctx.stokRef.tipOzellikler.depo,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.stokRef.tipOzellikler.depo) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Cari",
            span: "3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CariSelectBox, {
                id: _ctx.stokRef.tipOzellikler.cari.id,
                "onUpdate:id": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.stokRef.tipOzellikler.cari.id) = $event)),
                "get-unvan": _ctx.stokRef.tipOzellikler.cari.name,
                "onUpdate:getUnvan": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.stokRef.tipOzellikler.cari.name) = $event))
              }, null, 8, ["id", "get-unvan"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "70px 1fr",
            label: "Açıklama",
            span: "3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTextBox, {
                value: _ctx.stokRef.tipOzellikler.aciklama,
                "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.stokRef.tipOzellikler.aciklama) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}