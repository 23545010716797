import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import { MenuFormType } from "@/modules/Ortak/models/Menu/MenuFormType";
import { MenuRefrenceType } from "@/modules/Ortak/models/Menu/MenuRefrenceType";
import { KasaHareketTip } from "@/api/models/KasaHareketTip";

const tip = KasaHareketTip;
export const KasaMenuListesi: MenuModel[] = [
  {
    name: "Giriş",
    childs: [
      {
        name: "Borç Devri",
        formType: MenuFormType.Kasa_Dekont_Form,
        refrenceType: MenuRefrenceType.Kullanilmaz,
        hareketTipi: tip.KasaGirenDevir
      },
      {
        name: "Hizmet Masraf Tahsilatı",
        formType: MenuFormType.Kasa_Dekont_Form,
        refrenceType: MenuRefrenceType.Hizmet_Masraf,
        hareketTipi: tip.KasayaHizmetTahsilati
      },
      {
        name: "Firma Kredi Kartından Para Çekme",
        formType: MenuFormType.Kasa_Dekont_Form,
        refrenceType: MenuRefrenceType.Kredi_Karti,
        hareketTipi: tip.KasayaFirmaKrediKartindanParaCekme
      },
      {
        name: "Döviz Alma",
        formType: MenuFormType.Kasa_Dekont_Form,
        refrenceType: MenuRefrenceType.Kasa,
        hareketTipi: tip.KasayaDovizAlma
      },
      {
        name: "Giriş Fişi",
        formType: MenuFormType.Kasa_Dekont_Form,
        refrenceType: MenuRefrenceType.Kullanilmaz,
        hareketTipi: tip.KasaNoksanlik
      },
      {
        name:"Bankadan Para Çekme" ,
        formType: MenuFormType.Kasa_Dekont_Form,
        refrenceType:MenuRefrenceType.Banka,
        hareketTipi : tip.KasayaBankadanCekme
      },
      {
        name: "Değerli Kıymet Alma",
        formType: MenuFormType.Kasa_Dekont_Form,
        refrenceType: MenuRefrenceType.Kiymetler,
        hareketTipi: tip.KasadanDegerliKiymetAlimi
      }
    ]
  },
  {
    name: "Çıkış",
    childs: [
      {
        name: "Alacak Devri",
        formType: MenuFormType.Kasa_Dekont_Form,
        refrenceType: MenuRefrenceType.Kullanilmaz,
        hareketTipi: tip.KasaCikanDevir
      },
      {
        name: "Hizmet Masraf Ödemesi",
        formType: MenuFormType.Kasa_Dekont_Form,
        refrenceType: MenuRefrenceType.Hizmet_Masraf,
        hareketTipi: tip.KasadanMasrafOdeme
      },
      {
        name: "Firma Kredi Kartına Para Yatırma",
        formType: MenuFormType.Kasa_Dekont_Form,
        refrenceType: MenuRefrenceType.Kredi_Karti,
        hareketTipi: tip.KasadanFirmaKrediKartinaOdeme
      },
      {
        name: "Döviz Bozdurma",
        formType: MenuFormType.Kasa_Dekont_Form,
        refrenceType: MenuRefrenceType.Kasa,
        hareketTipi: tip.KasadanDovizBozdurma
      },
      {
        name: "Kredi Ödemesi",
        formType: MenuFormType.Kasa_Dekont_Form,
        refrenceType: MenuRefrenceType.Krediler,
        hareketTipi: tip.KasadanBankaKredisiOdeme
      },
      {
        name: "Çıkış Fişi",
        formType: MenuFormType.Kasa_Dekont_Form,
        refrenceType: MenuRefrenceType.Kullanilmaz,
        hareketTipi: tip.KasaFazlalik
      },
      {
        name:"Bankaya Ödeme" ,
        formType: MenuFormType.Kasa_Dekont_Form,
        refrenceType:MenuRefrenceType.Banka,
        hareketTipi : tip.KasadanBankayaOdeme
      },
      {
        name: "Değerli Kıymet Bozdurma",
        formType: MenuFormType.Kasa_Dekont_Form,
        refrenceType: MenuRefrenceType.Kiymetler,
        hareketTipi: tip.KasayaDegerliKiymetTahsilati
      },
      {
        name: "Kredi Ödemesi",
        formType: MenuFormType.Kasa_Dekont_Form,
        refrenceType: MenuRefrenceType.Krediler,
        hareketTipi: tip.KasadanBankaKredisiOdeme
      }
    ]
  },
  {
    name: "Alınan Virman",
    formType: MenuFormType.Kasa_Dekont_Form,
    refrenceType: MenuRefrenceType.Kasa,
    hareketTipi: tip.KasadanVirmanTahsilati
  },


];
