
import { computed, defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: {
    valueField: {
      type: String,
      default: "value"
    },
    labelField: {
      type: String,
      default: "description"
    },
    items: {
      type: Array
    },
    modelValue: {},
    firstOptionLabel: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const modelValueRef = computed({
      get: () => props.modelValue,
      set: val => emit("update:modelValue", val)
    });
    const disabledRef = computed(() => props.disabled);

    function getLabel(item: any) {
      if (props.labelField) {
        return item[props.labelField];
      }
      return item;
    }

    function getValue(item: any) {
      if (props.valueField) {
        return item[props.valueField];
      }
      return item;
    }

    function changed(e: any) {
      let index: number = e.target.options.selectedIndex as number;

      if (props.firstOptionLabel && props.firstOptionLabel.length > 0){
        index--;
      }


      let item: any = undefined;
      if (props.valueField) {
        item = e.target.value;
      } else if (props.items) {
        item = props.items[index];
      }

      if (props.items) emit("selectedChanged", index, props.items[index]);

      e.stopPropagation();
    }

    return { getLabel, getValue, changed, modelValueRef, disabledRef };
  }
});
