
import { NSortOrder } from "@/models/table.model";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "NSortOrderIcon",
  props: {
    sortOrder: {
      type: String as () => NSortOrder,
      default: NSortOrder.None,
      required: true
    },
    sortBy: {
      type: String
    },
    colProp: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const isVisible = computed(() => {
      if (props.sortOrder === NSortOrder.None) return true;
      else if (props.sortBy && props.sortBy === props.colProp) return true;
      else return false;
    });
    return {
      isVisible
    };
  }
});
