import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NTagList = _resolveComponent("NTagList")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    width: "560px",
    modelValue: _ctx.showRef,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showRef) = $event))
  }, {
    header: _withCtx(() => [
      _createVNode(_component_NFormField, {
        label: "Hızlı Seri Ekleme Formu",
        "grid-cols": "1fr 2fr"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DxTextBox, {
            id: "hizliSeriDialogInput",
            class: "hizliSeriDialogInput",
            ref: "hizliSeriDialogInput",
            "show-clear-button": false,
            onEnterKey: _ctx.onEnterKey,
            placeholder: "Seri Okut",
            value: _ctx.seri,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.seri) = $event))
          }, null, 8, ["onEnterKey", "value"])
        ]),
        _: 1
      })
    ]),
    body: _withCtx(() => [
      _createVNode(_component_NFormField, {
        label: "Barkod",
        "grid-cols": "80px 1fr"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, _toDisplayString(_ctx.model.barkod), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_NFormField, {
        label: "Ürün Adı",
        "grid-cols": "80px 1fr"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, _toDisplayString(_ctx.model.ad), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_NFormField, {
        label: "Adet",
        "grid-cols": "80px 50px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NTag, {
            type: "success",
            filled: true,
            style: {"font-size":"18px"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.model.miktar * _ctx.model.carpan), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_NFormField, {
        label: "Seriler",
        "grid-cols": "80px 1fr"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NTagList, {
            disabled: true,
            modelValue: _ctx.model.seriler,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.seriler) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_NButton, {
        type: "danger",
        onClick: _ctx.seriSil
      }, {
        default: _withCtx(() => [
          _createTextVNode("Seriler Sil")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_NButton, {
        type: "info",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showRef = false ))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Kaydet")
        ]),
        _: 1
      }),
      _createVNode(_component_NButton, {
        disabled: !(_ctx.modelIdRef && _ctx.modelId.length >0),
        click: _ctx.guncelle,
        type: "success"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Güncelle")
        ]),
        _: 1
      }, 8, ["disabled", "click"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}