
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import { IdName } from "@/api/models/IdName";
import NDropDownListBox from "@/components/Dropdown/NDropDownListBox.vue";

import { HepsiBuradaValuesEntity } from "@/api/models/HepsiBuradaValuesEntity";
import { HepsiBuradaValuesView } from "@/api/models/HepsiBuradaValuesView";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import {useHepsiBuradaDegerlerService} from "@/api/services/HepsiBuradaDegerlerService";

export default defineComponent({
  components: { NLoadingPanel, NDropDownListBox  },
  props: {
    id: {
      type: String
    },
    show: {
      type: Boolean,
      default: false
    },
    propertyName: {  type:String },
    nesilceValues:{type:Array},
    hepsiBuradaValues:{type:Array},
    kategoriId:{type:Number},
  },
  setup(props, { emit }) {
    const localState = reactive({
      model: ref([] as any[]),
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val)
      }),
      subTitle: ref(props.propertyName),
      title: ref("HepsiBurada Özellik Eşletirme Formu"),
      hepsiBuradaValuesDb:ref([] as HepsiBuradaValuesEntity[])
    });

    async function load() {

      localState.hepsiBuradaValuesDb = await useHepsiBuradaDegerlerService().getGetirOzellik(props.propertyName as string,props.kategoriId as number)
      localState.model = [];
        props.nesilceValues?.forEach((nesilceValue:any,i:number)=>{
          const findVal = findHepsiBuradaValue(nesilceValue);

          localState.model.push({id:i, categoryId:props.kategoriId, property:props.propertyName, nesilceValue:nesilceValue, hepsiBuradaValue:findVal})
        });

    }

    function findHepsiBuradaValue(nesilceValue:string){
      const HepsiBuradaValueDb = localState.hepsiBuradaValuesDb.find(a => a.nesilceValue == nesilceValue);
      if (HepsiBuradaValueDb)
        return HepsiBuradaValueDb.hepsiBuradaValue;
      const HepsiBuradaValue = props.hepsiBuradaValues?.find((a:any)=> a.value == nesilceValue) as any;
      if (HepsiBuradaValue)
        return HepsiBuradaValue.value as number

      return ""
    }

    async function save() {
      for (let i = 0; i < localState.model.length; i++) {
        const hepsiBuradaValue:HepsiBuradaValuesView = localState.model[i];
          await useHepsiBuradaDegerlerService().postEkle(hepsiBuradaValue);
      }

      localState.showRef = false;
    }

    load();

    return {
      ...toRefs(localState),
      load,
      save
    };
  }
});
