
import { computed, defineComponent, onMounted, reactive, ref, toRefs, watch } from "vue";
import { AdresItem } from "@/api/models/AdresItem";
import { useCariService } from "@/api/services/CariService";
import NGrid from "@/components/Grid/NGrid.vue";
import { AdresTipList } from "@/api/models/AdresTip";

export default defineComponent({
  components: { NGrid },
  props: {
    cariId: {
      type: String,
      default: ""
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      adresler: ref([] as AdresItem[]),
      adresTipList: AdresTipList,
      cariIdRef: computed(() => props.cariId as string)
    });
    load();

    async function load() {
      if (localState.cariIdRef && localState.cariIdRef.length > 0) {
        localState.adresler = await useCariService().getGetAllAddress(localState.cariIdRef);
      }

    }

    async function onValueChanged(e: any) {
      // console.log("onValueChanged" , e)
      const selectedItem = e?.component?.option("selectedItem");
      if (selectedItem)
        emit("selectedChanged", selectedItem);

    }

    watch(() => localState.cariIdRef, async () => {
      if (localState.cariIdRef && localState.cariIdRef.length > 0) {
        await load();
      }
    });


    return {
      ...toRefs(localState),
      onValueChanged,
      load
    };
  }
});
