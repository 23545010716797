import { NModuleRoute } from "@/models/route.model";

export const ServislerModuleRoutes: NModuleRoute[] = [
  {
    id: "ServislerModule",
    order: 14,
    displayName: "Servisler",
    icon: {
      type: "server"
    },
    children: [
      {
        id: "XmlStokList",
        order: 0,
        displayName: "Xml Stok Servisi",
        requiredRoles:{
          readRoles:["XmlStok.Liste"],
          insertRoles:["XmlStok.Ekle"],
          updateRoles:["XmlStok.Guncelle"],
          deleteRoles:["XmlStok.Sil"]
        },
        icon: {
          type: "list"
        },
        component: () =>
            import("../../Servisler/XmlImporter/pages/XmlImporterStokListPage.vue")
      },
      {
        id: "NetGsm",
        order: 0,
        displayName: "NetGsm Sms",
        requiredRoles:{
          readRoles:["NetGsm.Liste"],
          insertRoles:["NetGsm.Ekle"],
          updateRoles:["NetGsm.Guncelle"],
          deleteRoles:["NetGsm.Sil"]
        },
        icon: {
          type: "send"
        },
        component: () =>
          import(
            /* webpackChunkName: "NetGsm"*/ "../NetGsm/pages/NetGsmSmsListPage.vue"
          )
      },
      {
        id: "Mail",
        order: 0,
        displayName: "Mail Servisi",
        requiredRoles:{
          readRoles:["Mail.Liste"],
          insertRoles:["Mail.Ekle"],
          updateRoles:["Mail.Guncelle"],
          deleteRoles:["Mail.Sil"]
        },
        icon: {
          type: "mail"
        },
        component: () =>
            import(
                /* webpackChunkName: "NetGsm"*/ "../Mail/pages/MailListPage.vue"
                )
      },
      {
        id: "ArasKargo",
        order: 0,
        displayName: "Aras Kargo",
        requiredRoles:{
          readRoles:["ArasKargo.Liste"],
          insertRoles:["ArasKargo.Ekle"],
          updateRoles:["ArasKargo.Guncelle"],
          deleteRoles:["ArasKargo.Sil"]
        },
        icon: {
          type: "package"
        },
        component: () =>
            import(
                /* webpackChunkName: "NetGsm"*/ "../ArasKargo/pages/ArasKargoListPage.vue"
                )
      },
      {
        id: "YurtIciKargo",
        order: 0,
        displayName: "Yurtiçi Kargo",
        requiredRoles:{
          readRoles:["YurtIciKargo.Liste"],
          insertRoles:["YurtIciKargo.Ekle"],
          updateRoles:["YurtIciKargo.Guncelle"],
          deleteRoles:["YurtIciKargo.Sil"]
        },
        icon: {
          type: "package"
        },
        component: () =>
            import(
                /* webpackChunkName: "Yurtiçi Kargo"*/ "../YurtIci/pages/YurtIciKargoListPage.vue"
                )
      },
      {
        id: "PayTROdeme",
        order: 0,
        displayName: "PayTR Ödeme Servisi",
        requiredRoles:{
          readRoles:["PayTR.Liste"],
          insertRoles:["PayTR.Ekle"],
          updateRoles:["PayTR.Guncelle"],
          deleteRoles:["PayTR.Sil"]
        },
        icon: {
          type: "credit-card"
        },
        component: () =>
            import(
                /* webpackChunkName: "PayTR"*/ "../Odeme/PayTR/pages/PayTROdemelerPage.vue"
                )
      },
      {
        id: "PayNetOdeme",
        order: 0,
        displayName: "PayNet Ödeme Servisi",
        requiredRoles:{
          readRoles:["PayNet.Liste"],
          insertRoles:["PayNet.Ekle"],
          updateRoles:["PayNet.Guncelle"],
          deleteRoles:["PayNet.Sil"]
        },
        icon: {
          type: "credit-card"
        },
        component: () =>
            import(
                /* webpackChunkName: "PayNet"*/ "../Odeme/PayNet/pages/PayNetOdemelerPage.vue"
                )
      },
      {
        id: "GarantiSanalPos",
        order: 0,
        displayName: "Garanti Sanal Pos",
        requiredRoles:{
          readRoles:["GarantiSanalPos.Liste"],
          insertRoles:["GarantiSanalPos.Ekle"],
          updateRoles:["GarantiSanalPos.Guncelle"],
          deleteRoles:["GarantiSanalPos.Sil"]
        },
        icon: {
          type: "credit-card"
        },
        component: () =>
            import(
                /* webpackChunkName: "GarantiSanalPos"*/ "../Odeme/GarantiSanalPos/pages/GarantiSanalPosOdemelerPage.vue"
                )
      },

    ]
  }
];
