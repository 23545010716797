import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIconIonic = _resolveComponent("NIconIonic")!
  const _component_NTag = _resolveComponent("NTag")!
  const _component_StokAramaInput = _resolveComponent("StokAramaInput")!
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_ReportDropDownItemList = _resolveComponent("ReportDropDownItemList")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropDownLabelItem = _resolveComponent("NDropDownLabelItem")!
  const _component_NDropdownMenu = _resolveComponent("NDropdownMenu")!
  const _component_NDropdown = _resolveComponent("NDropdown")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NViewToolbar = _resolveComponent("NViewToolbar")!
  const _component_SiparisTeklifCariTab = _resolveComponent("SiparisTeklifCariTab")!
  const _component_DxDateBox = _resolveComponent("DxDateBox")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_DepoSelectBox = _resolveComponent("DepoSelectBox")!
  const _component_FiyatGruplariSelectBox = _resolveComponent("FiyatGruplariSelectBox")!
  const _component_ProjeSelectBox = _resolveComponent("ProjeSelectBox")!
  const _component_PlasiyerSelectBox = _resolveComponent("PlasiyerSelectBox")!
  const _component_NTextarea = _resolveComponent("NTextarea")!
  const _component_NCheckbox = _resolveComponent("NCheckbox")!
  const _component_FaturaBilgiItemComponent = _resolveComponent("FaturaBilgiItemComponent")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NListView = _resolveComponent("NListView")!
  const _component_CokluDokuman = _resolveComponent("CokluDokuman")!
  const _component_SiparisTeklifOdemeComp = _resolveComponent("SiparisTeklifOdemeComp")!
  const _component_SmsKargoMailGonderComponent = _resolveComponent("SmsKargoMailGonderComponent")!
  const _component_NCard = _resolveComponent("NCard")!
  const _component_SiparisSatirlari = _resolveComponent("SiparisSatirlari")!
  const _component_NViewGrid = _resolveComponent("NViewGrid")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!
  const _component_CariFormDialog = _resolveComponent("CariFormDialog")!
  const _component_ReportViewerDialog = _resolveComponent("ReportViewerDialog")!
  const _component_FaturaKopyalaDialogs = _resolveComponent("FaturaKopyalaDialogs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NLoadingPanel, {
      title: "Siparis Yükleniyor...",
      action: _ctx.load
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NViewGrid, {
          cols: "minmax(0, 1fr)",
          rows: "auto minmax(0,1fr)"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NViewToolbar, null, {
              title: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.menu.name) + " ", 1),
                _createVNode(_component_NTag, {
                  type:  _ctx.model.cariId && _ctx.model.cariId.length > 0 ?  'info' : 'danger',
                  filled: true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NIconIonic, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cariFormShow = true )),
                      style: {"padding-right":"5px"},
                      renk: "white",
                      title: "Cari Düzenle",
                      tip: "open-outline"
                    }),
                    _createTextVNode(" " + _toDisplayString(_ctx.model.cariId && _ctx.model.cariId?.length > 0 ? _ctx.model.cariUnvan : "Cari Seçiniz veya Yeni Cari Bilgilerini Giriniz !"), 1)
                  ]),
                  _: 1
                }, 8, ["type"])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_NGrid, {
                  cols: "300px auto auto auto auto",
                  gap: "3px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_StokAramaInput, {
                      onCokluHizmet: _ctx.cokluhHizmetDblClick,
                      onHizmetDblClick: _ctx.hizmetDblClick,
                      onTek: _ctx.stokDblClick,
                      onCoklu: _ctx.coklu
                    }, null, 8, ["onCokluHizmet", "onHizmetDblClick", "onTek", "onCoklu"]),
                    _createVNode(_component_NFormField, {
                      "grid-cols": "1px 1fr",
                      label: " "
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NSwitch, {
                          "sub-label": "Sol Menü",
                          type: "primary",
                          modelValue: _ctx.solMenuDisabled,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.solMenuDisabled) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NButton, {
                      size: "xsmall",
                      click: _ctx.kaydetVeBekle,
                      type: "info",
                      icon: "save"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Kyd. Bekle")
                      ]),
                      _: 1
                    }, 8, ["click"]),
                    _createVNode(_component_NButton, {
                      size: "xsmall",
                      click: _ctx.kaydet,
                      type: "success",
                      icon: "save"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Kyd.")
                      ]),
                      _: 1
                    }, 8, ["click"]),
                    _createVNode(_component_NDropdown, { placement: "bottom-right" }, {
                      menu: _withCtx(() => [
                        _createVNode(_component_NDropdownMenu, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ReportDropDownItemList, {
                              "use-me": false,
                              "report-type": _ctx.reportType.Siparis_Teklif,
                              onReportClick: _ctx.reportClick
                            }, null, 8, ["report-type", "onReportClick"]),
                            _createVNode(_component_NDropdownItem, {
                              onClick: _ctx.faturaKopyalaDialogOpen,
                              icon: "copy"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("Kopyala")
                              ]),
                              _: 1
                            }, 8, ["onClick"]),
                            (_ctx.siparisHareketTip  ==  _ctx.sipTip.AlisSiparis || _ctx.siparisHareketTip == _ctx.sipTip.SatisSiparis )
                              ? (_openBlock(), _createBlock(_component_NDropdownItem, {
                                  key: 0,
                                  type: "success",
                                  icon: "send",
                                  onClick: _ctx.convertFatura
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Faturaya Dönüştür ")
                                  ]),
                                  _: 1
                                }, 8, ["onClick"]))
                              : _createCommentVNode("", true),
                            (_ctx.siparisHareketTip  ==  _ctx.sipTip.AlisSiparis || _ctx.siparisHareketTip == _ctx.sipTip.SatisSiparis)
                              ? (_openBlock(), _createBlock(_component_NDropdownItem, {
                                  key: 1,
                                  type: "warning",
                                  icon: "send",
                                  onClick: _ctx.convertIrsaliye
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("İrsaliyeye Dönüştür ")
                                  ]),
                                  _: 1
                                }, 8, ["onClick"]))
                              : _createCommentVNode("", true),
                            (_ctx.siparisHareketTip  ==  _ctx.sipTip.AlisTeklif || _ctx.siparisHareketTip == _ctx.sipTip.SatisTeklif)
                              ? (_openBlock(), _createBlock(_component_NDropdownItem, {
                                  key: 2,
                                  type: "info",
                                  icon: "send",
                                  onClick: _ctx.convertSiparis
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Siparişe Dönüştür ")
                                  ]),
                                  _: 1
                                }, 8, ["onClick"]))
                              : _createCommentVNode("", true),
                            _createVNode(_component_NDropDownLabelItem, { label: "Birim Fiyat" }),
                            (_ctx.model && _ctx.model.satirlar && _ctx.model.satirlar.length >  0 )
                              ? (_openBlock(), _createBlock(_component_NDropdownItem, {
                                  key: 3,
                                  icon: "refresh-ccw",
                                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.siparisUtil.satirBirimFiyatUpdate(_ctx.model.satirlar , _ctx.cari.birimFiyatGrupAdi)))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" Fiyatlari Tekrar Güncelle ")
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (_ctx.model && _ctx.model.id && _ctx.model.id.length > 0 )
                              ? (_openBlock(), _createBlock(_component_NDropdownItem, {
                                  key: 4,
                                  icon: "trash",
                                  onClick: _ctx.sil
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Sil ")
                                  ]),
                                  _: 1
                                }, 8, ["onClick"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_NButton, {
                          type: "primary",
                          icon: "zap"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("İşlemler")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_NGrid, {
              gap: "5px",
              cols: 
      !_ctx.solMenuDisabled  ? '1fr' :  (_ctx.farkliTeslimatAdresiRef && _ctx.selectedTab === 0 ) ? '750px 1fr': '375px 1fr',
              rows: "minmax(0,1fr)",
              style: {height: '100%'}
            }, {
              default: _withCtx(() => [
                (_ctx.solMenuDisabled)
                  ? (_openBlock(), _createBlock(_component_NCard, {
                      key: 0,
                      col: "1fr",
                      row: "minimal(0,1fr)",
                      tabs: [ {label : 'Cari'  , disabled : (_ctx.model.id && _ctx.model.id.length > 0 )  } , { label:'Genel'} ,{label : 'Diğer'} , {label:'Adresler'}  , {label : 'Dokümanlar'}  , {label : '' , icon : 'truck'}],
                      modelValue: _ctx.selectedTab,
                      "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.selectedTab) = $event)),
                      scrollable: ""
                    }, {
                      default: _withCtx(() => [
                        (_ctx.selectedTab === 0 )
                          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                              _createVNode(_component_SiparisTeklifCariTab, {
                                "farkli-teslimat-adresi": _ctx.farkliTeslimatAdresiRef,
                                "onUpdate:farkliTeslimatAdresi": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.farkliTeslimatAdresiRef) = $event)),
                                modelValue: _ctx.model,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model) = $event))
                              }, null, 8, ["farkli-teslimat-adresi", "modelValue"])
                            ]))
                          : _createCommentVNode("", true),
                        _withDirectives(_createVNode(_component_NGrid, { cols: "1fr" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createVNode(_component_NFormField, { label: "Evrak Tarihi" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_DxDateBox, {
                                    value: _ctx.model.tarih,
                                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.tarih) = $event))
                                  }, null, 8, ["value"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_NFormField, { label: "Vade Tarihi" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_DxDateBox, {
                                    onKeyDown: _ctx.vadeChanged,
                                    value: _ctx.model.vade,
                                    "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.vade) = $event))
                                  }, null, 8, ["onKeyDown", "value"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_NFormField, { label: "Evrak No" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_NInput, {
                                    modelValue: _ctx.model.evrakNo,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.evrakNo) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_NFormField, { label: "Şube/Depo" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_DepoSelectBox, {
                                    modelValue: _ctx.depoId,
                                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.depoId) = $event)),
                                    "sube-id": _ctx.subeId,
                                    "onUpdate:subeId": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.subeId) = $event)),
                                    "first-select": true
                                  }, null, 8, ["modelValue", "sube-id"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_NFormField, { label: "Fiyat Grubu" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_FiyatGruplariSelectBox, {
                                    modelValue: _ctx.fiyatGrubuId,
                                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.fiyatGrubuId) = $event)),
                                    oran: _ctx.fiyatGrubuOran,
                                    "onUpdate:oran": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.fiyatGrubuOran) = $event))
                                  }, null, 8, ["modelValue", "oran"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_NFormField, { label: "Proje" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ProjeSelectBox, {
                                    modelValue: _ctx.model.projeId,
                                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.projeId) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_NFormField, { label: "Plasiyer" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_PlasiyerSelectBox, {
                                    modelValue: _ctx.selectedPlasiyerId,
                                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.selectedPlasiyerId) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_NFormField, { label: "Açıklama" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_NTextarea, {
                                    rows: "3px",
                                    modelValue: _ctx.model.aciklama,
                                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.aciklama) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_NFormField, {
                                label: "Tümünü Teslim Et",
                                "grid-cols": "150px 1fr"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_NCheckbox, {
                                    type: "success",
                                    modelValue: _ctx.tumunuTeslimEt,
                                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.tumunuTeslimEt) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_FaturaBilgiItemComponent, {
                                placeholder: "Teklif No",
                                modelValue: _ctx.model.teklifler,
                                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model.teklifler) = $event)),
                                "add-text": "Yeni Teklif Bilgisi Ekle"
                              }, null, 8, ["modelValue"]),
                              _createVNode(_component_FaturaBilgiItemComponent, {
                                placeholder: "Sipariş No",
                                modelValue: _ctx.model.siparisler,
                                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model.siparisler) = $event)),
                                "add-text": "Yeni Sipariş Bilgisi Ekle"
                              }, null, 8, ["modelValue"]),
                              _createVNode(_component_FaturaBilgiItemComponent, {
                                placeholder: "Fatura No",
                                modelValue: _ctx.model.faturalar,
                                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.model.faturalar) = $event)),
                                "add-text": "Yeni Fatura Bilgisi Ekle"
                              }, null, 8, ["modelValue"]),
                              _createVNode(_component_FaturaBilgiItemComponent, {
                                placeholder: "İrsaliye Numarası",
                                modelValue: _ctx.model.irsaliyeler,
                                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.model.irsaliyeler) = $event)),
                                "add-text": "Yeni İrsaliye Bilgisi Ekle"
                              }, null, 8, ["modelValue"])
                            ])
                          ]),
                          _: 1
                        }, 512), [
                          [_vShow, _ctx.selectedTab === 1 ]
                        ]),
                        _createElementVNode("div", null, [
                          (_ctx.selectedTab === 2 )
                            ? (_openBlock(), _createBlock(_component_NGrid, {
                                key: 0,
                                cols: "1fr"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_NFormField, {
                                    label: "Hesabı",
                                    "grid-cols": "120px 1fr"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_DxSelectBox, {
                                        items: _ctx.cari.hesaplar,
                                        searchEnabled: true,
                                        value: _ctx.model.cariHesabi,
                                        "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.model.cariHesabi) = $event)),
                                        "show-clear-button": true,
                                        placeholder: "Cari Hesabi"
                                      }, null, 8, ["items", "value"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_NFormField, {
                                    label: "İşi",
                                    "grid-cols": "120px 1fr"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_DxSelectBox, {
                                        items: _ctx.cari.isler,
                                        searchEnabled: true,
                                        value: _ctx.model.cariIsi,
                                        "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.model.cariIsi) = $event)),
                                        "show-clear-button": true,
                                        placeholder: "Cari İşi"
                                      }, null, 8, ["items", "value"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_NFormField, {
                                    label: "Nakliye",
                                    "grid-cols": "120px 1fr"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_NTextarea, {
                                        rows: "2",
                                        modelValue: _ctx.model.nakliye,
                                        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.model.nakliye) = $event))
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_NFormField, {
                                    label: "Ödeme Şekli",
                                    "grid-cols": "120px 1fr"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_NTextarea, {
                                        rows: "2",
                                        modelValue: _ctx.model.odemeSekli,
                                        "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.model.odemeSekli) = $event))
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_NFormField, {
                                    label: "Teslimat Bilgileri",
                                    "grid-cols": "120px 1fr"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_NTextarea, {
                                        rows: "2",
                                        modelValue: _ctx.model.teslimatBilgileri,
                                        "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.model.teslimatBilgileri) = $event))
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_NFormField, {
                                    label: "Ekstra Not",
                                    "grid-cols": "120px 1fr"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_NTextarea, {
                                        rows: "2",
                                        modelValue: _ctx.model.not,
                                        "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.model.not) = $event))
                                      }, null, 8, ["modelValue"])
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        (_ctx.selectedTab === 3 )
                          ? (_openBlock(), _createBlock(_component_NGrid, {
                              key: 1,
                              cols: "1fr"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, [
                                  _createElementVNode("div", null, [
                                    _createVNode(_component_NButton, {
                                      type: "success",
                                      icon: "map-pin",
                                      onClick: _ctx.addAdres,
                                      block: true
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("Yeni Adres Ekle")
                                      ]),
                                      _: 1
                                    }, 8, ["onClick"])
                                  ]),
                                  _createVNode(_component_NListView, {
                                    "empty-message": "Adres Listesi Boş , Yukarıdan 'Yeni Adres Ekle' butonunu kullanarak adres ekleyebilir veya eklediğiniz adresi silebilirsiniz.",
                                    class: "siparisAdresItem",
                                    items: _ctx.model.teslimatlar?.slice()?.reverse(),
                                    "model-value": -1
                                  }, {
                                    item: _withCtx(({ item  }) => [
                                      _createVNode(_component_NGrid, { cols: "1fr 1fr" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_NFormField, {
                                            "grid-cols": "80px 1fr 50px",
                                            label: "Bölge",
                                            span: "2"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_NInput, {
                                                modelValue: item.bolge,
                                                "onUpdate:modelValue": ($event: any) => ((item.bolge) = $event),
                                                placeholder: item.bolge = _ctx.adresler.find(a=>a.sehir == item.sehir)?.bolge,
                                                readonly: ""
                                              }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder"]),
                                              _createVNode(_component_NButton, {
                                                onClick: ($event: any) => (_ctx.deleteAdres(item.id)),
                                                size: "small",
                                                type: "danger",
                                                icon: "trash"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode("Sil")
                                                ]),
                                                _: 2
                                              }, 1032, ["onClick"])
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_NFormField, {
                                            "grid-cols": "1px 1fr",
                                            label: " ",
                                            span: "2"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_NInput, {
                                                type: "text",
                                                modelValue: item.kisaAd,
                                                "onUpdate:modelValue": ($event: any) => ((item.kisaAd) = $event),
                                                placeholder: "Satırlarda Seçmek İçin Kısa Ad Giriniz"
                                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_NFormField, {
                                            "grid-cols": "1px 1fr",
                                            label: " "
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_NInput, {
                                                type: "text",
                                                modelValue: item.ulke,
                                                "onUpdate:modelValue": ($event: any) => ((item.ulke) = $event),
                                                placeholder: "Ülke Giriniz "
                                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_NFormField, {
                                            "grid-cols": "1px 1fr",
                                            label: " "
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_NInput, {
                                                type: "text",
                                                modelValue: item.pKodu,
                                                "onUpdate:modelValue": ($event: any) => ((item.pKodu) = $event),
                                                placeholder: "Posta Kodu"
                                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_NFormField, {
                                            "grid-cols": "1px 1fr 50px",
                                            label: " "
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_DxSelectBox, {
                                                items: _ctx.adresler.map(a=>a.sehir)
                            .filter((value, index, self) => self.indexOf(value) === index),
                                                "search-timeout": 1,
                                                searchEnabled: true,
                                                value: item.sehir,
                                                "onUpdate:value": ($event: any) => ((item.sehir) = $event),
                                                "show-clear-button": true,
                                                searchMode: "contains",
                                                autocomplete: "nope",
                                                placeholder: "Şehir"
                                              }, null, 8, ["items", "value", "onUpdate:value"]),
                                              _createVNode(_component_NButton, {
                                                size: "small",
                                                click: _ctx.adreslerLoad,
                                                icon: "download"
                                              }, null, 8, ["click"])
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_NFormField, {
                                            "grid-cols": "1px 1fr",
                                            label: " "
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_DxSelectBox, {
                                                items: _ctx.adresler.filter(a=>a.sehir == item.sehir)
                  .map(a=>a.ilce).filter((value, index, self) => self.indexOf(value) === index),
                                                "search-timeout": 1,
                                                searchEnabled: true,
                                                value: item.ilce,
                                                "onUpdate:value": ($event: any) => ((item.ilce) = $event),
                                                "show-clear-button": true,
                                                searchMode: "contains",
                                                acceptCustomValue: true,
                                                placeholder: "İlçe Giriniz"
                                              }, null, 8, ["items", "value", "onUpdate:value"])
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_NFormField, {
                                            "grid-cols": "1px 1fr",
                                            label: " "
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_DxSelectBox, {
                                                items: _ctx.adresler.filter(a=>a.sehir == item.sehir && a.ilce == item.ilce)
                  .map(a=>a.beldeAdi).filter((value, index, self) => self.indexOf(value) === index),
                                                "search-timeout": 1,
                                                searchEnabled: true,
                                                value: item.belde,
                                                "onUpdate:value": ($event: any) => ((item.belde) = $event),
                                                "show-clear-button": true,
                                                searchMode: "contains",
                                                acceptCustomValue: true,
                                                onValueChanged: ($event: any) => (_ctx.beldeChange(item)),
                                                placeholder: "Semt / Belde Bucak Giriniz"
                                              }, null, 8, ["items", "value", "onUpdate:value", "onValueChanged"])
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_NFormField, {
                                            "grid-cols": "1px 1fr",
                                            label: " "
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_DxSelectBox, {
                                                items: _ctx.adresler.find(a=>a.sehir == item.sehir && a.ilce == item.ilce && a.beldeAdi == item.belde)
                  ?.mahalleler,
                                                "search-timeout": 1,
                                                searchEnabled: true,
                                                value: item.mahalle,
                                                "onUpdate:value": ($event: any) => ((item.mahalle) = $event),
                                                "show-clear-button": true,
                                                searchMode: "contains",
                                                acceptCustomValue: true,
                                                placeholder: "Mahalle Giriniz"
                                              }, null, 8, ["items", "value", "onUpdate:value"])
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_NFormField, {
                                            "grid-cols": "1px 1fr",
                                            label: " "
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_NInput, {
                                                placeholder: "Telefon Numarası",
                                                type: "text",
                                                modelValue: item.cep,
                                                "onUpdate:modelValue": ($event: any) => ((item.cep) = $event)
                                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_NFormField, {
                                            "grid-cols": "1px 1fr",
                                            label: " "
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_NInput, {
                                                placeholder: "E-Posta Adresi",
                                                type: "text",
                                                modelValue: item.mail,
                                                "onUpdate:modelValue": ($event: any) => ((item.mail) = $event)
                                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_NFormField, {
                                            "grid-cols": "1px 1fr",
                                            label: " ",
                                            span: "2"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_NTextarea, {
                                                type: "text",
                                                placeholder: "Adres Giriniz !",
                                                rows: "2",
                                                modelValue: item.adres,
                                                "onUpdate:modelValue": ($event: any) => ((item.adres) = $event)
                                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                                            ]),
                                            _: 2
                                          }, 1024),
                                          _createVNode(_component_NFormField, {
                                            "grid-cols": "auto 1fr",
                                            label: "Planlanan Teslim Tarihi",
                                            span: "2"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_DxDateBox, {
                                                "use-mask-behavior": true,
                                                "display-format": "shortdate",
                                                type: "date",
                                                "picker-type": "rollers",
                                                value: item.planlananTarih,
                                                "onUpdate:value": ($event: any) => ((item.planlananTarih) = $event)
                                              }, null, 8, ["value", "onUpdate:value"])
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 1
                                  }, 8, ["items"])
                                ])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", null, [
                          (_ctx.selectedTab === 4)
                            ? (_openBlock(), _createBlock(_component_NGrid, {
                                key: 0,
                                cols: "1fr"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_CokluDokuman, {
                                    modelValue: _ctx.model.dokumanlar,
                                    "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.model.dokumanlar) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", null, [
                          (_ctx.selectedTab === 5 && !(_ctx.model && _ctx.model.id && _ctx.model.id.length > 0  ) )
                            ? (_openBlock(), _createBlock(_component_NGrid, {
                                key: 0,
                                cols: "1fr",
                                gap: "2px"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_SiparisTeklifOdemeComp, {
                                    modelValue: _ctx.model,
                                    "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.model) = $event))
                                  }, null, 8, ["modelValue"]),
                                  _createVNode(_component_SmsKargoMailGonderComponent, {
                                    modelValue: _ctx.model.gonder,
                                    "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.model.gonder) = $event)),
                                    tutar: _ctx.model.getOdenecekTutar()
                                  }, null, 8, ["modelValue", "tutar"])
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _: 1
                    }, 8, ["tabs", "modelValue"]))
                  : _createCommentVNode("", true),
                (_ctx.model)
                  ? (_openBlock(), _createBlock(_component_SiparisSatirlari, {
                      key: 1,
                      ref: "siparisSatirlari",
                      modelValue: _ctx.model,
                      "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.model) = $event)),
                      "depo-id": _ctx.depoId,
                      "onUpdate:depoId": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.depoId) = $event)),
                      "fiyat-grubu-oran": _ctx.fiyatGrubuOran,
                      "onUpdate:fiyatGrubuOran": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.fiyatGrubuOran) = $event)),
                      "plasiyer-id": _ctx.selectedPlasiyerId,
                      "onUpdate:plasiyerId": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.selectedPlasiyerId) = $event)),
                      "siparis-hareket-tip": _ctx.siparisHareketTip,
                      "onUpdate:siparisHareketTip": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.siparisHareketTip) = $event)),
                      "cari-prop": _ctx.cari,
                      "onUpdate:cariProp": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.cari) = $event))
                    }, null, 8, ["modelValue", "depo-id", "fiyat-grubu-oran", "plasiyer-id", "siparis-hareket-tip", "cari-prop"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["cols"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["action"]),
    (_ctx.cariFormShow)
      ? (_openBlock(), _createBlock(_component_CariFormDialog, {
          key: 0,
          id: _ctx.model.cariId,
          "onUpdate:id": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.model.cariId) = $event)),
          show: _ctx.cariFormShow,
          "onUpdate:show": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.cariFormShow) = $event))
        }, null, 8, ["id", "show"]))
      : _createCommentVNode("", true),
    (_ctx.showReportDialog)
      ? (_openBlock(), _createBlock(_component_ReportViewerDialog, {
          key: 1,
          "report-name": _ctx.reportName,
          show: _ctx.showReportDialog,
          "onUpdate:show": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.showReportDialog) = $event)),
          "model-value": _ctx.reportData,
          "slient-print": false,
          mailSendTo: _ctx.mailSendTo
        }, null, 8, ["report-name", "show", "model-value", "mailSendTo"]))
      : _createCommentVNode("", true),
    (_ctx.kopyalaDailog)
      ? (_openBlock(), _createBlock(_component_FaturaKopyalaDialogs, {
          key: 2,
          tip: _ctx.menuFormType.Siparis_Form,
          modelValue: _ctx.model,
          "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.model) = $event)),
          show: _ctx.kopyalaDailog,
          "onUpdate:show": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.kopyalaDailog) = $event))
        }, null, 8, ["tip", "modelValue", "show"]))
      : _createCommentVNode("", true)
  ], 64))
}