
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from "vue";
import NLoading from "@/components/Loading/NLoading.vue";
import { usePopupService } from "@/services/popupService";
import NAlert from "@/components/Alert/NAlert.vue";
import NButton from "@/components/Button/NButton.vue";

export default defineComponent({
  components: { NButton, NAlert, NLoading },
  props: {
    title:{
      type:String,
      default:'Yükleniyor...'
    },
    action:{
      type:Function
    }
  },
  setup(props) {

    const localState = reactive({
      isLoading:ref(true),
      errorMesage:ref(undefined),
      isError:ref(false)
    });

    async function load(){

      try {
        if (props.action){
          localState.isLoading = true;
          localState.isError = false;
          await props.action();
          localState.isLoading = false;
        }
      }catch (e:any) {
        localState.isError = true;
        localState.isLoading = true;
        usePopupService().pushDangerNotification({
          body:e.message
        })
        localState.errorMesage = "Hata oluştu: " + e.message?e.message:e;
        throw e;
      }
    }

    onMounted(()=>{
      load()
    })



    return {
      ...toRefs(localState),
      load
    };
  }
});

