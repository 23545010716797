
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import FaturaViewExtend from "@/modules/Fatura/models/FaturaViewExtend";

import KasaSelectBox from "@/modules/Kasa/components/KasaSelectBox.vue";
import PosSelectBox from "@/modules/Pos/components/PosSelectBox.vue";
import BankaSelectBox from "@/modules/Banka/components/BankaSelectBox.vue";
import NInput from "@/components/Input/NInput.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NIconIonic from "@/components/NIconIonic.vue";
import { DovizList } from "@/api/models/Doviz";
import NFormField from "@/components/FormField/NFormField.vue";
import NCheckbox from "@/components/Checkbox/NCheckbox.vue";
import NButton from "@/components/Button/NButton.vue";
import NLoading from "@/components/Loading/NLoading.vue";
import CariSelectBox from "@/modules/Cari/components/CariSelectBox.vue";


export default defineComponent({
  components: {
    CariSelectBox,
    NLoading,
    NButton,
    NCheckbox,
    NFormField,
    NIconIonic,
    NGrid,
    BankaSelectBox,
    PosSelectBox,
    KasaSelectBox
  },
  props: {
    modelValue: {
      type: Object

    }
  },
  setup(props, { emit }) {

    const localState = reactive({

      model: computed({
        get: () => props.modelValue as FaturaViewExtend,
        set: (val) => emit("update:modelValue", val)
      }),
      dovizList: DovizList,
      cokluTahsilat: ref(false) ,
      isSave:ref(false),

    });

    function dovizIsmi(): string {
      return localState.dovizList.find(a => a.value == localState.model.altBilgi!.cariIslemDoviz)?.description as string;
    }

    async function havaleIleOdeme() {
      localState.isSave = true ;
      localState.model.altBilgi!.havaleTutar = localState.model.getOdenecekDovizTutar(localState.model.altBilgi!.cariIslemKur as number);
      localState.model.altBilgi!.kasaTutar = 0;
      localState.model.altBilgi!.posTutar = 0;
      localState.model.altBilgi!.mailOrderTutar = 0;
      emit("save");

    }

    async function kasaIleOdeme() {
      localState.isSave = true ;
      localState.model.altBilgi!.havaleTutar = 0;
      localState.model.altBilgi!.kasaTutar = localState.model.getOdenecekDovizTutar(localState.model.altBilgi!.cariIslemKur as number);
      localState.model.altBilgi!.posTutar = 0;
      localState.model.altBilgi!.mailOrderTutar = 0;
      emit("save");
    }

    async function mailOrderOdeme() {
      localState.isSave = true ;
      localState.model.altBilgi!.havaleTutar = 0;
      localState.model.altBilgi!.kasaTutar = 0;
      localState.model.altBilgi!.mailOrderTutar = localState.model.getOdenecekDovizTutar(localState.model.altBilgi!.cariIslemKur as number);
      localState.model.altBilgi!.posTutar = 0;
      emit("save");
    }

    async function posIleOdeme() {
      localState.isSave = true ;
      localState.model.altBilgi!.havaleTutar = 0;
      localState.model.altBilgi!.kasaTutar = 0;
      localState.model.altBilgi!.mailOrderTutar = 0;
      localState.model.altBilgi!.posTutar = localState.model.getOdenecekDovizTutar(localState.model.altBilgi!.cariIslemKur as number);
      emit("save");
    }


    function kalanTutar(): number {
      const odeemeTutar =
        localState.model.altBilgi!.kasaTutar as number +
        (localState.model.altBilgi!.posTutar as number) +
        (localState.model.altBilgi!.mailOrderTutar as number) +
        (localState.model.altBilgi!.havaleTutar as number);

      const result = localState.model.getOdenecekDovizTutar(localState.model.altBilgi!.cariIslemKur as number) - odeemeTutar;
      return result;
    }

    watch(() => localState.cokluTahsilat, (oldValue, newValue) => {
      if (oldValue != newValue && localState.cokluTahsilat == false) {
        localState.model.altBilgi!.kasaTutar = 0;
        localState.model.altBilgi!.havaleTutar = 0;
        localState.model.altBilgi!.posTutar = 0;
        localState.model.altBilgi!.mailOrderTutar = 0 ;
      }

    });

    return {
      ...toRefs(localState),
      havaleIleOdeme,
      dovizIsmi,
      kalanTutar,
      posIleOdeme,
      kasaIleOdeme,
      mailOrderOdeme
    };
  }
});
