
import {computed, defineComponent, reactive, toRefs} from "vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import NButton from "@/components/Button/NButton.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NInput from "@/components/Input/NInput.vue";
import {VergiView} from "@/api/models/VergiView";
import {Vergiler} from "@/api/models/Vergiler";
import {ref} from "vue";
import NTag from "@/components/Tag/NTag.vue";
import FaturaSatirItemExtend from "@/modules/Fatura/models/FaturaSatirItemExtend";
import {FaturaSatirItem} from "@/api/models/FaturaSatirItem";
import {components} from "knockout";

export default defineComponent({
  components: {NTag, NInput, NFormField, NButton, NModalDialog},
  props: {
    modelValue:{
      type:Object,
    },
    show:{
      type:Boolean,
      default:false
    },
    satirId:{
      type:String,
      default:'',
    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      vergi:computed( () => props.modelValue as VergiView),
      showRef:computed({
        get : () => props.show,
        set : val => emit('update:show' , val )
      }),
      model:ref({} as Vergiler),
    })
    load();
    function load(){
      localState.model = {
        adi : localState.vergi.adi,
        kod : localState.vergi.kodu,
        deger : 0 ,
        oran : 0 ,
        vergiTip : localState.vergi.vergiTip,
      }
    }
    function vergiDialogsAdd(){
      emit('vergiDialogsAdd' , localState.model ,  props.satirId);
      localState.showRef  = false ;
    }

    return {
      ...toRefs(localState),
      vergiDialogsAdd
    };
  }
});
