import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {RecordStatus} from '../models/RecordStatus'
import {PazaramaValuesEntity} from '../models/PazaramaValuesEntity'

export const usePazaramaValuesService = defineStore({
id: 'PazaramaValuesService',
actions: {


	async getGetirTumu()
	{
		const { request, response } = useApi(`/api/PazaramaValues/GetirTumu`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetirOzellik(ozellik:string,kategoriId:string)
	{
		const { request, response } = useApi(`/api/PazaramaValues/GetirOzellik/${ozellik}/${kategoriId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetir(id:string)
	{
		const { request, response } = useApi(`/api/PazaramaValues/Getir/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const { request, response } = useApi(`/api/PazaramaValues/Sil/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postEkle(pazaramaValuesEntity:PazaramaValuesEntity)
	{
		const { request, response } = useApi(`/api/PazaramaValues/Ekle`,{ method:'POST', data:pazaramaValuesEntity });
		await request();
		return response.value;
	}
}
});
