
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import {CariHareketViewVadeli} from "@/api/models/CariHareketViewVadeli";
import NFormField from "@/components/FormField/NFormField.vue";
import {CariHareketTip} from "@/api/models/CariHareketTip";

import NTag from "@/components/Tag/NTag.vue";
import {DovizList} from "@/api/models/Doviz";

import NCheckbox from "@/components/Checkbox/NCheckbox.vue";
import { usedCacheFirmaService } from "@/modules/Ayarlar/Firmalar/services/usedCacheFirmaService";
import { useTaksitPlanService } from "@/api/services/TaksitPlanService";
import { TaksitPlanEntity } from "@/api/models/TaksitPlanEntity";
import _ from "lodash";

export default defineComponent({
  components: {NCheckbox, NTag, NFormField},
  props: {
    modelValue: {
      type: Object,
    },
    tip: {
      type: Number,

    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      modelRef: computed({
        get: () => props.modelValue as CariHareketViewVadeli,
        set: (val) => emit('update:modelValue', val),
      }),
      tipRef: computed(() => props.tip as CariHareketTip),
      alacakBorcOzet: [] as TaksitPlanEntity[],
      dovizListRef: DovizList,
      toplamKalanYerelTutar : ref(0 as number ),
    });

    load();

    async function load() {

      const firma = await usedCacheFirmaService().getFirma();
      if (firma)
        localState.modelRef.borcAlacaktanDussunmu = firma.taksitOtamatikDus ;


      localState.alacakBorcOzet = await useTaksitPlanService().getCariIdByTaksitlerHareketTip(localState.modelRef.cariId as string, localState.tipRef);
      localState.toplamKalanYerelTutar = _.sum(localState.alacakBorcOzet.map(a=>a.taksitKalanYerelTutar as number));
    }

    function getLabel(): string {

      if (localState.tipRef) {
        if (localState.tipRef >= 0 && localState.tipRef <= 1000)
          return "Alaca Taksitleri Toplamı : ";
        if (localState.tipRef >= 5000 && localState.tipRef <= 6000)
          return "Borç Taksitleri Toplamı : ";

      }

      return " ";
    }

    return {
      ...toRefs(localState),
      getLabel
    }
  }
})
