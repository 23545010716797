import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32cc8dfd"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["stroke", "stroke-width", "r", "cx", "cy"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: "n-loading",
    style: _normalizeStyle({
      'max-width': `${_ctx.radius * 2}px`,
      'max-height': `${_ctx.radius * 2}px`
    })
  }, [
    _createElementVNode("circle", {
      class: "n-loading-circle",
      stroke: _ctx.color,
      "stroke-width": _ctx.stroke,
      "stroke-linecap": "round",
      fill: "none",
      r: _ctx.normalizedRadius,
      cx: _ctx.radius,
      cy: _ctx.radius,
      pathLength: "100"
    }, null, 8, _hoisted_1)
  ], 4))
}