import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';


export const useHizmetMasrafHareketService = defineStore({
id: 'HizmetMasrafHareketService',
actions: {


	async getHizmetMasrafReportView()
	{
		const { request, response } = useApi(`/api/hizmetMasrafHareket/HizmetMasrafReportView`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/hizmetMasrafHareket/Liste/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListeByTarih(startDate:string,endDate:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/hizmetMasrafHareket/ListeByTarih/${shared.firm.id}/${shared.firm.period}/${startDate}/${endDate}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getReferansId(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/hizmetMasrafHareket/ReferansId/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getHizmetMasrafListe(hizmetMasrafId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/hizmetMasrafHareket/HizmetMasrafListe/${shared.firm.id}/${shared.firm.period}/${hizmetMasrafId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getHizmetMasrafListeByTarih(startDate:string,endDate:string,hizmetMasrafId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/hizmetMasrafHareket/HizmetMasrafListeByTarih/${shared.firm.id}/${shared.firm.period}/${startDate}/${endDate}/${hizmetMasrafId}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
