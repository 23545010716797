import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dx-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxLoadPanel = _resolveComponent("DxLoadPanel")!
  const _component_DxGroupPanel = _resolveComponent("DxGroupPanel")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxColumnChooser = _resolveComponent("DxColumnChooser")!
  const _component_DxEditing = _resolveComponent("DxEditing")!
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DxDataGrid, {
      dataSource: _ctx.dataSource,
      "onUpdate:dataSource": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dataSource) = $event)),
      "allow-column-reordering": true,
      "allow-column-resizing": true,
      "column-resizing-mode": "widget",
      "show-row-lines": true,
      toolbarTemplate: "toolbarTemplate",
      ref: "cariFormBankalarDataGrid",
      style: {"height":"200px"}
    }, {
      toolbarTemplate: _withCtx(() => [
        _createVNode(_component_NButton, {
          onClick: _ctx.newBankaBilgisi,
          size: "small",
          type: "info",
          icon: "plus"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Banka Bilgisi Ekle")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DxLoadPanel, { enabled: false }),
        _createVNode(_component_DxGroupPanel, { visible: false }),
        _createVNode(_component_DxHeaderFilter, { visible: false }),
        _createVNode(_component_DxFilterRow, { visible: false }),
        _createVNode(_component_DxColumnChooser, { enabled: false }),
        _createVNode(_component_DxGroupPanel, { visible: false }),
        _createVNode(_component_DxEditing, {
          "allow-updating": true,
          "allow-deleting": true,
          "use-icons": true,
          "select-text-on-edit-start": true,
          "start-edit-action": "click",
          mode: "cell"
        }),
        _createVNode(_component_DxColumn, {
          caption: "Sil",
          type: "buttons",
          width: 40
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxButton, { name: "delete" })
          ]),
          _: 1
        }),
        _createVNode(_component_DxColumn, {
          width: "40%",
          caption: "IBAN",
          dataField: "iban"
        }),
        _createVNode(_component_DxColumn, {
          width: "15%",
          caption: "Banka Adı",
          dataField: "banka"
        }),
        _createVNode(_component_DxColumn, {
          width: "15%",
          caption: "Şube Adı",
          dataField: "sube"
        }),
        _createVNode(_component_DxColumn, {
          width: "15%",
          caption: "Şube Kodu",
          dataField: "subeKod"
        }),
        _createVNode(_component_DxColumn, {
          width: "15%",
          caption: "Hesap No",
          dataField: "hesapNo"
        })
      ]),
      _: 1
    }, 8, ["dataSource"])
  ]))
}