
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import {StokSimpleView} from "@/api/models/StokSimpleView";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import {KesimApi} from "@/modules/SacUretim/api/KesimApi";
import UretimSacFisFormUstBilgi from "@/modules/SacUretim/components/UretimSacFisFormUstBilgi.vue";
import {SacUretimFisView} from "@/api/models/SacUretimFisView";
import {useSacUretimFisService} from "@/api/services/SacUretimFisService";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import UretimSacFisFormKesim from "@/modules/SacUretim/components/UretimSacFisFormKesim.vue";
import {useCachedSubeDepoService} from "@/modules/Sube/services/useCachedSubeDepoService";
import {UretimSacFisApi} from "@/modules/SacUretim/api/UretimSacFisApi";
import {confirm} from "devextreme/ui/dialog";
import ReportButtons from "@/modules/Report/components/ReportButtons.vue";
import {ReportType} from "@/api/models/ReportType";
import {SacUretimFisReportView} from "@/api/models/SacUretimFisReportView";
import {usedCacheFirmaService} from "@/modules/Ayarlar/Firmalar/services/usedCacheFirmaService";
import SacUretimSiparisSelectDialog from "@/modules/SacUretim/dialogs/SacUretimSiparisSelectButton.vue";
import {SacUretimSatirItem} from "@/api/models/SacUretimSatirItem";
import {SiparisSatirFlatView} from "@/api/models/SiparisSatirFlatView";
import {SacUretimSatirKaynakTip, SacUretimSatirKaynakTipList} from "@/api/models/SacUretimSatirKaynakTip";
import {useStokService} from "@/api/services/StokService";
import {StokEntity} from "@/api/models/StokEntity";
import CariSelectBox from "@/modules/Cari/components/CariSelectBox.vue";
import {SiparisSatirItem} from "@/api/models/SiparisSatirItem";
import {uuid} from "vue-uuid";
import NGrid from "@/components/Grid/NGrid.vue";
import {useSharedStore} from "@/store";
import {SacUretimFisEntityReportView} from "@/api/models/SacUretimFisEntityReportView";
import {StokMalzemeSekilTip, StokMalzemeSekilTipList} from "@/api/models/StokMalzemeSekilTip";
import UretimSacProfilFisForm from "@/modules/SacUretim/Profil/components/UretimSacProfilFisForm.vue";
import {GenelUtils} from "@/modules/Ortak/utils/GenelUtils";
import NButton from "@/components/Button/NButton.vue";
import {useStokSettingsStore} from "@/modules/Stok/store/StokSettingsStore";
import {usePopupService} from "@/services/popupService";


export default defineComponent({
    components: {
        NButton,
        ReportButtons,
        UretimSacProfilFisForm,
        NGrid,
        CariSelectBox,
        UretimSacFisFormUstBilgi, NFormGrid, NLoadingPanel
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        id: {
            type: String
        },
        stokId:{
            type:String
        }
    },
    setup(props, {emit}) {
        const localState = reactive({
            stokRef: ref({
                tip:0,
                tipOzellikler:{}
            } as StokSimpleView),
            showRef: computed({
                get: () => props.show,
                set: (val) => emit("update:show", val)
            }),
            title: ref("Sac Profil Talimat Formu"),
            model: ref({} as SacUretimFisView),
            malzemeSekilTipList: StokMalzemeSekilTipList,
            kaynakTipList: SacUretimSatirKaynakTipList,
            selectedIslem: ref(0),
            selectedCins: ref(""),
            kesimApi: KesimApi,
            aciklamaList:ref([] as string[]),
            paketlemeList:ref([] as string[]),

            store: ref(),
            dataSource: ref(),
            satirTip: StokMalzemeSekilTip,
            depoList: ref([] as any[]),
            uretimSacFisApi: UretimSacFisApi,
            reportType: ReportType,
            reportData: ref({} as SacUretimFisReportView),
            storeSettings:useStokSettingsStore(),
        });

        async function load() {

            localState.stokRef = await useStokService().getSimpleView(props.stokId as string);
            localState.depoList = await useCachedSubeDepoService().getUserDepoList();
            if (isUpdateMode()) {
                localState.model = await useSacUretimFisService().getGet(props.id as string)
                localState.stokRef.getKalanMiktar = localState.stokRef.getKalanMiktar as number + localState.uretimSacFisApi.fisToplamTonaj(localState.model)

            } else {
                localState.model = await useSacUretimFisService().getDefault()
            }
            localState.model.stokParentId = localState.stokRef?.id;

            // STORE
            const storeOptions = {
                data: localState.model.satirlar,
            }
            localState.store = new ArrayStore(storeOptions);

            // DATASOURCE
            const dataSourceOptions = {
                store: localState.store,
                reshapeOnPush: true,
                key: 'id'
            }
            localState.dataSource = new DataSource(dataSourceOptions);

            // Önceki Açıklamalar listesi
            localState.aciklamaList = await useSacUretimFisService().getAciklamaList()
            localState.paketlemeList = await useSacUretimFisService().getPaketlemeList()

            // İşlemleri Enum Sırasına göre filtrele, diz
            const siraListesi = [StokMalzemeSekilTip.Profil,
                StokMalzemeSekilTip.BoyaliProfil,
                StokMalzemeSekilTip.BoruProfil,
                StokMalzemeSekilTip.BoyaliBoru,
                StokMalzemeSekilTip.CProfil,
                StokMalzemeSekilTip.UProfil,
                StokMalzemeSekilTip.OmegaProfil,
                StokMalzemeSekilTip.ZProfil];
            localState.malzemeSekilTipList = StokMalzemeSekilTipList?.filter(a => siraListesi.indexOf(a.value) >= 0)
            localState.malzemeSekilTipList.sort(GenelUtils.sortByEnums(siraListesi));
            const item = localState.malzemeSekilTipList.find(a => a.value == StokMalzemeSekilTip.BoruProfil) as any
            item.description = "Boru"

        }


        async function save(dialogClose: boolean) {
            if (isUpdateMode()) {
                await useSacUretimFisService().putUpdate(localState.model, true)
            } else {
                await useSacUretimFisService().postInsert(localState.model, true);
            }

            await usePopupService().pushSuccessNotification({
                body: "Talimat kaydedildi!",
                duration: 2000,
                title: "Kaydedildi."
            });

            if (dialogClose)
                localState.showRef = false;
        }

        async function remove() {
            // Remove
            if (await confirm('Talimatı silmek istiyor musunuz ?', 'Uyarı')) {

                if (isUpdateMode()) {
                    await useSacUretimFisService().deleteDelete(props.id as string)
                    localState.showRef = false;
                }
            }
        }


        async function saveAndPrint() {
            await save(false)
            localState.reportData = {} as SacUretimFisReportView;

            // Fiş talimatlarında ana stokları yerleştir
            for (const fisReportEntity of [localState.model] as any[]) {
                const anaStok = await useStokService().getGetir(fisReportEntity.stokParentId as string) as StokEntity
                fisReportEntity.anaStok = {
                    genislik: anaStok.tipOzellikler?.genislik,
                    kalinlik: anaStok.tipOzellikler?.kalinlik,
                    uzunluk: anaStok.tipOzellikler?.uzunluk,
                    cins: anaStok.tipOzellikler?.cins,
                    kalite: anaStok.tipOzellikler?.kalite,
                    etiket: anaStok.tipOzellikler?.etiket,
                    adet: anaStok.tipOzellikler?.adet,
                    tonaj: (anaStok.getKalanMiktar as number) + Number(UretimSacFisApi.fisToplamTonaj(fisReportEntity) ?? 0)
                }
            }

            const sacUretimFisReportView: SacUretimFisReportView = {
                firma: await usedCacheFirmaService().getFirma(),
                kullanici:useSharedStore().user.name,
                fisler: [localState.model]
            };



            localState.reportData = sacUretimFisReportView;
            //localState.showRef = false;
        }


        function isUpdateMode() {
            return !!(props.id && props.id.length > 1);
        }

        function close() {
            localState.showRef = false;
        }


        return {
            ...toRefs(localState),
            isUpdateMode,
            load,
            save,
            remove,
            close,
            saveAndPrint,
        };
    }
});
