import { NModuleRoute } from "@/models";

export const FaturaModuleRoutes: NModuleRoute[] = [
  {
    id: "FaturaModule",
    order: 9,
    displayName: "Fatura / İrsaliye",
    icon: {
      type: "file-minus"
    },
    children: [
      {
        id: "FaturaListesi",
        order: 0,
        displayName: "Fatura Listesi",
        requiredRoles: {
          readRoles: ["Fatura.Liste", "Stok.Fatura.Liste", "Cari.Fatura.Liste", "HizmetMasraf.Fatura.Liste"],
          insertRoles: ["Fatura.Ekle"],
          updateRoles: ["Fatura.Guncelle"],
          deleteRoles: ["Fatura.Sil"]
        },
        icon: {
          type: "file-minus"
        },
        component: () =>
          import(
            /* webpackChunkName: "Faturalar"*/ "../pages/FaturaListPage.vue"
            )
      },
      {

        id: "IrsaliyeListe",
        order: 0,
        displayName: "İrsaliye Listesi",
        requiredRoles: {
          readRoles: ["Irsaliye.Liste", "Stok.Fatura.Liste", "Cari.Fatura.Liste", "HizmetMasraf.Fatura.Liste"],
          insertRoles: ["UretimSevk.Fatura.Ekle"],
          updateRoles: ["UretimSevk.Fatura.Guncelle"],
          deleteRoles: ["UretimSevk.Fatura.Sil"]
        },
        icon: {
          type: "file-minus"
        },
        component: () =>
          import(
            /* webpackChunkName: "Irsaliyeler"*/ "../pages/IrsaliyeListPage.vue"
            )
      },


      {
        id: "PlanliFatura",
        order: 0,
        displayName: "Planlı Fatura",
        requiredRoles: {
          readRoles: [""],
          insertRoles: [""],
          updateRoles: [""],
          deleteRoles: [""]

        },
        icon: {
          type: "file-minus"
        },
        component: () =>
          import(
            /* webpackChunkName: "Faturalar"*/ "../../FaturaPlan/pages/FaturaPlanPages.vue"
            )
      },

      {
        id: "BaBsRapor",
        order: 0,
        displayName: "BA / BS ",
        requiredRoles: {
          readRoles: ["Fatura.Rapor.Liste"],
          insertRoles: [],
          updateRoles: [],
          deleteRoles: []
        },
        icon: {
          type: "file-minus"
        },
        component: () =>
          import(
            /* webpackChunkName: "Faturalar"*/ "../pages/BaBsPage.vue"
            )
      },
      {
        id: "EFatura",
        order: 0,
        displayName: "Gelen E-Faturalar",
        requiredRoles: {
          readRoles: ["GelenFatura.List"],
          insertRoles: ["GelenFatura.Insert"],
          updateRoles: ["GelenFatura.Update"],
          deleteRoles: ["GelenFatura.Delete"]
        },
        icon: {
          type: "file-minus"
        },
        component: () =>
          import(/* webpackChunkName: "EFatura"*/ "../pages/EFaturaPage.vue")
      },
      {
        id: "EIrsaliye",
        order: 0,
        displayName: "Gelen E-Irsaliyeler",
        requiredRoles: {
          readRoles: ["GelenFatura.List"],
          insertRoles: ["GelenFatura.Insert"],
          updateRoles: ["GelenFatura.Update"],
          deleteRoles: ["GelenFatura.Delete"]
        },
        icon: {
          type: "file-minus"
        },
        component: () =>
            import(/* webpackChunkName: "EFatura"*/ "../pages/EIrsaliyePage.vue")
      }


    ]
  }
];
