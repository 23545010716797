import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {IdNameFisSatir} from '../models/IdNameFisSatir'

export const useSacUretimStokService = defineStore({
id: 'SacUretimStokService',
actions: {


	async getTreeView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sacUretimStok/TreeView/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async putStokEtiketGuncelle(liste:IdNameFisSatir[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sacUretimStok/StokEtiketGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:liste });
		await request();
		return response.value;
	}
}
});
