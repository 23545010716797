import {RecordStatus} from '../models/RecordStatus'
import {IdName} from '../models/IdName'
import {XmlImporterConfigStokMap} from '../models/XmlImporterConfigStokMap'
import {XmlImporterConfigLogItem} from '../models/XmlImporterConfigLogItem'

export class XmlImporterConfigEntity{
	id?:string;
	status?:RecordStatus;
	kayitTarihi?:string;
	kullaniciAdi?:string;
	name?:string;
	source?:string;
	userName?:string;
	password?:string;
	intervalHour?:number;
	active?:boolean;
	runForce?:boolean;
	depoId?:string;
	cari?:IdName;
	previewCount?:number;
	maxDataLogCount?:number;
	maxConfigLogCount?:number;
	executeTime?:string;
	cacheMinute?:number;
	statusDesc?:string;
	isRunning?:boolean;
	stokMap?:XmlImporterConfigStokMap;
	logs?:XmlImporterConfigLogItem[];
	addOrUpdateToStok?:boolean;
}
