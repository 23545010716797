import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dx-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_DepoSelectBox = _resolveComponent("DepoSelectBox")!
  const _component_NFormFieldLabelTop = _resolveComponent("NFormFieldLabelTop")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_DxExport = _resolveComponent("DxExport")!
  const _component_DxLoadPanel = _resolveComponent("DxLoadPanel")!
  const _component_DxGroupPanel = _resolveComponent("DxGroupPanel")!
  const _component_DxScrolling = _resolveComponent("DxScrolling")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxColumnChooser = _resolveComponent("DxColumnChooser")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    title: "Hızlı Saç Ekleme",
    modelValue: _ctx.showRef,
    "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.showRef) = $event)),
    width: "900px",
    style: {"height":"95%"}
  }, {
    header: _withCtx(() => [
      _createVNode(_component_NGrid, {
        cols: "auto auto",
        gap: "5px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NTag, {
            type: "info",
            filled: "",
            style: {"font-size":"12px"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Fatura Tarihi : " + _toDisplayString(_ctx.model.tarih.formatDate()), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_NTag, {
            type: "primary",
            filled: "",
            style: {"font-size":"12px"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Adet : 1")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    body: _withCtx(() => [
      _createVNode(_component_NFormGrid, {
        cols: "1fr",
        padding: "12px",
        gap: "4px",
        rows: "1fr minmax(0,1fr)"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NGrid, {
            cols: "1fr 1fr 1fr 1fr",
            gap: "2px"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTextBox, {
                labelMode: "floating",
                label: "Etiket",
                value: _ctx.model.etiket,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.etiket) = $event))
              }, null, 8, ["value"]),
              _createVNode(_component_DxTextBox, {
                labelMode: "floating",
                label: "Cinsi",
                value: _ctx.model.cins,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.cins) = $event))
              }, null, 8, ["value"]),
              _createVNode(_component_DxTextBox, {
                labelMode: "floating",
                label: "Kalite",
                value: _ctx.model.kalite,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.kalite) = $event))
              }, null, 8, ["value"]),
              _createVNode(_component_NFormFieldLabelTop, {
                label: "Şube / Depo",
                style: {"margin-top":"-2px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DepoSelectBox, {
                    modelValue: _ctx.model.depoId,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.depoId) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_DxNumberBox, {
                style: {"font-size":"18px"},
                height: "38px",
                labelMode: "floating",
                label: "Ağırlık",
                format: "#,##0 Kg",
                value: _ctx.model.agirlik,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.agirlik) = $event))
              }, null, 8, ["value"]),
              _createVNode(_component_DxNumberBox, {
                style: {"font-size":"18px"},
                height: "38px",
                labelMode: "floating",
                label: "Kalınlık",
                value: _ctx.model.kalinlik,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.kalinlik) = $event))
              }, null, 8, ["value"]),
              _createVNode(_component_DxNumberBox, {
                style: {"font-size":"18px"},
                height: "38px",
                labelMode: "floating",
                label: "Genişlik",
                value: _ctx.model.genislik,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.genislik) = $event))
              }, null, 8, ["value"]),
              _createVNode(_component_NGrid, { cols: "50px 1fr" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxTextBox, {
                    style: {"font-size":"18px"},
                    height: "38px",
                    labelMode: "floating",
                    label: "Boy",
                    value: _ctx.model.boyAciklama,
                    "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.boyAciklama) = $event))
                  }, null, 8, ["value"]),
                  _createVNode(_component_DxNumberBox, {
                    style: {"font-size":"18px"},
                    height: "38px",
                    labelMode: "floating",
                    label: " ",
                    value: _ctx.model.boy,
                    "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.boy) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_DxTextBox, {
                labelMode: "floating",
                label: "Plaka",
                value: _ctx.model.plaka,
                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.plaka) = $event))
              }, null, 8, ["value"]),
              _createVNode(_component_DxTextBox, {
                style: {"font-size":"15px"},
                height: "35px",
                labelMode: "floating",
                label: "Brüt Tonaj",
                value: _ctx.model.altBilgi.brutTonaj,
                "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.altBilgi.brutTonaj) = $event)),
                format: "#,##0.0000"
              }, null, 8, ["value"]),
              _createVNode(_component_DxTextBox, {
                labelMode: "floating",
                label: "Sipariş Numarası",
                value: _ctx.model.altBilgi.siparisNo,
                "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.altBilgi.siparisNo) = $event))
              }, null, 8, ["value"]),
              _createVNode(_component_DxTextBox, {
                labelMode: "floating",
                label: "Bobin No",
                value: _ctx.model.altBilgi.bobinNo,
                "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.altBilgi.bobinNo) = $event))
              }, null, 8, ["value"]),
              _createVNode(_component_DxTextBox, {
                labelMode: "floating",
                label: "Açıklama",
                style: {"grid-column":"span 2"},
                value: _ctx.model.aciklama,
                "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.aciklama) = $event))
              }, null, 8, ["value"]),
              _createVNode(_component_DxTextBox, {
                labelMode: "floating",
                label: "Not",
                style: {"grid-column":"span 2"},
                value: _ctx.model.altBilgi.not,
                "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.altBilgi.not) = $event))
              }, null, 8, ["value"]),
              _createVNode(_component_DxTextBox, {
                labelMode: "floating",
                label: "Lot No",
                value: _ctx.model.altBilgi.lotNo,
                "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.altBilgi.lotNo) = $event))
              }, null, 8, ["value"]),
              _createVNode(_component_DxTextBox, {
                labelMode: "floating",
                label: "Döküm No",
                value: _ctx.model.altBilgi.dokumNo,
                "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model.altBilgi.dokumNo) = $event))
              }, null, 8, ["value"]),
              _createVNode(_component_DxTextBox, {
                labelMode: "floating",
                label: "Menşei",
                value: _ctx.model.altBilgi.mensei,
                "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model.altBilgi.mensei) = $event))
              }, null, 8, ["value"]),
              _createElementVNode("div", null, [
                _createVNode(_component_NGrid, {
                  cols: "1fr auto auto auto",
                  style: {"grid-column":"span 2","margin-top":"5px"},
                  gap: "8px"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      (_ctx.model.id && _ctx.model.id.length > 0 )
                        ? (_openBlock(), _createBlock(_component_NButton, {
                            key: 0,
                            icon: "x",
                            size: "small",
                            type: "primary",
                            onClick: _ctx.iptalEt
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" İptal ")
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", null, [
                      (_ctx.model.id && _ctx.model.id.length > 0 )
                        ? (_openBlock(), _createBlock(_component_NButton, {
                            key: 0,
                            icon: "save",
                            size: "small",
                            type: "warning",
                            onClick: _ctx.satiraSacEkle
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Güncelle ")
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", null, [
                      (!(_ctx.model.id && _ctx.model.id.length > 0))
                        ? (_openBlock(), _createBlock(_component_NButton, {
                            key: 0,
                            style: {"text-align":"right"},
                            icon: "save",
                            size: "small",
                            type: "success",
                            onClick: _ctx.satiraSacEkle
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Satıra Ekle ")
                            ]),
                            _: 1
                          }, 8, ["onClick"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_DxDataGrid, {
              ref: "faturaUretimSacDxDataGrid",
              style: {"width":"100%","height":"100%"},
              dataSource: _ctx.models,
              "onUpdate:dataSource": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.models) = $event))
            }, {
              eylemlerCellTemplate: _withCtx(({data}) => [
                _createVNode(_component_NGrid, {
                  cols: "35px 35px",
                  gap: "2px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NButton, {
                      onClick: ($event: any) => (_ctx.sil(data.data.id)),
                      size: "xsmall",
                      type: "danger",
                      icon: "trash"
                    }, null, 8, ["onClick"]),
                    _createVNode(_component_NButton, {
                      onClick: ($event: any) => (_ctx.duzenle(data.data.id)),
                      size: "xsmall",
                      type: "primary",
                      icon: "edit"
                    }, null, 8, ["onClick"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_DxExport, { enabled: false }),
                _createVNode(_component_DxLoadPanel, { enabled: true }),
                _createVNode(_component_DxGroupPanel, { visible: false }),
                _createVNode(_component_DxScrolling, { mode: "standart" }),
                _createVNode(_component_DxHeaderFilter, { visible: true }),
                _createVNode(_component_DxFilterRow, { visible: false }),
                _createVNode(_component_DxColumnChooser, { enabled: false }),
                _createVNode(_component_DxColumn, {
                  width: "100px",
                  caption: "Eylemler",
                  cellTemplate: "eylemlerCellTemplate"
                }),
                _createVNode(_component_DxColumn, {
                  width: "80px",
                  caption: "Etiket",
                  dataField: "etiket"
                }),
                _createVNode(_component_DxColumn, {
                  width: "80px",
                  caption: "Cinsi",
                  dataField: "cins"
                }),
                _createVNode(_component_DxColumn, {
                  width: "80px",
                  caption: "Kalite",
                  dataField: "kalite"
                }),
                _createVNode(_component_DxColumn, {
                  width: "80px",
                  caption: "Ağırlık",
                  dataField: "getIlkHareket.tonaj"
                }),
                _createVNode(_component_DxColumn, {
                  width: "80px",
                  caption: "Kalınlık",
                  dataField: "kalinlik"
                }),
                _createVNode(_component_DxColumn, {
                  width: "80px",
                  caption: "Genişlik",
                  dataField: "genislik"
                }),
                _createVNode(_component_DxColumn, {
                  width: "80px",
                  caption: "Boy",
                  dataField: "boy"
                }),
                _createVNode(_component_DxColumn, {
                  width: "80px",
                  caption: "Boy A.",
                  dataField: "boyAciklama"
                }),
                _createVNode(_component_DxColumn, {
                  width: "80px",
                  caption: "Şube / Depo",
                  dataField: "depoId",
                  calculateCellValue: (d) => _ctx.depolar.find(a=>a.id == d.depoId)?.name
                }, null, 8, ["calculateCellValue"])
              ]),
              _: 1
            }, 8, ["dataSource"])
          ])
        ]),
        _: 1
      })
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_NButton, {
        type: "warning",
        onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.showRef = false )),
        icon: "x",
        size: "normal"
      }, {
        default: _withCtx(() => [
          _createTextVNode("İptal Et ")
        ]),
        _: 1
      }),
      _createVNode(_component_NButton, {
        type: "success",
        onClick: _ctx.faturayaAktar,
        icon: "save",
        size: "normal"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Forma Aktar ")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}