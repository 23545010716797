import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_NDropDownTreeView = _resolveComponent("NDropDownTreeView")!
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_HepsiBuradaOzellikEditor = _resolveComponent("HepsiBuradaOzellikEditor")!

  return (_openBlock(), _createElementBlock("div", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), [
    _createVNode(_component_NFormGrid, {
      cols: "1fr 1fr",
      padding: "0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NFormField, {
          label: "Marka",
          "grid-cols": "165px 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.hepsiBuradaRef.marka,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hepsiBuradaRef.marka) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Garanti Süresi",
          span: "1",
          "grid-cols": "150px 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxNumberBox, {
              value: _ctx.hepsiBuradaRef.garantiSuresi,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.hepsiBuradaRef.garantiSuresi) = $event))
            }, null, 8, ["value"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.hepsiBurada && _ctx.hepsiBuradaRef.kategori)
      ? (_openBlock(), _createBlock(_component_NFormGrid, {
          key: 0,
          cols: "1fr auto"
        }, {
          default: _withCtx(() => [
            (_ctx.stokRef)
              ? (_openBlock(), _createBlock(_component_NFormField, {
                  key: 0,
                  label: "Garanti Süresi",
                  span: "1",
                  "grid-cols": "100px 1fr"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxNumberBox, {
                      value: _ctx.hepsiBuradaRef.garantiSuresi,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.hepsiBuradaRef.garantiSuresi) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_NFormField, {
              label: "Kategori",
              "grid-cols": "150px 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NDropDownTreeView, {
                  "selected-item": _ctx.hepsiBuradaRef.kategori,
                  "onUpdate:selectedItem": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.hepsiBuradaRef.kategori) = $event)),
                  "data-source": _ctx.kategoriler,
                  "onUpdate:dataSource": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.kategoriler) = $event)),
                  "data-structure": "plain",
                  "key-expr": "categoryId",
                  "parent-id-expr": "parentCategoryId"
                }, null, 8, ["selected-item", "data-source"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Gönder",
              "grid-cols": "1fr auto"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NSwitch, {
                  modelValue: _ctx.hepsiBuradaRef.isActive,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.hepsiBuradaRef.isActive) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.hepsiBuradaRef && _ctx.hepsiBuradaRef.kategori && _ctx.hepsiBuradaRef.kategori.categoryId  > 0)
      ? (_openBlock(), _createBlock(_component_HepsiBuradaOzellikEditor, {
          key: 1,
          "kategori-id": _ctx.hepsiBuradaRef.kategori.categoryId,
          "onUpdate:kategoriId": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.hepsiBuradaRef.kategori.categoryId) = $event)),
          ozellikler: _ctx.hepsiBuradaRef.ozellikler,
          "onUpdate:ozellikler": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.hepsiBuradaRef.ozellikler) = $event))
        }, null, 8, ["kategori-id", "ozellikler"]))
      : _createCommentVNode("", true)
  ], 16))
}