import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {B2BCariLoginView} from '../models/B2BCariLoginView'

export const useB2BClientAccountService = defineStore({
id: 'B2BClientAccountService',
actions: {


	async postUpdateUser(b2BCariLoginView:B2BCariLoginView,cariId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/B2BClientAccount/UpdateUser/${shared.firm.id}/${cariId}`,{ method:'POST', data:b2BCariLoginView });
		await request();
		return response.value;
	},

	async getGetUser(cariId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/B2BClientAccount/GetUser/${shared.firm.id}/${cariId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetCariId()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/B2BClientAccount/GetCariId/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetCariEntity(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/B2BClientAccount/GetCariEntity/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
