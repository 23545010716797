
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import MahalleAraSelectBox from "@/modules/Ortak/components/MahalleAraSelectBox.vue";
import {AdresTipList} from "@/api/models/AdresTip";
import {AdresItem} from "@/api/models/AdresItem";
import NGrid from "@/components/Grid/NGrid.vue";
import NViewGrid from "@/components/ViewGrid/NViewGrid.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NInput from "@/components/Input/NInput.vue";
import NButton from "@/components/Button/NButton.vue";
import {DxTextBox} from "devextreme-vue";


export default defineComponent({
  components: {NGrid, NInput, NFormField, NFormGrid, MahalleAraSelectBox, DxTextBox},
  props: ['modelValue', 'adresler', 'active'],
  setup(props) {
    const localState = reactive({
      model: computed(() => {

        const propsClone = props;
        propsClone.modelValue.tel = propsClone.modelValue.tel?.trim();
        propsClone.modelValue.tel2 = propsClone.modelValue.tel2?.trim();
        propsClone.modelValue.cep = propsClone.modelValue.cep?.trim();
        propsClone.modelValue.cep2 = propsClone.modelValue.cep2?.trim();
        return propsClone.modelValue as AdresItem

      }),
      adresTipListRef: ref(AdresTipList),
      activeRef: computed(() => props.active),
      useMask:ref(true),
        maskOptions: ref()
    })

    localState.maskOptions = {
        icon: 'clearformat',
        width: 24,
        template:"maskButton",
        onClick: (e:any) => {
            localState.useMask = !localState.useMask
        },
    }

    function isAllNumber(numbers: any) {
      if (numbers?.length > 10)
        return false;

      if (numbers === undefined || numbers === null || numbers?.length <= 1) {
        return true;
      }

      const sonuc = isNaN(numbers); // NaN = non number
      if (sonuc) // NaN ise Sadece değildir
        return false;
      else
        return true;
    }

    return {
      ...toRefs(localState),
      isAllNumber
    }
  }
})
