
import { computed, defineComponent, reactive, toRefs } from "vue";
import NGrid from "@/components/Grid/NGrid.vue";
import { PerakendeView } from "@/api/models/PerakendeView";
import NInput from "@/components/Input/NInput.vue";
import NCard from "@/components/Card/NCard.vue";
import NSwitch from "@/components/Switch/NSwitch.vue";
import NCheckbox from "@/components/Checkbox/NCheckbox.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormFieldLabelTop from "@/components/FormField/NFormFieldLabelTop.vue";

export default defineComponent({
  components: { NGrid },
  props: {
    modelValue: {
      type: Object
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      model: computed({
        get: () => props.modelValue as PerakendeView,
        set: val => emit("update:modelValue", val)
      })
    });
    return {
      ...toRefs(localState)
    };
  }
});
