
import { computed, defineComponent, reactive, ref, toRefs } from "vue";

export default defineComponent({
  props: {
    tip: {
      type: String,
      default: "add-circle"
    },
    boyut: {
      type: String,
      default: "18px"
    },
    renk: {
      type: String,
      default: "#09203f"
    },
    title: {
      type: String,
      default: "Açıklama Yok"
    },
    addShadow: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  setup(props) {
    const localState = reactive({
      tipRef: computed(() => props.tip),
      renkRef: computed(() => props.renk),
      boyutRef: computed(() => props.boyut)
    });

    return {
      ...toRefs(localState)
    };
  }
});
