import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, mergeProps as _mergeProps, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["ondblclick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NCheckbox = _resolveComponent("NCheckbox")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdownMenu = _resolveComponent("NDropdownMenu")!
  const _component_NDropdown = _resolveComponent("NDropdown")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_StokListDialog = _resolveComponent("StokListDialog")!
  const _component_HizliStokDialog = _resolveComponent("HizliStokDialog")!
  const _component_StokXmlSelectListDialog = _resolveComponent("StokXmlSelectListDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NGrid, _mergeProps({
      cols: "1fr auto auto auto",
      gap: "3px",
      ref: "grid"
    }, _ctx.$attrs), {
      default: _withCtx(() => [
        _createElementVNode("div", { ondblclick: _ctx.stokAraDblClick }, [
          _createVNode(_component_DxTextBox, {
            id: "inputArama",
            class: "stokAramaInput",
            style: _normalizeStyle('font-size : ' + _ctx.fontSize + ' !important;' ),
            ref: "stokDxTextBox",
            "show-clear-button": false,
            onEnterKey: _ctx.stokAraEnterKey,
            placeholder: _ctx.flatStore.count + ' Adet Stok İçinde Ara',
            value: _ctx.arama,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.arama) = $event))
          }, null, 8, ["style", "onEnterKey", "placeholder", "value"])
        ], 8, _hoisted_1),
        _createElementVNode("div", null, [
          (_ctx.refresh)
            ? (_openBlock(), _createBlock(_component_NButton, {
                key: 0,
                type: "warning",
                click: _ctx.yenileStok,
                icon: "refresh-ccw",
                size: "small",
                title: "Stok Listesini Yenile"
              }, null, 8, ["click"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          (_ctx.insert)
            ? (_openBlock(), _createBlock(_component_NButton, {
                key: 0,
                type: "success",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hizliStokDialogShow = true )),
                icon: "plus",
                size: "small",
                title: "Yeni Stok Ekle"
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", null, [
          _createVNode(_component_NDropdown, { placement: "bottom-right" }, {
            menu: _withCtx(() => [
              _createVNode(_component_NDropdownMenu, { style: {"width":"200px"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_NDropdownItem, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_NCheckbox, {
                        label: "Sadece Barkodda Ara",
                        modelValue: _ctx.isSearchBarkod,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isSearchBarkod) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NDropdownItem, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_NCheckbox, {
                        label: "Sadece Stok Kodunda Ara",
                        modelValue: _ctx.isSearchStokKod,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isSearchStokKod) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NDropdownItem, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_NCheckbox, {
                        label: "Sadece Stok Adında Ara",
                        modelValue: _ctx.isSearchStokAd,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isSearchStokAd) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NDropdownItem, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_NCheckbox, {
                        label: "Barkodlarda Ara",
                        modelValue: _ctx.isSearchBarkodlar,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isSearchBarkodlar) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NDropdownItem, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_NCheckbox, {
                        label: "Kodlarda Ara",
                        modelValue: _ctx.isSearchKodlar,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isSearchKodlar) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NDropdownItem, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_NCheckbox, {
                        label: "Stok bulunamadığında XML Stoklarında Ara",
                        modelValue: _ctx.isSearchXmlStok,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.isSearchXmlStok) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_NButton, {
                type: "info",
                icon: "settings",
                size: "small"
              })
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 16),
    (_ctx.showStokListRef)
      ? (_openBlock(), _createBlock(_component_StokListDialog, {
          key: 0,
          onCoklu: _ctx.coklu,
          onStokDblClick: _ctx.tek,
          onHizmetDblClick: _ctx.tekHizmet,
          onCokluHizmet: _ctx.cokluHizmet,
          "selected-tab": _ctx.stoklistTab,
          "onUpdate:selectedTab": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.stoklistTab) = $event)),
          modelValue: _ctx.arama,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.arama) = $event)),
          show: _ctx.showStokListRef,
          "onUpdate:show": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.showStokListRef) = $event))
        }, null, 8, ["onCoklu", "onStokDblClick", "onHizmetDblClick", "onCokluHizmet", "selected-tab", "modelValue", "show"]))
      : _createCommentVNode("", true),
    (_ctx.hizliStokDialogShow)
      ? (_openBlock(), _createBlock(_component_HizliStokDialog, {
          key: 1,
          onStokEntityAdded: _ctx.stokEntityAdded,
          show: _ctx.hizliStokDialogShow,
          "onUpdate:show": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.hizliStokDialogShow) = $event))
        }, null, 8, ["onStokEntityAdded", "show"]))
      : _createCommentVNode("", true),
    (_ctx.showXmlStokListDialog)
      ? (_openBlock(), _createBlock(_component_StokXmlSelectListDialog, {
          key: 2,
          show: _ctx.showXmlStokListDialog,
          "onUpdate:show": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.showXmlStokListDialog) = $event)),
          onStokEntityAddedFromXml: _ctx.stokAddedFromXml,
          "search-text": _ctx.arama
        }, null, 8, ["show", "onStokEntityAddedFromXml", "search-text"]))
      : _createCommentVNode("", true)
  ], 64))
}