import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","height":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TestComponent = _resolveComponent("TestComponent")!
  const _component_NViewGrid = _resolveComponent("NViewGrid")!

  return (_openBlock(), _createBlock(_component_NViewGrid, { cols: "1fr" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_TestComponent)
      ])
    ]),
    _: 1
  }))
}