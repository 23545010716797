import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StokMiktarOzet = _resolveComponent("StokMiktarOzet")!
  const _component_StokHareketDialogList = _resolveComponent("StokHareketDialogList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showMiktarOzet)
      ? (_openBlock(), _createBlock(_component_StokMiktarOzet, {
          key: 0,
          show: _ctx.showMiktarOzet,
          "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showMiktarOzet) = $event)),
          "stok-id": _ctx.stokId,
          "onUpdate:stokId": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.stokId) = $event))
        }, null, 8, ["show", "stok-id"]))
      : _createCommentVNode("", true),
    (_ctx.stokHareketShowDialog)
      ? (_openBlock(), _createBlock(_component_StokHareketDialogList, {
          key: 1,
          "stok-adi": _ctx.stokAdi,
          "onUpdate:stokAdi": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.stokAdi) = $event)),
          "cari-id": "",
          show: _ctx.stokHareketShowDialog,
          "onUpdate:show": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.stokHareketShowDialog) = $event)),
          "stok-id": _ctx.stokId,
          "onUpdate:stokId": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.stokId) = $event)),
          "item-id": _ctx.itemId,
          "onUpdate:itemId": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.itemId) = $event))
        }, null, 8, ["stok-adi", "show", "stok-id", "item-id"]))
      : _createCommentVNode("", true)
  ], 64))
}