
import { computed, defineComponent, ref } from "vue";
import { Positions } from "@/models";
import { onClickOutside } from "@vueuse/core";
import usePopover from "@/utils/usePopover";

export default defineComponent({
  name: "NDropdown",
  props: {
    placement: {
      default: Positions.B,
      type: String,
      validator: (value: Positions) =>
        [
          Positions.B,
          Positions.BL,
          Positions.BR,
          Positions.L,
          Positions.LT,
          Positions.LB,
          Positions.R,
          Positions.RT,
          Positions.RB,
          Positions.T,
          Positions.TL,
          Positions.TR
        ].includes(value)
    },
    clickable: Boolean
  },
  setup(props) {
    const {
      show,
      placement: placementRef,
      disabled,
      popover,
      trigger,
      handleMouseEnter,
      handleMouseLeave,
      hidePopover,
      showPopover
    } = usePopover(props);

    function toggleDropdown() {
      if (disabled.value) {
        return;
      }
      if (show.value) {
        hidePopover();
      } else {
        showPopover();
      }
    }

    const transition = computed(() => `drop-${placementRef.value ?? "bottom"}`);

    const placementSide = computed(() =>
      placementRef.value ? placementRef.value.split("-")[0] : "bottom"
    );

    const listeners = computed(() => {
      return props.clickable
        ? {
            click: toggleDropdown,
          }
        : {
            mouseenter: handleMouseEnter,
            mouseleave: handleMouseLeave,
          };
    });

    const dropdownEl = ref();

    onClickOutside(dropdownEl, () => hidePopover());

    return {
      toggleDropdown,
      transition,
      placementSide,
      listeners,
      show,
      hidePopover,
      placementRef,
      popover,
      trigger,
      dropdownEl
    };
  }
});
