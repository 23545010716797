
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import { CategoryAttributeValueModel } from "@/api/models/CategoryAttributeValueModel";
import { useHepsiBuradaService } from "@/api/services/HepsiBuradaService";
import NDropDownListBox from "@/components/Dropdown/NDropDownListBox.vue";
import NLoading from "@/components/Loading/NLoading.vue";
import {StokView} from "@/api/models/StokView";
import HepsiBuradaEslestirmeDialog from "@/modules/Pazaryerleri/HepsiBurada/dialogs/HepsiBuradaEslestirmeDialog.vue";
import _ from "lodash";
import {StokEntity} from "@/api/models/StokEntity";
import {IdName} from "@/api/models/IdName";
import NRefresher from "@/components/Refresher/NRefresher.vue";

export default defineComponent({
  components: {NRefresher, HepsiBuradaEslestirmeDialog,  NDropDownListBox },
  props: {
    attribute:{
      type:Object
    },
    categoryId:{
      type:Number
    },
    label:{
      type:String
    },
    selectedValue:{
      type:String
    },
    stok:{
      type: StokView
    }
  },
  setup(props,{emit}) {

    const localState = reactive({
      refresher:ref(),
      loading:ref(true),
      values:ref([] as CategoryAttributeValueModel[]),
      selectedValueRef:computed({
        get:()=> props.selectedValue,
        set:(val) =>{
          emit('update:selectedValue',val);
          emit('change',val)
        }
      }),
      attributeRef:computed({get:()=> props.attribute,
        set:(val)=> {
            emit('update:attribute',val);
        }
      }),
      stokRef:computed(()=> props.stok as StokEntity),
      showEslestir:ref(false),
      selectedNesilceValues:ref(),
      selectedPropertyName:ref()
    });

    watch(()=> props.categoryId,()=>{
      //alert(props.categoryId)
      load()
    })



    async function load() {
      localState.loading = true;
      try {
        if (props.attribute) {
          localState.values = await useHepsiBuradaService().getKategoriOzellikDegerleri(365, props.categoryId as number, props.attribute.id as string)
        }
      }finally {
        localState.loading = false;
      }
    }


    function getPropertyValues(propertyName:string){

      const variantIndex = localState.stokRef.varyantlar?.findIndex(a => a.name == propertyName || a.nameHepsiBurada == propertyName) as number;
      if (variantIndex >= 0){

        const variantValues = localState.stokRef.items?.map(a => (a.varyantDegerleri?a.varyantDegerleri[variantIndex]:''))
        const variantValuesDistinc = _.uniq(variantValues);
        return  variantValuesDistinc;
      }


      return [];
    }



    function eslestir() {

      localState.selectedPropertyName = props.attribute?.name as string;
      localState.selectedNesilceValues = getPropertyValues(props.attribute?.name as string);

      localState.showEslestir = true;
    }

    return {
      ...toRefs(localState),
      eslestir,
      load
    };
  }
});

