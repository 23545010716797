
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import N11OzellikEditor from "@/modules/Pazaryerleri/N11/components/N11OzellikEditor.vue";
import { N11CategoryItem } from "@/api/models/N11CategoryItem";
import { useN11CategoryService } from "@/api/services/N11CategoryService";
import NDropDownTreeView from "@/components/DropDownTreeView/NDropDownTreeView.vue";
import { useN11ShipmentService } from "@/api/services/N11ShipmentService";
import { N11PazaryeriItem } from "@/api/models/N11PazaryeriItem";
import N11ShipmentTemplateSelectBox from "@/modules/Pazaryerleri/N11/components/N11ShipmentTemplateSelectBox.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import {useN11CategoryServiceCache} from "@/api/servicesCache/N11CategoryServiceCache";
import NFormField from "@/components/FormField/NFormField.vue";
import {PazaryeriFiyatTipiList} from "@/api/models/PazaryeriFiyatTipi";

export default defineComponent({
  components: {NFormField, NFormGrid, N11ShipmentTemplateSelectBox, NDropDownTreeView, N11OzellikEditor },
  props: ['n11'],
  setup(props) {

    const localState = reactive({
      n11Ref:computed(() => props.n11 as N11PazaryeriItem),
      kategoriler:ref([] as N11CategoryItem[]),
      shipmentTemplates:ref([]),
        pazaryeriFiyatTipiList:ref(PazaryeriFiyatTipiList),
    });


    async function load(){
      const response = await useN11CategoryServiceCache().getN11KategoriGetirTumu();
      localState.kategoriler = response.categories;
    }

    load()
    return {
      ...toRefs(localState)
    };
  }
});

