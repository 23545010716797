
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NInput from "@/components/Input/NInput.vue";
import NSwitch from "@/components/Switch/NSwitch.vue";
import { HepsiBuradaListingPazaryeriItem } from "@/api/models/HepsiBuradaListingPazaryeriItem";
import {PazaryeriFiyatTipiList} from "@/api/models/PazaryeriFiyatTipi";

export default defineComponent({
  components: { NSwitch, NFormField, NFormGrid },
  props: ['hepsiBuradaListing'],

  setup(props) {
    const localState = reactive({
      model:computed(()=> props.hepsiBuradaListing as HepsiBuradaListingPazaryeriItem),
        pazaryeriFiyatTipiList:ref(PazaryeriFiyatTipiList),
    });

    return {
      ...toRefs(localState)
    };
  }
});

