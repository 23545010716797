
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import {BasitUretimEntity} from "@/api/models/BasitUretimEntity";
import {BasitUretimView} from "@/api/models/BasitUretimView";
import NButton from "@/components/Button/NButton.vue";
import {DxLookup} from "devextreme-vue";
import {DxDropDownOptions} from "devextreme-vue/lookup";
import NCard from "@/components/Card/NCard.vue";
import {BasitUretimTip} from "@/api/models/BasitUretimTip";
import NTag from "@/components/Tag/NTag.vue";

export default defineComponent({
  components: {NTag, NGrid, NCard, NButton, NModalDialog},
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    liste: {
      type: Array
    },
    stokAd: {
      type: String,
      default: '',
    }

  },
  setup(props, {emit}) {

    const localState = reactive({
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val),
      }),
      model: computed(() => props.liste as BasitUretimView[]),
      selectedItem: ref({} as BasitUretimView),
      stokAdRef: computed(() => props.stokAd),
      uretimTip: BasitUretimTip,
    });

    function dblclick(view: BasitUretimView) {
      emit("selecetedDblClik" , view);
    }

    function getDisplayExpr(e: any) {
     // console.log("eeee", e);
      return e.id;
    }

    return {
      ...toRefs(localState),
      dblclick,
      getDisplayExpr
    };
  }
});
