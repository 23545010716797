import {defineStore} from "pinia";
import { HizmetMasrafEntity } from "@/api/models/HizmetMasrafEntity";
import { useHizmetMasrafService } from "@/api/services/HizmetMasrafService";
import { GiderYerleriView } from "@/api/models/GiderYerleriView";
import { useListelerService } from "@/api/services/ListelerService";


export const useCachedHizmetMasrafGiderService = defineStore({
  state: () => ({
    hizmetMasrafListe: [] as HizmetMasrafEntity[],
    giderlerListe : [] as GiderYerleriView[],
  }),
  id: "useCachedHizmetMasrafGiderService",
  actions: {
    async getHizmetMasrafList(): Promise<HizmetMasrafEntity[]> {
      if (this.hizmetMasrafListe.length == 0) {
        this.hizmetMasrafListe = await useHizmetMasrafService().getList() ;
        return this.hizmetMasrafListe
      }
      return this.hizmetMasrafListe;
    },
    async refreshHizmetMasraf(): Promise<HizmetMasrafEntity[]> {
        this.hizmetMasrafListe =await useHizmetMasrafService().getList() ;
      return this.hizmetMasrafListe;
    },
    async getGiderYerleriListe():Promise<GiderYerleriView[]>{
      if (this.giderlerListe.length == 0) {
        this.giderlerListe = await useListelerService().getGiderYerleri();
        return this.giderlerListe
      }
      return this.giderlerListe;
    },
    async refreshGiderYerleri():Promise<GiderYerleriView[]>{
      this.giderlerListe = await useListelerService().getGiderYerleri();
      return this.giderlerListe
    }


  }
})
