
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from "vue";
import NFormField from "@/components/FormField/NFormField.vue";
import {StokTip} from "@/api/models/StokTip";
import {StokView} from "@/api/models/StokView";
import DepoSelectBox from "@/modules/Ortak/components/DepoSelectBox.vue";
import CariSelectBox from "@/modules/Cari/components/CariSelectBox.vue";
import {useStokService} from "@/api/services/StokService";
import {useStokSettingsStore} from "@/modules/Stok/store/StokSettingsStore";

export default defineComponent({
    components: {CariSelectBox, DepoSelectBox, NFormField},
    props: {
        stok: {
            type: Object
        }
    },
    setup(props, {emit}) {

        const localState = reactive({
            stokRef: computed(() => props.stok as StokView),
            tipler: [],
            menseiList: ref([] as string[]),
            storeSettings:useStokSettingsStore()
        });

        onMounted(async () => {
            await load();
        })

        async function load() {
            localState.menseiList = await useStokService().getMenseiList()
        }

        function updateStokName(e: any) {
            localState.stokRef.tipOzellikler!.cins = localState.stokRef?.tipOzellikler?.cins?.toUpperCase()
            localState.stokRef.tipOzellikler!.kalite = localState.stokRef?.tipOzellikler?.kalite?.toUpperCase()

            if (localState.stokRef.tip === StokTip.Sac && isSacValid()) {
                const stokName =
                    localState.stokRef.tipOzellikler?.cins?.toUpperCase() + ' '
                    + localState.stokRef.tipOzellikler?.kalite?.toUpperCase() + ' '
                    + localState.stokRef.tipOzellikler?.kalinlik + ' * '
                    + localState.stokRef.tipOzellikler?.genislik + ' * '
                    + (localState.stokRef.tipOzellikler?.uzunluk && localState.stokRef.tipOzellikler?.uzunluk > 0
                        ? localState.stokRef.tipOzellikler?.uzunluk
                        : 'R')
                localState.stokRef.ad = stokName;
            }
        }

        function isSacValid() {
            return localState.stokRef.tipOzellikler?.cins
                && localState.stokRef.tipOzellikler?.kalite
                && localState.stokRef.tipOzellikler?.kalinlik && localState.stokRef.tipOzellikler?.kalinlik > 0
                && localState.stokRef.tipOzellikler?.genislik && localState.stokRef.tipOzellikler?.genislik > 0
        }

        return {
            ...toRefs(localState),
            updateStokName
        };
    }
});

