import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NRefresher = _resolveComponent("NRefresher")!

  return (_openBlock(), _createBlock(_component_NRefresher, {
    load: ()=> _ctx.load(0)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DxSelectBox, {
        value: _ctx.model,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
        items: _ctx.shipmentTemplates,
        "onUpdate:items": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.shipmentTemplates) = $event))
      }, null, 8, ["value", "items"])
    ]),
    _: 1
  }, 8, ["load"]))
}