
export enum PlatformTip{
secilmedi=0,
Trendyol=1,
N11=2,
Hepsiburada=3,
Vivense=5,
EpttAvm=6
}

export const PlatformTipList = [
{key:'secilmedi', value:0, description:'Seçilmedi'},
{key:'Trendyol', value:1, description:'Trendyol'},
{key:'N11', value:2, description:'N11'},
{key:'Hepsiburada', value:3, description:'HebsiBurada'},
{key:'Vivense', value:5, description:'Vivense'},
{key:'EpttAvm', value:6, description:'EpttAvm'}
]
