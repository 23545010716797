import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_OzellikFormItem = _resolveComponent("OzellikFormItem")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_OzellikFormDialog = _resolveComponent("OzellikFormDialog")!

  return (_openBlock(), _createElementBlock("div", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), [
    _createVNode(_component_NFormGrid, {
      title: _ctx.title,
      cols: "1fr 1fr",
      style: {"grid-column":"span 3","gap":"0","min-height":"50px"}
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_NButton, {
          type: "info",
          size: "small",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openOzellikForm('')))
        }, {
          default: _withCtx(() => [
            _createTextVNode("+ Özellik Ekle")
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ozelliklerRef, (ozellik) => {
          return (_openBlock(), _createElementBlock("div", {
            key: ozellik.id
          }, [
            _createVNode(_component_OzellikFormItem, {
              "is-stok": _ctx.isStok,
              onOpenOzellikForm: _ctx.openOzellikForm,
              ozellik: ozellik,
              "kategori-id": _ctx.kategoriIdRef,
              "onUpdate:kategoriId": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.kategoriIdRef) = $event))
            }, null, 8, ["is-stok", "onOpenOzellikForm", "ozellik", "kategori-id"])
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["title"]),
    (_ctx.showOzellikForm)
      ? (_openBlock(), _createBlock(_component_OzellikFormDialog, {
          key: 0,
          show: _ctx.showOzellikForm,
          "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showOzellikForm) = $event)),
          ozellikler: _ctx.ozelliklerRef,
          "onUpdate:ozellikler": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.ozelliklerRef) = $event)),
          "ozellik-id": _ctx.selectedOzellikId,
          "onUpdate:ozellikId": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedOzellikId) = $event)),
          "is-stok": _ctx.isStok
        }, null, 8, ["show", "ozellikler", "ozellik-id", "is-stok"]))
      : _createCommentVNode("", true)
  ], 16))
}