import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NInputHtmlDialog = _resolveComponent("NInputHtmlDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NGrid, {
      cols: "1fr auto",
      gap: "8px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NInput, {
          modelValue: _ctx.model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
          maxlength: -1,
          placeholder: _ctx.placeholder
        }, null, 8, ["modelValue", "placeholder"]),
        _createVNode(_component_NButton, {
          icon: "code",
          size: "small",
          "icon-size": 16,
          style: {"padding":"4px 8px 0px 8px"},
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialog=true))
        })
      ]),
      _: 1
    }),
    (_ctx.showDialog)
      ? (_openBlock(), _createBlock(_component_NInputHtmlDialog, {
          key: 0,
          modelValue: _ctx.model,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model) = $event)),
          "show-dialog": _ctx.showDialog,
          "onUpdate:showDialog": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showDialog) = $event)),
          fields: _ctx.fields
        }, null, 8, ["modelValue", "show-dialog", "fields"]))
      : _createCommentVNode("", true)
  ], 64))
}