import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIconIonic = _resolveComponent("NIconIonic")!
  const _component_NTag = _resolveComponent("NTag")!
  const _component_StokHareketList = _resolveComponent("StokHareketList")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    modelValue: _ctx.showDialogRef,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showDialogRef) = $event)),
    "closable-mask": "true",
    width: "100%",
    "show-close": true
  }, {
    header: _withCtx(() => [
      _createTextVNode(" Stok Hareketleri "),
      _createVNode(_component_NTag, {
        type: "info",
        filled: true,
        style: {"margin-left":"5px","cursor":"pointer"},
        onClick: _ctx.openStokDialog
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.stokAdi), 1),
          _createVNode(_component_NIconIonic, {
            style: {"margin-left":"8px"},
            renk: "white",
            title: "Stok Formunu Aç",
            tip: "open-outline"
          })
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    body: _withCtx(() => [
      _createVNode(_component_StokHareketList, {
        "cari-id": _ctx.cariIdRef,
        "onUpdate:cariId": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cariIdRef) = $event)),
        "item-id": _ctx.itemIdRef,
        "onUpdate:itemId": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.itemIdRef) = $event)),
        "stok-id": _ctx.stokIdRef,
        "onUpdate:stokId": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.stokIdRef) = $event))
      }, null, 8, ["cari-id", "item-id", "stok-id"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}