
import { defineComponent, reactive, ref, toRefs, watch} from "vue";
import NDropdownItem from "@/components/Dropdown/NDropdownItem.vue";
import NDropDownLabelItem from "@/components/Dropdown/NDropDownLabelItem.vue";
import {ImporterTypeList} from "@/api/models/ImporterType";
import {ImporterEntity} from "@/api/models/ImporterEntity";
import {useImporterService} from "@/api/services/ImporterService";
import NImporterDialog from "@/modules/Import/dialogs/NImporterDialog.vue";
import NImporterFormDialog from "@/modules/Import/dialogs/NImporterFormDialog.vue";

export default defineComponent({
  components: {NImporterDialog,NImporterFormDialog, NDropDownLabelItem, NDropdownItem},
  props: {
    importType: {
      type: Number
    },
    callback:{
      type:Function
    }
  },
  setup(props, {emit}) {

    const localState = reactive({
      importTypeList: ref(ImporterTypeList),
      importList: ref([] as ImporterEntity[]),
      showDialog: ref(false),
      selectedReportName: ref(),
      showYeniDialog:ref(false),
      selectedValue:ref()
    });

    watch(()=> localState.showYeniDialog,(val)=>{

      if (val == false){
        load()
      }
    })

    watch(()=> localState.showDialog,(val)=>{

      if (val == false){
        load()
      }
    })

    async function load() {
      //    localState.dataFlatRef = props.data;
      localState.importList = await useImporterService().getİmportGetAllByType(props.importType as number)
      // console.log("report Data" , localState.dataFlatRef);
    }
     load()

    function getImportName() {
      if (props.importType as number >=0) {
        const report = localState.importTypeList.find(a => a.value as number === props.importType as number);
        if (report)
          return report.description;
      }
      return "-";
    }

    async function openImportViewer(item: ImporterEntity) {
      emit('importClick', item.name);
      localState.selectedReportName = item.name;
      localState.selectedValue = item.id;
      localState.showDialog = true;
      //console.log("report Data open" , localState.dataFlatRef);
    }
    
    
    return {
      ...toRefs(localState),
      getImportName,
      openImportViewer
    };
  }
});

