
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import { TrendyolOzellikItem } from "@/api/models/TrendyolOzellikItem";
import _ from "lodash";
import {CicekSepetiCategoryAttribute} from "@/api/models/CicekSepetiCategoryAttribute";
import {Ozellik} from "@/api/models/Ozellik";
import {useCicekSepetiKategoriService} from "@/api/services/CicekSepetiKategoriService";
import NDropDownListBox from "@/components/Dropdown/NDropDownListBox.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import {CicekSepetiAttribute} from "@/api/models/CicekSepetiAttribute";
import CicekSepetiEslestirmeDialog from "@/modules/Pazaryerleri/CicekSepeti/dialogs/CicekSepetiEslestirmeDialog.vue";
import {IdName} from "@/api/models/IdName";
import {StokEntity} from "@/api/models/StokEntity";


export default defineComponent({
  components: {CicekSepetiEslestirmeDialog, NGrid, NLoadingPanel, NDropDownListBox, NFormField, NFormGrid },
  props: {
    ozellikler:{
      type:Array
    },
    kategoriId:{
      type:String
    },
    cols:{
      type:String,
      default:'1fr 1fr 1fr'
    },
    stok:{
      type:Object
    }
  },
  setup(props,{emit}) {

    const localState = reactive({
      loadingPanel:ref(),
      cicekSepetiOzellikler:ref({} as any[]),
      showEslestir:ref(false),
      selectedCicekSepetiValues:ref(),
      selectedNesilceValues:ref(),
      selectedPropertyName:ref(),
      stokRef:computed(()=> props.stok as StokEntity),
      nesilceOzellikler:computed({
        get:()=> props.ozellikler as CicekSepetiAttribute[],
        set:(val)=> emit('update:ozellikler',val)
      }),
      text:ref('REF')
    });

    watch(()=> props.kategoriId,(kategoriId)=>{
      localState.loadingPanel.load();
      //load()
    })


    load()

    async function load(){

      const kategoriId = props.kategoriId as string;
      if (kategoriId){
        // Load Özellikler
        localState.cicekSepetiOzellikler = await useCicekSepetiKategoriService().getGetVariantSpec(1,kategoriId);
        localState.cicekSepetiOzellikler = _.orderBy(localState.cicekSepetiOzellikler,['required','name'],['desc','asc'])
        // Nesilce to Trendyol
        nesilceToCicekSepeti();

      }
    }

    function nesilceToCicekSepeti(){
      //console.log("localState.nesilceOzellikler",localState.nesilceOzellikler)

      if (localState.nesilceOzellikler)
      {
        localState.nesilceOzellikler.forEach(ozellik=>{
            const cicekSepetiOzellik = localState.cicekSepetiOzellikler.find((a:CicekSepetiCategoryAttribute) => a.attributeId == ozellik.id) as any;
            if (cicekSepetiOzellik){
              cicekSepetiOzellik.valueId = ozellik.valueId;
              cicekSepetiOzellik.textLength = ozellik.textLength;
            }
        })
      }
    }


    function cicekSepetiToNesilce(){
      const nesilceOzellikler = [] as any[];

      if (localState.cicekSepetiOzellikler){
        localState.cicekSepetiOzellikler.forEach((attribute:any)=>{
          if (attribute.valueId)
          nesilceOzellikler.push({
            id:attribute.attributeId,
            valueId:attribute.valueId,
            textLength:attribute.textLength?attribute.textLength:0
          })
        })
      }

      localState.nesilceOzellikler = nesilceOzellikler;
    }

    function eslestir(propertyName:string, cicekSepetiPropertyValues:IdName[]){
      localState.selectedPropertyName = propertyName;

      localState.selectedNesilceValues = getPropertyValues(propertyName);

      localState.selectedCicekSepetiValues = cicekSepetiPropertyValues;
      localState.showEslestir = true;
    }
    function getPropertyValues(propertyName:string){
      // find variant propery index

      const variantIndex = localState.stokRef.varyantlar?.findIndex(a => a.name  == propertyName || a.nameCicekSepeti == propertyName) as number;
      if (variantIndex >= 0){
        const variantValues = localState.stokRef.items?.map(a => (a.varyantDegerleri?a.varyantDegerleri[variantIndex]:''))
        const variantValuesDistinc = _.uniq(variantValues);
        return  variantValuesDistinc;
      }


      return [];
    }

    function getPropertyValueId(cicekSepetiAttributeValues:IdName[],propertyValue:string){
      const valueIdName = cicekSepetiAttributeValues.find(a => a.name == propertyValue);
      if (valueIdName)
        return valueIdName.id;
      return  -1;
    }

    return {
      ...toRefs(localState),
      load,
      cicekSepetiToNesilce,
      eslestir,
      getPropertyValues,
      getPropertyValueId
    };
  }
});

