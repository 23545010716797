import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "custom-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_DxList = _resolveComponent("DxList")!
  const _component_DxDropDownBox = _resolveComponent("DxDropDownBox")!

  return (_openBlock(), _createBlock(_component_DxDropDownBox, {
    opened: _ctx.opened,
    "onUpdate:opened": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.opened) = $event)),
    fieldTemplate: "field"
  }, {
    field: _withCtx(({  }) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DxTextBox, {
          value: _ctx.getValue(),
          "read-only": true
        }, null, 8, ["value"])
      ])
    ]),
    content: _withCtx(() => [
      _createVNode(_component_DxList, {
        height: 300,
        dataSource: _ctx.dataSource,
        "search-enabled": true,
        onItemClick: _ctx.itemClick,
        displayExpr: _ctx.displayExpr
      }, null, 8, ["dataSource", "onItemClick", "displayExpr"])
    ]),
    _: 1
  }, 8, ["opened"]))
}