
import {defineComponent, onActivated, reactive, ref, toRefs, watch} from "vue";
import NCard from "@/components/Card/NCard.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import DepoSelectBox from "@/modules/Ortak/components/DepoSelectBox.vue";
import {usePopupService} from "@/services/popupService";
import PerakendeUstBilgiler from "@/modules/Perakende/components/PerakendeUstBilgiler.vue";
import {PerakendeView} from "@/api/models/PerakendeView";
import PerakendeOdemeVeSmsKargoMail from "@/modules/Perakende/components/PerakendeOdemeVeSmsKargoMail.vue";
import NButton from "@/components/Button/NButton.vue";
import {StokFlatView} from "@/api/models/StokFlatView";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import NTagList from "@/components/Tag/NTagList.vue";
import {PerakendeUtil} from "@/modules/Perakende/utils/PerakendeUtil";
import PlasiyerSelectBox from "@/modules/Plasiyer/components/PlasiyerSelectBox.vue";
import {DepoFlatView} from "@/api/models/DepoFlatView";
import {KurView} from "@/api/models/KurView";
import {useEvrakNoService} from "@/api/services/EvrakNoService";
import CariSelectBox from "@/modules/Cari/components/CariSelectBox.vue";
import {NModuleRoute} from "@/models";
import {useLayoutStore} from "@/layouts/ApplicationLayout/context";
import {usePerakendeService} from "@/api/services/PerakendeService";
import {useKurCachedServis} from "@/modules/Ortak/services/useKurCachedServis";
import {PlasiyerEntity} from "@/api/models/PlasiyerEntity";
import {useCachedSubeDepoService} from "@/modules/Sube/services/useCachedSubeDepoService";
import {useCachedPlasiyerList} from "@/modules/Plasiyer/services/useCachedPlasiyerList";
import {PerakendeIadeStoklar} from "@/api/models/PerakendeIadeStoklar";
import {PerakendeIadeItem} from "@/api/models/PerakendeIadeItem";
import {uuid} from "vue-uuid";
import PerakendeConfigDialogs from "@/modules/Perakende/dialogs/PerakendeConfigDialogs.vue";
import StokAramaInput from "@/modules/Fis/pages/StokAramaInput.vue";
import {DxDataGrid, DxExport} from "devextreme-vue/data-grid";
import {confirm} from "devextreme/ui/dialog";
import StokFlatListSagMenu from "@/modules/Stok/components/StokFlatListSagMenu.vue";
import {FirmaEntity} from "@/api/models/FirmaEntity";
import {usedCacheFirmaService} from "@/modules/Ayarlar/Firmalar/services/usedCacheFirmaService";
import ReportViewerDialog from "@/modules/Report/dialogs/ReportViewerDialog.vue";
import {PerakendeReportView} from "@/api/models/PerakendeReportView";
import {useSharedStore} from "@/store";
import {StokUtils} from "@/modules/Stok/utils/StokUtils";
import {CariEntity} from "@/api/models/CariEntity";
import {useCariService} from "@/api/services/CariService";
import {useStokFlatStore} from "@/modules/Stok/store/StokFlatStore";
import {StokBirimItem} from "@/api/models/StokBirimItem";
import {PerakendeSatirItem} from "@/api/models/PerakendeSatirItem";
import {StokBirimUtils} from "@/modules/Stok/utils/StokBirimUtils";
import {useCariStore} from "@/modules/Cari/store/CariStore";
import dxDataGrid from "devextreme/ui/data_grid";
import FiyatGruplariSelectBox from "@/modules/Ortak/components/FiyatGruplariSelectBox.vue";
import NDxStateStoring from "@/devextreme/NDxStateStoring.vue";
import {FaturaHareketTip} from "@/api/models/FaturaHareketTip";
import {DevExtremeUtils} from "@/devextreme/DevExtremeUtils";
import {EFaturaSenaryoTip} from "@/api/models/EFaturaSenaryoTip";
import {HizmetMasrafEntity} from "@/api/models/HizmetMasrafEntity";
import {FaturaUtil} from "@/modules/Fatura/utils/FaturaUtil";
import {FiyatGrupView} from "@/api/models/FiyatGrupView";

export default defineComponent({
  components: {

    DxDataGrid,
    NDxStateStoring,
    FiyatGruplariSelectBox,
    ReportViewerDialog,
    StokFlatListSagMenu,
    DxExport,
    StokAramaInput,
    PerakendeOdemeVeSmsKargoMail,
    PerakendeConfigDialogs,
    CariSelectBox,
    NButton,
    PlasiyerSelectBox,
    NTagList,
    NLoadingPanel,
    PerakendeUstBilgiler,
    DepoSelectBox, NFormField, NGrid, NCard
  },
  props: {},
  setup() {
    const localState = reactive({
      model: ref({} as PerakendeView),
      stoklar: ref([] as StokFlatView[]),
      stokAramaInput: ref(),
      showStoklar: ref(false),
      perakendeSatirlarDataGrid: ref(),
      depoIdRef: ref(""),
      plasiyerIdRef: ref(""),
      utils: PerakendeUtil,
      selectedTab: ref(0 as number),
      depoList: ref([] as DepoFlatView[]),
      kurlar: ref([] as KurView[]),
      plasiyerList: ref([] as PlasiyerEntity[]),
      iadeStoklar: ref([] as PerakendeIadeStoklar[]),
      iadeStoklarDataGrid: ref(),
      iadelerDataGrid: ref(),
      ayarlarShow: ref(false),
      stokFlatListSagMenu: ref(),
      firmaEntity: ref({} as FirmaEntity),
      showReportDialog: ref(false),
      reportData: ref({} as PerakendeReportView),
      stokUtils: StokUtils,
      cari: ref({} as CariEntity),
      reportName: ref("" as string),
      cariFiyatGrupId: ref("" as string),
      changingFieldName: ref(""),
      fiyatGrupView : ref({} as FiyatGrupView),
      perakendeOdemeVeSmsKargoMailComponent: ref(),

    });
    const route = {
      id: "PerakendeModule",
      order: -1,
      displayName: "Perakende",
      icon: {
        type: "shopping-cart"
      }
    } as NModuleRoute;


    async function loadPerakendeSatirState() {
      return await DevExtremeUtils.loadState(localState.perakendeSatirlarDataGrid?.instance);
    }

    async function load() {
      localState.depoList = await useCachedSubeDepoService().getUserDepoList();
      localState.firmaEntity = await usedCacheFirmaService().getFirma();
      if (localState.firmaEntity)
        localState.reportName = "Perakende";


      localState.model = localState.utils.newPerakende();
      const evrakNo = await useEvrakNoService().getYeniEvrakNo(10);
      if (evrakNo && evrakNo.length > 0)
        localState.model.evrakNo = evrakNo;

      const varsayilanCariId = await PerakendeUtil.getPerakendeCariId();
      if (varsayilanCariId && varsayilanCariId.length > 0)
        localState.model.cariId = varsayilanCariId;

      localState.depoIdRef = localState.depoList[0]?.depoId as string;

      localState.kurlar = await useKurCachedServis().getKurList();
      localState.plasiyerList = await useCachedPlasiyerList().getUserPlasiyerList();


      await loadCari();
    }

    watch(() => localState.fiyatGrupView.oran, () => {
      if (localState.model && localState.model.satirlar && localState.model.satirlar.length) {
        localState.model.satirlar.forEach((satir) => {
          const newSatir = fiyatGrubuAyari(satir as PerakendeSatirItem);

          if (satir) satir = newSatir;
        });
      }
    });

    watch(() => localState.fiyatGrupView.tutar, () => {
      if (localState.model && localState.model.satirlar && localState.model.satirlar.length) {
        localState.model.satirlar.forEach((satir) => {
          const newSatir = fiyatGrubuAyari(satir as PerakendeSatirItem);
          if (satir) satir = newSatir;
        });
      }
    });


    function fiyatGrubuAyari(satir: PerakendeSatirItem) {
      if (localState.fiyatGrupView.oran != 0 || localState.fiyatGrupView.tutar != 0) {
        satir.fiyat = StokUtils.getStokFiyat(satir.stokId as string, FaturaHareketTip.SatisFatura);
        satir.fiyat = satir.fiyat * (1 + (satir.kdv! / 100));

        if (localState.fiyatGrupView!.oran! > 0) {
          satir.fiyat = satir.fiyat! * (1 + (localState.fiyatGrupView!.oran! / 100));
          satir.iskontolar = [];
        } else if (localState.fiyatGrupView.oran != 0) {
          satir.iskontolar = [];
          satir.iskontolar.push(localState.fiyatGrupView!.oran! * -1);
        }

        if (localState.fiyatGrupView.oran == 0 && localState.fiyatGrupView.tutar != 0) {
          satir.fiyat = satir.fiyat + localState.fiyatGrupView!.tutar!;
        }
        satir.fiyat = StokUtils.getDovizFiyat(satir.fiyat, satir.stokId as string);

      } else {
        satir.fiyat = StokUtils.getStokFiyat(satir.stokId as string, FaturaHareketTip.SatisFatura);

        satir.fiyat = StokUtils.getPerakendeSubeFiyat(satir.fiyat, satir.stokId as string, satir.depoId as string);


        satir.fiyat = StokUtils.getDovizFiyat(satir.fiyat, satir.stokId as string);

        satir.fiyat = satir.fiyat * (1 + (satir.kdv! / 100));
        satir.iskontolar = [];
      }

      return satir;
    }

    function changedBirim(e: any, row: any) {

      //console.log(e);
      // Kampanyalı satırlar düzenlenemez
      if ((row?.kampanyaId?.length as number) > 0) {
        return;
      }
      const stok = useStokFlatStore().getStok(row.stokId);

      const birimler = stok?.birimFiyatlari as StokBirimItem[];
      if (birimler && stok) {


        const birimAdi = e.value;

        const birim = birimler.find(a => a.birim == birimAdi);

        if (birim) {


          row.birim = birimAdi;
          row.carpan = birim.carpan;
          row.fiyat = StokBirimUtils.getFiyat(
              birim.fiyat!,
              birim.fiyatTipi!,
              stok.satisFiyat!,
              stok.satisKdv!,
              stok.satisKdvDahil!,
              stok.alisFiyat!,
              stok.alisKdv!,
              stok.alisKdvDahil!,
              0
          );
        } else {
          row.birim = birimAdi;
          row.carpan = 1;
          row.fiyat = stok.satisKdvDahil ? stok.satisFiyat : stok.satisFiyat! * (1 + (stok.satisKdv! / 100));
        }

        // Kampanya Sil
        kampanyalariTemizle();
      }

    }


    watch(() => localState.model.cariId, () => {
      loadCari();
      kampanyalariTemizle();
      localState.perakendeOdemeVeSmsKargoMailComponent?.eFaturaDefaultSettings();
    });


    async function loadCari() {
      if (localState.model && localState.model.cariId && localState.model.cariId.length > 0) {
        localState.cari = await useCariService().getGetir(localState.model.cariId);
      }
    }


    function tekUrunEkle(urun: StokFlatView) {
      stokDblClick(urun);
    }

    function cokluUrunEkle(urunler: StokFlatView[]) {
      if (urunler && urunler.length > 1) {
        urunler.forEach((urun) => {
          stokDblClick(urun);
        });
      }
    }

    function successNotif(title: string, body: string, duration = 500) {
      usePopupService().pushSuccessNotification({
        body: body,
        duration: duration,
        title: title
      });
    }


    function dataGridRefresh() {
      localState.perakendeSatirlarDataGrid?.instance?.refresh();
      localState.iadelerDataGrid?.instance?.refresh();
      localState.stokAramaInput?.focuslama();
      scrollMoveToEnd();
    }

    function dataGridRefreshOnly() {
      localState.perakendeSatirlarDataGrid?.instance?.refresh();
    }
    function hizmetDblClick(hizmet: HizmetMasrafEntity) {
      if (localState.model.satirlar) {
        const satir = PerakendeUtil.toPerakendeHizmetSatiItem(hizmet , localState.depoIdRef , localState.plasiyerIdRef , localState.kurlar);

        localState.model.satirlar.push(satir);
        kampanyalariTemizle();
        dataGridRefresh();
      }
    }
    function stokDblClick(urun: StokFlatView) {
      //console.log(urun);

      if (localState.selectedTab === 0) {
        let stokFlatView = urun;

        if (localState.cariFiyatGrupId && localState.cariFiyatGrupId.length > 0) {
          ///
        } else
          stokFlatView = StokUtils.getSubeFiyat(localState.model.subeId as string, urun);

        const perakendeSatirItem = localState.utils.toPerakendeStokSatirItem(stokFlatView, localState.depoIdRef, localState.plasiyerIdRef, localState.kurlar);


        localState.model.satirlar!.push(perakendeSatirItem);
        localState.showStoklar = false;
      }
      if (localState.selectedTab === 1) {
        const stok = localState.utils.toPerakendeSatirIade(urun, localState.depoIdRef, localState.plasiyerIdRef, localState.kurlar);
        localState.model.iadeler!.push(stok);
        localState.showStoklar = false;
      }
      kampanyalariTemizle();
      dataGridRefresh();

    }

    async function veresiyeOdemeClick() {
      if (await confirm(`${localState.model.unvan} , carisi için veresiye işlemini onaylıyor musunuz ? `, "Veresiye Ödeme Onayı")) {
        if (localState.model.veresiye == true) {
          await usePerakendeService().postEkle(localState.model);
          successNotif("Başarılı", "Kaydedildi", 3000);
          await PrintPerakende();
          useLayoutStore().closeCurrentTab();
          useLayoutStore().openRouteAsTab(route);
        }
      } else
        localState.model.veresiye = false;
    }

    async function kasaOdemeClick() {
      if (localState.model.cokluTahsilat == false) {
        //  console.log("perakende Model " , localState.model) ;
        await usePerakendeService().postEkle(localState.model);
        successNotif("Başarılı", "Kaydedildi", 3000);
        await PrintPerakende();
        useLayoutStore().closeCurrentTab();
        useLayoutStore().openRouteAsTab(route);
      }
    }

    async function posOdemeClick() {
      if (localState.model.cokluTahsilat == false) {
        await usePerakendeService().postEkle(localState.model);
        successNotif("Başarılı", "Kaydedildi", 3000);
        await PrintPerakende();
        useLayoutStore().closeCurrentTab();
        useLayoutStore().openRouteAsTab(route);
      }
    }

    async function mailOrderOdemeClick() {
      if (localState.model.cokluTahsilat == false) {

        await usePerakendeService().postEkle(localState.model);
        successNotif("Başarılı", "Kaydedildi", 3000);
        await PrintPerakende();
        useLayoutStore().closeCurrentTab();
        useLayoutStore().openRouteAsTab(route);
      }
    }

    async function havaleOdemeClick() {
      if (localState.model.cokluTahsilat == false) {
        await usePerakendeService().postEkle(localState.model);
        successNotif("Başarılı", "Kaydedildi", 3000);
        await PrintPerakende();
        useLayoutStore().closeCurrentTab();
        useLayoutStore().openRouteAsTab(route);
      }
    }

    function yeni() {
      useLayoutStore().openRouteAsTab(route);
    }

    async function kaydet() {
      await usePerakendeService().postEkle(localState.model);
      successNotif("Başarılı", "Kaydedildi", 3000);
      await PrintPerakende();
      useLayoutStore().closeCurrentTab();
      useLayoutStore().openRouteAsTab(route);

    }

    watch(() => localState.selectedTab, val => {

      if (val && val == 1)
        iadeStoklarYukle();
    });

    async function iadeStoklarYukle() {
      localState.iadeStoklar = await usePerakendeService().getCariSatisFaturalari(localState.model!.cariId!);
      localState.iadeStoklarDataGrid?.instance?.refresh();
    }

    function iadeDblClcik(data: PerakendeIadeStoklar) {
      if (data) {
        const iade = {
          ad: data.urunAdi,
          barkod: data.barkod,
          birim: data.birim,
          carpan: data.carpan,
          depoId: localState.depoIdRef,
          fiyat: data.fiyat,
          kdv: data.kdv,
          iskontolar: [],
          itemId: data.itemId,
          miktar: data.miktar,
          plasiyerId: "",
          stokId: data.stokId,
          kod: uuid.v4()
        } as PerakendeIadeItem;

        localState.model!.iadeler!.push(iade);
        localState.iadelerDataGrid?.instance?.refresh();
      }
    }

    async function selectCariFiyatGrup() {
      const cariler = await useCariStore().getCariOzetleri();
      const result = cariler.find(a => a.id == localState.model.cariId);
      if (result)
        localState.cariFiyatGrupId = result.fiyatGrupId as string;
    }

    async function PrintPerakende() {
      const autoPrint = await PerakendeUtil.getPerakendeAutoPrint();
      if (autoPrint == true) {
        setTimeout(() => {

          const depoSubeAd = localState.depoList.find(a => a.depoId == localState.depoIdRef)?.depoSubeAd as string;
          const kullaniciAd = useSharedStore()?.user.name;
          localState.reportData = {
            cari: {},
            perakende: localState.model,
            firma: localState.firmaEntity,
            kullaniciAd: kullaniciAd,
            subeDepoAd: depoSubeAd
          };


          localState.showReportDialog = true;

        }, 500);
      }


    }

    function onSaved(e: any) {
      // console.log('onSaved', e)

      if (localState.changingFieldName == "miktar" || localState.changingFieldName == "fiyat" || localState.changingFieldName == "kdv") {
        kampanyalariTemizle();
      } else localState.changingFieldName = "";

      localState.perakendeSatirlarDataGrid?.instance?.refresh();
    }

    function onEditingStart(e: any) {
      // console.log('onEditingStart', e)
      localState.changingFieldName = e.column.dataField;

      if (localState.changingFieldName == "miktar" || localState.changingFieldName == "fiyat" || localState.changingFieldName == "kdv") {
        if (e.data?.kampanyaId?.length > 0)
          e.cancel = true;
      }


    }

    function kampanyalariTemizle() {
      // console.log('kampanyalar silindi')

      const faturaSatirlar = localState.model.satirlar?.filter(a => a.kampanyaId == undefined || a.kampanyaId == "" || a.kampanyaId?.length == 0) as any[];
      localState.model.satirlar = faturaSatirlar;
      localState.perakendeSatirlarDataGrid?.instance?.refresh();
    }

    function onRowPrepared(e: any) {
      if ((e.data?.kampanyaId?.length as number) > 0) {
        //e.rowElement.bgColor = 'green';
        e.rowElement.className = e.rowElement.className.indexOf("bgGreen") < 0 ?
            e.rowElement.className.replace("dx-row-alt", "") + " bgGreen" :
            e.rowElement.className;
        // console.log('row prepared', e.rowElement)

      }

    }

    function scrollMoveToEnd() {
      if (localState.model && localState.model.satirlar && localState.model.satirlar?.length > 0) {
        setTimeout(() => {
          const dataGrid = (localState.perakendeSatirlarDataGrid?.instance as dxDataGrid);
          //const element = dataGrid.getRowElement((localState.model.satirlar?.length as number) - 1) ;
          dataGrid.getScrollable()?.scrollTo({
            top: 100000,
            left: 0,
            behavior: "smooth"
          });

        }, 500);
      }
    }

    function onRowRemoved(e: any) {
      kampanyalariTemizle();
    }

    onActivated(() => {
      scrollMoveToEnd();
      localState.stokAramaInput?.focuslama();
      DevExtremeUtils.updateScroll(localState.perakendeSatirlarDataGrid);
    });


    return {
      ...toRefs(localState),
      load,
      tekUrunEkle,
      cokluUrunEkle,
      stokDblClick,
      posOdemeClick,
      kasaOdemeClick,
      havaleOdemeClick,
      yeni,
      kaydet,
      iadeDblClcik,
      veresiyeOdemeClick,
      mailOrderOdemeClick,
      //getBirimlerDataSource,
      changedBirim,
      selectCariFiyatGrup,
      onSaved,
      onEditingStart,
      dataGridRefresh,
      dataGridRefreshOnly,
      onRowPrepared,
      onRowRemoved,
      kampanyalariTemizle, loadPerakendeSatirState,
      hizmetDblClick

    };
  }
});
