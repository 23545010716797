import {NModuleRoute} from "@/models";

export const PerakendeModuleRoutes: NModuleRoute[] = [
    {
        id: "PerakendeModule",
        order: 4,
        displayName: "Perakende",
        icon: {
            type: "shopping-cart"
        },
        requiredRoles:{
            readRoles:['Cari.Perakende.Liste','Kasa.Liste','Pos.Liste','Stok.Perakende.Liste' , 'Perakende.Stok.Grup.Liste' ],
            insertRoles:['Perakende.Ekle' , 'Perakende.Cari.Ekle'],
            updateRoles:['Perakende.Guncelle'],
            deleteRoles:['Perakende.Sil']
        },
        component: () =>
            import(/* webpackChunkName: "Perakende"*/ "../pages/PerakendePage.vue")
    }

]
