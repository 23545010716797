import useApi from '@/api/useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {ReportType} from '../models/ReportType'
import { ReportView } from "@/api/models/ReportView";
import { useRaporEkraniService } from "@/api/services/RaporEkraniService";

export const useRaporEkraniServiceExtend = defineStore({
  id: 'RaporEkraniServiceExtend',
  state:()=>({
    list:[] as ReportView[]
  }),
  actions: {

    async getList(tipi:ReportType)
    {

      if (this.list.length == 0){
        this.list = await useRaporEkraniService().getGetAll() as ReportView[];
      }
      return this.list.filter(a => a.tip === tipi) as ReportView[];
    }
  }
});
