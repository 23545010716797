
export enum KargoOdemeTip{
Gonderici=1,
Alici=2,
Platform=3
}

export const KargoOdemeTipList = [
{key:'Gonderici', value:1, description:'Gönderici Ödemeli'},
{key:'Alici', value:2, description:'Alıcı Ödemeli'},
{key:'Platform', value:3, description:'Platform Ödemeli'}
]
