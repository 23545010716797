export type NColumnFormatter = (
  col: NColumn,
  row: any,
  colIndex: number,
  rowIndex: number
) => string;

export type NColumnSorter = (firstEl: unknown, secondEl: unknown) => number;

export enum NColumnAlignment {
  Left = "left",
  Center = "center",
  Right = "right"
}

export type NColumn = {
  label: string;
  prop: string;
  width?: string;
  sortable?: boolean;
  resizable?: boolean;
  hidden?: boolean;
  alignment?: NColumnAlignment;
  formatter?: NColumnFormatter;
  sorter?: NColumnSorter;
  class?: string;
};

export enum NSortOrder {
  Asc = "asc",
  Desc = "desc",
  None = "none"
}
