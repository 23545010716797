
export enum CekSenetDurum{
Portfoy=0,
Iade=1,
Ciro=2,
CirodanTahsil=3,
BankaTakas=4,
BankaTeminat=5,
CariTeminat=6,
TeminattanTahsil=7,
KasaTahsil=8,
KasaKismenTahsil=9,
BankaTahsil=10,
Karsiliksiz=12,
Avukat=13,
AvukatTahsil=14
}

export const CekSenetDurumList = [
{key:'Portfoy', value:0, description:'Portföy'},
{key:'Iade', value:1, description:'Iade'},
{key:'Ciro', value:2, description:'Ciro'},
{key:'CirodanTahsil', value:3, description:'Cirodan Tahsil Edildi'},
{key:'BankaTakas', value:4, description:'Bankada Takasta'},
{key:'BankaTeminat', value:5, description:'Bankada Teminatta'},
{key:'CariTeminat', value:6, description:'Caride Teminatta'},
{key:'TeminattanTahsil', value:7, description:'Teminattan Tahsil'},
{key:'KasaTahsil', value:8, description:'Kasadan Tahsil'},
{key:'KasaKismenTahsil', value:9, description:'Kasadan Kısmen Tahsil'},
{key:'BankaTahsil', value:10, description:'Bankadan Tahsil'},
{key:'Karsiliksiz', value:12, description:'Karşılıksız'},
{key:'Avukat', value:13, description:'Avukata Verildi'},
{key:'AvukatTahsil', value:14, description:'Avukattan Tahsil'}
]
