
import { defineComponent } from "vue";
import { Colors } from "@/models";
export default defineComponent({
  name: "NCheckbox",
  inheritAttrs: false,
  props: {
    type: {
      default: Colors.PRIMARY,
      type: String,
      validator: (value: Colors) =>
        [
          Colors.PRIMARY,
          Colors.SUCCESS,
          Colors.DANGER,
          Colors.WARNING,
          Colors.BLACK,
          Colors.NEUTRAL,
        ].includes(value)
    },
    label: { type: String },
    subLabel: { type: String },
    pulse: { type: Boolean },
    disabled: { type: Boolean },
    lineThrough: { type: Boolean },
    icon: { type: String, default: "check" },
    color: { type: String },
    modelValue: {}
  },
  setup(props, { emit }) {
    function toggle() {
      if (props.disabled) {
        return;
      }
      const newValue = !props.modelValue;
      emit("update:modelValue", newValue);
      emit("toggle", newValue);
    }
    return { toggle };
  }
});
