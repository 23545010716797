import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {Doviz} from '../models/Doviz'
import {DocumentItem} from '../models/DocumentItem'
import {KrediPlanVadelerItem} from '../models/KrediPlanVadelerItem'
import {KrediView} from '../models/KrediView'

export const useKrediService = defineStore({
id: 'KrediService',
actions: {


	async postKrediEkle(krediView:KrediView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kredi/KrediEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:krediView });
		await request();
		return response.value;
	},

	async deleteKrediSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kredi/KrediSil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async putKrediGuncelle(krediView:KrediView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kredi/KrediGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:krediView });
		await request();
		return response.value;
	},

	async getView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kredi/View/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kredi/List/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
