import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {TrendyolValuesView} from '../models/TrendyolValuesView'

export const useTrendyolDegerlerService = defineStore({
id: 'TrendyolDegerlerService',
actions: {


	async getGetirTumu()
	{
		const { request, response } = useApi(`/api/TrendyolDegerler/GetirTumu`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetirOzellik(ozellik:string,kategoriId:number)
	{
		const { request, response } = useApi(`/api/TrendyolDegerler/GetirOzellik/${ozellik}/${kategoriId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetir(id:string)
	{
		const { request, response } = useApi(`/api/TrendyolDegerler/Getir/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const { request, response } = useApi(`/api/TrendyolDegerler/Sil/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postEkle(trendyolValuesView:TrendyolValuesView)
	{
		const { request, response } = useApi(`/api/TrendyolDegerler/Ekle`,{ method:'POST', data:trendyolValuesView });
		await request();
		return response.value;
	}
}
});
