
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from "vue";
import { StokDevirItem } from "@/api/models/StokDevirItem";
import NGrid from "@/components/Grid/NGrid.vue";
import {useNesilceConfig} from "@/api/NesilceConfig";
import LoginForm from "@/layouts/LoginLayout/components/LoginForm.vue";
import {useVersionService} from "@/api/services/VersionService";

export default defineComponent({
  components: {},
  props: {

  },
  setup(props) {

    const localState = reactive({
        logoUrl:ref(useNesilceConfig().config.LOGO_URL),
        version:ref(0)
    });


      onMounted(() => {
          useVersionService().getGet().then((ver) => {
              localState.version = ver;
          });

      });

    return {
      ...toRefs(localState)
    };
  }
});

