
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import { CariEntity } from "@/api/models/CariEntity";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";


export default defineComponent({
  components: { NFormGrid },
  props: {
    cari: {
      type: Object
    }
  },
  setup(props) {
    const localState = reactive({
      cariRef: computed(() => props.cari as CariEntity)
    });

    return {
      ...toRefs(localState)
    };

  }
});
