
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import CariSelectBox from "@/modules/Cari/components/CariSelectBox.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import { useFirmaService } from "@/api/services/FirmaService";
import NButton from "@/components/Button/NButton.vue";
import NSwitch from "@/components/Switch/NSwitch.vue";
import { FirmaEntity } from "@/api/models/FirmaEntity";
import ReportListSelectBox from "@/modules/Report/components/ReportListSelectBox.vue";
import { ReportType } from "@/api/models/ReportType";
import { PerakendeUtil } from "@/modules/Perakende/utils/PerakendeUtil";

export default defineComponent({
  components: { ReportListSelectBox, NSwitch, NButton, NLoadingPanel, CariSelectBox, NFormField, NModalDialog },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      perakendeCariId: ref(""),
      perakendeAutoPrint: ref(true as boolean),
      reportType: ReportType,
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val)
      })
    });

    async function load() {
        localState.perakendeAutoPrint =  await  PerakendeUtil.getPerakendeAutoPrint();
        localState.perakendeCariId = await  PerakendeUtil.getPerakendeCariId();

    }

    async function save() {
        await PerakendeUtil.savePerakendeAutoPrint(localState.perakendeAutoPrint);
        await PerakendeUtil.savePerakendeCariId(localState.perakendeCariId);

        emit("saved",{
            autoPrint:localState.perakendeAutoPrint,
            cariId:localState.perakendeCariId
        })

        localState.showRef = false;

    }

    return {
      ...toRefs(localState),
      load,
      save
    };
  }
});
