
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import { IdName } from "@/api/models/IdName";
import NDropDownListBox from "@/components/Dropdown/NDropDownListBox.vue";
import { useCicekSepetiDegerlerService } from "@/api/services/CicekSepetiDegerlerService";
import { CicekSepetiValuesEntity } from "@/api/models/CicekSepetiValuesEntity";
import { CicekSepetiValuesView } from "@/api/models/CicekSepetiValuesView";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";

export default defineComponent({
  components: { NLoadingPanel, NDropDownListBox  },
  props: {
    id: {
      type: String
    },
    show: {
      type: Boolean,
      default: false
    },
    propertyName: {  type:String },
    nesilceValues:{type:Array},
    cicekSepetiValues:{type:Array},
    kategoriId:{type:String},
  },
  setup(props, { emit }) {
    const localState = reactive({
      model: ref([] as any[]),
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val)
      }),
      subTitle: ref(props.propertyName),
      title: ref("ÇiçekSepeti Özellik Eşletirme Formu"),
      cicekSepetiValuesDb:ref([] as CicekSepetiValuesEntity[])
    });

    async function load() {
      const categoryId = parseInt(props.kategoriId as string) as number;
      localState.cicekSepetiValuesDb = await useCicekSepetiDegerlerService().getGetirOzellik(props.propertyName as string,categoryId)
      localState.model = [];

        props.nesilceValues?.forEach((value:any,i:number)=>{
          const findVal = findValueId(value);
          localState.model.push({id:i, categoryId:categoryId, property:props.propertyName, value:value, valueId:findVal})
        });

    }

    function findValueId(value:string){
      const cicekSepetiValue = props.cicekSepetiValues?.find((a:any)=> a.name== value) as any;
      if (cicekSepetiValue)
        return cicekSepetiValue.id as number

      const cicekSepetiValueDb = localState.cicekSepetiValuesDb.find(a => a.value == value);
      if (cicekSepetiValueDb)
        return cicekSepetiValueDb.valueId;

      return 0
    }

    async function save() {
      for (let i = 0; i < localState.model.length; i++) {
        const cicekSepetiValue:CicekSepetiValuesView = localState.model[i];
        await useCicekSepetiDegerlerService().postEkle(cicekSepetiValue);
      }

      localState.showRef = false;
    }

    load();

    return {
      ...toRefs(localState),
      load,
      save
    };
  }
});
