
import { computed, defineComponent } from "vue";
//:style="{'grid-template-columns': gridCols}"
export default defineComponent({
  name: "NFormFieldLabelTop",
  props: {
    gridCols: {
      type: String,
      default: "1fr"
    },
    label: {
      type: String,
      default: "Label"
    },
    span: {
      type: String,
      default: "1"
    },
    fill: {
      type: Boolean
    }
  },
  setup(props) {
    const style = () => {
      return { "grid-template-columns": props.gridCols };
    };

    return {
      style
    };
  }
});
