
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import { DepoFlatView } from "@/api/models/DepoFlatView";
import { useCachedSubeDepoService } from "@/modules/Sube/services/useCachedSubeDepoService";

export default defineComponent({
  components: {},
  props: {
    subeId: {
      type: String
    },
    modelValue: {
      type: String
    },
    selectedItem: {
      type: Object
    },
    firstSelect: {
      type: Boolean,
      default: false
    },
    showClearButton: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      model: computed({
        get: () => props.modelValue,
        set: val => emit("update:modelValue", val)
      }),
      subeIdRef: computed({
        get: () => props.subeId,
        set: val => emit("update:subeId", val)
      }),
      showClearButtonRef: computed({
        get: () => props.showClearButton,
        set: (val) => emit("update:showClearButton")
      }),
      depoIdRef: ref(""),
      depoList: ref([] as DepoFlatView[])
    });

    watch(() => localState.model, (val) => depoChange());

    function depoChange() {
      if (localState.model && localState.model.length > 0) {
        const subeId = localState.depoList.find(a => a.depoId == localState.model)!.subeId;
        localState.subeIdRef = subeId as string;
      } else {
        const result = localState.depoList[0];
        if (result) {
          // if (localState.model == null)
          //   return;
          localState.model = result.depoId as string;
          localState.subeIdRef = result.subeId;
          emit("update:modelValue", result.depoId);
          emit("update:selectedItem", result);
        }
      }
    }

    load();

    async function load() {
      localState.depoList = await useCachedSubeDepoService().getUserDepoList();
      if (props.firstSelect == true)
        depoChange();

    }

    function valueChanged(e: any) {
      //console.log(e);
      emit("valueChanged", e);
    }

    return {
      ...toRefs(localState),
      valueChanged
    };
  }
});
