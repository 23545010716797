import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "dx-card",
  style: {"height":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NTag = _resolveComponent("NTag")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_DxDateBox = _resolveComponent("DxDateBox")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_DxLoadPanel = _resolveComponent("DxLoadPanel")!
  const _component_DxGroupPanel = _resolveComponent("DxGroupPanel")!
  const _component_DxGrouping = _resolveComponent("DxGrouping")!
  const _component_DxScrolling = _resolveComponent("DxScrolling")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxColumnChooser = _resolveComponent("DxColumnChooser")!
  const _component_DxEditing = _resolveComponent("DxEditing")!
  const _component_DxKeyboardNavigation = _resolveComponent("DxKeyboardNavigation")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_NViewGrid = _resolveComponent("NViewGrid")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    modelValue: _ctx.showRef,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showRef) = $event)),
    width: "650px",
    "closable-mask": false
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_NButton, {
        type: "warning",
        icon: "x",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showRef = false ))
      }, {
        default: _withCtx(() => [
          _createTextVNode("İptal")
        ]),
        _: 1
      }),
      _createVNode(_component_NButton, {
        type: "success",
        icon: "save",
        click: _ctx.kaydet
      }, {
        default: _withCtx(() => [
          _createTextVNode("Kaydet")
        ]),
        _: 1
      }, 8, ["click"])
    ]),
    header: _withCtx(() => [
      _createTextVNode(" Taksitlendirme Tablosu ")
    ]),
    body: _withCtx(() => [
      _createVNode(_component_NFormGrid, {
        cols: "1fr 1fr",
        title: "Taksit Bilgileri"
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_NTag, {
            style: {"cursor":"pointer"},
            onClick: _ctx.taksitlendir,
            type: "success",
            filled: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NIcon, {
                size: "15",
                type: "plus"
              }),
              _createTextVNode(" Taksitlendir ")
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_NFormField, {
            "grid-cols": "100px 1fr",
            label: "Tutar"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxNumberBox, {
                value: _ctx.hareketTutar,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.hareketTutar) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "100px 1fr",
            label: "Vade Başlangıç"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxDateBox, {
                value: _ctx.vadeRef,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.vadeRef) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "100px 1fr",
            label: "Taksit Sayısı"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NInput, {
                modelValue: _ctx.taksitSayisi,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.taksitSayisi) = $event)),
                modelModifiers: { number: true },
                type: "number",
                disabled: _ctx.taksitTutari > 0  
              }, null, 8, ["modelValue", "disabled"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "100px 1fr",
            label: "Taksit Tutarı"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NInput, {
                modelValue: _ctx.taksitTutari,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.taksitTutari) = $event)),
                modelModifiers: { number: true },
                type: "number",
                disabled: _ctx.taksitSayisi > 0
              }, null, 8, ["modelValue", "disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_NFormGrid, {
        cols: "1fr",
        title: "Taksitler",
        gap: "0",
        padding: "0"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_NViewGrid, {
              cols: "minmax(0, 1fr)",
              rows: "minmax(0,1fr)"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxDataGrid, {
                  "data-source": _ctx.taksitlerRef,
                  style: {"height":"300px","width":"100%"},
                  toolbarTemplate: "toolbar"
                }, {
                  rowIndexCellTemplate: _withCtx(({data}) => [
                    _createTextVNode(_toDisplayString((data.rowIndex + 1)) + " .Taksit ", 1)
                  ]),
                  toolbar: _withCtx(() => [
                    _createVNode(_component_NGrid, {
                      cols: "1fr 1fr 1fr",
                      gap: "2px"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NTag, {
                          style: {"font-size":"13px"},
                          type: "primary",
                          filled: ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Taksit Sayısı : " + _toDisplayString(_ctx.taksitSayisiToplam), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_NTag, {
                          style: {"font-size":"13px"},
                          type: "primary",
                          filled: ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Taksit Toplam : " + _toDisplayString(_ctx.taksitTutariToplam.format(2, 2)), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_NTag, {
                          style: {"font-size":"13px"},
                          type: (_ctx.hareketTutar - _ctx.taksitTutariToplam) <= 0  ? 'success' : 'danger'  ,
                          filled: ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Fark : " + _toDisplayString((_ctx.hareketTutar - _ctx.taksitTutariToplam).format(2,2)), 1)
                          ]),
                          _: 1
                        }, 8, ["type"])
                      ]),
                      _: 1
                    })
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_DxLoadPanel, { enabled: false }),
                    _createVNode(_component_DxGroupPanel, { visible: false }),
                    _createVNode(_component_DxGrouping, { "auto-expand-all": false }),
                    _createVNode(_component_DxScrolling, { mode: "scroll" }),
                    _createVNode(_component_DxHeaderFilter, { visible: false }),
                    _createVNode(_component_DxFilterRow, { visible: false }),
                    _createVNode(_component_DxColumnChooser, { enabled: false }),
                    _createVNode(_component_DxEditing, {
                      "allow-updating": true,
                      "allow-deleting": true,
                      "use-icons": true,
                      "select-text-on-edit-start": true,
                      "start-edit-action": "click",
                      mode: "cell"
                    }),
                    _createVNode(_component_DxKeyboardNavigation, {
                      "edit-on-key-press": true,
                      "enter-key-action": "moveFocus",
                      "enter-key-direction": "column"
                    }),
                    _createVNode(_component_DxGroupPanel, { visible: false }),
                    _createVNode(_component_DxColumn, {
                      width: "80",
                      cellTemplate: "rowIndexCellTemplate",
                      caption: "#",
                      "data-field": "rowIndex",
                      "calculate-cell-value": (data)=> data.columnIndex
                    }, null, 8, ["calculate-cell-value"]),
                    _createVNode(_component_DxColumn, {
                      alignment: "left",
                      caption: "Vade",
                      "data-field": "vade",
                      "data-type": "date"
                    }),
                    _createVNode(_component_DxColumn, {
                      alignment: "left",
                      caption: "Tutar",
                      "data-field": "tutar",
                      "data-type": "number",
                      format: (a) => a.format(2,2)
                    }, null, 8, ["format"]),
                    _createVNode(_component_DxColumn, {
                      caption: "Sil",
                      type: "buttons",
                      width: 40
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxButton, { name: "delete" })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["data-source"])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}