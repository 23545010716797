import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d3139c7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "n-card-header"
}
const _hoisted_2 = {
  key: 0,
  class: "n-card-title"
}
const _hoisted_3 = {
  key: 1,
  class: "n-card-header-content"
}
const _hoisted_4 = {
  key: 2,
  class: "n-card-actions"
}
const _hoisted_5 = ["disabled", "onClick"]
const _hoisted_6 = {
  key: 0,
  class: "n-card-tab",
  style: {"background-color":"#2f8fe8!important"}
}
const _hoisted_7 = {
  key: 2,
  class: "n-card-section n-card-section--top"
}
const _hoisted_8 = {
  key: 3,
  class: "n-card-section n-card-section--bottom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "n-card" }, _ctx.$attrs, {
    style: { 'grid-column': _ctx.col, 'grid-row': _ctx.row }
  }), [
    (_ctx.title || _ctx.$slots.actions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.title)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true),
          (_ctx.$slots.header)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "header", {}, undefined, true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.$slots.actions && !(_ctx.tabs && _ctx.tabs.length > 0))
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.tabs && _ctx.tabs.length)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(['n-card-tabs', (_ctx.title || _ctx.$slots.actions) && 'no-radius'])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs.filter(a => a.hide !== false), (tab, i) => {
            return (_openBlock(), _createElementBlock("button", {
              class: _normalizeClass([
          'n-card-tab',
          _ctx.selectedTabRef === i && 'n-card-tab--selected',
          tab.disabled && 'n-card-tab--disabled'
        ]),
              style: _normalizeStyle(  {width: _ctx.$slots.tabsAction ?  'inherit' : 'auto' } ),
              key: i,
              disabled: tab.disabled,
              onClick: _withModifiers(($event: any) => (tab.disabled ? {} : _ctx.selectTab(i)), ["stop"])
            }, [
              (tab.icon)
                ? (_openBlock(), _createBlock(_component_NIcon, {
                    key: 0,
                    style: {"vertical-align":"middle","margin-right":"3px"},
                    size: "18px",
                    type: tab.icon,
                    title: tab.label
                  }, null, 8, ["type", "title"]))
                : _createCommentVNode("", true),
              _createElementVNode("span", null, _toDisplayString(tab.label), 1)
            ], 14, _hoisted_5))
          }), 128)),
          (_ctx.$slots.tabsAction)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "tabsAction", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.$slots.top)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "top", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(['n-card-body', _ctx.scrollable && 'n-card-body--scrollable'])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, i) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "n-card-tab-content",
          key: tab.id
        }, [
          (_ctx.selectedTabRef === i)
            ? _renderSlot(_ctx.$slots, `tab:${tab.id}`, { key: 0 }, undefined, true)
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ], 2),
    (_ctx.$slots.bottom)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _renderSlot(_ctx.$slots, "bottom", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 16))
}