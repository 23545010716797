
export enum EIhracatKapCinsTip{
BA=0,
BE=1,
BG=2,
BH=3,
BI=4,
BJ=5,
BK=6,
BX=7,
CB=8,
CH=9,
CI=10,
CK=11,
CN=12,
CR=13,
DK=14,
DR=15,
EC=16,
FC=17,
JR=18,
LV=19,
NE=20,
PI=21,
PV=22,
PX=23,
RO=24,
SA=25,
SB=26,
SO=27,
SU=28,
TN=29,
VG=30,
VL=31,
VO=32
}

export const EIhracatKapCinsTipList = [
{key:'BA', value:0, description:'BA - Varil'},
{key:'BE', value:1, description:'BE - Bohça'},
{key:'BG', value:2, description:'BG - Torba'},
{key:'BH', value:3, description:'BH - Demet'},
{key:'BI', value:4, description:'BI - Çöp kutusu'},
{key:'BJ', value:5, description:'BJ - Kova'},
{key:'BK', value:6, description:'BK - Sepet'},
{key:'BX', value:7, description:'BX - Kutu'},
{key:'CB', value:8, description:'CB - Bira kasası'},
{key:'CH', value:9, description:'CH - Sandık'},
{key:'CI', value:10, description:'CI - Teneke kutu'},
{key:'CK', value:11, description:'CK - Fıçı'},
{key:'CN', value:12, description:'CN - Konteyner'},
{key:'CR', value:13, description:'CR - Kasa'},
{key:'DK', value:14, description:'DK - Karton kasa'},
{key:'DR', value:15, description:'DR - Bidon'},
{key:'EC', value:16, description:'EC - Plastik torba'},
{key:'FC', value:17, description:'FC - Meyve kasası'},
{key:'JR', value:18, description:'JR - Kavanoz'},
{key:'LV', value:19, description:'LV - Liftvan'},
{key:'NE', value:20, description:'NE - Ambalajsız'},
{key:'PI', value:21, description:'PI - Boru'},
{key:'PV', value:22, description:'PV - Boru, tomar/deste/demet halinde'},
{key:'PX', value:23, description:'PX - Palet'},
{key:'RO', value:24, description:'RO - Rulo'},
{key:'SA', value:25, description:'SA - Çuval'},
{key:'SB', value:26, description:'SB - Levha'},
{key:'SO', value:27, description:'SO - Bobin'},
{key:'SU', value:28, description:'SU - Bavul'},
{key:'TN', value:29, description:'TN - Teneke'},
{key:'VG', value:30, description:'VG - Dökme gaz'},
{key:'VL', value:31, description:'VL - Dökme sıvı'},
{key:'VO', value:32, description:'VO - Dökme katı'}
]
