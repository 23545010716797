import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSelectBox = _resolveComponent("NSelectBox")!

  return (_openBlock(), _createBlock(_component_NSelectBox, {
    items: _ctx.items,
    "label-field": "departmanAdi",
    "value-field": "id",
    modelValue: _ctx.selected,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
    "first-option-label": "-Seçiniz-"
  }, null, 8, ["items", "modelValue"]))
}