import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {DateBackType} from '../models/DateBackType'
import {UretimStokKalanType} from '../models/UretimStokKalanType'
import {UretimFilterViewStok} from '../models/UretimFilterViewStok'
import {RecordStatus} from '../models/RecordStatus'
import {FaturaHareketTip} from '../models/FaturaHareketTip'
import {FaturaTip} from '../models/FaturaTip'
import {FaturaUstBilgiItem} from '../models/FaturaUstBilgiItem'
import {TeslimatAdresItem} from '../models/TeslimatAdresItem'
import {FaturaSatirItem} from '../models/FaturaSatirItem'
import {FaturaAltBilgiItem} from '../models/FaturaAltBilgiItem'
import {DocumentItem} from '../models/DocumentItem'
import {PlanVadelerItem} from '../models/PlanVadelerItem'
import {FaturaEFaturaItem} from '../models/FaturaEFaturaItem'
import {SmsMailKargoGonderItem} from '../models/SmsMailKargoGonderItem'
import {FaturaView} from '../models/FaturaView'
import {IdNameAdet} from '../models/IdNameAdet'
import {UretimStokAltBilgi} from '../models/UretimStokAltBilgi'
import {UretimStokTipi} from '../models/UretimStokTipi'
import {UretimStokView} from '../models/UretimStokView'
import {IdName} from '../models/IdName'
import {UretimStokOlusturView} from '../models/UretimStokOlusturView'
import {UretimStokCikisFisiView} from '../models/UretimStokCikisFisiView'

export const useUretimStokService = defineStore({
id: 'UretimStokService',
actions: {


	async getGetir(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/Getir/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getStokGirisReportView()
	{
		const { request, response } = useApi(`/api/UretimStok/StokGirisReportView`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetirView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/GetirView/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetirViewPassive(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/GetirViewPassive/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/Liste/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getKaliteList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/KaliteList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCinsList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/CinsList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postListeFiltered(uretimFilterViewStok:UretimFilterViewStok)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/ListeFiltered/${shared.firm.id}`,{ method:'POST', data:uretimFilterViewStok });
		await request();
		return response.value;
	},

	async getListeTree()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/ListeTree/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getExistByEtiketNo(etiket:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/ExistByEtiketNo/${shared.firm.id}/${etiket}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postAlisStokEkle(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/AlisStokEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async postEkle(uretimStokView:UretimStokView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/Ekle/${shared.firm.id}`,{ method:'POST', data:uretimStokView });
		await request();
		return response.value;
	},

	async postPasifEkle(uretimStokView:UretimStokView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/PasifEkle/${shared.firm.id}`,{ method:'POST', data:uretimStokView });
		await request();
		return response.value;
	},

	async putPasifGuncelle(uretimStokView:UretimStokView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/PasifGuncelle/${shared.firm.id}`,{ method:'PUT', data:uretimStokView });
		await request();
		return response.value;
	},

	async putGuncelle(uretimStokView:UretimStokView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/Guncelle/${shared.firm.id}`,{ method:'PUT', data:uretimStokView });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/Sil/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getGetirOperatorListesi()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/GetirOperatorListesi/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetirDisDepoListesi()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/GetirDisDepoListesi/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async putCariyeSevkEt(ıdName:IdName,stokId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/CariyeSevkEt/${shared.firm.id}/${stokId}`,{ method:'PUT', data:ıdName });
		await request();
		return response.value;
	},

	async postUretimStokOlustur(uretimStokOlusturView:UretimStokOlusturView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/UretimStokOlustur/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:uretimStokOlusturView });
		await request();
		return response.value;
	},

	async deleteUretimStokOlustur(uretimStokId:string,normalStokId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/UretimStokOlustur/${shared.firm.id}/${shared.firm.period}/${uretimStokId}/${normalStokId}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postUretimStokCikisFisiEkle(uretimStokCikisFisiView:UretimStokCikisFisiView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/UretimStokCikisFisiEkle/${shared.firm.id}`,{ method:'POST', data:uretimStokCikisFisiView });
		await request();
		return response.value;
	},

	async deleteUretimStokCikisFisiSil(uretimStokId:string,hareketId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/UretimStokCikisFisiSil/${shared.firm.id}/${uretimStokId}/${hareketId}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async patchUretimStokKalanMigrate()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/UretimStokKalanMigrate/${shared.firm.id}`,{ method:'PATCH' });
		await request();
		return response.value;
	},

	async putSayim(etiket:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/Sayim/${shared.firm.id}/${etiket}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async getGetPlakaList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UretimStok/GetPlakaList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
