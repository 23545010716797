<template>
    <NGrid cols="1fr auto" gap="4px">
      <div>
        <slot />
      </div>
      <div>
        <NButton size="small" title="Yenilemek için tıklayınız" icon="refresh-ccw"  v-model:loading="isLoading" @click="loadClick" >
        </NButton>
      </div>
    </NGrid>
</template>

<script>

import { defineComponent, reactive, ref, toRefs } from "vue";
import NGrid from "../Grid/NGrid";
import NButton from "../Button/NButton";
import { alert } from "devextreme/ui/dialog";
import { usePopupService } from "@/services/popupService";

export default defineComponent({
  components: { NButton, NGrid },
  props: {
    load:{
      type:Function,
      default:()=> alert('load özelliği belirtilmemiş.')
    },
    loadStartup:{
      type:Boolean,
      default:false
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      isLoading:ref(false)

    })



    async function loadClick(){



      try {
        localState.isLoading = true;
        await props.load.call()
        localState.isLoading = false;
      }catch (e) {
        localState.isLoading = false;
        usePopupService().pushDangerNotification({
          body:e.message,
          title:'Hata oluştu'
        })
      }
    }

    if (props.loadStartup)
      loadClick()


    return {
      ...toRefs(localState),
      loadClick

    };
  }
});
</script>

<style scoped>

</style>