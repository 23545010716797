import {CariTip, CariTipList} from "@/api/models/CariTip";
import {CariView} from "@/api/models/CariView";
import {Doviz} from "@/api/models/Doviz";
import {KurumTip, KurumTipList} from "@/api/models/KurumTip";
import {useCariService} from "@/api/services/CariService";

import {CariEntity} from "@/api/models/CariEntity";
import {DocumentItem} from "@/api/models/DocumentItem";
import {AdresTip} from "@/api/models/AdresTip";
import {IdName} from "@/api/models/IdName";
import {Ozellik} from "@/api/models/Ozellik";
import {StokView} from "@/api/models/StokView";
import {StokKategoriTreeListView} from "@/api/models/StokKategoriTreeListView";
import {CariExcelView} from "@/api/models/CariExcelView";
import {BankaHareketTip, BankaHareketTipList} from "@/api/models/BankaHareketTip";
import {CariHareketTip, CariHareketTipList} from "@/api/models/CariHareketTip";


export const CariUtils = {
    async generateCariView(cariId: string): Promise<CariView> {
        if (!cariId)
            return  useCariService().getViewInsert();

        return  useCariService().getView(cariId);
    },

    async save(model: CariView): Promise<CariEntity> {
        if (model.id && model.id.length > 0)
            return await useCariService().putGuncelle(model);
        else
            return await useCariService().postEkle(model);


    },
    getSube(subeler: any[]) {
        //console.log(subeler);
    },

    getColumns() {
        return [
            {label: "Grp Şirket Kod", prop: "grupSirketKodu", visible: true},
            {label: "Kod", prop: "kod", visible: true},

            {label: "Ünvan", prop: "unvan", visible: true},
            {label: "Vergi Dairesi", prop: "vergiDairesi", visible: true},
            {label: "Vergi No", prop: "vergiNo", visible: true},
            {
                label: "Adres",
                formatter: (col: any, cari: CariEntity) =>
                    cari.adresler && cari.adresler?.length > 0
                        ? cari.adresler[0].adres
                        : "",
                visible: true
            },
            {
                label: "Sehir",
                formatter: (col: any, cari: CariEntity) =>
                    cari.adresler && cari.adresler?.length > 0 ? cari.adresler[0].sehir : "",
                visible: true
            },
            {
                label: "İlce",
                formatter: (col: any, cari: CariEntity) =>
                    cari.adresler && cari.adresler?.length > 0
                        ? cari.adresler[0].ilce
                        : "",
                visible: true
            },
            {
                label: "Tip",
                formatter: (col: any, cari: CariEntity) =>
                    CariTipList.find(a => a.value == cari.tip)?.description,
                visible: true
            },
            {
                label: "Kurum Tipi",
                formatter: (col: any, cari: CariEntity) =>
                    KurumTipList.find(a => a.value == cari.kurumTip)?.description,
                visible: true
            },
            {
                label: "Borç (₺)",
                prop: "tlBorcu",
                width: "100px",
                visible: true
            },
            {
                label: "Alacak (₺)",
                prop: "tlAlacagi",
                width: "100px",
                visible: true
            },
            {
                label: "Borç (₺)",
                prop: "tlBakiye",
                width: "100px",
                visible: true
            },
            {
                label: "Borç ($)",
                prop: "usdBorcu",
                width: "100px",
                visible: true
            },
            {
                label: "Alacak ($)",
                prop: "usdAlacagi",
                width: "100px",
                visible: true
            },
            {
                label: "Bakiye ($)",
                prop: "usdBakiye",
                width: "100px",
                visible: true
            },
            {
                label: "Borç (€)",
                prop: "euroBorcu",
                width: "100px",
                visible: true
            },
            {
                label: "Alacak (€)",
                prop: "euroAlacagi",
                width: "100px",
                visible: true
            },
            {
                label: "Bakiye (€)",
                prop: "euroBakiye",
                width: "100px",
                visible: true
            },
            {
                label: "Yerel Borç",
                prop: "yerelBorcu",
                width: "100px",
                visible: true
            },
            {
                label: "Yerel Alacak",
                prop: "yerelAlacagi",
                width: "100px",
                visible: true
            },
            {
                label: "Yerel Bakiye",
                prop: "yerelBakiye",
                width: "100px",
                visible: true
            }
        ];
    },
    getSosyalAglarList() {
        return [
            {icon: "facebook.png", label: "Facebook"},
            {icon: "whatsapp.png", label: "Whatsapp"},
            {icon: "yok.gif", label: "QQ"},
            {icon: "yok.gif", label: "WeChat"},
            {icon: "yok.gif", label: "QZone"},
            {icon: "yok.gif", label: "Tumblr"},
            {icon: "yok.gif", label: "Instagram"},
            {icon: "twitter.png", label: "Twitter"},
            {icon: "yok.gif", label: "Baidu Tieba"},
            {icon: "yok.gif", label: "Skype"},
            {icon: "yok.gif", label: "Viber"},
            {icon: "yok.gif", label: "Sina Weibo"},
            {icon: "yok.gif", label: "LINE"},
            {icon: "yok.gif", label: "Snapchat"},
            {icon: "yok.gif", label: "YY"},
            {icon: "yok.gif", label: "VKontakte (VK)"},
            {icon: "yok.gif", label: "Pinterest"},
            {icon: "yok.gif", label: "LinkedIn"},
            {icon: "yok.gif", label: "Telegram"},
            {icon: "yok.gif", label: "Taringa"},
            {icon: "yok.gif", label: "Foursquare"},
            {icon: "yok.gif", label: "Renren"},
            {icon: "yok.gif", label: "Tagged"},
            {icon: "yok.gif", label: "Badoo"},
            {icon: "yok.gif", label: "Myspace"},
            {icon: "yok.gif", label: "Mix"},
            {icon: "yok.gif", label: "The Dots"},
            {icon: "yok.gif", label: "Kiwibox"},
            {icon: "yok.gif", label: "Skyrock"},
            {icon: "yok.gif", label: "Delicious"},
            {icon: "yok.gif", label: "Snapfish"},
            {icon: "yok.gif", label: "ReverbNation"},
            {icon: "yok.gif", label: "Flixster"},
            {icon: "yok.gif", label: "Care2"},
            {icon: "yok.gif", label: "CafeMom"},
            {icon: "yok.gif", label: "Ravelry"},
            {icon: "yok.gif", label: "Nextdoor"},
            {icon: "yok.gif", label: "Wayn"},
            {icon: "yok.gif", label: "Cellefun"},
            {icon: "youtube.png", label: "YouTube"},
            {icon: "yok.gif", label: "Upstream"},
            {icon: "yok.gif", label: "Classmates"},
            {icon: "yok.gif", label: "MyHeritage"},
            {icon: "yok.gif", label: "Viadeo"},
            {icon: "yok.gif", label: "Xing"},
            {icon: "yok.gif", label: "Xanga"},
            {icon: "yok.gif", label: "LiveJournal"},
            {icon: "yok.gif", label: "Friendster"},
            {icon: "yok.gif", label: "Funny or Die"},
            {icon: "yok.gif", label: "Gaia Online"},
            {icon: "yok.gif", label: "We Heart It"},
            {icon: "yok.gif", label: "DeviantArt"},
            {icon: "yok.gif", label: "Flickr"},
            {icon: "yok.gif", label: "MeetMe"},
            {icon: "yok.gif", label: "Meetup"},
            {icon: "yok.gif", label: "Tout"},
            {icon: "yok.gif", label: "Mixi"},
            {icon: "yok.gif", label: "Douban"},
            {icon: "yok.gif", label: "Vero"},
            {icon: "yok.gif", label: "Quora"},
            {icon: "yok.gif", label: "Spreely"},
            {icon: "yok.gif", label: "Discord"},
            {icon: "yok.gif", label: "TikTok"},
            {icon: "yok.gif", label: "WT Social"},
            {icon: "yok.gif", label: "Triller"},
            {icon: "yok.gif", label: "Elpha"},
            {icon: "yok.gif", label: "Yubo"},
            {icon: "yok.gif", label: "PopBase"},
            {icon: "yok.gif", label: "Peanut"},
            {icon: "yok.gif", label: "Valence"},
            {icon: "yok.gif", label: "Flip"},
            {icon: "yok.gif", label: "HouseParty"},
            {icon: "yok.gif", label: "Caffeine"},
            {icon: "yok.gif", label: "Steemit"},
            {icon: "yok.gif", label: "Goodreads"},
            {icon: "yok.gif", label: "Twitch"},
            {icon: "yok.gif", label: "CaringBridge"},
            {icon: "yok.gif", label: "WattPad"},
            {icon: "yok.gif", label: "Crunchyroll"},
            {icon: "yok.gif", label: "Soundcloud"},
            {icon: "yok.gif", label: "Mocospace"},
            {icon: "yok.gif", label: "CouchSurfing"},
            {icon: "yok.gif", label: "italki"},
            {icon: "yok.gif", label: "Medium"},
            {icon: "yok.gif", label: "Ello"},
            {icon: "yok.gif", label: "Vimeo"},
            {icon: "yok.gif", label: "Giphy"},
            {icon: "yok.gif", label: "Tribe"},
            {icon: "yok.gif", label: "Kuaishou"},
            {icon: "yok.gif", label: "Imgur"},
            {icon: "yok.gif", label: "Influenster"},
            {icon: "yok.gif", label: "FilmAffinity"},
            {icon: "yok.gif", label: "Open Diary"},
            {icon: "yok.gif", label: "Bubbly"},
            {icon: "yok.gif", label: "Site Url"}
        ];
    },
    fillCariFromKategori(cari: CariView, kategori: StokKategoriTreeListView) {

        this.fillCariOzellikFromKategoriOzellik(cari.ozellik?.ozellikler as Ozellik[], kategori.ozellik?.ozellikler as Ozellik[]);
        this.fillCariOzellikFromKategoriOzellik(cari.ozellik?.ozellikler as Ozellik[], kategori.ozellikDevir?.ozellikler as Ozellik[]);


    },
    fillCariOzellikFromKategoriOzellik(cariOzellikler: Ozellik[], kategoriOzellikler: Ozellik[]) {
        // console.log(stokOzellikler, kategoriOzellikler);
        kategoriOzellikler.forEach((ozellikKategori) => {
            if (ozellikKategori.name && ozellikKategori.name.length) {
                const findIndex = cariOzellikler.findIndex(a => a.name == ozellikKategori.name);
                if (findIndex === -1) {
                    cariOzellikler.push(ozellikKategori);
                }
            }
        });
    },
    async cariAktar(cari: any) {

        await useCariService().postEkleExcel(cari as CariExcelView);
    },
    getEnumDescription(tip: CariHareketTip) {
        const result = CariHareketTipList.find(a => a.value === tip);
        if (result) return result.description;
    },

};
