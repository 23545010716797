
export enum CariHareketTip{
Cari_Borc_Devir=100,
Cari_Nakit_Odeme=110,
Cari_Havale_Gonderme=120,
Cariye_Firma_Kredi_Kartimizla_Odeme=130,
Cariye_Firma_Cekimizi_Verme=140,
Cariye_Firma_Senedimizi_Verme=150,
Cariye_Musteri_Ceki_Verme_Cirolama=160,
Cariye_Musteri_Senedi_Verme_Cirolama=170,
Cariye_Musteri_Cekini_Iade_Etme=180,
CariyeMusteriSenediniIadeEtme=190,
Cari_Borc_Dekontu=200,
Cari_Virman_Borc=210,
Satis_Faturasi=300,
Alis_Iade_Faturasi_Satis_Faturamiz=310,
MailOrderOdeme=320,
Dbs=330,
Kur_Farki_Faturamiz=2001,
Teminata_Musteri_Ceki_Verme_Cariye=3001,
Teminata_Msteri_Senedi_Verme_Cariye=3010,
Teminata_Firma_Cekimizi_Verme_Cariye=3020,
Teminata_Firma_Senedimizi_Verme_Cariye=3030,
Satis_Teklifi=3100,
Konsinye_Satis=3110,
Satis_Siparisi=3120,
Satis_Irsaliyesi=3130,
SatisIrsaliyeToFatura=3140,
MasrafGideri=3150,
BorcYapilandirma=3160,
Cari_Alacak_Devir=5100,
Cari_Nakit_Tahsilat=5110,
Cari_Havale_Alma=5120,
Cari_Pos_Cekme=5130,
Cari_Cek_Alma=5140,
Cari_Senet_Alma=5150,
Cariden_MusteriCekini_GeriAlma=5160,
Cariden_Musteri_Senedini_GeriAlma=5170,
Cariden_FirmaCekimizi_GeriAlma=5180,
Cariden_FirmaSenedimizi_GeriAlma=5190,
Cariden_FirmaDbs_GeriAlma=5191,
Cari_Alacak_Dekontu=5200,
Cari_Virman_Alacak=5210,
Alis_Faturasi=5300,
Satis_Iade_Faturasi_AlisFaturasi=5310,
PersonelHakedis=5320,
MailOrderCekme=5330,
Kur_Farki_Alis_Faturasi=6001,
Teminata_Cek_Alma=7001,
Teminata_Senet_Alma=7010,
Teminattan_Firma_Cekimizi_Geri_Alma=7020,
Teminattan_Firma_Senedimizi_Geri_Alma=7030,
Alis_Teklifi=7100,
Konsinye_Alis=7110,
Alis_Siparisi=7120,
Alis_Irsaliyesi=7130,
AlisIrsaliyeToFatura=7140,
CariCekAlmaDevir=8000,
CariSenetAlmaDevir=8100,
AlacakYapilandirma=8200,
FirmaCekiVermeDevir=8300,
FirmaSenediVermeDevir=8400,
Etkisiz_Satis_Irsaliyesi=8500,
Etkisiz_Satis_Fatura_Irsaliyeli=8501,
IslemYok=-1
}

export const CariHareketTipList = [
{key:'Cari_Borc_Devir', value:100, description:'Borç Devir'},
{key:'Cari_Nakit_Odeme', value:110, description:'Nakit Ödeme'},
{key:'Cari_Havale_Gonderme', value:120, description:'Havale Gönderme'},
{key:'Cariye_Firma_Kredi_Kartimizla_Odeme', value:130, description:'F.K.K. Ödeme'},
{key:'Cariye_Firma_Cekimizi_Verme', value:140, description:'F.Ç. Verme'},
{key:'Cariye_Firma_Senedimizi_Verme', value:150, description:'F.S. Verme'},
{key:'Cariye_Musteri_Ceki_Verme_Cirolama', value:160, description:'M.Ç. Cirolama'},
{key:'Cariye_Musteri_Senedi_Verme_Cirolama', value:170, description:'M.S. Cirolama'},
{key:'Cariye_Musteri_Cekini_Iade_Etme', value:180, description:'M.Ç. İade Etme'},
{key:'CariyeMusteriSenediniIadeEtme', value:190, description:'M.S. İade Etme'},
{key:'Cari_Borc_Dekontu', value:200, description:'Borç Dekontu'},
{key:'Cari_Virman_Borc', value:210, description:'Virman Borç'},
{key:'Satis_Faturasi', value:300, description:'Satış Faturası'},
{key:'Alis_Iade_Faturasi_Satis_Faturamiz', value:310, description:'Alıştan İade'},
{key:'MailOrderOdeme', value:320, description:'Mail Order Ödeme'},
{key:'Dbs', value:330, description:'DBS Verme'},
{key:'Kur_Farki_Faturamiz', value:2001, description:'Kur Farkı Faturası'},
{key:'Teminata_Musteri_Ceki_Verme_Cariye', value:3001, description:'Teminata M.Ç. Verme'},
{key:'Teminata_Msteri_Senedi_Verme_Cariye', value:3010, description:'Teminata M.S. Verme'},
{key:'Teminata_Firma_Cekimizi_Verme_Cariye', value:3020, description:'Teminata F.Ç. Verme'},
{key:'Teminata_Firma_Senedimizi_Verme_Cariye', value:3030, description:'Teminata M.S. Verme'},
{key:'Satis_Teklifi', value:3100, description:'Satış Teklifi'},
{key:'Konsinye_Satis', value:3110, description:'Satış Konsinye'},
{key:'Satis_Siparisi', value:3120, description:'Satış Sipariş'},
{key:'Satis_Irsaliyesi', value:3130, description:'Satış İrsaliye'},
{key:'SatisIrsaliyeToFatura', value:3140, description:'Satış İrsaliye'},
{key:'MasrafGideri', value:3150, description:'Masraf Gideri'},
{key:'BorcYapilandirma', value:3160, description:'Borç Yapılandırma'},
{key:'Cari_Alacak_Devir', value:5100, description:'Alacak Devir'},
{key:'Cari_Nakit_Tahsilat', value:5110, description:'Nakit Tahsilat'},
{key:'Cari_Havale_Alma', value:5120, description:'Havale Alma'},
{key:'Cari_Pos_Cekme', value:5130, description:'Pos Çekme'},
{key:'Cari_Cek_Alma', value:5140, description:'Çek Alma'},
{key:'Cari_Senet_Alma', value:5150, description:'Senet Alma'},
{key:'Cariden_MusteriCekini_GeriAlma', value:5160, description:'M.Ç. Geri Alma'},
{key:'Cariden_Musteri_Senedini_GeriAlma', value:5170, description:'M.S. Geri Alma'},
{key:'Cariden_FirmaCekimizi_GeriAlma', value:5180, description:'F.Ç. Geri Alma'},
{key:'Cariden_FirmaSenedimizi_GeriAlma', value:5190, description:'F.S Geri Alma'},
{key:'Cariden_FirmaDbs_GeriAlma', value:5191, description:'Firma DBS Geri Alma'},
{key:'Cari_Alacak_Dekontu', value:5200, description:'Alacak Dekontu'},
{key:'Cari_Virman_Alacak', value:5210, description:'Virman Alacak'},
{key:'Alis_Faturasi', value:5300, description:'Alış Fatura'},
{key:'Satis_Iade_Faturasi_AlisFaturasi', value:5310, description:'Satış Iade'},
{key:'PersonelHakedis', value:5320, description:'Personel Hakediş'},
{key:'MailOrderCekme', value:5330, description:'Mail Order Çekme'},
{key:'Kur_Farki_Alis_Faturasi', value:6001, description:'Kur Farkı (Alış)'},
{key:'Teminata_Cek_Alma', value:7001, description:'Teminata Çek Alma'},
{key:'Teminata_Senet_Alma', value:7010, description:'Teminata Senet Alma'},
{key:'Teminattan_Firma_Cekimizi_Geri_Alma', value:7020, description:'Teminattan F.Ç Geri Alma'},
{key:'Teminattan_Firma_Senedimizi_Geri_Alma', value:7030, description:'Teminattan F.S. Geri Alma'},
{key:'Alis_Teklifi', value:7100, description:'Alış Teklif'},
{key:'Konsinye_Alis', value:7110, description:'Alış Konsinye'},
{key:'Alis_Siparisi', value:7120, description:'Alış Sipariş'},
{key:'Alis_Irsaliyesi', value:7130, description:'Alış İrsaliye'},
{key:'AlisIrsaliyeToFatura', value:7140, description:'Alış İrsaliye'},
{key:'CariCekAlmaDevir', value:8000, description:'Çek Alma(D)'},
{key:'CariSenetAlmaDevir', value:8100, description:'Senet Alma(D)'},
{key:'AlacakYapilandirma', value:8200, description:'Alacak Yapılandırma'},
{key:'FirmaCekiVermeDevir', value:8300, description:'F.Ç. Verme (D)'},
{key:'FirmaSenediVermeDevir', value:8400, description:'F.S. Verme (D)'},
{key:'Etkisiz_Satis_Irsaliyesi', value:8500, description:'Etkisiz Satış İrsaliye'},
{key:'Etkisiz_Satis_Fatura_Irsaliyeli', value:8501, description:'Etkisiz Satış Faturalı Irsaliye'},
{key:'IslemYok', value:-1, description:'İşlem Yok'}
]
