import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NMultipleDocumentUploader = _resolveComponent("NMultipleDocumentUploader")!
  const _component_DxDateBox = _resolveComponent("DxDateBox")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_NCard = _resolveComponent("NCard")!

  return (_openBlock(), _createBlock(_component_NCard, { cols: "1fr" }, {
    header: _withCtx(() => [
      _createTextVNode("Dökümanlar")
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_NButton, {
        onClick: _ctx.yeniDokuman,
        type: "info",
        size: "small",
        icon: "upload-cloud"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Yeni Döküman Ekle ")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.dokumanlar, (doc, i) => {
        return (_openBlock(), _createBlock(_component_NFormGrid, {
          cols: "auto 140px 1fr auto",
          title: " ",
          gap: "4px",
          key: i
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NMultipleDocumentUploader, {
              "folder-name": "",
              height: 35,
              width: 35,
              src: _ctx.model.dokumanlar[i].url,
              "server-url": _ctx.serverUrl,
              "show-upload-button": true,
              modelValue: _ctx.model.dokumanlar[i].url,
              "onUpdate:modelValue": ($event: any) => ((_ctx.model.dokumanlar[i].url) = $event)
            }, null, 8, ["src", "server-url", "modelValue", "onUpdate:modelValue"]),
            _createVNode(_component_DxDateBox, {
              value: _ctx.model.dokumanlar[i].tarih,
              "onUpdate:value": ($event: any) => ((_ctx.model.dokumanlar[i].tarih) = $event)
            }, null, 8, ["value", "onUpdate:value"]),
            _createVNode(_component_NInput, {
              type: "text",
              modelValue: _ctx.model.dokumanlar[i].aciklama,
              "onUpdate:modelValue": ($event: any) => ((_ctx.model.dokumanlar[i].aciklama) = $event),
              placeholder: "Döküman Açıklaması Giriniz"
            }, null, 8, ["modelValue", "onUpdate:modelValue"]),
            _createElementVNode("div", null, [
              _createVNode(_component_NButton, {
                onClick: ($event: any) => (_ctx.silDokuman(i)),
                type: "danger",
                size: "small",
                icon: "trash",
                style: {"padding":"7px"}
              }, null, 8, ["onClick"])
            ])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}