import { NModuleRoute } from "@/models/route.model";

export const UretimModuleRoutes: NModuleRoute[] = [
  {
    id: "UretimModule",
    order: 11,
    displayName: "Üretim",
    icon: {
      type: "box"
    },
    children: [

      {
        id: "UretimStoklar",
        order: 0,
        displayName: "Stok Listesi (Yeni)",
        requiredRoles:{
          readRoles:['UretimStok.Liste'  ,'HizmetMasraf.Uretim.Liste' , 'Cari.Uretim.Liste'],
          insertRoles:['UretimStok.Ekle'],
          updateRoles:['UretimStok.Guncelle'],
          deleteRoles:['UretimStok.Sil']
        },
        icon: {
          type: "box"
        },
        component: () =>
            import(/* webpackChunkName: "UretimStokListesi"*/ "../pages/UretimStokListServerPage.vue")
      },
      {
        id: "UretimStoklarv1",
        order: 0,
        displayName: "Stok Listesi (v1)",
        requiredRoles:{
          readRoles:['UretimStok.Liste'  ,'HizmetMasraf.Uretim.Liste' , 'Cari.Uretim.Liste'],
          insertRoles:['UretimStok.Ekle'],
          updateRoles:['UretimStok.Guncelle'],
          deleteRoles:['UretimStok.Sil']
        },
        icon: {
          type: "box"
        },
        component: () =>
          import(/* webpackChunkName: "UretimStokListesi"*/ "../pages/UretimStokListPage.vue")
      },
      {
        id: "UretimStoklarPalet",
        order: 0,
        displayName: "Palet & Nakliye",
        requiredRoles:{
          readRoles:['UretimHizmet.Liste'],
          insertRoles:['UretimHizmet.Ekle'],
          updateRoles:['UretimHizmet.Guncelle'],
          deleteRoles:['UretimHizmet.Sil']
        },
        icon: {
          type: "box"
        },
        component: () =>
          import(/* webpackChunkName: "UretimHizmetListesi"*/ "../pages/UretimHizmetListPage.vue")
      },
      {
        id: "UretimStoklar_Talimat",
        order: 0,
        displayName: "Talimat Fişleri",
        requiredRoles:{
          readRoles:['UretimTalimat.Liste' , 'HizmetMasraf.Uretim.Liste' , 'Cari.Uretim.Liste'],
          insertRoles:['UretimTalimat.Ekle'],
          updateRoles:['UretimTalimat.Guncelle'],
          deleteRoles:['UretimTalimat.Sil']
        },
        icon: {
          type: "box"
        },
        component: () =>
          import(/* webpackChunkName: "UretimTalimatListesi"*/ "../pages/UretimTalimatFisListesiPage.vue")
      },
      {
        id: "UretimStoklar_Sevkler",
        order: 0,
        displayName: "Sevkler",
        requiredRoles:{
          readRoles:['UretimSevk.Liste' , 'HizmetMasraf.Uretim.Liste' , 'Cari.Uretim.Liste'],
          insertRoles:['UretimSevk.Ekle' ,  'UretimSevk.Fatura.Ekle'],
          updateRoles:['UretimSevk.Guncelle' , 'UretimSevk.Fatura.Guncelle'],
          deleteRoles:['UretimSevk.Sil' , 'UretimSevk.Fatura.Sil']
        },
        icon: {
          type: "box"
        },
        component: () =>
          import(/* webpackChunkName: "UretimSevkListesi"*/ "../pages/UretimSevkListesiPage.vue")
      },
      // {
      //   id: "UretimStoklar_SevklFaturalar",
      //   order: 0,
      //   displayName: "Fiyatlandırma",
      //   requiredRoles:{
      //     readRoles:['UretimSevk.Fiyatlandirma.Liste' , 'HizmetMasraf.Uretim.Liste' ,'Cari.Uretim.Liste'],
      //     insertRoles:[],
      //     updateRoles:['UretimFiyat.Guncelle'],
      //     deleteRoles:[]
      //   },
      //   icon: {
      //     type: "box"
      //   },
      //   component: () =>
      //     import(/* webpackChunkName: "UretimSevkFaturaListesi"*/ "../pages/UretimSevkFiyatlandirmaPage.vue")
      // },
      {
        id: "UretimStoklar_SevklFaturalar2",
        order: 0,
        displayName: "Fiyatlandırma",
        requiredRoles:{
          readRoles:['UretimSevk.Fiyatlandirma.Liste' , 'HizmetMasraf.Uretim.Liste' ,'Cari.Uretim.Liste'],
          insertRoles:[],
          updateRoles:['UretimFiyat.Guncelle'],
          deleteRoles:[]
        },
        icon: {
          type: "box"
        },
        component: () =>
          import(/* webpackChunkName: "UretimSevkFaturaListesi"*/ "../pages/UretimFaturaPage.vue")
      },
      {
        id: "UretimStoklarPlan",
        order: 0,
        displayName: "Üretim Planı",
        requiredRoles:{
          readRoles:['UretimStok.Plan'],
          insertRoles:[],
          updateRoles:[],
          deleteRoles:[]
        },
        icon: {
          type: "box"
        },
        component: () =>
            import(/* webpackChunkName: "UretimPlanPage"*/ "../pages/UretimPlanPage.vue")
      },
      {
        id: "UretimIrsaliyeRapor",
        order: 0,
        displayName: "İrsaliye Raporu",
        requiredRoles:{
          readRoles:['UretimStok.Irsaliye.Rapor'],
          insertRoles:[],
          updateRoles:[],
          deleteRoles:[]
        },
        icon: {
          type: "box"
        },
        component: () =>
            import(/* webpackChunkName: "UretimTalimatIrsaliyeRaporPage"*/ "../pages/UretimTalimatIrsaliyeRaporPage.vue")
      },
      {
        id: "UretimSiparis",
        order: 0,
        displayName: "Siparişler",
        requiredRoles: {
          readRoles: ['Uretim.Siparisler.Liste'],
          insertRoles: ['Uretim.Siparisler.Ekle'],
          updateRoles: ['Uretim.Siparisler.Guncelle'],
          deleteRoles: ['Uretim.Siparisler.Sil']
        },
        icon: {
          type: "disc"
        },
        component: () =>
            import(/* webpackChunkName: "UretimSiparisModule"*/ "../../UretimSiparis/pages/UretimSiparisler.vue")
      },
      // {
      //   id: "Siparis",
      //   order: 0,
      //   displayName: "Siparişler",
      //   requiredRoles:{
      //     readRoles:[''],
      //     insertRoles:[''],
      //     updateRoles:[''],
      //     deleteRoles:['']
      //   },
      //   icon: {
      //     type: "box"
      //   },
      //   component: () =>
      //       import(/* webpackChunkName: "UretimTalimatIrsaliyeRaporPage"*/ "../../UretimSiparis/pages/UretimSiparislerOld.vue")
      // }
    ]
  }
];
