
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import NDropdownItem from "@/components/Dropdown/NDropdownItem.vue";
import NDropDownLabelItem from "@/components/Dropdown/NDropDownLabelItem.vue";
import {ReportType, ReportTypeList} from "@/api/models/ReportType";
import {ReportView} from "@/api/models/ReportView";
import {useRaporEkraniServiceExtend} from "@/api/extends/RaportEkraniServiceExtend";
import ReportViewerDialog from "@/modules/Report/dialogs/ReportViewerDialog.vue";
import ReportStokBarkodPieceDialog from "@/modules/Report/dialogs/ReportStokBarkodPieceDialog.vue";
import {useStokService} from "@/api/services/StokService";
import {StokFlatView} from "@/api/models/StokFlatView";
import {StokEntity} from "@/api/models/StokEntity";
import {useReportStore} from "@/modules/Report/store/ReportStore";
import {useRaporEkraniService} from "@/api/services/RaporEkraniService";
import {FaturaEntityDataSource} from "@/api/models/FaturaEntityDataSource";
import {useFaturaService} from "@/api/services/FaturaService";
import {usedCacheFirmaService} from "@/modules/Ayarlar/Firmalar/services/usedCacheFirmaService";

export default defineComponent({
  components: { ReportViewerDialog, NDropDownLabelItem, NDropdownItem},
  props: {
    reportType: {
      type: Number
    },
    data: {
      type: Array
    },
    useMe: {
      type: Boolean,
      default: true,
    },
    showLabel:{
      type:Boolean,
      default : true ,
    },
    reportName:{
      type:String,
      default:'',
    },
    mailSendTo:{
        type:String,
        default:'',
    }
  },
  setup(props, {emit}) {

    const localState = reactive({
      reportTypeList: ref(ReportTypeList),
      reportList: ref([] as ReportView[]),
      showDialog: ref(false),
      //showBarkodPieceDialog: ref(false),
      //succesBarkodPieceDialog: ref(false),
      selectedReportName: ref(),
      dataFlatRef: computed(() => props.data),
      reportNameRef:computed({
        get : () => props.reportName ,
        set : (val) => emit("update:reportName" , val ),
      })
    });

    async function load() {

    //    localState.dataFlatRef = props.data;
      localState.reportList = await useRaporEkraniServiceExtend().getList(props.reportType as ReportType) as ReportView[];
        //console.log("report Data" , localState.dataFlatRef);
    }
    //
    // watch(() => localState.succesBarkodPieceDialog, (val) => {
    //   if (val) {
    //     const findReport = localState.reportList.find(a => a.name == localState.selectedReportName as string);
    //     if (findReport)
    //       sendToReport(findReport)
    //     else
    //       alert(localState.selectedReportName + " isimli rapor bulunamadı.")
    //   }
    // })

    load()

    function getReportName() {
      if (props.reportType) {
        const report = localState.reportTypeList.find(a => a.value as number === props.reportType as number);
        if (report)
          return report.description;
      }
      return "-";
    }

    async function openReportViewer(report: ReportView) {
      //console.log("report viewver data " , report);
      localState.reportNameRef = report.name as string;
      emit('reportClick', report.name);
      localState.selectedReportName = report.name;
      //console.log("report Data open" , localState.dataFlatRef);
      if (props.useMe == false)
        return;

        await sendToReport(report);

    }

    async function sendToReport(report: ReportView) {
      if (report.directPrint) {
        try {
          const defaultPrintName = await useReportStore().getPrinter(report.id as string)
          const reportBase64 = await useRaporEkraniService().getGetirBase64(report.id as string);
          await useReportStore().print(reportBase64, localState.dataFlatRef, defaultPrintName as string);
        } catch {
          localState.showDialog = true;
        }

      } else {
        localState.showDialog = true;
      }
    }

    return {
      ...toRefs(localState),
      getReportName,
      openReportViewer
    };
  }
});

