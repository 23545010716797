import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CariAdresSelecBox = _resolveComponent("CariAdresSelecBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_MahalleAraSelectBox = _resolveComponent("MahalleAraSelectBox")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NTextarea = _resolveComponent("NTextarea")!
  const _component_NInput = _resolveComponent("NInput")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NFormField, {
      "grid-cols": "0px 1fr",
      label: " "
    }, {
      default: _withCtx(() => [
        (_ctx.model?.ustBilgi?.cari?.id?.length > 0 )
          ? (_openBlock(), _createBlock(_component_CariAdresSelecBox, {
              key: 0,
              onSelectedChanged: _ctx.cariAdresSelectedChanged,
              "cari-id": _ctx.model.ustBilgi.cari.id,
              "onUpdate:cariId": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.ustBilgi.cari.id) = $event))
            }, null, 8, ["onSelectedChanged", "cari-id"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, {
      "grid-cols": "0px 1fr",
      label: " "
    }, {
      default: _withCtx(() => [
        (_ctx.model && _ctx.model.teslimatAdresi)
          ? (_openBlock(), _createBlock(_component_MahalleAraSelectBox, {
              key: 0,
              belde: _ctx.model.teslimatAdresi.belde,
              "onUpdate:belde": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.teslimatAdresi.belde) = $event)),
              mahalle: _ctx.model.teslimatAdresi.mahalle,
              "onUpdate:mahalle": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.teslimatAdresi.mahalle) = $event)),
              pk: _ctx.model.teslimatAdresi.pKodu,
              "onUpdate:pk": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.teslimatAdresi.pKodu) = $event)),
              ilce: _ctx.model.teslimatAdresi.ilce,
              "onUpdate:ilce": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.teslimatAdresi.ilce) = $event)),
              sehir: _ctx.model.teslimatAdresi.sehir,
              "onUpdate:sehir": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.teslimatAdresi.sehir) = $event))
            }, null, 8, ["belde", "mahalle", "pk", "ilce", "sehir"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, {
      "grid-cols": "80px 1fr",
      label: "Şehir"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DxSelectBox, {
          items: _ctx.adresler.map(a=>a.sehir)
                  .filter((value, index, self) => self.indexOf(value) === index),
          "search-timeout": 1,
          searchEnabled: true,
          value: _ctx.model.teslimatAdresi.sehir,
          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.teslimatAdresi.sehir) = $event)),
          "show-clear-button": true,
          acceptCustomValue: true,
          searchMode: "contains"
        }, null, 8, ["items", "value"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, {
      "grid-cols": "80px 1fr",
      label: "İlçe"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DxSelectBox, {
          items: _ctx.adresler.filter(a=>a.sehir == _ctx.model.sehir)
                  .map(a=>a.ilce).filter((value, index, self) => self.indexOf(value) === index),
          "search-timeout": 1,
          searchEnabled: true,
          value: _ctx.model.teslimatAdresi.ilce,
          "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.teslimatAdresi.ilce) = $event)),
          "show-clear-button": true,
          searchMode: "contains",
          acceptCustomValue: true
        }, null, 8, ["items", "value"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, {
      "grid-cols": "80px 1fr",
      label: "Belde"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DxSelectBox, {
          items: _ctx.adresler.filter(a=>a.sehir == _ctx.model.sehir && a.ilce == _ctx.model.ilce)
                  .map(a=>a.beldeAdi).filter((value, index, self) => self.indexOf(value) === index),
          "search-timeout": 1,
          searchEnabled: true,
          value: _ctx.model.teslimatAdresi.belde,
          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.teslimatAdresi.belde) = $event)),
          "show-clear-button": true,
          searchMode: "contains",
          acceptCustomValue: true,
          placeholder: "Semt / Belde Bucak Giriniz"
        }, null, 8, ["items", "value"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, {
      "grid-cols": "80px 1fr",
      label: "Mahalle"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DxSelectBox, {
          items: _ctx.adresler.find(a=>a.sehir == _ctx.model.sehir && a.ilce ==_ctx.model.ilce && a.beldeAdi == _ctx.model.belde)
                  ?.mahalleler,
          "search-timeout": 1,
          searchEnabled: true,
          value: _ctx.model.teslimatAdresi.mahalle,
          "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.teslimatAdresi.mahalle) = $event)),
          "show-clear-button": true,
          searchMode: "contains",
          acceptCustomValue: true,
          placeholder: "Mahalle Giriniz"
        }, null, 8, ["items", "value"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, {
      "grid-cols": "80px 1fr",
      label: "Adres"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NTextarea, {
          modelValue: _ctx.model.teslimatAdresi.adres,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.teslimatAdresi.adres) = $event)),
          rows: "2"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, {
      "grid-cols": "80px 1fr",
      label: "Posta Kodu"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NInput, {
          modelValue: _ctx.model.teslimatAdresi.pKodu,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.teslimatAdresi.pKodu) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, {
      "grid-cols": "80px 1fr",
      label: "Ülke"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NInput, {
          modelValue: _ctx.model.teslimatAdresi.ulke,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.teslimatAdresi.ulke) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, {
      "grid-cols": "80px 1fr",
      label: "Cep"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NInput, {
          modelValue: _ctx.model.teslimatAdresi.cep,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.teslimatAdresi.cep) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, {
      "grid-cols": "80px 1fr",
      label: "Mail"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NInput, {
          modelValue: _ctx.model.teslimatAdresi.mail,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.teslimatAdresi.mail) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, {
      "grid-cols": "80px 1fr",
      label: "Tslm Alan"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NInput, {
          modelValue: _ctx.model.teslimatAdresi.teslimAlan,
          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.teslimatAdresi.teslimAlan) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, {
      "grid-cols": "80px 1fr",
      label: "Tslm. Eden"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NInput, {
          modelValue: _ctx.model.teslimatAdresi.teslimEden,
          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model.teslimatAdresi.teslimEden) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    })
  ], 64))
}