import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CariSelectBox = _resolveComponent("CariSelectBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_ReportListSelectBox = _resolveComponent("ReportListSelectBox")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    modelValue: _ctx.showRef,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showRef) = $event))
  }, {
    header: _withCtx(() => [
      _createTextVNode(" Perakende Ayarları ")
    ]),
    body: _withCtx(() => [
      _createVNode(_component_NLoadingPanel, {
        ref: "asdas",
        title: "Ayarlar Yükleniyor...",
        action: _ctx.load
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NFormField, {
            "grid-cols": "150px 1fr",
            label: "Varsayılan Cari"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CariSelectBox, {
                id: _ctx.perakendeCariId,
                "onUpdate:id": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.perakendeCariId) = $event)),
                "show-bakiye": ""
              }, null, 8, ["id"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "150px 1fr",
            label: "Otamatik Yazdırma"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NSwitch, {
                type: "success",
                modelValue: _ctx.perakendeAutoPrint,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.perakendeAutoPrint) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            "grid-cols": "150px 1fr",
            label: "Varsayılan Yazdırma Şablonu"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ReportListSelectBox, {
                "report-type": _ctx.reportType.Perakende
              }, null, 8, ["report-type"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["action"])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_NButton, {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showRef  = false  )),
        type: "warning",
        icon: "x"
      }, {
        default: _withCtx(() => [
          _createTextVNode("İptal")
        ]),
        _: 1
      }),
      _createVNode(_component_NButton, {
        click: _ctx.save,
        type: "success",
        icon: "save"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Kaydet")
        ]),
        _: 1
      }, 8, ["click"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}