import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_CariSelectBox = _resolveComponent("CariSelectBox")!
  const _component_NFormFieldLabelTop = _resolveComponent("NFormFieldLabelTop")!
  const _component_CariAdresSelecBox = _resolveComponent("CariAdresSelecBox")!
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_MahalleAraSelectBox = _resolveComponent("MahalleAraSelectBox")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_NGrid = _resolveComponent("NGrid")!

  return (_openBlock(), _createBlock(_component_NGrid, {
    cols: _ctx.fakliTeslimatAdresiRef ? '1fr 1fr' :   '1fr'
  }, {
    default: _withCtx(() => [
      _createVNode(_component_NFormGrid, {
        cols: "1fr 1fr",
        gap: "0",
        padding: "5px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NTag, {
            style: {"grid-column":"span 2"},
            type: "success",
            filled: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode("Cari Bilgileri ve Fatura Adresi")
            ]),
            _: 1
          }),
          _createVNode(_component_NFormFieldLabelTop, {
            label: "Cari Seçiniz , veya aşağıdaki bilgileri doldurunuz",
            "grid-cols": "1fr",
            span: "2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CariSelectBox, {
                id: _ctx.model.cariId,
                "onUpdate:id": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.cariId) = $event)),
                "get-unvan": _ctx.model.cariUnvan,
                "onUpdate:getUnvan": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.cariUnvan) = $event)),
                "show-bakiye": ""
              }, null, 8, ["id", "get-unvan"])
            ]),
            _: 1
          }),
          (_ctx.model && _ctx.model.cariId && _ctx.model.cariId.length > 0 )
            ? (_openBlock(), _createBlock(_component_NFormFieldLabelTop, {
                key: 0,
                label: "Cari Adresleri",
                span: "2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CariAdresSelecBox, {
                    onSelectedChanged: _ctx.selectedChangedFaturaAdres,
                    "cari-id": _ctx.model.cariId,
                    "onUpdate:cariId": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.cariId) = $event))
                  }, null, 8, ["onSelectedChanged", "cari-id"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_NFormFieldLabelTop, {
            label: "Ünvan",
            "grid-cols": "1fr"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTextBox, {
                value: _ctx.model.cariUnvan,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.cariUnvan) = $event)),
                "show-clear-button": true
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormFieldLabelTop, {
            label: "Vkn / Tc",
            "grid-cols": "1fr"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTextBox, {
                value: _ctx.model.cariVknTc,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.cariVknTc) = $event)),
                mask: "###########"
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormFieldLabelTop, {
            label: "Ad",
            "grid-cols": "1fr"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTextBox, {
                value: _ctx.model.cariAd,
                "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.cariAd) = $event)),
                "show-clear-button": true
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormFieldLabelTop, {
            label: "Soyad",
            "grid-cols": "1fr"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTextBox, {
                value: _ctx.model.cariSoyad,
                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.cariSoyad) = $event)),
                "show-clear-button": true
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormFieldLabelTop, {
            span: "2",
            label: "Hızlıca Mahalle,Şehir,İlçe Araması Yapabilirsiniz"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_MahalleAraSelectBox, {
                style: {"grid-column":"span 2"},
                belde: _ctx.model.faturaAdres.belde,
                "onUpdate:belde": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.faturaAdres.belde) = $event)),
                ilce: _ctx.model.faturaAdres.ilce,
                "onUpdate:ilce": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.faturaAdres.ilce) = $event)),
                mahalle: _ctx.model.faturaAdres.mahalle,
                "onUpdate:mahalle": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.faturaAdres.mahalle) = $event)),
                pk: _ctx.model.faturaAdres.pKodu,
                "onUpdate:pk": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.faturaAdres.pKodu) = $event)),
                sehir: _ctx.model.faturaAdres.sehir,
                "onUpdate:sehir": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.faturaAdres.sehir) = $event))
              }, null, 8, ["belde", "ilce", "mahalle", "pk", "sehir"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormFieldLabelTop, { label: "Şehir" }, {
            default: _withCtx(() => [
              _createVNode(_component_DxSelectBox, {
                items: _ctx.adresler.map(a=>a.sehir)
                  .filter((value, index, self) => self.indexOf(value) === index),
                "search-timeout": 1,
                searchEnabled: true,
                value: _ctx.model.faturaAdres.sehir,
                "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.faturaAdres.sehir) = $event)),
                "show-clear-button": true,
                acceptCustomValue: false,
                searchMode: "contains",
                placeholder: "ŞEHİR SEÇİNİZ"
              }, null, 8, ["items", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormFieldLabelTop, { label: "İlçe" }, {
            default: _withCtx(() => [
              _createVNode(_component_DxSelectBox, {
                items: _ctx.adresler.filter(a=> a.sehir === _ctx.model.faturaAdres.sehir)
                  .map(a=>a.ilce).filter((value, index, self) => self.indexOf(value) === index),
                "search-timeout": 1,
                searchEnabled: true,
                value: _ctx.model.faturaAdres.ilce,
                "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.faturaAdres.ilce) = $event)),
                "show-clear-button": true,
                searchMode: "contains",
                acceptCustomValue: false,
                placeholder: "İLÇE SEÇİNİZ"
              }, null, 8, ["items", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormFieldLabelTop, { label: "Belde" }, {
            default: _withCtx(() => [
              _createVNode(_component_DxSelectBox, {
                items: _ctx.adresler.filter(a=> a.sehir === _ctx.model.faturaAdres.sehir && a.ilce == _ctx.model.faturaAdres.ilce)
                  .map(a=>a.beldeAdi).filter((value, index, self) => self.indexOf(value) === index),
                "search-timeout": 1,
                searchEnabled: true,
                value: _ctx.model.faturaAdres.belde,
                "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.faturaAdres.belde) = $event)),
                "show-clear-button": true,
                searchMode: "contains",
                acceptCustomValue: true,
                placeholder: "Semt / Belde Bucak Giriniz"
              }, null, 8, ["items", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormFieldLabelTop, { label: "Mahalle" }, {
            default: _withCtx(() => [
              _createVNode(_component_DxSelectBox, {
                items: _ctx.adresler.find(a=> a.sehir == _ctx.model.faturaAdres.sehir && a.ilce ==_ctx.model.faturaAdres.ilce && a.beldeAdi == _ctx.model.faturaAdres.belde)
                  ?.mahalleler,
                "search-timeout": 1,
                searchEnabled: true,
                value: _ctx.model.faturaAdres.mahalle,
                "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.faturaAdres.mahalle) = $event)),
                "show-clear-button": true,
                searchMode: "contains",
                acceptCustomValue: true,
                placeholder: "Mahalle Giriniz"
              }, null, 8, ["items", "value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormFieldLabelTop, {
            label: "Adres",
            span: "2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTextBox, {
                value: _ctx.model.faturaAdres.adres,
                "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model.faturaAdres.adres) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormFieldLabelTop, { label: "Tel" }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTextBox, {
                value: _ctx.model.faturaAdres.tel,
                "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model.faturaAdres.tel) = $event)),
                mask: _ctx.isAllNumber(_ctx.model.faturaAdres.tel) ? '(000) 000 00 00' : ''
              }, null, 8, ["value", "mask"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormFieldLabelTop, { label: "Mail" }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTextBox, {
                value: _ctx.model.faturaAdres.mail,
                "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.model.faturaAdres.mail) = $event))
              }, null, 8, ["value"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            label: "Teslimat Adresi Farklı",
            "grid-cols": "1fr auto",
            span: "2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NSwitch, {
                modelValue: _ctx.fakliTeslimatAdresiRef,
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.fakliTeslimatAdresiRef) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.fakliTeslimatAdresiRef && _ctx.model && _ctx.model.teslimatlar && _ctx.model.teslimatlar[0])
        ? (_openBlock(), _createBlock(_component_NFormGrid, {
            key: 0,
            cols: "1fr 1fr",
            gap: "0",
            padding: "5px"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NTag, {
                style: {"grid-column":"span 2"},
                type: "warning",
                filled: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Teslimat Bilgileri")
                ]),
                _: 1
              }),
              (_ctx.model && _ctx.model.cariId && _ctx.model.cariId.length > 0 )
                ? (_openBlock(), _createBlock(_component_NFormFieldLabelTop, {
                    key: 0,
                    label: "Cari Adresleri",
                    span: "2"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_CariAdresSelecBox, {
                        onSelectedChanged: _ctx.selectedChangedTeslimatAdres,
                        "cari-id": _ctx.model.cariId,
                        "onUpdate:cariId": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.model.cariId) = $event))
                      }, null, 8, ["onSelectedChanged", "cari-id"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_NFormFieldLabelTop, {
                span: "2",
                label: "Hızlıca Mahalle,Şehir,İlçe Araması Yapabilirsiniz"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_MahalleAraSelectBox, {
                    style: {"grid-column":"span 2"},
                    belde: _ctx.model.teslimatlar[0].belde,
                    "onUpdate:belde": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.model.teslimatlar[0].belde) = $event)),
                    ilce: _ctx.model.teslimatlar[0].ilce,
                    "onUpdate:ilce": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.model.teslimatlar[0].ilce) = $event)),
                    mahalle: _ctx.model.teslimatlar[0].mahalle,
                    "onUpdate:mahalle": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.model.teslimatlar[0].mahalle) = $event)),
                    pk: _ctx.model.teslimatlar[0].pKodu,
                    "onUpdate:pk": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.model.teslimatlar[0].pKodu) = $event)),
                    sehir: _ctx.model.teslimatlar[0].sehir,
                    "onUpdate:sehir": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.model.teslimatlar[0].sehir) = $event))
                  }, null, 8, ["belde", "ilce", "mahalle", "pk", "sehir"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormFieldLabelTop, { label: "Şehir" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxSelectBox, {
                    items: _ctx.adresler.map(a=>a.sehir)
                  .filter((value, index, self) => self.indexOf(value) === index),
                    "search-timeout": 1,
                    searchEnabled: true,
                    value: _ctx.model.teslimatlar[0].sehir,
                    "onUpdate:value": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.model.teslimatlar[0].sehir) = $event)),
                    "show-clear-button": true,
                    acceptCustomValue: false,
                    searchMode: "contains",
                    placeholder: "ŞEHİR SEÇİNİZ"
                  }, null, 8, ["items", "value"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormFieldLabelTop, { label: "İlçe" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxSelectBox, {
                    items: _ctx.adresler.filter(a=> a.sehir === _ctx.model.faturaAdres.sehir)
                  .map(a=>a.ilce).filter((value, index, self) => self.indexOf(value) === index),
                    "search-timeout": 1,
                    searchEnabled: true,
                    value: _ctx.model.teslimatlar[0].ilce,
                    "onUpdate:value": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.model.teslimatlar[0].ilce) = $event)),
                    "show-clear-button": true,
                    searchMode: "contains",
                    acceptCustomValue: false,
                    placeholder: "İLÇE SEÇİNİZ"
                  }, null, 8, ["items", "value"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormFieldLabelTop, { label: "Belde" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxSelectBox, {
                    items: _ctx.adresler.filter(a=> a.sehir === _ctx.model.faturaAdres.sehir && a.ilce == _ctx.model.faturaAdres.ilce)
                  .map(a=>a.beldeAdi).filter((value, index, self) => self.indexOf(value) === index),
                    "search-timeout": 1,
                    searchEnabled: true,
                    value: _ctx.model.teslimatlar[0].belde,
                    "onUpdate:value": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.model.teslimatlar[0].belde) = $event)),
                    "show-clear-button": true,
                    searchMode: "contains",
                    acceptCustomValue: true,
                    placeholder: "Semt / Belde Bucak Giriniz"
                  }, null, 8, ["items", "value"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormFieldLabelTop, { label: "Mahalle" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxSelectBox, {
                    items: _ctx.adresler.find(a=> a.sehir == _ctx.model.faturaAdres.sehir && a.ilce ==_ctx.model.faturaAdres.ilce && a.beldeAdi == _ctx.model.faturaAdres.belde)
                  ?.mahalleler,
                    "search-timeout": 1,
                    searchEnabled: true,
                    value: _ctx.model.teslimatlar[0].mahalle,
                    "onUpdate:value": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.model.teslimatlar[0].mahalle) = $event)),
                    "show-clear-button": true,
                    searchMode: "contains",
                    acceptCustomValue: true,
                    placeholder: "Mahalle Giriniz"
                  }, null, 8, ["items", "value"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormFieldLabelTop, {
                label: "Adres",
                span: "2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxTextBox, {
                    value: _ctx.model.teslimatlar[0].adres,
                    "onUpdate:value": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.model.teslimatlar[0].adres) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormFieldLabelTop, { label: "Tel" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxTextBox, {
                    value: _ctx.model.teslimatlar[0].tel,
                    "onUpdate:value": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.model.teslimatlar[0].tel) = $event)),
                    mask: _ctx.isAllNumber(_ctx.model.teslimatlar[0].tel) ? '(000) 000 00 00' : ''
                  }, null, 8, ["value", "mask"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormFieldLabelTop, { label: "Mail" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxTextBox, {
                    value: _ctx.model.teslimatlar[0].mail,
                    "onUpdate:value": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.model.teslimatlar[0].mail) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormFieldLabelTop, {
                label: "Teslim Alan",
                span: "2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxTextBox, {
                    value: _ctx.model.teslimatlar[0].teslimAlan,
                    "onUpdate:value": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.model.teslimatlar[0].teslimAlan) = $event))
                  }, null, 8, ["value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["cols"]))
}