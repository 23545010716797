
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import { useHepsiBuradaService } from "@/api/services/HepsiBuradaService";
import { CategoryAttributeModel } from "@/api/models/CategoryAttributeModel";
import HepsiBuradaOzellikSelectBox from "@/modules/Pazaryerleri/HepsiBurada/components/HepsiBuradaOzellikSelectBox.vue";
import { NameValue } from "@/api/models/NameValue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";


export default defineComponent({
  components: { NFormGrid, HepsiBuradaOzellikSelectBox, NLoadingPanel },
  props: {
    ozellikler:{
      type:Array
    },
    kategoriId:{
      type:Number
    },
    cols:{
      type:String,
      default:'1fr 1fr 1fr'
    },
    stok:{
      type:Object
    }
  },
  setup(props,{emit}) {

    const localState = reactive({
      loadingPanel:ref(),
      nesilceOzellikler:computed({
        get:()=> props.ozellikler as NameValue[],
        set:(val)=> {
          emit('update:ozellikler',val)
        }
      }),
      attributes:ref([] as CategoryAttributeModel),
      text:ref('REF')
    });

    watch(()=> props.kategoriId,(kategoriId)=>{
      localState.nesilceOzellikler=[];
      localState.attributes.attributes = [];
      localState.attributes.baseAttributes = [];
      localState.attributes.variantAttributes = [];

      localState.loadingPanel.load();
      hepsiBuradaToNesilce();
    })


    async function load(){
      localState.attributes = await useHepsiBuradaService().getKategoriOzellikleri(365,props.kategoriId as number);
      //hepsiBuradaToNesilce();
      nesilceToHepsiBurada()
    }

    // StokEntity.HepsiBurada.Ozellikler >>> HepsiBurada.
    function nesilceToHepsiBurada(){
      // console.log("localState.nesilceOzellikler",localState.nesilceOzellikler)
      // console.log("localState.attibutes",localState.attributes?.attributes)
      if (localState.nesilceOzellikler && localState.attributes?.attributes)
      {
        localState.nesilceOzellikler.forEach(ozellikNesilce=>{

            const hepsiBuradaAttribute = localState.attributes?.attributes?.find(a => a.name == ozellikNesilce.name) as any;

            if (hepsiBuradaAttribute){
              hepsiBuradaAttribute.value = ozellikNesilce.value;
            }
        })
      }

      if (localState.nesilceOzellikler && localState.attributes?.variantAttributes)
      {
        localState.nesilceOzellikler.forEach(ozellik=>{

          const hepsiBuradaOzellik = localState.attributes?.variantAttributes?.find(a => a.name == ozellik.name) as any;
          if (hepsiBuradaOzellik){

            hepsiBuradaOzellik.value = ozellik.value;
          }
        })
      }
    }


    function hepsiBuradaToNesilce(){
      const nesilceOzellikler = [] as NameValue[];


      if (localState.attributes && localState.attributes.attributes){
        localState.attributes.attributes.forEach((attribute:any)=>{
          if (attribute.value) {
            nesilceOzellikler.push({
              id:attribute.id,
              name:attribute.name,
              value: attribute.value,
            })
          }
        })

      }

      if (localState.attributes && localState.attributes.variantAttributes){

        localState.attributes.variantAttributes.forEach((attribute:any)=>{
         // if (attribute.value){
            nesilceOzellikler.push({
              id:attribute.id,
              name:attribute.name,
              value:attribute.value,
            })
         // }
        })
      }
      localState.nesilceOzellikler = nesilceOzellikler;
    }

    return {
      ...toRefs(localState),
      load,
      hepsiBuradaToNesilce
    };
  }
});

