import { NModuleRoute } from "@/models/route.model";

export const KartlarModuleRoutes: NModuleRoute[] = [
  {
    id: "KartlarModule",
    order: 6,
    displayName: "Kartlar",
    icon: {
      type: "grid"
    },
    children: [
      {
      id: "Sube",
      order: 0,
      displayName: "Sube",
      requiredRoles:{
        readRoles:['Sube.Liste'],
        insertRoles:['Sube.Ekle'],
        updateRoles:['Sube.Guncelle'],
        deleteRoles:['Sube.Sil']
      },
      icon: {
        type: "list"
      },
      component: () =>
        import(/* webpackChunkName: "Sube" */ "../../Sube/pages/SubePage.vue")
    },
      {
        id: "Depo",
        order: 0,
        displayName: "Depo",
        requiredRoles:{
          readRoles:['Depo.Liste'],
          insertRoles:['Depo.Ekle'],
          updateRoles:['Depo.Guncelle'],
          deleteRoles:['Depo.Sil']
        },
        icon: {
          type: "list"
        },
        component: () =>
          import(/* webpackChunkName: "Sube" */ "../../Depo/pages/DepoPage.vue")
      },
      {
        id: "Plasiyer",
        order: 0,
        displayName: "Plasiyer",
        requiredRoles:{
          readRoles:['Plasiyer.Liste'],
          insertRoles:['Plasiyer.Ekle'],
          updateRoles:['Plasiyer.Guncelle'],
          deleteRoles:['Plasiyer.Sil']
        },
        icon: {
          type: "users"
        },
        component: () =>
          import(
            /* webpackChunkName: "Plasiyer"*/ "../../Plasiyer/pages/PlasiyerPage.vue"
          )
      },
      {
        id: "Proje",
        order: 0,
        displayName: "Proje",
        requiredRoles:{
          readRoles:['Proje.Liste'],
          insertRoles:['Proje.Ekle'],
          updateRoles:['Proje.Guncelle'],
          deleteRoles:['Proje.Sil']
        },
        icon: {
          type: "slack"
        },
        component: () =>
          import(
            /* webpackChunkName: "Islemler_kasa" */ "../../Proje/pages/ProjePage.vue"
          )
      },
      {
        id: "Departman",
        order: 0,
        displayName: "Departman",
        requiredRoles:{
          readRoles:['Departman.Liste'],
          insertRoles:['Departman.Ekle'],
          updateRoles:['Departman.Guncelle'],
          deleteRoles:['Departman.Sil']
        },
        icon: {
          type: "list"
        },
        component: () =>
          import(
            /* webpackChunkName: "Islemler_banka" */ "../../Departman/pages/DepartmanPage.vue"
          )
      },

      {
        id: "Demirbas",
        order: 0,
        displayName: "Demirbaş",
        requiredRoles:{
          readRoles:['Demirbas.Liste'],
          insertRoles:['Demirbas.Ekle'],
          updateRoles:['Demirbas.Guncelle'],
          deleteRoles:['Demirbas.Sil']
        },
        icon: {
          type: "list"
        },
        component: () =>
          import(
            /* webpackChunkName: "Demirbas" */ "../../Demirbas/pages/DemirbasPage.vue"
          )
      },
      {
        id: "Kredi",
        order: 0,
        displayName: "Kredi",
        requiredRoles:{
          readRoles:['Kredi.Liste'],
          insertRoles:['Kredi.Ekle'],
          updateRoles:['Kredi.Guncelle'],
          deleteRoles:['Kredi.Sil']
        },
        icon: {
          type: "list"
        },
        component: () =>
          import(
            /* webpackChunkName: "Kredi" */ "../../Kredi/pages/KrediPage.vue"
          )
      }
    ]
  }
];
