
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import { TaksitPlanEntity } from "@/api/models/TaksitPlanEntity";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import NFormFieldLabelTop from "@/components/FormField/NFormFieldLabelTop.vue";
import NTextarea from "@/components/Textarea/NTextarea.vue";
import { TaksitPlanNotEklemeView } from "@/api/models/TaksitPlanNotEklemeView";
import { useTaksitPlanService } from "@/api/services/TaksitPlanService";

export default defineComponent({
  components: { NTextarea, NFormFieldLabelTop, NModalDialog },
  props: {

    modelValue: {
      type: Object
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val)
      }),
      model: computed(() => props.modelValue as TaksitPlanEntity)
    });

    async function save() {
      const view = {} as TaksitPlanNotEklemeView;
      view.taksitId = localState.model.id ;
      view.not = localState.model.not as string;
      view.vade = localState.model.vade;

      await  useTaksitPlanService().postNotEkleme(view );
      emit("saveRefresh");
      localState.showRef = false ;
    }

    return {
      ...toRefs(localState),
      save
    };
  }
});
