
import { useLayoutStore } from "@/layouts/ApplicationLayout/context";
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "NViewToolbar",
  setup() {
    const layout = useLayoutStore();
    const tab = computed(() => layout.selectedTab);

    return {
      tab
    };
  }
});
