import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_StokKategoriOzellikItem = _resolveComponent("StokKategoriOzellikItem")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_OzellikForm = _resolveComponent("OzellikForm")!
  const _component_OzellikFormReadOnly = _resolveComponent("OzellikFormReadOnly")!
  const _component_StokKategoriPazaryerleri = _resolveComponent("StokKategoriPazaryerleri")!
  const _component_StokKategori_Birimler = _resolveComponent("StokKategori_Birimler")!
  const _component_StokKategori_SubeFiyatTab = _resolveComponent("StokKategori_SubeFiyatTab")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_ctx.model && _ctx.model.ozellik)
    ? (_openBlock(), _createBlock(_component_NModalDialog, {
        key: 0,
        "close-on-esc": "false",
        modelValue: _ctx.showForm,
        "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.showForm) = $event)),
        tabs: _ctx.tabs,
        width: "900px",
        "selected-tab": _ctx.selectedTab,
        "onUpdate:selectedTab": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.selectedTab) = $event))
      }, {
        header: _withCtx(() => [
          _createTextVNode("Kategori Formu")
        ]),
        body: _withCtx(() => [
          _withDirectives(_createVNode(_component_NFormGrid, { cols: "1.3fr 1fr 1fr" }, {
            default: _withCtx(() => [
              _createVNode(_component_NFormField, {
                label: "Üst Kategori",
                "grid-cols": "120px 1fr",
                span: "3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxSelectBox, {
                    items: _ctx.kategoriler,
                    displayExpr: "name",
                    valueExpr: "id",
                    value: _ctx.model.parentId,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.parentId) = $event))
                  }, null, 8, ["items", "value"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                label: "Kategori Adı",
                "grid-cols": "120px 1fr",
                span: "2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.model.name,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.name) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                label: "Kategori Kodu",
                "grid-cols": "100px 1fr",
                span: "1"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.model.kodOnEk,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.kodOnEk) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              (_ctx.model.ozellik)
                ? (_openBlock(), _createBlock(_component_StokKategoriOzellikItem, {
                    key: 0,
                    ozellik: _ctx.model.ozellik,
                    ozellikDevir: _ctx.ozellikDevir
                  }, null, 8, ["ozellik", "ozellikDevir"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.selectedTab === 0]
          ]),
          _withDirectives(_createVNode(_component_OzellikForm, {
            "is-stok": false,
            ozellikler: _ctx.model.ozellik.ozellikler,
            "onUpdate:ozellikler": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.ozellik.ozellikler) = $event))
          }, null, 8, ["ozellikler"]), [
            [_vShow, _ctx.selectedTab === 1]
          ]),
          _withDirectives(_createVNode(_component_OzellikFormReadOnly, {
            title: "Özellikler (Üst Kategoriden Gelenler)",
            ozellikler: _ctx.ozellikDevir.ozellikler,
            "onUpdate:ozellikler": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.ozellikDevir.ozellikler) = $event))
          }, null, 8, ["ozellikler"]), [
            [_vShow, _ctx.selectedTab === 1]
          ]),
          _withDirectives(_createVNode(_component_NFormGrid, {
            title: "Stok Tipi",
            cols: "1fr 1fr",
            style: {"grid-column":"span 3","gap":"0","min-height":"50px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NFormField, { label: "Stok Tipi" }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxSelectBox, {
                    items: _ctx.tipList,
                    displayExpr: "description",
                    valueExpr: "value",
                    value: _ctx.model.ozellik.tip,
                    "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.ozellik.tip) = $event))
                  }, null, 8, ["items", "value"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.selectedTab === 1]
          ]),
          _withDirectives(_createVNode(_component_OzellikForm, {
            "is-stok": false,
            ozellikler: _ctx.model.ozellik.varyantlar,
            "onUpdate:ozellikler": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.ozellik.varyantlar) = $event)),
            title: "Varyant Özellikleri"
          }, null, 8, ["ozellikler"]), [
            [_vShow, _ctx.selectedTab === 2]
          ]),
          _withDirectives(_createVNode(_component_OzellikFormReadOnly, {
            title: "Varyant Özellikleri (Üst Kategoriden Gelenler)",
            ozellikler: _ctx.ozellikDevir.varyantlar,
            "onUpdate:ozellikler": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.ozellikDevir.varyantlar) = $event))
          }, null, 8, ["ozellikler"]), [
            [_vShow, _ctx.selectedTab === 2]
          ]),
          _withDirectives(_createVNode(_component_StokKategoriPazaryerleri, { kategori: _ctx.model }, null, 8, ["kategori"]), [
            [_vShow, _ctx.selectedTab === 3]
          ]),
          _withDirectives(_createVNode(_component_NFormGrid, { cols: "1fr" }, {
            default: _withCtx(() => [
              _createVNode(_component_NFormField, { label: "Seo Etiketleri" }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.model.ozellik.seoTags,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.ozellik.seoTags) = $event)),
                    placeholder: _ctx.ozellikDevir.seoTags
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, { label: "Seo Açıklaması" }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.model.ozellik.seoAciklama,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.ozellik.seoAciklama) = $event)),
                    placeholder: _ctx.ozellikDevir.seoAciklama
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.selectedTab === 4]
          ]),
          _withDirectives(_createVNode(_component_StokKategori_Birimler, {
            modelValue: _ctx.model.ozellik.birimler,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.ozellik.birimler) = $event)),
            birimlerDevir: _ctx.ozellikDevir.birimler,
            "onUpdate:birimlerDevir": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.ozellikDevir.birimler) = $event))
          }, null, 8, ["modelValue", "birimlerDevir"]), [
            [_vShow, _ctx.selectedTab === 5]
          ]),
          (_ctx.selectedTab === 6)
            ? (_openBlock(), _createBlock(_component_StokKategori_SubeFiyatTab, {
                key: 0,
                ozellik: _ctx.model.ozellik,
                "onUpdate:ozellik": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.ozellik) = $event)),
                "ozellik-devir": _ctx.ozellikDevir,
                "onUpdate:ozellikDevir": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.ozellikDevir) = $event))
              }, null, 8, ["ozellik", "ozellik-devir"]))
            : _createCommentVNode("", true)
        ]),
        actions: _withCtx(() => [
          (_ctx.model.id && _ctx.model.id.length > 0)
            ? (_openBlock(), _createBlock(_component_NButton, {
                key: 0,
                type: "danger",
                click: _ctx.remove,
                icon: "trash"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Sil")
                ]),
                _: 1
              }, 8, ["click"]))
            : _createCommentVNode("", true),
          _createVNode(_component_NButton, {
            type: "warning",
            icon: "x-circle",
            onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.showForm = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("İptal")
            ]),
            _: 1
          }),
          _createVNode(_component_NButton, {
            type: "success",
            icon: "save",
            click: _ctx.save
          }, {
            default: _withCtx(() => [
              _createTextVNode("Kaydet")
            ]),
            _: 1
          }, 8, ["click"])
        ]),
        _: 1
      }, 8, ["modelValue", "tabs", "selected-tab"]))
    : _createCommentVNode("", true)
}