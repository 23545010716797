
export enum StokTip{
TicariMal=0,
Mamul=1,
Hammadde=2,
Aluminyum=10,
Cinko=11,
Paslanmaz=12,
Bakir=13,
Bronz=14,
Pirinc=15,
Kereste=16,
Papel=17,
Sac=18,
OzelAluminyum=19,
SacProfil=20
}

export const StokTipList = [
{key:'TicariMal', value:0, description:'Ticari Mal'},
{key:'Mamul', value:1, description:'Mamül'},
{key:'Hammadde', value:2, description:'Hammadde'},
{key:'Aluminyum', value:10, description:'Alüminyum'},
{key:'Cinko', value:11, description:'Çinko'},
{key:'Paslanmaz', value:12, description:'Paslanmaz'},
{key:'Bakir', value:13, description:'Bakır'},
{key:'Bronz', value:14, description:'Bronz'},
{key:'Pirinc', value:15, description:'Pirinç'},
{key:'Kereste', value:16, description:'Kereste'},
{key:'Papel', value:17, description:'Papel'},
{key:'Sac', value:18, description:'Sac'},
{key:'OzelAluminyum', value:19, description:'Özel Alüminyum'},
{key:'SacProfil', value:20, description:'Sac Profil'}
]
