
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NButton from "@/components/Button/NButton.vue";
import { BirimFiyatGrupView } from "@/api/models/BirimFiyatGrupView";
import { useListelerService } from "@/api/services/ListelerService";
import { Liste } from "@/api/models/Liste";
import { Doviz, DovizList } from "@/api/models/Doviz";
import NTag from "@/components/Tag/NTag.vue";
import { StokView } from "@/api/models/StokView";
import { BirimFiyatTipi, BirimFiyatTipiList } from "@/api/models/BirimFiyatTipi";
import { StokBirimItem } from "@/api/models/StokBirimItem";
import { StokBirimUtils } from "@/modules/Stok/utils/StokBirimUtils";
import NFormField from "@/components/FormField/NFormField.vue";
import { FiyatUtils } from "@/modules/Stok/store/FiyatUtils";
import { KurView } from "@/api/models/KurView";
import { useKurCachedServis } from "@/modules/Ortak/services/useKurCachedServis";
import { PazaryeriFiyatTipi } from "@/api/models/PazaryeriFiyatTipi";
import NAlert from "@/components/Alert/NAlert.vue";
import NDxStateStoring from "@/devextreme/NDxStateStoring.vue";
import { DevExtremeUtils } from "@/devextreme/DevExtremeUtils";

export default defineComponent({
  components: { NDxStateStoring, NGrid, NAlert, NTag, NButton },
  props: {
    modelValue: {
      type: Object
    }
  },
  setup(props) {
    const localState = reactive({
      model: computed(() => props.modelValue as StokView),
      stokBirimlerDataGrid: ref(),
      gruplar: ref([] as BirimFiyatGrupView[]),
      birimler: ref([] as Liste[]),
      dovizList: DovizList,
      fiyatTipList: BirimFiyatTipiList,
      kurList: ref([] as KurView[])
    });

    function addBirim() {
      if (localState.model) {
        if (!localState.model.birimFiyatlari)
          localState.model.birimFiyatlari = [];

        localState.model.birimFiyatlari?.push({
          grup: localState.gruplar.find(() => true)?.grubAdi,
          birim: "Koli",
          fiyat: getKdvliFiyat(localState.model.satisFiyat as number, localState.model.satisKdv as number, localState.model.satisKdvDahil as boolean) as number,
          carpan: 1,
          barkod: Date.now().toString(),
          fiyatTipi: BirimFiyatTipi.SabitFiyat
        });
        localState.stokBirimlerDataGrid?.instance?.refresh();
      }
    }

    onMounted(async () => {
      localState.gruplar = await useListelerService().getBirimFiyatGrupListe();
      localState.birimler = await useListelerService().getBirimListesi();
      localState.kurList = await useKurCachedServis().getKurList();
      localState.gruplar.push({ grubAdi: "" });
      localState.stokBirimlerDataGrid?.instance?.refresh();
    });

    function removeBirim(index: number) {
      if (localState.model && localState.model.birimFiyatlari)
        localState.model.birimFiyatlari?.splice(index, 1);
    }

    function getFiyat(row: StokBirimItem) {
      const model = localState.model;
      const eTicaretFiyati = getEticaretFiyati();

      return StokBirimUtils.getFiyat(
        row.fiyat as number,
        row.fiyatTipi as BirimFiyatTipi,
        model.satisFiyat as number,
        model.satisKdv as number,
        model.satisKdvDahil as boolean,
        model.alisFiyat as number,
        model.alisKdv as number,
        model.alisKdvDahil as boolean, eTicaretFiyati) as number * (row.carpan as number);
    }

    function getFiyatKdvsiz(row: StokBirimItem) {
      const fiyat = getFiyat(row);
      return fiyat / (1 + ((localState.model.satisKdv as number) / 100));
    }

    function getAlisFiyatKdvli(row: StokBirimItem) {
      return getKdvliFiyat(localState.model.alisFiyat as number * (row.carpan as number), localState.model.alisKdv as number, localState.model.alisKdvDahil as boolean);
    }

    function getAlisFiyatKdvsiz(row: StokBirimItem) {
      return getKdvliFiyat(localState.model.alisFiyat as number * (row.carpan as number), localState.model.alisKdv as number, localState.model.alisKdvDahil as boolean) / ((localState.model.alisKdv as number + 100) / 100);
    }


    function getKdvliFiyat(fiyat: number, kdv: number, kdvDahil: boolean) {
      if (kdvDahil) {
        return fiyat;
      } else {
        return fiyat * ((kdv + 100) / 100);
      }
    }


    function getFiyatTagType(row: StokBirimItem) {
      if (row.fiyatTipi == BirimFiyatTipi.SabitFiyat)
        return "info";
      else if (row.fiyatTipi == BirimFiyatTipi.AlisOranEkle || row.fiyatTipi == BirimFiyatTipi.AlisTutarEkle)
        return "danger";
      else if (row.fiyatTipi == BirimFiyatTipi.ETicaretOranEkle || row.fiyatTipi == BirimFiyatTipi.ETicaretTutarEkle)
        return "warning";
      else
        return "success";

    }

    function getEticaretFiyati() {
      const stok = props.modelValue as StokView;
      if (stok.presta && stok.presta.length > 0) {
        const presta = stok.presta[0];

        if (presta && presta.fiyat) {

          const fiyatItem = presta.fiyat;
          const fiyat = FiyatUtils.getPazaryeriFiyat(
            localState.kurList,
            fiyatItem.tip as PazaryeriFiyatTipi,
            fiyatItem.deger as number,

            stok.satisDoviz as Doviz,
            stok.satisFiyat as number,
            stok.satisKdv as number,
            stok.satisKdvDahil as boolean,

            stok.alisDoviz as Doviz,
            stok.alisFiyat as number,
            stok.alisKdv as number,
            stok.alisKdvDahil as boolean,

            stok.getMaliyet as number,

            presta.karMarji as number,
            presta.kargoTutar as number,
            presta.komisyon as number);

          return fiyat;

        }
      }
      return 0;
    }

    let editingDataField = "";
    let editingData = {} as any;

    function editingStart(e: any) {
      editingDataField = e.column.dataField;
      editingData = JSON.parse(JSON.stringify(e.data));

      //alert(editingSatisFiyati)
      // console.log('editingStart',e)
    }

    function rowUpdated(e: any) {
      if (editingDataField == "satisFiyati") {

        // const updatedSatisFiyati = e.data.satisFiyati;
        // const editingSatisFiyati = editingData.satisFiyati;

        const birimFiyati = e.data.satisFiyati / e.data.carpan;


        switch (e.data.fiyatTipi) {
          case BirimFiyatTipi.SabitFiyat:
            e.data.fiyat = birimFiyati;
            break;
          case BirimFiyatTipi.ETicaretTutarEkle:
            e.data.fiyat = birimFiyati - getEticaretFiyati();
            break;
          case BirimFiyatTipi.SatisTutarEkle:
            e.data.fiyat = birimFiyati - getKdvliFiyat(localState.model.satisFiyat as number, localState.model.satisKdv as number, localState.model.satisKdvDahil as boolean);
            break;
          case BirimFiyatTipi.AlisTutarEkle:
            e.data.fiyat = birimFiyati - getKdvliFiyat(localState.model.alisFiyat as number, localState.model.alisKdv as number, localState.model.alisKdvDahil as boolean);
            break;
          case BirimFiyatTipi.ETicaretOranEkle: {
            const satisFiyati = getEticaretFiyati();
            e.data.fiyat = ((birimFiyati - satisFiyati) * 100) / satisFiyati;
            //
          }
            break;
          case BirimFiyatTipi.SatisOranEkle: {
            const satisFiyati = getKdvliFiyat(localState.model.satisFiyat as number, localState.model.satisKdv as number, localState.model.satisKdvDahil as boolean);
            e.data.fiyat = ((birimFiyati - satisFiyati) * 100) / satisFiyati;
            //
          }
            break;
          case BirimFiyatTipi.AlisOranEkle: {
            const satisFiyati = getKdvliFiyat(localState.model.alisFiyat as number, localState.model.alisKdv as number, localState.model.alisKdvDahil as boolean);
            e.data.fiyat = ((birimFiyati - satisFiyati) * 100) / satisFiyati;
            //
          }
            break;
        }
      }

    }

    function rowUpdating(e: any) {
      // console.log('rowUpdating',e)
    }

    async function loadState() {
      return await DevExtremeUtils.loadState(localState.stokBirimlerDataGrid?.instance);
    }

    return {
      ...toRefs(localState),
      addBirim,
      removeBirim,
      getFiyat,
      getFiyatKdvsiz,
      getFiyatTagType,
      getAlisFiyatKdvli,
      getAlisFiyatKdvsiz,
      getEticaretFiyati,
      editingStart,
      rowUpdated,
      rowUpdating,
      loadState
    };
  }
});
