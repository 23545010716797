import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {CariOzellikItem} from '../models/CariOzellikItem'
import {CariKategoriView} from '../models/CariKategoriView'

export const useCariKategoriService = defineStore({
id: 'CariKategoriService',
actions: {


	async getDefaultNew(parentId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cariKategori/DefaultNew/${shared.firm.id}/${parentId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getDefaultUpdate(kategoriId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cariKategori/DefaultUpdate/${shared.firm.id}/${kategoriId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetir(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cariKategori/Getir/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cariKategori/List/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getFlatListView()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cariKategori/FlatListView/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getTreeList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cariKategori/TreeList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getFlatList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cariKategori/FlatList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postEkle(cariKategoriView:CariKategoriView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cariKategori/Ekle/${shared.firm.id}`,{ method:'POST', data:cariKategoriView });
		await request();
		return response.value;
	},

	async putGuncelle(cariKategoriView:CariKategoriView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cariKategori/Guncelle/${shared.firm.id}`,{ method:'PUT', data:cariKategoriView });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cariKategori/Sil/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getGetAllKategoriKod(kategoriId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/cariKategori/GetAllKategoriKod/${shared.firm.id}/${kategoriId}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
