import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {DataSourceLoadOptionsItem} from '../models/DataSourceLoadOptionsItem'
import {IdName} from '../models/IdName'
import {OrderItemAttribute} from '../models/OrderItemAttribute'
import {ShipmentInfo} from '../models/ShipmentInfo'
import {OrderDetailsItem} from '../models/OrderDetailsItem'

export const useN11SiparisService = defineStore({
id: 'N11SiparisService',
actions: {


	async getN11SiparisleriListe(baslangic:string,bitis:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/N11Siparisleri/Liste/${shared.firm.id}/${baslangic}/${bitis}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postN11SiparisleriListDataGrid(dataSourceLoadOptionsItem:DataSourceLoadOptionsItem)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/N11Siparisleri/ListDataGrid/${shared.firm.id}`,{ method:'POST', data:dataSourceLoadOptionsItem });
		await request();
		return response.value;
	},

	async getN11SiparisleriGet(orderId:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/N11Siparisleri/Get/${shared.firm.id}/${orderId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getN11SiparisleriReplay(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/N11Siparisleri/Replay/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getN11SiparisleriCheckOrders()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/N11Siparisleri/CheckOrders/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async optionsN11SiparisleriCreateStokView(orderDetailsItem:OrderDetailsItem)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/N11Siparisleri/CreateStokView/${shared.firm.id}`,{ method:'OPTIONS', data:orderDetailsItem });
		await request();
		return response.value;
	},

	async deleteN11SiparisleriSatisFaturaSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/N11Siparisleri/SatisFaturaSil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getN11SiparisleriKampanyaKod(faturaId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/N11Siparisleri/KampanyaKod/${shared.firm.id}/${shared.firm.period}/${faturaId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async putN11SiparisleriUpdatePrintStatus(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/N11Siparisleri/UpdatePrintStatus/${shared.firm.id}/${id}`,{ method:'PUT' });
		await request();
		return response.value;
	}
}
});
