
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import NButton from "@/components/Button/NButton.vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import {usePopupService} from "@/services/popupService";

export default defineComponent({
  components: { NModalDialog, NButton},
  props: {
    modelValue: String,
    showDialog:Boolean,
    fields:Array
  },
  setup(props, { emit }) {


    const localState = reactive({
      editor:ref(),
      model:computed({
        get:()=> props.modelValue,
        set:(val)=> emit('update:modelValue',val)
      }),
      showDialogRef:computed({
        get:()=> props.showDialog,
        set:(val)=> emit('update:showDialog',val)
      }),
      content:ref(''),
      items: [

        {
          widget:"dxMenu",
          options:{
            displayExpr:"name",
            onItemClick:(e:any)=> {
              if (e.itemData.value){
                const selection = localState.editor.instance.getSelection();
                const index = selection?selection.index as number:0;


                localState.editor.instance.insertText(index, "[" + e.itemData.value + "]" )
              }
            },
            // itemTemplate: function(itemData:any, itemIndex:number, itemElement:any) {
            //   itemElement.innerHTML = (itemData.name == "Alan Ekle" ? "<div>" : "<div>") + itemData.name + "</div>" + itemIndex;
            // },
            items:[
              {
                name:"Alan Ekle",
                items: props.fields?.map((a) => ({name:a, value:a}))

              }
            ]
          }

        },
        "separator",
        {
          name: 'header',
          acceptedValues: [false, 1, 2, 3, 4, 5],
        },

        "undo",
        "redo",
        "bold",
        "italic",

        "separator",
        {
          name: "size",
          acceptedValues: ["10px", "12px", "14px", "16px", "18px", "20px", "22px", "32px", "36px", "48px"]
        },
        "separator",
        {
          name: "font",
          acceptedValues: ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'],
        },
        "separator",
        "orderedList",
        "bulletList",
        "alignLeft",
        "alignCenter",
        "alignRight",
        "separator",
        "color",
        "background",
        "separator",
        "link",
        "image",
        "separator",
        "clear",
        "codeBlock",
        "blockquote",
        "separator",
        "insertTable",
        "deleteTable",
        "insertRowAbove",
        "insertRowBelow",
        "deleteRow",
        "insertColumnLeft",
        "insertColumnRight",
        "deleteColumn",
        // {
        //   widget: 'dxButton',
        //   options: {
        //     text: 'Kaynak',
        //     stylingMode: 'text',
        //     onClick() {
        //       navigator.clipboard.writeText(localState.editor.instance?.option('value'))
        //       usePopupService().pushSuccessNotification({
        //         body:'Kaynak Kodlar Kopyalandı'
        //       })
        //     },
        //   },
        // },


      ]
    });



    function load(){

      if (localState.model && localState.model.length > 0) {
        localState.content = localState.model
      }
    }

    load()

    function save(){
      localState.model = localState.content;
      localState.showDialogRef = false;
    }

    return {
      ...toRefs(localState),
      save
    };
  }
});
