import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {RecordStatus} from '../models/RecordStatus'
import {FileLocation} from '../models/FileLocation'
import {FileConfigEntity} from '../models/FileConfigEntity'

export const useFileSettingsService = defineStore({
id: 'FileSettingsService',
actions: {


	async getDefaultInstall(firmaKod:string)
	{
		const { request, response } = useApi(`/api/filesettings/DefaultInstall/${firmaKod}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postKaydet(fileConfigEntity:FileConfigEntity)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/filesettings/Kaydet/${shared.firm.id}`,{ method:'POST', data:fileConfigEntity });
		await request();
		return response.value;
	},

	async postBaglantiKontrol(fileConfigEntity:FileConfigEntity)
	{
		const { request, response } = useApi(`/api/filesettings/BaglantiKontrol`,{ method:'POST', data:fileConfigEntity });
		await request();
		return response.value;
	},

	async getGetirKonum(fileLocation:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/filesettings/GetirKonum/${shared.firm.id}/${fileLocation}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getServerUrl()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/filesettings/ServerUrl/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
