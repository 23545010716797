import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';


export const useDovizKurService = defineStore({
id: 'DovizKurService',
actions: {


	async getKurlar()
	{
		const { request, response } = useApi(`/api/DovizKur/Kurlar`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getKurlarDate(date:string)
	{
		const { request, response } = useApi(`/api/DovizKur/KurlarDate/${date}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postKurlarSabitKurKaydet(usd:number,eur:number)
	{
		const { request, response } = useApi(`/api/DovizKur/Kurlar/SabitKurKaydet/${usd}/${eur}`,{ method:'POST' });
		await request();
		return response.value;
	},

	async deleteKurlarSabitKurSil()
	{
		const { request, response } = useApi(`/api/DovizKur/Kurlar/SabitKurSil`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getUpdateKurlar(startDate:string,endDate:string)
	{
		const { request, response } = useApi(`/api/DovizKur/UpdateKurlar/${startDate}/${endDate}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
