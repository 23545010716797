
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import NGrid from "@/components/Grid/NGrid.vue";
import { DovizList } from "@/api/models/Doviz";


export default defineComponent({
  components: { NGrid },
  props: {
    fiyat: {
      type: Number,
      default: 0
    },
    doviz: {
      type: Number,
      default: 0
    },
    format: {
        type: String,
        default: "#,##0.0000"
    }
  },
  setup(props, { emit }) {

    const localState = reactive({
      fiyatRef: computed({
        get: () => props.fiyat as number,
        set: (val: number) => emit("update:fiyat", val)
      }),
      dovizRef: computed({
        get: () => props.doviz as number,
        set: (val: number) => emit("update:doviz", val)
      }),
      dovizList: ref(DovizList)
    });

    function valueChanged(e: any) {
      emit("valueChanged", e);
    }

    return {
      ...toRefs(localState),
      valueChanged
    };
  }
});

