
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import SubeSelectBox from "@/modules/Sube/components/SubeSelectBox.vue";
import PlasiyerSelectBox from "@/modules/Plasiyer/components/PlasiyerSelectBox.vue";
import ProjeSelectBox from "@/modules/Proje/components/ProjeSelectBox.vue";
import DepartmanSelectBox from "@/modules/Departman/components/DepartmanSelectBox.vue";
import {CariHareketViewVadeli} from "@/api/models/CariHareketViewVadeli";

import {GenelResmiMuhasebeTipList} from "@/api/models/GenelResmiMuhasebeTip";
import {Doviz, DovizList} from "@/api/models/Doviz";
import {CariEntity} from "@/api/models/CariEntity";
import {useCariService} from "@/api/services/CariService";
import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import TutarsalBilgiler from "@/modules/Ortak/components/TutarsalBilgiler.vue";
import {CariHareketTip, CariHareketTipList} from "@/api/models/CariHareketTip";
import KasaSelectBox from "@/modules/Kasa/components/KasaSelectBox.vue";
import {MenuRefrenceType} from "@/modules/Ortak/models/Menu/MenuRefrenceType";
import BankaSelectBox from "@/modules/Banka/components/BankaSelectBox.vue";
import KrediKartiSelectBox from "@/modules/KrediKarti/components/KrediKartiSelectBox.vue";
import PosSelectBox from "@/modules/Pos/components/PosSelectBox.vue";
import {CariHareketUtils} from "@/modules/Cari/utils/CariHareketUtils";
import HizmetMasrafSelectBox from "@/modules/HizmetMasraf/components/HizmetMasrafSelectBox.vue";
import {useCariHareketService} from "@/api/services/CariHareketService";
import CokluDokuman from "@/modules/Ortak/components/CokluDokuman.vue";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";
import NInput from "@/components/Input/NInput.vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";

import {useBankaHareketService} from "@/api/services/BankaHareketService";
import {BankaHareketEntity} from "@/api/models/BankaHareketEntity";
import {BankaHareketTip} from "@/api/models/BankaHareketTip";
import NSwitch from "@/components/Switch/NSwitch.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import CariSelectBox from "@/modules/Cari/components/CariSelectBox.vue";
import {confirm} from "devextreme/ui/dialog";
import CariDekontDialogOncekiTaksitler from "@/modules/Cari/components/CariDekontDialogOncekiTaksitler.vue";
import AlacakBorcTaksitDekont from "@/modules/Cari/components/AlacakBorcTaksitDekont.vue";
import {FirmaEntity} from "@/api/models/FirmaEntity";
import {usedCacheFirmaService} from "@/modules/Ayarlar/Firmalar/services/usedCacheFirmaService";
import CariBakiyeBilgileri from "@/modules/Cari/components/CariBakiyeBilgileri.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import TaksitlendirmePanel from "@/modules/OdemeTahsilat/components/TaksitlendirmePanel.vue";
import {KurView} from "@/api/models/KurView";
import {useDovizKurService} from "@/api/services/DovizKurService";

export default defineComponent({
  components: {
    NFormGrid,
    HizmetMasrafSelectBox,
    CariBakiyeBilgileri,
    AlacakBorcTaksitDekont,
    CariDekontDialogOncekiTaksitler,
    CariSelectBox,
    NFormField,
    NSwitch,
    NModalDialog,
    NInput,
    CokluDokuman,
    PosSelectBox,
    NSelectBox,
    KrediKartiSelectBox,
    BankaSelectBox,
    KasaSelectBox,
    TutarsalBilgiler,
    DepartmanSelectBox,
    ProjeSelectBox,
    PlasiyerSelectBox,
    SubeSelectBox,
    TaksitlendirmePanel
  },
  props: {
    showForm: {
      type: Boolean,
      default: false
    },
    cariHareketId: {
      type: String
    },
    cariId: {
      type: String
    },
    menu: {
      type: Object
    },
    tutar: {
      type: Number,
      default: 0
    },
    taksitPlanId: {
      type: String,
      default: ""
    },
    onlineBankaHareketId : {
      type:String ,
      default : '',
    }
  },
  setup: function(props, { emit }) {
    const localState = reactive({
      isLoading: ref(true),
      cariCep: ref(""),
      cariMail: ref(""),
      smsSwicth: ref(false),
      mailSwitch: ref(false),
      kargoSwitch: ref(false),
      visible: ref(true),
      selectedSube: ref({} as any),
      showFormRef: computed({
        get: () => props.showForm,
        set: val => emit("update:showForm", val)
      }),
      genelResmiMuhasebeTipList: ref(GenelResmiMuhasebeTipList),
      model: ref({} as CariHareketViewVadeli),
      dovizTipleri: ref(DovizList),
      cari: ref({} as CariEntity),
      menuModel: ref(props.menu as MenuModel),
      menuReferansType: ref(MenuRefrenceType),
      hareketTipleri: ref(CariHareketTipList),
      cariHaraketTip: CariHareketTip,

      hareketUtils: CariHareketUtils,
      selectedTab: ref(0),
      formDeleteDisabled: ref(true),
      formSaveDisabled: ref(true),


      cariIdRef: computed(() => props.cariId),
      firma: ref({} as FirmaEntity),
      kurlarRef: ref([] as KurView[])

    });


    const tabs = [
      { label: "Genel Bilgiler" },
      { label: "Diğer Bilgiler" },
      { label: "Önceki Vadeleri" },
      { label: "Dökümanlar" },
      { label: "Gonder" },
      {
        label: "Taksitlendirme",
        disabled: !(
          localState.menuModel.refrenceType ==
          localState.menuReferansType.Kredi_Karti ||
          localState.menuModel.hareketTipi == CariHareketTip.Cari_Borc_Dekontu
        )
      }
    ];
    load();

    async function load() {
      localState.firma = await usedCacheFirmaService().getFirma();
      localState.isLoading = true;
      localState.model = await localState.hareketUtils.getCariHareketView(
        props.cariHareketId,
        props.cariId,
        props.tutar
      );

      if (props.onlineBankaHareketId && props.onlineBankaHareketId.length > 0 ){
        localState.model.onlineBankaHareketId = props.onlineBankaHareketId;
      }


      localState.cari = await useCariService().getGetir(props.cariId as string);

      if (localState.cari && localState.cari.adresler) {
        localState.cariCep = localState.cari.adresler[0]?.cep as string;
        localState.cariMail = localState.cari?.adresler[0]?.mail as string;
      }

      if (localState.model.id && localState.model.id.length > 0) {
        //
      } else {
        if (localState.cari && localState.cari.plasiyerId && localState.cari.plasiyerId.length > 0) {
          localState.model.plasiyerId = localState.cari.plasiyerId;
        }
        localState.model!.hesaplama!.islenecekDoviz = localState.cari.varsayilanDoviz;
      }


      formDigerIslemleri();

      localState.isLoading = false;
    }

    async function formDigerIslemleri() {

      if (localState.menuModel.hareketTipi == CariHareketTip.Cari_Pos_Cekme && localState.model && localState.model.id && localState.model.id.length > 0) {
        const result = await useBankaHareketService().getReferansHareket(localState.model.id) as BankaHareketEntity;

        if (result) {
          if (result.tip == BankaHareketTip.BankaPosIsleme)
            localState.formSaveDisabled = true , localState.formDeleteDisabled = true;
        }
      }

      localState.model.taksitPlanId = props.taksitPlanId;


    }


    async function save() {
      await localState.hareketUtils.dekontKaydet(
        localState.menuModel,
        localState.model
      );
      emit("saveRefresh");
      localState.showFormRef = false;

    }

    async function saveAndPrint() {

      await localState.hareketUtils.dekontKaydet(
        localState.menuModel,
        localState.model
      );
      emit("print");
      localState.showFormRef = false;
    }


    async function hareketDelete() {
      if (
        localState.model &&
        localState.model.id &&
        localState.model.id.length > 0
      ) {
        if (await confirm("Hareket silinecektir , Onaylıyor musunuz ? ", "Hareket Silme")) {
          await useCariHareketService().deleteSil(localState.model.id);
          localState.showFormRef = false;
        }
      }
    }


    function isHizmetSelect() {
      if (localState.menuModel.hareketTipi == CariHareketTip.Cari_Havale_Gonderme) {
        if (localState.model && localState.model.id && localState.model.id.length > 0) {
          if (localState.model.hizmetMasrafId && localState.model.hizmetMasrafId.length > 0 && localState.model.hizmetMasrafTutar && localState.model.hizmetMasrafTutar > 0 )
            return true;
          else
            return false;

        } else {
          return true;
        }
      }
      return false;
    }

    function varsayilanDovizChanged(doviz:string){

      switch (doviz) {
        case "TRY" :
          localState.model!.hesaplama!.islemDoviz = Doviz.TRY ;
          break;
        case "USD" :
          localState.model!.hesaplama!.islemDoviz = Doviz.USD ;
          break;
        case "EUR" :
          localState.model!.hesaplama!.islemDoviz = Doviz.EUR ;
          break ;

      }
    }

      async function evrakTarihiChanged(e: any) {
          if (e && e.value && e.value.length > 0) {
              const kurlar = await useDovizKurService().getKurlarDate(e.value) as KurView[];
              if (kurlar && kurlar.length > 0)
                  localState.kurlarRef = kurlar;
          }

          //console.log(e);
      }

    return {
      ...toRefs(localState),
      hareketDelete,
      varsayilanDovizChanged,
      evrakTarihiChanged,
      save,
      saveAndPrint,
      isHizmetSelect,
      tabs
    };
  }
})
;
