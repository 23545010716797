import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {StokHareketTip} from '../models/StokHareketTip'
import {DevirItem} from '../models/DevirItem'
import {StokDevirView} from '../models/StokDevirView'
import {FaturaHareketTip} from '../models/FaturaHareketTip'

export const useStokHareketService = defineStore({
id: 'StokHareketService',
actions: {


	async getSatisHareketListeStokCari(stokId:string,cariId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/SatisHareketListeStokCari/${shared.firm.id}/${shared.firm.period}/${stokId}/${cariId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getSatisHareketListeItemCari(stokId:string,itemId:string,cariId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/SatisHareketListeItemCari/${shared.firm.id}/${shared.firm.period}/${stokId}/${itemId}/${cariId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getSatisHareketListeCari(stokId:string,cariId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/SatisHareketListeCari/${shared.firm.id}/${shared.firm.period}/${stokId}/${cariId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getSatisHareketListeStokId(stokId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/SatisHareketListeStokId/${shared.firm.id}/${shared.firm.period}/${stokId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getSatisHareketListeItemId(stokId:string,itemId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/SatisHareketListeItemId/${shared.firm.id}/${shared.firm.period}/${stokId}/${itemId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getAlisHareketListeStokId(stokId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/AlisHareketListeStokId/${shared.firm.id}/${shared.firm.period}/${stokId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getAlisHareketListeItemId(stokId:string,itemId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/AlisHareketListeItemId/${shared.firm.id}/${shared.firm.period}/${stokId}/${itemId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCountStokHareket(stokId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/CountStokHareket/${shared.firm.id}/${shared.firm.period}/${stokId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getAlisHareketListeItemCari(stokId:string,itemId:string,cariId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/AlisHareketListeItemCari/${shared.firm.id}/${shared.firm.period}/${stokId}/${itemId}/${cariId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getAlisHareketListeCari(stokId:string,cariId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/AlisHareketListeCari/${shared.firm.id}/${shared.firm.period}/${stokId}/${cariId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getTeklifHareketListeStokId(stokId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/TeklifHareketListeStokId/${shared.firm.id}/${shared.firm.period}/${stokId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getTeklifHareketListeItemId(stokId:string,itemId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/TeklifHareketListeItemId/${shared.firm.id}/${shared.firm.period}/${stokId}/${itemId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getSiparisHareketListeStokId(stokId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/SiparisHareketListeStokId/${shared.firm.id}/${shared.firm.period}/${stokId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getSiparisHareketListeItemId(stokId:string,itemId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/SiparisHareketListeItemId/${shared.firm.id}/${shared.firm.period}/${stokId}/${itemId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getStokIdHareketListe(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/StokIdHareketListe/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getItemIdHareketListe(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/ItemIdHareketListe/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getHareketListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/HareketListe/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getAlisFiyatlariGuncelle()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/AlisFiyatlariGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getBakiyeHesapla(tip:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/BakiyeHesapla/${shared.firm.id}/${shared.firm.period}/${tip}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postIlkDevir(stokDevirView:StokDevirView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/IlkDevir/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:stokDevirView });
		await request();
		return response.value;
	},

	async getQueueStart(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/QueueStart/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getWaitingQueueStart()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/WaitingQueueStart/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getUpdateGuncellKurlar()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/UpdateGuncellKurlar/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getSeriSorgula(stokId:string,seri:string,tip:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/SeriSorgula/${shared.firm.id}/${shared.firm.period}/${stokId}/${seri}/${tip}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getSeriDurumu(stokId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/SeriDurumu/${shared.firm.id}/${shared.firm.period}/${stokId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getMaliyetHesapla()
	{
		const { request, response } = useApi(`/api/StokHareket/MaliyetHesapla`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postStokFifoMaliyetHesapla(kod:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/StokHareket/StokFifoMaliyetHesapla/${shared.firm.id}/${shared.firm.period}/${kod}`,{ method:'POST' });
		await request();
		return response.value;
	},

	async postStokDevirMigrate(kod:string)
	{
		const { request, response } = useApi(`/api/StokHareket/StokDevirMigrate`,{ method:'POST' });
		await request();
		return response.value;
	}
}
});
