import { AxiosRequestConfig } from "axios";
import { axios } from "@/api/axios";
import { ref } from "vue";
import { useNesilceConfig } from "@/api/NesilceConfig";

export default function useApi(
  url: string,
  options?: Omit<AxiosRequestConfig, "url">
) {
  const response = ref();


  const request = async () => {



    await axios({
      baseURL: useNesilceConfig().config.API_URL ,
      ...options,
      url
    }).then(res => (response.value = res.data));
  };

  return { response, request };
}
