import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5cd88e2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = ["href"]
const _hoisted_3 = ["data-state"]
const _hoisted_4 = {
  key: 0,
  class: "loading"
}
const _hoisted_5 = { class: "progress" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = {
  key: 0,
  class: "upload-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NLoading = _resolveComponent("NLoading")!

  return (_openBlock(), _createElementBlock("div", {
    class: "images",
    style: _normalizeStyle({
      'grid-template-columns': _ctx.getRepeat()
    })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.srcRef, (model, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "image image-loaded",
        key: i,
        style: _normalizeStyle({
        width: _ctx.width + 'px',
        height: _ctx.height + 'px'
      })
      }, [
        _createVNode(_component_NIcon, {
          type: "file-text",
          size: "18"
        }),
        (_ctx.showUploadButton)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "upload-button delete-button",
              onClick: ($event: any) => (_ctx.remove(i))
            }, [
              _createVNode(_component_NIcon, {
                type: "x",
                size: "12",
                stroke: "#FFF"
              })
            ], 8, _hoisted_1))
          : _createCommentVNode("", true),
        (_ctx.showUploadButton)
          ? (_openBlock(), _createElementBlock("a", {
              key: 1,
              class: "upload-button delete-button open-button",
              href: _ctx.serverUrl + model,
              target: "_blank"
            }, [
              _createVNode(_component_NIcon, {
                type: "eye",
                size: "10",
                stroke: "#FFF"
              })
            ], 8, _hoisted_2))
          : _createCommentVNode("", true)
      ], 4))
    }), 128)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.models.filter(a => a.state === _ctx.states.Loading), (model, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "image",
        key: i,
        "data-state": model.state,
        style: _normalizeStyle({
        width: _ctx.width + 'px',
        height: _ctx.height + 'px'
      })
      }, [
        (model.state === _ctx.states.Loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_NLoading, {
                radius: 12,
                stroke: "2"
              }),
              _createElementVNode("div", _hoisted_5, [
                (model.total > 0)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(((model.loaded * 100) / model.total).toFixed(0)), 1))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true)
      ], 12, _hoisted_3))
    }), 128)),
    (_ctx.showUploadButton)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("input", {
            type: "file",
            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.fileSelected && _ctx.fileSelected(...args))),
            multiple: "",
            title: "Dökümanları seçmek için tıklayınız"
          }, null, 32),
          _createVNode(_component_NIcon, {
            class: "delete-icon",
            type: "plus",
            size: "16"
          })
        ]))
      : _createCommentVNode("", true)
  ], 4))
}