import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {RecordStatus} from '../models/RecordStatus'
import {PazaryeriConfigMiktarItem} from '../models/PazaryeriConfigMiktarItem'
import {TrendyolConfigEntity} from '../models/TrendyolConfigEntity'

export const useTrendyolAyarService = defineStore({
id: 'TrendyolAyarService',
actions: {


	async getGetir()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/TrendyolAyar/Getir/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetDefault()
	{
		const { request, response } = useApi(`/api/TrendyolAyar/GetDefault`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetAll()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/TrendyolAyar/GetAll/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postKaydet(trendyolConfigEntity:TrendyolConfigEntity)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/TrendyolAyar/Kaydet/${shared.firm.id}`,{ method:'POST', data:trendyolConfigEntity });
		await request();
		return response.value;
	},

	async getAktif()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/TrendyolAyar/Aktif/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async deleteDelete(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/TrendyolAyar/Delete/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	}
}
});
