export const ObjectUtils = {
    setObj: function (obj: any, path: any, value: any): any {
        path = path.replace('.$[]','[0]')
        path = path.replace('[','.').replace(']','')
        const a = path.split('.')
        let o = obj
        while (a.length - 1) {
            const n = a.shift()
            if (!(n in o)) o[n] = {}
            o = o[n]
        }
        o[a[0]] = value
    },
    getValueFromPath: function (obj: any, path: any): any {
        path = path.replace('.$[]','[0]')
        path = path.replace('[','.').replace(']','')
        const a = path.split('.')
        let o = obj
        while (a.length - 1) {
            const n = a.shift()
            if (!(n in o)) o[n] = {}
            o = o[n]
        }
        return  o[a[0]];
    },
}