<template>
    <LoginFormBase>
        <template v-slot:title>
            Giriş Yap
            <NTag type="success" :filled="true" v-if="getRouteName() != 'ApplicationLayout'">{{getLayoutName()}}</NTag>
        </template>
        <template v-slot:default>
            <LoginForm :redirect-route-name="getRouteName()" :is-code="isCode()" />
        </template>
    </LoginFormBase>

</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import LoginForm from "./components/LoginForm.vue";
import { useVersionService } from "@/api/services/VersionService";
import { useRoute } from "vue-router";
import { useNesilceConfig } from "@/api/NesilceConfig";
import LoginFormBase from "@/layouts/LoginLayout/components/LoginFormBase.vue";


export default defineComponent({
  name: "LoginLayout",
  components: {
      LoginFormBase,
    LoginForm

  },
  setup() {
    const version = ref(0);




    function getRouteName() {
        if (useRoute().name == "UretimTalimatLogin")
            return  "UretimTalimatMobile";
        if (useRoute().name == "HizliSatisLogin")
            return  "HizliSatisLayout";
        if (useRoute().name == "FiyatGor")
            return  "FiyatGorLayout";
      return "ApplicationLayout";
    }

    function isCode() {
      return useRoute().name === "LoginCode";
    }

    // eslint-disable-next-line @typescript-eslint/no-var-requires
      function getLayoutName() {
          if (getRouteName() == "UretimTalimatMobile") {
              return "Sac Üretim";
          } else if (getRouteName() == "HizliSatisLayout") {
              return "Hızlı Satış"
          }else if (getRouteName() == "FiyatGorLayout") {
              return "Fiyat Gör"
          }
          return "";
      }



    return {
        getRouteName,
      isCode,
        getLayoutName
    };
  }
});
</script>
