import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {RecordStatus} from '../models/RecordStatus'
import {IdName} from '../models/IdName'
import {XmlImporterConfigStokMap} from '../models/XmlImporterConfigStokMap'
import {XmlImporterConfigLogItem} from '../models/XmlImporterConfigLogItem'
import {XmlImporterConfigEntity} from '../models/XmlImporterConfigEntity'

export const useXmlImporterConfigService = defineStore({
id: 'XmlImporterConfigService',
actions: {


	async getGetirDefault()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/XmlImporterConfig/GetirDefault/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetir(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/XmlImporterConfig/Getir/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetirTumu()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/XmlImporterConfig/GetirTumu/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetirIdName()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/XmlImporterConfig/GetirIdName/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postKaydet(xmlImporterConfigEntity:XmlImporterConfigEntity)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/XmlImporterConfig/Kaydet/${shared.firm.id}`,{ method:'POST', data:xmlImporterConfigEntity });
		await request();
		return response.value;
	},

	async putUpdateRunForce(configId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/XmlImporterConfig/UpdateRunForce/${shared.firm.id}/${configId}`,{ method:'PUT' });
		await request();
		return response.value;
	}
}
});
