import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"align-self":"center","align-content":"center","margin-left":"4px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NFormFieldLabelTop = _resolveComponent("NFormFieldLabelTop")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NCard = _resolveComponent("NCard")!
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NListView = _resolveComponent("NListView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NCard, {
      title: "İhracat Fatura Bilgileri",
      style: {"margin":"3px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NFormFieldLabelTop, { label: "GTİP No" }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              type: "text",
              modelValue: _ctx.bilgiler.gTipNo,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.bilgiler.gTipNo) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormFieldLabelTop, { label: "Gönderim Şekli" }, {
          default: _withCtx(() => [
            _createVNode(_component_DxSelectBox, {
              "show-clear-button": true,
              searchEnabled: true,
              "data-source": _ctx.eihracatGonderimTipList,
              "display-expr": "description",
              "value-expr": "value",
              value: _ctx.bilgiler.gonderimTip,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.bilgiler.gonderimTip) = $event))
            }, null, 8, ["data-source", "value"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormFieldLabelTop, { label: "Teslim Şartı" }, {
          default: _withCtx(() => [
            _createVNode(_component_DxSelectBox, {
              "show-clear-button": true,
              searchEnabled: true,
              "data-source": _ctx.eihracatTeslimTipList,
              "display-expr": "description",
              "value-expr": "value",
              value: _ctx.bilgiler.teslimTip,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.bilgiler.teslimTip) = $event))
            }, null, 8, ["data-source", "value"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormFieldLabelTop, { label: "Kabın Cinsi / Nevi" }, {
          default: _withCtx(() => [
            _createVNode(_component_DxSelectBox, {
              "show-clear-button": true,
              searchEnabled: true,
              "data-source": _ctx.eihracatKapTipLst,
              "display-expr": "description",
              "value-expr": "value",
              value: _ctx.bilgiler.kapTip,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.bilgiler.kapTip) = $event))
            }, null, 8, ["data-source", "value"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormFieldLabelTop, {
          span: "2",
          label: "İstisna"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxSelectBox, {
              "show-clear-button": true,
              searchEnabled: true,
              "data-source": 
      _ctx.kdvIstinalariRef.filter((a) =>a.kod=='301' || a.kod == '701' ||   a.kod=='702' || a.kod == '703'
   ),
              "display-expr": "aciklama",
              "value-expr": "aciklama",
              value: _ctx.bilgiler.kdvIstisnasi,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.bilgiler.kdvIstisnasi) = $event))
            }, null, 8, ["data-source", "value"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormFieldLabelTop, { label: "Kabın Markası" }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              type: "text",
              modelValue: _ctx.bilgiler.kapMarka,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.bilgiler.kapMarka) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NButton, {
          click: _ctx.faturayaAktar,
          size: "small",
          type: "info"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Faturaya Aktar")
          ]),
          _: 1
        }, 8, ["click"])
      ]),
      _: 1
    }),
    _createVNode(_component_NCard, {
      title: "Notlar",
      col: "1fr",
      style: {"margin":"3px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NButton, {
          block: true,
          size: "small",
          icon: "plus",
          type: "primary",
          onClick: _ctx.addNot
        }, {
          default: _withCtx(() => [
            _createTextVNode("Not Ekle")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_NListView, {
          items: _ctx.model.eFatura.notlar,
          style: {"max-height":"600px"},
          "model-value": -1
        }, {
          item: _withCtx(({ item , index }) => [
            _createVNode(_component_NGrid, {
              cols: "auto 25px",
              gap: "2px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  placeholder: item,
                  modelValue: _ctx.model.eFatura.notlar[index],
                  "onUpdate:modelValue": ($event: any) => ((_ctx.model.eFatura.notlar[index]) = $event)
                }, null, 8, ["placeholder", "modelValue", "onUpdate:modelValue"]),
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_NIcon, {
                    title: "Sil",
                    stroke: "#C00",
                    style: {"align-self":"flex-end"},
                    type: "trash",
                    size: "16",
                    onClick: ($event: any) => (_ctx.remove(index))
                  }, null, 8, ["onClick"])
                ])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["items"])
      ]),
      _: 1
    })
  ], 64))
}