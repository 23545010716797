import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  target: "_blank",
  style: {"color":"#FFF","padding":"12px","text-decoration":"none"},
  href: "/#/hizliSatis"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createBlock(_component_NButton, {
    type: "success",
    style: {"margin":"4px","padding":"0"}
  }, {
    default: _withCtx(() => [
      (_ctx.isActive())
        ? (_openBlock(), _createElementBlock("a", _hoisted_1, "Hızlı Satış"))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}