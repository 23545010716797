import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HizliSatisStoklarIadeTabs = _resolveComponent("HizliSatisStoklarIadeTabs")!
  const _component_HizliSatisSatirListesi = _resolveComponent("HizliSatisSatirListesi")!
  const _component_HizliSatisOdemeGenelGonderme = _resolveComponent("HizliSatisOdemeGenelGonderme")!
  const _component_NGrid = _resolveComponent("NGrid")!

  return (_ctx.tabsStore.selectedTab?.model)
    ? (_openBlock(), _createBlock(_component_NGrid, {
        key: 0,
        cols: "1fr minmax(740px, 1fr)",
        rows: " minmax(0,1fr) auto",
        gap: "12px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_HizliSatisStoklarIadeTabs, { style: {"grid-row":"span 2"} }),
          _createVNode(_component_HizliSatisSatirListesi),
          _createVNode(_component_HizliSatisOdemeGenelGonderme)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}