
export enum EFaturaTip{
SATIS=0,
IADE=1,
TEVKIFAT=2,
ISTISNA=3,
IADEISTISNA=4,
OZELMATRAH=5,
IHRACKAYITLI=6,
SEVK=7
}

export const EFaturaTipList = [
{key:'SATIS', value:0, description:'SATIŞ'},
{key:'IADE', value:1, description:'İADE'},
{key:'TEVKIFAT', value:2, description:'TEVKİFAT'},
{key:'ISTISNA', value:3, description:'İSTİSNA'},
{key:'IADEISTISNA', value:4, description:'İADE İSTİSNA'},
{key:'OZELMATRAH', value:5, description:'ÖZEL MATRAH'},
{key:'IHRACKAYITLI', value:6, description:'İHRAÇ KAYITLI'},
{key:'SEVK', value:7, description:'SEVK'}
]
