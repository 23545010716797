import {defineStore} from "pinia";
import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import {NModuleRoute} from "@/models";
import {useLayoutStore} from "@/layouts/ApplicationLayout/context";
import {SiparisView} from "@/api/models/SiparisView";

export const useSiparisStore = defineStore({
    id: "SiparisStore",
    state: () => ({
        cariId: '',
        cariUnvan: '',
        menu: {} as MenuModel,
        model: {} as SiparisView,
        hareketId: '',
    }),
    actions: {
        openSiparisFormView(menu: MenuModel, cariId: string, cariUnvan: string, haraketId: string , model:SiparisView){
            this.menu = menu;
            this.cariUnvan = cariUnvan;
            this.cariId = cariId;
            this.hareketId = haraketId;
            this.model = model ;
            const route: NModuleRoute = {
                id: "SiparisForm",
                order: 0,
                displayName: menu.name + ": " + cariUnvan,
                icon: {
                    type: "file-minus"
                },
                entityId: model.id,
            };
            useLayoutStore().openRouteAsTab(route);
        },
        openSiparisForm(menu: MenuModel, cariId: string, cariUnvan: string, haraketId: string) {
            this.menu = menu;
            this.cariUnvan = cariUnvan;
            this.cariId = cariId;
            this.hareketId = haraketId;
            const route: NModuleRoute = {
                id: "SiparisForm",
                order: 0,
                displayName: menu.name + ": " + cariUnvan,
                icon: {
                    type: "file-minus"
                },
                entityId:haraketId,
            };
            useLayoutStore().openRouteAsTab(route);
        }
    }
})

