import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!

  return (_ctx.reportType)
    ? (_openBlock(), _createBlock(_component_DxSelectBox, {
        key: 0,
        "show-clear-button": true,
        items: _ctx.list,
        "onUpdate:items": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
        value: _ctx.selectedReportId,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedReportId) = $event)),
        "display-expr": "name",
        "value-expr": "id",
        onValueChanged: _ctx.valueChanged
      }, null, 8, ["items", "value", "onValueChanged"]))
    : _createCommentVNode("", true)
}