import { Colors, NNotification } from "@/models";
import { defineStore } from "pinia";
import { uuid } from "vue-uuid";
import {useBildirimSesleriStore} from "@/modules/Ayarlar/Tanımlar/store/BildirimSesleriStore";

export const usePopupService = defineStore({
  id: "PopupService",
  state: () => ({
    notifications: [] as NNotification[]
  }),
  actions: {
    questionSoundPlay(){
      const soruSesi = useBildirimSesleriStore().bildirimSoruSesi
      if (soruSesi) {
        const sound = new Audio("/question.mp3");
        setTimeout(async () => sound.play())
      }
    },
    errorSoundPlay(){
      const hataSesi = useBildirimSesleriStore().bildirimHataSesi
      if (hataSesi) {
        const sound = new Audio("/error.mp3");
        setTimeout(async () => sound.play())
      }
    },
    successSoundPlay(){
      const basariliSesi = useBildirimSesleriStore().bildirimBasariliSesi
      if (basariliSesi) {
          const sound = new Audio("/success.mp3");
          setTimeout(async () => sound.play())
      }
    },
    pushNotification(newNotification: Omit<NNotification, "id">) {
      const id = uuid.v4();
      this.notifications.push({
        duration: 5000,
        ...newNotification,
        id
      });

    },
    pushSuccessNotification(
      newNotification: Omit<NNotification, "type" | "id">
    ) {
      this.pushNotification({
        type: Colors.SUCCESS,
        ...newNotification
      });
      this.successSoundPlay()
    },
    pushWarningNotification(
      newNotification: Omit<NNotification, "type" | "id">
    ) {
      this.pushNotification({
        type: Colors.WARNING,
        ...newNotification
      });
    },
    pushDangerNotification(
      newNotification: Omit<NNotification, "type" | "id">
    ) {
      this.pushNotification({
        type: Colors.DANGER,
        ...newNotification
      });
      this.errorSoundPlay()
    },
    clearAllNotifications(): void {
      this.notifications = [];
    },
    async closeNotification(id: string | undefined) {
      if (!this.notifications || !this.notifications.length) {
        this.clearAllNotifications();
      } else {
        this.notifications = this.notifications.filter(n => n.id !== id);
      }
    }
  }
});
