
export enum FaturaDurumu{
NewRecord=0,
Ready=1,
InQueue=2,
Sending=3,
Sended=4,
Error=5
}

export const FaturaDurumuList = [
{key:'NewRecord', value:0, description:'Yeni Kayıt'},
{key:'Ready', value:1, description:'Hazırlandı'},
{key:'InQueue', value:2, description:'Sıraya Alındı'},
{key:'Sending', value:3, description:'Gönderiliyor'},
{key:'Sended', value:4, description:'Gönderildi'},
{key:'Error', value:5, description:'Gönderilemedi'}
]
