import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {DocumentItem} from '../models/DocumentItem'
import {GenelResmiMuhasebeTip} from '../models/GenelResmiMuhasebeTip'
import {IslemHesaplamaEntity} from '../models/IslemHesaplamaEntity'
import {PlanVadelerItem} from '../models/PlanVadelerItem'
import {KrediKartiHareketView} from '../models/KrediKartiHareketView'
import {BankaHareketBakiyeRequestView} from '../models/BankaHareketBakiyeRequestView'

export const useKrediKartiHareketService = defineStore({
id: 'KrediKartiHareketService',
actions: {


	async getKrediKartiHareketReportView()
	{
		const { request, response } = useApi(`/api/KrediKartiHareket/KrediKartiHareketReportView`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/KrediKartiHareket/Liste/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getKrediKartiHareketListe(krediKartiId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/KrediKartiHareket/KrediKartiHareketListe/${shared.firm.id}/${shared.firm.period}/${krediKartiId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetir(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/KrediKartiHareket/Getir/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetirView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/KrediKartiHareket/GetirView/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCariHareketId(cariHareketId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/KrediKartiHareket/CariHareketId/${shared.firm.id}/${shared.firm.period}/${cariHareketId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async deleteSil(krediKartiHareketId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/KrediKartiHareket/Sil/${shared.firm.id}/${shared.firm.period}/${krediKartiHareketId}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postKrediKartiCikanDevir(krediKartiHareketView:KrediKartiHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/KrediKartiHareket/KrediKartiCikanDevir/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:krediKartiHareketView });
		await request();
		return response.value;
	},

	async putKrediKartiCikanDevirGuncelle(krediKartiHareketView:KrediKartiHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/KrediKartiHareket/KrediKartiCikanDevirGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:krediKartiHareketView });
		await request();
		return response.value;
	},

	async postKrediKartiMasrafOdeme(krediKartiHareketView:KrediKartiHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/KrediKartiHareket/KrediKartiMasrafOdeme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:krediKartiHareketView });
		await request();
		return response.value;
	},

	async putKrediKartiMasrafOdemeGuncelle(krediKartiHareketView:KrediKartiHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/KrediKartiHareket/KrediKartiMasrafOdemeGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:krediKartiHareketView });
		await request();
		return response.value;
	},

	async getRaporHareket(baslangic:string,bitis:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/KrediKartiHareket/RaporHareket/${shared.firm.id}/${shared.firm.period}/${baslangic}/${bitis}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postKrediKartiRaporView(bankaHareketBakiyeRequestView:BankaHareketBakiyeRequestView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/KrediKartiHareket/KrediKartiRaporView/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:bankaHareketBakiyeRequestView });
		await request();
		return response.value;
	}
}
});
