import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {PersonelEkHakedisItem} from '../models/PersonelEkHakedisItem'
import {PersonelCalismaSekli} from '../models/PersonelCalismaSekli'
import {DocumentItem} from '../models/DocumentItem'
import {PersonelHareketItem} from '../models/PersonelHareketItem'
import {PersonelIzinItem} from '../models/PersonelIzinItem'
import {PersonelView} from '../models/PersonelView'

export const usePersonelService = defineStore({
id: 'PersonelService',
actions: {


	async getDefault()
	{
		const { request, response } = useApi(`/api/Personel/default`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postEkle(personelView:PersonelView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Personel/Ekle/${shared.firm.id}`,{ method:'POST', data:personelView });
		await request();
		return response.value;
	},

	async putGuncelle(personelView:PersonelView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Personel/Guncelle/${shared.firm.id}`,{ method:'PUT', data:personelView });
		await request();
		return response.value;
	},

	async deleteSil(personelId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Personel/Sil/${shared.firm.id}/${personelId}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Personel/List/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getIdNameList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Personel/IdNameList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getPersonelGetir(personelId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Personel/PersonelGetir/${personelId}/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getIstenCikmisMi(personelId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Personel/IstenCikmisMi/${personelId}/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getView(personelId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Personel/View/${personelId}/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getPersonelListesiReportView()
	{
		const { request, response } = useApi(`/api/Personel/PersonelListesiReportView`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
