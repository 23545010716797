import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {DocumentItem} from '../models/DocumentItem'
import {GenelResmiMuhasebeTip} from '../models/GenelResmiMuhasebeTip'
import {IslemHesaplamaEntity} from '../models/IslemHesaplamaEntity'
import {KasaHareketView} from '../models/KasaHareketView'

export const useKasaHareketService = defineStore({
id: 'KasaHareketService',
actions: {


	async getKasaHareketReportView()
	{
		const { request, response } = useApi(`/api/kasaHareket/KasaHareketReportView`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetir(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/Getir/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetirView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/GetirView/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCariHareketId(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/CariHareketId/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/List/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListByDate(start:string,end:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/ListByDate/${shared.firm.id}/${shared.firm.period}/${start}/${end}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getKasaIdHareketList(kasaId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasaIdHareketList/${shared.firm.id}/${shared.firm.period}/${kasaId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async deleteSil(kasaHareketId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/Sil/${shared.firm.id}/${shared.firm.period}/${kasaHareketId}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postKasadanBankayaOdeme(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasadanBankayaOdeme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:kasaHareketView });
		await request();
		return response.value;
	},

	async putKasadanBankayaOdemeGuncelle(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasadanBankayaOdemeGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:kasaHareketView });
		await request();
		return response.value;
	},

	async postKasaNoksanlik(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasaNoksanlik/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:kasaHareketView });
		await request();
		return response.value;
	},

	async putKasaNoksanlikGuncelle(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasaNoksanlikGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:kasaHareketView });
		await request();
		return response.value;
	},

	async postKasaCikanDevir(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasaCikanDevir/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:kasaHareketView });
		await request();
		return response.value;
	},

	async putKasaCikanDevirGuncelle(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasaCikanDevirGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:kasaHareketView });
		await request();
		return response.value;
	},

	async postKasadanDovizBozdurma(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasadanDovizBozdurma/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:kasaHareketView });
		await request();
		return response.value;
	},

	async putKasadanDovizBozdurmaGuncelle(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasadanDovizBozdurmaGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:kasaHareketView });
		await request();
		return response.value;
	},

	async postKasayaVirmanOdeme(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasayaVirmanOdeme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:kasaHareketView });
		await request();
		return response.value;
	},

	async putKasayaVirmanOdemeGuncelle(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasayaVirmanOdemeGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:kasaHareketView });
		await request();
		return response.value;
	},

	async postKasadanMasrafOdeme(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasadanMasrafOdeme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:kasaHareketView });
		await request();
		return response.value;
	},

	async putKasadanMasrafOdemeGuncelle(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasadanMasrafOdemeGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:kasaHareketView });
		await request();
		return response.value;
	},

	async postKasadanFirmaKrediKartinaOdeme(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasadanFirmaKrediKartinaOdeme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:kasaHareketView });
		await request();
		return response.value;
	},

	async putKasadanFirmaKrediKartinaOdemeGuncelle(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasadanFirmaKrediKartinaOdemeGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:kasaHareketView });
		await request();
		return response.value;
	},

	async postKasadanFirmaSenediOdeme()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasadanFirmaSenediOdeme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST' });
		await request();
		return response.value;
	},

	async postKasadanFirmaCekiOdeme()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasadanFirmaCekiOdeme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST' });
		await request();
		return response.value;
	},

	async postKasaFazlalik(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasaFazlalik/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:kasaHareketView });
		await request();
		return response.value;
	},

	async putKasaFazlalikGuncelle(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasaFazlalikGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:kasaHareketView });
		await request();
		return response.value;
	},

	async postKasaGirenDevir(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasaGirenDevir/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:kasaHareketView });
		await request();
		return response.value;
	},

	async putKasaGirenDevirGuncelle(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasaGirenDevirGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:kasaHareketView });
		await request();
		return response.value;
	},

	async postKasaayaDovizAlma(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasaayaDovizAlma/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:kasaHareketView });
		await request();
		return response.value;
	},

	async putKasaayaDovizAlmaGuncelle(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasaayaDovizAlmaGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:kasaHareketView });
		await request();
		return response.value;
	},

	async postKasaVirmanTahsilat(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasaVirmanTahsilat/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:kasaHareketView });
		await request();
		return response.value;
	},

	async putKasaVirmanTahsilatGuncelle(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasaVirmanTahsilatGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:kasaHareketView });
		await request();
		return response.value;
	},

	async postKasayaBankadanCekme(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasayaBankadanCekme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:kasaHareketView });
		await request();
		return response.value;
	},

	async putKasayaBankadanCekmeGuncelle(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasayaBankadanCekmeGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:kasaHareketView });
		await request();
		return response.value;
	},

	async postKasayaHizmetTahsilati(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasayaHizmetTahsilati/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:kasaHareketView });
		await request();
		return response.value;
	},

	async putKasayaHizmetTahsilatiGuncelle(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasayaHizmetTahsilatiGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:kasaHareketView });
		await request();
		return response.value;
	},

	async postKasayaFirmaKrediKartindanParaCekme(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasayaFirmaKrediKartindanParaCekme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:kasaHareketView });
		await request();
		return response.value;
	},

	async putKasayaFirmaKrediKartindanParaCekmeGuncelle(kasaHareketView:KasaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/KasayaFirmaKrediKartindanParaCekmeGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:kasaHareketView });
		await request();
		return response.value;
	},

	async getRaporHareket(baslangic:string,bitis:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasaHareket/RaporHareket/${shared.firm.id}/${shared.firm.period}/${baslangic}/${bitis}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
