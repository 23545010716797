import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {CekSenetDurum} from '../models/CekSenetDurum'
import {GenelResmiMuhasebeTip} from '../models/GenelResmiMuhasebeTip'
import {IslemHesaplamaEntity} from '../models/IslemHesaplamaEntity'
import {CekSenetHareketView} from '../models/CekSenetHareketView'

export const useCekSenetHareketService = defineStore({
id: 'CekSenetHareketService',
actions: {


	async getHareketList(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CekSenetHareket/HareketList/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postPortfoy(cekSenetHareketView:CekSenetHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CekSenetHareket/Portfoy/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cekSenetHareketView });
		await request();
		return response.value;
	},

	async postSonHareketGuncelle(cekSenetHareketView:CekSenetHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CekSenetHareket/SonHareketGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cekSenetHareketView });
		await request();
		return response.value;
	},

	async postIade(cekSenetHareketView:CekSenetHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CekSenetHareket/Iade/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cekSenetHareketView });
		await request();
		return response.value;
	},

	async postCiro(cekSenetHareketView:CekSenetHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CekSenetHareket/Ciro/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cekSenetHareketView });
		await request();
		return response.value;
	},

	async getHareketView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CekSenetHareket/HareketView/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postCirodanTahsil(cekSenetHareketView:CekSenetHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CekSenetHareket/CirodanTahsil/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cekSenetHareketView });
		await request();
		return response.value;
	},

	async postBankaTakas(cekSenetHareketView:CekSenetHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CekSenetHareket/BankaTakas/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cekSenetHareketView });
		await request();
		return response.value;
	},

	async postBankaTeminat(cekSenetHareketView:CekSenetHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CekSenetHareket/BankaTeminat/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cekSenetHareketView });
		await request();
		return response.value;
	},

	async postCariTeminat(cekSenetHareketView:CekSenetHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CekSenetHareket/CariTeminat/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cekSenetHareketView });
		await request();
		return response.value;
	},

	async postTeminattanTahsil(cekSenetHareketView:CekSenetHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CekSenetHareket/TeminattanTahsil/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cekSenetHareketView });
		await request();
		return response.value;
	},

	async postKasaTahsil(cekSenetHareketView:CekSenetHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CekSenetHareket/KasaTahsil/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cekSenetHareketView });
		await request();
		return response.value;
	},

	async postKasaKismenTahsil(cekSenetHareketView:CekSenetHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CekSenetHareket/KasaKismenTahsil/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cekSenetHareketView });
		await request();
		return response.value;
	},

	async postBankaTahsil(cekSenetHareketView:CekSenetHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CekSenetHareket/BankaTahsil/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cekSenetHareketView });
		await request();
		return response.value;
	},

	async postKarsiliksiz(cekSenetHareketView:CekSenetHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CekSenetHareket/Karsiliksiz/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cekSenetHareketView });
		await request();
		return response.value;
	},

	async postAvukat(cekSenetHareketView:CekSenetHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CekSenetHareket/Avukat/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cekSenetHareketView });
		await request();
		return response.value;
	},

	async postAvukatTahsil(cekSenetHareketView:CekSenetHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/CekSenetHareket/AvukatTahsil/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:cekSenetHareketView });
		await request();
		return response.value;
	}
}
});
