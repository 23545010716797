import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"color":"#78b81c","display":"inline-block","margin-left":"8px"} }
const _hoisted_2 = { style: {"color":"#78b81c","display":"inline-block","margin-left":"8px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_DxRadioGroup = _resolveComponent("DxRadioGroup")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_DepoSelectBox = _resolveComponent("DepoSelectBox")!
  const _component_SacUretimSiparisSelectButton = _resolveComponent("SacUretimSiparisSelectButton")!
  const _component_DxAutocomplete = _resolveComponent("DxAutocomplete")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NFormGrid, {
      cols: "1fr",
      padding: "0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NFormField, {
          label: "Tonajlar",
          span: "1",
          "grid-cols": "80px 1fr"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_DxRadioGroup, {
                focusStateEnabled: false,
                activeStateEnabled: false,
                items: _ctx.dilmeApi.getSlots(_ctx.fisRef.satirlar as any, _ctx.stokRef.tipOzellikler?.genislik as number),
                value: _ctx.model.tabanId,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.tabanId) = $event)),
                layout: "vertical",
                "value-expr": "tabanId",
                itemTemplate: "item"
              }, {
                item: _withCtx(({data}) => [
                  _createVNode(_component_NGrid, {
                    style: {"width":"auto","text-align":"left"},
                    gap: "4px",
                    cols: "320px 280px 20px auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NTag, { style: {"display":"inline-block"} }, {
                        default: _withCtx(() => [
                          _createTextVNode("Üretilen Ağırlık: " + _toDisplayString(data.uretilenTonaj?.format(0,0)) + " / " + _toDisplayString(data.tabanTonaj?.format(0,2)) + " ", 1),
                          _createElementVNode("b", _hoisted_1, " Kalan: " + _toDisplayString(data.kalanTonaj?.format(0,2)), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_NTag, { style: {"display":"inline-block"} }, {
                        default: _withCtx(() => [
                          _createTextVNode("Üretilen Genişlik: " + _toDisplayString(data.uretilenGenislik?.format(0,2)) + " / " + _toDisplayString(_ctx.stokRef.tipOzellikler?.genislik?.format(0,2)) + " ", 1),
                          _createElementVNode("b", _hoisted_2, " Kalan: " + _toDisplayString((_ctx.stokRef.tipOzellikler?.genislik - data.uretilenGenislik)?.format(0,2)), 1)
                        ]),
                        _: 2
                      }, 1024),
                      (_ctx.stokRef.tipOzellikler?.genislik - data.uretilenGenislik <= 0)
                        ? (_openBlock(), _createBlock(_component_NTag, {
                            key: 0,
                            title: "Kontrol Ediniz!",
                            style: {"display":"inline-block","padding":"0"},
                            type: "success",
                            filled: true
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_NIcon, {
                                size: "16px",
                                type: "check",
                                style: {"height":"auto"}
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_NTag, {
                        style: {"display":"inline-block","padding":"2px 4px"},
                        onClick: ($event: any) => (_ctx.editTabanTonaj($event, data.tabanId,data.tabanTonaj))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_NIcon, {
                            size: "16px",
                            type: "edit",
                            style: {"height":"auto"}
                          })
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["items", "value"]),
              _createVNode(_component_DxRadioGroup, {
                focusStateEnabled: false,
                activeStateEnabled: false,
                items: _ctx.newSlot,
                "onUpdate:items": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newSlot) = $event)),
                value: _ctx.model.tabanId,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.tabanId) = $event)),
                layout: "vertical",
                "value-expr": "tabanId",
                itemTemplate: "item"
              }, {
                item: _withCtx(({data}) => [
                  _createVNode(_component_NGrid, {
                    cols: "auto auto auto  1fr",
                    gap: "8px",
                    style: {"width":"500px"}
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DxNumberBox, {
                        ref: "tonajNumberBox",
                        label: "Taban Tonaj",
                        value: _ctx.model.tonaj,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.tonaj) = $event)),
                        min: 0,
                        format: "#,##0",
                        style: {"width":"85px"},
                        onFocusIn: _ctx.focusTonajBox
                      }, null, 8, ["value", "onFocusIn"]),
                      _createVNode(_component_NTag, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Üretilebilecek Tonaj: " + _toDisplayString(data.uretilecekTonaj?.format(0,2)), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_NTag, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Toplam Tonaj: " + _toDisplayString((_ctx.stokRef.getKalanMiktar)?.format(0,2)), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["items", "value"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_NFormGrid, { cols: "1fr" }, {
      default: _withCtx(() => [
        _createVNode(_component_NGrid, {
          cols: "1fr 1.2fr 1fr 1fr 1fr 1fr 1fr",
          gap: "8px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxNumberBox, {
              label: "Genişlik",
              value: _ctx.model.genislik,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.genislik) = $event)),
              min: 0,
              max: _ctx.dilmeApi.getKalanGenislikFromTabanId(_ctx.fisRef.satirlar as any, _ctx.stokRef?.tipOzellikler?.genislik as number, _ctx.model.tabanId as number),
              format: "#,##0.00"
            }, null, 8, ["value", "max"]),
            _createVNode(_component_DxNumberBox, {
              value: _ctx.model.adet,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.adet) = $event)),
              min: 1,
              format: _ctx.storeSettings.model.sacAdet?.format,
              label: 'Adet (Max:' + _ctx.getMaxAdet()?.format(0,4) +')'
            }, null, 8, ["value", "format", "label"]),
            _createVNode(_component_DxNumberBox, {
              value: _ctx.getHesaplananTonaj(),
              format: "#,##0",
              label: "Üretilecek Tonaj",
              readOnly: true
            }, null, 8, ["value"]),
            _createVNode(_component_DxNumberBox, {
              value: _ctx.getKalacakGenislik(),
              format: "#,##0",
              label: "Kalacak Genişlik",
              readOnly: true
            }, null, 8, ["value"]),
            _createVNode(_component_DxNumberBox, {
              value: _ctx.model.paketeBol,
              "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.paketeBol) = $event)),
              min: 1,
              format: "#,##0",
              labelMode: "floating",
              label: "Paket"
            }, null, 8, ["value"]),
            _createVNode(_component_DxNumberBox, {
              value: _ctx.model.tonajaBol,
              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.tonajaBol) = $event)),
              min: 1,
              format: "#,##0",
              labelMode: "floating",
              label: "Tonaja Böl",
              disabled: !_ctx.isNewSlot()
            }, null, 8, ["value", "disabled"]),
            _createVNode(_component_NSwitch, {
              modelValue: _ctx.model.isFire,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.isFire) = $event)),
              label: "Fire",
              style: {"align-self":"center"}
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NGrid, {
          cols: "1fr 1fr 0.5fr 0.5fr",
          gap: "8px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DepoSelectBox, {
              modelValue: _ctx.model.depoId,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.depoId) = $event)),
              "first-select": true,
              labelMode: "floating",
              label: "Depo",
              style: {"grid-column":"span 2"}
            }, null, 8, ["modelValue"]),
            _createVNode(_component_NFormField, {
              label: " ",
              "grid-cols": "0 1fr",
              span: "2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SacUretimSiparisSelectButton, {
                  onAddRow: _ctx.addRowFromSiparis,
                  style: {"margin-top":"8px"}
                }, null, 8, ["onAddRow"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_NGrid, {
          cols: "1.25fr 0.75fr 0.5fr 0.5fr",
          gap: "8px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxAutocomplete, {
              value: _ctx.model.aciklama,
              "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.aciklama) = $event)),
              "show-clear-button": false,
              "data-source": _ctx.aciklamaList,
              labelMode: "floating",
              label: "Açıklama"
            }, null, 8, ["value", "data-source"]),
            _createVNode(_component_DxAutocomplete, {
              value: _ctx.model.paketleme,
              "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.paketleme) = $event)),
              "show-clear-button": false,
              "data-source": _ctx.paketlemeList,
              labelMode: "floating",
              label: "Paketleme"
            }, null, 8, ["value", "data-source"]),
            _createVNode(_component_NFormField, {
              label: " ",
              "grid-cols": "0 1fr",
              span: "2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NButton, {
                  style: {"margin-top":"8px"},
                  size: "small",
                  type: "info",
                  icon: "circle",
                  onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.addRow())),
                  disabled: _ctx.getHesaplananTonaj() <= 0
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Dilme")
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_NModalDialog, {
      modelValue: _ctx.showTabanEdit,
      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.showTabanEdit) = $event)),
      title: "Taban Tonaj Değiştir"
    }, {
      body: _withCtx(() => [
        _createVNode(_component_NFormGrid, { cols: "1fr" }, {
          default: _withCtx(() => [
            _createVNode(_component_NFormField, {
              label: "Önceki Taban Tonaj",
              "grid-cols": "150px auto 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NTag, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.tabanTonajEski), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_NTag, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.tabanTonajId), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Yeni Taban Tonaj",
              "grid-cols": "150px auto"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxNumberBox, {
                  value: _ctx.tabanTonajYeni,
                  "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.tabanTonajYeni) = $event)),
                  format: "#,##0"
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_NButton, {
          type: "warning",
          onClick: _cache[14] || (_cache[14] = ()=> _ctx.showTabanEdit=false)
        }, {
          default: _withCtx(() => [
            _createTextVNode("İptal")
          ]),
          _: 1
        }),
        _createVNode(_component_NButton, {
          type: "success",
          onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.editTabanTonajAppy()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Değiştir")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}