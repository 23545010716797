import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CariFormAdres = _resolveComponent("CariFormAdres")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NCard = _resolveComponent("NCard")!

  return (_openBlock(), _createBlock(_component_NCard, {
    ref: "cardRef",
    tabs: _ctx.tabs,
    modelValue: _ctx.selectedTab,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedTab) = $event))
  }, {
    tabsAction: _withCtx(() => [
      _createElementVNode("button", {
        style: {"background":"transparent","cursor":"pointer","border":"0","color":"#FFF!important","width":"100px","height":"34px"},
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.adresEkle && _ctx.adresEkle(...args)))
      }, "+ Adres Ekle")
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.adresler, (adres, i) => {
        return (_openBlock(), _createElementBlock("div", { key: i }, [
          _withDirectives(_createVNode(_component_CariFormAdres, {
            modelValue: _ctx.model.adresler[i],
            "onUpdate:modelValue": ($event: any) => ((_ctx.model.adresler[i]) = $event),
            adresler: _ctx.adresler,
            active: _ctx.selectedTab === i
          }, null, 8, ["modelValue", "onUpdate:modelValue", "adresler", "active"]), [
            [_vShow, _ctx.selectedTab === i]
          ])
        ]))
      }), 128)),
      (_ctx.model.adresler?.length > 1)
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 0,
            onClick: _ctx.adresiSil,
            size: "small",
            type: "danger"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.selectedTab + 1) + ". Adresi Sil", 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["tabs", "modelValue"]))
}