import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"16px"} }
const _hoisted_2 = {
  class: "header-line header-4",
  style: {"margin":"0 0 8px 0"}
}
const _hoisted_3 = { class: "desc-3" }
const _hoisted_4 = { class: "desc-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!

  return (_ctx.stok && _ctx.stok.birimFiyatlari)
    ? (_openBlock(), _createBlock(_component_NFormGrid, {
        key: 0,
        title: "Birim Fiyatları",
        cols: "auto auto auto auto",
        gap: "16px"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stok.birimFiyatlari, (birim, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "dx-card",
              key: i
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, _toDisplayString(birim.grup), 1),
                _createVNode(_component_NFormField, {
                  label: "Kdv Hariç",
                  "grid-cols": "60px 80px 100px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NTag, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getFiyat(birim, false).format(2,2)) + " " + _toDisplayString(_ctx.getParaBirim(birim)), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createElementVNode("span", _hoisted_3, _toDisplayString(birim.getBirimKisaAd), 1)
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_NFormField, {
                  label: "Kdv Dahil",
                  "grid-cols": "60px 80px 100px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NTag, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getFiyat(birim, true).format(2,2)) + " " + _toDisplayString(_ctx.getParaBirim(birim)), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(birim.getBirimKisaAd), 1)
                  ]),
                  _: 2
                }, 1024)
              ])
            ]))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}