
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import NCard from "@/components/Card/NCard.vue";
import TrendyolStokForm from "@/modules/Pazaryerleri/Trendyol/components/TrendyolStokForm.vue";
import N11StokForm from "@/modules/Pazaryerleri/N11/components/N11StokForm.vue";
import HepsiBuradaStokForm from "@/modules/Pazaryerleri/HepsiBurada/components/HepsiBuradaStokForm.vue";
import HepsiBuradaListingForm from "@/modules/Pazaryerleri/HepsiBurada/components/HepsiBuradaListingForm.vue";
import {N11PazaryeriItem} from "@/api/models/N11PazaryeriItem";
import {TrendyolPazaryeriItem} from "@/api/models/TrendyolPazaryeriItem";
import {HepsiBuradaListingPazaryeriItem} from "@/api/models/HepsiBuradaListingPazaryeriItem";
import {StokView} from "@/api/models/StokView";
import {PrestaPazaryeriItem} from "@/api/models/PrestaPazaryeriItem";
import PrestaStokForm from "@/modules/Pazaryerleri/Presta/pages/PrestaStokForm.vue";
import CicekSepetiStokForm from "@/modules/Pazaryerleri/CicekSepeti/components/CicekSepetiStokForm.vue";
import {CicekSepetiPazaryeriItem} from "@/api/models/CicekSepetiPazaryeriItem";
import {N11ConfigEntity} from "@/api/models/N11ConfigEntity";
import {usePrestaConfigService} from "@/api/services/PrestaConfigService";
import {PrestaConfigEntity} from "@/api/models/PrestaConfigEntity";

import PazaryeriFiyatItem from "@/modules/Pazaryerleri/components/PazaryeriFiyatItem.vue";

import {EpttAvmPazaryeriItem} from "@/api/models/EpttAvmPazaryeriItem";
import PazaramaStokForm from "@/modules/Pazaryerleri/Pazarama/components/PazaramaStokForm.vue";
import {PazaramaPazaryeriItem} from "@/api/models/PazaramaPazaryeriItem";
import StokFormBirimFiyat from "@/modules/Stok/components/StokFormBirimFiyat.vue";
import EpttAvmStokForm from "@/modules/Pazaryerleri/EpttAvm/components/EpttAvmStokFormu.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";

export default defineComponent({
  components: {
    NFormGrid,
    StokFormBirimFiyat,
    PazaramaStokForm,
    EpttAvmStokForm,
    PazaryeriFiyatItem,
    CicekSepetiStokForm,
    PrestaStokForm,
    HepsiBuradaListingForm,
    HepsiBuradaStokForm,
    N11StokForm,
    TrendyolStokForm,
    NCard
  },
  props: [
    'stok',
    'alisKdv',
    'alisKdvDahil',
    'satisKdv',
    'satisKdvDahil',
    'alisFiyat',
    'satisFiyat',
  ]
  ,
  setup(props, {emit}) {

    const localState = reactive({
      stokRef: computed(() => props.stok as StokView),
      selectedTab: ref(0),
      shipmentTemplates: ref([] as string[]),
      selectedHepsiBuradaTab: ref(0),
      alisKdvRef: computed({get: () => props.alisKdv, set: (val) => emit('update:alisKdv', val)}),
      alisKdvDahilRef: computed({get: () => props.alisKdvDahil, set: (val) => emit('update:alisKdvDahil', val)}),
      satisKdvRef: computed({get: () => props.satisKdv, set: (val) => emit('update:satisKdv', val)}),
      satisKdvDahilRef: computed({get: () => props.satisKdvDahil, set: (val) => emit('update:satisKdvDahil', val)}),
      alisFiyatRef: computed({get: () => props.alisFiyat, set: (val) => emit('update:alisFiyat', val)}),
      satisFiyatRef: computed({get: () => props.satisFiyat, set: (val) => emit('update:satisFiyat', val)}),
      configs: ref([] as N11ConfigEntity[]),
    });

    async function loadConfigs() {
      localState.configs = await usePrestaConfigService().getPrestaAyarGetir();
      localState.stokRef.presta?.forEach((presta: PrestaPazaryeriItem, i: number) => {
        if (!presta.configId)
          presta.configId = getConfigId(localState.configs, i)
      })
    }

    function getConfigId(configs: PrestaConfigEntity[], index: number) {
      if (configs.length > index) {
        const config = configs[index];
        return config.id;
      }
      return undefined;
    }

    loadConfigs()

    function getTabs() {
      const tabs = [] as any[];
      let i = 0;

      //console.log("localState.stokRef", localState.stokRef)

      tabs.push('Pazaryerleri')
      i++;

      localState.stokRef.n11?.forEach((pazaryeri: N11PazaryeriItem) => {
        tabs.push('N11');
        i++;
      })

      localState.stokRef.trendyol?.forEach((pazaryeri: TrendyolPazaryeriItem) => {
        tabs.push('Trendyol');
        i++;
      });

      localState.stokRef.hepsiBuradaListing?.forEach((pazaryeri: HepsiBuradaListingPazaryeriItem) => {
        tabs.push('HepsiBurada');
        i++;
      })

      localState.stokRef.presta?.forEach((pazaryeri: PrestaPazaryeriItem) => {
        tabs.push('E-Ticaret');
        i++;
      })


      localState.stokRef.cicekSepeti?.forEach((pazaryeri: CicekSepetiPazaryeriItem) => {
        tabs.push('ÇiçekSepeti');
        i++;
      })

      localState.stokRef.epttAvm?.forEach((pazaryeri: EpttAvmPazaryeriItem) => {
        tabs.push('EpttAvm');
        i++;
      })

      localState.stokRef.pazarama?.forEach((pazaryeri: PazaramaPazaryeriItem) => {
        tabs.push('Pazarama');
        i++;
      })

      return tabs;

    }


    return {
      ...toRefs(localState),
      getTabs
    };
  }
});

