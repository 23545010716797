import mitt, { Handler } from 'mitt';
import { defineStore } from 'pinia';
import { useSignal } from '@/services/useSignal';
//signal document content
import {RecordStatus} from '../models/RecordStatus'
import {QueueStatusType} from '../models/QueueStatusType'
import {DocumentItem} from '../models/DocumentItem'
import {GenelResmiMuhasebeTip} from '../models/GenelResmiMuhasebeTip'
import {IslemHesaplamaEntity} from '../models/IslemHesaplamaEntity'
import {IdName} from '../models/IdName'
import {CariHareketTip} from '../models/CariHareketTip'
import {CariHareketEntity} from '../models/CariHareketEntity'

export const onCariHareketHubManagerSignal = defineStore({
id: 'CariHareketHubManagerSignal',
actions: {


	Added(handler:Handler<CariHareketEntity>)
	{
		useSignal().on('CariHareketHubManager.Added',handler);
	},

	Changed(handler:Handler<CariHareketEntity>)
	{
		useSignal().on('CariHareketHubManager.Changed',handler);
	},

	Deleted(handler:Handler<string>)
	{
		useSignal().on('CariHareketHubManager.Deleted',handler);
	},

	LimitIzin(handler:Handler<string>)
	{
		useSignal().on('CariHareketHubManager.LimitIzin',handler);
	}
}
});
