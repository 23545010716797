
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NButton from "@/components/Button/NButton.vue";
import BirimlerSelectBox from "@/modules/Ortak/components/BirimlerSelectBox.vue";
import BirimFiyatGrupTanimComp from "@/modules/Stok/components/BirimFiyatGrupTanimComp.vue";
import {useListelerService} from "@/api/services/ListelerService";
import {BirimFiyatGrupView} from "@/api/models/BirimFiyatGrupView";
import {Liste} from "@/api/models/Liste";
import {DovizList} from "@/api/models/Doviz";
import {BirimFiyatTipi, BirimFiyatTipiList} from "@/api/models/BirimFiyatTipi";
import {StokKategoriView} from "@/api/models/StokKategoriView";
import {BirimView} from "@/api/models/BirimView";
import {StokKategoriBirimItem} from "@/api/models/StokKategoriBirimItem";

export default defineComponent({
  components: {BirimFiyatGrupTanimComp, BirimlerSelectBox, NButton, NFormGrid, NGrid },
  props: {
    modelValue: {
      type: Array
    },
    birimlerDevir:{
      type:Array
    }
  },
  setup(props) {
    const localState = reactive({
      model: computed(()=>props.modelValue as StokKategoriBirimItem[]),
      gruplar: ref([] as BirimFiyatGrupView[]),
      birimler: ref([] as Liste[]),
      dovizList: DovizList,
      fiyatTipList: BirimFiyatTipiList
    });


    function addBirim() {
      if (localState.model) {


        localState.model.push({
          grup:localState.gruplar.find(()=>true)?.grubAdi,
          birim: "Adet",
          fiyat: 0,
          carpan: 1,
          fiyatTipi:BirimFiyatTipi.SatisOranEkle
        });

      }
    }



    function removeBirim(index: number) {
      if (localState.model)
        localState.model.splice(index, 1);
    }

    async function load(){
      localState.gruplar = await useListelerService().getBirimFiyatGrupListe();
      localState.birimler = await useListelerService().getBirimListesi();
    }

    load()

    return {
      ...toRefs(localState),
      addBirim,
      removeBirim
    };
  }
});
