
export enum EIhracatGonderimTip{
Denizyolu=1,
Demiryolu=2,
Karayolu=3,
Havayolu=4,
Posta=5,
CokAracili=6,
SabitTasimaTesisleri=7,
IcSuTasimaciligi=8
}

export const EIhracatGonderimTipList = [
{key:'Denizyolu', value:1, description:'Denizyolu'},
{key:'Demiryolu', value:2, description:'Demiryolu'},
{key:'Karayolu', value:3, description:'Karayolu'},
{key:'Havayolu', value:4, description:'Havayolu'},
{key:'Posta', value:5, description:'Posta'},
{key:'CokAracili', value:6, description:'Çok araçlı'},
{key:'SabitTasimaTesisleri', value:7, description:'Sabit taşıma tesisleri'},
{key:'IcSuTasimaciligi', value:8, description:'İç su taşımacılığı'}
]
