import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["n-badge", [_ctx.square && 'n-badge--square']])
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _withDirectives(_createElementVNode("span", {
      class: _normalizeClass(["n-badge-body", [
        _ctx.$slots.default && `n-badge-body--corner-${_ctx.position}`,
        _ctx.point && 'n-badge-body--point',
        _ctx.square && 'n-badge-body--square',
        `n-badge-body--${_ctx.type}`
      ]])
    }, _toDisplayString(_ctx.contentValue), 3), [
      [_vShow, _ctx.show]
    ])
  ], 2))
}