
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import {EpttAvmPazaryeriItem} from "@/api/models/EpttAvmPazaryeriItem";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NSwitch from "@/components/Switch/NSwitch.vue";
import NRefresher from "@/components/Refresher/NRefresher.vue";
import NDropDownTreeView from "@/components/DropDownTreeView/NDropDownTreeView.vue";
import NInput from "@/components/Input/NInput.vue";
import NInputHtml from "@/components/Input/NInputHtml.vue";
import PazaryeriFiyatItem from "@/modules/Pazaryerleri/components/PazaryeriFiyatItem.vue";
import {useEpttAvmKategoriService} from "@/api/services/EpttAvmKategoriService";
import {Category1} from "@/api/models/Category1";
import NCard from "@/components/Card/NCard.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import {number, string} from "yup";
import EpttAvmOzellikEditor from "@/modules/Pazaryerleri/EpttAvm/components/EpttAvmOzellikEditor.vue";
import CustomStore from "devextreme/data/custom_store";
import {useTrendyolMarkaService} from "@/api/services/TrendyolMarkaService";
import NDropDownListCustomStore from "@/components/Dropdown/NDropDownListCustomStore.vue";

export default defineComponent({
    components: {
        NDropDownListCustomStore,
        EpttAvmOzellikEditor,
        PazaryeriFiyatItem,
        NInputHtml,
        NInput,
        NDropDownTreeView,
        NRefresher,
        NSwitch,
        NFormField,
        NFormGrid
    },
    props: [
        'eptt',
        'stok',
        'alisKdv',
        'alisKdvDahil',
        'satisKdv',
        'satisKdvDahil',
        'alisFiyat',
        'satisFiyat'
    ],

    setup(props, {emit}) {
        const localState = reactive({
            epttRef: computed(() => props.eptt as EpttAvmPazaryeriItem),
            stokRef: computed(() => props.stok),
            kategoriler: ref([] as any[]),
            selectedKategoriItem: ref({} as any),

            alisKdvRef: computed({get: () => props.alisKdv, set: (val) => emit('update:alisKdv', val)}),
            alisKdvDahilRef: computed({get: () => props.alisKdvDahil, set: (val) => emit('update:alisKdvDahil', val)}),
            satisKdvRef: computed({get: () => props.satisKdv, set: (val) => emit('update:satisKdv', val)}),
            satisKdvDahilRef: computed({
                get: () => props.satisKdvDahil,
                set: (val) => emit('update:satisKdvDahil', val)
            }),
            alisFiyatRef: computed({get: () => props.alisFiyat, set: (val) => emit('update:alisFiyat', val)}),
            satisFiyatRef: computed({get: () => props.satisFiyat, set: (val) => emit('update:satisFiyat', val)}),
            showSearch: ref(false),


            dataSourceMarkalar: new CustomStore({
                key: 'id',
                load: (loadOptions) => {
                    return useEpttAvmKategoriService().postGetBrands({data: JSON.stringify(loadOptions) as any})
                }
            })
        })

        async function load() {
            const response = await useEpttAvmKategoriService().getGetAll(true);
            localState.kategoriler = response;

            localState.selectedKategoriItem = {
                key: localState.epttRef.kategoriId ?? 0,
                title: getCategoryName(localState.epttRef.kategoriId as number, response),
            }

        }

        function getCategoryName(key: number, root: any[]) {

            for (const rootElement of root) {
                const sonuc = getRecursiveCategoryName(key, rootElement)
                if (sonuc)
                    return sonuc;
            }
            return '';
        }

        function getRecursiveCategoryName(key: number, currentNode: any): string {
            let i, currentChild, result;

            if (key == currentNode.key) {
                return currentNode.title;
            } else {
                for (i = 0; i < currentNode.children?.length; i += 1) {
                    currentChild = currentNode.children[i];

                    result = getRecursiveCategoryName(key, currentChild);
                    if (result) {
                        return result as string;
                    }
                }
                return '';
            }
        }


        watch(() => localState.selectedKategoriItem, (newValue) => {
            localState.epttRef.kategoriId = Number(newValue.key)
        })

        load();


        return {
            ...toRefs(localState),
            load
        }
    }
})
