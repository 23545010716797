import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ce6d9450"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dx-card" }
const _hoisted_2 = {
  class: "stok-ad",
  style: {"overflow":"hidden","white-space":"normal","display":"flex","align-items":"center"}
}
const _hoisted_3 = { style: {"display":"flex","align-content":"center","flex-wrap":"wrap"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StokAramaInput = _resolveComponent("StokAramaInput")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_DxList = _resolveComponent("DxList")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!

  return (_openBlock(), _createBlock(_component_NFormGrid, _mergeProps({
    title: "Gruba Ait Stoklar",
    cols: "1fr",
    rows: "auto minmax(100px 1fr)",
    style: {"margin":"0"}
  }, _ctx.$attrs), {
    actions: _withCtx(() => [
      _createVNode(_component_NFormField, {
        label: "Gruba eklemek için stok ara: ",
        "grid-cols": "auto auto"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_StokAramaInput, {
            onCoklu: _ctx.coklu,
            onTek: _ctx.tekli
          }, null, 8, ["onCoklu", "onTek"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DxList, {
          ref: "list",
          "data-source": _ctx.stokRef.stoklar,
          hoverStateEnabled: false,
          focusStateEnabled: false,
          activeStateEnabled: false,
          style: {"width":"100%","height":"100%"},
          itemTemplate: "grupItem"
        }, {
          grupItem: _withCtx(({data,index}) => [
            _createVNode(_component_NGrid, {
              cols: "minmax(10px,1fr) auto auto auto",
              rows: "auto",
              style: {"padding":"0 8px","margin":"0","grid-template-rows":"auto"},
              gap: "8px"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, _toDisplayString(data.ad), 1),
                _createVNode(_component_NFormField, {
                  label: "Fiyat",
                  "grid-cols": "auto 80px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxNumberBox, {
                      value: data.fiyat,
                      "onUpdate:value": ($event: any) => ((data.fiyat) = $event)
                    }, null, 8, ["value", "onUpdate:value"])
                  ]),
                  _: 2
                }, 1024),
                _createVNode(_component_NFormField, {
                  label: "Miktar",
                  "grid-cols": "auto 50px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxNumberBox, {
                      value: data.miktar,
                      "onUpdate:value": ($event: any) => ((data.miktar) = $event),
                      min: 1
                    }, null, 8, ["value", "onUpdate:value"])
                  ]),
                  _: 2
                }, 1024),
                _createElementVNode("div", _hoisted_3, [
                  (_openBlock(), _createBlock(_component_NButton, {
                    icon: "trash",
                    type: "danger",
                    key: index,
                    size: "small",
                    onClick: ()=> _ctx.sil(index)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Sil")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                ])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["data-source"])
      ])
    ]),
    _: 1
  }, 16))
}