import {useStokFlatStore} from "@/modules/Stok/store/StokFlatStore";
import {StokUtils} from "@/modules/Stok/utils/StokUtils";
import {useStokStore} from "@/modules/Stok/store/StokStore";
import {Liste} from "@/api/models/Liste";

export const StokFlatSearchUtils = () => ({
    stokStore: useStokStore(),
    stokSearchInFlatStore(searchText: string) {
        const query = useStokFlatStore().getQuery()
        let list = [];

        if (StokFlatSearchUtils().stokStore.isStokKodSearch === true ){
            list = query.filter(this.stokKodFilter(searchText)).slice(0, 2).toArray();

        }
        if (StokFlatSearchUtils().stokStore.isBarkodSearch === true  && list.length == 0){
            list = query.filter(this.stokBarkodFilter(searchText)).slice(0, 2).toArray();

        }
        if (StokFlatSearchUtils().stokStore.isStokAdSearch === true  && list.length == 0){
            list = query.filter(this.stokAdFilter(searchText)).slice(0, 2).toArray();

        }


        if (StokFlatSearchUtils().stokStore.isBarkodSearch || StokFlatSearchUtils().stokStore.isStokKodSearch || StokFlatSearchUtils().stokStore.isStokAdSearch) {

            return list;
        }

        // Genel Arama

        // Stok Barkod arama
        list = query.filter(this.stokBarkodFilter(searchText)).slice(0, 2).toArray();

        // Stok Kod ile arama
        if (list.length == 0) {
            list = query.filter(this.stokKodFilter(searchText)).slice(0, 2).toArray();
        }

        // Stok genel arama
        if (list.length == 0) {
            list = query.filter(this.stokGenelFilter(searchText)).slice(0, 2).toArray()
        }

        return list;
    },
    stokBarkodFilter: (searchText: string) => (a: any) => {
        return (a.barkodStok == searchText || a.barkodItem == searchText)
    },
    stokKodFilter: (searchText: string) => (a: any) => {
        return a.kod?.icindeGeciyorMu(searchText)
    },
    stokAdFilter: (searchText: string) => (a: any) => {
        return StokUtils.aramaPasGecilen(searchText, a.ad) || a.ad?.icindeGeciyorMu(searchText)
    },

    stokGenelFilter: (searchText: string) => (a: any) => {

        let icindeGeciyorMu = (a.barkodStok == searchText || a.barkodItem == searchText) ||
            StokUtils.aramaPasGecilen(searchText, a.ad) ||
            a.ad?.icindeGeciyorMu(searchText) ||
            a.kod?.icindeGeciyorMu(searchText) ||
            a.itemKod?.icindeGeciyorMu(searchText) ||
            a.kategori?.icindeGeciyorMu(searchText) ||
            a.ozellikler?.icindeGeciyorMu(searchText) ||
            a.uyumluluklar?.findIndex((a:any) => a.kod?.icindeGeciyorMu(searchText) ) >= 0 ||
            a.uyumluluklar?.findIndex((a:any) => a.marka?.icindeGeciyorMu(searchText) ) >= 0

        if (StokFlatSearchUtils().stokStore.isSearchBarkodlar)
            icindeGeciyorMu = icindeGeciyorMu || a.barkodlar?.findIndex((a:string) => a.icindeGeciyorMu(searchText)) >= 0

        if (StokFlatSearchUtils().stokStore.isSearchKodlar)
            icindeGeciyorMu = icindeGeciyorMu || a.kodlar?.findIndex((a:string) => a.icindeGeciyorMu(searchText)) >= 0 ||
                a.ureticiKod?.icindeGeciyorMu(searchText)

        return icindeGeciyorMu;

    },
    stokAllFilter: (searchText: string) => (a: any) => {

        const icindeGeciyorMu = (a.barkodStok == searchText || a.barkodItem == searchText) || (StokUtils.aramaPasGecilen(searchText, a.ad) ||
            a.ad?.icindeGeciyorMu(searchText) ||
            a.kod?.icindeGeciyorMu(searchText) ||
            a.itemKod?.icindeGeciyorMu(searchText) ||
            a.kategori?.icindeGeciyorMu(searchText) ||
            a.ozellikler?.icindeGeciyorMu(searchText) ||
            a.birimFiyatlari?.findIndex((a:any) => a.barkod?.icindeGeciyorMu(searchText) ) >= 0 ||
            a.uyumluluklar?.findIndex((a:any) => a.kod?.icindeGeciyorMu(searchText) ) >= 0 ||
            a.uyumluluklar?.findIndex((a:any) => a.marka?.icindeGeciyorMu(searchText) ) >= 0
        )

        return icindeGeciyorMu
    }

})