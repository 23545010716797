import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {DepartmanView} from '../models/DepartmanView'

export const useDepartmanService = defineStore({
id: 'DepartmanService',
actions: {


	async getDefault()
	{
		const { request, response } = useApi(`/api/departman/default`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/departman/Liste/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getIdNameListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/departman/IdNameListe/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetir(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/departman/Getir/${id}/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postEkle(departmanView:DepartmanView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/departman/Ekle/${shared.firm.id}`,{ method:'POST', data:departmanView });
		await request();
		return response.value;
	},

	async postGuncelle(departmanView:DepartmanView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/departman/Guncelle/${shared.firm.id}`,{ method:'POST', data:departmanView });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/departman/Sil/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	}
}
});
