import {NModuleRoute} from "@/models/route.model";

export const StokModuleRoutes: NModuleRoute[] = [
    {
        id: "StokModule",
        order: 2,
        displayName: "Stoklar",
        icon: {
            type: "box"
        },
        children: [
            {
                id: "StokListesiServer",
                order: 0,
                displayName: "Stok Listesi",
                requiredRoles:{
                    readRoles:['Stok.Liste'],
                    insertRoles:[],
                    updateRoles:[],
                    deleteRoles:[]
                },
                icon: {
                    type: "box"
                },

                component: () =>
                    import(/* webpackChunkName: "Stoklar"*/ "../pages/StokListServerPage.vue")
            },
            {
                id: "StokListesiBasit",
                order: 0,
                displayName: "Stok Listesi (v.1)",
                requiredRoles:{
                    readRoles:['Stok.Liste'],
                    insertRoles:[],
                    updateRoles:[],
                    deleteRoles:[]
                },
                icon: {
                    type: "box"
                },

                component: () =>
                    import(/* webpackChunkName: "Stoklar"*/ "../pages/StoklarPageBasit.vue")
            },
            {
                id: "StokListesi",
                order: 0,
                displayName: "Stok Listesi (Detaylı)",
                requiredRoles:{
                    readRoles:['Stok.Liste'],
                    insertRoles:[],
                    updateRoles:[],
                    deleteRoles:[]
                },
                icon: {
                    type: "box"
                },

                component: () =>
                    import(/* webpackChunkName: "Stoklar"*/ "../pages/StoklarPage.vue")
            },

            {
                id: "StokListesiListView",
                order: 0,
                displayName: "Stok Listesi (Liste)",
                requiredRoles:{
                    readRoles:['Stok.Liste'],
                    insertRoles:[],
                    updateRoles:[],
                    deleteRoles:[]
                },
                icon: {
                    type: "box"
                },

                component: () =>
                    import(/* webpackChunkName: "Stoklar"*/ "../pages/StokListViewPage.vue")
            },
            {
                id: "StokForm",
                order: 0,
                displayName: "Stok Formu",
                requiredRoles:{
                    readRoles:['Stok.Liste'],
                    insertRoles:['Stok.Ekle'],
                    updateRoles:['Stok.Guncelle'],
                    deleteRoles:['Stok.Sil']
                },
                icon: {
                    type: "box"
                },
                component: () =>
                  import(/* webpackChunkName: "Stoklar"*/ "../pages/StokFormPage.vue")
            },
            {
                id: "HizmetMasraflar",
                order: 0,
                displayName: "Hizmet - Masraflar",
                requiredRoles:{
                    readRoles:['HizmetMasraf.Liste'],
                    insertRoles:[],
                    updateRoles:[],
                    deleteRoles:[]
                },
                icon: {
                    type: "box"
                },
                component: () =>
                  import(/* webpackChunkName: "Stoklar"*/ "../../HizmetMasraf/pages/HizmetMasraflarPage.vue")
            },

            {
                id: "StokHaraket",
                order: 0,
                displayName: "Hareketler",
                requiredRoles:{
                    readRoles:['Stok.Hareket.Liste'],
                    insertRoles:[],
                    updateRoles:[],
                    deleteRoles:[]
                },
                icon: {
                    type: "box"
                },
                component: () =>
                    import(/* webpackChunkName: "Stoklar"*/ "../pages/StokHareketPage.vue")
            },
            {
                id: "StokKategoriler",
                order: 0,
                displayName: "Kategoriler",
                requiredRoles:{
                    readRoles:['Stok.Kategori.Liste'],
                    insertRoles:['Stok.Kategori.Ekle'],
                    updateRoles:['Stok.Kategori.Guncelle'],
                    deleteRoles:['Stok.Kategori.Sil']
                },
                icon: {
                    type: "box"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Stoklar"*/ "../../StokKategori/pages/StokKategorilerPage.vue"
                        )
            },
            {
                id: "StokFisler",
                order: 0,
                displayName: "Fişler",
                requiredRoles:{
                    readRoles:['StokFis.Liste'],
                    insertRoles:['StokFis.Ekle'],
                    updateRoles:['StokFis.Guncelle'],
                    deleteRoles:['StokFis.Sil']
                },
                icon: {
                    type: "box"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Stoklar"*/ "../../Fis/pages/StokFisPage.vue"
                        )
            },
            {
                id: "BasitUretim",
                order: 0,
                displayName: "Genel Üretim",
                requiredRoles:{
                    readRoles:['Stok.BasitUretim.Liste'],
                    insertRoles:[],
                    updateRoles:[],
                    deleteRoles:[]
                },
                icon: {
                    type: "box"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "Stoklar"*/ "../../Stok/pages/BasitUretimPage.vue"
                        )
            },
            {
                id: "SacUretim",
                order: 0,
                displayName: "Sac Üretim",
                requiredRoles:{
                    readRoles:['Stok.SacUretim.Fis.List'],
                    insertRoles:['Stok.SacUretim.Fis.Insert'],
                    updateRoles:['Stok.SacUretim.Fis.Update'],
                    deleteRoles:['Stok.SacUretim.Fis.Delete']
                },
                icon: {
                    type: "box"
                },
                component: () =>
                    import(
                        /* webpackChunkName: "SacUretimListPage"*/ "../../SacUretim/pages/SacUretimListPage.vue"
                        )
            },
            {
                id: "GrupluStokList",
                order: 0,
                displayName: "Gruplu Stoklar",
                requiredRoles:{
                    readRoles:['Stok.Grup.Liste'],
                    insertRoles:['Stok.Grup.Ekle'],
                    updateRoles:['Stok.Grup.Guncelle'],
                    deleteRoles:['Stok.Grup.Sil']
                },
                icon: {
                    type: "box"
                },
                component: () =>
                  import(/* webpackChunkName: "GrupluStoklar"*/ "../../StokGroup/pages/StokGroupListPage.vue")
            },
            {
                id: "GrupluStokForm",
                order: 0,
                displayName: "Gruplu Stok Formu",
                requiredRoles:{
                    readRoles:['Stok.Grup.Ekle'],
                    insertRoles:['Stok.Grup.Ekle'],
                    updateRoles:['Stok.Grup.Guncelle'],
                    deleteRoles:['Stok.Grup.Sil']
                },
                icon: {
                    type: "box"
                },
                component: () =>
                  import(/* webpackChunkName: "GrupluStoklar"*/ "../../StokGroup/pages/StokGroupFormPage.vue")
            },
            {
                id: "HizliStokAra",
                order: 0,
                displayName: "Hızlı Stok",
                requiredRoles:{
                    readRoles:['Hizli.Stok.Ara'],
                    insertRoles:[],
                    updateRoles:[],
                    deleteRoles:[]
                },
                icon: {
                    type: "box"
                },
                component: () =>
                    import(/* webpackChunkName: "HizliStokAra"*/ "../../Stok/pages/HizliStokAra.vue")
            },

            {
                id: "Kampanyalar",
                order: 0,
                displayName: "Kampanyalar",
                requiredRoles:{
                    readRoles:['Kampanyalar.Liste'],
                    insertRoles:['Kampanyalar.Ekle'],
                    updateRoles:['Kampanyalar.Guncelle'],
                    deleteRoles:['Kampanyalar.Sil']
                },
                icon: {
                    type: "gift"
                },
                component: () =>
                    import("../../Kampanya/pages/KampanyaListPage.vue")
            },

        ]
    }
];
