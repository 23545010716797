
import {computed, defineComponent, reactive, ref, toRefs, watch,} from "vue";
import {PlasiyerEntity} from "@/api/models/PlasiyerEntity";
import {useCachedPlasiyerList} from "@/modules/Plasiyer/services/useCachedPlasiyerList";
import {selected} from "@devexpress/analytics-core/queryBuilder-metadata";

export default defineComponent({
  components: {},
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    firstSelect: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      items: ref([] as PlasiyerEntity[]),
      selected: computed({
        get: () => props.modelValue,
        set: val => emit("update:modelValue", val)
      })
    });

    load();

    async function load() {
      localState.items = await useCachedPlasiyerList().getUserPlasiyerList();

      // Plasiyerin şubesini setler
      setTimeout(() => {
        const e:any = {};
        e.value = localState.selected;
        valueChanged(e);
      }, 250)
    }
    watch(() => localState.items , () => {
      if (props.firstSelect == true) {
        if (localState.items && localState.items.length > 0)
          emit("update:modelValue",  localState.items[0].id ) ;
      }
    })

    async function valueChanged(e:any) {
      const deger = e?.value as string;
      if (deger && deger?.length > 0) {
        const subeId = localState.items?.find(x => x.id == deger)?.subeId
        emit('subeChanged', subeId);
      }

    }

    return {
      ...toRefs(localState),
      valueChanged
    };
  }
});
