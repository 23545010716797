
import { NColumn, NSortOrder } from "@/models";
import { computed, defineComponent, PropType, toRef, ref } from "vue";

export default defineComponent({
  name: "NDataTable",
  props: {
    col: {
      type: String,
      default: "span 1"
    },
    row: {
      type: String,
      default: "span 1"
    },
    columns: {
      type: Array as PropType<NColumn[]>,
      default: [] as NColumn[]
    },
    data: {
      type: Array,
      default: [] as unknown[]
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const columns = toRef(props, "columns");

    const visibleColumns = computed(() => columns.value.filter(c => !c.hidden));

    const gridTemplateColumns = computed(() => {
      const widthArray = visibleColumns.value.map(c => c.width);
      if (!widthArray.includes("1fr")) {
        widthArray[widthArray.length - 1] = "1fr";
      }
      return widthArray.join(" ");
    });

    const sortBy = ref("");
    const sortOrder = ref<NSortOrder>(NSortOrder.None);

    function changeSortOrder() {
      switch (sortOrder.value) {
        case NSortOrder.None:
          sortOrder.value = NSortOrder.Asc;
          break;
        case NSortOrder.Asc:
          sortOrder.value = NSortOrder.Desc;
          break;
        case NSortOrder.Desc:
          sortOrder.value = NSortOrder.None;
          break;
      }
    }

    function sortByColumn(column: NColumn) {
      changeSortOrder();
      sortBy.value = column.prop;
      emit("sort", { sortBy: sortBy.value, sortOrder: sortOrder.value });
    }

    return {
      visibleColumns,
      gridTemplateColumns,
      sortByColumn,
      sortBy,
      sortOrder
    };
  }
});
