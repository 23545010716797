
import { defineComponent, reactive, ref, toRefs, watch } from "vue";
import { StokFlatView } from "@/api/models/StokFlatView";
import { BasitUretimView } from "@/api/models/BasitUretimView";
import { BasitUretimDurum, BasitUretimDurumList } from "@/api/models/BasitUretimDurum";
import { BasitUretimTip } from "@/api/models/BasitUretimTip";
import { BasitUretimItemView } from "@/api/models/BasitUretimItemView";
import { DateUtils } from "@/utils/date/DateUtils";
import { StokBirimItem } from "@/api/models/StokBirimItem";
import { useStokService } from "@/api/services/StokService";
import { uuid } from "vue-uuid";
import DepoSelectBox from "@/modules/Ortak/components/DepoSelectBox.vue";
import { DepoFlatView } from "@/api/models/DepoFlatView";
import { useSubeService } from "@/api/services/SubeService";
import { BasitUretimHizmetItem } from "@/api/models/BasitUretimHizmetItem";
import { useBasitUretimService } from "@/api/services/BasitUretimService";
import BasitUretimProp from "@/modules/Stok/models/BasitUretimProp";
import NCard from "@/components/Card/NCard.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import StokAramaInput from "@/modules/Fis/pages/StokAramaInput.vue";
import NButton from "@/components/Button/NButton.vue";
import { confirm } from "devextreme/ui/dialog";
import { useStokFlatStore } from "@/modules/Stok/store/StokFlatStore";
import { useLayoutStore } from "@/layouts/ApplicationLayout/context";
import { usePopupService } from "@/services/popupService";
import UretimTanimDialogs from "@/modules/Ayarlar/Tanımlar/dialogs/UretimTanimDialogs.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import { UretimTanimView } from "@/api/models/UretimTanimView";
import { useListelerService } from "@/api/services/ListelerService";
import NTextarea from "@/components/Textarea/NTextarea.vue";
import { FisTip, FisTipList } from "@/api/models/FisTip";
import { useEvrakNoService } from "@/api/services/EvrakNoService";
import { IdName } from "@/api/models/IdName";
import { GenelUtils } from "@/modules/Ortak/utils/GenelUtils";
import CokluDokuman from "@/modules/Ortak/components/CokluDokuman.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import { BasitUretimEntity } from "@/api/models/BasitUretimEntity";
import GenelUretimRecetreSelectDailogs from "@/modules/Stok/dialogs/GenelUretimRecetreSelectDailogs.vue";
import {DevExtremeUtils} from "@/devextreme/DevExtremeUtils";
import NDxStateStoring from "@/devextreme/NDxStateStoring.vue";
import {useBasitUretimStore} from "@/modules/Stok/store/BasitUretimStore";
import { DxDataGrid } from "devextreme-vue/data-grid";
import {StokUtils} from "@/modules/Stok/utils/StokUtils";
import {StokFiyatGuncelleView} from "@/api/models/StokFiyatGuncelleView";
import StokHareketDialogList from "@/modules/Stok/dialogs/StokHareketDialogList.vue";


export default defineComponent({
  components: {
      StokHareketDialogList,
    DxDataGrid,
    NDxStateStoring,
    GenelUretimRecetreSelectDailogs,
    NLoadingPanel,
    CokluDokuman,
    NTextarea,
    NFormField,
    UretimTanimDialogs,
    NButton,
    NGrid,
    StokAramaInput,
    NFormGrid,
    NCard,
    DepoSelectBox
  },
  props: {},
  setup() {
    const localState = reactive({
      fisTipList: FisTipList,
      makinaHatlari: ref([] as string[]),
      uretimTanimListesi: ref([] as UretimTanimView[]),
      uretimTanimShow: ref(false),
      model: ref({} as BasitUretimView),
      stokSelectedItem: ref({} as StokFlatView),
      tabs: [
        { label: "Üretilecek Stoklar" },
        { label: "Kullanılan Stoklar" },
        { label: "Fire" },
        { label: "Giderler" }
      ],
      selectedTabSol: ref(0),
      selectedTab: ref(0),
      dataSource: ref([] as BasitUretimItemView[]),
      basitUretimDurumListRef: BasitUretimDurumList,
      stokBirimItemRef: ref([] as StokBirimItem[]),
      depoIdRef: ref(""),
      subeIdRef: ref(),
      subeNameRef: ref(),
      depoNameRef: ref(),
      subeDepoList: ref([] as DepoFlatView[]),
      kullanilanStoklarToplam: ref(0 as number),
      fireStoklarToplam: ref(0 as number),
      giderlerToplam: ref(0 as number),
      stoklarArama: ref([] as StokFlatView[]),
      uretimDataGrid: ref(),
      receteListesi: ref([] as IdName[]),
      planListesi: ref([] as IdName[]),
      receteId: ref(""),
      planId: ref(""),
      receteDialogShow: ref(false),
      receteDialogListe: ref([] as BasitUretimView[]),
      receteDialogStokAd: ref("" as string),

      stokId: ref(""),
      itemId: ref(""),
      stokAdi: ref(""),
      stokHareketShowDialog: ref(false),
    });

    watch(() => localState.model.isIstasyonu, () => {
      if (localState.model.isIstasyonu && localState.model.isIstasyonu.length > 0) {
        localState.makinaHatlari = localState.uretimTanimListesi.find(a => a.isIstasyonu == localState.model.isIstasyonu)?.hatlar as string[];
      } else {
        localState.model.makinaHatti = "";
        localState.makinaHatlari = [];
      }
    });

    // watch(() => localState.model.hareketler?.filter(a=>a.uretimTip != BasitUretimTip.UretilenStoklar) , () => {
    //
    //   if (localState.model.id && localState.model.id.length > 0) {
    //     //
    //   } else {
    //     if (localState.model?.hareketler?.filter(a => a.uretimTip == BasitUretimTip.UretilenStoklar).length == 1) {
    //       const uretilenStok = localState.model.hareketler.find(a => a.uretimTip == BasitUretimTip.UretilenStoklar);
    //
    //
    //       let fiyat = 0;
    //       localState.model.hareketler.filter(a => a.uretimTip != BasitUretimTip.UretilenStoklar).forEach((satir) => {
    //         fiyat = fiyat * satir.miktar! * satir.carpan! * satir.maliyet!;
    //       });
    //       if (uretilenStok && uretilenStok.maliyet && uretilenStok.miktar && uretilenStok.carpan)
    //         uretilenStok.maliyet = fiyat / (uretilenStok.miktar * uretilenStok.carpan);
    //
    //
    //     }
    //   }
    // } ,  {deep : true });


    watch(() => localState.model, () => {
      if (localState.model.hareketler && localState.model.hareketler.length > 0) {
        let kullanilanToplam = 0;
        let fireToplam = 0;

        localState.model.hareketler.forEach(har => {
          if (har.uretimTip == BasitUretimTip.UretimdeKullanilan)
            kullanilanToplam += har.maliyet! * har.miktar! * har.carpan!;
          if (har.uretimTip == BasitUretimTip.Fire)
            fireToplam += har.maliyet! * har.miktar! * har.carpan!;

        });

        localState.fireStoklarToplam = fireToplam;
        localState.kullanilanStoklarToplam = kullanilanToplam;

      }

      if (localState.model.hizmetGiderleri && localState.model.hizmetGiderleri.length > 0) {
        const toplamArrtirilacakTutar = localState.fireStoklarToplam + localState.kullanilanStoklarToplam;
        let giderlerToplam = 0;
        localState.model.hizmetGiderleri.forEach(gider => {
          if (gider.tutarsal && gider.tutarsal > 0)
            giderlerToplam += gider.tutarsal;
          if (toplamArrtirilacakTutar > 0) {
            if (gider.oransal && gider.oransal > 0)
              giderlerToplam += toplamArrtirilacakTutar / 100 * gider.oransal;
          }
        });

        localState.giderlerToplam = giderlerToplam;
      }

    }, { deep: true });


    watch(() => localState.selectedTab, () => {
      formRefresh();

    });


    async function load() {
      await tanimlarLoad();
      localState.receteListesi = await useBasitUretimService().getReceteListe();
      localState.planListesi = await useBasitUretimService().getPlanListesi();
      const store = useBasitUretimStore().basitUretimFormProp as BasitUretimProp;

      if (store.id && store.id.length > 0) {
        localState.model = await useBasitUretimService().getView(store.id);
        localState.model.baslamaTarih = DateUtils.convertInputDateFromStringDateTime(new Date((Date.parse(localState.model!.baslamaTarih!))).toISOString());
        localState.model.bitisTarih = DateUtils.convertInputDateFromStringDateTime(new Date((Date.parse(localState.model!.bitisTarih!))).toISOString());
      } else {
        localState.model = {
          baslamaTarih: DateUtils.convertInputDateFromStringDateTime(new Date().toISOString()),
          bitisTarih: DateUtils.convertInputDateFromStringDateTime(new Date().toISOString()),
          durum: BasitUretimDurum.DevamEdiyor,
          hareketler: [],
          hizmetGiderleri: [],
          dokumanlar: [],
          fisTip: FisTip.Uretim,
          evrakNo: await useEvrakNoService().getYeniEvrakNo(10)
        } as BasitUretimView;
      }

      if (localState.model && localState.model.hareketler && localState.model.hareketler.length > 0)
        localState.dataSource = localState.model.hareketler.filter(a => a.uretimTip == BasitUretimTip.UretilenStoklar);
      localState.subeDepoList = await useSubeService().getDepoFlatList();
    }

    function getDataSource() {
      if (localState.selectedTab == 0)
        return localState.model.hareketler?.filter(a => a.uretimTip == BasitUretimTip.UretilenStoklar);
      if (localState.selectedTab == 1)
        return localState.model.hareketler?.filter(a => a.uretimTip == BasitUretimTip.UretimdeKullanilan);
      if (localState.selectedTab == 2)
        return localState.model.hareketler?.filter(a => a.uretimTip == BasitUretimTip.Fire);
    }

    async function tanimlarLoad() {
      localState.uretimTanimListesi = await useListelerService().getUretimTanimlari();
      localState.model.isIstasyonu = "";
      localState.model.makinaHatti = "";
    }

    async function stokEkle(stok: StokFlatView) {

      const uretimItem = {
        id: uuid.v4(),
        birim: stok.birim,
        carpan: 1,
        miktar: 1,
        maliyet: stok.alisFiyat,
        depoId: localState.depoIdRef,
        stokAd: stok.ad,
        stokId: stok.stokId,
        subeId: localState.subeIdRef,
        kod: stok.kod,
        barkod: (stok.itemId && stok.itemId.length > 0) ? stok.barkodItem : stok.barkodStok,
        itemId: stok.itemId


      } as BasitUretimItemView;

      if (localState.selectedTab === 0) {
        let receteler: BasitUretimEntity[] = [];

        uretimItem.uretimTip = BasitUretimTip.UretilenStoklar;
        localState.model.hareketler!.push(uretimItem);

        if (stok.itemId && stok.itemId.length > 0)
          receteler = await useBasitUretimService().getStokReceteItemListe(stok.stokId as string, stok.itemId as string);
        else
          receteler = await useBasitUretimService().getStokReceteListesi(stok.stokId as string);

        if (receteler && receteler.length > 0) {
          if (await GenelUtils.onayDiyalogu("Bu Stoğun , Reçete Listesi Bulundu,  Kullanılan Stoklar Otomatik Eklensin mi ? ", stok.ad + "  - Reçete Listesi")) {
            if (receteler && receteler.length == 1) {
              const kullanilanStoklar = receteler[0].hareketler?.filter(a => a.uretimTip == BasitUretimTip.UretimdeKullanilan);

              if (kullanilanStoklar && kullanilanStoklar.length > 0) {
                kullanilanStoklar.forEach((a) => localState.model.hareketler?.push(a));
                succesAddKullanilanStok();
              }
            } else {
              localState.receteDialogStokAd = stok.ad as string;
              localState.receteDialogListe = receteler;
              localState.receteDialogShow = true;
            }
          }
        }

        formRefresh();
      }
      if (localState.selectedTab === 1) {
        uretimItem.uretimTip = BasitUretimTip.UretimdeKullanilan;
        localState.model.hareketler!.push(uretimItem);
        formRefresh();
      }
      if (localState.selectedTab === 2) {
        uretimItem.uretimTip = BasitUretimTip.Fire;
        localState.model.hareketler!.push(uretimItem);
        formRefresh();
      }
    }

    function formRefresh() {
      localState.uretimDataGrid?.instance?.refresh();
      //console.log("model", localState.model);
    }

    async function clickBirim(data: any) {
      localState.stokBirimItemRef = await useStokService().getBirimItem(data.stok.id);
    }

    function changedBirim(e: any, row: any) {
      const result = localState.stokBirimItemRef.find(a => a.birim == e.value);
      if (result) {
        row.birim = result.birim;
        row.carpan = result.carpan;
      }
    }


    watch(() => localState.receteId, async () => {
      if (localState.receteId && localState.receteId.length > 0) {
        if (await GenelUtils.onayDiyalogu(
          "Tüm üretim satırları silinip , Seçtiğiniz recete uygulanacaktır. Onaylıyor musunuz ?",
          "Reçete Uygulama"
        )) {
          localState.model = await useBasitUretimService().getView(localState.receteId);
          localState.model.id = "";
        } else {
          localState.receteId = "";
        }

      }
    });
    watch(() => localState.planId, async () => {
      if (localState.planId && localState.planId.length > 0) {
        if (await GenelUtils.onayDiyalogu(
          "Tüm üretim satırları silinip , Seçtiğiniz plan uygulanacaktır. Onaylıyor musunuz ?",
          "Plan Uygulama"
        )) {
          localState.model = await useBasitUretimService().getView(localState.planId);
          localState.model.id = "";
        } else {
          localState.planId = "";
        }

      }
    });

    function cokluEkle(stoklar: StokFlatView[]) {
      if (stoklar && stoklar.length > 0) {
        stoklar.forEach((s) => {
          stokEkle(s);
        });
      }
    }

    function yeniGiderEkle() {
      const gider = {} as BasitUretimHizmetItem;
      localState.model.hizmetGiderleri!.push(gider);
    }

    async function save() {
      if (localState.model.id && localState.model.id.length > 0) {
        await useBasitUretimService().putGuncelle(localState.model);
        await saveAndClose();
      } else {
        await useBasitUretimService().postEkle(localState.model);
        await saveAndClose();
      }
    }

    async function removeGider(i: number) {
      if (localState.model && localState.model.hizmetGiderleri && await confirm("Satırı Silmek İstiyor Musunuz ? ", "Satır Silme İşlemi"))
        localState.model.hizmetGiderleri.splice(i, 1);
    }

    async function satirRemove(e: any) {
      if (await confirm("Satırı Silmek İstiyor Musunuz ? ", "Satır Silme İşlemi")) {
        const result = localState.model?.hareketler!.findIndex(a => a.id == e.row?.data?.id);
        if (result > -1) {
          localState.model?.hareketler!.splice(result, 1);
        }
        formRefresh();
      }
    }

    async function deleteAndClose() {
      usePopupService().pushDangerNotification({
        title: "Silindi",
        body: localState.model.evrakNo + " Nolu Üretim Başarıyla Silindi"
      });
      useLayoutStore().closeCurrentTab();
    }

    async function saveAndClose() {
      usePopupService().pushSuccessNotification({
        title: "Başarılı",
        body: localState.model.evrakNo + " Nolu Üretim kaydedildi"
      });
      useLayoutStore().closeCurrentTab();
    }

    async function hareketSil() {
      if (await GenelUtils.silmeOnayiDiyalogu()) {
        if (localState.model &&
          localState.model.durum == BasitUretimDurum.Tamamlandi &&
          localState.model.fisTip == FisTip.Uretim
        ) {
          if (await GenelUtils.onayDiyalogu("Tamamlanmış bir üretimi , gerçekten silmek istiyor musunuz ? ", "Silme Onayı"))
            await useBasitUretimService().deleteSil(localState.model.id as string) , await deleteAndClose();

        } else {
          await useBasitUretimService().deleteSil(localState.model.id as string) , await deleteAndClose();
        }
      }
    }

    function selecetedDblClik(view: BasitUretimView) {
      if (view) {
        const result = view.hareketler?.filter(a => a.uretimTip == BasitUretimTip.UretimdeKullanilan);
        if (result && result.length > 0) {
          result.forEach((x) => localState.model.hareketler?.push(x));
          localState.receteDialogShow = false;
          succesAddKullanilanStok();
        }
      }
    }

    function succesAddKullanilanStok() {
      usePopupService().pushSuccessNotification({
        title: "Kullanılan Stoklar",
        body: "Reçetenin kulanıılan stokları , başarıyla eklendi . Lütfen Kullanılan Stok TABINI kontrol ediniz !"


      });
    }

    async function loadState() {
      return await DevExtremeUtils.loadState(localState.uretimDataGrid?.instance);
    }

      function rightMenu(e: any) {
          e.items = [
              { text: "Stoğa Git", onClick: () => StokUtils.openStokForm(e?.row?.data?.stokId as string, false), icon: "plus" },
              {
                  text: "Stok Hareketleri", onClick: () => {
                      const satir = e?.row?.data as StokFiyatGuncelleView;
                      if (satir) {

                          localState.stokId = satir.stokId as string;
                          localState.stokAdi = satir.stokAd as string;
                          localState.stokHareketShowDialog = true;
                      }
                  }, icon: "verticalaligncenter"
              }
          ];

          if (e.row == undefined)
              e.items = undefined;
      }

    return {
      ...toRefs(localState),
      cokluEkle,
      saveAndClose,
      save,
      stokEkle,
      clickBirim,
      changedBirim,
      yeniGiderEkle,
      removeGider,
      getDataSource,
      satirRemove,
      tanimlarLoad,
      hareketSil,
      selecetedDblClik,
      load,
      loadState,
      rightMenu
    };
  }
});
