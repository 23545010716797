import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "dx-card",
  style: {"height":"100%","width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NIconIonic = _resolveComponent("NIconIonic")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_DxLoadPanel = _resolveComponent("DxLoadPanel")!
  const _component_DxGroupPanel = _resolveComponent("DxGroupPanel")!
  const _component_DxGrouping = _resolveComponent("DxGrouping")!
  const _component_DxScrolling = _resolveComponent("DxScrolling")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxColumnChooser = _resolveComponent("DxColumnChooser")!
  const _component_DxColumnFixing = _resolveComponent("DxColumnFixing")!
  const _component_DxStateStoring = _resolveComponent("DxStateStoring")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!

  return (_openBlock(), _createBlock(_component_NLoadingPanel, {
    action: _ctx.load,
    title: "Önceki Hareketler Yükleniyor..."
  }, {
    default: _withCtx(() => [
      _createVNode(_component_NGrid, {
        cols: "1fr",
        rows: "25px minmax(0,1fr)",
        gap: "10px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NFormField, {
            "grid-cols": "1px 1fr 20px",
            label: " "
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_NTag, { type: "primary" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.getName()), 1)
                  ]),
                  _: 1
                })
              ]),
              _createVNode(_component_NIconIonic, {
                tip: "code-working",
                boyut: "22px",
                renk: _ctx.genisListe == true ? '#78b81c' : '#34495e',
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.genisListe == false ? _ctx.genisListe = true  : _ctx.genisListe=false )),
                title: "Geniş Liste"
              }, null, 8, ["renk"])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_DxDataGrid, {
              "data-source": _ctx.cariOncekiStoklar,
              ref: "faturaOncekiStoklarDataGrid",
              "row-alternation-enabled": true,
              "allow-column-reordering": true,
              "allow-column-resizing": true,
              "word-wrap-enabled": true,
              "column-resizing-mode": "widget",
              "show-row-lines": true,
              "show-borders": true,
              selection: { mode: 'single' },
              style: {"height":"100%","width":"100%"},
              onRowDblClick: _cache[1] || (_cache[1] = (e)=>_ctx.dblClick(e.data))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxLoadPanel, { enabled: true }),
                _createVNode(_component_DxGroupPanel, { visible: false }),
                _createVNode(_component_DxGrouping, { "auto-expand-all": false }),
                _createVNode(_component_DxScrolling, { mode: "infinite" }),
                _createVNode(_component_DxHeaderFilter, { visible: true }),
                _createVNode(_component_DxFilterRow, { visible: true }),
                _createVNode(_component_DxColumnChooser, { enabled: false }),
                _createVNode(_component_DxColumnFixing, { enabled: false }),
                _createVNode(_component_DxStateStoring, {
                  enabled: true,
                  storageKey: "FaturaCariOncekiStoklarDataGrid"
                }),
                _createVNode(_component_DxColumn, {
                  width: "150px",
                  caption: "Ürün Adı",
                  "data-field": "ad"
                }),
                _createVNode(_component_DxColumn, {
                  alignment: "right",
                  width: "90px",
                  caption: "Net Fiyat",
                  "data-field": "getNetFiyat",
                  format: (a)=>a.format(2,2)
                }, null, 8, ["format"]),
                _createVNode(_component_DxColumn, {
                  alignment: "right",
                  width: "90px",
                  caption: "Net Fiyat (KdvLi)",
                  "data-field": "getNetFiyatKdvli",
                  calculateCellValue: (d) => d.getNetFiyat * ( 1+ (d.kdv / 100 )) ,
                  format: (a)=>a.format(2,2)
                }, null, 8, ["calculateCellValue", "format"]),
                _createVNode(_component_DxColumn, {
                  alignment: "right",
                  width: "80px",
                  caption: "Miktar",
                  "data-field": "miktar",
                  format: (a)=>a.format(2,2),
                  "data-type": "number"
                }, null, 8, ["format"]),
                _createVNode(_component_DxColumn, {
                  alignment: "right",
                  width: "40px",
                  caption: "Carpan",
                  "data-field": "carpan",
                  format: (a)=>a.format(2,2),
                  "data-type": "number"
                }, null, 8, ["format"]),
                _createVNode(_component_DxColumn, {
                  width: "45px",
                  caption: "Kdv",
                  "data-field": "kdv"
                }),
                _createVNode(_component_DxColumn, {
                  alignment: "right",
                  width: "80px",
                  caption: "Doviz",
                  "data-field": "doviz",
                  calculateCellValue: (a)=> _ctx.dovizTipList.find(x=>x.value == a.doviz)?.description 
                }, null, 8, ["calculateCellValue"]),
                _createVNode(_component_DxColumn, {
                  alignment: "right",
                  width: "60px",
                  caption: "Kur",
                  "data-field": "kur",
                  format: (a)=>a.format(2,2),
                  "data-type": "number"
                }, null, 8, ["format"]),
                _createVNode(_component_DxColumn, {
                  alignment: "right",
                  width: "100px",
                  caption: "Tarih",
                  "data-field": "kayitTarihi",
                  "data-type": "date"
                }),
                _createVNode(_component_DxColumn, {
                  alignment: "right",
                  width: "100px",
                  caption: "İade Olan Miktar",
                  "data-field": "toplamIade",
                  format: (a)=>a.format(2,2),
                  "data-type": "number"
                }, null, 8, ["format"]),
                _createVNode(_component_DxColumn, {
                  width: "80px",
                  caption: "Kod",
                  "data-field": "kod"
                })
              ]),
              _: 1
            }, 8, ["data-source"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["action"]))
}