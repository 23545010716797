
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import { IdName } from "@/api/models/IdName";
import NDropDownListBox from "@/components/Dropdown/NDropDownListBox.vue";
import { useTrendyolDegerlerService } from "@/api/services/TrendyolDegerlerService";
import { TrendyolValuesEntity } from "@/api/models/TrendyolValuesEntity";
import { TrendyolValuesView } from "@/api/models/TrendyolValuesView";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";

export default defineComponent({
  components: { NLoadingPanel, NDropDownListBox  },
  props: {
    id: {
      type: String
    },
    show: {
      type: Boolean,
      default: false
    },
    propertyName: {  type:String },
    nesilceValues:{type:Array},
    trendyolValues:{type:Array},
    kategoriId:{type:Number},
  },
  setup(props, { emit }) {
    const localState = reactive({
      model: ref([] as any[]),
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val)
      }),
      subTitle: ref(props.propertyName),
      title: ref("Trendyol Özellik Eşletirme Formu"),
      trendyolValuesDb:ref([] as TrendyolValuesEntity[])
    });

    async function load() {

      localState.trendyolValuesDb = await useTrendyolDegerlerService().getGetirOzellik(props.propertyName as string,props.kategoriId as number)
      localState.model = [];
        props.nesilceValues?.forEach((value:any,i:number)=>{
          const findVal = findValueId(value);
          localState.model.push({id:i, categoryId:props.kategoriId, property:props.propertyName, value:value, valueId:findVal})
        });

    }

    function findValueId(value:string){
      const trendyolValue = props.trendyolValues?.find((a:any)=> a.name== value) as any;
      if (trendyolValue)
        return trendyolValue.id as number

      const trendyolValueDb = localState.trendyolValuesDb.find(a => a.value == value);
      if (trendyolValueDb)
        return trendyolValueDb.valueId;

      return 0
    }

    async function save() {
      for (let i = 0; i < localState.model.length; i++) {
        const trendyolValue:TrendyolValuesView = localState.model[i];
        await useTrendyolDegerlerService().postEkle(trendyolValue);
      }

      localState.showRef = false;
    }

    load();

    return {
      ...toRefs(localState),
      load,
      save
    };
  }
});
