import { CekSenetDurum, CekSenetDurumList } from "@/api/models/CekSenetDurum";
import { CekSenetTip, CekSenetTipList } from "@/api/models/CekSenetTip";
import { CekSenetItemView } from "@/api/models/CekSenetItemView";
import { CekSenetFlatView } from "@/api/models/CekSenetFlatView";

export const CekSenetUtils = {
  getDurumDescription(durum: CekSenetDurum) {
    const result = CekSenetDurumList.find(a => a.value === durum);
    if (result) return result.description;
  },
  getTipDescription(tip: CekSenetTip) {
    const result = CekSenetTipList.find(a => a.value === tip);
    if (result) return result.description;
  },
  vadeTicks(vade: string, tutar: number) {
    const ticks = Date.parse(vade.split("T")[0]);
    const totalTick = tutar * ticks;
    return totalTick;
  },


};
