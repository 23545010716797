
import {defineComponent, reactive, ref, toRefs} from "vue";
import {dxDataGridColumn} from "devextreme/ui/data_grid";
import NViewGrid from "@/components/ViewGrid/NViewGrid.vue";
import {ImporterType} from "@/api/models/ImporterType";
import ImporterDialog from "@/modules/Import/dialogs/NImporterDialog.vue";
import NInputHtml from "@/components/Input/NInputHtml.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import {useServiceService} from "@/api/services/ServiceService";
import useApi from "@/api/useApi";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NInput from "@/components/Input/NInput.vue";
import AmazonEditor from "@/modules/Stok/components/Amazon/AmazonEditor.vue";
import NCard from "@/components/Card/NCard.vue";
import NDropDownTreeViewMultiSelect from "@/components/DropDownTreeView/NDropDownTreeViewMultiSelect.vue";
import {KampanyaFaturaView} from "@/api/models/KampanyaFaturaView";
import KampanyaDropDownList from "@/modules/Kampanya/components/KampanyaDropDownList.vue";
import {useSharedStore} from "@/store";
import {FaturaView} from "@/api/models/FaturaView";
import {FaturaSatirItem} from "@/api/models/FaturaSatirItem";
import {useStokStore} from "@/modules/Stok/store/StokStore";
import {useAccountServiceExtend} from "@/api/extends/AccountServiceExtend";
import NButton from "@/components/Button/NButton.vue";
import KampanyaKategoriSelectDialog from "@/modules/Kampanya/dialogs/KampanyaKategoriSelectDialog.vue";
import HizliSatisListesi from "@/modules/HizliSatis/components/HizliSatisStokListesi.vue";
import TestComponent from "@/modules/TestModule/components/TestComponent.vue";
import EPttAvmStokForm from "@/modules/Pazaryerleri/EpttAvm/components/EpttAvmStokFormu.vue";
import {StokView} from "@/api/models/StokView";
import {EpttAvmPazaryeriItem} from "@/api/models/EpttAvmPazaryeriItem";
import { useStokFlatStore } from "@/modules/Stok/store/StokFlatStore";


export default defineComponent({
  components: {NViewGrid, TestComponent},
  setup() {
    const localState = reactive({
      epttRef: ref({} as EpttAvmPazaryeriItem),
      stokRef: ref({} as StokView),
      alisKdvRef: ref(18),
      alisKdvDahilRef: ref(true),
      satisKdvRef: ref(18),
      satisKdvDahilRef: ref(true),
      alisFiyatRef: ref(100),
      satisFiyatRef: ref(150),
      isLoaded:ref(false)
    });
    const store = useSharedStore();
    store.firm.id = 'BysHome';
    store.firm.name = 'BysHome';
    store.firm.period = '2023';

    async function load() {
      const shared = useSharedStore();
      shared.firm.id = "NetWobil";
      shared.firm.period = "2021"
      // await useAccountServiceExtend().postLogin({
      //    userName: 'info@netwobil.com',
      //    password: '453216*'
      //  }, false)
      // localState.isLoaded = true;
   //   await useStokFlatStore().load()
   //   await useStokStore().load()
    }

    load()


    return {
      ...toRefs(localState),
    };
  }
});
