import mitt, { Handler } from 'mitt';
import { defineStore } from 'pinia';
import { useSignal } from '@/services/useSignal';
//signal document content
import {RecordStatus} from '../models/RecordStatus'
import {IdName} from '../models/IdName'
import {NotificationType} from '../models/NotificationType'
import {Scope} from '../models/Scope'
import {NotificationStatus} from '../models/NotificationStatus'
import {NotificationEntity} from '../models/NotificationEntity'
import {NameObject} from '../models/NameObject'

export const onNotificationHubManagerSignal = defineStore({
id: 'NotificationHubManagerSignal',
actions: {


	Added(handler:Handler<NotificationEntity>)
	{
		useSignal().on('NotificationHubManager.Added',handler);
	},

	Changed(handler:Handler<NotificationEntity>)
	{
		useSignal().on('NotificationHubManager.Changed',handler);
	},

	ChangedAll(handler:Handler<string>)
	{
		useSignal().on('NotificationHubManager.ChangedAll',handler);
	},

	ChangedField(handler:Handler<NameObject>)
	{
		useSignal().on('NotificationHubManager.ChangedField',handler);
	},

	Deleted(handler:Handler<string>)
	{
		useSignal().on('NotificationHubManager.Deleted',handler);
	}
}
});
