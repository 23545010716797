
export enum CicekSepetiDeliveryType{
Secilmedi=0,
ServisAracıİleGönderim=1,
KargoİleGönderim=2,
KargoArtıServisAracıİleGönderim=3
}

export const CicekSepetiDeliveryTypeList = [
{key:'Secilmedi', value:0, description:''},
{key:'ServisAracıİleGönderim', value:1, description:''},
{key:'KargoİleGönderim', value:2, description:''},
{key:'KargoArtıServisAracıİleGönderim', value:3, description:''}
]
