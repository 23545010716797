
import {computed, defineComponent, reactive, ref, toRefs} from "vue";

import {useFileSettingsService} from "@/api/services/FileSettingsService";
import {DateUtils} from "@/utils/date/DateUtils";
import NMultipleDocumentUploader from "@/components/Uploader/NMultipleDocumentUploader.vue";
import {DocumentItem} from "@/api/models/DocumentItem";
import NButton from "@/components/Button/NButton.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NListView from "@/components/ListView/NListView.vue";
import {useFileSettingsServiceCache} from "@/api/servicesCache/FileSettingsServiceCache";
import {alert, confirm} from "devextreme/ui/dialog";
import NFormField from "@/components/FormField/NFormField.vue";
import NTextarea from "@/components/Textarea/NTextarea.vue";

export default defineComponent({
  components: { NTextarea, NFormField, NListView, NButton, NMultipleDocumentUploader},
  props: {
    modelValue: {
      type: Array
    }
  },
  setup(props, {emit}) {
    const localState = reactive({

      model: computed({
        get: () => props.modelValue,
        set: val => emit('update:modelValue', val),
      }),
      serverUrl: ref(''),
    })

    load();

    async function load() {
      localState.serverUrl = await useFileSettingsServiceCache().getServerUrl();
    }

    function yeniDokuman() {
      const dokuman = {
        url: [],
        aciklama: "",
        tarih: DateUtils.convertInputDateFromStringDate(
            new Date().toISOString()
        )
      } as DocumentItem;

      if (localState.model && localState.model.length > 3){
        alert("<h4>Döküman Sınırına Ulaşıldı</h4>" +
            "<h5>İzin verilen döküma sınırı = 4</h5r>" , "Döküman Sınırı") ;
        return;
      }


      if (localState.model) {
        localState.model.push(dokuman);
        emit('update:modelValue', localState.model);
      }

    }

    async function silDokuman(i: number) {
      if (i > -1) {
        if (await confirm("Dökümanı Silmek İstiyor Musunuz ? ", "Döküman Silme"))
          localState.model?.splice(i, 1);
      }
    }

    return {
      ...toRefs(localState),
      yeniDokuman,
      silDokuman
    };
  }
});
