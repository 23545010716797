
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import CicekSepetiOzellikEditor from "@/modules/Pazaryerleri/CicekSepeti/components/CicekSepetiOzellikEditor.vue";
import NDropDownTreeView from "@/components/DropDownTreeView/NDropDownTreeView.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import PazaryeriFiyatItem from "@/modules/Pazaryerleri/components/PazaryeriFiyatItem.vue";
import {useCicekSepetiKategoriService} from "@/api/services/CicekSepetiKategoriService";
import {CicekSepetiPazaryeriItem} from "@/api/models/CicekSepetiPazaryeriItem";
import {CicekSepetiSubCategory} from "@/api/models/CicekSepetiSubCategory";
import {StokView} from "@/api/models/StokView";
import {CicekSepetiDeliveryTypeList} from "@/api/models/CicekSepetiDeliveryType";
import {CicekSepetiDeliveryMessageTypeList} from "@/api/models/CicekSepetiDeliveryMessageType";
import {IdName} from "@/api/models/IdName";
import NRefresher from "@/components/Refresher/NRefresher.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import {PazaryeriFiyatTipiList} from "@/api/models/PazaryeriFiyatTipi";

export default defineComponent({
  components: {NFormField, NRefresher,  NFormGrid, NDropDownTreeView, CicekSepetiOzellikEditor },
  props: [
    'cicekSepeti',
    'stok'
  ],
  setup(props,{emit}) {

    const localState = reactive({
      
        cicekSepetiRef:computed(() => props.cicekSepeti as CicekSepetiPazaryeriItem),
        stokRef:computed(()=> props.stok as StokView),
        kategoriler:ref([] as CicekSepetiSubCategory[]),

        deliveryTypeList:ref(CicekSepetiDeliveryTypeList),
        deliveryMessageTypeList:ref(CicekSepetiDeliveryMessageTypeList),
        pazaryeriFiyatTipiList:ref(PazaryeriFiyatTipiList),
    });

    async function load(){
      const categories = await useCicekSepetiKategoriService().getGetAll(360);
      localState.kategoriler = categories;
    }

    function selectedChangedKategori(data:CicekSepetiSubCategory) {
      localState.cicekSepetiRef.kategori = {id:data.id?.toString(), name:data.name as string} as IdName
    }

    load()
    return {
      ...toRefs(localState),
      load,
      selectedChangedKategori
    };
  }
});

