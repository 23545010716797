import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';


export const useVersionService = defineStore({
id: 'VersionService',
actions: {


	async getGet()
	{
		const { request, response } = useApi(`/api/version/Get`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
