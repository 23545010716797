
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NIconIonic from "@/components/NIconIonic.vue";
import { array } from "yup";

const __default__ = defineComponent({
  components: { NIconIonic, NGrid },
  props: {
    tip: {
      type: Number,
      default: 0
    },
    renk: {
      type: String,
      default: "#c0392b"
    },
    sembol: {
      type: String,
      default: "₺"
    },
    sayi: {
      type: Number,
      default: 0
    },
    detay: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    margin: {
      type: String,
      default: "8px"
    },
    backgroundColor: {
      type: String,
      default: "white"
    },
    detayWidth: {
      type: String,
      default: "200px"
    }

  },
  setup(props) {
    const localState = reactive({
      sayi: ref(0),
      sayiRef: computed(() => props.sayi),
      showBilgi: ref(false)

    });

    arttir(localState.sayi);

    function arttir(val: number) {
      const random = Math.ceil(Math.random() * localState.sayiRef / 50);

      setTimeout(() => {
        if (localState.sayiRef <= random) {
          localState.sayi = localState.sayiRef;
        } else if (localState.sayiRef > (localState.sayi + random)) {
          localState.sayi += random;
          arttir(localState.sayi);
        } else {
          localState.sayi = localState.sayiRef;
        }

      }, 10);
    }

    watch(() => localState.sayiRef, () => {
      if (localState.sayiRef != 0)
        arttir(localState.sayi);
      else
        localState.sayi = 0;
    });

    return {
      ...toRefs(localState)
    };
  }

});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "006c2a22": (_ctx.margin),
  "11db8ca4": (_ctx.renk),
  "547814e1": (_ctx.backgroundColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__