
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import OzellikFormDialog from "@/modules/StokOzellik/components/OzellikFormDialog.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NInput from "@/components/Input/NInput.vue";
import NFormField from "@/components/FormField/NFormField.vue";

export default defineComponent({
  components: { NFormField, NInput, NFormGrid, OzellikFormDialog },
  props: {
    ozellikler: {
      type: Array
    },
    title:{
      type:String,
      default:'-Özellikler-'
    }
  },
  setup(props) {
    const localState = reactive({
      ozelliklerRef: computed(() => props.ozellikler),
      showOzellikForm: ref(false),
      selectedOzellikId: ref("")
    });

    function openOzellikForm(ozellikId: string) {
      localState.selectedOzellikId = ozellikId;
      localState.showOzellikForm = true;

    }

    return {
      ...toRefs(localState),
      openOzellikForm
    };
  }
});
