import {StokFlatView} from "@/api/models/StokFlatView";
import {StokUtils} from "@/modules/Stok/utils/StokUtils";

export const StokMenu = {

    stokOpenPage(data:StokFlatView){
        if (data){
            StokUtils.openStokForm(data.stokId as string  , false );
        }
    }

}