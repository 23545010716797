
export enum SubeFiyatTipi{
OranEkle=0,
TutarEkle=1,
TutarEkleAlis=2,
OranEkleAlis=3
}

export const SubeFiyatTipiList = [
{key:'OranEkle', value:0, description:'Oran Ekle (Satış Fiyatına)'},
{key:'TutarEkle', value:1, description:'Tutar Ekle (Satış Fiyatına)'},
{key:'TutarEkleAlis', value:2, description:'Tutar Ekle (Alış Fiyatına)'},
{key:'OranEkleAlis', value:3, description:'Oran Ekle (Alış Fiyatına)'}
]
