
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import NIcon from "@/components/Icon/NIcon.vue";
import NLoading from "@/components/Loading/NLoading.vue";

import ImageUploadType from "@/api/uploaders/models/ImageUploadType";

import { usePopupService } from "@/services/popupService";
import { UploadState } from "@/api/uploaders/models/UploadState";
import {ImageManagementServiceUtil} from "@/api/uploaders/ImageManagementServiceUtil";

export default defineComponent({
  components: { NLoading, NIcon },
  props: {
    serverUrl: { type: String, default: "" },
    src: { type: String, default: "" },
    width: { type: Number, default: 30 },
    height: { type: Number, default: 30 },
    imageWidth: { type: Number, default: 150 },
    imageHeight: { type: Number, default: 150 },
    folderName: { type: String, default: "" }
  },
  setup(props, { emit }) {
    const localState = reactive({
      states: ref(UploadState),
      model: ref({
        state: UploadState.Ready,
        width: props.width,
        folderName: props.folderName,
        height: props.height
      } as ImageUploadType),
      // eslint-disable-next-line vue/return-in-computed-property
      srcRef: computed(() => {
        if (localState.model.state === UploadState.Ready) {
          return props.src && props.src.length > 0
            ? " url('" + props.serverUrl + props.src + "')"
            : "url('/user.png')";
        } else if (localState.model.state === UploadState.Loading)
          return "url('" + imageClientData.value + "')";
      })
    });
    const imageClientData = ref();

    function fileSelected(e: any) {
      const files = e.target.files;
      const reader: FileReader = new FileReader();
      reader.onload = () => {
        localState.model.state = UploadState.Loading;
        localState.model.file = files[0];
        imageClientData.value = reader.result;

        fileUpload();
      };
      reader.readAsDataURL(files[0]);
    }

    async function fileUpload() {
      const response = await ImageManagementServiceUtil
        .postImageManagmentResimEkle(localState.model)
        .then(a => {
          if (a.error && a.error.length > 0) {
            usePopupService().pushDangerNotification({
              title: "Yükleme yapılamadı.",
              body: a.error
            });
          } else {
            emit("update:src", a.filePath);
          }
          localState.model.state = UploadState.Ready;
        });
    }

    return {
      ...toRefs(localState),
      fileSelected
    };
  }
});
