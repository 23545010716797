import {defineStore} from "pinia";
import {ref} from "vue";
import {useCariService} from "@/api/services/CariService";
import { CariOzetView } from "@/api/models/CariOzetView";


export const useCariStore = defineStore({
    id: "useCariStore",
    state: () => ({
        cariOzetViewListe : [] as CariOzetView[],
        isOzetCache : false ,
    }),
    actions: {
        async getCariOzetleri():Promise<CariOzetView[]> {
            if (this.isOzetCache == false) {
                this.cariOzetViewListe = await useCariService().getTumListeOzet(false);
                this.isOzetCache = true;
                return this.cariOzetViewListe;
            } else {
                return this.cariOzetViewListe;
            }
        },
        async refreshCariOzet(): Promise<any[]> {
            this.isOzetCache = false;
            return this.getCariOzetleri();
        },
    }
});