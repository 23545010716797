import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';


export const useN11CategoryService = defineStore({
id: 'N11CategoryService',
actions: {


	async getN11KategoriGetir(id:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/N11Kategori/Getir/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getN11KategoriGetirTumu()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/N11Kategori/GetirTumu/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async putN11KategoriGuncelle(id:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/N11Kategori/Guncelle/${shared.firm.id}/${id}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async getN11KategoriGetirOzellikler(id:number,cacheDay:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/N11Kategori/GetirOzellikler/${shared.firm.id}/${id}/${cacheDay}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
