
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import NIcon from "@/components/Icon/NIcon.vue";
import NIconIonic from "@/components/NIconIonic.vue";

type NCardTab = {
  id: string;
  label: string;
  value?: string;
  disabled?: boolean;
  hide?:boolean;
  icon?:string;
  ikon?:string;
};

const __default__ = defineComponent({
  name: "NCard",
  components: {  NIcon},
  props: {
    tabs: Object as () => NCardTab[],
    title: String,
    scrollable: Boolean,
    modelValue: {
      type:Number,
      default : 0 ,
    },
    col: {
      type: String,
      default: "auto"
    },
    row: {
      type: String,
      default: "auto"
    },
    padding:{
      type:String,
      default : "10px",
    }
  },
  setup(props, { emit }) {


    const localState = reactive({
      selectedTabRef:computed({
        get : () => {
          return props.modelValue as number ;
        },
        set : (val:number) => {
          emit('update:modelValue',val);
        }
      })
    })

    function selectTab(i:number){

      localState.selectedTabRef = i;
    }

    return {
      ...toRefs(localState),
      selectTab

    };
  }
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "b03a7094": (_ctx.padding)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__