
export enum SacUretimSatirKaynakTip{
Talimat_Fisi=0,
Siparis=1
}

export const SacUretimSatirKaynakTipList = [
{key:'Talimat_Fisi', value:0, description:'Talimat Fişi'},
{key:'Siparis', value:1, description:'Sipariş'}
]
