
import {computed, defineComponent, reactive, toRefs} from "vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import {StokTip, StokTipList} from "@/api/models/StokTip";
import {StokView} from "@/api/models/StokView";
import StokOzellikTipForm_Diger from "@/modules/StokOzellik/components/StokOzellikTipForm_Diger.vue";
import StokOzellikTipForm_Sac from "@/modules/StokOzellik/components/StokOzellikTipForm_Sac.vue";
import NButton from "@/components/Button/NButton.vue";
import {useUserSettingsService} from "@/api/services/UserSettingsService";
import {usePopupService} from "@/services/popupService";
import StokOzellikTipForm_SacProfil from "@/modules/StokOzellik/components/StokOzellikTipForm_SacProfil.vue";
import DepoSelectBox from "@/modules/Ortak/components/DepoSelectBox.vue";

export default defineComponent({
    computed: {
        StokTip() {
            return StokTip
        }
    },
  components: {
      DepoSelectBox,
      StokOzellikTipForm_SacProfil,
      NButton, StokOzellikTipForm_Sac, StokOzellikTipForm_Diger, NFormField, NFormGrid  },
  props: {
    modelValue:{
        type:Object
      }
  },
  setup(props, {emit}) {

    const localState = reactive({
      tipList:StokTipList,
      tip:StokTip,
      model:computed(()=> props.modelValue as StokView)
    });

    async function load() {
        if (!localState.model?.id || localState.model?.id?.length < 4){
            const deger = String(await getDefaultSettings('varsayilanStokTip'))
            localState.model.tip = deger?.length > 0 ? Number(deger) : 0
        }

    }

    load();

    function selectionChanged(e:any) {
      if (!localState.model.tipOzellikler)
      localState.model.tipOzellikler = {
        carpan:0,
        uzunluk:0,
        kalinlik:0,
        genislik:0,
        yukseklik: 0,
        adet:1
      }

      localState.model.tipOzellikler.carpan = getCarpanByType(localState.model.tip as StokTip);

      if(!localState.model.tipOzellikler.uzunluk) localState.model.tipOzellikler.uzunluk = 0;
      if(!localState.model.tipOzellikler.genislik) localState.model.tipOzellikler.genislik = 0;
      if(!localState.model.tipOzellikler.kalinlik) localState.model.tipOzellikler.kalinlik = 0;
      if(!localState.model.tipOzellikler.cari) localState.model.tipOzellikler.cari = {};


    }

      function getCarpanByType(tip: StokTip) {
          switch (tip) {
              case StokTip.TicariMal:
              case StokTip.Mamul:
              case StokTip.Hammadde:
              case StokTip.Kereste:
              case StokTip.Papel:
                  return 1;
              case StokTip.Sac:
                  return 7.85
              default:
                  return localState.model.tipOzellikler?.carpan as number
          }
    }



      async function getDefaultSettings(key: string) {
          return useUserSettingsService().getGetValue(key);
      }

      async function saveSettings(key: string, value: string) {
          await useUserSettingsService().postSave({
              name: key,
              value: value
          });
          usePopupService().pushSuccessNotification({
              title: "Kullanıcı Tanımı",
              duration: 1500,
              body: "Varsayılan stok tipi kaydedildi."
          });
      }


    return {
      ...toRefs(localState),
      selectionChanged,
        getDefaultSettings,
        saveSettings
    };
  }
});

