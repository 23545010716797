
export enum KargoTeslimatTip{
StandartTeslimat=1,
GunIciTeslimat=2,
AksamTeslimat=3
}

export const KargoTeslimatTipList = [
{key:'StandartTeslimat', value:1, description:'Standart Teslimat'},
{key:'GunIciTeslimat', value:2, description:'Güniçi Teslimat'},
{key:'AksamTeslimat', value:3, description:'Akşam Teslimat'}
]
