
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NInput from "@/components/Input/NInput.vue";
import NSwitch from "@/components/Switch/NSwitch.vue";
import { HepsiBuradaListingPazaryeriItem } from "@/api/models/HepsiBuradaListingPazaryeriItem";
import NTagList from "@/components/Tag/NTagList.vue";
import StokPazaryeriFiyatItem from "@/modules/Stok/components/StokPazaryeriFiyatItem.vue";
import PazaryeriFiyatItem from "@/modules/Pazaryerleri/components/PazaryeriFiyatItem.vue";
import { StokEntity } from "@/api/models/StokEntity";
import HepsiBuradaSearchDialog from "@/modules/Stok/dialogs/HepsiBuradaSearchDialog.vue";
import {useHepsiBuradaService} from "@/api/services/HepsiBuradaService";
import NButton from "@/components/Button/NButton.vue";
import NTag from "@/components/Tag/NTag.vue";
import {usePopupService} from "@/services/popupService";
import {StokView} from "@/api/models/StokView";

export default defineComponent({
  components: {NTag, NButton, HepsiBuradaSearchDialog, PazaryeriFiyatItem,  NTagList, NSwitch, NFormField, NFormGrid },
  props: [
    'hepsiBuradaListing',
    'stok',
    'alisKdv',
    'alisKdvDahil',
    'satisKdv',
    'satisKdvDahil',
    'alisFiyat',
    'satisFiyat'
  ],


  setup(props,{emit}) {
    const localState = reactive({
      model:computed(()=> props.hepsiBuradaListing as HepsiBuradaListingPazaryeriItem),
      stokRef:computed(()=> props.stok as StokView),
      alisKdvRef:computed({get:()=>props.alisKdv, set:(val)=> emit('update:alisKdv',val) }),
      alisKdvDahilRef:computed({get:()=>props.alisKdvDahil, set:(val)=> emit('update:alisKdvDahil',val) }),
      satisKdvRef:computed({get:()=>props.satisKdv, set:(val)=> emit('update:satisKdv',val) }),
      satisKdvDahilRef:computed({get:()=>props.satisKdvDahil, set:(val)=> emit('update:satisKdvDahil',val) }),
      alisFiyatRef:computed({get:()=>props.alisFiyat, set:(val)=> emit('update:alisFiyat',val) }),
      satisFiyatRef:computed({get:()=>props.satisFiyat, set:(val)=> emit('update:satisFiyat',val) }),
      showSearch:ref(false),
      buyBox:ref([] as any[])
    });

    async function loadBuyBox(){
      try {
        localState.buyBox = await  useHepsiBuradaService().getProductsBuyBox(localState.model.kodlar?.join(',') as string)
        if (localState.buyBox.length == 0)
          usePopupService().pushWarningNotification({
            body:'Bu stok ile ilgili diğer satıcılara ait satış bilgisi bulunamadı.',
            title:'BuyBox bilgisi bulunamadı.'
          })
      }catch (e:any) {
        //
      }
    }


    return {
      ...toRefs(localState),
      loadBuyBox
    };
  }
});

