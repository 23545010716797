
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import {Liste} from "@/api/models/Liste";
import {useListelerService} from "@/api/services/ListelerService";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";
import {virtualElements} from "knockout";
import firstChild = virtualElements.firstChild;

export default defineComponent({
  components: {},
  props: {
    modelValue:{
      type:String,
      default:'',
    },
    placeholder:{
      type:String,
      default: ''
    },
    firstSelect:{
      type:Boolean,
      default:false,
    },
    showLabel:{
      type:Boolean,
      default:false,
    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      model: computed({
        get : ()=> props.modelValue,
        set: val => emit('update:modelValue' ,  val),
      }),
      birimler:ref([] as Liste[]),
      placeholderRef:computed(() => props.placeholder),

    })
    load()
    async function load(){
      localState.birimler = await useListelerService().getBirimListesi();
      if (props.firstSelect && localState.birimler && localState.birimler.length > 0 )
        emit("update:modelValue" ,  localState.birimler[0].deger as string )

    }
    return {
      ...toRefs(localState)
    };
  }
});
