import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(['separator', _ctx.position])
    }, null, 2),
    (_ctx.showToggle)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: _normalizeClass(['toggle-button', _ctx.isSidebarCollapsed ? 'collapsed' : 'expanded']),
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.toggleSidebar()), ["stop"]))
        }, [
          _createVNode(_component_NIcon, {
            type: _ctx.isSidebarCollapsed ? 'chevron-right' : 'chevron-left',
            size: "16px",
            strokeWidth: "3px"
          }, null, 8, ["type"])
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}