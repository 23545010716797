import {NModuleRoute} from "@/models/route.model";

export const EIhracatModuleRoutes: NModuleRoute[] = [
    {
        id: "EIhracat",
        order: 10,
        displayName: "E-İhracat",
        icon: {
            type: "truck"
        },
        children: [

            {
                id: "EIhracatTeklifListesi",
                order: 0,
                displayName: "Teklif Listesi",
                requiredRoles:{
                    readRoles:['EIhracat.Liste'],
                    insertRoles:[],
                    updateRoles:[],
                    deleteRoles:[]
                },
                icon: {
                    type: "truck"
                },
                component: () =>
                    import(/* webpackChunkName: "EIhracat"*/ "../pages/EIhracatTeklifPage.vue")
            }
        ]
    }
];
