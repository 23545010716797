import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-735d7197"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "n-list-view" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "n-list-item n-list-item--empty" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass([
        'n-list-item',
        _ctx.modelValue === index && 'n-list-item--selected',
        `n-list-item--${_ctx.size}`
      ]),
        key: index,
        onClick: ($event: any) => (_ctx.selectItem(index))
      }, [
        _renderSlot(_ctx.$slots, "item", {
          item: item,
          index: index
        }, () => [
          _createTextVNode(_toDisplayString(item), 1)
        ], true)
      ], 10, _hoisted_2))
    }), 128)),
    (!_ctx.items || !_ctx.items.length)
      ? _renderSlot(_ctx.$slots, "empty", { key: 0 }, () => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_NIcon, {
              type: "alert-circle",
              size: "16px"
            }),
            _createElementVNode("span", null, _toDisplayString(_ctx.emptyMessage), 1)
          ])
        ], true)
      : _createCommentVNode("", true)
  ]))
}