import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-078f08da"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loading"
}
const _hoisted_2 = { class: "progress" }
const _hoisted_3 = {
  key: 1,
  class: "upload-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NLoading = _resolveComponent("NLoading")!
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "image",
      style: _normalizeStyle({
        width: _ctx.width + 'px',
        height: _ctx.height + 'px',
        'background-image': _ctx.srcRef
      })
    }, [
      (_ctx.model.state === _ctx.states.Loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_NLoading, {
              radius: 16,
              stroke: 3
            }),
            _createElementVNode("div", _hoisted_2, _toDisplayString(((_ctx.model.loaded * 100) / _ctx.model.total).toFixed(0)), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.model.state !== _ctx.states.Loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("input", {
              type: "file",
              onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.fileSelected && _ctx.fileSelected(...args))),
              accept: "image/png, image/jpeg"
            }, null, 32),
            _createVNode(_component_NIcon, {
              type: "plus",
              size: "16"
            })
          ]))
        : _createCommentVNode("", true)
    ], 4)
  ]))
}