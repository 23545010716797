import { defineStore } from "pinia";
import { useImageManagementService } from "@/api/services/ImageManagementService";
import ImageUploadType from "@/api/uploaders/models/ImageUploadType";
import { useFileSettingsService } from "@/api/services/FileSettingsService";
import DocumentUploadType from "@/api/uploaders/models/DocumentUploadType";
import { useDocumentManagementService } from "@/api/services/DocumentManagementService";

export const useDocumentManagementServiceExtend = defineStore({
  id: "DocumentManagementServiceExtend",
  state: () => ({
    serverUrl: ""
  }),
  actions: {
    async postDocument(documentUploadType: DocumentUploadType) {
      const formData = new FormData();

      documentUploadType.isCompleted = false;

      formData.append("FormFile", documentUploadType.file);
      formData.append("FolderName", documentUploadType.folderName as string);
      formData.append("DocLocation", "0");

      const options = {
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
        onUploadProgress: (progressEvent: any) => {
          documentUploadType.loaded = progressEvent.loaded;
          documentUploadType.total = progressEvent.total;
        }
      };

      return await useDocumentManagementService().postDocumentManagmentDokumanEkle(
        options
      );
    },

    async getServerUrl() {
      if (this.serverUrl && this.serverUrl.length > 0) {
        return this.serverUrl;
      }
      return await useFileSettingsService().getServerUrl();
    }
  }
});
