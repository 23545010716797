
import {computed, defineComponent, onMounted, onUnmounted, reactive, ref, toRefs} from "vue";
import { StokDevirItem } from "@/api/models/StokDevirItem";
import NGrid from "@/components/Grid/NGrid.vue";
import LoginFormBase from "@/layouts/LoginLayout/components/LoginFormBase.vue";
import {useRoute} from "vue-router";
import {usePinStore} from "@/layouts/PinLayout/store/PinStore";
import {useField} from "vee-validate";

export default defineComponent({
  components: {LoginFormBase},
  props: {

  },
  setup(props) {

    const localState = reactive({
        store:usePinStore(),
        password:ref(),
        isLoading:ref(false),

    });

      function getRouteName() {
          if (useRoute().name == "UretimTalimatLogin")
              return  "UretimTalimatMobile";
          if (useRoute().name == "HizliSatisLogin")
              return  "HizliSatisLayout";
          return "ApplicationLayout";
      }

      function isCode() {
          return useRoute().name === "LoginCode";
      }

      // eslint-disable-next-line @typescript-eslint/no-var-requires
      function getLayoutName() {
          if (getRouteName() == "UretimTalimatMobile") {
              return "Sac Üretim";
          } else if (getRouteName() == "HizliSatisLayout") {
              return "Hızlı Satış"
          }

          return "";
      }

      onMounted(()=>{
          localState.store.removeEvent()
      })
      onUnmounted(()=>{
          //localState.store.removeEvent()
      })

      async function enterPin() {
         await localState.store.enterPin(localState.password)
      }

    return {
      ...toRefs(localState),
        getRouteName,
        getLayoutName,
        enterPin
    };
  }
});

