import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NDropDownTreeView = _resolveComponent("NDropDownTreeView")!
  const _component_NRefresher = _resolveComponent("NRefresher")!
  const _component_NDropDownListCustomStore = _resolveComponent("NDropDownListCustomStore")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_EpttAvmOzellikEditor = _resolveComponent("EpttAvmOzellikEditor")!

  return (_openBlock(), _createElementBlock("div", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), [
    _createVNode(_component_NFormGrid, {
      cols: "1fr",
      padding: "0",
      title: " "
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_NFormField, {
          label: "EpttAvm'ye Gönder",
          "grid-cols": "1fr auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NSwitch, {
              modelValue: _ctx.epttRef.isActive,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.epttRef.isActive) = $event)),
              style: {"margin-top":"-8px"}
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_NFormGrid, { cols: "1fr 1fr 1fr 1fr" }, {
          default: _withCtx(() => [
            _createVNode(_component_NFormField, {
              label: "Kategori",
              "grid-cols": "100px 1fr",
              span: "3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NRefresher, {
                  load: ()=>_ctx.load(true)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NDropDownTreeView, {
                      "selected-item": _ctx.selectedKategoriItem,
                      "onUpdate:selectedItem": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedKategoriItem) = $event)),
                      "data-source": _ctx.kategoriler,
                      "onUpdate:dataSource": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kategoriler) = $event)),
                      "display-expr": "title",
                      "key-expr": "key",
                      "items-expr": "children"
                    }, null, 8, ["selected-item", "data-source"])
                  ]),
                  _: 1
                }, 8, ["load"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Marka",
              span: "1",
              "grid-cols": "100px 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NDropDownListCustomStore, {
                  "display-expr": "title",
                  "selected-item": _ctx.epttRef.marka,
                  "onUpdate:selectedItem": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.epttRef.marka) = $event)),
                  "data-source": _ctx.dataSourceMarkalar
                }, null, 8, ["selected-item", "data-source"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Kısa Açıklama",
              span: "4",
              "grid-cols": "100px 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  modelValue: _ctx.epttRef.kisaAciklama,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.epttRef.kisaAciklama) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Açıklama",
              span: "4",
              "grid-cols": "100px 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  modelValue: _ctx.epttRef.aciklama,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.epttRef.aciklama) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Kar Marjı(%)",
              span: "1",
              "grid-cols": "100px 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxNumberBox, {
                  value: _ctx.epttRef.karMarji,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.epttRef.karMarji) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Komisyon (%)",
              span: "1",
              "grid-cols": "100px 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxNumberBox, {
                  value: _ctx.epttRef.komisyon,
                  "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.epttRef.komisyon) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Kargo Tutarı",
              span: "1",
              "grid-cols": "100px 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxNumberBox, {
                  value: _ctx.epttRef.kargoTutar,
                  "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.epttRef.kargoTutar) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "auto 240px",
              label: "Fiyatlandırma"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxSelectBox, {
                  value: _ctx.epttRef.fiyat.tip,
                  "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.epttRef.fiyat.tip) = $event)),
                  items: _ctx.pazaryeriFiyatTipiList,
                  "value-expr": "value",
                  "display-expr": "description"
                }, null, 8, ["value", "items"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.epttRef && _ctx.epttRef.kategoriId && _ctx.epttRef.kategoriId > 0)
          ? (_openBlock(), _createBlock(_component_EpttAvmOzellikEditor, {
              key: 0,
              "kategori-id": _ctx.epttRef.kategoriId,
              "onUpdate:kategoriId": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.epttRef.kategoriId) = $event)),
              ozellikler: _ctx.epttRef.ozellikler,
              "onUpdate:ozellikler": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.epttRef.ozellikler) = $event))
            }, null, 8, ["kategori-id", "ozellikler"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 16))
}