import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NDropDownTreeView = _resolveComponent("NDropDownTreeView")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_N11ShipmentTemplateSelectBox = _resolveComponent("N11ShipmentTemplateSelectBox")!
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_N11OzellikEditor = _resolveComponent("N11OzellikEditor")!

  return (_openBlock(), _createElementBlock("div", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), [
    _createVNode(_component_NFormGrid, {
      cols: "1fr",
      padding: "0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NFormField, {
          label: "N11 Kategorisi",
          "grid-cols": "116px 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NDropDownTreeView, {
              "selected-item": _ctx.n11Ref.kategori,
              "onUpdate:selectedItem": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.n11Ref.kategori) = $event)),
              "data-source": _ctx.kategoriler,
              "onUpdate:dataSource": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.kategoriler) = $event)),
              "items-expr": "subCategories"
            }, null, 8, ["selected-item", "data-source"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_NFormGrid, { cols: "1fr 1fr 1fr 1fr " }, {
      default: _withCtx(() => [
        _createVNode(_component_NFormField, {
          label: "Kargo Şablonu",
          span: "3",
          "grid-cols": "100px 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_N11ShipmentTemplateSelectBox, {
              modelValue: _ctx.n11Ref.teslimatSablonu,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.n11Ref.teslimatSablonu) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "N11'e Gönder",
          "grid-cols": "1fr auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NSwitch, {
              modelValue: _ctx.n11Ref.isActive,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.n11Ref.isActive) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Kısa Açıklama",
          span: "3",
          "grid-cols": "100px 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.n11Ref.kisaAciklama,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.n11Ref.kisaAciklama) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Hazırlanma Günü",
          span: "1",
          "grid-cols": "140px 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxNumberBox, {
              value: _ctx.n11Ref.hazirlanmaGunu,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.n11Ref.hazirlanmaGunu) = $event)),
              placeholder: "En az 1 gün giriniz",
              type: "number"
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Açıklama",
          span: "4",
          "grid-cols": "100px 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.n11Ref.aciklama,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.n11Ref.aciklama) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Kar Marjı(%)",
          span: "1",
          "grid-cols": "100px 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxNumberBox, {
              value: _ctx.n11Ref.karMarji,
              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.n11Ref.karMarji) = $event))
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Komisyon(%)",
          span: "1",
          "grid-cols": "100px 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxNumberBox, {
              value: _ctx.n11Ref.komisyon,
              "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.n11Ref.komisyon) = $event))
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Kargo Tutarı",
          span: "1",
          "grid-cols": "100px 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxNumberBox, {
              value: _ctx.n11Ref.kargoTutar,
              "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.n11Ref.kargoTutar) = $event))
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          "grid-cols": "auto 240px",
          label: "Fiyatlandırma"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxSelectBox, {
              value: _ctx.n11Ref.fiyat.tip,
              "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.n11Ref.fiyat.tip) = $event)),
              items: _ctx.pazaryeriFiyatTipiList,
              "value-expr": "value",
              "display-expr": "description"
            }, null, 8, ["value", "items"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.n11Ref && _ctx.n11Ref.kategori && _ctx.n11Ref.kategori && _ctx.n11Ref.kategori.id  > 0)
      ? (_openBlock(), _createBlock(_component_N11OzellikEditor, {
          key: 0,
          cols: "1fr 1fr",
          "kategori-id": _ctx.n11Ref.kategori.id,
          "onUpdate:kategoriId": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.n11Ref.kategori.id) = $event)),
          ozellikler: _ctx.n11Ref.ozellikler,
          "onUpdate:ozellikler": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.n11Ref.ozellikler) = $event))
        }, null, 8, ["kategori-id", "ozellikler"]))
      : _createCommentVNode("", true)
  ], 16))
}