import {SacUretimSatirItem} from "@/api/models/SacUretimSatirItem";
import {groupBy, map, sum} from "lodash";

export const DilmeApi = {
    /**
     * Ağırlıklar (TabanId)
    * */
    getToplamAgirlikFromTabanId(satirlar: SacUretimSatirItem[], tabanId: number) {
        return sum(this.getSatirlarFromTabanId(satirlar, tabanId).map(a => a.tonaj))
    },
    getKalanAgirlikFromTabanId(satirlar: SacUretimSatirItem[], tabanId: number) {
        return  this.getTabanTonaj(satirlar, tabanId) - this.getToplamAgirlikFromTabanId(satirlar, tabanId);
    },
    getTabanTonaj(satirlar: SacUretimSatirItem[], tabanId: number): number {
        const taban = satirlar.find(a => a.tabanId == tabanId);
        if (taban)
            return taban.tabanTonaj as number;
        return 0;
    },
    getToplamAgirlik(satirlar: SacUretimSatirItem[]) {
        return sum(satirlar.map(a => a.tonaj))
    },
    /**
    * Genişlikler
    * */
    getToplamGenislikFromTabanId(satirlar: SacUretimSatirItem[],  tabanId: number) {
        return sum(this.getSatirlarFromTabanId(satirlar, tabanId).map(a => (a.genislik as number) * (a.adet as number)))
    },
    getKalanGenislikFromTabanId(satirlar: SacUretimSatirItem[],tabanGenislik:number, tabanId: number) {
        if (tabanId <= 0)
            return tabanGenislik;
        return  tabanGenislik - this.getToplamGenislikFromTabanId(satirlar, tabanId);
    },
    getToplamGenislik(satirlar: SacUretimSatirItem[]) {
        if (satirlar && satirlar.length > 0)
        return sum(satirlar.map(a => (a.genislik as number) * (a.adet as number)))
        return  0;
    },
    getKalanGenislik(satirlar: SacUretimSatirItem[],tabanGenislik:number) {
        return  tabanGenislik - this.getToplamGenislik(satirlar);
    },

    /**
    * ÜRETİLEBİLECEK AĞIRLIK
    * */
    getKullanilanTonaj(satirlar: SacUretimSatirItem[]){
        const group = groupBy(satirlar,a => a.tabanId);
        const list = map(group,a => a[0].tabanTonaj);
        const toplamKullanilan = sum(list);
        return toplamKullanilan;
    },
    getKullanilabilecekTonaj(satirlar:SacUretimSatirItem[],toplamTonaj:number){
        if (satirlar.length == 0)
            return toplamTonaj;
        return toplamTonaj - this.getKullanilanTonaj(satirlar)
    },
    getUretilenTonaj(tabanTonaj:number, tabanGenislik:number, kullanilanGenislik:number, kullanilanAdet:number){
        // tabanGenislik                     tabanTonaj
        // kullanilanGenislik * adet            ??
        //console.log("args",tabanTonaj,tabanGenislik,kullanilanGenislik, kullanilanAdet)
        return (kullanilanGenislik * kullanilanAdet * tabanTonaj) / tabanGenislik;
    },

    // Helper
    getSatirlarFromTabanId(satirlar: SacUretimSatirItem[], tabanId: number) {
        return satirlar.filter(a => a.tabanId == tabanId);
    },


    /***
     * Form
     * */
    getSlots(satirlar:SacUretimSatirItem[], tabanGenislik:number){
        const group =  groupBy(satirlar, a => a.tabanId);
        return  map(group,satirlar=> ({
            satirlar: satirlar,
            tabanId:satirlar[0].tabanId,
            disabled: (tabanGenislik - this.getToplamGenislik(satirlar)) <= 0 || (satirlar[0].tabanTonaj as number - this.getToplamAgirlik(satirlar)) <= 0,
            uretilenTonaj:this.getToplamAgirlik(satirlar),
            uretilenGenislik:this.getToplamGenislik(satirlar),
            tabanTonaj:satirlar[0].tabanTonaj as number,
            kalanTonaj: satirlar[0].tabanTonaj as number - this.getToplamAgirlik(satirlar),
            kalanGenislik: tabanGenislik - this.getToplamGenislik(satirlar),
            kullanilanTonaj: this.getToplamGenislik(satirlar) + (satirlar[0].tabanTonaj as number - this.getToplamAgirlik(satirlar))
        }));
    },
    getNewSlot(satirlar:SacUretimSatirItem[], toplamTonaj:number, toplamGenislik:number){
        const kullanilabilecekTonaj = this.getKullanilabilecekTonaj(satirlar, toplamTonaj);
        // if (kullanilabilecekTonaj > 0)
            return [{
                tabanId:Date.now(),
                uretilecekTonaj: Math.max(kullanilabilecekTonaj,0),
                tabanTonaj:0,
                kalanTonaj:0,
                kalanGenislik:0,
                kullanilanTonaj:0,
            }]

        // return  [];
    }
}