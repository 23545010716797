import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {SiparisHareketTip} from '../models/SiparisHareketTip'
import {SiparisTip} from '../models/SiparisTip'
import {TeslimatAdresItem} from '../models/TeslimatAdresItem'
import {SiparisAdresItem} from '../models/SiparisAdresItem'
import {SmsMailKargoGonderItem} from '../models/SmsMailKargoGonderItem'
import {DocumentItem} from '../models/DocumentItem'
import {SiparisSatirItem} from '../models/SiparisSatirItem'
import {FaturaBilgiItem} from '../models/FaturaBilgiItem'
import {Doviz} from '../models/Doviz'
import {SiparisView} from '../models/SiparisView'
import {RecordStatus} from '../models/RecordStatus'
import {FaturaHareketTip} from '../models/FaturaHareketTip'
import {FaturaTip} from '../models/FaturaTip'
import {FaturaUstBilgiItem} from '../models/FaturaUstBilgiItem'
import {FaturaSatirItem} from '../models/FaturaSatirItem'
import {FaturaAltBilgiItem} from '../models/FaturaAltBilgiItem'
import {PlanVadelerItem} from '../models/PlanVadelerItem'
import {FaturaEFaturaItem} from '../models/FaturaEFaturaItem'
import {FaturaView} from '../models/FaturaView'

export const useSiparisService = defineStore({
id: 'SiparisService',
actions: {


	async getDefault()
	{
		const { request, response } = useApi(`/api/siparis/default`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/View/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGet(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/Get/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getSiparisFlatListeBekleyenler(start:string,end:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/SiparisFlatListeBekleyenler/${shared.firm.id}/${shared.firm.period}/${start}/${end}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getSiparisFlatListeTamamlanan(start:string,end:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/SiparisFlatListeTamamlanan/${shared.firm.id}/${shared.firm.period}/${start}/${end}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getSiparisSatirFlatListBekleyenler(cariId:string,hareketTip:number,start:string,end:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/SiparisSatirFlatListBekleyenler/${shared.firm.id}/${shared.firm.period}/${cariId}/${hareketTip}/${start}/${end}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getSiparisFlatKargoListe(start:string,end:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/SiparisFlatKargoListe/${shared.firm.id}/${shared.firm.period}/${start}/${end}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getB2BSiparisFlatListe(start:string,end:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/B2BSiparisFlatListe/${shared.firm.id}/${shared.firm.period}/${start}/${end}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getTeklifFlatListeTamamlanan(start:string,end:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/TeklifFlatListeTamamlanan/${shared.firm.id}/${shared.firm.period}/${start}/${end}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getTeklifFlatListeBekleyenler(start:string,end:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/TeklifFlatListeBekleyenler/${shared.firm.id}/${shared.firm.period}/${start}/${end}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postSiparisEkle(siparisView:SiparisView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/SiparisEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:siparisView });
		await request();
		return response.value;
	},

	async putGuncelle(siparisView:SiparisView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/Guncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:siparisView });
		await request();
		return response.value;
	},

	async postFaturaEkle(faturaView:FaturaView,tip:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/FaturaEkle/${shared.firm.id}/${shared.firm.period}/${tip}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async deleteFaturaSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/FaturaSil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/Sil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postSiparisToIrsaliye(siparisView:SiparisView,tip:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/SiparisToIrsaliye/${shared.firm.id}/${shared.firm.period}/${tip}`,{ method:'POST', data:siparisView });
		await request();
		return response.value;
	},

	async getSiparisTeklifEntityDataSource()
	{
		const { request, response } = useApi(`/api/siparis/SiparisTeklifEntityDataSource`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postToEntity(siparisView:SiparisView,tip:number)
	{
		const { request, response } = useApi(`/api/siparis/ToEntity/${tip}`,{ method:'POST', data:siparisView });
		await request();
		return response.value;
	},

	async getCariReportSatirlar(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/CariReportSatirlar/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getStokHareketToSiparisView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/StokHareketToSiparisView/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postB2bFaturalandir(siparisId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/B2bFaturalandir/${shared.firm.id}/${shared.firm.period}/${siparisId}`,{ method:'POST' });
		await request();
		return response.value;
	},

	async postB2bKargola(siparisId:string,kargoFirmasi:string,kargoTakipKodu:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/B2bKargola/${shared.firm.id}/${shared.firm.period}/${siparisId}/${kargoFirmasi}/${kargoTakipKodu}`,{ method:'POST' });
		await request();
		return response.value;
	},

	async deleteB2bSiparisiIptalEt(siparisId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/B2bSiparisiIptalEt/${shared.firm.id}/${shared.firm.period}/${siparisId}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async deleteB2bFaturaSil(faturaId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/siparis/B2bFaturaSil/${shared.firm.id}/${shared.firm.period}/${faturaId}`,{ method:'DELETE' });
		await request();
		return response.value;
	}
}
});
