
import { defineComponent, reactive, ref, toRefs } from "vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NInput from "@/components/Input/NInput.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import { StokEntity } from "@/api/models/StokEntity";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";
import { useCariService } from "@/api/services/CariService";
import { IdName } from "@/api/models/IdName";
import CariSelectBox from "@/modules/Cari/components/CariSelectBox.vue";

export default defineComponent({
  components: { NSelectBox,  NFormGrid, NInput, NFormField },
  props: {
    modelValue: {
      type: Object
    }
  },
  setup(props) {

    const localState = reactive({
      model: ref(props.modelValue as StokEntity),
      cariler:ref([] as IdName[])
    });

    function ureticiEkle() {

      if  (localState.model && !localState.model.ureticiler){
        localState.model.ureticiler  = []
      }
      if (localState.model && localState.model.ureticiler){

        localState.model.ureticiler.push({cari:{}})
      }
    }

    async function load() {
      localState.cariler = await useCariService().getIdNameCariList();
    }

    function ureticiSil(index:number){
      if (localState.model.ureticiler)
        localState.model.ureticiler.splice(index,1)
    }

    function cariSelectedChanged(indexCari:number,cariIdName:IdName,indexUretici:number){
      if (localState.model.ureticiler)
      localState.model.ureticiler[indexUretici].cari = cariIdName;
    }

    load()

    return {
      ...toRefs(localState),
      ureticiEkle,
      ureticiSil,
      cariSelectedChanged
    };

  }
});

