
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import { HizmetMasrafEntity } from "@/api/models/HizmetMasrafEntity";
import { useHizmetMasrafService } from "@/api/services/HizmetMasrafService";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import { useCachedHizmetMasrafGiderService } from "@/modules/HizmetMasraf/services/useCachedHizmetMasrafGiderService";

export default defineComponent({
  components: { NGrid },
  props: {
    modelValue: {
      type: String
    },
    hizmetAdi: {
      type: String
    },
    hizmetler: { // Bu props dışarıdan verilmesse servisten yükler
      type: Array
    },
    kdv: {
      type: Number,
      default: 0
    },
    cariId: {
      type: String
    },
    cariName: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    firstSelect:{
      type:Boolean,
      default:true ,
    }


  },
  setup(props, { emit }) {
    const localState = reactive({
      items: ref([] as HizmetMasrafEntity[]),
      selected: computed({
        get: () => props.modelValue,
        set: val => emit("update:modelValue", val)
      })

    });

    watch(() => localState.selected, () => {
      if (localState.selected && localState.selected.length > 0) {
        const result = localState.items.find(a => a.id == localState.selected);
        if (result) {
          emit("update:hizmetAdi", result.ad);
          emit("update:kdv", result.kdv);
          emit("update:cariId", result.varsayilanCariId);
          emit("update:cariName", result.varsayilanCariName);
          emit("change")

        }

      }else{
        emit("update:hizmetAdi", "");
        emit("update:kdv", 0);
        emit("update:cariId", "");
        emit("update:cariName", "");
      }
    });


    async function load() {
      if (props.hizmetler && props.hizmetler.length > 0) {
        localState.items = props.hizmetler as HizmetMasrafEntity[];
      } else {
        localState.items = await useCachedHizmetMasrafGiderService().getHizmetMasrafList();
      }
      if (props.modelValue && props.modelValue.length > 0) {
        //
      } else {
        if (localState.items && props.firstSelect == true )
          localState.selected = localState.items[0].id as string;
      }
    }

    async function refresh() {
      localState.items = await useCachedHizmetMasrafGiderService().refreshHizmetMasraf();
    }


    load();
    return {
      ...toRefs(localState),
      refresh
    };
  }
});
