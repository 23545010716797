
export enum ActionStatusType{
Ready=0,
Sending=2,
Sended=3,
Error=4,
Processing=5
}

export const ActionStatusTypeList = [
{key:'Ready', value:0, description:'Hazır'},
{key:'Sending', value:2, description:'Gönderiliyor'},
{key:'Sended', value:3, description:'Gönderildi'},
{key:'Error', value:4, description:'Hata Oluştu'},
{key:'Processing', value:5, description:'İşleniyor'}
]
