import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {PrestaConfigEntity} from '../models/PrestaConfigEntity'

export const usePrestaConfigService = defineStore({
id: 'PrestaConfigService',
actions: {


	async getPrestaAyarGetir()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PrestaAyar/Getir/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postPrestaAyarKaydet(liste:PrestaConfigEntity[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PrestaAyar/Kaydet/${shared.firm.id}`,{ method:'POST', data:liste });
		await request();
		return response.value;
	},

	async getPrestaAyarSiparisStatuleri(configId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PrestaAyar/SiparisStatuleri/${shared.firm.id}/${configId}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
