import { NModuleRoute } from "@/models/route.model";

export const ReportsModuleRoutes: NModuleRoute[] = [
  {
    id: "PrintSchemas",
    order: 20,
    displayName: "Yazdırma Şablonları",
    requiredRoles:{
      readRoles:['ReportEkrani.Liste'],
      insertRoles:['ReportEkrani.Ekle'],
      updateRoles:['ReportEkrani.Guncelle'],
      deleteRoles:['ReportEkrani.Sil']
    },
    icon: {
      type: "printer"
    },
    component: () => import("../pages/ReportDesignerPage.vue")
  }
];
