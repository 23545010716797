import { usePopupService } from "@/services/popupService";
import axios from "axios";
import { useAccountService } from "./services/AccountService";
import { ResponseMessageConverter } from "@/api/utils/ResponseMessageConverter";

const axiosInstance = axios.create({maxRedirects: 0});// maxRedirects: Başarısız olunca, birden çok isteği sunucuya göndermez

axiosInstance.interceptors.request.use(
  config => {
    const account = useAccountService();
    if (account.isAuthenticated && account.token) {
      config.headers["Authorization"] = `Bearer ${account.token}`;
    }
    if (config.withCredentials)
      config.withCredentials = true;
    else
      config.withCredentials = false;

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function(response) {
    return response;
  },
  async function(error) {


    if (error.response)
      usePopupService().pushDangerNotification({
        body: ResponseMessageConverter.getMessage(error.response) || ""
      });

     return Promise.reject(error);
  }

);

export { axiosInstance as axios };
