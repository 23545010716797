import { fastCartesian } from "@/utils/cartesian/main";
import { SubeEntity } from "@/api/models/SubeEntity";
import { StokKategoriTreeListView } from "@/api/models/StokKategoriTreeListView";
import { StokView } from "@/api/models/StokView";
import { StokViewItem } from "@/api/models/StokViewItem";
import { ActionStatusType } from "@/api/models/ActionStatusType";
import { ActionType } from "@/api/models/ActionType";
import { useStokService } from "@/api/services/StokService";
import { uuid } from "vue-uuid";
import { PazaryeriFiyatTipi, PazaryeriFiyatTipiList } from "@/api/models/PazaryeriFiyatTipi";
import { N11PazaryeriItem } from "@/api/models/N11PazaryeriItem";
import { PazaryeriProcessItem } from "@/api/models/PazaryeriProcessItem";
import { StokEntity } from "@/api/models/StokEntity";
import { useStokFlatStore } from "@/modules/Stok/store/StokFlatStore";
import { FaturaHareketTip } from "@/api/models/FaturaHareketTip";
import { StokFlatView } from "@/api/models/StokFlatView";
import { StokBirimItem } from "@/api/models/StokBirimItem";
import { Ozellik } from "@/api/models/Ozellik";
import { SubeFiyatTipi } from "@/api/models/SubeFiyatTipi";
import { useCachedSubeDepoService } from "@/modules/Sube/services/useCachedSubeDepoService";
import { Doviz } from "@/api/models/Doviz";
import { useKurCachedServis } from "@/modules/Ortak/services/useKurCachedServis";
import {PrestaPazaryeriItem} from "@/api/models/PrestaPazaryeriItem";
import {KurView} from "@/api/models/KurView";
import {NModuleRoute} from "@/models";
import {useLayoutStore} from "@/layouts/ApplicationLayout/context";
import {usedCacheFirmaService} from "@/modules/Ayarlar/Firmalar/services/usedCacheFirmaService";
import { StokResimGuncelleImporterView } from "@/api/models/StokResimGuncelleImporterView";
import {StokAktarView} from "@/api/models/StokAktarView";


export const StokUtils = {
  kurList: [] as KurView[],
  stokId:"",
  isClone:false,
  tabKey:"",
  stokView:{},
  async load() {
    this.kurList = await useKurCachedServis().getKurList();
  },
  genelTabs: [
    {label: 'Genel', id: 0},
    {label: 'Diğer', id: 1},
    {label: 'Özellikler', id: 2},
    {label: 'Uyumluluklar', id: 3},
    {label: 'Birimler', id: 4},
    {label: 'Üreticiler', id: 5},
    {label: 'Şube', id: 6},
  ],

  openStokForm(stokId: string, isClone: boolean, stokView?: StokView) {

    this.stokId = stokId;
    this.isClone = isClone;
    this.tabKey = uuid.v4();
    this.stokView = stokView as StokView;
    const route: NModuleRoute = {
      id: "StokForm",
      order: 0,
      displayName: "Stok Formu",
      icon: {
        type: "box"
      }
    };
    useLayoutStore().openRouteAsTab(route);
  },
  openThreeMigrateForm() {
    this.tabKey = uuid.v4();
    const route: NModuleRoute = {
      id: "ThreeMigrateStok",
      order: 0,
      displayName: "Önceki Versiyondan Stok Aktar",
      icon: {
        type: "corner-right-down"
      }
    };
    useLayoutStore().openRouteAsTab(route);
  },
  closeStokForm(key: string) {
    useLayoutStore().closeTab(key)
  },
  openGrupluStok(stokGrupId: string) {
    this.stokId = stokGrupId;
    const route: NModuleRoute = {
      id: "GrupluStokForm",
      order: 0,
      displayName: "Gruplu Stok Formu",
      icon: {
        type: "box"
      }
    };
    useLayoutStore().openRouteAsTab(route);
  },
  async newStok() {
    const stokView = await useStokService().getDefault() as StokView;
    return stokView;
  },
  getDovizFiyat(fiyat: number, stokId: string) {

    const findStok = useStokFlatStore().getStok(stokId) as any;
    if (findStok) {
      if (findStok.satisDoviz != Doviz.TRY) {
        const kurlar = useKurCachedServis().kurList;

        if (kurlar) {
          const kur = kurlar.find(a => a.doviz == findStok.satisDoviz)?.alis;
          if (kur && kur > 0)
            return (fiyat * kur);
        }
      }
    }
    return fiyat;
  },
  getPerakendeSubeFiyat(fiyat: number, stokId: string, depoId: string): number {
    const depolar = useCachedSubeDepoService().depoUserList;

    let sonFiyat = fiyat;
    const subeEntity = depolar.find(a => a.depoId == depoId);
    if (subeEntity) {
      const findStok = useStokFlatStore().getStok(stokId) as StokFlatView;

      if (findStok) {
        const subeFiyat = findStok.subeFiyatlari?.find((a: any) => a.subeId == subeEntity.subeId);
        if (subeFiyat) {
          switch (subeFiyat.tip) {
            case SubeFiyatTipi.OranEkle: {
              if (findStok.satisKdvDahil) {
                sonFiyat = findStok.satisFiyat! / (1 + (findStok.satisKdv! / 100)) * (1 + (subeFiyat.deger! / 100));
              } else {
                sonFiyat = (findStok.satisFiyat! * (1 + (subeFiyat.deger! / 100)));
              }

            }
              break;
            case SubeFiyatTipi.TutarEkle: {
              if (findStok.satisKdvDahil) {
                sonFiyat = (findStok.satisFiyat! / (1 + (findStok.satisKdv! / 100))) + subeFiyat.deger!;
              } else {
                sonFiyat = (findStok.satisFiyat! + subeFiyat.deger!);
              }
            }
              break;

          }
        }
      }

    }
    return sonFiyat;
  },
  getSubeFiyat(subeId: string, stokFlat: StokFlatView): StokFlatView {
    const stk = Object.assign({}, stokFlat);
    const subeFiyat = stokFlat.subeFiyatlari?.find(a => a.subeId == subeId);
    if (subeFiyat) {

      switch (subeFiyat.tip) {
        case SubeFiyatTipi.OranEkleAlis: {
          if (stk.alisKdvDahil) {
            stk.satisFiyat = stk.getAlisFiyat! * (1 + (subeFiyat.deger! / 100));
          } else {
            stk.satisFiyat = (stk.getAlisFiyat! / (1 + (stk.alisKdv! / 100))) * (1 + (subeFiyat.deger! / 100));
          }

        }
          break;
        case SubeFiyatTipi.TutarEkleAlis: {
          if (stk.alisKdvDahil) {
            stk.satisFiyat = stk.getAlisFiyat! * (1 + (subeFiyat.deger! / 100));
          } else {
            stk.satisFiyat = (stk.getAlisFiyat! / (1 + (stk.alisKdv! / 100))) * (1 + (subeFiyat.deger! / 100));
          }

        }
          break;
        case SubeFiyatTipi.OranEkle: {
          if (stk.satisKdvDahil) {
            stk.satisFiyat = stk.getSatisFiyat! * (1 + (subeFiyat.deger! / 100));
          } else {
            stk.satisFiyat = (stk.getSatisFiyat! / (1 + (stk.satisKdv! / 100))) * (1 + (subeFiyat.deger! / 100));
          }

        }
          break;
        case SubeFiyatTipi.TutarEkle: {
          if (stk.satisKdvDahil) {
            stk.satisFiyat = stk.getSatisFiyat! + subeFiyat.deger!;
          } else {
            stk.satisFiyat = (stk.getSatisFiyat! / (1 + (stk.satisKdv! / 100))) + subeFiyat.deger!;
          }
        }
          break;

      }
    }

    return stk;

  },

  async getStokKontrol(idler: string[]): Promise<boolean> {
    for (let i = 0; i < idler.length; i++) {
      if(!useStokFlatStore().isExist((a:any) => a.id == idler[i]))
        return  false;
    }
    return true;
  },

  getBirimler(stokId: string): StokBirimItem[] {
    if (stokId && stokId.length > 0) {

      const stok = useStokFlatStore().getStoklar((a:any) => a.stokId == stokId,0,1);
      if (stok.length > 0) {
        return stok[0].birimFiyatlari as StokBirimItem[];
      }
    }
    return [];
  },

  getStokFiyat(stokId: string, hareketTipi: FaturaHareketTip): number {
    // const stoklarFlat =  useStokFlatStore().stoklarFlat  ;
    let fiyat = 0;
    const stok = useStokFlatStore().getStoklar((a:any) => a.stokId == stokId,0,1)[0];

    if (stok) {
      if (
        hareketTipi == FaturaHareketTip.SatisFatura ||
        hareketTipi == FaturaHareketTip.AlisIadeFaturasi
      ) {


        if (stok.satisKdvDahil == true) {
          fiyat = stok.satisFiyat! / (1 + (stok.satisKdv! / 100));
        } else {
          fiyat = stok.satisFiyat!;
        }

      } else {
        if (stok.alisKdvDahil == true) {
          fiyat = stok.alisFiyat! / (1 + (stok.alisKdv! / 100));
        } else {
          fiyat = stok.alisFiyat!;
        }
      }

    }


    return fiyat;
  },

  generateStokItems(stokView: StokView, subeler: SubeEntity[]) {
    let total = 0;
    const ozellikler = stokView.varyantlar;

    if (!stokView.items) stokView.items = [];
    if (ozellikler && ozellikler.length > 0) {
      // IOzellik[]

      const kartezyenlenecekler: any[] = []; // [ [Asus,Dell], [X1,X2] ]

      ozellikler.forEach((ozellik: any) => {
        // IOzellik.values.split()
        if (ozellik.values && ozellik.values.length > 0) {
          //const splitOZellikDegerler: any[] = ozellik.values.split(","); //  [Asus,Dell] , [X10,X20]
          kartezyenlenecekler.push(ozellik.selectedValues);
        }
      });

      const kartezyenListesi = fastCartesian(kartezyenlenecekler); // [ [Asus, X10], [Asus, X30]]

      kartezyenListesi.forEach((kartezyen: string[], index: number) => {
        // [Asus, X10]
        const ozellikValues: string[] = kartezyen;

        let barkod: number = Date.now();

        while (stokView.items?.find(a => a.barkod == barkod.toString())) {
          barkod++;
        }

        const item: StokViewItem = {
          varyantDegerleri: ozellikValues,
          barkod: barkod.toString(),
          resimler: [],
          // devirler: this.getDevirItems(subeler),
          id: uuid.v4(),
          satisFiyati: 0,
          alisFiyati: 0,
          itemKod: stokView.kod + "." + ozellikValues.join('.')
        };

        // Yoksa pushla
        const isExist = this.varmi(stokView, ozellikValues);

        if (!isExist) {
          total++;
          // console.log(stokView.items);
          if (stokView && stokView.items) {
            stokView.items.push(item);
          }
        }

      });
    } else {
      alert("Varyantlanabilir özellik yok.");
    }

    return total;
  },
  // getDevirItems(subeler: SubeEntity[]): StokDevirItem[] {
  //
  //   let count = 0;
  //   const devirItems: StokDevirItem[] = [];
  //   subeler.forEach((sube, s) => {
  //     count++;
  //     if (sube.depolar)
  //       sube.depolar.forEach((depo, d) => {
  //         count++;
  //         devirItems.push({
  //           sube: { id: sube.id, name: sube.name },
  //           depo: { id: depo.id, name: depo.name },
  //           devirMaliyeti: 0,
  //           devir: 0,
  //           //no: count.toString(),
  //           id: uuid.v4()
  //         } as StokDevirItem);
  //       });
  //   });
  //   return devirItems;
  // },
  varmi(stokView: StokView, degerler: any[]) {
    if (stokView.items) {
      for (const index in stokView.items) {
        const item = stokView.items[index];
        let isExist = true;

        item.varyantDegerleri?.forEach((a: any, i: number) => {
          if (degerler && degerler.length > 0 && a.length > 0) {
            const degerlerDeger = degerler[i];

            if (degerlerDeger) {
              isExist =
                isExist &&
                degerlerDeger.toString().toLowerCase() ==
                a.toString().toLowerCase();
            }
          }
        }); // liste
        if (isExist) {
          return true;
        }
      }
    }
    return false;
  },
  getColumns() {
    return [
      { label: "no", prop: "no", hidden: false, width: "80px" },
      {
        label: "Kategoriler",
        prop: "kategoriler",
        hidden: false,
        width: "300px"
      },
      {
        label: "Kod",
        prop: "kod",
        width: "120px",
        hidden: false
      },
      { label: "Barkod", prop: "barkod", hidden: false, width: "120px" },
      {
        label: "Stok Adı",
        prop: "ad",
        width: "300px",
        resizable: true,
        hidden: false
      },

      {
        label: "Üretici Kodları",
        prop: "ureticiKodlar",
        visible: false,
        width: "100px"
      },
      {
        label: "Kısa Açıklama",
        prop: "kisaAciklama",
        visible: false,
        width: "200px"
      },
      { label: "Açıklama", prop: "aciklama", visible: false, width: "200px" },
      {
        label: "Satış Fiyatı",
        prop: "satisFiyat",
        hidden: false,
        width: "100px"
      },
      { label: "Kdv (S)", prop: "satisKdv", hidden: false, width: "100px" },
      {
        label: "Kdv Dahil(S)",
        prop: "satisKdvDahil",
        hidden: false,
        width: "100px"
      },
      { label: "Döviz (S)", prop: "satisDoviz", hidden: false, width: "100px" },
      {
        label: "Alış Fiyatı",
        prop: "alisFiyat",
        hidden: false,
        width: "100px"
      },
      {
        label: "Kdv Dahil (A)",
        prop: "alisKdvDahil",
        hidden: false,
        width: "100px"
      },
      { label: "Döviz (A)", prop: "alisDoviz", hidden: false, width: "100px" },

      { label: "Birim", prop: "birim", hidden: false, width: "100px" },
      { label: "Resimler", prop: "resimler", hidden: false, width: "100px" },
      { label: "Varyantlar", prop: "items", hidden: false, width: "100px" },
      {
        label: "Birimler",
        prop: "birimFiyatlari",
        visible: false,
        width: "300px"
      },

      {
        label: "Özellikler",
        prop: "ozellikler",
        visible: false,
        width: "300px"
      },
      {
        label: "Varyant Özellikleri",
        prop: "varyantlar",
        visible: false,
        width: "300px"
      },
      {
        label: "Fiyat Özetleri",
        prop: "fiyatOzet",
        visible: false,
        width: "300px"
      },
      {
        label: "Miktar Özetleri",
        prop: "miktarOzet",
        hidden: false,
        width: "300px"
      }
    ];
  },
  getBarkod() {
    return (new Date().getTime() + Math.floor(Math.random() * 10000)).toString();
  },
  fillStokOzellikFromKategoriOzellik(stokOzellikler: Ozellik[], kategoriOzellikler: Ozellik[]) {
    // console.log(stokOzellikler, kategoriOzellikler);
    kategoriOzellikler.forEach((ozellikKategori) => {
      if (ozellikKategori.name && ozellikKategori.name.length) {
        const findIndex = stokOzellikler.findIndex(a => a.name?.toUpperCase() == ozellikKategori.name?.toUpperCase());

        if (findIndex === -1) {
          stokOzellikler.push(ozellikKategori);

        }
      }
    });
  },
  fillStokFromKategori(stok: StokView, kategori: StokKategoriTreeListView) {

    stok.birimFiyatlari = [];

    // Özellikler
    this.fillStokOzellikFromKategoriOzellik(stok.ozellikler as Ozellik[], kategori.ozellik?.ozellikler as Ozellik[]);
    this.fillStokOzellikFromKategoriOzellik(stok.ozellikler as Ozellik[], kategori.ozellikDevir?.ozellikler as Ozellik[]);

    // Varyant özellikleri
    if (!stok?.items || stok?.items?.length == 0)
      stok.varyantlar = []
    this.fillStokOzellikFromKategoriOzellik(stok.varyantlar as Ozellik[], kategori.ozellik?.varyantlar as Ozellik[]);
    this.fillStokOzellikFromKategoriOzellik(stok.varyantlar as Ozellik[], kategori.ozellikDevir?.varyantlar as Ozellik[]);

    // stok.ozellikler.push(...kategori.ozellik?.ozellikler as []);
    // stok.ozellikler.push(...kategori.ozellikDevir?.ozellikler as []);

    // stok.varyantlar?.push(...kategori.ozellik?.varyantlar as []);
    // stok.varyantlar?.push(...kategori.ozellikDevir?.varyantlar as []);

    stok.birimFiyatlari.push(...kategori.ozellik?.birimler as []);
    stok.birimFiyatlari.push(...kategori.ozellikDevir?.birimler as []);


    stok.subeFiyatlari = [];
    stok.subeFiyatlari.push(...kategori.ozellik?.subeFiyatlari as []); // 5 2

    if (kategori.ozellikDevir?.subeFiyatlari) // 10 5
      for (let i = 0; i < kategori.ozellikDevir?.subeFiyatlari?.length; i++) {
        const subeFiyatDevir = kategori.ozellikDevir.subeFiyatlari[i];
        const stokSubeFiyat = stok.subeFiyatlari?.find(a => a.subeId == subeFiyatDevir.subeId);
        if (stokSubeFiyat) {
          if (stokSubeFiyat.deger == 0) {
            stokSubeFiyat.deger = subeFiyatDevir.deger;
            stokSubeFiyat.tip = subeFiyatDevir.tip;
          }
        }
      }


    if (stok.birimFiyatlari && stok.birimFiyatlari.length > 0)
      for (let i = 0; i < stok.birimFiyatlari.length; i++) {
        stok.birimFiyatlari[i].barkod = this.getBarkod();
        //stok.birimFiyatlari[i].fiyat = stok.satisFiyat;
      }


    if (stok.varyantlar && stok.varyantlar.length > 0)
      stok.varyantlar.forEach((varyant: any) => {
        varyant.selectedValues = this.clone(varyant.values);
      });
    if (stok.id && stok.id.length > 0) return;

    // Stok N11
    if (kategori.ozellik?.n11 != undefined && kategori.ozellik?.n11?.length > 0)
      stok.n11 = this.clone(kategori.ozellik?.n11);

    // Stok Trendyol
    if (kategori.ozellik?.trendyol != undefined && kategori.ozellik?.trendyol?.length > 0)
      stok.trendyol = this.clone(kategori.ozellik?.trendyol);

    // Stok HepsiBurada
    if (kategori.ozellik?.hepsiBurada != undefined && kategori.ozellik?.hepsiBurada?.length > 0)
      stok.hepsiBurada = this.clone(kategori.ozellik?.hepsiBurada);

    // Stok HepsiBurada Listing
    if (kategori.ozellik?.hepsiBuradaListing != undefined && kategori.ozellik?.hepsiBuradaListing?.length > 0)
      stok.hepsiBuradaListing = this.clone(kategori.ozellik?.hepsiBuradaListing);

    // Stok Ciçek Sepeti
    if (kategori.ozellik?.cicekSepeti != undefined && kategori.ozellik?.cicekSepeti?.length > 0)
      stok.cicekSepeti = this.clone(kategori.ozellik?.cicekSepeti);

    // Stok Presta
    if (kategori.ozellik?.presta != undefined && kategori.ozellik?.presta?.length > 0)
      stok.presta = this.clone(kategori.ozellik?.presta);

    // Stok PttAvm
    if (kategori.ozellik?.epttAvm != undefined && kategori.ozellik?.epttAvm?.length > 0)
      stok.epttAvm = this.clone(kategori.ozellik?.epttAvm);

    // Stok Pazarama
    if (kategori.ozellik?.pazarama != undefined && kategori.ozellik?.pazarama?.length > 0)
      stok.pazarama = this.clone(kategori.ozellik?.pazarama);

    // Stok Birimler
    //stok.birimFiyatlari = this.clone(kategori.ozellik?.birimler)


    // Kdv
    if (kategori.ozellik?.kdv) {
      stok.alisKdv = kategori.ozellik?.kdv;
      stok.satisKdv = kategori.ozellik?.kdv;
    } else if (kategori.ozellikDevir?.kdv) {
      stok.alisKdv = kategori.ozellikDevir?.kdv;
      stok.satisKdv = kategori.ozellikDevir?.kdv;
    }
    // Satış Kdv Dahil
    if (kategori.ozellik?.satisKdvDahil != undefined)
      stok.satisKdvDahil = kategori.ozellik?.satisKdvDahil;

    // Kar Marjı
    if (kategori.ozellik?.karMarji != undefined)
      stok.karMarji = kategori.ozellik?.karMarji;


    // Alış Kdv Dahil
    if (kategori.ozellik?.alisKdvDahil != undefined)
      stok.alisKdvDahil = kategori.ozellik?.alisKdvDahil;

    // Birim
    if (kategori.ozellik?.birim)
      stok.birim = kategori.ozellik?.birim;
    else if (kategori.ozellikDevir?.birim)
      stok.birim = kategori.ozellikDevir?.birim;


    // Açıklama
    if (kategori.ozellik?.stokAciklama) {
      stok.aciklama = kategori.ozellik?.stokAciklama;
    } else if (kategori.ozellikDevir?.stokAciklama) {
      stok.aciklama = kategori.ozellikDevir?.stokAciklama;
    }
    // Kısa Açıklama
    if (kategori.ozellik?.stokKisaAciklama) {
      stok.kisaAciklama = kategori.ozellik?.stokKisaAciklama;
    } else if (kategori.ozellikDevir?.stokKisaAciklama) {
      stok.kisaAciklama = kategori.ozellikDevir?.stokKisaAciklama;
    }

    // Stok Tip
    stok.tip = kategori.ozellik?.tip;

  },
  clone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  },
  getSatisFiyati(fiyat: N11PazaryeriItem, satisFiyati: number, alisFiyati: number) {

    switch (fiyat.fiyat?.tip) {
      case PazaryeriFiyatTipi.Otomatik:
        return (((alisFiyati * ((fiyat.karMarji as number + 100) / 100)) + (fiyat.kargoTutar as number)) / (100 - (fiyat.komisyon as number))) * 100;
      case PazaryeriFiyatTipi.Oran:
        return satisFiyati * (((fiyat.fiyat?.deger as number) + 100) / 100);
      case PazaryeriFiyatTipi.Tutar:
        return satisFiyati + (fiyat.fiyat?.deger as number);
      case PazaryeriFiyatTipi.Fiyat:
        return (fiyat.fiyat?.deger as number);
      default:
        return 0;
    }
  },
  getSatisFiyatiArtiOran(satisFiyati: number, oran: number) {
    return satisFiyati * ((oran + 100) / 100);
  },
  getOzellikler(stok: StokEntity) {
    if (stok.ozellikler)
      return stok.ozellikler.filter(a => a.value).map(a => a.value).join(", ");
    return "-";
  },
  getOzelliklerDetails(stok: StokEntity) {
    if (stok.ozellikler)
      return stok.ozellikler.filter(a => a.value).map(a => "\n" + a.name + ": " + a.value).join(", ");

    return "-";
  },
  getStatusDescription(pazaryeriProcessItem?: PazaryeriProcessItem) {

    if (pazaryeriProcessItem) {

      const actionType: ActionType = pazaryeriProcessItem.actionType as ActionType;
      const actionStatusType: ActionStatusType = pazaryeriProcessItem.actionStatusType as ActionStatusType;

      if (actionType == ActionType.Gonder && actionStatusType == ActionStatusType.Ready)
        return "Gönderilecek";

      //console.log(actionType + "-" + actionStatusType)

      switch (actionType) {

        case ActionType.Guncelle:
          switch (actionStatusType) {
            case ActionStatusType.Sending:
              return "Güncelleniyor";
            case ActionStatusType.Sended:
              return "Güncellendi";
            case ActionStatusType.Ready:
              return "Güncellenecek";
            case ActionStatusType.Processing:
              return "Güncelleme İşleniyor";
            case ActionStatusType.Error:
              return "Güncellenemedi";
          }
          break;
        case ActionType.Sil:
          switch (actionStatusType) {
            case ActionStatusType.Sending:
              return "Siliniyor";
            case ActionStatusType.Sended:
              return "Silindi";
            case ActionStatusType.Ready:
              return "Silinecek";
            case ActionStatusType.Processing:
              return "Silme İşleniyor";
            case ActionStatusType.Error:
              return "Silinemedi";
          }
          break;
        case ActionType.Gonder:
          switch (actionStatusType) {
            case ActionStatusType.Sending:
              return "Gönderiliyor";
            case ActionStatusType.Sended:
              return "Gönderildi";
            case ActionStatusType.Ready:
              return "Gönderilecek";
            case ActionStatusType.Processing:
              return "Gönderme İşleniyor";
            case ActionStatusType.Error:
              return "Gönderilemedi";
          }
          break;
        case ActionType.Islem_Yok:
          return "Pasif";
      }
      // alert(actionType + "-" + actionStatusType)
    }

    return "Pasif";
  },
  async stokAktar(aktarView: any) {

    await useStokService().postAktar(aktarView);
  },
  async stokAktarFiyatGuncelle(stok: any){
    await useStokService().putStokFiyatGuncelleImporter(stok)
  },
  async stokAktarResimGuncelle(stok: StokResimGuncelleImporterView){
    await useStokService().putStokResimGuncelleImporter(stok)
  },
  getPazaryeriFiyat(stok: StokEntity, pazaryeri: PrestaPazaryeriItem) {
    switch (pazaryeri?.fiyat?.tip) {
      case PazaryeriFiyatTipi.Otomatik:
        return (((this.getKdvDahilAlisFiyat(stok.alisFiyat as number, stok.alisKdv as number, stok.alisKdvDahil as boolean) * this.carpan(pazaryeri.karMarji as number)) + this.getKargoFiyat(stok as StokEntity, pazaryeri)) / this.bolen(pazaryeri.komisyon as number));
      case PazaryeriFiyatTipi.Oran:
        return (((this.getKdvDahilSatisFiyat(stok) * this.carpan(pazaryeri.fiyat?.deger as number)) + this.getKargoFiyat(stok, pazaryeri)) / this.bolen(pazaryeri.komisyon as number));
      case PazaryeriFiyatTipi.Tutar:
        return (((this.getKdvDahilSatisFiyat(stok as StokEntity) + (pazaryeri.fiyat?.deger as number)) + this.getKargoFiyat(stok as StokEntity, pazaryeri)) / this.bolen(pazaryeri.komisyon as number));
      case PazaryeriFiyatTipi.Fiyat:
        return pazaryeri?.fiyat?.deger as number;
      default:
        return 0;
    }
  },


  getPazaryeriFiyatTip(fiyatTip: number) {
    const tip = PazaryeriFiyatTipiList.find(a => a.value == fiyatTip);
    if (tip)
      return tip.description;
    return "-";
  },

  getKargoFiyat(stok: StokEntity, pazaryeri: PrestaPazaryeriItem) {
    const doviz = this.getDoviz(stok, pazaryeri);
    if (doviz != Doviz.TRY) {
      const kur = this.getKur(stok, pazaryeri);
      if (kur && kur != 0)
        return pazaryeri.kargoTutar as number / kur;
    }
    return pazaryeri.kargoTutar as number;
  },

  getDoviz(stok: StokEntity, pazaryeri: PrestaPazaryeriItem) {
    if (pazaryeri?.fiyat?.tip == PazaryeriFiyatTipi.Otomatik)
      return stok.alisDoviz;
    return stok.satisDoviz;
  },

  getKdvDahilSatisFiyat(stok: StokEntity) {
    if (stok.satisKdvDahil == false)
      return this.kdvLiFiyat(stok.satisFiyat as number, stok.satisKdv as number);
    return stok.satisFiyat as number;
  },

  getKur(stok: StokEntity, pazaryeri: PrestaPazaryeriItem) {

    const doviz: Doviz = this.getDoviz(stok, pazaryeri) as Doviz;

    if (isNaN(doviz))
      return 0;

    if (doviz == Doviz.TRY)
      return 1;

    const kurItem = this.kurList.find(a => a.doviz == doviz);

    if (!kurItem)
      return 1;

    return kurItem?.alis;
  },
  getKdvDahilAlisFiyat(alisFiyati: number, alisKdv: number, alisKdvDahil: boolean) {
    if (alisKdvDahil == false)
      return this.kdvLiFiyat(alisFiyati, alisKdv as number);
    return alisFiyati;
  },
  carpan(oran: number) {
    return (100 + oran) / 100;
  },
  bolen(oran: number) {
    return (100 - oran) / 100;
  },

  kdvLiFiyat(fiyat: number, kdv: number) {
    return ((100 + kdv) / 100) * fiyat;
  },
  getPazaryeriTitle(islem: PazaryeriProcessItem) {
    if (islem)
      return islem.date?.formatDateTime() + ": " + islem.description;

    return "";
  },
  aramaPasGecilen(arananKelime:string|undefined, arananField:string|undefined) {

    const firmaEntity = usedCacheFirmaService().firma;
    //const pasGecilenKelimeler : string[] = [' ', '/', 'R'];
    const pasGecilenKelimeler = firmaEntity.pasGecilecekKarakterler as string[];
    let arananFieldTemp = arananField;

    for (let i = 0; i < pasGecilenKelimeler?.length; i++) {
      const karakter = pasGecilenKelimeler[i];
      arananFieldTemp = arananFieldTemp?.replaceAll(karakter, '')
    }

    if (arananFieldTemp?.icindeGeciyorMu(arananKelime))
      return true;
    else
      return false;
  }




};