import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_BankaSelectBox = _resolveComponent("BankaSelectBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_KasaSelectBox = _resolveComponent("KasaSelectBox")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_PosSelectBox = _resolveComponent("PosSelectBox")!
  const _component_KrediKartiSelectBox = _resolveComponent("KrediKartiSelectBox")!
  const _component_HizmetMasrafSelectBox = _resolveComponent("HizmetMasrafSelectBox")!
  const _component_DxDateBox = _resolveComponent("DxDateBox")!
  const _component_NSelectBox = _resolveComponent("NSelectBox")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_ProjeSelectBox = _resolveComponent("ProjeSelectBox")!
  const _component_GiderYeriSelectBox = _resolveComponent("GiderYeriSelectBox")!
  const _component_SubeSelectBox = _resolveComponent("SubeSelectBox")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_TutarsalBilgiler = _resolveComponent("TutarsalBilgiler")!
  const _component_CokluDokuman = _resolveComponent("CokluDokuman")!
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NTextarea = _resolveComponent("NTextarea")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    modelValue: _ctx.showRef,
    "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.showRef) = $event)),
    width: "650px",
    "closable-mask": false,
    tabs: [
        {label:'Evrak Bilgileri'} ,
        {label:'Dökümanlar'} ,
        {label:'> Gönder'}
        ],
    "selected-tab": _ctx.selectedTab,
    "onUpdate:selectedTab": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.selectedTab) = $event))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.menuModel.name) + " ", 1),
      _createVNode(_component_NTag, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.selectedBanka.bankaAdi), 1)
        ]),
        _: 1
      })
    ]),
    body: _withCtx(() => [
      _createVNode(_component_NLoadingPanel, {
        title: "Yükleniyor...",
        action: _ctx.load
      }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(_component_NFormGrid, {
              gap: "0",
              cols: "1fr 1fr",
              rows: "auto auto 1fr"
            }, {
              default: _withCtx(() => [
                (_ctx.menuModel.refrenceType === _ctx.menuRefrenceType.Banka)
                  ? (_openBlock(), _createBlock(_component_NFormField, {
                      key: 0,
                      "grid-cols": "80px 1fr",
                      label: _ctx.getBankaLabel(),
                      span: "2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_BankaSelectBox, {
                          name: _ctx.model.referansAd,
                          "onUpdate:name": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.referansAd) = $event)),
                          modelValue: _ctx.model.referansId,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.referansId) = $event))
                        }, null, 8, ["name", "modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label"]))
                  : _createCommentVNode("", true),
                (_ctx.menuModel.refrenceType === _ctx.menuRefrenceType.Kasa)
                  ? (_openBlock(), _createBlock(_component_NFormField, {
                      key: 1,
                      "grid-cols": "80px 1fr",
                      label: "Kasa",
                      span: "2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_KasaSelectBox, {
                          name: _ctx.model.referansAd,
                          "onUpdate:name": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.referansAd) = $event)),
                          modelValue: _ctx.model.referansId,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.referansId) = $event))
                        }, null, 8, ["name", "modelValue"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.menuModel.refrenceType === _ctx.menuRefrenceType.Krediler)
                  ? (_openBlock(), _createBlock(_component_NFormField, {
                      key: 2,
                      "grid-cols": "80px 1fr",
                      label: "Kredi",
                      span: "1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxSelectBox, {
                          "display-expr": "ad",
                          "value-expr": "id",
                          items: _ctx.krediler,
                          value: _ctx.model.referansId,
                          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.referansId) = $event)),
                          searchEnabled: false,
                          "show-clear-button": false,
                          onValueChanged: _ctx.krediValueChanged
                        }, null, 8, ["items", "value", "onValueChanged"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.menuModel.refrenceType === _ctx.menuRefrenceType.Krediler)
                  ? (_openBlock(), _createBlock(_component_NFormField, {
                      key: 3,
                      "grid-cols": "80px 1fr",
                      label: "Faiz Tutar",
                      span: "1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxNumberBox, {
                          value: _ctx.model.faizTutar,
                          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.faizTutar) = $event)),
                          format: "#,##0.00"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.menuModel.refrenceType === _ctx.menuRefrenceType.Pos)
                  ? (_openBlock(), _createBlock(_component_NFormField, {
                      key: 4,
                      "grid-cols": "80px 1fr",
                      label: "Pos",
                      span: "2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_PosSelectBox, {
                          name: _ctx.model.referansAd,
                          "onUpdate:name": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.referansAd) = $event)),
                          modelValue: _ctx.model.referansId,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.referansId) = $event))
                        }, null, 8, ["name", "modelValue"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.menuModel.refrenceType === _ctx.menuRefrenceType.Kredi_Karti)
                  ? (_openBlock(), _createBlock(_component_NFormField, {
                      key: 5,
                      "grid-cols": "80px 1fr",
                      label: "Kredi Kartı",
                      span: "2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_KrediKartiSelectBox, {
                          name: _ctx.model.referansAd,
                          "onUpdate:name": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.referansAd) = $event)),
                          modelValue: _ctx.model.referansId,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.referansId) = $event))
                        }, null, 8, ["name", "modelValue"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.menuModel.refrenceType === _ctx.menuRefrenceType.Hizmet_Masraf)
                  ? (_openBlock(), _createBlock(_component_NFormField, {
                      key: 6,
                      "grid-cols": "80px 1fr",
                      label: "Hizmet/Masraf",
                      span: "2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_HizmetMasrafSelectBox, {
                          "cari-id": _ctx.model.cariId,
                          "onUpdate:cariId": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.cariId) = $event)),
                          kdv: _ctx.model.kdv,
                          "onUpdate:kdv": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.kdv) = $event)),
                          "hizmet-adi": _ctx.model.referansAd,
                          "onUpdate:hizmetAdi": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.referansAd) = $event)),
                          "cari-name": _ctx.model.cariName,
                          "onUpdate:cariName": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.cariName) = $event)),
                          modelValue: _ctx.model.referansId,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.referansId) = $event))
                        }, null, 8, ["cari-id", "kdv", "hizmet-adi", "cari-name", "modelValue"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_NFormField, {
                  label: "Evrak Tarihi",
                  "grid-cols": "80px 1fr"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxDateBox, {
                      value: _ctx.model.tarih,
                      "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.tarih) = $event)),
                      onValueChanged: _ctx.evrakTarihiChanged
                    }, null, 8, ["value", "onValueChanged"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_NFormField, {
                  "grid-cols": "80px 1fr",
                  label: "Evrak Tipi"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NSelectBox, {
                      modelValue: _ctx.model.genelResmiMuhasebeTip,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model.genelResmiMuhasebeTip) = $event)),
                      items: _ctx.genelResmiMuhasebeTipList,
                      "label-field": "key",
                      "value-field": "value"
                    }, null, 8, ["modelValue", "items"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_NFormField, {
                  "grid-cols": "80px 1fr",
                  label: "Evrak No"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NInput, {
                      type: "text",
                      modelValue: _ctx.model.evrakNo,
                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model.evrakNo) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_NFormField, {
                  "grid-cols": "80px 1fr",
                  label: "Vade Tarihi"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxDateBox, {
                      value: _ctx.model.vade,
                      "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.model.vade) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_NFormField, {
                  "grid-cols": "80px 1fr",
                  label: "Açıklama"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NInput, {
                      type: "text",
                      modelValue: _ctx.model.aciklama,
                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.model.aciklama) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_NFormField, {
                  "grid-cols": "80px 1fr",
                  label: "Proje"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ProjeSelectBox, {
                      modelValue: _ctx.model.projeId,
                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.model.projeId) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_NFormField, {
                  "grid-cols": "80px 1fr",
                  label: "Gider Yeri",
                  span: "2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_GiderYeriSelectBox, {
                      modelValue: _ctx.model.giderYeri,
                      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.model.giderYeri) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_NGrid, {
                  cols: "1fr 0.7fr 1fr",
                  style: {"grid-column":"span 2"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NFormField, {
                      "grid-cols": "80px 1fr",
                      label: "Kdv Tutar"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxNumberBox, {
                          value: _ctx.model.kdvTutar,
                          "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.model.kdvTutar) = $event)),
                          format: "#,##0.####"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      "grid-cols": "80px 1fr",
                      label: "Kdv"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxNumberBox, {
                          value: _ctx.model.kdv,
                          "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.model.kdv) = $event)),
                          "value-change-event": "keyup"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      label: "Şube",
                      "grid-cols": "80px 1fr"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_SubeSelectBox, {
                          modelValue: _ctx.model.subeId,
                          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.model.subeId) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ], 512), [
            [_vShow, _ctx.selectedTab === 0]
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(_component_TutarsalBilgiler, {
              kurlar: _ctx.kurlarRef,
              "islem-hesaplama-entity": _ctx.model.hesaplama,
              "hareket-id": _ctx.model.id
            }, null, 8, ["kurlar", "islem-hesaplama-entity", "hareket-id"])
          ], 512), [
            [_vShow, _ctx.selectedTab == 0]
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(_component_CokluDokuman, {
              modelValue: _ctx.model.dokumanlar,
              "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.model.dokumanlar) = $event))
            }, null, 8, ["modelValue"])
          ], 512), [
            [_vShow, _ctx.selectedTab === 1 ]
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _createVNode(_component_NFormGrid, {
              cols: "1fr",
              gap: "2px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NFormGrid, { cols: "1fr" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NFormField, {
                      "grid-cols": "75px 1fr 150px",
                      label: "Cep"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NInput),
                        _createVNode(_component_NSwitch, { label: "Sms Gönder" })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_NFormGrid, { cols: "1fr" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NFormField, {
                      "grid-cols": "75px 1fr 150px",
                      label: "Mail"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NInput),
                        _createVNode(_component_NSwitch, { label: "Mail Gönder" })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_NFormGrid, { cols: "1fr" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NFormField, {
                      "grid-cols": "75px 1fr 150px",
                      label: "Kargo"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NSelectBox, {
                          items: ['Yurtiçi','Aras' , 'Mng' , 'Sürat' , 'Ptt' , 'UPS'],
                          "label-field": "",
                          "value-field": ""
                        }),
                        _createVNode(_component_NSwitch, { label: "Kargo Gönder" })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_NFormField, {
                  "grid-cols": "75px 1fr",
                  label: "Açıklama"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NTextarea, { placeholder: "Açıklama Buraya Giriniz" })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ], 512), [
            [_vShow, _ctx.selectedTab === 2 ]
          ])
        ]),
        _: 1
      }, 8, ["action"])
    ]),
    actions: _withCtx(() => [
      (_ctx.model.id && _ctx.model.id.length > 0)
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 0,
            type: "danger",
            size: "small",
            icon: "trash",
            click: _ctx.hareketDelete
          }, null, 8, ["click"]))
        : _createCommentVNode("", true),
      _createVNode(_component_NButton, {
        type: "warning",
        size: "small",
        icon: "x-circle",
        onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.showRef = false))
      }, {
        default: _withCtx(() => [
          _createTextVNode("İptal")
        ]),
        _: 1
      }),
      _createVNode(_component_NButton, {
        type: "success",
        size: "small",
        icon: "save",
        click: _ctx.save
      }, {
        default: _withCtx(() => [
          _createTextVNode("Kaydet")
        ]),
        _: 1
      }, 8, ["click"]),
      _createVNode(_component_NButton, {
        type: "info",
        size: "small",
        icon: "printer",
        click: _ctx.saveAndPrint
      }, {
        default: _withCtx(() => [
          _createTextVNode("Kaydet ve Yazdır")
        ]),
        _: 1
      }, 8, ["click"])
    ]),
    _: 1
  }, 8, ["modelValue", "selected-tab"]))
}