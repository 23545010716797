
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import {KampanyaFaturaView} from "@/api/models/KampanyaFaturaView";
import {useKampanyaService} from "@/api/services/KampanyaService";
import {KampanyaEntity} from "@/api/models/KampanyaEntity";
import NDropdownMenu from "@/components/Dropdown/NDropdownMenu.vue";
import NButton from "@/components/Button/NButton.vue";
import {FaturaView} from "@/api/models/FaturaView";
import {useStokStore} from "@/modules/Stok/store/StokStore";
import {StokEntity} from "@/api/models/StokEntity";
import {FaturaSatirItem} from "@/api/models/FaturaSatirItem";
import {KaynakHedefTipi} from "@/api/models/KaynakHedefTipi";
import {IdName} from "@/api/models/IdName";
import {DegerTipi} from "@/api/models/DegerTipi";
import NIcon from "@/components/Icon/NIcon.vue";
import NDropdown from "@/components/Dropdown/NDropdown.vue";
import {useCachedSubeDepoService} from "@/modules/Sube/services/useCachedSubeDepoService";
import {DepoFlatView} from "@/api/models/DepoFlatView";
import {useCachedPlasiyerList} from "@/modules/Plasiyer/services/useCachedPlasiyerList";
import {PlasiyerEntity} from "@/api/models/PlasiyerEntity";
import {uuid} from "vue-uuid";
import NGrid from "@/components/Grid/NGrid.vue";
import NTag from "@/components/Tag/NTag.vue";
import KampanyaKategoriSelectDialog from "@/modules/Kampanya/dialogs/KampanyaKategoriSelectDialog.vue";
import {StokFlatView} from "@/api/models/StokFlatView";
import {FaturaPlanSatirItem} from "@/api/models/FaturaPlanSatirItem";
import {FaturaPlanStokTip} from "@/api/models/FaturaPlanStokTip";
import {StokKampanyaFlatView} from "@/api/models/StokKampanyaFlatView";
import {KampanyaView} from "@/api/models/KampanyaView";
import NCard from "@/components/Card/NCard.vue";
import {useCariCachedService} from "@/modules/Cari/services/useCariCachedService";
import {useCariStore} from "@/modules/Cari/store/CariStore";

export default defineComponent({
  components: {
    NCard,
    NGrid,
    NTag,
    NIcon,
    NButton,
    NDropdownMenu,
    NDropdown,
    KampanyaKategoriSelectDialog
  },
  props: {
    fatura: {
      type: FaturaView
    },
  },

  setup(props, {emit}) {
    // kampanyaUygula($event, item)
    const localState = reactive({
      model: computed(() => props.fatura as FaturaView),
      list: ref([] as KampanyaEntity[]),
      debugKampanyaFaturaView: ref(),
      depoList: ref([] as DepoFlatView[]),
      plasiyerList: ref([] as PlasiyerEntity[]),
      uygulananKampanyaAdi: ref(''),
      showKategoriSecDialog: ref(false),
      kaynakHedefTipi: KaynakHedefTipi,
      selectedKampanyaId: ref(''),
    })


    async function load() {

      localState.depoList = await useCachedSubeDepoService().getUserDepoList();
      localState.plasiyerList = await useCachedPlasiyerList().getUserPlasiyerList();


      localState.list = [];
      // FaturaEntity
      const kampanyaFaturaView = await convertFaturaToKampanyaFaturaView(localState.model) as KampanyaFaturaView;
      // KampanyaFaturaView
      localState.debugKampanyaFaturaView = kampanyaFaturaView;
      localState.list = await useKampanyaService().postGetAllByFatura(kampanyaFaturaView);
    }

    function getHediyeList(entity: KampanyaEntity) {
      return entity?.getDropDownSatir?.split('/')
    }

    function getItem(satir: string, tur: string) {
      satir = satir?.replace('[', '')?.replace(']', '')
      if (tur == 'id')
        return satir.split('|')[0]
      if (tur == 'name')
        return satir.split('|')[1]
    }

    function idNamesSeperate(array: any[]) {

      return array.map((a) => a.name).join(", ");

    }

    async function kampanyaUygula(selectedId: string, kampanya: KampanyaEntity) {

      // console.log(selectedId)
      //
      //
      // const a = 10;
      // if (a === 10)
      //   return;

      setSatirKategoriIds(localState.model)
      resetSatir(localState.model);

      localState.uygulananKampanyaAdi = kampanya.name as string;

      switch (kampanya.hedef?.degerTipi) {
        case DegerTipi.Oran:


          if (selectedId.length == 0) // Hepsini satıra indirir
          {
            kampanya.hedef?.idNames?.forEach((idName) => {
              const stok = stokIdToFaturaSatirItem(idName.id as string, kampanya.id as string, 1, -1)
              stok.iskontolar = [kampanya.hedef?.deger as number]

              localState.model.satirlar?.push(stok);
            })
          } else {
            const stok = stokIdToFaturaSatirItem(selectedId as string, kampanya.id as string, 1, -1)
            stok.iskontolar = [kampanya.hedef?.deger as number]
            localState.model.satirlar?.push(stok);
          }

          break;
        case DegerTipi.Tutar:


          if (selectedId.length == 0) // Hepsini satıra indirir
          {
            kampanya.hedef?.idNames?.forEach((idName) => {
              const stok = stokIdToFaturaSatirItem(idName.id as string, kampanya.id as string, 1, -1)
              stok.fiyat = (stok?.fiyat as number) - (kampanya.hedef?.deger as number);
              localState.model.satirlar?.push(stok);
            })

          } else {
            const stok = stokIdToFaturaSatirItem(selectedId as string, kampanya.id as string, 1, -1)
            stok.fiyat = (stok?.fiyat as number) - (kampanya.hedef?.deger as number);
            if (stok && (stok.fiyat as number) < 0)
              stok.fiyat = 0;
            localState.model.satirlar?.push(stok);
          }

          break;

        case DegerTipi.Fiyat:


          if (selectedId.length == 0) // Hepsini satıra indirir
          {
            kampanya.hedef?.idNames?.forEach((idName) => {
              const stok = stokIdToFaturaSatirItem(idName.id as string, kampanya.id as string, 1, kampanya.hedef?.deger as number)
              localState.model.satirlar?.push(stok);
            })

          } else {
            const stok = stokIdToFaturaSatirItem(selectedId as string, kampanya.id as string, 1, kampanya.hedef?.deger as number)
            localState.model.satirlar?.push(stok);
          }

          break;

        case DegerTipi.Adet:
          // kaynak Çarpanı x Miktar

            //console.log('adet olarak eklenecek')
          if (selectedId.length == 0) // Hepsini satıra indirir
          {
            //console.log('111111')
            kampanya.hedef?.idNames?.forEach((idName) => {
              const stok = stokIdToFaturaSatirItem(idName.id as string, kampanya.id as string, kampanya.hedef?.deger as number, 0)
              localState.model.satirlar?.push(stok);
            })

          } else {
            //console.log('222222')
            const stok = stokIdToFaturaSatirItem(selectedId as string, kampanya.id as string, kampanya.hedef?.deger as number, 0)
            //console.log('stok', stok)
            // stok.iskontolar = [kampanya.hedef?.deger as number]
            localState.model.satirlar?.push(stok);
          }

          break;
      }

      //console.log(localState.model)
      emit('refreshList')


    }

    function stokIdToFaturaSatirItem(stokId: string, kampanyaId: string, miktar: number, fiyat: number) {
      const varsDepoId = localState.depoList[0]?.depoId as string;
      const varsPlasiyerId = localState.plasiyerList[0]?.id as string;

      const stok = useStokStore().getStok(stokId) as StokEntity;
      if (!stok)
        throw new Error("Stok bulunamadı!")

      return {
        id: uuid.v4(),
        ad: stok.ad,
        stokId: stok.id,
        fiyat: fiyat >= 0 ? fiyat : stok.satisFiyat,
        miktar: miktar,
        kdv: stok.satisKdv,
        kampanyaId: kampanyaId,
        itemId: '',
        birim: stok.birim,
        barkod: stok.barkod,
        aciklama: stok.aciklama,
        carpan: 1,
        doviz: stok.satisDoviz,
        kod: stok.kod,
        plasiyerId: varsPlasiyerId,
        depoId: varsDepoId,
      } as FaturaSatirItem
    }


    function getHedeflenenSatirlar(fatura: FaturaView, kampanya: KampanyaEntity) {
      return fatura.satirlar?.filter(satir => hedeflenenSatirFilter(satir, kampanya));
    }

    function hedeflenenSatirFilter(satir: any, kampanya: KampanyaEntity) {
      let result = undefined;
      switch (kampanya.kaynak?.tip) {
        case KaynakHedefTipi.Stok:

          result = kampanya.kaynak?.idNames?.find((a: IdName) => a.id == satir.stokId);
          if (result)
            return true;

          break;
        case KaynakHedefTipi.Kategori:

          result = kampanya.kaynak?.idNames?.find((a) => satir.kategoriIds?.findIndex((b: any) => b.id == a.id) > -1)
          if (result)
            return true;
          break;
      }

      return false;
    }


    function setSatirKategoriIds(fatura: FaturaView) {

      const faturaSatirlar = (fatura.satirlar as any[])?.filter(a => !a.kategoriIds) as any[];
      faturaSatirlar.forEach((satir) => {
        satir.kategoriIds = getStokKategoriIds(satir.stokId as string);
      })
    }

    async function resetSatir(fatura: FaturaView) {
      const faturaSatirlar = fatura.satirlar?.filter(a => a.kampanyaId == undefined || a.kampanyaId == '' || a.kampanyaId?.length == 0) as FaturaSatirItem[];
      fatura.satirlar = faturaSatirlar;
      // faturaSatirlar.forEach((satir) => {
      //   satir.iskontolar = [];
      //   satir.kampanyaId = undefined;
      // })

      // if (fatura.altBilgi)
      //   fatura.altBilgi.altIskontolar = [];
    }

    async function convertFaturaToKampanyaFaturaView(faturaView: any) {

      const cariSelectBoxListe = await useCariStore().getCariOzetleri();
      const cari = cariSelectBoxListe?.find(a => a.id == faturaView.cariId)
      // console.log("cari", cari)

      const kampanyaFaturaView = {
        // cariId: faturaView.ustBilgi?.cari?.id ?? faturaView.cariId,
        cariId: faturaView.cariId,
        grup1:cari?.grup1,
        grup2:cari?.grup2,
        grup3:cari?.grup3,
        grup4:cari?.grup4,
        satirlar: faturaView.satirlar?.map((a: any) =>
            ({
              kampanyaId: a.kampanyaId,
              stokId: a.stokId,
              kategoriIds: a.kategoriIds,
              miktar: a.miktar,
              fiyat: getKdvliFiyat(a.fiyat as number, a.kdv as number),
            })
        )
      } as KampanyaFaturaView;

      return kampanyaFaturaView;
    }

    function getStokKategoriIds(stokId: string) {

      //console.log("stoklar", stoklar)
      const stok = useStokStore().arrayStore.createQuery().filter((a: any) => a.id == stokId).slice(0,1).toArray()[0] as StokEntity;
      return stok?.kategoriler;
    }

    function getKdvliFiyat(fiyat: number, kdv: number) {
      return fiyat * ((100 + kdv) / 100);
    }

    function getIndirimOrani(fiyat: number, tutar: number) {
      if (fiyat == 0)
        return 0;
      return (tutar * 100) / fiyat
    }

    function getFiyatOrani(fiyat: number, istenilenFiyat: number) {
      if (fiyat == 0)
        return 0;
      return 100 - (100 / (fiyat / istenilenFiyat));
    }

    function getSelectedKampanyaId() {
      const faturaClone = JSON.parse(JSON.stringify(props.fatura)) as FaturaView;
      const kampanyaSayisi = groupBySize(faturaClone.satirlar?.filter(a => a.kampanyaId != '' && a.kampanyaId != undefined) as [], 'kampanyaId');
      if (kampanyaSayisi == 0 || localState.uygulananKampanyaAdi.length == 0)
        return 'Kampanya Seç';
      return localState.uygulananKampanyaAdi + ' uygulandı';
    }

    function getSelectedKampanyaLabel() {
      return getSelectedKampanyaId();
    }

    function groupBySize(array: [], key: string) {
      if (array) {
        const arrayUniqueByKey = [...new Map(array.map(item =>
            [item[key], item])).values()];
        // console.log('arrayUniqueByKey', arrayUniqueByKey)
        return arrayUniqueByKey.length;
      } else
        return 0;
    }

    function stokSec(selectedKampanyaId: string) {
      localState.showKategoriSecDialog = true;
      localState.selectedKampanyaId = selectedKampanyaId;
    }

    function tekStokEkle(stok: StokKampanyaFlatView, kampanya: KampanyaView, coklu: boolean) {
      if (!coklu) {
        setSatirKategoriIds(localState.model)
        resetSatir(localState.model);
      }

      localState.uygulananKampanyaAdi = kampanya.name as string;
      if (stok) {
        //////  if adet , oran , tutar , fiyat vs
        let stokFatura = {} as FaturaSatirItem;
        switch (kampanya.hedef?.degerTipi) {
          case DegerTipi.Adet:
            stokFatura = stokIdToFaturaSatirItem(stok.stokId as string, kampanya.id as string, (coklu ? stok.kampanyaMiktar : kampanya.hedef?.deger) as number, 0)
            break;

          case DegerTipi.Oran:
            stokFatura = stokIdToFaturaSatirItem(stok.stokId as string, kampanya.id as string, 1, -1)
            stokFatura.iskontolar = [kampanya.hedef?.deger as number]
            break;

          case DegerTipi.Fiyat:
            stokFatura = stokIdToFaturaSatirItem(stok.stokId as string, kampanya.id as string, 1, kampanya.hedef?.deger as number)
            break;

          case DegerTipi.Tutar:
            stokFatura = stokIdToFaturaSatirItem(stok.stokId as string, kampanya.id as string, 1, -1)
            stokFatura.fiyat = (stokFatura?.fiyat as number) - (kampanya.hedef?.deger as number);
            break;
        }

        if (stokFatura && (stokFatura.fiyat as number) < 0)
          stokFatura.fiyat = 0;

        // stokFatura.iskontolar = [kampanya.hedef?.deger as number]
        localState.model.satirlar?.push(stokFatura);
        emit('refreshList')
      }
    }

    function coklu(stoklar: StokKampanyaFlatView[], kampanya: KampanyaView) {
      if (stoklar && stoklar.length > 0) {
        setSatirKategoriIds(localState.model)
        resetSatir(localState.model);
        stoklar.forEach((stok) => {

          tekStokEkle(stok, kampanya, true);

        })
      }
    }


    return {
      ...toRefs(localState),
      load,
      getSelectedKampanyaLabel,
      kampanyaUygula,
      getHediyeList,
      getItem,
      idNamesSeperate,
      stokSec,
      coklu,
      tekStokEkle
    }


  }
})
