
import { computed, defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "NListCard",
  props: ["id", "items", "modelValue"],
  setup(props, { emit }) {
    const selectedItemIndex = ref<number>(props.modelValue);

    function selectItem(index: number) {
      selectedItemIndex.value = index;
      emit("update:modelValue", index);
    }
    watch(
      () => props.modelValue,
      (newValue: any, oldValue: any) => {
        selectedItemIndex.value = newValue;
      }
    );

    return {
      selectItem,
      selectedItemIndex
    };
  }
});
