
import {computed, defineComponent, reactive, ref, toRefs} from "vue";

import {useSiparisService} from "@/api/services/SiparisService";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import {SiparisHareketTip, SiparisHareketTipList} from "@/api/models/SiparisHareketTip";
import {DovizList} from "@/api/models/Doviz";
import {FaturaSatirTipList} from "@/api/models/FaturaSatirTip";
import NButton from "@/components/Button/NButton.vue";
import {FirmaCekSenetFlatView} from "@/api/models/FirmaCekSenetFlatView";
import {SiparisSatirFlatView} from "@/api/models/SiparisSatirFlatView";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import NGrid from "@/components/Grid/NGrid.vue";
import SiparisFlatListBekleyenler from "@/modules/SiparisTeklif/dialogs/SiparisFlatListBekleyenler.vue";

export default defineComponent({
    components: { SiparisFlatListBekleyenler, NButton,},
    props: {},
    setup(props, {emit}) {

        const localState = reactive({
            selectSiparisDialogShow:ref(false),
            siparisHareketTip : SiparisHareketTip,
        });



        function  siparistenEkleClick(){
            localState.selectSiparisDialogShow  = true
        }

        function addRow(a:any) {
            emit('addRow', a)
        }
        return {
            ...toRefs(localState),
            siparistenEkleClick,
            addRow
        };
    }
});

