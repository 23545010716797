
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import {useStokService} from "@/api/services/StokService";
import {StokView} from "@/api/models/StokView";
import {SubeFiyatTipi, SubeFiyatTipiList} from "@/api/models/SubeFiyatTipi";
import {SubeFiyatItem} from "@/api/models/SubeFiyatItem";
import {DovizList} from "@/api/models/Doviz";
import {StokOzellikItem} from "@/api/models/StokOzellikItem";
import NCard from "@/components/Card/NCard.vue";


export default defineComponent({
  components: {NCard},
  props: {
    modelValue: {
      type: Object
    },
    subeler:{
      type:Array
    },
    title:{
      type:String
    },
    disabled:{
      type:Boolean,
      default:false
    }
  },
  setup(props) {

    const localState = reactive({
      modelRef: computed(() => props.modelValue as StokOzellikItem),
      selectedColumn: ref(''),
      subeFiyatTipList:SubeFiyatTipiList,
      dovizList: DovizList,
    });

    function getSubeSatisFiyati(row:SubeFiyatItem) {
      if (row){
        if (row.tip == SubeFiyatTipi.OranEkle)
          return getKdvliFiyat(props.modelValue?.satisFiyat as number,props.modelValue?.satisKdv as number, props.modelValue?.satisKdvDahil as boolean) *((100 + (row.deger as number))/100)
        else if (row.tip == SubeFiyatTipi.TutarEkle)
          return getKdvliFiyat(props.modelValue?.satisFiyat as number,props.modelValue?.satisKdv as number, props.modelValue?.satisKdvDahil as boolean) + (row.deger as number);
        else if (row.tip == SubeFiyatTipi.OranEkleAlis)
          return getKdvliFiyat(props.modelValue?.alisFiyat as number,props.modelValue?.alisKdv as number, props.modelValue?.alisKdvDahil as boolean) *((100 + (row.deger as number))/100)
        else if (row.tip == SubeFiyatTipi.TutarEkleAlis)
          return getKdvliFiyat(props.modelValue?.alisFiyat as number,props.modelValue?.alisKdv as number, props.modelValue?.alisKdvDahil as boolean) + (row.deger as number);
      }
    }

    function getKdvliFiyat(fiyat:number, kdv:number, kdvDahil:boolean){
      if (kdvDahil)
        return fiyat;
      return  fiyat * ((100+kdv) / 100);
    }

    return {
      ...toRefs(localState),
      getSubeSatisFiyati
    };
  }
});

