export {};


Number.prototype.format = function(
  decimalMinCount: number,
  decimalMaxCount: number
): string {
  return new Intl.NumberFormat("tr-TR", {
    minimumFractionDigits: decimalMinCount,
    maximumFractionDigits: decimalMaxCount
  }).format(this as number).toString();
};



Number.prototype.formatToNumber = function(
    decimalMinCount: number,
    decimalMaxCount: number
): number {

  const num = this.format(decimalMinCount,decimalMaxCount).replace('.','').replace(',','.')
  const parsed = parseFloat(num);
  return parsed;
};
