
import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";

export default class FaturaFormProp {
  constructor(
    public menu: MenuModel,
    public cariId: string,
    public faturaId?: string,
    public model?: any,
    public fatuuraTip?:any,
  ) {}
}



