import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NMultipleDocumentUploader = _resolveComponent("NMultipleDocumentUploader")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NTextarea = _resolveComponent("NTextarea")!
  const _component_DxDateBox = _resolveComponent("DxDateBox")!
  const _component_NListView = _resolveComponent("NListView")!

  return (_openBlock(), _createElementBlock("div", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), [
    _createVNode(_component_NButton, {
      onClick: _ctx.yeniDokuman,
      type: "primary",
      size: "small",
      icon: "upload-cloud",
      block: true
    }, {
      default: _withCtx(() => [
        _createTextVNode("Yeni Döküman Ekle ")
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_NListView, {
      items: _ctx.model,
      "model-value": -1
    }, {
      item: _withCtx(({item , index}) => [
        _createVNode(_component_NFormField, {
          label: "Evraklar",
          "grid-cols": "50px 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NMultipleDocumentUploader, {
              "folder-name": "",
              height: 35,
              width: 35,
              src: item.url,
              "server-url": _ctx.serverUrl,
              "show-upload-button": true,
              modelValue: item.url,
              "onUpdate:modelValue": ($event: any) => ((item.url) = $event)
            }, null, 8, ["src", "server-url", "modelValue", "onUpdate:modelValue"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_NFormField, {
          "grid-cols": "50px 1fr",
          label: "Not"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NTextarea, {
              rows: "2",
              modelValue: item.aciklama,
              "onUpdate:modelValue": ($event: any) => ((item.aciklama) = $event),
              placeholder: "Döküman Açıklaması Giriniz"
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_NFormField, {
          label: "Tarih",
          "grid-cols": "50px 1fr auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxDateBox, {
              value: item.tarih,
              "onUpdate:value": ($event: any) => ((item.tarih) = $event)
            }, null, 8, ["value", "onUpdate:value"]),
            _createVNode(_component_NButton, {
              onClick: ($event: any) => (_ctx.silDokuman(index)),
              type: "danger",
              size: "small",
              icon: "trash"
            }, null, 8, ["onClick"])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["items"])
  ], 16))
}