import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NFormFieldLabelTop = _resolveComponent("NFormFieldLabelTop")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_NCard = _resolveComponent("NCard")!
  const _component_NTextarea = _resolveComponent("NTextarea")!
  const _component_NInput = _resolveComponent("NInput")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NCard, {
      col: "1fr",
      title: "Kargo Bilgileri",
      gap: "0px"
    }, {
      bottom: _withCtx(() => [
        _createVNode(_component_NFormField, {
          "grid-cols": "auto auto",
          label: "Gönder"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NSwitch, {
              type: "warning",
              pulse: "",
              modelValue: _ctx.model.kargoGonder,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.kargoGonder) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_NGrid, {
          cols: "1fr 1fr",
          gap: "0px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NFormFieldLabelTop, {
              label: "Kargo Seçiniz",
              span: "2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxSelectBox, {
                  "display-expr": "description",
                  "value-expr": "value",
                  items: _ctx.kargoTipList,
                  searchEnabled: false,
                  value: _ctx.model.kargoTip,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.kargoTip) = $event)),
                  "show-clear-button": false
                }, null, 8, ["items", "value"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormFieldLabelTop, { label: "Teslimat Tipi" }, {
              default: _withCtx(() => [
                _createVNode(_component_DxSelectBox, {
                  "display-expr": "description",
                  "value-expr": "value",
                  items: _ctx.kargoTeslimatTipList,
                  searchEnabled: false,
                  value: _ctx.model.kargoTeslimatTip,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.kargoTeslimatTip) = $event)),
                  "show-clear-button": false
                }, null, 8, ["items", "value"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormFieldLabelTop, { label: "Kargo Boyutu" }, {
              default: _withCtx(() => [
                _createVNode(_component_DxSelectBox, {
                  "display-expr": "description",
                  "value-expr": "value",
                  items: _ctx.kargoBoyutTipList,
                  searchEnabled: false,
                  value: _ctx.model.kargoBoyutTip,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.kargoBoyutTip) = $event)),
                  "show-clear-button": false
                }, null, 8, ["items", "value"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormFieldLabelTop, { label: "Kargo Teslim Tip" }, {
              default: _withCtx(() => [
                _createVNode(_component_DxSelectBox, {
                  "display-expr": "description",
                  "value-expr": "value",
                  items: _ctx.kargoTeslimTipList,
                  searchEnabled: false,
                  value: _ctx.model.kargoTeslimTip,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.kargoTeslimTip) = $event)),
                  "show-clear-button": false
                }, null, 8, ["items", "value"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormFieldLabelTop, { label: "Kargo Ödeme Tipi" }, {
              default: _withCtx(() => [
                _createVNode(_component_DxSelectBox, {
                  "display-expr": "description",
                  "value-expr": "value",
                  items: _ctx.kargoOdemeTipList,
                  searchEnabled: false,
                  value: _ctx.model.kargoOdemeTip,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.kargoOdemeTip) = $event)),
                  "show-clear-button": false
                }, null, 8, ["items", "value"])
              ]),
              _: 1
            }),
            _createVNode(_component_NGrid, {
              cols: "1fr 1fr 2fr",
              style: {"grid-column":"span 2"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NFormFieldLabelTop, { label: "Desi" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxNumberBox, {
                      min: "0",
                      value: _ctx.model.desi,
                      "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.desi) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_NFormFieldLabelTop, { label: "Kg" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxNumberBox, {
                      min: "0",
                      value: _ctx.model.kg,
                      "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.kg) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_NFormFieldLabelTop, { label: "Kapıda Ödeme" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NSwitch, {
                      type: "danger",
                      modelValue: _ctx.model.kapidaOdemeMi,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.kapidaOdemeMi) = $event)),
                      style: {"text-align":"center","margin":"5px auto"},
                      disabled: !(_ctx.model.kargoOdemeTip != _ctx.kargoOdemeTip.Platform)
                    }, null, 8, ["modelValue", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.model.kargoOdemeTip == _ctx.kargoOdemeTip.Platform)
              ? (_openBlock(), _createBlock(_component_NFormFieldLabelTop, {
                  key: 0,
                  label: "Platform Seçiniz "
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxSelectBox, {
                      "display-expr": "description",
                      "value-expr": "value",
                      items: _ctx.platformTipList,
                      searchEnabled: false,
                      value: _ctx.model.platformTip,
                      "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.platformTip) = $event)),
                      "show-clear-button": false
                    }, null, 8, ["items", "value"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.model.kargoOdemeTip == _ctx.kargoOdemeTip.Platform)
              ? (_openBlock(), _createBlock(_component_NFormFieldLabelTop, {
                  key: 1,
                  label: "Kampanya Kodu "
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxTextBox, {
                      value: _ctx.model.platformKampanyaKodu,
                      "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.platformKampanyaKodu) = $event))
                    }, null, 8, ["value"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.model.kapidaOdemeMi)
              ? (_openBlock(), _createBlock(_component_NFormFieldLabelTop, {
                  key: 2,
                  label: "Ödeme Tipi"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxSelectBox, {
                      "display-expr": "description",
                      "value-expr": "value",
                      items: _ctx.kapidaOdemeTipList,
                      searchEnabled: false,
                      value: _ctx.model.kapidaOdemeTip,
                      "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.kapidaOdemeTip) = $event)),
                      "show-clear-button": false
                    }, null, 8, ["items", "value"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.model.kapidaOdemeMi)
              ? (_openBlock(), _createBlock(_component_NFormFieldLabelTop, {
                  key: 3,
                  label: "Tutar"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxNumberBox, {
                      placeholder: _ctx.tutarRef,
                      modelValue: _ctx.model.kapidaOdemeTutar,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.kapidaOdemeTutar) = $event)),
                      format: "#,##0.##"
                    }, null, 8, ["placeholder", "modelValue"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_NCard, {
      cols: "1fr",
      title: "SMS Bilgisi",
      gap: "0px"
    }, {
      bottom: _withCtx(() => [
        _createVNode(_component_NGrid, { cols: "auto auto" }, {
          default: _withCtx(() => [
            _createVNode(_component_NFormField, { label: "Gönder" }, {
              default: _withCtx(() => [
                _createVNode(_component_NSwitch, {
                  type: "warning",
                  pulse: "",
                  modelValue: _ctx.model.smsGonder,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.smsGonder) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Otm. Gönder",
              "grid-cols": "auto auto"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NSwitch, {
                  type: "success",
                  pulse: "",
                  modelValue: _ctx.model.otomatikSmsGonder,
                  "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.otomatikSmsGonder) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_DxTextBox, {
          value: _ctx.model.smsNumara,
          "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.smsNumara) = $event)),
          mask: _ctx.genelUtils.isAllNumber(_ctx.model.smsNumara) ? '(000) 000 00 00' : ''
        }, null, 8, ["value", "mask"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_NFormField, {
          "grid-cols": "1px 1fr",
          label: " "
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NTextarea, {
              placeholder: "SMS Metni",
              rows: "2"
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_NCard, {
      cols: "1fr",
      title: "E-Posta Bilgisi",
      gap: "1px"
    }, {
      bottom: _withCtx(() => [
        _createVNode(_component_NGrid, { cols: "auto auto" }, {
          default: _withCtx(() => [
            _createVNode(_component_NSwitch, {
              type: "warning",
              pulse: "",
              modelValue: _ctx.model.eArsivMailGonder,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model.eArsivMailGonder) = $event)),
              label: "E-Arşiv Mail Gön."
            }, null, 8, ["modelValue"]),
            _createVNode(_component_NSwitch, {
              type: "success",
              pulse: "",
              modelValue: _ctx.model.mailGonder,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model.mailGonder) = $event)),
              label: "Normal Mail Gön."
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_NFormField, {
          "grid-cols": "1px 1fr",
          label: " "
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.model.mailAdres,
              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.model.mailAdres) = $event)),
              placeholder: "Gönderilecek E-Posta Hesabı"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          "grid-cols": "1px 1fr",
          label: " "
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NTextarea, {
              modelValue: _ctx.model.mailMetin,
              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.model.mailMetin) = $event)),
              placeholder: "E-Posta Metni",
              rows: "2"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}