import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {UserSettingsView} from '../models/UserSettingsView'

export const useUserSettingsService = defineStore({
id: 'UserSettingsService',
actions: {


	async getGetValue(name:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UserSettings/GetValue/${shared.firm.id}/${name}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postSave(userSettingsView:UserSettingsView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UserSettings/Save/${shared.firm.id}`,{ method:'POST', data:userSettingsView });
		await request();
		return response.value;
	},

	async deleteDelete(name:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/UserSettings/Delete/${shared.firm.id}`,{ method:'DELETE' });
		await request();
		return response.value;
	}
}
});
