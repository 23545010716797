import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxList = _resolveComponent("DxList")!
  const _component_DxDropDownBox = _resolveComponent("DxDropDownBox")!

  return (_openBlock(), _createBlock(_component_DxDropDownBox, {
    title: _ctx.selectedValueRef,
    onValueChanged: _ctx.valueChanged,
    "show-clear-button": _ctx.showClearButton,
    "accept-custom-value": true,
    inputAttr: _ctx.getInputAttr(),
    class: "n-dropdown-list-box",
    ref: "dropDown",
    disabled: _ctx.disabledRef,
    placeholder: _ctx.placeholder,
    value: _ctx.label
  }, {
    content: _withCtx(({  }) => [
      (_ctx.items && _ctx.items.length > 0)
        ? (_openBlock(), _createBlock(_component_DxList, {
            key: 0,
            onItemClick: _ctx.itemClick,
            searchMode: _ctx.searchMode,
            placeholder: _ctx.placeholder,
            searchTimeout: "300",
            "selection-mode": "single",
            searchEditorOptions: {placeholder: _ctx.items.length.format(0,0) + ' kayıt içinde ara...'},
            "selected-items": _ctx.selectedItems,
            "onUpdate:selectedItems": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItems) = $event)),
            displayExpr: _ctx.displayExpr,
            searchExpr: _ctx.displayExpr,
            keyExpr: _ctx.valueExpr,
            "search-enabled": true,
            "data-source": _ctx.items
          }, null, 8, ["onItemClick", "searchMode", "placeholder", "searchEditorOptions", "selected-items", "displayExpr", "searchExpr", "keyExpr", "data-source"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "onValueChanged", "show-clear-button", "inputAttr", "disabled", "placeholder", "value"]))
}