
import {computed, defineComponent, onActivated, reactive, ref, toRefs, watch} from "vue";
import {DepoIdName} from "@/api/models/DepoIdName";
import {useSubeService} from "@/api/services/SubeService";
import {StokHareketEntity} from "@/api/models/StokHareketEntity";
import {GenelResmiMuhasebeTipList} from "@/api/models/GenelResmiMuhasebeTip";
import {DovizList} from "@/api/models/Doviz";
import {useStokHareketService} from "@/api/services/StokHareketService";
import {StokHareketTip, StokHareketTipList} from "@/api/models/StokHareketTip";
import {useFaturaService} from "@/api/services/FaturaService";
import {useFaturaStore} from "@/modules/Fatura/store";
import {MenuFormType} from "@/modules/Ortak/models/Menu/MenuFormType";
import {MenuRefrenceType} from "@/modules/Ortak/models/Menu/MenuRefrenceType";
import {FaturaHareketTip, FaturaHareketTipList} from "@/api/models/FaturaHareketTip";
import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import {FaturaView} from "@/api/models/FaturaView";
import {SiparisView} from "@/api/models/SiparisView";
import {useSiparisService} from "@/api/services/SiparisService";
import {useSiparisStore} from "@/modules/SiparisTeklif/stores";
import {SiparisHareketTip, SiparisHareketTipList} from "@/api/models/SiparisHareketTip";
import {useFisStore} from "@/modules/Fis/routes";
import {StokFisView} from "@/api/models/StokFisView";
import {useStokFisService} from "@/api/services/StokFisService";
import NCard from "@/components/Card/NCard.vue";
import {useBasitUretimStore} from "@/modules/Stok/store/BasitUretimStore";
import {useBasitUretimService} from "@/api/services/BasitUretimService";
import {DevExtremeUtils} from "@/devextreme/DevExtremeUtils";
import NDxStateStoring from "@/devextreme/NDxStateStoring.vue";
import SacUretimTalimatFormDialog from "@/modules/SacUretim/dialogs/SacUretimTalimatFormDialog.vue";
import {useSacUretimFisService} from "@/api/services/SacUretimFisService";
import {SacUretimFisEntity} from "@/api/models/SacUretimFisEntity";
import {StokUtils} from "@/modules/Stok/utils/StokUtils";
import SacProfilUretimTalimatFormDialog from "@/modules/SacUretim/Profil/dialogs/SacProfilUretimTalimatFormDialog.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NButton from "@/components/Button/NButton.vue";

export default defineComponent({
  components: {NButton, NGrid, SacProfilUretimTalimatFormDialog, SacUretimTalimatFormDialog, NDxStateStoring, NCard },
  props: {
    allListe: {
      type: Boolean,
      default: false
    },
    stokId: {
      type: String
    },
    itemId: {
      type: String
    },
    cariId: {
      type: String
    },
    selectTab: {
      type: Number,
      default: -1
    }
  },
  setup: function(props, { emit }) {
    const localState = reactive({
      stokHareketListesi: ref([] as StokHareketEntity[]),
      tumStokHareketleriListesiDataGrid: ref(),
      stokHareketleriListesiDataGrid: ref(),
      depoIdNameRef: ref([] as DepoIdName[]),
      tumStokHareketleri: ref([] as StokHareketEntity[]),
      genelResmiMuhasebeRef: GenelResmiMuhasebeTipList,
      dovizListRef: DovizList,
      stokIdRef: computed(() => props.stokId),
      itemIdRef: computed(() => props.itemId),
      cariIdRef: computed(() => props.cariId),
      stokHareketTipList: StokHareketTipList,
        showSacTalimatDialog:ref(false),
      tabs: [
        { label: "Önceki Satışlar", icon: "chevrons-up" },
        { label: "Bu Cariye Satışlar", icon: "user-plus", disabled: !(props.cariId && props.cariId.length > 0) },
        { label: "Önceki Alışlar", icon: "chevrons-down" },
        { label: "Bu Cariden Alışlar", icon: "user-minus", disabled: !(props.cariId && props.cariId.length > 0) },
        { label: "Teklifler", icon: "shopping-bag" },
        { label: "Siparişler", icon: "shopping-cart" },
        { label: "Tüm Hareketler", icon: "align-justify" }
      ],
      selectedTab: ref(0),
      sacTalimat:ref({} as SacUretimFisEntity),
      showSacProfilTalimatDialog: ref(false)

    });
    load();

    function getDisabled(): boolean {
      if (localState.cariIdRef && localState.cariIdRef.length > 0)
        return false;
      else
        return true;
    }

    onActivated(()=>{
      DevExtremeUtils.updateScroll(localState.stokHareketleriListesiDataGrid);
      DevExtremeUtils.updateScroll(localState.tumStokHareketleriListesiDataGrid);
    })
    async function rowDblClick(stokHareket: StokHareketEntity) {
  //    console.log(stokHareket);


      if (stokHareket) {
        if (
          stokHareket.tip == StokHareketTip.AlisSiparis ||
          stokHareket.tip == StokHareketTip.SatisSiparis ||
          stokHareket.tip == StokHareketTip.AlisTeklif ||
          stokHareket.tip == StokHareketTip.SatisTeklif
        ) {
          const siparisView: SiparisView = await useSiparisService().getStokHareketToSiparisView(stokHareket.referansId as string);
          if (siparisView) {

            useSiparisStore().openSiparisFormView(
              {
                formType: MenuFormType.Siparis_Form,
                refrenceType: MenuRefrenceType.Kullanilmaz,
                hareketTipi: siparisView.tip as SiparisHareketTip,
                name: SiparisHareketTipList.find(a => a.value == siparisView.tip)?.description + " " + (stokHareket?.stok?.name as string)
              } as MenuModel,
              siparisView.cariId as string,
              siparisView.cariUnvan as string,
              siparisView.id as string,
              siparisView
            );
          }

        } else if (
          stokHareket.tip == StokHareketTip.UretimdeKullanilan ||
          stokHareket.tip == StokHareketTip.UretimFire ||
          stokHareket.tip == StokHareketTip.UretimGiren
        ) {

          const id = await useBasitUretimService().getGenelUretimId(stokHareket.referansId as string);
          useBasitUretimStore().openBasitUretimForm(id);

        } else if (
          stokHareket.tip == StokHareketTip.SayimCikan ||
          stokHareket.tip == StokHareketTip.SayimGiren ||
          stokHareket.tip == StokHareketTip.NakilCikan ||
          stokHareket.tip == StokHareketTip.NakilGiren ||
          stokHareket.tip == StokHareketTip.GirisFisi ||
          stokHareket.tip == StokHareketTip.CikisFisi ||
          stokHareket.tip == StokHareketTip.DevirFisi
        ) {
          const sayimView: StokFisView = await useStokFisService().getStokHareketToFisView(stokHareket.referansId as string);

          if (sayimView) {
            useFisStore().openFisForm({
                hareketTipi: sayimView.tip,
                formType: MenuFormType.FisForm,
                name: StokHareketTipList.find(a => a.value == sayimView.tip)?.description + " " + (stokHareket?.stok?.name as string)
              },
              sayimView.id as string,
              sayimView);
          }
        }else if(stokHareket.tip == StokHareketTip.SacGirisFis || stokHareket.tip == StokHareketTip.SacCikisFis){
            localState.sacTalimat = await useSacUretimFisService().getFindFisFromSatirId(stokHareket.referansId as string);
            if (localState.sacTalimat?.satirlar?.[0].profilTip != 0)
                localState.showSacProfilTalimatDialog = true;
            else
                localState.showSacTalimatDialog = true;

        }
        else {
          const faturaView: FaturaView = await useFaturaService().getStokHareketToFaturaView(stokHareket.referansId as string);
          if (faturaView) {
            useFaturaStore().viewToFaturaForm(
              {
                formType: MenuFormType.Fatura_Form,
                refrenceType: MenuRefrenceType.Kullanilmaz,
                hareketTipi: faturaView.tip as FaturaHareketTip,
                name: FaturaHareketTipList.find(a => a.value == faturaView.tip)?.description + " " + (stokHareket?.stok?.name as string)
              } as MenuModel,
              faturaView as FaturaView,
              faturaView.faturaTip
            );

          }
        }

      }

    }

    function stokHareketListesiDataGridRefresh() {
      localState.stokHareketleriListesiDataGrid?.instance?.refresh();
    }

    async function load() {
      localState.selectedTab = props.selectTab == -1 ? 6 : props.selectTab;
      await tabaGoreDataGridYukle();

      localState.depoIdNameRef = await useSubeService().getDepolar();

      if (props.allListe == true) {
        localState.tumStokHareketleri = await useStokHareketService().getHareketListe();
      } else {
        if (localState.stokIdRef && localState.stokIdRef.length > 0 && localState.itemIdRef && localState.itemIdRef.length > 0)
          localState.tumStokHareketleri = await useStokHareketService().getItemIdHareketListe(localState.itemIdRef);
        else {
          if (localState.stokIdRef && localState.stokIdRef.length > 0)
            localState.tumStokHareketleri = await useStokHareketService().getStokIdHareketListe(localState.stokIdRef as string);
        }
      }
    }

    async function tabaGoreDataGridYukle() {
      localState.stokHareketListesi = [];
      switch (localState.selectedTab) {
        case 0 : {
          if (localState.itemIdRef && localState.itemIdRef.length > 0)
            localState.stokHareketListesi = await useStokHareketService().getSatisHareketListeItemId(localState.stokIdRef as string, localState.itemIdRef);
          else
            localState.stokHareketListesi = await useStokHareketService().getSatisHareketListeStokId(localState.stokIdRef as string);

          stokHareketListesiDataGridRefresh();
          break;
        }

        case 1 : {
          if (localState.cariIdRef && localState.cariIdRef.length) {
            if (localState.itemIdRef && localState.itemIdRef.length > 0)
              localState.stokHareketListesi = await useStokHareketService().getSatisHareketListeItemCari(localState.stokIdRef as string, localState.itemIdRef, localState.cariIdRef);
            else
              localState.stokHareketListesi = await useStokHareketService().getSatisHareketListeCari(localState.stokIdRef as string, localState.cariIdRef);
          }
          stokHareketListesiDataGridRefresh();
          break;
        }

        case 2 : {
          if (localState.itemIdRef && localState.itemIdRef.length > 0)
            localState.stokHareketListesi = await useStokHareketService().getAlisHareketListeItemId(localState.stokIdRef as string, localState.itemIdRef);
          else
            localState.stokHareketListesi = await useStokHareketService().getAlisHareketListeStokId(localState.stokIdRef as string);

          stokHareketListesiDataGridRefresh();
          break;
        }
        case 3 : {
          if (localState.cariIdRef && localState.cariIdRef.length) {
            if (localState.itemIdRef && localState.itemIdRef.length > 0)
              localState.stokHareketListesi = await useStokHareketService().getAlisHareketListeItemCari(localState.stokIdRef as string, localState.itemIdRef, localState.cariIdRef);
            else
              localState.stokHareketListesi = await useStokHareketService().getAlisHareketListeCari(localState.stokIdRef as string, localState.cariIdRef);
          }
          stokHareketListesiDataGridRefresh();
          break;
        }

        case 4 : {

          if (localState.itemIdRef && localState.itemIdRef.length > 0)
            localState.stokHareketListesi = await useStokHareketService().getTeklifHareketListeItemId(localState.stokIdRef as string, localState.itemIdRef);
          else
            localState.stokHareketListesi = await useStokHareketService().getTeklifHareketListeStokId(localState.stokIdRef as string);

          stokHareketListesiDataGridRefresh();
          break;
        }

        case 5 : {

          if (localState.itemIdRef && localState.itemIdRef.length > 0)
            localState.stokHareketListesi = await useStokHareketService().getSiparisHareketListeItemId(localState.stokIdRef as string, localState.itemIdRef);
          else
            localState.stokHareketListesi = await useStokHareketService().getSiparisHareketListeStokId(localState.stokIdRef as string);

          stokHareketListesiDataGridRefresh();
          break;
        }
      }
    }

    watch(() => localState.selectedTab, async () => {
      await tabaGoreDataGridYukle();
    });

    async function loadStateHareketler() {
      return await DevExtremeUtils.loadState(localState.stokHareketleriListesiDataGrid?.instance);
    }
    async function loadStateTumHareketler() {
      return await DevExtremeUtils.loadState(localState.tumStokHareketleriListesiDataGrid?.instance);
    }

      return {
          ...toRefs(localState),
          rowDblClick,
          loadStateHareketler,
          loadStateTumHareketler,
          load,
      };
  }
});
