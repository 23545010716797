
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import { useTrendyolKategoriOzellikService } from "@/api/services/TrendyolKategoriOzellikService";
import { TrendyolKategoriOzellikResponse } from "@/api/models/TrendyolKategoriOzellikResponse";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NDropDownListBox from "@/components/Dropdown/NDropDownListBox.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import { TrendyolOzellikItem } from "@/api/models/TrendyolOzellikItem";
import { N11Attribute } from "@/api/models/N11Attribute";
import { useN11CategoryService } from "@/api/services/N11CategoryService";
import { N11OzellikItem } from "@/api/models/N11OzellikItem";
import _ from "lodash";
import NButton from "@/components/Button/NButton.vue";


export default defineComponent({
  components: {NButton, NLoadingPanel, NDropDownListBox, NFormField, NFormGrid },
  props: {
    ozellikler:{
      type:Array
    },
    kategoriId:{
      type:Number
    },
    cols:{
      type:String,
      default:'1fr 1fr 1fr'
    }
  },
  setup(props,{emit}) {

    const localState = reactive({
      loadingPanel:ref(),
      n11Ozellikler:ref({} as N11Attribute[]),
      nesilceOzellikler:computed({
        get:()=> props.ozellikler as N11OzellikItem[],
        set:(val)=> emit('update:ozellikler',val)
      }),
      text:ref('REF')
    });

    watch(()=> props.kategoriId,(kategoriId)=>{
      localState.loadingPanel.load(369);
      //load()
    })


    //load(367)

    async function load(cacheDay:number){
      const kategoriId = props.kategoriId as number;
      if (kategoriId && kategoriId > 0){
        // Load Özellikler
        localState.n11Ozellikler = await useN11CategoryService().getN11KategoriGetirOzellikler(kategoriId,cacheDay);
        localState.n11Ozellikler = _.orderBy(localState.n11Ozellikler,['mondatory','name'],['desc','asc'])
        //console.log("localState.nesilceOzellikler",localState.nesilceOzellikler);
        // Nesilce to Trendyol
        nesilceToN11();

      }
    }

    function nesilceToN11(){
      // Fill localState.nesilceOzellikler to localState.trendyolOzellikler
      // Filter     : trendyolOzellikler.attribute.id = nesilceOzellikler.nameId
      // Value Map  : trendyolOzellikler.value        = nesilceOzellikler.value
      //              trendyolOzellikler.valueId      = nesilceOzellikler.valueId

      if (localState.nesilceOzellikler)
      {
        localState.nesilceOzellikler.forEach(ozellik=>{
            const n11Ozellik = localState.n11Ozellikler.find(a => a.name == ozellik.name) as any;
            if (n11Ozellik){
              n11Ozellik.value = ozellik.value;
            }
        })
      }
    }


    function n11ToNesilce(){
      const nesilceOzellikler = [] as TrendyolOzellikItem[];

      if (localState.n11Ozellikler){
        localState.n11Ozellikler.forEach((attribute:any)=>{
          if (attribute.value)
          nesilceOzellikler.push({
            name:attribute.name,
            value:attribute.value,
          })
        })
      }
      localState.nesilceOzellikler = nesilceOzellikler;
    }

    return {
      ...toRefs(localState),
      load,
      n11ToNesilce
    };
  }
});

