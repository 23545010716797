import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "n-notification-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_NAlert = _resolveComponent("NAlert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        (_ctx.isLoaded)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 0 }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.notifications.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_TransitionGroup, { name: "notif-list" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notif, i) => {
                return (_openBlock(), _createBlock(_component_NAlert, _mergeProps(notif, { key: i }), null, 16))
              }), 128))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}