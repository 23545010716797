export const NumberUtils={
    castZero(obj:any,numberFields:string[], castValue:number){
        numberFields.forEach(numberField=>{
            const value = obj[numberField];
            if (!value){
                obj[numberField] = castValue;
            }else if (value == ""){
                obj[numberField] = castValue;
            }
        })
    },
    toRound(sayi:number){
        return  Math.round((sayi + Number.EPSILON) * 100) / 100;
    }
}