
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import { GiderYerleriView } from "@/api/models/GiderYerleriView";
import { useListelerService } from "@/api/services/ListelerService";
import { useCachedHizmetMasrafGiderService } from "@/modules/HizmetMasraf/services/useCachedHizmetMasrafGiderService";
import NGrid from "@/components/Grid/NGrid.vue";

export default defineComponent({
  components: { NGrid },
  props: {
    modelValue: {
      type: String,
      default: ""
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      giderYeriList: ref([] as GiderYerleriView[]),
      select: computed({
        get: () => props.modelValue,
        set: val => emit("update:modelValue", val)
      })
    });
    load();

    async function load() {
      localState.giderYeriList = await useCachedHizmetMasrafGiderService().getGiderYerleriListe();
    }

    async function refresh() {
      localState.giderYeriList = await useCachedHizmetMasrafGiderService().refreshGiderYerleri();
    }

    return {
      ...toRefs(localState),
      refresh
    };
  }
});
