
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import {StokDevirItem} from "@/api/models/StokDevirItem";
import NGrid from "@/components/Grid/NGrid.vue";
import {useStokStore} from "@/modules/Stok/store/StokStore";
import {useStokService} from "@/api/services/StokService";
import {StokView} from "@/api/models/StokView";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import {usePopupService} from "@/services/popupService";

export default defineComponent({
    components: {NLoadingPanel, NModalDialog},
    props: {
        show: {
            type: Boolean,
            default: false
        },
        stokId: {
            type: String
        }
    },
    setup(props, { emit })  {
        
        const localState = reactive({
            showRef: computed({
                get: () => props.show,
                set: val => emit("update:show", val)
            }),
            fiyat:ref(0),
            stok:ref({} as StokView)
        });

        async function load() {
            localState.stok = await useStokService().getGetirView(props.stokId as string) as StokView;
            if (localState.stok){
                localState.fiyat = localState.stok.satisFiyat as number;
            }
        }

        async function save() {
            await useStokService().putStokSatisFiyatGuncelle(localState.stok.id as string, localState.fiyat);
            await usePopupService().pushSuccessNotification({
                body:'Satış fiyatı değiştirildi.',
                title:"İşlem Başarılı"
            })
            localState.showRef = false;
        }

        function initialized(e:any) {
            setTimeout(()=>{
                    const element = e.element.getElementsByClassName("dx-texteditor-input")[0] as HTMLInputElement;
                    element.focus()
                element.select()
            },500)


        }
        
        return {
            ...toRefs(localState),
            save,
            load,initialized
        };
    }
});

