import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("span", {
    title: _ctx.title,
    style: {"cursor":"pointer"}
  }, [
    _createVNode(_component_ion_icon, {
      style: _normalizeStyle(
    {
       'pointer-events': 'none',
      'font-size' : _ctx.boyutRef,
      'color' : _ctx.renkRef,
      'filter': _ctx.addShadow ? 'box-shadow: rgba(33, 35, 38, 0.4) 0px 10px 10px -10px;' : ''

    }),
      name: _ctx.tipRef
    }, null, 8, ["style", "name"])
  ], 8, _hoisted_1))
}