import useApi from '@/api/useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';
import {useN11CategoryService} from "@/api/services/N11CategoryService";


export const useN11CategoryServiceCache = defineStore({
id: 'N11CategoryServiceCache',
	state:()=>({
		cacheObject:{} as any

	}),
	actions: {


	async getN11KategoriGetir(id:number)
	{
		const key = "getN11KategoriGetir" + id;
		const data = this.cacheObject[key];
		if (data)
			return data;
		return this.cacheObject[key] = useN11CategoryService().getN11KategoriGetir(id);
	},

	async getN11KategoriGetirTumu()
	{
		const key = "getN11KategoriGetirTumu";
		const data = this.cacheObject[key];
		if (data)
			return data;
		return this.cacheObject[key] = useN11CategoryService().getN11KategoriGetirTumu();
	},


	async getN11KategoriGetirOzellikler(id:number,cacheDay:number)
	{
		const key = "getN11KategoriGetirOzellikler";
		const data = this.cacheObject[key] + id;
		if (data)
			return data;
		return this.cacheObject[key] = useN11CategoryService().getN11KategoriGetirOzellikler(id,cacheDay);
	}
}
});
