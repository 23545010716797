import { NModuleRoute } from "@/models/route.model";

export const AyarModuleRoutes: NModuleRoute[] = [
  {
    id: "AyarModule",
    order: 13,
    displayName: "Ayarlar",
    icon: {
      type: "settings"
    },
    children: [

      {
        id: "Users",
        order: 0,
        displayName: "Kullanıcılar",
        requiredRoles:{
          readRoles:["Kullanicilar.Liste"],
          insertRoles:["Kullanicilar.Ekle"],
          updateRoles:["Kullanicilar.Guncelle"],
          deleteRoles:["Kullanicilar.Sil"]
        },
        icon: {
          type: "users"
        },
        component: () =>
          import(
            /* webpackChunkName: "Users"*/ "../Kullanicilar/pages/KullanicilarPage.vue"
          )
      },
      {
        id: "RoleGroups",
        order: 0,
        displayName: "Role Grupları",
        requiredRoles:{
          readRoles:["RoleGruplari.Liste"],
          insertRoles:["RoleGruplari.Ekle"],
          updateRoles:["RoleGruplari.Guncelle"],
          deleteRoles:["RoleGruplari.Sil"]
        },
        icon: {
          type: "users"
        },
        component: () =>
          import(
            /* webpackChunkName: "Users"*/ "../RoleGroups/pages/RoleGroupsPage.vue"
          )
      },
      {
        id: "Tanimlar",
        order: 0,
        displayName: "Tanımlar",
        requiredRoles:{
          readRoles:["Tanimlar.Liste"],
          insertRoles:["Tanimlar.Ekle"],
          updateRoles:["Tanimlar.Guncelle"],
          deleteRoles:["Tanimlar.Sil"]
        },
        icon: {
          type: "box"
        },
        component: () =>
            import(
                /* webpackChunkName: "Users"*/ "../Tanımlar/pages/TanimlarPages.vue"
                )
      },

      {
        id: "Firmalar",
        order: 0,
        displayName: "Firma",
        requiredRoles:{
          readRoles:["Firmalar.Liste"],
          insertRoles:["Firmalar.Ekle"],
          updateRoles:["Firmalar.Guncelle"],
          deleteRoles:["Firmalar.Sil"]
        },
        icon: {
          type: "users"
        },
        component: () =>
          import(
            /* webpackChunkName: "Firmalar"*/ "../Firmalar/pages/FirmalarPage.vue"
            )
      },
      {
        id: "ServisGunlukleri",
        order: 0,
        displayName: "Servisler",
        requiredRoles:{
          readRoles:["BackgroundServices.Liste"],
          insertRoles:["BackgroundServices.Ekle"],
          updateRoles:["BackgroundServices.Guncelle"],
          deleteRoles:["BackgroundServices.Sil"]
        },
        icon: {
          type: "users"
        },
        component: () =>
            import(
                /* webpackChunkName: "ServisGunlukleri"*/ "../ServisGunlukleri/pages/ServislerPage.vue"
                )
      },
      {
        id: "Gunlukler",
        order: 0,
        displayName: "Günlükler",
        requiredRoles:{
          readRoles:["Gunlukler.Liste"],
          insertRoles:[],
          updateRoles:[],
          deleteRoles:[]
        },
        icon: {
          type: "users"
        },
        component: () =>
            import(
                /* webpackChunkName: "Gunlukler"*/ "../Gunlukler/pages/GunluklerPage.vue"
                )
      },
      {
        id: "ServisSablonlari",
        order: 0,
        displayName: "Servis Şablonları",
        requiredRoles:{
          readRoles:["ServisSablonlari.Liste"],
          insertRoles:["ServisSablonlari.Ekle"],
          updateRoles:["ServisSablonlari.Guncelle"],
          deleteRoles:["ServisSablonlari.Sil"]
        },
        icon: {
          type: "users"
        },
        component: () =>
            import(
                /* webpackChunkName: "Users"*/ "../../Sablonlar/pages/SablonlarPage.vue"
                )
      },
      {
        id: "Yedekleme",
        order: 0,
        displayName: "Yedekleme",
        requiredRoles:{
          readRoles:["Yedekleme.Liste"],
          insertRoles:[],
          updateRoles:[],
          deleteRoles:[]
        },
        icon: {
          type: "database"
        },
        component: () =>
            import(
                /* webpackChunkName: "Users"*/ "../Yedekleme/pages/YedeklemePage.vue"
                )
      },
      {
        id: "Bildirimler",
        order: 0,
        displayName: "Bildirimler",
        requiredRoles:{
          readRoles:["Bildirimler.Liste"],
          insertRoles:[],
          updateRoles:[],
          deleteRoles:[]
        },
        icon: {
          type: "bell"
        },
        component: () =>
            import(
                /* webpackChunkName: "Users"*/ "../Bildirimler/pages/BildirimPages.vue"
                )
      },
    ]
  }
];
