import {defineStore} from "pinia";
import {NModuleRoute} from "@/models";
import {useUserSettingsService} from "@/api/services/UserSettingsService";
import {UserSettingsView} from "@/api/models/UserSettingsView";


export const useFavoriteStore = defineStore({
    id: "useFavoriteStore",
    state: () => ({
        userRoutes: [] as NModuleRoute[],
        settingsName: 'User.Favorites',
        favorite: {
            order: 0,
            children: [],
            id: 'Favoriler',
            icon: {type: 'star'},
            displayName: 'Favoriler'
        } as NModuleRoute
    }),
    actions: {
        async load(userRoutes: NModuleRoute[]) {
            this.userRoutes = userRoutes;
            const list = await useUserSettingsService().getGetValue(this.settingsName);
            if (list)
            this.favorite.children = this.generateModuleRoutesToIds(list);
        },
        async toggle(module: NModuleRoute) {
            const findIndex = this.findIndexFavoriteList(module.id)
            if (findIndex >= 0) {
                await this.remove(module.id)
            } else {
                await this.add(module)
            }
        },
        async add(module: NModuleRoute) {
            this.favorite.children?.push(module);
            await this.save();

        },
        async remove(id: string) {
            // Remove child

            if (this.favorite.children){
                const childIndex = this.favorite.children?.findIndex(a => a.id == id);
                if (childIndex >= 0)
                    this.favorite.children?.splice(childIndex, 1);
            }




            // Save (Remove db)
            await this.save();

        },
        async save() {
            await useUserSettingsService().postSave({
                name: this.settingsName,
                value: JSON.stringify(this.favorite.children?.map(a => a.id))
            } as UserSettingsView);
        },
        findIndexFavoriteList(id: string):number {
            if (this.favorite.children)
            return this.favorite.children.findIndex(a => a.id == id);
            return  -1;
        },
        generateModuleRoutesToIds(ids: string[]) {
            const modules = [];
            for (let i = 0; i < ids.length; i++) {
                const findModule = this.findInChild(ids[i]);
                if (findModule)
                    modules.push(findModule)
            }
            return modules;
        },
        findInChild(id: string) {
            for (let p = 0; p < this.userRoutes.length; p++) {
                if (this.userRoutes[p].children) {
                    const childRoutes = this.userRoutes[p].children as NModuleRoute[];
                    for (let c = 0; c < childRoutes.length; c++) {
                        if (childRoutes[c].id == id) {
                            return childRoutes[c];
                        }
                    }
                }
            }
            return undefined;
        }
    }
});