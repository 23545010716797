import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NSelectBox = _resolveComponent("NSelectBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.ureticiler, (uretici, i) => {
      return (_openBlock(), _createBlock(_component_NFormGrid, {
        title: 'Üretici ' + (i +1),
        key: i,
        cols: "1.6fr 1fr 1fr",
        rows: "auto auto"
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_NButton, {
            onClick: ($event: any) => (_ctx.ureticiSil(i)),
            size: "small",
            text: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode("Sil")
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_NFormField, {
            label: "Cari",
            "grid-cols": "60px 1fr",
            span: "3"
          }, {
            default: _withCtx(() => [
              (uretici.cari)
                ? (_openBlock(), _createBlock(_component_NSelectBox, {
                    key: 0,
                    "label-field": "name",
                    "value-field": "id",
                    items: _ctx.cariler,
                    modelValue: uretici.cari.id,
                    "onUpdate:modelValue": ($event: any) => ((uretici.cari.id) = $event),
                    onSelectedChanged: (index,idName)=>_ctx.cariSelectedChanged(index,idName,i)
                  }, null, 8, ["items", "modelValue", "onUpdate:modelValue", "onSelectedChanged"]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_NFormField, {
            label: "Stok Adı",
            "grid-cols": "60px 1fr"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NInput, {
                modelValue: uretici.stokAdi,
                "onUpdate:modelValue": ($event: any) => ((uretici.stokAdi) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_NFormField, {
            label: "Kod",
            "grid-cols": "60px 1fr"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NInput, {
                modelValue: uretici.kod,
                "onUpdate:modelValue": ($event: any) => ((uretici.kod) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_NFormField, {
            label: "Barkod",
            "grid-cols": "60px 1fr"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NInput, {
                modelValue: uretici.barkod,
                "onUpdate:modelValue": ($event: any) => ((uretici.barkod) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 2
          }, 1024),
          _createVNode(_component_NFormField, {
            label: "Marka",
            "grid-cols": "60px 1fr"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NInput, {
                modelValue: uretici.marka,
                "onUpdate:modelValue": ($event: any) => ((uretici.marka) = $event)
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1032, ["title"]))
    }), 128)),
    _createVNode(_component_NFormGrid, {
      cols: "1fr",
      rows: "auto",
      style: {"margin-top":"10px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NButton, {
          icon: "plus",
          type: "info",
          size: "small",
          onClick: _ctx.ureticiEkle
        }, {
          default: _withCtx(() => [
            _createTextVNode("Yeni Üretici Ekle")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    })
  ]))
}