<template>
  <div
    class="wrapper"
    ref="scrollWrapper"
    :style="{ width: elWidth, height: elHeight }"
  >
    <div
      class="scroll-container"
      ref="scrollContainer"
      :style="{ width: elWidth, height: elHeight }"
      @scroll="toggleShadow()"
    >
      <slot></slot>
      <div :class="['shadow-top', shadow.top && 'is-active']"></div>
      <div :class="['shadow-right', shadow.right && 'is-active']"></div>
      <div :class="['shadow-bottom', shadow.bottom && 'is-active']"></div>
      <div :class="['shadow-left', shadow.left && 'is-active']"></div>
    </div>
  </div>
</template>

<script>
import { useResizeObserver } from "@vueuse/core";
import { defineComponent, onMounted, reactive, ref, toRefs } from "vue";

export default defineComponent({
  name: "ScrollContainer",
  props: ["width", "height"],
  setup(props) {
    const { width, height } = toRefs(props);
    const scrollContainer = ref(null);
    const scrollWrapper = ref(null);
    const elWidth = ref(width.value ?? "inherit");
    const elHeight = ref(height.value ?? "inherit");
    const shadow = reactive({
      top: false,
      right: false,
      bottom: false,
      left: false
    });

    function toggleShadow() {
      const hasHorizontalScrollbar =
        scrollContainer.value.clientWidth < scrollContainer.value.scrollWidth;
      const hasVerticalScrollbar =
        scrollContainer.value.clientHeight < scrollContainer.value.scrollHeight;

      const scrolledFromLeft =
        scrollContainer.value.offsetWidth + scrollContainer.value.scrollLeft;
      const scrolledFromTop =
        scrollContainer.value.offsetHeight + scrollContainer.value.scrollTop;

      const scrolledToTop = scrollContainer.value.scrollTop === 0;
      const scrolledToRight =
        scrolledFromLeft >= scrollContainer.value.scrollWidth;
      const scrolledToBottom =
        scrolledFromTop >= scrollContainer.value.scrollHeight;
      const scrolledToLeft = scrollContainer.value.scrollLeft === 0;

      shadow.top = hasVerticalScrollbar && !scrolledToTop;
      shadow.right = hasHorizontalScrollbar && !scrolledToRight;
      shadow.bottom = hasVerticalScrollbar && !scrolledToBottom;
      shadow.left = hasHorizontalScrollbar && !scrolledToLeft;
    }

    onMounted(() => {
      useResizeObserver(scrollWrapper, entries => {
        const entry = entries[0];
        const { obWidth, obHeight } = entry.contentRect;
        if (obWidth) elWidth.value = `${obWidth}px`;
        if (obHeight) elHeight.value = `${obHeight}px`;
      });

      useResizeObserver(scrollContainer, () => {
        toggleShadow();
      });
    });

    return {
      scrollWrapper,
      scrollContainer,
      elWidth,
      elHeight,
      shadow,
      toggleShadow
    };
  }
});
</script>

<style lang="scss" scoped>
@use "@/styles" as vars;

.wrapper {
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.scroll-container {
  box-sizing: border-box;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: darken(vars.$color-secondary, 10);
    border-radius: 20px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background-color: lighten(vars.$color-primary, 10);
  }
}

.shadow-top,
.shadow-right,
.shadow-bottom,
.shadow-left {
  position: absolute;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
}

.shadow-top,
.shadow-bottom {
  width: 100%;
  height: 20px;
  background-image: linear-gradient(rgba(#555, 0.1) 0%, rgba(#fff, 0) 100%);
}

.shadow-right,
.shadow-left {
  width: 20px;
  height: 100%;
  background-image: linear-gradient(
    90deg,
    rgba(#555, 0.1) 0%,
    rgba(#fff, 0) 100%
  );
}

.shadow-top,
.shadow-left {
  top: 0;
  left: 0;
}

.shadow-right {
  top: 0;
  right: 0;
}

.shadow-bottom {
  bottom: 0;
  left: 0;
  transform: rotate(180deg);
}

.is-active {
  opacity: 1;
}
</style>
