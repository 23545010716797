
import {computed, defineComponent, onBeforeUnmount, onMounted, onUnmounted, reactive, ref, toRefs, watch} from "vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import {useFileSettingsService} from "@/api/services/FileSettingsService";
import {StokFlatView} from "@/api/models/StokFlatView";
import ArrayStore from "devextreme/data/array_store";
import NViewGrid from "@/components/ViewGrid/NViewGrid.vue";
import {DxDataGrid, DxExport} from "devextreme-vue/data-grid";

import NTag from "@/components/Tag/NTag.vue";
import {DovizList} from "@/api/models/Doviz";
import {StokMenu} from "@/modules/Stok/models/StokMenu";
import NButton from "@/components/Button/NButton.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import {useFileSettingsServiceCache} from "@/api/servicesCache/FileSettingsServiceCache";
import {useStokFlatStore} from "@/modules/Stok/store/StokFlatStore";
import {usePopupService} from "@/services/popupService";
import {functionsIn} from "lodash";
import {HizmetMasrafEntity} from "@/api/models/HizmetMasrafEntity";
import {useHizmetMasrafService} from "@/api/services/HizmetMasrafService";
import StokFlatListSagMenu from "@/modules/Stok/components/StokFlatListSagMenu.vue";
import {StokGrupEntity} from "@/api/models/StokGrupEntity";
import {useStokGrupService} from "@/api/services/StokGrupService";
import NImages from "@/components/Images/NImages.vue";
import {alert} from "devextreme/ui/dialog";
import DataSource from "devextreme/data/data_source";
import {useHizmetMasrafStore} from "@/modules/HizmetMasraf/store/HizmetMasrafStore";
import {HizmetMasrafSearchUtils} from "@/modules/HizmetMasraf/utils/HizmetMasrafSearchUtils";
import {StokFlatSearchUtils} from "@/modules/Stok/utils/StokFlatSearchUtils";
import {DevExtremeUtils} from "@/devextreme/DevExtremeUtils";
import NDxStateStoring from "@/devextreme/NDxStateStoring.vue";
import {useStokKategoriService} from "@/api/services/StokKategoriService";
import dxDataGrid, {dxDataGridColumn} from "devextreme/ui/data_grid";
import XmlImporterStokListesi from "@/modules/Servisler/XmlImporter/components/XmlImporterStokListesi.vue";
import HizliStokDialog from "@/modules/Stok/dialogs/HizliStokDialog.vue";

export default defineComponent({
    components: {
        HizliStokDialog,
        XmlImporterStokListesi,
        NDxStateStoring,
        StokFlatListSagMenu,
        NButton,
        NTag,
        DxDataGrid,
        DxExport,
        NViewGrid,
        NGrid,
        NModalDialog,
    },
    props: {
        modelValue: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        hizmetler: {
            type: Array
        },
        selectedTab: {
            type: Number,
            default: 0
        }
    },
    setup(props, {emit}) {
        const localState = reactive({
            showRef: computed({
                get: () => props.show,
                set: val => emit("update:show", val)
            }),
            serverUrlRef: ref(""),
            dovizList: DovizList,
            resimler: ref([] as string[]),
            searchStokInput: computed({
                get: () => props.modelValue,
                set: val => emit("update:modelValue", val)
            }),
            searchHizmetInput: ref(''),

            selectedTabRef: computed({
                get: () => props.selectedTab,
                set: (val) => emit("update:selectedTab", val)
            }),

            dovizTipList: DovizList,

            stokFlatListSagMenu: ref(),


            stoklarDataSource: ref(new DataSource({})),
            stokAramaInputDataGrid: ref(),
            stoklarSelecteds: ref([] as StokFlatView[]),
            stoklarSelected: ref({} as StokFlatView),
            stoklarHover: ref({} as StokFlatView),

            hizmetlerDataSource: ref(new DataSource({})),
            hizmetlerDataGrid: ref(),
            hizmetlerSelecteds: ref([] as HizmetMasrafEntity[]),
            hizmetlerSelected: ref({} as HizmetMasrafEntity),


            grupluStokDataSource: ref([] as StokGrupEntity[]),
            grupluStokListDataGrid: ref(),
            grupluStokSelecteds: ref([] as StokGrupEntity[]),
            grupluStokSelected: ref({} as StokGrupEntity),
            ozelliklerColums: [] as any[],
            showImage: ref(false),
            selectedImage: ref(""),
            hizliStokDialogShow: ref(false),
        });
        const stokMenu = StokMenu;
        load();

        async function load() {
            await loadOzelliklerColumns();
            localState.hizmetlerDataSource = useHizmetMasrafStore().generateDataSource();

            const stokDataSource = useStokFlatStore().generateDataSource() as DataSource
            if (localState.searchStokInput && localState.searchStokInput != "" && localState.searchStokInput?.length > 0) {
                if (localState.selectedTabRef == 0)
                    stokDataSource?.filter(StokFlatSearchUtils().stokAllFilter(localState.searchStokInput as string));
                else if (localState.selectedTabRef == 1) {
                    localState.searchHizmetInput = localState.searchStokInput
                    hizmetArama();
                }

                // else if (localState.selectedTabRef == 2) // XML Stok Arama
                //     hizmetArama();
            }
            localState.stoklarDataSource = stokDataSource;
            //console.log('stokDataSource', stokDataSource)

            await loadGrupluStokListe();
            localState.serverUrlRef = await useFileSettingsServiceCache().getServerUrl();

            //stokArama();
        }

        async function loadOzelliklerColumns() {

            const names = await useStokKategoriService().getOzellikNames() as string[]

            for (let i = 0; i < names.length; i++) {
                const name = names[i];
                const column = {
                    caption: name,
                    dataField: `ozellik[${name}]`,
                    width: '100px'
                } as dxDataGridColumn
                localState.ozelliklerColums.push(column)
            }
        }

        function stokAramaValueChanged(e: any) {
            if (localState.selectedTabRef == 0) {
                if (e && e.value && e.value.length > 0) {
                    stokArama();
                } else {
                    clearDataGridsFilter();
                }
            }
        }

        function hizmetAramaValueChanged(e: any) {
            if (localState.selectedTabRef == 1) {
                if (e && e.value && e.value.length > 0) {
                    hizmetArama();
                } else {
                    clearDataGridsFilter();
                }
            }
        }

        async function loadGrupluStokListe() {
            localState.grupluStokDataSource = await useStokGrupService().getGrupluStokListe();
        }

        function getStokResim(data: StokFlatView) {
            if (data.resimler && data.resimler[0] && data.resimler[0].length > 0)
                return localState.serverUrlRef + "thumbs/" + data.resimler[0] + "/80";

            else
                return "@/assets/images/gorselYok.png";
        }

        function getImage() {
            if (localState.stoklarHover && localState.stoklarHover.resimler && localState.stoklarHover.resimler.length > 0)
                return localState.serverUrlRef + localState.stoklarHover.resimler[0];
            else
                return "@/assets/images/gorselYok.png";
        }

        function itemClick(e: any) {
            localState.stoklarSelecteds = e?.selectedRowsData;
            localState.stoklarSelected = e?.selectedRowsData[0];

        }

        function hizmetItemClick(e: any) {
            localState.hizmetlerSelecteds = e?.selectedRowsData;
            localState.hizmetlerSelected = e?.selectedRowsData[0];
        }

        function grupluStokItemClick(e: any) {
            localState.grupluStokSelecteds = e?.selectedRowsData;
            localState.grupluStokSelected = e?.selectedRowsData[0];
        }


        // const timeout = setTimeout(() => {
        //
        //   clearTimeout(timeout)
        // }, 500);


        function clearDataGridsFilter() {
            localState.hizmetlerDataGrid?.instance?.clearFilter();
            localState.stokAramaInputDataGrid?.instance?.clearFilter();
        }

        function hizmetArama() {
            localState.hizmetlerDataSource.filter(HizmetMasrafSearchUtils().filter(localState.searchHizmetInput as string));
            localState.hizmetlerDataSource.load();
        }

        function stokArama() {

            const stokAraText = localState.searchStokInput;

            if (stokAraText && stokAraText != "" && stokAraText.length > 0) {
                localState.stokAramaInputDataGrid?.instance?.filter(StokFlatSearchUtils().stokAllFilter(stokAraText));
            } else
                localState.stokAramaInputDataGrid?.instance?.filter();
        }


        function hizmetDblClick(hizmet: HizmetMasrafEntity) {
            if (hizmet) {
                emit("hizmetDblClick", hizmet);
                localState.showRef = false;
            }
        }

        function rowDblClick(data: StokFlatView) {
            if (data) {
                emit("stokDblClick", data);
                localState.showRef = false;
            }
        }

        function grupluStokDblClick(grupStok: StokGrupEntity) {
            if (grupStok && grupStok.stoklar && grupStok.stoklar.length > 0) {
                const stoklistesi: StokFlatView[] = [];
                //const stoklar = (useStokFlatStore().dataSource.store() as ArrayStore).createQuery().toArray() as StokFlatView[];

                grupStok.stoklar.forEach((a) => {
                    const findStok = useStokFlatStore().getStok(a.stokId as string) as any;
                    if (findStok) {
                        const flatView: any = Object.assign({}, findStok);
                        flatView.gelenMiktar = a.miktar;
                        flatView.fiyat = a.fiyat;
                        flatView.satisFiyat = a.fiyat;
                        flatView.alisFiyat = a.fiyat;
                        flatView.satisKdv = grupStok.satisKdv;
                        flatView.alisKdv = grupStok.satisKdv;
                        flatView.satisKdvDahil = true;
                        flatView.alisKdvDahil = true;
                        stoklistesi.push(flatView);
                    }
                });


                if (stoklistesi.length > 0)
                    emit("coklu", stoklistesi) , localState.showRef = false;
                else
                    alert("Gruplu Stoğun , Alt Stokları Bulunamadı !", "Gruplu Stok Hata");
            }
        }


        function coklu() {
            if (localState.selectedTabRef == 0 || localState.selectedTabRef == 2)
                emit("coklu", localState.stoklarSelecteds);
            else
                emit("cokluHizmet", localState.hizmetlerSelecteds);

            localState.showRef = false;
        }


        function cellHover(e: any) {
            //localState.stoklarHover = e.data;
        }

        function clickImage(url: string) {
            localState.selectedImage = url;
            localState.showImage = true;
        }

        async function loadState() {
            return await DevExtremeUtils.loadState(localState.stokAramaInputDataGrid?.instance);
        }

        let timerId: any = undefined;

        async function aramaDelay(e: any, callback: any, delay: number) {
            if (timerId)
                clearTimeout(timerId);
            timerId = setTimeout(async () => {
                await callback(e)
            }, delay)
        }

        onBeforeUnmount(() => {
            (localState.stokAramaInputDataGrid?.instance as dxDataGrid).dispose();
        })

        function stokAddedFromXml(data:StokFlatView) {
            if (data) {
                emit("stokDblClick", data);
                localState.showRef = false;
            }
        }

        function stokEntityAdded(stok: StokFlatView[]) {
            if (stok && stok?.length > 0) {
                emit("stokDblClick", stok[0])
                localState.showRef = false;
            }
        }

        return {
            ...toRefs(localState),
            getImage,
            itemClick,
            load,
            rowDblClick,
            stokAddedFromXml,
            coklu,
            cellHover,
            hizmetDblClick,
            grupluStokDblClick,
            hizmetItemClick,
            grupluStokItemClick,
            clickImage,
            loadState,
            getStokResim,
            stokAramaValueChanged,
            hizmetAramaValueChanged,
            aramaDelay,
            stokEntityAdded
        };
    }
});
