
import { computed, defineComponent, PropType } from "vue";
import { useLayoutStore } from "../context";

export default defineComponent({
  name: "SectionSeparator",
  props: {
    showToggle: {
      type: Boolean,
      default: false
    },
    position: {
      type: String as PropType<"top" | "bottom">,
      default: "bottom",
      validator: (prop: string) => ["bottom", "top"].includes(prop)
    }
  },
  setup() {
    const layout = useLayoutStore();
    return {
      toggleSidebar: layout.toggleSidebar,
      isSidebarCollapsed: computed(() => layout.isSidebarCollapsed)
    };
  }
});
