import {NModuleRoute} from "@/models/route.model";
import {defineStore} from "pinia";
import {uuid} from "vue-uuid";
import {GenelUtils} from "@/modules/Ortak/utils/GenelUtils";

export type LayoutTab = {
    key: string;
};

export const useLayoutStore = defineStore({
    id: "AppLayoutStore",
    state: () => {
        return {
            isSidebarCollapsed: false,
            tabs: [] as (NModuleRoute & LayoutTab)[],
            selectedTabKey: "",
            tabClosed:(tab:NModuleRoute & LayoutTab)=> {},
        }
    },
    getters: {
        selectedTab() {
            const tabs = this.tabs;
            const selectedTabKey = this.selectedTabKey;
            if (tabs) {
                const tab = tabs.find((t: any) => t.key === selectedTabKey as string) as any;
                //console.log(tab);
                return tab as any;
            }
            return undefined;
        },
        selectedTabId() {
            return this.selectedTab ? (this.selectedTab as any).id as string : "";
        }
    },
    actions: {
        toggleSidebar() {
            this.isSidebarCollapsed = !this.isSidebarCollapsed;
        },
        selectTab(key: string) {
            const tab = this.tabs.find(a => a.key == key);
            if (tab)
                tab.order = Date.now();
            this.selectedTabKey = key;
        },
        closeTab(key: string) {
            const tabIndex = this.tabs.findIndex(a => a.key == key);
            //alert(tabIndex + "--" + key)
            this.tabClosed(this.tabs[tabIndex]);
            this.tabs[tabIndex].key = "";

            this.tabs.splice(tabIndex, 1);
            if (this.tabs.length < 1) {
                this.selectedTabKey = "";
            } else if (key === this.selectedTabKey) {
                this.selectedTabKey = this.getSelectedKeyMaxOrder()
            }


        },
        async closeTabValidate(key: string) {
            const filter = this.tabs.find(a => a.key == key) as any;
            //console.log("closing tab", filter)
            if (
                filter.id == "FaturaForm" ||
                filter.id == "SiparisForm" ||
                filter.id == "FisForm" ||
                filter.id == "PerakendeModule"

            ) {
                if (filter.entityId && filter.entityId.length > 0) {
                    await this.closeTab(key);
                } else if (await GenelUtils.onayDiyalogu("Sekmeyi Kapatmak İstiyor musunuz ?", "Kapatma Onayı")) {
                    await this.closeTab(key);
                }
            } else
                await this.closeTab(key)
        },
        async closeCurrentTabValidate() {
            const filter = this.tabs.find(a => a.key == this.selectedTabKey) as any;
            //console.log("closing tab", filter)
            if (
                filter.id == "FaturaForm" ||
                filter.id == "SiparisForm" ||
                filter.id == "FisForm" ||
                filter.id == "PerakendeModule"

            ) {
                if (filter.entityId && filter.entityId.length > 0) {
                    await this.closeCurrentTab();
                } else if (await GenelUtils.onayDiyalogu("Sekmeyi Kapatmak İstiyor musunuz ?", "Kapatma Onayı")) {
                    await this.closeCurrentTab();
                }
            } else
                await this.closeCurrentTab();
        },
        async closeCurrentTab() {
            this.closeTab(this.selectedTabKey);
        },
        openRouteAsTab(route: NModuleRoute) {
            const key = uuid.v4();
            const newTab = {
                ...route,
                key
            };
            if (this.tabs.length > 0){
                newTab.order = Date.now();
            }
            this.tabs.push(newTab);

            this.selectTab(key);
            return key;
        },
        openRouteAsTabFromKey(route: NModuleRoute, key: string) {
            //const key = uuid.v4();
            this.tabs.push({
                ...route,
                key
            });
            this.selectTab(key);
            return key;
        },
        changeOrder(sourceKey:string, targetKey:string){
            const source = this.tabs.findIndex(a => a.key == sourceKey);
            const target = this.tabs.findIndex(a => a.key == targetKey);
            this.move(source,target)

        },
        move  (from:number, to:number) {
            this.tabs.splice(to, 0, this.tabs.splice(from, 1)[0]);
        },
        getSelectedKeyMaxOrder(){
            let tabKey = "";
            let currentOrder = 0;
            for (let i = 0; i < this.tabs.length; i++) {
                const tab = this.tabs[i];
                if (tab.order > currentOrder){
                    currentOrder = tab.order;
                    tabKey = tab.key;
                }
            }
            return tabKey;
        }
    }
});
