
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import ArrayStore from "devextreme/data/array_store";
import {StokSimpleView} from "@/api/models/StokSimpleView";
import {SacUretimFisView} from "@/api/models/SacUretimFisView";
import {SacUretimSatirItem} from "@/api/models/SacUretimSatirItem";
import {UretimSacFisApi} from "@/modules/SacUretim/api/UretimSacFisApi";
import {usePopupService} from "@/services/popupService";
import {SacUretimDilmeModel} from "@/modules/SacUretim/api/SacUretimDilmeModel";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import {DilmeApi} from "@/modules/SacUretim/api/DilmeApi";
import NTag from "@/components/Tag/NTag.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NIcon from "@/components/Icon/NIcon.vue";
import NSwitch from "@/components/Switch/NSwitch.vue";
import NButton from "@/components/Button/NButton.vue";
import DepoSelectBox from "@/modules/Ortak/components/DepoSelectBox.vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import {SacUretimSatirKaynakTip} from "@/api/models/SacUretimSatirKaynakTip";
import SacUretimSiparisSelectButton from "@/modules/SacUretim/dialogs/SacUretimSiparisSelectButton.vue";
import {SiparisSatirFlatView} from "@/api/models/SiparisSatirFlatView";
import {useStokService} from "@/api/services/StokService";
import {StokEntity} from "@/api/models/StokEntity";
import {uuid} from "vue-uuid";
import {SiparisSatirItem} from "@/api/models/SiparisSatirItem";
import {useSacUretimFisService} from "@/api/services/SacUretimFisService";
import {alert} from "devextreme/ui/dialog";
import {useStokSettingsStore} from "@/modules/Stok/store/StokSettingsStore";

export default defineComponent({
    components: {
        SacUretimSiparisSelectButton,
        NModalDialog, DepoSelectBox, NButton, NSwitch, NIcon, NFormField, NGrid, NTag, NFormGrid},
    props: {
        stok: {
            type: Object,
            default: () => ({} as StokSimpleView)
        },
        fis: {
            type: Object
        },
        store: {
            type: Object
        },
        tip: {
            type: Number,
            default: -1
        },
        aciklamaList: {
            type: Array,
            default: () => [],
        },
        paketlemeList: {
            type: Array,
            default: () => [],
        },
        isUpdate: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {

        const localState = reactive({
            model: ref({
                kaynakTip: SacUretimSatirKaynakTip.Talimat_Fisi,
                tonaj: 0,
                genislik: 0,
                paketeBol: 1,
                tonajaBol: 1,
                depoId: '',
                adet: 1,
                isFire: false,
                tabanId: 0,
                aciklama: '',
                stok: {}
            } as SacUretimDilmeModel),
            stokRef: computed(() => props.stok as StokSimpleView),
            fisRef: computed(() => props.fis as SacUretimFisView),
            storeRef: computed(() => props.store as ArrayStore),
            dilmeApi: DilmeApi,
            uretimSacFisApi: UretimSacFisApi,
            oldTabanId: ref(0),


            tonajNumberBox: ref(),
            tabanTonajEski: ref(0),
            tabanTonajYeni: ref(0),
            tabanTonajId: ref(0),
            showTabanEdit: ref(false),
            newSlot: ref([] as SacUretimDilmeModel[]),
            storeSettings:useStokSettingsStore()
        });

        async function load() {
            ///
            setNewSlot();
            localState.model.tonaj = Math.max(getKullanilabilecekTonaj(), 0)
            localState.model.genislik = getGenislik();

        }

        load();

        watch(() => localState.model.tabanId, (deger) => {

            // Radio değişince, üretilebilecek max tutarı kutaya girer
            if (Number(deger) <= 0 || Number(deger) > 0) {
                localState.model.genislik = getGenislik();
                localState.model.adet = 1;
            }
        })

        watch(() => localState.fisRef?.satirlar, () => {
            setNewSlot();
            localState.model.tonaj = Math.max(getKullanilabilecekTonaj(), 0)
        }, {deep: true})

        function setNewSlot() {
            localState.newSlot = localState.dilmeApi.getNewSlot(localState.fisRef.satirlar as any, localState.stokRef.getKalanMiktar as number, localState.stokRef.tipOzellikler?.genislik as number)
            if (localState.newSlot.length > 0)
                localState.model.tabanId = localState.newSlot[0].tabanId;
        }

        function isNewSlot() {
            if (localState.newSlot.length == 0)
                return false;
            return localState.newSlot[0].tabanId === localState.model.tabanId
        }

        function getHesaplananTonaj() {
            let genislik = Number(localState.stokRef?.tipOzellikler?.genislik);
            let tonaj = localState.model.tonaj;
            if (!isNewSlot()) {
                tonaj = localState.dilmeApi.getKalanAgirlikFromTabanId(localState.fisRef?.satirlar as any, localState.model.tabanId as number)
                genislik = localState.dilmeApi.getKalanGenislikFromTabanId(localState.fisRef?.satirlar as any, Number(localState.stokRef?.tipOzellikler?.genislik), localState.model.tabanId as number)
            }
            if (genislik > 0) {
                const result = ((localState.model.genislik as number) * (localState.model.adet as number) * (tonaj as number)) / genislik as number
                return Math.max(result, 0);
            } else
                return 0
        }

        function getMaxAdet() {
            let genislik = Number(localState.stokRef?.tipOzellikler?.genislik);
            let tonaj = localState.model.tonaj;
            if (!isNewSlot()) {
                tonaj = localState.dilmeApi.getKalanAgirlikFromTabanId(localState.fisRef?.satirlar as any, localState.model.tabanId as number)
                genislik = localState.dilmeApi.getKalanGenislikFromTabanId(localState.fisRef?.satirlar as any, Number(localState.stokRef?.tipOzellikler?.genislik), localState.model.tabanId as number)
            }
            if (genislik > 0 && tonaj) {
                const birAdetTonaj = ((localState.model.genislik as number) * 1 * (tonaj as number)) / genislik as number
                const result = tonaj / birAdetTonaj
                return Math.max(result, 0);
            } else
                return 0
        }

        function getKalacakGenislik() {
            const toplamGenislik = localState.stokRef.tipOzellikler?.genislik as number;
            const formGenislik =( localState.model.genislik as number) * (localState.model.adet as number);
            const satirToplamGenislik = localState.dilmeApi.getToplamGenislikFromTabanId(localState.fisRef?.satirlar as any[], localState.model.tabanId as number) as number
            if (toplamGenislik && formGenislik && satirToplamGenislik >= 0)
                return toplamGenislik - formGenislik - satirToplamGenislik;
            return 0;

        }

        function getKullanilabilecekTonaj() {
            return localState.dilmeApi.getKullanilabilecekTonaj(localState.fisRef.satirlar as any, localState.stokRef.getKalanMiktar as number)

        }

        function getGenislik() {
            // hiç slot seçili değil veya yeni slot yok
            if (localState.model.tabanId === 0)
                return 0
            return localState.model.genislik = localState.dilmeApi.getKalanGenislikFromTabanId(localState.fisRef.satirlar as any, localState.stokRef?.tipOzellikler?.genislik as number, localState.model.tabanId as number)
        }

        function addRow() {
            let kalanTonaj = 0;
            if (isNewSlot())
                kalanTonaj = localState.model.tonaj as number;
            else {
                kalanTonaj = localState.dilmeApi.getKalanAgirlikFromTabanId(localState.fisRef?.satirlar as any, localState.model.tabanId as number)
                localState.model.tonaj = localState.dilmeApi.getTabanTonaj(localState.fisRef?.satirlar as any, localState.model.tabanId as number)
            }

            if (!localState.model.depoId || localState.model.depoId?.length < 4) {
                usePopupService().pushDangerNotification({
                    title: "Uyarı",
                    body: "Depo seçiniz!"
                });
            } else if (kalanTonaj < getHesaplananTonaj()) {
                usePopupService().pushDangerNotification({
                    title: "Uyarı",
                    body: "Girdiğiniz değerler taban tonajını aşıyor!"
                });
            } else if ((localState.model.paketeBol as number) > 1 &&
                ((localState.model.adet as number) % (localState.model.paketeBol as number) != 0)) {
                usePopupService().pushDangerNotification({
                    title: "Hata",
                    body: "Girdiğiniz paket değeri, adet veya bölenleri olmalıdır!"
                });
            } else {

                localState.oldTabanId = localState.model.tabanId as number
                const satirlar = generateSatirlar(
                    localState.model.tabanId as number,
                    localState.model.tonaj as number, // taban tonaj girmeli isnewSlot Değil ise
                    localState.stokRef.tipOzellikler?.genislik as number,
                    localState.model.genislik as number,
                    localState.stokRef.tipOzellikler?.kalinlik as number,
                    localState.model.adet as number,
                    localState.model.tonajaBol as number,
                    localState.model.paketeBol as number
                ) as SacUretimSatirItem[];

                for (const satir of satirlar) {
                    const changes = [{type: 'insert', data: satir}] as any;
                    localState.storeRef.push(changes);
                }

                // refresh
                setTimeout(() => {

                    localState.model = {
                        kaynakTip: SacUretimSatirKaynakTip.Talimat_Fisi,
                        tonaj: Math.max(getKullanilabilecekTonaj(), 0),
                        genislik: 0,
                        paketeBol: 1,
                        tonajaBol: 1,
                        depoId: localState.model.depoId,
                        adet: 1,
                        isFire: false,
                        tabanId: localState.oldTabanId,
                        aciklama: '',
                        stok: {}
                    } as SacUretimDilmeModel
                    const kalanAgirlik = localState.dilmeApi.getKalanAgirlikFromTabanId(localState.fisRef?.satirlar as any[], localState.oldTabanId as number)
                    const kalanGenislik = localState.dilmeApi.getKalanGenislikFromTabanId(localState.fisRef?.satirlar as any[], localState.stokRef?.tipOzellikler?.genislik as number, localState.oldTabanId as number)

                    if (kalanAgirlik <= 0 || kalanGenislik <= 0) {
                        if (localState.newSlot.length > 0) {
                            localState.model.tabanId = localState.newSlot[0].tabanId
                            localState.model.genislik = localState.stokRef?.tipOzellikler?.genislik
                        } else {
                            localState.model.tabanId = 0
                            localState.model.genislik = 0
                        }
                    }
                    else {
                        getGenislik()
                    }

                }, 250)

            }
        }

        function focusTonajBox(e: any) {
            //alert('focus')
            setTimeout(() => {
                localState.tonajNumberBox?.instance?.focus();

            }, 250)
        }

        function generateSatirlar(tabanId: number, tabanTonaj: number, tabanGenislik: number, genislik: number, kalinlik: number, adet: number, tonajBol: number, paketBol: number) {
            let i = 0;
            const satirlar = [] as SacUretimSatirItem[];
            tabanTonaj = tabanTonaj / tonajBol;
            adet = adet / paketBol;

            for (let t = 0; t < tonajBol; t++) {
                for (let p = 0; p < paketBol; p++) {

                    const item = {
                        kaynakTip: localState.model.kaynakTip,
                        uzunluk: 0,
                        tip: props.tip,
                        isFire: localState.model.isFire,
                        aciklama: localState.model.aciklama,
                        paketleme: localState.model.paketleme,
                        depoId: localState.model.depoId,
                        kur: 1,
                        stok: localState.model.stok,
                        id: (Date.now() + i++).toString(),
                        genislik: genislik,
                        kalinlik: kalinlik,
                        adet: adet,
                        tonaj: generateTonaj(tabanTonaj, tabanGenislik, genislik, adet),
                        tabanTonaj: tabanTonaj,
                        tabanId: tabanId,

                        // Sipariş stokları
                        cari: localState.model.cari,
                        siparisId: localState.model.siparisId,
                        siparisNo: localState.model.siparisNo,
                        doviz: localState.model.doviz,
                        siparisSatirId: localState.model.siparisSatirId,
                        subeId: localState.model.subeId,
                        //


                    } as SacUretimSatirItem;
                    if (tonajBol > 1) {
                        item.tabanId = tabanId + t;
                    }
                    satirlar.push(item)
                }
            }
            return satirlar;
        }

        function generateTonaj(tabanTonaj: number, tabanGenislik: number, uretilenGenislik: number, adet: number) {
            return ((tabanTonaj * uretilenGenislik) / tabanGenislik) * adet;
        }


        function editTabanTonaj(e: MouseEvent, tabanId: number, tabanTonaj: number) {
            localState.tabanTonajEski = tabanTonaj;
            localState.tabanTonajYeni = tabanTonaj;
            localState.tabanTonajId = tabanId;
            localState.showTabanEdit = true;
            e.preventDefault();
            e.stopPropagation()
        }

        function editTabanTonajAppy() {

            const tabanTonajlar = localState.fisRef.satirlar?.filter(a => a.tabanId as number === localState.tabanTonajId);
            tabanTonajlar?.forEach(a => {
                const agirlikYeni = (a.tonaj as number * localState.tabanTonajYeni) / localState.tabanTonajEski;
                a.tonaj = agirlikYeni;
                a.tabanTonaj = localState.tabanTonajYeni;
            })
            localState.showTabanEdit = false;
        }


        async function addRowFromSiparis(row:SiparisSatirFlatView) {

            const ilgiliStok = await useStokService().getGetir(row?.stokId as string) as StokEntity
            const fis = await useSacUretimFisService().getFindFisFromStokId(ilgiliStok.id as string)
            if (fis)
            {
                await alert(`Seçtiğiniz sipariş stoğu daha önce başka talimatta kullanılmış! (${fis.stokParent.name})`, "Hata");
                return;
            }

            const result = localState.fisRef?.satirlar?.find((a:SacUretimSatirItem) => a.stok?.id == ilgiliStok.id)
            if (result)
            {
                await alert(`Seçtiğiniz sipariş stoğu daha önce satırda kullanılmış!`, "Hata");
                return;
            }

            localState.model = {
                id: uuid.v4(),
                uzunluk: 0,
                paketeBol: 1,
                tonajaBol: 1,

                // Sipariş stokları
                cari: row.cari,
                kaynakTip: SacUretimSatirKaynakTip.Siparis,
                siparisId: row.siparisId,
                siparisNo: row.evrakNo,
                doviz: row?.doviz,
                siparisSatirId: row.id,
                subeId: row.subeId,
                //

                isFire: false,
                aciklama: localState.model.aciklama,
                paketleme: localState.model.paketleme,
                depoId: localState.model.depoId,
                kur: 1,
                stok: {id: row?.stokId, name: row?.ad, kod: ilgiliStok.kod, ilkKod: ilgiliStok.kod},
                genislik: ilgiliStok?.tipOzellikler?.genislik,
                kalinlik: ilgiliStok?.tipOzellikler?.kalinlik,
                adet: ilgiliStok?.tipOzellikler?.adet,
                tonaj: generateTonajFromSiparis(row?.miktar as number,
                    ilgiliStok?.tipOzellikler?.genislik as number,
                    ilgiliStok?.tipOzellikler?.genislik as number),
                tabanTonaj: getSiparisTabanTonaj(localState.stokRef as StokEntity, ilgiliStok, row as any),
                tabanId: localState.model.tabanId,
            } as SacUretimDilmeModel;

        }

        function getSiparisTabanTonaj(anaStok:StokEntity, stok:StokEntity, satir:SiparisSatirFlatView) {
            const kullanilanGenislik = (stok.tipOzellikler?.adet as number) * (stok.tipOzellikler?.genislik as number);
            const toplamGenislik = anaStok.tipOzellikler?.genislik as number;
            const kullanilanTonaj = satir.miktar as number;
            return toplamGenislik * kullanilanTonaj / kullanilanGenislik;
        }


        function generateTonajFromSiparis(tabanTonaj: number, tabanGenislik: number, uretilenGenislik: number) {
            return ((tabanTonaj * uretilenGenislik) / tabanGenislik);
        }


        return {
            ...toRefs(localState),
            addRow,
            focusTonajBox,
            getHesaplananTonaj,
            editTabanTonaj,
            editTabanTonajAppy,
            isNewSlot,
            addRowFromSiparis,
            getKalacakGenislik,
            getMaxAdet
        };
    }
});

