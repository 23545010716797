import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BirimlerSelectBox = _resolveComponent("BirimlerSelectBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NMultipleImageUploader = _resolveComponent("NMultipleImageUploader")!
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!

  return (_openBlock(), _createBlock(_component_NFormGrid, {
    cols: "1.5fr 1fr 1fr",
    span: "3"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_NFormField, {
        label: "Birim",
        "grid-cols": "120px 1fr"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BirimlerSelectBox, {
            modelValue: _ctx.model.birim,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.birim) = $event)),
            placeholder: _ctx.ozellikDevir.birim
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _: 1
      }),
      _createVNode(_component_NFormField, {
        label: "Kar Marjı",
        "grid-cols": "60px 1fr"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DxNumberBox, {
            value: _ctx.model.karMarji,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.karMarji) = $event)),
            placeholder: _ctx.ozellikDevir.karMarji
          }, null, 8, ["value", "placeholder"])
        ]),
        _: 1
      }),
      _createVNode(_component_NFormField, {
        label: "Kdv",
        "grid-cols": "60px 1fr"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DxNumberBox, {
            value: _ctx.model.kdv,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.kdv) = $event)),
            placeholder: _ctx.ozellikDevir.kdv
          }, null, 8, ["value", "placeholder"])
        ]),
        _: 1
      }),
      _createVNode(_component_NFormField, {
        label: "Kısa Açıklama",
        "grid-cols": "120px 1fr",
        span: "3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NInput, {
            modelValue: _ctx.model.stokKisaAciklama,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.stokKisaAciklama) = $event)),
            placeholder: _ctx.ozellikDevir.stokKisaAciklama
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _: 1
      }),
      _createVNode(_component_NFormField, {
        label: "Açıklama",
        "grid-cols": "120px 1fr",
        span: "3"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NInput, {
            modelValue: _ctx.model.stokAciklama,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.stokAciklama) = $event)),
            placeholder: _ctx.ozellikDevir.stokAciklama
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _: 1
      }),
      _createVNode(_component_NFormField, {
        label: "Kategori Resimleri",
        "grid-cols": "120px 1fr",
        span: "1",
        style: {"margin-top":"8px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NMultipleImageUploader, {
            src: _ctx.model.resimler,
            "onUpdate:src": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.resimler) = $event)),
            "server-url": _ctx.serverUrl
          }, null, 8, ["src", "server-url"])
        ]),
        _: 1
      }),
      _createVNode(_component_NFormField, {
        label: " ",
        "grid-cols": "auto 1fr 1fr",
        span: "2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NSwitch, {
            modelValue: _ctx.model.satisKdvDahil,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.satisKdvDahil) = $event)),
            label: "Satış Kdv Dahil"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_NSwitch, {
            modelValue: _ctx.model.alisKdvDahil,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.alisKdvDahil) = $event)),
            label: "Alış Kdv Dahil"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}