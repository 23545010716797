import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"width":"100%","height":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_CariBakiyeBilgileri = _resolveComponent("CariBakiyeBilgileri")!
  const _component_CariSelectBox = _resolveComponent("CariSelectBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_KasaSelectBox = _resolveComponent("KasaSelectBox")!
  const _component_BankaSelectBox = _resolveComponent("BankaSelectBox")!
  const _component_KrediKartiSelectBox = _resolveComponent("KrediKartiSelectBox")!
  const _component_PosSelectBox = _resolveComponent("PosSelectBox")!
  const _component_DxDateBox = _resolveComponent("DxDateBox")!
  const _component_NSelectBox = _resolveComponent("NSelectBox")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_HizmetMasrafSelectBox = _resolveComponent("HizmetMasrafSelectBox")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_TutarsalBilgiler = _resolveComponent("TutarsalBilgiler")!
  const _component_AlacakBorcTaksitDekont = _resolveComponent("AlacakBorcTaksitDekont")!
  const _component_SubeSelectBox = _resolveComponent("SubeSelectBox")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_PlasiyerSelectBox = _resolveComponent("PlasiyerSelectBox")!
  const _component_ProjeSelectBox = _resolveComponent("ProjeSelectBox")!
  const _component_DepartmanSelectBox = _resolveComponent("DepartmanSelectBox")!
  const _component_CariDekontDialogOncekiTaksitler = _resolveComponent("CariDekontDialogOncekiTaksitler")!
  const _component_CokluDokuman = _resolveComponent("CokluDokuman")!
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NTextarea = _resolveComponent("NTextarea")!
  const _component_TaksitlendirmePanel = _resolveComponent("TaksitlendirmePanel")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    modelValue: _ctx.showFormRef,
    "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.showFormRef) = $event)),
    width: "700px",
    "closable-mask": false,
    tabs: _ctx.isLoading ? [] : _ctx.tabs,
    "selected-tab": _ctx.selectedTab,
    "onUpdate:selectedTab": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.selectedTab) = $event)),
    height: "800px"
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.menu.name) + " ", 1),
      _createVNode(_component_NTag, { type: "success" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.cari.getUnvan), 1)
        ]),
        _: 1
      })
    ]),
    body: _withCtx(() => [
      (_ctx.model && !_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_CariBakiyeBilgileri, { cari: _ctx.cari }, null, 8, ["cari"]),
            (_ctx.selectedTab === 0)
              ? (_openBlock(), _createBlock(_component_NFormGrid, {
                  key: 0,
                  gap: "0",
                  cols: "1fr 1fr",
                  rows: "auto auto 1fr"
                }, {
                  default: _withCtx(() => [
                    (_ctx.menuModel.refrenceType == _ctx.menuReferansType.Cari)
                      ? (_openBlock(), _createBlock(_component_NFormField, {
                          key: 0,
                          "grid-cols": "80px 1fr",
                          label: _ctx.menuModel?.hareketTipi == _ctx.cariHaraketTip.Cari_Virman_Alacak ?  'Alan Cari' :
            _ctx.menuModel?.hareketTipi == _ctx.cariHaraketTip.Cari_Virman_Borc ? 'Veren Cari' : 'Cari',
                          span: "2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_CariSelectBox, {
                              "first-select": false,
                              "get-unvan": _ctx.model.referansAd,
                              "onUpdate:getUnvan": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.referansAd) = $event)),
                              id: _ctx.model.referansId,
                              "onUpdate:id": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.referansId) = $event)),
                              "show-bakiye": ""
                            }, null, 8, ["get-unvan", "id"])
                          ]),
                          _: 1
                        }, 8, ["label"]))
                      : _createCommentVNode("", true),
                    (_ctx.menuModel.refrenceType == _ctx.menuReferansType.Kasa)
                      ? (_openBlock(), _createBlock(_component_NFormField, {
                          key: 1,
                          "grid-cols": "80px 1fr",
                          label: "Kasa",
                          span: "2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_KasaSelectBox, {
                              "first-select": false,
                              name: _ctx.model.referansAd,
                              "onUpdate:name": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.referansAd) = $event)),
                              modelValue: _ctx.model.referansId,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.referansId) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.menuModel.refrenceType == _ctx.menuReferansType.Banka)
                      ? (_openBlock(), _createBlock(_component_NFormField, {
                          key: 2,
                          "grid-cols": "80px 1fr",
                          label: "Banka",
                          span: "2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_BankaSelectBox, {
                              "first-select": false,
                              name: _ctx.model.referansAd,
                              "onUpdate:name": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.referansAd) = $event)),
                              modelValue: _ctx.model.referansId,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.referansId) = $event))
                            }, null, 8, ["name", "modelValue"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.menuModel.refrenceType == _ctx.menuReferansType.CariMailOrder)
                      ? (_openBlock(), _createBlock(_component_NFormField, {
                          key: 3,
                          "grid-cols": "80px 1fr",
                          label: "Mail Order Cari",
                          span: "2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_CariSelectBox, {
                              "get-unvan": _ctx.model.referansAd,
                              "onUpdate:getUnvan": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.referansAd) = $event)),
                              id: _ctx.model.referansId,
                              "onUpdate:id": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.referansId) = $event)),
                              "is-pos-cihazi": true,
                              "show-bakiye": "",
                              onVarsayilanDoviz: _ctx.varsayilanDovizChanged
                            }, null, 8, ["get-unvan", "id", "onVarsayilanDoviz"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.menuModel.refrenceType == _ctx.menuReferansType.Kredi_Karti)
                      ? (_openBlock(), _createBlock(_component_NFormField, {
                          key: 4,
                          "grid-cols": "80px 1fr",
                          label: "Kredi Kartı",
                          span: "2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_KrediKartiSelectBox, {
                              name: _ctx.model.referansAd,
                              "onUpdate:name": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.referansAd) = $event)),
                              "hareket-id": _ctx.model.id,
                              "onUpdate:hareketId": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.id) = $event)),
                              vade: _ctx.model.vade,
                              "onUpdate:vade": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.vade) = $event)),
                              modelValue: _ctx.model.referansId,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.referansId) = $event))
                            }, null, 8, ["name", "hareket-id", "vade", "modelValue"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.menuModel.refrenceType == _ctx.menuReferansType.Pos)
                      ? (_openBlock(), _createBlock(_component_NFormField, {
                          key: 5,
                          "grid-cols": "80px 1fr",
                          label: "Pos",
                          span: "2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_PosSelectBox, {
                              "hareket-id": _ctx.model.id,
                              "onUpdate:hareketId": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.id) = $event)),
                              vade: _ctx.model.vade,
                              "onUpdate:vade": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.vade) = $event)),
                              modelValue: _ctx.model.referansId,
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.referansId) = $event)),
                              name: _ctx.model.referansAd,
                              "onUpdate:name": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.referansAd) = $event))
                            }, null, 8, ["hareket-id", "vade", "modelValue", "name"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(_component_NFormField, {
                      label: "Evrak Tarihi",
                      "grid-cols": "80px 1fr"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxDateBox, {
                          value: _ctx.model.tarih,
                          "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model.tarih) = $event)),
                          onValueChanged: _ctx.evrakTarihiChanged
                        }, null, 8, ["value", "onValueChanged"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      "grid-cols": "80px 1fr",
                      label: "Evrak Tipi"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NSelectBox, {
                          modelValue: _ctx.model.genelResmiMuhasebeTip,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model.genelResmiMuhasebeTip) = $event)),
                          items: _ctx.genelResmiMuhasebeTipList,
                          "label-field": "key",
                          "value-field": "value"
                        }, null, 8, ["modelValue", "items"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      "grid-cols": "80px 1fr",
                      label: "Evrak No"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NInput, {
                          type: "text",
                          modelValue: _ctx.model.evrakNo,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.model.evrakNo) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      "grid-cols": "80px 1fr",
                      label: "Vade Tarihi"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxDateBox, {
                          value: _ctx.model.vade,
                          "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.model.vade) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      "grid-cols": "80px 1fr",
                      span: "2",
                      label: "Açıklama"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NInput, {
                          type: "text",
                          modelValue: _ctx.model.aciklama,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.model.aciklama) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    (_ctx.isHizmetSelect())
                      ? (_openBlock(), _createBlock(_component_NFormField, {
                          key: 6,
                          "grid-cols": "80px 1fr",
                          label: "Banka Komisyon",
                          span: "1"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_HizmetMasrafSelectBox, {
                              "first-select": false,
                              modelValue: _ctx.model.hizmetMasrafId,
                              "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.model.hizmetMasrafId) = $event))
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.isHizmetSelect())
                      ? (_openBlock(), _createBlock(_component_NFormField, {
                          key: 7,
                          "grid-cols": "80px 1fr",
                          label: "Komisyon Tutar",
                          span: "1"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_DxNumberBox, {
                              value: _ctx.model.hizmetMasrafTutar,
                              "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.model.hizmetMasrafTutar) = $event)),
                              min: 0,
                              format: "#,##0.0000"
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.selectedTab === 0)
              ? (_openBlock(), _createBlock(_component_TutarsalBilgiler, {
                  key: 1,
                  kurlar: _ctx.kurlarRef,
                  "islem-hesaplama-entity": _ctx.model.hesaplama,
                  "hareket-id": _ctx.model.id
                }, null, 8, ["kurlar", "islem-hesaplama-entity", "hareket-id"]))
              : _createCommentVNode("", true),
            (_ctx.firma.odemeTahsilatPlanKullanimi)
              ? _withDirectives((_openBlock(), _createBlock(_component_AlacakBorcTaksitDekont, {
                  key: 2,
                  modelValue: _ctx.model,
                  "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.model) = $event)),
                  tip: _ctx.menuModel.hareketTipi,
                  "onUpdate:tip": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.menuModel.hareketTipi) = $event))
                }, null, 8, ["modelValue", "tip"])), [
                  [_vShow, _ctx.selectedTab === 0]
                ])
              : _createCommentVNode("", true),
            _withDirectives(_createElementVNode("div", null, [
              _createVNode(_component_NFormGrid, {
                gap: "0",
                cols: "1fr 1fr",
                rows: "auto auto 1fr"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NFormField, {
                    label: "Şube",
                    "grid-cols": "80px 1fr"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SubeSelectBox, {
                        modelValue: _ctx.model.subeId,
                        "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.model.subeId) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NFormField, {
                    label: "Hesabı",
                    "grid-cols": "80px 1fr"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DxSelectBox, {
                        "show-clear-button": true,
                        searchEnabled: true,
                        items: _ctx.cari.hesaplar,
                        "search-timeout": 1,
                        value: _ctx.model.cariHesabi,
                        "onUpdate:value": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.model.cariHesabi) = $event))
                      }, null, 8, ["items", "value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NFormField, {
                    label: "İşi",
                    "grid-cols": "80px 1fr"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DxSelectBox, {
                        items: _ctx.cari.isler,
                        "search-timeout": 1,
                        searchEnabled: false,
                        value: _ctx.model.cariIsi,
                        "onUpdate:value": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.model.cariIsi) = $event))
                      }, null, 8, ["items", "value"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NFormField, {
                    label: "Plasiyer",
                    "grid-cols": "80px 1fr"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PlasiyerSelectBox, {
                        modelValue: _ctx.model.plasiyerId,
                        "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.model.plasiyerId) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NFormField, {
                    label: "Proje",
                    "grid-cols": "80px 1fr"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ProjeSelectBox, {
                        modelValue: _ctx.model.projeId,
                        "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.model.projeId) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NFormField, {
                    label: "Departman",
                    "grid-cols": "80px 1fr"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DepartmanSelectBox, {
                        modelValue: _ctx.model.departmanId,
                        "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.model.departmanId) = $event))
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ], 512), [
              [_vShow, _ctx.selectedTab === 1]
            ]),
            (_ctx.selectedTab === 2 )
              ? (_openBlock(), _createBlock(_component_CariDekontDialogOncekiTaksitler, {
                  key: 3,
                  "cari-id": _ctx.cariIdRef,
                  "cari-hareket-tip": _ctx.menuModel.hareketTipi
                }, null, 8, ["cari-id", "cari-hareket-tip"]))
              : _createCommentVNode("", true),
            (_ctx.selectedTab === 3 )
              ? (_openBlock(), _createBlock(_component_NFormGrid, {
                  key: 4,
                  cols: "1fr",
                  gap: "10px"
                }, {
                  default: _withCtx(() => [
                    (_ctx.selectedTab === 3 && _ctx.model.dokumanlar )
                      ? (_openBlock(), _createBlock(_component_CokluDokuman, {
                          key: 0,
                          modelValue: _ctx.model.dokumanlar,
                          "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.model.dokumanlar) = $event))
                        }, null, 8, ["modelValue"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.selectedTab===4)
              ? (_openBlock(), _createBlock(_component_NFormGrid, {
                  key: 5,
                  cols: "1fr",
                  gap: "2px"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NFormGrid, {
                      cols: "1fr",
                      title: "Sms Gönder"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NFormField, {
                          "grid-cols": "50px 1fr 150px",
                          label: "Cep"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_NInput, {
                              modelValue: _ctx.cariCep,
                              "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.cariCep) = $event))
                            }, null, 8, ["modelValue"]),
                            _createVNode(_component_NSwitch, {
                              modelValue: _ctx.smsSwicth,
                              "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.smsSwicth) = $event)),
                              label: "Sms Gönder"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormGrid, {
                      cols: "1fr",
                      title: "Mail Gönder"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NFormField, {
                          "grid-cols": "50px 1fr 150px",
                          label: "Mail"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_NInput, {
                              modelValue: _ctx.cariMail,
                              "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.cariMail) = $event))
                            }, null, 8, ["modelValue"]),
                            _createVNode(_component_NSwitch, {
                              modelValue: _ctx.mailSwitch,
                              "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.mailSwitch) = $event)),
                              label: "Mail Gönder"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormGrid, {
                      cols: "1fr",
                      title: "Kargo Gönder"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NFormField, {
                          "grid-cols": "50px 1fr 150px",
                          label: "Kargo"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_NSelectBox, {
                              items: ['Yurtiçi','Aras' , 'Mng' , 'Sürat' , 'Ptt' , 'UPS'],
                              "label-field": "",
                              "value-field": ""
                            }),
                            _createVNode(_component_NSwitch, {
                              modelValue: _ctx.kargoSwitch,
                              "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.kargoSwitch) = $event)),
                              label: "Kargo Gönder"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormGrid, {
                      cols: "1fr",
                      title: "Açıklama"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NTextarea, {
                          placeholder: _ctx.cari.getUnvan + ' carisine  ' +  _ctx.model.hesaplama?.islenecekTutar.format(2,2) + ' '  + _ctx.menuModel.name
                        }, null, 8, ["placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.selectedTab == 5)
              ? (_openBlock(), _createBlock(_component_NFormGrid, {
                  key: 6,
                  cols: "1fr"
                }, {
                  default: _withCtx(() => [
                    (_ctx.selectedTab == 5)
                      ? (_openBlock(), _createBlock(_component_TaksitlendirmePanel, {
                          key: 0,
                          taksitler: _ctx.model.vadeler,
                          "onUpdate:taksitler": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.model.vadeler) = $event)),
                          tutar: _ctx.model.hesaplama.islenecekTutar,
                          "onUpdate:tutar": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.model.hesaplama.islenecekTutar) = $event)),
                          referansHareketId: _ctx.model.id,
                          vade: _ctx.model.vade
                        }, null, 8, ["taksitler", "tutar", "referansHareketId", "vade"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    actions: _withCtx(() => [
      (_ctx.model.id && _ctx.model.id.length > 0)
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 0,
            type: "danger",
            size: "small",
            icon: "trash",
            disabled: !_ctx.formDeleteDisabled,
            click: _ctx.hareketDelete
          }, null, 8, ["disabled", "click"]))
        : _createCommentVNode("", true),
      _createVNode(_component_NButton, {
        type: "warning",
        size: "small",
        icon: "x-circle",
        onClick: _cache[39] || (_cache[39] = ($event: any) => (_ctx.showFormRef = false))
      }, {
        default: _withCtx(() => [
          _createTextVNode("İptal ")
        ]),
        _: 1
      }),
      _createVNode(_component_NButton, {
        disabled: !_ctx.formSaveDisabled,
        type: "success",
        size: "small",
        icon: "save",
        click: _ctx.save
      }, {
        default: _withCtx(() => [
          _createTextVNode("Kaydet")
        ]),
        _: 1
      }, 8, ["disabled", "click"]),
      _createVNode(_component_NButton, {
        disabled: !_ctx.formSaveDisabled,
        type: "info",
        size: "small",
        icon: "printer",
        click: _ctx.saveAndPrint
      }, {
        default: _withCtx(() => [
          _createTextVNode("Kaydet ve Yazdır ")
        ]),
        _: 1
      }, 8, ["disabled", "click"])
    ]),
    _: 1
  }, 8, ["modelValue", "tabs", "selected-tab"]))
}