import { StokFlatView } from "@/api/models/StokFlatView";

export const StokFlatUtils = {
  getKalanMiktar(stok: StokFlatView, depoId: string): number {
    if (!stok)
      return 0;
    if (stok.miktarOzet && stok.miktarOzet.length > 0 && depoId && depoId.length > 0) {
      const miktarOzet = stok.miktarOzet.find(a => a.depo?.id == depoId);

      if (miktarOzet)
        return miktarOzet.kalanMiktar as number;
      else
        return stok.kalanMiktar as number;
    }
    return stok.kalanMiktar as number;

  }


}