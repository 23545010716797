
import { computed, defineComponent } from "vue";
import NGrid from "@/components/Grid/NGrid.vue";

export default defineComponent({
  name: "NFormGrid",
  components: { NGrid },
  props: {
    tabs: {
      type: Array
    },
    cols: {
      type: String,
      default: "repeat(12, 1fr)"
    },
    rows: {
      type: String,
      default: "auto"
    },
    gap: {
      type: String,
      default: "0px"
    },
    title: {
      type: String
    },
    span: {
      type: String,
      default: "1"
    },
    description: {
      type: String
    },
    padding: {
      type: String,
      default: "8px 16px 16px 16px"
    },
    selectedTab: {
      default: 0
    },
    scrollable: Boolean,
  },
  setup(props, { emit }) {


    const selectedTabRef = computed({
      get: () => props.selectedTab,
      set: (val) => emit("update:selectedTab", val)
    });

    function getGridFormClass() {
      if (props.title && props.title.length > 0) {
        //   console.log(props.title);
        return ["form-grid", "form-grid-headered"];
      } else {
        return ["form-grid", "form-grid-headered"];
      }
    }

    function isTabs() {
      return props.tabs && props.tabs.length > 0;
    }

    return {
      getGridFormClass,
      isTabs,
      selectedTabRef
    };
  }
});
