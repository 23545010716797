import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { style: {"width":"100%"} }
const _hoisted_2 = { style: {"margin-bottom":"3px"} }
const _hoisted_3 = {
  key: 0,
  style: {"margin":"5px 10px"}
}
const _hoisted_4 = { class: "cariSelectBox" }
const _hoisted_5 = { class: "unvan" }
const _hoisted_6 = {
  key: 0,
  style: {"font-size":"10px","color":"#8a95a4"}
}
const _hoisted_7 = {
  key: 1,
  style: {"font-size":"10px","color":"#8a95a4"}
}
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { title: "Tel" }
const _hoisted_10 = { title: "Tel 2" }
const _hoisted_11 = { title: "Cep" }
const _hoisted_12 = { title: "Cep 2" }
const _hoisted_13 = {
  key: 0,
  style: {"text-align":"right","font-family":"'Barlow Semi Condensed', san-serif"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NTag = _resolveComponent("NTag")!
  const _component_DxList = _resolveComponent("DxList")!
  const _component_DxDropDownBox = _resolveComponent("DxDropDownBox")!
  const _component_CariFormDialog = _resolveComponent("CariFormDialog")!

  return (_openBlock(), _createBlock(_component_NGrid, _mergeProps({
    cols: "1fr 35px auto",
    gap: "5px"
  }, _ctx.$attrs, { style: {"align-items":"center"} }), {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DxDropDownBox, {
          value: _ctx.selectedId,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedId) = $event)),
          ref: "dropDown",
          "defer-rendering": false,
          "display-expr": _ctx.gridBoxDisplayExpr,
          "show-clear-button": true,
          "data-source": _ctx.dxListKartListe,
          "value-expr": "id",
          placeholder: "Cari Seçiniz...",
          labelMode: "floating",
          disabled: _ctx.disabled,
          label: _ctx.label
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NGrid, {
              cols: "1fr",
              rows: "35px 500px"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_DxTextBox, {
                    "value-change-event": "keyup",
                    "show-clear-button": true,
                    placeholder: "Cari Seçiniz",
                    value: _ctx.cariTextField,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.cariTextField) = $event))
                  }, null, 8, ["value"])
                ]),
                (_ctx.dxListKartListe && _ctx.dxListKartListe.length  == 0 )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_NButton, {
                        block: "",
                        type: "success",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.yeniCariAc())),
                        icon: "user-plus"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Yeni Cari Aç ")
                        ]),
                        _: 1
                      })
                    ]))
                  : (_openBlock(), _createBlock(_component_DxList, {
                      key: 1,
                      ref: "dxListCariSelectBox",
                      "data-source": _ctx.dxListKartListe,
                      "onUpdate:dataSource": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dxListKartListe) = $event)),
                      height: 465,
                      "search-enabled": false,
                      "search-expr": ['unvan' , 'ad' , 'soyad' , 'vergiNo' , 'tcNo' ] ,
                      onItemClick: _ctx.itemClick
                    }, {
                      item: _withCtx(({ data }) => [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_NGrid, { cols: "1fr" }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_5, _toDisplayString(data?.unvan && data?.unvan.length > 0 ? data.unvan : "") + " " + _toDisplayString(data?.ad && data?.ad.length > 0 ? data.ad : "") + " " + _toDisplayString(data?.soyad && data?.soyad.length > 0 ? data.soyad : ""), 1),
                              _createVNode(_component_NGrid, { cols: "2fr auto" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_NGrid, { cols: "1fr" }, {
                                    default: _withCtx(() => [
                                      (data && data.vergiNo)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(data.vergiDairesi && data.vergiDairesi.length > 0 ? data.vergiDairesi + " - " : "") + " " + _toDisplayString(data.vergiNo), 1))
                                        : (data && data.tckNo)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(data.vergiDairesi && data.vergiDairesi.length > 0 ? data.vergiDairesi + " - " : "") + " " + _toDisplayString(data.tckNo), 1))
                                          : (_openBlock(), _createElementBlock("div", _hoisted_8)),
                                      _createVNode(_component_NGrid, {
                                        cols: "1fr",
                                        style: {"font-size":"10px","color":"#8a95a4"}
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("div", _hoisted_9, _toDisplayString(data?.tel), 1),
                                          _createElementVNode("div", _hoisted_10, _toDisplayString(data?.te2), 1),
                                          _createElementVNode("div", _hoisted_11, _toDisplayString(data?.cep), 1),
                                          _createElementVNode("div", _hoisted_12, _toDisplayString(data?.cep2), 1)
                                        ]),
                                        _: 2
                                      }, 1024)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  (_ctx.showBakiye)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                        _createVNode(_component_NTag, {
                                          title: "YEREL BAKİYE",
                                          style: {"font-size":"14px"},
                                          type: data && data.yerelBakiye && data.yerelBakiye == 0 ? 'primary' : data && data.yerelBakiye > 0 ?  'success' : 'danger'  ,
                                          filled: ""
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(data?.yerelBakiye.format(2, 2)) + " ₺ ", 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["type"])
                                      ]))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ]),
                      _: 1
                    }, 8, ["data-source", "onItemClick"]))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "display-expr", "data-source", "disabled", "label"])
      ]),
      _createVNode(_component_NButton, {
        title: "Carileri yeniden yükle",
        style: {"margin-top":"0px"},
        size: "small",
        click: _ctx.yenile,
        icon: "refresh-ccw",
        disabled: _ctx.disabled
      }, null, 8, ["click", "disabled"]),
      ((_ctx.id && _ctx.id.length > 0) )
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 0,
            title: "Seçilen cariyi düzenle",
            style: {"margin-top":"0px"},
            type: "info",
            size: "small",
            onClick: _ctx.duzenle,
            icon: "edit"
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.cariFormDialog)
        ? (_openBlock(), _createBlock(_component_CariFormDialog, {
            key: 1,
            "default-unvan": _ctx.cariTextField,
            id: _ctx.editCariId,
            "onUpdate:id": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.editCariId) = $event)),
            show: _ctx.cariFormDialog,
            "onUpdate:show": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.cariFormDialog) = $event)),
            onSave: _ctx.saveCari
          }, null, 8, ["default-unvan", "id", "show", "onSave"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16))
}