
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import {PazaramaValuesEntity} from "@/api/models/PazaramaValuesEntity";
import {usePazaramaValuesService} from "@/api/services/PazaramaValuesService";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import NTag from "@/components/Tag/NTag.vue";
import NDropDownListBox from "@/components/Dropdown/NDropDownListBox.vue";
import {uuid} from "vue-uuid";

export default defineComponent({
  components: {NDropDownListBox, NTag, NLoadingPanel, NModalDialog},
  props: {
    id: {
      type: String
    },
    show: {
      type: Boolean,
      default: false
    },
    propertyName: {  type:String },
    nesilceValues:{type:Array},
    pazaramaValues:{type:Array},
    kategoriId:{type:String},
  },

  setup(props, {emit}) {
    const localState = reactive({
      model: ref([] as any[]),
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val)
      }),
      subTitle: ref(props.propertyName),
      title: ref("Pazarama Özellik Eşletirme Formu"),
      pazaramaValuesDb:ref([] as PazaramaValuesEntity[])
    })


    async function load() {
      const categoryId = props.kategoriId as string
      localState.pazaramaValuesDb = await usePazaramaValuesService().getGetirOzellik(props.propertyName as string, categoryId)
      localState.model = [];

      props.nesilceValues?.forEach((value:any,i:number)=>{
        const findVal = findValueId(value);
        localState.model.push({id:i, categoryId:categoryId, property:props.propertyName, value:value, valueId:findVal})
      });

    }

    function findValueId(value:string){
      const pazaramaValue = props.pazaramaValues?.find((a:any)=> a.name == value) as any;
      if (pazaramaValue)
        return pazaramaValue.id

      const pazaramaValueDb = localState.pazaramaValuesDb.find(a => a.value == value);
      if (pazaramaValueDb)
        return pazaramaValueDb.valueId;

      return 0
    }

    async function save() {
      for (let i = 0; i < localState.model.length; i++) {
        const pazaramaValue:PazaramaValuesEntity = localState.model[i];
        pazaramaValue.id = uuid.v4()
        await usePazaramaValuesService().postEkle(pazaramaValue);
      }

      localState.showRef = false;
    }

    load();

    return {
      ...toRefs(localState),
      load,
      save
    }
  }
})
