export const fastCartesian = function(arrays: any[]) {
  if (arrays.length === 0) {
    return [];
  }

  const loopFunc = getLoopFunc(arrays.length);
  const result = [] as any[];
  loopFunc(arrays, result);
  return result;
};

const getLoopFunc = function(length: number) {
  const cachedLoopFunc = cache[length];

  if (cachedLoopFunc !== undefined) {
    return cachedLoopFunc;
  }

  const loopFunc = mGetLoopFunc(length);
  cache[length] = loopFunc;
  return loopFunc;
};

const cache: any = {};

const mGetLoopFunc = function(length: number) {
  const indexes = Array.from({ length }, getIndex);
  const start = indexes
    .map(index => `for (const value${index} of arrays[${index}]) {`)
    .join("\n");
  const middle = indexes.map(index => `value${index}`).join(", ");
  const end = "}\n".repeat(length);

  // eslint-disable-next-line no-new-func
  return new Function(
    "arrays",
    "result",
    `${start}\nresult.push([${middle}])\n${end}`
  );
};

const getIndex = function(value: any, index: number) {
  return String(index);
};
