
import { defineComponent } from "vue";

export default defineComponent({
  name: "NViewGrid",
  props: {
    cols: {
      type: String,
      default: "repeat(12, 1fr)"
    },
    rows: {
      type: String,
      default: "auto"
    },
    gap: {
      type: String,
      default: "15px"
    }
  }
});
