import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "dx-card" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { style: {"opacity":"0.8"} }
const _hoisted_11 = { style: {"opacity":"0.8"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NAlert = _resolveComponent("NAlert")!
  const _component_DxExport = _resolveComponent("DxExport")!
  const _component_DxLoadPanel = _resolveComponent("DxLoadPanel")!
  const _component_DxGroupPanel = _resolveComponent("DxGroupPanel")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxColumnChooser = _resolveComponent("DxColumnChooser")!
  const _component_DxEditing = _resolveComponent("DxEditing")!
  const _component_DxStateStoring = _resolveComponent("DxStateStoring")!
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxLookup = _resolveComponent("DxLookup")!
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NDxStateStoring = _resolveComponent("NDxStateStoring")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_NGrid = _resolveComponent("NGrid")!

  return (_ctx.model)
    ? (_openBlock(), _createElementBlock("div", _mergeProps({ key: 0 }, _ctx.$attrs, { style: {"margin":"0","padding":"0"} }), [
        (_ctx.model.items?.length > 0)
          ? (_openBlock(), _createBlock(_component_NAlert, { key: 0 }, {
              default: _withCtx(() => [
                _createTextVNode("Varyantlı stok için birimler kullanılamaz.")
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_NGrid, {
              key: 1,
              gap: "0",
              cols: "1fr"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_DxDataGrid, {
                    dataSource: _ctx.model.birimFiyatlari,
                    "onUpdate:dataSource": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.birimFiyatlari) = $event)),
                    ref: "stokBirimlerDataGrid",
                    "allow-column-reordering": true,
                    "allow-column-resizing": true,
                    "column-resizing-mode": "widget",
                    "show-row-lines": true,
                    onEditingStart: _ctx.editingStart,
                    onRowUpdated: _ctx.rowUpdated,
                    onRowUpdating: _ctx.rowUpdating,
                    style: {"height":"400px","width":"100%"},
                    toolbarTemplate: "toolbar"
                  }, {
                    satisFiyati: _withCtx(({data}) => [
                      _createVNode(_component_NTag, {
                        type: _ctx.getFiyatTagType(data.data),
                        filled: true
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.getFiyat(data.data).format(2, 2)) + " ", 1),
                          (data.data.fiyatTipi === 0 || data.data.fiyatTipi === 1 || data.data.fiyatTipi === 2)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.dovizList.find(a => a.value == _ctx.model.satisDoviz)?.description), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.dovizList.find(a => a.value == _ctx.model.alisDoviz)?.description), 1))
                        ]),
                        _: 2
                      }, 1032, ["type"])
                    ]),
                    satisFiyatiKdvsiz: _withCtx(({data}) => [
                      _createVNode(_component_NTag, {
                        type: _ctx.getFiyatTagType(data.data),
                        filled: true
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.getFiyatKdvsiz(data.data).format(2, 2)) + " ", 1),
                          (data.data.fiyatTipi === 0 || data.data.fiyatTipi === 1 || data.data.fiyatTipi === 2)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.dovizList.find(a => a.value == _ctx.model.satisDoviz)?.description), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.dovizList.find(a => a.value == _ctx.model.alisDoviz)?.description), 1))
                        ]),
                        _: 2
                      }, 1032, ["type"])
                    ]),
                    alisFiyati: _withCtx(({data}) => [
                      _createVNode(_component_NTag, {
                        type: _ctx.getFiyatTagType(data.data)
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.getAlisFiyatKdvli(data.data).format(2, 2)) + " ", 1),
                          (data.data.fiyatTipi === 0 || data.data.fiyatTipi === 1 || data.data.fiyatTipi === 2)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.dovizList.find(a => a.value == _ctx.model.satisDoviz)?.description), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.dovizList.find(a => a.value == _ctx.model.alisDoviz)?.description), 1))
                        ]),
                        _: 2
                      }, 1032, ["type"])
                    ]),
                    alisFiyatiKdvsiz: _withCtx(({data}) => [
                      _createVNode(_component_NTag, {
                        type: _ctx.getFiyatTagType(data.data)
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", null, _toDisplayString(_ctx.getAlisFiyatKdvsiz(data.data).format(2, 2)) + " ", 1),
                          (data.data.fiyatTipi === 0 || data.data.fiyatTipi === 1 || data.data.fiyatTipi === 2)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.dovizList.find(a => a.value == _ctx.model.satisDoviz)?.description), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.dovizList.find(a => a.value == _ctx.model.alisDoviz)?.description), 1))
                        ]),
                        _: 2
                      }, 1032, ["type"])
                    ]),
                    fiyatEditTemplate: _withCtx(({data}) => [
                      _createElementVNode("div", null, _toDisplayString(data.data.fiyat.format(2, 2)) + " " + _toDisplayString(_ctx.dovizList.find(a => a.value == _ctx.model.satisDoviz)?.description), 1)
                    ]),
                    toolbar: _withCtx(() => [
                      _createVNode(_component_NFormGrid, {
                        cols: "auto auto auto auto auto",
                        padding: "0",
                        gap: "2px"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_NDxStateStoring, { "data-grid-ref": _ctx.stokBirimlerDataGrid }, null, 8, ["data-grid-ref"]),
                          _createVNode(_component_NButton, {
                            title: "Yeni Birim Ekle",
                            type: "info",
                            size: "small",
                            onClick: _ctx.addBirim,
                            icon: "plus"
                          }, null, 8, ["onClick"]),
                          _createVNode(_component_NTag, {
                            title: "E-Ticaret Fiyatı (Kdv Dahil)",
                            type: "warning",
                            filled: true
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getEticaretFiyati().format(2, 2)), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_NTag, {
                            title: "Alış Dövizi , Alış Fiyat ( Alış Fiyatı Kdv Dahil) Kdv Oranı",
                            type: "danger",
                            filled: true
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.dovizList.find(a => a.value == _ctx.model.alisDoviz)?.description) + " " + _toDisplayString((_ctx.model.alisKdvDahil ? (_ctx.model.alisFiyat / (1 + (_ctx.model.alisKdv / 100))) : _ctx.model.alisFiyat).format(2, 2)) + " ", 1),
                              _createElementVNode("span", _hoisted_10, " + %" + _toDisplayString(_ctx.model.alisKdv) + " Kdv = ", 1),
                              _createTextVNode(" " + _toDisplayString((_ctx.model.alisKdvDahil ? _ctx.model.alisFiyat : (_ctx.model.alisFiyat * (1 + (_ctx.model.alisKdv / 100)))).format(2, 2)), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_NTag, {
                            title: "Satış Dövizi , Satış Fiyat ( Satış Fiyatı Kdv Dahil) Kdv Oranı",
                            type: "success",
                            filled: true
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.dovizList.find(a => a.value == _ctx.model.satisDoviz)?.description) + " " + _toDisplayString((_ctx.model.satisKdvDahil ? (_ctx.model.satisFiyat / (1 + (_ctx.model.satisKdv / 100))) : _ctx.model.satisFiyat).format(2, 2)) + " ", 1),
                              _createElementVNode("span", _hoisted_11, " + %" + _toDisplayString(_ctx.model.satisKdv) + " Kdv = ", 1),
                              _createTextVNode(" " + _toDisplayString((_ctx.model.satisKdvDahil ? _ctx.model.satisFiyat : (_ctx.model.satisFiyat * (1 + (_ctx.model.satisKdv / 100)))).format(2, 2)), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_DxExport, { enabled: true }),
                      _createVNode(_component_DxLoadPanel, { enabled: false }),
                      _createVNode(_component_DxGroupPanel, { visible: false }),
                      _createVNode(_component_DxHeaderFilter, { visible: false }),
                      _createVNode(_component_DxFilterRow, { visible: false }),
                      _createVNode(_component_DxColumnChooser, {
                        enabled: true,
                        mode: "select"
                      }),
                      _createVNode(_component_DxGroupPanel, { visible: false }),
                      _createVNode(_component_DxEditing, {
                        "allow-updating": true,
                        mode: "cell",
                        "allow-deleting": true,
                        "use-icons": true
                      }),
                      _createVNode(_component_DxStateStoring, {
                        enabled: true,
                        type: "custom",
                        "custom-load": () =>  _ctx.loadState()
                      }, null, 8, ["custom-load"]),
                      _createVNode(_component_DxColumn, {
                        caption: "Sil",
                        type: "buttons",
                        width: 40
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_DxButton, { name: "delete" })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_DxColumn, {
                        caption: "Tanımlar",
                        cssClass: "text-center"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_DxColumn, {
                            width: "60px",
                            caption: "Grup",
                            dataField: "grup"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_DxLookup, {
                                "data-source": _ctx.gruplar,
                                "display-expr": "grubAdi",
                                "value-expr": "grubAdi"
                              }, null, 8, ["data-source"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_DxColumn, {
                            width: "60px",
                            caption: "Birim",
                            dataField: "birim"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_DxLookup, {
                                "data-source": _ctx.birimler,
                                "display-expr": "deger",
                                "value-expr": "deger"
                              }, null, 8, ["data-source"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_DxColumn, {
                            width: "60px",
                            caption: "Çarpan",
                            dataField: "carpan"
                          }),
                          _createVNode(_component_DxColumn, {
                            width: "60px",
                            caption: "Değer",
                            dataField: "fiyat",
                            format: "#,##0.00"
                          }),
                          _createVNode(_component_DxColumn, {
                            width: "auto",
                            caption: "Fiyat Tipi",
                            dataField: "fiyatTipi"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_DxLookup, {
                                "data-source": _ctx.fiyatTipList,
                                "display-expr": "description",
                                "value-expr": "value"
                              }, null, 8, ["data-source"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_DxColumn, {
                        caption: "Satış Fiyatı",
                        "css-class": "text-center"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_DxColumn, {
                            width: "auto",
                            caption: "Kdv Dahil",
                            dataField: "satisFiyati",
                            format: "#,##0.00",
                            class: "bold",
                            "calculate-cell-value": (a)=> _ctx.getFiyat(a),
                            "allow-editing": true,
                            "cell-template": "satisFiyati"
                          }, null, 8, ["calculate-cell-value"]),
                          _createVNode(_component_DxColumn, {
                            width: "auto",
                            caption: "Kdv Hariç",
                            dataField: "satisFiyatiKdvsiz",
                            format: "#,##0.00",
                            class: "bold",
                            "calculate-cell-value": (a)=> _ctx.getFiyatKdvsiz(a),
                            "allow-editing": true,
                            "cell-template": "satisFiyatiKdvsiz"
                          }, null, 8, ["calculate-cell-value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_DxColumn, {
                        caption: "Alış Fiyatı",
                        "css-class": "text-center"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_DxColumn, {
                            width: "auto",
                            caption: "Kdv Dahil",
                            dataField: "alisFiyati",
                            format: "#,##0.00",
                            class: "bold",
                            "calculate-cell-value": (a)=> _ctx.getAlisFiyatKdvli(a),
                            "allow-editing": true,
                            "cell-template": "alisFiyati"
                          }, null, 8, ["calculate-cell-value"]),
                          _createVNode(_component_DxColumn, {
                            width: "auto",
                            caption: "Kdv Hariç",
                            dataField: "alisFiyatiKdvsiz",
                            format: "#,##0.00",
                            class: "bold",
                            "calculate-cell-value": (a)=> _ctx.getFiyatKdvsiz(a),
                            "allow-editing": true,
                            "cell-template": "alisFiyatiKdvsiz"
                          }, null, 8, ["calculate-cell-value"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_DxColumn, {
                        width: "auto",
                        caption: "Barkod",
                        dataField: "barkod"
                      })
                    ]),
                    _: 1
                  }, 8, ["dataSource", "onEditingStart", "onRowUpdated", "onRowUpdating"])
                ])
              ]),
              _: 1
            }))
      ], 16))
    : _createCommentVNode("", true)
}