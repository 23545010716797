
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NButton from "@/components/Button/NButton.vue";
import NInput from "@/components/Input/NInput.vue";
import { Ozellik } from "@/api/models/Ozellik";
import { StokKategoriView } from "@/api/models/StokKategoriView";
import { StokKategoriUtils } from "@/modules/Stok/utils/StokKategoriUtils";
import { useStokKategoriService } from "@/api/services/StokKategoriService";
import OzellikForm from "@/modules/StokOzellik/components/OzellikForm.vue";
import { StokOzellikItem } from "@/api/models/StokOzellikItem";
import StokKategoriOzellikItem from "@/modules/StokKategori/components/StokKategoriOzellikItem.vue";
import OzellikFormReadOnly from "@/modules/StokOzellik/components/OzellikFormReadOnly.vue";
import { N11PazaryeriItem } from "@/api/models/N11PazaryeriItem";
import StokKategoriPazaryerleri from "@/modules/StokKategori/components/StokKategoriPazaryerleri.vue";
import { TrendyolPazaryeriItem } from "@/api/models/TrendyolPazaryeriItem";
import { Doviz } from "@/api/models/Doviz";
import { HepsiBuradaKategori } from "@/api/models/HepsiBuradaKategori";
import { HepsiBuradaPazaryeriItem } from "@/api/models/HepsiBuradaPazaryeriItem";
import { HepsiBuradaListingPazaryeriItem } from "@/api/models/HepsiBuradaListingPazaryeriItem";
import { StokKategoriDefaultView } from "@/api/models/StokKategoriDefaultView";
import StokKategori_Birimler from "@/modules/StokKategori/components/StokKategori_Birimler.vue";
import NCard from "@/components/Card/NCard.vue";
import {confirm} from "devextreme/ui/dialog";
import StokKategori_SubeFiyatTab from "@/modules/StokKategori/components/StokKategori_SubeFiyatTab.vue";
import {StokTipList} from "@/api/models/StokTip";

export default defineComponent({
  components: {
    StokKategori_SubeFiyatTab,
    StokKategori_Birimler,
    StokKategoriPazaryerleri,
    OzellikFormReadOnly,
    OzellikForm,
    StokKategoriOzellikItem,
    NInput,
    NButton,
    NFormField,
    NFormGrid,
    NModalDialog
  },
  props: {
    show: {
      type: Boolean
    },
    kategoriId: {
      type: String
    },
    parentId: {
      type: String
    },
    parent:{
      type:Object
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      showForm: computed({
        get: () => props.show,
        set: val => emit("update:show", val)
      }),
      tabs: [
        { label: "Genel Bilgiler", id: "tab1" },
        { label: "Özellikler", id: "tab2" },
        { label: "Varyantlar", id: "tab3" },
        { label: "Pazaryerleri", id: "tab4" },
        { label: "Seo Ayarları", id: "tab5" },
        { label: "Birimler", id: "tab6" },
        { label: "Şube Fiyatları", id: "tab7" },
      ],
      selectedTab: ref(0),
      model: ref({} as StokKategoriView),
      kategoriler: ref([] as StokKategoriView[]),
      ozellikDevir:ref({} as StokOzellikItem),
      tipList:StokTipList,

    });

    async function load() {
     // console.log("stokKategoriDialog.load");
      localState.kategoriler = await useStokKategoriService().getList();


      let defaultView:StokKategoriDefaultView = {};
      if (props.kategoriId && props.kategoriId.length > 1){ // Update
        defaultView = await  useStokKategoriService().getDefaultUpdate(props.kategoriId as string) as StokKategoriDefaultView;
      }else{
        defaultView = await  useStokKategoriService().getDefaultNew(props.parentId as string) as StokKategoriDefaultView;
      }


      localState.model = defaultView.kategori as StokKategoriView;
      localState.ozellikDevir = defaultView.ozellikDevir as StokOzellikItem;

      if (localState.model && localState.model.ozellik)
        localState.model.ozellik.tip = localState.ozellikDevir.tip;
    }

    load();





    async function save() {
      if (localState.model.id && localState.model.id.length > 0)
        await useStokKategoriService().putGuncelle(localState.model);
      else await useStokKategoriService().postEkle(localState.model);
      localState.showForm = false;
    }

    async function remove() {
      if (await confirm("Bu kategoriyi silmek istiyor musunuz?","UYARI!")){
      if (localState.model.id && localState.model.id.length > 0) {
        await useStokKategoriService().deleteSil(localState.model.id);
        localState.showForm = false;
      }
      }
    }

    return {
      ...toRefs(localState),
      save,
      remove
    };
  }
});
