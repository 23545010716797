import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"text-align":"right","font-weight":"bold","font-size":"12px","align-self":"center","grid-column":"span 2"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_DxAutocomplete = _resolveComponent("DxAutocomplete")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_DepoGroupedSelectBox = _resolveComponent("DepoGroupedSelectBox")!
  const _component_SacUretimSiparisSelectButton = _resolveComponent("SacUretimSiparisSelectButton")!
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NGrid, {
      cols: "1fr 0.75fr 0.5fr 0.75fr 0.75fr 0.75fr 0.75fr",
      gap: "4px",
      style: {"padding-bottom":"3px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DxSelectBox, {
          items: _ctx.malzemeSekilTipList,
          value: _ctx.model.tip,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.tip) = $event)),
          displayExpr: "description",
          valueExpr: "value",
          label: "Tip Seçiniz"
        }, null, 8, ["items", "value"]),
        _createVNode(_component_DxAutocomplete, {
          "data-source": _ctx.cinsList,
          value: _ctx.model.cins,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.cins) = $event)),
          class: "uppercase",
          showClearButton: false,
          label: "Cinsi"
        }, null, 8, ["data-source", "value"]),
        _createVNode(_component_DxNumberBox, {
          format: (d) => d?.format(0,2),
          value: _ctx.stokRef.tipOzellikler?.kalinlik,
          label: "Kalınlık",
          disabled: true
        }, null, 8, ["format", "value"]),
        (!(_ctx.model.tip == _ctx.sekilTip.BoruProfil || _ctx.model.tip == _ctx.sekilTip.BoyaliBoru)  )
          ? (_openBlock(), _createBlock(_component_DxNumberBox, {
              key: 0,
              format: (d) => d?.format(0,2),
              value: _ctx.model.genislik,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.genislik) = $event)),
              max: _ctx.stokRef?.tipOzellikler?.genislik,
              label: "Genişlik",
              onChange: _cache[3] || (_cache[3] = () => {})
            }, null, 8, ["format", "value", "max"]))
          : _createCommentVNode("", true),
        (_ctx.model.tip == _ctx.sekilTip.Profil || _ctx.model.tip == _ctx.sekilTip.BoyaliProfil || _ctx.model.tip == _ctx.sekilTip.UProfil ||_ctx.model.tip == _ctx.sekilTip.CProfil ||_ctx.model.tip == _ctx.sekilTip.OmegaProfil || _ctx.model.tip == _ctx.sekilTip.ZProfil )
          ? (_openBlock(), _createBlock(_component_DxNumberBox, {
              key: 1,
              format: (d) => d?.format(0,2),
              value: _ctx.model.yukseklik,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.yukseklik) = $event)),
              min: 0,
              label: "Yükseklik"
            }, null, 8, ["format", "value"]))
          : _createCommentVNode("", true),
        (_ctx.model.tip == _ctx.sekilTip.CProfil ||_ctx.model.tip == _ctx.sekilTip.OmegaProfil )
          ? (_openBlock(), _createBlock(_component_DxNumberBox, {
              key: 2,
              format: (d) => d?.format(0,2),
              value: _ctx.model.yanak,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.yanak) = $event)),
              min: 0,
              label: "Yanak"
            }, null, 8, ["format", "value"]))
          : _createCommentVNode("", true),
        (_ctx.model.tip == _ctx.sekilTip.BoruProfil ||_ctx.model.tip == _ctx.sekilTip.BoyaliBoru  )
          ? (_openBlock(), _createBlock(_component_DxNumberBox, {
              key: 3,
              format: (d) => d?.format(0,2),
              value: _ctx.model.cap,
              "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.cap) = $event)),
              min: 0,
              label: "Çap"
            }, null, 8, ["format", "value"]))
          : _createCommentVNode("", true),
        _createVNode(_component_DxNumberBox, {
          format: (d) => d?.format(0,2),
          value: _ctx.model.uzunluk,
          "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.uzunluk) = $event)),
          max: _ctx.uretimSacFisApi.getKalanBoy(_ctx.stokRef, _ctx.fisRef),
          label: "Boy:",
          onChange: _ctx.valueChangedUzunluk
        }, null, 8, ["format", "value", "max", "onChange"])
      ]),
      _: 1
    }),
    _createVNode(_component_NGrid, {
      cols: "1fr 1fr 1fr 1fr 1fr 1fr",
      gap: "4px",
      style: {"padding-bottom":"2px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DxNumberBox, {
          format: "#,##0",
          value: _ctx.stokRef.getKalanMiktar,
          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.stokRef.getKalanMiktar) = $event)),
          label: "Üretilecek Tonaj"
        }, null, 8, ["value"]),
        _createVNode(_component_DxNumberBox, {
          format: _ctx.storeSettings.model.sacAdet?.format,
          value: _ctx.model.adet,
          "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.adet) = $event)),
          label: "Adet",
          onChange: _ctx.valueChangedAdet
        }, null, 8, ["format", "value", "onChange"]),
        _createVNode(_component_DxNumberBox, {
          format: "#,##0",
          value: _ctx.model.tonaj,
          "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.tonaj) = $event)),
          label: "Tonaj",
          onChange: _ctx.valueChangedTonaj,
          max: _ctx.uretimSacFisApi.getKalanTonaj(_ctx.stokRef, _ctx.fisRef)
        }, null, 8, ["value", "onChange", "max"]),
        _createVNode(_component_DxNumberBox, {
          format: "#,##0",
          value: _ctx.uretimSacFisApi.getKalanTonaj(_ctx.stokRef, _ctx.fisRef),
          label: "Kalan",
          "read-only": true
        }, null, 8, ["value"]),
        _createVNode(_component_NSwitch, {
          label: "Fire",
          style: {"align-self":"center"},
          modelValue: _ctx.model.isFire,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.isFire) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_NGrid, {
      cols: "1fr 1.25fr 0.75fr 0.5fr",
      gap: "4px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DepoGroupedSelectBox, {
          modelValue: _ctx.model.depoId,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.depoId) = $event)),
          style: {"grid-column":"span 1"}
        }, null, 8, ["modelValue"]),
        _createVNode(_component_DxAutocomplete, {
          value: _ctx.model.aciklama,
          "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.aciklama) = $event)),
          "show-clear-button": false,
          "data-source": _ctx.aciklamaList,
          labelMode: "floating",
          label: "Açıklama"
        }, null, 8, ["value", "data-source"]),
        _createVNode(_component_DxAutocomplete, {
          value: _ctx.model.paketleme,
          "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.paketleme) = $event)),
          "show-clear-button": false,
          "data-source": _ctx.paketlemeList,
          labelMode: "floating",
          label: "Paketleme"
        }, null, 8, ["value", "data-source"]),
        _createVNode(_component_DxNumberBox, {
          value: _ctx.model.paketeBol,
          "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.paketeBol) = $event)),
          min: 1,
          format: "#,##0",
          labelMode: "floating",
          label: "Paket"
        }, null, 8, ["value"]),
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.model.kaynakTip == 1 ? '(Siparişten Stok Seçildi *)' : ''), 1),
        _createVNode(_component_SacUretimSiparisSelectButton, {
          onAddRow: _ctx.addRowFromSiparis,
          style: {"align-self":"center"}
        }, null, 8, ["onAddRow"]),
        _createVNode(_component_NButton, {
          style: {"align-self":"center"},
          size: "small",
          type: "info",
          onClick: _ctx.addRow,
          disabled: !_ctx.isProfilValid() || _ctx.uretimSacFisApi.getKalanTonaj(_ctx.stokRef, _ctx.fisRef)<=0
        }, {
          default: _withCtx(() => [
            _createTextVNode("Üretim Yap")
          ]),
          _: 1
        }, 8, ["onClick", "disabled"])
      ]),
      _: 1
    })
  ], 64))
}