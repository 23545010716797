import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {FileLocation} from '../models/FileLocation'
import {ImageFileItem} from '../models/ImageFileItem'
import {FileFolderInfo} from '../models/FileFolderInfo'
import {FilePathInfo} from '../models/FilePathInfo'

export const useImageManagementService = defineStore({
id: 'ImageManagementService',
actions: {


	async postImageManagmentResimEkle(options:any)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/ImageManagment/ResimEkle/${shared.firm.id}`,options);
		await request();
		return response.value;
	},

	async postImageManagmentTopluResimEkle(options:any)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/ImageManagment/TopluResimEkle/${shared.firm.id}`,options);
		await request();
		return response.value;
	},

	async postImageManagmentResimleriGetir(fileFolderInfo:FileFolderInfo)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/ImageManagment/ResimleriGetir/${shared.firm.id}`,{ method:'POST', data:fileFolderInfo });
		await request();
		return response.value;
	},

	async deleteImageManagmentResimSil(filePathInfo:FilePathInfo)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/ImageManagment/ResimSil/${shared.firm.id}`,{ method:'DELETE', data:filePathInfo });
		await request();
		return response.value;
	}
}
});
