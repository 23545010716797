
import {defineComponent, reactive, ref, toRefs} from "vue";
import {StokUtils} from "@/modules/Stok/utils/StokUtils";
import {StokEntity} from "@/api/models/StokEntity";
import NFormField from "@/components/FormField/NFormField.vue";
import {StokBirimItem} from "@/api/models/StokBirimItem";
import {BirimFiyatTipi} from "@/api/models/BirimFiyatTipi";
import {Doviz} from "@/api/models/Doviz";
import {PazaryeriFiyatTipi} from "@/api/models/PazaryeriFiyatTipi";
import {StokBirimUtils} from "@/modules/Stok/utils/StokBirimUtils";
import {FiyatUtils} from "@/modules/Stok/store/FiyatUtils";
import {KurView} from "@/api/models/KurView";
import {useKurCachedServis} from "@/modules/Ortak/services/useKurCachedServis";
import NTag from "@/components/Tag/NTag.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";

export default defineComponent({
  components: { NFormGrid, NTag, NFormField  },
  props: {
    stok:{
      type:Object
    }
  },
  setup(props) {

    const localState = reactive({
      stokUtil:StokUtils,
      //stokRef:computed(()=>props.stok as StokEntity),
      kurList: ref([] as KurView[]),
      stokBirimUtils:StokBirimUtils
    });

    async function load() {
      localState.kurList = await useKurCachedServis().getKurList();
    }

    load()

    function getStokRef():StokEntity {
      if (props.stok && (props.stok as StokEntity).birimFiyatlari)
        return props.stok as StokEntity;
      return {
      } as StokEntity;
    }

    function getParaBirim(birim:StokBirimItem) {

      const stok = getStokRef() as StokEntity;
      if (!stok.birimFiyatlari)
        return  0;

      switch (birim.fiyatTipi) {
        case BirimFiyatTipi.SabitFiyat:
        case BirimFiyatTipi.SatisTutarEkle:
        case BirimFiyatTipi.SatisOranEkle:
          return getParaBirimAdi(stok.satisDoviz as Doviz);

        case BirimFiyatTipi.AlisOranEkle:
        case BirimFiyatTipi.AlisTutarEkle:
          return getParaBirimAdi(stok.alisDoviz as Doviz);

        case BirimFiyatTipi.ETicaretTutarEkle:
        case BirimFiyatTipi.ETicaretOranEkle:
          return getParaBirimAdiByETicaret()
      }
    }
    function getParaBirimAdi(doviz:Doviz){
      switch (doviz) {
        case Doviz.TRY:
        return "₺"
        case Doviz.EUR:
          return  "€";
        case Doviz.USD:
          return  "$";
      }
    }
    function getParaBirimAdiByETicaret() {
      const stok = getStokRef();
      if (!stok.birimFiyatlari)
        return  0;

      if (stok.presta && stok.presta?.length > 0){
        const presta = stok.presta[0];
        if (presta.fiyat){
          switch (presta.fiyat.tip) {
            case PazaryeriFiyatTipi.Otomatik:
              return getParaBirimAdi(stok.alisDoviz as Doviz)
            case PazaryeriFiyatTipi.Fiyat:
            case PazaryeriFiyatTipi.Oran:
            case PazaryeriFiyatTipi.Tutar:
              return getParaBirimAdi(stok.satisDoviz as Doviz)
          }
        }
      }
      return  "-";
    }


    // Fiyatlar

    function getFiyat(row: StokBirimItem, kdvDahil:true){
      const stok = getStokRef();
      if (!stok.birimFiyatlari)
        return  0;
      if (kdvDahil)
        return getFiyatKdvli(row)
      return  getFiyatKdvsiz(row)
    }
    function getFiyatKdvli(row: StokBirimItem) {
      const stok = getStokRef();
      if (!stok.birimFiyatlari)
        return  0;

      return StokBirimUtils.getFiyat(
          row.fiyat as number,
          row.fiyatTipi as BirimFiyatTipi,
          stok.satisFiyat as number,
          stok.satisKdv as number,
          stok.satisKdvDahil as boolean ,
          stok.alisFiyat as number,
          stok.alisKdv as number,
          stok.alisKdvDahil as boolean ,
          getEticaretFiyati()) as number * (row.carpan as number);
    }

    function getFiyatKdvsiz(row: StokBirimItem) {
      const stok = getStokRef();
      if (!stok.birimFiyatlari)
        return  0;
      const kdvliFiyat = getFiyatKdvli(row) as number;
      // kdvHaric
      switch (row.fiyatTipi) {
        case BirimFiyatTipi.SabitFiyat:
        case BirimFiyatTipi.SatisTutarEkle:
        case BirimFiyatTipi.SatisOranEkle:
        case BirimFiyatTipi.ETicaretTutarEkle:
        case BirimFiyatTipi.ETicaretOranEkle:
          return  StokBirimUtils.getKdvsizFiyat(kdvliFiyat,stok.satisKdv as number,true);

        case BirimFiyatTipi.AlisOranEkle:
        case BirimFiyatTipi.AlisTutarEkle:
          return  StokBirimUtils.getKdvsizFiyat(kdvliFiyat,stok.alisKdv as number,true);
      }
    }

    function getEticaretFiyati() {
      const stok = getStokRef();
      if (!stok.birimFiyatlari)
        return  0;

      if (stok.presta && stok.presta.length > 0) {
        const presta = stok.presta[0];

        if (presta && presta.fiyat) {

          const fiyatItem = presta.fiyat;
          const fiyat = FiyatUtils.getPazaryeriFiyat(
              localState.kurList,
              fiyatItem.tip as PazaryeriFiyatTipi,
              fiyatItem.deger as number,

              stok.satisDoviz as Doviz,
              stok.satisFiyat as number,
              stok.satisKdv as number,
              stok.satisKdvDahil as boolean,

              stok.alisDoviz as Doviz,
              stok.alisFiyat as number,
              stok.alisKdv as number,
              stok.alisKdvDahil as boolean,

              stok.maliyet as number,

              presta.karMarji as number,
              presta.kargoTutar as number,
              presta.komisyon as number);

          return fiyat;

        }
      }
      return 0;
    }



    return {
      ...toRefs(localState),
      getParaBirim,
      getFiyat,
      getParaBirimAdi,
      getStokRef
    };
  }
});

