import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b88dcf82"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "n-checkbox-label-group"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("label", _mergeProps({ class: "n-checkbox-wrapper" }, _ctx.$attrs), [
    _createElementVNode("span", {
      class: _normalizeClass(["n-checkbox-check-wrapper", [_ctx.pulse && !_ctx.disabled && 'pulse']])
    }, [
      _createElementVNode("input", {
        type: "checkbox",
        class: "n-checkbox-input",
        disabled: _ctx.disabled,
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
      }, null, 40, _hoisted_1),
      _createElementVNode("span", {
        class: _normalizeClass(["n-checkbox", [
          `n-checkbox--${_ctx.type}`,
          _ctx.modelValue && `n-checkbox--${_ctx.type}--checked`,
          _ctx.disabled && 'n-checkbox--disabled'
        ]])
      }, [
        _createVNode(_component_NIcon, {
          size: "16px",
          type: _ctx.icon
        }, null, 8, ["type"])
      ], 2)
    ], 2),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          (_ctx.label && !_ctx.$slots.default)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(["n-checkbox-label", [
          _ctx.lineThrough && _ctx.modelValue && 'n-checkbox-label--linethrough',
          _ctx.disabled && 'n-checkbox-label--disabled'
        ]])
              }, _toDisplayString(_ctx.label), 3))
            : _createCommentVNode("", true),
          (_ctx.subLabel && !_ctx.$slots.sublabel)
            ? (_openBlock(), _createElementBlock("span", {
                key: 1,
                class: _normalizeClass(["n-checkbox-label n-checkbox-label--sub", [_ctx.disabled && 'n-checkbox-label--disabled']])
              }, _toDisplayString(_ctx.subLabel), 3))
            : _createCommentVNode("", true),
          (_ctx.$slots.default)
            ? (_openBlock(), _createElementBlock("span", {
                key: 2,
                class: _normalizeClass(["n-checkbox-label", [
          _ctx.lineThrough && _ctx.modelValue && 'n-checkbox-label--linethrough',
          _ctx.disabled && 'n-checkbox-label--disabled'
        ]])
              }, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.$slots.sublabel)
            ? (_openBlock(), _createElementBlock("span", {
                key: 3,
                class: _normalizeClass(["n-checkbox-label n-checkbox-label--sub", [_ctx.disabled && 'n-checkbox-label--disabled']])
              }, [
                _renderSlot(_ctx.$slots, "sublabel", {}, undefined, true)
              ], 2))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 16))
}