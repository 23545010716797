import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"display":"flex"}
}
const _hoisted_2 = { style: {"padding":"6px","min-height":"34px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxTreeView = _resolveComponent("DxTreeView")!
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_DxDropDownBox = _resolveComponent("DxDropDownBox")!

  return (_ctx.selectedItemRef && _ctx.dataSource && _ctx.dataSource.length > 0)
    ? (_openBlock(), _createBlock(_component_DxDropDownBox, {
        key: 0,
        value: _ctx.selectedItemRef[_ctx.displayExpr],
        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedItemRef[_ctx.displayExpr]) = $event)),
        opened: _ctx.isTreeBoxOpened,
        "onUpdate:opened": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isTreeBoxOpened) = $event)),
        "show-clear-button": true,
        "data-source": _ctx.dataSource,
        "display-expr": _ctx.displayExpr,
        placeholder: "Seçiniz",
        onValueChanged: _cache[6] || (_cache[6] = ($event: any) => (_ctx.syncTreeViewSelection($event)))
      }, {
        content: _withCtx(() => [
          _createVNode(_component_DxTreeView, {
            ref: "treeViewRefName",
            "min-search-length": 3,
            "data-source": _ctx.dataSource,
            "selection-mode": "single",
            "select-by-click": false,
            itemsExpr: _ctx.itemsExpr,
            selectionMode: "single",
            searchEnabled: true,
            "display-expr": _ctx.displayExpr,
            searchExpr: _ctx.displayExpr,
            "data-structure": _ctx.dataStructure,
            "parent-id-expr": _ctx.parentIdExpr,
            "key-expr": _ctx.keyExpr,
            fieldTemplate: "fieldTemplate",
            searchTimeout: "1000",
            "virtual-mode-enabled": true,
            onContentReady: _cache[0] || (_cache[0] = ($event: any) => ($event.component.selectItem(_ctx.selectedItem))),
            onItemSelectionChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.treeView_itemSelectionChanged($event))),
            onItemClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onTreeItemClick($event)))
          }, null, 8, ["data-source", "itemsExpr", "display-expr", "searchExpr", "data-structure", "parent-id-expr", "key-expr"])
        ]),
        fieldTemplate: _withCtx(({}) => [
          _createElementVNode("div", null, [
            _withDirectives(_createVNode(_component_DxTextBox, {
              placeholder: "Seçiniz....",
              "read-only": true,
              value: _ctx.selectedItemRef[_ctx.displayExpr]
            }, null, 8, ["value"]), [
              [_vShow, !_ctx.selectedItemRef[_ctx.displayExpr] || _ctx.selectedItemRef[_ctx.displayExpr].length == 0]
            ]),
            (_ctx.selectedItemRef[_ctx.displayExpr] && _ctx.selectedItemRef[_ctx.displayExpr].length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.selectedItemRef[_ctx.displayExpr]), 1),
                  _createElementVNode("span", {
                    class: "dx-icon dx-icon-clear",
                    style: {"right":"0"},
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectedItemRef = {}))
                  })
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["value", "opened", "data-source", "display-expr"]))
    : _createCommentVNode("", true)
}