
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import FaturaSatirItemExtend from "@/modules/Fatura/models/FaturaSatirItemExtend";
import NButton from "@/components/Button/NButton.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NTag from "@/components/Tag/NTag.vue";
import NTagList from "@/components/Tag/NTagList.vue";
import {alert, confirm} from "devextreme/ui/dialog";
import {useStokHareketService} from "@/api/services/StokHareketService";
import {useFaturaService} from "@/api/services/FaturaService";

export default defineComponent({
  components: {NTagList, NTag, NFormField, NButton, NModalDialog},
  props: {
    modelId: {
      type: String,

    },
    show: {
      type: Boolean,
      default: false,
    },
    satirItem: {
      type: Object
    },
    hareketTip : {
      type:Number ,
      default : 0 ,
    }
  },
  setup(props, {emit}) {

    const localState = reactive({
      model: computed(() => props.satirItem as any),
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val),
      }),
      modelIdRef: computed(() => props.modelId),
      seri: ref(''),
      hizliSeriDialogInput: ref(),
    });
    setTimeout(() => {
      localState.hizliSeriDialogInput?.instance?.focus();
    }, 950)


   async function onEnterKey(e: any) {
      if (!localState.seri) {
        alert("Boş Seri Girdiniz", "Seri Hatası");
        return;
      }

      if (localState.seri && localState.seri.trim().length == 0
          && localState.seri == '') {
        alert("Boş Seri Girdiniz", "Seri Hatası");
        return;
      }


      if (localState.model && localState.model.miktar && localState.model.seriler) {


        const bulunan = localState.model.seriler.find((a:any) => a == localState.seri.trim());
        if (bulunan) {
          alert(bulunan + " - Bu Seriden Daha Önce Girilmiş ", "Seri Hatası");
          return;
        }

        const seriler = localState.seri.split(',');

        if (seriler && seriler.length > 0) {

          await useStokHareketService().getSeriSorgula(localState.model.stokId as string , localState.seri as string , props.hareketTip)
          seriler.forEach((a) => localState.model!.seriler!.push(a.trim()));

        } else {


          localState.model.seriler.push(localState.seri.trim());
        }
        if (localState.model.miktar < localState.model.seriler.length) {
          localState.model.miktar = localState.model.seriler.length;
        }

      } else {
        localState.model.seriler = [];
        localState.model.seriler.push(localState.seri.trim());
      }


      e?.element?.querySelector('.dx-texteditor-input').select()
    }

    async function seriSil() {
      if (await confirm("Emin Misiniz ?", "Seri Silme")) {
        localState.model.seriler = [];
      }
    }

    async function guncelle() {
      await useFaturaService().postSeriUpdate(localState.model.seriler as string[], localState.model.id as string,);
      localState.showRef = false;

    }


    return {
      ...toRefs(localState),
      seriSil,
      onEnterKey,
      guncelle
    };
  }
});
