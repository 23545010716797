
import {computed, defineComponent, onActivated, onMounted, reactive, ref, toRefs, watch} from "vue";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import {useXmlImporterConfigService} from "@/api/services/XmlImporterConfigService";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import {useXmlImporterService} from "@/api/services/XmlImporterService";
import {XmlImporterConfigEntity} from "@/api/models/XmlImporterConfigEntity";
import CustomStore from "devextreme/data/custom_store";
import {useStokDataSourceService} from "@/api/services/StokDataSourceService";
import NGrid from "@/components/Grid/NGrid.vue";
import NDxStateStoring from "@/devextreme/NDxStateStoring.vue";
import NButton from "@/components/Button/NButton.vue";
import StokMasterDetailRemote from "@/modules/Stok/components/StokMasterDetailRemote.vue";
import StokSettingsButton from "@/modules/Stok/components/StokSettingsButton.vue";
import {DevExtremeUtils} from "@/devextreme/DevExtremeUtils";
import dxDataGrid from "devextreme/ui/data_grid";
import dxTreeList from "devextreme/ui/tree_list";
import {LoadOptions} from "devextreme/data";
import {StokSimpleView} from "@/api/models/StokSimpleView";
import {XmlImporterDataEntity} from "@/api/models/XmlImporterDataEntity";
import NTag from "@/components/Tag/NTag.vue";
import StokFormDialog from "@/modules/Stok/dialogs/StokFormDialog.vue";
import {StokFlatView} from "@/api/models/StokFlatView";

export default defineComponent({
    components: {StokFormDialog, NTag, NButton, NDxStateStoring, NGrid},
    props: {
        searchText: {
            type: String
        },
        isSingleSelect: { // Stok aktarımında, ana dialog kapanır
            type: Boolean,
            default: false
        }
    },
    setup(props, {emit}) {

        const localState = reactive({
            list: ref([]),
            store: ref(),
            configs: ref([] as XmlImporterConfigEntity[]),
            dataSource: ref(new DataSource({})),
            xmlStokListPageDataGrid:ref(),
            selectedRows: ref([] as XmlImporterDataEntity[]),
            searchTextRef:ref(),
            isLoading:ref(false),
            selecting:ref(false),
            selectionDataSourceOptions: ref({} as LoadOptions),
            showActionTray:ref(false),
            selectedData:ref(),
            selectedDataId:ref(),
            showStokForm:ref(false),
            xmlStokView:ref(),
            selectedStokId:ref(),
            selectedConfig: ref({} as XmlImporterConfigEntity)


        });

        let searchTimeout = -1;
        watch(() => localState.searchTextRef, () => {
            search()
        });

        watch(()=> localState.showStokForm,(val)=>{

            if (!val){
                refresh();
            }
        })

        function search() {
            if (searchTimeout && searchTimeout > 0) {
                clearTimeout(searchTimeout);
            }
            searchTimeout = setTimeout(() => {
                applySearch()

                clearTimeout(searchTimeout);
                searchTimeout = -1;
            }, 500);
        }

        function applySearch() {
            (localState.xmlStokListPageDataGrid?.instance as dxDataGrid)?.filter([
                ["xmlView.ad", "contains", localState.searchTextRef],
                "or",
                ["xmlView.kod", "contains", localState.searchTextRef],
                "or",
                ["xmlView.barkod", "=", localState.searchTextRef],
                "or",
                ["xmlView.birimler.0.barkod", "=", localState.searchTextRef],
                "or",
                ["xmlView.birimler.1.barkod", "=", localState.searchTextRef],
                "or",
                ["xmlView.birimler.2.barkod", "=", localState.searchTextRef],
            ]);
        }

        async function load() {
            localState.configs = await useXmlImporterConfigService().getGetirTumu();
            //localState.list = await useXmlImporterService().getGetDataAll();
            if (props.searchText && props.searchText?.length > 0) {
                localState.searchTextRef = props.searchText
                applySearch()
            }

            const storeOptions = {
                key: "id",
                load: (options: LoadOptions) => {
                    if (options.take == null && options.skip == null) {
                        localState.selectionDataSourceOptions = options;

                        //console.log("options by select", options)
                    }else{

                        const cols = (localState.xmlStokListPageDataGrid?.instance as dxDataGrid)?.getVisibleColumns().filter(a => a.dataField).map(a => a.dataField) as string[]
                        options.select = cols;
                    }

                    if (localState.selecting){
                        options.select = [];
                    }
                    //console.log(options)
                    //console.log("LOAD DATASOuRCE",options)
                    return useXmlImporterService().postDataSource({data: JSON.stringify(options)} as any);
                },
                onLoading: () => {
                    localState.isLoading = true;
                },
                onLoaded: (data:any) => {
                    if(localState.selecting){
                        localState.selecting = false;
                        selectedRows(data.data)
                    }
                    localState.isLoading = false;
                },
                byKey: (id: any) => {
                    //console.log("byKey.id",id)
                    // const rowIndex =  (localState.stokListServerPageDataGrid.instance as dxDataGrid).getRowIndexByKey(id);
                    // if (rowIndex >= 0)
                    //   return (localState.stokListServerPageDataGrid.instance as dxDataGrid).getRowElement(rowIndex)
                }
            } as any;

            localState.store = new CustomStore(storeOptions);

            const options = {
                store: localState.store,
                key: "id",
                reshapeOnPush: true
            };



            localState.dataSource = new DataSource(options);


        }

        onMounted(() => {
            load();
        })

        onActivated(async () => {
            //await localState.dataGridStore.load(localState.depolar);
                DevExtremeUtils.updateScroll(localState.xmlStokListPageDataGrid);
        });

        async function loadState() {
            return await DevExtremeUtils.loadState(localState.xmlStokListPageDataGrid?.instance);
        }

        function selectedRows(rows:any[]) {
            localState.selectedRows = rows;
            localState.showActionTray = localState.selectedRows.length > 0;
            if (localState.selectedRows && localState.selectedRows.length == 1) {
                localState.selectedData = localState.selectedRows[0];
                localState.selectedDataId = localState.selectedData.id;
            } else {
                localState.selectedData = {};
                localState.selectedDataId = "";
            }

            localState.selecting = false;
        }


        function clearFilter() {

            localState.searchTextRef = "";
            (localState.xmlStokListPageDataGrid?.instance as dxTreeList)?.clearFilter();
        }
        function refresh() {
            localState.dataSource.reload();
        }

        function mouseEnter(e: MouseEvent, resim: string) {

            const img = document.createElement("img");
            img.src = resim;

            const div = document.createElement("div");
            div.append(img);

            getPreviewContainer().append(div);
        }

        function mouseOut(e: MouseEvent) {

            getPreviewContainer().innerHTML = "";
        }
        function getPreviewContainer() {
            const container = document.getElementById("previewImage") as HTMLDivElement;
            return container;
        }

        async function stokAktarDialog(data:any) {
            localState.selectedDataId = data.id;
            localState.selectedStokId = data.stokId;
            localState.selectedConfig = localState.configs?.find((a:any) => a.id == data.configId) as XmlImporterConfigEntity
            localState.xmlStokView = await useXmlImporterService().postStokView(data.xmlView, localState.selectedConfig.id as string);
            localState.showStokForm = true;


        }

        function stokEntityAddedFromXml(stok:StokFlatView) {
            emit('stokEntityAddedFromXml', stok, props.isSingleSelect)
        }


        return {
            ...toRefs(localState),
            load,
            loadState,
            clearFilter,
            refresh,
            mouseEnter,
            mouseOut,
            stokAktarDialog,
            stokEntityAddedFromXml
        };
    }
});

