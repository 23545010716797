
export enum KaynakHedefTipi{
Stok=0,
Kategori=1
}

export const KaynakHedefTipiList = [
{key:'Stok', value:0, description:'Stok'},
{key:'Kategori', value:1, description:'Kategori'}
]
