
import {defineComponent, reactive, toRefs} from "vue";
import NButton from "@/components/Button/NButton.vue";
import {useRouter} from "vue-router";
import {useSharedStore} from "@/store";

export default defineComponent({
  components: {NButton},
  setup() {
    const localState = reactive({
      router: useRouter()
    })

    async function goto() {

      await localState.router.push({
        name: "HizliSatisLogin",
        params: {
          firm: useSharedStore().firm.id,
          period: useSharedStore().firm.period
        },
        path:'/hizliSatis'
      });
    }

    function isActive() {

        return false;
    }

    return {
      ...toRefs(localState),
      goto,
        isActive
    }
  }
})
