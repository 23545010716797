
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import {KvkkTipList} from "@/api/models/KvkkTip";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";

export default defineComponent({
  components: {NSelectBox},
  props:['modelValue'],
  setup(props) {
    const localState = reactive({
      model:computed(()=> props.modelValue),
      kvkkListRef: ref(KvkkTipList),
    })
    return {
      ...toRefs(localState)
    }
  }
})
