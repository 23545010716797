import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, Transition as _Transition, mergeProps as _mergeProps, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "n-modal-header"
}
const _hoisted_2 = {
  key: 0,
  class: "n-modal-title"
}
const _hoisted_3 = {
  key: 1,
  class: "n-modal-header-content"
}
const _hoisted_4 = { style: {"display":"flex","right":"26px","cursor":"pointer","align-items":"center","justify-content":"space-between","gap":"12px"} }
const _hoisted_5 = {
  key: 1,
  class: "n-modal-tabs"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 2,
  class: "n-modal-content"
}
const _hoisted_9 = {
  key: 0,
  class: "n-modal-footer-left"
}
const _hoisted_10 = {
  key: 1,
  class: "n-modal-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NGrid = _resolveComponent("NGrid")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modal-container" }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _mergeProps(_ctx.$attrs, {
          class: "n-modal-mask",
          style: { cursor: _ctx.closableMask ? 'pointer' : 'default' },
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.maskClick && _ctx.maskClick(...args)), ["self"]))
        }), [
          _createVNode(_Transition, { name: "drop-top" }, {
            default: _withCtx(() => [
              _withDirectives(_createElementVNode("div", {
                class: "n-modal-body",
                style: _normalizeStyle({ width: _ctx.width })
              }, [
                _renderSlot(_ctx.$slots, "image"),
                _renderSlot(_ctx.$slots, "default"),
                (_ctx.title || _ctx.$slots.header)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      (_ctx.title)
                        ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.title), 1))
                        : _createCommentVNode("", true),
                      (_ctx.$slots.header)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _renderSlot(_ctx.$slots, "header")
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", null, [
                          _renderSlot(_ctx.$slots, "toolbar")
                        ]),
                        (_ctx.showClose)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
                            }, [
                              _createVNode(_component_NIcon, { type: "x" })
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.tabs && _ctx.tabs.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs.filter(a => a.hide !== false), (tab, i) => {
                        return (_openBlock(), _createElementBlock("button", {
                          class: _normalizeClass([
                  'n-modal-tab',
                  _ctx.selectedTab === i && 'n-modal-tab--selected',
                  tab.disabled && 'n-modal-tab--disabled'
                ]),
                          key: i,
                          onClick: _withModifiers(($event: any) => (tab.disabled ? {} : _ctx.selectTab(i)), ["stop"])
                        }, [
                          (tab.icon)
                            ? (_openBlock(), _createBlock(_component_NIcon, {
                                key: 0,
                                style: {"height":"18px","margin-right":"8px"},
                                size: "18px",
                                type: tab.icon,
                                title: tab.label
                              }, null, 8, ["type", "title"]))
                            : _createCommentVNode("", true),
                          (tab.showLabel !== false)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(tab.label), 1))
                            : _createCommentVNode("", true)
                        ], 10, _hoisted_6))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.$slots.body)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _renderSlot(_ctx.$slots, "body")
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", null, [
                  _createVNode(_component_NGrid, { cols: "1fr auto" }, {
                    default: _withCtx(() => [
                      (_ctx.$slots.actionsLeft)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            _renderSlot(_ctx.$slots, "actionsLeft")
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.$slots.actions)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _renderSlot(_ctx.$slots, "actions")
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 3
                  })
                ])
              ], 4), [
                [_vShow, _ctx.modelValue]
              ])
            ]),
            _: 3
          })
        ], 16), [
          [_vShow, _ctx.modelValue]
        ])
      ]),
      _: 3
    })
  ]))
}