import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a6be15a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  id: "application-layout",
  class: "layout"
}
const _hoisted_2 = { class: "sidebar-container" }
const _hoisted_3 = { class: "toolbar" }
const _hoisted_4 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EnterPinForm = _resolveComponent("EnterPinForm")!
  const _component_SidebarPanel = _resolveComponent("SidebarPanel")!
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_HizliSatisButton = _resolveComponent("HizliSatisButton")!
  const _component_KurToolbarItem = _resolveComponent("KurToolbarItem")!
  const _component_NotificationToolbarItem = _resolveComponent("NotificationToolbarItem")!
  const _component_NAvatar = _resolveComponent("NAvatar")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdownMenu = _resolveComponent("NDropdownMenu")!
  const _component_NDropdown = _resolveComponent("NDropdown")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_ViewRenderer = _resolveComponent("ViewRenderer")!

  return (!_ctx.pinStore.isListening)
    ? (_openBlock(), _createBlock(_component_EnterPinForm, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_SidebarPanel)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Tabs),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_NGrid, {
              cols: "auto auto auto auto",
              gap: "8px"
            }, {
              default: _withCtx(() => [
                false
                  ? (_openBlock(), _createBlock(_component_HizliSatisButton, { key: 0 }))
                  : _createCommentVNode("", true),
                _createVNode(_component_KurToolbarItem),
                _createVNode(_component_NotificationToolbarItem),
                _createVNode(_component_NDropdown, {
                  style: {"height":"50px","margin-top":"3px"},
                  placement: "bottom-right",
                  clickable: true
                }, {
                  menu: _withCtx(() => [
                    _createVNode(_component_NDropdownMenu, null, {
                      default: _withCtx(() => [
                        (_ctx.user.name)
                          ? (_openBlock(), _createBlock(_component_NDropdownItem, {
                              key: 0,
                              padding: "11px 14px"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", null, _toDisplayString(_ctx.user.name), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_NDropdownItem, {
                          type: "danger",
                          icon: "log-out",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Çıkış")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_NAvatar, {
                      class: _normalizeClass(_ctx.signalStatus + ' signal'),
                      src: _ctx.user.photo,
                      text: _ctx.user.name,
                      square: ""
                    }, null, 8, ["class", "src", "text"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", {
          onMouseover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mouseOver && _ctx.mouseOver(...args))),
          id: "view-container",
          class: _normalizeClass({ 'view-container': true, 'round-corner': !_ctx.tabs.length })
        }, [
          _createVNode(_component_ViewRenderer)
        ], 34)
      ]))
}