
export enum BirimFiyatTipi{
SabitFiyat=0,
SatisTutarEkle=1,
SatisOranEkle=2,
AlisTutarEkle=3,
AlisOranEkle=4,
ETicaretTutarEkle=5,
ETicaretOranEkle=6
}

export const BirimFiyatTipiList = [
{key:'SabitFiyat', value:0, description:'Sabit Fiyat'},
{key:'SatisTutarEkle', value:1, description:'Tutar Ekle (Satış Fiyatına)'},
{key:'SatisOranEkle', value:2, description:'Oran Ekle (Satış Fiyatına)'},
{key:'AlisTutarEkle', value:3, description:'Tutar Ekle (Alış Fiyatına)'},
{key:'AlisOranEkle', value:4, description:'Oran Ekle (Alış Fiyatına)'},
{key:'ETicaretTutarEkle', value:5, description:'Tutar Ekle (E-Ticaret Fiyatına)'},
{key:'ETicaretOranEkle', value:6, description:'Oran Ekle (E-Ticaret Fiyatına)'}
]
