import {SiparisTip} from '../models/SiparisTip'
import {SiparisHareketTip} from '../models/SiparisHareketTip'
import {TeslimatAdresItem} from '../models/TeslimatAdresItem'
import {SiparisAdresItem} from '../models/SiparisAdresItem'
import {SmsMailKargoGonderItem} from '../models/SmsMailKargoGonderItem'
import {DocumentItem} from '../models/DocumentItem'
import {SiparisSatirItem} from '../models/SiparisSatirItem'
import {FaturaBilgiItem} from '../models/FaturaBilgiItem'
import {Doviz} from '../models/Doviz'

export class SiparisView{
	siparisTip?:SiparisTip;
	id?:string;
	tip?:SiparisHareketTip;
	subeId?:string;
	aciklama?:string;
	tarih?:string;
	vade?:string;
	projeId?:string;
	cariHesabi?:string;
	cariIsi?:string;
	evrakNo?:string;
	referansNo?:string;
	fiyatGrupId?:string;
	cariId?:string;
	cariUnvan?:string;
	cariAd?:string;
	cariSoyad?:string;
	cariVknTc?:string;
	faturaUnvan?:string;
	kargoFirmasi?:string;
	kargoTakipNo?:string;
	kargoBarkod?:string;
	vergiNo?:string;
	vergiDairesi?:string;
	ad?:string;
	soyad?:string;
	kargolandi?:boolean;
	smsGonderildi?:boolean;
	referansId?:string;
	odemeSekli?:string;
	nakliye?:string;
	teslimatBilgileri?:string;
	faturaAdres?:TeslimatAdresItem;
	teslimatlar?:SiparisAdresItem[];
	gonder?:SmsMailKargoGonderItem;
	dokumanlar?:DocumentItem[];
	satirlar?:SiparisSatirItem[];
	siparisler?:FaturaBilgiItem[];
	teklifler?:FaturaBilgiItem[];
	faturalar?:FaturaBilgiItem[];
	irsaliyeler?:FaturaBilgiItem[];
	faturaMaliyeti?:number;
	cariIslemDoviz?:Doviz;
	cariIslemKur?:number;
	altIskontolar?:number[];
	tutarIskonto?:number;
	faturaKalan?:number;
	not?:string;
	isKasaOdeme?:boolean;
	kasaTutar?:number;
	kasaId?:string;
	isPosOdeme?:boolean;
	posTutar?:number;
	posId?:string;
	isBankaOdeme?:boolean;
	bankaTutar?:number;
	bankaId?:string;
}
