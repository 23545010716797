import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';


export const useAdresService = defineStore({
id: 'AdresService',
actions: {


	async getUlkeler()
	{
		const { request, response } = useApi(`/api/adres/Ulkeler`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getFlatUlkeler()
	{
		const { request, response } = useApi(`/api/adres/FlatUlkeler`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
