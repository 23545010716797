
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import {useB2BClientAccountService} from "@/api/services/B2BClientAccountService";
import {usePopupService} from "@/services/popupService";
import {B2BCariLoginView} from "@/api/models/B2BCariLoginView";
import NAlert from "@/components/Alert/NAlert.vue";
import ShowPasswordComponent from "@/modules/Ortak/components/ShowPasswordComponent.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";

export default defineComponent({
  components: {
    ShowPasswordComponent,
    NAlert,
    NLoadingPanel
  },
  props:['modelValue'],
  setup(props) {
    const localState = reactive({
      model:computed(()=> props.modelValue),
      modelB2BLogin: ref({userName: '', password: '', isAktif: true} as B2BCariLoginView),
      b2bKullaniciAdi: ref(''),
      b2bSifre: ref(''),
      b2bIsAktif: ref(true),
    })

    async function getB2bLoginBilgileri() {
      if (localState.model.id && localState.model.id.length > 0) {
        localState.modelB2BLogin = await useB2BClientAccountService().getGetUser(localState.model.id);
      }
    }

    async function b2bLoad() {
      await getB2bLoginBilgileri();
      if (!localState.modelB2BLogin.userName || localState.modelB2BLogin.userName?.length == 0) {
        localState.modelB2BLogin.userName = localState.model?.getAdresler![0].mail ?? localState.model?.getAdresler![0].mail2;
      }

      if (!localState.modelB2BLogin.adSoyad || localState.modelB2BLogin.adSoyad?.length == 0) {
        localState.modelB2BLogin.adSoyad = localState.model?.unvan ?? localState.model?.getUnvan;
      }

    }

    async function b2bSave() {
      await useB2BClientAccountService().postUpdateUser(localState.modelB2BLogin, localState.model.id as string);
      localState.modelB2BLogin.id = "11111111111";
      usePopupService().pushSuccessNotification({
        title: "Başarılı",
        body: "B2B Bilgileri Kaydedildi."
      });
    }


    return {
      ...toRefs(localState),
      b2bSave,
      b2bLoad,
    }
  }
})
