import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {StokHizliSatisUpdateView} from '../models/StokHizliSatisUpdateView'
import {PazaryeriNameType} from '../models/PazaryeriNameType'
import {FilterView} from '../models/FilterView'
import {StokEntity} from '../models/StokEntity'
import {IdName} from '../models/IdName'
import {Ozellik} from '../models/Ozellik'
import {StokBirimItem} from '../models/StokBirimItem'
import {Doviz} from '../models/Doviz'
import {SubeFiyatItem} from '../models/SubeFiyatItem'
import {StokUreticiItem} from '../models/StokUreticiItem'
import {N11PazaryeriItem} from '../models/N11PazaryeriItem'
import {CicekSepetiPazaryeriItem} from '../models/CicekSepetiPazaryeriItem'
import {TrendyolPazaryeriItem} from '../models/TrendyolPazaryeriItem'
import {HepsiBuradaPazaryeriItem} from '../models/HepsiBuradaPazaryeriItem'
import {HepsiBuradaListingPazaryeriItem} from '../models/HepsiBuradaListingPazaryeriItem'
import {PrestaPazaryeriItem} from '../models/PrestaPazaryeriItem'
import {EpttAvmPazaryeriItem} from '../models/EpttAvmPazaryeriItem'
import {PazaramaPazaryeriItem} from '../models/PazaramaPazaryeriItem'
import {StokViewItem} from '../models/StokViewItem'
import {StokUyumluluktem} from '../models/StokUyumluluktem'
import {OzellikVaryant} from '../models/OzellikVaryant'
import {StokTipOzellikler} from '../models/StokTipOzellikler'
import {StokTip} from '../models/StokTip'
import {StokLanguageItem} from '../models/StokLanguageItem'
import {PluKod} from '../models/PluKod'
import {StokView} from '../models/StokView'
import {StokAktarView} from '../models/StokAktarView'
import {FaturaStokKontrolView} from '../models/FaturaStokKontrolView'
import {StokItemView} from '../models/StokItemView'
import {ActionStatusType} from '../models/ActionStatusType'
import {ActionType} from '../models/ActionType'
import {StokKategoriEkleView} from '../models/StokKategoriEkleView'
import {StokGrupItem} from '../models/StokGrupItem'
import {StokGrupView} from '../models/StokGrupView'
import {StokFiyatGuncelleView} from '../models/StokFiyatGuncelleView'
import {PazaryeriFiyatTipi} from '../models/PazaryeriFiyatTipi'
import {StokPazaryeriFiyatGuncelleView} from '../models/StokPazaryeriFiyatGuncelleView'
import {StokFiyatPazaryeriItem} from '../models/StokFiyatPazaryeriItem'
import {StokFiyatGuncelleImporterView} from '../models/StokFiyatGuncelleImporterView'
import {StokResimGuncelleImporterView} from '../models/StokResimGuncelleImporterView'
import {StokBakiyeRequest} from '../models/StokBakiyeRequest'

export const useStokService = defineStore({
id: 'StokService',
actions: {


	async getDefault()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/Default/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getDefaultClone(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/DefaultClone/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getStokNames()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/StokNames/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getStokHizliSatisNames()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/StokHizliSatisNames/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postStokHizliSatisGuncelle(stokHizliSatisUpdateView:StokHizliSatisUpdateView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/StokHizliSatisGuncelle/${shared.firm.id}`,{ method:'POST', data:stokHizliSatisUpdateView });
		await request();
		return response.value;
	},

	async getFlatView(id:string,itemId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/FlatView/${shared.firm.id}/${id}/${itemId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getMobileStokFlatList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/MobileStokFlatList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getStokFlatList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/StokFlatList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postStokKampanyaFlatList(liste:string[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/StokKampanyaFlatList/${shared.firm.id}`,{ method:'POST', data: liste });
		await request();
		return response.value;
	},

	async getList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/List/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getPazaryeriStatusList(pazaryeriName:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/PazaryeriStatusList/${shared.firm.id}/${pazaryeriName}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postListFilter(filterView:FilterView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/ListFilter/${shared.firm.id}`,{ method:'POST', data:filterView });
		await request();
		return response.value;
	},

	async getGetir(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/Getir/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetirView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/GetirView/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postConvertFlatFromEntities(liste:StokEntity[])
	{
		const { request, response } = useApi(`/api/stok/ConvertFlatFromEntities`,{ method:'POST', data:liste });
		await request();
		return response.value;
	},

	async postFlatViewsFromStokIds(liste:string[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/FlatViewsFromStokIds/${shared.firm.id}`,{ method:'POST', data: liste });
		await request();
		return response.value;
	},

	async getBirimItem(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/BirimItem/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getMenseiList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/MenseiList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postEkle(stokView:StokView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/Ekle/${shared.firm.id}`,{ method:'POST', data:stokView });
		await request();
		return response.value;
	},

	async postAktar(stokAktarView:StokAktarView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/Aktar/${shared.firm.id}`,{ method:'POST', data:stokAktarView });
		await request();
		return response.value;
	},

	async putGuncelle(stokView:StokView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/Guncelle/${shared.firm.id}`,{ method:'PUT', data:stokView });
		await request();
		return response.value;
	},

	async putStokKontrol(faturaStokKontrolView:FaturaStokKontrolView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/StokKontrol/${shared.firm.id}`,{ method:'PUT', data:faturaStokKontrolView });
		await request();
		return response.value;
	},

	async postVaryantEkle(stokItemView:StokItemView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/VaryantEkle/${shared.firm.id}`,{ method:'POST', data:stokItemView });
		await request();
		return response.value;
	},

	async deleteVaryantSil(stokId:string,itemId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/VaryantSil/${shared.firm.id}/${shared.firm.period}/${stokId}/${itemId}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async putPazaryeriDurumGuncelle(stokId:string,status:number,pazaryeri:number,index:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/PazaryeriDurumGuncelle/${shared.firm.id}/${stokId}/${status}/${pazaryeri}/${index}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async putPazaryeriDurumGuncelleTumu(status:number,pazaryeri:number,index:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/PazaryeriDurumGuncelleTumu/${shared.firm.id}/${status}/${pazaryeri}/${index}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async putPazaryeriGonder(stokId:string,actionType:number,pazaryeri:number,index:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/PazaryeriGonder/${shared.firm.id}/${stokId}/${pazaryeri}/${actionType}/${index}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/Sil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async putSecilenlereKategoriEkle(stokKategoriEkleView:StokKategoriEkleView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/SecilenlereKategoriEkle/${shared.firm.id}`,{ method:'PUT', data:stokKategoriEkleView });
		await request();
		return response.value;
	},

	async postStokGrubuEkle(stokGrupView:StokGrupView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/StokGrubuEkle/${shared.firm.id}`,{ method:'POST', data:stokGrupView });
		await request();
		return response.value;
	},

	async getStokMiktarlar(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/StokMiktarlar/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getMiktarOzetleriStok(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/MiktarOzetleriStok/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getMiktarOzetleriItem(id:string,itemId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/MiktarOzetleriItem/${shared.firm.id}/${id}/${itemId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async putStokFiyatGuncelleDefault(liste:StokEntity[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/StokFiyatGuncelleDefault/${shared.firm.id}`,{ method:'PUT', data:liste });
		await request();
		return response.value;
	},

	async putStokSatisFiyatGuncelle(id:string,fiyat:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/StokSatisFiyatGuncelle/${shared.firm.id}/${id}/${fiyat}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async putStokFiyatGuncelle(liste:StokFiyatGuncelleView[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/StokFiyatGuncelle/${shared.firm.id}`,{ method:'PUT', data:liste });
		await request();
		return response.value;
	},

	async putStokFiyatPazaryeriGuncelleDataSource(stokPazaryeriFiyatGuncelleView:StokPazaryeriFiyatGuncelleView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/StokFiyatPazaryeriGuncelleDataSource/${shared.firm.id}`,{ method:'PUT', data:stokPazaryeriFiyatGuncelleView });
		await request();
		return response.value;
	},

	async putStokFiyatGuncelleDataSource(stokFiyatGuncelleView:StokFiyatGuncelleView,isAlisFiyatina:boolean)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/StokFiyatGuncelleDataSource/${shared.firm.id}/${isAlisFiyatina}`,{ method:'PUT', data:stokFiyatGuncelleView });
		await request();
		return response.value;
	},

	async putStokFiyatGuncelleImporter(stokFiyatGuncelleImporterView:StokFiyatGuncelleImporterView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/StokFiyatGuncelleImporter/${shared.firm.id}`,{ method:'PUT', data:stokFiyatGuncelleImporterView });
		await request();
		return response.value;
	},

	async putStokResimGuncelleImporter(stokResimGuncelleImporterView:StokResimGuncelleImporterView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/StokResimGuncelleImporter/${shared.firm.id}`,{ method:'PUT', data:stokResimGuncelleImporterView });
		await request();
		return response.value;
	},

	async getGrup1()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/Grup1/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGrup2(grup1:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/Grup2/${shared.firm.id}/${grup1}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGrup3(grup1:string,grup2:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/Grup3/${shared.firm.id}/${grup1}/${grup2}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGrup4(grup1:string,grup2:string,grup3:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/Grup4/${shared.firm.id}/${grup1}/${grup2}/${grup3}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postPazaryeriTekrarGonder()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/PazaryeriTekrarGonder/${shared.firm.id}`,{ method:'POST' });
		await request();
		return response.value;
	},

	async postStokBakiyesi(liste:StokBakiyeRequest[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/StokBakiyesi/${shared.firm.id}`,{ method:'POST', data:liste });
		await request();
		return response.value;
	},

	async getSimpleView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/SimpleView/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getYeniStokKategoriNo(kategoriId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stok/YeniStokKategoriNo/${shared.firm.id}/${kategoriId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getTestSignal()
	{
		const { request, response } = useApi(`/api/stok/TestSignal`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
