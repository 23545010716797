
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import { useCariService } from "@/api/services/CariService";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";
import PlasiyerSelectBox from "@/modules/Plasiyer/components/PlasiyerSelectBox.vue";
import NTagList from "@/components/Tag/NTagList.vue";
import FiyatGruplariSelectBox from "@/modules/Ortak/components/FiyatGruplariSelectBox.vue";
import BirimFiyatGrupTanimComp from "@/modules/Stok/components/BirimFiyatGrupTanimComp.vue";
import CariGrupSelectBox from "@/modules/Cari/components/CariGrupSelectBox.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NInput from "@/components/Input/NInput.vue";
import { DovizList } from "@/api/models/Doviz";
import NSwitch from "@/components/Switch/NSwitch.vue";
import { DxTagBox } from "devextreme-vue";
import { Liste } from "@/api/models/Liste";
import { useListelerService } from "@/api/services/ListelerService";
import { CariEntity } from "@/api/models/CariEntity";
import NGrid from "@/components/Grid/NGrid.vue";
import SubeSelectBox from "@/modules/Sube/components/SubeSelectBox.vue";
import { IdName } from "@/api/models/IdName";
import { useCachedSubeDepoService } from "@/modules/Sube/services/useCachedSubeDepoService";

export default defineComponent({
  components: {
    NGrid,
    NSwitch,
    NInput,
    NFormField,
    CariGrupSelectBox,
    DxTagBox,
    BirimFiyatGrupTanimComp,
    FiyatGruplariSelectBox, PlasiyerSelectBox, NSelectBox, NLoadingPanel, NFormGrid
  },
  props: ["modelValue"],
  setup(props) {
    const localState = reactive({
      model: computed(() => props.modelValue as CariEntity),
      grup1Ref: ref([] as any[]),
      grup2Ref: ref([] as any[]),
      grup3Ref: ref([] as any[]),
      grup4Ref: ref([] as any[]),
      dovizListRef: ref(DovizList),
      hesapListesi: [] as string[],
      subeler: ref([] as IdName[])


    });


    async function load() {

      localState.subeler = await useCachedSubeDepoService().getUserSubeList();


      await grup1ListeYukle();
      const result = await useListelerService().getCariHesapListesi();
      if (result && result.length > 0)
        localState.hesapListesi = result.map((a: any) => a.deger);



    }

    async function grup1ListeYukle() {
      localState.grup1Ref = await useCariService().getGrup1();
      if (localState.model.grup1 && localState.model.grup1.length > 0)
        grup2ListeYukle();

      if (localState.model.grup2 && localState.model.grup2.length > 0)
        grup3ListeYukle();

      if (localState.model.grup3 && localState.model.grup3.length > 0)
        grup4ListeYukle();

    }

    function onIsleriItemClick(e: any) {

      const text = e?.text;
      if (text && text.length > 0) {
        e.customItem = text;
      }

    }

    function onHesaplariItemClick(e: any) {

      const text = e?.text;
      if (text && text.length > 0) {
        e.customItem = text;
      }

    }

    async function grup2ListeYukle() {
      localState.grup2Ref = await useCariService().getGrup2(
        localState.model.grup1 as string
      );
    }

    async function grup3ListeYukle() {
      localState.grup3Ref = await useCariService().getGrup3(
        localState.model.grup1 as string,
        localState.model.grup2 as string
      );
    }

    async function grup4ListeYukle() {
      localState.grup4Ref = await useCariService().getGrup4(
        localState.model.grup1 as string,
        localState.model.grup2 as string,
        localState.model.grup3 as string
      );
    }

    return {
      ...toRefs(localState),
      load,
      grup1ListeYukle,
      grup2ListeYukle,
      grup3ListeYukle,
      grup4ListeYukle,
      onHesaplariItemClick,
      onIsleriItemClick
    };
  }
});
