import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a5b8920"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "n-empty"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["n-avatar", [(_ctx.squaredGroup || _ctx.square) && 'n-avatar--square']]),
    style: _normalizeStyle({
      'background-color': _ctx.color || '',
      width: _ctx.computedSize,
      height: _ctx.computedSize,
      'line-height': _ctx.computedSize
    })
  }, [
    (_ctx.src)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.src
        }, null, 8, _hoisted_1))
      : (_ctx.text)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.avatarText), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_NIcon, { type: "user" })
          ]))
  ], 6))
}