import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_StokForm_GenelBilgiler = _resolveComponent("StokForm_GenelBilgiler")!
  const _component_StokForm_Aciklamalar = _resolveComponent("StokForm_Aciklamalar")!
  const _component_OzellikForm = _resolveComponent("OzellikForm")!
  const _component_StokForm_Uyumluluklar = _resolveComponent("StokForm_Uyumluluklar")!
  const _component_StokForm_Birimler = _resolveComponent("StokForm_Birimler")!
  const _component_StokForm_Ureticiler = _resolveComponent("StokForm_Ureticiler")!
  const _component_StokForm_Devirler = _resolveComponent("StokForm_Devirler")!
  const _component_NCard = _resolveComponent("NCard")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    modelValue: _ctx.showRef,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showRef) = $event)),
    width: "700px",
    height: "600px",
    "close-on-esc": true
  }, {
    header: _withCtx(() => [
      _createTextVNode(" Hızlı Stok Girişi "),
      (_ctx.model.ad?.length > 0)
        ? (_openBlock(), _createBlock(_component_NTag, {
            key: 0,
            type: "info",
            filled: true
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.model.ad), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_NLoadingPanel, {
        title: "Stok Kartı Yükleniyor...",
        action: _ctx.load
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NCard, {
            modelValue: _ctx.selectedTab,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedTab) = $event)),
            tabs: _ctx.genelTabs
          }, {
            default: _withCtx(() => [
              _withDirectives(_createVNode(_component_StokForm_GenelBilgiler, {
                "server-url": _ctx.serverUrl,
                "onUpdate:serverUrl": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.serverUrl) = $event)),
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model) = $event)),
                "show-devir": true
              }, null, 8, ["server-url", "modelValue"]), [
                [_vShow, _ctx.selectedTab === 0]
              ]),
              _withDirectives(_createVNode(_component_StokForm_Aciklamalar, {
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model) = $event))
              }, null, 8, ["modelValue"]), [
                [_vShow, _ctx.selectedTab === 1]
              ]),
              _withDirectives(_createVNode(_component_OzellikForm, {
                ozellikler: _ctx.model.ozellikler,
                "is-stok": true
              }, null, 8, ["ozellikler"]), [
                [_vShow, _ctx.selectedTab=== 2]
              ]),
              _withDirectives(_createVNode(_component_StokForm_Uyumluluklar, {
                "stok-id": _ctx.model.id,
                model: _ctx.model.uyumluluklar
              }, null, 8, ["stok-id", "model"]), [
                [_vShow, _ctx.selectedTab=== 3]
              ]),
              (_ctx.selectedTab === 4)
                ? (_openBlock(), _createBlock(_component_StokForm_Birimler, {
                    key: 0,
                    modelValue: _ctx.model,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model) = $event))
                  }, null, 8, ["modelValue"]))
                : _createCommentVNode("", true),
              _withDirectives(_createVNode(_component_StokForm_Ureticiler, {
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model) = $event))
              }, null, 8, ["modelValue"]), [
                [_vShow, _ctx.selectedTab === 5]
              ]),
              (_ctx.selectedTab === 6)
                ? (_openBlock(), _createBlock(_component_StokForm_Devirler, {
                    key: 1,
                    modelValue: _ctx.model,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model) = $event))
                  }, null, 8, ["modelValue"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue", "tabs"])
        ]),
        _: 1
      }, 8, ["action"])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_NButton, {
        type: "warning",
        size: "small",
        icon: "x-circle",
        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showRef = false))
      }, {
        default: _withCtx(() => [
          _createTextVNode("İptal")
        ]),
        _: 1
      }),
      _createVNode(_component_NButton, {
        type: "success",
        size: "small",
        icon: "save",
        click: _ctx.saveAdd,
        title: "Kaydet"
      }, {
        default: _withCtx(() => [
          _createTextVNode("Kaydet")
        ]),
        _: 1
      }, 8, ["click"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}