import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NDropDownTreeView = _resolveComponent("NDropDownTreeView")!
  const _component_NRefresher = _resolveComponent("NRefresher")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_BirimlerSelectBox = _resolveComponent("BirimlerSelectBox")!
  const _component_NTag = _resolveComponent("NTag")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_NMultipleImageUploader = _resolveComponent("NMultipleImageUploader")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!

  return (_openBlock(), _createElementBlock("div", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), [
    _createVNode(_component_NFormGrid, {
      gap: "0px",
      cols: "1.2fr 1fr 0.78fr"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NFormField, {
          label: "Kategori",
          "grid-cols": "90px 1fr",
          span: "3"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NRefresher, { load: _ctx.load }, {
              default: _withCtx(() => [
                _createVNode(_component_NDropDownTreeView, {
                  "selected-item": _ctx.model.kategori,
                  "onUpdate:selectedItem": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.kategori) = $event)),
                  onSelectedChanged: _ctx.selectedChanged,
                  "data-source": _ctx.kategoriler,
                  "items-expr": "altKategoriler"
                }, null, 8, ["selected-item", "onSelectedChanged", "data-source"])
              ]),
              _: 1
            }, 8, ["load"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Stok Adı",
          "grid-cols": "90px 1fr",
          span: "3"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.model.ad,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.ad) = $event)),
              "is-big": true
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Barkod",
          "grid-cols": "90px 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.model.barkod,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.barkod) = $event)),
              title: "Programın oluşturması için boş bırakınız"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Stok Kodu",
          "grid-cols": "76px 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.model.kod,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.kod) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Birim",
          "grid-cols": "76px 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BirimlerSelectBox, {
              modelValue: _ctx.model.birim,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.birim) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Satış Fiyatı",
          "grid-cols": "90px 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NTag, {
              type: "info",
              style: {"justify-content":"left","line-height":"18px"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getSatisFiyati()) + " ₺", 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Satış Kdv",
          "grid-cols": "76px 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.model.satisKdv,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.satisKdv) = $event)),
              modelModifiers: { number: true },
              type: "number"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Desi",
          "grid-cols": "76px 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxNumberBox, {
              value: _ctx.model.desi,
              "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.desi) = $event)),
              type: "number"
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Kısa Açıklama",
          "grid-cols": "90px 1fr",
          span: "3"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.model.kisaAciklama,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.kisaAciklama) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Açıklama",
          "grid-cols": "90px 1fr",
          span: "3"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("textarea", {
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.aciklama) = $event)),
              rows: "2"
            }, null, 512), [
              [_vModelText, _ctx.model.aciklama]
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Resimler",
          "grid-cols": "90px 1fr",
          span: "3"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NMultipleImageUploader, {
              src: _ctx.model.resimler,
              "onUpdate:src": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.resimler) = $event)),
              "server-url": _ctx.serverUrl,
              width: 60,
              height: 60
            }, null, 8, ["src", "server-url"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 16))
}