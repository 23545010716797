import { NModuleRoute } from "@/models/route.model"
import { createPinia, defineStore } from "pinia"
import { UserDefaultView } from "@/api/models/UserDefaultView"

export const pinia = createPinia()

export const useSharedStore = defineStore({
  id: "Shared",
  state: () => ({
    firm: {
      logo: "",
      name: "NesilceSoft",
      id: "Test",
      period: "2022"
    },
    user: {} as UserDefaultView,
    navigation: [] as NModuleRoute[],
    loginMethod: ""
  }),
  actions: {
    registerRoute(route: NModuleRoute) {
      this.navigation.push(route)
      this.navigation = this.navigation.sort(
        (a: any, b: any) => a.order - b.order
      )
    },
    setUser(user: Partial<UserDefaultView>) {
      this.user = {
        ...this.user,
        ...user
      }
    },
    setFirm(routeParams: Record<string, string | string[]>) {
      this.firm.id = routeParams.firm as string
      this.firm.period = routeParams.period as string
    },
    setUserName(userName: string, remember: boolean) {
      if (!userName || userName === "") throw Error("Geçersiz Kullanıcı Adı!")
      this.user.userName = userName
      this.loginMethod = remember ? "local" : "session"
    }
  }
})
