import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"color":"#C00"}
}
const _hoisted_2 = {
  key: 0,
  style: {"color":"#C00"}
}
const _hoisted_3 = {
  key: 0,
  style: {"color":"#C00"}
}
const _hoisted_4 = {
  key: 0,
  style: {"color":"#C00"}
}
const _hoisted_5 = {
  key: 0,
  style: {"color":"#C00"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HepsiBuradaOzellikSelectBox = _resolveComponent("HepsiBuradaOzellikSelectBox")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!

  return (_openBlock(), _createBlock(_component_NLoadingPanel, {
    ref: "loadingPanel",
    action: _ctx.load,
    title: "HepsiBurada özellikleri yükleniyor..."
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (_ctx.attributes && _ctx.attributes.attributes)
          ? (_openBlock(), _createBlock(_component_NFormGrid, {
              key: 0,
              title: "HepsiBurada Katalog Özellikleri",
              cols: "1fr 1fr"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attributes.attributes?.filter(a => a.type == 'enum'), (attribute, i) => {
                  return (_openBlock(), _createBlock(_component_HepsiBuradaOzellikSelectBox, {
                    onChange: _ctx.hepsiBuradaToNesilce,
                    "selected-value": attribute.value,
                    "onUpdate:selectedValue": ($event: any) => ((attribute.value) = $event),
                    attribute: attribute,
                    "category-id": _ctx.kategoriId,
                    label: attribute.name,
                    key: i
                  }, null, 8, ["onChange", "selected-value", "onUpdate:selectedValue", "attribute", "category-id", "label"]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attributes.attributes.filter(a => a.type == 'string'), (attribute, i) => {
                  return (_openBlock(), _createBlock(_component_NFormField, {
                    label: attribute.name,
                    "grid-cols": "120px 1fr",
                    key: i
                  }, {
                    label: _withCtx(() => [
                      (attribute.mandatory)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
                        : _createCommentVNode("", true)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_NInput)
                    ]),
                    _: 2
                  }, 1032, ["label"]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attributes.attributes.filter(a =>  a.type === 'string'), (attribute, i) => {
                  return (_openBlock(), _createBlock(_component_NFormField, {
                    title: attribute.id,
                    label: attribute.name,
                    "grid-cols": "120px 1fr",
                    key: i
                  }, {
                    label: _withCtx(() => [
                      (attribute.mandatory)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
                        : _createCommentVNode("", true)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_NInput, {
                        type: "text",
                        modelValue: attribute.value,
                        "onUpdate:modelValue": ($event: any) => ((attribute.value) = $event),
                        onChange: _ctx.hepsiBuradaToNesilce
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                    ]),
                    _: 2
                  }, 1032, ["title", "label"]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attributes.attributes.filter(a => a.type === 'integer'), (attribute, i) => {
                  return (_openBlock(), _createBlock(_component_NFormField, {
                    title: attribute.id,
                    label: attribute.name,
                    "grid-cols": "120px 1fr",
                    key: i
                  }, {
                    label: _withCtx(() => [
                      (attribute.mandatory)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
                        : _createCommentVNode("", true)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_NInput, {
                        type: "number",
                        modelValue: attribute.value,
                        "onUpdate:modelValue": ($event: any) => ((attribute.value) = $event),
                        onChange: _ctx.hepsiBuradaToNesilce
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                    ]),
                    _: 2
                  }, 1032, ["title", "label"]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.attributes && _ctx.attributes.variantAttributes)
          ? (_openBlock(), _createBlock(_component_NFormGrid, {
              key: 1,
              title: "HepsiBurada Katalog Varyant Özellikleri",
              cols: "1fr 1fr"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attributes.variantAttributes?.filter(a => a.type == 'enum'), (attribute, i) => {
                  return (_openBlock(), _createBlock(_component_HepsiBuradaOzellikSelectBox, {
                    stok: _ctx.stok,
                    onChange: _ctx.hepsiBuradaToNesilce,
                    "selected-value": attribute.value,
                    "onUpdate:selectedValue": ($event: any) => ((attribute.value) = $event),
                    attribute: attribute,
                    "category-id": _ctx.kategoriId,
                    label: attribute.name,
                    key: i
                  }, null, 8, ["stok", "onChange", "selected-value", "onUpdate:selectedValue", "attribute", "category-id", "label"]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attributes.variantAttributes.filter(a =>  a.type === 'string'), (attribute, i) => {
                  return (_openBlock(), _createBlock(_component_NFormField, {
                    title: attribute.id,
                    label: attribute.name,
                    "grid-cols": "120px 1fr",
                    key: i
                  }, {
                    label: _withCtx(() => [
                      (attribute.mandatory)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "*"))
                        : _createCommentVNode("", true)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_NInput, {
                        type: "text",
                        modelValue: attribute.value,
                        "onUpdate:modelValue": ($event: any) => ((attribute.value) = $event),
                        onChange: _ctx.hepsiBuradaToNesilce
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                    ]),
                    _: 2
                  }, 1032, ["title", "label"]))
                }), 128)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.attributes.variantAttributes.filter(a => a.type === 'integer'), (attribute, i) => {
                  return (_openBlock(), _createBlock(_component_NFormField, {
                    title: attribute.id,
                    label: attribute.name,
                    "grid-cols": "120px 1fr",
                    key: i
                  }, {
                    label: _withCtx(() => [
                      (attribute.mandatory)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_5, "*"))
                        : _createCommentVNode("", true)
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_NInput, {
                        type: "number",
                        modelValue: attribute.value,
                        "onUpdate:modelValue": ($event: any) => ((attribute.value) = $event),
                        onChange: _ctx.hepsiBuradaToNesilce
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                    ]),
                    _: 2
                  }, 1032, ["title", "label"]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["action"]))
}