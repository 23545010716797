
import {computed, defineComponent, reactive, toRefs} from "vue";
import {DateUtils} from "@/utils/date/DateUtils";
import {DocumentItem} from "@/api/models/DocumentItem";
import NMultipleDocumentUploader from "@/components/Uploader/NMultipleDocumentUploader.vue";
import NCard from "@/components/Card/NCard.vue";

export default defineComponent({
  components: {NCard, NMultipleDocumentUploader},
  props:['modelValue','serverUrl'],
  setup(props) {
    const localState = reactive({
      model:computed(()=> props.modelValue)
    })

    function yeniDokuman() {
      const dokumen = {
        url: [],
        aciklama: "",
        tarih: DateUtils.convertInputDateFromStringDate(
            new Date().toISOString()
        )
      } as DocumentItem;

      if (localState.model && localState.model.dokumanlar)
        localState.model.dokumanlar.push(dokumen);
    }

    function silDokuman(i: number) {
      if (i > -1) {
        localState.model?.dokumanlar?.splice(i, 1);
      }
    }

    return {
       ...toRefs(localState),
      yeniDokuman,
      silDokuman
    }
  }
})
