
export enum ActionType{
Islem_Yok=0,
Gonder=1,
Guncelle=2,
Sil=3
}

export const ActionTypeList = [
{key:'Islem_Yok', value:0, description:'İşlem Yok'},
{key:'Gonder', value:1, description:'Gönder'},
{key:'Guncelle', value:2, description:'Güncelle'},
{key:'Sil', value:3, description:'Sil'}
]
