
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import OzellikFormDialog from "@/modules/StokOzellik/components/OzellikFormDialog.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NIcon from "@/components/Icon/NIcon.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NTagList from "@/components/Tag/NTagList.vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import NButton from "@/components/Button/NButton.vue";
import { StokUtils } from "@/modules/Stok/utils/StokUtils";
import { SubeEntity } from "@/api/models/SubeEntity";
import { usePopupService } from "@/services/popupService";
import NAlert from "@/components/Alert/NAlert.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import { StokView } from "@/api/models/StokView";
import {useStokService} from "@/api/services/StokService";
import {StokItem} from "@/api/models/StokItem";
import {StokViewItem} from "@/api/models/StokViewItem";

export default defineComponent({
  components: { NGrid, NAlert, NButton, NModalDialog, NTagList,  NFormGrid, NIcon, NFormField, OzellikFormDialog },
  props: {
    stok: {
      type: Array
    },
    subeler: {
      type: Array
    },
    show:{
      type:Boolean
    }
  },
  setup(props,{emit}) {
    const localState = reactive({
      showOzellikForm: ref(false),
      selectedOzellikId: ref(""),
      showRef:computed({get:()=> props.show, set:(val)=> emit('update:show',val) }),
      stokRef:computed(()=> props.stok as StokView)
    });

    if (localState.stokRef && localState.stokRef.varyantlar){
      localState.stokRef.varyantlar.forEach((ozellik:any) => {
        ozellik.selectedValues = []

      })
    }

    function openOzellikForm(ozellikId: string) {
      localState.selectedOzellikId = ozellikId;
      localState.showOzellikForm = true;
    }

   async function generateItems() {
      const count = StokUtils.generateStokItems(localState.stokRef as StokView, props.subeler as SubeEntity[]);
      await addNewItemsForUpdate();
      if (count > 0)
      usePopupService().pushSuccessNotification({
        title:count + ' adet varyant eklendi.',
        duration:1000,
        body:''
      })
    }
    async function addNewItemsForUpdate() {
      if (isUpdateMode() && localState.stokRef.items){
        for (let i = 0; i < localState.stokRef.items.length; i++) {
          const item = localState.stokRef.items[i] as StokViewItem;
          if (!item.no){

            const itemAdded = await useStokService().postVaryantEkle({
              itemId:item.id,
              itemKod:item.itemKod,
              alisFiyat:item.alisFiyati,
              barkod:item.barkod,
              satisFiyat:item.satisFiyati,
              stokId:localState.stokRef.id,
              varyantDegerleri:item.varyantDegerleri
            }) as StokItem;
            item.no = itemAdded.no;
            item.id = itemAdded.id;
          }
        }
      }
    }


    function isUpdateMode(){
      return localState.stokRef.id && localState.stokRef.id.length > 1
    }

    return {
      ...toRefs(localState),
      openOzellikForm,
      generateItems
    };
  }
});
