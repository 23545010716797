import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2881e677"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tabs" }
const _hoisted_2 = ["onMouseup", "onClick", "onDragstart", "onDrop", "onDragover"]
const _hoisted_3 = {
  key: 0,
  class: "tab-icon"
}
const _hoisted_4 = ["title"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs.filter(a => !a.isClosed), (tab) => {
      return (_openBlock(), _createElementBlock("button", {
        draggable: "true",
        class: _normalizeClass({ tab: true, 'tab--selected': _ctx.selectedTabKey === tab.key }),
        key: tab.key,
        onMouseup: _withModifiers(($event: any) => (_ctx.closeTab(tab.key)), ["middle"]),
        onClick: ($event: any) => (_ctx.selectTab(tab.key)),
        onDragstart: (e)=>_ctx.drag(e, tab),
        onDrop: (e)=>_ctx.drop(e, tab),
        onDragover: (e)=>_ctx.allowDrop(e, tab)
      }, [
        (tab.icon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_NIcon, _mergeProps(tab.icon, { size: "18px" }), null, 16)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "tab-title",
          title: tab.order +' - ' + tab.id + ' ' + tab.key
        }, _toDisplayString(tab.displayName), 9, _hoisted_4),
        _createElementVNode("button", {
          class: "tab-close",
          onClick: _withModifiers(()=>_ctx.closeTab(tab.key), ["stop"])
        }, [
          _createVNode(_component_NIcon, {
            type: "x",
            size: "16px"
          })
        ], 8, _hoisted_5)
      ], 42, _hoisted_2))
    }), 128))
  ]))
}