import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {KasaView} from '../models/KasaView'

export const useKasaService = defineStore({
id: 'KasaService',
actions: {


	async getDefaultInstall(firmaKod:string)
	{
		const { request, response } = useApi(`/api/kasa/DefaultInstall/${firmaKod}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasa/Liste/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListeIdName()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasa/ListeIdName/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetir(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasa/Getir/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postEkle(kasaView:KasaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasa/Ekle/${shared.firm.id}`,{ method:'POST', data:kasaView });
		await request();
		return response.value;
	},

	async putGuncelle(kasaView:KasaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasa/Guncelle/${shared.firm.id}`,{ method:'PUT', data:kasaView });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasa/Sil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getUserKasaList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/kasa/UserKasaList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
