import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {RecordStatus} from '../models/RecordStatus'
import {TevkifatOranlariListe} from '../models/TevkifatOranlariListe'
import {KdvIstisnaListe} from '../models/KdvIstisnaListe'
import {FirmaEntity} from '../models/FirmaEntity'

export const useFirmaService = defineStore({
id: 'FirmaService',
actions: {


	async getDefaultInstall()
	{
		const { request, response } = useApi(`/api/firma/DefaultInstall`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getFirmaListesi()
	{
		const { request, response } = useApi(`/api/firma/FirmaListesi`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getDefaultFirma()
	{
		const { request, response } = useApi(`/api/firma/DefaultFirma`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getVeritabaniAyariGetir(dbname:string)
	{
		const { request, response } = useApi(`/api/firma/VeritabaniAyariGetir`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getVeritabaniAyarlariGetir()
	{
		const { request, response } = useApi(`/api/firma/VeritabaniAyarlariGetir`,{ method:'GET' });
		await request();
		return response.value;
	},

	async get()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/firma/Firma/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async putIhracatAciklamaGuncelle(liste:string[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/firma/IhracatAciklamaGuncelle/${shared.firm.id}`,{ method:'PUT', data: liste });
		await request();
		return response.value;
	},

	async putFirmaGuncelle(firmaEntity:FirmaEntity)
	{
		const { request, response } = useApi(`/api/firma/FirmaGuncelle`,{ method:'PUT', data:firmaEntity });
		await request();
		return response.value;
	},

	async getTumFirmalar()
	{
		const { request, response } = useApi(`/api/firma/TumFirmalar`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getDBBackup()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/firma/DBBackup/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getResimBackup()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/firma/ResimBackup/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
