
import {SiparisView} from "@/api/models/SiparisView";
import SiparisSatirItemExtend from "@/modules/SiparisTeklif/models/SiparisSatirItemExtend";
import FaturaSatirItemExtend from "@/modules/Fatura/models/FaturaSatirItemExtend";
import { KdvMatrahToplamlari } from "@/modules/Fatura/models/KdvMatrahToplamlari";
import { BirimToplamlari } from "@/modules/Fatura/models/BirimToplamlari";
import _ from "lodash";


export default class SiparisViewExtend extends SiparisView {
    constructor(siparisView?: SiparisView) {

        super();
        if (siparisView) {
            this.id = siparisView.id;
            this.siparisTip = siparisView.siparisTip;
            this.subeId = siparisView.subeId;
            this.aciklama = siparisView.aciklama;
            this.tarih = siparisView.tarih ;
            this.vade = siparisView.vade;
            this.projeId = siparisView.projeId;
            this.cariHesabi = siparisView.cariHesabi;
            this.cariIsi = siparisView.cariIsi;
            this.evrakNo = siparisView.evrakNo;
            this.referansNo = siparisView.referansNo ;
            this.fiyatGrupId = siparisView.fiyatGrupId;
            this.cariId = siparisView.cariId;
            this.cariUnvan = siparisView.cariUnvan;
            this.faturaUnvan = siparisView.faturaUnvan ;
            this.kargoFirmasi = siparisView.kargoFirmasi ;
            this.kargoTakipNo = siparisView.kargoTakipNo ;
            this.kargoBarkod = siparisView.kargoBarkod ;
            this.vergiNo = siparisView.vergiNo ;
            this.vergiDairesi = siparisView.vergiDairesi ;
            this.ad = siparisView.ad;
            this.soyad = siparisView.soyad;
            this.kargolandi = siparisView.kargolandi;
            this.smsGonderildi = siparisView.smsGonderildi;
            this.referansId = siparisView.referansId;
            this.faturaAdres = siparisView.faturaAdres;
            this.teslimatlar = siparisView.teslimatlar ;
            this.gonder = siparisView.gonder;
            this.dokumanlar = siparisView.dokumanlar;
            this.siparisler = siparisView.siparisler;
            this.teklifler = siparisView.teklifler;
            this.faturalar = siparisView.faturalar ;
            this.irsaliyeler = siparisView.irsaliyeler;
            this.faturaMaliyeti = siparisView.faturaMaliyeti;
            this.cariIslemDoviz = siparisView.cariIslemDoviz;
            this.cariIslemKur = siparisView.cariIslemKur;
            this.altIskontolar = siparisView.altIskontolar ;
            this.tutarIskonto = siparisView.tutarIskonto ;
            this.faturaKalan = siparisView.faturaKalan ;
            this.tip = siparisView.tip;
            this.satirlar = [];

            this.teslimatBilgileri = siparisView.teslimatBilgileri;
            this.nakliye = siparisView.nakliye;
            this.not = siparisView.not;
            this.odemeSekli = siparisView.odemeSekli;


            if (siparisView.satirlar && siparisView.satirlar?.length > 0) {
                for (let i = 0; i < siparisView.satirlar?.length; i++) {
                    if (siparisView.satirlar) {
                        const siparisSatir = new SiparisSatirItemExtend(
                            siparisView.satirlar[i]
                        );
                        this.satirlar.push(siparisSatir);
                    }
                }
            }

        }
    }
    getKdvTevkifatTutar(): number {

        return (this.satirlar as SiparisSatirItemExtend[])?.reduce((x, satir: any) => x + satir.getKdvTevkifatTutarExtend(), 0)
    }

    getKdvTevkifatliTutar(): number {
        return (this.satirlar as SiparisSatirItemExtend[])?.reduce((x, satir: any) => x + satir.getKdvTevkifatliTutarExtend(), 0)
    }
    getSatirBrutToplam(): number {
        return (this.satirlar as SiparisSatirItemExtend[])?.reduce((x, satir: any) => x + satir.getSatirBrutToplamExtend(), 0)
    }

    getSatirIskontoTutar(): number {
        return (this.satirlar as SiparisSatirItemExtend[])?.reduce((x, satir: any) => x + satir.getSatirIskontoTutarExtend(), 0)
    }

    getAltIskontoTutar(): number {
        return (this.satirlar as SiparisSatirItemExtend[])?.reduce((x, satir: any) => x + satir.getAltIskontoTutarExtend(), 0)
    }

    getIskontoluTutar(): number {
        return (this.satirlar as SiparisSatirItemExtend[])?.reduce((x, satir: any) => x + satir.getIskontoluTutarExtend(), 0)
    }

    getKdvTutar(): number {
        return (this.satirlar as SiparisSatirItemExtend[])?.reduce((x, satir: any) => x + satir.getKdvTutarExtend(), 0)
    }

    getKdvliTutar(): number {
        return (this.satirlar as SiparisSatirItemExtend[])?.reduce((x, satir: any) => x + satir.getKdvliTutarExtend() , 0)
    }


    getOdenecekTutar() {
        return this.getIskontoluTutar() + this.getKdvTutar() - this.getKdvTevkifatTutar();
    }


    // Helpers

    altIskontoOranBul(toplamSatirIskontoluTutar: number, altIskontoTutar: number, iskontolar: number[]) {
        const toplamSatirIskontoluTutarDeger = toplamSatirIskontoluTutar;
        let tutar = 0;
        iskontolar.forEach(iskonto => {
            tutar += toplamSatirIskontoluTutar * iskonto / 100;
            toplamSatirIskontoluTutar -= tutar;
        })

        const sonuc = tutar + altIskontoTutar;


        return 100 * sonuc / toplamSatirIskontoluTutarDeger;
    }

    setFaturaAltiIskonto() {
        const oran = this.altIskontoOranBul(this.getSatirBrutToplam() - this.getSatirIskontoTutar(), this.tutarIskonto!, this.altIskontolar!)
        this.satirlar?.forEach(satir => {
            satir.altIskontoOran = oran;
        })
    }
    setFaturaAltiIskontoSifirla() {
        const oran = this.altIskontoOranBul(this.getSatirBrutToplam() - this.getSatirIskontoTutar(), 0, this.altIskontolar!)
        this.satirlar?.forEach(satir => {
            satir.altIskontoOran = oran;
        })
    }
    getBirimTekTekHesapla():BirimToplamlari[]{
        const birimToplamlari =[] as BirimToplamlari[];

        const birimler = this.satirlar?.map(a=>a.birim);
        const uniqBirim = [...new Set(birimler)];

        if (uniqBirim && uniqBirim.length > 0) {

            uniqBirim.forEach(birim => {
                if (birim && birim.length > 0 ){
                    const miktar = _.sum(this.satirlar?.filter(a=>a.birim == birim ).map((satir: any) => (satir.miktar * satir.carpan)));

                    birimToplamlari.push({
                        birim : birim,
                        miktar :miktar,
                    });
                }
            })

        }
        return birimToplamlari ;
    }
    getKdvTekTekHesapla():KdvMatrahToplamlari[] {
        const kdvToplamlari =[] as  KdvMatrahToplamlari[];
        const kdvler = this.satirlar?.map(a => a.kdv);
        const uniqKdv = [...new Set(kdvler)];
        if (uniqKdv && uniqKdv.length > 0) {

            uniqKdv.forEach(kdv=>{
                let kdvToplam =  0;
                let kdvMatrahToplam =  0;
                const satir = this.satirlar?.filter(a=>a.kdv == kdv) as FaturaSatirItemExtend[];
                satir.forEach(a => {
                    kdvToplam += a.getKdvTutarExtend()  - a.getKdvTevkifatTutarExtend();
                    kdvMatrahToplam += a.getIskontoluTutarExtend() - a.getAltIskontoTutarExtend();
                } );

                kdvToplamlari.push({
                    kdv : kdv,
                    kdvTutar : kdvToplam,
                    matrah : kdvMatrahToplam,
                });
            });
        }
        return kdvToplamlari;
    }
    // // kdv matrahlarını ayrı ayrı hesaplama
    //
    // getKdvTekTekHesapla():KdvMatrahToplamlari[] {
    //     const kdvToplamlari =[] as  KdvMatrahToplamlari[];
    //     const kdvler = this.satirlar?.map(a => a.kdv);
    //     const uniqKdv = [...new Set(kdvler)];
    //     if (uniqKdv && uniqKdv.length > 0) {
    //
    //         uniqKdv.forEach(kdv=>{
    //             let kdvToplam =  0;
    //             let kdvMatrahToplam =  0;
    //             const satir = this.satirlar?.filter(a=>a.kdv == kdv) as FaturaSatirItemExtend[];
    //             satir.forEach(a => {
    //                 kdvToplam += a.getKdvTutarExtend()  - a.getKdvTevkifatTutarExtend();
    //                 kdvMatrahToplam += a.getIskontoluTutarExtend() - a.getAltIskontoTutarExtend();
    //             } );
    //
    //             kdvToplamlari.push({
    //                 kdv : kdv,
    //                 kdvTutar : kdvToplam,
    //                 matrah : kdvMatrahToplam,
    //             });
    //         });
    //     }
    //     return kdvToplamlari;
    // }


}
