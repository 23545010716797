import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "dx-card",
  style: {"grid-column":"span 5","height":"100%","width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NButtonFile = _resolveComponent("NButtonFile")!
  const _component_DxKeyboardNavigation = _resolveComponent("DxKeyboardNavigation")!
  const _component_DxEditing = _resolveComponent("DxEditing")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NImporterFormDialog = _resolveComponent("NImporterFormDialog")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    modelValue: _ctx.showRef,
    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.showRef) = $event)),
    width: _ctx.width,
    "closable-mask": false
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
      _createVNode(_component_NTag, {
        type: "info",
        filled: true
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.subTitle), 1)
        ]),
        _: 1
      })
    ]),
    body: _withCtx(() => [
      _createVNode(_component_NGrid, {
        cols: "1fr auto auto auto auto",
        rows: "auto 1fr",
        gap: "8px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DxSelectBox, {
            items: _ctx.list,
            "onUpdate:items": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.list) = $event)),
            value: _ctx.selectedValue,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedValue) = $event)),
            "display-expr": "name",
            "value-expr": "id"
          }, null, 8, ["items", "value"]),
          _createVNode(_component_NButton, {
            style: {"align-self":"center"},
            icon: "edit",
            size: "small",
            onClick: _ctx.openImportForm
          }, null, 8, ["onClick"]),
          _createVNode(_component_NButton, {
            style: {"align-self":"center"},
            icon: "refresh-ccw",
            size: "small",
            onClick: _ctx.load
          }, null, 8, ["onClick"]),
          _createVNode(_component_NButtonFile, {
            style: {"align-self":"center"},
            type: "info",
            icon: "file",
            size: "small",
            onChange: _ctx.selectFile
          }, {
            default: _withCtx(() => [
              _createTextVNode("Dosya Seç (xlsx)")
            ]),
            _: 1
          }, 8, ["onChange"]),
          _createVNode(_component_NButton, {
            style: {"align-self":"center"},
            type: "info",
            icon: "clipboard",
            size: "small",
            onClick: _ctx.paste
          }, {
            default: _withCtx(() => [
              _createTextVNode("Excel'den Yapıştır")
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_DxDataGrid, {
              ref: "dataGrid",
              columns: _ctx.model.columns,
              "onUpdate:columns": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.columns) = $event)),
              "data-source": _ctx.data,
              "onUpdate:dataSource": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.data) = $event)),
              style: {"width":"100%","height":"100%"},
              toolbarTemplate: "toolbar",
              "allow-column-resizing": true
            }, {
              toolbar: _withCtx(() => [
                _createVNode(_component_NButton, {
                  icon: "trash",
                  size: "small",
                  onClick: _cache[2] || (_cache[2] = ()=> _ctx.data=[])
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Tümünü Sil")
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_DxKeyboardNavigation, {
                  "edit-on-key-press": true,
                  "enter-key-action": "moveFocus",
                  "enter-key-direction": "column"
                }),
                _createVNode(_component_DxEditing, {
                  "allow-updating": true,
                  mode: "cell",
                  "allow-deleting": true,
                  "use-icons": true
                }),
                _createVNode(_component_DxHeaderFilter, { visible: true }),
                _createVNode(_component_DxFilterRow, { visible: true })
              ]),
              _: 1
            }, 8, ["columns", "data-source"])
          ])
        ]),
        _: 1
      }),
      (_ctx.showFormDialog)
        ? (_openBlock(), _createBlock(_component_NImporterFormDialog, {
            key: 0,
            show: _ctx.showFormDialog,
            "onUpdate:show": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.showFormDialog) = $event)),
            tip: _ctx.tip,
            id: _ctx.selectedValue,
            "onUpdate:id": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedValue) = $event))
          }, null, 8, ["show", "tip", "id"]))
        : _createCommentVNode("", true)
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_NButton, {
        type: "warning",
        size: "small",
        icon: "x-circle",
        onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showRef = false))
      }, {
        default: _withCtx(() => [
          _createTextVNode("İptal")
        ]),
        _: 1
      }),
      _createVNode(_component_NButton, {
        type: "success",
        size: "small",
        icon: "save",
        click: _ctx.save
      }, {
        default: _withCtx(() => [
          _createTextVNode("Aktarımı Başlat")
        ]),
        _: 1
      }, 8, ["click"])
    ]),
    _: 1
  }, 8, ["modelValue", "width"]))
}