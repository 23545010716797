import {RecordStatus} from '../models/RecordStatus'
import {FaturaSatirTip} from '../models/FaturaSatirTip'
import {Doviz} from '../models/Doviz'
import {IadeItem} from '../models/IadeItem'
import {Vergiler} from '../models/Vergiler'
import {EIhracatGonderimTip} from '../models/EIhracatGonderimTip'
import {EIhracatTeslimSartiTip} from '../models/EIhracatTeslimSartiTip'
import {EIhracatKapCinsTip} from '../models/EIhracatKapCinsTip'

export class FaturaSatirItem{
	id?:string;
	status?:RecordStatus;
	kayitTarihi?:string;
	kullaniciAdi?:string;
	tip?:FaturaSatirTip;
	stokId?:string;
	itemId?:string;
	referansId?:string;
	ad?:string;
	kod?:string;
	barkod?:string;
	miktar?:number;
	kdv?:number;
	doviz?:Doviz;
	kur?:number;
	fiyat?:number;
	birim?:string;
	carpan?:number;
	giderYeri?:string;
	iadeler?:IadeItem[];
	iskontolar?:number[];
	altIskontoOran?:number;
	genelKur?:number;
	aciklama?:string;
	not?:string;
	katilimPayi?:number;
	depoId?:string;
	plasiyerId?:string;
	tevkifatKod?:string;
	tevkifatAd?:string;
	mensei?:string;
	adet?:number;
	kiloAdet?:number;
	tevkifatOran?:number;
	komisyon?:number;
	kdvIstisnasi?:string;
	vergiler?:Vergiler[];
	seriler?:string[];
	siparisTarih?:string;
	kampanyaId?:string;
	readonly toplamIade?:number;
	readonly getAciklama?:string;
	readonly getSatirBrutToplam?:number;
	readonly getDovizBirimFiyat?:number;
	readonly getIskontoluToplam?:number;
	readonly getAltIskontoluToplam?:number;
	readonly getKdvTutar?:number;
	readonly getKdvTevkifatTutar?:number;
	readonly getFaturaListeKdvTevkifatTutar?:number;
	readonly getTevkifatTutar?:number;
	readonly getNetFiyat?:number;
	readonly getNetFiyatKdvLi?:number;
	yeniSatisFiyat?:number;
	uzunluk?:number;
	kgAdet?:number;
	gonderimTip?:EIhracatGonderimTip;
	teslimTip?:EIhracatTeslimSartiTip;
	gTipNo?:string;
	kapMarka?:string;
	kapAdet?:number;
	kapTip?:EIhracatKapCinsTip;
}
