export enum MenuRefrenceType {
  Kullanilmaz,
  Cari,
  Kasa,
  Banka,
  Pos,
  Kredi_Karti,
  Hizmet_Masraf,
 FirmaCekSenet,
  MusteriCekSenet,
  Krediler,
  Kiymetler,
    Perakende ,
  CariMailOrder,
  Sac
}
