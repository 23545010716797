import {NModuleRoute} from "@/models";

export const RaporModuleRoutes: NModuleRoute[] = [
    {
        id: "RaporModule",
        order: 5,
        displayName: "Raporlar",
        icon: {
            type: "activity"
        },

        children: [
            {
                id: "CariDonemBakiye",
                order: 0,
                displayName: "Cari Bakiye",
                icon: {
                    type: "activity"
                },
                requiredRoles:{
                    readRoles:['Cari.Bakiye.Rapor'],
                    deleteRoles:[],
                    updateRoles:[],
                    insertRoles:[],
                },
                component: () =>
                  import(
                    /* webpackChunkName: "CariDonemBakiye"*/ "../pages/CariHareketDonemselRaporPage.vue"
                    )
            },
            {
                id: "CariSatislar",
                order: 0,
                displayName: "Cari Satışlar",
                icon: {
                    type: "activity"
                },
                requiredRoles:{
                    readRoles:['Cari.Satislar.Rapor'],
                    deleteRoles:[],
                    updateRoles:[],
                    insertRoles:[],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "CariDonemBakiye"*/ "../pages/CariSatislarRaporPage.vue"
                        )
            },
            {
                id: "BankaDonemBakiye",
                order: 0,
                displayName: "Banka Bakiye",
                icon: {
                    type: "activity"
                },
                requiredRoles:{
                    readRoles:['Banka.Bakiye.Rapor'],
                    deleteRoles:[],
                    updateRoles:[],
                    insertRoles:[],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "BankaDonemBakiye"*/ "../pages/BankaBakiyeRaporPage.vue"
                        )
            },
            {
                id: "KasaDonemBakiye",
                order: 0,
                displayName: "Kasa Bakiye",
                icon: {
                    type: "activity"
                },
                requiredRoles:{
                    readRoles:['Kasa.Bakiye.Rapor'],
                    deleteRoles:[],
                    updateRoles:[],
                    insertRoles:[],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "KasaDonemBakiye"*/ "../pages/KasaBakiyeRaporPage.vue"
                        )
            },
            {
                id: "StokDevirHizi",
                order: 0,
                displayName: "Stok Devir Hızı",
                icon: {
                    type: "activity"
                },
                requiredRoles:{
                    readRoles:['Gunluk.Rapor'],
                    deleteRoles:[],
                    updateRoles:[],
                    insertRoles:[],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "StokDevirHizi"*/ "../pages/StokDevirHiziPage.vue"
                        )
            },
            {
                id: "StokEnvanter",
                order: 0,
                displayName: "Stok Envanter",
                icon: {
                    type: "activity"
                },
                requiredRoles:{
                    readRoles:['Gunluk.Rapor'],
                    deleteRoles:[],
                    updateRoles:[],
                    insertRoles:[],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "StokEnvanterRapor"*/ "../pages/StokEnvanterRaporPage.vue"
                        )
            },
            {
                id: "FaturaHareketleri",
                order: 0,
                displayName: "Fatura Hareketleri",
                icon: {
                    type: "activity"
                },
                requiredRoles:{
                    readRoles:["Fatura.Rapor.Hareketleri"],
                    deleteRoles:[],
                    updateRoles:[],
                    insertRoles:[],
                },
                component: () =>
                  import(
                    /* webpackChunkName: "FaturaHareketleri"*/ "../pages/FaturaHareketleriPage.vue"
                    )
            },
            {
                id: "SiparisHareketleri",
                order: 0,
                displayName: "Sipariş Hareketleri",
                icon: {
                    type: "activity"
                },
                requiredRoles:{
                    readRoles:["Siparis.Rapor.Hareketleri"],
                    deleteRoles:[],
                    updateRoles:[],
                    insertRoles:[],
                },
                component: () =>
                  import(
                    /* webpackChunkName: "FaturaHareketleri"*/ "../pages/SiparisHareketleriPage.vue"
                    )
            },
            {
                id: "FaturaKarlilik",
                order: 0,
                displayName: "Fatura Kârlılık",
                icon: {
                    type: "activity"
                },
                requiredRoles:{
                    readRoles:['Gunluk.Rapor'],
                    deleteRoles:[],
                    updateRoles:[],
                    insertRoles:[],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "FaturaKarlılık"*/ "../pages/FaturaKarlilikPage.vue"
                        )
            },
            {
                id: "GunlukHareketler",
                order: 0,
                displayName: "Günlük Rapor",
                icon: {
                    type: "activity"
                },
                requiredRoles:{
                    readRoles:['Gunluk.Rapor'],
                    deleteRoles:[],
                    updateRoles:[],
                    insertRoles:[],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "GunlukHareketler"*/ "../pages/GunlukRaporV2.vue"
                        )
            },
            // {
            //     id: "HareketRaporlari",
            //     order: 0,
            //     displayName: "Hareketler",
            //     icon: {
            //         type: "activity"
            //     },
            //     requiredRoles:{
            //         readRoles:['Fatura.Rapor.Liste'],
            //         deleteRoles:['Fatura.Rapor.Liste'],
            //         updateRoles:['Fatura.Rapor.Liste'],
            //         insertRoles:['Fatura.Rapor.Liste'],
            //     },
            //     component: () =>
            //         import(
            //             /* webpackChunkName: "Faturalar"*/ "../pages/HareketRaporPage.vue"
            //             )
            // },
            {
                id: "StokHareketRapor",
                order: 0,
                displayName: "Stok Hareketleri",
                icon: {
                    type: "activity"
                },
                requiredRoles:{
                    readRoles:['Fatura.Rapor.Liste'],
                    deleteRoles:[],
                    updateRoles:[],
                    insertRoles:[],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "StokHareketRaporPage"*/ "../pages/StokHareketRaporPage.vue"
                        )
            },
            {
                id: "StokBakiyeRapor",
                order: 0,
                displayName: "Stok Bakiyeleri",
                icon: {
                    type: "activity"
                },
                requiredRoles:{
                    readRoles:['Fatura.Rapor.Liste'],
                    deleteRoles:[],
                    updateRoles:[],
                    insertRoles:[],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "StokBakiyeleriPage"*/ "../pages/StokBakiyeleriPage.vue"
                        )
            },
            {
                id: "KdvRaporPage",
                order: 0,
                displayName: "Kdv Rapor",
                icon: {
                    type: "activity"
                },
                requiredRoles:{
                    readRoles:['Kdv.Rapor.Page'],
                    deleteRoles:[],
                    updateRoles:[],
                    insertRoles:[],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "KdvRaporPage"*/ "../pages/KdvRaporPage.vue"
                        )
            },
            {
                id: "FirmaDurum",
                order: 0,
                displayName: "Firma Durum Raporu",
                icon: {
                    type: "activity"
                },
                requiredRoles:{
                    readRoles:['Firma.Durum.Rapor'],
                    deleteRoles:[],
                    updateRoles:[],
                    insertRoles:[],
                },
                component: () =>
                    import(
                        /* webpackChunkName: "FirmaDurumRaporPage"*/ "../pages/FirmaDurumRaporPage.vue"
                        )
            },

        ]
    }
];
