import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_StokOzellikTipForm_Sac = _resolveComponent("StokOzellikTipForm_Sac")!
  const _component_StokOzellikTipForm_SacProfil = _resolveComponent("StokOzellikTipForm_SacProfil")!
  const _component_StokOzellikTipForm_Diger = _resolveComponent("StokOzellikTipForm_Diger")!
  const _component_DepoSelectBox = _resolveComponent("DepoSelectBox")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!

  return (_openBlock(), _createBlock(_component_NFormGrid, {
    title: "Stok Tipi Özellikleri",
    rows: "auto",
    cols: "1fr",
    style: {"background":"none","border":"none"}
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_NFormField, {
        "grid-cols": "auto 185px auto",
        label: "Stok Tipi"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DxSelectBox, {
            items: _ctx.tipList,
            displayExpr: "description",
            valueExpr: "value",
            value: _ctx.model.tip,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.tip) = $event)),
            onSelectionChanged: _ctx.selectionChanged
          }, null, 8, ["items", "value", "onSelectionChanged"]),
          _createVNode(_component_NButton, {
            click: async () => { await _ctx.saveSettings('varsayilanStokTip', String(_ctx.model.tip))},
            size: "small",
            icon: "save",
            title: "Yeni stoklar için varsayılan tipi kaydet"
          }, null, 8, ["click"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      (_ctx.model.tipOzellikler && _ctx.model.tip == _ctx.StokTip.Sac)
        ? (_openBlock(), _createBlock(_component_StokOzellikTipForm_Sac, {
            key: 0,
            stok: _ctx.model,
            "onUpdate:stok": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model) = $event))
          }, null, 8, ["stok"]))
        : _createCommentVNode("", true),
      (_ctx.model.tipOzellikler && _ctx.model.tip == _ctx.StokTip.SacProfil)
        ? (_openBlock(), _createBlock(_component_StokOzellikTipForm_SacProfil, {
            key: 1,
            stok: _ctx.model,
            "onUpdate:stok": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model) = $event))
          }, null, 8, ["stok"]))
        : _createCommentVNode("", true),
      (_ctx.model.tipOzellikler && _ctx.model.tip != _ctx.StokTip.Sac && _ctx.model.tip != _ctx.StokTip.SacProfil && _ctx.model.tip != _ctx.StokTip.TicariMal)
        ? (_openBlock(), _createBlock(_component_StokOzellikTipForm_Diger, {
            key: 2,
            stok: _ctx.model,
            "onUpdate:stok": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model) = $event))
          }, null, 8, ["stok"]))
        : _createCommentVNode("", true),
      (_ctx.model.tipOzellikler && _ctx.model.tipOzellikler &&
            _ctx.model.tip != _ctx.StokTip.Sac && _ctx.model.tip != _ctx.StokTip.SacProfil && _ctx.model.tip == _ctx.StokTip.TicariMal)
        ? (_openBlock(), _createBlock(_component_NFormField, {
            key: 3,
            "grid-cols": "65px 0.5fr",
            label: "Depo",
            span: "2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DepoSelectBox, {
                modelValue: _ctx.model.tipOzellikler.depo,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.tipOzellikler.depo) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}