import {RecordStatus} from '../models/RecordStatus'
import {Doviz} from '../models/Doviz'
import {PluKod} from '../models/PluKod'
import {StokItem} from '../models/StokItem'
import {StokBirimItem} from '../models/StokBirimItem'
import {IdName} from '../models/IdName'
import {Ozellik} from '../models/Ozellik'
import {OzellikVaryant} from '../models/OzellikVaryant'
import {SubeFiyatItem} from '../models/SubeFiyatItem'
import {StokUreticiItem} from '../models/StokUreticiItem'
import {StokLanguageItem} from '../models/StokLanguageItem'
import {N11PazaryeriItem} from '../models/N11PazaryeriItem'
import {TrendyolPazaryeriItem} from '../models/TrendyolPazaryeriItem'
import {HepsiBuradaPazaryeriItem} from '../models/HepsiBuradaPazaryeriItem'
import {HepsiBuradaListingPazaryeriItem} from '../models/HepsiBuradaListingPazaryeriItem'
import {AliExpressPazaryeriItem} from '../models/AliExpressPazaryeriItem'
import {EpttAvmPazaryeriItem} from '../models/EpttAvmPazaryeriItem'
import {PazaramaPazaryeriItem} from '../models/PazaramaPazaryeriItem'
import {PrestaPazaryeriItem} from '../models/PrestaPazaryeriItem'
import {CicekSepetiPazaryeriItem} from '../models/CicekSepetiPazaryeriItem'
import {StokUyumluluktem} from '../models/StokUyumluluktem'
import {StokTipOzellikler} from '../models/StokTipOzellikler'
import {StokTip} from '../models/StokTip'
import {StokMiktarOzetItem} from '../models/StokMiktarOzetItem'
import {StokAnalizItem} from '../models/StokAnalizItem'

export class StokEntity{
	id?:string;
	status?:RecordStatus;
	kayitTarihi?:string;
	kullaniciAdi?:string;
	aktif?:boolean;
	kod?:string;
	no?:string;
	parentId?:string;
	kategoriNo?:number;
	ad?:string;
	kisaAciklama?:string;
	aciklama?:string;
	satisFiyat?:number;
	satisKdv?:number;
	satisKdvDahil?:boolean;
	satisDoviz?:Doviz;
	alisFiyat?:number;
	alisKdv?:number;
	alisKdvDahil?:boolean;
	alisDoviz?:Doviz;
	grup1?:string;
	grup2?:string;
	grup3?:string;
	grup4?:string;
	barkod?:string;
	birim?:string;
	yerlimi?:boolean;
	desi?:number;
	karMarji?:number;
	maliyet?:number;
	hizliSatis?:string;
	isSerili?:boolean;
	raf?:string;
	pluKod?:PluKod;
	pluNo?:number;
	resimler?:string[];
	items?:StokItem[];
	birimFiyatlari?:StokBirimItem[];
	kategoriler?:IdName[];
	ozellikler?:Ozellik[];
	varyantlar?:OzellikVaryant[];
	subeFiyatlari?:SubeFiyatItem[];
	ureticiler?:StokUreticiItem[];
	adlar?:string[];
	faturaAd?:string;
	barkodlar?:string[];
	kodlar?:string[];
	readonly ozellik?:object;
	languages?:StokLanguageItem[];
	gtip?:string;
	n11?:N11PazaryeriItem[];
	trendyol?:TrendyolPazaryeriItem[];
	hepsiBurada?:HepsiBuradaPazaryeriItem[];
	hepsiBuradaListing?:HepsiBuradaListingPazaryeriItem[];
	aliExpress?:AliExpressPazaryeriItem[];
	epttAvm?:EpttAvmPazaryeriItem[];
	pazarama?:PazaramaPazaryeriItem[];
	presta?:PrestaPazaryeriItem[];
	cicekSepeti?:CicekSepetiPazaryeriItem[];
	uyumluluklar?:StokUyumluluktem[];
	tipOzellikler?:StokTipOzellikler;
	tip?:StokTip;
	readonly getPazaryeriKod?:string;
	miktarOzetleri?:StokMiktarOzetItem[];
	analiz?:StokAnalizItem;
	getKalanMiktar?:number;
	readonly getAdetKilo?:number;
	getToplamAdet?:number;
	readonly getEtkisizKalan?:number;
	ureticiKod?:string;
	adetBolen?:number;
	readonly getAdet?:number;
	readonly getSatisFiyat?:number;
	readonly getAlisFiyat?:number;
	readonly getSatisFiyatToplami?:number;
	readonly getAlisFiyatToplami?:number;
	readonly getSatisFiyatKdvSiz?:number;
	readonly getAlisFiyatKdvSiz?:number;
	readonly getUrunAdi?:string;
	readonly getSatisSiparisMiktar?:number;
	readonly getAlisSiparisMiktar?:number;
}
