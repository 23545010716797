import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!

  return (_openBlock(), _createBlock(_component_DxSelectBox, {
    "display-expr": "bankaAdi",
    "value-expr": "id",
    items: _ctx.items,
    "search-timeout": 1,
    searchEnabled: true,
    onValueChanged: _ctx.valueChanged,
    value: _ctx.selected,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
    "show-clear-button": false,
    disabled: _ctx.disabled
  }, null, 8, ["items", "onValueChanged", "value", "disabled"]))
}