
export enum FaturaHareketTip{
AlisFaturasi=100,
HizmetMasrafAlisFatura=105,
SatisIadeFaturasi=110,
GiderPusulasiAlis=120,
DemirbasGirisi=130,
KurFarkiAlis=140,
FiyatFarkiAlis=150,
TevkifatliAlis=160,
MustahsilMakbuzuAlis=170,
SMMAlis=180,
SatisFatura=1100,
AlisIadeFaturasi=1110,
GiderPusulasiGiris=1120,
DemirbasSatis=1130,
KurFarkiSatis=1140,
FiyatFarkiSatis=1150,
TevkifatliSatis=1160,
MustahsilMakbuzuSatis=1170,
SMMSatis=1180,
AlisKonsinye=2100,
AlisIrsaliye=2200,
SatisKonsinye=3100,
SatisIrsaliye=3200,
AlisIrsaliyeToFatura=4000,
SatisIrsaliyeToFatura=5000
}

export const FaturaHareketTipList = [
{key:'AlisFaturasi', value:100, description:'Alış Faturası'},
{key:'HizmetMasrafAlisFatura', value:105, description:'Alış Faturası'},
{key:'SatisIadeFaturasi', value:110, description:'Satıştan İade Faturası'},
{key:'GiderPusulasiAlis', value:120, description:'Gider Pusulası Alış'},
{key:'DemirbasGirisi', value:130, description:'Demirbaş Girişi'},
{key:'KurFarkiAlis', value:140, description:'Kur Farkı Alış'},
{key:'FiyatFarkiAlis', value:150, description:'Fiyat Farkı Alış'},
{key:'TevkifatliAlis', value:160, description:'Tevkifat Alış'},
{key:'MustahsilMakbuzuAlis', value:170, description:'Mustahsil Makbuzu Alış'},
{key:'SMMAlis', value:180, description:'SMM Alış'},
{key:'SatisFatura', value:1100, description:'Satış Faturası'},
{key:'AlisIadeFaturasi', value:1110, description:'Alıştan İade Faturası'},
{key:'GiderPusulasiGiris', value:1120, description:'Gider Pusulası Giriş'},
{key:'DemirbasSatis', value:1130, description:'Demirbaş Satış'},
{key:'KurFarkiSatis', value:1140, description:'Kur Farkı Satış'},
{key:'FiyatFarkiSatis', value:1150, description:'Fiyat Farkı Satış'},
{key:'TevkifatliSatis', value:1160, description:'Tevkifat Satış'},
{key:'MustahsilMakbuzuSatis', value:1170, description:'Mustahsil Makbuzu Satış'},
{key:'SMMSatis', value:1180, description:'SMM Satış'},
{key:'AlisKonsinye', value:2100, description:'Alış Konsinye'},
{key:'AlisIrsaliye', value:2200, description:'Alış İrsaliye'},
{key:'SatisKonsinye', value:3100, description:'Satış Konsinye'},
{key:'SatisIrsaliye', value:3200, description:'Satış İrsaliye'},
{key:'AlisIrsaliyeToFatura', value:4000, description:'Alış Faturası ( İrsaliyeli )'},
{key:'SatisIrsaliyeToFatura', value:5000, description:'Satış Faturası ( İrsaliyeli )'}
]
