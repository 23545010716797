import {defineStore} from "pinia";
import {KurView} from "@/api/models/KurView";
import {useDovizKurService} from "@/api/services/DovizKurService";
import {PlasiyerEntity} from "@/api/models/PlasiyerEntity";
import {usePlasiyerService} from "@/api/services/PlasiyerService";


export const useCachedPlasiyerList = defineStore({
    state: () => ({
        userListe: [] as PlasiyerEntity[],
        liste : [] as PlasiyerEntity[],
    }),
    id: "useCachedPlasiyerList",
    actions: {
        async getUserPlasiyerList(): Promise<PlasiyerEntity[]> {
            if (this.userListe.length == 0) {
                this.userListe = await  usePlasiyerService().getUserPlasiyerList() ;
                return this.userListe
            }
            return this.userListe;
        },
        async getAllPlasiyerList(): Promise<PlasiyerEntity[]> {
            if (this.liste.length == 0) {
                this.liste = await  usePlasiyerService().getListe() ;
                return this.liste
            }
            return this.liste;
        }
    }
})
