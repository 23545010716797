import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NInputHtml = _resolveComponent("NInputHtml")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_NFormGrid, {
      cols: "1fr 1fr 1fr 1fr",
      title: "Genel Bilgiler"
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_NFormField, {
          label: "Gönder",
          "grid-cols": "auto auto auto auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NSwitch, {
              modelValue: _ctx.prestaRef.isActive,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.prestaRef.isActive) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_NButton, {
              icon: "plus",
              size: "small",
              onClick: _ctx.addNew
            }, {
              default: _withCtx(() => [
                _createTextVNode("Yeni Site Ekle")
              ]),
              _: 1
            }, 8, ["onClick"]),
            (_ctx.index > 0)
              ? (_openBlock(), _createBlock(_component_NButton, {
                  key: 0,
                  icon: "trash",
                  size: "small",
                  type: "danger",
                  "icon-size": 12,
                  style: {"padding":"4px"},
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.remove(_ctx.index)))
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_NFormField, {
          label: "Kısa Açıklama",
          "grid-cols": "100px 1fr",
          span: "4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.prestaRef.kisaAciklama,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.prestaRef.kisaAciklama) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Açıklama",
          "grid-cols": "100px 1fr",
          span: "4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInputHtml, {
              modelValue: _ctx.prestaRef.aciklama,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.prestaRef.aciklama) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Hazırlanma Günü",
          "grid-cols": "100px 1fr",
          span: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxNumberBox, {
              value: _ctx.prestaRef.hazirlanmaGunu,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.prestaRef.hazirlanmaGunu) = $event)),
              min: 0
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Site",
          "grid-cols": "100px 1fr",
          span: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxSelectBox, {
              value: _ctx.prestaRef.configId,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.prestaRef.configId) = $event)),
              items: _ctx.configs,
              "display-expr": "url",
              "value-expr": "id"
            }, null, 8, ["value", "items"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Seo Başlık",
          "grid-cols": "100px 1fr",
          span: "4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.prestaRef.seoTitle,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.prestaRef.seoTitle) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Seo Açıklama",
          "grid-cols": "100px 1fr",
          span: "4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.prestaRef.seoDescription,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.prestaRef.seoDescription) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Seo Etiketleri",
          "grid-cols": "100px 1fr",
          span: "4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.prestaRef.seoTags,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.prestaRef.seoTags) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Kar Marjı(%)",
          span: "1",
          "grid-cols": "100px 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxNumberBox, {
              value: _ctx.prestaRef.karMarji,
              "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.prestaRef.karMarji) = $event))
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Komisyon (%)",
          span: "1",
          "grid-cols": "auto 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxNumberBox, {
              value: _ctx.prestaRef.komisyon,
              "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.prestaRef.komisyon) = $event))
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Kargo (₺)",
          span: "1",
          "grid-cols": "auto 1fr"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxNumberBox, {
              value: _ctx.prestaRef.kargoTutar,
              "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.prestaRef.kargoTutar) = $event))
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          "grid-cols": "auto 240px",
          label: "Fiyatlandırma"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxSelectBox, {
              value: _ctx.prestaRef.fiyat.tip,
              "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.prestaRef.fiyat.tip) = $event)),
              items: _ctx.pazaryeriFiyatTipiList,
              "value-expr": "value",
              "display-expr": "description"
            }, null, 8, ["value", "items"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}