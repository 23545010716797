
import {defineComponent, onActivated, reactive, ref, toRefs} from "vue";
import {BankaItem} from "@/api/models/BankaItem";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import {DevExtremeUtils} from "@/devextreme/DevExtremeUtils";

export default defineComponent({
  props:['bankaBilgileri'],
  setup(props) {
    const localState = reactive({
      dataSource:new DataSource({
        key:'id',
        reshapeOnPush:true,
      }),
      arrayStore:new ArrayStore({
        data:props.bankaBilgileri,
        key:'id'
      }),
        cariFormBankalarDataGrid:ref()
    })

    function load(){

      if (props.bankaBilgileri && props.bankaBilgileri.length > 0){
        props.bankaBilgileri.forEach((banka:any,i:number)=>{
          banka.id = Date.now().toString() + i
        })
      }

      localState.dataSource = new DataSource({
        store:localState.arrayStore
      });

    }
    load()

    function newBankaBilgisi() {
      const banka = {
        id:Date.now().toString(),
        banka: "",
        hesapNo: "",
        iban: "TR",
        sube: "",
        subeKod: ""
      } as BankaItem;
      localState.arrayStore.push([{type: "insert", data: banka}]);
      localState.dataSource.reload()
    }
      onActivated(() => {
          DevExtremeUtils.updateScroll(localState.cariFormBankalarDataGrid);
      });

    return {
      ...toRefs(localState),
      newBankaBilgisi
    }
  }
})
