
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import {LayoutTab, useLayoutStore} from "../context";
import { useSharedStore } from "@/store";
import SectionSeparator from "./SectionSeparator.vue";
import FirmSelector from "./FirmSelector.vue";

import { useRoute, useRouter } from "vue-router";
import { useRoleService } from "@/api/services/RoleService";
import { NModuleRoute } from "@/models";
import { UserRoleGroupView } from "@/api/models/UserRoleGroupView";
import { UserFirmaRoleItem } from "@/api/models/UserFirmaRoleItem";
import { useVersionService } from "@/api/services/VersionService";
import { useNesilceConfig } from "@/api/NesilceConfig";
import {useFavoriteStore} from "@/modules/Cari/store/FavoriteStore";

export default defineComponent({
  components: {
    SectionSeparator,
    FirmSelector
  },
  setup() {
    const layout = useLayoutStore();
    const shared = useSharedStore();
    const route = useRoute();
    const favoriteStore = useFavoriteStore();
    const favoriteRoute = computed(()=> favoriteStore.favorite)
    const isSidebarCollapsed = computed(() => layout.isSidebarCollapsed);
    const navigationRoutes = ref([] as NModuleRoute[]);
    const logoUrl = ref("");
    const selectedTabId = computed(() => layout.selectedTabId);

    const expandedNavItems = ref<string[]>([]);

    const firmLogo = computed(() => shared.firm.logo);
    const isLoadedNavigation = false;
    const version = ref(0);
    const draggedId= ref("")



    const logo = computed(() =>
      process.env.NODE_ENV === "development"
        ? require("@/assets/images/LogoDev.png")
        : require("@/assets/images/Logo.png")
    );

    // watch(
    //   () => isSidebarCollapsed.value,
    //   (value, oldValue) => {
    //     if (value) {
    //       expandedNavItems.value = [];
    //     }
    //   }
    // );

    watch(() => shared.user.name, (newValue) => {
      load(); // Kullanıcı Bilgileri sayfa refresh olduğunda hemen gelmiyor
    });

    onMounted(async () => {
      load();
      await getLogo();
    });

    async function getLogo() {
        logoUrl.value = useNesilceConfig().config.LOGO_URL as string;
    }

    // load()
    async function load() {
      useVersionService().getGet().then((ver) => {
        version.value = ver;

      });

      if (isLoadedNavigation) return;

      // Admin ise full yetki ver
      if (shared.user.isAdmin == true) {
        navigationRoutes.value = shared.navigation;
        await favoriteStore.load(navigationRoutes.value);
        return;
      }

      // Seçili firma
      const firmaAdi = route.params.firm;

      // Kullanıcının Seçili Firmaya Ait RoleItem ı
      const userRoleItem = shared.user.firmaRoles?.find(a => a.firmaKodu == firmaAdi) as UserFirmaRoleItem;
      if (userRoleItem) {
        // Kullanıcı RoleGrubuna ait roller (RoleGrup listesinden alınıyor.)
        const userRoleGroupId = userRoleItem.roleGroupId;
        const userRoleGroup: UserRoleGroupView = await useRoleService().getGetRoleGroup(userRoleGroupId as string) as UserRoleGroupView;

        if (userRoleGroup) {
         await loadNavigation(userRoleGroup.roles as string[]);

        } else {
          alert("Kullanıcıya ait RoleGrubu tanımlanmamış.");
        }
      } else {
        //if (!isLoadedNavigation)
        //alert(firmaAdi +  '  firması ile işlem yapma yetkiniz bulunmuyor.')
        //console.log(shared.user);
      }
        await favoriteStore.load(navigationRoutes.value);
    }


   async function loadNavigation(userRoles: string[]) {
      navigationRoutes.value = []; // Reset


      const moduleRoutes = shared.navigation as NModuleRoute[];


      moduleRoutes.forEach((mainRoute: NModuleRoute) => {

        const checkMainRoute = checkMainRouteRole(mainRoute, userRoles) as boolean;
        if (checkMainRoute) {
          const cloneMainRoute = getCloneMainRoute(mainRoute);
          const cloneChildren = getCloneChildrenRoutes(mainRoute, userRoles);


          cloneMainRoute.children = cloneChildren;
          if (cloneMainRoute.children.length == 0 && cloneMainRoute.component) {
            navigationRoutes.value.push(mainRoute);
          } else if (cloneMainRoute.children.length > 0) {
            navigationRoutes.value.push(cloneMainRoute);
          }
        }
      });

    }

    function checkMainRouteRole(mainRoute: NModuleRoute, userRoles: string[]): boolean {
      if (mainRoute.requiredRoles && mainRoute.requiredRoles.readRoles && mainRoute.requiredRoles.readRoles.length > 0) {
        for (let i = 0; i < mainRoute.requiredRoles?.readRoles.length; i++) {
          const readRole = mainRoute.requiredRoles?.readRoles[i];
          if (!userRoles.find(a => a == readRole))
            return false;
        }
      }
      return true;
    }

    function getCloneChildrenRoutes(mainRoute: NModuleRoute, userRoleIds: string[]) {
      const children = [] as NModuleRoute[];


      // Main Route Children >> children
      mainRoute.children?.forEach(childRoute => {
        const isExist = isModuleInRoles(childRoute.requiredRoles?.readRoles as string[], userRoleIds);
        if (isExist == true) {
          children.push(childRoute);
        } else if (isExist == undefined) {
          children.push(childRoute);
        }
      });
      return children;
    }

    function isModuleInRoles(requiredRoles: string[], userRoles: string[]) {

      const requiredRolesInExistUserRoles = requiredRoles?.every(requiredRole => userRoles && userRoles.includes(requiredRole));
      return requiredRolesInExistUserRoles;
    }


    function getCloneMainRoute(moduleRoute: NModuleRoute) {
      //alert(moduleRoute.displayName + moduleRoute.component)
      return {
        children: [],
        requiredRoles: moduleRoute.requiredRoles,
        displayName: moduleRoute.displayName,
        id: moduleRoute.id,
        order: moduleRoute.order,
        icon: moduleRoute.icon,
        component: moduleRoute.component
      } as NModuleRoute;
    }


    function toggleNavItem(routeId: string) {
      if (expandedNavItems.value.includes(routeId)) {
        expandedNavItems.value = expandedNavItems.value.filter(
          (n: string) => n !== routeId
        );
      } else {
        if (isSidebarCollapsed.value) {
          layout.toggleSidebar();
        }
        expandedNavItems.value.push(routeId);
      }
    }

    function openNavItem(route: NModuleRoute, e: MouseEvent) {
      const openedTab = layout.tabs.find(a => a.id == route.id);

      if (openedTab && !e.ctrlKey) {
        layout.selectTab(openedTab.key);
      } else {
        layout.openRouteAsTab(route);
      }
    }

    function isOpened(id: string) {
      const tab = layout.tabs.find((a: any) => a.id == id);
      return tab != undefined;
    }

    function mouseOver(e: any) {
      //layout.isSidebarCollapsed = false ;
    }

    function favoriteClick(e:MouseEvent,data:NModuleRoute) {
        e.preventDefault();
        e.stopImmediatePropagation();
        favoriteStore.toggle(data)

    }

      function allowDrop(e:any, child:NModuleRoute) {
          e.preventDefault();
      }

      function drag(e:any,child:NModuleRoute) {
          //console.log(e, tab)
          e.dataTransfer.setData("text", child.id);
          draggedId.value = child.id;
      }

      function dragEnter(e:any,child:NModuleRoute) {
          draggedId.value = child.id;
      }

      function dragEnd(e:any,child:NModuleRoute) {
          draggedId.value = "";
      }

      async function drop(ev:any,child:NModuleRoute) {
          ev.preventDefault();
          const sourceKey = ev.dataTransfer.getData("text");
          changeOrder(sourceKey,child.id);
          draggedId.value = "";
          await favoriteStore.save()
          //ev.target.appendChild(document.getElementById(data));
      }
      function changeOrder(sourceId:string, targetId:string){
        if (favoriteRoute.value.children){
          const source = favoriteRoute.value.children.findIndex(a => a.id == sourceId);
          const target = favoriteRoute.value.children.findIndex(a => a.id == targetId);
          move(source,target)
        }
      }

      function move  (from:number, to:number) {
        if (favoriteRoute.value.children){
          favoriteRoute.value.children.splice(to, 0, favoriteRoute.value.children.splice(from, 1)[0]);
        }
      }

      return {
      toggleSidebar: layout.toggleSidebar,
      openNavItem,
      firm: shared.firm,
      isSidebarCollapsed,
      navigationRoutes,
      expandedNavItems,
      toggleNavItem,
      selectedTabId,
      logo,
      firmLogo,
      isOpened,
      mouseOver,
      getLogo,
      logoUrl,
      version,
      favoriteClick,
        favoriteRoute,
        favoriteStore,
          allowDrop,
          drag,
          drop,
          draggedId,
          dragEnd,
          dragEnter
    };
  }
});
