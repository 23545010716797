
import { Colors } from "../../models";
import { defineComponent, computed } from "vue";
export default defineComponent({
  name: "NRadio",
  inheritAttrs: false,
  props: {
    type: {
      default: Colors.PRIMARY,
      type: String,
      validator: (value: Colors) =>
        [
          Colors.PRIMARY,
          Colors.SUCCESS,
          Colors.DANGER,
          Colors.WARNING,
          Colors.BLACK
        ].includes(value)
    },
    label: { type: String },
    subLabel: { type: String },
    pulse: { type: Boolean },
    disabled: { type: Boolean },
    modelValue: {},
    value: {}
  },
  setup(props, { emit }) {
    function check() {
      if (props.disabled) {
        return;
      }
      emit("update:modelValue", props.value);
    }
    const isChecked = computed(() => props.modelValue === props.value);
    return { check, isChecked };
  }
});
