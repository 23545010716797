
import {defineComponent, reactive, ref, toRefs} from "vue";
import NCard from "@/components/Card/NCard.vue";
import HizliSatisOdeme from "@/modules/HizliSatis/components/HizliSatisOdeme.vue";
import HizliSatisGenel from "@/modules/HizliSatis/components/HizliSatisGenel.vue";

export default defineComponent({
  components: {HizliSatisGenel, HizliSatisOdeme, NCard},
  setup() {
    const localState = reactive({
      selectedTab:ref(0)
    })


    return {
      ...toRefs(localState)
    }
  }
})
