import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_ReportViewerDialog = _resolveComponent("ReportViewerDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reportList, (report, i) => {
      return (_openBlock(), _createBlock(_component_NButton, _mergeProps(_ctx.$attrs, {
        size: _ctx.buttonSize,
        type: _ctx.buttonType,
        key: i,
        icon: "printer",
        click: ()=>_ctx.openReportViewer(report)
      }), {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(report.name) + " " + _toDisplayString(_ctx.label), 1)
        ]),
        _: 2
      }, 1040, ["size", "type", "click"]))
    }), 128)),
    (_ctx.showDialog)
      ? (_openBlock(), _createBlock(_component_ReportViewerDialog, {
          key: 0,
          "report-name": _ctx.selectedReportName,
          "onUpdate:reportName": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedReportName) = $event)),
          show: _ctx.showDialog,
          "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDialog) = $event)),
          "slient-print": _ctx.reportType !== _ctx.reportTypeModel.Uretim_Talimat || _ctx.reportType !== _ctx.reportTypeModel.Uretim_Sevk_Fisi,
          modelValue: _ctx.dataRef,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dataRef) = $event))
        }, null, 8, ["report-name", "show", "slient-print", "modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}