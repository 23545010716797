
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import { useN11ShipmentService } from "@/api/services/N11ShipmentService";
import NGrid from "@/components/Grid/NGrid.vue";
import NRefresher from "@/components/Refresher/NRefresher.vue";
import {useN11ShipmentServiceCache} from "@/api/servicesCache/N11ShipmentServiceCache";

export default defineComponent({
  components: { NRefresher },
  props: {
    modelValue:{
      type:Object
    }
  },
  setup(props,{emit}) {

    const localState = reactive({
      shipmentTemplates:ref([]),
      model:computed({
        get:()=> props.modelValue,
        set:(val)=> emit('update:modelValue', val)
      })
    });

    async function load(cacheDay:number){
      localState.shipmentTemplates = await useN11ShipmentServiceCache().getGetir(cacheDay);
    }



    load(365);

    return {
      ...toRefs(localState),
      load
    };
  }
});

