import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "dx-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_UretimSacFisFormUstBilgi = _resolveComponent("UretimSacFisFormUstBilgi")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_UretimSacFisFormKesim = _resolveComponent("UretimSacFisFormKesim")!
  const _component_UretimSacFisFormDilme = _resolveComponent("UretimSacFisFormDilme")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_DxEditing = _resolveComponent("DxEditing")!
  const _component_DxGroupPanel = _resolveComponent("DxGroupPanel")!
  const _component_DxExport = _resolveComponent("DxExport")!
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxLookup = _resolveComponent("DxLookup")!
  const _component_CariSelectBox = _resolveComponent("CariSelectBox")!
  const _component_DxTotalItem = _resolveComponent("DxTotalItem")!
  const _component_DxSummary = _resolveComponent("DxSummary")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!
  const _component_ReportButtons = _resolveComponent("ReportButtons")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    modelValue: _ctx.showRef,
    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.showRef) = $event)),
    width: "950px",
    "closable-mask": false
  }, {
    header: _withCtx(() => [
      _createVNode(_component_NGrid, {
        cols: "auto 1fr 1fr auto auto auto",
        gap: "8px",
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
          _createVNode(_component_NTag, {
            type: "info",
            filled: false
          }, {
            default: _withCtx(() => [
              _createElementVNode("b", null, _toDisplayString(_ctx.stokRef?.ad), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_NTag, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.stokRef.kod), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_NTag, null, {
            default: _withCtx(() => [
              _createTextVNode("Etiket: " + _toDisplayString(_ctx.stokRef.tipOzellikler?.etiket), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_NTag, null, {
            default: _withCtx(() => [
              _createTextVNode("Sipariş Miktarı: " + _toDisplayString(_ctx.stokRef.getAlisSiparisMiktar?.format(0, 2)) + " kg.", 1)
            ]),
            _: 1
          }),
          _createVNode(_component_NTag, {
            type: "info",
            filled: false
          }, {
            default: _withCtx(() => [
              _createElementVNode("b", null, "Toplam: " + _toDisplayString(_ctx.stokRef.getKalanMiktar?.format(0, 2)) + " kg.", 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    body: _withCtx(() => [
      _createVNode(_component_NLoadingPanel, {
        action: _ctx.load,
        title: "Form Yükleniyor.."
      }, {
        default: _withCtx(() => [
          _createVNode(_component_UretimSacFisFormUstBilgi, {
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
            stok: _ctx.stokRef,
            "onUpdate:stok": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.stokRef) = $event))
          }, null, 8, ["modelValue", "stok"]),
          _createVNode(_component_NFormGrid, {
            gap: "0",
            cols: "1fr",
            rows: "auto",
            title: "İşlem bilgileri"
          }, {
            actions: _withCtx(() => [
              _createVNode(_component_NFormField, {
                label: "İşlem seçiniz",
                "grid-cols": "auto 140px"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxSelectBox, {
                    items: _ctx.satirTipList,
                    value: _ctx.selectedIslem,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedIslem) = $event)),
                    displayExpr: "description",
                    valueExpr: "value"
                  }, null, 8, ["items", "value"])
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              (_ctx.selectedIslem === _ctx.satirTip.Sicak_Kesim || _ctx.selectedIslem === _ctx.satirTip.Soguk_Kesim)
                ? (_openBlock(), _createBlock(_component_UretimSacFisFormKesim, {
                    key: 0,
                    stok: _ctx.stokRef,
                    "onUpdate:stok": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.stokRef) = $event)),
                    store: _ctx.store,
                    "onUpdate:store": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.store) = $event)),
                    fis: _ctx.model,
                    "onUpdate:fis": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model) = $event)),
                    tip: _ctx.selectedIslem,
                    "onUpdate:tip": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectedIslem) = $event)),
                    aciklamaList: _ctx.aciklamaList,
                    "paketleme-list": _ctx.paketlemeList,
                    "is-update": _ctx.isUpdateMode()
                  }, null, 8, ["stok", "store", "fis", "tip", "aciklamaList", "paketleme-list", "is-update"]))
                : _createCommentVNode("", true),
              (_ctx.selectedIslem === _ctx.satirTip.Sicak_Dilme || _ctx.selectedIslem === _ctx.satirTip.Soguk_Dilme)
                ? (_openBlock(), _createBlock(_component_UretimSacFisFormDilme, {
                    key: 1,
                    stok: _ctx.stokRef,
                    "onUpdate:stok": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.stokRef) = $event)),
                    store: _ctx.store,
                    "onUpdate:store": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.store) = $event)),
                    fis: _ctx.model,
                    "onUpdate:fis": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model) = $event)),
                    tip: _ctx.selectedIslem,
                    "onUpdate:tip": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.selectedIslem) = $event)),
                    aciklamaList: _ctx.aciklamaList,
                    "paketleme-list": _ctx.paketlemeList,
                    "is-update": _ctx.isUpdateMode()
                  }, null, 8, ["stok", "store", "fis", "tip", "aciklamaList", "paketleme-list", "is-update"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_NFormGrid, {
            title: "Üretilen Saclar",
            cols: "1fr"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_DxDataGrid, {
                  width: "100%",
                  style: {"min-height":"250px"},
                  "data-source": _ctx.dataSource,
                  "onUpdate:dataSource": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.dataSource) = $event)),
                  onRowRemoving: _ctx.rowRemoving
                }, {
                  cariTemplate: _withCtx(({data}) => [
                    _createVNode(_component_CariSelectBox, {
                      id: data.data.cari.id,
                      "onUpdate:id": ($event: any) => ((data.data.cari.id) = $event),
                      "get-unvan": data.data.cari.name,
                      "onUpdate:getUnvan": ($event: any) => ((data.data.cari.name) = $event)
                    }, null, 8, ["id", "onUpdate:id", "get-unvan", "onUpdate:getUnvan"])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_DxEditing, {
                      "allow-updating": true,
                      mode: "cell",
                      "allow-deleting": true,
                      "use-icons": true
                    }),
                    _createVNode(_component_DxGroupPanel, { visible: false }),
                    _createVNode(_component_DxExport, { enabled: false }),
                    _createVNode(_component_DxColumn, {
                      caption: "Sil",
                      type: "buttons",
                      width: 40
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxButton, { name: "delete" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_DxColumn, {
                      "allow-editing": true,
                      width: 40,
                      caption: "Fire",
                      "data-field": "isFire"
                    }),
                    _createVNode(_component_DxColumn, {
                      "allow-editing": false,
                      width: 80,
                      caption: "Kaynak",
                      "data-field": "kaynakTip"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxLookup, {
                          "data-source": _ctx.kaynakTipList,
                          "display-expr": "description",
                          "value-expr": "value"
                        }, null, 8, ["data-source"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_DxColumn, {
                      "allow-editing": false,
                      width: 60,
                      caption: "Kalınlık",
                      "data-field": "kalinlik",
                      format: "#,##0.00"
                    }),
                    _createVNode(_component_DxColumn, {
                      "allow-editing": true,
                      width: 80,
                      caption: "Genişlik",
                      "data-field": "genislik",
                      format: "#,##0"
                    }),
                    _createVNode(_component_DxColumn, {
                      "allow-editing": true,
                      width: 80,
                      caption: "Uzunluk",
                      "data-field": "uzunluk",
                      format: "#,##0"
                    }),
                    _createVNode(_component_DxColumn, {
                      "allow-editing": true,
                      width: 60,
                      caption: "Adet",
                      "data-field": "adet",
                      format: _ctx.storeSettings.model.sacAdet?.format
                    }, null, 8, ["format"]),
                    _createVNode(_component_DxColumn, {
                      "allow-editing": true,
                      width: 80,
                      caption: "Tonaj",
                      "data-field": "tonaj",
                      format: "#,##0"
                    }),
                    _createVNode(_component_DxColumn, {
                      width: "150",
                      "data-field": "depoId",
                      caption: "Şube/Depo"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxLookup, {
                          "data-source": _ctx.depoList,
                          "display-expr": "depoSubeAd",
                          "value-expr": "depoId"
                        }, null, 8, ["data-source"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_DxColumn, {
                      "allow-editing": false,
                      width: 90,
                      caption: "Etiket",
                      "data-field": "stok.etiket"
                    }),
                    _createVNode(_component_DxColumn, {
                      "allow-editing": false,
                      width: 90,
                      caption: "Kod",
                      "data-field": "stok.kod"
                    }),
                    _createVNode(_component_DxColumn, {
                      "allow-editing": true,
                      width: 100,
                      caption: "Açıklama",
                      "data-field": "aciklama"
                    }),
                    _createVNode(_component_DxColumn, {
                      "allow-editing": true,
                      width: 100,
                      caption: "Paketleme",
                      "data-field": "paketleme"
                    }),
                    _createVNode(_component_DxColumn, {
                      "allow-editing": true,
                      width: 300,
                      caption: "Cari",
                      "data-field": "cari.name",
                      editCellTemplate: "cariTemplate"
                    }),
                    _createVNode(_component_DxColumn, {
                      "allow-editing": true,
                      width: 90,
                      caption: "Tip",
                      "data-field": "tip"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxLookup, {
                          "data-source": _ctx.satirTipList,
                          "display-expr": "description",
                          "value-expr": "value"
                        }, null, 8, ["data-source"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_DxColumn, {
                      "allow-editing": true,
                      width: 80,
                      caption: "TabanId",
                      "data-field": "tabanId"
                    }),
                    _createVNode(_component_DxColumn, {
                      "allow-editing": true,
                      width: 80,
                      caption: "Taban Tonaj",
                      "data-field": "tabanTonaj",
                      format: "#,##0"
                    }),
                    _createVNode(_component_DxColumn, {
                      width: 50,
                      caption: "id",
                      "data-field": "id"
                    }),
                    _createVNode(_component_DxSummary, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxTotalItem, {
                          column: "tonaj",
                          "summary-type": "sum",
                          "value-format": "#,##0",
                          "display-format": "{0}"
                        }),
                        _createVNode(_component_DxTotalItem, {
                          column: "adet",
                          "summary-type": "sum",
                          "value-format": _ctx.storeSettings.model.sacAdet?.format,
                          "display-format": "{0}"
                        }, null, 8, ["value-format"]),
                        _createVNode(_component_DxTotalItem, {
                          column: "uzunluk",
                          "summary-type": "sum",
                          "value-format": "#,##0",
                          "display-format": "{0}"
                        }),
                        _createVNode(_component_DxTotalItem, {
                          column: "tip",
                          "summary-type": "count",
                          "value-format": "#,##0",
                          "display-format": "{0}"
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["data-source", "onRowRemoving"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["action"])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_ReportButtons, {
        onClose: _ctx.close,
        "button-size": "small",
        label: "Yazdır",
        "button-type": "",
        "report-click": _ctx.saveAndPrint,
        "report-type": _ctx.reportType.SacUretimFis,
        data: _ctx.reportData,
        "onUpdate:data": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.reportData) = $event))
      }, null, 8, ["onClose", "report-click", "report-type", "data"]),
      (_ctx.isUpdateMode())
        ? (_openBlock(), _createBlock(_component_NButton, {
            key: 0,
            type: "danger",
            size: "small",
            icon: "trash",
            click: _ctx.remove
          }, {
            default: _withCtx(() => [
              _createTextVNode("Sil")
            ]),
            _: 1
          }, 8, ["click"]))
        : _createCommentVNode("", true),
      _createVNode(_component_NButton, {
        type: "warning",
        size: "small",
        icon: "x-circle",
        onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.showRef = false))
      }, {
        default: _withCtx(() => [
          _createTextVNode("İptal")
        ]),
        _: 1
      }),
      _createVNode(_component_NButton, {
        type: "success",
        size: "small",
        icon: "save",
        click: ()=>_ctx.save(true)
      }, {
        default: _withCtx(() => [
          _createTextVNode("Kaydet")
        ]),
        _: 1
      }, 8, ["click"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}