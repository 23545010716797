import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28dbce48"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "resizeContent",
  class: "dx-card",
  style: {"height":"100%"}
}
const _hoisted_2 = { class: "miktar-template" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "miktar-text" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FiyatGruplariSelectBox = _resolveComponent("FiyatGruplariSelectBox")!
  const _component_NDxStateStoring = _resolveComponent("NDxStateStoring")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_DxStateStoring = _resolveComponent("DxStateStoring")!
  const _component_DxEditing = _resolveComponent("DxEditing")!
  const _component_DxExport = _resolveComponent("DxExport")!
  const _component_DxGroupPanel = _resolveComponent("DxGroupPanel")!
  const _component_DxScrolling = _resolveComponent("DxScrolling")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxLookup = _resolveComponent("DxLookup")!
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NTagList = _resolveComponent("NTagList")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_NCard = _resolveComponent("NCard")!

  return (_openBlock(), _createBlock(_component_NCard, _mergeProps(_ctx.$attrs, { title: "Satırlar" }), {
    header: _withCtx(() => []),
    actions: _withCtx(() => [
      _createVNode(_component_NGrid, {
        cols: "auto auto",
        gap: "8px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_FiyatGruplariSelectBox, {
            modelValue: _ctx.store.selectedTab.cariFiyatGrupId,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.store.selectedTab.cariFiyatGrupId) = $event)),
            oran: _ctx.store.selectedTab.fiyatGrupOran,
            "onUpdate:oran": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.store.selectedTab.fiyatGrupOran) = $event)),
            tutar: _ctx.store.selectedTab.fiyatGrupTutar,
            "onUpdate:tutar": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.store.selectedTab.fiyatGrupTutar) = $event))
          }, null, 8, ["modelValue", "oran", "tutar"]),
          _createVNode(_component_NDxStateStoring, {
            "data-grid-ref": _ctx.hizliSatisSatirlarDataGrid,
            "menu-align": "bottom-right"
          }, null, 8, ["data-grid-ref"])
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DxDataGrid, {
          focusStateEnabled: false,
          ref: "hizliSatisSatirlarDataGrid",
          "data-source": _ctx.store.selectedTab.dataSource,
          style: {"height":"100%"},
          onRowUpdated: _ctx.rowUpdated
        }, {
          miktarTemplate: _withCtx(({data}) => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_NGrid, {
                cols: "20px auto 20px",
                style: {"align-items":"center"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "miktar-icon miktar-icon-minus",
                    onClick: (e)=>_ctx.miktarChange(e, data.data,-1)
                  }, [
                    _createVNode(_component_NIcon, {
                      type: "minus-circle",
                      size: "20px",
                      style: {"margin":"0px 0"},
                      stroke: "#FFF"
                    })
                  ], 8, _hoisted_3),
                  _createElementVNode("div", _hoisted_4, _toDisplayString(data.data.miktar), 1),
                  _createElementVNode("div", {
                    class: "miktar-icon miktar-icon-plus",
                    onClick: (e)=>_ctx.miktarChange(e,data.data,1)
                  }, [
                    _createVNode(_component_NIcon, {
                      type: "plus-circle",
                      size: "20px",
                      style: {"margin":"0px 0"},
                      stroke: "#FFF"
                    })
                  ], 8, _hoisted_5)
                ]),
                _: 2
              }, 1024)
            ])
          ]),
          carpantemplate: _withCtx(({data}) => [
            _createElementVNode("div", null, [
              _createVNode(_component_NTag, {
                type: data.data.carpan != 1  ? 'primary' : 'neutral',
                filled: true
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(data.data.carpan) + " " + _toDisplayString(data.data.birim), 1)
                ]),
                _: 2
              }, 1032, ["type"])
            ])
          ]),
          no: _withCtx(({data}) => [
            _createElementVNode("div", null, _toDisplayString(data.rowIndex + 1), 1)
          ]),
          iskontolar: _withCtx(({data}) => [
            _createVNode(_component_NTagList, {
              type: "number",
              modelValue: data.data.iskontolar,
              "onUpdate:modelValue": ($event: any) => ((data.data.iskontolar) = $event)
            }, null, 8, ["modelValue", "onUpdate:modelValue"])
          ]),
          sil: _withCtx(({data}) => [
            _createVNode(_component_NButton, {
              size: "small",
              style: {"padding":"4px"},
              icon: "trash",
              onClick: ($event: any) => (_ctx.satirSil(data.data))
            }, null, 8, ["onClick"])
          ]),
          birimEditTemplate: _withCtx(({data}) => [
            _createVNode(_component_DxSelectBox, {
              items: data.data.birimler,
              onValueChanged: ($event: any) => (_ctx.changedBirim($event,data.data)),
              placeholder: data.data.birim,
              "show-clear-button": true,
              valueExpr: "birim",
              displayExpr: "birim"
            }, null, 8, ["items", "onValueChanged", "placeholder"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_DxStateStoring, {
              enabled: true,
              type: "custom",
              "custom-load": () =>  _ctx.loadState()
            }, null, 8, ["custom-load"]),
            _createVNode(_component_DxEditing, {
              "allow-updating": true,
              mode: "cell",
              "allow-deleting": true,
              "use-icons": true
            }),
            _createVNode(_component_DxExport, { enabled: false }),
            _createVNode(_component_DxGroupPanel, { visible: false }),
            _createVNode(_component_DxScrolling, {
              scrollByContent: true,
              "use-native": false,
              mode: "standard"
            }),
            _createVNode(_component_DxColumn, {
              "allow-editing": false,
              width: "36px",
              caption: "Sil",
              "data-field": "sil",
              "cell-template": "sil",
              fixed: true
            }),
            _createVNode(_component_DxColumn, {
              "allow-editing": true,
              width: "150px",
              caption: "Stok / Hizmet Adı",
              "data-field": "ad",
              fixed: true
            }),
            _createVNode(_component_DxColumn, {
              "allow-editing": true,
              width: "100px",
              caption: "Miktar",
              "data-field": "miktar",
              "cell-template": "miktarTemplate",
              "editor-options": {min:0}
            }),
            _createVNode(_component_DxColumn, {
              "allow-editing": true,
              width: "80",
              editCellTemplate: "birimEditTemplate",
              caption: "Birim",
              "data-field": "birim"
            }),
            _createVNode(_component_DxColumn, {
              "allow-editing": true,
              width: "120",
              caption: "Fiyat (KDV Dahil)",
              "data-field": "fiyat",
              "data-type": "number",
              format: (a)=>a.format(2,2)
            }, null, 8, ["format"]),
            _createVNode(_component_DxColumn, {
              "allow-editing": true,
              width: "80px",
              caption: "Kdv",
              "data-field": "kdv"
            }),
            _createVNode(_component_DxColumn, {
              "allow-editing": true,
              width: "80px",
              caption: "İskontolar",
              "data-field": "iskontolar",
              "edit-cell-template": "iskontolar"
            }),
            _createVNode(_component_DxColumn, {
              "allow-editing": true,
              width: "130px",
              caption: "Barkod",
              "data-field": "barkod"
            }),
            _createVNode(_component_DxColumn, {
              "allow-editing": true,
              width: "80px",
              caption: "Kod",
              "data-field": "kod"
            }),
            _createVNode(_component_DxColumn, {
              width: "50",
              cellTemplate: "carpantemplate",
              caption: "Çarpan",
              "data-field": "carpan",
              "allow-editing": false
            }),
            _createVNode(_component_DxColumn, {
              "allow-editing": true,
              width: "150",
              "data-field": "depoId",
              caption: "Şube/Depo"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxLookup, {
                  "data-source": _ctx.depoList,
                  "display-expr": "depoSubeAd",
                  "value-expr": "depoId"
                }, null, 8, ["data-source"])
              ]),
              _: 1
            }),
            _createVNode(_component_DxColumn, {
              "allow-editing": true,
              width: "80px",
              caption: "Plasiyer",
              "data-field": "plasiyerId"
            }),
            _createVNode(_component_DxColumn, {
              "allow-editing": true,
              width: "120px",
              caption: "Açıklama",
              "data-field": "aciklama"
            })
          ]),
          _: 1
        }, 8, ["data-source", "onRowUpdated"])
      ])
    ]),
    _: 1
  }, 16))
}