
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import {FiyatGrupView} from "@/api/models/FiyatGrupView";
import {useListelerService} from "@/api/services/ListelerService";

export default defineComponent({
  components: {},
  props: {
    modelValue: {
      type: String,
      default: 'null',
    },
    // oran: {
    //   type: Number,
    //   default: 0,
    // },
    // tutar:{
    //   type:Number,
    //   default:0
    // }
  },
  setup(props, {emit}) {
    const localState = reactive({
      model: computed({
        get: () => props.modelValue,
        set: val => emit('update:modelValue', val),
      }),
      fiyatGrupList: ref([] as FiyatGrupView[])
    })

    watch(() => localState.model, (val) => oranChange());

    function  onValueChanged(e:any){

      if (e.value && e.value.length > 5) {
        const result = localState.fiyatGrupList?.find(a => a.id == e.value) as FiyatGrupView;
        if (result){

          emit('onValueChanged', result);

        }
      }else {
        emit('onValueChanged', { ad : "" , id : '' , oran : 0 , tutar : 0 } as FiyatGrupView);
      }
      // if (e && e.value && e.value.length > 0 ){
      // //
      // }else{
      //   emit("update:modelValue" , "");
      //   emit("update:oran" , 0 );
      //   emit("update:tutar" , 0 );
      //   emit("onValueChanged");
      // }


    }

    load()

    async function load() {
      localState.fiyatGrupList = await useListelerService().getFiyatGruplari();
      if (localState.fiyatGrupList && localState.fiyatGrupList.length && localState.fiyatGrupList.length > 0)
        localState.fiyatGrupList?.forEach(a =>  a.ad =`${a.ad} (%${a.oran}, ${a.tutar}₺)`)
      oranChange()
    }

    function oranChange() {


      if (localState.model && localState.model.length > 5) {
        const result = localState.fiyatGrupList?.find(a => a.id == localState.model);
        if (result){


          emit('update:oran', result.oran);
          emit('update:tutar' , result.tutar)
        }
      }
    }

    return {
      ...toRefs(localState),
      onValueChanged
    };
  }
});
