
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import {SacUretimStokTreeView} from "@/api/models/SacUretimStokTreeView";
import {useSacUretimStokService} from "@/api/services/SacUretimStokService";
import NButton from "@/components/Button/NButton.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import NInput from "@/components/Input/NInput.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import {useSacUretimFisService} from "@/api/services/SacUretimFisService";
import {GenelUtils} from "@/modules/Ortak/utils/GenelUtils";
import {sum} from "lodash";
import {StokEntity} from "@/api/models/StokEntity";
import {usePopupService} from "@/services/popupService";

export default defineComponent({
    computed: {
        StokEntity() {
            return StokEntity
        }
    },
    methods: {sum},
    components: {NGrid, NInput, NLoadingPanel, NButton},
    props: {
        id: {
            type: String,
        },
        show: {
            type: Boolean,
            default: false
        }
    },

    setup(props, {emit}) {
        const localState = reactive({

            etiket: ref(),
            showRef: computed({
                get: () => props.show,
                set: (val) => emit("update:show", val)
            }),
            treeList: ref(),
            etiketList: ref([]),

            model: ref([] as SacUretimStokTreeView[]),
        })

        watch(() => localState.etiket, (deger) => {
            if (deger) {
                changeRecursive(deger)
            }
        })

        async function load() {
            localState.model = await useSacUretimStokService().getTreeView(props.id as string);
            if (localState.model[0].stok == null)
            {
                usePopupService().pushWarningNotification({
                    title: "Sac Üretim",
                    body:  "Stoğa ait üretim talimatı bulunamadı!"
                });
                localState.showRef = false;
            }

        }

        function changeRecursive(etiket: string) {
            if (localState.model && localState.model.length > 0) {
                setEtiket(localState.model[0], etiket)
                localState.treeList?.instance?.refresh();
            }

        }

        function setEtiket(model: any, suffix: string) {
            model.name = suffix;
            if (model.childs && model.childs.length > 0) {
                for (let i = 0; i < model.childs.length; i++) {
                    if (model.isParent){
                        setEtiket(model.childs[i], suffix + "_" + (i + 1))
                    }else {
                        setEtiket(model.childs[i],suffix )
                    }

                }
            }
        }

        function fillEtikler(model: any, list: any[]) {
            if (model.childs && model.childs.length > 0) {
                model.childs.forEach((item: any) => fillEtikler(item, list))
            }

            list.push({id: model.stok.id, name: model.name, fisId: model.fisId, fisSatirId:model.fisSatirId})
        }

        async function save() {

            if (await GenelUtils.onayDiyalogu(`Girdiğiniz etiketi listedeki tüm stoklara uygulamak istiyor musunuz ?<br/><br/>Değiştirilecek Etiket : <b>${localState.etiket}</b>`, "Etiket Değiştirme Onayı")) {
                localState.etiketList = [];
                if (localState.model && localState.model.length > 0) {
                    fillEtikler(localState.model[0], localState.etiketList)
                    if (localState.etiketList && localState.etiketList.length > 0)
                        await useSacUretimStokService().putStokEtiketGuncelle(localState.etiketList)
                }
                localState.showRef = false;
            }
        }

        return {
            ...toRefs(localState),
            load,
            save
        }
    }
})
