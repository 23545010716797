
import { defineComponent, computed } from "vue";
export default defineComponent({
  name: "NLoading",
  props: {
    stroke: { default: 6, type: Number },
    radius: { default: 32, type: Number },
    color: { default: "#09203F", type: String }
  },
  setup(props) {
    const normalizedRadius = computed(() => props.radius - props.stroke / 2);
    return { normalizedRadius };
  }
});
