import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8cee5c48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-field-container" }
const _hoisted_2 = {
  key: 0,
  style: {"display":"grid","grid-template-columns":"1fr auto"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.fill ? 'fill' : ''),
    style: _normalizeStyle({ gridColumn: 'span ' + _ctx.span })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _mergeProps(_ctx.$attrs, {
        class: "form-field-grid",
        style: { gridTemplateColumns: _ctx.gridCols }
      }), [
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
              _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
              _renderSlot(_ctx.$slots, "label", {}, undefined, true)
            ]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 16)
    ])
  ], 6))
}