
import { computed, defineComponent, ref } from "vue";
import { Colors } from "@/models";
import NCheckbox from "@/components/Checkbox/NCheckbox.vue";
export default defineComponent({
  name: "NTag",
  components: { NCheckbox },
  props: {
    type: {
      default: Colors.NEUTRAL,
      // validator: (value: Colors) =>
      //     [
      //       Colors.PRIMARY,
      //       Colors.SUCCESS,
      //       Colors.DANGER,
      //       Colors.WARNING,
      //       Colors.BLACK,
      //       Colors.NEUTRAL
      //     ].includes(value)
    },
    closable: { type: Boolean },
    filled: { type: Boolean },
    modelValue:{type:Boolean, default:undefined}
  },
  setup(props, { emit }) {
    const show = ref(true);
    const model = computed({
      get:()=> props.modelValue,
      set:(val)=> emit('update:modelValue',val)
    })
    function close() {
      if (!props.closable) {
        return;
      }

      //show.value = false;
      emit("close", false);
    }

    function toggle(val:any){
      emit('toggle',val)

    }

    return { show, close,model,toggle };
  }
});
