import {defineStore} from "pinia";
import {SubeEntity} from "@/api/models/SubeEntity";
import {useSubeService} from "@/api/services/SubeService";
import {DepoFlatView} from "@/api/models/DepoFlatView";
import {IdName} from "@/api/models/IdName";
import {DepoIdName} from "@/api/models/DepoIdName";

export const useCachedSubeDepoService = defineStore({
    state: () => ({
        subeUserList: [] as SubeEntity[],
        depoUserList: [] as DepoFlatView[],
        listeSube: [] as IdName[],
        listDepo : [] as DepoIdName[],
    }),
    id: "useCachedSubeDepoService",
    actions: {
        async getUserSubeList(): Promise<SubeEntity[]> {
            if (this.subeUserList?.length == 0) {
                this.subeUserList = await useSubeService().getUserSubeList();
                return this.subeUserList
            }
            return this.subeUserList;
        },
        async getUserDepoList(): Promise<DepoFlatView[]> {
            if (this.depoUserList?.length == 0) {
                this.depoUserList = await useSubeService().getUserDepoList();
                return this.depoUserList
            }
            return this.depoUserList;
        },
        async getSubeList(): Promise<IdName[]> {
            if (this.listeSube?.length == 0) {
                this.listeSube = await useSubeService().getListeIdName();
                return this.listeSube;
            }
            return this.listeSube;
        },
        async refreshCachedSubeList(): Promise<any> {
            this.listeSube = await useSubeService().getListeIdName();
        },
        async getDepoList(): Promise<IdName[]> {
            if (this.listDepo?.length == 0) {
                this.listDepo = await useSubeService().getDepolar();
                return this.listDepo;
            }
            return this.listDepo;
        }
    }
})
