
import {computed, defineComponent, onActivated, reactive, ref, toRefs} from "vue";
import { StokHareketEntity } from "@/api/models/StokHareketEntity";
import FaturaSatirItemExtend from "@/modules/Fatura/models/FaturaSatirItemExtend";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import { FaturaHareketTip } from "@/api/models/FaturaHareketTip";
import { useStokHareketService } from "@/api/services/StokHareketService";
import NGrid from "@/components/Grid/NGrid.vue";
import NTag from "@/components/Tag/NTag.vue";
import NFormField from "@/components/FormField/NFormField.vue";

import { DovizList } from "@/api/models/Doviz";
import { useFaturaService } from "@/api/services/FaturaService";
import { useFaturaRaporService } from "@/api/services/FaturaRaporService";
import { FaturaSatirItem } from "@/api/models/FaturaSatirItem";
import {DevExtremeUtils} from "@/devextreme/DevExtremeUtils";

export default defineComponent({
  components: { NFormField, NTag, NGrid, NLoadingPanel },
  props: {
    cariId: {
      type: String
    },
    tip: {
      type: Number
    },
    genislik: {
      type: Boolean
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      cariIdRef: computed(() => props.cariId as string),
      tipRef: computed(() => props.tip as FaturaHareketTip),
      cariOncekiStoklar: ref([] as FaturaSatirItem[]),
      genisListe: computed({
        get: () => props.genislik as boolean,
        set: (val) => emit("update:genislik", val)
      }),
      faturaHareketTip: FaturaHareketTip,
      dovizTipList: DovizList,
        faturaOncekiStoklarDataGrid:ref()
    });


    async function load() {

      if (localState.cariIdRef && localState.cariIdRef.length > 0) {
        switch (localState.tipRef) {
          case FaturaHareketTip.AlisIadeFaturasi :
            localState.cariOncekiStoklar = await useFaturaRaporService().getCariOncekiStoklar(FaturaHareketTip.AlisFaturasi, localState.cariIdRef);
            break;
          case  FaturaHareketTip.SatisIadeFaturasi :
            localState.cariOncekiStoklar = await useFaturaRaporService().getCariOncekiStoklar(FaturaHareketTip.SatisFatura, localState.cariIdRef);
            break;
          case  FaturaHareketTip.AlisFaturasi :
            localState.cariOncekiStoklar = await useFaturaRaporService().getCariOncekiStoklar(FaturaHareketTip.AlisFaturasi, localState.cariIdRef);
            break;
          case  FaturaHareketTip.SatisFatura :
            localState.cariOncekiStoklar = await useFaturaRaporService().getCariOncekiStoklar(FaturaHareketTip.SatisFatura, localState.cariIdRef);
            break;
        }

      }

    }


    function dblClick(data: StokHareketEntity) {
      if (data) {

        emit("dblClick", data);

      }


    }

    function getName() {
      switch (localState.tipRef) {
        case FaturaHareketTip.AlisIadeFaturasi :
          return "En Son Alınan Stoklar";
        case  FaturaHareketTip.SatisIadeFaturasi :
          return "En Son Satılan Stoklar";
        case  FaturaHareketTip.AlisFaturasi :
          return "En Son Alınan Stoklar";
        case  FaturaHareketTip.SatisFatura :
          return "En Son Satılan Stoklar";


      }
      return "";
    }
      onActivated(() => {
          DevExtremeUtils.updateScroll(localState.faturaOncekiStoklarDataGrid);
      });

    return {
      ...toRefs(localState),
      dblClick,
      load,
      getName
    };
  }
});
