
import {defineComponent, reactive, ref, toRefs, watch} from "vue";
import {useStokService} from "@/api/services/StokService";
import {useStokKategoriService} from "@/api/services/StokKategoriService";
import {StokUtils} from "@/modules/Stok/utils/StokUtils";
import {StokKategoriFlatListView} from "@/api/models/StokKategoriFlatListView";
import StokForm_Aciklamalar from "@/modules/Stok/components/StokForm_Aciklamalar.vue";
import StokForm_Varyantlar from "@/modules/Stok/components/StokForm_Varyantlar.vue";
import StokForm_Birimler from "@/modules/Stok/components/StokForm_Birimler.vue";
import StokForm_GenelBilgiler from "@/modules/Stok/components/StokForm_GenelBilgiler.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NLoading from "@/components/Loading/NLoading.vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import StokForm_Fiyatlar from "@/modules/Stok/components/StokForm_Fiyatlar.vue";
import StokForm_Pazaryerleri from "@/modules/Stok/components/StokForm_Pazaryerleri.vue";
import StokForm_Ureticiler from "@/modules/Stok/components/StokForm_Ureticiler.vue";
import StokForm_Devirler from "@/modules/Stok/components/StokForm_Devirler.vue";
import {SubeEntity} from "@/api/models/SubeEntity";
import {useSubeService} from "@/api/services/SubeService";
import {StokView} from "@/api/models/StokView";
import {useStokSettingsStore} from "@/modules/Stok/store/StokSettingsStore";
import {useXmlImporterService} from "@/api/services/XmlImporterService";
import {StokUreticiItem} from "@/api/models/StokUreticiItem";
import {XmlImporterConfigEntity} from "@/api/models/XmlImporterConfigEntity";
import {ImageManagementServiceUtil} from "@/api/uploaders/ImageManagementServiceUtil";
import {useFileSettingsServiceCache} from "@/api/servicesCache/FileSettingsServiceCache";
import NCard from "@/components/Card/NCard.vue";
import {useStokHareketService} from "@/api/services/StokHareketService";
import {StokDevirView} from "@/api/models/StokDevirView";
import OzellikForm from "@/modules/StokOzellik/components/OzellikForm.vue";

export default defineComponent({
    components: {
        OzellikForm,

        StokForm_Varyantlar,
        StokForm_Ureticiler,
        StokForm_Pazaryerleri,
        NModalDialog,
        NLoading,
        NFormGrid,
        StokForm_Aciklamalar,
        StokForm_Birimler,
        StokForm_GenelBilgiler
    },
    props: {
        showForm: {
            type: Boolean,
            default: true
        },
        isClone: {
            type: Boolean
        },
        stokId: {
            type: String
        },
        stokView: {
            type: Object
        },
        xmlConfig: {
            type: XmlImporterConfigEntity
        },
        xmlDataId: {
            type: String

        }
    },
    setup(props, {emit}) {
        const localState = reactive({
            model: ref({} as StokView),
            selectedTab: ref(0),
            otomatikBarkod: ref(Date.now().toString()),
            tabs: ref([
                {label: "Genel"},
                {label: "Açıklamalar"},
                {label: "Özellikler"},
                {label: "Birimler"},
                {label: "Varyantlar"},
                {label: "Pazaryerleri"},
                {label: "Üreticiler"},
            ]),
            kategoriler: ref([] as StokKategoriFlatListView[]),
            showFormRef: ref(true),
            showOzellikForm: ref(true),
            isLoading: ref(true),
            subeler: ref([] as SubeEntity[]),
            settingsStore: useStokSettingsStore(),
             serverUrl:ref()

        });

        watch(
            () => props.showForm,
            newVal => {
                localState.showFormRef = newVal;
            }
        );

        watch(
            () => localState.showFormRef,
            newVal => {
                emit("update:showForm", newVal);
            }
        );

        async function loadForm() {

            localState.isLoading = true;
            //localState.kategoriler = await useStokKategoriService().getFlatList();
            localState.serverUrl = await useFileSettingsServiceCache().serverUrl
            localState.subeler = await useSubeService().getListe()
            const stokViewNew = props.stokView as StokView
            if (props.stokId && props.stokId.length > 0) { // update
                localState.model = await useStokService().getGetirView(props.stokId);
                if (localState.model && localState.model.items) {
                    localState.model.items.forEach((item: any) => {
                        if (!item.resimler)
                            item.resimler = []
                    })
                }
                if (stokViewNew) { // aktarılan stoğun güncellenmesi
                    localState.model.alisFiyat = stokViewNew.alisFiyat

                    // Olmayan Üretici bul
                    const cariId = props.xmlConfig?.cari?.id
                    const uretici = localState.model.ureticiler?.findIndex(a => a.cari?.id == cariId)
                    //console.log("cari:", cariId, "uretici:", uretici)
                    if (uretici == -1) {
                        localState.model.ureticiler?.push({
                            cari: props.xmlConfig?.cari,
                            kod: stokViewNew.kod,
                            barkod: stokViewNew.barkod,
                            stokAdi: stokViewNew.ad,
                        })
                    }

                }

            } else { // insert
                if (props.stokView) {
                    localState.model = props.stokView;
                } else {
                    localState.model = await StokUtils.newStok();
                    localState.model.satisDoviz = localState.settingsStore.model.satisDoviz;
                    localState.model.alisDoviz = localState.settingsStore.model.alisDoviz;
                }

                if (props.xmlDataId && props.xmlDataId?.length > 0) {
                    localState.model.devirDepoId = props.xmlConfig?.depoId
                }
            }
            if (props.isClone == true) {
                localState.model.id = '';
            }

            localState.isLoading = false;
        }

        async function save() {
            await uploadUrlImages();
            if (!localState.model.barkod) {
                localState.model.barkod = localState.otomatikBarkod;
            } else if (localState.model.barkod.length < 1) {
                localState.model.barkod = localState.otomatikBarkod;
            }
            if (props.xmlDataId && props.xmlDataId.length > 0) {
                const stoklar = await useXmlImporterService().postSaveStok(localState.model, props.xmlDataId);
                await saveStokDevir(stoklar[0].id as string)
                emit("stokEntityAddedFromXml", stoklar[0]); // StokFlatView
            } else {
                if (localState.model.id && localState.model.id.length > 0) {
                    await useStokService().putGuncelle(localState.model);
                } else {
                    const stoklar = await useStokService().postEkle(localState.model);
                    await saveStokDevir(stoklar[0].id as string)
                }
            }
            localState.showFormRef = false;
        }

        async function remove() {
            if (confirm("Stoğu silmek istiyor musunuz?")) {
                await useStokService().deleteSil(localState.model.id as string);
                localState.showFormRef = false;
            }
        }

        async  function uploadUrlImages(){
            if (localState.model.resimler) {
                for (let i = 0; i < localState.model.resimler.length; i++) {
                    const resim = localState.model.resimler[i]
                    if (resim?.startsWith('http://') || resim?.startsWith('https://')){
                        const result = await ImageManagementServiceUtil.uploadImageFromUrl(resim);
                        //console.log(result)
                        localState.model.resimler.splice(i,1,result.name)
                    }
                }
                
                for (const resim of localState.model.resimler) {
//
                }
            }
        }

        loadForm();

        async function saveStokDevir(stokId: string) {
            if (localState.model.devirMiktar && localState.model.devirMiktar > 0) {
                const stokDevirView: StokDevirView = {};
                stokDevirView.devirler = [{
                    miktar: localState.model.devirMiktar,
                    depoId: localState.model.devirDepoId,
                    stokId: stokId
                }];
                stokDevirView.tarih = new Date().toISOString();
                await useStokHareketService().postIlkDevir(stokDevirView);
            }
        }

        return {
            ...toRefs(localState),
            remove,
            save,
        };
    }
});
