
import { defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import NViewGrid from "@/components/ViewGrid/NViewGrid.vue";
import NButtonGroup from "@/components/Button/NButtonGroup.vue";
import NButton from "@/components/Button/NButton.vue";
import NViewToolbar from "@/components/Toolbar/NToolbar.vue";
import OdemeTaksitHareketleri from "@/modules/OdemeTahsilat/components/OdemeTaksitHareketleri.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import CariDekontDialog from "@/modules/Cari/dialogs/CariDekontDialog.vue";
import { MenuFormType } from "@/modules/Ortak/models/Menu/MenuFormType";
import { CariHareketTip } from "@/api/models/CariHareketTip";
import { MenuRefrenceType } from "@/modules/Ortak/models/Menu/MenuRefrenceType";
import { CekSenetFlatView } from "@/api/models/CekSenetFlatView";
import { CekSenetDurum } from "@/api/models/CekSenetDurum";
import { GenelUtils } from "@/modules/Ortak/utils/GenelUtils";
import { alert } from "devextreme/ui/dialog";
import { useCekSenetService } from "@/api/services/CekSenetService";
import CekSenetHareketDialog from "@/modules/CekSenet/dialogs/CekSenetHareketDialog.vue";
import { useFirmaCekSenetService } from "@/api/services/FirmaCekSenetService";
import { FirmaCekSenetFlatView } from "@/api/models/FirmaCekSenetFlatView";
import KasaDekontDialog from "@/modules/Kasa/dialogs/KasaDekontDialog.vue";
import { KasaHareketTip } from "@/api/models/KasaHareketTip";
import BankaDekontDialog from "@/modules/Banka/dialogs/BankaDekontDialog.vue";
import { BankaHareketTip } from "@/api/models/BankaHareketTip";
import KrediKartiDekontDialog from "@/modules/KrediKarti/dialogs/KrediKartiDekontDialog.vue";
import { KrediKartiHareketTip } from "@/api/models/KrediKartiHareketTip";
import { useBankaService } from "@/api/services/BankaService";
import { useBankaHareketService } from "@/api/services/BankaHareketService";
import { TaksitPlanEntity } from "@/api/models/TaksitPlanEntity";
import { useTaksitPlanService } from "@/api/services/TaksitPlanService";
import { TaksitPlanDonemTip } from "@/api/models/TaksitPlanDonemTip";
import NCheckbox from "@/components/Checkbox/NCheckbox.vue";
import NotEklemeDialog from "@/modules/OdemeTahsilat/dialogs/NotEklemeDialog.vue";

export default defineComponent({
  components: {
    NotEklemeDialog,
    NCheckbox,
    KrediKartiDekontDialog,
    BankaDekontDialog,
    KasaDekontDialog,
    CekSenetHareketDialog,
    CariDekontDialog,
    NGrid,
    OdemeTaksitHareketleri,
    NViewToolbar,
    NButton,
    NButtonGroup,
    NViewGrid
  },
  props: {},
  setup() {
    const localState = reactive({
      odemeler: ref([] as TaksitPlanEntity[]),
      tahsilatlar: ref([] as TaksitPlanEntity[]),
      odemeFiltre: ref(),
      tahsilatFiltre: ref(),
      kapatilmisTaksitlerRef: ref(false),
      hizmetMasrafId: ref("" as string),
      taksitPlanId: ref("" as string),
      donemTip: TaksitPlanDonemTip,
      isOdemeTahsilat: ref(false),
      taksitPlanEntity: ref({} as TaksitPlanEntity),

      notEkleDialogShow: ref(false),

      //ortak
      menuRef: ref({} as MenuModel),
      dekontCariId: ref("" as string),
      tutar: ref(0 as number),

      // Cari
      cariDekontDialogShow: ref(false),


      // Müşteri Çek Senet
      cekSenetlerFlatView: ref([] as CekSenetFlatView[]),
      cekSenetDurum: CekSenetDurum,
      cekSenetHareketDialogShow: ref(false),


      // kasa
      kasaDekontShowDialog: ref(false as boolean),
      kasaId: ref("" as string),


      // banka

      bankaDekontShowDailog: ref(false as boolean),
      bankaId: ref("" as string),

      // firma  kredi kartı
      firmaKrediKartiDekontShowDialog: ref(false as boolean),
      firmaKrediKarti: ref()

    });


    onMounted(async () => {
      await load(TaksitPlanDonemTip.BuHafta);
    });

    async function load(donemTip: TaksitPlanDonemTip) {
      localState.odemeler = await useTaksitPlanService().getOdemeTaksitleriByTarih(donemTip, localState.isOdemeTahsilat);
      localState.tahsilatlar = await useTaksitPlanService().getTahsilatTaksitleriByTarih(donemTip, localState.isOdemeTahsilat);
    }


    function havaleOdemeClick(data: TaksitPlanEntity) {
      if (data && data) {
        localState.menuRef = {
          formType: MenuFormType.Cari_Dekont_Form,
          name: "Havale İle Ödeme => Cari",
          hareketTipi: CariHareketTip.Cari_Havale_Gonderme,
          refrenceType: MenuRefrenceType.Banka
        };


        localState.taksitPlanId = data.id as string;
        localState.dekontCariId = data.cari?.id as string;
        localState.tutar = data.taksitKalan as number;
        localState.cariDekontDialogShow = true;
      }
    }

    async function hesabaAktar(data: TaksitPlanEntity) {
      if (data) {
        if (await GenelUtils.onayDiyalogu("Seçili İşlemi Pos'un bulunduğu bankaya aktarılacaktır . Onaylıyor musunuz ?", "Pos Hesaba Aktarma")) {
          if (data && data.referansHareketId && data.referansHareketId.length > 0) {
            alert("Şu anda , bu işlemi gerçekleştiremiyoruz !" , "Hata");

            //await useBankaHareketService().putTopluBankaPosIsleme([data.referansHareketId]);
            await saveRefresh();
          }
        }
      }
    }

    function havaleAlmaClick(data: TaksitPlanEntity) {
      if (data && data) {
        localState.menuRef = {
          formType: MenuFormType.Cari_Dekont_Form,
          name: "Cari => Havale Alma",
          hareketTipi: CariHareketTip.Cari_Havale_Alma,
          refrenceType: MenuRefrenceType.Banka
        };
        localState.taksitPlanId = data.id as string;
        localState.dekontCariId = data.cari?.id as string;
        localState.tutar = data.taksitKalan as number;
        localState.cariDekontDialogShow = true;
      }
    }

    function firmaKrediKartiClick(data: TaksitPlanEntity) {
      if (data && data) {
        localState.menuRef = {
          formType: MenuFormType.Cari_Dekont_Form,
          name: "Firma Kredi Kartımızla Ödeme => Cari",
          hareketTipi: CariHareketTip.Cariye_Firma_Kredi_Kartimizla_Odeme,
          refrenceType: MenuRefrenceType.Kredi_Karti
        };


        localState.taksitPlanId = data.id as string;
        localState.dekontCariId = data.cari?.id as string;
        localState.tutar = data.taksitKalan as number;
        localState.cariDekontDialogShow = true;
      }
    }

    function  krediBankadanOde(data:TaksitPlanEntity){
      alert("<b>Hata Oluştu </b>  Banka İşlemleri -> Çıkış -> Kredi ödemesi seçeneğini kullanınız" , "Hata Oluştu")
    }

    function posCekClick(data: TaksitPlanEntity) {
      if (data && data) {
        localState.menuRef = {
          formType: MenuFormType.Cari_Dekont_Form,
          name: "Cari => Pos Çekme",
          hareketTipi: CariHareketTip.Cari_Pos_Cekme,
          refrenceType: MenuRefrenceType.Pos
        };
        localState.taksitPlanId = data.id as string;
        localState.dekontCariId = data.cari?.id as string;
        localState.tutar = data.taksitKalan as number;
        localState.cariDekontDialogShow = true;
      }
    }

    function kasadanOdeClick(data: TaksitPlanEntity) {
      if (data && data) {
        localState.menuRef = {
          formType: MenuFormType.Cari_Dekont_Form,
          name: "Kasadan Ödeme => Cari",
          hareketTipi: CariHareketTip.Cari_Nakit_Odeme,
          refrenceType: MenuRefrenceType.Kasa
        };
        localState.taksitPlanId = data.id as string;
        localState.dekontCariId = data.cari?.id as string;
        localState.tutar = data.taksitKalan as number;
        localState.cariDekontDialogShow = true;

      }
    }

    function kasadanTahsilClick(data: TaksitPlanEntity) {
      if (data && data) {
        localState.menuRef = {
          formType: MenuFormType.Cari_Dekont_Form,
          name: "Cari => Kasadan Tahsilat",
          hareketTipi: CariHareketTip.Cari_Nakit_Tahsilat,
          refrenceType: MenuRefrenceType.Kasa
        };
        localState.taksitPlanId = data.id as string;
        localState.dekontCariId = data.cari?.id as string;
        localState.tutar = data.taksitKalan as number;
        localState.cariDekontDialogShow = true;

      }


    }

    async function bankadanOdeFirmaKrediKarti(data: TaksitPlanEntity, bankaId: string) {
      if (data && data.referansHareketId && data.referansHareketId.length > 0) {
        localState.menuRef = {
          formType: MenuFormType.Banka_Dekont_Form,
          name: "Bankadan -> Firma Kredi Kartı Ödemesi",
          hareketTipi: BankaHareketTip.BankadanFirmaKkOdeme,
          refrenceType: MenuRefrenceType.Kredi_Karti
        };
        localState.bankaId = bankaId;
        localState.taksitPlanId = data.id as string;
        localState.hizmetMasrafId = data.referansId as string;

        localState.tutar = data.taksitKalan as number;
        localState.bankaDekontShowDailog = true;


      }
    }

    async function bankadanTahsilClick(data: TaksitPlanEntity) {
      if (data && data.referansHareketId && data.referansHareketId.length > 0) {
        localState.cekSenetlerFlatView = [];
        const cekSenetFlatView = await useCekSenetService().getFlatListId(data.referansHareketId);
        if (cekSenetFlatView) {

          localState.cekSenetlerFlatView.push(cekSenetFlatView as CekSenetFlatView);
          localState.cekSenetHareketDialogShow = true;
        } else
          await alert("Hata Oluştu", "Çek Senet Bulunamadı  !");
      } else
        await alert("Hata Oluştu", "Çek Senet Bulunamadı  !");
    }

    async function firmaKrediKartiMasrafClick(data: TaksitPlanEntity, krediKartiId: string) {
      if (data && data) {

        localState.menuRef = {
          formType: MenuFormType.Kredi_Karti_Dekont_Form,
          name: "Firma Kredi Kartı -> Masraf Ödeme",
          hareketTipi: KrediKartiHareketTip.KrediKartiIleMasrafOdeme,
          refrenceType: MenuRefrenceType.Hizmet_Masraf
        };

        const firmaKrediKarti = await useBankaService().getKrediKartiView(krediKartiId);

        localState.taksitPlanId = data.id as string;
        localState.firmaKrediKarti = firmaKrediKarti;
        localState.tutar = data.taksitKalan as number;
        localState.firmaKrediKartiDekontShowDialog = true;
        localState.hizmetMasrafId = data.referansHareketId as string;

      }
    }

    async function kasadanMasrafOdeClick(data: TaksitPlanEntity, kasaId: string) {

      if (data && data) {

        localState.menuRef = {
          formType: MenuFormType.Kasa_Dekont_Form,
          name: "Kasadan -> Masraf Ödeme",
          hareketTipi: KasaHareketTip.KasadanMasrafOdeme,
          refrenceType: MenuRefrenceType.Hizmet_Masraf
        };
        localState.taksitPlanId = data.id as string;
        localState.kasaId = kasaId;
        localState.tutar = data.taksitKalan as number;
        localState.kasaDekontShowDialog = true;
        localState.hizmetMasrafId = data.referansHareketId as string;

      }
    }

    async function bankadanMasrafOdeClick(data: TaksitPlanEntity, bankaId: string) {
      if (data && data) {
        localState.menuRef = {
          formType: MenuFormType.Banka_Dekont_Form,
          name: "Bankadan -> Masraf Ödeme",
          hareketTipi: BankaHareketTip.BankadanMasrafOdeme,
          refrenceType: MenuRefrenceType.Hizmet_Masraf
        };

        //localState.dekontCariId = data.cari?.id as string;
        localState.taksitPlanId = data.id as string;
        localState.bankaId = bankaId;
        localState.tutar = data.taksitKalan as number;
        localState.bankaDekontShowDailog = true;
        localState.hizmetMasrafId = data.referansHareketId as string;

      }
    }

    async function kasadanOde(data: TaksitPlanEntity, kasaId: string) {
      if (data && data.referansHareketId && data.referansHareketId.length > 0) {
        const cekSenet: FirmaCekSenetFlatView = await useFirmaCekSenetService().getFlatId(data.referansHareketId);
        if (cekSenet && kasaId && kasaId.length > 0) {
          if (await GenelUtils.onayDiyalogu()) {
            const post = [] as string[];
            post.push(cekSenet.cekSenetId as string);
            await useFirmaCekSenetService().postKasayaOdeme(post as string[] , new Date().toISOString(), kasaId);
          }

        } else
          await alert("Firma Çek Senet te <b>Kasa Bilgisi</b> Bulunamadı , Firma Çek Senet İşlemlerinden , İlgili Çek Senetin <b>Kasa</b> Bilgisini Güncelleyiniz.  ", "Hata !");


      }
    }

    async function bankadanOde(data: TaksitPlanEntity) {
      if (data && data.referansHareketId && data.referansHareketId.length > 0) {
        const cekSenet: FirmaCekSenetFlatView = await useFirmaCekSenetService().getFlatId(data.referansHareketId);
        if (cekSenet && cekSenet.bankaId && cekSenet.bankaId.length > 0) {
          if (await GenelUtils.onayDiyalogu()) {
            const post = [] as string[];
            post.push(cekSenet.cekSenetId as string);
            await useFirmaCekSenetService().postBankayaOdeme(post as string[] , new Date().toISOString());
          }

        } else
          await alert("Firma Çek Senet te <b>Banka Bilgisi</b> Bulunamadı , Firma Çek Senet İşlemlerinden , İlgili Çek Senetin <b>Banka</b> Bilgisini Güncelleyiniz.  ", "Hata !");


      }


    }


    async function saveRefresh() {
      // console.log("geldi");
      await load(TaksitPlanDonemTip.BuHafta);
    }

    async function noteEkleClick(t: TaksitPlanEntity) {
      localState.taksitPlanEntity = t;
      localState.notEkleDialogShow = true;
    }


    return {
      ...toRefs(localState),
      noteEkleClick,
      kasadanTahsilClick,
      kasadanOde,
      kasadanOdeClick,
      havaleAlmaClick,
      bankadanTahsilClick,
      posCekClick,
      load,
      saveRefresh,
      bankadanOde,
      havaleOdemeClick,
      firmaKrediKartiClick,
      bankadanMasrafOdeClick,
      kasadanMasrafOdeClick,
      firmaKrediKartiMasrafClick,
      bankadanOdeFirmaKrediKarti,
      hesabaAktar,krediBankadanOde


    };
  }
});
