import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {CariHareketTip} from '../models/CariHareketTip'

export const useCariSatisIzinleriService = defineStore({
id: 'CariSatisIzinleriService',
actions: {


	async getCarisatisizinleriEkle(cariId:string,tip:number,tutar:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carisatisizinleri/Ekle/${shared.firm.id}/${cariId}/${tip}/${tutar}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCarisatisizinleriIzinIsteyenCariler()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/carisatisizinleri/IzinIsteyenCariler/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCarisatisizinleriSatisIzinVer(firmaKey:string,key:string,izin:boolean)
	{
		const { request, response } = useApi(`/api/carisatisizinleri/SatisIzinVer/${firmaKey}/${key}/${izin}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
