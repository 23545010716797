import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5bf402e4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "n-toolbar-name" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "n-toolbar-secondary-info" }
const _hoisted_4 = { class: "n-toolbar-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("div", _mergeProps({ class: "n-toolbar" }, _ctx.$attrs), [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.tab && _ctx.tab.icon)
        ? (_openBlock(), _createBlock(_component_NIcon, _mergeProps({ key: 0 }, _ctx.tab.icon, { size: "16px" }), null, 16))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, [
        _renderSlot(_ctx.$slots, "title", {}, () => [
          (_ctx.tab && _ctx.tab.displayName)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.tab.displayName), 1))
            : _createCommentVNode("", true)
        ], true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "subTitle", {}, undefined, true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 16))
}