import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {DataSourceLoadOptionsItem} from '../models/DataSourceLoadOptionsItem'

export const useEpttAvmKategoriService = defineStore({
id: 'EpttAvmKategoriService',
actions: {


	async getGetAll(isCache:boolean)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/EpttAvmKategori/GetAll/${shared.firm.id}/${isCache}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetCategoryAttributes(kategoriId:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/EpttAvmKategori/GetCategoryAttributes/${shared.firm.id}/${kategoriId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postGetBrands(dataSourceLoadOptionsItem:DataSourceLoadOptionsItem)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/EpttAvmKategori/GetBrands/${shared.firm.id}`,{ method:'POST', data:dataSourceLoadOptionsItem });
		await request();
		return response.value;
	}
}
});
