
export enum KargoTeslimTip{
AdresTeslim=1,
AliciHaberli=2
}

export const KargoTeslimTipList = [
{key:'AdresTeslim', value:1, description:'Adresten Alım'},
{key:'AliciHaberli', value:2, description:'Alıcı Haberli'}
]
