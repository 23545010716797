
import {computed, defineComponent, reactive, toRefs} from "vue";
import {FaturaBilgiItem} from "@/api/models/FaturaBilgiItem";
import NGrid from "@/components/Grid/NGrid.vue";
import NListView from "@/components/ListView/NListView.vue";

export default defineComponent({
  components: {NListView, NGrid},
  props: {
    modelValue:{
      type:Array
    },
    placeholder:{
      type:String,
      default:'',
    },
    addText:{
      type:String,
      default:'Ekle',
    }
  },
  setup(props , {emit }) {
    const localState = reactive({
      modelRef:computed({
        get:() => props.modelValue as FaturaBilgiItem[],
        set:val=> emit("update:modelValue" , val) ,
      }),
      placeholderRef:computed(() => props.placeholder),
      addTextRef:computed(()=> props.addText),
    })

    function add() {
      if (localState.modelRef) {
        localState.modelRef.push({no: '', tarih: new Date().toISOString().split('T')[0]})
      }
    }

    function remove(index: number) {
      if (localState.modelRef) {
        localState.modelRef.splice(index, 1)
      }
    }
    return {
      ...toRefs(localState),
      add,
      remove,
    };
  }
});
