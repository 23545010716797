import { defineStore } from "pinia";
import FaturaFormProp from "@/modules/Fatura/models/FaturaFormProp";
import { useLayoutStore } from "@/layouts/ApplicationLayout/context";
import { NModuleRoute } from "@/models";
import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import {FaturaView} from "@/api/models/FaturaView";
import { FaturaTip } from "@/api/models/FaturaTip";

export const useFaturaStore = defineStore({
  id: "FaturaStore",
  state: () => ({
    onSignalIzin:false,
    faturaFormProp: {
      menu: {},
      cariId: "",
      faturaId: "",
      model: FaturaView,
      faturaTip : FaturaTip,
    } as FaturaFormProp
  }),
  actions: {

    openFaturaForm(menu: MenuModel, cariId: string, cariUnvan: string, faturaId?: string , faturaTip?:FaturaTip) {

      this.faturaFormProp = new FaturaFormProp(menu, cariId, faturaId , undefined,faturaTip );
      const route: NModuleRoute = {
        id: "FaturaForm",
        order: 0,
        displayName: menu.name + ": " + cariUnvan,
        icon: {
          type: "file-minus"
        },
        entityId : faturaId,

      };
      useLayoutStore().openRouteAsTab(route);
    },
    viewToFaturaForm(menu:MenuModel , model:FaturaView , faturaTip?:FaturaTip){
      this.faturaFormProp = new FaturaFormProp(menu, "" , "" , model , faturaTip );
      const route: NModuleRoute = {
        id: "FaturaForm",
        order: 0,
        displayName: menu.name!,
        icon: {
          type: "file-minus"
        },
        entityId : model.id,
      };
      useLayoutStore().openRouteAsTab(route);
    }


  }
});
