import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!

  return (_openBlock(), _createBlock(_component_DxSelectBox, {
    label: _ctx.showLabel ? 'Ana Birim' : '',
    "display-expr": "deger",
    "value-expr": "deger",
    items: _ctx.birimler,
    searchEnabled: true,
    value: _ctx.model,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    "show-clear-button": false,
    placeholder: _ctx.placeholderRef
  }, null, 8, ["label", "items", "value", "placeholder"]))
}