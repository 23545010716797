import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"color":"#C00","margin-left":"4px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDropDownListBox = _resolveComponent("NDropDownListBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!

  return (_openBlock(), _createBlock(_component_NFormGrid, {
    cols: "1fr",
    title: "N11 Özellikleri"
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_NButton, {
        icon: "refresh-ccw",
        size: "small",
        click: ()=> _ctx.load(0)
      }, {
        default: _withCtx(() => [
          _createTextVNode("Yenile")
        ]),
        _: 1
      }, 8, ["click"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_NLoadingPanel, {
        ref: "loadingPanel",
        action: ()=> _ctx.load(368),
        title: "N11 özellikleri yükleniyor..."
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NFormGrid, { cols: _ctx.cols }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.n11Ozellikler, (attribute, i) => {
                return (_openBlock(), _createBlock(_component_NFormField, {
                  "grid-cols": "100px 1fr",
                  label: attribute.name + ' (' + attribute.values.length + ')',
                  key: i
                }, {
                  label: _withCtx(() => [
                    _createElementVNode("div", null, [
                      (attribute.mondatory)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createVNode(_component_NDropDownListBox, {
                        "accept-custom-value": !attribute.mondatory,
                        text: attribute.value,
                        "onUpdate:text": ($event: any) => ((attribute.value) = $event),
                        onChange: _ctx.n11ToNesilce,
                        "selected-value": attribute.value,
                        "onUpdate:selectedValue": ($event: any) => ((attribute.value) = $event),
                        "value-expr": "name",
                        "display-expr": "name",
                        items: attribute.values
                      }, null, 8, ["accept-custom-value", "text", "onUpdate:text", "onChange", "selected-value", "onUpdate:selectedValue", "items"])
                    ])
                  ]),
                  _: 2
                }, 1032, ["label"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["cols"])
        ]),
        _: 1
      }, 8, ["action"])
    ]),
    _: 1
  }))
}