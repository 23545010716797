
export enum PazaryeriFiyatTipi{
Otomatik=0,
Oran=1,
Fiyat=2,
Tutar=3,
MaliyetAlisOran=4
}

export const PazaryeriFiyatTipiList = [
{key:'Otomatik', value:0, description:'Alış Fiyatına Oran Ekle'},
{key:'Oran', value:1, description:'Satış Fiyatına Oran Ekle'},
{key:'Fiyat', value:2, description:'Sabit Fiyat'},
{key:'Tutar', value:3, description:'Satış Fiyatına Tutar Ekle'},
{key:'MaliyetAlisOran', value:4, description:'Maliyet yada Alış Fiyatına Oran Ekle'}
]
