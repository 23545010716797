import { CariHareketTip, CariHareketTipList } from "@/api/models/CariHareketTip";
import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import { useFaturaStore } from "@/modules/Fatura/store";
import { FaturaListView } from "@/api/models/FaturaListView";
import { ref } from "vue";
import { CariHareketViewVadeli } from "@/api/models/CariHareketViewVadeli";
import { useCariHareketService } from "@/api/services/CariHareketService";
import { GenelResmiMuhasebeTip } from "@/api/models/GenelResmiMuhasebeTip";
import { Doviz } from "@/api/models/Doviz";
import { CariMenuListesi } from "@/modules/Cari/models/CariMenuListesi";
import { FaturaHareketTip, FaturaHareketTipList } from "@/api/models/FaturaHareketTip";
import { MenuFormType } from "@/modules/Ortak/models/Menu/MenuFormType";
import { MenuRefrenceType } from "@/modules/Ortak/models/Menu/MenuRefrenceType";
import { SiparisHareketTip } from "@/api/models/SiparisHareketTip";
import { useSiparisStore } from "@/modules/SiparisTeklif/stores";
import { useEvrakNoService } from "@/api/services/EvrakNoService";
import { FaturaTip } from "@/api/models/FaturaTip";

export const CariHareketUtils = {
    getEnumDescription(tip: CariHareketTip) {
        const result = CariHareketTipList.find(a => a.value === tip);
        if (result) {
            return result.description;
        }
    },
    getColumns() {
        return ref([
            {
                label: "Durum",
                prop: "queueDescription",
                width: "100px",
                class: "number"
            },
            {
                label: "Tarih",
                prop: "tarih",
                width: "100px"
            },
            {
                label: "Evrak Tip",
                prop: "genelResmiMuhasebeTip",
                width: "100px"
            },
            {
                label: "Hesabı",
                prop: "cariHesabi",
                width: "100px"
            },
            {
                label: "İşi",
                prop: "cariIsi",
                width: "100px"
            },
            {
                label: "Plasiyer",
                prop: "plasiyerId",
                width: "100px"
            },
            {
                label: "Proje",
                prop: "projeId",
                width: "100px"
            },
            {
                label: "Departman",
                prop: "departmanId",
                width: "100px"
            },
            {
                label: "Vade",
                prop: "vade",
                width: "100px"
            },
            {
                label: "Evrak No",
                prop: "evrakNo",
                width: "100px"
            },
            {
                label: "Tip",
                prop: "tip",
                width: "100px"
            },
            {
                label: "Açıklama",
                prop: "aciklama",
                width: "100px",
                cls: "number"
            },
            {
                label: "Borç(₺)",
                prop: "getTlBorcu",
                width: "100px",
                cls: "number"
            },
            {
                label: "Borç($)",
                prop: "getUsdBorcu",
                width: "100px",
                cls: "number"
            },
            {
                label: "Borç(€)",
                prop: "getEuroBorcu",
                width: "100px",
                cls: "number"
            },
            {
                label: "Alacak(₺)",
                prop: "getTlAlacagi",
                width: "100px",
                cls: "number"
            },
            {
                label: "Alacak($)",
                prop: "getUsdAlacagi",

                width: "100px",
                cls: "number"
            },
            {
                label: "Alacak(€)",
                prop: "getEuroAlacagi",

                width: "100px",
                cls: "number"
            },
            {
                label: "Yerel Alacak",
                prop: "getYerelAlacagi",

                width: "100px",
                cls: "number"
            },
            {
                label: "Yerel Borç",
                prop: "getYerelBorcu",

                width: "100px",
                cls: "number"
            },
            {
                label: "Yerel Bakiye",
                prop: "getYerelBakiye",

                width: "100px",
                cls: "number"
            }
        ]);
    },

    openSiparisForm(menu: MenuModel, cariId: string, cariUnvan: string, haraketId: string) {
        const siparisMenu: MenuModel = {};
        switch (menu.hareketTipi) {
            case CariHareketTip.Satis_Siparisi:
                siparisMenu.hareketTipi = SiparisHareketTip.SatisSiparis;
                siparisMenu.formType = MenuFormType.Siparis_Form;
                siparisMenu.name = "Satış Siparişi";
                useSiparisStore().openSiparisForm(siparisMenu, cariId, cariUnvan ,  haraketId);
                break;
            case CariHareketTip.Alis_Siparisi:
                siparisMenu.hareketTipi = SiparisHareketTip.AlisSiparis;
                siparisMenu.formType = MenuFormType.Siparis_Form;
                siparisMenu.name = "Alış Siparişi";
                useSiparisStore().openSiparisForm(siparisMenu, cariId, cariUnvan ,  haraketId);
                break;
            case CariHareketTip.Satis_Teklifi:
                siparisMenu.hareketTipi = SiparisHareketTip.SatisTeklif;
                siparisMenu.formType = MenuFormType.Siparis_Form;
                siparisMenu.name = "Satış Teklifi";
                useSiparisStore().openSiparisForm(siparisMenu, cariId, cariUnvan ,  haraketId);
                break;
            case CariHareketTip.Alis_Teklifi:
                siparisMenu.hareketTipi = SiparisHareketTip.AlisTeklif;
                siparisMenu.formType = MenuFormType.Siparis_Form;
                siparisMenu.name = "Alış Teklifi";
                useSiparisStore().openSiparisForm(siparisMenu, cariId, cariUnvan ,  haraketId);
                break;
        }
    },
    openFaturaForm(
        menu: MenuModel,
        cariId: string,
        cariUnvan: string,
        hareketId: string
    ) {
        const faturaMenu: MenuModel = {};

        switch (menu.hareketTipi) {
            case CariHareketTip.Satis_Faturasi:
                faturaMenu.formType = MenuFormType.Fatura_Form;
                faturaMenu.hareketTipi = FaturaHareketTip.SatisFatura;
                faturaMenu.name = "Satış Fatura";
                useFaturaStore().openFaturaForm(faturaMenu, cariId, cariUnvan, hareketId);
                break;
            case CariHareketTip.Alis_Faturasi:
                faturaMenu.formType = MenuFormType.Fatura_Form;
                faturaMenu.hareketTipi = FaturaHareketTip.AlisFaturasi;
                faturaMenu.name = "Alış Fatura";
                useFaturaStore().openFaturaForm(faturaMenu, cariId, cariUnvan, hareketId);
                break;
            case CariHareketTip.Alis_Irsaliyesi:

                faturaMenu.formType = MenuFormType.Fatura_Form;
                faturaMenu.hareketTipi = FaturaHareketTip.AlisIrsaliye;
                if (menu.refrenceType === MenuRefrenceType.Sac){
                    faturaMenu.name = "Sac Alış İrsliyesi";
                    useFaturaStore().openFaturaForm(faturaMenu, cariId, cariUnvan, hareketId ,  FaturaTip.Sac);
                }else{
                    faturaMenu.name = "Alış İrsaliye";
                    useFaturaStore().openFaturaForm(faturaMenu, cariId, cariUnvan, hareketId);
                }

                break;
            case CariHareketTip.Satis_Irsaliyesi:
                faturaMenu.formType = MenuFormType.Fatura_Form;
                faturaMenu.hareketTipi = FaturaHareketTip.SatisIrsaliye;
                faturaMenu.name = "Satış İrsaliye";
                useFaturaStore().openFaturaForm(faturaMenu, cariId, cariUnvan, hareketId);
                break;
            case CariHareketTip.Konsinye_Satis:
                faturaMenu.formType = MenuFormType.Fatura_Form;
                faturaMenu.hareketTipi = FaturaHareketTip.SatisKonsinye;
                faturaMenu.name = "Satış Konsinye";
                useFaturaStore().openFaturaForm(faturaMenu, cariId, cariUnvan, hareketId);
                break;
            case CariHareketTip.Kur_Farki_Faturamiz:
                faturaMenu.formType = MenuFormType.Fatura_Form;
                faturaMenu.hareketTipi = FaturaHareketTip.KurFarkiSatis;
                faturaMenu.name = "Kur Farkı Satış";
                useFaturaStore().openFaturaForm(faturaMenu, cariId, cariUnvan, hareketId);
                break;
            case CariHareketTip.Kur_Farki_Alis_Faturasi:
                faturaMenu.formType = MenuFormType.Fatura_Form;
                faturaMenu.hareketTipi = FaturaHareketTip.KurFarkiAlis;
                faturaMenu.name = "Kur Farkı Alış";
                useFaturaStore().openFaturaForm(faturaMenu, cariId, cariUnvan, hareketId);
                break;
            case CariHareketTip.Alis_Iade_Faturasi_Satis_Faturamiz:
                faturaMenu.formType = MenuFormType.Fatura_Form;
                faturaMenu.hareketTipi = FaturaHareketTip.AlisIadeFaturasi;
                faturaMenu.name = "Alıştan İade";
                useFaturaStore().openFaturaForm(faturaMenu, cariId, cariUnvan, hareketId);
                break;
            case CariHareketTip.Satis_Iade_Faturasi_AlisFaturasi:
                faturaMenu.formType = MenuFormType.Fatura_Form;
                faturaMenu.hareketTipi = FaturaHareketTip.SatisIadeFaturasi;
                faturaMenu.name = "Satıştan İade";
                useFaturaStore().openFaturaForm(faturaMenu, cariId, cariUnvan, hareketId);
                break;

        }

    },
    openFaturaFormFromFaturaListView(fatura: FaturaListView) {
        if (fatura.id && fatura.cariId) {
            useFaturaStore().openFaturaForm(
                {
                    name: FaturaHareketTipList.find(a => a.value == fatura.tip)?.description,
                    hareketTipi: fatura.tip,
                    formType: MenuFormType.Fatura_Form,
                    refrenceType: MenuRefrenceType.Kullanilmaz,
                } as MenuModel,
                fatura.cariId,
                fatura.cariUnvan as string,
                fatura.id
            );
        }
    },
    async dekontKaydet(menu: MenuModel, model: CariHareketViewVadeli) {
        if (model.id && model.id.length > 0) {
            switch (menu.hareketTipi) {
                case CariHareketTip.MailOrderCekme:
                    await useCariHareketService().putMailOrderCekmeGuncelle(model);
                    break;
                case CariHareketTip.Cari_Borc_Dekontu:
                    await useCariHareketService().putBorcDekontuGuncelle(model);
                    break;
                case CariHareketTip.Cari_Borc_Devir:
                    await useCariHareketService().putBorcDevirGuncelle(model);
                    break;
                case CariHareketTip.Cari_Alacak_Devir:
                    await useCariHareketService().putAlacakDevirGuncelle(model);
                    break;
                case CariHareketTip.Cari_Alacak_Dekontu:
                    await useCariHareketService().putAlacakDekontuGuncelle(model);
                    break;
                case CariHareketTip.Cari_Havale_Alma:
                    await useCariHareketService().putCariHavaleAlmaGuncelle(model);
                    break;
                case CariHareketTip.Cari_Havale_Gonderme:
                    await useCariHareketService().putCariHaveleGondermeGuncelle(model);
                    break;
                case CariHareketTip.Cari_Nakit_Odeme:
                    await useCariHareketService().putNakitOdemeGuncelle(model);
                    break;
                case CariHareketTip.Cari_Nakit_Tahsilat:
                    await useCariHareketService().putNakitTahsilatGuncelle(model);
                    break;
                case CariHareketTip.Cari_Pos_Cekme:
                    await useCariHareketService().putCariPosCekmeGuncelle(model);
                    break;
                case CariHareketTip.Cariye_Firma_Kredi_Kartimizla_Odeme:
                    await useCariHareketService().putCariyeFirmaKrediKartimizlaOdemeGuncelle(
                        model
                    );
                    break;
                case CariHareketTip.Cari_Virman_Borc:
                    await useCariHareketService().putVirmanBorcGuncelle(model);
                    break;
                case CariHareketTip.Cari_Virman_Alacak:
                    await useCariHareketService().putVirmanAlacakGuncelle(model);
                    break;
            }
        } else {
            // insert
            switch (menu.hareketTipi) {
                case CariHareketTip.MailOrderCekme:
                    await useCariHareketService().postMailOrderCekme(model);
                    break;
                case CariHareketTip.Cari_Borc_Dekontu:
                    await useCariHareketService().postBorcDekontuEkle(model);
                    break;
                case CariHareketTip.Cari_Borc_Devir:
                    await useCariHareketService().postBorcDevirEkle(model);
                    break;
                case CariHareketTip.Cari_Alacak_Dekontu:
                    await useCariHareketService().postAlacakDekontuEkle(model);
                    break;
                case CariHareketTip.Cari_Alacak_Devir:
                    await useCariHareketService().postAlacakDevirEkle(model);
                    break;
                case CariHareketTip.Cari_Havale_Alma:
                    await useCariHareketService().postCariHavaleAlma(model);
                    break;
                case CariHareketTip.Cari_Havale_Gonderme:
                    await useCariHareketService().postCariHaveleGonderme(model);
                    break;
                case CariHareketTip.Cariye_Firma_Kredi_Kartimizla_Odeme:
                    await useCariHareketService().postCariyeFirmaKrediKartimizlaOdeme(
                        model
                    );
                    break;
                case CariHareketTip.Cari_Nakit_Odeme:
                    await useCariHareketService().postNakitOdemeEkle(model);
                    break;
                case CariHareketTip.Cari_Nakit_Tahsilat:
                    await useCariHareketService().postNakitTahsilatEkle(model);
                    break;
                case CariHareketTip.Cari_Pos_Cekme:
                    await useCariHareketService().postCariPosCekme(model);
                    break;
                case CariHareketTip.Cari_Virman_Borc:
                    await useCariHareketService().postVirmanBorc(model);
                    break;
                case CariHareketTip.Cari_Virman_Alacak:
                    await useCariHareketService().postVirmanAlacak(model);
                    break;
            }
        }
    },

    async getCariHareketView(
        cariHareketId?: string,
        cariId?: string,
        tutar?:number,
    ): Promise<CariHareketViewVadeli> {
        if (cariHareketId && cariHareketId.length > 0) {
            return (await useCariHareketService().getGetirView(
                cariHareketId
            )) as CariHareketViewVadeli;
        } else {
            return {
                evrakNo : await useEvrakNoService().getYeniEvrakNo(10),
                cariId: cariId,
                genelResmiMuhasebeTip: GenelResmiMuhasebeTip.Genel,
                vade: new Date().toISOString(),
                tarih: new Date().toISOString(),
                hesaplama: {
                    islemDoviz: Doviz.TRY,
                    islemKur: 1,
                    islemTutar: tutar,
                    islemYerelTutar: tutar,
                    islenecekDoviz: Doviz.TRY,
                    islenecekKur: 1,
                    islenecekTutar: tutar,
                    islenecekYerelTutar: tutar
                },
                vadeler: [],
                dokumanlar: [],
                hizmetMasrafTutar : 0 ,
                borcAlacaktanDussunmu : false,
            };
        }
    },
    getMenuFromCariHareket(hareketType: CariHareketTip) {
        for (let i = 0; i < CariMenuListesi.length; i++) {
            const mainMenu = CariMenuListesi[i];
            if (mainMenu.hareketTipi == hareketType) {
                return mainMenu;
            } else {
                if (mainMenu.childs && mainMenu.childs.length > 0) {
                    for (let j = 0; j < mainMenu.childs.length; j++) {
                        const subMenu = mainMenu.childs[j];
                        if (subMenu.hareketTipi === hareketType) return subMenu;
                    }
                }
            }
        }
    }
};
