
export enum CariTip{
Alıcı_Satıcı=0,
Alıcı=1,
Satıcı=2,
Personel=3,
Sanal_Pazar=4,
Kurumlar=5,
Grup=6,
Ithalat=7,
Ihracat=8,
IthalatIhracat=9
}

export const CariTipList = [
{key:'Alıcı_Satıcı', value:0, description:'Alıcı / Satıcı'},
{key:'Alıcı', value:1, description:'Alıcı'},
{key:'Satıcı', value:2, description:'Satıcı'},
{key:'Personel', value:3, description:'Personel'},
{key:'Sanal_Pazar', value:4, description:'Sanal Pazar'},
{key:'Kurumlar', value:5, description:'Kurum'},
{key:'Grup', value:6, description:'Ana Grup Şirketi'},
{key:'Ithalat', value:7, description:'İthalat'},
{key:'Ihracat', value:8, description:'İhracat'},
{key:'IthalatIhracat', value:9, description:'İthalat/İhracat'}
]
