import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NCheckbox = _resolveComponent("NCheckbox")!
  const _component_NDropdownMenu = _resolveComponent("NDropdownMenu")!
  const _component_NDropdown = _resolveComponent("NDropdown")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_HizliSatisStokUpdateDialog = _resolveComponent("HizliSatisStokUpdateDialog")!
  const _component_HizliStokDialog = _resolveComponent("HizliStokDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NGrid, {
      cols: "1fr auto auto",
      gap: "4px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DxTextBox, {
          id: "stokSearch",
          onEnterKey: _ctx.stokAraEnterKey,
          ref: "textBox",
          "show-clear-button": true,
          value: _ctx.valueRef,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueRef) = $event)),
          placeholder: _ctx.placeholder,
          style: {"width":"100%"}
        }, null, 8, ["onEnterKey", "value", "placeholder"]),
        _createVNode(_component_NDropdown, { clickable: true }, {
          default: _withCtx(() => [
            _createVNode(_component_NButton, {
              icon: "menu",
              type: "info"
            })
          ]),
          menu: _withCtx(() => [
            _createVNode(_component_NDropdownMenu, null, {
              default: _withCtx(() => [
                _createVNode(_component_NDropdownItem, {
                  icon: "plus",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hizliStokDialogShow=true))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Stok Ekle")
                  ]),
                  _: 1
                }),
                _createVNode(_component_NDropdownItem, {
                  icon: "link",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hizliSatisDialogShow=true))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Hızlı Satışa Stok Ekle")
                  ]),
                  _: 1
                }),
                _createVNode(_component_NDropdownItem, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_NCheckbox, {
                      label: "Barkodlarda Ara",
                      modelValue: _ctx.isSearchBarkodlar,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.isSearchBarkodlar) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_NDropdownItem, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_NCheckbox, {
                      label: "Kodlarda Ara",
                      modelValue: _ctx.isSearchKodlar,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isSearchKodlar) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_NDropdownItem, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_NCheckbox, {
                      label: "Stok bulunamadığında XML Stoklarında Ara",
                      modelValue: _ctx.isSearchXmlStok,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isSearchXmlStok) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_NDropdownItem, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_NCheckbox, {
                      label: "Satırlara eklemek için Tek Tıkla",
                      modelValue: _ctx.isAddSatirSingleClick,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.isAddSatirSingleClick) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.hizliSatisDialogShow)
      ? (_openBlock(), _createBlock(_component_HizliSatisStokUpdateDialog, {
          key: 0,
          onStokEntityAdded: _ctx.stokEntityAdded,
          show: _ctx.hizliSatisDialogShow,
          "onUpdate:show": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.hizliSatisDialogShow) = $event))
        }, null, 8, ["onStokEntityAdded", "show"]))
      : _createCommentVNode("", true),
    (_ctx.hizliStokDialogShow)
      ? (_openBlock(), _createBlock(_component_HizliStokDialog, {
          key: 1,
          show: _ctx.hizliStokDialogShow,
          "onUpdate:show": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.hizliStokDialogShow) = $event))
        }, null, 8, ["show"]))
      : _createCommentVNode("", true)
  ], 64))
}