import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import { MenuFormType } from "@/modules/Ortak/models/Menu/MenuFormType";
import { MenuRefrenceType } from "@/modules/Ortak/models/Menu/MenuRefrenceType";
import { BankaHareketTip } from "@/api/models/BankaHareketTip";

export const BankaMenuListesi: MenuModel[] = [
  {
    name: "Giriş",
    childs: [
      {
        name: "Borç Devri",
        formType: MenuFormType.Banka_Dekont_Form,
        refrenceType: MenuRefrenceType.Kullanilmaz,
        hareketTipi: BankaHareketTip.BankaGirenDevir
      },
      // {
      //   name: "Kasadan Para Yatırma",
      //   formType: MenuFormType.Banka_Dekont_Form,
      //   refrenceType: MenuRefrenceType.Kasa,
      //   hareketTipi: BankaHareketTip.BankayaKasadanParaYatirma
      // },
      {
        name: "Hizmet Masraf Tahsilatı",
        formType: MenuFormType.Banka_Dekont_Form,
        refrenceType: MenuRefrenceType.Hizmet_Masraf,
        hareketTipi: BankaHareketTip.BankayaHizmetTahsilati
      },
      {
        name: "Firma Kredi Kartından Para Çekme",
        formType: MenuFormType.Banka_Dekont_Form,
        refrenceType: MenuRefrenceType.Kredi_Karti,
        hareketTipi: BankaHareketTip.BankayaFirmaKkParaCekme
      },
      {
        name: "Döviz Alma",
        formType: MenuFormType.Banka_Dekont_Form,
        refrenceType: MenuRefrenceType.Banka,
        hareketTipi: BankaHareketTip.BankayaVirmanOdeme
      },
      {
        name: "Giriş Fişi",
        formType: MenuFormType.Banka_Dekont_Form,
        refrenceType: MenuRefrenceType.Kullanilmaz,
        hareketTipi: BankaHareketTip.BankaGirisFisi
      },
      {
        name: "Değerli Kıymet Alma",
        formType: MenuFormType.Banka_Dekont_Form,
        refrenceType: MenuRefrenceType.Kiymetler,
        hareketTipi: BankaHareketTip.BankayaDegerliKiymetTahsilati
      }
    ]
  },
  {
    name: "Çıkış",
    childs: [
      {
        name: "Alacak Devri",
        formType: MenuFormType.Banka_Dekont_Form,
        refrenceType: MenuRefrenceType.Kullanilmaz,
        hareketTipi: BankaHareketTip.BankaCikanDevir
      },
      // {
      //   name: "Kasaya Para Çekme",
      //   formType: MenuFormType.Banka_Dekont_Form,
      //   refrenceType: MenuRefrenceType.Kasa,
      //   hareketTipi: BankaHareketTip.BankadanKasayaParaCekme
      // },
      {
        name: "Hizmet Masraf Ödemesi",
        formType: MenuFormType.Banka_Dekont_Form,
        refrenceType: MenuRefrenceType.Hizmet_Masraf,
        hareketTipi: BankaHareketTip.BankadanMasrafOdeme
      },
      {
        name: "Firma Kredi Kartına Para Yatırma",
        formType: MenuFormType.Banka_Dekont_Form,
        refrenceType: MenuRefrenceType.Kredi_Karti,
        hareketTipi: BankaHareketTip.BankadanFirmaKkOdeme
      },
      {
        name: "Döviz Bozdurma",
        formType: MenuFormType.Banka_Dekont_Form,
        refrenceType: MenuRefrenceType.Banka,
        hareketTipi: BankaHareketTip.BankadanVirmanTahsilati
      },
      {
        name: "Kredi Ödemesi",
        formType: MenuFormType.Banka_Dekont_Form,
        refrenceType: MenuRefrenceType.Krediler,
        hareketTipi: BankaHareketTip.BankadanBankaKredisiOdeme
      },
      {
        name: "Çıkış Fişi",
        formType: MenuFormType.Banka_Dekont_Form,
        refrenceType: MenuRefrenceType.Kullanilmaz,
        hareketTipi: BankaHareketTip.BankaCikisFisi
      },
      {
        name: "Değerli Kıymet Bozdurma",
        formType: MenuFormType.Banka_Dekont_Form,
        refrenceType: MenuRefrenceType.Kiymetler,
        hareketTipi: BankaHareketTip.BankayaDegerliKiymetTahsilati
      }
    ]
  },
  {
    name: "Alınan Virman",
    formType: MenuFormType.Banka_Dekont_Form,
    refrenceType: MenuRefrenceType.Banka,
    hareketTipi: BankaHareketTip.BankadanVirmanTahsilati
  },




];
