
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import NGrid from "@/components/Grid/NGrid.vue";
import {useSubeService} from "@/api/services/SubeService";
import StokKategori_SubeFiyatlari from "@/modules/StokKategori/components/StokKategori_SubeFiyatlari.vue";
import {StokOzellikItem} from "@/api/models/StokOzellikItem";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import {SubeView} from "@/api/models/SubeView";
import {SubeFiyatTipi} from "@/api/models/SubeFiyatTipi";

export default defineComponent({
  components: {NLoadingPanel, StokKategori_SubeFiyatlari, NGrid  },
  props: {
    ozellik:{
      type:Object
    },
    ozellikDevir:{
      type:Object
    }
  },
  setup(props) {

    const localState = reactive({
      subeler:ref([]as SubeView[]),
      ozellikRef:computed(()=>props.ozellik as StokOzellikItem),
      ozellikDevirRef:computed(()=>props.ozellikDevir as StokOzellikItem)
    });

    async function load(){
      localState.subeler = await useSubeService().getListe() as SubeView[];
      localState.subeler.forEach(sube=>{
        if (localState.ozellikRef){
        const subeFiyatItemInOzellikRef = localState.ozellikRef.subeFiyatlari?.find(a => a.subeId == sube.id);
        if (!subeFiyatItemInOzellikRef){
          localState.ozellikRef.subeFiyatlari?.push({
            subeId:sube.id,
            id:Date.now().toString(),
            tip:SubeFiyatTipi.OranEkle,
            deger:0
          })
        }
        }
      })
    }

    return {
      ...toRefs(localState),
      load
    };
  }
});

