import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';


export const useEvrakNoService = defineStore({
id: 'EvrakNoService',
actions: {


	async getYeniEvrakNo(basamak:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/evrakNo/YeniEvrakNo/${shared.firm.id}/${shared.firm.period}/${basamak}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
