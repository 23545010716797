
import {computed, defineComponent, onMounted, reactive, ref, toRefs, watch} from "vue";
import {DxTextBox} from "devextreme-vue";
import {StokFlatView} from "@/api/models/StokFlatView";
import {usePopupService} from "@/services/popupService";
import NGrid from "@/components/Grid/NGrid.vue";
import NButton from "@/components/Button/NButton.vue";

import StokListDialog from "@/modules/Stok/components/StokListDialog.vue";
import {useStokFlatStore} from "@/modules/Stok/store/StokFlatStore";
import {useStokStore} from "@/modules/Stok/store/StokStore";

import {StokBirimItem} from "@/api/models/StokBirimItem";
import {HizmetMasrafEntity} from "@/api/models/HizmetMasrafEntity";
import {useHizmetMasrafService} from "@/api/services/HizmetMasrafService";
import HizliStokDialog from "@/modules/Stok/dialogs/HizliStokDialog.vue";
import {StokBirimUtils} from "@/modules/Stok/utils/StokBirimUtils";

import {useStokGrupService} from "@/api/services/StokGrupService";
import {StokGrupEntity} from "@/api/models/StokGrupEntity";
import DataSource from "devextreme/data/data_source";
import {dataSource} from "devexpress-reporting/designer/controls/metadata/properties/metadata";
import {Benchmark} from "@/utils/benchmark/Benchmark";
import {StokFlatSearchUtils} from "@/modules/Stok/utils/StokFlatSearchUtils";
import {HizmetMasrafSearchUtils} from "@/modules/HizmetMasraf/utils/HizmetMasrafSearchUtils";
import dxTextBox from "devextreme/ui/text_box";
import {StokUtils} from "@/modules/Stok/utils/StokUtils";
import {StokPluUtils} from "@/modules/Stok/utils/StokPluUtils";
import NDropdownMenu from "@/components/Dropdown/NDropdownMenu.vue";
import NDropdownItem from "@/components/Dropdown/NDropdownItem.vue";
import NCheckbox from "@/components/Checkbox/NCheckbox.vue";
import {useUserSettingsService} from "@/api/services/UserSettingsService";
import {useBildirimSesleriStore} from "@/modules/Ayarlar/Tanımlar/store/BildirimSesleriStore";
import { useHizmetMasrafStore } from "@/modules/HizmetMasraf/store/HizmetMasrafStore";
import StokXmlSelectListDialog from "@/modules/Stok/dialogs/StokXmlSelectListDialog.vue";


export default defineComponent({
  components: {
      StokXmlSelectListDialog,
      NCheckbox, NDropdownItem, NDropdownMenu, HizliStokDialog, StokListDialog, NButton, NGrid, DxTextBox},
  props: {
    fontSize: {
      type: String,
      default: "13px"
    },
    refresh: {
      type: Boolean,
      default: true
    },
    insert: {
      type: Boolean,
      default: true
    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      arama: ref(),
      stokDxTextBox: ref(),
      grid: ref(),
      showStokListRef: ref(false),
      flatStore: useStokFlatStore(),
      grupluStoklar: ref([] as StokGrupEntity[]),
      stoklistTab: ref(0),
      hizliStokDialogShow: ref(false),
      audio: new Audio(('/pos.mp3')),
      stokBulunduSesi: useBildirimSesleriStore().bildirimStokBulunduSesi,
      isSearchBarkod: ref(false),
      isSearchStokKod: ref(false),
      isSearchStokAd: ref(false),
      isSearchXmlStok: ref(false),
      isEventLoaded: ref(false),
      showXmlStokListDialog: ref(false),
      isSearchBarkodlar:  ref(false),
      isSearchKodlar:  ref(false)


    });
    onMounted(async () => await load());

    function installWatches() {
      watch(() => localState.isSearchStokKod, async (deger: boolean) => {
        if (deger) {
          await saveSettings("isStokKodSearch", "1");
          useStokStore().isStokKodSearch = true;
        } else {
          await saveSettings("isStokKodSearch", "0");
          useStokStore().isStokKodSearch = false;
        }
      })

      watch(() => localState.isSearchBarkod, async (deger: boolean) => {
        if (deger) {
          await saveSettings("isBarkodSearch", "1");
          useStokStore().isBarkodSearch = true;
        } else {
          await saveSettings("isBarkodSearch", "0");
          useStokStore().isBarkodSearch = false;
        }
      })

      watch(() => localState.isSearchStokAd, async (deger: boolean) => {
        if (deger) {
          await saveSettings("isStokAdSearch", "1");
          useStokStore().isStokAdSearch = true;
        } else {
          await saveSettings("isStokAdSearch", "0");
          useStokStore().isStokAdSearch = false;
        }
      })

        watch(() => localState.isSearchBarkodlar, async (deger: boolean) => {
        if (deger) {
          await saveSettings("isSearchBarkodlar", "1");
          useStokStore().isSearchBarkodlar = true;
        } else {
          await saveSettings("isSearchBarkodlar", "0");
          useStokStore().isSearchBarkodlar = false;
        }
      })

        watch(() => localState.isSearchKodlar, async (deger: boolean) => {
        if (deger) {
          await saveSettings("isSearchKodlar", "1");
          useStokStore().isSearchKodlar = true;
        } else {
          await saveSettings("isSearchKodlar", "0");
          useStokStore().isSearchKodlar = false;
        }
      })

        watch(() => localState.isSearchXmlStok, async (deger: boolean) => {
        if (deger) {
          await saveSettings("isSearchXmlStok", "1");
          useStokStore().isSearchXmlStok = true;
        } else {
          await saveSettings("isSearchXmlStok", "0");
          useStokStore().isSearchXmlStok = false;
        }
      })

    }
    async function load() {



      localState.grupluStoklar = await useStokGrupService().getGrupluStokListe();

      const stokKodu = await getDefaultSettings("isStokKodSearch");
      if (stokKodu == '1') {
        localState.isSearchStokKod = true;
        useStokStore().isStokKodSearch = true;
      }

      const stokBarkod = await getDefaultSettings("isBarkodSearch");
      if (stokBarkod == '1') {
        localState.isSearchBarkod = true;
        useStokStore().isBarkodSearch = true;
      }

      const stokAd = await getDefaultSettings("isStokAdSearch");
      if (stokAd == '1') {
        localState.isSearchStokAd = true;
        useStokStore().isStokAdSearch = true;
      }

      const xmlStok = await getDefaultSettings("isSearchXmlStok");
      if (xmlStok == '1') {
        localState.isSearchXmlStok = true;
        useStokStore().isSearchXmlStok = true;
      }

      const barkodlar = await getDefaultSettings("isSearchBarkodlar");
      if (barkodlar == '1') {
        localState.isSearchBarkodlar = true;
        useStokStore().isSearchBarkodlar = true;
      }

      const kodlar = await getDefaultSettings("isSearchKodlar");
      if (kodlar == '1') {
        localState.isSearchKodlar = true;
        useStokStore().isSearchKodlar = true;
      }

      installWatches();
    }


    onMounted(() => {
      focuslama();
    });

    function stokAraDblClick() {
        if (localState.arama == undefined || localState.arama?.length == 0) {
            localState.showStokListRef = true;
            return;
        }
    }
    async function stokAraEnterKey(e: any) {

      e.event?.currentTarget?.select();

      if (localState.arama == undefined || localState.arama?.length == 0) {
        localState.showStokListRef = true;
        return;
      }

      //await Benchmark.stopWatchAsync("STOK ARAMA:",async () =>{
      const pluItem = StokPluUtils.splitPluBarcode(localState.arama);

      if (pluItem == undefined) {
        const list = StokFlatSearchUtils().stokSearchInFlatStore(localState.arama)

        if (list.length == 1) { // STOK 1 tane var
          tek(list[0]);
          if (localState.stokBulunduSesi)
            await localState.audio.play();
        } else if (list.length > 1) { // Stok 1den fazla var
          localState.stoklistTab = 0;
          localState.showStokListRef = true;

        } else { // HİZMET ARAMA (Stok yok)
          hizmetArama()
        }
      } else {

        const tekStok = useStokFlatStore().getQuery().filter(StokFlatSearchUtils().stokKodFilter(pluItem.stokKodu)).slice(0, 1).toArray()
        if (tekStok && tekStok.length == 1) {
          tek(tekStok[0], pluItem.miktar);
        }

      }


    }

    function hizmetArama() {

      const hizmetBulunanlar = HizmetMasrafSearchUtils().search(localState.arama)

      if (hizmetBulunanlar && hizmetBulunanlar.length > 0) {
        localState.stoklistTab = 1;
        if (hizmetBulunanlar.length == 1) {
          tekHizmet(hizmetBulunanlar[0]);
        } else
          localState.showStokListRef = true;
      } else {
        grupluStokArama();
      }
    }

    function grupluStokArama() {
      const grupluStokBulunanlar = localState.grupluStoklar.filter((a: StokGrupEntity) => {
            return (
                StokUtils.aramaPasGecilen(localState.arama, a.ad) ||
                a.ad?.icindeGeciyorMu(localState.arama) ||
                a.kod?.icindeGeciyorMu(localState.arama) ||
                a.barkod?.icindeGeciyorMu(localState.arama)
            );
          }
      );
      if (grupluStokBulunanlar && grupluStokBulunanlar.length > 0) {


        if (grupluStokBulunanlar.length == 1) {

          const stoklistesi: StokFlatView[] = [];


          grupluStokBulunanlar[0]?.stoklar?.forEach((a) => {
            const findStok = useStokFlatStore().getStok(a.stokId as string) as any;
            if (findStok) {
              const flatView: any = Object.assign({}, findStok);
              flatView.gelenMiktar = a.miktar;
              flatView.fiyat = a.fiyat;
              flatView.satisFiyat = a.fiyat;
              flatView.alisFiyat = a.fiyat;
              flatView.satisKdv = grupluStokBulunanlar[0].satisKdv;
              flatView.alisKdv = grupluStokBulunanlar[0].satisKdv;
              flatView.satisKdvDahil = true;
              flatView.alisKdvDahil = true;
              stoklistesi.push(flatView);
            }
          });
          coklu(stoklistesi);
        } else {

          localState.stoklistTab = 2;
          localState.showStokListRef = true;

        }

      } else {
          if (localState.isSearchXmlStok)
              localState.showXmlStokListDialog = true
          else
            dangerNotif("Stok, Hizmet,Masraf ve Gruplu Stok Bulunamadı", `${localState.arama} - Herhangi Bir Stokla veya Hizmet/Masrafla Eşleşmedi !`);
      }

    }


    function tekHizmet(hizmet: HizmetMasrafEntity) {
      if (hizmet) {
        emit("hizmetDblClick", hizmet);
      }
    }

    function tek(urun: StokFlatView, miktar = 1) {
      const bulunan: any = Object.assign({}, urun);

      if (miktar != 1) {

        bulunan.gelenMiktar = miktar;
      }


      if (bulunan.birimFiyatlari && bulunan.birimFiyatlari.length > 0) {
        const birim: StokBirimItem = bulunan.birimFiyatlari.find((a: any) => a.barkod == localState.arama);

        if (birim) {
          bulunan.birim = birim.birim;
          bulunan.barkodStok = birim.barkod;
          bulunan.carpan = birim.carpan;
          bulunan.fiyat = StokBirimUtils.getFiyat(
              birim.fiyat!,
              birim.fiyatTipi!,
              bulunan.satisFiyat!,
              bulunan.satisKdv!,
              bulunan.satisKdvDahil!,
              bulunan.alisFiyat!,
              bulunan.alisKdv!,
              bulunan.alisKdvDahil!,
              0
          );

        }
      }

      emit("tek", bulunan);
    }

    function coklu(urunler: StokFlatView[]) {
      emit("coklu", urunler);
    }

    function cokluHizmet(hizmetler: HizmetMasrafEntity[]) {
      emit("cokluHizmet", hizmetler);
    }


    function dangerNotif(title: string, body: string) {
      usePopupService().pushDangerNotification({
        body: body,
        duration: 4000,
        title: title
      });
    }

    function yeniStok() {
      StokUtils.openStokForm("", false);
    }

    async function yenileStok() {
      await useStokFlatStore().load();
      await load();
      await  useHizmetMasrafStore().load();
    }

    function focuslama() {
      setTimeout(() => {
        localState.stokDxTextBox?.instance?.focus();
      }, 500);
    }


    function stokEntityAdded(stok: StokFlatView[]) {
      tek(stok[0]);
    }
    function stokAddedFromXml(stok: StokFlatView) {
      tek(stok);
    }

    async function saveSettings(key: string, value: string) {
      await useUserSettingsService().postSave({
        name: key,
        value: value
      });
    }

    async function getDefaultSettings(key: string) {
      return useUserSettingsService().getGetValue(key);
    }



    return {
      ...toRefs(localState),
      stokAraEnterKey,
      yeniStok,
      yenileStok,
      tek,
      coklu,
      focuslama,
      tekHizmet,
      cokluHizmet,
      stokEntityAdded,
      stokAddedFromXml,
      stokAraDblClick,
    };
  }
});
