import { defineStore } from "pinia";

import HizliSatisTabModel from "@/modules/HizliSatis/model/HizliSatisTabModel";
import { StokFlatView } from "@/api/models/StokFlatView";
import { HizliSatisUtils } from "@/modules/HizliSatis/utils/HizliSatisUtils";
import { HizliSatisOdemeTip } from "@/modules/HizliSatis/model/HizliSatisOdemeTip";
import { PerakendeSatirItem } from "@/api/models/PerakendeSatirItem";
import { useFirmaService } from "@/api/services/FirmaService";
import { KurView } from "@/api/models/KurView";
import { useKurCachedServis } from "@/modules/Ortak/services/useKurCachedServis";
import { PerakendeUtil } from "@/modules/Perakende/utils/PerakendeUtil";
import { StokUtils } from "@/modules/Stok/utils/StokUtils";
import { FaturaHareketTip } from "@/api/models/FaturaHareketTip";
import { usePerakendeService } from "@/api/services/PerakendeService";
import { usePopupService } from "@/services/popupService";
import {useHizliSatisStokFlatStore} from "@/modules/HizliSatis/store/HizliSatisStokFlatStore";
import {useBildirimSesleriStore} from "@/modules/Ayarlar/Tanımlar/store/BildirimSesleriStore";


export const useHizliSatisTabsStore = defineStore({
  id: "HizliSatisTabsStore",
  state: () => ({
    tabs: [] as HizliSatisTabModel[],
    selectedTab: {} as HizliSatisTabModel,
    selectedIndex: 0,
    selectedTabId: 0,
    varsayilanCariId: "",
    kurlar: [] as KurView[],
    eventTarget:new EventTarget(),
    isLoaded:false,
    audio:new Audio(('/pos.mp3'))
  }),
  actions: {
    async load() {

      await useHizliSatisStokFlatStore().load()
      this.kurlar = await useKurCachedServis().getKurList();
      const varsayilanCariId = await PerakendeUtil.getPerakendeCariId();
      if (varsayilanCariId && varsayilanCariId.length > 0)
        this.varsayilanCariId = varsayilanCariId;

      if (this.tabs.length == 0)
        this.addTab()

      this.isLoaded = true;
    },
    addTab() {
      const tab = new HizliSatisTabModel();
      this.tabs.push(tab);
      this.selectedTab = tab;
      this.selectedTabId = tab.id;
      this.selectedTab.model.cariId = this.varsayilanCariId;

      this.selectedIndex = this.tabs.length - 1;
    },

    selectTabByIndex(index: number) {
      if (this.tabs.length > 0){
      this.selectedTab = this.tabs[index];
      this.selectedIndex = index;
      this.selectedTabId = this.selectedTab.id;
      }
    },
    removeActiveTab() {
      this.tabs.splice(this.selectedIndex, 1);
      this.selectTabByIndex(this.tabs.length - 1);

      if (this.tabs.length == 0){
        this.addTab()
      }
    },
    removeTabById(tabId: number) {
      this.tabs = this.tabs.filter(a => a.id != tabId);
    },
    selectTabById(id: number) {
      this.selectedIndex = this.tabs.findIndex(a => a.id === id);
      if (this.selectedIndex >=0)
        this.selectTabByIndex(this.selectedIndex);
    },
    async save() {
      const netTutar = HizliSatisUtils.getOdenecekTutar(this.selectedTab.model);


      switch (this.selectedTab.odemeTip) {
        case HizliSatisOdemeTip.Nakit: {
          this.selectedTab.model.kasa = true;
          this.selectedTab.model.kasaTutar = netTutar;
          this.selectedTab.model.havaleTutar = 0;
          this.selectedTab.model.posTutar = 0;
        }

          break;

        case HizliSatisOdemeTip.KrediKarti: {
          this.selectedTab.model.pos = true;
          this.selectedTab.model.kasaTutar = 0;
          this.selectedTab.model.havaleTutar = 0;
          this.selectedTab.model.posTutar = netTutar;
        }
          break;

        case HizliSatisOdemeTip.Havale: {
          this.selectedTab.model.banka = true;
          this.selectedTab.model.kasaTutar = 0;
          this.selectedTab.model.havaleTutar = netTutar;
          this.selectedTab.model.posTutar = 0;
        }
          break;

        case HizliSatisOdemeTip.Coklu :
          this.selectedTab.model.cokluTahsilat = true;
      }
      await usePerakendeService().postEkle(this.selectedTab.model);
      this.successNotif("Başarılı", "Kaydedildi", 3000);
      const currentTabId = this.selectedTabId;
      this.addTab();
      this.removeTabById(currentTabId);

    },
    successNotif(title: string, body: string, duration = 500) {
      usePopupService().pushSuccessNotification({
        body: body,
        duration: duration,
        title: title
      });
    },
    addRowIade(stok: StokFlatView) {
      if (stok) {
        const iadeSatiri = PerakendeUtil.toPerakendeStokSatirItem(stok, this.selectedTab.depoId, this.selectedTab.plasiyerId, this.kurlar);
        if (iadeSatiri)
          this.selectedTab.arrayStoreIade.push([{ type: "insert", data: iadeSatiri }]);
      }
    },

    addRow(stok: StokFlatView, miktar:number) {
      //console.log("tab", this.selectedTab)
      let satir = this.selectedTab.model.iadeler?.find(
        (a: PerakendeSatirItem) =>
          a.stokId == stok.stokId &&
          a.itemId == stok.itemId &&
          a.depoId == this.selectedTab.depoId &&
          a.birim == stok.birim &&
          a.plasiyerId == this.selectedTab.plasiyerId
      );
      if (satir) {
        satir.miktar = satir.miktar as number + miktar;
        this.selectedTab.arrayStore.push([{ type: "update", data: satir, key: satir.id }]);
      } else {
        satir = this.selectedTab.model.satirlar?.find(a => a.stokId == stok.stokId && a.itemId == stok.itemId)

        if (!satir){
          satir = PerakendeUtil.toPerakendeStokSatirItem(stok, this.selectedTab.depoId, this.selectedTab.plasiyerId, this.kurlar);
          if (satir)
          satir.miktar = miktar;
          this.selectedTab.arrayStore.push([{ type: "insert", data: satir }]);
        } else {
          satir.miktar! += miktar
          this.selectedTab.arrayStore.push([{ key:satir.id, type: "update", data: satir }]);
        }


        this.eventTarget.dispatchEvent(new Event("rowInserted"))
      }
      this.playAudio();
    },
    addRowPlu(stok: StokFlatView, pluItem:any) {

        const satir = PerakendeUtil.toPerakendeStokSatirItem(stok, this.selectedTab.depoId, this.selectedTab.plasiyerId, this.kurlar);
        satir.miktar = pluItem.miktar;
        this.selectedTab.arrayStore.push([{ type: "insert", data: satir }]);
        this.eventTarget.dispatchEvent(new Event("rowInserted"))

      this.playAudio();
    },
    addSatirFiyatGrubu(satir: PerakendeSatirItem) {
      const fiyatGrubuOran = this.selectedTab.fiyatGrupOran as number;
      const fiyatGrubuTutar = this.selectedTab.fiyatGrupTutar as number;

      satir.fiyat = StokUtils.getStokFiyat(satir.stokId as string, FaturaHareketTip.SatisFatura);
      satir.fiyat = satir.fiyat * (1 + (satir.kdv! / 100));

      if (fiyatGrubuOran > 0) {
        satir.fiyat = satir.fiyat! * (1 + (fiyatGrubuOran / 100));
        satir.iskontolar = [];
      } else if (fiyatGrubuOran != 0) {
        satir.iskontolar = [];
        satir.iskontolar.push(fiyatGrubuOran * -1);
      }

      if (fiyatGrubuOran == 0 && fiyatGrubuTutar != 0) {
        satir.fiyat = satir.fiyat + fiyatGrubuTutar;
      }
      satir.fiyat = StokUtils.getDovizFiyat(satir.fiyat, satir.stokId as string);

    },
    removeSatirFiyatGrubu(satir: PerakendeSatirItem) {
      satir.fiyat = StokUtils.getStokFiyat(satir.stokId as string, FaturaHareketTip.SatisFatura);

      satir.fiyat = StokUtils.getPerakendeSubeFiyat(satir.fiyat, satir.stokId as string, satir.depoId as string);

      satir.fiyat = StokUtils.getDovizFiyat(satir.fiyat, satir.stokId as string);

      satir.fiyat = satir.fiyat * (1 + (satir.kdv! / 100));
      satir.iskontolar = [];
    },
    setFiyatGrubu(satirlar: PerakendeSatirItem[]) {
      if (satirlar && satirlar.length > 0) {
        satirlar.forEach((satir) => {
          if (this.selectedTab.fiyatGrupOran != 0 || this.selectedTab.fiyatGrupTutar != 0) {
            this.addSatirFiyatGrubu(satir);
          } else {
            this.removeSatirFiyatGrubu(satir);
          }
          this.selectedTab.arrayStore.push([{ type: "update", data: satir, key: satir.id }]);
        });
      }
    },
    playAudio(){
      try {
        const stokBulunduSesi = useBildirimSesleriStore().bildirimStokBulunduSesi
        if (stokBulunduSesi)
          this.audio.play();
      }catch (e:any){
        console.error(e)
      }
    }
  }

});
