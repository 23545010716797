import { NModuleRoute } from "@/models/route.model";

export const PazaryerleriModuleRoutes: NModuleRoute[] = [
  {
    id: "Pazaryerleri",
    order: 8,
    displayName: "Pazaryerleri",
    icon: {
      type: "settings"
    },
    children: [
      {
        id: "OrtakSiparisler",
        order: 0,
        displayName: "Siparişler",
        requiredRoles:{
          readRoles:['Ortak.Siparis.Liste'],
          insertRoles:[],
          updateRoles:[],
          deleteRoles:[]
        },
        icon: {
          type: "users"
        },
        component: () =>
            import(
                /* webpackChunkName: "OrtakSiparislerListPage"*/ "../OrtakSiparisler/pages/OrtakSiparislerListPage.vue"
                )
      },
      {
        id: "Presta",
        order: 0,
        displayName: "Nesilce E-Ticaret",
        requiredRoles:{
          readRoles:['Presta.Siparis.Liste'],
          insertRoles:[],
          updateRoles:[],
          deleteRoles:[]
        },
        icon: {
          type: "users"
        },
        component: () =>
            import(
                /* webpackChunkName: "Presta"*/ "../Presta/pages/PrestaSiparislerPage.vue"
                )
      },
      {
        id: "N11",
        order: 0,
        displayName: "N11",
        requiredRoles:{
          readRoles:['N11.Siparis.Liste'],
          insertRoles:[],
          updateRoles:[],
          deleteRoles:[]
        },
        icon: {
          type: "users"
        },
        component: () =>
            import(
                /* webpackChunkName: "N11"*/ "../N11/pages/N11Page.vue"
                )
      },
      {
        id: "Trendyol",
        order: 0,
        displayName: "Trendyol",
        requiredRoles:{
          readRoles:['Trendyol.Siparis.Liste'],
          insertRoles:[],
          updateRoles:[],
          deleteRoles:[]
        },
        icon: {
          type: "users"
        },
        component: () =>
            import(
                /* webpackChunkName: "Trendyol"*/ "../Trendyol/pages/TrendyolSiparislerPages.vue"
                )
      },
      {
        id: "HepsiBurada",
        order: 0,
        displayName: "HepsiBurada",
        requiredRoles:{
          readRoles:['HepsiBurada.Siparis.Liste'],
          insertRoles:[],
          updateRoles:[],
          deleteRoles:[]
        },
        icon: {
          type: "users"
        },
        component: () =>
            import(
                /* webpackChunkName: "HepsiBurada"*/ "../HepsiBurada/pages/HepsiBuradaSiparislerPage.vue"
                )
      },
      {
        id: "CicekSepeti",
        order: 0,
        displayName: "ÇiçekSepeti",
        requiredRoles:{
          readRoles:['CicekSepeti.Siparis.Liste'],
          insertRoles:[],
          updateRoles:[],
          deleteRoles:[]
        },
        icon: {
          type: "users"
        },
        component: () =>
            import(
                /* webpackChunkName: "CicekSepeti"*/ "../CicekSepeti/pages/CicekSepetiPage.vue"
                )
      },
      {
        id: "Amazon",
        order: 0,
        displayName: "Amazon",
        requiredRoles:{
          readRoles:['Amazon.Siparis.Liste'],
          insertRoles:[],
          updateRoles:[],
          deleteRoles:[]
        },
        icon: {
          type: "users"
        },
        component: () =>
            import(
                /* webpackChunkName: "Amazon"*/ "../Amazon/pages/AmazonPage.vue"
                )
      },
      {
        id: "EpttAvm",
        order: 0,
        displayName: "EpttAvm",
        requiredRoles:{
          readRoles:['EpttAvm.Siparis.Liste'],
          insertRoles:[],
          updateRoles:[],
          deleteRoles:[]
        },
        icon: {
          type: "users"
        },
        component: () =>
            import(
                /* webpackChunkName: "EpttAvm"*/ "../EpttAvm/pages/EpttAvmPage.vue"
                )
      },
      {
        id: "Pazarama",
        order: 0,
        displayName: "Pazarama",
        requiredRoles:{
          readRoles:['Pazarama.Siparis.Liste'],
          insertRoles:[],
          updateRoles:[],
          deleteRoles:[]
        },
        icon: {
          type: "users"
        },
        component: () =>
            import(
                /* webpackChunkName: "Pazarama"*/ "../Pazarama/pages/PazaramaPage.vue"
                )
      }
    ]
  }
];
