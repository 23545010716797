import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "dx-card",
  style: {"height":"100%"}
}
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 0,
  class: "imageStok",
  style: {"width":"360px"}
}
const _hoisted_4 = { span: "2" }
const _hoisted_5 = {
  class: "dx-card",
  style: {"height":"100%"}
}
const _hoisted_6 = {
  class: "dx-card",
  style: {"height":"100%"}
}
const _hoisted_7 = ["src"]
const _hoisted_8 = { style: {"text-align":"center"} }
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxExport = _resolveComponent("DxExport")!
  const _component_DxLoadPanel = _resolveComponent("DxLoadPanel")!
  const _component_DxScrolling = _resolveComponent("DxScrolling")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxGroupPanel = _resolveComponent("DxGroupPanel")!
  const _component_DxSelection = _resolveComponent("DxSelection")!
  const _component_DxColumnChooser = _resolveComponent("DxColumnChooser")!
  const _component_DxStateStoring = _resolveComponent("DxStateStoring")!
  const _component_NDxStateStoring = _resolveComponent("NDxStateStoring")!
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NViewGrid = _resolveComponent("NViewGrid")!
  const _component_XmlImporterStokListesi = _resolveComponent("XmlImporterStokListesi")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!
  const _component_StokFlatListSagMenu = _resolveComponent("StokFlatListSagMenu")!
  const _component_HizliStokDialog = _resolveComponent("HizliStokDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NModalDialog, {
      width: "1200px",
      modelValue: _ctx.showRef,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.showRef) = $event)),
      "closable-mask": false,
      tabs: [{ label : 'Stok Listesi' , icon : 'shopping-bag' } ,
                 {label : 'Hizmet Listesi' , icon : 'cpu' } ,
                 {label : 'Gruplu Stok Listesi' , icon : 'copy'},
                 {label : 'XML Stok Listesi' , icon : 'link'}
                 ],
      title: "Stok / Hizmet - Masraf / Gruplu Stok / XML Stok Arama Formu",
      "show-close": true,
      "selected-tab": _ctx.selectedTabRef,
      "onUpdate:selectedTab": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.selectedTabRef) = $event))
    }, {
      body: _withCtx(() => [
        _withDirectives(_createVNode(_component_NGrid, {
          cols: "2fr auto",
          gap: "8px"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_DxDataGrid, {
                key: "id",
                "data-source": _ctx.stoklarDataSource,
                ref: "stokAramaInputDataGrid",
                class: "stokFlatListDataGrid",
                "row-alternation-enabled": true,
                "allow-column-reordering": true,
                "allow-column-resizing": true,
                "column-resizing-mode": "widget",
                toolbarTemplate: "toolbar",
                "show-row-lines": true,
                "show-borders": true,
                "word-wrap-enabled": true,
                onRowDblClick: _cache[4] || (_cache[4] = (e)=>_ctx.rowDblClick(e.data)),
                onSelectionChanged: _ctx.itemClick,
                onContextMenuPreparing: (e) =>  _ctx.stokFlatListSagMenu.sagMenu(e),
                onCellHoverChanged: _ctx.cellHover,
                style: {"height":"100%","width":"100%"}
              }, {
                toolbar: _withCtx(() => [
                  _createVNode(_component_NGrid, {
                    cols: "auto 1fr auto",
                    rows: "34px",
                    gap: "8px"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NDxStateStoring, { "data-grid-ref": _ctx.stokAramaInputDataGrid }, null, 8, ["data-grid-ref"]),
                      _createVNode(_component_DxTextBox, {
                        placeholder: "Stok Adı, Kod, Barkod ile ara...",
                        value: _ctx.searchStokInput,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchStokInput) = $event)),
                        width: 300,
                        "show-clear-button": true,
                        "prefix-icon": "search",
                        onValueChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.aramaDelay($event, _ctx.stokAramaValueChanged, 750))),
                        type: "text",
                        "value-change-event": "keyup"
                      }, null, 8, ["value"]),
                      _createVNode(_component_NButton, {
                        type: "success",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.hizliStokDialogShow = true )),
                        icon: "plus",
                        size: "small",
                        title: "Yeni Stok Ekle"
                      })
                    ]),
                    _: 1
                  })
                ]),
                resimTemplate: _withCtx(({data}) => [
                  _createElementVNode("div", null, [
                    _createElementVNode("img", {
                      style: {"max-height":"125px","max-width":"125px"},
                      src: _ctx.getStokResim(data.data)
                    }, null, 8, _hoisted_2)
                  ])
                ]),
                satisFiyatTemplate: _withCtx(({data}) => [
                  _createVNode(_component_NGrid, { cols: "1fr" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(data.data.satisFiyat.format(2, 2)) + " " + _toDisplayString(_ctx.dovizList.find(a => a.value == data.data.satisDoviz)?.description), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_DxExport, { enabled: true }),
                  _createVNode(_component_DxLoadPanel, { enabled: false }),
                  _createVNode(_component_DxScrolling, { mode: "virtual" }),
                  _createVNode(_component_DxHeaderFilter, { visible: true }),
                  _createVNode(_component_DxFilterRow, { visible: true }),
                  _createVNode(_component_DxGroupPanel, { visible: true }),
                  _createVNode(_component_DxSelection, {
                    "select-all-mode": "allMode",
                    "show-check-boxes-mode": "always",
                    mode: "multiple"
                  }),
                  _createVNode(_component_DxColumnChooser, {
                    enabled: true,
                    mode: "select"
                  }),
                  _createVNode(_component_DxStateStoring, {
                    enabled: true,
                    type: "custom",
                    "custom-load": () =>  _ctx.loadState()
                  }, null, 8, ["custom-load"]),
                  _createVNode(_component_DxColumn, {
                    width: "100",
                    "data-field": "kod",
                    caption: "Stok Kodu"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "90",
                    "data-field": "no",
                    caption: "No"
                  }),
                  _createVNode(_component_DxColumn, {
                    cssClass: "urunAdi",
                    width: "220px",
                    "data-field": "ad",
                    caption: "Ürün Adı"
                  }),
                  _createVNode(_component_DxColumn, {
                    cssClass: "satisFiyat",
                    width: "85px",
                    "data-field": "getSatisFiyat",
                    caption: "Satış",
                    "data-type": "number",
                    format: (r) => r.format(2,2) ,
                    cellTemplate: "satisFiyatTemplate"
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    cssClass: "miktar",
                    width: "85px",
                    "data-field": "kalanMiktar",
                    caption: "Miktar",
                    "data-type": "number",
                    format: (r) => r.format(0,0)
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    width: "85px",
                    "data-field": "getSatisSiparisMiktari",
                    caption: "S.Sipariş Miktar",
                    "data-type": "number"
                  }),
                  _createVNode(_component_DxColumn, {
                    cssClass: "alisFiyat",
                    width: "85px",
                    "data-field": "getAlisFiyat",
                    caption: "Alış",
                    "data-type": "number",
                    format: (r) => r.format(2,2)
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    visible: false,
                    caption: "Özellikler",
                    columns: _ctx.ozelliklerColums,
                    "onUpdate:columns": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.ozelliklerColums) = $event))
                  }, null, 8, ["columns"]),
                  _createVNode(_component_DxColumn, {
                    cssClass: "middle",
                    width: "150px",
                    "data-field": "grup1",
                    caption: "Grup 1 ",
                    "data-type": "string"
                  }),
                  _createVNode(_component_DxColumn, {
                    cssClass: "middle",
                    width: "150px",
                    "data-field": "grup2",
                    caption: "Grup 2 ",
                    "data-type": "string"
                  }),
                  _createVNode(_component_DxColumn, {
                    cssClass: "middle",
                    width: "80px",
                    "data-field": "grup3",
                    caption: "Grup 3 ",
                    "data-type": "string"
                  }),
                  _createVNode(_component_DxColumn, {
                    cssClass: "middle",
                    width: "80px",
                    "data-field": "grup4",
                    caption: "Grup 4 ",
                    "data-type": "string"
                  }),
                  _createVNode(_component_DxColumn, {
                    cssClass: "middle",
                    width: "120px",
                    "data-field": "barkodStok",
                    caption: "Barkod",
                    "data-type": "string"
                  }),
                  _createVNode(_component_DxColumn, {
                    cssClass: "middle",
                    width: "120px",
                    "data-field": "barkodItem",
                    caption: "Varyant Barkod",
                    "data-type": "string"
                  }),
                  _createVNode(_component_DxColumn, {
                    cssClass: "middle",
                    width: "120px",
                    "data-field": "ureticiKod",
                    caption: "Üretici Kodu",
                    "data-type": "string"
                  }),
                  _createVNode(_component_DxColumn, {
                    caption: "Resim",
                    width: "130px",
                    "data-field": "resim",
                    cellTemplate: "resimTemplate",
                    visible: false
                  }),
                  _createVNode(_component_DxColumn, {
                    width: 100,
                    "data-field": "tipOzellikler.etiket",
                    caption: "Etiket"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: 100,
                    "data-field": "tipOzellikler.kalite",
                    caption: "Kalite"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: 100,
                    "data-field": "tipOzellikler.cins",
                    caption: "Cins"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: 100,
                    "data-field": "tipOzellikler.kalinlik",
                    caption: "Kalınlık",
                    format: (d) => d?.format(0, 2)
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    width: 100,
                    "data-field": "tipOzellikler.genislik",
                    caption: "Genişlik",
                    format: (d) => d?.format(0, 2)
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    width: 100,
                    "data-field": "tipOzellikler.uzunluk",
                    caption: "Uzunluk"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: 100,
                    "data-field": "tipOzellikler.mensei",
                    caption: "Mensei"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: 100,
                    "data-field": "tipOzellikler.adet",
                    caption: "Adet",
                    format: (d) => d?.format(0, 2)
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    width: 100,
                    "data-field": "tipOzellikler.cari.name",
                    caption: "Cari"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: 100,
                    "data-field": "tipOzellikler.bultenNo",
                    caption: "Bülten No"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: 100,
                    "data-field": "tipOzellikler.siparisNo",
                    caption: "Sipariş No"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: 120,
                    "data-field": "kayitTarihi",
                    caption: "Kayıt Tarihi",
                    "data-type": "datetime",
                    "sort-order": "desc",
                    "sort-index": 0
                  }),
                  _createVNode(_component_DxColumn, {
                    cssClass: "middle",
                    width: "120px",
                    "data-field": "itemKod",
                    caption: "Varyant Kod",
                    "data-type": "string"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "100",
                    "data-field": "uyumluluklarString",
                    caption: "Uyumluluk",
                    visible: false
                  })
                ]),
                _: 1
              }, 8, ["data-source", "onSelectionChanged", "onContextMenuPreparing", "onCellHoverChanged"])
            ]),
            (_ctx.stoklarHover && _ctx.stoklarHover.ad)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_NTag, {
                      filled: true,
                      type: "success",
                      style: {"font-size":"25px","font-weight":"bold"}
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.stoklarHover?.getSatisFiyat?.format(2, 2)) + " " + _toDisplayString(_ctx.dovizList.find(a => a.value == _ctx.stoklarSelected?.satisDoviz)?.description), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createVNode(_component_NTag, { style: {"padding":"8px","margin":"8px 0","width":"100%","height":"48px"} }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.stoklarHover?.ad), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NTag, { style: {"width":"100%"} }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        style: _normalizeStyle({backgroundImage:'url(' + _ctx.getImage() + ')', height:'320px', width:'100%', backgroundSize:'cover'})
                      }, null, 4)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.selectedTabRef === 0 ]
        ]),
        _withDirectives(_createVNode(_component_NGrid, { cols: "1fr" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_NViewGrid, {
                cols: "minmax(0, 1fr)",
                rows: "minmax(0,1fr)"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxDataGrid, {
                    ref: "hizmetlerDataGrid",
                    "data-source": _ctx.hizmetlerDataSource,
                    "row-alternation-enabled": true,
                    "allow-column-reordering": true,
                    "allow-column-resizing": true,
                    "column-resizing-mode": "widget",
                    "show-row-lines": true,
                    "show-borders": true,
                    onRowDblClick: _cache[7] || (_cache[7] = (e)=>_ctx.hizmetDblClick(e.data)),
                    onSelectionChanged: _ctx.hizmetItemClick,
                    toolbarTemplate: "toolbarTemplate",
                    style: {"height":"100%","width":"100%"},
                    key: "id"
                  }, {
                    toolbarTemplate: _withCtx(() => [
                      _createVNode(_component_NGrid, {
                        cols: "auto 300px",
                        rows: "34px",
                        gap: "8px"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_NDxStateStoring, { "data-grid-ref": _ctx.hizmetlerDataGrid }, null, 8, ["data-grid-ref"]),
                          _createVNode(_component_DxTextBox, {
                            "value-change-event": "keyup",
                            value: _ctx.searchHizmetInput,
                            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.searchHizmetInput) = $event)),
                            "prefix-icon": "search",
                            onValueChanged: _cache[6] || (_cache[6] = ($event: any) => (_ctx.aramaDelay($event, _ctx.hizmetAramaValueChanged, 750))),
                            type: "text",
                            placeholder: "Hizmet Ara..."
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      })
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_DxExport, { enabled: false }),
                      _createVNode(_component_DxLoadPanel, { enabled: true }),
                      _createVNode(_component_DxScrolling, { mode: "virtual" }),
                      _createVNode(_component_DxHeaderFilter, { visible: true }),
                      _createVNode(_component_DxFilterRow, { visible: true }),
                      _createVNode(_component_DxGroupPanel, { visible: false }),
                      _createVNode(_component_DxSelection, {
                        "select-all-mode": "allMode",
                        "show-check-boxes-mode": "always",
                        mode: "multiple"
                      }),
                      _createVNode(_component_DxColumn, {
                        width: 90,
                        "data-field": "kod",
                        caption: "Kod"
                      }),
                      _createVNode(_component_DxColumn, {
                        width: 90,
                        "data-field": "barkod",
                        caption: "Barkod"
                      }),
                      _createVNode(_component_DxColumn, {
                        width: 90,
                        "data-field": "ad",
                        caption: "Adı"
                      }),
                      _createVNode(_component_DxColumn, {
                        width: 90,
                        "data-field": "grup1",
                        caption: "Grup 1"
                      }),
                      _createVNode(_component_DxColumn, {
                        width: 90,
                        "data-field": "grup2",
                        caption: "Grup 2"
                      }),
                      _createVNode(_component_DxColumn, {
                        width: 90,
                        "data-field": "grup3",
                        caption: "Grup 3"
                      }),
                      _createVNode(_component_DxColumn, {
                        width: 90,
                        "data-field": "grup4",
                        caption: "Grup 4"
                      }),
                      _createVNode(_component_DxColumn, {
                        width: 90,
                        "data-field": "kdv",
                        caption: "KDV"
                      }),
                      _createVNode(_component_DxColumn, {
                        width: 90,
                        "data-field": "birim",
                        caption: "Birim"
                      }),
                      _createVNode(_component_DxColumn, {
                        width: 120,
                        "data-field": "kayitTarihi",
                        caption: "Kayıt Tarihi",
                        "data-type": "datetime",
                        "sort-order": "desc",
                        "sort-index": 0
                      }),
                      _createVNode(_component_DxColumn, {
                        width: 90,
                        "data-field": "doviz",
                        caption: "Döviz",
                        calculateCellValue: (a)=> _ctx.dovizTipList.find(x=>x.value == a.doviz)?.description
                      }, null, 8, ["calculateCellValue"]),
                      _createVNode(_component_DxColumn, {
                        width: 90,
                        "data-field": "kur",
                        caption: "Kur",
                        alignment: "right",
                        format: (a) => a.format(2,2)
                      }, null, 8, ["format"])
                    ]),
                    _: 1
                  }, 8, ["data-source", "onSelectionChanged"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.selectedTabRef === 1 ]
        ]),
        _withDirectives(_createVNode(_component_NGrid, { cols: "1fr" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_DxDataGrid, {
                ref: "grupluStokDataGrid",
                "data-source": _ctx.grupluStokDataSource,
                "row-alternation-enabled": true,
                "allow-column-reordering": true,
                "allow-column-resizing": true,
                "column-resizing-mode": "widget",
                "show-row-lines": true,
                "show-borders": true,
                onRowDblClick: _cache[8] || (_cache[8] = (e)=>_ctx.grupluStokDblClick(e.data)),
                onSelectionChanged: _ctx.grupluStokItemClick,
                style: {"height":"100%","width":"100%"},
                key: "id"
              }, {
                resimCellTemplate: _withCtx(({data}) => [
                  _createElementVNode("img", {
                    src: _ctx.serverUrlRef + 'thumbs/' + data.data.resim + '/20',
                    height: "100px"
                  }, null, 8, _hoisted_7)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_DxExport, { enabled: false }),
                  _createVNode(_component_DxLoadPanel, { enabled: true }),
                  _createVNode(_component_DxScrolling, { mode: "virtual" }),
                  _createVNode(_component_DxHeaderFilter, { visible: true }),
                  _createVNode(_component_DxFilterRow, { visible: true }),
                  _createVNode(_component_DxGroupPanel, { visible: false }),
                  _createVNode(_component_DxSelection, {
                    "select-all-mode": "allMode",
                    "show-check-boxes-mode": "always",
                    mode: "multiple"
                  }),
                  _createVNode(_component_DxStateStoring, {
                    enabled: true,
                    storageKey: "StokHizmetListDialogDataGrid"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "90",
                    "data-field": "kod",
                    caption: "Kod"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "100",
                    "data-field": "barkod",
                    caption: "Barkod"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "150",
                    "data-field": "ad",
                    caption: "Kod"
                  }),
                  _createVNode(_component_DxColumn, {
                    width: "80",
                    "data-field": "satisFiyat",
                    caption: "Fiyatı",
                    format: (d) => d.format(2,2) 
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    width: "80",
                    "data-field": "satisKdv",
                    caption: "Kdv",
                    format: (d) => d.format(2,2) 
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    width: "80",
                    "data-field": "bakiye",
                    caption: "Miktar",
                    format: (d) => d.format(2,2) 
                  }, null, 8, ["format"]),
                  _createVNode(_component_DxColumn, {
                    width: 100,
                    "allow-sorting": false,
                    visible: true,
                    "data-field": "resim",
                    "cell-template": "resimCellTemplate"
                  })
                ]),
                _: 1
              }, 8, ["data-source", "onSelectionChanged"])
            ])
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.selectedTabRef === 2 ]
        ]),
        _withDirectives(_createVNode(_component_NGrid, {
          cols: "2fr auto",
          gap: "8px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_XmlImporterStokListesi, {
              searchText: _ctx.searchStokInput,
              onStokEntityAddedFromXml: _ctx.stokAddedFromXml,
              isSingleSelect: true
            }, null, 8, ["searchText", "onStokEntityAddedFromXml"])
          ]),
          _: 1
        }, 512), [
          [_vShow, _ctx.selectedTabRef === 3 ]
        ])
      ]),
      actions: _withCtx(() => [
        _createVNode(_component_NButton, {
          size: "small",
          type: "danger",
          icon: "x",
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.showRef = false))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Kapat")
          ]),
          _: 1
        }),
        _createVNode(_component_NButton, {
          onClick: _ctx.coklu,
          disabled: !(_ctx.stoklarSelecteds?.length > 1 || _ctx.hizmetlerSelecteds?.length > 1 || _ctx.grupluStokSelecteds?.length > 1 ),
          size: "small",
          type: "success",
          icon: "plus"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Seçilenleri Ekle ")
          ]),
          _: 1
        }, 8, ["onClick", "disabled"])
      ]),
      _: 1
    }, 8, ["modelValue", "selected-tab"]),
    _createVNode(_component_StokFlatListSagMenu, { ref: "stokFlatListSagMenu" }, null, 512),
    (_ctx.showImage)
      ? (_openBlock(), _createBlock(_component_NModalDialog, {
          key: 0,
          "show-close": true,
          modelValue: _ctx.showImage,
          "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.showImage) = $event)),
          "closable-mask": true,
          "close-on-esc": true,
          title: "Resim Önizleme",
          width: "100%"
        }, {
          body: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("img", { src: _ctx.selectedImage }, null, 8, _hoisted_9)
            ])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.hizliStokDialogShow)
      ? (_openBlock(), _createBlock(_component_HizliStokDialog, {
          key: 1,
          onStokEntityAdded: _ctx.stokEntityAdded,
          show: _ctx.hizliStokDialogShow,
          "onUpdate:show": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.hizliStokDialogShow) = $event))
        }, null, 8, ["onStokEntityAdded", "show"]))
      : _createCommentVNode("", true)
  ], 64))
}