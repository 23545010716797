
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import NDropDownTreeView from "@/components/DropDownTreeView/NDropDownTreeView.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import {EpttAvmPazaryeriItem} from "@/api/models/EpttAvmPazaryeriItem";
import {Category1} from "@/api/models/Category1";
import {useEpttAvmKategoriService} from "@/api/services/EpttAvmKategoriService";
import NRefresher from "@/components/Refresher/NRefresher.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NSwitch from "@/components/Switch/NSwitch.vue";
import NInput from "@/components/Input/NInput.vue";
import NDropDownListCustomStore from "@/components/Dropdown/NDropDownListCustomStore.vue";
import CustomStore from "devextreme/data/custom_store";
import {number, string} from "yup";
import EpttAvmOzellikEditor from "@/modules/Pazaryerleri/EpttAvm/components/EpttAvmOzellikEditor.vue";
import {PazaryeriFiyatTipiList} from "@/api/models/PazaryeriFiyatTipi";

export default defineComponent({
  components: {
      EpttAvmOzellikEditor,
      NDropDownListCustomStore, NInput, NSwitch, NFormField, NRefresher, NFormGrid, NDropDownTreeView},
  props: ['epttAvm'],
  setup(props) {

    const localState = reactive({
        epttRef: computed(() => props.epttAvm as EpttAvmPazaryeriItem),
        kategoriler: ref([] as any[]),
        selectedKategoriItem: ref({} as any),
        dataSourceMarkalar: new CustomStore({
            key: 'id',
            load: (loadOptions) => {
                return useEpttAvmKategoriService().postGetBrands({data: JSON.stringify(loadOptions) as any})
            }
        }),
        pazaryeriFiyatTipiList:ref(PazaryeriFiyatTipiList),
    });

    watch(() => localState.selectedKategoriItem, (newValue) => {
        localState.epttRef.kategoriId = Number(newValue.key)
    })


    async function load() {
        const response = await useEpttAvmKategoriService().getGetAll(true);
        localState.kategoriler = response;

        localState.selectedKategoriItem = {
            key: localState.epttRef.kategoriId ?? 0,
            title: getCategoryName(localState.epttRef.kategoriId as number, response),
        }
    }

    function getCategoryName(id: number, root: any[]) {

      for (const rootElement of root) {
        const sonuc = getRecursiveCategoryName(id, rootElement)
        if (sonuc)
          return sonuc;
      }
      return '';
    }

      function getRecursiveCategoryName(key: number, currentNode: any): string {
          let i, currentChild, result;

          if (key == currentNode.key) {
              return currentNode.title;
          } else {
              for (i = 0; i < currentNode.children?.length; i += 1) {
                  currentChild = currentNode.children[i];

                  result = getRecursiveCategoryName(key, currentChild);
                  if (result) {
                      return result as string;
                  }
              }
              return '';
          }
      }

    load()
    return {
      ...toRefs(localState),
      load
    };
  }
});

