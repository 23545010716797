
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NDropDownListBox from "@/components/Dropdown/NDropDownListBox.vue";
import {StokEntity} from "@/api/models/StokEntity";
import _ from "lodash";
import NGrid from "@/components/Grid/NGrid.vue";
import {EpttAvmOzellikItem} from "@/api/models/EpttAvmOzellikItem";
import {EPttAvmCategoryPropertyData} from "@/api/models/EPttAvmCategoryPropertyData";
import {useEpttAvmKategoriService} from "@/api/services/EpttAvmKategoriService";
import {IdName} from "@/api/models/IdName";

export default defineComponent({
    components: {NGrid, NDropDownListBox, NFormField, NFormGrid, NLoadingPanel},
    props: {
        ozellikler: {
            type: Array
        },
        kategoriId: {
            type: Number
        },
        cols: {
            type: String,
            default: '1fr 1fr 1fr'
        },
        stok: {
            type: Object
        }
    },

    setup(props, {emit}) {
        const localState = reactive({
            loadingPanel: ref(),
            epttAvmOzellikler: ref([] as EPttAvmCategoryPropertyData[]),
            stokRef: computed(() => props.stok as StokEntity),
            nesilceOzellikler: computed({
                get: () => props.ozellikler as EpttAvmOzellikItem[],
                set: (val) => emit('update:ozellikler', val)
            }),
            text: ref('REF')
        })

        watch(() => props.kategoriId, (kategoriId) => {
            localState.loadingPanel.load();
        })

        async function load() {
            const kategoriId = String(props.kategoriId);
            if (kategoriId) {
                // Load Özellikler
                localState.epttAvmOzellikler = await useEpttAvmKategoriService().getGetCategoryAttributes(Number(kategoriId));
                localState.epttAvmOzellikler = _.orderBy(localState.epttAvmOzellikler, ['title'], ['asc'])
                // console.log("epttAvmOzellikler", localState.epttAvmOzellikler)

                nesilceToEpttAvm();

            }
        }

        function nesilceToEpttAvm() {

            //console.log(localState.pazaramaOzellikler) // özellik array isRequired, id, name, attrs vs
            //console.log("nesilceOzellikler", localState.nesilceOzellikler) // id, value = selectedvalue


            if (localState.nesilceOzellikler) {
                // selected id,value array
                localState.nesilceOzellikler.forEach(ozellik => { // (id = aeb2323-feb2521-acb45-ecda023, value = "Bağlantı Tipi")

                    const epttAvmOzellik = localState.epttAvmOzellikler.find((a: any) => a.id == ozellik.id) as any;

                    if (epttAvmOzellik) {
                        epttAvmOzellik.values = ozellik.value?.split('+')[0]
                    }
                    // console.log("pttOzellik:", epttAvmOzellik, "özellik", ozellik)

                })

            } // if
        }


        function epttAvmToNesilce() {
            const nesilceOzellikler = [] as any[];

            if (localState.epttAvmOzellikler) {
                localState.epttAvmOzellikler.forEach((attribute: any) => {

                    if (attribute.values && attribute.values?.length > 0)
                        nesilceOzellikler.push({
                            id: String(attribute.id),
                            value: attribute.values + '+' + getPropertyNameByValue(attribute.values),
                            name: attribute.title as string
                        })
                })
            }

            localState.nesilceOzellikler = nesilceOzellikler;
        }

        function getPropertyNameByValue(attributeValue: string) {
            let valueName:any = ""
            // console.log("giren:", attributeValue)
            for (const ozellik of localState.epttAvmOzellikler) {
                // console.log("ozellik", ozellik)
                valueName = ozellik.attributes?.find(a => a.id === attributeValue)?.name
                if (valueName && valueName.length > 0)
                    return valueName
            }

          return "";
        }


        return {
            ...toRefs(localState),
            load,
            epttAvmToNesilce,
        }
    }
})
