import DateTimeFormatOptions = Intl.DateTimeFormatOptions;

export {};

Date.prototype.formatDate = function(): string {
  return new Intl.DateTimeFormat("tr-TR", {
    dateStyle: "short"
  } as DateTimeFormatOptions).format(this);
};

Date.prototype.formatDateTime = function(): string {
  return new Intl.DateTimeFormat("tr-TR", {
    dateStyle: "short",
    timeStyle: "short"
  } as DateTimeFormatOptions).format(this);
};
Date.prototype.formatTime = function(): string {
  return new Intl.DateTimeFormat("tr-TR", {
    timeStyle: "short"
  } as DateTimeFormatOptions).format(this);
};

Date.prototype.isLeapYear = function (year:number):boolean {
  return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0))  ;
};

Date.prototype.getDaysInMonth2 = function (year:number, month:number):number {
  return [31, (Date.prototype.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
};

Date.prototype.isLeapYear2 = function () {
  return Date.prototype.isLeapYear(this.getFullYear());
};


Date.prototype.getDaysInMonth = function ():number {
  return Date.prototype.getDaysInMonth2(this.getFullYear(), this.getMonth());
};


Date.prototype.addMonths = function (value:number) {
  const n = this.getDate();
  this.setDate(1);
  this.setMonth(this.getMonth() + value);
  this.setDate(Math.min(n, this.getDaysInMonth()));
  return this;
};
Date.prototype.addDays = function(days:number):Date {
  const n  = this.getDate() ;
  this.setDate(n + days);
  return this;
}

Date.prototype.addHours = function(hours:number):Date {
  const n  = this.getHours();
  this.setHours(n+ hours);
  return this;
}

