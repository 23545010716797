
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from "vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NInput from "@/components/Input/NInput.vue";
import {PrestaPazaryeriItem} from "@/api/models/PrestaPazaryeriItem";
import {StokEntity} from "@/api/models/StokEntity";
import NSwitch from "@/components/Switch/NSwitch.vue";
import PazaryeriFiyatItem from "@/modules/Pazaryerleri/components/PazaryeriFiyatItem.vue";
import NButton from "@/components/Button/NButton.vue";
import NInputHtml from "@/components/Input/NInputHtml.vue";
import {IdName} from "@/api/models/IdName";
import {usePrestaKatalogService} from "@/api/services/PrestaKatalogService";
import {StokView} from "@/api/models/StokView";

export default defineComponent({
  components: {NInputHtml, NFormField, NButton, PazaryeriFiyatItem, NSwitch, NFormGrid},
  props: [
    'presta',
    'stok',
    'alisKdv',
    'alisKdvDahil',
    'satisKdv',
    'satisKdvDahil',
    'alisFiyat',
    'satisFiyat',
    'configs',
    'index',
    'prestaKategorileri'
  ],
  setup(props, {emit}) {

    const localState = reactive({
      prestaRef: computed(a => props.presta as PrestaPazaryeriItem),
      stokRef: computed(a => props.stok as StokView),
      prestaKategorileriRef: ref([] as IdName[]),
      alisKdvRef: computed({get: () => props.alisKdv, set: (val) => emit('update:alisKdv', val)}),
      alisKdvDahilRef: computed({get: () => props.alisKdvDahil, set: (val) => emit('update:alisKdvDahil', val)}),
      satisKdvRef: computed({get: () => props.satisKdv, set: (val) => emit('update:satisKdv', val)}),
      satisKdvDahilRef: computed({get: () => props.satisKdvDahil, set: (val) => emit('update:satisKdvDahil', val)}),
      alisFiyatRef: computed({get: () => props.alisFiyat, set: (val) => emit('update:alisFiyat', val)}),
      satisFiyatRef: computed({get: () => props.satisFiyat, set: (val) => emit('update:satisFiyat', val)}),
    });

    onMounted(async () => {
      localState.prestaKategorileriRef = await  usePrestaKatalogService().getGetKategoriler();
    })

    function addNew() {
      if (confirm('Yeni site eklemek istediğinize emin misiniz?'))
        if (localState.stokRef.presta) {
          const clone = JSON.parse(JSON.stringify(localState.stokRef.presta[0]));
          localState.stokRef.presta.push(clone);
        }
    }

    function remove(index: number) {
      if (confirm('E-Ticaret sitesini silmek istiyor musunuz?'))
        if (localState.stokRef?.presta) {
          localState.stokRef.presta.splice(index, 1)
        }
    }

    // console.log(props.presta);
    return {
      ...toRefs(localState),
      addNew,
      remove
    };
  }
});

