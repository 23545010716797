import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ff6edfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-box" }
const _hoisted_2 = { class: "n-input-wrapper" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  key: 0,
  value: "null"
}
const _hoisted_5 = ["value"]
const _hoisted_6 = ["disabled"]
const _hoisted_7 = {
  key: 0,
  value: "null"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.valueField)
        ? _withDirectives((_openBlock(), _createElementBlock("select", {
            key: 0,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelValueRef) = $event)),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changed && _ctx.changed(...args))),
            class: "n-input",
            disabled: _ctx.disabledRef
          }, [
            (_ctx.firstOptionLabel && _ctx.firstOptionLabel.length > 0)
              ? (_openBlock(), _createElementBlock("option", _hoisted_4, _toDisplayString(_ctx.firstOptionLabel), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
              return (_openBlock(), _createElementBlock("option", {
                value: _ctx.getValue(item),
                key: item[_ctx.valueField]
              }, _toDisplayString(_ctx.getLabel(item)), 9, _hoisted_5))
            }), 128))
          ], 40, _hoisted_3)), [
            [_vModelSelect, _ctx.modelValueRef]
          ])
        : _withDirectives((_openBlock(), _createElementBlock("select", {
            key: 1,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelValueRef) = $event)),
            onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.changed && _ctx.changed(...args))),
            class: "n-input",
            disabled: _ctx.disabledRef
          }, [
            (_ctx.firstOptionLabel && _ctx.firstOptionLabel.length > 0)
              ? (_openBlock(), _createElementBlock("option", _hoisted_7, _toDisplayString(_ctx.firstOptionLabel), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
              return (_openBlock(), _createElementBlock("option", { key: item }, _toDisplayString(_ctx.getLabel(item)), 1))
            }), 128))
          ], 40, _hoisted_6)), [
            [_vModelSelect, _ctx.modelValueRef]
          ])
    ])
  ]))
}