
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import CicekSepetiOzellikEditor from "@/modules/Pazaryerleri/CicekSepeti/components/CicekSepetiOzellikEditor.vue";
import NDropDownTreeView from "@/components/DropDownTreeView/NDropDownTreeView.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import PazaryeriFiyatItem from "@/modules/Pazaryerleri/components/PazaryeriFiyatItem.vue";
import {useCicekSepetiKategoriService} from "@/api/services/CicekSepetiKategoriService";
import {CicekSepetiPazaryeriItem} from "@/api/models/CicekSepetiPazaryeriItem";
import {CicekSepetiSubCategory} from "@/api/models/CicekSepetiSubCategory";
import {StokView} from "@/api/models/StokView";
import {CicekSepetiDeliveryTypeList} from "@/api/models/CicekSepetiDeliveryType";
import {CicekSepetiDeliveryMessageTypeList} from "@/api/models/CicekSepetiDeliveryMessageType";
import {IdName} from "@/api/models/IdName";
import NRefresher from "@/components/Refresher/NRefresher.vue";
import NInputHtml from "@/components/Input/NInputHtml.vue";

export default defineComponent({
  components: { NInputHtml, NRefresher, PazaryeriFiyatItem, NFormGrid, NDropDownTreeView, CicekSepetiOzellikEditor },
  props: [
    'cicekSepeti',
    'stok',
    'alisKdv',
    'alisKdvDahil',
    'satisKdv',
    'satisKdvDahil',
    'alisFiyat',
    'satisFiyat'
  ],
  setup(props,{emit}) {

    const localState = reactive({
      
        cicekSepetiRef:computed(() => props.cicekSepeti as CicekSepetiPazaryeriItem),
        stokRef:computed(()=> props.stok as StokView),
        kategoriler:ref([] as CicekSepetiSubCategory[]),

      alisKdvRef:computed({get:()=>props.alisKdv, set:(val)=> emit('update:alisKdv',val) }),
      alisKdvDahilRef:computed({get:()=>props.alisKdvDahil, set:(val)=> emit('update:alisKdvDahil',val) }),
      satisKdvRef:computed({get:()=>props.satisKdv, set:(val)=> emit('update:satisKdv',val) }),
      satisKdvDahilRef:computed({get:()=>props.satisKdvDahil, set:(val)=> emit('update:satisKdvDahil',val) }),
      alisFiyatRef:computed({get:()=>props.alisFiyat, set:(val)=> emit('update:alisFiyat',val) }),
      satisFiyatRef:computed({get:()=>props.satisFiyat, set:(val)=> emit('update:satisFiyat',val) }),
      deliveryTypeList:ref(CicekSepetiDeliveryTypeList),
      deliveryMessageTypeList:ref(CicekSepetiDeliveryMessageTypeList)
    });

    async function load(){
      const categories = await useCicekSepetiKategoriService().getGetAll(360);
      localState.kategoriler = categories;
    }

    function selectedChangedKategori(data:CicekSepetiSubCategory) {
      localState.cicekSepetiRef.kategori = {id:data.id?.toString(), name:data.name as string} as IdName
    }

    load()
    return {
      ...toRefs(localState),
      load,
      selectedChangedKategori
    };
  }
});

