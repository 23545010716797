import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3dff0d1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NLoading = _resolveComponent("NLoading")!

  return (_openBlock(), _createElementBlock("div", _mergeProps(_ctx.$attrs, {
    ref: "btn",
    class: ["n-btn", [
      _ctx.type ? `n-btn--${_ctx.type}` : 'n-btn--neutral',
      _ctx.size && `n-btn--${_ctx.size}`,
      _ctx.block && 'n-btn--block',
      _ctx.text && 'n-btn--text'
    ]],
    style: [!_ctx.$slots.default && { padding: '9px 9px' }],
    disabled: _ctx.disabled || _ctx.isLoadingRef || _ctx.isLoadingProp,
    type: _ctx.isSubmit ? 'submit' : 'button'
  }), [
    (_ctx.icon && !_ctx.iconAfter)
      ? (_openBlock(), _createBlock(_component_NIcon, {
          key: 0,
          class: _normalizeClass(_ctx.$slots.default && 'n-icon--before'),
          size: _ctx.iconSize ?? _ctx.calculatedIconSize,
          type: _ctx.icon,
          style: _normalizeStyle({
        visibility: _ctx.loading ? 'hidden' : 'visible'
      })
        }, null, 8, ["class", "size", "type", "style"]))
      : _createCommentVNode("", true),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          style: _normalizeStyle({ visibility: _ctx.loading ? 'hidden' : 'visible' }),
          class: "n-btn-text"
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 4))
      : _createCommentVNode("", true),
    (_ctx.icon && _ctx.iconAfter)
      ? (_openBlock(), _createBlock(_component_NIcon, {
          key: 2,
          class: _normalizeClass(_ctx.$slots.default && 'n-icon--after'),
          size: _ctx.iconSize,
          type: _ctx.icon,
          style: _normalizeStyle({
        visibility: _ctx.loading || _ctx.isLoadingProp ? 'hidden' : 'visible'
      })
        }, null, 8, ["class", "size", "type", "style"]))
      : _createCommentVNode("", true),
    (_ctx.loading || _ctx.isLoadingProp)
      ? (_openBlock(), _createBlock(_component_NLoading, {
          key: 3,
          color: "#fff",
          radius: 10,
          style: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        marginLeft: '-10px',
        marginTop: '-10px'
      },
          stroke: 3
        }))
      : _createCommentVNode("", true),
    (_ctx.isLoadingRef || _ctx.isLoadingProp)
      ? (_openBlock(), _createBlock(_component_NLoading, {
          key: 4,
          stroke: 3,
          radius: 8,
          style: {"position":"absolute","top":"6px","left":"calc((100% /2))","display":"inline-block","margin-left":"-10px","z-index":"99999"}
        }))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      type: "file",
      class: "file-upload",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args)))
    }, null, 32)
  ], 16, _hoisted_1))
}