import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {IdName} from '../models/IdName'
import {EFaturaFaturaSatir} from '../models/EFaturaFaturaSatir'
import {EFaturaSenaryoTip} from '../models/EFaturaSenaryoTip'
import {EFaturaTip} from '../models/EFaturaTip'
import {EFaturaFaturaView} from '../models/EFaturaFaturaView'
import {RecordStatus} from '../models/RecordStatus'
import {FaturaHareketTip} from '../models/FaturaHareketTip'
import {FaturaTip} from '../models/FaturaTip'
import {FaturaUstBilgiItem} from '../models/FaturaUstBilgiItem'
import {TeslimatAdresItem} from '../models/TeslimatAdresItem'
import {FaturaSatirItem} from '../models/FaturaSatirItem'
import {FaturaAltBilgiItem} from '../models/FaturaAltBilgiItem'
import {DocumentItem} from '../models/DocumentItem'
import {PlanVadelerItem} from '../models/PlanVadelerItem'
import {FaturaEFaturaItem} from '../models/FaturaEFaturaItem'
import {SmsMailKargoGonderItem} from '../models/SmsMailKargoGonderItem'
import {FaturaView} from '../models/FaturaView'

export const useIziGelenFaturaService = defineStore({
id: 'IziGelenFaturaService',
actions: {


	async getGetAllAsync()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenFatura/GetAllAsync/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetInvoiceType(uuid:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenFatura/GetInvoiceType/${shared.firm.id}/${uuid}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetAllAsyncNotMatched(faturaId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenFatura/GetAllAsyncNotMatched/${shared.firm.id}/${shared.firm.period}/${faturaId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetInvoiceDetailsAsync(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenFatura/GetInvoiceDetailsAsync/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postGenerateFaturaView(eFaturaFaturaView:EFaturaFaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenFatura/GenerateFaturaView/${shared.firm.id}`,{ method:'POST', data:eFaturaFaturaView });
		await request();
		return response.value;
	},

	async getGetCariByVergiNo(vergiNo:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenFatura/GetCariByVergiNo/${shared.firm.id}/${vergiNo}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postFaturaEkle(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenFatura/FaturaEkle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async deleteFaturaSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenFatura/FaturaSil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async putEFaturaToFaturaLink(faturaId:string,eFaturaId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenFatura/EFaturaToFaturaLink/${shared.firm.id}/${shared.firm.period}/${faturaId}/${eFaturaId}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async putUpdateOkundu(eFaturaId:string,isOkundu:boolean)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenFatura/UpdateOkundu/${shared.firm.id}/${eFaturaId}/${isOkundu}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async putRemoveMatch(faturaId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/IziGelenFatura/RemoveMatch/${shared.firm.id}/${faturaId}`,{ method:'PUT' });
		await request();
		return response.value;
	}
}
});
