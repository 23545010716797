import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {FirmaCekSenetItemView} from '../models/FirmaCekSenetItemView'
import {DocumentItem} from '../models/DocumentItem'
import {FirmaCekSenetView} from '../models/FirmaCekSenetView'

export const useFirmaCekSenetService = defineStore({
id: 'FirmaCekSenetService',
actions: {


	async getFlatListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FirmaCekSenet/FlatListe/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getFlatId(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FirmaCekSenet/FlatId/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FirmaCekSenet/View/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postEkle(firmaCekSenetView:FirmaCekSenetView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FirmaCekSenet/Ekle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:firmaCekSenetView });
		await request();
		return response.value;
	},

	async putGuncelle(firmaCekSenetView:FirmaCekSenetView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FirmaCekSenet/Guncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:firmaCekSenetView });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FirmaCekSenet/Sil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postIadeGeriAl(liste:string[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FirmaCekSenet/IadeGeriAl/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data: liste });
		await request();
		return response.value;
	},

	async postIadeEt(liste:string[],tarih:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FirmaCekSenet/IadeEt/${tarih}/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:liste });
		await request();
		return response.value;
	},

	async postBankayaOdeme(liste:string[],tarih:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FirmaCekSenet/BankayaOdeme/${tarih}/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:liste });
		await request();
		return response.value;
	},

	async postBankadanGeriCekme(liste:string[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FirmaCekSenet/BankadanGeriCekme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data: liste });
		await request();
		return response.value;
	},

	async postKasayaOdeme(liste:string[],tarih:string,kasaId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FirmaCekSenet/KasayaOdeme/${tarih}/${kasaId}/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:liste });
		await request();
		return response.value;
	},

	async postKasadanGeriCekme(liste:string[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FirmaCekSenet/KasadanGeriCekme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data: liste });
		await request();
		return response.value;
	},

	async getRaporHareket(baslangic:string,bitis:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FirmaCekSenet/RaporHareket/${shared.firm.id}/${shared.firm.period}/${baslangic}/${bitis}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getFirmaCekSenetRapor(baslangic:string,bitis:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FirmaCekSenet/FirmaCekSenetRapor/${shared.firm.id}/${shared.firm.period}/${baslangic}/${bitis}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
