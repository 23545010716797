import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NFormField = _resolveComponent("NFormField")!

  return (_ctx.devirler && _ctx.devirler.length > 0 && _ctx.countStokHareket === 0)
    ? (_openBlock(), _createBlock(_component_NFormField, {
        key: 0,
        label: "Devir Miktarı",
        "grid-cols": "90px 90px 200px  1fr",
        span: "3",
        style: {"margin-bottom":"8px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DxNumberBox, {
            value: _ctx.devirler[_ctx.index].devir,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.devirler[_ctx.index].devir) = $event)),
            onValueChanged: _ctx.valueChanged
          }, null, 8, ["value", "onValueChanged"]),
          _createVNode(_component_DxSelectBox, {
            value: _ctx.selectedId,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedId) = $event)),
            valueExpr: "id",
            displayExpr: "depo.name",
            items: _ctx.stokRef.devirler
          }, null, 8, ["value", "items"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}