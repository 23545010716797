import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_NDropdownMenu = _resolveComponent("NDropdownMenu")!
  const _component_NDropdown = _resolveComponent("NDropdown")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createVNode(_component_NDropdown, { placement: _ctx.menuAlign }, {
      menu: _withCtx(() => [
        _createVNode(_component_NDropdownMenu, null, {
          default: _withCtx(() => [
            _createVNode(_component_NDropdownItem, {
              icon: "save",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.devExtremeUtils.saveStete(_ctx.dataGrid?.instance)))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Düzeni Kaydet ")
              ]),
              _: 1
            }),
            _createVNode(_component_NDropdownItem, {
              icon: "filter",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.devExtremeUtils.saveStete(_ctx.dataGrid?.instance , true)))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Filtre & Düzeni Kaydet ")
              ]),
              _: 1
            }),
            _createVNode(_component_NDropdownItem, {
              icon: "x-circle",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.devExtremeUtils.clearFilter(_ctx.dataGrid?.instance)))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Filtreleri Sıfırla ")
              ]),
              _: 1
            }),
            _createVNode(_component_NDropdownItem, {
              icon: "refresh-ccw",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.devExtremeUtils.resetState(_ctx.dataGrid?.instance)))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Düzeni Sıfırla ")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_NButton, {
          size: "small",
          style: {"color":"black","padding":"4px"},
          type: "white",
          icon: "grid",
          "icon-after": ""
        })
      ]),
      _: 1
    }, 8, ["placement"]), [
      [_vShow, _ctx.dataGrid?.instance]
    ])
  ]))
}