
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import { IdName } from "@/api/models/IdName";
import NDropDownListBox from "@/components/Dropdown/NDropDownListBox.vue";
import { useTrendyolDegerlerService } from "@/api/services/TrendyolDegerlerService";
import { TrendyolValuesEntity } from "@/api/models/TrendyolValuesEntity";
import { TrendyolValuesView } from "@/api/models/TrendyolValuesView";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import {useTrendyolWebService} from "@/api/services/TrendyolWebService";

export default defineComponent({
  components: { NLoadingPanel  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
      barcodes:{
        type:Array,
          default:()=>[] as string[]
      }
  },
  setup(props, { emit }) {
    const localState = reactive({
      model: ref([] as any[]),
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val)
      }),
      subTitle: ref(props.barcodes?.join(',')),
      title: ref("Trendyol Diğer Satıcı Fiyatları"),
      products:ref([] as any[])
    });

    async function load() {
      localState.products = await useTrendyolWebService().postGetProductsFromBarkods(props.barcodes as string[])
    }



    return {
      ...toRefs(localState),
      load
    };
  }
});
