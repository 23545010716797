import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "dx-card" }
const _hoisted_2 = { style: {"margin":"-7px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_DxGroupingPanel = _resolveComponent("DxGroupingPanel")!
  const _component_DxStateStoring = _resolveComponent("DxStateStoring")!
  const _component_DxExport = _resolveComponent("DxExport")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_NDropDownListBox = _resolveComponent("NDropDownListBox")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    modelValue: _ctx.showRef,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showRef) = $event)),
    width: "800px",
    "closable-mask": false
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
      _createVNode(_component_NTag, {
        type: "info",
        filled: true
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.subTitle), 1)
        ]),
        _: 1
      })
    ]),
    body: _withCtx(() => [
      _createVNode(_component_NLoadingPanel, {
        title: "Trendyol eşleştirme tablosu yükleniyor",
        action: _ctx.load
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.model && _ctx.model.length > 0)
              ? (_openBlock(), _createBlock(_component_DxDataGrid, {
                  key: 0,
                  "data-source": _ctx.model,
                  "key-expr": "id"
                }, {
                  trendyolOzellik: _withCtx(({data}) => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_NDropDownListBox, {
                        "accept-custom-value": false,
                        "selected-value": data.data.valueId,
                        "onUpdate:selectedValue": ($event: any) => ((data.data.valueId) = $event),
                        items: _ctx.trendyolValues
                      }, null, 8, ["selected-value", "onUpdate:selectedValue", "items"])
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_DxGroupingPanel, { visible: false }),
                    _createVNode(_component_DxStateStoring, {
                      enabled: true,
                      storageKey: "TrendyolEslestirmeDialogDataGrid"
                    }),
                    _createVNode(_component_DxExport, { enabled: false }),
                    _createVNode(_component_DxColumn, {
                      caption: 'Nesilce ' + _ctx.propertyName,
                      "data-field": "value"
                    }, null, 8, ["caption"]),
                    _createVNode(_component_DxColumn, {
                      caption: 'Trendyol ' + _ctx.propertyName,
                      "data-field": "valueId",
                      "cell-template": "trendyolOzellik"
                    }, null, 8, ["caption"]),
                    _createVNode(_component_DxColumn, {
                      caption: "Özellik Adı",
                      "data-field": "property"
                    }),
                    _createVNode(_component_DxColumn, {
                      caption: "'Kategori Id",
                      "data-field": "categoryId"
                    })
                  ]),
                  _: 1
                }, 8, ["data-source"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["action"])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_NButton, {
        type: "warning",
        size: "small",
        icon: "x-circle",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showRef = false))
      }, {
        default: _withCtx(() => [
          _createTextVNode("İptal")
        ]),
        _: 1
      }),
      _createVNode(_component_NButton, {
        type: "success",
        size: "small",
        icon: "save",
        click: _ctx.save
      }, {
        default: _withCtx(() => [
          _createTextVNode("Kaydet")
        ]),
        _: 1
      }, 8, ["click"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}