import { renderSlot as _renderSlot, mergeProps as _mergeProps, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e3fe92f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _mergeProps(_ctx.$attrs, {
      class: "grid-content",
      style: {
        'grid-template-columns': _ctx.cols,
        'grid-template-rows': _ctx.rows,
        gap: _ctx.gap
      }
    }), [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 16)
  ]))
}