import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxMediaResizing = _resolveComponent("DxMediaResizing")!
  const _component_DxToolbar = _resolveComponent("DxToolbar")!
  const _component_DxHtmlEditor = _resolveComponent("DxHtmlEditor")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    title: "Html Düzenleme Formu",
    modelValue: _ctx.showDialogRef,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showDialogRef) = $event)),
    width: "900px"
  }, {
    body: _withCtx(() => [
      _createVNode(_component_DxHtmlEditor, {
        value: _ctx.content,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.content) = $event)),
        ref: "editor",
        height: "725px",
        tableContextMenu: { enabled: true },
        tableResizing: { enabled: true }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DxMediaResizing, { enabled: true }),
          _createVNode(_component_DxToolbar, {
            multiline: true,
            items: _ctx.items
          }, null, 8, ["items"])
        ]),
        _: 1
      }, 8, ["value"])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_NButton, {
        type: "warning",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showDialogRef=false))
      }, {
        default: _withCtx(() => [
          _createTextVNode("İptal")
        ]),
        _: 1
      }),
      _createVNode(_component_NButton, {
        type: "success",
        click: _ctx.save
      }, {
        default: _withCtx(() => [
          _createTextVNode("Kaydet")
        ]),
        _: 1
      }, 8, ["click"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}