import { useAccountService } from "@/api/services/AccountService"
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  useRouter
} from "vue-router"
import { useAccountServiceExtend } from "@/api/extends/AccountServiceExtend"
import { useSharedStore } from "@/store"
const router = useRouter()

const authGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const account = useAccountService()
  const shared = useSharedStore()

  let storedAuth = null

  if (shared.loginMethod === "session") {
    storedAuth = sessionStorage.getItem(shared.user.userName ?? "User")
  }
  if (shared.loginMethod === "local") {
    storedAuth = localStorage.getItem("User")
  }

  if (to.name === "Login" && (storedAuth || account.isAuthenticated)) {
    if (account.isAuthenticated) {
      await useAccountServiceExtend().postLogout()
      await router.push({
        name: "Login"
      })
    }
    return next(true)
  }
  if (!to.meta.authRequired) return next()

  if (account.isAuthenticated) {
    return next()
  } else if (storedAuth) {
    account.$patch(JSON.parse(storedAuth))
    return next()
  } else {

    if (to.name == "HizliSatisLayout"){
      return next({ name: "HizliSatisLogin" })
    }else if(to.name == "UretimTalimatMobile")
      return next({name: "UretimTalimatLogin"})
    return next({ name: "Login" })
  }
}

export default authGuard
