
export enum KrediKartiHareketTip{
KrediKartiIleKasayaOdeme=100,
KrediKartiIleCariyeOdeme=110,
KrediKartiIleMasrafOdeme=120,
KrediKartiCikanDevir=130,
KrediKartiIleBankayaOdeme=140,
FirmaKkKasadanParaCekme=1100,
FirmaKkBankadanParaCekme=1140
}

export const KrediKartiHareketTipList = [
{key:'KrediKartiIleKasayaOdeme', value:100, description:'Kredi Kartı İle Kasaya Ödeme '},
{key:'KrediKartiIleCariyeOdeme', value:110, description:'Kredi Kartı İle Cariye Ödeme '},
{key:'KrediKartiIleMasrafOdeme', value:120, description:'Kredi Kartı İle Masraf Ödeme '},
{key:'KrediKartiCikanDevir', value:130, description:'Kredi Kartı Çıkan Devir '},
{key:'KrediKartiIleBankayaOdeme', value:140, description:'Kredi Kartıyla Bankaya Ödeme'},
{key:'FirmaKkKasadanParaCekme', value:1100, description:'Firma Kredi Kartı İle Kasadan Para Çekme'},
{key:'FirmaKkBankadanParaCekme', value:1140, description:'Firma Kredi Kartına Bankadan Para Çekme'}
]
