
import {defineComponent, reactive, ref, toRefs} from "vue";
import NGrid from "@/components/Grid/NGrid.vue";
import HizliSatisTabs from "@/modules/HizliSatis/components/HizliSatisTabs.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NButton from "@/components/Button/NButton.vue";
import SubeSelectBox from "@/modules/Sube/components/SubeSelectBox.vue";
import CariSelectBox from "@/modules/Cari/components/CariSelectBox.vue";
import {useHizliSatisTabsStore} from "@/modules/HizliSatis/store/HizliSatisTabsStore";
import DepoSelectBox from "@/modules/Ortak/components/DepoSelectBox.vue";
import PlasiyerSelectBox from "@/modules/Plasiyer/components/PlasiyerSelectBox.vue";
import PerakendeConfigDialogs from "@/modules/Perakende/dialogs/PerakendeConfigDialogs.vue";

export default defineComponent({
  components: {
    PerakendeConfigDialogs,
    PlasiyerSelectBox, DepoSelectBox, CariSelectBox, NButton, HizliSatisTabs, NGrid},
  setup() {
    const localState = reactive({
      store:useHizliSatisTabsStore(),
      showAyarDialog:ref()
    })

      function perakendeSaved(data:any) {
          localState.store.varsayilanCariId = data.cariId;
          localState.store.selectedTab.model.cariId = data.cariId;

      }



    return {
      ...toRefs(localState),
        perakendeSaved
    }
  }
})
