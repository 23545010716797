
import {defineComponent, reactive, ref, toRefs, watch} from "vue";
import NViewGrid from "@/components/ViewGrid/NViewGrid.vue";
import NViewToolbar from "@/components/Toolbar/NToolbar.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NCard from "@/components/Card/NCard.vue";
import StokForm_Pazaryerleri from "@/modules/Stok/components/StokForm_Pazaryerleri.vue";
import { StokView } from "@/api/models/StokView";
import { useStokService } from "@/api/services/StokService";
import { useSharedStore } from "@/store";
import { useFileSettingsService } from "@/api/services/FileSettingsService";
import StokGroup_GenelBilgiler from "@/modules/StokGroup/components/StokGroup_GenelBilgiler.vue";
import { useStokGrupService } from "@/api/services/StokGrupService";
import { usePopupService } from "@/services/popupService";
import StokGroupStoklar from "@/modules/StokGroup/components/StokGroupStoklar.vue";
import { StokGrupView } from "@/api/models/StokGrupView";
import { useAccountService } from "@/api/services/AccountService";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import { useAccountServiceExtend } from "@/api/extends/AccountServiceExtend";
import { StokFlatView } from "@/api/models/StokFlatView";
import _ from "lodash";
import { StokGrupItem } from "@/api/models/StokGrupItem";
import { useLayoutStore } from "@/layouts/ApplicationLayout/context";
import { confirm } from "devextreme/ui/dialog";
import {useFileSettingsServiceCache} from "@/api/servicesCache/FileSettingsServiceCache";
import OzellikForm from "@/modules/StokOzellik/components/OzellikForm.vue";
import {useStokStore} from "@/modules/Stok/store/StokStore";
import {StokUtils} from "@/modules/Stok/utils/StokUtils";

export default defineComponent({
  components: {
    OzellikForm,
    NLoadingPanel,
    NCard,
    StokGroupStoklar,
    StokGroup_GenelBilgiler,
    StokForm_Pazaryerleri,
    NGrid,
    NViewToolbar,
    NViewGrid

  },
  props: {

  },
  setup(props) {
    const localState = reactive({
      stok:ref({} as StokGrupView),
      serverUrl:ref(),
      tabKey:ref(),
      selectedTab:ref(0),
      genelTabs:ref([
        {label: 'Genel Bilgiler', id: 0},
        // {label: 'Açıklamalar', id: 1},
        {label: 'Özellikler', id: 2},
        // {label: 'Uyumluluklar', id: 3},
        // {label: 'Birimler',id: 4},
        // {label: 'Üreticiler',id: 5},
        // {label: 'Devirler',id :6}
      ]),
    });

    localState.tabKey = useLayoutStore().selectedTab?.key;

    async function load(){

      const stokId:string = StokUtils.stokId as string;

      localState.serverUrl = await useFileSettingsServiceCache().getServerUrl();

      if (stokId && stokId.length > 0) {
        localState.stok = await useStokGrupService().getGrupluStokDefaultId(stokId);
        StokUtils.stokId = "";
      }
      else {
        localState.stok = await useStokGrupService().getGrupluStokDefault();
        localState.stok.barkod = getBarkod();
      }
    }

    async function save(){
      if (localState.stok.id && localState.stok.id.length > 0) {
        await useStokGrupService().putGrupluStokGuncelle(localState.stok);
        showMessage("Gruplu stok güncellendi.");
        close()
      }else{
        await useStokGrupService().postGrupluStokEkle(localState.stok)
        showMessage("Gruplu stok eklendi.");
      }
      localState.stok = await useStokGrupService().getGrupluStokDefault();
    }

    function getBarkod(){
      return ( new Date().getTime() + Math.floor(Math.random() * 10000)).toString();
    }
    async function remove(){
      if (localState.stok && localState.stok.id && localState.stok.id.length > 0){
        if (await confirm('Gruplu stoğu silmek istiyor musunuz?','UYARI!')){
          await useStokGrupService().deleteGrupluStokSil(localState.stok.id as string);
          showMessage('Gruplu stok silindi.');
          close()
        }
      }
    }

    function close(){
      StokUtils.closeStokForm(localState.tabKey);
    }

    function showMessage(message: string){
      usePopupService().pushSuccessNotification({
        body:message
      })
    }
    function getSatisFiyati(){
      if (localState.stok?.stoklar && localState.stok.stoklar.length > 0)
        return _.sum(localState.stok.stoklar.map((a:StokGrupItem)=> a.fiyat! * a.miktar!))
      return  0
    }

    function isUpdateMode(){
      return localState.stok && localState.stok.id && localState.stok.id.length > 1;
    }


    return {
      ...toRefs(localState),
      save,
      load,
      getSatisFiyati,
      remove,
      isUpdateMode
    };
  }
});
