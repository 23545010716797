import {HizliSatisUtils} from "@/modules/HizliSatis/utils/HizliSatisUtils";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import {PerakendeView} from "@/api/models/PerakendeView";
import HizliSatisPage from "@/modules/HizliSatis/pages/HizliSatisPage.vue";
import {HizliSatisOdemeTip} from "@/modules/HizliSatis/model/HizliSatisOdemeTip";


export default class HizliSatisTabModel {

   constructor() {
        this.fiyatGrupTutar = 0 ;
        this.fiyatGrupOran = 0 ;
        this.id = Date.now();
        this.component = HizliSatisPage;
        this.model = HizliSatisUtils.generateView();
        this.plasiyerId = "";
        this.depoId = "";
        this.fisYazdir = false;
        this.odemeTip = HizliSatisOdemeTip.Nakit;

        this.arrayStore = new ArrayStore({
            key:'id',
            data:this.model.satirlar
        });
        this.dataSource = new DataSource({
            key:'id',
            reshapeOnPush:true,
            store:this.arrayStore,
        })

        this.arrayStoreIade = new ArrayStore({
            key:'id',
            data:this.model.iadeler
        });
        this.dataSourceIade = new DataSource({
            key:'id',
            reshapeOnPush:true,
            store:this.arrayStoreIade,
        })
    }
    public id:number;
    public component:any;
    public model = {
        cariId : '',

    } as PerakendeView;
    public plasiyerId:string;
    public depoId:string;
    public fiyatGrupOran:number;
    public fiyatGrupTutar:number;
    public cariFiyatGrupId?:string;



    public fisYazdir:boolean;
    public dataSource:DataSource;
    public dataSourceIade:DataSource;
    public arrayStore:ArrayStore;
    public arrayStoreIade:ArrayStore;
    public odemeTip:HizliSatisOdemeTip
}
