import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!

  return (_openBlock(), _createBlock(_component_DxSelectBox, {
    "display-expr": "projeAdi",
    "value-expr": "id",
    items: _ctx.items,
    searchEnabled: true,
    value: _ctx.selected,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
    "show-clear-button": true,
    placeholder: "Proje Seçiniz"
  }, null, 8, ["items", "value"]))
}