
export enum Doviz{
TRY=0,
USD=1,
EUR=2
}

export const DovizList = [
{key:'TRY', value:0, description:'₺'},
{key:'USD', value:1, description:'$'},
{key:'EUR', value:2, description:'€'}
]
