import {FaturaSatirTip} from "@/api/models/FaturaSatirTip";
import {useFaturaService} from "@/api/services/FaturaService";
import FaturaViewExtend from "@/modules/Fatura/models/FaturaViewExtend";
import {FaturaHareketTip, FaturaHareketTipList} from "@/api/models/FaturaHareketTip";
import {useCariService} from "@/api/services/CariService";
import {CariEntity} from "@/api/models/CariEntity";
import {FaturaView} from "@/api/models/FaturaView";
import {GenelResmiMuhasebeTip, GenelResmiMuhasebeTipList} from "@/api/models/GenelResmiMuhasebeTip";
import {Doviz, DovizList} from "@/api/models/Doviz";
import {AdresTip} from "@/api/models/AdresTip";
import FaturaSatirItemExtend from "@/modules/Fatura/models/FaturaSatirItemExtend";
import {uuid} from "vue-uuid";
import {StokBirimItem} from "@/api/models/StokBirimItem";
import {StokFlatView} from "@/api/models/StokFlatView";
import {TeslimatAdresItem} from "@/api/models/TeslimatAdresItem";
import {HizmetMasrafView} from "@/api/models/HizmetMasrafView";
import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import {NumberUtils} from "@/utils/number/NumberUtils";
import {EFaturaSenaryoTipList} from "@/api/models/EFaturaSenaryoTip";
import {FaturaListView} from "@/api/models/FaturaListView";
import {EFaturaTipList} from "@/api/models/EFaturaTip";
import {KargoTip} from "@/api/models/KargoTip";
import {KargoTeslimatTip} from "@/api/models/KargoTeslimatTip";
import {KargoBoyutTip} from "@/api/models/KargoBoyutTip";
import {KargoTeslimTip} from "@/api/models/KargoTeslimTip";
import {KargoOdemeTip} from "@/api/models/KargoOdemeTip";
import {PlatformTip} from "@/api/models/PlatformTip";
import {KapidaOdemeTip} from "@/api/models/KapidaOdemeTip";
import {useUretimSevkService} from "@/api/services/UretimSevkService";
import {useEvrakNoService} from "@/api/services/EvrakNoService";
import {FaturaTip, FaturaTipList} from "@/api/models/FaturaTip";
import {useSiparisService} from "@/api/services/SiparisService";
import {RecordStatus} from "@/api/models/RecordStatus";
import {StokHareketEntity} from "@/api/models/StokHareketEntity";
import {FaturaDurumu, FaturaDurumuList} from "@/api/models/FaturaDurumu";
import {EFaturaDurumu, EFaturaDurumuList} from "@/api/models/EFaturaDurumu";
import {ProjeEntity} from "@/api/models/ProjeEntity";
import {usePersonelIsTakipService} from "@/api/services/PersonelIsTakipService";
import {useIziGelenFaturaService} from "@/api/services/IziGelenFaturaService";
import {StokFlatUtils} from "@/modules/Stok/utils/StokFlatUtils";
import {IdName} from "@/api/models/IdName";
import {UretimStokEntity} from "@/api/models/UretimStokEntity";
import {useUretimStokService} from "@/api/services/UretimStokService";
import {FaturaSatirItem} from "@/api/models/FaturaSatirItem";
import {StokTip} from "@/api/models/StokTip";
import {useStokService} from "@/api/services/StokService";
import {StokBirimUtils} from "@/modules/Stok/utils/StokBirimUtils";
import {StokBakiyeRequest} from "@/api/models/StokBakiyeRequest";
import {SiparisSatirFlatView} from "@/api/models/SiparisSatirFlatView";
import {types} from "sass";
import Null = types.Null;
import {useIziGelenIrsaliyeService} from "@/api/services/IziGelenIrsaliyeService";
import {EFaturaGeneralStatusType, EFaturaGeneralStatusTypeList} from "@/api/models/EFaturaGeneralStatusType";
import {useSharedStore} from "@/store";

export const FaturaUtil = {

    async saveFaturaForm(model: FaturaViewExtend, menu: MenuModel) {
        const user = useSharedStore().user;



        if (model.altBilgi) {
            NumberUtils.castZero(model.altBilgi, ["tutarIskonto"], 0);
            NumberUtils.castZero(model.altBilgi, ["cariIslemKur"], 1);
        }
        if (model.id && model.id.length > 0) {
            if (model.faturaTip == FaturaTip.Sac)
                await useUretimSevkService().putSacFaturaGuncelle(model);
            else if (user.eFaturaDuzeltme == true  )
                await useFaturaService().putEFaturaGuncelle(model);
            else
                await useFaturaService().putFaturaGuncelle(model);
        } else {
            switch (menu.hareketTipi) {
                case FaturaHareketTip.SatisKonsinye:
                    await useFaturaService().postSatisKonsinyeEkle(model as FaturaView);
                    break;
                case FaturaHareketTip.AlisIrsaliyeToFatura:
                    await useFaturaService().postIrsaliyeliAlisFaturaEkle(model as FaturaView);
                    break;
                case FaturaHareketTip.SatisIrsaliyeToFatura:
                    await useFaturaService().postIrsaliyeliSatisFaturaEkle(model as FaturaView);
                    break;
                case FaturaHareketTip.AlisFaturasi: {
                    if (model.faturaTip === FaturaTip.Siparis) {
                        await useSiparisService().postFaturaEkle(model as FaturaView, model.tip as FaturaHareketTip);
                    } else if (model.faturaTip === FaturaTip.Izibiz) {
                        await useIziGelenFaturaService().postFaturaEkle(model as FaturaView);
                    } else {
                        await useFaturaService().postAlisFaturaEkle(model as FaturaView);
                    }
                    break;


                }

                case FaturaHareketTip.AlisIrsaliye:
                    if (model.faturaTip == FaturaTip.Siparis) {
                        await useSiparisService().postFaturaEkle(model as FaturaView, FaturaHareketTip.AlisIrsaliye);
                    } else if (model.faturaTip == FaturaTip.Sac) {
                        await useUretimStokService().postAlisStokEkle(model as FaturaView);
                    } else if (model.faturaTip === FaturaTip.Izibiz) {
                        await useIziGelenIrsaliyeService().postFaturaEkle(model as FaturaView);
                    } else
                        await useFaturaService().postAlisIrsaliyeEkle(model as FaturaView);

                    break;
                case FaturaHareketTip.SatisIrsaliye:
                    if (model.faturaTip == FaturaTip.Sac) {
                        await useUretimSevkService().postSacFaturaEkle(model as FaturaView, FaturaHareketTip.SatisIrsaliye);
                    } else if (model.faturaTip == FaturaTip.Siparis) {
                        await useSiparisService().postFaturaEkle(model as FaturaView, FaturaHareketTip.SatisIrsaliye);
                    } else
                        await useFaturaService().postSatisIrsaliyeEkle(model as FaturaView);

                    break;
                case FaturaHareketTip.SatisFatura: {
                    if (model.faturaTip === FaturaTip.Siparis) {
                        await useSiparisService().postFaturaEkle(model as FaturaView, model.tip as FaturaHareketTip);
                    } else if (model.faturaTip == FaturaTip.Sac) {
                        await useUretimSevkService().postSacFaturaEkle(model as FaturaView, FaturaHareketTip.SatisFatura);
                    } else if (model.faturaTip == FaturaTip.IsTakip) {
                        await usePersonelIsTakipService().postFaturalandir(model as FaturaView);
                    } else {
                        await useFaturaService().postSatisFaturaEkle(model as FaturaView);
                    }
                    break;
                }
                case FaturaHareketTip.KurFarkiAlis:
                    await useFaturaService().postAlisKurFarki(model as FaturaView);
                    break;
                case FaturaHareketTip.KurFarkiSatis:
                    await useFaturaService().postSatisKurFarki(model as FaturaView);
                    break;
                case FaturaHareketTip.SatisIadeFaturasi: {
                    if (model.faturaTip === FaturaTip.Izibiz)
                        await useIziGelenFaturaService().postFaturaEkle(model as FaturaView);
                    else
                        await useFaturaService().postSatistanIade(model as FaturaView);

                }
                    break;
                case FaturaHareketTip.AlisIadeFaturasi:
                    await useFaturaService().postAlistanIade(model as FaturaView);
                    break;


            }
        }

    },
    generateSiparisToFatura(depoId: string, plasiyerId: string, data: SiparisSatirFlatView) {
        const satir = new FaturaSatirItemExtend();
        satir.tip = FaturaSatirTip.Stok;
        satir.fiyat = data.getNetFiyat
        satir.fiyatKdvli = data.getNetFiyatKdvLi;
        satir.kdv = data.kdv;
        satir.depoId = depoId;
        satir.plasiyerId = plasiyerId;
        satir.birim = data.birim;
        satir.kur = 1;
        satir.birimler = [{birim: data.birim, id: "0"}];
        satir.barkod = data.barkod;
        satir.carpan = 1;
        satir.ad = data.ad;
        satir.miktar = data.getTeslimKalan;
        satir.doviz = Doviz.TRY;
        satir.kod = data.kod;
        satir.iskontolar = [];
        satir.altIskontoOran = 0;
        satir.tevkifatOran = 0;
        satir.stokId = data.stokId;
        satir.vergiler = [];
        satir.katilimPayi = 0;
        satir.referansId = data.id
        satir.seriler = [];
        satir.satirIskontoTutar = 0;
        satir.bakiye = undefined;
        satir.kapAdet = 0 ;

        return satir;
    },
    generateFaturaHizmetSatirItem(hizmet: HizmetMasrafView) {
        const satir = new FaturaSatirItemExtend();
        satir.tip = FaturaSatirTip.HizmetMasraf;
        satir.fiyat = hizmet.aylikTutar;
        satir.fiyatKdvli = hizmet.aylikTutar;
        satir.kdv = hizmet.kdv;
        satir.birim = hizmet.birim;
        satir.kur = 1;
        satir.birimler = [{birim: hizmet.birim, id: "0"}];
        satir.barkod = hizmet.barkod;
        satir.carpan = 1;
        satir.ad = hizmet.ad;
        satir.miktar = 1;
        satir.doviz = Doviz.TRY;
        satir.kod = hizmet.kod;
        satir.iskontolar = [];
        satir.altIskontoOran = 0;
        satir.tevkifatOran = 0;
        satir.stokId = hizmet.id;
        satir.vergiler = [];
        satir.katilimPayi = 0;
        satir.seriler = [];
        satir.satirIskontoTutar = 0;
        satir.kapAdet = 0 ;
        return satir;
    },
    generateStokHareketToFaturaSatirItem(stok: StokHareketEntity) {

        const satir = new FaturaSatirItemExtend();
        satir.id = uuid.v4();
        satir.tip = FaturaSatirTip.Stok;
        satir.ad = (stok.stok?.name as string);
        satir.doviz = stok.doviz;
        satir.kur = stok.kur;
        satir.kdv = stok.kdv;
        satir.iskontolar = [];
        satir.miktar = stok.miktar;
        satir.stokId = stok.stok?.id;
        satir.itemId = stok.itemId;
        satir.birim = "Adet";
        satir.kod = stok.kod;
        satir.altIskontoOran = 0;
        satir.barkod = stok.barkod;
        satir.carpan = 1;
        satir.tevkifatOran = 0;
        satir.tevkifatKod = "";
        satir.tevkifatAd = "";
        satir.kdvIstisnasi = "";
        satir.plasiyerId = stok.plasiyerId;
        satir.vergiler = [];
        satir.fiyat = stok.fiyat;
        satir.fiyatKdvli = (stok.fiyat! * (1 + (stok.kdv! / 100)));
        satir.doviz = stok.doviz;
        satir.katilimPayi = 0;
        satir.seriler = [];
        satir.kapAdet = 0 ;
        return satir;
    },
    generateFaturaUretimStokItem(uretimStok: UretimStokEntity, depoId: string) {
        const satir = {} as FaturaSatirItem;
        satir.tevkifatOran = 0;
        satir.tip = FaturaSatirTip.Sac;
        satir.fiyat = 0;
        satir.kdv = 18;

        satir.carpan = 1;
        satir.kur = 1;
        satir.miktar = uretimStok.getIlkHareket?.tonaj;
        satir.itemId = "";
        satir.stokId = uretimStok.id;
        if (uretimStok.hareketler && uretimStok.hareketler.length > 0)
            satir.aciklama = uretimStok!.hareketler[0]!.aciklama;

        satir.ad = uretimStok.getStokAd;
        satir.birim = uretimStok.birim;
        satir.depoId = uretimStok.depoId;
        satir.iadeler = [];
        satir.vergiler = [];
        satir.komisyon = 0;
        satir.altIskontoOran = 0;
        satir.iskontolar = [];
        satir.doviz = Doviz.TRY;
        satir.katilimPayi = 0;
        satir.kapAdet = 0 ;

        const newSatir = new FaturaSatirItemExtend(satir);
        return newSatir;
    },
    getStokBirimGroupFiyat(cariGroup: string, stok: StokFlatView, satir: FaturaSatirItemExtend) {
        if (stok && stok.birimFiyatlari && stok.birimFiyatlari.length > 0) {
            const birimFiyat = stok.birimFiyatlari.find(a => a.grup == cariGroup && a.carpan == 1);
            if (birimFiyat) {
                const fiyat = StokBirimUtils.getFiyatStokBirimItem(birimFiyat, stok);


                satir.birim = birimFiyat.birim;
                satir.fiyatKdvli = fiyat;
                satir.fiyat = satir.fiyatKdvli! / (1 + (satir.kdv! / 100));


                // console.log("birim fiyat ", birimFiyat);
                // console.log("fiyat", fiyat);
                // console.log("satir", satir);


            }


        }

        return satir;
    },

    generateFaturaStokSatirItem(gelenStok: any, faturaHareketTip: FaturaHareketTip, plasiyerId: string) {
       // console.log("plasiyerID" , plasiyerId);
        // Birimler
         //console.log("gelen stok", gelenStok);
        const birimler: StokBirimItem[] = [];
        const stok = gelenStok as StokFlatView;

        const birim: StokBirimItem = {};
        birim.barkod =
            stok.barkodItem && stok.barkodItem.length > 0
                ? stok.barkodItem
                : stok.barkodStok;

        if (gelenStok.carpan && gelenStok.carpan > 0) {
            birim.carpan = gelenStok.carpan;
        } else
            birim.carpan = 1;


        birim.fiyat = stok.satisFiyat;
        birim.birim = stok.birim;
        birimler.push(birim);

        // if (stok.birimFiyatlari) {
        //     stok.birimFiyatlari.forEach(birim => {
        //         birimler.push({
        //             id: birim.id,
        //             birim: birim.birim,
        //             fiyat: birim.fiyat,
        //             barkod: birim.barkod,
        //             carpan: birim.carpan
        //         })
        //     })
        //
        // }

        //
        const satir = new FaturaSatirItemExtend();
        satir.id = uuid.v4();
        satir.seriler = [];
        satir.tip = FaturaSatirTip.Stok;
        if (stok.faturaAd && stok.faturaAd.length > 0 )
            satir.ad = stok.faturaAd ;
        else
            satir.ad = stok.ad as string;

        satir.doviz = stok.satisDoviz;
        satir.kur = 1;
        satir.kdv = stok.satisKdv;
        satir.iskontolar = [];
        if (gelenStok.gelenMiktar && gelenStok.gelenMiktar > 1) {
            //console.log("buraya girdi");
            stok.satisFiyat = gelenStok.satisFiyat;
            stok.alisFiyat = gelenStok.satisFiyat;
            stok.alisKdvDahil = gelenStok.alisKdvDahil;
            stok.satisKdvDahil = gelenStok.satisKdvDahil;
            satir.miktar = gelenStok.gelenMiktar;
        } else
            satir.miktar = 1;

        //console.log("1. işlem  " , stok );

        satir.stokId = stok.stokId;
        satir.itemId = stok.itemId;
        satir.birim = stok.birim;
        satir.kod = stok.kod;
        satir.altIskontoOran = 0;
        satir.birim = birim.birim;
        satir.barkod = birim.barkod;
        satir.carpan = birim.carpan;
        satir.birimler = birimler;
        satir.adet = stok.adet;
        satir.mensei = stok.mensei;
        satir.tevkifatOran = 0;
        satir.tevkifatKod = "";
        satir.tevkifatAd = "";
        satir.kdvIstisnasi = "";
        satir.plasiyerId = plasiyerId;
        satir.vergiler = [];
        if (faturaHareketTip == FaturaHareketTip.AlisFaturasi) {
            if (satir.carpan && satir.carpan > 1) {
                satir.fiyat = gelenStok.fiyat! / (1 + (stok.alisKdv! / 100));
                satir.fiyatKdvli = gelenStok.fiyat;
            } else if (stok.alisKdvDahil == true) {
                satir.fiyat = stok.alisFiyat! / (1 + (stok.alisKdv! / 100));
                satir.fiyatKdvli = stok.alisFiyat;
                satir.doviz = stok.alisDoviz;
            } else {
                satir.fiyatKdvli = stok.alisFiyat! * (1 + (stok.alisKdv! / 100));
                satir.fiyat = stok.alisFiyat;
                satir.doviz = stok.alisDoviz;
            }


        } else {
            if (satir.carpan && satir.carpan > 1) {
                satir.fiyat = gelenStok.fiyat! / (1 + (stok.satisKdv! / 100));
                satir.fiyatKdvli = gelenStok.fiyat;
            } else if (stok.satisKdvDahil == true) {
                //console.log("stok satış fiyat" , stok.satisFiyat)
                satir.fiyat = stok.satisFiyat! / (1 + (stok.satisKdv! / 100));
                satir.fiyatKdvli = stok.satisFiyat;
                satir.doviz = stok.satisDoviz;
            } else {
                satir.fiyatKdvli = stok.satisFiyat! * (1 + (stok.satisKdv! / 100));
                satir.fiyat = stok.satisFiyat;
                satir.doviz = stok.satisDoviz;
            }
        }
        satir.katilimPayi = 0;
        satir.siparisTarih = new Date().toISOString();

        satir.bakiye = StokFlatUtils.getKalanMiktar(gelenStok as StokFlatView, satir.depoId as string);
        satir.anaBirim = stok.birim;

        satir.guncelSatisFiyat = stok.satisFiyat;
        satir.guncelSatisDoviz = stok.satisDoviz;
        satir.guncelSatisKdv = stok.satisKdv;
        satir.guncelSatisKdvDahil = stok.satisKdvDahil;


        satir.yeniSatisFiyatKdvDahil = 0;
        satir.yeniSatisFiyat = 0;

        satir.kiloAdet = 1;
        satir.uzunluk = 0;
        if (stok.tip != StokTip.TicariMal) {
            satir.kiloAdet = stok.getAdetKilo;
            satir.adetKiloCarpan = stok.getAdetKilo;
            satir.uzunluk = stok.uzunluk;
            satir.kiloAdet = 1;
            satir.sacKgMetre = stok.getKgMetre;

            if (stok.uzunluk && stok.uzunluk > 1)
                satir.miktar = (1 * stok.uzunluk! * stok.getKgMetre!) / 1000;
            else
                satir.miktar = 1 * stok.getKgMetre!;
        }

        satir.satirIskontoTutar = 0;
        return satir;
    },
    async getFaturaLoadingStokBilgileri(satirlar: FaturaSatirItemExtend[]) {

        if (satirlar && satirlar.length > 0) {

            const stokBakiyeRequest: StokBakiyeRequest[] = [];
            satirlar.forEach((h) => {
                if (h.tip == FaturaSatirTip.Stok)
                    stokBakiyeRequest.push({
                        stokId: h.stokId,
                        depoId: h.depoId,
                        itemId: h.itemId,
                        miktar: 0
                    });
            });

            const stokBakiyeResponse = await useStokService().postStokBakiyesi(stokBakiyeRequest);

            let sayac = 0;
            if (stokBakiyeResponse != null) {
                satirlar.forEach((h: any) => {
                    if (h.tip == FaturaSatirTip.Stok) {
                        const bakiye = stokBakiyeResponse[sayac];
                        if (bakiye == null)
                            h.bakiye = 0;
                        else {
                            sayac++;
                            h.bakiye = bakiye.miktar;
                        }

                    } else {
                        h.bakiye = 0;
                        h.anaBirim = h.birim;
                    }

                    h.yeniSatisFiyat = 0;
                    h.yeniSatisFiyatKdvDahil = 0;
                });
            }
        }


    },
    getDoviz(doviz: number) {
        if (doviz) {
            const dovizIsmi = DovizList.find(a => a.value == doviz);
            if (dovizIsmi) return dovizIsmi.description;
        }
        return "₺";
    },

    getSatirTipName(tip: FaturaSatirTip) {
        switch (tip) {
            case FaturaSatirTip.Stok:
                return "S";
                break;
            case FaturaSatirTip.HizmetMasraf:
                return "H/M";
                break;
            case  FaturaSatirTip.Sac :
                return "SAC";
                break;
        }
        return "";
    },

    async generateUpdateFaturaView(faturaId: string) {
        const faturaView = await useFaturaService().getGetirView(faturaId);
        if (faturaView && faturaView.altBilgi) {
            if (!faturaView.altBilgi.iskontolar)
                faturaView.altBilgi.iskontolar = [];
            if (!faturaView.eFatura)
                faturaView.eFatura = {};
            if (!faturaView.teslimatAdresi)
                faturaView.teslimatAdresi = {} as TeslimatAdresItem;
            if (!faturaView.faturaAdresi)
                faturaView.faturaAdresi = {} as TeslimatAdresItem;

        }

        return new FaturaViewExtend(faturaView);
    },


    async generateInsertFaturaView(cariId: string, tip: FaturaHareketTip) {


        const evrakNo = await useEvrakNoService().getYeniEvrakNo(10);
        const cari = (await useCariService().getGetir(cariId)) as CariEntity;

        if (!cari) {
            throw "Cari bulunamadı. Id:" + cariId;
        }
        const fatura: FaturaView = {
            tip: tip,
            status: RecordStatus.active,
            ustBilgi: {
                genelResmiMuhasebeTip: GenelResmiMuhasebeTip.Genel,
                tarih: new Date().toISOString(),
                vade: new Date().toISOString(),
                cari: {id: cari.id, name: cari.getUnvan},
                aciklama: "",
                evrakNo: evrakNo,
                faturaSeri: "",
                projeId: "",
                referansId: "",
                irsaliyeler: [],
                siparisler: [],
                iadeFaturalari: [],
                subeId: "",
                sevkTarih: new Date().toISOString(),
                etkisizFis : false ,
            },
            altBilgi: {
                altIskontolar: [],
                tutarIskonto: 0,
                cariIslemDoviz: Doviz.TRY,
                cariIslemKur: 1,
                faturaKalan: 0,
                faturaMaliyeti: 0,
                havaleTutar: 0,
                kasaTutar: 0,
                posTutar: 0,
                mailOrderTutar: 0,
                mailOrderCari: {}
            },
            dokumanlar: [],
            satirlar: [],
            teslimatAdresi: {} as TeslimatAdresItem,
            vadeler: [],
            eFatura: {},
            isFatura: false,
            gonder: {
                kargoTip: KargoTip.Mng,
                kargoTeslimatTip: KargoTeslimatTip.StandartTeslimat,
                kargoBoyutTip: KargoBoyutTip.Paket,
                kargoTeslimTip: KargoTeslimTip.AdresTeslim,
                kargoOdemeTip: KargoOdemeTip.Gonderici,
                platformTip: PlatformTip.secilmedi,
                kapidaOdemeMi: false,
                kapidaOdemeTip: KapidaOdemeTip.Nakit,
                kargoGonder: false,
                desi: 3,
                kg: 3,
                eArsivMailGonder: false
            },
            satisFiyatGuncelle: false,
            faturaMaliyet: 0,
            ekMasraf: 0


        };
        if (cari.adresler && cari.adresler.length > 0) {
            const cariFat = cari.adresler.find(a => a.tipi == AdresTip.Fatura);
            const cariTes = cari.adresler.find(a => a.tipi == AdresTip.Teslimat);

            if (cariFat) {
                fatura.ustBilgi!.sehir = cariFat!.sehir;
                fatura.ustBilgi!.ilce = cariFat!.ilce;
                fatura.ustBilgi!.adres = cariFat!.adres;
                fatura.ustBilgi!.cep = cariFat!.cep;
                fatura.ustBilgi!.mail = cariFat!.mail;
                fatura.ustBilgi!.ulke = cariFat!.ulke;
                fatura.ustBilgi!.pKodu = cariFat!.pKodu;
                fatura.ustBilgi!.mahalle = cariFat!.mahalle;
                fatura.ustBilgi!.belde = cariFat!.belde;


            }

            if (cariTes) {
                fatura.teslimatAdresi = {
                    sehir: cariTes!.sehir,
                    adres: cariTes.adres,
                    cep: cariTes.cep,
                    ilce: cariTes.ilce,
                    mail: cariTes.mail,
                    pKodu: cariTes.pKodu,
                    belde: cariTes.belde,
                    ulke: cariTes.ulke
                };
            }

        }

        return new FaturaViewExtend(fatura);
    },

    getFaturaDurum(faturaState: FaturaDurumu) {
        if (faturaState == FaturaDurumu.NewRecord)
            return "warning";
        else if (faturaState == FaturaDurumu.InQueue)
            return "info";
        else if (faturaState == FaturaDurumu.Sending)
            return "primary";
        else if (faturaState == FaturaDurumu.Error)
            return "danger";
        else if (faturaState == FaturaDurumu.Sended)
            return "success";

    },
    geteFaturaDurum(efaturaState: EFaturaGeneralStatusType) {
        switch (efaturaState) {
            case EFaturaGeneralStatusType.Waiting:
            case EFaturaGeneralStatusType.Reporting:
                return "info"
            case EFaturaGeneralStatusType.Completed:
                return "success"
            case EFaturaGeneralStatusType.Error:
            case EFaturaGeneralStatusType.Rejected:
            case EFaturaGeneralStatusType.Cancelled:
                return "danger"
            case EFaturaGeneralStatusType.Warning:
            case EFaturaGeneralStatusType.InProgress:
                return "warning"
        }
        return "neutral"
    },

    geteFaturaDurumAciklama(efaturaState: EFaturaGeneralStatusType) {
        return EFaturaGeneralStatusTypeList.find(a => a.value == efaturaState)?.description
    },

    getIrsaliyeColumns(projeListesi: ProjeEntity[]) {

        const column = [

            {visible: true, width: 100, dataField: "evrakNo", caption: "EvrakNo"},
            {visible: true, width: 250, dataField: "cariUnvan", caption: "Cari"},

            {
                visible: true,
                width: 100,
                dataField: "tip",
                caption: "Tip",
                calculateCellValue: (item: FaturaListView) => FaturaHareketTipList.find(a => a.value == item.tip)?.description
            },

            {
                visible: true, width: 120, dataField: "eFatura.senaryo", caption: "Senorya",
                calculateCellValue: (item: FaturaListView) => EFaturaSenaryoTipList.find(a => a.value == item.eFatura?.senaryo)?.description
            },
            {
                visible: true, width: 60, dataField: "eFatura.eFaturaTip", caption: "E-İrsaliye Tip",
                calculateCellValue: (item: FaturaListView) => EFaturaTipList.find(a => a.value == item.eFatura?.eFaturaTip)?.description
            },
            {visible: true, width: 150, dataField: "eFatura.eFaturaNo", caption: "E-İrsaliye No"},
            {
                visible: true, width: 120, dataField: "eFatura.faturaDurumu", caption: "İrsaliye Durumu",
                calculateCellValue: (item: FaturaListView) => FaturaDurumuList.find(a => a.value == item.eFatura?.faturaDurumu)?.description,
                cellTemplate: "faturaDurumTemplate"
            },
            {
                visible: true, width: 100, dataField: "projeId", caption: "Proje"
                , calculateCellValue: (a: FaturaListView) => projeListesi?.find(b => b.id == a.projeId)?.projeAdi

            },
            {
                visible: true, width: 120, dataField: "eFatura.eFaturaDurumu", caption: "E-İrsaliye Durumu",
                calculateCellValue: (item: FaturaListView) => this.geteFaturaDurumAciklama(item.eFatura?.eFaturaStatus?.generalStatus as number),
                cellTemplate: "efaturaDurumTemplate"
            },

            {visible: true, width: 120, dataField: "faturalandirildi", caption: "İrsaliyelendirildi"},
            {visible: true, width: 50, dataField: "faturaSeriNo", caption: "Seri"},

            {visible: true, width: 100, dataField: "vade", caption: "Vade", dataType: "date"},
            {visible: true, width: 100, dataField: "tarih", caption: "Tarih", dataType: "date"},
            {visible: true, width: 200, dataField: "aciklama", caption: "Aciklama"},

            {visible: true, width: 100, dataField: "referansTarih", caption: "İrsaliyelenme Tarih", dataType: "date"},
            {visible: true, width: 100, dataField: "satirCount", caption: "Satırlar"},
            {visible: true, width: 100, dataField: "cariHesabi", caption: "Cari Hesabı"},
            {visible: true, width: 100, dataField: "cariIsi", caption: "Cari İşi"},
            {
                visible: true,
                width: 100,
                dataField: "genelResmiMuhasebeTip",
                caption: "Muhasebe",
                format: (d: number) => GenelResmiMuhasebeTipList.find(a => a.value == d)!.description
            }

        ];

        return column;
    },
    async getFaturaColumns(projeListesi: ProjeEntity[], plasiyerListesi: IdName[], subeList: IdName[]) {

        //console.log("şubeler", subeList)
        const column = [
            {
                visible: true,
                width: 38,
                dataField: "faturaTip",
                caption: "Tip",
                cellTemplate: "faturaTipTemplate",
                calculateCellValue: (item: FaturaListView) => FaturaTipList.find(a => a.value == item.faturaTip)?.description
            },
            {visible: true, width: 100, dataField: "evrakNo", caption: "EvrakNo"},
            {visible: true, width: 250, dataField: "cariUnvan", caption: "Cari"},
            {visible: true, width: 120, dataField: "faturaUnvan", caption: "Fatura Unvan"},
            {
                visible: true,
                width: 100,
                dataField: "tip",
                caption: "Tip",
                calculateCellValue: (item: FaturaListView) => FaturaHareketTipList.find(a => a.value == item.tip)?.description
            },

            {
                visible: true, width: 120, dataField: "eFatura.senaryo", caption: "Senaryo",
                calculateCellValue: (item: FaturaListView) => EFaturaSenaryoTipList.find(a => a.value == item.eFatura?.senaryo)?.description
            },
            {
                visible: true, width: 60, dataField: "eFatura.eFaturaTip", caption: "E-Fatura Tip",
                calculateCellValue: (item: FaturaListView) => EFaturaTipList.find(a => a.value == item.eFatura?.eFaturaTip)?.description
            },
            {visible: true, width: 150, dataField: "eFatura.eFaturaNo", caption: "E-Fatura No"},
            {
                visible: true, width: 140, dataField: "eFatura.faturaDurumu", caption: "Fatura Durumu",
                calculateCellValue: (item: FaturaListView) => FaturaDurumuList.find(a => a.value == item.eFatura?.faturaDurumu)?.description,
                cellTemplate: "faturaDurumTemplate"
            },
            {
                visible: true, width: 120, dataField: "eFatura.eFaturaDurumu", caption: "E-Fatura Durumu",
                calculateCellValue: (item: FaturaListView) => this.geteFaturaDurumAciklama(item.eFatura?.eFaturaStatus?.generalStatus as number),
                cellTemplate: "status"
            },


            {visible: true, width: 50, dataField: "faturaSeriNo", caption: "Seri"},
            {visible: true, width: 100, dataField: "vade", caption: "Vade", dataType: "date"},
            {visible: true, width: 100, dataField: "tarih", caption: "Tarih", dataType: "date", sortOrder: "desc"},
            {visible: true, width: 200, dataField: "aciklama", caption: "Aciklama"},
            {
                visible: true,
                width: 200,
                dataField: "plasiyerler",
                caption: "Plasiyerler",
                calculateCellValue: (a: FaturaListView) => {
                    return a.plasiyerler ? (a.plasiyerler?.map((x: any) => (plasiyerListesi.find((z: any) => z.id === x)?.name))?.join(", ")) : "";
                }

            },
            {
                visible: true,
                width: 100,
                dataField: "kur",
                caption: "Kur",
                dataType: "number",
                format: (a: number) => {
                    return a.format(2, 2);
                }
            },
            {visible: true, width: 100, dataField: "tutarIskonto", caption: "TutarIskonto"},
            {
                visible: true,
                width: 160,
                dataField: "getTryToplam",
                caption: "TRY Toplam",
                dataType: "number",
                format : "#,##0.00",
            },
            {
                visible: true,
                width: 160,
                dataField: "getUsdToplam",
                caption: "USD Toplam",
                dataType: "number",
                format : "#,##0.00",
            },
            {
                visible: true,
                width: 160,
                dataField: "getEurToplam",
                caption: "EUR Toplam",
                dataType: "number",
                format : "#,##0.00",
            },
            {
                visible: true,
                width: 160,
                dataField: "kdvTutar",
                caption: "Yerel KDV Tutar",
                dataType: "number",
                format : "#,##0.00",
            },
            {
                visible: true,
                width: 160,
                dataField: "getYerelKdvMatrah",
                caption: "Yerel KDV Matrah",
                dataType: "number",
                format : "#,##0.00",
            },
            {
                visible: true,
                width: 160,
                dataField: "kdvOran",
                caption: "Kdv Oran",
                dataType: "number",
                format: {
                    type: 'fixedPoint',
                    precision: 2
                }
            },
            {
                visible: true,
                width: 160,
                dataField: "tevkifatOran",
                caption: "Tevkifat Oran",
                dataType: "number",
                format: {
                    type: 'fixedPoint',
                    precision: 2
                }
            },
            {
                visible: true,
                width: 160,
                dataField: "getYerelToplam",
                caption: "Yerel KDV Dahil Tutar",
                dataType: "number",
                format : "#,##0.00",
            },




            {
                visible: true,
                width: 160,
                dataField: "tevkifatTutar",
                caption: "Tevkifat Tutar",
                dataType: "number",
                format : "#,##0.00",
            },
            {
                visible: true,
                width: 80,
                dataField: "ekMasraf",
                caption: "Ek Masraf",
                dataType: "number",
                format: (a: number) => {
                    return a.format(2, 2);
                }
            },
            {
                visible: true,
                width: 80,
                dataField: "faturaMaliyet",
                caption: "Fatura Maliyet",
                dataType: "number",
                format: (a: number) => {
                    return a.format(2, 2);
                }
            },

            {
                visible: true,
                width: 100,
                dataField: "faturaKalan",
                caption: "Kalan",
                dataType: "number", format: (a: number) => a.format(2, 2)
            },
            {
                visible: true,
                width: 100,
                dataField: "doviz",
                caption: "Döviz",
                format: (d: number) => DovizList.find(a => a.value == d)!.key
            },
            {visible: true, width: 100, dataField: "odemeSekli", caption: "Ödeme Şekli"},
            {
                visible: true, width: 100, dataField: "projeId", caption: "Proje"
                , calculateCellValue: (a: FaturaListView) => projeListesi?.find(b => b.id == a.projeId)?.projeAdi

            },
            {
                visible: true, width: 100, dataField: "subeId", caption: "Şube"
                , calculateCellValue: (a: FaturaListView) => subeList?.find(b => b.id == a.subeId)?.name
            },
            {
                visible: true,
                width: 100,
                dataField: "Fatura",
                caption: "Fatura",
                calculateCellValue: (item: FaturaListView) => {
                    if (item.referansId && item.referansId.length > 0)
                        return "Faturalandı";
                    else
                        return "";
                }
            },
            {visible: true, width: 100, dataField: "referansTarih", caption: "Faturalanma Tarih", dataType: "date"},
            {visible: true, width: 100, dataField: "satirCount", caption: "Satırlar"},
            {visible: true, width: 100, dataField: "cariHesabi", caption: "Cari Hesabı"},
            {visible: true, width: 100, dataField: "cariIsi", caption: "Cari İşi"},
            {
                visible: true,
                width: 100,
                dataField: "genelResmiMuhasebeTip",
                caption: "Muhasebe",
                format: (d: number) => GenelResmiMuhasebeTipList.find(a => a.value == d)!.description
            }

        ];

        return column;
    }


};
