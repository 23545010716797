
import { defineComponent, reactive, ref, toRefs } from "vue";
import StokMiktarOzet from "@/modules/Stok/components/StokMiktarOzet.vue";
import { StokFlatView } from "@/api/models/StokFlatView";
import { useStokStore } from "@/modules/Stok/store/StokStore";
import { FaturaSatirItem } from "@/api/models/FaturaSatirItem";
import { FaturaSatirTip } from "@/api/models/FaturaSatirTip";
import { PerakendeSatirItem } from "@/api/models/PerakendeSatirItem";
import StokHareketDialogList from "@/modules/Stok/dialogs/StokHareketDialogList.vue";
import {StokUtils} from "@/modules/Stok/utils/StokUtils";
import FaturaSatirItemExtend from "@/modules/Fatura/models/FaturaSatirItemExtend";

export default defineComponent({
  components: { StokHareketDialogList, StokMiktarOzet },
  props: {},
  setup(emit) {
    const localState = reactive({
      showMiktarOzet: ref(false),
      stokId: ref(""),
      itemId: ref(""),
      stokAdi : ref('') ,
      stokHareketShowDialog : ref(false),
    });

    function sagMenu(e: any) {

      if (e) {

        if (e.row == undefined)
          e.items = undefined;
        else if (e.row && e.row && e.row.data && e.row.data.tip == 1 )
          e.items = undefined;
        else{

          e.items = [
            { text: "Stok Formuna Git", icon: "product", onClick: () => stokOpenTab(e?.row?.data.stokId)},
            { text: "Stok Hareketleri", icon: "event"  , onClick: () => {
                const satir = e?.row?.data as PerakendeSatirItem;
                if (satir) {
                  localState.stokId = satir.stokId as string;
                  localState.itemId = satir.itemId as string;
                  localState.stokAdi = satir.ad as string;
                  localState.stokHareketShowDialog = true;
                }
              } },
            { text: "Stok Miktar Özetleri", icon: "overflow", onClick: () => openMiktarOzet(e) },

            { text: "Giriş", icon: "chevronnext", items: [] },
            { text: "Çıkış", icon: "chevronprev", items: [] }
          ];
        }
      }
    }

    function stokOpenTab(stokId: string) {
      if (stokId && stokId.length > 0)
        StokUtils.openStokForm(stokId, false);
    }


    function openMiktarOzet(e: any) {
      const stok: StokFlatView = e.row.data;
      if (stok) {
        localState.stokId = stok.stokId as string;
        localState.itemId = stok.itemId as string;
        localState.showMiktarOzet = true;
      }
    }

    return {
      ...toRefs(localState),
      sagMenu
    };
  }
});
