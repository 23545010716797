import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00f98b4b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StokFormBirimFiyat = _resolveComponent("StokFormBirimFiyat")!
  const _component_PazaryeriFiyatItem = _resolveComponent("PazaryeriFiyatItem")!
  const _component_N11StokForm = _resolveComponent("N11StokForm")!
  const _component_TrendyolStokForm = _resolveComponent("TrendyolStokForm")!
  const _component_HepsiBuradaListingForm = _resolveComponent("HepsiBuradaListingForm")!
  const _component_HepsiBuradaStokForm = _resolveComponent("HepsiBuradaStokForm")!
  const _component_NCard = _resolveComponent("NCard")!
  const _component_PrestaStokForm = _resolveComponent("PrestaStokForm")!
  const _component_CicekSepetiStokForm = _resolveComponent("CicekSepetiStokForm")!
  const _component_EpttAvmStokForm = _resolveComponent("EpttAvmStokForm")!
  const _component_PazaramaStokForm = _resolveComponent("PazaramaStokForm")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!

  return (_ctx.stok && _ctx.stok.n11 && _ctx.stok.trendyol && _ctx.stok.presta)
    ? (_openBlock(), _createBlock(_component_NFormGrid, _mergeProps({
        key: 0,
        tabs: _ctx.getTabs()
      }, _ctx.$attrs, {
        "selected-tab": _ctx.selectedTab,
        "onUpdate:selectedTab": _cache[165] || (_cache[165] = ($event: any) => ((_ctx.selectedTab) = $event)),
        cols: "1fr",
        rows: "1fr",
        scrollable: ""
      }), {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            (_ctx.selectedTab==0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  (_ctx.stokRef && _ctx.stokRef.birimFiyatlari && _ctx.stokRef.birimFiyatlari.length > 0)
                    ? (_openBlock(), _createBlock(_component_StokFormBirimFiyat, {
                        key: 0,
                        stok: _ctx.stokRef,
                        "onUpdate:stok": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.stokRef) = $event))
                      }, null, 8, ["stok"]))
                    : _createCommentVNode("", true),
                  (_ctx.stokRef.n11?.length > 0)
                    ? (_openBlock(), _createBlock(_component_PazaryeriFiyatItem, {
                        key: 1,
                        title: "N11 Fiyatı",
                        "show-indirim": false,
                        "alis-kdv": _ctx.alisKdvRef,
                        "onUpdate:alisKdv": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
                        "alis-kdv-dahil": _ctx.alisKdvDahilRef,
                        "onUpdate:alisKdvDahil": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
                        "satis-kdv": _ctx.satisKdvRef,
                        "onUpdate:satisKdv": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
                        "satis-kdv-dahil": _ctx.satisKdvDahilRef,
                        "onUpdate:satisKdvDahil": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
                        "alis-fiyati": _ctx.alisFiyatRef,
                        "onUpdate:alisFiyati": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
                        "satis-fiyati": _ctx.satisFiyatRef,
                        "onUpdate:satisFiyati": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.satisFiyatRef) = $event)),
                        "kargo-fiyat": _ctx.stokRef.n11[0].kargoTutar,
                        "onUpdate:kargoFiyat": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.stokRef.n11[0].kargoTutar) = $event)),
                        "komisyon-oran": _ctx.stokRef.n11[0].komisyon,
                        "onUpdate:komisyonOran": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.stokRef.n11[0].komisyon) = $event)),
                        "fiyat-tipi": _ctx.stokRef.n11[0].fiyat.tip,
                        "onUpdate:fiyatTipi": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.stokRef.n11[0].fiyat.tip) = $event)),
                        deger: _ctx.stokRef.n11[0].fiyat.deger,
                        "onUpdate:deger": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.stokRef.n11[0].fiyat.deger) = $event)),
                        "kar-marji": _ctx.stokRef.n11[0].karMarji,
                        "onUpdate:karMarji": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.stokRef.n11[0].karMarji) = $event)),
                        "indirim-tipi": _ctx.stokRef.n11[0].indirim.tip,
                        "onUpdate:indirimTipi": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.stokRef.n11[0].indirim.tip) = $event)),
                        indirim: _ctx.stokRef.n11[0].indirim.deger,
                        "onUpdate:indirim": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.stokRef.n11[0].indirim.deger) = $event)),
                        "alis-doviz": _ctx.stokRef.alisDoviz,
                        "onUpdate:alisDoviz": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.stokRef.alisDoviz) = $event)),
                        "satis-doviz": _ctx.stokRef.satisDoviz,
                        "onUpdate:satisDoviz": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.stokRef.satisDoviz) = $event)),
                        maliyet: _ctx.stokRef.getMaliyet,
                        "onUpdate:maliyet": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.stokRef.getMaliyet) = $event))
                      }, null, 8, ["alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyati", "satis-fiyati", "kargo-fiyat", "komisyon-oran", "fiyat-tipi", "deger", "kar-marji", "indirim-tipi", "indirim", "alis-doviz", "satis-doviz", "maliyet"]))
                    : _createCommentVNode("", true),
                  (_ctx.stokRef.trendyol?.length > 0)
                    ? (_openBlock(), _createBlock(_component_PazaryeriFiyatItem, {
                        key: 2,
                        title: "Trendyol Fiyatı",
                        "show-indirim": false,
                        "alis-kdv": _ctx.alisKdvRef,
                        "onUpdate:alisKdv": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
                        "alis-kdv-dahil": _ctx.alisKdvDahilRef,
                        "onUpdate:alisKdvDahil": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
                        "satis-kdv": _ctx.satisKdvRef,
                        "onUpdate:satisKdv": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
                        "satis-kdv-dahil": _ctx.satisKdvDahilRef,
                        "onUpdate:satisKdvDahil": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
                        "alis-fiyati": _ctx.alisFiyatRef,
                        "onUpdate:alisFiyati": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
                        "satis-fiyati": _ctx.satisFiyatRef,
                        "onUpdate:satisFiyati": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.satisFiyatRef) = $event)),
                        "kargo-fiyat": _ctx.stokRef.trendyol[0].kargoTutar,
                        "onUpdate:kargoFiyat": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.stokRef.trendyol[0].kargoTutar) = $event)),
                        "komisyon-oran": _ctx.stokRef.trendyol[0].komisyon,
                        "onUpdate:komisyonOran": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.stokRef.trendyol[0].komisyon) = $event)),
                        "fiyat-tipi": _ctx.stokRef.trendyol[0].fiyat.tip,
                        "onUpdate:fiyatTipi": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.stokRef.trendyol[0].fiyat.tip) = $event)),
                        deger: _ctx.stokRef.trendyol[0].fiyat.deger,
                        "onUpdate:deger": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.stokRef.trendyol[0].fiyat.deger) = $event)),
                        "kar-marji": _ctx.stokRef.trendyol[0].karMarji,
                        "onUpdate:karMarji": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.stokRef.trendyol[0].karMarji) = $event)),
                        "indirim-tipi": _ctx.stokRef.trendyol[0].indirim.tip,
                        "onUpdate:indirimTipi": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.stokRef.trendyol[0].indirim.tip) = $event)),
                        indirim: _ctx.stokRef.trendyol[0].indirim.deger,
                        "onUpdate:indirim": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.stokRef.trendyol[0].indirim.deger) = $event)),
                        "alis-doviz": _ctx.stokRef.alisDoviz,
                        "onUpdate:alisDoviz": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.stokRef.alisDoviz) = $event)),
                        "satis-doviz": _ctx.stokRef.satisDoviz,
                        "onUpdate:satisDoviz": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.stokRef.satisDoviz) = $event)),
                        maliyet: _ctx.stokRef.getMaliyet,
                        "onUpdate:maliyet": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.stokRef.getMaliyet) = $event))
                      }, null, 8, ["alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyati", "satis-fiyati", "kargo-fiyat", "komisyon-oran", "fiyat-tipi", "deger", "kar-marji", "indirim-tipi", "indirim", "alis-doviz", "satis-doviz", "maliyet"]))
                    : _createCommentVNode("", true),
                  (_ctx.stokRef.hepsiBuradaListing?.length > 0)
                    ? (_openBlock(), _createBlock(_component_PazaryeriFiyatItem, {
                        key: 3,
                        title: "HepsiBurada Fiyatı",
                        "show-indirim": false,
                        "alis-kdv": _ctx.alisKdvRef,
                        "onUpdate:alisKdv": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
                        "alis-kdv-dahil": _ctx.alisKdvDahilRef,
                        "onUpdate:alisKdvDahil": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
                        "satis-kdv": _ctx.satisKdvRef,
                        "onUpdate:satisKdv": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
                        "satis-kdv-dahil": _ctx.satisKdvDahilRef,
                        "onUpdate:satisKdvDahil": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
                        "alis-fiyati": _ctx.alisFiyatRef,
                        "onUpdate:alisFiyati": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
                        "satis-fiyati": _ctx.satisFiyatRef,
                        "onUpdate:satisFiyati": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.satisFiyatRef) = $event)),
                        "kargo-fiyat": _ctx.stokRef.hepsiBuradaListing[0].kargoTutar,
                        "onUpdate:kargoFiyat": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.stokRef.hepsiBuradaListing[0].kargoTutar) = $event)),
                        "komisyon-oran": _ctx.stokRef.hepsiBuradaListing[0].komisyon,
                        "onUpdate:komisyonOran": _cache[40] || (_cache[40] = ($event: any) => ((_ctx.stokRef.hepsiBuradaListing[0].komisyon) = $event)),
                        "fiyat-tipi": _ctx.stokRef.hepsiBuradaListing[0].fiyat.tip,
                        "onUpdate:fiyatTipi": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.stokRef.hepsiBuradaListing[0].fiyat.tip) = $event)),
                        deger: _ctx.stokRef.hepsiBuradaListing[0].fiyat.deger,
                        "onUpdate:deger": _cache[42] || (_cache[42] = ($event: any) => ((_ctx.stokRef.hepsiBuradaListing[0].fiyat.deger) = $event)),
                        "kar-marji": _ctx.stokRef.hepsiBuradaListing[0].karMarji,
                        "onUpdate:karMarji": _cache[43] || (_cache[43] = ($event: any) => ((_ctx.stokRef.hepsiBuradaListing[0].karMarji) = $event)),
                        "indirim-tipi": _ctx.stokRef.hepsiBuradaListing[0].indirim.tip,
                        "onUpdate:indirimTipi": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.stokRef.hepsiBuradaListing[0].indirim.tip) = $event)),
                        indirim: _ctx.stokRef.hepsiBuradaListing[0].indirim.deger,
                        "onUpdate:indirim": _cache[45] || (_cache[45] = ($event: any) => ((_ctx.stokRef.hepsiBuradaListing[0].indirim.deger) = $event)),
                        "alis-doviz": _ctx.stokRef.alisDoviz,
                        "onUpdate:alisDoviz": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.stokRef.alisDoviz) = $event)),
                        "satis-doviz": _ctx.stokRef.satisDoviz,
                        "onUpdate:satisDoviz": _cache[47] || (_cache[47] = ($event: any) => ((_ctx.stokRef.satisDoviz) = $event)),
                        maliyet: _ctx.stokRef.getMaliyet,
                        "onUpdate:maliyet": _cache[48] || (_cache[48] = ($event: any) => ((_ctx.stokRef.getMaliyet) = $event))
                      }, null, 8, ["alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyati", "satis-fiyati", "kargo-fiyat", "komisyon-oran", "fiyat-tipi", "deger", "kar-marji", "indirim-tipi", "indirim", "alis-doviz", "satis-doviz", "maliyet"]))
                    : _createCommentVNode("", true),
                  (_ctx.stokRef.presta?.length > 0)
                    ? (_openBlock(), _createBlock(_component_PazaryeriFiyatItem, {
                        key: 4,
                        title: "E-Ticaret Fiyatı",
                        "show-indirim": false,
                        "alis-kdv": _ctx.alisKdvRef,
                        "onUpdate:alisKdv": _cache[49] || (_cache[49] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
                        "alis-kdv-dahil": _ctx.alisKdvDahilRef,
                        "onUpdate:alisKdvDahil": _cache[50] || (_cache[50] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
                        "satis-kdv": _ctx.satisKdvRef,
                        "onUpdate:satisKdv": _cache[51] || (_cache[51] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
                        "satis-kdv-dahil": _ctx.satisKdvDahilRef,
                        "onUpdate:satisKdvDahil": _cache[52] || (_cache[52] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
                        "alis-fiyati": _ctx.alisFiyatRef,
                        "onUpdate:alisFiyati": _cache[53] || (_cache[53] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
                        "satis-fiyati": _ctx.satisFiyatRef,
                        "onUpdate:satisFiyati": _cache[54] || (_cache[54] = ($event: any) => ((_ctx.satisFiyatRef) = $event)),
                        "kargo-fiyat": _ctx.stokRef.presta[0].kargoTutar,
                        "onUpdate:kargoFiyat": _cache[55] || (_cache[55] = ($event: any) => ((_ctx.stokRef.presta[0].kargoTutar) = $event)),
                        "komisyon-oran": _ctx.stokRef.presta[0].komisyon,
                        "onUpdate:komisyonOran": _cache[56] || (_cache[56] = ($event: any) => ((_ctx.stokRef.presta[0].komisyon) = $event)),
                        "fiyat-tipi": _ctx.stokRef.presta[0].fiyat.tip,
                        "onUpdate:fiyatTipi": _cache[57] || (_cache[57] = ($event: any) => ((_ctx.stokRef.presta[0].fiyat.tip) = $event)),
                        deger: _ctx.stokRef.presta[0].fiyat.deger,
                        "onUpdate:deger": _cache[58] || (_cache[58] = ($event: any) => ((_ctx.stokRef.presta[0].fiyat.deger) = $event)),
                        "kar-marji": _ctx.stokRef.presta[0].karMarji,
                        "onUpdate:karMarji": _cache[59] || (_cache[59] = ($event: any) => ((_ctx.stokRef.presta[0].karMarji) = $event)),
                        "indirim-tipi": _ctx.stokRef.presta[0].indirim.tip,
                        "onUpdate:indirimTipi": _cache[60] || (_cache[60] = ($event: any) => ((_ctx.stokRef.presta[0].indirim.tip) = $event)),
                        indirim: _ctx.stokRef.presta[0].indirim.deger,
                        "onUpdate:indirim": _cache[61] || (_cache[61] = ($event: any) => ((_ctx.stokRef.presta[0].indirim.deger) = $event)),
                        "alis-doviz": _ctx.stokRef.alisDoviz,
                        "onUpdate:alisDoviz": _cache[62] || (_cache[62] = ($event: any) => ((_ctx.stokRef.alisDoviz) = $event)),
                        "satis-doviz": _ctx.stokRef.satisDoviz,
                        "onUpdate:satisDoviz": _cache[63] || (_cache[63] = ($event: any) => ((_ctx.stokRef.satisDoviz) = $event)),
                        maliyet: _ctx.stokRef.getMaliyet,
                        "onUpdate:maliyet": _cache[64] || (_cache[64] = ($event: any) => ((_ctx.stokRef.getMaliyet) = $event))
                      }, null, 8, ["alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyati", "satis-fiyati", "kargo-fiyat", "komisyon-oran", "fiyat-tipi", "deger", "kar-marji", "indirim-tipi", "indirim", "alis-doviz", "satis-doviz", "maliyet"]))
                    : _createCommentVNode("", true),
                  (_ctx.stokRef.cicekSepeti?.length > 0)
                    ? (_openBlock(), _createBlock(_component_PazaryeriFiyatItem, {
                        key: 5,
                        title: "ÇiçekSepeti Fiyatı",
                        "show-indirim": false,
                        "alis-kdv": _ctx.alisKdvRef,
                        "onUpdate:alisKdv": _cache[65] || (_cache[65] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
                        "alis-kdv-dahil": _ctx.alisKdvDahilRef,
                        "onUpdate:alisKdvDahil": _cache[66] || (_cache[66] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
                        "satis-kdv": _ctx.satisKdvRef,
                        "onUpdate:satisKdv": _cache[67] || (_cache[67] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
                        "satis-kdv-dahil": _ctx.satisKdvDahilRef,
                        "onUpdate:satisKdvDahil": _cache[68] || (_cache[68] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
                        "alis-fiyati": _ctx.alisFiyatRef,
                        "onUpdate:alisFiyati": _cache[69] || (_cache[69] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
                        "satis-fiyati": _ctx.satisFiyatRef,
                        "onUpdate:satisFiyati": _cache[70] || (_cache[70] = ($event: any) => ((_ctx.satisFiyatRef) = $event)),
                        "kargo-fiyat": _ctx.stokRef.cicekSepeti[0].kargoTutar,
                        "onUpdate:kargoFiyat": _cache[71] || (_cache[71] = ($event: any) => ((_ctx.stokRef.cicekSepeti[0].kargoTutar) = $event)),
                        "komisyon-oran": _ctx.stokRef.cicekSepeti[0].komisyon,
                        "onUpdate:komisyonOran": _cache[72] || (_cache[72] = ($event: any) => ((_ctx.stokRef.cicekSepeti[0].komisyon) = $event)),
                        "fiyat-tipi": _ctx.stokRef.cicekSepeti[0].fiyat.tip,
                        "onUpdate:fiyatTipi": _cache[73] || (_cache[73] = ($event: any) => ((_ctx.stokRef.cicekSepeti[0].fiyat.tip) = $event)),
                        deger: _ctx.stokRef.cicekSepeti[0].fiyat.deger,
                        "onUpdate:deger": _cache[74] || (_cache[74] = ($event: any) => ((_ctx.stokRef.cicekSepeti[0].fiyat.deger) = $event)),
                        "kar-marji": _ctx.stokRef.cicekSepeti[0].karMarji,
                        "onUpdate:karMarji": _cache[75] || (_cache[75] = ($event: any) => ((_ctx.stokRef.cicekSepeti[0].karMarji) = $event)),
                        "indirim-tipi": _ctx.stokRef.cicekSepeti[0].indirim.tip,
                        "onUpdate:indirimTipi": _cache[76] || (_cache[76] = ($event: any) => ((_ctx.stokRef.cicekSepeti[0].indirim.tip) = $event)),
                        indirim: _ctx.stokRef.cicekSepeti[0].indirim.deger,
                        "onUpdate:indirim": _cache[77] || (_cache[77] = ($event: any) => ((_ctx.stokRef.cicekSepeti[0].indirim.deger) = $event)),
                        "alis-doviz": _ctx.stokRef.alisDoviz,
                        "onUpdate:alisDoviz": _cache[78] || (_cache[78] = ($event: any) => ((_ctx.stokRef.alisDoviz) = $event)),
                        "satis-doviz": _ctx.stokRef.satisDoviz,
                        "onUpdate:satisDoviz": _cache[79] || (_cache[79] = ($event: any) => ((_ctx.stokRef.satisDoviz) = $event)),
                        maliyet: _ctx.stokRef.getMaliyet,
                        "onUpdate:maliyet": _cache[80] || (_cache[80] = ($event: any) => ((_ctx.stokRef.getMaliyet) = $event))
                      }, null, 8, ["alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyati", "satis-fiyati", "kargo-fiyat", "komisyon-oran", "fiyat-tipi", "deger", "kar-marji", "indirim-tipi", "indirim", "alis-doviz", "satis-doviz", "maliyet"]))
                    : _createCommentVNode("", true),
                  (_ctx.stokRef.epttAvm?.length > 0)
                    ? (_openBlock(), _createBlock(_component_PazaryeriFiyatItem, {
                        key: 6,
                        title: "EpttAvm Fiyatı",
                        "show-indirim": false,
                        "alis-kdv": _ctx.alisKdvRef,
                        "onUpdate:alisKdv": _cache[81] || (_cache[81] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
                        "alis-kdv-dahil": _ctx.alisKdvDahilRef,
                        "onUpdate:alisKdvDahil": _cache[82] || (_cache[82] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
                        "satis-kdv": _ctx.satisKdvRef,
                        "onUpdate:satisKdv": _cache[83] || (_cache[83] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
                        "satis-kdv-dahil": _ctx.satisKdvDahilRef,
                        "onUpdate:satisKdvDahil": _cache[84] || (_cache[84] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
                        "alis-fiyati": _ctx.alisFiyatRef,
                        "onUpdate:alisFiyati": _cache[85] || (_cache[85] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
                        "satis-fiyati": _ctx.satisFiyatRef,
                        "onUpdate:satisFiyati": _cache[86] || (_cache[86] = ($event: any) => ((_ctx.satisFiyatRef) = $event)),
                        "kargo-fiyat": _ctx.stokRef.epttAvm[0].kargoTutar,
                        "onUpdate:kargoFiyat": _cache[87] || (_cache[87] = ($event: any) => ((_ctx.stokRef.epttAvm[0].kargoTutar) = $event)),
                        "komisyon-oran": _ctx.stokRef.epttAvm[0].komisyon,
                        "onUpdate:komisyonOran": _cache[88] || (_cache[88] = ($event: any) => ((_ctx.stokRef.epttAvm[0].komisyon) = $event)),
                        "fiyat-tipi": _ctx.stokRef.epttAvm[0].fiyat.tip,
                        "onUpdate:fiyatTipi": _cache[89] || (_cache[89] = ($event: any) => ((_ctx.stokRef.epttAvm[0].fiyat.tip) = $event)),
                        deger: _ctx.stokRef.epttAvm[0].fiyat.deger,
                        "onUpdate:deger": _cache[90] || (_cache[90] = ($event: any) => ((_ctx.stokRef.epttAvm[0].fiyat.deger) = $event)),
                        "kar-marji": _ctx.stokRef.epttAvm[0].karMarji,
                        "onUpdate:karMarji": _cache[91] || (_cache[91] = ($event: any) => ((_ctx.stokRef.epttAvm[0].karMarji) = $event)),
                        "indirim-tipi": _ctx.stokRef.epttAvm[0].indirim.tip,
                        "onUpdate:indirimTipi": _cache[92] || (_cache[92] = ($event: any) => ((_ctx.stokRef.epttAvm[0].indirim.tip) = $event)),
                        indirim: _ctx.stokRef.epttAvm[0].indirim.deger,
                        "onUpdate:indirim": _cache[93] || (_cache[93] = ($event: any) => ((_ctx.stokRef.epttAvm[0].indirim.deger) = $event)),
                        "alis-doviz": _ctx.stokRef.alisDoviz,
                        "onUpdate:alisDoviz": _cache[94] || (_cache[94] = ($event: any) => ((_ctx.stokRef.alisDoviz) = $event)),
                        "satis-doviz": _ctx.stokRef.satisDoviz,
                        "onUpdate:satisDoviz": _cache[95] || (_cache[95] = ($event: any) => ((_ctx.stokRef.satisDoviz) = $event)),
                        maliyet: _ctx.stokRef.getMaliyet,
                        "onUpdate:maliyet": _cache[96] || (_cache[96] = ($event: any) => ((_ctx.stokRef.getMaliyet) = $event))
                      }, null, 8, ["alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyati", "satis-fiyati", "kargo-fiyat", "komisyon-oran", "fiyat-tipi", "deger", "kar-marji", "indirim-tipi", "indirim", "alis-doviz", "satis-doviz", "maliyet"]))
                    : _createCommentVNode("", true),
                  (_ctx.stokRef.pazarama?.length > 0)
                    ? (_openBlock(), _createBlock(_component_PazaryeriFiyatItem, {
                        key: 7,
                        title: "Pazarama Fiyatı",
                        "show-indirim": false,
                        "alis-kdv": _ctx.alisKdvRef,
                        "onUpdate:alisKdv": _cache[97] || (_cache[97] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
                        "alis-kdv-dahil": _ctx.alisKdvDahilRef,
                        "onUpdate:alisKdvDahil": _cache[98] || (_cache[98] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
                        "satis-kdv": _ctx.satisKdvRef,
                        "onUpdate:satisKdv": _cache[99] || (_cache[99] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
                        "satis-kdv-dahil": _ctx.satisKdvDahilRef,
                        "onUpdate:satisKdvDahil": _cache[100] || (_cache[100] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
                        "alis-fiyati": _ctx.alisFiyatRef,
                        "onUpdate:alisFiyati": _cache[101] || (_cache[101] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
                        "satis-fiyati": _ctx.satisFiyatRef,
                        "onUpdate:satisFiyati": _cache[102] || (_cache[102] = ($event: any) => ((_ctx.satisFiyatRef) = $event)),
                        "kargo-fiyat": _ctx.stokRef.pazarama[0].kargoTutar,
                        "onUpdate:kargoFiyat": _cache[103] || (_cache[103] = ($event: any) => ((_ctx.stokRef.pazarama[0].kargoTutar) = $event)),
                        "komisyon-oran": _ctx.stokRef.pazarama[0].komisyon,
                        "onUpdate:komisyonOran": _cache[104] || (_cache[104] = ($event: any) => ((_ctx.stokRef.pazarama[0].komisyon) = $event)),
                        "fiyat-tipi": _ctx.stokRef.pazarama[0].fiyat.tip,
                        "onUpdate:fiyatTipi": _cache[105] || (_cache[105] = ($event: any) => ((_ctx.stokRef.pazarama[0].fiyat.tip) = $event)),
                        deger: _ctx.stokRef.pazarama[0].fiyat.deger,
                        "onUpdate:deger": _cache[106] || (_cache[106] = ($event: any) => ((_ctx.stokRef.pazarama[0].fiyat.deger) = $event)),
                        "kar-marji": _ctx.stokRef.pazarama[0].karMarji,
                        "onUpdate:karMarji": _cache[107] || (_cache[107] = ($event: any) => ((_ctx.stokRef.pazarama[0].karMarji) = $event)),
                        "indirim-tipi": _ctx.stokRef.pazarama[0].indirim.tip,
                        "onUpdate:indirimTipi": _cache[108] || (_cache[108] = ($event: any) => ((_ctx.stokRef.pazarama[0].indirim.tip) = $event)),
                        indirim: _ctx.stokRef.pazarama[0].indirim.deger,
                        "onUpdate:indirim": _cache[109] || (_cache[109] = ($event: any) => ((_ctx.stokRef.pazarama[0].indirim.deger) = $event)),
                        "alis-doviz": _ctx.stokRef.alisDoviz,
                        "onUpdate:alisDoviz": _cache[110] || (_cache[110] = ($event: any) => ((_ctx.stokRef.alisDoviz) = $event)),
                        "satis-doviz": _ctx.stokRef.satisDoviz,
                        "onUpdate:satisDoviz": _cache[111] || (_cache[111] = ($event: any) => ((_ctx.stokRef.satisDoviz) = $event)),
                        maliyet: _ctx.stokRef.getMaliyet,
                        "onUpdate:maliyet": _cache[112] || (_cache[112] = ($event: any) => ((_ctx.stokRef.getMaliyet) = $event))
                      }, null, 8, ["alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyati", "satis-fiyati", "kargo-fiyat", "komisyon-oran", "fiyat-tipi", "deger", "kar-marji", "indirim-tipi", "indirim", "alis-doviz", "satis-doviz", "maliyet"]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stokRef.n11, (n11, i) => {
              return (_openBlock(), _createElementBlock("div", { key: i }, [
                (_ctx.selectedTab === i +1)
                  ? (_openBlock(), _createBlock(_component_N11StokForm, {
                      key: 0,
                      n11: n11,
                      stok: _ctx.stokRef,
                      "onUpdate:stok": _cache[113] || (_cache[113] = ($event: any) => ((_ctx.stokRef) = $event)),
                      "alis-kdv": _ctx.alisKdvRef,
                      "onUpdate:alisKdv": _cache[114] || (_cache[114] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
                      "alis-kdv-dahil": _ctx.alisKdvDahilRef,
                      "onUpdate:alisKdvDahil": _cache[115] || (_cache[115] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
                      "satis-kdv": _ctx.satisKdvRef,
                      "onUpdate:satisKdv": _cache[116] || (_cache[116] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
                      "satis-kdv-dahil": _ctx.satisKdvDahilRef,
                      "onUpdate:satisKdvDahil": _cache[117] || (_cache[117] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
                      "alis-fiyat": _ctx.alisFiyatRef,
                      "onUpdate:alisFiyat": _cache[118] || (_cache[118] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
                      "satis-fiyat": _ctx.satisFiyatRef,
                      "onUpdate:satisFiyat": _cache[119] || (_cache[119] = ($event: any) => ((_ctx.satisFiyatRef) = $event)),
                      index: i
                    }, null, 8, ["n11", "stok", "alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyat", "satis-fiyat", "index"]))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stokRef.trendyol, (trendyol, i) => {
              return (_openBlock(), _createElementBlock("div", { key: i }, [
                (_ctx.selectedTab === i + _ctx.stok.n11.length + 1)
                  ? (_openBlock(), _createBlock(_component_TrendyolStokForm, {
                      key: 0,
                      trendyol: trendyol,
                      stok: _ctx.stokRef,
                      "onUpdate:stok": _cache[120] || (_cache[120] = ($event: any) => ((_ctx.stokRef) = $event)),
                      "alis-kdv": _ctx.alisKdvRef,
                      "onUpdate:alisKdv": _cache[121] || (_cache[121] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
                      "alis-kdv-dahil": _ctx.alisKdvDahilRef,
                      "onUpdate:alisKdvDahil": _cache[122] || (_cache[122] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
                      "satis-kdv": _ctx.satisKdvRef,
                      "onUpdate:satisKdv": _cache[123] || (_cache[123] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
                      "satis-kdv-dahil": _ctx.satisKdvDahilRef,
                      "onUpdate:satisKdvDahil": _cache[124] || (_cache[124] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
                      "alis-fiyat": _ctx.alisFiyatRef,
                      "onUpdate:alisFiyat": _cache[125] || (_cache[125] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
                      "satis-fiyat": _ctx.satisFiyatRef,
                      "onUpdate:satisFiyat": _cache[126] || (_cache[126] = ($event: any) => ((_ctx.satisFiyatRef) = $event)),
                      index: i
                    }, null, 8, ["trendyol", "stok", "alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyat", "satis-fiyat", "index"]))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            _withDirectives(_createVNode(_component_NCard, {
              modelValue: _ctx.selectedHepsiBuradaTab,
              "onUpdate:modelValue": _cache[135] || (_cache[135] = ($event: any) => ((_ctx.selectedHepsiBuradaTab) = $event)),
              tabs: [{label:'Listeleme'},{label:'Kataloğa Gönder'}],
              rows: "auto 1fr",
              cols: "1fr"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stokRef.hepsiBuradaListing, (hepsiBuradaListing, i) => {
                  return _withDirectives((_openBlock(), _createBlock(_component_HepsiBuradaListingForm, {
                    "hepsi-burada-listing": hepsiBuradaListing,
                    stok: _ctx.stokRef,
                    "onUpdate:stok": _cache[127] || (_cache[127] = ($event: any) => ((_ctx.stokRef) = $event)),
                    "alis-kdv": _ctx.alisKdvRef,
                    "onUpdate:alisKdv": _cache[128] || (_cache[128] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
                    "alis-kdv-dahil": _ctx.alisKdvDahilRef,
                    "onUpdate:alisKdvDahil": _cache[129] || (_cache[129] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
                    "satis-kdv": _ctx.satisKdvRef,
                    "onUpdate:satisKdv": _cache[130] || (_cache[130] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
                    "satis-kdv-dahil": _ctx.satisKdvDahilRef,
                    "onUpdate:satisKdvDahil": _cache[131] || (_cache[131] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
                    "alis-fiyat": _ctx.alisFiyatRef,
                    "onUpdate:alisFiyat": _cache[132] || (_cache[132] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
                    "satis-fiyat": _ctx.satisFiyatRef,
                    "onUpdate:satisFiyat": _cache[133] || (_cache[133] = ($event: any) => ((_ctx.satisFiyatRef) = $event)),
                    key: i
                  }, null, 8, ["hepsi-burada-listing", "stok", "alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyat", "satis-fiyat"])), [
                    [_vShow, _ctx.selectedHepsiBuradaTab === 0]
                  ])
                }), 128)),
                (_ctx.selectedHepsiBuradaTab === 1)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stokRef.hepsiBurada, (hepsiBurada, i) => {
                        return _withDirectives((_openBlock(), _createBlock(_component_HepsiBuradaStokForm, {
                          stok: _ctx.stokRef,
                          "onUpdate:stok": _cache[134] || (_cache[134] = ($event: any) => ((_ctx.stokRef) = $event)),
                          "hepsi-burada": hepsiBurada,
                          key: i
                        }, null, 8, ["stok", "hepsi-burada"])), [
                          [_vShow, _ctx.selectedHepsiBuradaTab === 1]
                        ])
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue"]), [
              [_vShow, _ctx.selectedTab === _ctx.stok.n11.length + _ctx.stok.trendyol.length + 1]
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stokRef.presta, (presta, i) => {
              return (_openBlock(), _createElementBlock("div", { key: i }, [
                (_ctx.selectedTab === _ctx.stok.n11.length + _ctx.stok.trendyol.length + Math.max(_ctx.stok.hepsiBuradaListing.length,_ctx.stok.hepsiBurada.length) + i + 1)
                  ? (_openBlock(), _createBlock(_component_PrestaStokForm, {
                      key: 0,
                      presta: presta,
                      stok: _ctx.stokRef,
                      "onUpdate:stok": _cache[136] || (_cache[136] = ($event: any) => ((_ctx.stokRef) = $event)),
                      "alis-kdv": _ctx.alisKdvRef,
                      "onUpdate:alisKdv": _cache[137] || (_cache[137] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
                      "alis-kdv-dahil": _ctx.alisKdvDahilRef,
                      "onUpdate:alisKdvDahil": _cache[138] || (_cache[138] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
                      "satis-kdv": _ctx.satisKdvRef,
                      "onUpdate:satisKdv": _cache[139] || (_cache[139] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
                      "satis-kdv-dahil": _ctx.satisKdvDahilRef,
                      "onUpdate:satisKdvDahil": _cache[140] || (_cache[140] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
                      "alis-fiyat": _ctx.alisFiyatRef,
                      "onUpdate:alisFiyat": _cache[141] || (_cache[141] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
                      "satis-fiyat": _ctx.satisFiyatRef,
                      "onUpdate:satisFiyat": _cache[142] || (_cache[142] = ($event: any) => ((_ctx.satisFiyatRef) = $event)),
                      index: i,
                      configs: _ctx.configs,
                      "onUpdate:configs": _cache[143] || (_cache[143] = ($event: any) => ((_ctx.configs) = $event))
                    }, null, 8, ["presta", "stok", "alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyat", "satis-fiyat", "index", "configs"]))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stokRef.cicekSepeti, (cicekSepeti, i) => {
              return (_openBlock(), _createElementBlock("div", { key: i }, [
                (_ctx.selectedTab === _ctx.stok.n11.length + _ctx.stok.trendyol.length + _ctx.stok.presta.length + Math.max(_ctx.stok.hepsiBuradaListing.length,_ctx.stok.hepsiBurada.length) + 1)
                  ? (_openBlock(), _createBlock(_component_CicekSepetiStokForm, {
                      key: 0,
                      stok: _ctx.stokRef,
                      "onUpdate:stok": _cache[144] || (_cache[144] = ($event: any) => ((_ctx.stokRef) = $event)),
                      "cicek-sepeti": cicekSepeti,
                      "alis-kdv": _ctx.alisKdvRef,
                      "onUpdate:alisKdv": _cache[145] || (_cache[145] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
                      "alis-kdv-dahil": _ctx.alisKdvDahilRef,
                      "onUpdate:alisKdvDahil": _cache[146] || (_cache[146] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
                      "satis-kdv": _ctx.satisKdvRef,
                      "onUpdate:satisKdv": _cache[147] || (_cache[147] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
                      "satis-kdv-dahil": _ctx.satisKdvDahilRef,
                      "onUpdate:satisKdvDahil": _cache[148] || (_cache[148] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
                      "alis-fiyat": _ctx.alisFiyatRef,
                      "onUpdate:alisFiyat": _cache[149] || (_cache[149] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
                      "satis-fiyat": _ctx.satisFiyatRef,
                      "onUpdate:satisFiyat": _cache[150] || (_cache[150] = ($event: any) => ((_ctx.satisFiyatRef) = $event))
                    }, null, 8, ["stok", "cicek-sepeti", "alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyat", "satis-fiyat"]))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stokRef.epttAvm, (epttavm, i) => {
              return (_openBlock(), _createElementBlock("div", { key: i }, [
                (_ctx.selectedTab === _ctx.stok.n11.length + _ctx.stok.trendyol.length + _ctx.stok.presta.length + Math.max(_ctx.stok.hepsiBuradaListing.length,_ctx.stok.hepsiBurada.length) + _ctx.stok.cicekSepeti.length + 1)
                  ? (_openBlock(), _createBlock(_component_EpttAvmStokForm, {
                      key: 0,
                      stok: _ctx.stokRef,
                      "onUpdate:stok": _cache[151] || (_cache[151] = ($event: any) => ((_ctx.stokRef) = $event)),
                      eptt: epttavm,
                      "alis-kdv": _ctx.alisKdvRef,
                      "onUpdate:alisKdv": _cache[152] || (_cache[152] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
                      "alis-kdv-dahil": _ctx.alisKdvDahilRef,
                      "onUpdate:alisKdvDahil": _cache[153] || (_cache[153] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
                      "satis-kdv": _ctx.satisKdvRef,
                      "onUpdate:satisKdv": _cache[154] || (_cache[154] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
                      "satis-kdv-dahil": _ctx.satisKdvDahilRef,
                      "onUpdate:satisKdvDahil": _cache[155] || (_cache[155] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
                      "alis-fiyat": _ctx.alisFiyatRef,
                      "onUpdate:alisFiyat": _cache[156] || (_cache[156] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
                      "satis-fiyat": _ctx.satisFiyatRef,
                      "onUpdate:satisFiyat": _cache[157] || (_cache[157] = ($event: any) => ((_ctx.satisFiyatRef) = $event))
                    }, null, 8, ["stok", "eptt", "alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyat", "satis-fiyat"]))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stokRef.pazarama, (pazarama, i) => {
              return (_openBlock(), _createElementBlock("div", { key: i }, [
                (_ctx.selectedTab === _ctx.stok.n11.length + _ctx.stok.trendyol.length + _ctx.stok.presta.length + Math.max(_ctx.stok.hepsiBuradaListing.length,_ctx.stok.hepsiBurada.length) + _ctx.stok.cicekSepeti.length + _ctx.stok.epttAvm.length + 1)
                  ? (_openBlock(), _createBlock(_component_PazaramaStokForm, {
                      key: 0,
                      stok: _ctx.stokRef,
                      "onUpdate:stok": _cache[158] || (_cache[158] = ($event: any) => ((_ctx.stokRef) = $event)),
                      pazarama: pazarama,
                      "alis-kdv": _ctx.alisKdvRef,
                      "onUpdate:alisKdv": _cache[159] || (_cache[159] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
                      "alis-kdv-dahil": _ctx.alisKdvDahilRef,
                      "onUpdate:alisKdvDahil": _cache[160] || (_cache[160] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
                      "satis-kdv": _ctx.satisKdvRef,
                      "onUpdate:satisKdv": _cache[161] || (_cache[161] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
                      "satis-kdv-dahil": _ctx.satisKdvDahilRef,
                      "onUpdate:satisKdvDahil": _cache[162] || (_cache[162] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
                      "alis-fiyat": _ctx.alisFiyatRef,
                      "onUpdate:alisFiyat": _cache[163] || (_cache[163] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
                      "satis-fiyat": _ctx.satisFiyatRef,
                      "onUpdate:satisFiyat": _cache[164] || (_cache[164] = ($event: any) => ((_ctx.satisFiyatRef) = $event))
                    }, null, 8, ["stok", "pazarama", "alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyat", "satis-fiyat"]))
                  : _createCommentVNode("", true)
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }, 16, ["tabs", "selected-tab"]))
    : _createCommentVNode("", true)
}