import {IdName} from '../models/IdName'
import {Ozellik} from '../models/Ozellik'
import {StokBirimItem} from '../models/StokBirimItem'
import {Doviz} from '../models/Doviz'
import {SubeFiyatItem} from '../models/SubeFiyatItem'
import {StokUreticiItem} from '../models/StokUreticiItem'
import {N11PazaryeriItem} from '../models/N11PazaryeriItem'
import {CicekSepetiPazaryeriItem} from '../models/CicekSepetiPazaryeriItem'
import {TrendyolPazaryeriItem} from '../models/TrendyolPazaryeriItem'
import {HepsiBuradaPazaryeriItem} from '../models/HepsiBuradaPazaryeriItem'
import {HepsiBuradaListingPazaryeriItem} from '../models/HepsiBuradaListingPazaryeriItem'
import {PrestaPazaryeriItem} from '../models/PrestaPazaryeriItem'
import {EpttAvmPazaryeriItem} from '../models/EpttAvmPazaryeriItem'
import {PazaramaPazaryeriItem} from '../models/PazaramaPazaryeriItem'
import {StokViewItem} from '../models/StokViewItem'
import {StokUyumluluktem} from '../models/StokUyumluluktem'
import {OzellikVaryant} from '../models/OzellikVaryant'
import {StokTipOzellikler} from '../models/StokTipOzellikler'
import {StokTip} from '../models/StokTip'
import {StokLanguageItem} from '../models/StokLanguageItem'
import {PluKod} from '../models/PluKod'

export class StokView{
	aktif?:boolean;
	id?:string;
	kod?:string;
	ad?:string;
	kategori?:IdName;
	ozellikler?:Ozellik[];
	birimFiyatlari?:StokBirimItem[];
	kisaAciklama?:string;
	aciklama?:string;
	satisFiyat?:number;
	satisKdv?:number;
	satisKdvDahil?:boolean;
	satisDoviz?:Doviz;
	grup1?:string;
	grup2?:string;
	grup3?:string;
	grup4?:string;
	alisFiyat?:number;
	alisKdv?:number;
	alisKdvDahil?:boolean;
	ureticiKod?:string;
	alisDoviz?:Doviz;
	barkod?:string;
	birim?:string;
	yerlimi?:boolean;
	desi?:number;
	karMarji?:number;
	piyasaFiyati?:number;
	isSerili?:boolean;
	devirMiktar?:number;
	devirDepoId?:string;
	resimler?:string[];
	subeFiyatlari?:SubeFiyatItem[];
	ureticiler?:StokUreticiItem[];
	n11?:N11PazaryeriItem[];
	cicekSepeti?:CicekSepetiPazaryeriItem[];
	trendyol?:TrendyolPazaryeriItem[];
	hepsiBurada?:HepsiBuradaPazaryeriItem[];
	hepsiBuradaListing?:HepsiBuradaListingPazaryeriItem[];
	presta?:PrestaPazaryeriItem[];
	epttAvm?:EpttAvmPazaryeriItem[];
	pazarama?:PazaramaPazaryeriItem[];
	items?:StokViewItem[];
	uyumluluklar?:StokUyumluluktem[];
	varyantlar?:OzellikVaryant[];
	tipOzellikler?:StokTipOzellikler;
	tip?:StokTip;
	languages?:StokLanguageItem[];
	faturaAd?:string;
	gtip?:string;
	getMaliyet?:number;
	getEnvanter?:number;
	adlar?:string[];
	barkodlar?:string[];
	kodlar?:string[];
	adetBolen?:number;
	readonly no?:string;
	kategoriNo?:number;
	hizliSatis?:string;
	raf?:string;
	pluKod?:PluKod;
	pluNo?:number;
	parentId?:string;
}
