
import {defineComponent, reactive, toRefs} from "vue";

export default defineComponent({
  setup() {
    const localState = reactive({})


    return {
      ...toRefs(localState)
    }
  }
})
