<script>
import feather from "feather-icons";
import { defineComponent, h, toRefs, watch } from "vue";
export default defineComponent({
  name: "NIcon",
  props: {
    animation: {
      type: String,
      default: undefined
    },
    animationSpeed: {
      type: String,
      default: undefined
    },
    fill: {
      type: String,
      default: "none"
    },
    size: {
      type: [Number, String],
      default: 24
    },
    stroke: {
      type: String,
      default: "currentColor"
    },
    strokeLinecap: {
      type: String,
      default: "round"
    },
    strokeLinejoin: {
      type: String,
      default: "round"
    },
    strokeWidth: {
      type: [Number, String],
      default: 2
    },
    tag: {
      type: String,
      default: "i"
    },
    type: {
      type: String,
      required: true,
      validator(type) {
        if (!feather.icons[type]) {
          throw new Error(`"${type}" is not an available icon type.`);
        }
        return true;
      }
    }
  },
  setup(props) {
    const {
      animation,
      animationSpeed,
      size,
      fill,
      stroke,
      strokeLinecap,
      strokeLinejoin,
      strokeWidth,
      tag,
      type
    } = toRefs(props);

    let icon = feather.icons[type.value];

    watch(type, (currentType, previousType) => {
      icon = feather.icons[type.value];
    });

    return () =>
      h(
        tag.value,
        {
          "data-name": type.value,
          "data-tags": icon ? icon.tags : "",
          "data-type": type.value,
          class: {
            icon: true,
            feather: true,
            [`feather--${type.value}`]: type.value,
            [`feather--${animation.value}`]: animation.value,
            [`feather--${animationSpeed.value}`]: animationSpeed.value
          }
        },
        [
          icon
            ? h(
                "svg",
                {
                  ...icon.attrs,
                  width: size.value,
                  height: size.value,
                  fill: fill.value,
                  stroke: stroke.value,
                  "stroke-linecap": strokeLinecap.value,
                  "stroke-linejoin": strokeLinejoin.value,
                  "stroke-width": strokeWidth.value,
                  class: [icon.attrs.class, "feather__content"],
                  innerHTML: icon.contents
                },
                ""
              )
            : ""
        ]
      );
  }
});
</script>
<!--icon feather feather--settings feather--spin-->
<style lang="scss" >
@keyframes feather--spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.feather {
  display: inline-block;
  overflow: hidden;
  &--spin {
    animation: feather--spin 2s linear infinite;
  }
  &--pulse {
    animation: feather--spin 2s infinite steps(8);
  }
  &--slow {
    animation-duration: 3s;
  }
  &--fast {
    animation-duration: 1s;
  }
  &__content {
    display: block;
    height: inherit;
    width: inherit;
  }
}
</style>
