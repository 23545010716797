import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {ProjeView} from '../models/ProjeView'

export const useProjeService = defineStore({
id: 'ProjeService',
actions: {


	async getDefault()
	{
		const { request, response } = useApi(`/api/proje/default`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/proje/List/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getIdNameList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/proje/IdNameList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetir(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/proje/Getir/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postEkle(projeView:ProjeView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/proje/Ekle/${shared.firm.id}`,{ method:'POST', data:projeView });
		await request();
		return response.value;
	},

	async postGuncelle(projeView:ProjeView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/proje/Guncelle/${shared.firm.id}`,{ method:'POST', data:projeView });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/proje/Sil/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	}
}
});
