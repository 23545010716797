import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, vShow as _vShow, mergeProps as _mergeProps, withDirectives as _withDirectives, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "loading-panel-content" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "desc-2",
  style: {"background":"#FFF","padding":"24px","display":"inline-block","border-radius":"8px"}
}
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NLoading = _resolveComponent("NLoading")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NAlert = _resolveComponent("NAlert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createElementVNode("div", _mergeProps({
      class: "loading-panel-container",
      style: {"min-height":"160px"}
    }, _ctx.$attrs), [
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.isError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_NLoading)
            ]))
          : _createCommentVNode("", true),
        (!_ctx.isError)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.title), 1))
          : _createCommentVNode("", true),
        (_ctx.isError)
          ? (_openBlock(), _createBlock(_component_NAlert, {
              key: 2,
              style: {"max-width":"300px","margin":"0 auto"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.errorMesage) + " ", 1),
                _createVNode(_component_NButton, { onClick: _ctx.load }, {
                  default: _withCtx(() => [
                    _createTextVNode("Yeniden Dene")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ], 16), [
      [_vShow, _ctx.isLoading]
    ]),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : _createCommentVNode("", true)
  ], 64))
}