import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NInput = _resolveComponent("NInput")!
  const _component_DxTreeListColumn = _resolveComponent("DxTreeListColumn")!
  const _component_DxTreeList = _resolveComponent("DxTreeList")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_ctx.showRef)
    ? (_openBlock(), _createBlock(_component_NModalDialog, {
        key: 0,
        title: "Etiket Değiştir (Sac Üretim)",
        modelValue: _ctx.showRef,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showRef) = $event)),
        "closable-mask": false,
        width: "800px",
        "show-close": true
      }, {
        body: _withCtx(() => [
          _createVNode(_component_NLoadingPanel, {
            action: _ctx.load,
            title: "Etiket Değiştirme Formu Yükleniyor..."
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NGrid, {
                cols: "1fr",
                style: {"height":"auto"},
                gap: "8px"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.etiket,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.etiket) = $event)),
                    placeholder: "Yeni Etiket Değerini Giriniz...",
                    style: {"width":"250px"}
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_DxTreeList, {
                    ref: "treeList",
                    autoExpandAll: true,
                    style: {"width":"100%","height":"350px"},
                    "data-structure": "tree",
                    "show-row-lines": true,
                    "show-borders": true,
                    "data-source": _ctx.model,
                    "items-expr": "childs"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DxTreeListColumn, {
                        width: 200,
                        "data-field": "stok.ad",
                        caption: "Stok Adı",
                        "data-type": "text"
                      }),
                      _createVNode(_component_DxTreeListColumn, {
                        width: 100,
                        "data-field": "stok.kod",
                        caption: "Stok Kodu"
                      }),
                      _createVNode(_component_DxTreeListColumn, {
                        width: 125,
                        "data-field": "stok.tipOzellikler.etiket",
                        caption: "Etiket"
                      }),
                      _createVNode(_component_DxTreeListColumn, {
                        width: 125,
                        "data-field": "name",
                        caption: "Yeni Etiket"
                      }),
                      _createVNode(_component_DxTreeListColumn, {
                        width: 100,
                        "data-field": "getSatisSiparisMiktar",
                        caption: "Satış Sipariş Miktar",
                        "calculate-cell-value": (a)=> (a.stok.getSatisSiparisMiktar ?? 0)?.format(0,4)
                      }, null, 8, ["calculate-cell-value"]),
                      _createVNode(_component_DxTreeListColumn, {
                        width: 100,
                        "data-field": "getAlisSiparisMiktar",
                        caption: "Alış Sipariş Miktar",
                        "calculate-cell-value": (a)=> (a.stok.getAlisSiparisMiktar ?? 0)?.format(0,4)
                      }, null, 8, ["calculate-cell-value"])
                    ]),
                    _: 1
                  }, 8, ["data-source"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["action"])
        ]),
        actions: _withCtx(() => [
          _createVNode(_component_NButton, {
            type: "warning",
            size: "small",
            icon: "x-circle",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showRef = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("İptal")
            ]),
            _: 1
          }),
          _createVNode(_component_NButton, {
            icon: "save",
            type: "success",
            size: "small",
            click: _ctx.save,
            disabled: !_ctx.etiket ||(_ctx.etiket && _ctx.etiket.length == 0)
          }, {
            default: _withCtx(() => [
              _createTextVNode("Kaydet")
            ]),
            _: 1
          }, 8, ["click", "disabled"])
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}