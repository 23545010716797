import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "dx-card",
  style: {"height":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxLoadPanel = _resolveComponent("DxLoadPanel")!
  const _component_DxGroupPanel = _resolveComponent("DxGroupPanel")!
  const _component_DxGrouping = _resolveComponent("DxGrouping")!
  const _component_DxScrolling = _resolveComponent("DxScrolling")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxColumnChooser = _resolveComponent("DxColumnChooser")!
  const _component_DxEditing = _resolveComponent("DxEditing")!
  const _component_DxKeyboardNavigation = _resolveComponent("DxKeyboardNavigation")!
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DxDataGrid, {
      "data-source": _ctx.model.yetkililer,
      ref: "yetkililerDataGrid",
      "row-alternation-enabled": true,
      "allow-column-reordering": true,
      toolbarTemplate: "toolbarTemplate",
      style: {"height":"200px"}
    }, {
      toolbarTemplate: _withCtx(() => [
        _createVNode(_component_NButton, {
          size: "small",
          type: "info",
          onClick: _ctx.yetkiliEkle,
          icon: "plus"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Yetkili Ekle")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DxLoadPanel, { enabled: false }),
        _createVNode(_component_DxGroupPanel, { visible: false }),
        _createVNode(_component_DxGrouping, { "auto-expand-all": false }),
        _createVNode(_component_DxScrolling, { mode: "infinite" }),
        _createVNode(_component_DxHeaderFilter, { visible: false }),
        _createVNode(_component_DxFilterRow, { visible: false }),
        _createVNode(_component_DxColumnChooser, { enabled: false }),
        _createVNode(_component_DxEditing, {
          "allow-updating": true,
          "allow-deleting": true,
          "use-icons": true,
          "select-text-on-edit-start": true,
          "start-edit-action": "click",
          mode: "cell"
        }),
        _createVNode(_component_DxKeyboardNavigation, {
          "edit-on-key-press": true,
          "enter-key-action": "moveFocus",
          "enter-key-direction": "column"
        }),
        _createVNode(_component_DxGroupPanel, { visible: false }),
        _createVNode(_component_DxColumn, {
          caption: "Sil",
          type: "buttons",
          width: 40
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxButton, { name: "delete" })
          ]),
          _: 1
        }),
        _createVNode(_component_DxColumn, {
          width: 200,
          caption: "Görev",
          "data-field": "unvan"
        }),
        _createVNode(_component_DxColumn, {
          width: 80,
          caption: "Ad",
          "data-field": "ad"
        }),
        _createVNode(_component_DxColumn, {
          width: 80,
          caption: "Soyad",
          "data-field": "soyad"
        }),
        _createVNode(_component_DxColumn, {
          width: 80,
          caption: "Telefon",
          "data-field": "telefon"
        }),
        _createVNode(_component_DxColumn, {
          width: 80,
          caption: "E-Posta",
          "data-field": "eposta"
        }),
        _createVNode(_component_DxColumn, {
          width: 200,
          caption: "Not",
          "data-field": "not"
        }),
        _createVNode(_component_DxColumn, {
          width: 80,
          caption: "Durumu",
          "data-field": "durum"
        })
      ]),
      _: 1
    }, 8, ["data-source"])
  ]))
}