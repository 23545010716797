
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NInput from "@/components/Input/NInput.vue";
import { PrestaPazaryeriItem } from "@/api/models/PrestaPazaryeriItem";
import { StokEntity } from "@/api/models/StokEntity";
import NSwitch from "@/components/Switch/NSwitch.vue";
import PazaryeriFiyatItem from "@/modules/Pazaryerleri/components/PazaryeriFiyatItem.vue";
import NButton from "@/components/Button/NButton.vue";
import {StokKategoriEntity} from "@/api/models/StokKategoriEntity";
import {StokKategoriView} from "@/api/models/StokKategoriView";
import NInputHtml from "@/components/Input/NInputHtml.vue";
import {PazaryeriFiyatTipiList} from "@/api/models/PazaryeriFiyatTipi";

export default defineComponent({
  components: {NInputHtml, NButton,  NSwitch,  NFormGrid },
  props: [
    'presta',
    'kategori',
      'configs',
      'index'
  ],
  setup(props,{emit}) {

    const localState = reactive({
      prestaRef:computed(a => props.presta as PrestaPazaryeriItem),
      kategoriRef:computed(a => props.kategori as StokKategoriEntity),
        pazaryeriFiyatTipiList:ref(PazaryeriFiyatTipiList),
      });

    function addNew(){
      if (confirm('Yeni site eklemek istediğinize emin misiniz?'))
      if (localState.kategoriRef.ozellik?.presta){
        const clone = JSON.parse(JSON.stringify(localState.kategoriRef.ozellik.presta[0]));
        localState.kategoriRef.ozellik.presta.push(clone);
      }
    }
    function remove(index:number){
      if (confirm('E-Ticaret sitesini silmek istiyor musunuz?'))
      if (localState.kategoriRef.ozellik?.presta){
        localState.kategoriRef.ozellik.presta.splice(index,1)
      }
    }

    return {
      ...toRefs(localState),
      addNew,
      remove
    };
  }
});

