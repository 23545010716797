
export enum PazaryeriNameType{
Tumu=0,
N11=1,
HepsiBurada=2,
HepsiBuradaListing=3,
Trendyol=4,
Presta=5,
CicekSepeti=7,
EpttAvm=8,
Pazarama=9
}

export const PazaryeriNameTypeList = [
{key:'Tumu', value:0, description:'Tümü'},
{key:'N11', value:1, description:'N11'},
{key:'HepsiBurada', value:2, description:'HepsiBurada Katalog'},
{key:'HepsiBuradaListing', value:3, description:'HepsiBurada Listeleme'},
{key:'Trendyol', value:4, description:'Trendyol'},
{key:'Presta', value:5, description:'E-Ticaret'},
{key:'CicekSepeti', value:7, description:'Çiçek Sepeti'},
{key:'EpttAvm', value:8, description:'EpttAvm'},
{key:'Pazarama', value:9, description:'Pazarama'}
]
