
import {defineComponent, onMounted, onUnmounted} from "vue";
import useCheckSlot from "@/utils/useCheckSlots";
import NIcon from "@/components/Icon/NIcon.vue";
import NGrid from "@/components/Grid/NGrid.vue";

type NModalTab = {
  id: string;
  label: string;
  value?: string;
  disabled?: boolean;
  icon?:string;
  showLabel?:boolean,
  hide?:boolean
};

export default defineComponent({
  name: "NModalDialog",
  components: {NGrid, NIcon },
  props: {
    modelValue: { type: Boolean, default: false },
    width: { type: String, default: "500px" },
    closableMask: { type: Boolean, default: false },
    closeOnEsc: { type: Boolean, default: false },
    title: { type: String },
    tabs: { type: Object as () => NModalTab[] },
    selectedTab: { type: Number, default: 0 },
    showClose:{type:Boolean, default:false}
  },
  setup(props, { emit, slots }) {
    const itHasHeader = useCheckSlot(slots, "header") !== null;
    const itHasBody = useCheckSlot(slots, "body") !== null;
    const itHasActions = useCheckSlot(slots, "actions") !== null;

    function close() {
      emit("update:modelValue", false);
    }

    function maskClick() {
      if (props.closableMask) {
        close();
      }
    }

    function selectTab(tabIndex: number) {
      emit("update:selectedTab", tabIndex);
    }

    onMounted(()=>{
      document.addEventListener("keydown", closeEvent)
    })

      onUnmounted(()=>{
          document.removeEventListener("keydown",closeEvent)
      })

      function closeEvent(e:KeyboardEvent) {
        if (e.key == "Escape"){
         close();
        }
      }


    return {
      maskClick,
      itHasHeader,
      itHasBody,
      itHasActions,
      close,
      selectTab
    };
  }
});
