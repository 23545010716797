
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import NButton from "@/components/Button/NButton.vue";
import { useCariStore } from "@/modules/Cari/store/CariStore";
import NGrid from "@/components/Grid/NGrid.vue";
import { usePersonelService } from "@/api/services/PersonelService";
import { PersonelEntity } from "@/api/models/PersonelEntity";
import { DxAutocomplete, DxList } from "devextreme-vue";
import DxDropDownBox from "devextreme-vue/drop-down-box";
import dxDropDownBox from "devextreme/ui/drop_down_box";
import NIconIonic from "@/components/NIconIonic.vue";
import CariFormDialog from "@/modules/Cari/dialogs/CariFormDialog.vue";
import NViewGrid from "@/components/ViewGrid/NViewGrid.vue";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import { CariEntity } from "@/api/models/CariEntity";
import { CariOzetView } from "@/api/models/CariOzetView";
import { useCariService } from "@/api/services/CariService";
import NBadge from "@/components/Badge/NBadge.vue";
import NTag from "@/components/Tag/NTag.vue";

export default defineComponent({
  components: { NTag, CariFormDialog, NGrid, NButton, DxDropDownBox, DxList },
  props: {
    id: {
      type: String,
      default: ""
    },
    label: {
      type: String
    },
    fiyatId: {
      type: String,
      default: ""
    },
    firstSelect: {
      type: Boolean,
      default: false
    },
    getUnvan: {
      type: String,
      defaut: ""
    },
    kod: {
      type: String,
      defaut: ""
    },
    hesaplar: {
      type: Array
    },
    isler: {
      type: Array
    },
    isPersonel: {
      type: Boolean,
      default: false
    },
    isPosCihazi: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    grup1: {
      type: String,
      default: ""
    },
    grup2: {
      type: String,
      default: ""
    },
    grup3: {
      type: String,
      default: ""
    },
    grup4: {
      type: String,
      default: ""
    },
    anaGrupSirketId: {
      type: String,
      default: ""
    },
    showBakiye:{
      type:Boolean,
      default:false,
    }

  },
  setup(props, { emit }) {
    const localState = reactive({
      kartListe: ref([] as CariOzetView[]),
      dxListKartListe: ref(),
      placeHolder: props.isPersonel ? "Personel Seçiniz" : "Cari Seçiniz...",
      selectedValue: ref(),
      disabledRef: computed(() => props.disabled),
      dropDown: ref(),
      cariFormDialog: ref(false),
      cariTextField: ref(),
      dxListCariSelectBox: ref(),
      editCariId: ref(""),
      selectedId: computed({
        get: () => props.id,
        set: (val) => {
          //console.log(val);
          //console.log("setlendi", val);
          emit("update:id", val);

          if (val && val.length > 0) {
            const result = localState.kartListe?.find((a: any) => a.id == val);
            //console.log("seçilen cari", result);

            if (result) {

              let cariUnvan = "";
              if (result.ad) cariUnvan += result.ad;
              if (result.soyad) cariUnvan += " " + result.soyad;
              if (result.unvan) cariUnvan += " " + result.unvan;

              emit("update:getUnvan", cariUnvan);
              emit("update:hesaplar", result.hesaplar);
              emit("update:isler", result.isler);
              emit("update:grup1", result.grup1);
              emit("update:grup2", result.grup2);
              emit("update:grup3", result.grup1);
              emit("update:grup4", result.grup4);
              emit("update:anaGrupSirketId", result.grupSirket?.id);
              emit("selectCariFiyatGrup");
              emit("varsayilanDoviz",result.doviz)

              //console.log("cari", result);

              if (result.kod) emit("update:kod", result.kod);

            }

          } else {
            ///
          }
        }
      })

    });

    async function yenile() {
      localState.kartListe = await useCariService().getTumListeOzet(false);
      await load();
    }


    async function load() {
      if (props.isPersonel) {
        localState.kartListe = await useCariService().getTumListeOzet(false);
        const personelList = await usePersonelService().getList() as PersonelEntity[];

        localState.kartListe = localState.kartListe.filter((a: any) => {
            for (let i = 0; i < personelList.length; i++) {
              if (personelList[i].cari?.id == a.id)
                return true;
            }
            return false;

          }
        );

      } else if (props.isPosCihazi == true) {
        const kartListe = await useCariService().getTumListeOzet(false);
        localState.kartListe = kartListe.filter((a: any) => a.posCihaziKullanilacakMi == true);

      } else {
        localState.kartListe = await useCariService().getTumListeOzet(false);
      }


      setTimeout(() => {
        if (props.id && props.id.length > 0) {
          localState.selectedId = props.id;
        }


      }, 100);
      localState.dxListKartListe = Object.assign([], localState.kartListe);
    }

    async function saveCari(cariEntity: CariEntity) {
      await yenile();
      localState.selectedId = cariEntity.id as string;
      emit("save");
    }

    load();

    function duzenle() {
      localState.editCariId = props.id as string;
      localState.cariFormDialog = true;
    }

    watch(() => localState.selectedId, () => {
      const result = localState.kartListe?.find((a: any) => a.id == localState.selectedId);

      if (result) {

        let cariUnvan = "";

        if (result.ad) cariUnvan += result.ad;
        if (result.soyad) cariUnvan += " " + result.soyad;
        if (result.unvan) cariUnvan += " " + result.unvan;


        emit("selectCariFiyatGrup", result.fiyatGrupId);
        emit("update:getUnvan", cariUnvan);
        emit("update:hesaplar", result.hesaplar);
        emit("update:isler", result.isler);
        if (result.kod) emit("update:kod", result.kod);

      } else {
        emit("update:getUnvan", "");
        emit("update:hesaplar", []);
        emit("update:isler", []);
        emit("selectCariFiyatGrup", "");

      }
    });

    function yeniCariAc() {
      (localState.dropDown?.instance as dxDropDownBox).close();
      localState.cariFormDialog = true;
    }

    function itemClick(e: any) {
      (localState.dropDown?.instance as dxDropDownBox).close();
      if (e.itemData && e.itemData.id && e.itemData.id.length > 0)
        localState.selectedId = e.itemData.id;
      else
        localState.selectedId = "";

    }

    function gridBoxDisplayExpr(data: any) {
      let result = "";

      if (data?.unvan && data?.unvan.length > 0) result += data.unvan;
      if (data?.ad && data?.ad.length > 0) result += " " + data.ad;
      if (data?.soyad && data?.soyad.length > 0) result += " " + data.soyad;
      return result;
    }

    watch(() => localState.cariTextField, () => {


      if (localState.cariTextField && localState.cariTextField.length > 0) {

        localState.dxListKartListe = localState.kartListe?.filter((b: any) => {

          const getUnvan = b?.unvan + " " + b?.ad + " " + b?.soyad;
          return (
            getUnvan?.icindeGeciyorMu(localState.cariTextField) ||
            b?.vergiNo?.icindeGeciyorMu(localState.cariTextField) ||
            b?.tcNo?.icindeGeciyorMu(localState.cariTextField) ||
            b?.tel?.icindeGeciyorMu(localState.cariTextField) ||
            b?.tel2?.icindeGeciyorMu(localState.cariTextField) ||
            b?.cep?.icindeGeciyorMu(localState.cariTextField) ||
            b?.cep2?.icindeGeciyorMu(localState.cariTextField)
          );

        });

      } else {
        localState.dxListKartListe = Object.assign([], localState.kartListe);
      }
    });

    return {
      ...toRefs(localState),
      itemClick,

      yenile,
      gridBoxDisplayExpr,
      load,
      yeniCariAc,
      saveCari,
      duzenle
    };
  }
});
