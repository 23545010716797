
import {  AdresTipList } from "@/api/models/AdresTip";
import { CariView } from "@/api/models/CariView";
import { DovizList } from "@/api/models/Doviz";
import { useCariService } from "@/api/services/CariService";
import { computed, defineComponent, onMounted, reactive, ref, toRefs, watch } from "vue";
import { CariUtils } from "../utils/CariUtils";
import { SosyalAglar } from "@/api/models/SosyalAglar";
import NGrid from "@/components/Grid/NGrid.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import { UlkeEntity } from "@/api/models/UlkeEntity";
import { alert, confirm } from "devextreme/ui/dialog";
import { useFileSettingsServiceCache } from "@/api/servicesCache/FileSettingsServiceCache";
import { useGenelCache } from "@/modules/Ortak/services/useGenelCache";

import { useSharedStore } from "@/store";
import { useCariOnlineService } from "@/api/services/CariOnlineService";
import OzellikForm from "@/modules/StokOzellik/components/OzellikForm.vue";
import CariGenelBilgiler from "@/modules/Cari/components/CariForm/CariGenelBilgiler.vue";
import CariFormTanimlar from "@/modules/Cari/components/CariForm/CariFormTanimlar.vue";
import CariFormKvkk from "@/modules/Cari/components/CariForm/CariFormKvkk.vue";
import CariFormDokumanlar from "@/modules/Cari/components/CariForm/CariFormDokumanlar.vue";
import CariFormYetkililer from "@/modules/Cari/components/CariForm/CariFormYetkililer.vue";
import CariFormB2B from "@/modules/Cari/components/CariForm/CariFormB2B.vue";
import CariFormBankalar from "@/modules/Cari/components/CariForm/CariFormBankalar.vue";
import { useAccountService } from "@/api/services/AccountService";
import { useB2BClientAccountService } from "@/api/services/B2BClientAccountService";
import { B2BCariLoginView } from "@/api/models/B2BCariLoginView";
import { SubeEntity } from "@/api/models/SubeEntity";
import { useCachedSubeDepoService } from "@/modules/Sube/services/useCachedSubeDepoService";
import NTag from "@/components/Tag/NTag.vue";

export default defineComponent({
  components: {
      NTag,
    CariFormB2B,
    CariFormDokumanlar,
    CariFormYetkililer,
    CariFormKvkk,
    OzellikForm,
    CariFormBankalar,
    CariFormTanimlar,
    CariGenelBilgiler,
    NLoadingPanel,
    NGrid
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    defaultUnvan: {
      type: String,
      default: ""
    }
  },

  setup(props, { emit }) {
    const localState = reactive({
      showRef: computed({
        get: () => props.show,
        set: val => emit("update:show", val)
      }),
      showAdresFormRef: ref(false),
      selectedTab: ref(0),
      dovizListRef: ref(DovizList),
      sosyalAglarSelectBox: CariUtils.getSosyalAglarList(),
      modelSosyalAglar: ref({} as SosyalAglar),
      tabs: ref([
        { label: "Genel Bilgiler", id: 0 },
        { label: "Tanımlar", id: 1 },
        { label: "Bankalar", id: 2 },
        { label: "Özel Alanlar", id: 3 },
        { label: "KVKK", id: 4 },
        { label: "Yetkililer", id: 5 },
        { label: "Sözleşmeler", id: 6 },
        { label: "B2B Bilgileri", id: 7 }
      ]),
      sosyalAgColumns: ref([
        { label: "#", prop: "icon", width: "10%" },
        { label: "Ağ", prop: "sosyalAg", width: "25%" },
        { label: "Link", prop: "link", width: "55%" },
        { label: "Sil", prop: "delete", width: "10%" }
      ]),
      model: ref({} as CariView),


      serverUrl: ref(""),
      cariUtils: CariUtils,
      pkList: ref([{ id: "--Seçiniz--" }]),
      fiyatOran: ref(0 as number),
      ozellikModel: ref({}),
      adresler: ref([] as UlkeEntity[]),
      bankaBilgileriDataGrid: ref(),
      shared: useSharedStore(),
      onlineUrl: ref(""),
      adresTipList: AdresTipList,
      idRef: computed(() => props.id),
      kategoriler: ref([]),
      subeler:[] as SubeEntity[],


    });
    // const cariIsler = computed({
    //   get: () => localState.model.isler,
    //   set: val => localState.model.isler
    // });
    // const cariHesaplar = computed({
    //   get: () => localState.model.hesaplar,
    //   set: val => localState.model.hesaplar
    // });


    async function load() {
      localState.subeler = await useCachedSubeDepoService().getUserSubeList();
      localState.model = await CariUtils.generateCariView(props.id as string);

      if ( !(props.id && props.id.length > 0) && props.defaultUnvan && props.defaultUnvan.length > 4) {

        localState.model.ad = "";
        localState.model.soyad = "";
        localState.model.tcNo = "11111111111";
        const adSoyad = props.defaultUnvan?.replace("   ", " ").replace("   ", " ").split(" ");


        if (adSoyad && adSoyad.length > 1) {
          localState.model.ad = adSoyad[0];
          for (let i = 1; i < adSoyad.length; i++) {
            localState.model.soyad += adSoyad[i] + " ";
          }

        } else {
          localState.model.ad = props.defaultUnvan;

        }


      }





      await getOnlieCreateLink();

      if (localState.subeler && localState.subeler.length > 0) {
        if (!(localState.model.id && localState.model.id.length > 0)) {
          localState.model.subeler = [];
          localState.model.subeler.push(localState.subeler[0]?.id as string);
        }
      }
    }

    async function loadDefault() {
      localState.serverUrl = await useFileSettingsServiceCache().getServerUrl();
      localState.adresler = await useGenelCache().getUlkeList() as UlkeEntity[];
    }

    loadDefault();


    async function formSave() {
      const cariEntity = await CariUtils.save(localState.model as CariView);
      emit("save", cariEntity);
      localState.showRef = false;
    }


    function formClose() {
      localState.showRef = false;
    }

    async function formDelete() {
      if (localState.model.id && localState.model.id.length > 0) {
        if (await confirm(localState.model?.getUnvan + " carisi silinecektir, Onaylıyor musunuz ? ", "Cari Kartı Silme")) {
          await useCariService().deleteSil(localState.model.id);
          // B2B Account Sil
          const modelB2BLogin: B2BCariLoginView = await useB2BClientAccountService().getGetUser(localState.model.id);
          if (modelB2BLogin &&  modelB2BLogin.id && modelB2BLogin.id.length > 0)
            await useAccountService().deleteUserDelete(modelB2BLogin.id!);

          localState.showRef = false;

        }
      }
    }

    function getSosyalAglarPng(label: string) {
      const result = localState.cariUtils
        .getSosyalAglarList()
        .find(a => a.label == label);
      if (result) {
        return "../sosyalAglar/" + result.icon;
      }
    }

    function sosyalAgSil(row: object, index: number) {
      if (localState.model && localState.model.sosyalAglarList) {
        localState.model.sosyalAglarList.splice(index, 1);
      }
    }

    function sosyalAgFormatter(ag: { icon: string; label: string }) {
      return ag.label;
    }

    function sosyalAgEkle() {
      const liste = localState.modelSosyalAglar as SosyalAglar;
      if (
        liste.link &&
        liste.link.length > 0 &&
        liste.sosyalAg &&
        liste.sosyalAg.length > 0
      ) {
        if (localState.model && localState.model.sosyalAglarList) {
          localState.model.sosyalAglarList.push(liste);
          localState.modelSosyalAglar = {};
        }
      } else {
        alert("(Ağ) ve (Link) alanları boş bırakılamaz !", "Hata");
      }
    }

    async function getOnlieCreateLink() {
      const result = await useCariOnlineService().getCreateLink(localState.model.id as string);
      localState.onlineUrl = location.origin + "/#/Online/Cari/" + result;
    }

    function whatsapUrl() {
      if (localState.model.adresler &&
        localState.model.adresler[0] &&
        localState.model.adresler[0].cep &&
        localState.model.adresler[0].cep.length > 0) {

        let cep = localState.model.adresler[0].cep;
        cep = cep.replace(" ", "");
        cep = cep.replace("(", "");
        cep = cep.replace(")", "");
        if (cep.substring(0, 1) == "0")
          cep = "9" + cep;
        else
          cep = "90" + cep;

        return `https://web.whatsapp.com/send?phone=${cep}&text=${localState.onlineUrl}`;
      } else
        return "CARIDE_CEP_NO_EKSIK";
    }


    return {
      ...toRefs(localState),
      whatsapUrl,
      sosyalAgEkle,
      //ozelAlanEkle,
      formClose,
      formSave,
      formDelete,
      sosyalAgFormatter,
      load,
      getOnlieCreateLink


    };
  }
});
