
import {defineComponent, reactive, toRefs} from "vue";
import {useHizliSatisTabsStore} from "@/modules/HizliSatis/store/HizliSatisTabsStore";
import {confirm} from "devextreme/ui/dialog";

export default defineComponent({
  setup() {
    const localState = reactive({
      tabsStore:useHizliSatisTabsStore()
    })



    async function removeActiveTab() {
      if(await confirm( "<b>Satış " + (localState.tabsStore.selectedIndex as number + 1) + "</b> silmek istiyor musunuz?","UYARI !")){
        localState.tabsStore.removeActiveTab()
      }

    }

    return {
      ...toRefs(localState),
      removeActiveTab
    }
  }
})
