import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {N11WebProductModel} from '../models/N11WebProductModel'

export const useN11ProductService = defineStore({
id: 'N11ProductService',
actions: {


	async getWebProducts(kelime:string)
	{
		const { request, response } = useApi(`/api/N11Product/WebProducts/${kelime}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postWebProduct(n11WebProductModel:N11WebProductModel)
	{
		const { request, response } = useApi(`/api/N11Product/WebProduct`,{ method:'POST', data:n11WebProductModel });
		await request();
		return response.value;
	}
}
});
