
import {computed, defineComponent, onActivated, reactive, ref, toRefs, watch} from "vue";
import NButton from "@/components/Button/NButton.vue";
import NCard from "@/components/Card/NCard.vue";
import NInput from "@/components/Input/NInput.vue";
import {StokFlatView} from "@/api/models/StokFlatView";
import {StokFisView} from "@/api/models/StokFisView";
import {GenelResmiMuhasebeTip, GenelResmiMuhasebeTipList} from "@/api/models/GenelResmiMuhasebeTip";
import NViewGrid from "@/components/ViewGrid/NViewGrid.vue";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";
import DepoSelectBox from "@/modules/Ortak/components/DepoSelectBox.vue";
import {DepoFlatView} from "@/api/models/DepoFlatView";
import {DovizList} from "@/api/models/Doviz";

import {useStokFisService} from "@/api/services/StokFisService";
import {usePopupService} from "@/services/popupService";
import {useLayoutStore} from "@/layouts/ApplicationLayout/context";
import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import {useFisStore} from "@/modules/Fis/routes";
import StokFisFormProp from "@/modules/Cari/models/StokFisFormProp";

import {uuid} from "vue-uuid";
import StokAramaInput from "@/modules/Fis/pages/StokAramaInput.vue";
import {StokUtils} from "@/modules/Stok/utils/StokUtils";
import {FaturaSatirTip} from "@/api/models/FaturaSatirTip";
import {StokFisItem} from "@/api/models/StokFisItem";
import {useStokFlatStore} from "@/modules/Stok/store/StokFlatStore";
import {useEvrakNoService} from "@/api/services/EvrakNoService";
import {StokMenu} from "@/modules/Stok/models/StokMenu";
import StokHareketDialogList from "@/modules/Stok/dialogs/StokHareketDialogList.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import CokluDokuman from "@/modules/Ortak/components/CokluDokuman.vue";
import NTextarea from "@/components/Textarea/NTextarea.vue";
import {ImporterType} from "@/api/models/ImporterType";
import ImporterDialog from "@/modules/Import/dialogs/NImporterDialog.vue";
import {alert, confirm} from "devextreme/ui/dialog";
import {RecordStatus} from "@/api/models/RecordStatus";
import {useCachedSubeDepoService} from "@/modules/Sube/services/useCachedSubeDepoService";
import {StokFlatUtils} from "@/modules/Stok/utils/StokFlatUtils";
import NViewToolbar from "@/components/Toolbar/NToolbar.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NDxStateStoring from "@/devextreme/NDxStateStoring.vue";
import {DevExtremeUtils} from "@/devextreme/DevExtremeUtils";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import NLoading from "@/components/Loading/NLoading.vue";
import {useStokService} from "@/api/services/StokService";
import {StokBakiyeRequest} from "@/api/models/StokBakiyeRequest";
import {StokHareketTip} from "@/api/models/StokHareketTip";
import NDropdownItem from "@/components/Dropdown/NDropdownItem.vue";
import {StokBarkodView} from "@/modules/Stok/models/StokBarkodView";
import StokBarkodBasDialogs from "@/modules/Stok/dialogs/StokBarkodBasDialogs.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import DxDropDownBox from "devextreme-vue/drop-down-box";
import NFormFieldLabelTop from "@/components/FormField/NFormFieldLabelTop.vue";
import NSwitch from "@/components/Switch/NSwitch.vue";

export default defineComponent({
  components: {
    NSwitch,
    NFormFieldLabelTop,
    DxDropDownBox,
    NFormField,
    StokBarkodBasDialogs,
    NDropdownItem,
    NLoading,
    NLoadingPanel,
    NDxStateStoring,
    NGrid,
    NViewToolbar,
    ImporterDialog,
    NTextarea,
    CokluDokuman,
    NFormGrid,
    StokHareketDialogList,
    StokAramaInput
    , DepoSelectBox, NViewGrid, NInput, NCard, NButton
  },
  props: {},
  setup() {
    const localState = reactive({
      menu: ref({} as MenuModel),
      dovizListRef: DovizList,
      depoId: ref(""),
      subeId: ref(""),
      rDepoId: ref(""),
      rSubeId: ref(""),
      depoList: ref([] as DepoFlatView[]),
      model: ref({} as StokFisView),
      selectedTab: ref(0),
      stokFisFormHareketlerDataGrid: ref(),
      genelResmiMuhasebeTipListRef: GenelResmiMuhasebeTipList,
      subeDepolabel: ref("Şube/Depo"),
      referansDepoIdVisible: ref(true),
      stokUtil: StokUtils,
      faturaSatirTip: FaturaSatirTip,
      fisHareketTip: StokHareketTip,
      stokHareketShowDialog: ref(false),
      stokId: ref(""),
      itemId: ref(""),
      stokAdi: ref(""),
      importerTip: ImporterType,
      importerShow: ref(false),
      recordStatus: RecordStatus,
      geciciKayit: ref(true),

      isFisSliniyor: ref(false),

      basilacaklar: ref([] as StokBarkodView[]),
      showBarkodBasDialog: ref(false),


    });

    const depoListFilter = computed(() => localState.depoList.filter(x => x.isBakiye == true));

    async function valueChangedDepoId(e: any) {

      if (e.previousValue && e.previousValue.length > 0) {
        if (await confirm("Satırdaki Depolda Değiştirilsin mi ?", "Genel Depo Değiştirme")) {
          localState.model.hareketler?.forEach((har) => {
            har.depoId = e.value;
          });
        }
      }

    }

    async function valueChangedReferansDepoId(e: any) {

      if (e.previousValue && e.previousValue.length > 0) {
        if (await confirm("Satırdaki Depolda Değiştirilsin mi ?", "Genel Depo Değiştirme")) {
          localState.model.hareketler?.forEach((har) => {
            har.referansDepoId = e.value;
          });
        }
      }

    }

    async function load() {
      const stokFisFormProp = useFisStore().stokFisFormProp as StokFisFormProp;
      //  console.log("stokfisformprop", stokFisFormProp);
      if (stokFisFormProp) {
        localState.menu = stokFisFormProp.menu;
        if (localState.menu.hareketTipi == StokHareketTip.NakilFis)
          localState.subeDepolabel = "Varış Şube/Depo";
        else
          localState.referansDepoIdVisible = false;
      }

      if (stokFisFormProp.id && stokFisFormProp.id.length > 0) {
        localState.model = await useStokFisService().getView(stokFisFormProp.id);
      } else {
        if (stokFisFormProp.model && stokFisFormProp.model.tip)
          localState.model = stokFisFormProp.model;
        else
          localState.model = {
            genelResmiMuhasebeTip: GenelResmiMuhasebeTip.Genel,
            hareketler: [],
            tarih: new Date().toISOString(),
            evrakNo: await useEvrakNoService().getYeniEvrakNo(10),
            dokumanlar: [],
            id: "",
            geciciKayitMi: true,
            tip: localState.menu.hareketTipi,
            isSifirla: false,
            sifirlananDepoIds: [],
          };
      }
      localState.depoList = await useCachedSubeDepoService().getUserDepoList();
      localState.stokFisFormHareketlerDataGrid?.instance?.columnOption("referansDepoId", "visible", localState.referansDepoIdVisible);

      if (localState.model && localState.model.hareketler && localState.model.id && localState.model.id.length > 0) {

        const stokBakiyeRequest: StokBakiyeRequest[] = [];
        localState.model.hareketler.forEach((h) => {
          stokBakiyeRequest.push({
            stokId: h.stokId,
            depoId: h.depoId,
            itemId: h.itemId,
            miktar: 0
          });
        });

        const stokBakiyeResponse = await useStokService().postStokBakiyesi(stokBakiyeRequest);
        if (stokBakiyeResponse != null) {
          localState.model.hareketler.forEach((h: any) => {
            const bakiye = stokBakiyeResponse.find((a: any) => a.stokId == h.stokId && a.itemId == h.itemId && a.depoId == h.depoId);
            if (bakiye == null)
              h.bakiye = 0;
            else
              h.bakiye = bakiye.miktar;
          });
        }
      }

      if (
          localState.model.id
          && localState.model.id.length > 0
          && localState.model.geciciKayitMi == false
          && localState.menu.hareketTipi == StokHareketTip.SayimFis
      )
        await alert("Bu Fişi Sadece Görüntüleyebilirsiniz . Sayım Yapmak İçin Yeni Fiş Açın veya bu fişi silin", "Sayım Fişi Güncelleme");

    }

    async function stokClick(stok: StokFlatView, miktar = 1) {
      const stokMiktari = StokFlatUtils.getKalanMiktar(stok, localState.depoId as string);
      let anaBiirimMaliyet = 0;
      const stokHareketTip = localState.menu.hareketTipi;

      if (
          stokHareketTip == StokHareketTip.DevirFisi ||
          stokHareketTip == StokHareketTip.GirisFisi ||
          stokHareketTip == StokHareketTip.SayimFis
      )
        anaBiirimMaliyet = stok.analiz?.getHesaplananMaliyet as number;
      else
        anaBiirimMaliyet = stok.analiz?.getHesaplananMaliyet as number;

      let barkod = "";
      if (stok && stok.itemId && stok.itemId.length) {
        barkod = stok?.barkodItem as string;
      } else {
        barkod = stok?.barkodStok as string;
      }


      const item: any = {
        id: uuid.v4(),
        barkod: barkod,
        kod: stok.kod,
        miktar: miktar,
        birim: stok.birim,
        carpan: 1,
        stokAd: stok.ad,
        stokId: stok.stokId,
        itemId: stok.itemId,
        depoId: localState.depoId,
        kur: 1,
        doviz: stok.alisDoviz,
        anaBirimMaliyet: anaBiirimMaliyet,
        referansDepoId: localState.rDepoId,
        referansSubeId: localState.rSubeId,
        bakiye: stokMiktari,
        mevcutMiktar: stokMiktari


        //mevcutMiktar : localState.stokUtil.getBakiyeSatir(stok.stokId as string , stok.itemId  as string , FaturaSatirTip.Stok ) ,
      };


      let bulundu = false;
      if (localState.model && localState.model.hareketler && localState.model.hareketler.length > 0) {

        localState.model.hareketler.forEach((har) => {
          if (stok.stokId == har.stokId && stok.itemId == har.itemId && localState.depoId == har.depoId) {
            if (har.miktar) {
              har.miktar += miktar;
              bulundu = true;
              return;
            }
          }
        });
      }

      if (!bulundu)
        localState.model.hareketler!.push(item);

      localState.stokFisFormHareketlerDataGrid?.instance?.refresh();


    }


    watch(() => localState.model.hareketler, async () => {

      if (
          localState.model &&
          localState.model.hareketler!.length > 0 &&
          localState.model.id!.length == 0 ||
          localState.model.geciciKayitMi == true
      ) {

        if (localState.geciciKayit == true) {
          localState.geciciKayit = false;
          localState.geciciKayit = await geciciKayit();
        }


      }
    }, {deep: true});

    async function kaydet() {
      if (localState.model && localState.model.id && localState.model.id.length > 0 && localState.model.geciciKayitMi == false) {
        await useStokFisService().putFisGuncelle(localState.model);
        updateNotifactions();
      } else {

        switch (localState.menu.hareketTipi) {
          case StokHareketTip.Fire :
            await useStokFisService().postFireFisi(localState.model);
            saveNotifactions();
            break;
          case StokHareketTip.DevirFisi :
            await useStokFisService().postDevirFisi(localState.model);
            saveNotifactions();
            break;
          case StokHareketTip.GirisFisi:
            await useStokFisService().postGirisFisi(localState.model);
            saveNotifactions();
            break;
          case StokHareketTip.CikisFisi:
            await useStokFisService().postCikisFisi(localState.model);
            saveNotifactions();
            break;
          case StokHareketTip.NakilFis:
            await useStokFisService().postNakilFis(localState.model);
            saveNotifactions();
            break;
          case StokHareketTip.SayimFis:
            localState.model.geciciKayitMi = false;
            await useStokFisService().postSayimFis(localState.model);
            saveNotifactions();
            break;
        }
      }
    }

    async function hareketSil() {
      if (await confirm("Silmek istiyor musunuz ? ", "Uyarı")) {
        if (localState.model.id && localState.model.id.length > 0) {
          localState.isFisSliniyor = true;
          await useStokFisService().deleteSil(localState.model.id);
          deleteNotifactions();
          localState.isFisSliniyor = false;
        }
      }
    }


    function coklu(stoklar: StokFlatView[]) {
      if (stoklar && stoklar.length > 0) {
        stoklar.forEach((s) => {
          stokClick(s);
        });

      }
    }


    function updateNotifactions() {
      usePopupService().pushWarningNotification({
        title: "Güncelleme",
        body: `${localState.menu.name} Başarı İle Güncellendi`
      });
      useLayoutStore().closeCurrentTab();
    }

    function deleteNotifactions() {
      usePopupService().pushDangerNotification({
        title: "Silindi",
        body: `${localState.menu.name} Silindi`
      });
      useLayoutStore().closeCurrentTab();
    }

    function saveNotifactions() {
      usePopupService().pushSuccessNotification({
        title: "Başarılı",
        body: `${localState.menu.name} Başırı ile Kayıt Edildi`
      });
      useLayoutStore().closeCurrentTab();
    }

    function stokDblClick(stok: StokFlatView) {
      stokClick(stok);
    }

    function tek(stok: StokFlatView) {
      stokClick(stok);
    }

    function delay(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    async function geciciKayit(): Promise<boolean> {
      localState.model.geciciKayitMi = true;
      localState.model.id = await useStokFisService().postSayimFis(localState.model);
      return true;
    }

    function rightMenu(e: any) {

      e.items = [
        {text: "Stoğa Git", onClick: () => StokMenu.stokOpenPage(e?.row?.data), icon: "plus"},
        {
          text: "Stok Hareketleri", onClick: () => {
            const satir = e?.row?.data as StokFisItem;
            if (satir) {

              localState.stokId = satir.stokId as string;
              localState.itemId = satir.itemId as string;
              localState.stokAdi = satir.stokAd as string;
              localState.stokHareketShowDialog = true;
            }
          }, icon: "verticalaligncenter"
        }
      ];

      if (e.row == undefined)
        e.items = undefined;
    }

    function importerClick(data: any) {
      if (data && data.barkod && data.barkod.length > 0) {
        const findStok: StokFlatView = useStokFlatStore().getStoklar((a: any) => a.barkodStok == data.barkod || a.barkodItem == data.barkod || a.kod == data.barkod || a.itemKod == data.barkod, 0, 1)[0];
        if (findStok) {


          if (data && data.alisFiyat && data.alisFiyat > 0) {
            findStok.alisFiyat = data.alisFiyat / (1 + (findStok!.alisKdv as number / 100));
          } else (data && data.alisFiyatKdvHaric && data.alisFiyatKdvHaric > 0);
          findStok.alisFiyat = data.alisFiyat;


          stokClick(findStok, data.sayilan);
        } else {
          alert(data.barkod + " Bulunamadı !", "Stok Sayım Fiş");
        }
      } else {
        alert("Barkod Boş Olamaz !", "Stok Sayım Fiş");
      }

    }

    async function sayilanlariSifirla() {
      if (await confirm("Satırlardaki tüm sayılanlar sıfıra eşitlenecek, devam etmek istiyor musunuz ?", "Uyarı")) {
        localState.model?.hareketler?.forEach((a) => {
          a.miktar = 0;
        });
      }
    }

    async function loadState() {
      return await DevExtremeUtils.loadState(localState.stokFisFormHareketlerDataGrid?.instance);
    }

    async function barkodBas() {
      localState.basilacaklar = [];
      localState.model.hareketler?.forEach((stk) => {
        localState.basilacaklar.push({
          miktar: stk.miktar! * stk.carpan! as number,
          itemId: stk.itemId,
          stokId: stk.stokId as string
        } as StokBarkodView);
      });
      localState.showBarkodBasDialog = true;
    }

    onActivated(() => {
      DevExtremeUtils.updateScroll(localState.stokFisFormHareketlerDataGrid);
    });


    return {
      ...toRefs(localState),
      valueChangedDepoId,
      importerClick,
      rightMenu,
      kaydet,
      hareketSil,
      coklu,
      tek,
      stokDblClick,
      loadState,
      load,
      sayilanlariSifirla,
      valueChangedReferansDepoId,
      barkodBas,
      depoListFilter

    };
  }
});
