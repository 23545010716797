import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NGrid = _resolveComponent("NGrid")!

  return (_openBlock(), _createBlock(_component_NGrid, { cols: "1fr 70px" }, {
    default: _withCtx(() => [
      _createVNode(_component_DxNumberBox, {
        format: _ctx.format,
        value: _ctx.fiyatRef,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fiyatRef) = $event)),
        min: 0,
        "value-change-event": "keyup",
        onValueChanged: _ctx.valueChanged
      }, null, 8, ["format", "value", "onValueChanged"]),
      _createVNode(_component_DxSelectBox, {
        value: _ctx.dovizRef,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dovizRef) = $event)),
        items: _ctx.dovizList,
        valueExpr: "value",
        displayExpr: "description"
      }, null, 8, ["value", "items"])
    ]),
    _: 1
  }))
}