import {useUserSettingsService} from "@/api/services/UserSettingsService";
import {defineStore} from "pinia";

export const useUserSettingsStore = defineStore({
    id: "useUserSettingsStore",
    state: () => ({

    }),
    actions: {
        async saveSettings(key: string, value: string) {
            await useUserSettingsService().postSave({
                name: key,
                value: value
            });
        },
        async getDefaultSettings(key: string) {
            return await useUserSettingsService().getGetValue(key);
        }
    }
})


