
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import {StokEntity} from "@/api/models/StokEntity";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import NCard from "@/components/Card/NCard.vue";
import StokForm_GenelBilgiler from "@/modules/Stok/components/StokForm_GenelBilgiler.vue";
import StokForm_Aciklamalar from "@/modules/Stok/components/StokForm_Aciklamalar.vue";
import OzellikForm from "@/modules/StokOzellik/components/OzellikForm.vue";
import StokForm_Uyumluluklar from "@/modules/Stok/components/StokForm_Uyumluluklar.vue";
import StokForm_Birimler from "@/modules/Stok/components/StokForm_Birimler.vue";
import StokForm_Ureticiler from "@/modules/Stok/components/StokForm_Ureticiler.vue";
import StokForm_Devirler from "@/modules/Stok/components/StokForm_Devirler.vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import NTag from "@/components/Tag/NTag.vue";
import NButton from "@/components/Button/NButton.vue";
import {useFileSettingsServiceCache} from "@/api/servicesCache/FileSettingsServiceCache";
import {useStokStore} from "@/modules/Stok/store/StokStore";
import {useStokService} from "@/api/services/StokService";
import {usedCacheFirmaService} from "@/modules/Ayarlar/Firmalar/services/usedCacheFirmaService";
import {useSubeService} from "@/api/services/SubeService";
import {SubeEntity} from "@/api/models/SubeEntity";
import {MiktarOzetlerView} from "@/api/models/MiktarOzetlerView";
import {StokBarkodView} from "@/modules/Stok/models/StokBarkodView";
import {TrendyolPazaryeriItem} from "@/api/models/TrendyolPazaryeriItem";
import {TrendyolOzellikItem} from "@/api/models/TrendyolOzellikItem";
import {usePopupService} from "@/services/popupService";
import {useLayoutStore} from "@/layouts/ApplicationLayout/context";
import {StokFlatView} from "@/api/models/StokFlatView";
import NGrid from "@/components/Grid/NGrid.vue";
import NViewGrid from "@/components/ViewGrid/NViewGrid.vue";
import NIcon from "@/components/Icon/NIcon.vue";
import {StokUtils} from "@/modules/Stok/utils/StokUtils";
import {StokDevirView} from "@/api/models/StokDevirView";
import {useStokHareketService} from "@/api/services/StokHareketService";
import {StokView} from "@/api/models/StokView";


export default defineComponent({
  components: {
    NTag,
    NButton,
    NModalDialog,
    NLoadingPanel,
    NCard,
    StokForm_GenelBilgiler,
    StokForm_Aciklamalar,
    OzellikForm,
    StokForm_Uyumluluklar,
    StokForm_Birimler,
    StokForm_Ureticiler,
    StokForm_Devirler,

  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    stokId:{
      type:String
    }
  },

  setup(props, {emit}) {
    const localState = reactive({
      model: ref({} as StokView),
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val)
      }),
      subeler: ref([] as SubeEntity[]),
      otomatikBarkod: ref(Date.now().toString()),
      serverUrl: ref(''),
      isClone: ref(false),
      selectedTab: ref(0),
      tabKey: ref(),
      genelTabs: ref([
        {label: 'Genel Bilgiler', id: 0},
        {label: 'Açıklamalar', id: 1},
        {label: 'Özellikler', id: 2},
        {label: 'Uyumluluklar', id: 3},
        {label: 'Birimler', id: 4},
        {label: 'Üreticiler', id: 5},
        {label: 'Devirler', id: 6}
      ]),
      miktarlar: ref({} as MiktarOzetlerView),
      basilacaklar: ref([] as StokBarkodView[]),

    })

    async function load() {
      localState.serverUrl = await useFileSettingsServiceCache().getServerUrl();

      localState.subeler = await useSubeService().getListe();
      if (props.stokId && props.stokId.length > 1)
        localState.miktarlar = await useStokService().getStokMiktarlar(props.stokId);
    }

    loadForm();

    async function loadForm() {

      if (props.stokId && props.stokId.length > 1) {
        localState.model = await useStokService().getGetirView(props.stokId);
      } else { // insert
        localState.model = await useStokService().getDefault();
        const firma = await usedCacheFirmaService().getFirma();
        if (firma) {
          localState.model.birim = firma?.varsayilanBirim;
          localState.model.satisKdv = firma?.varsayilanKdv;
          localState.model.alisKdv = firma?.varsayilanKdv;
        }

      }

    }

    async function save(): Promise<StokFlatView[]> {
      if (localState.model?.trendyol) {
        localState.model.trendyol.forEach((trendyol: TrendyolPazaryeriItem) => {
          if (trendyol.ozellikler) {
            trendyol.ozellikler.forEach((ozellik: TrendyolOzellikItem) => {
              if (ozellik.nameId) {
                ozellik.nameId = parseInt(ozellik.nameId.toString())
              }
              if (ozellik.valueId) {
                ozellik.valueId = parseInt(ozellik.valueId.toString())
              }
            })
          }
        })
      }


      if (!localState.model.barkod) {
        localState.model.barkod = localState.otomatikBarkod;
      } else if (localState.model.barkod.length < 1) {
        localState.model.barkod = localState.otomatikBarkod;
      }

      let stoklar: StokFlatView[]; // stokEntity, stokFlatView
      if (localState.model.id && localState.model.id.length > 0) {
        stoklar = await useStokService().putGuncelle(localState.model);
        showMessage("Stok Güncellendi: " + localState.model?.ad)
      } else {
        stoklar = await useStokService().postEkle(localState.model);
        await saveStokDevir(stoklar[0].id as string)
        showMessage("Stok Eklendi: " + localState.model?.ad)

      }
      return stoklar;
    }

    // async function saveWait() {
    //   const stoklar = await save()
    //   localState.model.id = stoklar[0].id;
    //
    // }

    // async function saveClose() {
    //   await save();
    //   localState.showRef = false;
    // }

    // async function saveNew() {
    //   await save();
    //   localState.model = await useStokService().getDefault();
    // }

    async function saveAdd() {
      const stoklar = await save();
      emit("stokEntityAdded", stoklar);
      localState.showRef = false;
    }

    function showMessage(message: string) {
      usePopupService().pushSuccessNotification({
        body: message
      })
    }

      async function saveStokDevir(stokId: string) {
          if (localState.model.devirMiktar && localState.model.devirMiktar > 0) {
              const stokDevirView: StokDevirView = {};
              stokDevirView.devirler = [{
                  miktar: localState.model.devirMiktar,
                  depoId: localState.model.devirDepoId,
                  stokId: stokId
              }];
              stokDevirView.tarih = new Date().toISOString();
              await useStokHareketService().postIlkDevir(stokDevirView);
          }
      }

    return {
      ...toRefs(localState),
      load,
      save,
      saveAdd,

    }
  }
})
