import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {TemplateView} from '../models/TemplateView'

export const useTemplateService = defineStore({
id: 'TemplateService',
actions: {


	async getGetFields()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Template/GetFields/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getSablonGetir(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Template/SablonGetir/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getSablonListesi()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Template/SablonListesi/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getIdNameList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Template/IdNameList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postSablonEkle(templateView:TemplateView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Template/SablonEkle/${shared.firm.id}`,{ method:'POST', data:templateView });
		await request();
		return response.value;
	},

	async putSablonGuncelle(templateView:TemplateView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Template/SablonGuncelle/${shared.firm.id}`,{ method:'PUT', data:templateView });
		await request();
		return response.value;
	},

	async deleteSablonSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Template/SablonSil/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	}
}
});
