

import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NMultipleImageUploader from "@/components/Uploader/NMultipleImageUploader.vue";
import { useFileSettingsService } from "@/api/services/FileSettingsService";
import BirimlerSelectBox from "@/modules/Ortak/components/BirimlerSelectBox.vue";
import NDropDownTreeView from "@/components/DropDownTreeView/NDropDownTreeView.vue";
import { StokKategoriTreeListView } from "@/api/models/StokKategoriTreeListView";
import { StokUtils } from "@/modules/Stok/utils/StokUtils";
import { StokView } from "@/api/models/StokView";
import DovizFiyatItem from "@/modules/Ortak/components/DovizFiyatItem.vue";
import NInput from "@/components/Input/NInput.vue";
import { useStokKategoriService } from "@/api/services/StokKategoriService";
import { StokGrupView } from "@/api/models/StokGrupView";
import { StokGrupItem } from "@/api/models/StokGrupItem";
import _ from "lodash";
import NRefresher from "@/components/Refresher/NRefresher.vue";

export default defineComponent({
  components: {NRefresher, NInput, NDropDownTreeView, BirimlerSelectBox, NMultipleImageUploader, NFormField },
  props: {
    modelValue: {
      type: Object
    },
    serverUrl:{
      type:String
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      model: computed(() => props.modelValue as StokGrupView),
      selectedItem:ref({id:'',name:''}),
      kategoriler: ref([] as StokKategoriTreeListView[]),
    });

    if(localState.model.kategori)
      localState.model.kategori = {}


    async function load() {
      // localState.serverUrl = await useFileSettingsService().getServerUrl();
      localState.kategoriler = await useStokKategoriService().getTreeList();
    }

    function selectedChanged(kategori:StokKategoriTreeListView){

      const stok = localState.model as StokView;
      StokUtils.fillStokFromKategori(stok,kategori)
      //  console.log("kategori",kategori);
    }

    function getSatisFiyati(){
      if (localState.model.stoklar && localState.model.stoklar.length > 0)
      return _.sum(localState.model.stoklar.map((a:StokGrupItem)=> a.fiyat! * a.miktar!)).format(2,2)
      return  0
    }

     load();

    return {
      load,
      ...toRefs(localState),
      selectedChanged,
      getSatisFiyati
    };
  }
});
