
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import {usePazaramaCategoryService} from "@/api/services/PazaramaCategoryService";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NDropDownListBox from "@/components/Dropdown/NDropDownListBox.vue";
import {StokEntity} from "@/api/models/StokEntity";
import {PazaramaOzellikItem} from "@/api/models/PazaramaOzellikItem";
import _ from "lodash";
import {IdName} from "@/api/models/IdName";
import {PazaramaCategoryAttribute} from "@/api/models/PazaramaCategoryAttribute";
import NGrid from "@/components/Grid/NGrid.vue";
import PazaramaEslestirmeDialog from "@/modules/Pazaryerleri/Pazarama/components/PazaramaEslestirmeDialog.vue";

export default defineComponent({
  components: {PazaramaEslestirmeDialog, NGrid, NDropDownListBox, NFormField, NFormGrid, NLoadingPanel},
  props: {
    ozellikler: {
      type: Array
    },
    kategoriId: {
      type: String
    },
    cols: {
      type: String,
      default: '1fr 1fr 1fr'
    },
    stok: {
      type: Object
    }
  },

  setup(props, {emit}) {
    const localState = reactive({
      loadingPanel: ref(),
      pazaramaOzellikler: ref([] as PazaramaCategoryAttribute[]),
      showEslestir: ref(false),
      selectedPazaramaValues: ref(),
      selectedNesilceValues: ref(),
      selectedPropertyName: ref(),
      stokRef: computed(() => props.stok as StokEntity),
      nesilceOzellikler: computed({
        get: () => props.ozellikler as PazaramaOzellikItem[],
        set: (val) => emit('update:ozellikler', val)
      }),
      text: ref('REF')
    })

    watch(() => props.kategoriId, (kategoriId) => {
      localState.loadingPanel.load();
    })

    async function load() {
      const kategoriId = props.kategoriId as string;
      if (kategoriId) {
        // Load Özellikler
        localState.pazaramaOzellikler = await usePazaramaCategoryService().getGetCategoryAttributes(kategoriId);
        localState.pazaramaOzellikler = _.orderBy(localState.pazaramaOzellikler, ['isRequired', 'name'], ['desc', 'asc'])
        //console.log("pazaramaOzellikler", localState.pazaramaOzellikler)
        nesilceToPazarama();

      }
    }

    function nesilceToPazarama() {

      //console.log(localState.pazaramaOzellikler) // özellik array isRequired, id, name, attrs vs
      //console.log("nesilceOzellikler", localState.nesilceOzellikler) // id, value = selectedvalue


      if (localState.nesilceOzellikler) {
        // selected id,value array
        localState.nesilceOzellikler.forEach(ozellik => { // (id = aeb2323-feb2521-acb45-ecda023, value = "Bağlantı Tipi")

          const pazaramaOzellik = localState.pazaramaOzellikler.find((a: any) => a.id == ozellik.id) as any;

          //console.log("pazaramaOzellik:", pazaramaOzellik, "özellik id:", ozellik.id)
          if (pazaramaOzellik) {
            pazaramaOzellik.value = ozellik.value;
          }
        })

      } // if
    }


    function pazaramaToNesilce() {
      const nesilceOzellikler = [] as any[];

      if (localState.pazaramaOzellikler) {
        localState.pazaramaOzellikler.forEach((attribute: any) => {
          if (attribute.attributeValues)
            nesilceOzellikler.push({
              id: attribute.id,
              value: attribute.value,
              name: attribute.name
            })
        })
      }

      localState.nesilceOzellikler = nesilceOzellikler;
    }

    function eslestir(propertyName: string, pazaramaPropertyValues: IdName[]) {
      localState.selectedPropertyName = propertyName;

      localState.selectedNesilceValues = getPropertyValues(propertyName);

      localState.selectedPazaramaValues = pazaramaPropertyValues;
      localState.showEslestir = true;
    }

    function getPropertyValues(propertyName: string) {
      // find variant propery index

      const variantIndex = localState.stokRef.varyantlar?.findIndex(a => a.name == propertyName || a.namePazarama == propertyName) as number;
      if (variantIndex >= 0) {
        const variantValues = localState.stokRef.items?.map(a => (a.varyantDegerleri ? a.varyantDegerleri[variantIndex] : ''))
        const variantValuesDistinc = _.uniq(variantValues);
        return variantValuesDistinc;
      }


      return [];
    }

    // function getPropertyValueId(pazaramaAttributeValues: PazaramaOzellikItem[], propertyValue: string) {
    //   const valueIdName = pazaramaAttributeValues.find(a => a.value == propertyValue);
    //   if (valueIdName)
    //     return valueIdName.id;
    //   return "";
    // }


    return {
      ...toRefs(localState),
      load,
      pazaramaToNesilce,
      getPropertyValues,
      eslestir
    }
  }
})
