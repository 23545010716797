
export enum ListeTip{
Birim=1,
FiyatGrup=2,
GiderYeri=3,
EarsivSeri=4,
EFaturaSeri=5,
EirsaliyeSeri=6,
BirimFiyatGrup=7,
UretimTanim=8,
CariHesap=9,
StokTipOzellik=10
}

export const ListeTipList = [
{key:'Birim', value:1, description:'Birim'},
{key:'FiyatGrup', value:2, description:'Fiyat Grup'},
{key:'GiderYeri', value:3, description:'Gider Yeri'},
{key:'EarsivSeri', value:4, description:'E-Arşiv Seri'},
{key:'EFaturaSeri', value:5, description:'E-Fatura Seri'},
{key:'EirsaliyeSeri', value:6, description:'E-Irsaliye Seri'},
{key:'BirimFiyatGrup', value:7, description:'Birim Fiyat Grubu'},
{key:'UretimTanim', value:8, description:'Üretim Tanımlar'},
{key:'CariHesap', value:9, description:'Cari Hesap Tanımları'},
{key:'StokTipOzellik', value:10, description:'Stok Tip Özellik Tanımları'}
]
