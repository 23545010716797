import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aa39151e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "n-input-label"
}
const _hoisted_2 = { class: "n-input-prefix-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "n-input-prefix"
}
const _hoisted_4 = {
  key: 0,
  class: "n-input-icon-wrapper"
}
const _hoisted_5 = ["autocomplete", "name", "type", "maxlength", "value", "placeholder", "disabled", "list"]
const _hoisted_6 = {
  key: 2,
  class: "n-input-icon-wrapper"
}
const _hoisted_7 = {
  key: 1,
  class: "n-input-suffix"
}
const _hoisted_8 = ["id"]
const _hoisted_9 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NTag = _resolveComponent("NTag")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.labelTop)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.labelTop), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.prefix)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.prefix), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["n-input-wrapper", [
          _ctx.status && `n-input-wrapper--${_ctx.status}`,
          _ctx.disabled && `n-input-wrapper--disabled`,
          _ctx.isBig && `big`
        ]])
      }, [
        (_ctx.prefixIcon)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
              _createVNode(_component_NIcon, {
                class: "n-input-icon",
                type: _ctx.prefixIcon,
                size: "18"
              }, null, 8, ["type"])
            ]))
          : _createCommentVNode("", true),
        _withDirectives(_createElementVNode("input", _mergeProps({
          autocomplete: _ctx.autoComplete,
          ref: "textBox"
        }, _ctx.$attrs, {
          class: "n-input",
          name: _ctx.fieldName,
          type: _ctx.type,
          maxlength: _ctx.maxlength,
          value: _ctx.modelValue,
          placeholder: _ctx.placeholder,
          disabled: _ctx.disabled,
          list: 
            _ctx.dataset && _ctx.dataset.length ? `datalist-${_ctx.fieldName}` : undefined
          ,
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.input && _ctx.input(...args))),
          onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInputFocused && _ctx.onInputFocused(...args))),
          onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
          onMaska: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onMaska && _ctx.onMaska(...args))),
          onKeypress: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onKeyDown && _ctx.onKeyDown(...args)))
        }), null, 16, _hoisted_5), [
          [_directive_maska, _ctx.mask]
        ]),
        (_ctx.showCounter && _ctx.maxlength > 0)
          ? (_openBlock(), _createBlock(_component_NTag, { key: 1 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.modelValue?_ctx.modelValue.length:0) + "/" + _toDisplayString(_ctx.maxlength), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.suffixIcon)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
              _createVNode(_component_NIcon, {
                class: "n-input-icon",
                type: _ctx.suffixIcon,
                size: "18"
              }, null, 8, ["type"])
            ]))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.suffix)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.suffix), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.dataset && _ctx.dataset.length)
      ? (_openBlock(), _createElementBlock("datalist", {
          key: 1,
          id: `datalist-${_ctx.fieldName}`
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataset, (row, i) => {
            return (_openBlock(), _createElementBlock("option", {
              value: _ctx.datasetFormatter ? _ctx.datasetFormatter(row) : row,
              key: i
            }, _toDisplayString(_ctx.datasetFormatter ? _ctx.datasetFormatter(row) : row), 9, _hoisted_9))
          }), 128))
        ], 8, _hoisted_8))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "fade-bottom" }, {
      default: _withCtx(() => [
        (_ctx.message)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["n-input-message", [
          _ctx.status && `n-input-message--${_ctx.status}`,
          _ctx.disabled && `n-input-message--disabled`
        ]])
            }, _toDisplayString(_ctx.message), 3))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}