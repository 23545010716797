
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import CariFormAdresler from "@/modules/Cari/components/CariForm/CariFormAdresler.vue";
import {useCariService} from "@/api/services/CariService";
import {useCariKategoriService} from "@/api/services/CariKategoriService";
import {KurumTip, KurumTipList} from "@/api/models/KurumTip";
import {CariTipList} from "@/api/models/CariTip";
import {CariKategoriTreeListView} from "@/api/models/CariKategoriTreeListView";
import {CariView} from "@/api/models/CariView";
import {CariUtils} from "@/modules/Cari/utils/CariUtils";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";
import NDropDownTreeView from "@/components/DropDownTreeView/NDropDownTreeView.vue";
import NImageUploader from "@/components/Uploader/NImageUploader.vue";
import {DxTextBox} from "devextreme-vue";
import NGrid from "@/components/Grid/NGrid.vue";

export default defineComponent({
  components: {NGrid, NImageUploader, CariFormAdresler, NDropDownTreeView, NSelectBox, DxTextBox},
  props:['modelValue','serverUrl','adresler'],
  setup(props) {
    const localState = reactive({
      model:computed(()=> props.modelValue as CariView),
      kategoriler:ref([]),
      adreslerRef:computed(()=>props.adresler),
      kurumTipListRef: ref(KurumTipList),
      cariTipListRef: ref(CariTipList),
      kodOnEk : ref("NC-"),
      cariNo:ref(''),
      disableStokKodu: ref(true),

    })

    load()

    async function load(){
      if (!(localState.model && localState.model.id && localState.model.id.length > 0)){
        localState.disableStokKodu = false;
        localState.cariNo = await useCariService().getCariKod();
        localState.model.kod = localState.kodOnEk + localState.cariNo ;
      }

    }

    watch(
        () => localState.model.kurumTipi,
        val => {
          if (val === KurumTip.Sahis) {
            if (localState.model.kurumTipi === KurumTip.Sahis) {
              if (localState.model.tcNo) {
                if (localState.model.tcNo.length < 10)
                  localState.model.tcNo = "11111111111";
              } else {
                localState.model.tcNo = "11111111111";
              }
            }
          }
        }
    );

    watch(() => localState.model.kategori, (deger) => {
      if (deger && deger.id && deger.id?.length > 0) {
        if (!(localState.model.id && localState.model.id?.length > 0)) {
          kategoridenKodOlustur();
        }
      }
    })

    // Kategoriler
    async function loadKategoriler() {
      localState.kategoriler = await useCariKategoriService().getTreeList();
    }
    loadKategoriler();

    // Gruplar



    function selectedChanged(kategori: CariKategoriTreeListView) {

      const cari = localState.model as CariView;
      // if (kategori.kodOnEk && localState.kodOnEk.length >  0 ){
      //   localState.model.kod = localState.model.kod?.replace(localState.kodOnEk , kategori.kodOnEk );
      //   localState.kodOnEk = kategori.kodOnEk;
      //
      // }
      CariUtils.fillCariFromKategori(cari, kategori)


    }

    async function kategoridenKodOlustur() {

      if (localState.model.kategori && localState.model.kategori?.id && localState.model.kategori?.id.length > 0) {
          let kategoriKodu = await useCariKategoriService().getGetAllKategoriKod(localState.model.kategori.id) as string
          const yeniKategoriNo = await useCariService().getYeniCariKategoriNo(localState.model.kategori.id) as number

          localState.model.kategoriNo = yeniKategoriNo;
          kategoriKodu = String(kategoriKodu)

          if (kategoriKodu && kategoriKodu?.length > 0 && kategoriKodu.trim().length > 0)
            localState.model.kod = kategoriKodu + String(yeniKategoriNo).padStart(3, '0');
          else
            localState.model.kod = "NC-" + localState.cariNo;

      }
    }


    return {
      ...toRefs(localState),
      loadKategoriler,
      selectedChanged
    }
  }
})
