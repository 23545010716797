
export enum KurumTip{
Tanimsiz=0,
Mukellef=1,
Sahis=2,
Bos=-1
}

export const KurumTipList = [
{key:'Tanimsiz', value:0, description:'Tanımsız'},
{key:'Mukellef', value:1, description:'Mükellef'},
{key:'Sahis', value:2, description:'Şahıs'},
{key:'Bos', value:-1, description:'Boş'}
]
