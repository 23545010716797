
import {computed, defineComponent, onMounted, reactive, ref, toRefs, watch} from "vue";
import dxDropDownBox from "devextreme/ui/drop_down_box";


export default defineComponent({
  props: {
    showClearButton: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array
    },
    selectedItem: {
      type: Object
    },
    selectedValue: {
      type: Object
    },
    displayExpr: {
      type: String,
      default: 'name'
    },
    valueExpr: {
      type: String,
      default: 'id'
    },
    text: {
      type: String
    },
    acceptCustomValue: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    disabled:{
      type:Boolean,
      default : false,
    },
    searchMode:{
      type:String,
      default:'contains'
    }
  },
  setup(props, {emit}) {

    const localState = reactive({
      dropDown: ref(),
      selectedItems: ref([] as any),
      showClearButtonRef:computed({
        get : () => props.showClearButton,
        set : (val) => emit('update:showClearButton'),
      }),
      selectedItemRef: computed({
        get: () => props.selectedItem,
        set: ((val) => {
                emit('update:selectedItem', val);
                emit('selectedChanged', val);

                // Value update if valueExpr
                if (props.valueExpr && props.valueExpr.length > 0 && val){
                  const value = val[props.valueExpr];
                  emit('update:selectedValue', value);
                  emit('change',value);
                  updateBridge(value)
                }else{
                  emit('update:selectedValue', val);
                  emit('change',val);
                  updateBridge(val)
                }
            })
      }),
      disabledRef:computed(() => props.disabled),
      selectedValueRef:computed({
          get:() => props.selectedValue,
          set:(val)=> emit('update:selectedValue',val)
        }),
      textRef:computed({
        get:()=> props.text,
        set:((val)=> {
          emit('update:text', val);
          emit('change',val);
        })
      }),
      value:ref(),
      label:ref()
    });
    function updateBridge(value:any){
      localState.value =value;
    }

    function load(){ // Set text if props.text exist
//debugger;
      // Set value

      if (localState.selectedItemRef && localState.selectedItemRef[props.valueExpr]){

        localState.value = localState.selectedItemRef[props.valueExpr];
        localState.label = localState.selectedItemRef[props.displayExpr];
        localState.selectedValueRef = localState.value;

      }
      else if (!localState.selectedValueRef && props.text)
      {
        localState.selectedValueRef = props.text as any;
        localState.value = props.text;
        localState.label = props.text;
      }
      else
      {
        localState.value = localState.selectedValueRef;

        const findLabel = props.items?.find((a:any) => a[props.valueExpr] == localState.value) as any;
        if (findLabel)
          localState.label = findLabel[props.displayExpr];
      }



      // Set item

    }


    load();





    function itemClick(e:any) {
      (localState.dropDown?.instance as dxDropDownBox).close();
      // 1. Set Selected Item
      localState.selectedItemRef = e.itemData;
      if (props.displayExpr && e.itemData){
        localState.label = e.itemData[props.displayExpr]
      }else{
        localState.label = e.itemData;
      }
    }

    function valueChanged(e:any) {
      // 3. Set Custom Text
      if (e.event?.type=="change"){ // custom input
        localState.textRef = e.value;
      }else{ // item click
        //localState.textRef = undefined;
      }
    }



    function getInputAttr() {
      if (props.acceptCustomValue){
        return undefined;
      }
      return {readonly:false}
    }


    return {
      ...toRefs(localState),
      itemClick,
      valueChanged,
      getInputAttr
    };
  }
});

