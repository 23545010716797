<template>
  <div class="n-view-grid-container" v-if="tab" id="view-container">
    <KeepAlive :max="10">
      <component  :is="tab.id" :key="tab.key"  />
    </KeepAlive>
  </div>

  <TabNotSelected v-else/>


</template>

<script>
import { useLayoutStore } from "@/layouts/ApplicationLayout/context";
import {computed, defineComponent, onBeforeUnmount , onMounted, onUnmounted, ref, VueElement} from "vue";
import TabNotSelected from "./TabNotSelected.vue";

export default defineComponent({
  components: {
    TabNotSelected
  },
  setup() {
    const layout = useLayoutStore();
    const tab = computed(() => layout.selectedTab);


    onMounted(()=>{
      layout.tabClosed = (tab)=> {
      //
      }
    })

    return {
      tab
    };
  }


});
</script>

<style lang="scss" scoped>
.n-view-grid-container {
  padding: 10px 5px 10px 15px;
  width: 100%;
  height: 100%;
}
</style>
