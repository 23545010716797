
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import NCard from "@/components/Card/NCard.vue";


import N11KategoriForm from "@/modules/Pazaryerleri/N11/components/N11KategoriForm.vue";
import TrendyolKategoriForm from "@/modules/Pazaryerleri/Trendyol/components/TrendyolKategoriForm.vue";
import { StokKategoriView } from "@/api/models/StokKategoriView";
import HepsiBuradaKategoriForm from "@/modules/Pazaryerleri/HepsiBurada/components/HepsiBuradaKategoriForm.vue";
import HepsiBuradaListingForm from "@/modules/Pazaryerleri/HepsiBurada/components/HepsiBuradaListingForm.vue";
import HepsiBuradaListingKategoriForm
  from "@/modules/Pazaryerleri/HepsiBurada/components/HepsiBuradaListingKategoriForm.vue";
import CicekSepetiKategoriForm from "@/modules/Pazaryerleri/CicekSepeti/components/CicekSepetiKategoriForm.vue";
import PrestaKategoriForm from "@/modules/Pazaryerleri/Presta/pages/PrestaKategoriForm.vue";
import {PrestaConfigEntity} from "@/api/models/PrestaConfigEntity";
import {usePrestaConfigService} from "@/api/services/PrestaConfigService";
import EpttAvmKategoriForm from "@/modules/Pazaryerleri/EpttAvm/components/EpttAvmKategoriForm.vue";
import PazaramaKategoriForm from "@/modules/Pazaryerleri/Pazarama/components/PazaramaKategoriForm.vue";


export default defineComponent({
  components: {
    PazaramaKategoriForm,
    EpttAvmKategoriForm,
    PrestaKategoriForm,
    CicekSepetiKategoriForm,  HepsiBuradaListingKategoriForm, HepsiBuradaKategoriForm, TrendyolKategoriForm, N11KategoriForm,  NCard},
  props: {
    kategori:{
      type:Object
    }
  },
  setup(props) {

    const localState = reactive({
      kategoriRef:computed(()=> props.kategori as StokKategoriView),
      selectedTab:ref(0),
      shipmentTemplates:ref([] as string[]),
      configs:ref([] as PrestaConfigEntity)
    });

    async function load() {
      localState.configs = await usePrestaConfigService().getPrestaAyarGetir()
    }

    load()

    function getTabs() {
      const tabs =  [];
      if (localState.kategoriRef && localState.kategoriRef.ozellik){


          const n11Tabs = localState.kategoriRef.ozellik?.n11?.map((a,i) => ({ label:'N11' ,id:(tabs.length + i) } as any));
        if (n11Tabs)
          tabs.push(...n11Tabs);



        const trendyolTabs = localState.kategoriRef?.ozellik?.trendyol?.map((a,i) => ({ label:'Trendyol' , id:(tabs.length + i) } as any));

        if (trendyolTabs)
          tabs.push(...trendyolTabs);


        const hbTabsListingTabs = localState.kategoriRef?.ozellik?.hepsiBuradaListing?.map((a,i) => ({ label:'HB Listeleme' , id:(tabs.length + i) } as any));
        if (hbTabsListingTabs)
          tabs.push(...hbTabsListingTabs);

        const hbTabsCatalogTabs = localState.kategoriRef?.ozellik?.hepsiBurada?.map((a,i) => ({ label:'HB Katalog' , id:(tabs.length + i) } as any));
        if (hbTabsCatalogTabs)
          tabs.push(...hbTabsCatalogTabs);

        const prestaTabsCatalogTabs = localState.kategoriRef?.ozellik?.presta?.map((a,i) => ({ label:'E-Ticaret' , id:(tabs.length + i) } as any));

        if (prestaTabsCatalogTabs)
          tabs.push(...prestaTabsCatalogTabs);

        const cicekSepetiTabsCatalogTabs = localState.kategoriRef?.ozellik?.cicekSepeti?.map((a,i) => ({ label:'ÇiçekSepeti' , id:(tabs.length + i) } as any));

        if (cicekSepetiTabsCatalogTabs)
          tabs.push(...cicekSepetiTabsCatalogTabs);


        const epttAvmTabsCatalogTabs = localState.kategoriRef?.ozellik?.epttAvm?.map((a,i) => ({ label:'Eptt Avm' , id:(tabs.length + i) } as any));

        if (epttAvmTabsCatalogTabs)
          tabs.push(...epttAvmTabsCatalogTabs);


        const pazaramaTabsCatalogTabs = localState.kategoriRef?.ozellik?.pazarama?.map((a,i) => ({ label:'Pazarama' , id:(tabs.length + i) } as any));
        if (pazaramaTabsCatalogTabs)
          tabs.push(...pazaramaTabsCatalogTabs);



      }
      return tabs;

    }


    return {
      ...toRefs(localState),
      getTabs
    };
  }
});

