import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createBlock as _createBlock, vShow as _vShow, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-459407c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "n-alert-iconbox",
  style: {"height":"20px","margin-top":"2px"}
}
const _hoisted_2 = { class: "n-alert-title" }
const _hoisted_3 = {
  key: 0,
  class: "n-alert-slot"
}
const _hoisted_4 = {
  key: 1,
  class: "n-alert-slot"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        class: _normalizeClass([
        'n-alert',
        `n-alert--${_ctx.type}`,
        !_ctx.body && !_ctx.$slots.default && 'n-alert--small'
      ]),
        style: _normalizeStyle({padding:_ctx.padding})
      }, [
        (_ctx.showIcon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_NIcon, {
                class: "n-alert-icon",
                type: _ctx.typeIcon[`${_ctx.type}`]
              }, null, 8, ["type"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.title), 1),
          (!_ctx.$slots.default && _ctx.body)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.body), 1))
            : _createCommentVNode("", true),
          (_ctx.$slots.default)
            ? (_openBlock(), _createElementBlock("p", _hoisted_4, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.closable)
          ? (_openBlock(), _createBlock(_component_NIcon, {
              key: 1,
              type: "x",
              size: "18px",
              class: "n-alert-close",
              onClick: _ctx.clickCross
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ], 6), [
        [_vShow, _ctx.visible]
      ])
    ]),
    _: 3
  }))
}