import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"color":"#C00","margin-left":"4px"}
}
const _hoisted_2 = {
  key: 1,
  style: {"color":"#00C","margin-left":"4px"}
}
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NDropDownListBox = _resolveComponent("NDropDownListBox")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!
  const _component_CicekSepetiEslestirmeDialog = _resolveComponent("CicekSepetiEslestirmeDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NLoadingPanel, {
      ref: "loadingPanel",
      action: _ctx.load,
      title: "CicekSepeti özellikleri yükleniyor..."
    }, {
      default: _withCtx(() => [
        _createVNode(_component_NFormGrid, { cols: _ctx.cols }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cicekSepetiOzellikler, (attribute, i) => {
              return (_openBlock(), _createBlock(_component_NFormField, {
                "grid-cols": "100px 1fr",
                label: attribute.attributeName + ' (' + attribute.attributeValues.length + ')',
                key: i
              }, {
                label: _withCtx(() => [
                  _createElementVNode("div", null, [
                    (attribute.required)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
                      : _createCommentVNode("", true),
                    (attribute.varianter)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "V"))
                      : _createCommentVNode("", true)
                  ])
                ]),
                default: _withCtx(() => [
                  (_ctx.stokRef.varyantlar?.find(a => a.name===attribute.attributeName || a.nameCicekSepeti === attribute.attributeName))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("span", null, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getPropertyValues(attribute.attribute?.name), (value, i) => {
                            return (_openBlock(), _createElementBlock("span", {
                              style: {"display":"inline-block","margin-right":"4px"},
                              type: "warning",
                              key: i
                            }))
                          }), 128)),
                          _createVNode(_component_NTag, {
                            type: "info",
                            filled: true,
                            style: {"font-weight":"100","cursor":"pointer"},
                            onClick: ($event: any) => (_ctx.eslestir(attribute.attributeName,attribute.attributeValues)),
                            title: "ÇiçekSepeti seçenekleri ile eşleşmeyen seçenekleri eşletirmek için tıklayınız."
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Seçenekleri Eşleştir")
                            ]),
                            _: 2
                          }, 1032, ["onClick"])
                        ])
                      ]))
                    : (_openBlock(), _createBlock(_component_NGrid, {
                        key: 1,
                        cols: "auto auto",
                        gap: "2px"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_NDropDownListBox, {
                            "accept-custom-value": false,
                            onChange: _ctx.cicekSepetiToNesilce,
                            "selected-value": attribute.valueId,
                            "onUpdate:selectedValue": ($event: any) => ((attribute.valueId) = $event),
                            "value-expr": "id",
                            "display-expr": "name",
                            items: attribute.attributeValues
                          }, null, 8, ["onChange", "selected-value", "onUpdate:selectedValue", "items"]),
                          (attribute.type === 'Dinamik Özellik')
                            ? (_openBlock(), _createBlock(_component_DxNumberBox, {
                                key: 0,
                                value: attribute.textLength,
                                "onUpdate:value": ($event: any) => ((attribute.textLength) = $event),
                                min: 0,
                                onValueChanged: _ctx.cicekSepetiToNesilce,
                                width: "50"
                              }, null, 8, ["value", "onUpdate:value", "onValueChanged"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1024))
                ]),
                _: 2
              }, 1032, ["label"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["cols"])
      ]),
      _: 1
    }, 8, ["action"]),
    (_ctx.showEslestir)
      ? (_openBlock(), _createBlock(_component_CicekSepetiEslestirmeDialog, {
          key: 0,
          show: _ctx.showEslestir,
          "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showEslestir) = $event)),
          "cicek-sepeti-values": _ctx.selectedCicekSepetiValues,
          "onUpdate:cicekSepetiValues": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCicekSepetiValues) = $event)),
          "kategori-id": _ctx.kategoriId,
          "nesilce-values": _ctx.selectedNesilceValues,
          "onUpdate:nesilceValues": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedNesilceValues) = $event)),
          "property-name": _ctx.selectedPropertyName,
          "onUpdate:propertyName": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedPropertyName) = $event))
        }, null, 8, ["show", "cicek-sepeti-values", "kategori-id", "nesilce-values", "property-name"]))
      : _createCommentVNode("", true)
  ], 64))
}