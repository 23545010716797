
import {defineComponent, reactive, ref, toRefs} from "vue";
import router from "@/router";
import NCard from "@/components/Card/NCard.vue";
import {GenelUtils} from "@/modules/Ortak/utils/GenelUtils";
import {useCariMutabakatService} from "@/api/services/CariMutabakatService";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import NViewGrid from "@/components/ViewGrid/NViewGrid.vue";

export default defineComponent({
  components: {NCard, NLoadingPanel, NViewGrid},
  props: {},

  setup() {
    const localState = reactive({
      key: ref(''),
      message: ref(''),
      durum: ref(false),
    })


    async function load() {

      localState.key = router.resolve("").params?.key as string;
      localState.durum = await useCariMutabakatService().getBaBsGetMutabakatDurum(localState.key) as boolean;
      if (localState.durum) // daha önce cevaplanmış
      {
        localState.message = "Daha önce seçim yaptınız. bu sayfayı kapatabilirsiniz.";
      } else {
        setTimeout(async () => {
          if (localState.key && localState.key.length > 0) {
            if (await GenelUtils.onayDiyalogu("Tarafınıza iletilen ayın matrahı için; <p>Mutabık kaldıysanız 'Evet'e basınız, Mutabık değilseniz 'Hayır'a basınız.</p>", "Cari Mutabakat Onayı")) {
              await useCariMutabakatService().getBaBsSaveMutabakat(localState.key, true);
              localState.message = "Mutabık kalındığı bilgisi tarafımıza iletildi, bu sayfayı kapatabilirsiniz.";
            } else {
              await useCariMutabakatService().getBaBsSaveMutabakat(localState.key, false);
              localState.message = "Mutabık kalınmadığı bilgisi tarafımıza iletildi, bu sayfayı kapatabilirsiniz.";
            }
          }
        }, 500)
      }

    }


    return {
      ...toRefs(localState), load
    }
  }
})
