import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { style: {"margin-top":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NTag = _resolveComponent("NTag")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_DxAutocomplete = _resolveComponent("DxAutocomplete")!
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_DxTagBox = _resolveComponent("DxTagBox")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NInputHtml = _resolveComponent("NInputHtml")!
  const _component_DxDateBox = _resolveComponent("DxDateBox")!
  const _component_CokluDokuman = _resolveComponent("CokluDokuman")!
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    modelValue: _ctx.showRef,
    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.showRef) = $event)),
    width: "600px"
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
      _createVNode(_component_NTag, {
        type: "info",
        filled: _ctx.id && _ctx.id.length > 0
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.subTitle), 1)
        ]),
        _: 1
      }, 8, ["filled"])
    ]),
    body: _withCtx(() => [
      _createVNode(_component_NFormField, {
        "grid-cols": "120px 280px",
        label: "Kimden"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DxSelectBox, {
            items: _ctx.fromMailList,
            value: _ctx.model.from,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.from) = $event))
          }, null, 8, ["items", "value"])
        ]),
        _: 1
      }),
      (_ctx.isKime)
        ? (_openBlock(), _createBlock(_component_NFormField, {
            key: 0,
            "grid-cols": "120px 1fr 16px",
            label: "Kime"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxAutocomplete, {
                "data-source": _ctx.gonderilenMailList,
                value: _ctx.model.email,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.email) = $event)),
                showClearButton: false,
                showDropDownButton: true,
                inputAttr: {style:'cursor: text'}
              }, null, 8, ["data-source", "value"]),
              (!(_ctx.model && _ctx.model.id && _ctx.model.id.length > 0))
                ? (_openBlock(), _createBlock(_component_NIcon, {
                    key: 0,
                    type: "at-sign",
                    size: "16",
                    onClick: _ctx.toggleBulk,
                    style: {"cursor":"pointer"},
                    title: "Birden çok kişiye gönder"
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!(_ctx.model && _ctx.model.id && _ctx.model.id.length > 0) && !_ctx.isKime)
        ? (_openBlock(), _createBlock(_component_NFormField, {
            key: 1,
            "grid-cols": "120px 1fr 16px",
            label: "Kimlere"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DxTagBox, {
                items: _ctx.gonderilenMailList,
                "accept-custom-value": true,
                value: _ctx.model.emails,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.emails) = $event)),
                searchEnabled: "true"
              }, null, 8, ["items", "value"]),
              _createVNode(_component_NIcon, {
                type: "paperclip",
                size: "16",
                onClick: _ctx.toggleBulk,
                style: {"cursor":"pointer"},
                title: "Tek kişiye gönder"
              }, null, 8, ["onClick"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_NFormField, {
        "grid-cols": "120px 1fr",
        label: "Şablon"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DxSelectBox, {
            items: _ctx.sablonList,
            displayExpr: "name",
            valueExpr: "id",
            value: _ctx.seciliSablonId,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.seciliSablonId) = $event)),
            onValueChanged: _ctx.sablonChanged
          }, null, 8, ["items", "value", "onValueChanged"])
        ]),
        _: 1
      }),
      _createVNode(_component_NFormField, {
        "grid-cols": "120px 1fr",
        label: "Konu"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NInput, {
            type: "text",
            modelValue: _ctx.model.subject,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.subject) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_NFormField, {
        "grid-cols": "120px 1fr",
        label: "İçerik"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NInputHtml, {
            placeholder: "HTML düzenlemek için tuşa basın",
            "model-value": _ctx.model.body,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.body) = $event))
          }, null, 8, ["model-value"])
        ]),
        _: 1
      }),
      _createVNode(_component_NFormField, {
        "grid-cols": "120px 200px",
        label: "Gönderilecek Tarih"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DxDateBox, {
            type: "datetime",
            "use-mask-behavior": true,
            "display-format": "dd.MM.yyyy HH.mm",
            "picker-type": "list",
            value: _ctx.model.gonderilecekTarih,
            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.gonderilecekTarih) = $event))
          }, null, 8, ["value"])
        ]),
        _: 1
      }),
      _createVNode(_component_NFormField, {
        "grid-cols": "120px 1fr",
        label: "Açıklama"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NInput, {
            type: "text",
            modelValue: _ctx.model.aciklama,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.aciklama) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_CokluDokuman, {
          modelValue: _ctx.model.attachments,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.attachments) = $event))
        }, null, 8, ["modelValue"])
      ]),
      (_ctx.model.attachmentsBase64?.length > 0)
        ? (_openBlock(), _createBlock(_component_NFormGrid, {
            key: 2,
            cols: "1fr",
            style: {"margin-top":"15px"},
            title: "Dosya Ekleri"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.attachmentsBase64, (item, i) => {
                return (_openBlock(), _createBlock(_component_NGrid, {
                  cols: "40px 1fr",
                  key: i
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      style: {"height":"32px","width":"32px","margin-top":"10px","cursor":"pointer"},
                      src: "/images/fileType/pdf.png",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.openFileTab(_ctx.model.attachmentsBase64?.[0].dosya, _ctx.model.attachmentsBase64?.[0].dosyaAdi)))
                    }),
                    _createVNode(_component_DxTextBox, {
                      type: "text",
                      value: _ctx.model.attachmentsBase64[i].dosyaAdi,
                      "onUpdate:value": ($event: any) => ((_ctx.model.attachmentsBase64[i].dosyaAdi) = $event),
                      label: "Dosya Adı",
                      onChange: ($event: any) => (_ctx.model.attachmentsBase64[i].dosyaAdi = _ctx.checkFileExtension(_ctx.model.attachmentsBase64[i].dosyaAdi))
                    }, null, 8, ["value", "onUpdate:value", "onChange"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    actions: _withCtx(() => [
      _withDirectives(_createVNode(_component_NButton, {
        type: "danger",
        size: "small",
        icon: "trash",
        click: _ctx.remove
      }, {
        default: _withCtx(() => [
          _createTextVNode("Sil")
        ]),
        _: 1
      }, 8, ["click"]), [
        [_vShow, _ctx.id && _ctx.id.length > 0]
      ]),
      _createVNode(_component_NButton, {
        type: "warning",
        size: "small",
        icon: "x-circle",
        onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.showRef = false))
      }, {
        default: _withCtx(() => [
          _createTextVNode("İptal")
        ]),
        _: 1
      }),
      _createVNode(_component_NButton, {
        type: "success",
        size: "small",
        icon: "send",
        click: _ctx.save
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.id && _ctx.id.length > 0 ? 'Tekrar Gönder' : 'Gönder'), 1)
        ]),
        _: 1
      }, 8, ["click"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}