
export enum ValueType{
Metin=0,
Sayi=1,
Tarih=2,
EvetHayir=3
}

export const ValueTypeList = [
{key:'Metin', value:0, description:''},
{key:'Sayi', value:1, description:''},
{key:'Tarih', value:2, description:''},
{key:'EvetHayir', value:3, description:''}
]
