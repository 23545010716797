
import {computed, defineComponent, reactive, toRefs} from "vue";
import {GenelResmiMuhasebeTipList} from "@/api/models/GenelResmiMuhasebeTip";

export default defineComponent({
  components: {},
  props: {
    modelValue: {
      type: Number,
      default: 0,
    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      items: GenelResmiMuhasebeTipList,
      model: computed({
        get: () => props.modelValue,
        set: val => emit('update:modelValue', val),
      })
    })
    return {
      ...toRefs(localState)
    };
  }
});
