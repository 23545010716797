import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NDropDownTreeView = _resolveComponent("NDropDownTreeView")!
  const _component_NRefresher = _resolveComponent("NRefresher")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!

  return (_openBlock(), _createElementBlock("div", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), [
    _createVNode(_component_NFormGrid, {
      cols: "1fr",
      padding: "0",
      title: " "
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_NFormField, {
          label: "Pazarama'ya Gönder",
          "grid-cols": "1fr auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NSwitch, {
              modelValue: _ctx.pazaramaRef.isActive,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pazaramaRef.isActive) = $event)),
              style: {"margin-top":"-8px"}
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_NGrid, {
          cols: "1fr 1fr 1fr 1fr",
          gap: "4px",
          rows: "auto",
          style: {"padding-bottom":"5px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NFormField, {
              label: "Kategori",
              "grid-cols": "100px 1fr",
              span: "4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NRefresher, { load: _ctx.load }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NDropDownTreeView, {
                      "selected-item": _ctx.selectedKategoriItem,
                      "onUpdate:selectedItem": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedKategoriItem) = $event)),
                      "data-source": _ctx.kategoriler,
                      "onUpdate:dataSource": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kategoriler) = $event)),
                      "items-expr": "subCategories",
                      "display-expr": "name"
                    }, null, 8, ["selected-item", "data-source"])
                  ]),
                  _: 1
                }, 8, ["load"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Kısa Açıklama",
              span: "4",
              "grid-cols": "100px 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  modelValue: _ctx.pazaramaRef.kisaAciklama,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pazaramaRef.kisaAciklama) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Açıklama",
              span: "4",
              "grid-cols": "100px 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NInput, {
                  modelValue: _ctx.pazaramaRef.aciklama,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pazaramaRef.aciklama) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Kar Marjı(%)",
              span: "1",
              "grid-cols": "100px 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxNumberBox, {
                  value: _ctx.pazaramaRef.karMarji,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pazaramaRef.karMarji) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Komisyon (%)",
              span: "1",
              "grid-cols": "100px 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxNumberBox, {
                  value: _ctx.pazaramaRef.komisyon,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.pazaramaRef.komisyon) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Kargo Tutarı",
              span: "1",
              "grid-cols": "100px 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxNumberBox, {
                  value: _ctx.pazaramaRef.kargoTutar,
                  "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.pazaramaRef.kargoTutar) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "auto 240px",
              label: "Fiyatlandırma"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxSelectBox, {
                  value: _ctx.pazaramaRef.fiyat.tip,
                  "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.pazaramaRef.fiyat.tip) = $event)),
                  items: _ctx.pazaryeriFiyatTipiList,
                  "value-expr": "value",
                  "display-expr": "description"
                }, null, 8, ["value", "items"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 16))
}