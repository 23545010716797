import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxDateBox = _resolveComponent("DxDateBox")!
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!

  return (_openBlock(), _createBlock(_component_NFormGrid, {
    cols: "1fr 1fr 1fr 1fr",
    title: "Talimat bilgileri",
    gap: "4px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DxDateBox, {
        labelMode: "floating",
        value: _ctx.model.baslamaTarihi,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.baslamaTarihi) = $event)),
        label: "Başlama Tarihi"
      }, null, 8, ["value"]),
      _createVNode(_component_DxDateBox, {
        labelMode: "floating",
        value: _ctx.model.bitisTarihi,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.bitisTarihi) = $event)),
        label: "Bitiş Tarihi"
      }, null, 8, ["value"]),
      _createVNode(_component_DxDateBox, {
        labelMode: "floating",
        value: _ctx.model.terminTarihi,
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.terminTarihi) = $event)),
        label: "Termin Tarihi"
      }, null, 8, ["value"]),
      _createVNode(_component_DxDateBox, {
        labelMode: "floating",
        value: _ctx.model.planlamaTarihi,
        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.planlamaTarihi) = $event)),
        label: "Planlama Tarihi"
      }, null, 8, ["value"]),
      _createVNode(_component_DxTextBox, {
        labelMode: "floating",
        value: _ctx.model.hatAdi,
        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.hatAdi) = $event)),
        label: "Hat Adı"
      }, null, 8, ["value"]),
      _createVNode(_component_DxTextBox, {
        labelMode: "floating",
        value: _ctx.model.aciklama,
        "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.aciklama) = $event)),
        label: "Açıklama",
        style: {"grid-column":"span 3"}
      }, null, 8, ["value"])
    ]),
    _: 1
  }))
}