
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";
import {SubeEntity} from "@/api/models/SubeEntity";
import {useSubeService} from "@/api/services/SubeService";
import {useCachedSubeDepoService} from "@/modules/Sube/services/useCachedSubeDepoService";
import NButton from "@/components/Button/NButton.vue";
import NGrid from "@/components/Grid/NGrid.vue";

export default defineComponent({
  components: {},
  props: {
    modelValue: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearButton: {
      type: Boolean,
      default: false,
    },
    firstSelect: {
      type: Boolean,
      default: true,
    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      items: ref([] as SubeEntity[]),
      selected: computed({
        get: () => props.modelValue,
        set: val => emit("update:modelValue", val)
      }),
      disabledRef: computed({
        get: () => props.disabled,
        set: val => emit("update:disabled", val)
      })
    });


    async function load() {
      localState.items = (await useCachedSubeDepoService().getSubeList()) as SubeEntity[];
      if (localState.items.length > 0) {
        if (props.modelValue && props.modelValue.length > 0) {
          //
        } else {
          if (props.firstSelect == true)
            localState.selected = localState.items[0].id as string;
        }
      }
    }

    async function refreshSubeList() {
      await useCachedSubeDepoService().refreshCachedSubeList()
      load();
    }

    load();

    return {
      ...toRefs(localState),
      refreshSubeList
    };
  }
});
