
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import NIcon from "@/components/Icon/NIcon.vue";

import ImageUploadType from "@/api/uploaders/models/ImageUploadType";
import { UploadState } from "@/api/uploaders/models/UploadState";
import NCard from "@/components/Card/NCard.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import {ImageManagementServiceUtil} from "@/api/uploaders/ImageManagementServiceUtil";

export default defineComponent({
  components: {NGrid, NCard, NIcon, NModalDialog },
  props: {
    serverUrl: { type: String, default: "" },
    src: { type: Array },
    width: { type: Number, default: 30 },
    height: { type: Number, default: 30 },
    imageWidth: { type: Number, default: 150 },
    imageHeight: { type: Number, default: 150 },
    folderName: { type: String, default: "" },
    showUploadButton: { type: Boolean, default: true }
  },
  setup(props, { emit }) {
    const localState = reactive({
      states: ref(UploadState),
      models: ref([] as any[]),
      srcRef: computed(() => props.src as any[]),
      showImage:ref(false),
      imageDeleteOparation:ref(false),
      selectedImage:ref(''),

    });
    const imageClientDatas = ref([] as any[]);

    function getImagePath(index: number) {

      const model = localState.models[index] as ImageUploadType;
      if (model.state !== UploadState.Loading) {
        return props.src && props.src.length > 0
          ? " url('" + props.serverUrl + props.src + "')"
          : "url('/user.png')";
      } else if (model.state === UploadState.Loading)
        return "url('" + imageClientDatas.value[index] + "')";
    }

    async function fileSelected(e: any) {
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          imageClientDatas.value.push(reader.result);
        };
        reader.onerror = (err)=>{
          console.log("FileReader hatası",err)
        }

        await uploadFile(file)
      }
    }
    async function uploadFile(file:any,url?:string) {

      const model: any = {};
      //alert(file.name)
      model.width = 0;
      model.folderName = '';
      model.height = 0;
      model.state = UploadState.Loading;
      model.file = file;
      model.loaded = 0;
      model.url = url;
      model.total = 0;

      localState.models.push(model);
      await fileUpload(model);
    }


    async function pasteAsUrl(){
      const url= await navigator.clipboard.readText();
      await uploadFile(undefined,url);
    }

    async function fileUpload(model: ImageUploadType) {
      try {
        model.state = UploadState.Loading;

        const response = await ImageManagementServiceUtil.postImageManagmentResimEkle(
          model
        );

        if (response.name) localState.srcRef.push(response.name);
        model.error = response.error;
        if (model.error && model.error.length > 0) {
          model.state = UploadState.Error;
        } else {
          model.state = UploadState.Ready;
        }
      } catch (err:any) {
        model.error = err.message;
        model.state = UploadState.Error;
        throw err;
      }
      model.state = UploadState.Ready;
    }

    function getRepeat() {
      if (localState.srcRef)
        return (
          "repeat(" +
          (localState.srcRef.length + 1) +
          ", " +
          props.width +
          "px) 1fr"
        );
      else return props.width + "px auto";
    }

    function remove(i: number) {
      localState.imageDeleteOparation = true;
      if (confirm(i + 1 + " numaralı resmi silmek istiyor musunuz?"))
        localState.srcRef.splice(i, 1);
    }

    function load() {
      if (!props.src) {
        emit("update:src", []);
      }
    }

    function onTaskDrop(e:any) {
      const itemData = localState.srcRef[e.fromIndex];
      localState.srcRef.splice(e.fromIndex, 1);

      localState.srcRef.splice(e.toIndex, 0, itemData);
    }

    function showImagePreview(resimUrl:string){
      if (localState.imageDeleteOparation)
      {
        localState.imageDeleteOparation = false;
        return;
      }

      localState.selectedImage =  getImageUrl(resimUrl,false);
      localState.showImage = true;
    }

    function getImageUrl(model:any, isThumb:boolean) {
        const isExternalUrl = model?.startsWith("http://") || model?.startsWith("https://");
        if (isExternalUrl)
            return model;

        if (isThumb)
            return  props.serverUrl + 'thumbs/' + model +  '/20'
        return  props.serverUrl +  model;
    }

    load();

    return {
      ...toRefs(localState),
      fileSelected,
      getImagePath,
      remove,
      getRepeat,
      onTaskDrop,
      showImagePreview,
      pasteAsUrl,
        getImageUrl
    };
  }
});
