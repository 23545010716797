import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {RaporRequestView} from '../models/RaporRequestView'
import {FaturaTip} from '../models/FaturaTip'
import {FaturaFirmaDurumRequestView} from '../models/FaturaFirmaDurumRequestView'
import {FaturaHareketTip} from '../models/FaturaHareketTip'
import {FaturaCariSatislarRaporRequestView} from '../models/FaturaCariSatislarRaporRequestView'

export const useFaturaRaporService = defineStore({
id: 'FaturaRaporService',
actions: {


	async getKdvRaporDetayli(isGenel:boolean,isResmiMuhasebe:boolean)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FaturaRapor/KdvRaporDetayli/${shared.firm.id}/${shared.firm.period}/${isGenel}/${isResmiMuhasebe}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getKdvRapor(month:number,year:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FaturaRapor/KdvRapor/${shared.firm.id}/${shared.firm.period}/${month}/${year}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postPerakendeCariSatislar(raporRequestView:RaporRequestView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FaturaRapor/PerakendeCariSatislar/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:raporRequestView });
		await request();
		return response.value;
	},

	async getPerakendeSatisToplamTutar(baslangic:string,bitis:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FaturaRapor/PerakendeSatisToplamTutar/${shared.firm.id}/${shared.firm.period}/${baslangic}/${bitis}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getPerakendeSatisByCariName(baslangic:string,bitis:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FaturaRapor/PerakendeSatisByCariName/${shared.firm.id}/${shared.firm.period}/${baslangic}/${bitis}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCariSatislarFaturaRapor(start:string,end:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FaturaRapor/CariSatislarFaturaRapor/${shared.firm.id}/${shared.firm.period}/${start}/${end}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getPerakendeCariSatislarFaturaRapor(start:string,end:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FaturaRapor/PerakendeCariSatislarFaturaRapor/${shared.firm.id}/${shared.firm.period}/${start}/${end}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postFaturaCiroRapor(faturaFirmaDurumRequestView:FaturaFirmaDurumRequestView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FaturaRapor/FaturaCiroRapor/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:faturaFirmaDurumRequestView });
		await request();
		return response.value;
	},

	async getGunlukSatislarAlislar()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FaturaRapor/GunlukSatislarAlislar/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCariSatislar(baslangic:string,bitis:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FaturaRapor/CariSatislar/${shared.firm.id}/${shared.firm.period}/${baslangic}/${bitis}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getTumFaturalar(baslangic:string,bitis:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FaturaRapor/TumFaturalar/${shared.firm.id}/${shared.firm.period}/${baslangic}/${bitis}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getSiparisVeTeklifler(baslangic:string,bitis:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FaturaRapor/SiparisVeTeklifler/${shared.firm.id}/${shared.firm.period}/${baslangic}/${bitis}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCariOncekiStoklar(tip:number,id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FaturaRapor/CariOncekiStoklar/${shared.firm.id}/${shared.firm.period}/${tip}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getFaturaSatirlari(startDate:string,endDate:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FaturaRapor/FaturaSatirlari/${shared.firm.id}/${shared.firm.period}/${startDate}/${endDate}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postFaturaCariSatislarRapor(faturaCariSatislarRaporRequestView:FaturaCariSatislarRaporRequestView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FaturaRapor/FaturaCariSatislarRapor/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:faturaCariSatislarRaporRequestView });
		await request();
		return response.value;
	},

	async getFaturaSatisPlasiyerRapor(plasiyerId:string,baslangic:string,bitis:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/FaturaRapor/FaturaSatisPlasiyerRapor/${shared.firm.id}/${shared.firm.period}/${baslangic}/${bitis}/${plasiyerId}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
