
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import OzellikFormDialog from "@/modules/StokOzellik/components/OzellikFormDialog.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NInput from "@/components/Input/NInput.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import OzellikFormItem from "@/modules/StokOzellik/components/OzellikFormItem.vue";

export default defineComponent({
  components: {OzellikFormItem, NFormGrid, OzellikFormDialog },
  props: {
    ozellikler: {
      type: Array
    },
    title:{
      type:String,
      default:'Özellikler'
    },
    isStok:{
      type:Boolean,
      default:true
    },
    kategoriId:{
      type:String
    }

  },
  setup(props) {
    const localState = reactive({
      ozelliklerRef: computed(() => props.ozellikler),
      showOzellikForm: ref(false),
      selectedOzellikId: ref(""),
      kategoriIdRef:computed(()=>props.kategoriId),
    });

    function openOzellikForm(ozellikId: string) {
      localState.selectedOzellikId = ozellikId;
      localState.showOzellikForm = true;
    }

    function loadValues(propertyName:string) {
      if (props.isStok){
        // alert('asd')
      }
    }

    return {
      ...toRefs(localState),
      openOzellikForm

    };
  }
});
