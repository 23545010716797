
import {computed, defineComponent, reactive, ref, toRefs} from "vue";

import NGrid from "@/components/Grid/NGrid.vue";
import {confirm} from "devextreme/ui/dialog";
import {useDovizKurService} from "@/api/services/DovizKurService";
import {useKurCachedServis} from "@/modules/Ortak/services/useKurCachedServis";
import {KurView} from "@/api/models/KurView";
import {Doviz} from "@/api/models/Doviz";

import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NButton from "@/components/Button/NButton.vue";
import NIcon from "@/components/Icon/NIcon.vue";

export default defineComponent({
  components: {NIcon, NButton, NFormField, NFormGrid, NGrid  },
  props: {

  },
  setup(props) {

    const localState = reactive({
      dolar : ref(0),
      euro : ref(0),
      dolarForm : ref(0),
      euroForm : ref(0),
      showKurEditor:ref(false)
    });

    async function loadKur() {
      const kurList = await useDovizKurService().getKurlar();

      kurList.forEach((kur: KurView) => {
        if (kur.doviz == Doviz.EUR) {
          localState.euro = kur.alis as number;
          localState.euroForm = kur.alis as number;
        } else if (kur.doviz == Doviz.USD) {
          localState.dolar = kur.alis as number;
          localState.dolarForm = kur.alis as number;
        }
      });

    }

    loadKur();
    async function refreshKur() {
      if (await confirm("Sabit kuru güncel kur ile değiştirmek istiyormusunuz?", "Kur Güncelleme")) {
        await useDovizKurService().deleteKurlarSabitKurSil();
        await loadKur();
        await useKurCachedServis().refresh();
      }
    }

    function editKur() {

      localState.dolarForm = localState.dolar as number;
      localState.euroForm = localState.euro as number;
      localState.showKurEditor = true;
    }

    async function saveKur() {
      await useDovizKurService().postKurlarSabitKurKaydet(localState.dolarForm, localState.euroForm);
      await loadKur();
      await useKurCachedServis().refresh();
      localState.showKurEditor = false;
    }

    return {
      ...toRefs(localState),
      refreshKur,
      loadKur,
      saveKur,
      editKur
    };
  }
});

