import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NDropDownTreeView = _resolveComponent("NDropDownTreeView")!
  const _component_NRefresher = _resolveComponent("NRefresher")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NDropDownListCustomStore = _resolveComponent("NDropDownListCustomStore")!
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_TrendyolKategoriOzellikEditor = _resolveComponent("TrendyolKategoriOzellikEditor")!

  return (_openBlock(), _createElementBlock("div", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), [
    (_ctx.trendyolRef && _ctx.trendyolRef.kategori)
      ? (_openBlock(), _createBlock(_component_NFormGrid, {
          key: 0,
          cols: "auto auto auto auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NFormField, {
              label: "Trendyol Kategorisi",
              "grid-cols": "120px 1fr",
              span: "4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NRefresher, {
                  load: ()=>_ctx.loadKategoriler(0)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NDropDownTreeView, {
                      "selected-item": _ctx.trendyolRef.kategori,
                      "onUpdate:selectedItem": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.trendyolRef.kategori) = $event)),
                      "data-source": _ctx.kategoriler,
                      "onUpdate:dataSource": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.kategoriler) = $event)),
                      "items-expr": "subCategories"
                    }, null, 8, ["selected-item", "data-source"])
                  ]),
                  _: 1
                }, 8, ["load"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Marka",
              span: "3",
              "grid-cols": "120px 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NDropDownListCustomStore, {
                  "selected-item": _ctx.trendyolRef.marka,
                  "onUpdate:selectedItem": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.trendyolRef.marka) = $event)),
                  "data-source": _ctx.dataSourceMarkalar
                }, null, 8, ["selected-item", "data-source"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Trendyol'a Gönder",
              "grid-cols": "1fr auto"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NSwitch, {
                  modelValue: _ctx.trendyolRef.isActive,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.trendyolRef.isActive) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Kar Marjı(%)",
              span: "1",
              "grid-cols": "120px 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxNumberBox, {
                  value: _ctx.trendyolRef.karMarji,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.trendyolRef.karMarji) = $event)),
                  type: "number"
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Kargo(₺)",
              span: "1",
              "grid-cols": "auto 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxNumberBox, {
                  value: _ctx.trendyolRef.kargoTutar,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.trendyolRef.kargoTutar) = $event))
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              label: "Komisyon(%)",
              span: "1",
              "grid-cols": "auto 1fr"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxNumberBox, {
                  value: _ctx.trendyolRef.komisyon,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.trendyolRef.komisyon) = $event)),
                  type: "number"
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_NFormField, {
              "grid-cols": "auto 240px",
              label: "Fiyatlandırma"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxSelectBox, {
                  value: _ctx.trendyolRef.fiyat.tip,
                  "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.trendyolRef.fiyat.tip) = $event)),
                  items: _ctx.pazaryeriFiyatTipiList,
                  "value-expr": "value",
                  "display-expr": "description"
                }, null, 8, ["value", "items"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.trendyolRef.kategori.id && _ctx.trendyolRef.kategori.id > 0)
      ? (_openBlock(), _createBlock(_component_TrendyolKategoriOzellikEditor, {
          key: 1,
          cols: "1fr 1fr",
          "kategori-id": _ctx.trendyolRef.kategori.id,
          "onUpdate:kategoriId": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.trendyolRef.kategori.id) = $event)),
          modelValue: _ctx.trendyolRef.ozellikler,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.trendyolRef.ozellikler) = $event))
        }, null, 8, ["kategori-id", "modelValue"]))
      : _createCommentVNode("", true)
  ], 16))
}