import mitt from "mitt";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { pinia } from "./store";
import registerGlobalComponents from "./utils/registerGlobalComponents";
import registerModules from "./utils/registerModules";
import StokFisForm from "@/modules/Fis/pages/StokFisForm.vue";
import useDevExtreme from "@/devextreme/useDevExtreme";




import "./extensions";
import BasitUretimForm from "@/modules/Stok/components/BasitUretimForm.vue";
import OdemeTahsilatPages from "@/modules/OdemeTahsilat/pages/OdemeTahsilatPages.vue";
import FaturaFormTabPage from "@/modules/Fatura/pages/FaturaFormTabPage.vue";
import PerakendePage from "@/modules/Perakende/pages/PerakendePage.vue";
import SiparisFormTabPage from "@/modules/SiparisTeklif/pages/SiparisFormTabPage.vue";
import StokGroupFormPage from "@/modules/StokGroup/pages/StokGroupFormPage.vue";
import { DevExtremeUtils } from "@/devextreme/DevExtremeUtils";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const printnb = require("vue3-print-nb");





export const app = createApp(App);

app.component("DevExtremeUtils" , DevExtremeUtils);
app.component("FaturaForm", FaturaFormTabPage);
app.component("FisForm", StokFisForm);
app.component("BasitUretimForm", BasitUretimForm);
app.component("OdemeTahsilatPage", OdemeTahsilatPages);
app.component("PerakendeModule" , PerakendePage);
app.component("SiparisForm" , SiparisFormTabPage);
app.component("StokGroupFormPage" , StokGroupFormPage);

app.config.performance = true;

const emitter = mitt();

app.config.globalProperties.emitter = emitter;

app.use(pinia);

app.use(useDevExtreme)


app.use(registerGlobalComponents);
app.use(registerModules);
app.use(printnb);
app.use(router).mount("#app");

