
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";

import {useBankaService} from "@/api/services/BankaService";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";
import {KrediKartiView} from "@/api/models/KrediKartiView";
import {DateUtils} from "@/utils/date/DateUtils";

export default defineComponent({
  components: {},
  props: {
    modelValue: {
      type: String
    },
    hareketId: {
      type: String
    },
    vade: {
      type: String
    },
    name: {
      type: String
    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      dateUtils: DateUtils,
      items: ref([] as KrediKartiView[]),
      selected: computed({
        get: () => props.modelValue,
        set: val => emit("update:modelValue", val)
      }),
      vadeRef: computed({
        get: () => props.vade,
        set: val => emit("update:vade", val)
      })
    });

    async function load() {
      localState.items = await useBankaService().getKrediKartiList();

      if (props.modelValue && props.modelValue.length > 0) {
        //
      } else {
        localState.selected = localState.items[0]?.krediKartId;
        emit("update:name" , localState.items[0]?.kartAdi) ;
      }
    }

    load();


    watch(
        () => props.modelValue,
        newVal => {
          if (props.modelValue) {
            if (props.hareketId && props.hareketId.length > 0) {
              //
            } else {
              const result = localState.items.find(
                  a => a.krediKartId == props.modelValue
              );
              if (result) {
                localState.vadeRef = new Date(Date.parse(result.sonOdemeGunuTarihi as string)).toISOString();
                emit("update:name", result.kartAdi);
              }
            }
          }
        }
    );

    return {
      ...toRefs(localState)
    };
  }
});
