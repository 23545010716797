
import { computed, defineComponent, reactive, ref, toRefs } from "vue";

export default defineComponent({
    components: {},
  props: {
    dataSource:{
      type:Array
    },
    itemsExpr:{
      type:String
    },
    selectedItem:{
      type:Object
    },
    keyExpr:{
      type:String,
      default:'id'
    },
    dataStructure:{
      type:String,
      default:'tree'
    },
    parentIdExpr:{
      type:String,
      default:'parentId'
    },
    displayExpr:{
      type:String,
      default:'name'
    }

  },
  setup(props,{emit}) {

    const localState = reactive({
      treeDataSource: ref( props.dataSource),
      treeBoxValue: ref(null),
      isTreeBoxOpened: ref(false),
      treeViewRefName: ref(),
      selectedItemRef:computed(
          {
            get:()=> props.selectedItem as any,
            set:(val) =>{
              emit('update:selectedItem',val)
            }
      })
    });

    if (!localState.selectedItemRef)
      localState.selectedItemRef = {}

    if (!localState.selectedItemRef){
      localState.selectedItemRef =  {};
    }

    function load(){

      if (localState.selectedItemRef)
        localState.selectedItemRef.selected=true
    }
    load()


    function syncTreeViewSelection(e:any) {
      //console.log("syncTreeViewSelection",e);
      if (!e.value)
        localState.selectedItemRef = {};
      // if (localState.selected) {
      //   localState.treeViewRefName.instance.unselectAll();
      // } else {
      //   localState.treeViewRefName.instance.selectItem(localState.selected);
      // }
    }

    function treeView_itemSelectionChanged(e:any) {
    //  console.log("treeView_itemSelectionChanged",e);
      //localState.selected = e.component.getSelectedNodeKeys()[0];
    }

    function onTreeItemClick(e:any) {
      // console.log("e.itemData",e.itemData);


      if(e.node.expanded)
        e.component.collapseItem(e.itemElement);
      else
      e.component.expandItem(e.itemElement)

      if (e.itemData && props.itemsExpr){
        if(e.itemData[props.itemsExpr] && e.itemData[props.itemsExpr].length == 0){

          localState.selectedItemRef = e.itemData as any;
          localState.selectedItemRef.selected = true;
          // id: e.itemData[props.keyExpr], name:e.itemData[props.displayExpr]

            localState.isTreeBoxOpened = false;
        }
      }
      if (e.itemData && e.itemData.leaf === true){
        localState.selectedItemRef = e.itemData;
        localState.isTreeBoxOpened = false;
      }
      emit('selectedChanged',e.itemData)
    }

    return {
      ...toRefs(localState),
      syncTreeViewSelection,
      treeView_itemSelectionChanged,
      onTreeItemClick
    };
  }
});

