
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from "vue";
import NCard from "@/components/Card/NCard.vue";

import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxColumnChooser,
  DxLoadPanel

} from "devextreme-vue/data-grid";
import { DxLookup } from "devextreme-vue";
import NIconIonic from "@/components/NIconIonic.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import { UlkeView } from "@/api/models/UlkeView";
import useApi from "@/api/useApi";
import dxDropDownBox from "devextreme/ui/drop_down_box";
import MahalleAraSelectBox from "@/modules/Ortak/components/MahalleAraSelectBox.vue";
import { sum } from "lodash";

export class GecicListeEntity {
  constructor() {
    this.barkod = "";
    this.miktar = 0;
    this.kg = 0;

  }

  barkod: string;
  miktar: number;
  kg: number;
}

export default defineComponent({
  components: {},
  props: {},
  setup() {
    const localState = reactive({
      //
    });

    onMounted(() => {

    //


    });


    return {
      ...toRefs(localState)


    };
  }
});


