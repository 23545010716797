
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import UretimTanim from "@/modules/Ayarlar/Tanımlar/components/UretimTanim.vue";
import NButton from "@/components/Button/NButton.vue";

export default defineComponent({
  components: {NButton, UretimTanim, NModalDialog},
  props: {
    show: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, {emit}) {

    const localState = reactive({
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val),
      })

    });

    return {
      ...toRefs(localState),
    };
  }
});
