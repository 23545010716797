
import {computed, defineComponent, reactive, toRefs} from "vue";
import {StokView} from "@/api/models/StokView";
import NInputHtml from "@/components/Input/NInputHtml.vue";
import NButton from "@/components/Button/NButton.vue";
import FirmaLanguageSelectBox from "@/modules/Ayarlar/Firmalar/components/FirmaLanguageSelectBox.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";

export default defineComponent({
  components: {NFormField, NGrid, FirmaLanguageSelectBox, NButton, NInputHtml},
  props: {
    modelValue: {}
  },
  setup(props) {

    const localState = reactive({
      model: computed(() => props.modelValue as StokView)
    });

    function dilEkle() {
      localState.model?.languages?.push({})
    }

    function remove(index: number) {
      localState.model.languages?.splice(index, 1);
    }

    function load() {
      if (!localState.model.languages)
        localState.model.languages = []
    }

    load()

    return {
      ...toRefs(localState),
      dilEkle,
      remove
    };
  }
});

