
export enum KargoTip{
IslemYok=0,
Aras=1,
Yurtici=2,
Mng=3
}

export const KargoTipList = [
{key:'IslemYok', value:0, description:'Seçili Değil'},
{key:'Aras', value:1, description:'Aras Kargo'},
{key:'Yurtici', value:2, description:'Yurtiçi Kargo'},
{key:'Mng', value:3, description:'Mng Kargo'}
]
