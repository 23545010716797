
export enum GenelResmiMuhasebeTip{
Genel=0,
Resmi=1,
Muhasebe=2
}

export const GenelResmiMuhasebeTipList = [
{key:'Genel', value:0, description:'Genel'},
{key:'Resmi', value:1, description:'Resmi'},
{key:'Muhasebe', value:2, description:'Muhasebe'}
]
