
import {defineComponent, reactive, ref, toRefs} from "vue";
import StokSeriHareketListDialog from "@/modules/Stok/components/StokSeriHareketListDialog.vue";
import NButton from "@/components/Button/NButton.vue";


export default defineComponent({
    components: {NButton},
    props: {},
    setup(props) {

        const localState = reactive({
            show: ref(true),
            showXmlSelectDialog: ref(true),

        });

        async function load() {

        }

        load();

        function testClick() {
            const win = window.open("https://www.trendyol.com","trendyol");
            console.log(win)

            setTimeout(()=>{
                if (win)
                win.postMessage("javascript:alert('test')")
            },3000)
        }



        return {
            ...toRefs(localState),
            testClick

        };
    }
});

