import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "dx-card",
  style: {"height":"100%"}
}
const _hoisted_2 = { style: {"height":"120px","white-space":"normal"} }
const _hoisted_3 = { style: {"font-size":"14px","color":"#212121"} }
const _hoisted_4 = {
  class: "text-1",
  style: {"margin-top":"3px","color":"#7d7d7d","line-height":"1.2em"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxList = _resolveComponent("DxList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DxList, { "data-source": _ctx.list }, {
      item: _withCtx(({data:item}) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("b", null, _toDisplayString(item.name), 1)
          ]),
          _createElementVNode("div", _hoisted_4, _toDisplayString(item.getListAciklama), 1)
        ])
      ]),
      _: 1
    }, 8, ["data-source"])
  ]))
}