
import {defineComponent, reactive, ref, toRefs} from "vue";
import NGrid from "@/components/Grid/NGrid.vue";
import HizliSatisSatirListesi from "@/modules/HizliSatis/components/HizliSatisSatirListesi.vue";
import {useHizliSatisTabsStore} from "@/modules/HizliSatis/store/HizliSatisTabsStore";
import {PerakendeView} from "@/api/models/PerakendeView";
import {HizliSatisOdemeTip} from "@/modules/HizliSatis/model/HizliSatisOdemeTip";
import HizliSatisStoklarIadeTabs from "@/modules/HizliSatis/components/HizliSatisStoklarIadeTabs.vue";
import HizliSatisOdemeGenelGonderme from "@/modules/HizliSatis/components/HizliSatisOdemeGenelGonderme.vue";
import {confirm} from "devextreme/ui/dialog";

export default defineComponent({
  components: {
    HizliSatisOdemeGenelGonderme,
    HizliSatisStoklarIadeTabs,

    HizliSatisSatirListesi,
    NGrid},
  setup() {
    const localState = reactive({
      tabId:ref(useHizliSatisTabsStore().selectedTabId),
      tabsStore:useHizliSatisTabsStore(),
      model:ref({} as PerakendeView)
    })


    window.addEventListener("keydown",async (e)=>{
      if (localState.tabsStore.selectedTabId === localState.tabId){
        if(e.key == "Escape"){
          const textBox = document.getElementById('stokSearch')?.getElementsByTagName('input')?.item(0);
          if (textBox){
            textBox.focus();
            textBox.setSelectionRange(0,11111)
          }
          //e.preventDefault();
        }else if (e.key == "F2"){ // Nakit ödeme
          e.preventDefault();
          localState.tabsStore.selectedTab.odemeTip = HizliSatisOdemeTip.Nakit; // Nakit
          localState.tabsStore.save()
        }else if (e.key == "F3"){ // Satış iptal
            e.preventDefault();
            if (await confirm("Satışı iptal etmek istiyor musunuz?","UYARI!")){
            //localState.tabsStore.selectedTab.odemeTip = HizliSatisOdemeTip.Nakit; // Nakit
            localState.tabsStore.removeActiveTab();
          }
        }
        else if (e.key == "F4"){ // Yeni satış
            e.preventDefault();
            localState.tabsStore.addTab()
        }
      }
    })



    return {
      ...toRefs(localState),

    }
  }
})
