
export enum SiparisHareketTip{
SatisSiparis=100,
SatisTeklif=110,
AlisSiparis=1000,
AlisTeklif=1100
}

export const SiparisHareketTipList = [
{key:'SatisSiparis', value:100, description:'Satış Siparişi'},
{key:'SatisTeklif', value:110, description:'Satış Teklifi'},
{key:'AlisSiparis', value:1000, description:'Alış Siparişi'},
{key:'AlisTeklif', value:1100, description:'Alış Teklifi'}
]
