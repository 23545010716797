import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dx-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxScrolling = _resolveComponent("DxScrolling")!
  const _component_DxSearchPanel = _resolveComponent("DxSearchPanel")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NButton, {
      size: "small",
      icon: "list",
      type: "info",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show=true))
    }, {
      default: _withCtx(() => [
        _createTextVNode("Sevk Seç...")
      ]),
      _: 1
    }),
    (_ctx.show)
      ? (_openBlock(), _createBlock(_component_NModalDialog, {
          key: 0,
          title: "Sevk seçiniz...",
          modelValue: _ctx.show,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.show) = $event)),
          "show-close": true,
          width: "900px"
        }, {
          body: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_DxDataGrid, {
                style: {"height":"500px","width":"100%"},
                "remote-operations": true,
                key: "id",
                "data-source": _ctx.dataSource,
                onRowClick: _cache[1] || (_cache[1] = (e) => _ctx.rowClick(e)),
                ref: "faturaSevkSelectDataGrid"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_DxHeaderFilter, { visible: true }),
                  _createVNode(_component_DxFilterRow, { visible: true }),
                  _createVNode(_component_DxScrolling, { mode: "virtual" }),
                  _createVNode(_component_DxSearchPanel, { visible: true }),
                  _createVNode(_component_DxColumn, {
                    caption: "Ad",
                    "data-field": "ustBilgi.sevkAd",
                    "sort-order": "desc"
                  }),
                  _createVNode(_component_DxColumn, {
                    caption: "Soyad",
                    "data-field": "ustBilgi.sevkSoyad"
                  }),
                  _createVNode(_component_DxColumn, {
                    caption: "SevkTcNo",
                    "data-field": "ustBilgi.sevkTcNo"
                  }),
                  _createVNode(_component_DxColumn, {
                    caption: "Plaka",
                    "data-field": "ustBilgi.sevkPlaka"
                  }),
                  _createVNode(_component_DxColumn, {
                    caption: "Dorse Plaka",
                    "data-field": "ustBilgi.sevkDorsePlaka"
                  }),
                  _createVNode(_component_DxColumn, {
                    caption: " Firma",
                    "data-field": "ustBilgi.sevkFirma"
                  }),
                  _createVNode(_component_DxColumn, {
                    caption: "FirmaVergiNo",
                    "data-field": "ustBilgi.sevkFirmaVergiNo"
                  }),
                  _createVNode(_component_DxColumn, {
                    caption: "Id",
                    "data-field": "id"
                  })
                ]),
                _: 1
              }, 8, ["data-source"])
            ])
          ]),
          _: 1
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}