import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NTagList = _resolveComponent("NTagList")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_NInputHtml = _resolveComponent("NInputHtml")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_StokDiller = _resolveComponent("StokDiller")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!

  return (_ctx.model)
    ? (_openBlock(), _createElementBlock("div", _normalizeProps(_mergeProps({ key: 0 }, _ctx.$attrs)), [
        _createVNode(_component_NLoadingPanel, {
          title: "Yükleniyor...",
          action: _ctx.load
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NGrid, {
              cols: "1fr",
              rows: "auto auto"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NFormGrid, {
                  gap: "0",
                  cols: "1.2fr 1fr 1fr"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_NFormField, {
                      label: "Hızlı Satış Grubu",
                      "grid-cols": "100px 1fr",
                      span: "1"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxSelectBox, {
                          "accept-custom-value": true,
                          value: _ctx.model.hizliSatis,
                          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.hizliSatis) = $event)),
                          items: _ctx.hizliSatisGruplari,
                          placeholder: "Örn: Muhtelif"
                        }, null, 8, ["value", "items"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      "grid-cols": "50px 1fr",
                      label: "Raf"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NInput, {
                          modelValue: _ctx.model.raf,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.raf) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NGrid, {
                      cols: "1fr 1fr",
                      style: {"margin-left":"8px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NSwitch, {
                          modelValue: _ctx.model.isSerili,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.isSerili) = $event)),
                          label: "Serili"
                        }, null, 8, ["modelValue"]),
                        _createVNode(_component_NSwitch, {
                          modelValue: _ctx.model.yerlimi,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.yerlimi) = $event)),
                          label: "Yerli"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      label: "Barkodlar",
                      "grid-cols": "100px 1fr",
                      span: "2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NTagList, {
                          modelValue: _ctx.model.barkodlar,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.barkodlar) = $event)),
                          type: "string"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      label: "Kodlar",
                      "grid-cols": "100px 1fr",
                      span: "2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NTagList, {
                          modelValue: _ctx.model.kodlar,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.kodlar) = $event)),
                          type: "string"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      label: "Piyasa Adları",
                      "grid-cols": "100px 1fr",
                      span: "2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NTagList, {
                          modelValue: _ctx.model.adlar,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.adlar) = $event)),
                          type: "string"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      label: "Kar Marjı (%)",
                      "grid-cols": "100px 1fr"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxNumberBox, {
                          value: _ctx.model.karMarji,
                          "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.karMarji) = $event))
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      label: "GTIP",
                      "grid-cols": "100px 1fr"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NInput, {
                          modelValue: _ctx.model.gtip,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.gtip) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      label: "PLU Kodu",
                      "grid-cols": "100px 1fr"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxSelectBox, {
                          value: _ctx.model.pluKod,
                          "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.pluKod) = $event)),
                          items: _ctx.pluKodList,
                          "display-expr": "description",
                          "value-expr": "value"
                        }, null, 8, ["value", "items"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      label: "PLU No",
                      "grid-cols": "100px 1fr"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxNumberBox, {
                          value: _ctx.model.pluNo,
                          "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.pluNo) = $event)),
                          min: 0
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      label: "Desi",
                      "grid-cols": "100px 1fr"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxNumberBox, {
                          value: _ctx.model.desi,
                          "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.desi) = $event)),
                          type: "number"
                        }, null, 8, ["value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      label: "Adet Böleni",
                      "grid-cols": "100px 1fr"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxNumberBox, {
                          min: 0.01,
                          value: _ctx.model.adetBolen,
                          "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.adetBolen) = $event))
                        }, null, 8, ["min", "value"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      label: "Fatura Ad",
                      "grid-cols": "100px 1fr",
                      span: "3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NInput, {
                          modelValue: _ctx.model.faturaAd,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.faturaAd) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      label: "Kısa Açıklama",
                      "grid-cols": "100px 1fr",
                      span: "3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NInput, {
                          modelValue: _ctx.model.kisaAciklama,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.kisaAciklama) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      label: "Açıklama",
                      "grid-cols": "100px 1fr",
                      span: "3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_NInputHtml, {
                          modelValue: _ctx.model.aciklama,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.aciklama) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_NFormField, {
                      label: "Gruplar",
                      "grid-cols": "100px 1fr 1fr 1fr 1fr",
                      span: "3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxSelectBox, {
                          acceptCustomValue: true,
                          items: _ctx.grup1Ref,
                          "onUpdate:items": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.grup1Ref) = $event)),
                          value: _ctx.model.grup1,
                          "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model.grup1) = $event)),
                          onValueChanged: _ctx.grup2ListeYukle,
                          placeholder: "Grup 1"
                        }, null, 8, ["items", "value", "onValueChanged"]),
                        _createVNode(_component_DxSelectBox, {
                          acceptCustomValue: true,
                          items: _ctx.grup2Ref,
                          "onUpdate:items": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.grup2Ref) = $event)),
                          value: _ctx.model.grup2,
                          "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.model.grup2) = $event)),
                          onValueChanged: _ctx.grup3ListeYukle,
                          placeholder: "Grup 2"
                        }, null, 8, ["items", "value", "onValueChanged"]),
                        _createVNode(_component_DxSelectBox, {
                          acceptCustomValue: true,
                          items: _ctx.grup3Ref,
                          "onUpdate:items": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.grup3Ref) = $event)),
                          value: _ctx.model.grup3,
                          "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.model.grup3) = $event)),
                          onValueChanged: _ctx.grup4ListeYukle,
                          placeholder: "Grup 3"
                        }, null, 8, ["items", "value", "onValueChanged"]),
                        _createVNode(_component_DxSelectBox, {
                          acceptCustomValue: true,
                          items: _ctx.grup4Ref,
                          "onUpdate:items": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.grup4Ref) = $event)),
                          value: _ctx.model.grup4,
                          "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.model.grup4) = $event)),
                          placeholder: "Grup 4"
                        }, null, 8, ["items", "value"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_StokDiller, {
                  "model-value": _ctx.model,
                  "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.model) = $event))
                }, null, 8, ["model-value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["action"])
      ], 16))
    : _createCommentVNode("", true)
}