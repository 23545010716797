
import {defineComponent, reactive, toRefs} from "vue";
import useApi from "@/api/useApi";
import {useSharedStore} from "@/store";
import router from "@/router";
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
  components: {},
  props: {},

  setup(props, {emit}) {
    const localState = reactive({})

    load();
    async  function  load(){
      //
    }
    function  test(){



    }


     // http://localhost:5000/api/edocdisplay/GetHtml/Yasin/2021/6192c794b05d2bb84222141d"

    return {
      ...toRefs(localState),
      test,

    };
  }


})
