
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import { useTrendyolKategoriOzellikService } from "@/api/services/TrendyolKategoriOzellikService";
import { TrendyolKategoriOzellikResponse } from "@/api/models/TrendyolKategoriOzellikResponse";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NDropDownListBox from "@/components/Dropdown/NDropDownListBox.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import { TrendyolOzellikItem } from "@/api/models/TrendyolOzellikItem";
import _ from "lodash";
import { StokEntity } from "@/api/models/StokEntity";
import { IdName } from "@/api/models/IdName";
import { Ozellik } from "@/api/models/Ozellik";
import { StokItem } from "@/api/models/StokItem";
import NTag from "@/components/Tag/NTag.vue";
import TrendyolEslestirmeDialog from "@/modules/Pazaryerleri/Trendyol/dialogs/TrendyolEslestirmeDialog.vue";


export default defineComponent({
  components: { TrendyolEslestirmeDialog, NTag, NLoadingPanel, NDropDownListBox, NFormField, NFormGrid },
  props: {
    modelValue:{
      type:Array
    },
    kategoriId:{
      type:Number
    },
    cols:{
      type:String,
      default:'1fr 1fr 1fr'
    },
    stok:{
      type:Object
    }
  },
  setup(props,{emit}) {

    const localState = reactive({
      loadingPanel:ref(),
      trendyolOzellikler:ref({} as TrendyolKategoriOzellikResponse),
      nesilceOzellikler:computed({
        get:()=> props.modelValue as TrendyolOzellikItem[],
        set:(val)=> emit('update:modelValue',val)
      }),
      text:ref('REF'),
      stokRef:computed(()=> props.stok as StokEntity),
      showEslestir:ref(false),
      selectedTrendyolValues:ref(),
      selectedNesilceValues:ref(),
      selectedPropertyName:ref()
    });

    watch(()=> props.kategoriId,(kategoriId)=>{
      localState.loadingPanel.load(369);
      //load()
    })

    //load()

    async function load(cacheDay:number){

      const kategoriId = props.kategoriId as number;

      if (kategoriId && kategoriId > 0){
        // Load Özellikler
        localState.trendyolOzellikler = await useTrendyolKategoriOzellikService().getGetir(kategoriId);
        localState.trendyolOzellikler.categoryAttributes = _.orderBy(localState.trendyolOzellikler.categoryAttributes, ['required','allowCustom','attribute.name'],['desc','desc','asc'])
        //alert(kategoriId)
        // Nesilce to Trendyol
        nesilceToTrendyol()
      }
    }

    function getPropertyValueId(trendyolAttributeValues:IdName[],propertyValue:string){
      const valueIdName = trendyolAttributeValues.find(a => a.name == propertyValue);
      if (valueIdName)
        return valueIdName.id;
      return  -1;
    }

    function getPropertyValues(propertyName:string){
        // find variant propery index

      const variantIndex = localState.stokRef.varyantlar?.findIndex(a => a.name


== propertyName) as number;
        if (variantIndex >= 0){
          const variantValues = localState.stokRef.items?.map(a => (a.varyantDegerleri?a.varyantDegerleri[variantIndex]:''))
          const variantValuesDistinc = _.uniq(variantValues);
          return  variantValuesDistinc;
        }


      return [];
    }

    function nesilceToTrendyol(){

      // Fill localState.nesilceOzellikler to localState.trendyolOzellikler
      // Filter     : trendyolOzellikler.attribute.id = nesilceOzellikler.nameId
      // Value Map  : trendyolOzellikler.value        = nesilceOzellikler.value
      //              trendyolOzellikler.valueId      = nesilceOzellikler.valueId
      if (localState.nesilceOzellikler)
      {
        localState.nesilceOzellikler.forEach(ozellik=>{
          if (localState.trendyolOzellikler.categoryAttributes){
            const trendyolOzellik = localState.trendyolOzellikler.categoryAttributes.find(a => a.attribute?.id == ozellik.nameId) as any;
            if (trendyolOzellik){
              trendyolOzellik.valueId = ozellik.valueId;
              trendyolOzellik.value = ozellik.value;
            }
          }
        })
      }
    }

    function trendyolToNesilce(){

      const nesilceOzellikler = [] as TrendyolOzellikItem[];

      if (localState.trendyolOzellikler.categoryAttributes){
        localState.trendyolOzellikler.categoryAttributes.forEach((attribute:any)=>{
          if (attribute.value || attribute.valueId)
          nesilceOzellikler.push({
            name:attribute.attribute?.name,
            nameId:attribute.attribute?.id,
            value:attribute.value,
            valueId:attribute.valueId?parseInt(attribute.valueId):0
          })
        })
      }

      localState.nesilceOzellikler = nesilceOzellikler;
    }

    function eslestir(propertyName:string, trendyolPropertyValues:IdName[]){
      localState.selectedPropertyName = propertyName;
      localState.selectedNesilceValues = getPropertyValues(propertyName);
      localState.selectedTrendyolValues = trendyolPropertyValues;
      localState.showEslestir = true;
    }






    return {
      ...toRefs(localState),
      load,
      trendyolToNesilce,
      getPropertyValues,
      getPropertyValueId,
      eslestir
    };
  }
});

