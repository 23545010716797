import {StokMalzemeSekilTip} from "@/api/models/StokMalzemeSekilTip";
import {StokTip} from "@/api/models/StokTip";
import {StokTipOzellikler} from "@/api/models/StokTipOzellikler";

export const StokOzellikTipDigerApi = {
    getTeorikAgirlik(stokTip: StokTipOzellikler) {
        let sonuc = 0;
        const genislik = stokTip?.genislik as number
        const kalinlik = stokTip?.kalinlik as number
        const yukseklik = stokTip?.yukseklik as number
        const uzunluk = stokTip?.uzunluk as number
        const ozgulAgirlik = stokTip?.carpan as number
        try {
            switch (stokTip?.malzemeSekilTip) {
                case StokMalzemeSekilTip.LamaCubuk:
                case StokMalzemeSekilTip.PlakaLevha:
                case StokMalzemeSekilTip.KalinPlakaLevha:
                case StokMalzemeSekilTip.RuloLevha:
                case StokMalzemeSekilTip.KareCubuk:
                    sonuc = (genislik * kalinlik) / 1000 * ozgulAgirlik
                    break;

                case StokMalzemeSekilTip.YuvarlakCubuk:
                    sonuc = ((3.14) * (genislik / 2) * (genislik / 2)) / 1000 * ozgulAgirlik;
                    break;
                case StokMalzemeSekilTip.AltigenCubuk:
                    sonuc = ((((genislik / 1.73205) * (genislik / 1.73205)) * 1.73205) / 4) * 6 * ozgulAgirlik / 1000
                    break;
                case StokMalzemeSekilTip.KareKutuProfil:
                    sonuc = (((genislik * genislik)) - (genislik - (kalinlik * 2)) * (genislik - (kalinlik * 2))) / 1000 * ozgulAgirlik
                    break;
                case StokMalzemeSekilTip.DikdortgenKutuProfil:
                    sonuc = (((((genislik) - (kalinlik * 2)) * 2) * kalinlik) + ((yukseklik * kalinlik) * 2)) / 1000 * ozgulAgirlik
                    break;
                case StokMalzemeSekilTip.LKosebent:
                case StokMalzemeSekilTip.TProfil:
                    sonuc = (((genislik * yukseklik)) - (genislik - kalinlik) * (yukseklik - kalinlik)) / 1000 * ozgulAgirlik
                    break;
                case StokMalzemeSekilTip.UProfil:
                    sonuc = (((yukseklik * kalinlik) * 2) + ((genislik - (kalinlik * 2)) * kalinlik)) * ozgulAgirlik / 1000
                    break;
                case StokMalzemeSekilTip.BoruProfil:
                    sonuc = ((((((3.14) * (genislik / 2)) * (genislik / 2)) / 1000 * ozgulAgirlik)) - (((3.14) * (((genislik - (kalinlik * 2)) / 2) * (genislik - (kalinlik * 2)) / 2)) * ozgulAgirlik) / 1000)
                    break;
            }
            return sonuc ?? 0
        } catch (e) {
            return 0
        }

    },
    getToplamAgirlik(stokTip: StokTipOzellikler) {
        const uzunluk = stokTip?.uzunluk as number
        try {
            let sonuc = 0;
            if (stokTip.malzemeSekilTip === StokMalzemeSekilTip.Ozel)
                sonuc = uzunluk/1000*(stokTip?.carpan as number)
            else
            {
                const teorikAgirlik = this.getTeorikAgirlik(stokTip) as number
                sonuc = ((teorikAgirlik / 1000) * uzunluk)
            }
            return sonuc ?? 0
        } catch (e) {
            return 0
        }
    },
    getAlasimListesiByTip(tip: StokTip) {
        const alasimlar = [];

        switch (tip) {
            case StokTip.Aluminyum:
                alasimlar.push({value: 2.74, name: '6061'})
                alasimlar.push({value: 2.70, name: '6060'})
                alasimlar.push({value: 2.71, name: '1030'})
                alasimlar.push({value: 2.71, name: '1050'})
                alasimlar.push({value: 2.70, name: '1060'})
                alasimlar.push({value: 2.70, name: '1070'})
                alasimlar.push({value: 2.85, name: '2007'})
                alasimlar.push({value: 2.82, name: '2011'})
                alasimlar.push({value: 2.80, name: '2014'})
                alasimlar.push({value: 2.80, name: '2017'})
                alasimlar.push({value: 2.77, name: '2024'})
                alasimlar.push({value: 2.73, name: '3003'})
                alasimlar.push({value: 2.74, name: '3004'})
                alasimlar.push({value: 2.72, name: '3005'})
                alasimlar.push({value: 2.71, name: '3105'})
                alasimlar.push({value: 2.69, name: '5005'})
                alasimlar.push({value: 2.68, name: '5052'})
                alasimlar.push({value: 2.68, name: '5083'})
                alasimlar.push({value: 2.65, name: '5754'})
                alasimlar.push({value: 2.71, name: '6013'})
                alasimlar.push({value: 2.71, name: '6063'})
                alasimlar.push({value: 2.70, name: '6082'})
                alasimlar.push({value: 2.71, name: '6351'})
                alasimlar.push({value: 2.82, name: '7039'})
                alasimlar.push({value: 2.82, name: '7050'})
                alasimlar.push({value: 2.82, name: '7075'})
                alasimlar.push({value: 2.71, name: '8011'})
                break;
            case StokTip.Bakir:
                alasimlar.push({value: 8.94, name: 'Cu-DHP'})
                alasimlar.push({value: 8.94, name: 'Cu-ETP'})
                alasimlar.push({value: 7.50, name: 'CuAl10Fe'})
                break
            case StokTip.Bronz:
                alasimlar.push({value: 8.80, name: 'CuZn10'})
                alasimlar.push({value: 8.50, name: 'CuZn33'})
                alasimlar.push({value: 8.47, name: 'CuZn37'})
                alasimlar.push({value: 8.41, name: 'CuZn40'})
                alasimlar.push({value: 8.44, name: 'CuZn38Pb2'})
                alasimlar.push({value: 8.43, name: 'CuZn40Pb2'})
                alasimlar.push({value: 8.46, name: 'CuZn39Pb3'})
                alasimlar.push({value: 8.82, name: 'CuSn6'})
                alasimlar.push({value: 8.90, name: 'CuSn7Zn4Pb7'})
                alasimlar.push({value: 8.80, name: 'CuSn8'})
                alasimlar.push({value: 8.90, name: 'CuSn12'})
                alasimlar.push({value: 8.92, name: 'CuNi10Fe1Mn'})
                alasimlar.push({value: 8.93, name: 'CuNi30Mn1Fe'})
                alasimlar.push({value: 7.5, name: 'CuAl10Fe3'})
                alasimlar.push({value: 7.6, name: 'CuAl10Fe5Ni5'})
                break
            case StokTip.Pirinc:
                alasimlar.push({value: 8.80, name: 'CuZn10'})
                alasimlar.push({value: 8.50, name: 'CuZn33'})
                alasimlar.push({value: 8.47, name: 'CuZn37'})
                alasimlar.push({value: 8.41, name: 'CuZn40'})
                alasimlar.push({value: 8.44, name: 'CuZn38Pb2'})
                alasimlar.push({value: 8.43, name: 'CuZn40Pb2'})
                alasimlar.push({value: 8.46, name: 'CuZn39Pb3'})
                alasimlar.push({value: 8.80, name: 'CuSn6'})
                alasimlar.push({value: 8.80, name: 'CuSn7'})
                alasimlar.push({value: 8.80, name: 'CuSn8'})
                alasimlar.push({value: 8.60, name: 'CuSn12'})
                alasimlar.push({value: 8.92, name: 'CuNi10Fe1Mn'})
                alasimlar.push({value: 8.93, name: 'CuNi30Mn1Fe'})
                break
            case StokTip.Paslanmaz:
                alasimlar.push({value: 8.00, name: '301'})
                alasimlar.push({value: 8.00, name: '302'})
                alasimlar.push({value: 8.00, name: '303'})
                alasimlar.push({value: 8.00, name: '304/304L'})
                alasimlar.push({value: 8.00, name: '309'})
                alasimlar.push({value: 8.00, name: '310'})
                alasimlar.push({value: 8.00, name: '316'})
                alasimlar.push({value: 8.00, name: '321'})
                alasimlar.push({value: 7.80, name: '430'})
                break
            case StokTip.Cinko:
                alasimlar.push({value: 7.15, name: 'TiZn99.9'})
                break
        }

        return alasimlar;
    },
    getMalzemeResim(malzemeSekilTip: StokMalzemeSekilTip) {
        switch (malzemeSekilTip) {
            case StokMalzemeSekilTip.Ozel:
                return 'images/UretimDigerProfil/Ozel.jpg'
            case StokMalzemeSekilTip.LamaCubuk:
                return 'images/UretimDigerProfil/LamaCubuk.jpg'
            case StokMalzemeSekilTip.PlakaLevha:
                return 'images/UretimDigerProfil/PlakaLevha.jpg'
            case StokMalzemeSekilTip.KalinPlakaLevha:
                return 'images/UretimDigerProfil/KalinPlakaLevha.jpg'
            case StokMalzemeSekilTip.RuloLevha:
                return 'images/UretimDigerProfil/RuloLevha.jpg'
            case StokMalzemeSekilTip.KareCubuk:
                return 'images/UretimDigerProfil/KareCubuk.jpg'
            case StokMalzemeSekilTip.YuvarlakCubuk:
                return 'images/UretimDigerProfil/YuvarlakCubuk.jpg'
            case StokMalzemeSekilTip.AltigenCubuk:
                return 'images/UretimDigerProfil/AltigenCubuk.jpg'
            case StokMalzemeSekilTip.KareKutuProfil:
                return 'images/UretimDigerProfil/KareKutuProfil.jpg'
            case StokMalzemeSekilTip.DikdortgenKutuProfil:
                return 'images/UretimDigerProfil/DikdortgenKutuProfil.jpg'
            case StokMalzemeSekilTip.LKosebent:
                return 'images/UretimDigerProfil/LKosebent.jpg'
            case StokMalzemeSekilTip.TProfil:
                return 'images/UretimDigerProfil/TProfil.jpg'
            case StokMalzemeSekilTip.UProfil:
                return 'images/UretimDigerProfil/UProfil.jpg'
            case StokMalzemeSekilTip.BoruProfil:
                return 'images/UretimDigerProfil/BoruProfil.jpg'

            case StokMalzemeSekilTip.Profil:
                return 'images/UretimDigerProfil/KareKutuProfil.jpg'
            case StokMalzemeSekilTip.BoyaliProfil:
                return 'images/UretimDigerProfil/KareKutuProfil.jpg'
            case StokMalzemeSekilTip.CProfil:
                return 'images/UretimDigerProfil/CProfil.jpg'
            case StokMalzemeSekilTip.ZProfil:
                return 'images/UretimDigerProfil/ZProfil.jpg'
            case StokMalzemeSekilTip.OmegaProfil:
                return 'images/UretimDigerProfil/OmegaProfil.jpg'
            case StokMalzemeSekilTip.BoyaliBoru:
                return 'images/UretimDigerProfil/BoruProfil.jpg'
        }
    },
    getMalzemeResimDetay(malzemeSekilTip?: StokMalzemeSekilTip) {
        switch (malzemeSekilTip) {
            case StokMalzemeSekilTip.Ozel:
                return 'images/UretimDigerProfil/DetayResimleri/Ozel.jpg'
            case StokMalzemeSekilTip.LamaCubuk:
                return 'images/UretimDigerProfil/DetayResimleri/LamaCubuk.jpg'
            case StokMalzemeSekilTip.PlakaLevha:
                return 'images/UretimDigerProfil/DetayResimleri/PlakaLevha.jpg'
            case StokMalzemeSekilTip.KalinPlakaLevha:
                return 'images/UretimDigerProfil/DetayResimleri/KalinPlakaLevha.jpg'
            case StokMalzemeSekilTip.RuloLevha:
                return 'images/UretimDigerProfil/DetayResimleri/RuloLevha.jpg'
            case StokMalzemeSekilTip.KareCubuk:
                return 'images/UretimDigerProfil/DetayResimleri/KareCubuk.jpg'
            case StokMalzemeSekilTip.YuvarlakCubuk:
                return 'images/UretimDigerProfil/DetayResimleri/YuvarlakCubuk.jpg'
            case StokMalzemeSekilTip.AltigenCubuk:
                return 'images/UretimDigerProfil/DetayResimleri/AltigenCubuk.jpg'
            case StokMalzemeSekilTip.KareKutuProfil:
                return 'images/UretimDigerProfil/DetayResimleri/KareKutuProfil.jpg'
            case StokMalzemeSekilTip.DikdortgenKutuProfil:
                return 'images/UretimDigerProfil/DetayResimleri/DikdortgenKutuProfil.jpg'
            case StokMalzemeSekilTip.LKosebent:
                return 'images/UretimDigerProfil/DetayResimleri/LKosebent.jpg'
            case StokMalzemeSekilTip.TProfil:
                return 'images/UretimDigerProfil/DetayResimleri/TProfil.jpg'
            case StokMalzemeSekilTip.UProfil:
                return 'images/UretimDigerProfil/DetayResimleri/UProfil.jpg'
            case StokMalzemeSekilTip.BoruProfil:
                return 'images/UretimDigerProfil/DetayResimleri/BoruProfil.jpg'

        }
    }

}