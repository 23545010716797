import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"color":"#C00","margin-left":"4px"}
}
const _hoisted_2 = {
  key: 1,
  style: {"color":"#0C0","margin-left":"4px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NDropDownListBox = _resolveComponent("NDropDownListBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!

  return (_openBlock(), _createBlock(_component_NLoadingPanel, {
    ref: "loadingPanel",
    action: _ctx.load,
    title: "Trendyol özellikleri yükleniyor..."
  }, {
    default: _withCtx(() => [
      _createVNode(_component_NFormGrid, {
        cols: _ctx.cols,
        title: "Trendyol Özellikleri"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trendyolOzellikler.categoryAttributes, (attribute, i) => {
            return (_openBlock(), _createBlock(_component_NFormField, {
              "grid-cols": "150px 1fr",
              label: attribute.attribute.name + ' (' + attribute.attributeValues.length + ')',
              key: i
            }, {
              label: _withCtx(() => [
                _createElementVNode("div", null, [
                  (attribute.required)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
                    : _createCommentVNode("", true),
                  (attribute.varianter)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2, "V"))
                    : _createCommentVNode("", true)
                ])
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_NDropDownListBox, {
                    "accept-custom-value": attribute.allowCustom,
                    text: attribute.value,
                    "onUpdate:text": ($event: any) => ((attribute.value) = $event),
                    onChange: _ctx.trendyolToNesilce,
                    "selected-value": attribute.valueId,
                    "onUpdate:selectedValue": ($event: any) => ((attribute.valueId) = $event),
                    items: attribute.attributeValues
                  }, null, 8, ["accept-custom-value", "text", "onUpdate:text", "onChange", "selected-value", "onUpdate:selectedValue", "items"])
                ])
              ]),
              _: 2
            }, 1032, ["label"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["cols"])
    ]),
    _: 1
  }, 8, ["action"]))
}