import {defineStore} from "pinia";
import {FirmaEntity} from "@/api/models/FirmaEntity";
import {useFirmaService} from "@/api/services/FirmaService";

export const usedCacheFirmaService = defineStore({
    state: () => ({
        firma: {} as FirmaEntity,
    }),
    id: "usedCacheFirmaService",
    actions: {
        async getFirma(): Promise<FirmaEntity> {
            if (this.firma && this.firma.id && this.firma.id.length > 0) {
                return this.firma;
            }else {
                this.firma = await useFirmaService().get();
                return this.firma;
            }
        },
        async refresh():Promise<FirmaEntity>{
            this.firma = await useFirmaService().get();
            return  this.firma;
        }
    }
});