
import { computed, defineComponent, reactive, ref, toRefs } from "vue";

import { SubeEntity } from "@/api/models/SubeEntity";
import { useStokService } from "@/api/services/StokService";
import { StokView } from "@/api/models/StokView";
import { uuid } from "vue-uuid";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import { useStokHareketService } from "@/api/services/StokHareketService";
import NAlert from "@/components/Alert/NAlert.vue";

import { useStokStore } from "@/modules/Stok/store/StokStore";


export default defineComponent({
  components: { NAlert, NLoadingPanel },
  props: {
    modelValue: {
      type: Object
    }
  },
  setup(props) {

    const localState = reactive({
      stok: computed(() => props.modelValue as StokView),
      subeler: ref([] as SubeEntity[]),
      selectedColumn: ref(""),
      countStokHareket: ref(0)
    });

    async function load() {
      localState.countStokHareket = await useStokHareketService().getCountStokHareket(localState.stok.id as string);
    }

    // function getFlatDevirler() {
    //
    //   const flatList: any = [];
    //   if (localState.stok.items)
    //     localState.stok.items.forEach((item) => {
    //       if (item.devirler)
    //         item.devirler.forEach((devir, i) => {
    //           flatList.push({
    //             id: uuid.v4(),
    //             item: item,
    //             devir: devir
    //
    //           });
    //         });
    //     });
    //   return flatList;
    // }

    function gridEditing(e: any) {
      localState.selectedColumn = e.column.name;
    }

    // async function gridUpdatedVaryantsiz(e: any) {
    //   if (isUpdateMode()) {
    //     const devirItem = e.data as StokDevirItem;
    //     devirMaliyetiHesapla(devirItem);
    //     await useStokService().putDevirGuncelleVaryantsiz(devirItem, localState.stok.id as string);
    //   }
    // }

    // async function gridUpdated(e: any) {
    //   if (isUpdateMode()) {
    //
    //     const devirItem = e.data.devir as StokDevirItem;
    //     const item = e.data.item;
    //
    //     devirMaliyetiHesapla(devirItem);
    //     await useStokService().putDevirGuncelleVaryantli(devirItem, localState.stok.id as string, item.id as string);
    //   }
    // }

    function isUpdateMode() {
      return localState.stok && localState.stok.id && localState.stok.id.length > 0;
    }

    // function  devirMaliyetiHesapla(devirItem:StokDevirItem){
    //   if (devirItem.devirMaliyeti == 0) {
    //     if (localState.stok.alisKdvDahil)
    //       devirItem.devirMaliyeti = localState.stok.alisFiyat! / (1 + (localState.stok.alisKdv! / 100));
    //     else
    //       devirItem.devirMaliyeti = localState.stok.alisFiyat!;
    //   }
    // }
    return {
      ...toRefs(localState),
      load,
      gridEditing,

    };
  }
});

