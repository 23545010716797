import { Component, createApp } from "vue";
import * as GlobalComponents from "@/components";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.softblue.css";
//import "jquery-ui/themes/base/all.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.light.css";
import "@devexpress/analytics-core/dist/css/dx-querybuilder.css";
import "devexpress-reporting/dist/css/dx-webdocumentviewer.css";
import "devexpress-reporting/dist/css/dx-reportdesigner.css";

import {
  DxColumn,
  DxColumnChooser,
  DxDataGrid,
  DxFilterRow,
  DxHeaderFilter,
  DxLoadPanel,
  DxScrolling,
  DxSearchPanel,
  DxEditing,
  DxSelection,
  DxForm,
  DxFormItem,
  DxKeyboardNavigation,
  DxColumnLookup,
  DxLookup,
  DxButton,
  DxExport,
  DxMasterDetail,
  DxGroupPanel,
  DxGrouping,
  DxSummary,
  DxTotalItem,
  DxPaging,
  DxPager,
  DxStateStoring,
  DxGroupItem,
  DxRowDragging
} from "devextreme-vue/data-grid";

import { DxTreeList, DxColumn as DxTreeListColumn } from "devextreme-vue/tree-list";

import DxTreeView from "devextreme-vue/tree-view";
import DxDropDownBox from "devextreme-vue/drop-down-box";
import {
  DxResizable,
  DxSortable,
  DxAutocomplete,
  DxCheckBox,
  DxTextBox,
  DxSelectBox,
  DxList,
  DxDateBox,
  DxRadioGroup,
  DxToolbar as DxDataGridToolbar, DxTileView, DxDropDownButton, DxMenu, DxPopover
} from "devextreme-vue";
import dxDataGrid, { dxDataGridColumn } from "devextreme/ui/data_grid";
import { DxNumberBox } from "devextreme-vue/number-box";
import { DxPopup, DxPosition, DxToolbarItem } from "devextreme-vue/popup";
import trMessages from "devextreme/localization/messages/tr.json";
import { locale, loadMessages } from "devextreme/localization";
import dxAutocomplete from "devextreme/ui/autocomplete";
import { DxScrollView } from "devextreme-vue/scroll-view";

import {
  DxHtmlEditor,
  DxToolbar,
  DxMediaResizing,
  DxItem
} from "devextreme-vue/html-editor";
import { DxLabel, DxSimpleItem } from "devextreme-vue/form";
import dxDateBox from "devextreme/ui/date_box";
import dxTreeList from "devextreme/ui/tree_list";
import { renderTemplate } from "knockout";
import component from "*.vue";
import { useUserSettingsService } from "@/api/services/UserSettingsService";
import DevExpress from "devextreme";
import dxSelectBox = DevExpress.ui.dxSelectBox;
import dxNumberBox from "devextreme/ui/number_box";
import { Benchmark } from "@/utils/benchmark/Benchmark";
import dxScrollView from "devextreme/ui/scroll_view";
import dxTextBox from "devextreme/ui/text_box";
import {uuid} from "vue-uuid";

export default {
  install: (app: ReturnType<typeof createApp>) => {

    loadMessages(trMessages);
    locale(navigator.language);

    app.component("DxDataGrid", DxDataGrid);
    app.component("DxSelection", DxSelection);
    app.component("DxColumn", DxColumn);
    app.component("DxLoadPanel", DxLoadPanel);
    app.component("DxScrolling", DxScrolling);
    app.component("DxHeaderFilter", DxHeaderFilter);
    app.component("DxSearchPanel", DxSearchPanel);
    app.component("DxFilterRow", DxFilterRow);
    app.component("DxColumnChooser", DxColumnChooser);
    app.component("DxEditing", DxEditing);
    app.component("DxKeyboardNavigation", DxKeyboardNavigation);
    app.component("DxColumnLookup", DxColumnLookup);
    app.component("DxLookup", DxLookup);
    app.component("DxSelectBox", DxSelectBox);
    app.component("DxButton", DxButton);
    app.component("DxTreeList", DxTreeList);
    app.component("DxTreeView", DxTreeView);
    app.component("DxTreeListColumn", DxTreeListColumn);
    app.component("DxMasterDetail", DxMasterDetail);
    app.component("DxGroupPanel", DxGroupPanel);
    app.component("DxGrouping", DxGrouping);
    app.component("DxList", DxList);
    app.component("DxSummary", DxSummary);
    app.component("DxTotalItem", DxTotalItem);
    app.component("DxNumberBox", DxNumberBox);
    app.component("DxDateBox", DxDateBox);
    app.component("DxDropDownBox", DxDropDownBox);
    app.component("DxAutocomplete", DxAutocomplete);
    app.component("DxTextBox", DxTextBox);
    app.component("DxHtmlEditor", DxHtmlEditor);
    app.component("DxMediaResizing", DxMediaResizing);
    app.component("DxToolbar", DxToolbar);
    app.component("DxItem", DxItem);
    app.component("DxSimpleItem", DxSimpleItem);
    app.component("DxCheckBox", DxCheckBox);
    app.component("DxPopup", DxPopup);
    app.component("DxForm", DxForm);
    app.component("DxFormItem", DxFormItem);
    app.component("DxExport", DxExport);
    app.component("DxLabel", DxLabel);
    app.component("DxPaging", DxPaging);
    app.component("DxPager", DxPager);
    app.component("DxRadioGroup", DxRadioGroup);
    app.component("DxStateStoring", DxStateStoring);
    app.component("DxGroupItem", DxGroupItem);
    app.component("DxButton", DxButton);
    app.component("DxSortable", DxSortable);
    app.component("DxPosition", DxPosition);
    app.component("DxToolbarItem", DxToolbarItem);
    app.component("DxRowDragging", DxRowDragging);
    app.component("DxScrollView ", DxScrollView);
    app.component("DxResizable ", DxResizable);
    app.component("DxDataGridToolbar ", DxDataGridToolbar);
    app.component("DxTileView", DxTileView);
    app.component("DxDropDownButton", DxDropDownButton);
    app.component("DxMenu", DxMenu);
    app.component("DxPopover", DxPopover);

    dxTextBox.defaultOptions({
      options:{
          name:uuid.v4()
      }
    })


    dxAutocomplete.defaultOptions({
      options: {
        minSearchLength: 0,
        showClearButton: true,
        showDropDownButton: false,
        openOnFieldClick:true
        //maxItemCount:100,
      }
    });
    dxDateBox.defaultOptions({
      options: {
        forceIsoDateParsing: true,
        onInitialized:(e:any)=>{
          const dateBox: any = e.component.instance()

          e.element.addEventListener("keydown",(i:KeyboardEvent)=>{
            if (i.key == "Enter" && i.target){
              const input = (i.target as HTMLInputElement);
              if (input.value?.length == 8) {
                const dateString = `${input.value.substring(2, 4)}.${input.value.substring(0, 2)}.${input.value.substring(4, 8)}`
                try {
                  dateBox.option({value: new Date(dateString).toISOString()})
                } catch {
                  dateBox.option({value: ''})
                }
              }

            }

          })
        },
        onDisposing:(e:any) => {
          if (e && e.element)
            e.element.removeEventListener("keydown", () => {});
        }

      }
    });


    //<DxKeyboardNavigation :edit-on-key-press="true" enter-key-action="moveFocus" enter-key-direction="column"/>

    dxDataGrid.defaultOptions({

      options: {
        bindingOptions: {},
        keyboardNavigation: {
          editOnKeyPress: true,
          enterKeyAction: "moveFocus",
          enterKeyDirection: "column"
        },
        showBorders: true,
        showRowLines: true,
        allowColumnResizing: true,
        allowColumnReordering: true,
        columnResizingMode: "widget",
        paging: { enabled: false },
        pager: { visible: false },
        scrolling: { mode: "virtual", rowRenderingMode:"virtual", showScrollbar:'always' },
        rowAlternationEnabled: true,
        groupPanel: { allowColumnDragging: true, visible: "false" },
        hoverStateEnabled: true,
        repaintChangesOnly: true,
        highlightChanges: true,
        renderAsync: true,
        columnWidth: 150,
        cacheEnabled:false,
        export: {
          allowExportSelectedData: true,
          enabled: true,
          texts: {
            exportAll: "Tümünü Excele Aktar",
            exportSelectedRows: "Seçilenleri Excele Aktar",
            exportTo: "Export"
          }
        },
        stateStoring: {
          customLoad: async function() {
            const t = this as any;
            if (t && t.storageKey && t.storageKey.length > 0) {
              const state = await useUserSettingsService().getGetValue(t.storageKey);
              return state;
            }

          },
          customSave: async function(state: any) {
            const t = this as any;
            if (t && t.storageKey && t.storageKey.length > 0) {
              const colums = (state.columns as dxDataGridColumn[]).map(a => ({
                name: a.name,
                columns: a.columns,
                width: a.width,
                dataField: a.dataField,
                visible: a.visible,
                visibleIndex: a.visibleIndex
              } as dxDataGridColumn));

              await useUserSettingsService().postSave({
                name: t.storageKey,
                value: JSON.stringify({ columns: colums })
              });
            }
          },
          getThis() {
            return this;
          },
          enabled: false,
          savingTimeout: 2000,
          type: "custom"
        },
        onToolbarPreparing: (e: any) => {

          // Tab değişimlerde tablarda ki scroll bar ilk haline geri dönüyor ve virtual scroll ilk halde ki datayı render etmiyor.
          // datagrid.instance.updateScroll() methodu onActivate() hook ile çağrıldığında datagrid scroll olması gereken konuma setleniyor.
          const component = e.component;
          //console.log("Toolbar prepharing")

          setTimeout(async () => {
            let scrollOffset = component?.getScrollable()?.scrollOffset();

            component?.getScrollable()?.on("scroll", (s: any) => {
              scrollOffset = s.scrollOffset;
            });


            component.updateScroll = async () => {
              //await component.repaint();

              //  console.log(scrollOffset)
                await component.getScrollable()?.scrollBy(scrollOffset);
                await component.repaint();

            };

          }, 300);


          const findTemplate = e.component._options?._optionManager?._options?.toolbarTemplate;


          if (findTemplate) {

            e.toolbarOptions.items.unshift({
              location: "before",
              template: findTemplate
            });
          }
        }
      },
    });

    dxNumberBox.defaultOptions({
      options: {
        //format="#,##0.####"
      }
    });
    dxTreeList.defaultOptions({
      options: {
        allowColumnReordering: true,
        allowColumnResizing: true,
        columnResizingMode: "widget",
        paging: { enabled: false },
        pager: { visible: false },
        scrolling: { mode: "virtual" },
        rowAlternationEnabled: true,
        groupPanel: { allowColumnDragging: true, visible: "false" },
        hoverStateEnabled: true,
        repaintChangesOnly: true,
        highlightChanges: true,
        renderAsync: true,
        export: {
          allowExportSelectedData: true,
          enabled: true,
          texts: {
            exportAll: "Tümünü Excele Aktar",
            exportSelectedRows: "Seçilenleri Excele Aktar",
            exportTo: "Export"
          }
        },
        onToolbarPreparing: (e: any) => {

          // const component = e.component;
          // setTimeout(()=>{
          //   let scrollOffset = {
          //     top:0,
          //     left:0
          //   };
          //
          //   component.getScrollable().on('scroll',(s:any)=>{
          //     scrollOffset = s.scrollOffset;
          //   })
          //
          //   component.updateScroll = ()=>{
          //     component.getScrollable().scrollBy(scrollOffset);
          //     //component.repaint();
          //   }
          //
          // },1000)

          const component = e.component;
          //console.log("Toolbar prepharing")

          setTimeout(async () => {
            let scrollOffset = component?.getScrollable()?.scrollOffset();

            component?.getScrollable()?.on("scroll", (s: any) => {
              scrollOffset = s.scrollOffset;
            });


            component.updateScroll = async () => {
              //await component.repaint();

              //  console.log(scrollOffset)
              await component.getScrollable()?.scrollBy(scrollOffset);
              await component.repaint();

            };

          }, 300);

          const findTemplate = e.component._options?._optionManager?._options?.toolbarTemplate;


          if (findTemplate) {

            e.toolbarOptions.items.unshift({
              location: "before",
              template: findTemplate
            });
          }
        }
      }
    });
  }
};
