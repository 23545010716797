
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import CariFormAdres from "@/modules/Cari/components/CariForm/CariFormAdres.vue";
import NCard from "@/components/Card/NCard.vue";
import {CariView} from "@/api/models/CariView";
import {AdresTip} from "@/api/models/AdresTip";

export default defineComponent({
  components: { NCard, CariFormAdres},
  props:['modelValue','adresler'],
  setup(props) {
    const localState = reactive({
      selectedTab:ref(0),
      model:computed(()=> props.modelValue as CariView),
      cardRef:ref(),
      tabs:ref([] as any[])

    })

    function load() {

      localState.model.adresler?.forEach((a,i)=>{
        addTab(i);
      })
    }


    function addTab(i:number){
      localState.tabs.push({

        label:"Adres " + (i + 1)
      })
    }

    load()

    function adresEkle() {
      localState.model.adresler?.push({
        tipi:AdresTip.Fatura
      });

      addTab((localState.model.adresler?.length as number) - 1)
      localState.selectedTab = (localState.model.adresler?.length as number) -1
      revize()
    }

    function adresiSil() {
      localState.model.adresler?.splice(localState.selectedTab,1);
      localState.tabs?.splice(localState.selectedTab,1);
      revize()
      localState.selectedTab = 0;
    }

    function revize() {
      localState.tabs.forEach((a,i)=> a.label = "Adres " + (i+1) )
    }

    return {
      ...toRefs(localState),
      adresEkle,
      adresiSil
    }
  }
})
