
import {computed, defineComponent, reactive, toRefs} from "vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import StokHareketList from "@/modules/Stok/components/StokHareketList.vue";
import NTag from "@/components/Tag/NTag.vue";
import NIconIonic from "@/components/NIconIonic.vue";
import {StokUtils} from "@/modules/Stok/utils/StokUtils";

export default defineComponent({
  components: {NIconIonic, NTag, StokHareketList, NModalDialog},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    stokAdi: {
      type: String,
      default: '',
    },
    stokId: {
      type: String,
      default: '',
    },
    itemId: {
      type: String,
      default: '',
    },
    cariId: {
      type: String,
      default: '',
    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      showDialogRef: computed({
        get: () => props.show,
        set: val => emit('update:show', val),
      }),
      stokIdRef: computed(() => props.stokId),
      itemIdRef: computed(() => props.itemId),
      cariIdRef: computed(() => props.cariId),
    })

    function  openStokDialog() {
        StokUtils.openStokForm(localState.stokIdRef as string, false)
    }

    return {
      ...toRefs(localState),
        openStokDialog
    };
  }
});
