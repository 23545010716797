import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {IdName} from '../models/IdName'
import {Doviz} from '../models/Doviz'
import {PersonelIsTakipDurumItem} from '../models/PersonelIsTakipDurumItem'
import {PersonelIsTakipStokItem} from '../models/PersonelIsTakipStokItem'
import {PersonelGunlukItem} from '../models/PersonelGunlukItem'
import {DocumentItem} from '../models/DocumentItem'
import {PersonelIsTakipView} from '../models/PersonelIsTakipView'
import {RecordStatus} from '../models/RecordStatus'
import {PersonelIsTakipDurum} from '../models/PersonelIsTakipDurum'
import {FaturaHareketTip} from '../models/FaturaHareketTip'
import {FaturaTip} from '../models/FaturaTip'
import {FaturaUstBilgiItem} from '../models/FaturaUstBilgiItem'
import {TeslimatAdresItem} from '../models/TeslimatAdresItem'
import {FaturaSatirItem} from '../models/FaturaSatirItem'
import {FaturaAltBilgiItem} from '../models/FaturaAltBilgiItem'
import {PlanVadelerItem} from '../models/PlanVadelerItem'
import {FaturaEFaturaItem} from '../models/FaturaEFaturaItem'
import {SmsMailKargoGonderItem} from '../models/SmsMailKargoGonderItem'
import {FaturaView} from '../models/FaturaView'
import {SiparisTip} from '../models/SiparisTip'
import {SiparisHareketTip} from '../models/SiparisHareketTip'
import {SiparisAdresItem} from '../models/SiparisAdresItem'
import {SiparisSatirItem} from '../models/SiparisSatirItem'
import {FaturaBilgiItem} from '../models/FaturaBilgiItem'
import {SiparisView} from '../models/SiparisView'

export const usePersonelIsTakipService = defineStore({
id: 'PersonelIsTakipService',
actions: {


	async getGet(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PersonelIsTakip/Get/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getView()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PersonelIsTakip/View/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetAll()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PersonelIsTakip/GetAll/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetAllByTarih(baslangic:string,bitis:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PersonelIsTakip/GetAllByTarih/${shared.firm.id}/${baslangic}/${bitis}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postInsert(personelIsTakipView:PersonelIsTakipView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PersonelIsTakip/Insert/${shared.firm.id}`,{ method:'POST', data:personelIsTakipView });
		await request();
		return response.value;
	},

	async putUpdate(personelIsTakipView:PersonelIsTakipView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PersonelIsTakip/Update/${shared.firm.id}`,{ method:'PUT', data:personelIsTakipView });
		await request();
		return response.value;
	},

	async deleteDelete(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PersonelIsTakip/Delete/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postInsertIsDurum(personelIsTakipDurumItem:PersonelIsTakipDurumItem,id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PersonelIsTakip/InsertIsDurum/${shared.firm.id}/${id}`,{ method:'POST', data:personelIsTakipDurumItem });
		await request();
		return response.value;
	},

	async deleteUndoIsDurum(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PersonelIsTakip/UndoIsDurum/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postIsToFaturaView(liste:string[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PersonelIsTakip/IsToFaturaView/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data: liste });
		await request();
		return response.value;
	},

	async postIsToSiparisView(liste:string[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PersonelIsTakip/IsToSiparisView/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data: liste });
		await request();
		return response.value;
	},

	async postFaturalandir(faturaView:FaturaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PersonelIsTakip/Faturalandir/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:faturaView });
		await request();
		return response.value;
	},

	async deleteFaturaSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PersonelIsTakip/FaturaSil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postSiparisVer(siparisView:SiparisView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PersonelIsTakip/SiparisVer/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:siparisView });
		await request();
		return response.value;
	},

	async deleteSiparisSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/PersonelIsTakip/SiparisSil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	}
}
});
