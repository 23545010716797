import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NCheckbox = _resolveComponent("NCheckbox")!
  const _component_NTag = _resolveComponent("NTag")!
  const _component_NFormField = _resolveComponent("NFormField")!

  return (_openBlock(), _createBlock(_component_NFormField, _mergeProps(_ctx.$attrs, {
    "grid-cols": "160px 50px 1fr 1fr 1fr",
    gap: "10px",
    label: _ctx.getLabel()
  }), {
    default: _withCtx(() => [
      _createVNode(_component_NCheckbox, {
        title: "Borç/Alacaktan Düşmesini İstiyor Musunuz ? ",
        icon: "plus",
        type: "black",
        "model-value": _ctx.modelRef.borcAlacaktanDussunmu,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modelRef.borcAlacaktanDussunmu) = $event)),
        disabled: _ctx.alacakBorcOzet.length == 0 ,
        pulse: true,
        label: " "
      }, null, 8, ["model-value", "disabled"]),
      _createVNode(_component_NTag, {
        filled: true,
        type: "black"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Yerel Tutar : " + _toDisplayString(_ctx.toplamKalanYerelTutar.format(2,2)) + " ₺ ", 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 16, ["label"]))
}