import {defineStore} from "pinia";
import {UlkeEntity} from "@/api/models/UlkeEntity";
import {useAdresService} from "@/api/services/AdresService";
import {TevkifatOranlariListe} from "@/api/models/TevkifatOranlariListe";
import {useListelerService} from "@/api/services/ListelerService";
import {KdvIstisnaListe} from "@/api/models/KdvIstisnaListe";
import {VergiView} from "@/api/models/VergiView";
import { UlkeView } from "@/api/models/UlkeView";

export const useGenelCache = defineStore({
    state: () => ({
        adresler: [] as UlkeEntity[],
        mahalleler : [] as UlkeView[],
        tevkifatlar: [] as TevkifatOranlariListe[],
        kdvIstisnalar: [] as KdvIstisnaListe[],
        vergiler: [] as VergiView[],
    }),
    id: "useGenelCache",
    actions: {
        async getUlkeList(): Promise<UlkeEntity[]> {
            if (this.adresler.length == 0) {
                this.adresler = await useAdresService().getUlkeler() as UlkeEntity[];
                return this.adresler
            }
            return this.adresler;
        },
        async getMahalleList(): Promise<UlkeView[]> {
            if (this.mahalleler.length == 0) {
                this.mahalleler = await useAdresService().getFlatUlkeler() as UlkeView[];
                return this.mahalleler
            }
            return this.mahalleler;
        },
        async getTevkifatList() : Promise<TevkifatOranlariListe[]>{
            if (this.tevkifatlar.length == 0) {
                this.tevkifatlar = await useListelerService().getTevkifatOranListe();
                return this.tevkifatlar
            }
            return this.tevkifatlar;
        },
        async getKdvIstisnaList() : Promise<KdvIstisnaListe[]>{
            if (this.kdvIstisnalar.length == 0) {
                this.kdvIstisnalar = await useListelerService().getKdvIstisnaListe();
                return this.kdvIstisnalar
            }
            return this.kdvIstisnalar;
        },
        async getVergiList() : Promise<VergiView[]>{
            if (this.vergiler.length == 0) {
                this.vergiler = await useListelerService().getVergiListesi();
                return this.vergiler
            }
            return this.vergiler;
        }



    }


})
