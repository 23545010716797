import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02cd0cd0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "n-action-tray"
}
const _hoisted_2 = { class: "n-action-tray__selected" }
const _hoisted_3 = { class: "n-action-tray__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modal-container" }, [
    _createVNode(_Transition, { name: "drop-top" }, {
      default: _withCtx(() => [
        (_ctx.visible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_NButton, {
                class: "close-btn",
                icon: "x",
                "icon-size": "24px",
                onClick: _ctx.close
              }, null, 8, ["onClick"]),
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.selected ?? 0) + " satır seçildi. " + _toDisplayString(_ctx.label), 1),
              _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}