
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import HizmetMasrafSelectBox from "@/modules/HizmetMasraf/components/HizmetMasrafSelectBox.vue";
import FaturaSatirItemExtend from "@/modules/Fatura/models/FaturaSatirItemExtend";
import {FaturaSatirTip} from "@/api/models/FaturaSatirTip";
import {Doviz} from "@/api/models/Doviz";
import GiderYeriSelectBox from "@/modules/Ortak/components/GiderYeriSelectBox.vue";
import NFormFieldLabelTop from "@/components/FormField/NFormFieldLabelTop.vue";

export default defineComponent({
  components: {
    NFormFieldLabelTop,
    GiderYeriSelectBox,
    HizmetMasrafSelectBox,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    depoId:{
      type:String,
    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      depoIdRef:computed(()=>props.depoId),
      kdvHesapRef: ref([] as KdvHesaplama[]),
      showRef: computed({
        get: () => props.show,
        set: val => emit('update:show', val),
      }),
      satirlarRef:ref([] as FaturaSatirItemExtend[]),

    })

    function ekle() {
      localState.kdvHesapRef.push({
        kdvTutar: 1, kdv: 10
      });
    }

    function kdvToplam(): number {
      let kdvLiToplam = 0;
      localState.kdvHesapRef.forEach(a => {
        kdvLiToplam += a.kdvTutar!;
      })
      return kdvLiToplam;
    }

    function kdvMatrahToplam(): number {
      let kdvMatrahToplami = 0;
      localState.kdvHesapRef.forEach(a => {
        if (a.kdv && a.kdv > 0 && a.kdvTutar && a.kdvTutar > 0)
          kdvMatrahToplami += a.kdvTutar * 100 / a.kdv;
      })
      return kdvMatrahToplami;
    }

    class KdvHesaplama{
      hizmetAdi?:string;
      hizmetMasrafId?:string;
      kdv?:number;
      kdvTutar?:number;
      giderYeri?:string;
    }

    function faturayaAktar(){
      localState.satirlarRef = [];
      localState.showRef = false;
      if (localState.kdvHesapRef  && localState.kdvHesapRef.length > 0){
        localState.kdvHesapRef.forEach(item => {
          const satir ={
            kdv :item.kdv,
            kur : 1 ,
            giderYeri : item.giderYeri,
            stokId : item.hizmetMasrafId,
            tip : FaturaSatirTip.HizmetMasraf,
            ad : item.hizmetAdi,
            doviz : Doviz.TRY,
            birim : "Adet",
            carpan : 1 ,
            fiyat : item.kdvTutar! * 100 / item.kdv!,
            miktar : 1 ,
            tevkifatOran : 0 ,
            depoId : localState.depoIdRef,
          }as FaturaSatirItemExtend;

          localState.satirlarRef.push(satir);
        })

      }

      emit("hizmasrafEkleClick" ,localState.satirlarRef);
    }
    return {
      ...toRefs(localState),
      kdvToplam,
      kdvMatrahToplam,
      faturayaAktar,
      ekle
    };
  }
});
