
import { defineComponent } from "vue";

export default defineComponent({
  name: "NListView",
  props: {
    items: {
      type: Object
    },
    size: {
      type: String,
      default: "normal"
    },
    modelValue: {
      type: Number,
      default: 0
    },
    emptyMessage: {
      type: String,
      default: "Liste Boş"
    }
  },
  setup(props, { emit }) {
    function selectItem(index: number) {
      emit("update:modelValue", index);
    }

    return {
      selectItem
    };
  }
});
