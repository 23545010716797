import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "dx-card",
  style: {"width":"100%","height":"100%"}
}
const _hoisted_2 = { style: {"margin":"-7px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StokAramaInput = _resolveComponent("StokAramaInput")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_ReportDropDownItemList = _resolveComponent("ReportDropDownItemList")!
  const _component_NDropdownMenu = _resolveComponent("NDropdownMenu")!
  const _component_NDropdown = _resolveComponent("NDropdown")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_DxExport = _resolveComponent("DxExport")!
  const _component_DxGroupPanel = _resolveComponent("DxGroupPanel")!
  const _component_DxEditing = _resolveComponent("DxEditing")!
  const _component_DxKeyboardNavigation = _resolveComponent("DxKeyboardNavigation")!
  const _component_DxColumnChooser = _resolveComponent("DxColumnChooser")!
  const _component_DxStateStoring = _resolveComponent("DxStateStoring")!
  const _component_NDxStateStoring = _resolveComponent("NDxStateStoring")!
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_DxTotalItem = _resolveComponent("DxTotalItem")!
  const _component_DxSummary = _resolveComponent("DxSummary")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    modelValue: _ctx.showRef,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showRef) = $event)),
    "close-on-esc": true,
    width: "950px"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_NFormField, {
        label: " ",
        "grid-cols": "1px auto auto auto auto"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_StokAramaInput, {
            onCoklu: _ctx.cokluUrunEkle,
            ref: "stokAramaInput",
            onTek: _ctx.tekUrunEkle
          }, null, 8, ["onCoklu", "onTek"]),
          _createVNode(_component_NDropdown, null, {
            menu: _withCtx(() => [
              _createVNode(_component_NDropdownMenu, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ReportDropDownItemList, {
                    data: _ctx.reportData,
                    "onUpdate:data": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.reportData) = $event)),
                    "report-type": _ctx.reportType.StokBarkod
                  }, null, 8, ["data", "report-type"])
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_NButton, {
                type: "primary",
                icon: "zap"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("İşlemler")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_NButton, {
            type: "success",
            icon: "database",
            click: _ctx.miktarlariCek
          }, {
            default: _withCtx(() => [
              _createTextVNode("Güncel Miktarları Çek")
            ]),
            _: 1
          }, 8, ["click"]),
          _createVNode(_component_NButton, {
            type: "info",
            icon: "database",
            click: _ctx.birAdetIsaretle
          }, {
            default: _withCtx(() => [
              _createTextVNode("1 Adet Yazdır")
            ]),
            _: 1
          }, 8, ["click"])
        ]),
        _: 1
      })
    ]),
    body: _withCtx(() => [
      _createVNode(_component_NGrid, {
        cols: "1fr",
        gap: "0",
        rows: "auto"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_DxDataGrid, {
              scrolling: {mode:'standard' , rowRenderingMode : 'standard' , columnRenderingMode:'standard'  },
              toolbarTemplate: "toolbar",
              ref: "stokBarkodDataGrid",
              "data-source": _ctx.stoklarFlat,
              style: {"width":"100%","height":"450px"}
            }, {
              toolbar: _withCtx(() => [
                _createVNode(_component_NDxStateStoring, { "data-grid-ref": _ctx.stokBarkodDataGrid }, null, 8, ["data-grid-ref"])
              ]),
              birimler: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_DxSelectBox, {
                    onItemClick: ($event: any) => (_ctx.birimChanged(data.data,$event)),
                    value: data.data.birim,
                    "onUpdate:value": ($event: any) => ((data.data.birim) = $event),
                    "data-source": data.data.birimFiyatlari,
                    "display-expr": "birim",
                    "value-expr": "birim"
                  }, null, 8, ["onItemClick", "value", "onUpdate:value", "data-source"])
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_DxExport, { enabled: false }),
                _createVNode(_component_DxGroupPanel, { visible: false }),
                _createVNode(_component_DxEditing, {
                  "allow-updating": true,
                  mode: "cell",
                  "allow-deleting": true,
                  "use-icons": true
                }),
                _createVNode(_component_DxKeyboardNavigation, {
                  "edit-on-key-press": true,
                  "enter-key-action": "moveFocus",
                  "enter-key-direction": "column"
                }),
                _createVNode(_component_DxColumnChooser, {
                  enabled: true,
                  mode: "select"
                }),
                _createVNode(_component_DxStateStoring, {
                  enabled: true,
                  type: "custom",
                  "custom-load": () =>  _ctx.loadState()
                }, null, 8, ["custom-load"]),
                _createVNode(_component_DxColumn, {
                  caption: "Sil",
                  type: "buttons",
                  width: 40
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxButton, { name: "delete" })
                  ]),
                  _: 1
                }),
                _createVNode(_component_DxColumn, {
                  cssClass: "barkodGiris",
                  caption: "Barkod Adedi",
                  "data-field": "kalanMiktar",
                  width: "100"
                }),
                _createVNode(_component_DxColumn, {
                  "allow-editing": true,
                  caption: "Kod",
                  "data-field": "kod"
                }),
                _createVNode(_component_DxColumn, {
                  "allow-editing": true,
                  caption: "Stok Adı",
                  "data-field": "getUrunAdi"
                }),
                _createVNode(_component_DxColumn, {
                  "allow-editing": true,
                  caption: "Barkod",
                  "data-field": "barkodStok"
                }),
                _createVNode(_component_DxColumn, {
                  "allow-editing": true,
                  caption: "Satış Fiyatı",
                  "data-field": "satisFiyat",
                  "data-type": "number",
                  format: "#,##0.00"
                }),
                _createVNode(_component_DxColumn, {
                  "allow-editing": false,
                  caption: "Birim",
                  "data-field": "birim",
                  "cell-template": "birimler"
                }),
                _createVNode(_component_DxColumn, {
                  "allow-editing": false,
                  caption: "Özellikler",
                  "data-field": "ozellikler"
                }),
                _createVNode(_component_DxColumn, {
                  "allow-editing": true,
                  caption: "Grup1",
                  "data-field": "grup1"
                }),
                _createVNode(_component_DxColumn, {
                  "allow-editing": true,
                  caption: "Grup2",
                  "data-field": "grup2"
                }),
                _createVNode(_component_DxColumn, {
                  "allow-editing": true,
                  caption: "Grup3",
                  "data-field": "grup3"
                }),
                _createVNode(_component_DxColumn, {
                  "allow-editing": true,
                  caption: "Grup4",
                  "data-field": "grup4"
                }),
                _createVNode(_component_DxSummary, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxTotalItem, {
                      column: "kalanMiktar",
                      "summary-type": "sum",
                      "display-format": "{0}"
                    }),
                    _createVNode(_component_DxTotalItem, {
                      column: "ad",
                      "summary-type": "count",
                      "display-format": "{0}"
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["data-source"])
          ])
        ]),
        _: 1
      })
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_NButton, {
        type: "warning",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showRef = false))
      }, {
        default: _withCtx(() => [
          _createTextVNode("Kapat")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}