import {defineStore} from "pinia";
import {KurView} from "@/api/models/KurView";
import {useDovizKurService} from "@/api/services/DovizKurService";

export const useKurCachedServis = defineStore({
    state: () => ({
        kurList: [] as KurView[]
    }),
    id: "useKurCachedServis",
    actions: {
        async getKurList(): Promise<KurView[]> {
            if (this.kurList.length == 0) {
                this.kurList = await useDovizKurService().getKurlar() as KurView[];
                return this.kurList
            }
            return this.kurList;
        },
        async refresh() {
            this.kurList = await useDovizKurService().getKurlar() as KurView[];

        }
    }
})
