
export enum EFaturaSenaryoTip{
TANIMSIZ=0,
TICARIFATURA=1,
EARSIVFATURA=2,
TEMELFATURA=3,
TEMELIRSALIYE=4,
IHRACAT=5
}

export const EFaturaSenaryoTipList = [
{key:'TANIMSIZ', value:0, description:'-SEÇİLMEDİ-'},
{key:'TICARIFATURA', value:1, description:'TİCARİ FATURA'},
{key:'EARSIVFATURA', value:2, description:'E-ARŞİV FATURA'},
{key:'TEMELFATURA', value:3, description:'TEMEL FATURA'},
{key:'TEMELIRSALIYE', value:4, description:'TEMEL İRSALİYE'},
{key:'IHRACAT', value:5, description:'İHRACAT FATURA'}
]
