<template>

  <NFormField :grid-cols="cols" :label="label" :span="span" v-bind="$attrs">
    <NInput  :placeholder="placeholder"  autocomplete="off" v-model:type="chkShowPassword" v-model="model"/>
    <NCheckbox v-model="showHide" @change="showHide == true ? chkShowPassword = 'text' : chkShowPassword = 'password' " label="Göster"/>
  </NFormField>

</template>

<script>
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import NInput from "@/components/Input/NInput";
import NCheckbox from "@/components/Checkbox/NCheckbox";
import NFormField from "@/components/FormField/NFormField";

export default defineComponent({
  components:{NFormField, NCheckbox, NInput},
  props:{
    modelValue:{
      type:String,
    },
    label:{
      type:String,
      default:'Şifre'
    },
    placeholder:{
      type:String,
      default : "",
    },

    cols:{
      type:String,
      default:'140px 3fr 1fr',
    },
    span:{
      type:Number,
      default:1
    }
  },
  setup(props , {emit}){
      const localState = reactive({
        chkShowPassword:ref('password'),
        showHide:ref(false),
        model:computed({
          get : () => props.modelValue,
          set : (val) => emit("update:modelValue", val),
        })
      })

      return{
        ...toRefs(localState),

      }
  }


})
</script>

<style scoped>

</style>
