
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import { DepartmanEntity } from "@/api/models/DepartmanEntity";
import NSelectBox from "@/components/SelectBox/NSelectBox.vue";
import { useDepartmanService } from "@/api/services/DepartmanService";

export default defineComponent({
  components: { NSelectBox },
  props: {
    modelValue: {
      type: String,
      default: "null"
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      items: ref([] as DepartmanEntity[]),
      selected: computed({
        get: () => props.modelValue,
        set: val => emit("update:modelValue", val)
      })
    });

    async function load() {
      localState.items = await useDepartmanService().getListe();
    }

    load();

    watch(
      () => localState.selected,
      newSelected => {
        emit("update:modelValue", newSelected);
      }
    );

    return {
      ...toRefs(localState)
    };
  }
});
