import { useFirmaService } from "@/api/services/FirmaService";
import { useRouter } from "vue-router";
import { FirmaRoleDefaultView } from "@/api/models/FirmaRoleDefaultView";


export const useFirmaStore = {
   async getFirma(userName:string) {

    // Local
    const firmaLocal = this.getFirmaLocal(userName);
    if (firmaLocal && firmaLocal.firma && firmaLocal.donem)
      return firmaLocal;

    // Server
    const firmaServer = await useFirmaService().getDefaultFirma();

    if (firmaServer)
      return firmaServer;
    return undefined;
  },

  getFirmaLocal(userName:string) {
    const firmaString = localStorage.getItem('firmaSelected_' + userName);
    if (firmaString && firmaString.length > 5) {
      const firma = JSON.stringify(firmaString) as any;
      if (firma && firma.donem && firma.firma) {
        return firma;
      }
    }
    return undefined;
  },
  setFirma(firma:any, useName:string) {
    const firmaString = JSON.stringify(firma);
    localStorage.setItem('firmaSelected_' + useName, firmaString);
  }
}
