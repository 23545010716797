
import { useFavicon } from "@vueuse/core";
import {computed, defineComponent, onMounted, ref} from "vue";


import { useSignal } from "@/services/useSignal";
import {usePopupService} from "@/services/popupService";
import {useNesilceConfig} from "@/api/NesilceConfig";

export default defineComponent({
  name: "NesilceClient",
  setup() {
    const favicon = process.env.NODE_ENV === "development" ?'favicon-dev.ico':'favicon-prod.ico';
    const isLoaded =  ref(false);
    const popupService = usePopupService();

    const notifications = computed(() => popupService.notifications);

    onMounted(  async () => {
      await useNesilceConfig().load();
      useFavicon(favicon);
       await useSignal().init();
       isLoaded.value = true;
    });
    return {
      notifications,
      isLoaded
    };
  }
});
