import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f53f5cb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "n-dropdown-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NIcon = _resolveComponent("NIcon")!

  return (_openBlock(), _createElementBlock("li", {
    class: _normalizeClass(["n-dropdown-item", [
      _ctx.disabled && 'n-dropdown-item--disabled',
      _ctx.divided && 'n-dropdown-item--divided',
      _ctx.type && `n-dropdown-item--${_ctx.type}`
    ]]),
    style: _normalizeStyle({ padding: _ctx.padding })
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_NIcon, {
          key: 0,
          class: "n-dropdown-icon",
          size: "16",
          type: _ctx.icon
        }, null, 8, ["type"]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 6))
}