import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NDropDownTreeView = _resolveComponent("NDropDownTreeView")!
  const _component_NRefresher = _resolveComponent("NRefresher")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NInputHtml = _resolveComponent("NInputHtml")!
  const _component_NDropDownListCustomStore = _resolveComponent("NDropDownListCustomStore")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_PazaryeriFiyatItem = _resolveComponent("PazaryeriFiyatItem")!
  const _component_EpttAvmOzellikEditor = _resolveComponent("EpttAvmOzellikEditor")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), [
      (_ctx.stokRef && _ctx.eptt)
        ? (_openBlock(), _createBlock(_component_NFormGrid, {
            key: 0,
            cols: "1fr 1fr 1fr",
            title: "EpttAvm'ye Gönder"
          }, {
            actions: _withCtx(() => [
              _createVNode(_component_NFormField, {
                label: "EpttAvm'ye Gönder",
                "grid-cols": "1fr auto"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NSwitch, {
                    modelValue: _ctx.epttRef.isActive,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.epttRef.isActive) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_NFormField, {
                label: "Kategori",
                "grid-cols": "120px 1fr",
                span: "3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NRefresher, {
                    load: ()=>_ctx.load(true)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NDropDownTreeView, {
                        "selected-item": _ctx.selectedKategoriItem,
                        "onUpdate:selectedItem": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedKategoriItem) = $event)),
                        "data-source": _ctx.kategoriler,
                        "onUpdate:dataSource": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kategoriler) = $event)),
                        "display-expr": "title",
                        "key-expr": "key",
                        "items-expr": "children"
                      }, null, 8, ["selected-item", "data-source"])
                    ]),
                    _: 1
                  }, 8, ["load"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                label: "Stok Kodu",
                "grid-cols": "120px 1fr",
                span: "2"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInput, {
                    modelValue: _ctx.epttRef.epttAvmStokKod,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.epttRef.epttAvmStokKod) = $event)),
                    placeholder: "EpttAvm'de kayıtlı stok kodu. Stok EpttAvm'de yoksa boş bırakınız",
                    type: "text"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              (_ctx.stokRef)
                ? (_openBlock(), _createBlock(_component_NFormField, {
                    key: 0,
                    label: "Barkod",
                    "grid-cols": "60px 1fr"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NInput, {
                        modelValue: _ctx.epttRef.barkod,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.epttRef.barkod) = $event)),
                        placeholder: _ctx.stokRef?.barkod
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.stokRef)
                ? (_openBlock(), _createBlock(_component_NFormField, {
                    key: 1,
                    label: "Stok Adı",
                    "grid-cols": "120px 1fr",
                    span: "3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NInput, {
                        modelValue: _ctx.epttRef.ad,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.epttRef.ad) = $event)),
                        placeholder: _ctx.stokRef?.ad
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_ctx.stokRef)
                ? (_openBlock(), _createBlock(_component_NFormField, {
                    key: 2,
                    label: "Kısa Açıklama",
                    "grid-cols": "120px 1fr",
                    span: "3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NInput, {
                        modelValue: _ctx.epttRef.kisaAciklama,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.epttRef.kisaAciklama) = $event)),
                        placeholder: _ctx.stokRef?.kisaAciklama
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_NFormField, {
                label: "Açıklama",
                "grid-cols": "120px 1fr",
                span: "3"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NInputHtml, {
                    modelValue: _ctx.epttRef.aciklama,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.epttRef.aciklama) = $event)),
                    placeholder: _ctx.stokRef?.aciklama
                  }, null, 8, ["modelValue", "placeholder"])
                ]),
                _: 1
              }),
              _createVNode(_component_NFormField, {
                label: "Marka",
                span: "4",
                "grid-cols": "120px 1fr"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NDropDownListCustomStore, {
                    "display-expr": "title",
                    "selected-item": _ctx.epttRef.marka,
                    "onUpdate:selectedItem": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.epttRef.marka) = $event)),
                    "data-source": _ctx.dataSourceMarkalar
                  }, null, 8, ["selected-item", "data-source"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_PazaryeriFiyatItem, {
        title: "EpttAvm Fiyatı",
        "alis-kdv": _ctx.alisKdvRef,
        "onUpdate:alisKdv": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
        "alis-kdv-dahil": _ctx.alisKdvDahilRef,
        "onUpdate:alisKdvDahil": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
        "satis-kdv": _ctx.satisKdvRef,
        "onUpdate:satisKdv": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
        "satis-kdv-dahil": _ctx.satisKdvDahilRef,
        "onUpdate:satisKdvDahil": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
        "alis-fiyati": _ctx.alisFiyatRef,
        "onUpdate:alisFiyati": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
        "satis-fiyati": _ctx.satisFiyatRef,
        "onUpdate:satisFiyati": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.satisFiyatRef) = $event)),
        "kargo-fiyat": _ctx.epttRef.kargoTutar,
        "onUpdate:kargoFiyat": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.epttRef.kargoTutar) = $event)),
        "komisyon-oran": _ctx.epttRef.komisyon,
        "onUpdate:komisyonOran": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.epttRef.komisyon) = $event)),
        "fiyat-tipi": _ctx.epttRef.fiyat.tip,
        "onUpdate:fiyatTipi": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.epttRef.fiyat.tip) = $event)),
        deger: _ctx.epttRef.fiyat.deger,
        "onUpdate:deger": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.epttRef.fiyat.deger) = $event)),
        "kar-marji": _ctx.epttRef.karMarji,
        "onUpdate:karMarji": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.epttRef.karMarji) = $event)),
        "indirim-tipi": _ctx.epttRef.indirim.tip,
        "onUpdate:indirimTipi": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.epttRef.indirim.tip) = $event)),
        indirim: _ctx.epttRef.indirim.deger,
        "onUpdate:indirim": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.epttRef.indirim.deger) = $event)),
        "alis-doviz": _ctx.stokRef.alisDoviz,
        "onUpdate:alisDoviz": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.stokRef.alisDoviz) = $event)),
        "satis-doviz": _ctx.stokRef.satisDoviz,
        "onUpdate:satisDoviz": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.stokRef.satisDoviz) = $event)),
        maliyet: _ctx.stokRef.getMaliyet,
        "onUpdate:maliyet": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.stokRef.getMaliyet) = $event))
      }, null, 8, ["alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyati", "satis-fiyati", "kargo-fiyat", "komisyon-oran", "fiyat-tipi", "deger", "kar-marji", "indirim-tipi", "indirim", "alis-doviz", "satis-doviz", "maliyet"])
    ], 16),
    (_ctx.epttRef && _ctx.epttRef.kategoriId && _ctx.epttRef.kategoriId > 0)
      ? (_openBlock(), _createBlock(_component_EpttAvmOzellikEditor, {
          key: 0,
          stok: _ctx.stokRef,
          "onUpdate:stok": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.stokRef) = $event)),
          "kategori-id": _ctx.epttRef.kategoriId,
          "onUpdate:kategoriId": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.epttRef.kategoriId) = $event)),
          ozellikler: _ctx.epttRef.ozellikler,
          "onUpdate:ozellikler": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.epttRef.ozellikler) = $event))
        }, null, 8, ["stok", "kategori-id", "ozellikler"]))
      : _createCommentVNode("", true)
  ], 64))
}