
import {computed, defineComponent, onActivated, onMounted, reactive, ref, toRefs, watch} from "vue";
import NCard from "@/components/Card/NCard.vue";
import { useHizliSatisTabsStore } from "@/modules/HizliSatis/store/HizliSatisTabsStore";
import NTagList from "@/components/Tag/NTagList.vue";
import NButton from "@/components/Button/NButton.vue";
import { PerakendeSatirItem } from "@/api/models/PerakendeSatirItem";
import { useCachedSubeDepoService } from "@/modules/Sube/services/useCachedSubeDepoService";
import FiyatGruplariSelectBox from "@/modules/Ortak/components/FiyatGruplariSelectBox.vue";
import { StokBirimItem } from "@/api/models/StokBirimItem";
import { StokBirimUtils } from "@/modules/Stok/utils/StokBirimUtils";
import { StokFlatView } from "@/api/models/StokFlatView";
import { useStokFlatStore } from "@/modules/Stok/store/StokFlatStore";
import { number } from "yup";
import { useStokStore } from "@/modules/Stok/store/StokStore";
import { StokEntity } from "@/api/models/StokEntity";
import { StokView } from "@/api/models/StokView";
import { FiyatUtils } from "@/modules/Stok/store/FiyatUtils";
import { PazaryeriFiyatTipi } from "@/api/models/PazaryeriFiyatTipi";
import { Doviz } from "@/api/models/Doviz";
import NDxStateStoring from "@/devextreme/NDxStateStoring.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import { DevExtremeUtils } from "@/devextreme/DevExtremeUtils";
import dxDataGrid from "devextreme/ui/data_grid";
import ArrayStore from "devextreme/data/array_store";


export default defineComponent({
  components: { NGrid, NDxStateStoring, FiyatGruplariSelectBox, NButton, NTagList, NCard },
  setup(props) {
    // load

    const localState = reactive({
      depoList: ref([] as any[]),
      store: useHizliSatisTabsStore(),
      hizliSatisSatirlarDataGrid: ref()
    });
    onMounted(async () => {
      localState.depoList = await useCachedSubeDepoService().getUserDepoList();
      localState.store.eventTarget.addEventListener("rowInserted",()=>{
        scrollMoveToEnd()
      })
    });



    function rowUpdated(e: any) {
     // console.log(e);
    }

    function satirSil(satir: PerakendeSatirItem) {
      localState.store.selectedTab.arrayStore.push([{ type: "remove", key: satir.id }]);
    }

    // function getBirimlerDataSource(satir:PerakendeSatirItem) {
    //
    //
    //
    //   if (satir.stokId && satir.stokId.length > 0) {
    //
    //     const findStok = localState.stoklar.find(a => a.stokId == satir.stokId);
    //     if (findStok) {
    //       const birimler = Object.assign([], findStok.birimFiyatlari as StokBirimItem[]);
    //       birimler.push({
    //         birim: findStok.birim,
    //         carpan: 1,
    //         getBirimKisaAd: findStok.birim
    //
    //       });
    //       return birimler;
    //     }
    //   }
    // }

    function getEticaretFiyati(stok: StokView) {

      if (stok.presta && stok.presta.length > 0) {
        const presta = stok.presta[0];

        if (presta && presta.fiyat) {

          const fiyatItem = presta.fiyat;
          const fiyat = FiyatUtils.getPazaryeriFiyat(
            localState.store.kurlar,
            fiyatItem.tip as PazaryeriFiyatTipi,
            fiyatItem.deger as number,

            stok.satisDoviz as Doviz,
            stok.satisFiyat as number,
            stok.satisKdv as number,
            stok.satisKdvDahil as boolean,

            stok.alisDoviz as Doviz,
            stok.alisFiyat as number,
            stok.alisKdv as number,
            stok.alisKdvDahil as boolean,

            stok.getMaliyet as number,

            presta.karMarji as number,
            presta.kargoTutar as number,
            presta.komisyon as number);

          return fiyat;

        }
      }
      return 0;
    }

    function changedBirim(e: any, row: PerakendeSatirItem) {


      // Kampanyalı satırlar düzenlenemez
      const stokEntity: StokEntity = useStokStore().getStok(row.stokId as string);
      const eticaretFiyat = getEticaretFiyati(stokEntity);


      if ((row?.kampanyaId?.length as number) > 0)
        return;

      const stok = useStokFlatStore().getStok(row.stokId as string);
      const birimler = stok?.birimFiyatlari as StokBirimItem[];
      if (birimler && stok) {
        const birimAdi = e.value;
        const birim = birimler.find(a => a.birim == birimAdi);

        if (birim) {
          row.birim = birimAdi;
          row.carpan = birim.carpan;
          row.fiyat = StokBirimUtils.getFiyat(
            birim.fiyat!,
            birim.fiyatTipi!,
            stok.satisFiyat!,
            stok.satisKdv!,
            stok.satisKdvDahil!,
            stok.alisFiyat!,
            stok.alisKdv!,
            stok.alisKdvDahil!,
            eticaretFiyat
          );
        } else {
          row.birim = birimAdi;
          row.carpan = 1;
          row.fiyat = stok.satisKdvDahil ? stok.satisFiyat : stok.satisFiyat! * (1 + (stok.satisKdv! / 100));
        }

        // Kampanya Sil
        // kampanyalariTemizle();
      }

    }

    // function getStokAnaBirim(stokId: string) {
    //   if (stokId && stokId.length > 0) {
    //
    //     const findStok = localState.stoklar.find(a => a.stokId == stokId);
    //
    //     if (findStok)
    //       return findStok.birim as string;
    //     else
    //       return "Adet";
    //
    //   }
    //
    //   return "Adet";
    //
    // }

    // watch(() => [
    //   localState.store.selectedTab.cariFiyatGrupId,
    //   localState.store.selectedTab.fiyatGrupTutar,
    //   localState.store.selectedTab.fiyatGrupOran
    // ], () => {
    //
    //
    //   localState.store.setFiyatGrubu(localState.store.selectedTab.model.satirlar as PerakendeSatirItem[]);
    //
    //
    // });
    async function loadState() {
      return await DevExtremeUtils.loadState(localState.hizliSatisSatirlarDataGrid?.instance);
    }


    function scrollMoveToEnd() {
      setTimeout(()=>{
        const dataGrid =  (localState.hizliSatisSatirlarDataGrid?.instance as dxDataGrid);

        dataGrid?.getScrollable().scrollBy(
            {
              top: 100000,
              left: 0,
              behavior: 'smooth'
            }
        );
      },500)
    }
      onActivated(() => {
          DevExtremeUtils.updateScroll(localState.hizliSatisSatirlarDataGrid);
      });


    function miktarChange(e:any,row:any, amount:number) {
        let miktar = row.miktar as number;
        miktar = miktar + amount;
        row.miktar = Math.max(miktar,1);

        e.preventDefault();
        e.stopImmediatePropagation();
    }

    return {
      ...toRefs(localState),
      loadState,
      rowUpdated,
      changedBirim,
      satirSil,
        miktarChange

    };
  }
});
