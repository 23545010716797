
export enum KvkkTip{
OnayVerildi=0,
OnayVerilmedi=1,
IptalEdildi=2
}

export const KvkkTipList = [
{key:'OnayVerildi', value:0, description:'Onay Verildi'},
{key:'OnayVerilmedi', value:1, description:'Onay Verilmedi'},
{key:'IptalEdildi', value:2, description:'İptal Edildi'}
]
