
import { defineComponent, onMounted, nextTick, ref, computed } from "vue";

export default defineComponent({
  name: "NTextarea",
  props: {
    placeholder: String,
    disabled: Boolean,
    resizable: Boolean,
    resizeOnWrite: Boolean,
    labelTop: String,
    rows: { type: Number, default: 4 },
    modelValue: String
  },
  setup(props, { emit }) {
    const textarea = ref(null);
    const focus = ref(false);
    const resize = computed(() =>
      props.resizable === true ? "vertical" : "none"
    );
    function onInput(e: Event) {
      const target = e.target as HTMLTextAreaElement;
      emit("update:modelValue", target.value);
      if (props.resizeOnWrite) {
        target.style.height = "auto";
        target.style.height = target.scrollHeight + "px";
      }
    }
    onMounted(async () => {
      if (props.resizeOnWrite) {
        await nextTick();
        (textarea.value! as HTMLTextAreaElement).style.height =
          (textarea.value! as HTMLTextAreaElement).scrollHeight + "px";
      }
    });
    return { textarea, onInput, resize, focus };
  }
});
