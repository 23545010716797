import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {CicekSepetiValuesView} from '../models/CicekSepetiValuesView'

export const useCicekSepetiDegerlerService = defineStore({
id: 'CicekSepetiDegerlerService',
actions: {


	async getGetirTumu()
	{
		const { request, response } = useApi(`/api/CicekSepetiDegerler/GetirTumu`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetirOzellik(ozellik:string,kategoriId:number)
	{
		const { request, response } = useApi(`/api/CicekSepetiDegerler/GetirOzellik/${ozellik}/${kategoriId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetir(id:string)
	{
		const { request, response } = useApi(`/api/CicekSepetiDegerler/Getir/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const { request, response } = useApi(`/api/CicekSepetiDegerler/Sil/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postEkle(cicekSepetiValuesView:CicekSepetiValuesView)
	{
		const { request, response } = useApi(`/api/CicekSepetiDegerler/Ekle`,{ method:'POST', data:cicekSepetiValuesView });
		await request();
		return response.value;
	}
}
});
