
export enum BasitUretimDurum{
DevamEdiyor=0,
Tamamlandi=1
}

export const BasitUretimDurumList = [
{key:'DevamEdiyor', value:0, description:'Devam Ediyor'},
{key:'Tamamlandi', value:1, description:'Tamamlandı'}
]
