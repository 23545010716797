import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HizliSatisToolbar = _resolveComponent("HizliSatisToolbar")!
  const _component_NViewGrid = _resolveComponent("NViewGrid")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!

  return (_openBlock(), _createBlock(_component_NLoadingPanel, {
    title: "Hızlı satış hazırlanıyor...",
    action: _ctx.load
  }, {
    default: _withCtx(() => [
      (_ctx.tabsStore.isLoaded)
        ? (_openBlock(), _createBlock(_component_NViewGrid, {
            key: 0,
            style: {"background-color":"#FFFFFF","padding":"0px 12px 12px 12px"},
            rows: "auto minmax(0,100%)",
            cols: "1fr"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_HizliSatisToolbar),
              (_openBlock(), _createBlock(_KeepAlive, null, [
                (_ctx.tabsStore.selectedTab)
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tabsStore.selectedTab.component), {
                      key: _ctx.tabsStore.selectedTab.id
                    }))
                  : _createCommentVNode("", true)
              ], 1024))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["action"]))
}