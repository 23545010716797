import { UserLoginView } from "../models/UserLoginView"
import { useAccountService } from "@/api/services/AccountService"
import {ajaxSetup} from "@devexpress/analytics-core/analytics-utils";




export const useAccountServiceExtend = function() {
  return {
    async postLogin(userLoginView: UserLoginView, rememberMe: boolean) {
      const token = await useAccountService().postLogin(userLoginView)
      useAccountService().$patch(token)

      const userName = userLoginView.userName?.toString() ?? "User"

      if (rememberMe) {
       // localStorage.setItem("User", JSON.stringify(token))
      } else {
        sessionStorage.setItem(userName, JSON.stringify(token))

        ajaxSetup.ajaxSettings = {
          headers: { 'Authorization': token.token }
        };
      }

      return Promise.resolve(token)
    },
    async postLogout() {
      useAccountService().$patch({
        expiration: "",
        isAuthenticated: false,
        token: ""
      })
      localStorage.removeItem("User")
    }
  }
}
