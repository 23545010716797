import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dx-card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxStateStoring = _resolveComponent("DxStateStoring")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    "closable-mask": true,
    modelValue: _ctx.showRef,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showRef) = $event)),
    width: "850px"
  }, {
    header: _withCtx(() => [
      _createTextVNode(" Şube / Depo Bazlı Miktar Özetleri ")
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DxDataGrid, {
          "data-source": _ctx.dataSource,
          columns: _ctx.columns,
          selection: { mode: 'single' },
          "row-alternation-enabled": true,
          "allow-column-reordering": true,
          "allow-column-resizing": true,
          "column-resizing-mode": "widget",
          "show-row-lines": true,
          "show-borders": true,
          style: {"width":"100%","height":"300px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxStateStoring, {
              enabled: true,
              storageKey: "StokMiktarOzetDataGrid"
            })
          ]),
          _: 1
        }, 8, ["data-source", "columns"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}