
export enum AdresTip{
Fatura=0,
Sevk=1,
Teslimat=2
}

export const AdresTipList = [
{key:'Fatura', value:0, description:'Fatura'},
{key:'Sevk', value:1, description:'Sevk'},
{key:'Teslimat', value:2, description:'Teslimat'}
]
