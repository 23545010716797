
import {defineComponent, reactive, toRefs} from "vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NInput from "@/components/Input/NInput.vue";
import {useHizliSatisTabsStore} from "@/modules/HizliSatis/store/HizliSatisTabsStore";

export default defineComponent({
  components: {NInput, NFormField, NFormGrid},
  setup() {
    const localState = reactive({
      store: useHizliSatisTabsStore()
    })


    return {
      ...toRefs(localState)
    }
  }
})
