import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {BankaKrediLimitItem} from '../models/BankaKrediLimitItem'
import {BankaView} from '../models/BankaView'
import {PosView} from '../models/PosView'
import {KrediKartTip} from '../models/KrediKartTip'
import {KrediKartiEkKartItem} from '../models/KrediKartiEkKartItem'
import {Doviz} from '../models/Doviz'
import {KrediKartiView} from '../models/KrediKartiView'

export const useBankaService = defineStore({
id: 'BankaService',
actions: {


	async getBankaList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/BankaList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getIdNameBankaList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/IdNameBankaList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async get(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/Banka/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getBankaView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/BankaView/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postBankaEkle(bankaView:BankaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/BankaEkle/${shared.firm.id}`,{ method:'POST', data:bankaView });
		await request();
		return response.value;
	},

	async putBankaGuncelle(bankaView:BankaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/BankaGuncelle/${shared.firm.id}`,{ method:'PUT', data:bankaView });
		await request();
		return response.value;
	},

	async deleteBankaSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/BankaSil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getUserBankaList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/UserBankaList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getPosList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/PosList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getPosView(posId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/PosView/${shared.firm.id}/${posId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postPosEkle(posView:PosView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/PosEkle/${shared.firm.id}`,{ method:'POST', data:posView });
		await request();
		return response.value;
	},

	async deletePosSil(posId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/PosSil/${shared.firm.id}/${posId}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async putPosGuncelle(posView:PosView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/PosGuncelle/${shared.firm.id}`,{ method:'PUT', data:posView });
		await request();
		return response.value;
	},

	async getUserPosList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/UserPosList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getKrediKartiList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/KrediKartiList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getKrediKartiView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/KrediKartiView/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postKrediKartiEkle(krediKartiView:KrediKartiView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/KrediKartiEkle/${shared.firm.id}`,{ method:'POST', data:krediKartiView });
		await request();
		return response.value;
	},

	async deleteKrediKartiSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/KrediKartiSil/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async putKrediKartiGuncelle(krediKartiView:KrediKartiView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Banka/KrediKartiGuncelle/${shared.firm.id}`,{ method:'PUT', data:krediKartiView });
		await request();
		return response.value;
	}
}
});
