
export enum ImporterType{
Fatura_Satir=0,
Cari=10,
Stok=20,
Stok_Sayim=21,
Stok_FiyatGuncelle=22,
Stok_ResimGuncelle=23
}

export const ImporterTypeList = [
{key:'Fatura_Satir', value:0, description:'Fatura Satırları'},
{key:'Cari', value:10, description:'Cari Aktarma'},
{key:'Stok', value:20, description:'Stok Aktarma'},
{key:'Stok_Sayim', value:21, description:'Stok Sayım Aktarma'},
{key:'Stok_FiyatGuncelle', value:22, description:'Stok Fiyat Güncelle'},
{key:'Stok_ResimGuncelle', value:23, description:'Stok Resim Güncelle'}
]
