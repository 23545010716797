
export enum FisTip{
Uretim=0,
Recete=1,
Plan=2
}

export const FisTipList = [
{key:'Uretim', value:0, description:'Üretim Fişi'},
{key:'Recete', value:1, description:'Reçete Fişi'},
{key:'Plan', value:2, description:'Plan Fişi'}
]
