import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {TopluPosAktarmaView} from '../models/TopluPosAktarmaView'
import {DocumentItem} from '../models/DocumentItem'
import {GenelResmiMuhasebeTip} from '../models/GenelResmiMuhasebeTip'
import {IslemHesaplamaEntity} from '../models/IslemHesaplamaEntity'
import {BankaHareketView} from '../models/BankaHareketView'

export const useBankaHareketService = defineStore({
id: 'BankaHareketService',
actions: {


	async getBankaHareketReportView()
	{
		const { request, response } = useApi(`/api/BankaHareket/BankaHareketReportView`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/List/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getBankaHareketListByDate(start:string,end:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankaHareketListByDate/${shared.firm.id}/${shared.firm.period}/${start}/${end}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getPosHareketListByDate(start:string,end:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/PosHareketListByDate/${shared.firm.id}/${shared.firm.period}/${start}/${end}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListeMuhasebeResmi()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/ListeMuhasebeResmi/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListeMuhasebeResmiBanka(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/ListeMuhasebeResmiBanka/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getReferansHareket(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/ReferansHareket/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListePosCekim()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/ListePosCekim/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListePosCekimBanka(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/ListePosCekimBanka/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListeGenelveMuhasebe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/ListeGenelveMuhasebe/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getListeGenelveMuhasebeBanka(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/ListeGenelveMuhasebeBanka/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getHareketView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/HareketView/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getHareket(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/Hareket/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getPosHareket(posId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/PosHareket/${shared.firm.id}/${shared.firm.period}/${posId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCariHareketId(cariHareketId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/CariHareketId/${shared.firm.id}/${shared.firm.period}/${cariHareketId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getBankaIdHareketList(bankaId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankaIdHareketList/${shared.firm.id}/${shared.firm.period}/${bankaId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async putTopluBankaPosIsleme(topluPosAktarmaView:TopluPosAktarmaView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/TopluBankaPosIsleme/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:topluPosAktarmaView });
		await request();
		return response.value;
	},

	async putTopluPosIslemeGeriAl(liste:string[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/TopluPosIslemeGeriAl/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data: liste });
		await request();
		return response.value;
	},

	async deleteSil(bankaHareketId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/Sil/${shared.firm.id}/${shared.firm.period}/${bankaHareketId}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postBankadanMasrafOdeme(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankadanMasrafOdeme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:bankaHareketView });
		await request();
		return response.value;
	},

	async putBankadanMasrafOdemeGuncelle(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankadanMasrafOdemeGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:bankaHareketView });
		await request();
		return response.value;
	},

	async postKrediOdemesi(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/KrediOdemesi/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:bankaHareketView });
		await request();
		return response.value;
	},

	async putKrediOdemesiGuncelle(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/KrediOdemesiGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:bankaHareketView });
		await request();
		return response.value;
	},

	async postBankayaVirmanOdeme(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankayaVirmanOdeme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:bankaHareketView });
		await request();
		return response.value;
	},

	async putBankayaVirmanOdemeGuncelle(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankayaVirmanOdemeGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:bankaHareketView });
		await request();
		return response.value;
	},

	async postBankadanFirmaKkOdeme(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankadanFirmaKkOdeme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:bankaHareketView });
		await request();
		return response.value;
	},

	async putBankadanFirmaKkOdemeGuncelle(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankadanFirmaKkOdemeGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:bankaHareketView });
		await request();
		return response.value;
	},

	async postBankaCikisFisi(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankaCikisFisi/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:bankaHareketView });
		await request();
		return response.value;
	},

	async putBankaCikisFisiGuncelle(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankaCikisFisiGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:bankaHareketView });
		await request();
		return response.value;
	},

	async postBankaCikanDevir(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankaCikanDevir/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:bankaHareketView });
		await request();
		return response.value;
	},

	async putBankaCikanDevirGuncelle(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankaCikanDevirGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:bankaHareketView });
		await request();
		return response.value;
	},

	async postBankadanVirmanTahsilati(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankadanVirmanTahsilati/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:bankaHareketView });
		await request();
		return response.value;
	},

	async putBankadanVirmanTahsilatiGuncelle(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankadanVirmanTahsilatiGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:bankaHareketView });
		await request();
		return response.value;
	},

	async postBankayaHizmetTahsilati(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankayaHizmetTahsilati/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:bankaHareketView });
		await request();
		return response.value;
	},

	async putBankayaHizmetTahsilatiGuncelle(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankayaHizmetTahsilatiGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:bankaHareketView });
		await request();
		return response.value;
	},

	async postBankaGirisFisi(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankaGirisFisi/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:bankaHareketView });
		await request();
		return response.value;
	},

	async putBankaGirisFisiGuncelle(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankaGirisFisiGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:bankaHareketView });
		await request();
		return response.value;
	},

	async postBankaGirenDevir(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankaGirenDevir/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:bankaHareketView });
		await request();
		return response.value;
	},

	async putBankaGirenDevirGuncelle(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankaGirenDevirGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:bankaHareketView });
		await request();
		return response.value;
	},

	async postBankayaFirmaKkParaCekme(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankayaFirmaKkParaCekme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:bankaHareketView });
		await request();
		return response.value;
	},

	async putBankayaFirmaKkParaCekmeGuncelle(bankaHareketView:BankaHareketView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/BankayaFirmaKkParaCekmeGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:bankaHareketView });
		await request();
		return response.value;
	},

	async getRaporHareket(baslangic:string,bitis:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/BankaHareket/RaporHareket/${shared.firm.id}/${shared.firm.period}/${baslangic}/${bitis}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getPosHareketReportView()
	{
		const { request, response } = useApi(`/api/BankaHareket/PosHareketReportView`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
