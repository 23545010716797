
export enum PluKod{
Normal=0,
Plu27=27,
Plu28=28,
Plu29=29
}

export const PluKodList = [
{key:'Normal', value:0, description:' '},
{key:'Plu27', value:27, description:'PLU-27'},
{key:'Plu28', value:28, description:'PLU-28'},
{key:'Plu29', value:29, description:'PLU-29'}
]
