import { defineStore } from "pinia";
import { useImageManagementService } from "@/api/services/ImageManagementService";
import {useFileSettingsServiceCache} from "@/api/servicesCache/FileSettingsServiceCache";
import ImageUploadType from "@/api/uploaders/models/ImageUploadType";

export const ImageManagementServiceUtil = {
    async postImageManagmentResimEkle(imageUploadType: any) {
      const formData = new FormData();

      imageUploadType.isCompleted = false;

      formData.append("FormFile", imageUploadType.file);
      formData.append("Width", imageUploadType.width as string);
      formData.append("Height", imageUploadType.height as string);
      if (imageUploadType.url){
        formData.append("Url", imageUploadType.url as string);
      }
      formData.append("FolderName", imageUploadType.folderName as string);
      formData.append("ImageLocation", "0");

      const options = {
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
        onUploadProgress: (progressEvent: any) => {
          imageUploadType.loaded = progressEvent.loaded;
          imageUploadType.total = progressEvent.total;
        }
      };

      return await useImageManagementService().postImageManagmentResimEkle(options);
    },

    async uploadImageFromUrl(url:string){
      const model = {
        width:0,
        file:undefined,
        height:0,
        folderName:"",
        url:url

      } as ImageUploadType;
      return this.postImageManagmentResimEkle(model);
    },
    async getServerUrl() {

      return await useFileSettingsServiceCache().getServerUrl();
    }

};
