import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "tree-view-container" }
const _hoisted_2 = { class: "tree-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TreeViewItem = _resolveComponent("TreeViewItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "header"),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (listItem, i) => {
        return (_openBlock(), _createBlock(_component_TreeViewItem, {
          item: listItem,
          subField: _ctx.subField,
          key: i
        }, {
          item: _withCtx(({ item, parent }) => [
            _renderSlot(_ctx.$slots, "item", {
              item: item,
              parent: parent
            })
          ]),
          _: 2
        }, 1032, ["item", "subField"]))
      }), 128))
    ])
  ]))
}