export const DateUtils = {
  formatDate(tarihString?: string) {
    const tarih = new Date(Date.parse(tarihString as string));
    //return tarih.toLocaleDateString();

    return tarih.toLocaleDateString("tr-TR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
  },
  getInputString(date: Date) {
    return date.toLocaleDateString();
  },
  convertInputDateFromStringDate(date: string) {
    return date.split("T")[0];
  },
  convertInputDateFromStringDateTime(dateTime: string) {
    return dateTime.replace("Z", "").split(".")[0];
  },
  getDateString(): string {
    return new Date().toISOString().split("T")[0];
  },
  getDateTimeString(): string {
    return new Date()
      .toISOString()
      .replace("Z", "")
      .split(".")[0];
  },
  parseDate(dateString:string):Date{
    const date = new Date(dateString.trim().replace(/(\d{2})\.(\d{2})\.(\d{4})/,'$3-$2-$1'));
    return  date;
  }

};
