import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';


export const useTrendyolKategoriService = defineStore({
id: 'TrendyolKategoriService',
actions: {


	async getGetir(cacheDay:number)
	{
		const { request, response } = useApi(`/api/TrendyolKategori/Getir/${cacheDay}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
