
export enum DegerTipi{
Adet=0,
Fiyat=1,
Oran=2,
Tutar=3
}

export const DegerTipiList = [
{key:'Adet', value:0, description:'Adet'},
{key:'Fiyat', value:1, description:'Fiyat'},
{key:'Oran', value:2, description:'Oran'},
{key:'Tutar', value:3, description:'Tutar'}
]
