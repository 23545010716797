
import { defineComponent } from "vue";
import { Colors } from "@/models";
export default defineComponent({
  name: "NSwitch",
  inheritAttrs: false,
  props: {
    type: {
      default: Colors.PRIMARY,
      type: String,
      validator: (value: Colors) =>
        [
          Colors.PRIMARY,
          Colors.SUCCESS,
          Colors.DANGER,
          Colors.WARNING,
          Colors.BLACK
        ].includes(value)
    },
    label: { type: String },
    subLabel: { type: String },
    pulse: { type: Boolean },
    disabled: { type: Boolean },
    modelValue: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    function toggle() {
      if (props.disabled) {
        return;
      }
      const newValue = !props.modelValue;
      emit("update:modelValue", newValue);
      emit("click",newValue)
    }

    function clickCheck(e:MouseEvent){

      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      toggle()
    }


    return { toggle,clickCheck };
  }
});
