import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NButton = _resolveComponent("NButton")!
  const _component_SiparisFlatListBekleyenler = _resolveComponent("SiparisFlatListBekleyenler")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NButton, _mergeProps({
      size: "small",
      icon: "plus",
      onClick: _ctx.siparistenEkleClick
    }, _ctx.$attrs), {
      default: _withCtx(() => [
        _createTextVNode("Sipariş Seç")
      ]),
      _: 1
    }, 16, ["onClick"]),
    (_ctx.selectSiparisDialogShow)
      ? (_openBlock(), _createBlock(_component_SiparisFlatListBekleyenler, {
          key: 0,
          show: _ctx.selectSiparisDialogShow,
          "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectSiparisDialogShow) = $event)),
          "siparis-hareket-tip": _ctx.siparisHareketTip.SatisSiparis,
          onAddRow: _ctx.addRow
        }, null, 8, ["show", "siparis-hareket-tip", "onAddRow"]))
      : _createCommentVNode("", true)
  ], 64))
}