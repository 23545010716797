
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import FaturaViewExtend from "@/modules/Fatura/models/FaturaViewExtend";
import NFormFieldLabelTop from "@/components/FormField/NFormFieldLabelTop.vue";
import { EIhracatGonderimTipList } from "@/api/models/EIhracatGonderimTip";
import { EIhracatTeslimSartiTipList } from "@/api/models/EIhracatTeslimSartiTip";
import { EIhracatKapCinsTipList } from "@/api/models/EIhracatKapCinsTip";
import NInput from "@/components/Input/NInput.vue";
import { KdvIstisnaListe } from "@/api/models/KdvIstisnaListe";
import NButton from "@/components/Button/NButton.vue";
import { alert } from "devextreme/ui/dialog";
import { GenelUtils } from "@/modules/Ortak/utils/GenelUtils";
import { usePopupService } from "@/services/popupService";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NCard from "@/components/Card/NCard.vue";
import NListView from "@/components/ListView/NListView.vue";

export default defineComponent({
  components: {NListView, NCard, NButton, NInput, NFormFieldLabelTop },
  props: {
    modelValue: {
      type: Object

    },
    kdvIstinalari: {
      type: Array

    }
  },
  setup(props, { emit }) {

    const localState = reactive({
      model: computed({
        get: () => props.modelValue as FaturaViewExtend,
        set: (val) => emit("update:modelValue", val)
      }),
      bilgiler: {} as any,
      eihracatGonderimTipList: EIhracatGonderimTipList,
      eihracatTeslimTipList: EIhracatTeslimSartiTipList,
      eihracatKapTipLst: EIhracatKapCinsTipList,
      kdvIstinalariRef: computed(() => props.kdvIstinalari as KdvIstisnaListe[])

    });
    function remove(index:number ){
      if (
          localState.model &&
          localState.model.eFatura &&
          localState.model.eFatura.notlar
      )
        localState.model!.eFatura!.notlar?.splice(index , 1 );
    }
    function  addNot(){
      if (
          localState.model &&
          localState.model.eFatura &&
          localState.model.eFatura.notlar
      )
      localState.model!.eFatura!.notlar.push("");

    }
    async function faturayaAktar() {
      localState.model.satirlar?.forEach((satir) => {
        if (satir.kdv != 0) {
          alert("Fatura Satirlarında Kdv 0 Değil", "Satırları Kontrol Ediniz");
          return;
        }

        satir.gTipNo = localState.bilgiler?.gTipNo;
        satir.teslimTip = localState.bilgiler?.teslimTip;
        satir.gonderimTip = localState.bilgiler?.gonderimTip;
        satir.kapTip = localState.bilgiler?.kapTip;
        satir.kapMarka = localState.bilgiler?.kapMarka;
        satir.kdvIstisnasi = localState.bilgiler?.kdvIstisnasi;

      });

      await usePopupService().successSoundPlay();

    }


    return {

      ...toRefs(localState),
      faturayaAktar,
      remove,
      addNot
    };
  }
});
