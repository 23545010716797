import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {MailActionStatusType} from '../models/MailActionStatusType'
import {DocumentItem} from '../models/DocumentItem'
import {MailFileInfo} from '../models/MailFileInfo'
import {ServisGonderimTip} from '../models/ServisGonderimTip'
import {MailView} from '../models/MailView'
import {Doviz} from '../models/Doviz'
import {SablonRequestView} from '../models/SablonRequestView'
import {OtomatikGonderimHareketTip} from '../models/OtomatikGonderimHareketTip'
import {RecordStatus} from '../models/RecordStatus'
import {OtomatikMailGonderItem} from '../models/OtomatikMailGonderItem'
import {MailConfigEntity} from '../models/MailConfigEntity'

export const useMailService = defineStore({
id: 'MailService',
actions: {


	async getMailGetir(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Mail/MailGetir/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetReferansId(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Mail/GetReferansId/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getTumMailler()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Mail/TumMailler/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getAllSentMails()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Mail/AllSentMails/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postMailEkle(mailView:MailView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Mail/MailEkle/${shared.firm.id}`,{ method:'POST', data:mailView });
		await request();
		return response.value;
	},

	async putMailGuncelle(mailView:MailView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Mail/MailGuncelle/${shared.firm.id}`,{ method:'PUT', data:mailView });
		await request();
		return response.value;
	},

	async deleteMailSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Mail/MailSil/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postSendOtomatikMail(sablonRequestView:SablonRequestView,otomatikTip:number,servisGonderimTip:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Mail/SendOtomatikMail/${shared.firm.id}/${otomatikTip}/${servisGonderimTip}`,{ method:'POST', data:sablonRequestView });
		await request();
		return response.value;
	},

	async postGetString(sablonRequestView:SablonRequestView,otomatikTip:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Mail/GetString/${shared.firm.id}/${otomatikTip}`,{ method:'POST', data:sablonRequestView });
		await request();
		return response.value;
	},

	async putReplay(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Mail/Replay/${shared.firm.id}/${id}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async getMailConfigGetir()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Mail/MailConfigGetir/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getMailConfigIsimGetir()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Mail/MailConfigIsimGetir/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postMailConfigKaydet(mailConfigEntity:MailConfigEntity)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Mail/MailConfigKaydet/${shared.firm.id}`,{ method:'POST', data:mailConfigEntity });
		await request();
		return response.value;
	},

	async deleteMailConfigSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Mail/MailConfigSil/${shared.firm.id}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postGetMailViewFromReport(data:any,reportName:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/Mail/GetMailViewFromReport/${shared.firm.id}/${reportName}`,{ method:'POST', data:data });
		await request();
		return response.value;
	},

	async postRestartMailService()
	{
		const { request, response } = useApi(`/api/Mail/RestartMailService`,{ method:'POST' });
		await request();
		return response.value;
	}
}
});
