
import { computed, defineComponent, onMounted, reactive, ref, toRefs, watch } from "vue";
import SiparisViewExtend from "@/modules/SiparisTeklif/models/SiparisViewExtend";
import NDxStateStoring from "@/devextreme/NDxStateStoring.vue";
import NTagList from "@/components/Tag/NTagList.vue";
import { DevExtremeUtils } from "@/devextreme/DevExtremeUtils";
import { Doviz, DovizList } from "@/api/models/Doviz";
import { useStokFlatStore } from "@/modules/Stok/store/StokFlatStore";
import { StokBirimItem } from "@/api/models/StokBirimItem";
import { StokBirimUtils } from "@/modules/Stok/utils/StokBirimUtils";
import SiparisSatirItemExtend from "@/modules/SiparisTeklif/models/SiparisSatirItemExtend";
import { FaturaSatirTip } from "@/api/models/FaturaSatirTip";
import { SiparisUtil } from "@/modules/SiparisTeklif/utils/SiparisUtil";
import { StokFlatView } from "@/api/models/StokFlatView";
import { StokFlatUtils } from "@/modules/Stok/utils/StokFlatUtils";
import { KurView } from "@/api/models/KurView";
import { SiparisHareketTip } from "@/api/models/SiparisHareketTip";
import { useCachedPlasiyerList } from "@/modules/Plasiyer/services/useCachedPlasiyerList";
import { DepoFlatView } from "@/api/models/DepoFlatView";
import { PlasiyerEntity } from "@/api/models/PlasiyerEntity";
import { useKurCachedServis } from "@/modules/Ortak/services/useKurCachedServis";
import { useCachedSubeDepoService } from "@/modules/Sube/services/useCachedSubeDepoService";
import { CariEntity } from "@/api/models/CariEntity";
import NFormField from "@/components/FormField/NFormField.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import { StokMenu } from "@/modules/Stok/models/StokMenu";
import { SiparisSatirItem } from "@/api/models/SiparisSatirItem";
import StokHareketDialogList from "@/modules/Stok/dialogs/StokHareketDialogList.vue";
import StokMiktarOzet from "@/modules/Stok/components/StokMiktarOzet.vue";
import { FaturaSatirItem } from "@/api/models/FaturaSatirItem";
import HizliStokDialog from "@/modules/Stok/dialogs/HizliStokDialog.vue";
import { TevkifatOranlariListe } from "@/api/models/TevkifatOranlariListe";
import { DxDataGrid } from "devextreme-vue/data-grid";
import { useGenelCache } from "@/modules/Ortak/services/useGenelCache";
import { alert } from "devextreme/ui/dialog";
import SacUretimEtiketDegistirDialog from "@/modules/SacUretim/dialogs/SacUretimEtiketDegistirDialog.vue";
import { HizmetMasrafEntity } from "@/api/models/HizmetMasrafEntity";
import { FaturaUtil } from "@/modules/Fatura/utils/FaturaUtil";
import NTag from "@/components/Tag/NTag.vue";

export default defineComponent({
  components: {
    NTag,
      SacUretimEtiketDegistirDialog,
    DxDataGrid,
    HizliStokDialog,
    StokMiktarOzet,
    StokHareketDialogList,
    NGrid,
    NFormField,
    NTagList,
    NDxStateStoring
  },
  props: {
    modelValue: {
      type: Object
    },
    depoId: {
      type: String,
      default: ""
    },
    plasiyerId: {
      type: String,
      default: ""
    },
    siparisHareketTip: {},
    fiyatGrubuOran: {
      type: Number,
      default: 0
    },
    dataGridHeight: {
      type: String,
      default: "100%"
    },
    cariProp: {
      type: Object

    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      model: computed({
        get: () => props.modelValue as SiparisViewExtend,
        set: (val) => emit("update:modelValue", val)
      }),
      siparisSatirDataGrid: ref(),
      dovizListRef: DovizList,
      kdvsizTutarIskonto: ref(0),
      kurList: ref([] as KurView[]),
      depoIdRef: computed(() => props.depoId),
      plasiyerIdRef: computed(() => props.plasiyerId),

      depoList: ref([] as DepoFlatView[]),
      plasiyerList: ref([] as PlasiyerEntity[]),
      cari: computed(() => props.cariProp as CariEntity),
      siparisHareketTipRef: computed(() => props.siparisHareketTip),
      fiyatGrubuOran: computed(() => props.fiyatGrubuOran),
      stokHareketShowDialog: ref(false),
      stokAdi: ref(""),
      stokId: ref(""),
      itemId: ref(""),

      showMiktarOzet: ref(false),
      hizliStokDialogShow: ref(false),
      showEtiketlendirDialog:ref(false),
      tevkifatOranlari: ref([] as TevkifatOranlariListe[])
    });

    onMounted(async () => {

      await load();
    });

    async function load() {

      localState.kurList = await useKurCachedServis().getKurList();
      localState.depoList = await useCachedSubeDepoService().getUserDepoList();
      localState.plasiyerList = await useCachedPlasiyerList().getUserPlasiyerList();


      const tevkifatOran = localState.model.satirlar?.find((a:any)=>a.tevkifatOran > 0) ;
      if (tevkifatOran)
        localState.tevkifatOranlari = await  useGenelCache().getTevkifatList();

    }


    async function loadState() {
      return await DevExtremeUtils.loadState(localState.siparisSatirDataGrid?.instance);
    }

    function dataGridRefresh() {
      localState.siparisSatirDataGrid?.instance?.refresh();
    }

    function getDovizGenel() {
      if (localState.kurList && localState.kurList.length > 0) {
        const doviz = localState.model.cariIslemDoviz as number;
        const kur = localState.kurList[doviz];
        if (kur) return kur.sembol;
      }
      return "₺";
    }

    function changedBirim(e: any, row: any) {
      const stok = useStokFlatStore().getStok(row.stokId as string);
      const birimler = stok?.birimFiyatlari as StokBirimItem[];
      if (birimler && stok) {
        const birimAdi = e.value;
        const birim = birimler.find(a => a.birim == birimAdi);

        if (birim) {
          row.birim = birimAdi;
          row.carpan = birim.carpan;
          row.fiyat = StokBirimUtils.getFiyat(
            birim.fiyat!,
            birim.fiyatTipi!,
            stok.satisFiyat!,
            stok.satisKdv!,
            stok.satisKdvDahil!,
            stok.alisFiyat!,
            stok.alisKdv!,
            stok.alisKdvDahil!,
            0
          );
          //var fiyatKdvliMi = StokBirimUtils.getFiyatKdvDahilMi(birim.fiyatTipi , stok.satisKdvDahil , stok.alisKdvDahil);


          row.fiyatKdvli = row.fiyat * (1 + (stok.satisKdv / 100));
        } else {
          row.birim = birimAdi;
          row.carpan = 1;
          row.fiyat = !stok.satisKdvDahil ? stok.satisFiyat : stok.satisFiyat! / (1 + (stok.satisKdv! / 100));
          row.fiyatKdvli = row.fiyat * (1 + (stok.satisKdv / 100));
        }

      }

    }

    function getBirimlerDataSource(data: any) {
      if (data && data.data && data.data.stokId && data.data.stokId.length > 0) {

        const findStok = useStokFlatStore().getStok(data.data.stokId);

        if (findStok) {
          const birimler = Object.assign([], findStok.birimFiyatlari as StokBirimItem[]);
          birimler.push({
            birim: findStok.birim,
            carpan: 1,
            getBirimKisaAd: findStok.birim

          });
          data.data.birimler = birimler;

        }

      }


    }

    function stokDblClick(stok: StokFlatView) {
      const satir: any = util.newSiparisSatirItem(stok, localState.depoIdRef, localState.siparisHareketTipRef as SiparisHareketTip);
     // console.log("stok", stok);
      //console.log("satir",satir);
      if (satir) {
        satir.adetBolen = stok.adetBolen;
        satir.plasiyerId = localState.plasiyerIdRef;
        const fiyatGrupSatir = fiyatGrubuAyari(satir as SiparisSatirItemExtend);

        const birimGrupSatir = SiparisUtil.getStokBirimGroupFiyat(localState.cari.birimFiyatGrupAdi as string, stok, fiyatGrupSatir);


        if (birimGrupSatir.doviz && (birimGrupSatir.doviz == Doviz.EUR || birimGrupSatir.doviz == Doviz.USD)) {
          birimGrupSatir.kur = localState.kurList.find(a => a.doviz == satir.doviz)?.alis;
        }

        satir.bakiye = StokFlatUtils.getKalanMiktar(stok as StokFlatView, satir.depoId as string);
        satir.anaBirim = stok.birim;


        localState.model.satirlar!.push(new SiparisSatirItemExtend(birimGrupSatir));
        dataGridRefresh();
      }
    }


    function fiyatGrubuAyari(satir: SiparisSatirItemExtend) {
      if (localState.fiyatGrubuOran != 0) {
        if (localState.fiyatGrubuOran > 0) {
          if (satir.tip == FaturaSatirTip.Stok) {
            satir.fiyat = SiparisUtil.getStokFiyat(satir.stokId as string, localState.siparisHareketTipRef as SiparisHareketTip);
            satir.fiyat = satir.fiyat! * (1 + (localState.fiyatGrubuOran / 100));
            satir.fiyatKdvli = satir.fiyat * (1 + (satir.kdv! / 100));
          }
          localState.model.altIskontolar = [];
          localState.model.setFaturaAltiIskonto();
        } else {
          if (satir.tip == FaturaSatirTip.Stok) {
            satir.fiyat = SiparisUtil.getStokFiyat(satir.stokId as string, localState.siparisHareketTipRef as SiparisHareketTip);

            satir.fiyatKdvli = satir.fiyat * (1 + (satir.kdv! / 100));
          }

          localState.model.altIskontolar = [];
          localState.model.altIskontolar.push(localState.fiyatGrubuOran * -1);
          localState.model.setFaturaAltiIskonto();
        }
      } else {
        if (satir.tip == FaturaSatirTip.Stok) {
          satir.fiyat = SiparisUtil.getStokFiyat(satir.stokId as string, localState.siparisHareketTipRef as SiparisHareketTip);
          satir.fiyatKdvli = satir.fiyat * (1 + (satir.kdv! / 100));
        }
        localState.model.altIskontolar = [];
        localState.model.setFaturaAltiIskonto();
      }

      return satir;
    }


    const util = SiparisUtil;

    watch(() => localState.model.cariIslemDoviz, () => {
      const kur = localState.kurList.find(a => a.doviz == localState.model.cariIslemDoviz);
      if (kur) {
        localState.model.cariIslemKur = kur.alis;
      }
    });

    watch(() => localState.kdvsizTutarIskonto, () => {
      localState.model.setFaturaAltiIskontoSifirla();
      const toplam = localState.model.getSatirBrutToplam() - localState.model.getSatirIskontoTutar();
      localState.model.tutarIskonto = toplam * localState.kdvsizTutarIskonto / (toplam + localState.model.getKdvTutar());
    });

    watch(() => localState.fiyatGrubuOran, () => {

      if (localState.model && localState.model.satirlar && localState.model.satirlar.length) {
        localState.model.satirlar.forEach((satir) => {
          const newSatir = fiyatGrubuAyari(satir as SiparisSatirItemExtend);
          //console.log("new satir" , newSatir);
          if (satir)
            satir = newSatir;
        });
      }
    });

    watch(() => localState.model.altIskontolar, () => {
      localState.model.setFaturaAltiIskonto();
    }, { deep: true });
    watch(() => localState.model.tutarIskonto, () => {
      localState.model.setFaturaAltiIskonto();
    });

    function rightMenu(e: any) {
      e.items = [
        { text: "Stoğa Git", onClick: () => StokMenu.stokOpenPage(e?.row?.data), icon: "plus" },
        {
          text: "Stok Hareketleri", onClick: () => {
            const satir = e?.row?.data as SiparisSatirItem;
            if (satir && satir.tip == FaturaSatirTip.Stok) {

              localState.stokId = satir.stokId as string;
              localState.itemId = satir.itemId as string;
              localState.stokAdi = satir.ad as string;
              localState.stokHareketShowDialog = true;
            }
          }, icon: "verticalaligncenter"
        },
        { text: "Stok Miktar Özetleri", icon: "overflow", onClick: () => openMiktarOzet(e) },
        {
          text: "Hızlı Stok Düzenle", icon: "edit", onClick: () => {
            const satir = e?.row?.data as FaturaSatirItem;
            if (satir && satir.tip == FaturaSatirTip.Stok) {
              localState.stokId = satir.stokId as string;
              localState.hizliStokDialogShow = true;
            }
          }
        },
        {
            text: "Etiketlendir", icon: "bookmark", onClick: () => {
                const satir = e?.row?.data as FaturaSatirItem;
                if (satir && satir.tip == FaturaSatirTip.Stok) {
                    localState.stokId = satir.stokId as string;
                    localState.showEtiketlendirDialog = true;
                }
            }
        }
      ];

      if (e.row == undefined)
        e.items = undefined;

    }


    function openMiktarOzet(e: any) {
      const stok: StokFlatView = e.row.data;
      if (stok) {
        localState.stokId = stok.stokId as string;
        localState.showMiktarOzet = true;
      }
    }

    async function rowEditing(e: any) {
      if (e.column.name == "kur") {
        if (e.data.doviz == Doviz.TRY) e.cancel = true;
      }
      if (e.column.name == "getTeslimMiktar")
        e.cancel = true;
      if (e.column.name == "kalan")
        e.cancel = true;




    }

    async function rowUpdating(e: any) {


      const doviz = e.newData["doviz"] as Doviz;
      if (doviz > -1 && localState.kurList && localState.kurList.length > 0) {
        const fiyat = e.key.fiyat * e.key.kur;
        const kurAlis = localState.kurList.find(a => a.doviz == doviz);
        if (kurAlis)
          e.key.kur = kurAlis.alis;
        e.key.fiyat = fiyat / e.key.kur;
        SiparisUtil.changedKdvsiz(e.key, fiyat);
      }


      const miktar = e.newData["miktar"];
      if (miktar) {
        //  console.log(e);
        if (e.key && e.key.adetBolen && e.key.adetBolen != 1)
          e.key.kgAdet = miktar / e.key.adetBolen;


      }

      const kdvliFiyat = e.newData["fiyatKdvli"];
      if (kdvliFiyat) {
        SiparisUtil.changedKdvli(e.key, kdvliFiyat);
      }
      const kdvsizFiyat = e.newData["fiyat"];
      if (kdvsizFiyat) {
        SiparisUtil.changedKdvsiz(e.key, kdvsizFiyat);
      }
      const kdv = e.newData["kdv"];
      if (kdv > -1) {
        if (kdv == 0)
          e.key.fiyatKdvli = e.key.fiyat;
        else {
          const carpan = ((100 + kdv) / 100);
          e.key.fiyatKdvli = e.key.fiyat * carpan;
        }
      }


      const tevkifatOran = e.newData["tevkifatOran"];
      if (tevkifatOran == 0) {
        e.key.tevkifatKod = "";
        e.key.tevkifatAd = "";
      }
      if (tevkifatOran && tevkifatOran > 0) {
        if (localState.tevkifatOranlari.length == 0)
          localState.tevkifatOranlari = await useGenelCache().getTevkifatList();

        const tevkifat = localState.tevkifatOranlari?.find(a => a.oran == tevkifatOran);
        e.key.tevkifatKod = tevkifat?.kod;
        e.key.tevkifatAd = tevkifat?.aciklama;
      }
      const tevkifatKod = e.newData["tevkifatKod"];
      if (tevkifatKod) {
        const tevkifat = localState.tevkifatOranlari?.find(a => a.kod == tevkifatKod);
        e.key.tevkifatAd = tevkifat?.aciklama;
        e.key.tevkifatOran = tevkifat?.oran;
      }

      const tevkifatAd = e.newData["tevkifatAd"];
      if (tevkifatAd) {
        const tevkifat = localState.tevkifatOranlari?.find(a => a.aciklama == tevkifatAd);
        e.key.tevkifatAd = tevkifat?.aciklama;
        e.key.tevkifatOran = tevkifat?.oran;
      }
    }

    function cokluhHizmetDblClick(hizmetler: HizmetMasrafEntity[]) {
      if (hizmetler && hizmetler.length > 0) {
        hizmetler.forEach((a) => hizmetDblClick(a));
      }
    }

    function hizmetDblClick(hizmet: HizmetMasrafEntity) {
      if (localState.model.satirlar) {
        const satir =SiparisUtil.generateSiparisTeklifHizmetSatir(hizmet);
        satir.depoId = localState.depoIdRef;
        satir.plasiyerId = localState.plasiyerIdRef;
        const newSatir = fiyatGrubuAyari(satir);
        newSatir.bakiye = 0;
        newSatir.anaBirim = satir.birim;


        // newSatir.guncelSatisFiyat = 0;
        // newSatir.guncelSatisDoviz = hizmet.doviz;
        // newSatir.guncelSatisKdv = hizmet.kdv;
        // newSatir.guncelSatisKdvDahil = false;

        // satir.yeniSatisFiyatKdvDahil = 0;
        // satir.yeniSatisFiyat = 0;


        localState.model.satirlar.push(newSatir);

        (localState.siparisSatirDataGrid.instance as any).navigateToRow(satir.id);
        localState.model.setFaturaAltiIskonto();
        dataGridRefresh();
      }
    }


    return {
      ...toRefs(localState),
      getDovizGenel,
      loadState,
      getBirimlerDataSource,
      util,
      hizmetDblClick,
      cokluhHizmetDblClick,
      changedBirim,
      load,
      stokDblClick,
      rightMenu,
      rowUpdating,
      rowEditing

    };


  }
});
