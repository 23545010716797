import { useUserSettingsService } from "@/api/services/UserSettingsService";
import dxDataGrid, { dxDataGridColumn } from "devextreme/ui/data_grid";
import { usePopupService } from "@/services/popupService";
import { GenelUtils } from "@/modules/Ortak/utils/GenelUtils";
import {Benchmark} from "@/utils/benchmark/Benchmark";


export const DevExtremeUtils = {
  async resetState(dataGridInstance: any) {

    const key = dataGridInstance?.option()?.ref;

    if (key && key.length > 0 && await GenelUtils.onayDiyalogu("Kullandığınız düzen, varsayılan düzene sıfırlanacaktır. Onaylıyor musunuz ?", "Düzen Sıfırlama")) {
      await useUserSettingsService().postSave({
        name: key,
        value: JSON.stringify({ columns: null })
      });

      await usePopupService().pushSuccessNotification({
        body: "Düzen Sıfırlandı",
        duration: 1500,
        title: "Data Grid Düzeni"
      });
    }


  },
  async clearFilter(dataGridInstance: any) {
    dataGridInstance?.clearFilter();
  },
  async saveStete(dataGridInstance: any, isFilter = false) {
    let saveColumns: dxDataGridColumn[] = [];

    const columns = dataGridInstance.state()?.columns;
    const key = dataGridInstance?.option()?.ref;
    //console.log("Save State", columns)
    if (isFilter) {

      saveColumns = columns;
    } else {
      columns?.forEach((a: any) => {
        saveColumns.push({
          name: a.name,
          columns: a?.columns,
          width: a.width,
          dataField: a.dataField,
          visible: a.visible,
          visibleIndex: a.visibleIndex
        });
      });
    }


    if (saveColumns && saveColumns.length > 0 && key && key.length > 0) {
      await useUserSettingsService().postSave({
        name: key,
        value: JSON.stringify({ columns: saveColumns })
      });

      await usePopupService().pushSuccessNotification({
        body: "Düzen Başarıyla Kaydedildi",
        duration: 1500,
        title: "Data Grid Düzeni"
      });
    }


  },
  async loadState(dataGridInstance: any) {

    const key = dataGridInstance?.option()?.ref;

    // console.log("dataGridInstance", dataGridInstance);
    // console.log("dataGridInstance", dataGridInstance?.option()?.stateStoring?.enabled);
    // console.log("dataGridInstance?.option()", dataGridInstance?.option());
    await Benchmark.delay(200); // Component içinde DataGrid sütunları sonradan yüklendiği için yapıldı.
    if (key && key.length > 0) {
      const state = await useUserSettingsService().getGetValue(key);
      if (dataGridInstance &&
        dataGridInstance.option() &&
        dataGridInstance.option().stateStoring &&
        dataGridInstance.option().stateStoring.enabled &&
        dataGridInstance.option().stateStoring.enabled == true) {
        dataGridInstance.option().stateStoring.enabled = false;
        return state;
      } else {
        return {};
      }

    }
    return {};

  },
  updateScroll(dataGrid: any) {

    if (dataGrid?.instance){

      if (dataGrid && dataGrid.instance && Object.getOwnPropertyNames(dataGrid.instance).find(a => a == "updateScroll")){
        dataGrid?.instance?.updateScroll();

      }

    }

  }


};
