import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "dx-card",
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxLoadPanel = _resolveComponent("DxLoadPanel")!
  const _component_DxScrolling = _resolveComponent("DxScrolling")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxColumnChooser = _resolveComponent("DxColumnChooser")!
  const _component_DxSelection = _resolveComponent("DxSelection")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxLookup = _resolveComponent("DxLookup")!
  const _component_DxDateBox = _resolveComponent("DxDateBox")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_ctx.showRef)
    ? (_openBlock(), _createBlock(_component_NModalDialog, {
        key: 0,
        title: "Sipariş verilen stoklardan seç...",
        modelValue: _ctx.showRef,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showRef) = $event)),
        "closable-mask": false,
        width: "100%",
        "show-close": true
      }, {
        body: _withCtx(() => [
          _createVNode(_component_NLoadingPanel, {
            action: _ctx.load,
            title: "Siparişler yükleniyor..."
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_DxDataGrid, {
                  "data-source": _ctx.dataSource,
                  toolbarTemplate: "toolbar",
                  height: 500,
                  onSelectionChanged: _ctx.selectionChanged,
                  onRowDblClick: _cache[2] || (_cache[2] = (e)=> _ctx.rowDblClick(e.data)),
                  width: "100%"
                }, {
                  toolbar: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createVNode(_component_NFormField, {
                        "grid-cols": "auto 1fr 1fr auto",
                        label: "Başlangıç - Bitiş Tarihi"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_DxDateBox, {
                            width: "120px",
                            value: _ctx.startDate,
                            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.startDate) = $event))
                          }, null, 8, ["value"]),
                          _createVNode(_component_DxDateBox, {
                            width: "120px",
                            value: _ctx.endDate,
                            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.endDate) = $event))
                          }, null, 8, ["value"]),
                          _createVNode(_component_NButton, {
                            icon: "filter",
                            type: "info",
                            size: "small",
                            onClick: _ctx.load
                          }, null, 8, ["onClick"])
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_DxLoadPanel, { enabled: true }),
                    _createVNode(_component_DxScrolling, { mode: "virtual" }),
                    _createVNode(_component_DxHeaderFilter, { visible: true }),
                    _createVNode(_component_DxFilterRow, { visible: true }),
                    _createVNode(_component_DxColumnChooser, {
                      enabled: true,
                      mode: "select"
                    }),
                    _createVNode(_component_DxSelection, {
                      mode: "multiple",
                      "show-check-boxes-mode": "always"
                    }),
                    _createVNode(_component_DxColumn, {
                      width: "100",
                      "data-field": "tarih",
                      caption: "Tarih",
                      "data-type": "date"
                    }),
                    _createVNode(_component_DxColumn, {
                      width: "100",
                      "data-field": "vade",
                      caption: "Vade",
                      "data-type": "date"
                    }),
                    _createVNode(_component_DxColumn, {
                      width: "100",
                      "data-field": "evrakNo",
                      caption: "Evrak No",
                      "group-index": 0
                    }),
                    _createVNode(_component_DxColumn, {
                      width: "150",
                      "data-field": "tip",
                      caption: "Tip"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxLookup, {
                          items: _ctx.siparisHareketTipListRef,
                          displayExpr: "description",
                          valueExpr: "value"
                        }, null, 8, ["items"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_DxColumn, {
                      width: "200",
                      "data-field": "cari.name",
                      caption: "Cari"
                    }),
                    _createVNode(_component_DxColumn, {
                      width: 220,
                      caption: "Stok Adı",
                      "data-field": "ad"
                    }),
                    _createVNode(_component_DxColumn, {
                      width: 80,
                      caption: "Kod",
                      "data-field": "kod"
                    }),
                    _createVNode(_component_DxColumn, {
                      width: 80,
                      caption: "Miktar",
                      "data-field": "miktar",
                      format: "#,##0.00"
                    }),
                    _createVNode(_component_DxColumn, {
                      cssClass: "fontBold",
                      width: 80,
                      "data-field": "getTeslimKalan",
                      caption: "Kalan (Teslim)",
                      "data-type": "number",
                      format: "#,##0.00"
                    }),
                    _createVNode(_component_DxColumn, {
                      width: 80,
                      caption: "Kdv",
                      "data-field": "kdv"
                    }),
                    _createVNode(_component_DxColumn, {
                      cation: "Barkod",
                      "data-field": "barkod"
                    }),
                    _createVNode(_component_DxColumn, {
                      cation: "Bülten No",
                      "data-field": "bultenNo"
                    }),
                    _createVNode(_component_DxColumn, {
                      cation: "Siparis No",
                      "data-field": "siparisNo"
                    }),
                    _createVNode(_component_DxColumn, {
                      alignment: "right",
                      width: "45",
                      "data-field": "doviz",
                      caption: "Döviz",
                      "calculate-cell-value": (r) => _ctx.dovizList.find(a=>a.value === r.doviz )?.key
                    }, null, 8, ["calculate-cell-value"]),
                    _createVNode(_component_DxColumn, {
                      alignment: "right",
                      width: "45",
                      "data-field": "kur",
                      caption: "Kur",
                      "data-type": "number",
                      format: "#,##0.00"
                    }),
                    _createVNode(_component_DxColumn, {
                      width: "200",
                      "data-field": "aciklama",
                      caption: "Açıklama"
                    }),
                    _createVNode(_component_DxColumn, {
                      width: "200",
                      "data-field": "birim",
                      caption: "Birim"
                    })
                  ]),
                  _: 1
                }, 8, ["data-source", "onSelectionChanged"])
              ])
            ]),
            _: 1
          }, 8, ["action"])
        ]),
        actions: _withCtx(() => [
          _createVNode(_component_NButton, {
            type: "warning",
            size: "small",
            icon: "x-circle",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showRef = false))
          }, {
            default: _withCtx(() => [
              _createTextVNode("İptal")
            ]),
            _: 1
          }),
          _createVNode(_component_NButton, {
            type: "success",
            size: "small",
            icon: "save",
            onClick: _ctx.aktar
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.selectedRows.length) + " - Adet Satırı Aktar ", 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}