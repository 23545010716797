import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxDateBox = _resolveComponent("DxDateBox")!
  const _component_NIcon = _resolveComponent("NIcon")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_GenelResmiMuhasebeSelectBox = _resolveComponent("GenelResmiMuhasebeSelectBox")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_DepoSelectBox = _resolveComponent("DepoSelectBox")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_PlasiyerSelectBox = _resolveComponent("PlasiyerSelectBox")!
  const _component_ProjeSelectBox = _resolveComponent("ProjeSelectBox")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_NTextarea = _resolveComponent("NTextarea")!
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_FiyatGruplariSelectBox = _resolveComponent("FiyatGruplariSelectBox")!
  const _component_FaturaBilgiItemComponent = _resolveComponent("FaturaBilgiItemComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_NFormField, {
      label: "Evrak Tarihi",
      "grid-cols": "100px 1fr auto"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DxDateBox, {
          type: "datetime",
          value: _ctx.model.ustBilgi.tarih,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.ustBilgi.tarih) = $event)),
          onValueChanged: _ctx.evrakTarihiChanged
        }, null, 8, ["value", "onValueChanged"]),
        _createVNode(_component_NIcon, {
          style: {"cursor":"pointer"},
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.model.ustBilgi.tarih = new Date().toISOString())),
          type: "calendar",
          title: "Şimdiki  Tarih ve Saat"
        })
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, { label: "Vade Tarihi" }, {
      default: _withCtx(() => [
        _createVNode(_component_DxDateBox, {
          value: _ctx.model.ustBilgi.vade,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.ustBilgi.vade) = $event)),
          onKeyDown: _ctx.vadeChanged
        }, null, 8, ["value", "onKeyDown"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, { label: "Muh. Durum" }, {
      default: _withCtx(() => [
        _createVNode(_component_GenelResmiMuhasebeSelectBox, {
          modelValue: _ctx.model.ustBilgi.genelResmiMuhasebeTip,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.ustBilgi.genelResmiMuhasebeTip) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, { label: "Evrak No" }, {
      default: _withCtx(() => [
        _createVNode(_component_NInput, {
          modelValue: _ctx.model.ustBilgi.evrakNo,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.ustBilgi.evrakNo) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, { label: "Şube/Depo" }, {
      default: _withCtx(() => [
        _createVNode(_component_DepoSelectBox, {
          onValueChanged: _ctx.valueChangedDepoId,
          modelValue: _ctx.depoIdRef,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.depoIdRef) = $event)),
          "sube-id": _ctx.subeIdRef,
          "onUpdate:subeId": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.subeIdRef) = $event)),
          "first-select": true
        }, null, 8, ["onValueChanged", "modelValue", "sube-id"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, { label: "Hesabı" }, {
      default: _withCtx(() => [
        _createVNode(_component_DxSelectBox, {
          items: _ctx.cariRef.hesaplar,
          searchEnabled: true,
          value: _ctx.model.ustBilgi.cariHesabi,
          "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.ustBilgi.cariHesabi) = $event)),
          "show-clear-button": true,
          placeholder: "Cari Hesabi"
        }, null, 8, ["items", "value"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, { label: "İşi" }, {
      default: _withCtx(() => [
        _createVNode(_component_DxSelectBox, {
          items: _ctx.cariRef.isler,
          searchEnabled: true,
          value: _ctx.model.ustBilgi.cariIsi,
          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.ustBilgi.cariIsi) = $event)),
          "show-clear-button": true,
          placeholder: "Cari İşi"
        }, null, 8, ["items", "value"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, { label: "Plasiyer" }, {
      default: _withCtx(() => [
        _createVNode(_component_PlasiyerSelectBox, {
          modelValue: _ctx.plasiyerIdRef,
          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.plasiyerIdRef) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, { label: "Proje" }, {
      default: _withCtx(() => [
        _createVNode(_component_ProjeSelectBox, {
          modelValue: _ctx.model.ustBilgi.projeId,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.ustBilgi.projeId) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, { label: "Ek Masraf" }, {
      default: _withCtx(() => [
        _createVNode(_component_DxNumberBox, {
          value: _ctx.model.ekMasraf,
          "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.ekMasraf) = $event))
        }, null, 8, ["value"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, {
      label: "Fatura Maliyet",
      "grid-cols": "100px 1fr 80px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DxNumberBox, {
          value: _ctx.model.faturaMaliyet,
          "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.faturaMaliyet) = $event)),
          format: "#,##0.0000"
        }, null, 8, ["value"]),
        _createVNode(_component_DxNumberBox, {
          format: (d) => '% ' +  d.format(2,2),
          "value-change-event": "keyup",
          value: _ctx.maliyetYuzde,
          "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.maliyetYuzde) = $event))
        }, null, 8, ["format", "value"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, { label: "Açıklama" }, {
      default: _withCtx(() => [
        _createVNode(_component_NTextarea, {
          rows: "3px",
          modelValue: _ctx.model.ustBilgi.aciklama,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model.ustBilgi.aciklama) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_NFormField, { label: "Etkisiz" }, {
      default: _withCtx(() => [
        _createVNode(_component_NSwitch, {
          modelValue: _ctx.model.ustBilgi.etkisizFis,
          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.ustBilgi.etkisizFis) = $event)),
          style: {"text-align":"center"}
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_FiyatGruplariSelectBox, {
      modelValue: _ctx.fiyatGrubuId,
      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.fiyatGrubuId) = $event)),
      onOnValueChanged: _ctx.fiyatGrubuOnValueChanged
    }, null, 8, ["modelValue", "onOnValueChanged"]),
    _createVNode(_component_FaturaBilgiItemComponent, {
      placeholder: "İrsaliye Numarası",
      modelValue: _ctx.model.ustBilgi.irsaliyeler,
      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model.ustBilgi.irsaliyeler) = $event)),
      "add-text": "Yeni İrsaliye Bilgisi Ekle"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_FaturaBilgiItemComponent, {
      placeholder: "Siparis Numarası",
      modelValue: _ctx.model.ustBilgi.siparisler,
      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.model.ustBilgi.siparisler) = $event)),
      "add-text": "Yeni Sipariş Bilgisi Ekle"
    }, null, 8, ["modelValue"])
  ], 64))
}