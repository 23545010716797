import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {Doviz} from '../models/Doviz'
import {PlanVadelerItem} from '../models/PlanVadelerItem'
import {DocumentItem} from '../models/DocumentItem'
import {HizmetMasrafView} from '../models/HizmetMasrafView'

export const useHizmetMasrafService = defineStore({
id: 'HizmetMasrafService',
actions: {


	async getDefaultInstall(firmaKod:string)
	{
		const { request, response } = useApi(`/api/hizmetMasraf/DefaultInstall/${firmaKod}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/hizmetMasraf/List/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getTaksitliListe()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/hizmetMasraf/TaksitliListe/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getIdNameList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/hizmetMasraf/IdNameList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetir(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/hizmetMasraf/Getir/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetirView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/hizmetMasraf/GetirView/${shared.firm.id}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postEkle(hizmetMasrafView:HizmetMasrafView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/hizmetMasraf/Ekle/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:hizmetMasrafView });
		await request();
		return response.value;
	},

	async putGuncelle(hizmetMasrafView:HizmetMasrafView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/hizmetMasraf/Guncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:hizmetMasrafView });
		await request();
		return response.value;
	},

	async putHizmetMasrafKartlariKontrol(liste:string[])
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/hizmetMasraf/HizmetMasrafKartlariKontrol/${shared.firm.id}`,{ method:'PUT', data: liste });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/hizmetMasraf/Sil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getGrup1()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/hizmetMasraf/Grup1/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGrup2(grup1:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/hizmetMasraf/Grup2/${shared.firm.id}/${grup1}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGrup3(grup1:string,grup2:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/hizmetMasraf/Grup3/${shared.firm.id}/${grup1}/${grup2}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGrup4(grup1:string,grup2:string,grup3:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/hizmetMasraf/Grup4/${shared.firm.id}/${grup1}/${grup2}/${grup3}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
