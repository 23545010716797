import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSelectBox = _resolveComponent("NSelectBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!

  return (_openBlock(), _createBlock(_component_NFormGrid, { cols: "1fr 1fr" }, {
    default: _withCtx(() => [
      _createVNode(_component_NFormField, {
        "grid-cols": "100px 1fr",
        label: "KVKK İzni"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NSelectBox, {
            items: _ctx.kvkkListRef,
            "value-field": "value",
            "label-field": "description",
            modelValue: _ctx.model.kvkkTip,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.kvkkTip) = $event)),
            "first-option-label": "--Seçiniz--"
          }, null, 8, ["items", "modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_NFormField, {
        "grid-cols": "100px 1fr",
        label: "KVKK Açıklama",
        span: "2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_NInput, {
            type: "text",
            modelValue: _ctx.model.kvkkAciklama,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.kvkkAciklama) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}