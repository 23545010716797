import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_CariSelectBox = _resolveComponent("CariSelectBox")!
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NModalDialog = _resolveComponent("NModalDialog")!

  return (_openBlock(), _createBlock(_component_NModalDialog, {
    modelValue: _ctx.showRef,
    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showRef) = $event)),
    width: "500px"
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.tipRef == _ctx.menuFormType.Fatura_Form ? "Fatura Kopylama" : "Sipariş/Teklif Kopyalama"), 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_NLoadingPanel, { action: _ctx.load }, {
        default: _withCtx(() => [
          _createVNode(_component_NFormField, {
            label: _ctx.tipRef == _ctx.menuFormType.Fatura_Form ? 'Fatura Tip' : 'Sipariş/Teklif Tip',
            "grid-cols": "100px 1fr"
          }, {
            default: _withCtx(() => [
              (_ctx.tipRef == _ctx.menuFormType.Fatura_Form)
                ? (_openBlock(), _createBlock(_component_DxSelectBox, {
                    key: 0,
                    "display-expr": (a) => _ctx.faturaHareketTipList?.find(b=>b.value == a )?.description,
                    items: _ctx.faturaTipLoadListe,
                    searchEnabled: true,
                    value: _ctx.faturaTip,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.faturaTip) = $event)),
                    "show-clear-button": true
                  }, null, 8, ["display-expr", "items", "value"]))
                : (_openBlock(), _createBlock(_component_DxSelectBox, {
                    key: 1,
                    "display-expr": "description",
                    "value-expr": "value",
                    items: _ctx.siparisHareketTipList,
                    searchEnabled: true,
                    value: _ctx.siparisTip,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.siparisTip) = $event)),
                    "show-clear-button": true
                  }, null, 8, ["items", "value"]))
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_NFormField, {
            label: "Cari",
            "grid-cols": "100px 1fr"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CariSelectBox, {
                id: _ctx.cariId,
                "onUpdate:id": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.cariId) = $event)),
                "get-unvan": _ctx.getUnvan,
                "onUpdate:getUnvan": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.getUnvan) = $event))
              }, null, 8, ["id", "get-unvan"])
            ]),
            _: 1
          }),
          _createVNode(_component_NFormField, {
            label: _ctx.tipRef == _ctx.menuFormType.Fatura_Form ? 'Faturayı Sil' : 'Sipariş/Teklif Sil'
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NSwitch, {
                type: "danger",
                modelValue: _ctx.sil,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sil) = $event)),
                pulse: "true",
                label: "Test Aşamasında"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["action"])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_NButton, {
        type: "warning",
        icon: "x",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showRef = false))
      }, {
        default: _withCtx(() => [
          _createTextVNode("İptal")
        ]),
        _: 1
      }),
      _createVNode(_component_NButton, {
        type: "success",
        icon: "chevrons-down",
        onClick: _ctx.kopyala
      }, {
        default: _withCtx(() => [
          _createTextVNode("Kopyala")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}