import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {GenelResmiMuhasebeTip} from '../models/GenelResmiMuhasebeTip'
import {StokHareketTip} from '../models/StokHareketTip'
import {StokFisItem} from '../models/StokFisItem'
import {DocumentItem} from '../models/DocumentItem'
import {StokFisView} from '../models/StokFisView'

export const useStokFisService = defineStore({
id: 'StokFisService',
actions: {


	async getFisler()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stokFis/Fisler/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stokFis/View/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stokFis/Sil/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async putFisGuncelle(stokFisView:StokFisView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stokFis/FisGuncelle/${shared.firm.id}/${shared.firm.period}`,{ method:'PUT', data:stokFisView });
		await request();
		return response.value;
	},

	async postFireFisi(stokFisView:StokFisView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stokFis/FireFisi/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:stokFisView });
		await request();
		return response.value;
	},

	async postGirisFisi(stokFisView:StokFisView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stokFis/GirisFisi/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:stokFisView });
		await request();
		return response.value;
	},

	async postDevirFisi(stokFisView:StokFisView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stokFis/DevirFisi/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:stokFisView });
		await request();
		return response.value;
	},

	async postCikisFisi(stokFisView:StokFisView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stokFis/CikisFisi/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:stokFisView });
		await request();
		return response.value;
	},

	async postNakilFis(stokFisView:StokFisView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stokFis/NakilFis/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:stokFisView });
		await request();
		return response.value;
	},

	async postSayimFis(stokFisView:StokFisView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stokFis/SayimFis/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:stokFisView });
		await request();
		return response.value;
	},

	async getFlatList(kayit:boolean)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stokFis/FlatList/${shared.firm.id}/${shared.firm.period}/${kayit}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getStokHareketToFisView(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/stokFis/StokHareketToFisView/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
