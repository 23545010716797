import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-026c5746"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "n-list-card-items"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NListItem = _resolveComponent("NListItem")!
  const _component_NCard = _resolveComponent("NCard")!

  return (_openBlock(), _createBlock(_component_NCard, {
    class: "n-list-card",
    scrollable: ""
  }, {
    actions: _withCtx(() => [
      _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      (_ctx.items)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
              return _renderSlot(_ctx.$slots, "list-item", {
                selectItem: _ctx.selectItem,
                item: item,
                index: i,
                key: i
              }, () => [
                _createVNode(_component_NListItem, {
                  item: item,
                  selected: _ctx.selectedItemIndex === i,
                  onSelect: ($event: any) => (_ctx.selectItem(i))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item), 1)
                  ]),
                  _: 2
                }, 1032, ["item", "selected", "onSelect"])
              ], true)
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}