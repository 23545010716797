import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NDropDownLabelItem = _resolveComponent("NDropDownLabelItem")!
  const _component_NDropdownItem = _resolveComponent("NDropdownItem")!
  const _component_ReportViewerDialog = _resolveComponent("ReportViewerDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showLabel)
      ? (_openBlock(), _createBlock(_component_NDropDownLabelItem, {
          key: 0,
          label: _ctx.getReportName()
        }, null, 8, ["label"]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reportList, (report, i) => {
      return (_openBlock(), _createBlock(_component_NDropdownItem, {
        key: i,
        icon: "printer",
        onClick: ($event: any) => (_ctx.openReportViewer(report))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(report.name), 1)
        ]),
        _: 2
      }, 1032, ["onClick"]))
    }), 128)),
    (_ctx.showDialog)
      ? (_openBlock(), _createBlock(_component_ReportViewerDialog, {
          key: 1,
          "report-name": _ctx.selectedReportName,
          "onUpdate:reportName": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedReportName) = $event)),
          show: _ctx.showDialog,
          "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showDialog) = $event)),
          "slient-print": true,
          modelValue: _ctx.dataFlatRef,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dataFlatRef) = $event)),
          "mail-send-to": _ctx.mailSendTo
        }, null, 8, ["report-name", "show", "modelValue", "mail-send-to"]))
      : _createCommentVNode("", true)
  ], 64))
}