
export enum FaturaTip{
Normal=0,
N11=1,
HepsiBurada=2,
Trendyol=3,
CicekSepeti=5,
Aliexpress=6,
Amazon=7,
Web=10,
Sac=11,
Perakende=12,
Siparis=13,
EpttAvm=14,
IsTakip=15,
Izibiz=16,
B2b=17,
Pazarama=18,
Bulten=19
}

export const FaturaTipList = [
{key:'Normal', value:0, description:'Normal'},
{key:'N11', value:1, description:'N11'},
{key:'HepsiBurada', value:2, description:'Hepsiburada'},
{key:'Trendyol', value:3, description:'Trendyol'},
{key:'CicekSepeti', value:5, description:'ÇiçekSepeti'},
{key:'Aliexpress', value:6, description:'Aliexpress'},
{key:'Amazon', value:7, description:'Amazon'},
{key:'Web', value:10, description:'E-Ticaret'},
{key:'Sac', value:11, description:'Sac'},
{key:'Perakende', value:12, description:'Perakende'},
{key:'Siparis', value:13, description:'Sipariş'},
{key:'EpttAvm', value:14, description:'EpttAvm'},
{key:'IsTakip', value:15, description:'İş Takip'},
{key:'Izibiz', value:16, description:'Izibiz'},
{key:'B2b', value:17, description:'B2B'},
{key:'Pazarama', value:18, description:'Pazarama'},
{key:'Bulten', value:19, description:'Bülten'}
]
