import {defineStore} from "pinia";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import {useHizmetMasrafService} from "@/api/services/HizmetMasrafService";
import {onHizmetMasrafHubManagerSignal} from "@/api/signals/HizmetMasrafHubManagerSignal";
import {HizmetMasrafEntity} from "@/api/models/HizmetMasrafEntity";

export const useHizmetMasrafStore = defineStore({
    id: "HizmetMasrafStore",
    state: () => ({
        store: new ArrayStore,
        signalListening:false,
        isLoaded:false,
        count:0,
        list:[] as HizmetMasrafEntity[]
    }),
    actions: {
        getQuery() {
            return this.store.createQuery();
        },
        getList(predicate: any, skip: number, take: number) {
            return this.getQuery().filter(predicate).slice(skip, take).toArray();
        },
        getEntity(stokId:string){
            return this.getList((a:any)=> a.stokId == stokId,0,1)[0];
        },
        isExist(predicate:any){
            return this.getQuery().filter(predicate).slice(0,1).toArray().length > 0;
        },
        generateDataSource(){
            return new DataSource({
                store:this.store,
                reshapeOnPush:true
            })
        },

        async load() {
            if (!this.signalListening)
                this.onSignal();
            this.isLoaded = false;
            this.list = await useHizmetMasrafService().getList();
            this.store = new ArrayStore({
                data: this.list,
                key: "id",
            });
            this.count = this.list.length;
            this.isLoaded = true;

        },
        onSignal() {
            onHizmetMasrafHubManagerSignal().Added((entity) => {
                if (entity) {
                    this.store.push([{type: "insert", data: entity}]);
                }
            })
            onHizmetMasrafHubManagerSignal().Changed((entity) => {
                if (entity) {
                    this.store.push([{type: "update", data: entity, key: entity.id}]);
                }
            })
            onHizmetMasrafHubManagerSignal().Deleted((entityId) => {
                if (entityId && entityId.length > 0) {

                    this.store.push([{type: "remove", key: entityId}]);
                }
            })
        }

    }


});
