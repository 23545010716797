import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';


export const useTrendyolWebService = defineStore({
id: 'TrendyolWebService',
actions: {


	async postGetProductsFromBarkods(liste:string[])
	{
		const { request, response } = useApi(`/api/TrendyolWeb/GetProductsFromBarkods`,{ method:'POST', data: liste });
		await request();
		return response.value;
	}
}
});
