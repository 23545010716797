import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!

  return (_openBlock(), _createBlock(_component_DxSelectBox, _mergeProps({ "on-value-changed": _ctx.valueChanged }, _ctx.$attrs, {
    "display-expr": "depoSubeAd",
    "value-expr": "depoId",
    items: _ctx.depoList,
    "search-timeout": 1,
    searchEnabled: true,
    value: _ctx.model,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    "show-clear-button": _ctx.showClearButtonRef
  }), null, 16, ["on-value-changed", "items", "value", "show-clear-button"]))
}