
import { defineComponent, reactive, ref, toRefs, watch } from "vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import NViewGrid from "@/components/ViewGrid/NViewGrid.vue";
import { SiparisView } from "@/api/models/SiparisView";
import NViewToolbar from "@/components/Toolbar/NToolbar.vue";
import NTag from "@/components/Tag/NTag.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import NButton from "@/components/Button/NButton.vue";
import NCard from "@/components/Card/NCard.vue";
import { useCachedSubeDepoService } from "@/modules/Sube/services/useCachedSubeDepoService";
import { StokFlatView } from "@/api/models/StokFlatView";
import { SiparisUtil } from "@/modules/SiparisTeklif/utils/SiparisUtil";
import StokAramaInput from "@/modules/Fis/pages/StokAramaInput.vue";
import { uuid } from "vue-uuid";
import NListView from "@/components/ListView/NListView.vue";
import { SiparisAdresItem } from "@/api/models/SiparisAdresItem";
import NFormField from "@/components/FormField/NFormField.vue";
import NInput from "@/components/Input/NInput.vue";
import { UlkeEntity } from "@/api/models/UlkeEntity";

import NTextarea from "@/components/Textarea/NTextarea.vue";
import { alert, confirm } from "devextreme/ui/dialog";
import ProjeSelectBox from "@/modules/Proje/components/ProjeSelectBox.vue";
import DepoSelectBox from "@/modules/Ortak/components/DepoSelectBox.vue";
import { CariEntity } from "@/api/models/CariEntity";
import { useSiparisStore } from "@/modules/SiparisTeklif/stores";
import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import { usePopupService } from "@/services/popupService";
import { useLayoutStore } from "@/layouts/ApplicationLayout/context";
import { FaturaHareketTip, FaturaHareketTipList } from "@/api/models/FaturaHareketTip";
import { useSiparisService } from "@/api/services/SiparisService";
import { SiparisHareketTip } from "@/api/models/SiparisHareketTip";
import { useFaturaStore } from "@/modules/Fatura/store";
import { MenuFormType } from "@/modules/Ortak/models/Menu/MenuFormType";
import { useCariService } from "@/api/services/CariService";
import PlasiyerSelectBox from "@/modules/Plasiyer/components/PlasiyerSelectBox.vue";
import { StokUtils } from "@/modules/Stok/utils/StokUtils";
import FaturaBilgiItemComponent from "@/modules/Fatura/components/FaturaBilgiItemComponent.vue";
import { FaturaView } from "@/api/models/FaturaView";
import { SiparisTeklifEntityDataSource } from "@/api/models/SiparisTeklifEntityDataSource";
import { usedCacheFirmaService } from "@/modules/Ayarlar/Firmalar/services/usedCacheFirmaService";
import ReportViewerDialog from "@/modules/Report/dialogs/ReportViewerDialog.vue";
import { ReportType } from "@/api/models/ReportType";
import ReportDropDownItemList from "@/modules/Report/components/ReportDropDownItemList.vue";
import NDropdown from "@/components/Dropdown/NDropdown.vue";
import { IdName } from "@/api/models/IdName";
import SiparisViewExtend from "@/modules/SiparisTeklif/models/SiparisViewExtend";
import SiparisSatirItemExtend from "@/modules/SiparisTeklif/models/SiparisSatirItemExtend";
import FaturaKopyalaDialogs from "@/modules/Fatura/dialogs/FaturaKopyalaDialogs.vue";
import NSwitch from "@/components/Switch/NSwitch.vue";
import { useStokFlatStore } from "@/modules/Stok/store/StokFlatStore";
import FiyatGruplariSelectBox from "@/modules/Ortak/components/FiyatGruplariSelectBox.vue";
import { useFileSettingsServiceCache } from "@/api/servicesCache/FileSettingsServiceCache";
import NCheckbox from "@/components/Checkbox/NCheckbox.vue";
import CokluDokuman from "@/modules/Ortak/components/CokluDokuman.vue";
import { useGenelCache } from "@/modules/Ortak/services/useGenelCache";
import { FaturaAltBilgiItem } from "@/api/models/FaturaAltBilgiItem";
import { SiparisTip } from "@/api/models/SiparisTip";
import { usePersonelIsTakipService } from "@/api/services/PersonelIsTakipService";
import { GenelUtils } from "@/modules/Ortak/utils/GenelUtils";
import NDropDownLabelItem from "@/components/Dropdown/NDropDownLabelItem.vue";
import SiparisTeklifCariTab from "@/modules/SiparisTeklif/components/SiparisTeklifCariTab.vue";
import SmsKargoMailGonderComponent from "@/modules/Ortak/components/SmsKargoMailGonderComponent.vue";
import SiparisTeklifOdemeComp from "@/modules/SiparisTeklif/components/SiparisTeklifOdemeComp.vue";
import SiparisSatirlari from "@/modules/SiparisTeklif/components/SiparisSatirlari.vue";
import tabs from "@/layouts/ApplicationLayout/components/Tabs.vue";
import { onStokHubManagerSignal } from "@/api/signals/StokHubManagerSignal";
import { ObjectUtils } from "@/utils/object/ObjectUtils";
import { HizmetMasrafEntity } from "@/api/models/HizmetMasrafEntity";
import { FaturaUtil } from "@/modules/Fatura/utils/FaturaUtil";
import {useSharedStore} from "@/store";
import {useCariCachedService} from "@/modules/Cari/services/useCariCachedService";
import {useCachedPlasiyerList} from "@/modules/Plasiyer/services/useCachedPlasiyerList";
import NIconIonic from "@/components/NIconIonic.vue";
import CariFormDialog from "@/modules/Cari/dialogs/CariFormDialog.vue";

export default defineComponent({
  components: {
      CariFormDialog,
      NIconIonic,
    SiparisSatirlari,
    SiparisTeklifOdemeComp,
    SmsKargoMailGonderComponent,
    SiparisTeklifCariTab,
    NDropDownLabelItem,
    CokluDokuman,
    NCheckbox,
    FiyatGruplariSelectBox,
    NSwitch,
    FaturaKopyalaDialogs,
    ReportDropDownItemList,
    ReportViewerDialog,
    FaturaBilgiItemComponent,
    PlasiyerSelectBox,

    DepoSelectBox,
    ProjeSelectBox,
    NTextarea,
    NInput,
    NDropdown,
    NFormField,
    NListView,
    StokAramaInput,
    NCard,
    NButton, NGrid, NTag, NViewToolbar, NViewGrid, NLoadingPanel
  },
  props: {},
  setup() {
    const localState = reactive({
      model: ref({} as SiparisViewExtend),
      selectedTab: ref(-1),
      adresler: ref([] as UlkeEntity[]),
      cari: ref({} as CariEntity),
      depoId: ref(""),
      subeId: ref(""),
      menu: ref({} as MenuModel),
      girilenTeslimMiktar: 0,
      getTeslimKalan: 0,
      getTeslimMiktar: 0,
      fatHareketTipList: FaturaHareketTipList,
      selectedPlasiyerId: ref("" as string),
      stokUtil: StokUtils,

      siparisUtil: SiparisUtil,
      sipTip: SiparisHareketTip,
      reportData: ref([] as SiparisTeklifEntityDataSource[]),
      showReportDialog: ref(false),
      cariFormShow: ref(false),
      reportType: ReportType,
      subeler: ref([] as IdName[]),
      reportName: ref(""),
      mailSendTo: ref(""),

      kopyalaDailog: ref(false),
      menuFormType: MenuFormType,
      solMenuDisabled: ref(false),
      fiyatGrubuId: ref(""),
      fiyatGrubuOran: ref(0),
      serverUrl: ref(""),
      tumunuTeslimEt: ref(false),
      farkliTeslimatAdresiRef: ref(false),
      siparisSatirlari:ref(),
      siparisHareketTip: ref({} as SiparisHareketTip),
      sharedStore:useSharedStore(),
      plasiyerListe: ref([] as IdName[]),
    });

    function faturaKopyalaDialogOpen() {
      localState.model.subeId = localState.subeId;
      localState.model.tip = localState.siparisHareketTip;
      localState.kopyalaDailog = true;
    }


    async function load() {
      localState.serverUrl = await useFileSettingsServiceCache().getServerUrl();
      localState.subeler = await useCachedSubeDepoService().getSubeList();
      const plasiyerListe = await useCachedPlasiyerList().getAllPlasiyerList();
      localState.plasiyerListe = plasiyerListe.map(a => ({'id': a.id, 'name': a.adSoyad})) as IdName[]

      localState.menu = useSiparisStore().menu;

      localState.siparisHareketTip = localState.menu.hareketTipi;

      const loadModel = useSiparisStore().model;


      if (loadModel && loadModel.cariId && loadModel.cariId.length > 0) {
        localState.model = new SiparisViewExtend(useSiparisStore().model);
        useSiparisStore().model = {};
      } else {
        const siparisView = await SiparisUtil.load(
          useSiparisStore().cariId,
          useSiparisStore().cariUnvan,
          useSiparisStore().hareketId
        );
        localState.model = new SiparisViewExtend(siparisView);

      }


      if (localState.model && localState.model.cariId && localState.model.cariId.length > 0){
        localState.cari = await  useCariService().getGetir(localState.model.cariId);
        localState.selectedTab = 1;
      }else{
        localState.selectedTab = 0 ;
      }

      // if (useSiparisStore().cariId && useSiparisStore().cariId.length > 0) {
      //   localState.cari = await useCariService().getGetir(useSiparisStore().cariId as string);
      //
      // }

      if (localState.cari && localState.cari.plasiyerId && localState.cari.plasiyerId.length > 0) {
        localState.selectedPlasiyerId = localState.cari.plasiyerId;
      }

      if (localState.cari && localState.cari.fiyatGrupId && localState.cari.fiyatGrupId.length && (localState.model.id == null || localState.model.id == "")) {
        localState.fiyatGrubuId = localState.cari.fiyatGrupId;
      }


      // Sol Menü Varsayılanı
      const solMenuVarsayilan = await GenelUtils.getDefaultSettings("siparisTeklifSolMenu");
      if (solMenuVarsayilan == "1") {
        localState.solMenuDisabled = true;
      } else if (solMenuVarsayilan == "0") {
        localState.solMenuDisabled = false;
      } else {
        localState.solMenuDisabled = true;
      }

       await SiparisUtil.getFaturaLoadingBakiye(localState.model.satirlar as SiparisSatirItemExtend[]);




    }



    async function adreslerLoad() {

      localState.adresler = await useGenelCache().getUlkeList() as UlkeEntity[];

    }





    async function kaydet() {

        // Tabloyu commit et (focus)
      localState.siparisSatirlari.siparisSatirDataGrid?.instance?.closeEditCell()

      localState.model.subeId = localState.subeId;
      localState.model.tip = localState.siparisHareketTip;
      const siparisEntity = await SiparisUtil.siparisKaydetVeyaGuncelle(localState.model);
      if (siparisEntity && siparisEntity.id  && siparisEntity.id.length > 0) {
        usePopupService().pushSuccessNotification({
          title: "Sipariş Kaydedildi",
          body: `${localState.model.cariUnvan} - Carisine Sipariş Başarılı Bir Şekilde Kaydedildi`
        });
        useLayoutStore().closeCurrentTab();
      }

    }

    async function kaydetVeBekle() {

      // Tabloyu commit et (focus)
      localState.siparisSatirlari.siparisSatirDataGrid?.instance?.closeEditCell()

      localState.model.subeId = localState.subeId;
      localState.model.tip = localState.siparisHareketTip;

      const siparisEntity = await SiparisUtil.siparisKaydetVeyaGuncelle(localState.model);
      if (siparisEntity && siparisEntity.id && siparisEntity.id.length > 0) {
        usePopupService().pushSuccessNotification({
          title: "Sipariş Kaydedildi",
          body: `${localState.model.cariUnvan} - Carisine Sipariş Başarılı Bir Şekilde Kaydedildi`
        });
        useLayoutStore().closeCurrentTab();
        useSiparisStore().openSiparisForm(
          localState.menu,
          localState.model.cariId as string,
          localState.model.cariUnvan as string,
          siparisEntity.id
        );
      }
    }

    async function deleteAdres(id: string) {
      if (await confirm("Adresi Silmek İstiyor Musunuz ? ", "Adres Silme")) {
        const i = localState.model!.teslimatlar!.findIndex(a => a.id == id);
        if (i > -1)
          localState.model!.teslimatlar!.splice(i, 1);
      }
    }

    function addAdres() {
      const result: SiparisAdresItem = {
        id: uuid.v4(),
        ulke: "Türkiye",
        planlananTarih: new Date().toISOString()
      };
      localState.model.teslimatlar!.push(result);
    }

    function beldeChange(data: SiparisAdresItem) {
      //data.pKodu = localState.adresler.find(a=>a.sehir == data.sehir && a.ilce == data.ilce && a.beldeAdi == data.belde);
    }


    function kontrolEt(): boolean {
      if (localState.model && localState.model.satirlar && localState.model.satirlar.length > 0) {
        const girilenMiktar = localState.model.satirlar.find(a => a.girilenTeslimMiktar! > 0);
        if (girilenMiktar || localState.tumunuTeslimEt == true) {
          if (localState.tumunuTeslimEt == true)
            localState.model.satirlar.forEach((a: any) => {
              a.girilenTeslimMiktar = a.teslimKalan;
            });
          return true;
        } else {
          alert("Teslim Edilecek Miktar Hiç Girmediniz !", "Teslim Edilecek Miktar Hatası");
          return false;
        }
      } else {
        alert("Teslim Edilecek Miktar Hiç Girmediniz !", "Teslim Edilecek Miktar Hatası");
        return false;
      }
    }

    async function convertIrsaliye() {

      // Tablo Commit
      localState.siparisSatirlari.siparisSatirDataGrid?.instance?.closeEditCell()

      const model = localState.model as SiparisView;
      if (model && model.id && model.id.length > 0) {
        if (kontrolEt() == true) {
          const faturaView: FaturaView = await useSiparisService().postSiparisToIrsaliye(localState.model, localState.menu.hareketTipi);
          let faturaHareketTip = FaturaHareketTip.SatisIrsaliye;
          if (faturaView) {
            switch (localState.menu.hareketTipi) {
              case SiparisHareketTip.AlisSiparis :
                faturaHareketTip = FaturaHareketTip.AlisIrsaliye;
                break;
              case SiparisHareketTip.SatisSiparis :
                faturaHareketTip = FaturaHareketTip.SatisIrsaliye;
                break;
            }
            if (faturaView && faturaView.ustBilgi && faturaView.ustBilgi.siparisler) {
              faturaView.ustBilgi.siparisler = [];

              faturaView.ustBilgi?.siparisler?.push({
                tarih: localState.model.tarih,
                no: localState.model.evrakNo,
                referansId: localState.model.id
              });

              if (faturaHareketTip && faturaHareketTip > 0) {

                useLayoutStore().closeCurrentTab();
                useFaturaStore().viewToFaturaForm({
                    name: localState.fatHareketTipList.find(a => a.value == faturaHareketTip)?.description,
                    hareketTipi: faturaHareketTip,
                    formType: MenuFormType.Fatura_Form
                  } as MenuModel,
                  faturaView
                );


              }
            } else
              alert("Sipariş Dönüştürlemedi", "Hata");
          }
        }
      } else {
        alert("Siparişi İlk Önce Kaydetmeniz Gerekiyor", "Sipariş Hata");
      }
    }




    async function convertFatura() {

      // Tablo Commit
      localState.siparisSatirlari.siparisSatirDataGrid?.instance?.closeEditCell()

      const model = localState.model as SiparisView;
      if (model && model.id && model.id.length > 0) {
        if (kontrolEt() == true) {
          const faturaView: FaturaView = await useSiparisService().postSiparisToIrsaliye(localState.model, localState.menu.hareketTipi);
          let faturaHareketTip = FaturaHareketTip.SatisFatura;
          if (faturaView) {
            switch (localState.menu.hareketTipi) {
              case SiparisHareketTip.AlisSiparis :
                faturaHareketTip = FaturaHareketTip.AlisFaturasi;
                break;
              case SiparisHareketTip.SatisSiparis :
                faturaHareketTip = FaturaHareketTip.SatisFatura;
                break;
            }
            faturaView.altBilgi = {
              altIskontolar: model.altIskontolar,
              cariIslemKur: model.cariIslemKur,
              tutarIskonto: model.tutarIskonto,
              cariIslemDoviz: model.cariIslemDoviz,
              faturaKalan: 0,
              faturaMaliyeti: 0,
              havaleTutar: 0,
              kasaTutar: 0,
              posTutar: 0

            } as FaturaAltBilgiItem;

            faturaView.ustBilgi?.siparisler?.push({
              tarih: localState.model.tarih,
              no: localState.model.evrakNo,
              referansId: localState.model.id

            });

            if (faturaHareketTip && faturaHareketTip > 0) {


              useLayoutStore().closeCurrentTab();
              useFaturaStore().viewToFaturaForm({
                  name: localState.fatHareketTipList.find(a => a.value == faturaHareketTip)?.description,
                  hareketTipi: faturaHareketTip,
                  formType: MenuFormType.Fatura_Form
                } as MenuModel,
                faturaView
              );


            }
          }
        }
      } else {
        alert("Siparişi İlk Önce Kaydetmeniz Gerekiyor", "Sipariş Hata");
      }

    }


    async function sil() {
      if (await confirm(`${localState.model.cariUnvan}  - Carisine Ait Sipariş Silinecektir , Onaylıyo musunuz ?`, "Sipariş Silme Onayı")) {
        if (localState.model && localState.model.siparisTip && localState.model.siparisTip == SiparisTip.IsTakip)
          await usePersonelIsTakipService().deleteSiparisSil(localState.model.id as string);
        else
          await useSiparisService().deleteSil(localState.model.id as string);

        usePopupService().pushWarningNotification({
          duration: 5000,
          title: "Başarılı",
          body:
            localState.cari.getUnvan +
            " carisine ait Sipariş/Teklif kaydı başarıyla silindi."
        });
        useLayoutStore().closeCurrentTab();
      }
    }

    function convertSiparis() {

        // Tablo Commit
        localState.siparisSatirlari.siparisSatirDataGrid?.instance?.closeEditCell()


        if (localState.model.id && localState.model.id.length > 0) {


            let tip: SiparisHareketTip;
            let tipAdi: string;
            const menuModel: MenuModel = localState.menu;
            const model: SiparisView = localState.model;

            model.teklifler?.push(
                {
                    no: localState.model.evrakNo,
                    referansId: localState.model.id,
                    tarih: localState.model.tarih
                }
            );
            model.id = "";


            if (localState.menu.hareketTipi == SiparisHareketTip.AlisTeklif) {
                tip = SiparisHareketTip.AlisSiparis;
                tipAdi = "Alış Sipariş";
            } else {
                tip = SiparisHareketTip.SatisSiparis;
                tipAdi = "Satış Sipariş";
            }


            menuModel.hareketTipi = tip;
            menuModel.name = tipAdi;

            useSiparisStore().openSiparisFormView(
                menuModel,
                localState.cari.id as string,
                localState.cari.getUnvan as string,
                "",
                model
            );
        } else
            alert("Siparişe dönüştürmek için , ilk önce KAYDET 'meniz gerekiyor", "Teklif -> Sipariş");
        //useLayoutStore().closeCurrentTab();
    }

    async function getStokResim(stokId: string) {
      const stok = useStokFlatStore().getStok(stokId) as StokFlatView;
      if (stok && stok.resimler && stok.resimler.length > 0) {
        return localState.serverUrl + stok.resimler[0];
      }
      return "";
    }


    async function reportClick(reportName: string) {
      if (reportName && reportName.length > 0) {

        await reportDataCreate();
        await setReportMail();

        // window.alert(localState.mailSendTo)
        localState.reportName = reportName;
        localState.showReportDialog = true;
      }
    }

    async function reportDataCreate() {
      localState.cari.unvan = localState.model.cariUnvan;
      localState.cari.ad = localState.model.cariAd;
      localState.cari.soyad = localState.model.cariSoyad;

      const reportData: SiparisTeklifEntityDataSource = {
        cari: localState.cari,
        plasiyerAdi: localState.plasiyerListe?.find(a => a.id == localState.cari.plasiyerId)?.name,
        firma: await usedCacheFirmaService().getFirma(),
        siparisTeklif: await useSiparisService().postToEntity(localState.model, localState.siparisHareketTip),
        kullaniciAdSoyad: localState.sharedStore.user?.name,
        kullaniciMail: localState.sharedStore.user?.userName,
      };

      if (reportData.siparisTeklif && reportData.siparisTeklif.satirlar && reportData.siparisTeklif.satirlar.length > 0) {
        for (let i = 0; i < reportData.siparisTeklif.satirlar.length; i++) {
          reportData.siparisTeklif.satirlar[i].not = await getStokResim(reportData.siparisTeklif.satirlar[i].stokId as string) as string;
        }
      }
      if (reportData && reportData.siparisTeklif && reportData.siparisTeklif.subeId && reportData.siparisTeklif.subeId.length) {
        reportData.subeAdi = localState.subeler?.find(a => a.id == reportData.siparisTeklif?.subeId)?.name as string;
      }


      localState.reportData = [];
      localState.reportData.push(reportData);
      // console.log("reportData", reportData);
    }

    function coklu(stoklar: StokFlatView[]) {
      if (stoklar && stoklar.length > 0) {
        stoklar.forEach((stok) => {
          localState.siparisSatirlari.stokDblClick(stok);
        });
      }
    }
    function stokDblClick(stok: StokFlatView) {

      localState.siparisSatirlari.stokDblClick(stok);
    }


    function cokluhHizmetDblClick(hizmetler: HizmetMasrafEntity[]) {
      if (hizmetler && hizmetler.length > 0) {
        hizmetler.forEach((a) => localState.siparisSatirlari.hizmetDblClick(a));
      }
    }
    function vadeChanged(e: any) {

      if (e.event.originalEvent.key == "Enter" && e.event.target?.value) {
        const day = e.event.target.value as string;
        if (localState.model)
          localState.model.vade = getAddedDay(day)
      }
    }

    function getAddedDay(day:string) {
      if (!day.includes('.') && localState.model) {

        let gun = parseInt(day)
        if (!gun) gun = 0;

        let tarihMs = Date.parse(localState.model.vade as string);
        tarihMs += (gun * 60 * 60 * 24 * 1000);

        const vadeTarihi = new Date(tarihMs)
        return vadeTarihi.toISOString();
      }
      return  new Date().toISOString();
    }
    function hizmetDblClick(hizmet: HizmetMasrafEntity) {
      localState.siparisSatirlari.hizmetDblClick(hizmet)
    }

    async function setReportMail() {
        // cari maili bul
        await useCariCachedService().loadTumListe()
        const cariEntity = await useCariCachedService().cariListesi?.find(a => a.id == localState.cari.id) as CariEntity
        if (cariEntity && cariEntity.adresler && cariEntity.adresler?.length > 0)
            localState.mailSendTo = cariEntity.adresler[0].mail as string
    }


    return {
      ...toRefs(localState),
      vadeChanged,
      faturaKopyalaDialogOpen,
      cokluhHizmetDblClick,
      load,
      stokDblClick,
      kaydet,
      kaydetVeBekle,
      addAdres,
      beldeChange,
      hizmetDblClick,      deleteAdres,
      convertIrsaliye,

      convertFatura,
      convertSiparis,
      coklu,
      sil,
      reportClick,
      adreslerLoad,

    };
  }
})
;
