
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import NGrid from "@/components/Grid/NGrid.vue";
import SmsKargoMailGonderComponent from "@/modules/Ortak/components/SmsKargoMailGonderComponent.vue";

import {PerakendeView} from "@/api/models/PerakendeView";
import {PerakendeSatirItem} from "@/api/models/PerakendeSatirItem";
import NTag from "@/components/Tag/NTag.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import KasaSelectBox from "@/modules/Kasa/components/KasaSelectBox.vue";
import PosSelectBox from "@/modules/Pos/components/PosSelectBox.vue";
import BankaSelectBox from "@/modules/Banka/components/BankaSelectBox.vue";
import NInput from "@/components/Input/NInput.vue";
import NTextarea from "@/components/Textarea/NTextarea.vue";
import {EFaturaSenaryoTip, EFaturaSenaryoTipList} from "@/api/models/EFaturaSenaryoTip";
import {EFaturaTipList} from "@/api/models/EFaturaTip";
import NCard from "@/components/Card/NCard.vue";
import {PerakendeIadeItem} from "@/api/models/PerakendeIadeItem";
import NButton from "@/components/Button/NButton.vue";
import {CariEntity} from "@/api/models/CariEntity";
import NIconIonic from "@/components/NIconIonic.vue";
import KampanyaDropDownList from "@/modules/Kampanya/components/KampanyaDropDownList.vue";
import KampanyaListesi from "@/modules/Perakende/components/KampanyaListesi.vue";
import CariSelectBox from "@/modules/Cari/components/CariSelectBox.vue";
import {useUserSettingsService} from "@/api/services/UserSettingsService";
import NFormFieldLabelTop from "@/components/FormField/NFormFieldLabelTop.vue";
import {alert} from "devextreme/ui/dialog";
import GenelResmiMuhasebeSelectBox from "@/modules/Ortak/components/GenelResmiMuhasebeSelectBox.vue";
import {FaturaSerileri} from "@/api/models/FaturaSerileri";
import MahalleAraSelectBox from "@/modules/Ortak/components/MahalleAraSelectBox.vue";
import {UlkeEntity} from "@/api/models/UlkeEntity";
import {useGenelCache} from "@/modules/Ortak/services/useGenelCache";
import {useCariService} from "@/api/services/CariService";
import {KurumTip} from "@/api/models/KurumTip";
import {useIzibizEfaturaService} from "@/api/services/IzibizEfaturaService";
import {usePopupService} from "@/services/popupService";
import {PKAdresItem} from "@/api/models/PKAdresItem";
import {ListeTip} from "@/api/models/ListeTip";
import {useFirmaService} from "@/api/services/FirmaService";
import {useListelerService} from "@/api/services/ListelerService";


export default defineComponent({
  components: {
    MahalleAraSelectBox,
    GenelResmiMuhasebeSelectBox,
    NFormFieldLabelTop,
    CariSelectBox,
    KampanyaListesi,
    KampanyaDropDownList,
    NIconIonic,
    NButton,
    NCard,
    NTextarea,
    NInput,
    BankaSelectBox,
    PosSelectBox,
    KasaSelectBox, NFormGrid, NFormField, NTag, SmsKargoMailGonderComponent, NGrid
  },
  props: {
    modelValue: {
      type: Object
    },
    cari: {
      type: Object
    },
    cariFiyatGrupId: {
      type: String,
      default: ""
    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      selectedTab: ref(0),
      efaturaSenoryaTip: ([{
        senaryo: EFaturaSenaryoTip.TANIMSIZ,
        pkAdres: ""
      }] as PKAdresItem[]),
      faturaSenoryaTip: EFaturaSenaryoTip,
      efaturaTip: EFaturaTipList,
      genelnetTutar: ref(0 as number),
      model: computed({
        get: () => props.modelValue as PerakendeView,
        set: val => emit("update:modelValue", val)
      }),
      cariRef: computed(() => props.cari as CariEntity),
      odenecekTutar: ref(0),
      iadeTutar: ref(0),
      alinacakTutar: ref(0),
      perakendeParaUstu: ref(false),
      selectedEfaturaSenaryoTip: (EFaturaSenaryoTip.TANIMSIZ as EFaturaSenaryoTip),


      faturaSerileri: ref([] as FaturaSerileri[]),
      faturaSerileriSelectBox: ref([] as FaturaSerileri[]),
      eFaturaSenaryoTipList: EFaturaSenaryoTipList,
      adresler: ref([] as UlkeEntity[]),
      cari: {} as CariEntity,
    });
    // watch(() => props.cariFiyatGrupId, () => localState.cariFiyatGrupIdRef = props.cariFiyatGrupId);


    const verilecekTutar = computed(() => {
      const netTutar = localState.odenecekTutar - localState.iadeTutar - localState.model.genelIskonto!;
      return (localState.alinacakTutar - netTutar);
    });
    const netTutar = computed(() => {
      const fiyat = (localState.odenecekTutar - localState.iadeTutar - localState.model.genelIskonto!) as number;
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      localState.genelnetTutar = fiyat;

      if (fiyat <= 50)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        localState.alinacakTutar = 50;
      else if (fiyat <= 100)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        localState.alinacakTutar = 100;
      else if (fiyat <= 200)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        localState.alinacakTutar = 200;
      else if (fiyat <= 400)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        localState.alinacakTutar = 400;
      else if (fiyat <= 600)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        localState.alinacakTutar = 600;
      else if (fiyat <= 800)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        localState.alinacakTutar = 800;
      else
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        localState.alinacakTutar = Math.round(Math.floor(fiyat));
      return fiyat;
    });


    load();

    async function load() {

      localState.model.kasa = false;
      localState.model.pos = false;
      localState.model.banka = false;


      perakendeParaUstuMenu();


    }


    function  eFaturaDefaultSettings(){
      localState.efaturaSenoryaTip = ([{
        senaryo: EFaturaSenaryoTip.TANIMSIZ,
        pkAdres: ""
      }] as PKAdresItem[]);
      localState.selectedEfaturaSenaryoTip = EFaturaSenaryoTip.TANIMSIZ;
      localState.model.senaryo = EFaturaSenaryoTip.TANIMSIZ;
      localState.model.faturaSeriNo = "" ;
    }
    watch(() => localState.model.bilgileriKullan, () => {
      eFaturaDefaultSettings();
    })
    watch(() => localState.model.satirlar, () => {
      odenecekTutuarHesapla();
    }, {deep: true});
    watch(() => localState.model.iadeler, () => {
      iadeTutarHesapla();
    }, {deep: true});

    watch(() => localState.selectedTab, async () => {
      localState.adresler = await useGenelCache().getUlkeList() as UlkeEntity[];
      localState.faturaSerileri = await useListelerService().getFaturaSerileri();
    })

    function onFocusIn(e: any) {
      e?.element?.querySelector(".dx-texteditor-input")?.select();
    }

    function iadeTutarHesapla() {
      if (localState.model && localState.model.iadeler && localState.model.iadeler.length > 0) {
        let fiyat = 0;
        localState.model.iadeler.forEach((satir: PerakendeIadeItem) => {
          fiyat = fiyat + (satir.fiyat! * satir.miktar! * satir.carpan!);
        });
        localState.iadeTutar = fiyat;
      }
    }

    function odenecekTutuarHesapla() {
      if (localState.model && localState.model.satirlar && localState.model.satirlar.length > 0) {
        let fiyat = 0;
        localState.model.satirlar.forEach((satir: PerakendeSatirItem) => {
          let satirFiyat = (satir.fiyat! * satir.miktar! * satir.carpan!);


          if (satir.iskontolar && satir.iskontolar.length > 0) {
            satir.iskontolar.forEach((i: number) => {
              satirFiyat *= (1 - (i / 100));
            });
          }
          fiyat = fiyat + satirFiyat;
        });
        localState.odenecekTutar = fiyat;

      } else
        localState.odenecekTutar = 0;
    }


    watch(() => (localState.model.genelIskonto , localState.model.havaleTutar , localState.model.posTutar , localState.model.kasaTutar), () => {
      odenecekTutuarHesapla();
    }, {deep: true});


    watch(() => localState.model.veresiye, (val) => {

      localState.model.posTutar = 0;
      localState.model.havaleTutar = 0;
      localState.model.kasaTutar = 0;
      localState.model.kasa = false;
      localState.model.pos = false;
      localState.model.banka = false;

      if (localState.model.veresiye == true) {
        localState.model.cokluTahsilat = false;
        emit("veresiyeOdemeClick");
      }


    });


    watch(() => localState.model.cokluTahsilat, (val) => {
      if (val == true)
        localState.model.veresiye = false;


      localState.model.posTutar = 0;
      localState.model.havaleTutar = 0;
      localState.model.kasaTutar = 0;
      localState.model.mailOrderTutar = 0;
      localState.model.mailOrder = false;
      localState.model.kasa = false;
      localState.model.pos = false;
      localState.model.banka = false;
    });


    function havaleOdeme() {
      localState.model.banka = true;
      localState.model.mailOrder = false;
      localState.model.pos = false;
      localState.model.kasa = false;
      localState.model.kasaTutar = 0;
      localState.model.havaleTutar = netTutar.value;
      localState.model.posTutar = 0;
      localState.model.mailOrderTutar = 0;
      emit("havaleOdemeClick");
    }

    function mailOrderOdeme() {
      localState.model.mailOrder = true;
      localState.model.banka = false;
      localState.model.pos = false;
      localState.model.kasa = false;
      localState.model.kasaTutar = 0;
      localState.model.mailOrderTutar = netTutar.value;
      localState.model.havaleTutar = 0;
      localState.model.posTutar = 0;
      emit("mailOrderOdemeClick");
    }

    function kasaOdeme() {
      localState.model.kasa = true;
      localState.model.banka = false;
      localState.model.mailOrder = false;
      localState.model.pos = false;
      localState.model.kasaTutar = netTutar.value;
      localState.model.havaleTutar = 0;
      localState.model.posTutar = 0;
      localState.model.mailOrderTutar = 0;
      emit("kasaOdemeClick");
    }

    function posOdeme() {
      localState.model.pos = true;
      localState.model.banka = false;
      localState.model.mailOrder = false;
      localState.model.kasa = false;
      localState.model.kasaTutar = 0;
      localState.model.havaleTutar = 0;
      localState.model.posTutar = netTutar.value;
      localState.model.mailOrderTutar = 0;
      emit("posOdemeClick");
    }

    function refreshDataGrid() {
      emit("refreshDataGrid");
    }

    async function perakendeParaUstuMenu() {
      const perakendeParaUstu = await useUserSettingsService().getGetValue("perakendeParaUstu");

      if (perakendeParaUstu && perakendeParaUstu == "1")
        localState.perakendeParaUstu = true;
      else
        localState.perakendeParaUstu = false;

    }

    function valueChangedGenelNetTutar(e: any) {

      if (e) {
        const netTutarFiyat = netTutar.value;
        let fiyat = netTutarFiyat - e.value;
        if (localState.model && localState.model.genelIskonto)
          fiyat = fiyat + localState.model.genelIskonto

        if (fiyat < 0) {
          localState.genelnetTutar = netTutar.value;
          alert("Ödenecek Tutar , Toplam Ödenecek Tutardan Büyük Olamaz", "Tutar Hatası");
        } else {
          if (fiyat > 0)
            localState.model.genelIskonto = fiyat;

        }

      }


    }

    async function efaturaOnFoucIn(refresh = true) {

      if (localState.model && localState.model.cariId && localState.model.cariId.length > 0)
        localState.cari = await useCariService().getGetir(localState.model.cariId);


      if (localState.model.bilgileriKullan == false) {

        if (localState.cari.pkAdresleri && localState.cari.pkAdresleri.length > 0 && refresh == true) {
          localState.efaturaSenoryaTip = localState.cari.pkAdresleri;
        } else {
          let idendiferNumber = "";
          switch (localState.cari.kurumTip) {
            case  KurumTip.Mukellef:
              idendiferNumber = localState.cari.vergiNo as string;
              break;
            case KurumTip.Sahis:
              idendiferNumber = localState.cari.tcNo as string;
              break;
            case KurumTip.Bos: {
              if (localState.cari.vergiNo && localState.cari.vergiNo.length > 0)
                idendiferNumber = localState.cari.vergiNo as string;
              else
                idendiferNumber = localState.cari.tcNo as string;
              break;
            }
          }

          if (idendiferNumber && idendiferNumber.length > 0) {
            localState.efaturaSenoryaTip = await useIzibizEfaturaService().getEfaturaKontrol(idendiferNumber);
            localState.cari.pkAdresleri = localState.efaturaSenoryaTip;

            if (localState.efaturaSenoryaTip.filter(a => a.senaryo == EFaturaSenaryoTip.EARSIVFATURA).length == 0)
              await useCariService().putPkAdresiKaydet(localState.efaturaSenoryaTip, localState.cari.id as string);
          } else {
            usePopupService().pushDangerNotification(
                {
                  body: "Cari VKN veya TCKNO Eksik", title: "Eksik Bilgi"
                }
            );
          }
        }

      } else {
        let idendiferNumber = "";

        if (localState.model.vergiNo && localState.model.vergiNo.length > 0)
          idendiferNumber = localState.model.vergiNo;
        if (idendiferNumber && idendiferNumber.length > 0)
          localState.efaturaSenoryaTip = await useIzibizEfaturaService().getEfaturaKontrol(idendiferNumber);


      }


    }

    async function changedEfaturaSenaryo() {


      switch (localState.selectedEfaturaSenaryoTip) {
        case EFaturaSenaryoTip.EARSIVFATURA:
          localState.faturaSerileriSelectBox = localState.faturaSerileri.filter(a => a.tip == ListeTip.EarsivSeri);
          localState.model.faturaSeriNo = localState.faturaSerileri.find(a => a.tip == ListeTip.EarsivSeri)?.seri;
          localState.model.senaryo = localState.selectedEfaturaSenaryoTip;
          break;
        case EFaturaSenaryoTip.TEMELFATURA :
        case EFaturaSenaryoTip.TICARIFATURA : {
          const firma = await useFirmaService().get();

          localState.faturaSerileriSelectBox = localState.faturaSerileri.filter(a => a.tip == ListeTip.EFaturaSeri);
          localState.model.faturaSeriNo = localState.faturaSerileri.find(a => a.tip == ListeTip.EFaturaSeri)?.seri;
          localState.model.senaryo = localState.selectedEfaturaSenaryoTip;


        }

          break;

      }


    }

    return {
      ...toRefs(localState),
      valueChangedGenelNetTutar,
      kasaOdeme,
      havaleOdeme,
      posOdeme,
      netTutar,
      onFocusIn,
      verilecekTutar,
      mailOrderOdeme,
      refreshDataGrid,
      efaturaOnFoucIn,
      changedEfaturaSenaryo,
      eFaturaDefaultSettings

    };
  }
});
