import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {HepsiBuradaValuesView} from '../models/HepsiBuradaValuesView'

export const useHepsiBuradaDegerlerService = defineStore({
id: 'HepsiBuradaDegerlerService',
actions: {


	async getGetirTumu()
	{
		const { request, response } = useApi(`/api/HepsiBuradaDegerler/GetirTumu`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetirOzellik(ozellik:string,kategoriId:number)
	{
		const { request, response } = useApi(`/api/HepsiBuradaDegerler/GetirOzellik/${ozellik}/${kategoriId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGetir(id:string)
	{
		const { request, response } = useApi(`/api/HepsiBuradaDegerler/Getir/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async deleteSil(id:string)
	{
		const { request, response } = useApi(`/api/HepsiBuradaDegerler/Sil/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async postEkle(hepsiBuradaValuesView:HepsiBuradaValuesView)
	{
		const { request, response } = useApi(`/api/HepsiBuradaDegerler/Ekle`,{ method:'POST', data:hepsiBuradaValuesView });
		await request();
		return response.value;
	}
}
});
