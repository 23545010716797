import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import { MenuFormType } from "@/modules/Ortak/models/Menu/MenuFormType";
import { MenuRefrenceType } from "@/modules/Ortak/models/Menu/MenuRefrenceType";
import { KrediKartiHareketTip } from "@/api/models/KrediKartiHareketTip";

export const KrediKartiMenuListesi: MenuModel[] = [

  {
    name: "Çıkan Devir",
    refrenceType: MenuRefrenceType.Kullanilmaz,
    formType: MenuFormType.Kredi_Karti_Dekont_Form,
    hareketTipi: KrediKartiHareketTip.KrediKartiCikanDevir,
  },
  {
    name: "Masraf Ödemesi",
    refrenceType: MenuRefrenceType.Hizmet_Masraf,
    formType: MenuFormType.Kredi_Karti_Dekont_Form,
    hareketTipi: KrediKartiHareketTip.KrediKartiIleMasrafOdeme
  }
];
