
import {computed, defineComponent, reactive, ref, toRefs} from "vue";
import {useSubeService} from "@/api/services/SubeService";
import DataSource from "devextreme/data/data_source";

export default defineComponent({
  components: {  },
  props: {
    modelValue:{ // Depo Id
      type:String
    }
  },
  setup(props,{emit}) {

    const localState = reactive({
      model:computed({
        get:()=> props.modelValue,
        set:(val)=> emit('update:modelValue',val)
      }),
      depolar:ref()
    });

    async function load() {
      const depolar =  (await useSubeService().getDepolar());
      const options = {
        store: {
          type: 'array',
          data: depolar,
          key: 'id',
        },
        group: 'subeAdi',
      }
      localState.depolar = new DataSource(options as any)
    }

    load()


    return {
      ...toRefs(localState)
    };
  }
});

