
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from "vue";

import { UlkeView } from "@/api/models/UlkeView";
import useApi from "@/api/useApi";
import dxDropDownBox from "devextreme/ui/drop_down_box";
import { useGenelCache } from "@/modules/Ortak/services/useGenelCache";


export default defineComponent({
  components: {},
  props: {
    sehir: {
      type: String,
      default: ""
    },
    ilce: {
      type: String,
      default: ""
    },
    belde: {
      type: String,
      default: ""
    },
    mahalle: {
      type: String,
      default: ""
    },
    pk: {
      type: String,
      default: ""
    },


  },
  setup(props, { emit }) {
    const localState = reactive({
      mahalleler: ref([] as UlkeView[]),
      searchValue: ref("".toLocaleLowerCase("tr-TR")),
      selectedAdres: ref(""),
      dropDown: ref(),
      mahallelerDataGrid: ref()
    });


    onMounted(async () => {
      localState.mahalleler = await useGenelCache().getMahalleList();
    });

    watch(() => localState.searchValue, () => {
      adresArama();
    });


    function adresArama() {
      localState.mahallelerDataGrid?.instance?.clearFilter();
      const searchText = localState.searchValue;
      if (searchText && searchText != "") {

        localState.mahallelerDataGrid?.instance?.filter((a: UlkeView) => {
          return a.getValue?.icindeGeciyorMu(localState.searchValue);
        });

      } else
        localState.mahallelerDataGrid?.instance?.clearFilter();
    }

    function rowClick(e: any) {
      (localState.dropDown?.instance as dxDropDownBox).close();
      if (e.key && e.key.getValue && e.key.getValue.length > 0) {
        localState.selectedAdres = e.data.getValue;
        const ulkeView = e.key as UlkeView;
        emit("update:pk", ulkeView.pk);
        emit("update:sehir", ulkeView.sehir);
        emit("update:ilce", ulkeView.ilce);
        emit("update:mahalle", ulkeView.mahalle);
        emit("update:belde", ulkeView.belde);
      } else
        localState.selectedAdres = "";

    }

    return {
      ...toRefs(localState),
      rowClick

    };
  }
});
