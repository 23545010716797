
import {computed, defineComponent, reactive, ref, toRefs, watch} from "vue";
import OzellikVaryantForm from "@/modules/StokOzellik/components/OzellikVaryantFormDialog.vue";
import {DovizList} from "@/api/models/Doviz";
import {useFileSettingsService} from "@/api/services/FileSettingsService";
import {Ozellik} from "@/api/models/Ozellik";
import NCard from "@/components/Card/NCard.vue";
import NButton from "@/components/Button/NButton.vue";
import DevExpress from "devextreme";
import {DxDataGrid} from "devextreme-vue/data-grid";
import dxDataGrid from "devextreme/ui/data_grid";
import {SubeEntity} from "@/api/models/SubeEntity";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NInput from "@/components/Input/NInput.vue";
import NMultipleImageUploader from "@/components/Uploader/NMultipleImageUploader.vue";
import {StokView} from "@/api/models/StokView";
import NIcon from "@/components/Icon/NIcon.vue";
import {useFileSettingsServiceCache} from "@/api/servicesCache/FileSettingsServiceCache";
import {useStokService} from "@/api/services/StokService";
import {string} from "yup";
import {confirm} from "devextreme/ui/dialog";


export default defineComponent({
  components: {
    NIcon,
    NMultipleImageUploader,
    NInput,
    NFormField,
    NFormGrid,
    NButton,
    NCard,
    OzellikVaryantForm
  },
  props: {
    modelValue: {
      type: Object
    },
    subeler: {
      type: Array
    }
  },
  setup(props) {
    const localState = reactive({
      model: computed(() => props.modelValue as StokView),
      dovizTipList: ref(DovizList),
      serverUrl: ref(""),
      showVaryantForm: ref(false),
      dataGrid: ref({} as DxDataGrid),
    });
    const columns = [
      {caption: "Sil", dataField: "sil", width: "30px", cellTemplate: 'sil', allowEditing: false},
      //{caption: getVaryantlarNames(), dataField: "varyantDegerleri", width: "auto", allowEditing: false},
        {caption: "Kod", dataField: "itemKod", width: "80px"},
        {caption: "Barkod", dataField: "barkod", width: "130px"},
        {caption: "Resimler", dataField: "resimler", width: "120px", cellTemplate: 'resimler', allowEditing: false},
        {caption: "HB Stok Kodu", dataField: "hbStokKod", width: "96px"},
        {caption: "ÇiçekSepeti Barkod", dataField: "cicekSepetiBarkod", width: "96px"},
        {caption: "N11 Stok Kodu", dataField: "n11StokKod", width: "96px"},
        {caption: "Trendyol Barkod", dataField: "trendyolBarkod", width: "96px"},
        {caption: "Pazarama Barkod", dataField: "pazaramaBarkod", width: "96px"},
        {caption: "Bakiye", dataField: "getKalanMiktar", width: "60px", allowEditing: false},
        {caption: "No", dataField: "no", width: "36px", allowEditing: false},
        {caption: "Alış Fiyatı", dataField: "alisFiyati", width: "84px", format: "#,##0.00"},
        {caption: "Satış Fiyatı", dataField: "satisFiyati", width: "96px", format: "#,##0.00"},
    ] as any[];


    // watch form
    watch(() => localState.showVaryantForm, (newValue) => {
      if (!newValue) {
        (localState.dataGrid?.instance as dxDataGrid)?.refresh();
        (localState.dataGrid?.instance as dxDataGrid)?.repaint();
      }
    })

    function getColumns() {
      const cols = JSON.parse(JSON.stringify(columns)) as any[];
      if (localState.model.varyantlar && columns) {
        const varyantColumns = localState.model.varyantlar.map(
            (ozellik: Ozellik, i: number) => {
              return {
                caption: ozellik.name,
                dataField: 'varyantDegerleri[' + i.toString() + ']',
                width: "auto",
                allowEditing: false
              } as any;
            }
        );
        if (varyantColumns)
        cols.splice(1, 0, ...varyantColumns);
      }
      return cols;
    }

    if (localState.model)
      if (!localState.model.varyantlar) localState.model.varyantlar = [];

    async function remove(i: number) {

      if (await confirm('Varyantı silmek istiyor musunuz?', 'Varyant Sil')) {
        if (localState.model) {
          if (localState.model.items) {
            if (isUpdateMode()){
              const stokItem = localState.model.items[i];
              await useStokService().deleteVaryantSil(localState.model.id as string, stokItem.id as string)
            }
            localState.model.items.splice(i, 1);
            (localState.dataGrid?.instance as dxDataGrid)?.refresh();
          }
        }
      }
    }

    function isUpdateMode(){
      return localState.model.id && localState.model.id.length > 1
    }

    async function load() {
      localState.serverUrl = await useFileSettingsServiceCache().getServerUrl();
    }

    // function devirlerToColumns(devirler: StokDevirItem[]): dxDataGridColumn[] {
    //
    //   const columns: dxDataGridColumn[] = [];
    //   devirler.forEach((devir, index) => columns.push(devirToColumn(devir, index)))
    //   return columns;
    // }

    function openVaryantForm() {
      localState.showVaryantForm = true;

    }

    // function devirToColumn(devir: StokDevirItem, index: number): dxDataGridColumn {
    //   return {
    //     caption: devir.sube?.name + " / " + devir.depo,
    //     dataField: `devirler[${index}]`,
    //     width: '100px'
    //   }
    // }

    function getVaryantlarNames(){
      if (localState.model && localState.model.varyantlar && localState.model.varyantlar.length > 0)
        return  localState.model.varyantlar.map((a:any) => a.name).join(', ')
      return "Özellik";
    }

    load();

    return {
      ...toRefs(localState),
      remove,
      getColumns,
      openVaryantForm
    };
  }
});
