
import { Colors } from "@/models";
import { defineComponent } from "vue";

export default defineComponent({
  name: "NCardAction",
  props: {
    padding: {
      type: String,
      default: "6px 8px"
    },
    text: Boolean,
    type: {
      type: String,
      validator: (value: Colors) =>
        [
          Colors.NEUTRAL,
          Colors.PRIMARY,
          Colors.SUCCESS,
          Colors.INFO,
          Colors.WARNING,
          Colors.DANGER
        ].includes(value),
      default: Colors.NEUTRAL
    }
  }
});
