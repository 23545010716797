import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NStatictisCard = _resolveComponent("NStatictisCard")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NCard = _resolveComponent("NCard")!

  return (_openBlock(), _createBlock(_component_NCard, { style: {"width":"100%","height":"100%"} }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_NGrid, {
          cols: "1fr 1fr 1fr 1fr",
          gap: "10px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NStatictisCard, {
              renk: "#192a56",
              sembol: "$",
              sayi: 15984
            }, {
              detay: _withCtx(() => [
                _createTextVNode(" buraya geldi ")
              ]),
              _: 1
            }),
            _createVNode(_component_NStatictisCard, {
              renk: "yellow",
              sembol: "$",
              sayi: 456456,
              detay: "true"
            }, {
              detay: _withCtx(() => [
                _createTextVNode(" buraya geldi ")
              ]),
              _: 1
            }),
            _createVNode(_component_NStatictisCard, {
              renk: "yellow",
              sembol: "$",
              sayi: 456456,
              detay: "true"
            }, {
              detay: _withCtx(() => [
                _createTextVNode(" buraya geldi ")
              ]),
              _: 1
            }),
            _createVNode(_component_NStatictisCard, {
              renk: "yellow",
              sembol: "$",
              sayi: 456456,
              detay: "true"
            }, {
              detay: _withCtx(() => [
                _createTextVNode(" buraya geldi ")
              ]),
              _: 1
            }),
            _createVNode(_component_NStatictisCard, {
              renk: "yellow",
              sembol: "$",
              sayi: 456456,
              detay: "true"
            }, {
              detay: _withCtx(() => [
                _createTextVNode(" buraya geldi ")
              ]),
              _: 1
            }),
            _createVNode(_component_NStatictisCard, {
              renk: "green",
              sembol: "$",
              sayi: 567567567
            }, {
              detay: _withCtx(() => [
                _createTextVNode(" buraya geldi ")
              ]),
              _: 1
            }),
            _createVNode(_component_NStatictisCard, {
              renk: "red",
              sembol: "$",
              sayi: 57567
            }, {
              detay: _withCtx(() => [
                _createTextVNode(" buraya geldi ")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}