
import { useSharedStore } from "@/store";
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import { SidebarPanel, Tabs } from "./components";
import { useLayoutStore } from "./context";
import { useAccountService } from "@/api/services/AccountService";
import { useSignal } from "@/services/useSignal";
import { useAccountServiceExtend } from "@/api/extends/AccountServiceExtend";

import NGrid from "@/components/Grid/NGrid.vue";

import { useCariStore } from "@/modules/Cari/store/CariStore";
import { useCachedSubeDepoService } from "@/modules/Sube/services/useCachedSubeDepoService";
import { usedCacheFirmaService } from "@/modules/Ayarlar/Firmalar/services/usedCacheFirmaService";
import { useGenelCache } from "@/modules/Ortak/services/useGenelCache";
import { useStokFlatStore } from "@/modules/Stok/store/StokFlatStore";
import { useStokStore } from "@/modules/Stok/store/StokStore";
import { useCachedHizmetMasrafGiderService } from "@/modules/HizmetMasraf/services/useCachedHizmetMasrafGiderService";
import NDropdownMenu from "@/components/Dropdown/NDropdownMenu.vue";
import NDropdown from "@/components/Dropdown/NDropdown.vue";
import NAvatar from "@/components/Avatar/NAvatar.vue";
import HizliSatisButton from "@/modules/HizliSatis/components/HizliSatisButton.vue";
import KurToolbarItem from "@/layouts/ApplicationLayout/components/KurToolbarItem.vue";
import NotificationToolbarItem from "@/layouts/ApplicationLayout/components/NotificationToolbarItem.vue";
import {useHizmetMasrafStore} from "@/modules/HizmetMasraf/store/HizmetMasrafStore";
import {useBildirimSesleriStore} from "@/modules/Ayarlar/Tanımlar/store/BildirimSesleriStore";
import ViewRenderer from "@/layouts/ApplicationLayout/components/ViewRenderer.vue";
import {usePinStore} from "@/layouts/PinLayout/store/PinStore";
import EnterPinForm from "@/layouts/PinLayout/components/EnterPinForm.vue";


export default defineComponent({
  name: "ApplicationLayout",
  components: {
      EnterPinForm,
    SidebarPanel,
    ViewRenderer,
    NotificationToolbarItem,
    KurToolbarItem,
    HizliSatisButton,
    NAvatar,
    NDropdownMenu,
    NDropdown,
    NGrid,
    Tabs
  },
  setup() {

    const layout = useLayoutStore();
    const shared = useSharedStore();
    const account = useAccountService();

    const tabs = computed(() => layout.tabs);
    const user = computed(() => shared.user);


    const route = useRoute();
    const router = useRouter();

    const showKurEditor = ref(false);

    const pinStore = usePinStore()
    const signalStatus = computed(() => useSignal().signalStatus);

    shared.setFirm(route.params);


    onMounted(async () => {

      account.getMe().then((user) => {
        shared.setUser({
          ...user
        });
      });

    });





    // first loading
    setTimeout(async () => {
    //  await useCariStore().getSelectBoxCari();
      await useCachedSubeDepoService().getSubeList();
      await useCachedSubeDepoService().getDepoList();
      await useCachedSubeDepoService().getUserDepoList();
      await useCachedSubeDepoService().getUserSubeList();
      await usedCacheFirmaService().getFirma();
      await useGenelCache().getUlkeList();
      await useGenelCache().getKdvIstisnaList();
      await useGenelCache().getTevkifatList();
      await useGenelCache().getVergiList();
      await useCachedHizmetMasrafGiderService().getHizmetMasrafList();
      await useHizmetMasrafStore().load();
      await useCachedHizmetMasrafGiderService().getGiderYerleriListe();

    }, 1000);

    setTimeout(async () => {
      await useStokFlatStore().load();
      //await useStokStore().load();
      await useBildirimSesleriStore().load();
    }, 2500);



    async function logout() {
      await useAccountServiceExtend().postLogout();
      await router.push({
        name: "Login"
      });
    }

    function mouseOver() {
      //useLayoutStore().isSidebarCollapsed = true;
    }





    return {
      ...toRefs({
      }),
      tabs,
      user,
      logout,
      signalStatus,
      mouseOver,
        pinStore

    };
  }
})
;
