import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NInputHtml = _resolveComponent("NInputHtml")!
  const _component_DxNumberBox = _resolveComponent("DxNumberBox")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_PazaryeriFiyatItem = _resolveComponent("PazaryeriFiyatItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_NFormGrid, {
      title: "Genel Bilgiler",
      cols: "1fr 1fr"
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_NFormField, {
          label: "Uyum. Günc.",
          "grid-cols": "auto auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NSwitch, {
              modelValue: _ctx.prestaRef.uyumluluklariGuncelle,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.prestaRef.uyumluluklariGuncelle) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Resimleri Günc.",
          "grid-cols": "auto auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NSwitch, {
              modelValue: _ctx.prestaRef.resimleriGuncelle,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.prestaRef.resimleriGuncelle) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Gönder",
          "grid-cols": "auto auto auto auto auto"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NSwitch, {
              modelValue: _ctx.prestaRef.isActive,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.prestaRef.isActive) = $event))
            }, null, 8, ["modelValue"]),
            _createVNode(_component_NButton, {
              icon: "plus",
              size: "small",
              onClick: _ctx.addNew
            }, {
              default: _withCtx(() => [
                _createTextVNode("Yeni Site Ekle")
              ]),
              _: 1
            }, 8, ["onClick"]),
            (_ctx.index > 0)
              ? (_openBlock(), _createBlock(_component_NButton, {
                  key: 0,
                  icon: "trash",
                  size: "small",
                  type: "danger",
                  "icon-size": 12,
                  style: {"padding":"4px"},
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.remove(_ctx.index)))
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_NFormField, {
          label: "Stok Adı",
          "grid-cols": "120px 1fr",
          span: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.prestaRef.ad,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.prestaRef.ad) = $event)),
              placeholder: _ctx.stokRef.ad
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Kısa Açıklama",
          "grid-cols": "120px 1fr",
          span: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.prestaRef.kisaAciklama,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.prestaRef.kisaAciklama) = $event)),
              placeholder: _ctx.stokRef.kisaAciklama
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Açıklama",
          "grid-cols": "120px 1fr",
          span: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInputHtml, {
              modelValue: _ctx.prestaRef.aciklama,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.prestaRef.aciklama) = $event)),
              placeholder: _ctx.stokRef.aciklama
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Barkod",
          "grid-cols": "120px 1fr",
          span: "1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.prestaRef.barkod,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.prestaRef.barkod) = $event)),
              placeholder: _ctx.stokRef.barkod
            }, null, 8, ["modelValue", "placeholder"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Hazırlanma Günü",
          "grid-cols": "120px 1fr",
          span: "1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxNumberBox, {
              value: _ctx.prestaRef.hazirlanmaGunu,
              "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.prestaRef.hazirlanmaGunu) = $event)),
              min: 0
            }, null, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Vars. Uyumluluk",
          "grid-cols": "120px 1fr",
          span: "1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.prestaRef.varsayilanUyumluluk,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.prestaRef.varsayilanUyumluluk) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Site",
          "grid-cols": "120px 1fr",
          span: "1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxSelectBox, {
              value: _ctx.prestaRef.configId,
              "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.prestaRef.configId) = $event)),
              items: _ctx.configs,
              "display-expr": "url",
              "value-expr": "id"
            }, null, 8, ["value", "items"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Seo Başlık",
          "grid-cols": "120px 1fr",
          span: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.prestaRef.seoTitle,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.prestaRef.seoTitle) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Seo Açıklama",
          "grid-cols": "120px 1fr",
          span: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.prestaRef.seoDescription,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.prestaRef.seoDescription) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Seo Etiketleri",
          "grid-cols": "120px 1fr",
          span: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_NInput, {
              modelValue: _ctx.prestaRef.seoTags,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.prestaRef.seoTags) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Outlet Kategori",
          "grid-cols": "250px 1fr",
          span: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxSelectBox, {
              value: _ctx.prestaRef.outletKategoriId,
              "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.prestaRef.outletKategoriId) = $event)),
              "data-source": _ctx.prestaKategorileriRef,
              valueExpr: "id",
              displayExpr: "name",
              searchEnabled: true,
              "show-clear-button": true,
              searchMode: "contains"
            }, null, 8, ["value", "data-source"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Kampanya Kategori",
          "grid-cols": "250px 1fr",
          span: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxSelectBox, {
              value: _ctx.prestaRef.kampanyaKategoriId,
              "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.prestaRef.kampanyaKategoriId) = $event)),
              "data-source": _ctx.prestaKategorileriRef,
              valueExpr: "id",
              displayExpr: "name",
              searchEnabled: true,
              "show-clear-button": true,
              searchMode: "contains"
            }, null, 8, ["value", "data-source"])
          ]),
          _: 1
        }),
        _createVNode(_component_NFormField, {
          label: "Fırs.Ürn. Kategori",
          "grid-cols": "250px 1fr",
          span: "2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxSelectBox, {
              value: _ctx.prestaRef.firsatUrunKategoriId,
              "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.prestaRef.firsatUrunKategoriId) = $event)),
              "data-source": _ctx.prestaKategorileriRef,
              valueExpr: "id",
              displayExpr: "name",
              searchEnabled: true,
              "show-clear-button": true,
              searchMode: "contains"
            }, null, 8, ["value", "data-source"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_PazaryeriFiyatItem, {
      "alis-kdv": _ctx.alisKdvRef,
      "onUpdate:alisKdv": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
      "alis-kdv-dahil": _ctx.alisKdvDahilRef,
      "onUpdate:alisKdvDahil": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
      "satis-kdv": _ctx.satisKdvRef,
      "onUpdate:satisKdv": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
      "satis-kdv-dahil": _ctx.satisKdvDahilRef,
      "onUpdate:satisKdvDahil": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
      "alis-fiyati": _ctx.alisFiyatRef,
      "onUpdate:alisFiyati": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
      "satis-fiyati": _ctx.satisFiyatRef,
      "onUpdate:satisFiyati": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.satisFiyatRef) = $event)),
      "kargo-fiyat": _ctx.prestaRef.kargoTutar,
      "onUpdate:kargoFiyat": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.prestaRef.kargoTutar) = $event)),
      "komisyon-oran": _ctx.prestaRef.komisyon,
      "onUpdate:komisyonOran": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.prestaRef.komisyon) = $event)),
      "fiyat-tipi": _ctx.prestaRef.fiyat.tip,
      "onUpdate:fiyatTipi": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.prestaRef.fiyat.tip) = $event)),
      deger: _ctx.prestaRef.fiyat.deger,
      "onUpdate:deger": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.prestaRef.fiyat.deger) = $event)),
      "kar-marji": _ctx.prestaRef.karMarji,
      "onUpdate:karMarji": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.prestaRef.karMarji) = $event)),
      "indirim-tipi": _ctx.prestaRef.indirim.tip,
      "onUpdate:indirimTipi": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.prestaRef.indirim.tip) = $event)),
      indirim: _ctx.prestaRef.indirim.deger,
      "onUpdate:indirim": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.prestaRef.indirim.deger) = $event)),
      "alis-doviz": _ctx.stokRef.alisDoviz,
      "onUpdate:alisDoviz": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.stokRef.alisDoviz) = $event)),
      "satis-doviz": _ctx.stokRef.satisDoviz,
      "onUpdate:satisDoviz": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.stokRef.satisDoviz) = $event)),
      maliyet: _ctx.stokRef.getMaliyet,
      "onUpdate:maliyet": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.stokRef.getMaliyet) = $event))
    }, null, 8, ["alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyati", "satis-fiyati", "kargo-fiyat", "komisyon-oran", "fiyat-tipi", "deger", "kar-marji", "indirim-tipi", "indirim", "alis-doviz", "satis-doviz", "maliyet"])
  ]))
}