import {SacUretimKesimModel} from "@/modules/SacUretim/api/SacUretimKesimModel";
import {StokTipOzellikler} from "@/api/models/StokTipOzellikler";
import {StokSimpleView} from "@/api/models/StokSimpleView";

export const KesimApi = {
    getAdet(tonaj: number,genislik: number, kalinlik: number,uzunluk: number) {
        return this.getUzunluk(tonaj, genislik, kalinlik) / uzunluk;
    },
    getUzunluk(tonaj: number, genislik: number, kalinlik: number) {
        return this.getSize(tonaj, genislik, kalinlik);
    },
    getAgirlik(genislik: number, kalinlik: number, uzunluk: number, adet:number) {
        return (genislik * kalinlik * uzunluk * 7.85 * adet) / 1000000;
    },
    getSize(weight: number, x: number, y: number) {
        //console.log("getSize (x,y)",x,y, weight / (x * y * 7.85) * 1000000)
        return weight / (x * y * 7.85) * 1000000;
    },
}