
import {computed, defineComponent, onActivated, reactive, ref, toRefs, watch} from "vue";
import NButton from "@/components/Button/NButton.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import {ImporterEntity} from "@/api/models/ImporterEntity";
import {useImporterService} from "@/api/services/ImporterService";
import {ImporterTypeList} from "@/api/models/ImporterType";
import NImporterFormDialog from "@/modules/Import/dialogs/NImporterFormDialog.vue";
import dxDataGrid from "devextreme/ui/data_grid";
import {read, utils} from "xlsx";
import NButtonFile from "@/components/Button/NButtonFile.vue";
import {DevExtremeUtils} from "@/devextreme/DevExtremeUtils";


export default defineComponent({
  components: {NButtonFile, NGrid, NButton,NImporterFormDialog},
  props: {
    tip: {
      type: Number
    },
    id: {
      type: String
    },
    show: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Function
    },
    width: {
      type: String,
      default: '900px'
    },
    useValidate: {
      type: Boolean,
      default: true
    }
  },
  setup(props, {emit}) {
    const localState = reactive({
      model: ref({} as ImporterEntity),
      data: ref([] as any[]),
      list: ref([] as ImporterEntity[]),
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val)
      }),
      selectedValue: ref(),
      subTitle: ref("-"),
      title: "İçe aktar...",
      tipRef: computed(() => props.tip),
      showFormDialog: ref(false),
      datePattern:/(\d{2})\.(\d{2})\.(\d{4})/,
      dataGrid:ref()

    });

    async function load() {

      localState.list = await useImporterService().getİmportGetAllByType(localState.tipRef as number)




      if (localState.list.length > 0) {

        if (props.useValidate) {
          localState.list.forEach(column => {

            column.columns?.push({
              caption: 'Aktarıldı',
              dataField: 'aktarildi',
              dataType: 'boolean',
              width: '80px',
              visible: true,
              allowEditing: false,
              visibleIndex:1000,
              allowExporting:false
            } as any)

            column.columns?.push({
              caption: 'Aktarım Durumu',
              dataField: 'aktarimDurumu',
              dataType: 'string',
              visible: true,
              allowEditing: false,
              visibleIndex:1001,
              allowExporting:false
            } as any)


          })

          localState.model = localState.list[0];

        }

        if (props.id && props.id.length) {
          localState.selectedValue = props.id;
        }

        const typeItem = ImporterTypeList.find(a => a.value == props.tip)

        localState.subTitle = typeItem?.description as string;


      }
    }

    watch(() => localState.showFormDialog, (val) => {
      if (val === false)
        load();
    })

    watch(() => localState.selectedValue, (val) => {
      if (val && val.length > 1) {
        localState.model = localState.list.find(a => a.id == val) as ImporterEntity;
        localState.model.columns = localState.model.columns?.filter(a => a.visible === true)
          localState.model.columns?.forEach((a:any)=>{
              if (a.alias && a.alias.length > 0){

                  a.caption = `${a.alias} (${a.caption})`
              }
          })
      }
    })

    async function save() {

      if (props.callback) {
        const data = localState.data.filter(a => a.aktarildi !== true || !props.useValidate);
        console.log("importsave DAta" ,  data);
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          try {
            if (props.callback){
              await props.callback(item);

            }
            item.aktarildi = true;
            item.aktarimDurumu = "";
          } catch (e:any) {
            item.aktarildi = false;
            item.aktarimDurumu = e.message;
          }
        }
        if (localState.data.filter(a => a.aktarildi == true).length == localState.data.length || props.useValidate === false){
          localState.data = [];
          (localState.dataGrid?.instance as dxDataGrid)?.refresh();
          setTimeout(()=> {
            localState.showRef = false;
          },300)

        }
      } else {
        alert('callback methodu belirtilmemiş!')
      }
    }


    function selectFile(event:any) {

        if (event.target.files && event.target.files.length > 0){
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e:any) => {
            localState.data = [];
            const data = new Uint8Array(e.target.result);
            console.log("data",data)
            const workbook = read(data, { type: 'array' });

            // İlk sayfayı al
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];

            // Verileri çıkar
            const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

            // json data
            const columns = localState.model.columns?.filter((a:any) => a.visible == true && a.dataField != 'aktarimDurumu' && a.dataField != 'aktarildi')
                .sort((a: any, b: any) => (a.visibleIndex > b.visibleIndex ? 1 : -1));
            //console.log("columns",columns)
            if (jsonData.length > 1)
            for (let row = 1; row < jsonData.length; row++) {
                const converted = getArrayToObject(columns as any[], jsonData[row] as any[]);
                localState.data.push(converted)
            }
        };
        reader.readAsArrayBuffer(file);
        }
    }

    function  getArrayToObject(columns:any[],row:any[]) {
        const data = {} as any;
        for (let i = 0; i < columns.length; i++) {
          //console.log(columns[i].dataField, row[i])

            const colAlias = columns[i].alias;
            const colName = columns[i].dataField as string;

            if (colAlias && colAlias.length > 0){
                data[colName + "Alias"] = colAlias;
            }

            if (columns[i].dataType == "string"){
                data[columns[i].dataField] = row[i]?.toString();
            }else{
                data[columns[i].dataField] = row[i];
            }
        }

        return data;
    }


    async function paste() {
      localState.data = [];
      const pastedData = await navigator.clipboard.readText()

      const rowsExcel = pastedData.split('\n') as string[];
      const columns = localState.model.columns?.filter((a:any) => a.visible).sort((a: any, b: any) => (a.visibleIndex > b.visibleIndex ? 1 : -1));

      // console.log("rowsExcel",rowsExcel);
      // console.log("columns",columns);


      rowsExcel.filter(a => a && a.length > 0)
          .map(a => a.trim())
          .forEach((rowExcel) => {

            const cells = rowExcel.split('\t'); // Veriler  ([0]="Stok", [1]="Fiyat")


            const data = {} as any;

            cells.forEach((cellValue, index) => {

              if (columns && columns.length > 0) {

                const colName = columns[index].dataField as string;
                const colDataType = columns[index].dataType;
                const colAlias = columns[index].alias;


                if (colAlias && colAlias.length > 0){
                    data[colName + "Alias"] = colAlias;
                }

                if (columns[index] && columns[index].dataType && colDataType == "number") {
                    let newCellValue = cellValue?.trim();
                    newCellValue = newCellValue.replaceAll(","  ,".");
                    data[colName] = parseFloat(newCellValue);
                } else if (columns[index] && columns[index].dataType && columns[index].dataType == "date")
                  data[colName] = new Date(cellValue.trim().replace(localState.datePattern, '$3-$2-$1'));
                else{
                  data[colName] = cellValue?.trim();


              }
            }
            })
            if (cells.length != columns?.length as number -2)
            {
              data.aktarildi = true;
              data.aktarimDurumu = "Sütun sayıları eşleşmiyor. Hücrelerde TAB karakteri olabilir. Lütfen temizledikten sonra tekrar deneyiniz."
            }
            console.log("data.pushed",data)
            localState.data.push(data);

          })

      // const rowData = convertTableToList(localState.columns,localState.pastedData)
      //console.log("rowData",rowData)
      //localState.gridData = rowData;

    }


    function openImportForm() {
      //
      localState.showFormDialog = true;
    }

      onActivated(() => {
          DevExtremeUtils.updateScroll(localState.dataGrid);
      });

    load();

    return {
      ...toRefs(localState),
      load,
      save,
      paste,
      openImportForm,
        selectFile
    };
  }
});
