import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {TaksitPlanNotEklemeView} from '../models/TaksitPlanNotEklemeView'
import {TaksitPlanDonemTip} from '../models/TaksitPlanDonemTip'
import {CariHareketTip} from '../models/CariHareketTip'

export const useTaksitPlanService = defineStore({
id: 'TaksitPlanService',
actions: {


	async getTumTaksitOdemler()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/TaksitPlan/TumTaksitOdemler/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getTumTaksitTahsilatlar()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/TaksitPlan/TumTaksitTahsilatlar/${shared.firm.id}/${shared.firm.period}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postNotEkleme(taksitPlanNotEklemeView:TaksitPlanNotEklemeView)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/TaksitPlan/NotEkleme/${shared.firm.id}/${shared.firm.period}`,{ method:'POST', data:taksitPlanNotEklemeView });
		await request();
		return response.value;
	},

	async getOdemeTaksitleriByTarih(donemTip:number,isOdenmis:boolean)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/TaksitPlan/OdemeTaksitleriByTarih/${shared.firm.id}/${shared.firm.period}/${donemTip}/${isOdenmis}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getTahsilatTaksitleriByTarih(donemTip:number,isOdenmis:boolean)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/TaksitPlan/TahsilatTaksitleriByTarih/${shared.firm.id}/${shared.firm.period}/${donemTip}/${isOdenmis}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getReferansHareketIdByTaksitler(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/TaksitPlan/ReferansHareketIdByTaksitler/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCariIdByTaksitlerHareketTip(id:string,tip:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/TaksitPlan/CariIdByTaksitlerHareketTip/${shared.firm.id}/${shared.firm.period}/${id}/${tip}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCariIdByTaksitler(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/TaksitPlan/CariIdByTaksitler/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCariIdByOdemeTaksitleri(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/TaksitPlan/CariIdByOdemeTaksitleri/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getCariIdByTahsilatTaksitleri(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/TaksitPlan/CariIdByTahsilatTaksitleri/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getOdemeItemByReferansId(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/TaksitPlan/OdemeItemByReferansId/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
