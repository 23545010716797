
import {computed, defineComponent, reactive, ref, toRefs} from "vue";

import NGrid from "@/components/Grid/NGrid.vue";
import {StokBarkodView} from "@/modules/Stok/models/StokBarkodView";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import {useSharedStore} from "@/store";
import {useFaturaService} from "@/api/services/FaturaService";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import dxDropDownBox from "devextreme/ui/drop_down_box";
import {UlkeView} from "@/api/models/UlkeView";

export default defineComponent({
  components: {NModalDialog},
  props: {},
  setup(props, {emit}) {

    const localState = reactive({
      listBasilacakBarkodlar: ref([] as StokBarkodView[]),
      dataSource: new DataSource({}),
      store: new CustomStore(),
      show: ref(false)
    });

    async function load() {

      localState.store = new CustomStore({
        key: "id",
        load: (options) => {

          return useFaturaService().postSevkListDataGrid({data: JSON.stringify(options)} as any);
        }
      });

      localState.dataSource = new DataSource({
        store: localState.store,
        key: "id",
        reshapeOnPush: true
      });
    }

    load()

    function rowClick(e: any) {
      //console.log(e.data.ustBilgi)
      emit('sevkSelect', e.data.ustBilgi)
      localState.show = false;
    }


    return {
      ...toRefs(localState),
      rowClick
    };
  }
});

