import {KrediKartiHareketTip, KrediKartiHareketTipList} from "@/api/models/KrediKartiHareketTip";
import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import {KrediKartiHareketView} from "@/api/models/KrediKartiHareketView";
import {useKrediKartiHareketService} from "@/api/services/KrediKartiHareketService";
import {GenelResmiMuhasebeTip} from "@/api/models/GenelResmiMuhasebeTip";
import {Doviz} from "@/api/models/Doviz";
import {KrediKartiMenuListesi} from "@/modules/KrediKarti/models/KrediKartiMenuListesi";
import {useEvrakNoService} from "@/api/services/EvrakNoService";

export const KrediKartiUtils = {
    async getKrediKartiHareketView(krediKartiId: string, hareketId: string , tutar : number): Promise<KrediKartiHareketView> {
        if (hareketId && hareketId.length > 0) {
            return await useKrediKartiHareketService().getGetirView(hareketId);

        } else {
            const evrakNo = await useEvrakNoService().getYeniEvrakNo(10) as string;
            return {
                dokumanlar: [],
                evrakNo: evrakNo,
                genelResmiMuhasebeTip: GenelResmiMuhasebeTip.Genel,
                tarih: new Date().toISOString(),
                vade: new Date().toISOString(),
                krediKartiId: krediKartiId,
                kdv : 0,
                kdvTutar : 0,
                hesaplama: {
                    islenecekDoviz: Doviz.TRY,
                    islemDoviz: Doviz.TRY,
                    islenecekKur: 1,
                    islemTutar: tutar ,
                    islemKur: 1,
                    islemYerelTutar: 0,
                    islenecekTutar: 0,
                    islenecekYerelTutar: 0,
                }
            }
        }
    },
    async dekontKaydet(menu: MenuModel, model: KrediKartiHareketView) {
        if (model.id && model.id.length > 0) {
            switch (menu.hareketTipi) {
                case KrediKartiHareketTip.KrediKartiCikanDevir:
                    await useKrediKartiHareketService().putKrediKartiCikanDevirGuncelle(model);
                    break;
                case KrediKartiHareketTip.KrediKartiIleMasrafOdeme:
                    await useKrediKartiHareketService().putKrediKartiMasrafOdemeGuncelle(model);
                    break;

            }
        } else {
            switch (menu.hareketTipi) {
                case KrediKartiHareketTip.KrediKartiCikanDevir:
                    await useKrediKartiHareketService().postKrediKartiCikanDevir(model);
                    break;
                case KrediKartiHareketTip.KrediKartiIleMasrafOdeme:
                    await useKrediKartiHareketService().postKrediKartiMasrafOdeme(model);
                    break;
            }
        }
    },

    getEnumDescription(tip: KrediKartiHareketTip) {
        const result = KrediKartiHareketTipList.find(a => a.value === tip);
        if (result) return result.description;
    },

    getKrediKartiMenu(tip: KrediKartiHareketTip) {
        for (let i = 0; i < KrediKartiMenuListesi.length; i++) {
            const mainMenu = KrediKartiMenuListesi[i];
            if (mainMenu.hareketTipi == tip) {
                return mainMenu;
            } else {
                if (mainMenu.childs && mainMenu.childs.length > 0) {
                    for (let j = 0; j < mainMenu.childs.length; j++) {
                        const subMenu = mainMenu.childs[j];
                        if (subMenu.hareketTipi === tip) return subMenu;
                    }
                }
            }
        }
    }

};
