import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!

  return (_openBlock(), _createBlock(_component_DxSelectBox, {
    items: _ctx.fiyatGrupList,
    displayExpr: "ad",
    "search-timeout": 1,
    searchEnabled: true,
    "show-clear-button": true,
    onValueChanged: _ctx.onValueChanged,
    valueExpr: "id",
    value: _ctx.model,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    placeholder: "- Fiyat Grubunu Seçiniz - "
  }, null, 8, ["items", "onValueChanged", "value"]))
}