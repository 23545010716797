
import {computed, defineComponent, onMounted, reactive, ref, toRefs} from "vue";
import {N11CategoryItem} from "@/api/models/N11CategoryItem";
import NDropDownTreeView from "@/components/DropDownTreeView/NDropDownTreeView.vue";
import {useTrendyolKategoriService} from "@/api/services/TrendyolKategoriService";
import {TrendyolPazaryeriItem} from "@/api/models/TrendyolPazaryeriItem";
import {useTrendyolMarkaService} from "@/api/services/TrendyolMarkaService";
import NDropDownListBox from "@/components/Dropdown/NDropDownListBox.vue";
import TrendyolOzellikEditor from "@/modules/Pazaryerleri/Trendyol/components/TrendyolOzellikEditor.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NTagList from "@/components/Tag/NTagList.vue";
import PazaryeriFiyatItem from "@/modules/Pazaryerleri/components/PazaryeriFiyatItem.vue";
import NAlert from "@/components/Alert/NAlert.vue";
import NRefresher from "@/components/Refresher/NRefresher.vue";
import NInputHtml from "@/components/Input/NInputHtml.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import NGrid from "@/components/Grid/NGrid.vue";
import CustomStore from "devextreme/data/custom_store";
import NDropDownListBox__ from "@/components/Dropdown/NDropDownListBox__.vue";
import NDropDownListCustomStore from "@/components/Dropdown/NDropDownListCustomStore.vue";
import {useTrendyolAyarService} from "@/api/services/TrendyolAyarService";
import {TrendyolConfigEntity} from "@/api/models/TrendyolConfigEntity";
import TrendyolBuyBoxDialog from "@/modules/Pazaryerleri/Trendyol/dialogs/TrendyolBuyBoxDialog.vue";

export default defineComponent({
    components: {
        TrendyolBuyBoxDialog,
        NGrid,
        NDropDownListCustomStore,

        NLoadingPanel,
        NInputHtml,
        NRefresher,
        NAlert,
        PazaryeriFiyatItem,
        NTagList,
        NFormField,
        NFormGrid,
        TrendyolOzellikEditor,
        NDropDownTreeView
    },
    props: [
        'trendyol',
        'stok',
        'alisKdv',
        'alisKdvDahil',
        'satisKdv',
        'satisKdvDahil',
        'alisFiyat',
        'satisFiyat',
        'index'
    ],
    setup(props, {emit}) {

        const localState = reactive({
            trendyolRef: computed(() => props.trendyol as TrendyolPazaryeriItem),
            stokRef: computed(() => props.stok),
            kategoriler: ref([] as N11CategoryItem[]),
            alisKdvRef: computed({get: () => props.alisKdv, set: (val) => emit('update:alisKdv', val)}),
            alisKdvDahilRef: computed({get: () => props.alisKdvDahil, set: (val) => emit('update:alisKdvDahil', val)}),
            satisKdvRef: computed({get: () => props.satisKdv, set: (val) => emit('update:satisKdv', val)}),
            satisKdvDahilRef: computed({
                get: () => props.satisKdvDahil,
                set: (val) => emit('update:satisKdvDahil', val)
            }),
            alisFiyatRef: computed({get: () => props.alisFiyat, set: (val) => emit('update:alisFiyat', val)}),
            satisFiyatRef: computed({get: () => props.satisFiyat, set: (val) => emit('update:satisFiyat', val)}),
            dataSourceMarkalar: new CustomStore({
                key: 'id',
                load: (loadOptions) => {
                    return useTrendyolMarkaService().postByNameAsync({data: JSON.stringify(loadOptions) as any})
                }
            }),
            showBuyBoxDialog:ref(false),
            configs:ref([] as TrendyolConfigEntity[]),
        });

        onMounted(async () => {
            await load()
        })

        async function loadKategoriler(cache: number) {
            if (!localState.trendyolRef.kategori) {
                localState.trendyolRef.kategori = {id: 0}
            }

            localState.kategoriler = await useTrendyolKategoriService().getGetir(cache);
        }

        async function load() {

            // Mağaza configleri
            localState.configs = await useTrendyolAyarService().getGetAll();
        }

        async function trendyolKatalogForm() {
            loadKategoriler(300)
        }

        function addNew() {
            if (confirm('Yeni Trendyol sekmesi eklemek istediğinize emin misiniz?'))
                if (localState.stokRef.trendyol) {
                    const clone = JSON.parse(JSON.stringify(localState.stokRef.trendyol[0]));
                    localState.stokRef.trendyol.push(clone);
                }
        }

        function remove(index: number) {
            if (confirm('Trendyol sekmesini silmek istiyor musunuz?'))
                if (localState.stokRef?.trendyol) {
                    localState.stokRef.trendyol.splice(index, 1)
                }
        }

        //loadKategoriler(300);
        //loadMarkalar(301);

        return {
            ...toRefs(localState),
            loadKategoriler,
            trendyolKatalogForm,
            addNew,
            remove
        };
    }
});

