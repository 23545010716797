
import { defineComponent, reactive, ref, toRefs } from "vue";
import { StokOzellikItem } from "@/api/models/StokOzellikItem";
import NGrid from "@/components/Grid/NGrid.vue";
import NFormGrid from "@/components/FormGrid/NFormGrid.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NImageUploader from "@/components/Uploader/NImageUploader.vue";
import NMultipleImageUploader from "@/components/Uploader/NMultipleImageUploader.vue";
import NSwitch from "@/components/Switch/NSwitch.vue";
import { useFileSettingsService } from "@/api/services/FileSettingsService";
import BirimlerSelectBox from "@/modules/Ortak/components/BirimlerSelectBox.vue";
import {useFileSettingsServiceCache} from "@/api/servicesCache/FileSettingsServiceCache";

export default defineComponent({
  components: { BirimlerSelectBox, NSwitch, NMultipleImageUploader, NFormField, NFormGrid },
  props: {
    ozellik: Object,
    ozellikDevir:{
      type:Object
    }
  },
  setup(props) {
    const localState = reactive({
      model: ref(props.ozellik as StokOzellikItem),
      serverUrl: ref("")
    });

    async function load() {
      localState.serverUrl = await useFileSettingsServiceCache().getServerUrl();
    }

    load();

    return {
      ...toRefs(localState)
    };
  }
});
