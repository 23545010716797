import { createApp, defineAsyncComponent } from "vue";
import * as Modules from "@/modules";
import { NModuleRoute } from "@/models/route.model";
import { TabNotSelectedView } from "@/components";
import { pinia, useSharedStore } from "@/store";

const RegisterModuleRoutes = (
  app: ReturnType<typeof createApp>,
  moduleRoutes: NModuleRoute[]
) => {
  const sharedStore = useSharedStore(pinia);
  moduleRoutes.forEach(route => {
    if (route.children) {
      route.children.forEach(childRoute => {
        const component = defineAsyncComponent({
          loader: childRoute.component as () => Promise<typeof import("*.vue")>,
          loadingComponent: TabNotSelectedView,
          errorComponent: TabNotSelectedView
        });
        app.component(childRoute.id, component);
      });
    } else {
      const component = defineAsyncComponent({
        loader: route.component as () => Promise<typeof import("*.vue")>,
        loadingComponent: TabNotSelectedView,
        errorComponent: TabNotSelectedView
      });
      app.component(route.id, component);
    }
    sharedStore.registerRoute(route);
  });
};

export default {
  install: (app: ReturnType<typeof createApp>) => {
    const MODULES = Object.values(Modules);
    MODULES.forEach(module => {
      RegisterModuleRoutes(app, module.Routes);
    });
  }
};
