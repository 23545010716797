<template>

<!--  <div style="padding-top: 8% ; padding-left: 8%">-->
<!--    <NGrid cols="1fr 1fr 1fr" >-->
<!--      <div>aa</div>-->
<!--      <div>bb</div>-->
<!--      <div>cc</div>-->
<!--    </NGrid>-->
<!--  </div>-->

  <NViewGrid cols="1fr">
  <div id="tab-not-selected">
    <section id="center-section">
      <img
        id="forms-graphic"
        :src="require('@/assets/svg/Forms.svg')"
        alt="Forms Image"
      />
      <div class="warning">
        Menüden istediğiniz formu seçerek işlemlerinize başlayabilirsiniz.
      </div>
    </section>
    <img
      id="arrow"
      :src="require('@/assets/svg/Arrow.svg')"
      alt="Arrow Pointing Sidebar"
    />
  </div>
  </NViewGrid>
</template>

<script>
import { defineComponent } from "vue";
import NGrid from "@/components/Grid/NGrid";
import NViewGrid from "@/components/ViewGrid/NViewGrid";

export default defineComponent({
  name: "TabNotSelected",
  components: {NViewGrid  }
});
</script>

<style lang="scss" scoped>
@use "@/styles/index.scss" as vars;

#tab-not-selected {
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

#center-section {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  transform: translate(-50%, -50%);
  .warning {
    display: block;
    margin: 40px 0 0px;
    padding: 10px 20px;
    border-radius: 10px;
    width: 100%;
    background-color: vars.$color-secondary;
    color: vars.$color-primary;
    text-align: center;
  }

  #forms-graphic {
    width: 100%;
  }
}

#arrow {
  position: absolute;
  top: 50%;
  left: 20px;
  width: 50px;
  transform: translateY(-50%);
  //animation: point-movement 3s ease-in-out infinite alternate;
}

//@keyframes point-movement {
//  0% {
//    left: 20px;
//  }
//  50% {
//    left: 50px;
//  }
//  100% {
//    left: 20px;
//  }
//}
</style>
