
export enum ReportType{
Cari=0,
CariBakiye=1,
CariHareket=2,
KasaHareket=5,
Banka=6,
StokListesi=8,
StokBarkod=9,
StokFisler=10,
Perakende=11,
Fatura=12,
Siparis_Teklif=13,
Uretim_Talimat=14,
Uretim_SacGiris=15,
MusteriCekSenet=16,
Uretim_Talimat_Barkod=17,
Uretim_StokList=18,
Uretim_Stok_Barkod_Online=19,
Stok_GenelUretim=20,
KrediKartiHareket=21,
HizmetMasrafHareket=22,
FaturaListesi=30,
Pos=31,
KargoBarkodu=32,
CariHesapOzeti=33,
Uretim_Sevk_Fisi=40,
PersonelIzinTalep=50,
PersonelListesi=51,
EIhracatTeklif=60,
FirmaCekSenet=65,
SacUretimFis=70,
SacProfilUretimFis=71
}

export const ReportTypeList = [
{key:'Cari', value:0, description:'Cari'},
{key:'CariBakiye', value:1, description:'Cari Bakiye'},
{key:'CariHareket', value:2, description:'Cari Hareket Listesi'},
{key:'KasaHareket', value:5, description:'Kasa ve Kasa Hareketi'},
{key:'Banka', value:6, description:'Banka Ve Banka Hareket'},
{key:'StokListesi', value:8, description:'Stok Listesi'},
{key:'StokBarkod', value:9, description:'Stok Barkodları'},
{key:'StokFisler', value:10, description:'Stok Fişleri'},
{key:'Perakende', value:11, description:'Perakende Fişi'},
{key:'Fatura', value:12, description:'Fatura'},
{key:'Siparis_Teklif', value:13, description:'Siparis/Teklif'},
{key:'Uretim_Talimat', value:14, description:'Üretim (Talimat Fişi)'},
{key:'Uretim_SacGiris', value:15, description:'Üretim (Saç Giriş)'},
{key:'MusteriCekSenet', value:16, description:'Müşteri Çek Senet Hareketi'},
{key:'Uretim_Talimat_Barkod', value:17, description:'Üretim (Talimat Barkod)'},
{key:'Uretim_StokList', value:18, description:'Üretim (Stok Listesi)'},
{key:'Uretim_Stok_Barkod_Online', value:19, description:'Üretim (Stok Barkod Online)'},
{key:'Stok_GenelUretim', value:20, description:'Stok Genel Üretim'},
{key:'KrediKartiHareket', value:21, description:'Kredi Kartı Hareketleri'},
{key:'HizmetMasrafHareket', value:22, description:'Hizmet Masraf Hareketleri'},
{key:'FaturaListesi', value:30, description:'Fatura Listesi'},
{key:'Pos', value:31, description:'POS ve Pos Hareket'},
{key:'KargoBarkodu', value:32, description:'Kargo Barkodu'},
{key:'CariHesapOzeti', value:33, description:'Cari Hesap Özeti'},
{key:'Uretim_Sevk_Fisi', value:40, description:'Üretim (Sevk Fişi)'},
{key:'PersonelIzinTalep', value:50, description:'Personel (İzin Talep)'},
{key:'PersonelListesi', value:51, description:'Personel Listesi'},
{key:'EIhracatTeklif', value:60, description:'E-İhracat Teklif'},
{key:'FirmaCekSenet', value:65, description:'Firma Çek Senet Hareketi'},
{key:'SacUretimFis', value:70, description:'Sac Üretim Fiş'},
{key:'SacProfilUretimFis', value:71, description:'Sac Profil Üretim Fiş'}
]
