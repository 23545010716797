import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NLoadingPanel = _resolveComponent("NLoadingPanel")!
  const _component_NCard = _resolveComponent("NCard")!
  const _component_NViewGrid = _resolveComponent("NViewGrid")!

  return (_openBlock(), _createBlock(_component_NViewGrid, {
    cols: "minmax(0,1fr)",
    rows: "minmax(0,1fr)"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_NCard, { scrollable: true }, {
        default: _withCtx(() => [
          _createVNode(_component_NLoadingPanel, {
            title: "İzinler Yükleniyor..",
            action: _ctx.load
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.message), 1)
            ]),
            _: 1
          }, 8, ["action"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}