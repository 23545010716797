import {StokFlatView} from "@/api/models/StokFlatView";
import {uuid} from "vue-uuid";
import {KargoTip} from "@/api/models/KargoTip";
import {KargoTeslimatTip} from "@/api/models/KargoTeslimatTip";
import {KargoBoyutTip} from "@/api/models/KargoBoyutTip";
import {KargoTeslimTip} from "@/api/models/KargoTeslimTip";
import {KargoOdemeTip} from "@/api/models/KargoOdemeTip";
import {PlatformTip} from "@/api/models/PlatformTip";
import {KapidaOdemeTip} from "@/api/models/KapidaOdemeTip";
import {PerakendeView} from "@/api/models/PerakendeView";
import {Doviz} from "@/api/models/Doviz";
import {KurView} from "@/api/models/KurView";
import {StokFlatUtils} from "@/modules/Stok/utils/StokFlatUtils";
import {useUserSettingsService} from "@/api/services/UserSettingsService";
import {FaturaSatirTip} from "@/api/models/FaturaSatirTip";
import {HizmetMasrafEntity} from "@/api/models/HizmetMasrafEntity";

export const PerakendeUtil = {
  async getPerakendeCariId():Promise<string> {
    const result =  await useUserSettingsService().getGetValue("PerakendeCariId");
    return result;
  },
  async savePerakendeCariId(cariId: string) {
    await useUserSettingsService().postSave({
      name: "PerakendeCariId",
      value: cariId
    });
  },
  async getPerakendeAutoPrint():Promise<boolean> {
    const autoPrint = await useUserSettingsService().getGetValue("PerakendeAutoPrint");
    if (autoPrint && autoPrint == true)
      return true;
    else
      return false;

  },
  async savePerakendeAutoPrint(autoPrint: boolean) {
    await useUserSettingsService().postSave({
      name: "PerakendeAutoPrint",
      value: autoPrint == true ? "true" : "false"
    });
  },

  newPerakende(): PerakendeView {
    return {
      subeId: "",
      mailOrderCari: {},
      mailOrder: false,
      mailOrderTutar: 0,
      veresiye: false,
      cokluTahsilat: false,
      kasaTutar: 0,
      posTutar: 0,
      havaleTutar: 0,
      cariId: "",
      evrakNo: "",
      genelIskonto: 0,
      tarih: new Date().toISOString(),
      vade: new Date().toISOString(),
      satirlar: [],
      iadeler: [],
      eFaturaGonder: false,
      bilgileriKullan:false,
      gonder: {
        kargoTip: KargoTip.Mng,
        kargoTeslimatTip: KargoTeslimatTip.StandartTeslimat,
        kargoBoyutTip: KargoBoyutTip.Paket,
        kargoTeslimTip: KargoTeslimTip.AdresTeslim,
        kargoOdemeTip: KargoOdemeTip.Gonderici,
        platformTip: PlatformTip.secilmedi,
        kapidaOdemeMi: false,
        kapidaOdemeTip: KapidaOdemeTip.Nakit,
        kargoGonder: false,
        desi: 3,
        kg: 3
      }
    };
  },


  toPerakendeSatirIade(stok: StokFlatView, depoId: string, plasiyerId: string, kurlar: KurView[]): any {
    let satisFiyat = 0;
    if (stok && stok.satisKdv && stok.satisKdv > 0) {
      if (stok.satisKdvDahil == false)
        satisFiyat = stok.satisFiyat! * (1 + (stok.satisKdv / 100));
      else
        satisFiyat = stok.satisFiyat!;
    } else {
      satisFiyat = stok.satisFiyat!;
    }
    if (stok.satisDoviz && (stok.satisDoviz == Doviz.USD || stok.satisDoviz == Doviz.EUR)) {
      const kur = kurlar.find(a => a.doviz == stok.satisDoviz);
      if (kur && kur.alis)
        satisFiyat = satisFiyat * kur.alis;
    }

    return {
      id: uuid.v4(),
      kod: stok.kod,
      ad: stok.ad,
      barkod: stok.barkodStok,
      birim: stok.birim,
      carpan: 1,
      stokId: stok.stokId,
      depoId: depoId,
      fiyat: satisFiyat,
      kdv: stok.satisKdv,
      itemId: stok.itemId,
      miktar: 1,
      plasiyerId: plasiyerId!,
      bakiye: StokFlatUtils.getKalanMiktar(stok, depoId)
    };
  },
  toPerakendeHizmetSatiItem(hizmet:HizmetMasrafEntity ,depoId: string, plasyierId: string , kurlar: KurView[]){
    let  fiyat = hizmet.satisFiyat! * (1+ (hizmet.kdv! / 100 )) ;

    if (hizmet.doviz && (hizmet.doviz == Doviz.USD || hizmet.doviz == Doviz.EUR)) {
      const kur = kurlar.find(a => a.doviz == hizmet.doviz);
      if (kur && kur.alis)
        fiyat = fiyat * kur.alis;
    }


    return {
      id: uuid.v4(),
      tip : FaturaSatirTip.HizmetMasraf ,
      kod: hizmet.kod,
      ad: hizmet.ad,
      barkod: hizmet.barkod,
      birim: hizmet.birim,
      carpan: 1,
      stokId: hizmet.id,
      depoId: depoId,
      fiyat: fiyat,

      kdv: hizmet.kdv,
      itemId: "",
      miktar: 1,
      plasiyerId: plasyierId,
      iskontolar: [],

      bakiye: 0,
    };
  },
  toPerakendeStokSatirItem(stok: any, depoId: string, plasyierId: string, kurlar: KurView[]): any {
      //console.log(stok);
    let satisFiyat = 0;
    //let miktar = 1 ;
    let carpan = 1;
    let miktar = 1;
    if (stok.carpan && stok.carpan > 1) {
      carpan = stok.carpan;
      satisFiyat = stok.fiyat!;
      // if (stok.satisKdvDahil == true ){
      //     satisFiyat =satisFiyat  *  (1 + (stok.satisKdv / 100));
      // }
    } else {
      if (stok && stok.satisKdv > 0) {
        if (stok.satisKdvDahil == false)
          satisFiyat = stok.satisFiyat! * (1 + (stok.satisKdv / 100));
        else
          satisFiyat = stok.satisFiyat!;
      } else {
        satisFiyat = stok.satisFiyat!;
      }
    }
    if (stok.satisDoviz && (stok.satisDoviz == Doviz.USD || stok.satisDoviz == Doviz.EUR)) {
      const kur = kurlar.find(a => a.doviz == stok.satisDoviz);
      if (kur && kur.alis)
        satisFiyat = satisFiyat * kur.alis;
    }

    if (stok.gelenMiktar && stok.gelenMiktar > 0)
      miktar = stok.gelenMiktar;

    //console.log(StokFlatUtils.getKalanMiktar(stok, depoId))
    return {
      id: uuid.v4(),
      tip : FaturaSatirTip.Stok ,
      kod: stok.kod,
      ad: stok.ad,
      barkod: stok.barkodStok,
      birim: stok.birim,
      carpan: carpan,
      stokId: stok.stokId,
      depoId: depoId,
      fiyat: satisFiyat,
      kdv: stok.satisKdv,
      itemId: stok.itemId,
      miktar: miktar,
      plasiyerId: plasyierId,
      iskontolar: [],
      birimler: stok.birimFiyatlari?.map((a: any) => ({
        birim: a.birim,
        carpan: a.carpan
      })),
      bakiye: StokFlatUtils.getKalanMiktar(stok, depoId)
    };
  }

};
