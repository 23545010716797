import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NSwitch = _resolveComponent("NSwitch")!
  const _component_NFormField = _resolveComponent("NFormField")!
  const _component_NDropDownTreeView = _resolveComponent("NDropDownTreeView")!
  const _component_NRefresher = _resolveComponent("NRefresher")!
  const _component_NInput = _resolveComponent("NInput")!
  const _component_NDropDownListBox = _resolveComponent("NDropDownListBox")!
  const _component_NInputHtml = _resolveComponent("NInputHtml")!
  const _component_NFormGrid = _resolveComponent("NFormGrid")!
  const _component_PazaryeriFiyatItem = _resolveComponent("PazaryeriFiyatItem")!
  const _component_PazaramaOzellikEditor = _resolveComponent("PazaramaOzellikEditor")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _normalizeProps(_guardReactiveProps(_ctx.$attrs)), [
      (_ctx.stokRef && _ctx.pazarama)
        ? (_openBlock(), _createBlock(_component_NFormGrid, {
            key: 0,
            cols: "1fr 1fr 1fr",
            title: "Pazarama'ya Gönder"
          }, {
            actions: _withCtx(() => [
              _createVNode(_component_NFormField, {
                label: "Pazarama'ya Gönder",
                "grid-cols": "1fr auto"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NSwitch, {
                    modelValue: _ctx.pazaramaRef.isActive,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pazaramaRef.isActive) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_NFormGrid, {
                cols: "1fr",
                span: "3",
                title: " "
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_NFormField, {
                    label: "Kategori",
                    "grid-cols": "120px 1fr",
                    span: "3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NRefresher, { load: _ctx.load }, {
                        default: _withCtx(() => [
                          _createVNode(_component_NDropDownTreeView, {
                            "selected-item": _ctx.selectedKategoriItem,
                            "onUpdate:selectedItem": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedKategoriItem) = $event)),
                            "data-source": _ctx.kategoriler,
                            "onUpdate:dataSource": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.kategoriler) = $event)),
                            "items-expr": "subCategories",
                            "display-expr": "name"
                          }, null, 8, ["selected-item", "data-source"])
                        ]),
                        _: 1
                      }, 8, ["load"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NFormField, {
                    label: "Barkod",
                    span: "2",
                    "grid-cols": "120px 1fr"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NInput, {
                        modelValue: _ctx.pazaramaRef.barkod,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pazaramaRef.barkod) = $event)),
                        placeholder: "Stokta barkod varsa boş bırakabilirsiniz.",
                        title: "Stokta barkod varsa boş bırakabilirsiniz.",
                        type: "text"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NFormField, {
                    label: "Stok Kodu",
                    "grid-cols": "120px 1fr",
                    span: "1"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NInput, {
                        modelValue: _ctx.pazaramaRef.kod,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pazaramaRef.kod) = $event)),
                        placeholder: "Pazarama'da kayıtlı stok kodu. Stok Pazarama'da yoksa boş bırakınız",
                        title: "Pazarama'da kayıtlı stok kodu. Stok Pazarama'da yoksa boş bırakınız",
                        type: "text"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_NFormField, {
                    label: 'Marka (' + _ctx.markalar.length + ')',
                    span: "2",
                    "grid-cols": "120px 1fr"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NRefresher, {
                        load: ()=> _ctx.loadMarkalar(0)
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_NDropDownListBox, {
                            "accept-custom-value": false,
                            "selected-item": _ctx.pazaramaRef.marka,
                            "onUpdate:selectedItem": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pazaramaRef.marka) = $event)),
                            "value-expr": "id",
                            "display-expr": "name",
                            items: _ctx.markalar
                          }, null, 8, ["selected-item", "items"])
                        ]),
                        _: 1
                      }, 8, ["load"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  (_ctx.stokRef)
                    ? (_openBlock(), _createBlock(_component_NFormField, {
                        key: 0,
                        label: "Stok Adı",
                        "grid-cols": "120px 1fr",
                        span: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_NInput, {
                            modelValue: _ctx.pazaramaRef.ad,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.pazaramaRef.ad) = $event)),
                            placeholder: _ctx.stokRef?.ad
                          }, null, 8, ["modelValue", "placeholder"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.stokRef)
                    ? (_openBlock(), _createBlock(_component_NFormField, {
                        key: 1,
                        label: "Kısa Açıklama",
                        "grid-cols": "120px 1fr",
                        span: "3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_NInput, {
                            modelValue: _ctx.pazaramaRef.kisaAciklama,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.pazaramaRef.kisaAciklama) = $event)),
                            placeholder: _ctx.stokRef?.kisaAciklama
                          }, null, 8, ["modelValue", "placeholder"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_NFormField, {
                    label: "Açıklama",
                    "grid-cols": "120px 1fr",
                    span: "3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_NInputHtml, {
                        modelValue: _ctx.pazaramaRef.aciklama,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.pazaramaRef.aciklama) = $event)),
                        placeholder: _ctx.stokRef?.aciklama
                      }, null, 8, ["modelValue", "placeholder"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_PazaryeriFiyatItem, {
        title: "Pazarama Fiyatı",
        "alis-kdv": _ctx.alisKdvRef,
        "onUpdate:alisKdv": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.alisKdvRef) = $event)),
        "alis-kdv-dahil": _ctx.alisKdvDahilRef,
        "onUpdate:alisKdvDahil": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.alisKdvDahilRef) = $event)),
        "satis-kdv": _ctx.satisKdvRef,
        "onUpdate:satisKdv": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.satisKdvRef) = $event)),
        "satis-kdv-dahil": _ctx.satisKdvDahilRef,
        "onUpdate:satisKdvDahil": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.satisKdvDahilRef) = $event)),
        "alis-fiyati": _ctx.alisFiyatRef,
        "onUpdate:alisFiyati": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.alisFiyatRef) = $event)),
        "satis-fiyati": _ctx.satisFiyatRef,
        "onUpdate:satisFiyati": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.satisFiyatRef) = $event)),
        "kargo-fiyat": _ctx.pazaramaRef.kargoTutar,
        "onUpdate:kargoFiyat": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.pazaramaRef.kargoTutar) = $event)),
        "komisyon-oran": _ctx.pazaramaRef.komisyon,
        "onUpdate:komisyonOran": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.pazaramaRef.komisyon) = $event)),
        "fiyat-tipi": _ctx.pazaramaRef.fiyat.tip,
        "onUpdate:fiyatTipi": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.pazaramaRef.fiyat.tip) = $event)),
        deger: _ctx.pazaramaRef.fiyat.deger,
        "onUpdate:deger": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.pazaramaRef.fiyat.deger) = $event)),
        "kar-marji": _ctx.pazaramaRef.karMarji,
        "onUpdate:karMarji": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.pazaramaRef.karMarji) = $event)),
        "indirim-tipi": _ctx.pazaramaRef.indirim.tip,
        "onUpdate:indirimTipi": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.pazaramaRef.indirim.tip) = $event)),
        indirim: _ctx.pazaramaRef.indirim.deger,
        "onUpdate:indirim": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.pazaramaRef.indirim.deger) = $event)),
        "alis-doviz": _ctx.stokRef.alisDoviz,
        "onUpdate:alisDoviz": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.stokRef.alisDoviz) = $event)),
        "satis-doviz": _ctx.stokRef.satisDoviz,
        "onUpdate:satisDoviz": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.stokRef.satisDoviz) = $event)),
        maliyet: _ctx.stokRef.getMaliyet,
        "onUpdate:maliyet": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.stokRef.getMaliyet) = $event))
      }, null, 8, ["alis-kdv", "alis-kdv-dahil", "satis-kdv", "satis-kdv-dahil", "alis-fiyati", "satis-fiyati", "kargo-fiyat", "komisyon-oran", "fiyat-tipi", "deger", "kar-marji", "indirim-tipi", "indirim", "alis-doviz", "satis-doviz", "maliyet"])
    ], 16),
    (_ctx.pazaramaRef && _ctx.pazaramaRef.kategoriId && _ctx.pazaramaRef.kategoriId?.length > 0)
      ? (_openBlock(), _createBlock(_component_PazaramaOzellikEditor, {
          key: 0,
          stok: _ctx.stokRef,
          "onUpdate:stok": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.stokRef) = $event)),
          "kategori-id": _ctx.pazaramaRef.kategoriId,
          "onUpdate:kategoriId": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.pazaramaRef.kategoriId) = $event)),
          ozellikler: _ctx.pazaramaRef.ozellikler,
          "onUpdate:ozellikler": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.pazaramaRef.ozellikler) = $event))
        }, null, 8, ["stok", "kategori-id", "ozellikler"]))
      : _createCommentVNode("", true)
  ], 64))
}