
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import { StokUyumluluktem } from "@/api/models/StokUyumluluktem";
import _ from "lodash";
import { ActionStatusType } from "@/api/models/ActionStatusType";
import { ActionType } from "@/api/models/ActionType";
import { PazaryeriNameType } from "@/api/models/PazaryeriNameType";
import { StokUtils } from "@/modules/Stok/utils/StokUtils";
import { useStokService } from "@/api/services/StokService";

export default defineComponent({
  props: {
    model:{
      type:Array
    },
    stokId:{
      type:String
    }
  },
  setup(props,{emit}) {

    const localState = reactive({
      modelRef:computed({
        get:()=> props.model as StokUyumluluktem[],
        set:(val) => emit('update:model',val)
      }),
      dataGrid:ref(),
      actionStatusType:ActionStatusType,
      actionType:ActionType,
      pazaryeriType:PazaryeriNameType,
      stokUtil:StokUtils
    });




    async function yapistir(){
      const text = await navigator.clipboard.readText();
      const splitRow = text.split('\n');
      splitRow.forEach((i:string)=>{
        if (i && i.trim().length > 0){
          const splitColumns = i.split('\t');
          if (splitColumns.length == 2){
            localState.modelRef.push({
              no:getNo(),
              kod:splitColumns[0],
              marka: splitColumns[1],
              hepsiBurada:{
                batchIds:[]
              },
              n11:{
                batchIds:[]
              }
            } as StokUyumluluktem)
          }
        }
      })
      localState.dataGrid?.instance?.refresh();
    }

    function rowInserting(e:any){
      e.data.no = getNo();
    }

    function getNo(){
      let max = _.max(localState.modelRef.map((a:any) => a.no?a.no:0))+1;
      if (isNaN(max))
        max = 1;
      return max;

    }

    function tekrarla(e:any,stokId:string, pazaryeriNameType:PazaryeriNameType, index:number){
      e.preventDefault()
      e.stopPropagation()
      useStokService().putPazaryeriDurumGuncelle(stokId,ActionStatusType.Ready,pazaryeriNameType,index)
    }

    return {
      ...toRefs(localState),
      yapistir,
      rowInserting,
      tekrarla
    };
  }
});

