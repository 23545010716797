import { Doviz } from "@/api/models/Doviz";
import { KasaHareketView } from "@/api/models/KasaHareketView";
import { GenelResmiMuhasebeTip } from "@/api/models/GenelResmiMuhasebeTip";
import MenuModel from "@/modules/Ortak/models/Menu/MenuModel";
import {
  KasaHareketTip,
  KasaHareketTipList
} from "@/api/models/KasaHareketTip";
import { useKasaHareketService } from "@/api/services/KasaHareketService";
import { ref } from "vue";

import { KasaMenuListesi } from "@/modules/Kasa/models/KasaMenuListesi";
import {useEvrakNoService} from "@/api/services/EvrakNoService";
import { useCachedSubeDepoService } from "@/modules/Sube/services/useCachedSubeDepoService";

export const KasaUtils = {
  getKasaMenuHareketIslem(tip: number) {
    for (let i = 0; i < KasaMenuListesi.length; i++) {
      const mainMenu = KasaMenuListesi[i];
      if (mainMenu.hareketTipi == tip) {
        return mainMenu;
      } else {
        if (mainMenu.childs && mainMenu.childs.length > 0) {
          for (let j = 0; j < mainMenu.childs.length; j++) {
            const subMenu = mainMenu.childs[j];
            if (subMenu.hareketTipi == tip) return subMenu;
          }
        }
      }
    }
  },
  getKasaHareketTipEnum(tip: number) {
    const result = KasaHareketTipList.find(a => a.value == tip);
    if (result) return result.description;
  },
  async dekontKaydet(menu: MenuModel, model: KasaHareketView) {
    const tip = KasaHareketTip;
    const useService = useKasaHareketService();

    if (model.id && model.id.length > 0) {
      switch (menu.hareketTipi) {
        case tip.KasaGirenDevir:
          await useService.putKasaGirenDevirGuncelle(model);
          break;
        case tip.KasayaBankadanCekme:
          await useService.putKasayaBankadanCekmeGuncelle(model);
          break;
        case tip.KasadanBankayaOdeme:
          await useService.putKasadanBankayaOdemeGuncelle(model);
          break;
        case tip.KasaNoksanlik:
          await useService.putKasaNoksanlikGuncelle(model);
          break;
        case tip.KasaCikanDevir:
          await useService.putKasaCikanDevirGuncelle(model);
          break;
        case tip.KasadanDovizBozdurma:
          await useService.putKasadanDovizBozdurmaGuncelle(model);
          break;
        case tip.KasayaVirmanOdeme:
          await useService.putKasayaVirmanOdemeGuncelle(model);
          break;
        case tip.KasadanMasrafOdeme:
          await useService.putKasadanMasrafOdemeGuncelle(model);
          break;
        case tip.KasadanFirmaKrediKartinaOdeme:
          await useService.putKasadanFirmaKrediKartinaOdemeGuncelle(model);
          break;
        case tip.KasaFazlalik:
          await useService.putKasaFazlalikGuncelle(model);
          break;
        case tip.KasayaDovizAlma:
          await useService.putKasaayaDovizAlmaGuncelle(model);
          break;
        case tip.KasadanVirmanTahsilati:
          await useService.putKasaVirmanTahsilatGuncelle(model);
          break;
        case tip.KasayaHizmetTahsilati:
          await useService.putKasayaHizmetTahsilatiGuncelle(model);
          break;
        case tip.KasayaFirmaKrediKartindanParaCekme:
          await useService.putKasayaFirmaKrediKartindanParaCekmeGuncelle(model);
          break;
      }
    } else {
      switch (menu.hareketTipi) {
        case tip.KasaGirenDevir:
          await useService.postKasaGirenDevir(model);
          break;
        case tip.KasayaBankadanCekme:
          await useService.postKasayaBankadanCekme(model);
          break;
        case tip.KasadanBankayaOdeme:
          await useService.postKasadanBankayaOdeme(model);
          break;
        case tip.KasaNoksanlik:
          await useService.postKasaNoksanlik(model);
          break;
        case tip.KasaCikanDevir:
          await useService.postKasaCikanDevir(model);
          break;
        case tip.KasadanDovizBozdurma:
          await useService.postKasadanDovizBozdurma(model);
          break;
        case tip.KasayaVirmanOdeme:
          await useService.postKasayaVirmanOdeme(model);
          break;
        case tip.KasadanMasrafOdeme:
          await useService.postKasadanMasrafOdeme(model);
          break;
        case tip.KasadanFirmaKrediKartinaOdeme:
          await useService.postKasadanFirmaKrediKartinaOdeme(model);
          break;
        case tip.KasaFazlalik:
          await useService.postKasaFazlalik(model);
          break;
        case tip.KasayaDovizAlma:
          await useService.postKasaayaDovizAlma(model);
          break;
        case tip.KasadanVirmanTahsilati:
          await useService.postKasaVirmanTahsilat(model);
          break;
        case tip.KasayaHizmetTahsilati:
          await useService.postKasayaHizmetTahsilati(model);
          break;
        case tip.KasayaFirmaKrediKartindanParaCekme:
          await useService.postKasayaFirmaKrediKartindanParaCekme(model);
          break;
      }
    }
  },

  getColumns() {
    return ref([
      {
        label: "Durum",
        prop: "queueDescription",
        visible: true,
        width: "80px"
      },
      { label: "Açıklama", prop: "aciklama", visible: true, width: "80px" },
      { label: "Tarih", prop: "tarih", visible: true, width: "80px" },
      { label: "Tip", prop: "tip", visible: true, width: "80px" },
      { label: "Evrak No", prop: "evrakNo", visible: true, width: "80px" },
      {
        label: "Evrak Tipi",
        prop: "genelResmiMuhasebeTip",
        visible: true,
        width: "80px"
      },
      { label: "Şube", prop: "subeId", visible: true, width: "80px" },
      { label: "İşlem", prop: "islem", visible: true, width: "150px" },
      {
        label: "Yerel Tutar",
        prop: "yerelTutar",
        visible: true,
        width: "110px"
      }
    ]);
  },
  async getHareketView(kasaId: string, hareketId: string , tutar:number) {
    if (hareketId && hareketId.length > 0) {
      return await useKasaHareketService().getGetirView(hareketId);
    } else {
      const subeler = await useCachedSubeDepoService().getUserSubeList();
      const evrakNo = await useEvrakNoService().getYeniEvrakNo(10) as string ;
      return {
        dokumanlar:[],
        kasaId: kasaId,
        evrakNo : evrakNo ,
        genelResmiMuhasebeTip: GenelResmiMuhasebeTip.Genel,
        tarih: new Date().toISOString(),
        kdv : 0 ,
        kdvTutar : 0 ,
        subeId : subeler[0]?.id,
        hesaplama: {
          islemTutar: tutar,
          islenecekYerelTutar: 0,
          islenecekTutar: 0,
          islenecekKur: 1,
          islemKur: 1,
          islemDoviz: Doviz.TRY,
          islenecekDoviz: Doviz.TRY,
          islemYerelTutar: 0
        },
      } as KasaHareketView;
    }
  }
};
