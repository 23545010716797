import useApi from '../useApi';
import { defineStore } from 'pinia';
import { useSharedStore } from '@/store';

import {DataSourceLoadOptionsItem} from '../models/DataSourceLoadOptionsItem'
import {SacUretimFisiDurum} from '../models/SacUretimFisiDurum'
import {SacUretimSatirItem} from '../models/SacUretimSatirItem'
import {SacUretimStokHizmetItem} from '../models/SacUretimStokHizmetItem'
import {SacUretimFisView} from '../models/SacUretimFisView'

export const useSacUretimFisService = defineStore({
id: 'SacUretimFisService',
actions: {


	async getDefault()
	{
		const { request, response } = useApi(`/api/sacUretimFis/Default`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getGet(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sacUretimFis/Get/${id}/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getFindFisFromSatirId(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sacUretimFis/FindFisFromSatirId/${id}/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async postListDataGrid(dataSourceLoadOptionsItem:DataSourceLoadOptionsItem)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sacUretimFis/ListDataGrid/${shared.firm.id}`,{ method:'POST', data:dataSourceLoadOptionsItem });
		await request();
		return response.value;
	},

	async postInsert(sacUretimFisView:SacUretimFisView,isSacProfil:boolean)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sacUretimFis/Insert/${shared.firm.id}/${shared.firm.period}/${isSacProfil}`,{ method:'POST', data:sacUretimFisView });
		await request();
		return response.value;
	},

	async putUpdate(sacUretimFisView:SacUretimFisView,isSacProfil:boolean)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sacUretimFis/Update/${shared.firm.id}/${shared.firm.period}/${isSacProfil}`,{ method:'PUT', data:sacUretimFisView });
		await request();
		return response.value;
	},

	async deleteDelete(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sacUretimFis/Delete/${shared.firm.id}/${shared.firm.period}/${id}`,{ method:'DELETE' });
		await request();
		return response.value;
	},

	async getIsHareketMevcut(stokId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sacUretimFis/IsHareketMevcut/${shared.firm.id}/${shared.firm.period}/${stokId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getFindFisFromStokId(stokId:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sacUretimFis/FindFisFromStokId/${shared.firm.id}/${stokId}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async putDurumDegistir(id:string,durum:number)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sacUretimFis/DurumDegistir/${shared.firm.id}/${id}/${durum}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async putUretimiTamamla(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sacUretimFis/UretimiTamamla/${shared.firm.id}/${id}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async putUretimiGeriAl(id:string)
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sacUretimFis/UretimiGeriAl/${shared.firm.id}/${id}`,{ method:'PUT' });
		await request();
		return response.value;
	},

	async getSacUretimFisReportView()
	{
		const { request, response } = useApi(`/api/sacUretimFis/SacUretimFisReportView`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getAciklamaList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sacUretimFis/AciklamaList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	},

	async getPaketlemeList()
	{
		const shared = useSharedStore();
		const { request, response } = useApi(`/api/sacUretimFis/PaketlemeList/${shared.firm.id}`,{ method:'GET' });
		await request();
		return response.value;
	}
}
});
