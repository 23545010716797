
import {defineComponent, reactive, ref, toRefs} from "vue";
import {useKampanyaService} from "@/api/services/KampanyaService";
import {KampanyaEntity} from "@/api/models/KampanyaEntity";

export default defineComponent({
  components: {},
  props: {},

  setup() {
    const localState = reactive({
      list:ref([] as KampanyaEntity[]),
    })

    async function load() {
      localState.list = await useKampanyaService().getGetAllAktif()
    }

    load();

    return {
      ...toRefs(localState)
    }
  }
})
