import {defineStore} from "pinia";
import BasitUretimProp from "@/modules/Stok/models/BasitUretimProp";
import {NModuleRoute} from "@/models";
import {useLayoutStore} from "@/layouts/ApplicationLayout/context";

export const useBasitUretimStore = defineStore({
    id: "BasitUretimStore",
    state: () => ({
        basitUretimFormProp: {} as BasitUretimProp,
    }),
    actions:{
        openBasitUretimForm(id:string){
            this.basitUretimFormProp = {id : id};
            const route: NModuleRoute = {
                id: "BasitUretimForm",
                order: 0,
                displayName: "Genel Üretim Fiş",
                icon: {
                    type: "file-minus"
                }
            };
            useLayoutStore().openRouteAsTab(route);
        }
    }

})
