import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KasaSelectBox = _resolveComponent("KasaSelectBox")!
  const _component_NFormFieldLabelTop = _resolveComponent("NFormFieldLabelTop")!
  const _component_BankaSelectBox = _resolveComponent("BankaSelectBox")!
  const _component_PosSelectBox = _resolveComponent("PosSelectBox")!
  const _component_NButton = _resolveComponent("NButton")!
  const _component_NGrid = _resolveComponent("NGrid")!
  const _component_NCard = _resolveComponent("NCard")!

  return (_ctx.model)
    ? (_openBlock(), _createBlock(_component_NCard, {
        key: 0,
        cols: "1fr",
        title: "Ödeme Bilgisi",
        gap: "0px"
      }, {
        bottom: _withCtx(() => [
          (_ctx.model.isKasaOdeme)
            ? (_openBlock(), _createBlock(_component_NFormFieldLabelTop, {
                key: 0,
                label: "Kasa Seçiniz"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_KasaSelectBox, {
                    modelValue: _ctx.model.kasaId,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.kasaId) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.model.isBankaOdeme)
            ? (_openBlock(), _createBlock(_component_NFormFieldLabelTop, {
                key: 1,
                label: "Banka Seçiniz"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BankaSelectBox, {
                    modelValue: _ctx.model.bankaId,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.bankaId) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.model.isPosOdeme)
            ? (_openBlock(), _createBlock(_component_NFormFieldLabelTop, {
                key: 2,
                label: "Pos Seçiniz"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_PosSelectBox, {
                    modelValue: _ctx.model.posId,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.posId) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_NGrid, {
            cols: "1fr 1fr 1fr",
            gap: "2px"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_NButton, {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.nakitOdeme())),
                type: "success"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Nakit")
                ]),
                _: 1
              }),
              _createVNode(_component_NButton, {
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.bankaOdeme())),
                type: "warning"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Havale")
                ]),
                _: 1
              }),
              _createVNode(_component_NButton, {
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.posOdeme())),
                type: "info"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Pos")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}