
import { computed, defineComponent, reactive, ref, toRefs } from "vue";
import NButton from "@/components/Button/NButton.vue";
import NFormField from "@/components/FormField/NFormField.vue";
import NLoadingPanel from "@/components/Loading/NLoadingPanel.vue";
import NModalDialog from "@/components/Modal/NModalDialog.vue";
import { useSiparisService } from "@/api/services/SiparisService";
import { SiparisHareketTip, SiparisHareketTipList } from "@/api/models/SiparisHareketTip";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { SiparisSatirFlatView } from "@/api/models/SiparisSatirFlatView";
import { DovizList } from "@/api/models/Doviz";
import { FaturaSatirTipList } from "@/api/models/FaturaSatirTip";
import { boolean } from "yup";

export default defineComponent({
  components: { NModalDialog, NLoadingPanel, NFormField, NButton },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    cariId: {
      type: String,
      default: null
    },
    siparisHareketTip : {
      type:Number,
      default : 0 ,
    }
  },
  setup(props, { emit }) {
    const localState = reactive({
      showRef: computed({
        get: () => props.show,
        set: (val) => emit("update:show", val)
      }),
      dataSource: ref(),
      liste: ref([] as SiparisSatirFlatView[]),
      startDate: ref(new Date().addMonths(-1)),
      endDate: ref(new Date()),
      siparisHareketTipListRef: SiparisHareketTipList,
      dovizList: DovizList,
      satirTipList: FaturaSatirTipList,
      saveDisabled: ref(true),
      selectedRows: [] as SiparisSatirFlatView[]
    });


    async function load() {

      localState.liste = await useSiparisService().getSiparisSatirFlatListBekleyenler(props.cariId as string, props.siparisHareketTip as SiparisHareketTip, localState.startDate.toISOString(), localState.endDate.toISOString());
      const store = new ArrayStore({
        key: "id",
        data: localState.liste
      });
      const dataSourceOptions = {
        key: "id",
        store: store
      };
      localState.dataSource = new DataSource(dataSourceOptions);

    }

    function rowDblClick(data: any) {
      if (data) {
        emit("addRow", data);
      }
      localState.showRef = false;
    }

    function aktar() {
      localState.selectedRows.forEach((a) => {
        emit("addRow", a);
      });
      localState.showRef = false;
    }

    function selectionChanged(e: any) {
      localState.selectedRows = e.selectedRowsData;
    }

    return {

      ...toRefs(localState),
      selectionChanged,
      load,
      rowDblClick,
      aktar

    };
  }
});
